import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { useUpdateEffect } from 'react-use';

// utils
import { STATE_OPTIONS, US_STATES } from 'utils';

// components
import {
  BetstampWordWhiteText,
  BetstampWordBlueText,
} from 'components/generic/Logos';
import { Row, Col } from 'components/generic/Layout';
import { AuthTextInput } from 'components/AuthTextInput';
import { AuthButton } from 'components/AuthButton';
import AffiliateLink from 'components/AffiliateLink';
import CustomSelect from 'components/generic/Select';

// actions
import {
  checkReferralCodeExists,
  getAffiliates,
  getSportsbookInfoMap,
} from 'actions';

export default function Vip() {
  const dispatch = useDispatch();
  const reduxProps = useSelector(state => ({
    referralCodeExists: state.authReducer.referralCodeExists,
    affiliates: state.affiliatesReducer.affiliates,
    isLoadingAffiliates: state.affiliatesReducer.isLoadingAffiliates,
    sportsbookInfoMap: state.affiliatesReducer.sportsbookInfoMap,
  }));
  const {
    referralCodeExists,
    affiliates,
    isLoadingAffiliates,
    sportsbookInfoMap,
  } = reduxProps;

  const history = useHistory();
  const urlParams = useLocation().search;
  const params = useMemo(() => new URLSearchParams(urlParams), [urlParams]);

  const [refCode, setRefCode] = useState(params.get('referral_code'));
  const [region, setRegion] = useState(params.get('region'));
  const [autoRedirect, setAutoRedirect] = useState(
    params.get('referral_code') && params.get('region')
  );
  const [requestedAffs, setRequestedAffs] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (refCode) {
      dispatch(checkReferralCodeExists(refCode));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (affiliates.length > 0) {
      window.scrollTo(0, 0);
    }
  }, [affiliates]);

  useUpdateEffect(() => {
    if (isLoadingAffiliates && !requestedAffs) {
      setRequestedAffs(true);
    }
  }, [isLoadingAffiliates, requestedAffs]);

  const noLinks = affiliates.reduce((acc, curr) => {
    return acc && curr.affiliate_links.length === 0;
  }, true);

  if (!isLoadingAffiliates && requestedAffs && noLinks) {
    return (
      <Col
        style={{
          minHeight: '100vh',
          padding: 'var(--space-xs)',
          backgroundColor: 'var(--color-primary)',
          justifyContent: 'center',
        }}
      >
        <div style={{ width: '100%', maxWidth: '1200px', textAlign: 'center' }}>
          <h4 style={{ color: 'white' }}>
            Sorry, we have no offers in {region}
          </h4>
          <AuthButton
            onPress={() => {
              setRegion(null);
              setRequestedAffs(false);
              history.push('/vip');
            }}
            colorTheme="white"
          >
            Try another region
          </AuthButton>
        </div>
      </Col>
    );
  }

  if (affiliates.length > 0 && noLinks === false) {
    const referralCode = params.get('referral_code') || refCode;
    return (
      <Col
        style={{
          minHeight: '100vh',
          justifyContent: 'flex-start',
          padding: '0 var(--space-xs)',
          paddingBottom: 'var(--space-md)',
        }}
      >
        <div
          style={{
            position: 'sticky',
            top: 0,
            backgroundColor: 'var(--color-bg)',
            padding: 'var(--space-xxs) 0',
          }}
        >
          <ol>
            <li>Open a betting account by clicking one of the links below</li>
            <li>Deposit at least the minimum balance</li>
            <li>Place one bet</li>
          </ol>
        </div>
        {affiliates.map((aff, i) => (
          <Row key={`street-promo-aff-link-${aff.id}`}>
            <AffiliateLink
              containerStyle={{ margin: 'var(--space-xxxxs) 0' }}
              book={aff}
              fromPage={`${region} Vip`}
              shape="bar"
              hideLinkable
              //useMinBalanceBanner
              hideText
              referralCode={referralCode}
              showMinDeposit={true}
              useGroupsMinDeposit
              src={
                sportsbookInfoMap[aff?.id]?.betlink_banner ||
                sportsbookInfoMap[aff?.id]?.book_banner_offer_and_min ||
                sportsbookInfoMap[aff?.id]?.book_banner_min ||
                sportsbookInfoMap[aff?.id]?.book_banner_offer ||
                undefined
              }
            />
          </Row>
        ))}
        <br />
        <BetstampWordBlueText height={100} />
        {region === 'Ontario' && (
          <p>
            19+ Gambling can be addictive, please play responsibly. Visit{' '}
            <a href="https://www.connexontario.ca">ConnexOntario.ca</a> or call
            1-800-531-2600 for responsible gambling information.
          </p>
        )}
        {US_STATES.includes(region) && (
          <p>
            21+ Gambling can be addictive, please play responsibly. If you or
            someone you know has a gambling problem and wants help, please call
            1-800 GAMBLERS in the U.S.
          </p>
        )}
        {region === 'Ontario' && (
          <p>bet365 operates pursuant to an agreement with iGaming Ontario.</p>
        )}
      </Col>
    );
  }

  return (
    <Col
      style={{
        minHeight: '100vh',
        padding: 'var(--space-xs)',
        backgroundColor: 'var(--color-primary)',
        justifyContent: 'center',
      }}
    >
      <div style={{ width: '100%', maxWidth: '1200px', textAlign: 'center' }}>
        <BetstampWordWhiteText height={100} />
        {params.get('referral_code') && (
          <>
            <br />
            <b style={{ color: 'white' }}>{params.get('referral_code')}</b>
          </>
        )}
        {!params.get('referral_code') && (
          <>
            <h5
              style={{
                textAlign: 'center',
                color: 'white',
              }}
            >
              Your Promo Code
            </h5>
            {!referralCodeExists &&
              region &&
              (refCode || params.get('referral_code')) && (
                <span
                  style={{
                    color: 'var(--color-danger)',
                    backgroundColor: 'rgba(255,255,255,0.80)',
                    padding: 'var(--space-xxs)',
                    margin: 'var(--space-xs)',
                    borderRadius: 'var(--std-border-radius)',
                  }}
                >
                  Referral code is invalid{' '}
                </span>
              )}
            <AuthTextInput
              placeholder="Enter code here"
              onChangeText={text => {
                dispatch(checkReferralCodeExists(text));
                setRefCode(text.toUpperCase());
              }}
              value={refCode || ''}
              inputRowStyle={{
                borderColor: '#ddd',
                borderRadius: 0,
                backgroundColor: 'transparent',
              }}
              inputStyle={{ textAlign: 'center', color: 'white' }}
              placeholderColor="#ddd"
            />
          </>
        )}
        {!params.get('region') && (
          <>
            <h5
              style={{
                textAlign: 'center',
                color: 'white',
              }}
            >
              Where are you located?
            </h5>
            <Row style={{ flex: 0 }}>
              <CustomSelect
                options={STATE_OPTIONS.map(s => ({
                  label: s,
                  value: s,
                }))}
                onChange={opt => setRegion(opt.value)}
                styles={{
                  placeholder: base => ({
                    ...base,
                    color: '#ddd',
                  }),
                  dropdownIndicator: base => ({
                    ...base,
                    color: 'white',
                  }),
                  indicatorSeparator: base => ({
                    ...base,
                    backgroundColor: 'white',
                  }),
                  singleValue: base => ({
                    ...base,
                    color: 'white',
                  }),
                  input: base => ({
                    ...base,
                    color: 'white',
                  }),
                  control: base => ({
                    ...base,
                    background: 'transparent',
                    border: '1px solid #ddd',
                    borderRadius: 0,
                    padding: 'var(--space-xxxs)',
                    color: '#ddd',
                  }),
                }}
              />
            </Row>
          </>
        )}

        {autoRedirect && isLoadingAffiliates && (
          <h5 style={{ color: 'white' }}>Loading Exclusive Offers</h5>
        )}

        {referralCodeExists && region && refCode && (
          <AuthButton
            colorTheme="white"
            containerStyle={{ flex: 0 }}
            isLoading={isLoadingAffiliates}
            disabled={
              !region || !refCode || refCode === '' || !referralCodeExists
            }
            onPress={() => {
              history.replace(`/vip?referral_code=${refCode}&region=${region}`);
              dispatch(getAffiliates(refCode, false, region, 'vip'));
              dispatch(getSportsbookInfoMap(region));
            }}
          >
            Next
          </AuthButton>
        )}
      </div>
      <div style={{ height: '200px' }} />
    </Col>
  );
}
