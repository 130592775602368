import { Observable } from 'rxjs';
import 'rxjs/add/operator/exhaustMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import { ajax } from 'rxjs/observable/dom/ajax';
import { BACKEND_API_URL, getHeaders } from 'utils';

import {
  START_PURCHASE_SESSION,
  startPurchaseSessionSuccess,
  startPurchaseSessionFailure,
} from 'actions';

export default function startPurchaseSession(action$, state$) {
  return action$.ofType(START_PURCHASE_SESSION).exhaustMap(action => {
    return ajax
      .post(
        BACKEND_API_URL + 'api/purchase_sessions/',
        action.purchaseSession,
        getHeaders(state$)
      )
      .map(data => startPurchaseSessionSuccess(data))
      .catch(error => Observable.of(startPurchaseSessionFailure(error.xhr)));
  });
}
