import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import ListOptionBtn from '../ListOptionBtn';
import { AuthButton } from 'components/AuthButton';
import { Col } from 'components/generic/Layout';

// actions
import { getAllBooks } from 'actions';
import ActivityIndicator from 'components/generic/ActivityIndicator';

export default function Sportsbook(props) {
  const reduxProps = useSelector(state => ({
    user: state.authReducer.user,
    allBooks: state.authReducer.allBooks,
    isLoadingAllBooks: state.authReducer.isLoadingAllBooks,
    allBooksError: state.authReducer.allBooksError,
  }));

  const dispatch = useDispatch();

  const { isLoadingAllBooks, allBooksError } = reduxProps;

  const [firstTime, setFirstTime] = useState(true);
  const [allBooks, setAllBooks] = useState(reduxProps.allBooks);

  let defaultSelectedBooks = props.defaultValue
    .replace(/"|\[|\]/g, '')
    .split(',');

  if (defaultSelectedBooks.length > 0 && defaultSelectedBooks[0] === '') {
    defaultSelectedBooks = [];
  }
  const [selectedBooks, setSelectedBooks] = useState(defaultSelectedBooks);
  const [slug, setSlug] = useState([props.defaultSlug]);

  useEffect(() => {
    if (firstTime) {
      dispatch(getAllBooks());
      setFirstTime(false);
    }
    setAllBooks(reduxProps.allBooks);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reduxProps.allBooks, firstTime]);

  useEffect(() => {
    let defaultSelectedBooks = props.defaultValue
      .replace(/"|\[|\]/g, '')
      .split(',');

    if (defaultSelectedBooks.length > 0 && defaultSelectedBooks[0] === '') {
      defaultSelectedBooks = [];
    }
    setSelectedBooks(defaultSelectedBooks);
    setSlug([props.defaultSlug]);
  }, [props.defaultValue, props.defaultSlug]);

  return (
    <>
      <h6 style={{ margin: 'var(--space-xs) 0' }}>Sportsbooks</h6>
      <p style={{ margin: 'var(--space-xs) 0' }}>
        Choose which sportsbooks to include in the analysis
      </p>
      <Col
        style={{
          flexFlow: 'column nowrap',
          justifyContent: 'flex-start',
          alignItems: 'stretch',
          overflowY: 'auto',
        }}
      >
        {isLoadingAllBooks && <ActivityIndicator size={1.5} />}
        {allBooksError && (
          <span style={{ color: 'var(--color-danger)' }}>
            Sorry, we ran into an error getting sportsbooks
          </span>
        )}
        <ListOptionBtn
          onClick={() => {
            let newSelectedBooks = [];
            let newSlug = ['All sportsbooks'];
            setSelectedBooks(newSelectedBooks);
            setSlug(newSlug);
          }}
          selected={slug.length > 0 && slug[0] === 'All sportsbooks'}
          text={'All Sportsbooks'}
        />

        {allBooks.map(item => (
          <ListOptionBtn
            key={`analysis-book-select-${item.id}`}
            onClick={() => {
              let newSelectedBooks = Array.from(selectedBooks);
              let newSlug = Array.from(slug);
              if (newSlug[0] === 'All sportsbooks') newSlug = [];
              let index = newSelectedBooks.findIndex(
                b => b === item.id.toString()
              );
              if (index > -1) {
                newSelectedBooks.splice(index, 1);
                newSlug.splice(index, 1);
              } else {
                newSelectedBooks.push(item.id.toString());
                newSlug.push(item.name);
              }

              if (newSlug.length === 0) newSlug = ['All sportsbooks'];

              setSelectedBooks(newSelectedBooks);
              setSlug(newSlug);
            }}
            selected={
              selectedBooks.filter(b => b === item.id.toString()).length > 0
            }
            text={item.name}
          />
        ))}
      </Col>
      {/* <AuthButton
        colorTheme="danger"
        btnTheme="borderless"
        containerStyle={{
          flex: 0
        }}
        onPress={() => {
          const value = '[' + props.defaultValue.toString() + ']';
          const slugString = props.defaultSlug.toString();
          props.onDone({
            filter: 'Books',
            value: value,
            slug: slugString,
          });
        }}
      >
        Cancel
      </AuthButton> */}
      <AuthButton
        containerStyle={{
          flex: 0,
        }}
        onPress={() => {
          const value = '[' + selectedBooks.toString() + ']';
          const slugString = slug.toString();
          props.onDone({
            filter: 'Books',
            value: value,
            slug: slugString,
          });
        }}
      >
        Done
      </AuthButton>
    </>
  );
}
