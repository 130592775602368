import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { useUpdateEffect } from 'react-use';

// component
import ActivityIndicator from 'components/generic/ActivityIndicator';
import { Row, Col } from 'components/generic/Layout';
import OddBtn from 'components/generic/OddBtn2';
import AffiliateLink from 'components/AffiliateLink';

// actions
import {
  // player list
  getPlayerPropPlayerListNext,
  // player's props
  getPlayerPropPropList,
  getPlayerPropPropListNext,
  // player props
  getPlayerProps,
  getPlayerPropsNext,
  clearPlayerPropOdds,
  getPlayerPropBA,
} from 'actions';

const NavBar = styled.div`
  height: 100vh;
  flex: 1 0 128px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: stretch;
  padding: var(--space-xs) 0;
  overflow-y: auto;
`;

const OddsContainer = styled.div`
  height: 100vh;
  flex: 2 0 256px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  overflow-y: auto;
  padding-left: var(--space-xs);
`;

const StyledTab = styled.div`
  flex: 0;
  width: 100%;
  cursor: pointer;
  background-color: ${props =>
    props.highlight ? 'var(--color-active)' : 'null'};
  border-right: ${props =>
    props.highlight && !props.mobile
      ? '2px solid var(--color-primary)'
      : 'null'};
  border-bottom: ${props =>
    props.highlight && props.mobile
      ? '2px solid var(--color-primary)'
      : 'null'};
  font-weight: ${props => (props.highlight ? 700 : 200)};
  padding: var(--space-xs) var(--space-md);
  transition: all var(--std-transition);
  &:hover {
    background-color: var(--color-active);
  }
`;

export default function PlayerPropPlayers() {
  const dispatch = useDispatch();
  const reduxProps = useSelector(state => ({
    game: state.gameReducer.game,
    // player list
    playerPropPlayerList: state.gameReducer.playerPropPlayerList,
    isLoadingPlayerPropPlayerList:
      state.gameReducer.isLoadingPlayerPropPlayerList,
    fetchPlayerPropPlayerListError:
      state.gameReducer.fetchPlayerPropPlayerListError,
    isLoadingPlayerPropPlayerListNext:
      state.gameReducer.isLoadingPlayerPropPlayerListNext,
    playerPropPlayerListNext: state.gameReducer.playerPropPlayerListNext,
    // player's props
    playerPropPlayerName: state.gameReducer.playerPropPlayerName,
    playerPropPropList: state.gameReducer.playerPropPropList,
    isLoadingPlayerPropPropList: state.gameReducer.isLoadingPlayerPropPropList,
    fetchPlayerPropPropListError:
      state.gameReducer.fetchPlayerPropPropListError,
    isLoadingPlayerPropPropListNext:
      state.gameReducer.isLoadingPlayerPropPropListNext,
    playerPropPropListNext: state.gameReducer.playerPropPropListNext,
    // player props
    playerPropPropType: state.gameReducer.playerPropPropType,
    playerProps: state.gameReducer.playerProps,
    isLoadingPlayerProps: state.gameReducer.isLoadingPlayerProps,
    fetchPlayerPropsError: state.gameReducer.fetchPlayerPropsError,
    playerPropsNext: state.gameReducer.playerPropsNext,
    isLoadingPlayerPropsNext: state.gameReducer.isLoadingPlayerPropsNext,
    playerPropBA: state.gameReducer.playerPropBA,
    isLoadingPlayerPropBA: state.gameReducer.isLoadingPlayerPropBA,
    playerPropBAError: state.gameReducer.playerPropBAError,
    allBooksMap: state.authReducer.allBooksMap,
  }));
  const {
    game,
    // player list
    playerPropPlayerList,
    isLoadingPlayerPropPlayerList,
    fetchPlayerPropPlayerListError,
    isLoadingPlayerPropPlayerListNext,
    playerPropPlayerListNext,
    // player's props
    playerPropPlayerName,
    playerPropPropList,
    isLoadingPlayerPropPropList,
    fetchPlayerPropPropListError,
    isLoadingPlayerPropPropListNext,
    playerPropPropListNext,
    // player props
    playerPropPropType,
    playerProps,
    isLoadingPlayerProps,
    fetchPlayerPropsError,
    playerPropsNext,
    isLoadingPlayerPropsNext,
    playerPropBA,
    isLoadingPlayerPropBA,
    playerPropBAError,
    allBooksMap,
  } = reduxProps;

  useEffect(() => {
    if (
      !playerPropPlayerName &&
      playerPropPlayerList &&
      playerPropPlayerList?.length
    ) {
      const player = playerPropPlayerList[0];
      dispatch(clearPlayerPropOdds());
      dispatch(
        getPlayerPropPropList({
          game_id: game.id,
          player_name: player,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playerPropPlayerList, playerPropPlayerName]);

  useEffect(() => {
    if (
      !playerPropPropType &&
      playerPropPropList &&
      playerPropPropList?.length
    ) {
      const prop = playerPropPropList[0];
      dispatch(
        getPlayerProps({
          game_id: game.id,
          player_name: playerPropPlayerName,
          prop_type: prop,
        })
      );
      dispatch(
        getPlayerPropBA({
          game_id: game.id,
          player_name: playerPropPlayerName,
          prop_type: prop,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playerPropPropList, playerPropPlayerName]);

  // player list
  const _getNext = () => {
    if (playerPropPlayerListNext && !isLoadingPlayerPropPlayerListNext) {
      dispatch(getPlayerPropPlayerListNext());
    }
  };
  useEffect(() => {
    _getNext();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playerPropPlayerListNext]);

  // player's props
  const _getNextPropList = () => {
    if (playerPropPropListNext && !isLoadingPlayerPropPropListNext) {
      dispatch(getPlayerPropPropListNext());
    }
  };
  useUpdateEffect(() => {
    _getNextPropList();
  }, [playerPropPropListNext]);

  // player props
  const _getNextProps = () => {
    if (playerPropsNext && !isLoadingPlayerPropsNext) {
      dispatch(getPlayerPropsNext());
    }
  };
  useUpdateEffect(() => {
    _getNextProps();
  }, [playerPropsNext]);

  const _renderItem = ({ item }) => {
    if(item.prop_type !== playerPropPropType) return null;
    const COMMON = {
      id: item.id,
      book: allBooksMap[item.book_id],
      game: game,
      prop_type: item.prop_type,
      type_name: 'Player Prop',
      number: item.number,
      period: 'FT',
      is_live: false,
      bet_type: 'BET',
      player: item.player,
    };
    return (
      <Row
        key={`player-prop-prop-${item.id}-${COMMON.book.id}`}
        style={{
          flex: 0,
          margin: 'var(--space-xxxxs) 0',
          alignItems: 'center',
        }}
      >
        <Row
          style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}
        >
          {COMMON.book.is_affiliate ? (
            <AffiliateLink
              book={COMMON.book}
              fromPage="Gameline Player Props Web"
              shape="bar"
              hideLinkable
              hideText
              fallbackToGeneric
              imgStyle={{
                width: '100%',
                height: 'auto',
                maxHeight: '64px',
                borderRadius: '4px',
              }}
              usePreferred
            />
          ) : COMMON.book.generic_banner ? (
            <img
              alt={COMMON.book.name}
              src={COMMON.book.generic_banner}
              style={{
                width: '100%',
                height: 'auto',
                borderRadius: '4px',
              }}
            />
          ) : (
            <Row
              style={{
                width: '100%',
                alignItems: 'center',
                justifyContent: 'flex-start',
                border: '1px solid var(--color-text-light)',
                borderRadius: '4px',
                padding: 'var(--space-xxs)',
              }}
            >
              <Col
                style={{
                  flex: '0 0 24px',
                  width: '24px',
                  height: '24px',
                  borderRadius: '24px',
                  backgroundColor: 'var(--color-text)',
                  border: '2px solid var(--color-primary)',
                  textAlign: 'center',
                }}
              >
                <span
                  style={{
                    textAlign: 'center',
                    color: 'var(--color-bg)',
                  }}
                >
                  {COMMON.book.name[0]}
                </span>
              </Col>
              <small style={{ margin: '0 var(--space-xxs)' }}>
                {COMMON.book.name}
              </small>
            </Row>
          )}
        </Row>
        <OddBtn
          btnTheme="borderless"
          odd={{ ...COMMON, side: 'Over', odds: item.over_odds }}
        />
        <b style={{ flex: 1, textAlign: 'center' }}>{item.number}</b>
        <OddBtn
          btnTheme="borderless"
          odd={{ ...COMMON, side: 'Under', odds: item.under_odds }}
        />
      </Row>
    );
  };

  if (fetchPlayerPropPlayerListError) {
    return (
      <h5 style={{ textAlign: 'center' }}>
        We ran into an error getting player props
      </h5>
    );
  }

  if (fetchPlayerPropPropListError) {
    return (
      <h5 style={{ textAlign: 'center' }}>
        We ran into an error getting a list of {playerPropPlayerName} props
      </h5>
    );
  }

  return (
    <Row style={{ flexFlow: 'row nowrap' }}>
      {playerPropPlayerList &&
        playerPropPlayerList?.length > 0 &&
        !isLoadingPlayerPropPlayerList && (
          <NavBar>
            {isLoadingPlayerPropPlayerList && <ActivityIndicator size={3} />}
            {playerPropPlayerList &&
              playerPropPlayerList.map(player => (
                <StyledTab
                  key={`pp-player-list-player-${player}`}
                  highlight={playerPropPlayerName === player}
                  onClick={() => {
                    dispatch(clearPlayerPropOdds());
                    dispatch(
                      getPlayerPropPropList({
                        game_id: game.id,
                        player_name: player,
                      })
                    );
                  }}
                >
                  {player}
                </StyledTab>
              ))}
          </NavBar>
        )}
      {playerPropPropList &&
        playerPropPropList?.length > 0 &&
        !isLoadingPlayerPropPropList && (
          <NavBar>
            {isLoadingPlayerPropPropList && <ActivityIndicator size={3} />}
            {playerPropPropList &&
              playerPropPropList.map(prop => (
                <StyledTab
                  key={`player-prop-prop-${prop}-${playerPropPlayerName}`}
                  highlight={playerPropPropType === prop}
                  onClick={() => {
                    dispatch(
                      getPlayerProps({
                        game_id: game.id,
                        player_name: playerPropPlayerName,
                        prop_type: prop,
                      })
                    );
                    dispatch(
                      getPlayerPropBA({
                        game_id: game.id,
                        player_name: playerPropPlayerName,
                        prop_type: prop,
                      })
                    );
                  }}
                >
                  {prop}
                </StyledTab>
              ))}
          </NavBar>
        )}
      {!fetchPlayerPropPlayerListError && (
        <OddsContainer>
          {fetchPlayerPropsError && (
            <h5 style={{ textAlign: 'center' }}>
              We ran into an error getting a list of {playerPropPlayerName}'s{' '}
              {playerPropPropType} props
            </h5>
          )}
          {!playerPropPlayerName && playerPropPlayerList?.length > 0 && (
            <h6 style={{ textAlign: 'center', marginTop: 'var(--space-md)' }}>
              Select a player on the left
            </h6>
          )}
          {!playerPropPlayerName && playerPropPlayerList?.length === 0 && (
            <h6 style={{ textAlign: 'center', marginTop: 'var(--space-md)' }}>
              We don't have player props for this game yet, check back later!
            </h6>
          )}
          {playerPropPlayerName && !playerPropPropType && (
            <h6 style={{ textAlign: 'center', marginTop: 'var(--space-md)' }}>
              Select a prop on the left
            </h6>
          )}
          {playerProps && playerProps?.length > 0 && (
            <>
              <Row
                style={{
                  flex: 0,
                  padding: 'var(--space-sm) 0',
                  backgroundColor: 'var(--color-fg)',
                  alignItems: 'center',
                }}
              >
                <b style={{ flex: 1, textAlign: 'center' }}>Book</b>
                <b style={{ flex: 1, textAlign: 'center' }}>Over</b>
                <b style={{ flex: 1, textAlign: 'center' }}>#</b>
                <b style={{ flex: 1, textAlign: 'center' }}>Under</b>
              </Row>
              <Row
                style={{
                  flex: 0,
                  alignItems: 'center',
                  marginBottom: 'var(--space-xxxs)',
                  paddingBottom: 'var(--space-xxs)',
                  borderBottom: playerPropBA
                    ? '1px solid var(--color-primary)'
                    : 'none',
                }}
              >
                {playerPropBA && (
                  <>
                    <Col>
                      <img
                        alt="Best Available Odds"
                        title="Best Available Odds"
                        src={
                          'https://betstamp-public.sfo2.cdn.digitaloceanspaces.com/ba_banner.png'
                        }
                        style={{
                          flex: 1,
                          height: 'auto',
                          width: '100%',
                          resizeMode: 'cover',
                          borderRadius: '4px',
                        }}
                      />
                    </Col>
                    <Col style={{ flex: 1 }}>
                      <OddBtn
                        btnTheme="borderless"
                        playerPropShowNumber
                        odd={{
                          book: allBooksMap[playerPropBA.over_book_id],
                          game: game,
                          prop_type: playerPropPropType,
                          type_name: 'Player Prop',
                          odds: playerPropBA.over_odds,
                          number: playerPropBA.over_number,
                          period: 'FT',
                          is_live: false,
                          bet_type: 'BET',
                          side: 'Over',
                          player: {
                            id: playerPropBA.player_id,
                            name: playerPropPlayerName,
                          },
                        }}
                      />
                      <small>
                        {allBooksMap[playerPropBA.over_book_id]?.name}
                      </small>
                    </Col>
                    <span style={{ flex: 1 }} />
                    <Col style={{ flex: 1 }}>
                      <OddBtn
                        btnTheme="borderless"
                        playerPropShowNumber
                        odd={{
                          book: allBooksMap[playerPropBA.under_book_id],
                          game: game,
                          prop_type: playerPropPropType,
                          type_name: 'Player Prop',
                          odds: playerPropBA.under_odds,
                          number: playerPropBA.under_number,
                          period: 'FT',
                          is_live: false,
                          bet_type: 'BET',
                          side: 'Under',
                          player: {
                            id: playerPropBA.player_id,
                            name: playerPropPlayerName,
                          },
                        }}
                      />
                      <small>
                        {allBooksMap[playerPropBA.under_book_id]?.name}
                      </small>
                    </Col>
                  </>
                )}
              </Row>
              {playerProps.map(item => _renderItem({ item }))}
            </>
          )}
          {playerProps && playerProps?.length === 0 && (
            <h6 style={{ textAlign: 'center', margin: 'var(--space-md)' }}>
              We couldn&apos;t find player props for {playerPropPlayerName} -{' '}
              {playerPropPropType}
            </h6>
          )}
          {isLoadingPlayerProps && <ActivityIndicator size={3} />}
        </OddsContainer>
      )}
    </Row>
  );
}
