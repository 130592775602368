import { Observable } from 'rxjs';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import { ajax } from 'rxjs/observable/dom/ajax';
import { BACKEND_API_URL, getHeaders } from 'utils';

import {
  GET_SPORTSBOOKS,
  getSportsbooksSuccess,
  getSportsbooksFailure,
} from 'actions';

// THIS IS FOR THE SPORTSBOOK COMPARISON PAGE
// DO NOT CONFUSE THIS WILL GET_ALL_BOOKS
export default function getSportsbooks(action$, state$) {
  return action$.ofType(GET_SPORTSBOOKS).switchMap(action => {
    let url = BACKEND_API_URL + `api/books/?`;

    if (action.is_affiliate) {
      url += `&is_affiliate=${action.is_affiliate}`;
    }

    if (action.states) {
      url += `&states=${JSON.stringify(action.states)}`;
    }

    return ajax
      .getJSON(url, getHeaders(state$))
      .map(data => getSportsbooksSuccess(data))
      .catch(error => Observable.of(getSportsbooksFailure(error.xhr)));
  });
}
