import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { IoChevronDown, IoChevronUp, IoOpenOutline } from 'react-icons/io5';
import styled from 'styled-components';
import InfiniteScroll from 'react-infinite-scroll-component';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { useUpdateEffect } from 'react-use';

// util
import { kFormatter, calculateBetPayout } from 'utils';

// components
import { Row, Col, DesktopLayout } from 'components/generic/Layout';
import AutoColoredAmount from 'components/generic/AutoColoredAmount';
import ActivityIndicator from 'components/generic/ActivityIndicator';
import TeamLeagueLogo from 'components/TeamLeagueLogo';
import Wager from 'components/Wager';
import { IconButton /*, AuthButton*/ } from 'components/AuthButton';

// actions
import { getWagersNext } from 'actions';

export default function TodaysAction2(props) {
  const dispatch = useDispatch();

  const reduxProps = useSelector(state => ({
    pickStats: state.picksReducer.pickStats,
    wagers: state.wagerReducer.wagers['todaysAction'],
    isWagersLoading: state.wagerReducer.isWagersLoading['todaysAction'],
    wagersError: state.wagerReducer.wagersError['todaysAction'],
    wagersNext: state.wagerReducer.wagersNext['todaysAction'],
    isWagersNextLoading: state.wagerReducer.isWagersNextLoading['todaysAction'],
  }));

  const {
    pickStats,
    wagers,
    isWagersLoading,
    wagersError,
    wagersNext,
    isWagersNextLoading,
  } = reduxProps;

  const [collapsed, setCollapsed] = useState({});
  const [forcedNextPage, setForcedNextPage] = useState(false);

  const _toggleCollapseAll = isCollapsed => {
    let newCollapsed = {};
    wagers.forEach(p => {
      newCollapsed[wagerCollapsedID(p)] = isCollapsed;
    });
    setCollapsed(newCollapsed);
  };

  const { collapseAll } = props;

  useUpdateEffect(() => {
    if (collapseAll !== null) {
      _toggleCollapseAll(collapseAll);
    }
  }, [collapseAll]);

  const _getWagersNext = () => {
    if (wagersNext) {
      if (!forcedNextPage) setForcedNextPage(true);
      dispatch(getWagersNext('todaysAction'));
    }
  };

  useUpdateEffect(() => {
    if (
      props.desktop &&
      wagers.length > 0 &&
      !isWagersNextLoading &&
      !forcedNextPage
    ) {
      _getWagersNext();
    }
  }, [wagers, isWagersLoading, forcedNextPage]);

  if (wagersError) {
    return <p>Sorry, we ran into an error! Please try again later.</p>;
  }

  let separatorStats = {};

  wagers.forEach(pick => {
    let actualBet = pick;
    const id = wagerCollapsedID(pick);
    if (separatorStats[id]) {
      separatorStats[id].risk_amount += parseFloat(actualBet.risk_amount);
      separatorStats[id].to_win += parseFloat(
        calculateBetPayout(actualBet.risk_amount, actualBet.odds)
      );
      separatorStats[id].net += parseFloat(actualBet.result);
    } else {
      separatorStats[id] = {
        risk_amount: parseFloat(actualBet.risk_amount),
        to_win: parseFloat(
          calculateBetPayout(actualBet.risk_amount, actualBet.odds)
        ),
        net: parseFloat(actualBet.result),
      };
    }
  });

  return (
    <>
      <Row
        style={{
          alignItems: 'center',
          width: '100%',
          padding: 'var(--space-sm) 0',
        }}
      >
        {pickStats['graded_today'].map((stat, i) => (
          <React.Fragment key={`pickstat-gt-desktop-${i}`}>
            {stat.text !== 'count' && (
              <Col>
                {stat.symbol ? (
                  <AutoColoredAmount bold="true" symbol={stat.symbol}>
                    {stat.num}
                  </AutoColoredAmount>
                ) : (
                  <b>{kFormatter(stat.num)}</b>
                )}
                <small style={{ lineHeight: 1 }}>
                  {stat.text.toUpperCase()}
                </small>
              </Col>
            )}
          </React.Fragment>
        ))}
        {pickStats['pending_today'].map((stat, i) => (
          <React.Fragment key={`pickstat-pt-desktop-${i}`}>
            {stat.text !== 'count' && (
              <Col>
                {stat.symbol ? (
                  <AutoColoredAmount bold="true" symbol={stat.symbol}>
                    {stat.num}
                  </AutoColoredAmount>
                ) : (
                  <b>{kFormatter(stat.num)}</b>
                )}
                <small style={{ lineHeight: 1 }}>
                  {stat.text.toUpperCase()}
                </small>
              </Col>
            )}
          </React.Fragment>
        ))}
      </Row>

      <InfiniteScroll
        scrollableTarget={props.scrollTarget || 'infinite-scroll-target'}
        scrollThreshold={0.5}
        next={_getWagersNext}
        hasMore={wagersNext}
        dataLength={wagers.length}
        loader={<ActivityIndicator size={1.5} />}
        style={{ overflow: 'hidden', paddingBottom: 'var(--space-xxl)' }}
        endMessage={
          <>
            {isWagersLoading ? (
              <ActivityIndicator size={2} />
            ) : (
              <h6 style={{ textAlign: 'center', margin: 'var(--space-md) 0' }}>
                {wagers.length === 0 ? 'No bets fit this filter!' : null}
              </h6>
            )}
          </>
        }
      >
        {wagers.map((item, i) => (
          <React.Fragment key={`todays-action-bets-${item.id}-${i}`}>
            <div>
              <Separator
                i={i}
                item={item}
                picks={wagers}
                isCollapsed={collapsed[wagerCollapsedID(item)]}
                toggleCollapsed={id => {
                  let col = { ...collapsed };
                  col[id] = !col[id];
                  setCollapsed(col);
                }}
                separatorStats={separatorStats[wagerCollapsedID(item)]}
              />
            </div>

            {!collapsed[wagerCollapsedID(item)] && (
              <Wager key={item.id} wager={item} todaysAction={true} />
            )}
          </React.Fragment>
        ))}
      </InfiniteScroll>
      {/* {wagersNext && !isWagersNextLoading && (
        <AuthButton
          onPress={() => _getPicksNext()}
        >
          Load More Picks
        </AuthButton>
      )} */}
    </>
  );
}

function wagerCollapsedID(wager) {
  if (!wager) return null;
  if (wager.multi_wager_id) {
    return `abcid-${wager.multi_wager_id}`;
  }
  if (wager.sports && wager.sports[0] === 'Golf' && wager.season) {
    return `abcid-${wager.season.id}-${wager.is_graded}`;
  }
  if (wager.game) {
    return `abcid-${wager.game.id}-${wager.is_graded}`;
  }
  const sport = wager.sports ? wager.sports[0] : '';
  return `abcid-${sport}-${moment(wager.first_date).format(
    'ddd MMM D @ h:mma'
  )}-${wager.is_graded}`;
}

function formatDate(date) {
  if (moment(date).isSame(moment(), 'day')) {
    return 'Today' + moment(date).format(' @ h:mma');
  }
  return moment(date).format('ddd MMM D @ h:mma');
}

function Separator(props) {
  const { i, item, picks, isCollapsed, separatorStats } = props;

  if (wagerCollapsedID(item) !== wagerCollapsedID(picks[i - 1])) {
    return (
      <Row
        style={{
          alignItems: 'center',
          justifyContent: 'flex-start',
          marginTop: i === 0 ? 'var(--space-sm)' : 'var(--space-lg)',
        }}
      >
        {isCollapsed ? (
          <IconButton
            iconName={IoChevronDown}
            containerStyle={{ marginRight: 'var(--space-xxs)' }}
            iconColor="var(--color-text)"
            iconSize={22}
            onPress={() => props.toggleCollapsed(wagerCollapsedID(item))}
          />
        ) : (
          <IconButton
            iconName={IoChevronUp}
            containerStyle={{ marginRight: 'var(--space-xxs)' }}
            iconColor="var(--color-text)"
            iconSize={22}
            onPress={() => props.toggleCollapsed(wagerCollapsedID(item))}
          />
        )}

        {item.bet_type === 'PARLAY' && (
          <>
            <b>
              {item.parlay_length}x Parlay | {item.leagues.join(', ')}
            </b>
            <div
              style={{
                flex: 1,
                height: '2px',
                backgroundColor: 'var(--color-text-light)',
                margin: '0 var(--space-md)',
              }}
            />
            <DesktopLayout>
              <b style={{ margin: 0, marginRight: '4px' }}>
                {item.first_date !== item.last_date
                  ? `${formatDate(item.first_date)} - ${formatDate(
                      item.last_date
                    )}`
                  : `${formatDate(item.first_date)}`}
              </b>
            </DesktopLayout>
          </>
        )}

        {item.game &&
          !item.multi_wager_id &&
          wagerCollapsedID(item) !== wagerCollapsedID(picks[i - 1]) && (
            <GameTitle
              game={item?.game}
              separatorStats={separatorStats}
              wager={item}
            />
          )}

        {!item.game &&
          !item.multi_wager_id &&
          wagerCollapsedID(item) !== wagerCollapsedID(picks[i - 1]) && (
            <>
              <TeamLeagueLogo league={item.sports[0]} iconSize={24} />
              {item.is_future && item?.season ? (
                <b>{item.season.name}</b>
              ) : (
                <b>{item.sports[0]}</b>
              )}
              <GameStats stats={separatorStats} is_complete={item.is_graded} />
              <div
                style={{
                  flex: 1,
                  height: '2px',
                  backgroundColor: 'var(--color-text-light)',
                  margin: '0 var(--space-md)',
                }}
              />
              <b style={{ margin: 0, marginRight: '4px' }}>
                {formatDate(item.first_date)}
              </b>
            </>
          )}
      </Row>
    );
  }

  return null;
}

const StyledLiveAnim = styled.div`
  flex: 1;
  height: 2px;
  background-color: var(--color-text-light);
  margin: 0 var(--space-md);
  position: relative;
  overflow: hidden;
  &::after {
    content: '';
    position: absolute;
    height: 100%;
    width: 10%;
    left: -3%;
    transform: translateX(-100%);
    background: var(--color-success);
    animation: progress 1.6s 1s infinite alternate ease-in-out;
  }
  @keyframes progress {
    0% {
      left: -3%;
      transform: translateX(-100%);
    }
    100% {
      left: 100%;
      transform: translateX(0%);
    }
  }
`;

function GameTitle({ game, iconSize, separatorStats, wager }) {
  const {
    away_team,
    home_team,
    league,
    is_complete,
    is_live,
    is_live_text,
    // home_score,
    // away_score,
    finish_type,
    sport
  } = game;

  const away_lost = game.is_complete && game.winner === game.home_team.name;
  const home_lost = game.is_complete && game.winner === game.away_team.name;
  let home_score,
    away_score = 0;
  if (['ATP', 'WTA'].indexOf(game.league) >= 0 && game.period_scores) {
    home_score = game.period_scores['match_score']
      ? game.period_scores['match_score']['home']
      : 0;
    away_score = game.period_scores['match_score']
      ? game.period_scores['match_score']['away']
      : 0;
  } else {
    home_score = game.home_score;
    away_score = game.away_score;
  }

  if (game.sport === 'Golf') {
    return (
      <>
        <b>{wager?.season?.name}</b>
        {is_live ? (
          <StyledLiveAnim />
        ) : (
          <div
            style={{
              flex: 1,
              height: '2px',
              backgroundColor: 'var(--color-text-light)',
              margin: '0 var(--space-md)',
            }}
          />
        )}
        <b>{formatDate(game.date)}</b>
      </>
    );
  }

  return (
    <>
      <TeamLeagueLogo
        iconSize={iconSize ? iconSize : 24}
        displayTeam
        league={league}
        team={away_team.name}
        style={{
          marginRight: 'var(--space-xxxs)',
          filter: away_lost ? 'grayscale(1)' : 'none',
        }}
      />
      <p
        style={{
          margin: 0,
          whiteSpace: 'nowrap',
          fontWeight: away_lost ? 'normal' : 'bold',
          opacity: is_complete && away_lost ? 0.7 : 1,
        }}
      >
        {away_team.name}
      </p>

      {sport !== 'MMA' && (is_complete || is_live) ? (
        <>
          <p
            style={{
              margin: '0 var(--space-xs)',
              whiteSpace: 'nowrap',
              fontWeight: away_lost ? 'normal' : 'bold',
              opacity: is_complete && away_lost ? 0.7 : 1,
            }}
          >
            {away_score}
          </p>
          <span> - </span>
          <p
            style={{
              margin: '0 var(--space-xs)',
              whiteSpace: 'nowrap',
              fontWeight: home_lost ? 'normal' : 'bold',
              opacity: is_complete && home_lost ? 0.7 : 1,
            }}
          >
            {home_score}
          </p>
        </>
      ) : (
        <h6
          style={{
            margin: 0,
            padding: '0 var(--space-xs)',
          }}
        >
          {sport === 'MMA' ? 'vs' : '@'}
        </h6>
      )}

      <p
        style={{
          margin: 0,
          whiteSpace: 'nowrap',
          fontWeight: home_lost ? 'normal' : 'bold',
          opacity: is_complete && home_lost ? 0.7 : 1,
        }}
      >
        {home_team.name}
      </p>

      <TeamLeagueLogo
        iconSize={iconSize ? iconSize : 24}
        displayTeam
        league={league}
        team={home_team.name}
        style={{
          marginRight: 0,
          marginLeft: 'var(--space-xxxs)',
          filter: home_lost ? 'grayscale(1)' : 'none',
        }}
      />
      <Link to={`/games/${game?.id}`} style={{ display: 'flex' }}>
        <IoOpenOutline
          title={'View Game'}
          style={{ marginLeft: 'var(--space-xs)', cursor: 'pointer' }}
          size={22}
        />
      </Link>

      <GameStats stats={separatorStats} is_complete={is_complete} />

      {is_live ? (
        <StyledLiveAnim />
      ) : (
        <div
          style={{
            flex: 1,
            height: '2px',
            backgroundColor: 'var(--color-text-light)',
            margin: '0 var(--space-md)',
          }}
        />
      )}

      {is_live && is_live_text ? (
        <b
          style={{
            color:
              game.is_live_text && game.is_live_text.indexOf('Delay') >= 0
                ? 'var(--color-text)'
                : 'var(--color-success)',
          }}
        >
          {is_live_text}
        </b>
      ) : (
        <>
          {sport !== 'MMA' && is_complete ? (
            <b>{`Final${finish_type ? ` (${finish_type})` : ''}`}</b>
          ) : (
            <b style={{ margin: 0, marginRight: '4px' }}>
              {formatDate(game.date)}
            </b>
          )}
        </>
      )}
    </>
  );
}

function GameStats({ stats, is_complete }) {
  return (
    <>
      <Row
        desktop
        style={{
          flex: 1,
          height: '2px',
          backgroundColor: 'var(--color-text-light)',
          margin: '0 var(--space-md)',
        }}
      />
      {!is_complete && (
        <Col desktop style={{ textAlign: 'center' }}>
          <span style={{ lineHeight: 1 }}>
            $
            {stats.risk_amount >= 10
              ? kFormatter(Math.round(stats.risk_amount * 100) / 100)
              : Math.round(stats.risk_amount * 100) / 100}
          </span>
          <small style={{ whiteSpace: 'nowrap', lineHeight: 1 }}>RISK</small>
        </Col>
      )}
      {is_complete && (
        <Col desktop style={{ textAlign: 'center' }}>
          <AutoColoredAmount style={{ lineHeight: 1 }} symbol={'$'}>
            {stats.net}
          </AutoColoredAmount>{' '}
          <small style={{ whiteSpace: 'nowrap', lineHeight: 1 }}>NET</small>
        </Col>
      )}
      <Row
        desktop
        style={{
          flex: 1,
          height: '2px',
          backgroundColor: 'var(--color-text-light)',
          margin: '0 var(--space-md)',
        }}
      />
      {!is_complete && (
        <Col desktop style={{ textAlign: 'center' }}>
          <span style={{ lineHeight: 1 }}>
            $
            {stats.to_win >= 10
              ? kFormatter(Math.round(stats.to_win))
              : Math.round(stats.to_win * 100) / 100}
          </span>
          <small style={{ whiteSpace: 'nowrap', lineHeight: 1 }}>TO WIN</small>
        </Col>
      )}
      {is_complete && (
        <Col desktop style={{ textAlign: 'center' }}>
          <AutoColoredAmount style={{ lineHeight: 1 }} symbol={'%'}>
            {(stats.net / stats.risk_amount) * 100}
          </AutoColoredAmount>
          <small style={{ whiteSpace: 'nowrap', lineHeight: 1 }}>ROI</small>
        </Col>
      )}
    </>
  );
}
