import React, { useState, useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import {
  useHistory,
  useParams,
  useLocation,
  Redirect,
  Link,
} from 'react-router-dom';
import { useEffectOnce, useUpdateEffect, useDropArea } from 'react-use';
import {
  IoCloudUploadOutline,
  IoAlertCircleOutline,
  IoTimeOutline,
  IoCheckmarkCircleOutline,
  IoCallOutline,
  IoEyeOffOutline,
  IoEyeOutline,
  IoLockClosedOutline,
  IoMailOutline,
} from 'react-icons/io5';
import Analytics from 'amplitude/Analytics';

// utils
import { humanDatetime, isEmailValid } from 'utils';

// components
import { AuthTextInput } from 'components/AuthTextInput';
import { AuthButton } from 'components/AuthButton';
import { Row, Col, DesktopLayout, Grid } from 'components/generic/Layout';
import AffiliateLink from 'components/AffiliateLink';
import ActivityIndicator from 'components/generic/ActivityIndicator';
import ModalWrapper from 'components/generic/ModalWrapper';
import OnbSidebar from 'components/nav/OnbSidebar';
import OnbBottomBar from 'components/nav/OnbBottomBar';
import Troubleshooter from 'components/onboarding/Troubleshooter';
import { BetstampWordBlueText } from 'components/generic/Logos';
import ErrorDisplay from 'components/generic/ErrorDisplay';
import GoogleAuthButton from 'components/GoogleAuthButton';
import AppleAuthButton from 'components/AppleAuthButton';
import TwitterAuthButton from 'components/TwitterAuthButton';
import AppStoreImg from 'images/download_app_store.png';
import PlayStoreImg from 'images/download_play_store.png';

// actions
import { loginUser, getAllBooks, getAffiliates, getAllBooksMap } from 'actions';

const AffLinkWrapper = props => {
  return (
    <AffiliateLink
      linkStyle={{ textDecoration: 'none' }}
      book={props.actualBook}
      fromPage="Sportsbook Assist Tool"
      referralCode={props.referralCode?.code}
    >
      {props.children}
    </AffiliateLink>
  );
};

const STEP_DATA = {
  NOT_STARTED: {
    title: 'Time to Register!',
    desc: 'Click the button below to register for an account with {book}. The below link will have our Betstamp exclusive bonuses pre-loaded into your new account. You may be required to submit documents to verify your identity as you register.',
    statusText: 'Not Started',
    headerText: 'Step 1: Register for a {book_name} account',
    mainBtn: {
      text: 'OPEN AN ACCOUNT',
      wrapper: AffLinkWrapper,
      modalData: {
        component: ConfirmAccountModal,
        modalSize: 'large',
      },
    },
    secBtn: {
      text: 'I already have an account',
      modalData: {
        component: AlreadyAccountModal,
        modalSize: 'large',
      },
    },
    troubleshootGroup: 'REGISTRATION',
    troubleshootModal: {
      component: TroubleshootModal,
      modalSize: 'large',
    },
    completion: 1, // for the dots
    progress: 0, // for the bar
    nextStep: 'DEPOSIT',
  },
  DEPOSIT: {
    title: 'Time to Deposit!',
    desc: null,
    statusText: 'Deposit Required',
    headerText: 'Step 2: Fund your {book_name} account',
    statusColor: '#d9ac2e',
    mainBtn: {
      text: 'I HAVE DEPOSITED INTO MY ACCOUNT',
      modalData: {
        component: ConfirmDepositModal,
        modalSize: 'large',
        //modalTitle: 'Confirm Deposit',
      },
    },
    middleBtns: [
      {
        text: 'MY ACCOUNT IS PENDING VERIFICATION',
        modalData: { component: QuickSkipFromDepositModal, modalSize: 'small' },
      },
    ],
    secBtn: {
      text: 'I am having trouble completing my deposit',
      modalData: {
        component: TroubleshootModal,
        modalSize: 'large',
      },
    },
    troubleshootGroup: 'DEPOSIT',
    troubleshootModal: {
      component: TroubleshootModal,
      modalSize: 'large',
    },
    completion: 2, // for the dots
    progress: 1, // for the bar
    nextStep: 'PLACE_BET',
    prevStep: 'NOT_STARTED',
  },
  PLACE_BET: {
    title: "It's time to place bets!",
    desc: 'To complete the registration for this sportsbook, place bets totaling at least {minDeposit}. Based on your deposit amount, the recommended bet sizing is {betSize} per bet.',
    statusText: 'Place Bets',
    statusColor: '#d9ac2e',
    headerText: 'Step 3: Place your bets with {book_name}',
    mainBtn: {
      text: 'I HAVE PLACED MY BETS',
      modalData: {
        component: SubmitScreenshotsModal,
        modalSize: 'large',
      },
    },
    middleBtns: [
      {
        text: 'I WILL PLACE MY BETS WITHIN 24HRS',
        modalData: {
          component: QuickSkipFromPlaceBetModal,
          modalSize: 'small',
        },
      },
    ],
    secBtn: {
      text: 'I am having trouble placing bets',
      modalData: {
        component: TroubleshootModal,
        modalSize: 'large',
      },
    },
    troubleshootGroup: 'PLACE_BET',
    troubleshootModal: {
      component: TroubleshootModal,
      modalSize: 'large',
    },
    completion: 3, // for the dots
    progress: 2, // for the bar
    nextStep: 'PENDING_SCREENSHOT_APPROVAL',
    prevStep: 'DEPOSIT',
    screenshotKind: 'initial',
  },
  PENDING_SCREENSHOT_APPROVAL: {
    title:
      'Great job - we’ll review your documents within the next 48 hrs. You’ll be able to see the status in the “All Tasks” section.',
    //desc: 'Get started on the next sportsbook in the meantime',
    statusText: 'Pending Screenshot Verification',
    headerText: 'Step: 4: Screenshot verification',
    completion: 3, // for the dots
    progress: 3, // for the bar
    statusIcon: IoTimeOutline,
  },
  SCREENSHOTS_INVALID: {
    title: 'Your Screenshots Were Invalid',
    desc: 'Reason: {rejectionReason}',
    statusText: 'Screenshots Invalid',
    statusIcon: IoAlertCircleOutline,
    statusColor: 'var(--color-danger)',
    headerText: 'Invalid Screenshots',
    mainBtn: {
      text: 'UPLOAD NEW SCREENSHOTS',
      modalData: {
        component: SubmitScreenshotsModal,
        modalSize: 'large',
      },
    },
    completion: 3, // for the dots
    progress: 3, // for the bar
    nextStep: 'PENDING_SCREENSHOT_APPROVAL',
    screenshotKind: 'reupload',
  },
  PENDING_DEPOSIT: {
    title: 'Has your deposit arrived in your account?',
    statusText: 'Pending Deposit',
    prevStep: 'NOT_STARTED',
    headerText: 'Waiting on deposit',
    completion: 2, // for the dots
    progress: 2, // for the bar
    statusIcon: IoTimeOutline,
    mainBtn: {
      text: 'MY DEPOSIT HAS ARRIVED IN MY ACCOUNT',
      modalData: { component: ConfirmDepositModal },
    },
    secBtn: {
      text: 'I am having trouble completing my deposit',
      modalData: {
        component: TroubleshootModal,
        modalSize: 'large',
      },
    },
    nextStep: 'PLACE_BET',
    troubleshootGroup: 'DEPOSIT',
    troubleshootModal: {
      component: TroubleshootModal,
      modalSize: 'large',
    },
  },
  PENDING_BOOK_DOC_APPROVAL: {
    title: 'The sportsbook can take up to 24 hours to verify your account',
    statusText: 'Pending Sportsbook Account Verification',
    prevStep: 'NOT_STARTED',
    headerText: 'Waiting on sportbooks account verification',
    completion: 1, // for the dots
    progress: 1, // for the bar
    statusIcon: IoTimeOutline,
    mainBtn: {
      text: 'MY ACCOUNT IS NOW VERIFIED',
      modalData: { component: ConfirmAccountModal },
    },
    secBtn: {
      text: 'I am having trouble verifying my account',
      modalData: {
        component: TroubleshootModal,
        //modalSize: 'small',
      },
    },
    troubleshootGroup: 'REGISTRATION',
    troubleshootModal: {
      component: TroubleshootModal,
      modalSize: 'large',
    },
    nextStep: 'DEPOSIT',
  },
  FULLY_COMPLETE: {
    title: 'All Done!',
    desc: 'Great - you can move on to the next sportsbook',
    statusText: 'Complete',
    headerText: 'Complete',
    completion: 0, // for the dots
    progress: 4, // for the bar
    statusIcon: IoCheckmarkCircleOutline,
    statusColor: 'var(--color-success)',
  },
  NEEDS_HELP: {
    title: "We're looking into your issue",
    //desc: 'In the meantime, get started on the next book',
    desc: '',
    statusText: 'Requires Assistance',
    statusIcon: IoTimeOutline,
  },
};

const TR = styled.tr`
  th td {
  }
  td {
    padding: var(--space-xs) 0;
  }
  td:first-child {
    transition: all var(--std-transition);
  }
  :hover td:first-child {
    text-decoration: underline;
  }
  :hover {
    color: var(--color-primary);
  }
`;

const CurrentWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  text-align: center;
  padding: var(--space-xs);
  height: 100%;
  .find-bets {
    overflow-x: auto;
    max-width: calc(100vw - var(--space-sm));
    margin-top: var(--space-sm);
    margin-bottom: var(--space-xl);
  }
  > :first-child {
    padding: 0 8vw;
    justify-content: center;
  }
  @media only screen and (max-width: 1800px) {
    > :first-child {
      padding: 0 var(--space-xxl);
    }
  }
  @media only screen and (max-width: 1600px) {
    > :first-child {
      padding: 0 var(--space-xs);
    }
  }
  @media only screen and (max-width: 1350px) {
    > :first-child {
      padding: 0 var(--space-xxs);
    }
  }
  @media only screen and (max-width: 1200px) {
    > :first-child {
      padding: 0 var(--space-xxxxs);
    }
  }
  @media only screen and (max-width: 1100px) {
    flex-flow: column-reverse nowrap;
    justify-content: flex-start;
    height: unset;
    > :first-child {
      padding: 0;
      justify-content: flex-start;
    }
  }
`;
const CompletionDot = styled.div`
  height: 12px;
  width: 12px;
  border-radius: 12px;
  /*padding: var(--space-xs) 0;*/
  margin-top: var(--space-lg);
  @media only screen and (max-width: 1367px) {
    margin-top: var(--space-sm);
  }
  transform: ${props => (props.complete ? 'scale(1.33)' : 'none')};
  background: ${props =>
    props.complete ? 'var(--color-onb-blue)' : 'rgba(0,0,0,0.15)'};
`;

const FourXLtoTwoXL = styled.p`
  color: var(--color-obj-blue);
  font-weight: bold;
  font-size: var(--text-xxxxl);
  line-height: 1.1;
  @media only screen and (max-width: 1367px) {
    font-size: var(--text-xxxl);
  }
  @media only screen and (max-width: 625px) {
    font-size: var(--text-xxl);
  }
`;
const TwoXLtoLG = styled.p`
  color: var(--color-obj-blue);
  font-size: var(--text-xxl);
  line-height: 1.1;
  @media only screen and (max-width: 1367px) {
    font-size: var(--text-xl);
  }
  @media only screen and (max-width: 625px) {
    font-size: var(--text-lg);
  }
`;

export default function AttendeeSession() {
  const dispatch = useDispatch();
  const reduxProps = useSelector(state => ({
    user: state.authReducer.user,
    userToken: state.authReducer.userToken,
    affiliates: state.affiliatesReducer.affiliates,
    isLoadingAffiliates: state.affiliatesReducer.isLoadingAffiliates,
    allBooksMap: state.authReducer.allBooksMap,
  }));
  const { user, affiliates, isLoadingAffiliates, allBooksMap, userToken } =
    reduxProps;

  useEffectOnce(() => {
    dispatch(getAllBooks());
    dispatch(getAllBooksMap());
  });

  const params = useParams();
  const location = useLocation();
  const session_id = params?.session_id;
  const attendee_id = params?.attendee_id;
  const page = params?.page;
  const searchParams = new URLSearchParams(location.search);

  const fetchSession = useQuery({
    enabled: !!session_id,
    refetchOnWindowFocus: false,
    queryKey: [
      {
        endpoint: 'onboarding-sessions',
        objectID: session_id,
        urlParams: {
          attendee_id: attendee_id,
          incr_sess_loaded: true,
        },
      },
    ],
  });

  const fetchAttendee = useQuery({
    enabled: !!attendee_id,
    refetchOnWindowFocus: false,
    queryKey: [
      {
        endpoint: 'onboarding-attendees',
        objectID: attendee_id,
      },
    ],
  });

  const fetchAttendeeList = useQuery({
    enabled: !attendee_id && !!session_id,
    refetchOnWindowFocus: false,
    queryKey: [
      {
        endpoint: 'onboarding-attendees',
        urlParams: { session_id: session_id },
      },
    ],
  });

  const [currentProgress, setCurrentProgress] = useState(null);
  const [allBooksDone, setAllBooksDone] = useState(false);
  const [showInitialLoginModal, setShowInitialLoginModal] = useState(false);
  useUpdateEffect(() => {
    if (showInitialLoginModal && userToken) {
      setShowInitialLoginModal(false);
    }
  }, [userToken]);

  const fetchProgresses = useQuery({
    enabled: !!attendee_id,
    refetchOnWindowFocus: false,
    onSuccess: data => {
      const allDone = !data.find(
        p => p.current_step !== 'FULLY_COMPLETE' && !p.is_hidden
      );
      if (allDone) setAllBooksDone(true);
      if (!allDone && !currentProgress) {
        let newCurrProg = null;

        if (!newCurrProg && searchParams.get('book')) {
          newCurrProg = data.find(
            p => p.book.toString() === searchParams.get('book')
          );
        }

        if (!newCurrProg) {
          newCurrProg = data.find(
            p => p.current_step === 'PLACE_BET' && !p.is_hidden
          );
        }

        if (!newCurrProg) {
          newCurrProg = data.find(
            p => p.current_step === 'DEPOSIT' && !p.is_hidden
          );
        }

        if (!newCurrProg) {
          newCurrProg = data.find(
            p =>
              p.current_step === 'NOT_STARTED' &&
              !p.is_hidden &&
              !p.already_had_book
          );
        }

        if (!newCurrProg) {
          newCurrProg = data.find(
            p =>
              [
                'PENDING_DEPOSIT',
                'PENDING_BOOK_DOC_APPROVAL',
                'PENDING_SCREENSHOT_APPROVAL',
              ].includes(p.current_step) && !p.is_hidden
          );
        }

        setCurrentProgress(newCurrProg);
        const atLeastOneStarted = data.find(
          p =>
            p.current_step !== 'NOT_STARTED' &&
            !p.is_hidden &&
            !p.already_had_book
        );
        if (!userToken && !atLeastOneStarted) {
          setShowInitialLoginModal(true);
        }
      }
    },
    queryKey: [
      {
        endpoint: 'onboarding-attendee-progresses',
        urlParams: {
          attendee_id: attendee_id,
          session_id: session_id,
        },
      },
    ],
  });

  const attendee = fetchAttendee?.data?.attendee;

  const session = fetchSession?.data?.onboarding_session;
  const progresses = fetchProgresses?.data;

  const sessionIsOpen = useMemo(() => {
    if (!session) return { open: false };
    const sessDate = session ? new Date(session?.date) : new Date();
    const rightNow = new Date();
    const diffMs = Math.round(sessDate - rightNow);
    const diffSec = diffMs / 1000;
    const diffMin = Math.round(diffSec / 60);
    const diffHour = diffMin / 60;
    const diffDay = diffHour / 24;
    const open = sessDate < rightNow;
    return {
      open: open,
      rightNow: rightNow,
      diffMs: diffMs,
      diffSec: diffSec,
      diffMin: diffMin,
      diffHour: diffHour,
      diffDay: diffDay,
    };
  }, [session]);

  useEffect(() => {
    let to = null;
    if (session && !sessionIsOpen.open && sessionIsOpen?.diffMin < 15) {
      to = setTimeout(() => {
        if (!sessionIsOpen.open) {
          window.location.reload();
        }
      }, sessionIsOpen.diffMs);
    }
    return () => clearTimeout(to);
  }, [sessionIsOpen, session]);

  const keepAlive = useMutation({
    mutationKey: `onboarding-keep-alive-${attendee_id}`,
  });

  useEffect(() => {
    if (sessionIsOpen.open && attendee_id) {
      keepAlive.mutate({
        endpoint: 'onboarding-extras',
        body: {
          attendee_id: attendee_id,
          session_id: session_id,
          command: 'alive',
        },
      });
    }

    const interval = setInterval(() => {
      if (sessionIsOpen.open && attendee_id) {
        keepAlive.mutate({
          endpoint: 'onboarding-extras',
          body: {
            attendee_id: attendee_id,
            session_id: session_id,
            command: 'alive',
          },
        });
      }
    }, 1000 * 10);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionIsOpen, attendee_id]);

  useUpdateEffect(() => {
    if (currentProgress && sessionIsOpen.open && attendee_id) {
      keepAlive.mutate({
        endpoint: 'onboarding-extras',
        body: {
          attendee_id: attendee_id,
          session_id: session_id,
          current_book_id: currentProgress.book,
          command: 'current_book',
        },
      });
    }
  }, [currentProgress, attendee_id]);

  // AffiliateLink component needs this in redux
  useUpdateEffect(() => {
    if (
      affiliates.length === 0 &&
      !isLoadingAffiliates &&
      session &&
      attendee
    ) {
      dispatch(
        getAffiliates(
          attendee?.signed_up_with_referral_code?.code ||
          session?.referral_code?.code,
          false, // promo page
          attendee?.region,
          'assist-tool' // link type
        )
      );
    }
  }, [attendee, session, isLoadingAffiliates]);

  const queryClient = useQueryClient();
  const submitProgress = useMutation({
    mutationKey: `submit-onboarding-progress`,
    onSuccess: data => {
      if (currentProgress.id === data.id) {
        setCurrentProgress(data);
      }
      queryClient.invalidateQueries({
        queryKey: [
          {
            endpoint: 'onboarding-attendee-progresses',
            urlParams: {
              attendee_id: attendee_id,
              session_id: session_id,
            },
          },
        ],
      });
    },
  });

  const {
    numDone,
    numInProgress,
    numApproval,
    numNeedAttn,
    numAll,
    numExisting,
  } = useMemo(() => {
    if (!progresses) {
      return { numDone: 0, numInProgress: 0, numApproval: 0, numExisting: 0 };
    }
    return progresses?.reduce(
      (acc, curr) => {
        if (curr.is_hidden) return acc;
        if (curr.already_had_book) {
          acc.numExisting++;
          return acc;
        }
        if (curr.current_step === 'FULLY_COMPLETE') {
          acc.numDone++;
        }
        if (curr.current_step === 'SCREENSHOTS_INVALID') {
          acc.numNeedAttn++;
        }
        if (
          [
            'PLACE_BET',
            'DEPOSIT',
            'PENDING_DEPOSIT',
            'PENDING_BOOK_DOC_APPROVAL',
          ].includes(curr.current_step)
        ) {
          acc.numInProgress++;
        }
        if (curr.current_step === 'PENDING_SCREENSHOT_APPROVAL') {
          acc.numApproval++;
        }
        if (curr.current_step !== 'NOT_STARTED') {
          acc.numAll++;
        }
        return acc;
      },
      {
        numDone: 0,
        numInProgress: 0,
        numApproval: 0,
        numNeedAttn: 0,
        numAll: 0,
        numExisting: 0,
      }
    );
  }, [progresses]);

  //if (!attendee_id || !session_id) {
  //  return <Redirect exact to="/games" />;
  //}

  if (user?.is_staff) {
    return <Redirect exact to="/" />;
  }

  if (fetchSession?.isError) {
    if (fetchSession?.error?.message === 'Expired') {
      return <Redirect to="/" />;
    }
    return (
      <Col
        style={{
          background: 'var(--color-onb-bg)',
          color: 'var(--color-onb-text)',
          justifyContent: 'flex-start',
          flexWrap: 'nowrap',
          overflowY: 'auto',
          minHeight: '100vh',
          height: '100%',
          textAlign: 'center',
          padding: 'var(--space-xs)',
        }}
      >
        <TwoXLtoLG>
          We ran into an error, please refresh the page to try again
        </TwoXLtoLG>
        <AuthButton
          containerStyle={{ maxWidth: '320px' }}
          btnTheme="onb"
          colorTheme="onbblue"
          onPress={() => window.location.reload()}
        >
          Refresh
        </AuthButton>
      </Col>
    );
  }

  if (!session) {
    return (
      <ActivityIndicator size={3} style={{ marginTop: '25vh' }} blue={'true'} />
    );
  }

  if (!sessionIsOpen.open) {
    return (
      <Col
        style={{
          background: 'var(--color-onb-bg)',
          color: 'var(--color-onb-text)',
          justifyContent: 'flex-start',
          flexWrap: 'nowrap',
          overflowY: 'auto',
          minHeight: '100vh',
          height: '100%',
          textAlign: 'center',
          padding: 'var(--space-xs)',
        }}
      >
        <BetstampWordBlueText height={116} />
        {sessionIsOpen.diffMin > 15 ? (
          <>
            <FourXLtoTwoXL>Your session hasn't started yet</FourXLtoTwoXL>
            <TwoXLtoLG>
              Come back on <b>{humanDatetime(session.date)}</b> to start your
              session.
            </TwoXLtoLG>
            {!userToken && (
              <>
                <p>
                  Login to betstamp to sync all your bets from select
                  sportsbooks directly into the app
                </p>

                <div>
                  <LoginForm />
                </div>
              </>
            )}
            <br />
            <p style={{ marginTop: 'var(--space-xl)' }}>
              Download the app for iOS or Android
            </p>
            <Row
              style={{ marginTop: 'var(--space-md)', gap: 'var(--space-sm)' }}
            >
              <a
                href="https://apps.apple.com/us/app/id1525948689"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={AppStoreImg}
                  alt="Download from the App Store"
                  style={{ width: '100%', height: 'auto' }}
                />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=app.cashew.betstamp"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={PlayStoreImg}
                  alt="Download from the Play Store"
                  style={{ width: '100%', height: 'auto' }}
                />
              </a>
            </Row>
          </>
        ) : (
          <>
            <FourXLtoTwoXL>Your session starts soon</FourXLtoTwoXL>
            <TwoXLtoLG>
              Session start time: <b>{humanDatetime(session.date)}</b>
            </TwoXLtoLG>
            {session?.google_meet_link && (
              <a
                href={session?.google_meet_link}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  textDecoration: 'none',
                  width: '100%',
                  maxWidth: '512px',
                }}
              >
                <AuthButton
                  btnTheme="onb"
                  colorTheme="onbblue"
                  leftIcon={IoCallOutline}
                >
                  Join the Google Meet
                </AuthButton>
              </a>
            )}
          </>
        )}
      </Col>
    );
  }

  if (!attendee_id) {
    let refCodeList = new Set();
    if (session?.referral_code) {
      refCodeList.add(session.referral_code?.code);
    }
    fetchAttendeeList?.data?.forEach(atde =>
      refCodeList.add(atde.attendee__signed_up_with_referral_code__code)
    );
    refCodeList = Array.from(refCodeList);
    return (
      <Col
        style={{
          background: 'var(--color-onb-bg)',
          color: 'var(--color-onb-text)',
          justifyContent: 'flex-start',
          alignItems: 'stretch',
          flexWrap: 'nowrap',
          overflowY: 'auto',
          minHeight: '100vh',
          height: '100%',
          textAlign: 'center',
          padding: 'var(--space-xs)',
        }}
      >
        <FourXLtoTwoXL style={{ marginBottom: 'var(--space-xxs)' }}>
          Welcome to the session
        </FourXLtoTwoXL>
        <TwoXLtoLG>Select your name from the list below</TwoXLtoLG>
        {fetchAttendeeList.isLoading && <ActivityIndicator size={3} />}
        <Grid
          style={{
            width: '100%',
            maxWidth: '1024px',
            margin: '0 auto',
            gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
          }}
        >
          {fetchAttendeeList?.data?.map(a => (
            <Link
              key={`atde-llist-${a.attendee_id}`}
              to={`/session/${session_id}/${a.attendee_id}`}
            >
              <AuthButton
                containerStyle={{ margin: 0 }}
                btnTheme="onb"
                colorTheme="onbblue"
              >
                {a.attendee__full_name}
              </AuthButton>
            </Link>
          ))}
        </Grid>
        <TwoXLtoLG
          style={{
            marginBottom: 'var(--space-xxs)',
            marginTop: 'var(--space-xxl)',
          }}
        >
          Don't see your name?
        </TwoXLtoLG>
        {refCodeList?.length === 0 ? (
          <Link to={`/intake?session_id=${session_id}`}>
            <AuthButton
              btnTheme="onb"
              colorTheme="onbbluetext"
              textStyle={{ textDecoration: 'underline' }}
            >
              Fill out the intake form here
            </AuthButton>
          </Link>
        ) : (
          <>
            {refCodeList.map(code => (
              <Link
                key={`intake-link-code-${code}`}
                to={`/intake?session_id=${session_id}&referral_code=${code}`}
              >
                <AuthButton
                  btnTheme="onb"
                  colorTheme="onbbluetext"
                  textStyle={{ textDecoration: 'underline' }}
                >
                  Fill out the intake form here if you are part of {code}
                </AuthButton>
              </Link>
            ))}
          </>
        )}
      </Col>
    );
  }

  if (affiliates.length === 0) {
    return (
      <ActivityIndicator size={3} style={{ marginTop: '25vh' }} blue={'true'} />
    );
  }

  return (
    <>
      {showInitialLoginModal && (
        <ModalWrapper
          modalIsOpen={showInitialLoginModal}
          onClose={() => setShowInitialLoginModal(false)}
          onRequestClose={() => setShowInitialLoginModal(false)}
          backgroundColor="white"
          color="black"
          //marginLeft="360px"
          marginLeft="16vw"
          boxShadow="0 2px 32px var(--color-onb-text)"
          modalSize={'large'}
          showX
        >
          <p
            style={{ textAlign: 'center', maxWidth: '500px', margin: '0 auto' }}
          >
            Login to betstamp to sync all your bets from select sportsbooks
            directly into the app
          </p>
          <br />
          <LoginForm />
          <AuthButton
            btnTheme="onb"
            colorTheme="onbbluetext"
            onPress={() => setShowInitialLoginModal(false)}
          >
            SKIP LOGIN
          </AuthButton>
        </ModalWrapper>
      )}
      <Row
        style={{
          flexFlow: 'row nowrap',
          justifyContent: 'flex-start',
          width: '100vw',
          height: '100vh',
          overflowY: 'hidden',
          overflowX: 'hidden',
        }}
      >
        <OnbSidebar
          currentProgress={currentProgress}
          numInProgress={numInProgress}
          numNeedAttn={numNeedAttn}
          session={session}
        />
        <Col
          style={{
            flexWrap: 'nowrap',
            justifyContent: 'flex-start',
            alignItems: 'stretch',
            overflowY: 'hidden',
            height: '100vh',
          }}
        >
          <Col
            style={{
              background: 'var(--color-onb-bg)',
              color: 'var(--color-onb-text)',
              justifyContent: 'flex-start',
              flexWrap: 'nowrap',
              overflowY: 'auto',
            }}
          >
            <DesktopLayout style={{ width: '100%' }}>
              {(!page || page === 'current') &&
                STEP_DATA[currentProgress?.current_step]?.headerText && (
                  <div
                    style={{
                      width: '100%',
                      backgroundColor: 'rgba(0,0,0,0.1)',
                      color: 'black',
                      padding: 'var(--space-sm) 0',
                    }}
                  >
                    <TwoXLtoLG
                      style={{
                        margin: 0,
                        padding: 'var(--space-sm) var(--space-xl)',
                        fontWeight: '600',
                      }}
                    >
                      {STEP_DATA[
                        currentProgress?.current_step
                      ]?.headerText?.replace(
                        '{book_name}',
                        allBooksMap[currentProgress?.book]?.name
                      )}
                    </TwoXLtoLG>
                  </div>
                )}
            </DesktopLayout>

            {(!page || page === 'current') && (
              <>
                {currentProgress ? (
                  <CurrentTask
                    progresses={progresses}
                    currentProgress={currentProgress}
                    submitProgress={submitProgress}
                    attendee_id={attendee_id}
                    attendee={attendee}
                    setCurrentProgress={setCurrentProgress}
                    session_id={session.id}
                    session={session}
                  />
                ) : (
                  <>
                    {fetchProgresses.isLoading ? (
                      <ActivityIndicator
                        size={2}
                        style={{ marginTop: '25vh' }}
                        blue={'true'}
                      />
                    ) : (
                      <>
                        {allBooksDone ? (
                          <TwoXLtoLG
                            style={{
                              marginTop: '25vh',
                              textAlign: 'center',
                            }}
                          >
                            Great! You have signed up for all available books!
                          </TwoXLtoLG>
                        ) : (
                          <>
                            {fetchProgresses.data?.length === 0 && (
                              <TwoXLtoLG
                                style={{
                                  marginTop: '25vh',
                                  textAlign: 'center',
                                }}
                              >
                                There are no accounts to sign up for. Please
                                contact your host.
                              </TwoXLtoLG>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            )}
            {page === 'all' && (
              <AllTasks
                currentProgress={currentProgress}
                setCurrentProgress={setCurrentProgress}
                progresses={progresses}
                numAll={numAll}
                numDone={numDone}
                numInProgress={numInProgress}
                numApproval={numApproval}
                numNeedAttn={numNeedAttn}
                numExisting={numExisting}
                submitProgress={submitProgress}
                attendee_id={attendee_id}
                attendee={attendee}
                fetchProgresses={fetchProgresses}
                session={session}
              />
            )}
          </Col>
          <OnbBottomBar />
        </Col>
      </Row>
    </>
  );
}

const HeaderWrapper = styled.div`
  display: flex;
  flexflow: row wrap;
  justify-content: flex-start;
  align-items: center;
  max-width: 720px;
  gap: 1rem;
  margin: 0 auto;
  padding-bottom: var(--space-sm);
  @media only screen and (max-width: 625px) {
    flex-flow: column nowrap;
  }
`;
function ModalHeaderWithBlueBox(props) {
  const { actualBook, title } = props;
  return (
    <>
      <HeaderWrapper>
        {!!actualBook && <BookVerticalBanner actualBook={actualBook} small />}
        <FourXLtoTwoXL
          style={{
            textAlign: 'center',
            margin: 'var(--space-xxxs) 0',
          }}
        >
          {title}
        </FourXLtoTwoXL>
      </HeaderWrapper>
      {props.blueBoxChildren && (
        <div
          style={{
            backgroundColor: 'var(--color-onb-blue)',
            color: 'white',
            padding: 'var(--space-lg) var(--space-md)',
            maxWidth: '720px',
            margin: '0 auto',
          }}
        >
          {props.blueBoxChildren}
        </div>
      )}
    </>
  );
}

function LoginModal(props) {
  const reduxProps = useSelector(state => ({
    userToken: state.authReducer.userToken,
  }));
  const { userToken } = reduxProps;
  useEffect(() => {
    if (userToken) props.closeModal();
  }, [userToken]);
  return <LoginForm />;
}

function ConfirmAccountModal(props) {
  const {
    currentProgress,
    actualBook,
    submitProgress,
    attendee_id,
    session_id,
  } = props;
  return (
    <>
      <ModalHeaderWithBlueBox
        title="Registration Verification"
        actualBook={actualBook}
        blueBoxChildren={
          <>
            <TwoXLtoLG
              style={{
                textAlign: 'center',
                margin: 'var(--space-xxs) 0 var(--space-md) 0',
              }}
            >
              Did you successfully register for an account?
            </TwoXLtoLG>
            <AuthButton
              containerStyle={{ maxWidth: '256px', margin: '0 auto' }}
              colorTheme="inverted"
              btnTheme="onb"
              onPress={() => {
                submitProgress.mutate({
                  endpoint: `onboarding-attendee-progresses/${currentProgress.id}`,
                  body: {
                    attendee_id: attendee_id,
                    current_step:
                      STEP_DATA[currentProgress.current_step].nextStep,
                    session_id: session_id,
                  },
                });
                props.closeModal();
              }}
            >
              Yes
            </AuthButton>
          </>
        }
      />
      <AuthButton
        colorTheme="onbbluetext"
        btnTheme="onb"
        textStyle={{ textDecoration: 'underline' }}
        onPress={() =>
          props.setModal({ show: true, ...props.troubleshootModal })
        }
      >
        No, I am having trouble with my registration
      </AuthButton>
    </>
  );
}

function AlreadyAccountModal(props) {
  const {
    stepData,
    currentProgress,
    actualBook,
    submitProgress,
    attendee_id,
    session_id,
  } = props;
  return (
    <>
      <TwoXLtoLG style={{ margin: 0 }}>Confirm Existing Account</TwoXLtoLG>
      <p>
        Did you already have a {actualBook?.name} account prior to this session?
      </p>
      <AuthButton
        colorTheme="onbblue"
        btnTheme="onb"
        onPress={() => {
          submitProgress.mutate({
            endpoint: `onboarding-attendee-progresses/${currentProgress.id}`,
            body: {
              attendee_id: attendee_id,
              current_step: 'FULLY_COMPLETE',
              already_had_book: true,
              session_id: session_id,
            },
          });
          props.closeModal();
        }}
      >
        I had this account prior to Betstamp
      </AuthButton>
      <AuthButton
        colorTheme="onbblue"
        btnTheme="onb"
        onPress={() => {
          submitProgress.mutate({
            endpoint: `onboarding-attendee-progresses/${currentProgress.id}`,
            body: {
              attendee_id: attendee_id,
              current_step: stepData.nextStep,
              session_id: session_id,
            },
          });
          props.closeModal();
        }}
      >
        I made this account through this Betstamp tool
      </AuthButton>
    </>
  );
}

function QuickSkipFromDepositModal(props) {
  const {
    attendee_id,
    currentProgress,
    submitProgress,
    nextProgress,
    setCurrentProgress,
    session_id,
  } = props;
  return (
    <>
      <h4
        style={{
          margin: 0,
          textAlign: 'center',
        }}
      >
        Confirm
      </h4>
      <p
        style={{
          marginBottom: 'var(--space-lg)',
        }}
      >
        If your account is pending verification, let's get started on the next
        one
      </p>
      <AuthButton
        btnTheme="onb"
        colorTheme="onbblue"
        onPress={() => {
          submitProgress.mutate(
            {
              endpoint: `onboarding-attendee-progresses/${currentProgress.id}`,
              body: {
                attendee_id: attendee_id,
                current_step: 'PENDING_BOOK_DOC_APPROVAL',
                session_id: session_id,
              },
            },
            {
              onSuccess: () => {
                setCurrentProgress(nextProgress);
                props.closeModal();
              },
            }
          );
        }}
      >
        My Account is Pending Verification
      </AuthButton>
      <AuthButton
        btnTheme="onb"
        colorTheme="onbbluetext"
        onPress={() => {
          //setCurrentProgress(nextProgress);
          props.closeModal();
        }}
      >
        Cancel
      </AuthButton>
    </>
  );
}

function QuickSkipFromPlaceBetModal(props) {
  const {
    attendee_id,
    currentProgress,
    submitProgress,
    nextProgress,
    setCurrentProgress,
    session_id,
  } = props;
  return (
    <>
      <p
        style={{
          marginBottom: 'var(--space-lg)',
          textAlign: 'center',
        }}
      >
        Please place your bets within 24hrs. In the meantime, let's get started
        on the next sportsbook
      </p>
      <AuthButton
        btnTheme="onb"
        colorTheme="onbblue"
        onPress={() => {
          setCurrentProgress(nextProgress);
          props.closeModal();
        }}
      >
        I will place my bets within 24hrs
      </AuthButton>
      <AuthButton
        btnTheme="onb"
        colorTheme="onbbluetext"
        onPress={() => {
          //setCurrentProgress(nextProgress);
          props.closeModal();
        }}
      >
        Place bets now
      </AuthButton>
    </>
  );
}

function TroubleshootModal(props) {
  const {
    stepData,
    attendee_id,
    currentProgress,
    submitProgress,
    allBooksMap,
    nextProgress,
    setCurrentProgress,
    session_id,
  } = props;
  const [issueSubmitted, setIssueSubmitted] = useState(false);

  if (issueSubmitted && submitProgress.isSuccess) {
    return (
      <>
        <h4 style={{ textAlign: 'center', marginBottom: 'var(--space-md)' }}>
          This issue with {allBooksMap[currentProgress?.book]?.name} has been
          flagged to your host. While the host investigates your issue, it’s
          time to register for the next account.
        </h4>
        <AuthButton
          btnTheme="onb"
          colorTheme="onbblue"
          onPress={() => {
            setCurrentProgress(nextProgress);
            props.closeModal();
          }}
        >
          OK
        </AuthButton>
      </>
    );
  }

  return (
    <Troubleshooter
      defaultSelectedGroup={stepData.troubleshootGroup}
      attendee_id={attendee_id}
      session_id={session_id}
      book_name={allBooksMap[currentProgress?.book]?.name}
      book_id={allBooksMap[currentProgress?.book]?.id}
      current_step={currentProgress?.current_step}
      onSubmitIssueText={text => {
        submitProgress.mutate(
          {
            endpoint: `onboarding-attendee-progresses/${currentProgress.id}`,
            body: {
              attendee_id: attendee_id,
              current_step: 'NEEDS_HELP',
              current_issue: `${currentProgress?.current_step}: ${text}`,
              session_id: session_id,
            },
          },
          {
            onSuccess: () => {
              props.closeModal();
            },
          }
        );
        setIssueSubmitted(true);
      }}
      updateCurrentProgress={newStep => {
        if (newStep) {
          submitProgress.mutate(
            {
              endpoint: `onboarding-attendee-progresses/${currentProgress.id}`,
              body: {
                attendee_id: attendee_id,
                current_step: newStep,
                session_id: session_id,
              },
            },
            {
              onSuccess: () => {
                props.closeModal();
              },
            }
          );
        }
      }}
      goToNextBook={currentProgressSetToStep => {
        if (currentProgressSetToStep) {
          submitProgress.mutate(
            {
              endpoint: `onboarding-attendee-progresses/${currentProgress.id}`,
              body: {
                attendee_id: attendee_id,
                current_step: currentProgressSetToStep,
                session_id: session_id,
              },
            },
            {
              onSuccess: () => {
                setCurrentProgress(nextProgress);
                props.closeModal();
              },
            }
          );
        }
      }}
    />
  );
}

function ConfirmDepositModal(props) {
  const {
    currentProgress,
    actualBook,
    submitProgress,
    attendee_id,
    session_id,
  } = props;
  const [depositAmt, setDepositAmt] = useState('');

  const minDeposit = currentProgress?.min_deposit;
  return (
    <>
      <ModalHeaderWithBlueBox
        title="Deposit Verification"
        actualBook={actualBook}
        blueBoxChildren={
          <>
            <h3
              style={{
                textAlign: 'center',
                margin: 'var(--space-xxs) 0 var(--space-xs) 0',
              }}
            >
              Please confirm the amount you deposited
            </h3>

            <AuthTextInput
              colorTheme="onb"
              inputStyle={{ textAlign: 'center', fontSize: 'var(--text-lg)' }}
              //type="number"
              inputMode="numeric"
              pattern="[0-9]*"
              placeholder={minDeposit || '20'}
              onChangeText={text => setDepositAmt(text)}
            />
            <AuthButton
              containerStyle={{
                maxWidth:
                  !depositAmt || depositAmt < minDeposit ? '390px' : '256px',
                margin: '0 auto',
              }}
              colorTheme="inverted"
              btnTheme="onb"
              disabled={!depositAmt || depositAmt < minDeposit}
              btnStyle={
                !depositAmt || depositAmt < minDeposit
                  ? { background: 'var(--color-onb-blue)', opacity: 0.8 }
                  : {}
              }
              title={
                !depositAmt || depositAmt < minDeposit
                  ? `You must deposit at least $${minDeposit}`
                  : ''
              }
              onPress={() => {
                submitProgress.mutate({
                  endpoint: `onboarding-attendee-progresses/${currentProgress.id}`,
                  body: {
                    attendee_id: attendee_id,
                    current_step:
                      STEP_DATA[currentProgress.current_step].nextStep,
                    session_id: session_id,
                    amount_deposited: depositAmt,
                  },
                });
                props.closeModal();
              }}
            >
              {!depositAmt || depositAmt < minDeposit
                ? `You must deposit at least $${minDeposit}`
                : 'Continue'}
            </AuthButton>
          </>
        }
      />
      <AuthButton
        colorTheme="onbbluetext"
        btnTheme="onb"
        textStyle={{ textDecoration: 'underline' }}
        onPress={() =>
          props.setModal({ show: true, ...props.troubleshootModal })
        }
      >
        I am having trouble completing my deposit
      </AuthButton>
    </>
  );
}

function SubmitScreenshotsModal(props) {
  const {
    currentProgress,
    actualBook,
    submitProgress,
    attendee_id,
    stepData,
    session_id,
  } = props;

  const [amtBet, setAmtBet] = useState(null);
  const [showSSUpload, setShowSSUpload] = useState(false);
  const [filesTooLarge, setFilesTooLarge] = useState(false);
  const [filesWrongType, setFilesWrongType] = useState(false);

  const [formData, setFormData] = useState({ screenshots: [] });
  const [dropBond, dropState] = useDropArea({
    onFiles: files => {
      setFilesTooLarge(false);
      setFilesWrongType(true);
      for (const f of files) {
        if (f.size / 1_000_000 >= 10) {
          setFilesTooLarge(true);
          break;
        }
        if (
          ![
            'image/png',
            'image/jpg',
            'image/jpeg',
            'image/webp',
            'image/gif',
          ].includes(f.type)
        ) {
          setFilesWrongType(true);
        }
      }
      setFormData({
        screenshots: files.filter(
          f =>
            f.type.includes('image') &&
            f.size / 1_000_000 < 10 &&
            [
              'image/png',
              'image/jpg',
              'image/jpeg',
              'image/webp',
              'image/gif',
            ].includes(f.type)
        ),
      });
    },
  });

  useUpdateEffect(() => {
    if (submitProgress?.isSuccess) {
      props.closeModal();
    }
  }, [submitProgress?.isSuccess]);

  const minDeposit = currentProgress?.min_deposit;

  return (
    <>
      <ModalHeaderWithBlueBox
        title="Bet Verification"
        actualBook={actualBook}
        blueBoxChildren={
          <>
            <form
              style={{}}
              onSubmit={ev => {
                ev.preventDefault();
                const fd = new FormData(ev.target);
                fd.append('attendee_id', attendee_id);
                fd.append('current_step', stepData?.nextStep);
                for (const file of formData.screenshots) {
                  fd.append('screenshots', file);
                }
                fd.append('amount_bet', amtBet);
                fd.append('session_id', session_id);
                if (stepData?.screenshotKind) {
                  fd.append('screenshot_kind', stepData?.screenshotKind);
                }
                if (showSSUpload) {
                  submitProgress.mutate({
                    endpoint: `onboarding-attendee-progresses/${currentProgress.id}`,
                    body: fd,
                  });
                } else if (amtBet && amtBet >= minDeposit) {
                  setShowSSUpload(true);
                }
              }}
            >
              {!showSSUpload ? (
                <>
                  <h3
                    style={{
                      textAlign: 'center',
                      margin: 'var(--space-xxs) 0 var(--space-xs) 0',
                    }}
                  >
                    Please confirm the total amount you wagered
                  </h3>
                  <AuthTextInput
                    colorTheme="onb"
                    inputStyle={{
                      textAlign: 'center',
                      fontSize: 'var(--text-lg)',
                    }}
                    //type="number"
                    inputMode="numeric"
                    pattern="[0-9]*"
                    // this is now the most hacky thing in all betstamp codebases
                    // this is not a space, its UTF U+2800, a blank character
                    // a label is required to show error text, but the design calls for no label
                    label="⠀"
                    placeholder={minDeposit || '20'}
                    errorText={
                      amtBet && amtBet < minDeposit
                        ? `Must bet at least ${minDeposit}`
                        : null
                    }
                    onChangeText={text => {
                      setAmtBet(text);
                    }}
                  />
                </>
              ) : (
                <>
                  <TwoXLtoLG
                    style={{
                      textAlign: 'center',
                      margin: 'var(--space-xxs) 0 var(--space-xs) 0',
                    }}
                  >
                    Please upload a screenshot of your bet(s) placed
                  </TwoXLtoLG>
                  <h6 style={{ textAlign: 'center', marginTop: 0 }}>
                    The screenshot should clearly show the sportsbook logo. In
                    order for it to be approved, the amount wagered should be at
                    least ${minDeposit}.
                  </h6>
                  <div
                    style={{
                      transition: 'all var(--std-transition)',
                      height: '128px',
                      display: 'flex',
                      flexFlow: 'column nowrap',
                      justifyContent: formData.screenshots?.length
                        ? 'flex-start'
                        : 'center',
                      zIndex: 10,
                      overflow: 'auto',
                    }}
                  >
                    <Grid
                      style={{
                        gridTemplateColumns:
                          filesWrongType || filesTooLarge
                            ? 'repeat(auto-fill, minmax(512px, 1fr))'
                            : 'repeat(auto-fill, minmax(128px, 1fr))',
                      }}
                    >
                      {formData.screenshots?.map((file, i) => (
                        <img
                          key={`place-bet-preview-screenshot-${i}-${file.name}`}
                          alt={`File Upload ${file.name}`}
                          src={URL.createObjectURL(file)}
                          style={{ width: '100%' }}
                        />
                      ))}
                      {filesTooLarge && (
                        <b style={{ color: 'var(--color-danger)' }}>
                          Each file must be less than 10mb
                        </b>
                      )}
                      {filesWrongType && (
                        <b style={{ color: 'var(--color-danger)' }}>
                          Each file must be a JPG, PNG, GIF, or WEBP
                        </b>
                      )}
                    </Grid>
                    <>
                      <label
                        {...dropBond}
                        htmlFor="file_upload_input"
                        style={{
                          cursor: 'pointer',
                          fontSize: '18px',
                          border: '2px dashed white',
                          borderRadius: '16px',
                          height: '100%',
                          padding: 'var(--space-sm)',
                        }}
                      >
                        <Row
                          style={{
                            height: '100%',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: '1rem',
                          }}
                        >
                          <IoCloudUploadOutline size={48} color="white" />
                          <span>
                            <u>Click to upload</u> or drag & drop to upload file
                          </span>
                          {filesTooLarge && (
                            <b style={{ color: 'var(--color-danger)' }}>
                              Each file must be less than 10mb
                            </b>
                          )}
                        </Row>
                        <input
                          id="file_upload_input"
                          style={{
                            display: 'none',
                            margin: '0 var(--space-sm)',
                          }}
                          multiple
                          type="file"
                          accept="image/png, image/gif, image/jpeg, image/jpg, image/webp"
                          onChange={input => {
                            setFilesTooLarge(false);
                            setFilesWrongType(true);
                            for (const f of input.target.files) {
                              if (f.size / 1_000_000 >= 10) {
                                setFilesTooLarge(true);
                                break;
                              }
                              if (
                                ![
                                  'image/png',
                                  'image/jpg',
                                  'image/jpeg',
                                  'image/webp',
                                  'image/gif',
                                ].includes(f.type)
                              ) {
                                setFilesWrongType(true);
                              }
                            }
                            setFormData({
                              screenshots: Array.from(
                                input.target.files
                              ).filter(
                                f =>
                                  f.type.includes('image') &&
                                  f.size / 1_000_000 < 10 &&
                                  [
                                    'image/png',
                                    'image/jpg',
                                    'image/jpeg',
                                    'image/webp',
                                    'image/gif',
                                  ].includes(f.type)
                              ),
                            });
                          }}
                        />
                      </label>
                    </>
                  </div>
                </>
              )}
              <br />
              <AuthButton
                type="submit"
                containerStyle={{ maxWidth: '256px', margin: '0 auto' }}
                colorTheme="inverted"
                btnTheme="onb"
                disabled={
                  showSSUpload
                    ? formData.screenshots?.length <= 0
                    : !amtBet || amtBet < minDeposit
                }
                isLoading={submitProgress.isLoading}
              >
                {showSSUpload ? 'Upload' : 'Continue'}
              </AuthButton>
              <span style={{ color: 'var(--color-danger)' }}>
                {submitProgress.isError ? submitProgress.error.message : ''}
              </span>
            </form>
          </>
        }
      />
      <AuthButton
        colorTheme="onbbluetext"
        btnTheme="onb"
        textStyle={{ textDecoration: 'underline' }}
        onPress={() =>
          props.setModal({ show: true, ...props.troubleshootModal })
        }
      >
        I am having trouble placing my bets
      </AuthButton>
    </>
  );
}

//function FindBets(props) {
//  const { actualBook, attendee } = props;
//
//  const fetchFindBets = useQuery({
//    queryKey: [
//      {
//        endpoint: `find_bets`,
//        urlParams: {
//          book_ids: JSON.stringify([actualBook.id]),
//          leagues: JSON.stringify(['NFL', 'NBA', 'MLS', 'NHL', 'MLB']),
//          odds_type: JSON.stringify(['regular']),
//          periods: JSON.stringify(['FT']),
//          onboarding: true,
//          region: attendee.region,
//        },
//      },
//    ],
//  });
//
//  if (!fetchFindBets?.isSuccess) return null;
//
//  return (
//    <>
//      <h5
//        style={{
//          marginTop: 0,
//          marginBottom: 'var(--space-sm)',
//          color: 'var(--color-onb-blue)',
//          fontWeight: 'bold',
//        }}
//      >
//        Check out some recommended bets below:
//      </h5>
//      <div className="find-bets">
//        <Row
//          style={{
//            flex: 0,
//            justifyContent: 'space-around',
//            width: '100%',
//            minWidth: '500px',
//            alignItems: 'center',
//            gap: '1rem',
//            margin: 0,
//            overflowX: 'auto',
//          }}
//        >
//          {fetchFindBets.isSuccess &&
//            fetchFindBets?.data?.results?.length === 0 && (
//              <p style={{ margin: 0 }}>
//                Sorry, we couldn't find any recommended bets
//              </p>
//            )}
//          {fetchFindBets?.data?.results.map((odd, i) => (
//            <Row
//              key={`find-bets-onb-odd-${odd.edge}-${i}`}
//              style={{
//                justifyContent: 'center',
//                alignItems: 'center',
//                gap: '1rem',
//                flexWrap: 'nowrap',
//              }}
//            >
//              <TeamLeagueLogo
//                style={{ flex: 0 }}
//                iconSize={52}
//                mobileIconSize={52}
//                league={odd?.league}
//              />
//              <div
//                style={{
//                  paddingLeft: '1rem',
//                  borderLeft: '1px solid rgba(0,0,0,0.3)',
//                  textAlign: 'left',
//                }}
//              >
//                <b>
//                  {odd.away_team} @ {odd?.home_team}
//                </b>
//                <BetLineLogos
//                  bet={{
//                    ...odd,
//                    player: { id: odd?.player_id, name: odd?.player_name },
//                    game: {
//                      id: odd?.game_id,
//                      league: odd.league,
//                      home_team: { name: odd?.home_team },
//                      away_team: { name: odd?.away_team },
//                    },
//                  }}
//                  odds_preference={0}
//                />
//                <span>{humanDatetime(odd.date, true)}</span>
//              </div>
//            </Row>
//          ))}
//        </Row>
//      </div>
//    </>
//  );
//}

function CurrentTask(props) {
  const dispatch = useDispatch();
  const reduxProps = useSelector(state => ({
    allBooksMap: state.authReducer.allBooksMap,
    userToken: state.authReducer.userToken,
    user: state.authReducer.user,
  }));
  const { allBooksMap, userToken, user } = reduxProps;

  const {
    progresses,
    currentProgress,
    submitProgress,
    attendee_id,
    attendee,
    setCurrentProgress,
    session_id,
    session,
  } = props;

  const history = useHistory();

  let actualBook = null;
  if (currentProgress) {
    actualBook = allBooksMap[currentProgress.book];
  }

  const nextProgress = useMemo(() => {
    if (!progresses) return null;
    return progresses.find(
      p =>
        p.current_step === 'NOT_STARTED' &&
        p.id !== currentProgress?.id &&
        !p.is_hidden
    );
  }, [progresses, currentProgress?.id]);

  const [modal, setModal] = useState({});

  const fetchSportsbookInfo = useQuery({
    enabled:
      currentProgress.current_step === 'DEPOSIT' ||
      currentProgress.current_step === 'PLACE_BET' ||
      currentProgress.current_step === 'NOT_STARTED',
    staleTime: 1000 * 60 * 5,
    queryKey: [
      {
        endpoint: `sportsbook-infos`,
        urlParams: {
          regions: JSON.stringify([attendee?.region]),
          book_ids: JSON.stringify([currentProgress.book]),
          get_one: true,
        },
      },
    ],
  });

  const stepData = STEP_DATA[currentProgress?.current_step];

  const MainBtnWrapper = stepData?.mainBtn?.wrapper || React.Fragment;
  let mainBtnProps = {};
  if (stepData?.mainBtn?.wrapper) {
    mainBtnProps = {
      actualBook: actualBook,
      referralCode:
        attendee?.signed_up_with_referral_code || session?.referral_code?.code,
    };
  }

  const ModalComp = modal.component || null;

  return (
    <>
      {modal?.show && (
        <ModalWrapper
          modalIsOpen={modal.show}
          onClose={() => setModal({ ...modal, show: false })}
          onRequestClose={() => setModal({ ...modal, show: false })}
          title={modal.modalTitle || modal.modalName}
          backgroundColor="white"
          color="black"
          //marginLeft="360px"
          marginLeft="16vw"
          boxShadow="0 2px 32px var(--color-onb-text)"
          modalSize={modal.modalSize || 'large'}
          showX
        >
          {modal.component && (
            <ModalComp
              {...props}
              stepData={stepData}
              actualBook={actualBook}
              fetchSportsbookInfo={fetchSportsbookInfo}
              closeModal={() => setModal({ show: false })}
              setModal={setModal}
              troubleshootModal={stepData?.troubleshootModal}
              nextProgress={nextProgress}
              setCurrentProgress={setCurrentProgress}
              allBooksMap={allBooksMap}
              session_id={session_id}
            />
          )}
        </ModalWrapper>
      )}
      {!currentProgress && (
        <ActivityIndicator
          size={2}
          style={{ marginTop: '25vh' }}
          blue={'true'}
        />
      )}
      {currentProgress && (
        <>
          <CurrentWrapper>
            <Col
              style={{
                flex: '5 0 300px',
                alignItems: 'stretch',
                flexWrap: 'nowrap',
              }}
            >
              <FourXLtoTwoXL>{stepData?.title}</FourXLtoTwoXL>

              {/* Description */}
              {currentProgress.current_step === 'DEPOSIT' &&
                fetchSportsbookInfo?.data && (
                  <>
                    {!!fetchSportsbookInfo?.data?.bonus && (
                      <h4 style={{ margin: 0 }}>
                        {actualBook?.name} is offering the following signup
                        bonus to new users:
                      </h4>
                    )}
                    {!!fetchSportsbookInfo?.data?.bonus && (
                      <TwoXLtoLG
                        style={{
                          marginTop: 'var(--space-lg)',
                          marginBottom: 'var(--space-sm)',
                        }}
                      >
                        {fetchSportsbookInfo?.data?.bonus}
                        {fetchSportsbookInfo?.data?.bonus_terms && (
                          <IoAlertCircleOutline
                            size={22}
                            color="var(--color-onb-blue)"
                            title={fetchSportsbookInfo?.data?.bonus_terms}
                            style={{
                              verticalAlign: 'middle',
                              margin: '0 var(--space-xxs)',
                            }}
                          />
                        )}
                      </TwoXLtoLG>
                    )}
                    <h5
                      style={{
                        color: 'var(--color-onb-blue)',
                        fontWeight: 'bold',
                        marginTop: 'var(--space-md)',
                      }}
                    >
                      Minimum Deposit: ${currentProgress?.min_deposit}
                    </h5>
                    <h5
                      style={{
                        color: 'var(--color-onb-blue)',
                        fontWeight: 'bold',
                        marginTop: 'var(--space-sm)',
                        marginBottom: 'var(--space-lg)',
                      }}
                    >
                      {!!fetchSportsbookInfo?.data.recommended_deposit && (
                        <>
                          Optimal Deposit: $
                          {fetchSportsbookInfo?.data.recommended_deposit}
                        </>
                      )}
                    </h5>
                  </>
                )}

              {stepData?.desc && (
                <TwoXLtoLG
                  style={{
                    marginBottom: 'var(--space-md)',
                    marginTop: 0,
                  }}
                >
                  {actualBook?.name === 'SuperBook' && (
                    <>
                      {stepData?.desc
                        .replace('{book}', actualBook?.name)
                        .replace(
                          'The below link will have our Betstamp exclusive bonuses pre-loaded into your new account.',
                          'Please use the code SUPERBOOK to get your bonus activated as you register.'
                        )
                        .replace(
                          '{rejectionReason}',
                          currentProgress?.rejection_reason
                        )
                        .replace(
                          '{minDeposit}',
                          `$${currentProgress?.min_deposit}`
                        )
                        .replace(
                          '{betSize}',
                          `$${currentProgress?.min_deposit
                            ? new Intl.NumberFormat('en', {
                              style: 'currency',
                              currency: 'CAD',
                              currencyDisplay: 'narrowSymbol',
                            }).format(currentProgress?.min_deposit / 2)
                            : ''
                          }`
                        )}
                    </>
                  )}
                  {actualBook?.name === 'Tipico' && (
                    <>
                      To complete the registration for this sportsbook, place{' '}
                      <u>
                        <b>TWO</b>
                      </u>{' '}
                      bets of{' '}
                      {new Intl.NumberFormat('en', {
                        style: 'currency',
                        currency: 'CAD',
                        currencyDisplay: 'narrowSymbol',
                      }).format(currentProgress?.min_deposit / 2)}{' '}
                      each. Please{' '}
                      <u>
                        <b>do not</b>
                      </u>{' '}
                      place the full ${currentProgress?.min_deposit} on one bet.
                    </>
                  )}

                  {actualBook?.name !== 'Tipico' &&
                    actualBook?.name !== 'SuperBook' &&
                    stepData?.desc
                      .replace('{book}', actualBook?.name)
                      .replace(
                        '{rejectionReason}',
                        currentProgress?.rejection_reason
                      )
                      .replace(
                        '{minDeposit}',
                        `$${currentProgress?.min_deposit}`
                      )
                      .replace(
                        '{betSize}',
                        `$${currentProgress?.min_deposit
                          ? new Intl.NumberFormat('en', {
                            style: 'currency',
                            currency: 'CAD',
                            currencyDisplay: 'narrowSymbol',
                          }).format(currentProgress?.min_deposit / 2)
                          : ''
                        }`
                      )}
                </TwoXLtoLG>
              )}

              {currentProgress.current_step === 'NEEDS_HELP' && (
                <>
                  <AuthButton
                    containerStyle={{ flex: 0 }}
                    colorTheme="onbblue"
                    btnTheme="onb"
                    onPress={
                      nextProgress
                        ? () => setCurrentProgress(nextProgress)
                        : () => history.push('all')
                    }
                    ampData={{
                      event: Analytics.events.STEP_CLICK,
                      data: {
                        attendee_id: attendee_id,
                        current_step: currentProgress?.currentProgress,
                        current_book_name: actualBook?.name,
                        current_book_id: actualBook?.id,
                        btnText: 'GET STARTED ON THE NEXT BOOK',
                      },
                    }}
                  >
                    GET STARTED ON THE NEXT BOOK
                  </AuthButton>
                  <AuthButton
                    containerStyle={{ flex: 0 }}
                    colorTheme="onbbluetext"
                    btnTheme="onb"
                    onPress={() => {
                      submitProgress.mutate({
                        endpoint: `onboarding-attendee-progresses/${currentProgress.id}`,
                        body: {
                          attendee_id: attendee_id,
                          session_id: session_id,
                          current_step: 'DEPOSIT',
                        },
                      });
                    }}
                    ampData={{
                      event: Analytics.events.STEP_CLICK,
                      data: {
                        attendee_id: attendee_id,
                        current_step: currentProgress?.currentProgress,
                        current_book_name: actualBook?.name,
                        current_book_id: actualBook?.id,
                        btnText: 'THE ISSUE HAS BEEN SOLVED',
                      },
                    }}
                  >
                    THE ISSUE HAS BEEN SOLVED
                  </AuthButton>
                </>
              )}

              {currentProgress?.current_step ===
                'PENDING_SCREENSHOT_APPROVAL' && (
                  <AuthButton
                    containerStyle={{ flex: 0 }}
                    colorTheme={'onbblue'}
                    btnTheme="onb"
                    onPress={
                      nextProgress
                        ? () => setCurrentProgress(nextProgress)
                        : () => history.push('all')
                    }
                    ampData={{
                      event: Analytics.events.STEP_CLICK,
                      data: {
                        attendee_id: attendee_id,
                        current_step: currentProgress?.currentProgress,
                        current_book_name: actualBook?.name,
                        current_book_id: actualBook?.id,
                        btnText: 'MOVE ON TO NEXT ACCOUNT',
                      },
                    }}
                  >
                    MOVE ON TO NEXT ACCOUNT
                  </AuthButton>
                )}

              {[
                //'PENDING_SCREENSHOT_APPROVAL',
                'FULLY_COMPLETE',
              ].includes(currentProgress.current_step) && (
                  <AuthButton
                    containerStyle={{ flex: 0 }}
                    colorTheme="onbblue"
                    btnTheme="onb"
                    onPress={
                      nextProgress
                        ? () => setCurrentProgress(nextProgress)
                        : () => history.push('all')
                    }
                    ampData={{
                      event: Analytics.events.STEP_CLICK,
                      data: {
                        attendee_id: attendee_id,
                        current_step: currentProgress?.currentProgress,
                        current_book_name: actualBook?.name,
                        current_book_id: actualBook?.id,
                        btnText: nextProgress
                          ? 'OK'
                          : stepData?.mainBtn?.text || 'OK',
                      },
                    }}
                  >
                    {nextProgress
                      ? stepData?.mainBtn?.text
                        ? stepData.mainBtn.text
                        : `OK`
                      : 'SEE ALL TASKS'}
                  </AuthButton>
                )}

              {stepData?.mainBtn && (
                <MainBtnWrapper {...mainBtnProps}>
                  <AuthButton
                    containerStyle={{ flex: 0 }}
                    colorTheme="onbblue"
                    btnTheme="onb"
                    onPress={() => {
                      props.submitProgress.reset();
                      if (currentProgress?.current_step === 'NOT_STARTED') {
                        submitProgress.mutate({
                          endpoint: `onboarding-attendee-progresses/${currentProgress.id}`,
                          body: {
                            attendee_id: attendee_id,
                            clicked_affiliate_link_increment: true,
                            session_id: session_id,
                          },
                        });
                      }
                      setModal({
                        show: true,
                        ...stepData?.mainBtn.modalData,
                      });
                    }}
                    ampData={{
                      event: Analytics.events.STEP_CLICK,
                      data: {
                        attendee_id: attendee_id,
                        current_step: currentProgress?.currentProgress,
                        current_book_name: actualBook?.name,
                        current_book_id: actualBook?.id,
                        btnText: stepData?.mainBtn?.text,
                        buttonType: 'main',
                      },
                    }}
                  >
                    {stepData?.mainBtn?.text}
                  </AuthButton>
                </MainBtnWrapper>
              )}
              {stepData?.middleBtns &&
                stepData.middleBtns.map(mb => (
                  <AuthButton
                    key={`onb-attde-middle-btn-${mb.text}`}
                    containerStyle={{ flex: 0 }}
                    colorTheme="onbbluetext"
                    btnTheme="onb"
                    btnStyle={{ backgroundColor: 'rgba(200,200,200)' }}
                    onPress={() =>
                      setModal({
                        show: true,
                        ...mb.modalData,
                      })
                    }
                    ampData={{
                      event: Analytics.events.STEP_CLICK,
                      data: {
                        attendee_id: attendee_id,
                        current_step: currentProgress?.currentProgress,
                        current_book_name: actualBook?.name,
                        current_book_id: actualBook?.id,
                        btnText: mb?.text,
                        buttonType: 'quick skip',
                      },
                    }}
                  >
                    {mb.text}
                  </AuthButton>
                ))}
              {stepData?.secBtn && (
                <AuthButton
                  containerStyle={{ flex: 0 }}
                  colorTheme="onbbluetext"
                  btnTheme="onb"
                  textStyle={{ textDecoration: 'underline' }}
                  onPress={() =>
                    setModal({
                      show: true,
                      ...stepData?.secBtn.modalData,
                    })
                  }
                  ampData={{
                    event: Analytics.events.STEP_CLICK,
                    data: {
                      attendee_id: attendee_id,
                      current_step: currentProgress?.currentProgress,
                      current_book_name: actualBook?.name,
                      current_book_id: actualBook?.id,
                      btnText: stepData?.secBtn?.text,
                      buttonType: 'secondary',
                    },
                  }}
                >
                  {stepData?.secBtn?.text}
                </AuthButton>
              )}
              {!!stepData?.completion && (
                <Row
                  style={{
                    flex: 0,
                    margin: '0 auto',
                    width: '100%',
                    maxWidth: '200px',
                  }}
                >
                  <CompletionDot complete={stepData?.completion === 1} />
                  <CompletionDot complete={stepData?.completion === 2} />
                  <CompletionDot complete={stepData?.completion === 3} />
                </Row>
              )}
            </Col>
            <Col
              style={{
                flex: '2 0 150px',
                justifyContent: 'center',
              }}
            >
              <BookVerticalBanner actualBook={actualBook} />
            </Col>
          </CurrentWrapper>
          <Row
            style={{
              width: '100%',
              justifyContent: 'space-between',
              gap: '1rem',
            }}
          >
            {stepData?.prevStep ? (
              <AuthButton
                containerStyle={{ maxWidth: '256px' }}
                colorTheme="onbbluetext"
                btnTheme="onb"
                textStyle={{ textDecoration: 'underline' }}
                onPress={() => {
                  submitProgress.mutate({
                    endpoint: `onboarding-attendee-progresses/${currentProgress.id}`,
                    body: {
                      attendee_id: attendee_id,
                      current_step: stepData.prevStep,
                      session_id: session_id,
                    },
                  });
                }}
                ampData={{
                  event: Analytics.events.STEP_CLICK,
                  data: {
                    attendee_id: attendee_id,
                    current_step: currentProgress?.currentProgress,
                    current_book_name: actualBook?.name,
                    current_book_id: actualBook?.id,
                    buttonType: 'previous',
                  },
                }}
              >
                Previous Step
              </AuthButton>
            ) : (
              <div stayle={{ flex: 1, maxWidth: '256px' }} />
            )}
            {stepData.troubleshootModal ? (
              <AuthButton
                containerStyle={{ maxWidth: '256px' }}
                colorTheme="onbbluetext"
                btnTheme="onb"
                textStyle={{ textDecoration: 'underline' }}
                onPress={() =>
                  setModal({
                    show: true,
                    ...stepData?.troubleshootModal,
                  })
                }
                ampData={{
                  event: Analytics.events.STEP_CLICK,
                  data: {
                    attendee_id: attendee_id,
                    current_step: currentProgress?.currentProgress,
                    current_book_name: actualBook?.name,
                    current_book_id: actualBook?.id,
                    buttonType: 'need help?',
                  },
                }}
              >
                Need Help?
              </AuthButton>
            ) : (
              <div style={{ flex: 1, maxWidth: '256px' }} />
            )}
          </Row>
        </>
      )}
    </>
  );
}

function AllTasks(props) {
  const dispatch = useDispatch();
  const reduxProps = useSelector(state => ({
    allBooksMap: state.authReducer.allBooksMap,
    userToken: state.authReducer.userToken,
    user: state.authReducer.user,
  }));
  const { allBooksMap, userToken, user } = reduxProps;

  const [showLoginModal, setShowLoginModal] = useState({
    show: false,
    book: {},
  });

  useEffect(() => {
    if (userToken) {
      setShowLoginModal({ ...showLoginModal, show: false });
    }
  }, [userToken]);

  const {
    progresses,
    currentProgress,
    numAll,
    numDone,
    numInProgress,
    numApproval,
    numExisting,
    setCurrentProgress,
    numNeedAttn,
    attendee,
    session,
  } = props;

  const history = useHistory();

  useEffectOnce(() => {
    if (props.fetchProgresses) {
      props.fetchProgresses.refetch();
    }
  });

  const [tab, setTab] = useState('all');
  const filteredProgresses = useMemo(() => {
    if (!progresses) return [];
    if (tab === 'all') {
      return progresses.filter(
        p =>
          p.id === currentProgress?.id ||
          (p.current_step !== 'NOT_STARTED' &&
            !p.already_had_book &&
            !p.is_hidden)
      );
    }
    if (tab === 'in progress') {
      return progresses.filter(
        p =>
          [
            'DEPOSIT',
            'PLACE_BET',
            'PENDING_DEPOSIT',
            'PENDING_BOOK_DOC_APPROVAL',
          ].includes(p.current_step) &&
          !p.already_had_book &&
          !p.is_hidden
      );
    }
    if (tab === 'pending approval') {
      return progresses.filter(
        p =>
          p.current_step === 'PENDING_SCREENSHOT_APPROVAL' &&
          !p.already_had_book &&
          !p.is_hidden
      );
    }
    if (tab === 'completed') {
      return progresses.filter(
        p =>
          p.current_step === 'FULLY_COMPLETE' &&
          !p.already_had_book &&
          !p.is_hidden
      );
    }
    if (tab === 'screenshots invalid') {
      return progresses.filter(
        p =>
          p.current_step === 'SCREENSHOTS_INVALID' &&
          !p.already_had_book &&
          !p.is_hidden
      );
    }
    if (tab === 'existing books') {
      return progresses.filter(p => p.already_had_book && !p.is_hidden);
    }
    return progresses;
  }, [progresses, tab]);

  return (
    <>
      {showLoginModal.show && (
        <ModalWrapper
          modalIsOpen={showLoginModal.show}
          onClose={() => setShowLoginModal({ ...showLoginModal, show: false })}
          onRequestClose={() =>
            setShowLoginModal({ ...showLoginModal, show: false })
          }
          title="Login to betstamp"
          backgroundColor="white"
          color="black"
          //marginLeft="360px"
          marginLeft="16vw"
          boxShadow="0 2px 32px var(--color-onb-text)"
        >
          <p>
            Login to your betstamp account to automatically sync all your wagers
            from {showLoginModal?.book?.name} into betstamp.
          </p>
          <LoginForm />
        </ModalWrapper>
      )}
      <DesktopLayout
        style={{
          width: '100%',
          minHeight: '93px',
          backgroundColor: 'rgba(0,0,0,0.1)',
        }}
      >
        <h4
          style={{
            flex: 1,
            fontWeight: 'bold',
            color: 'var(--color-onb-blue)',
            alignSelf: 'flex-start',
            padding: '0 var(--space-md)',
          }}
        >
          My Tasks
        </h4>
      </DesktopLayout>
      <TabBar
        tab={tab}
        setTab={setTab}
        numAll={numAll}
        numDone={numDone}
        numInProgress={numInProgress}
        numApproval={numApproval}
        numNeedAttn={numNeedAttn}
        numExisting={numExisting}
      />
      <div style={{ overflowX: 'hidden', width: '100%', flex: '1' }}>
        <div style={{ overflowX: 'auto' }}>
          {filteredProgresses?.length === 0 && (
            <p style={{ textAlign: 'center' }}>No sportsbooks {tab}</p>
          )}
          {filteredProgresses?.length > 0 && (
            <table
              cellSpacing={0}
              style={{
                width: '100%',
                minWidth: '768px',
                maxWidth: '1366px',
                padding: '0 var(--space-xs)',
                margin: '0 auto',
                tableLayout: 'fixed',
                paddingBottom: 'var(--space-xxl)',
              }}
            >
              <thead>
                <tr>
                  <th style={{ width: '40px' }}></th>
                  <th
                    style={{
                      textAlign: 'left',
                      padding: 'var(--space-sm) 0',
                      borderBottom: '2px solid rgba(0, 0, 0, 0.15)',
                    }}
                  >
                    SPORTSBOOK
                  </th>
                  <th
                    style={{
                      textAlign: 'left',
                      padding: 'var(--space-sm) 0',
                      borderBottom: '2px solid rgba(0, 0, 0, 0.15)',
                    }}
                  >
                    STATUS
                  </th>
                  <th style={{ borderBottom: '2px solid rgba(0, 0, 0, 0.15)' }}>
                    PROGRESS
                  </th>
                  <th
                    style={{ borderBottom: '2px solid rgba(0, 0, 0, 0.15)' }}
                  ></th>
                  <th
                    style={{
                      width: '64px',
                      borderBottom: '2px solid rgba(0, 0, 0, 0.15)',
                    }}
                  >
                    SHARE
                  </th>
                </tr>
              </thead>
              <tbody>
                {filteredProgresses.map(progress => {
                  let stepData = STEP_DATA[progress?.current_step];
                  if (progress?.already_had_book) {
                    stepData.statusText = 'Already had account';
                  }
                  const actualBook = allBooksMap[progress?.book];
                  const Icon = stepData?.statusIcon;
                  return (
                    <TR key={`onb-att-filtered-progress-${progress.id}`}>
                      <td style={{ width: '40px' }}>
                        {stepData?.statusIcon && (
                          <Icon
                            style={{ verticalAlign: 'middle' }}
                            size={28}
                            color={
                              stepData?.statusColor || 'var(--color-onb-text)'
                            }
                          />
                        )}
                      </td>
                      <td
                        onClick={() => {
                          setCurrentProgress(progress);
                          history.push('current');
                        }}
                        title={`Click to ${progress?.current_step === 'NOT_STARTED'
                            ? 'start'
                            : 'resume'
                          } ${actualBook?.name}`}
                      >
                        <Row
                          style={{
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            gap: '0.5rem',
                          }}
                        >
                          <img
                            style={{ borderRadius: '4px' }}
                            width={32}
                            src={actualBook?.logo}
                            alt={actualBook?.name}
                          />
                          <span>{actualBook?.name} </span>
                        </Row>
                      </td>
                      <td
                        style={{
                          lineHeight: 1,
                          color:
                            stepData?.statusColor || 'var(--color-onb-text)',
                        }}
                      >
                        {stepData.statusText || progress?.current_step}
                        {progress?.current_step === 'SCREENSHOTS_INVALID' &&
                          progress?.rejection_reason ? (
                          <small>
                            <br />
                            {progress.rejection_reason}
                          </small>
                        ) : null}
                      </td>
                      <td style={{ padding: '0 2vw' }}>
                        <div
                          style={{
                            height: '16px',
                            background: `linear-gradient(to right, var(--color-primary) ${(stepData?.progress / 4) * 100
                              }%, rgba(0,0,0,0.15) ${(stepData?.progress / 4) * 100 - 100
                              }%)`,
                          }}
                        />
                      </td>
                      <td>
                        {[
                          'NOT_STARTED',
                          'DEPOSIT',
                          'PLACE_BET',
                          'PENDING_DEPOSIT',
                          'PENDING_BOOK_DOC_APPROVAL',
                          'SCREENSHOTS_INVALID',
                          'PENDING_SCREENSHOT_APPROVAL',
                          'NEEDS_HELP',
                        ].includes(progress?.current_step) && (
                            <AuthButton
                              containerStyle={{ margin: 0 }}
                              btnStyle={{ borderRadius: '8px' }}
                              colorTheme="inverted"
                              title={`${progress?.current_step === 'NOT_STARTED'
                                  ? 'Start'
                                  : 'Resume'
                                } ${actualBook?.name}`}
                              onPress={() => {
                                setCurrentProgress(progress);
                                history.push('current');
                              }}
                            >
                              {progress?.current_step === 'NOT_STARTED'
                                ? 'START'
                                : progress?.current_step === 'SCREENSHOTS_INVALID'
                                  ? 'UPLOAD NEW SCREENSHOTS'
                                  : 'RESUME'}
                            </AuthButton>
                          )}
                      </td>
                      <td>
                        <Row
                          style={{
                            textAlign: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <AffiliateLink
                            shape="sharableLink"
                            fromPage="Sportsbook Assist Tool"
                            book={actualBook}
                            referralCode={
                              attendee?.signed_up_with_referral_code?.code ||
                              session?.referral_code?.code
                            }
                          />
                        </Row>
                      </td>
                    </TR>
                  );
                })}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </>
  );
}

function TabBar(props) {
  const {
    tab,
    setTab,
    numAll,
    numDone,
    numInProgress,
    numApproval,
    numNeedAttn,
    numExisting,
  } = props;

  return (
    <>
      <div style={{ overflowX: 'hidden', width: '100%', flex: '0 0 64px' }}>
        <div style={{ overflowX: 'auto' }}>
          <Row
            style={{
              flex: 0,
              width: '100%',
              justifyContent: 'flex-start',
              gap: 'var(--space-lg)',
              padding: '0 var(--space-md)',
              flexWrap: 'nowrap',
              minWidth: '600px',
            }}
          >
            <TabBtn
              selectedTab={tab}
              tab="all"
              setTab={setTab}
              title={`All (${numAll})`}
            />
            <TabBtn
              selectedTab={tab}
              tab="in progress"
              setTab={setTab}
              title={`In Progress (${numInProgress})`}
            />
            <TabBtn
              selectedTab={tab}
              tab="pending approval"
              setTab={setTab}
              title={`Pending Approval (${numApproval})`}
            />
            <TabBtn
              selectedTab={tab}
              tab="screenshots invalid"
              setTab={setTab}
              title={`Screenshots Rejected (${numNeedAttn})`}
            />
            <TabBtn
              selectedTab={tab}
              tab="completed"
              setTab={setTab}
              title={`Complete (${numDone})`}
            />
            <TabBtn
              selectedTab={tab}
              tab="existing books"
              setTab={setTab}
              title={`Existing Sportsbooks (${numExisting})`}
            />
          </Row>
        </div>
        <div
          style={{
            psition: 'absolute',
            width: '100%',
            marginTop: '-4px',
            borderBottom: '4px solid rgba(0,0,0,0.15)',
            zIndex: 1,
          }}
        />
      </div>
    </>
  );
}

function TabBtn({ selectedTab, tab, setTab, title }) {
  return (
    <AuthButton
      containerStyle={{
        zIndex: 2,
        flex: 0,
        borderBottom: `4px solid ${selectedTab === tab ? 'var(--color-primary)' : 'transparent'
          }`,
        margin: 0,
        borderRadius: 0,
      }}
      btnStyle={{
        padding: 'var(--space-sm) var(--space-md)',
        borderRadius: 0,
      }}
      textStyle={{
        whiteSpace: 'nowrap',
        color:
          selectedTab === tab
            ? 'var(--color-primary)'
            : 'var(--color-onb-blue)',
      }}
      btnTheme="borderless"
      onPress={() => setTab(tab)}
    >
      {title}
    </AuthButton>
  );
}

const VertImg = styled.img`
  width: ${props => (props.vertical && !props.small ? '256px' : '100px')};
  @media only screen and (max-width: 625px) {
    width: 116px;
  }
`;

function BookVerticalBanner({ actualBook, small }) {
  if (!actualBook) return null;
  return (
    <>
      <VertImg
        src={actualBook?.vertical_generic_banner || actualBook?.logo}
        alt={actualBook?.name}
        vertical={!!actualBook?.vertical_generic_banner}
        small={small}
      />
      {!actualBook?.vertical_generic_banner && (
        <b>{actualBook?.name.toUpperCase()}</b>
      )}
    </>
  );
}

function LoginForm() {
  const dispatch = useDispatch();
  const reduxProps = useSelector(state => ({
    isLoading: state.authReducer.isLoading,
    apiError: state.authReducer.apiError,
  }));
  const { isLoading, apiError } = reduxProps;

  const [valids, setValids] = useState({});
  const [forceEmailInvalid, setForceEmailInvalid] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [login, setLogin] = useState({ email: '', password: '' });

  const loginDisabled = !valids.email || !valids.password;

  useEffect(() => {
    const emailValid = isEmailValid(login.email);
    if (emailValid) {
      setForceEmailInvalid(null);
    } else {
      setForceEmailInvalid('Invalid email');
    }

    setValids({ ...valids, password: true, email: emailValid });
  }, [login]);

  return (
    <>
      <form
        onSubmit={ev => {
          ev.preventDefault();
          if (valids.email && valids.password) {
            dispatch(
              loginUser({
                email: login.email,
                password: login.password,
              })
            );
          }
        }}
      >
        <AuthTextInput
          label="Email"
          name="email"
          type="email"
          textContentType={'emailAddress'}
          leftIcon={IoMailOutline}
          leftIconColor={
            valids.email || login.email === ''
              ? 'var(--color-onb-blue)'
              : undefined
          }
          rightIcon={valids.email ? IoCheckmarkCircleOutline : null}
          rightIconColor={'var(--color-success)'}
          errorText={login.email && forceEmailInvalid}
          placeholder={'yourname@gmail.com'}
          containerStyle={{ maxWidth: '400px', margin: '0 auto' }}
          inputRowStyle={{
            backgroundColor: 'rgba(230,230,230)',
            color: 'var(--color-onb-blue)',
          }}
          onEndEditing={() => {
            if (!valids.email) setForceEmailInvalid('Invalid email');
          }}
          onChangeText={text => setLogin({ ...login, email: text })}
          value={login.email}
          autoFocus={true}
          colorTheme="onb"
        />
        <AuthTextInput
          label="Password"
          name="password"
          placeholder={'Password'}
          containerStyle={{
            maxWidth: '400px',
            margin: '0 auto',
          }}
          inputRowStyle={{
            backgroundColor: 'rgba(230,230,230)',
            color: 'var(--color-onb-blue)',
          }}
          leftIcon={IoLockClosedOutline}
          leftIconColor={'var(--color-onb-blue)'}
          rightIcon={showPassword ? IoEyeOutline : IoEyeOffOutline}
          rightIconOnPress={() => setShowPassword(!showPassword)}
          rightIconColor={'var(--color-onb-blue)'}
          type="password"
          onChangeText={text => setLogin({ ...login, password: text })}
          colorTheme="onb"
        />
        {apiError ? (
          <ErrorDisplay
            error={apiError}
            basic={true}
            containerStyle={{
              textAlign: 'center',
              padding: 'var(--space-xs)',
              backgroundColor: 'var(--color-fg)',
              borderRadius: 'var(--std-border-radius)',
              maxWidth: '400px',
              margin: '0 auto',
            }}
          />
        ) : (
          <div style={{ padding: 'var(--space-md)' }} />
        )}
        <AuthButton
          type="submit"
          btnTheme="onb"
          colorTheme="onbblue"
          containerStyle={{ maxWidth: '400px', margin: '0 auto' }}
          btnStyle={
            loginDisabled ? { backgroundColor: 'rgba(230,230,230)' } : {}
          }
          textStyle={loginDisabled ? { color: 'rgba(180,180,180)' } : {}}
          isLoading={isLoading}
          disabled={loginDisabled}
        >
          LOGIN
        </AuthButton>
      </form>

      <br />
      <Col style={{ margin: '0 auto', maxWidth: '400px', textAlign: 'center' }}>
        <span style={{ padding: 'var(--space-md) 0' }}>OR</span>
        <AppleAuthButton login />
        <GoogleAuthButton login />
        <TwitterAuthButton login />
      </Col>
    </>
  );
}
