//actions

export const GET_ACCOUNTS = 'GET_ACCOUNTS';
export const GET_ACCOUNTS_SUCCESS = 'GET_ACCOUNTS_SUCCESS';
export const GET_ACCOUNTS_FAILURE = 'GET_ACCOUNTS_FAILURE';

export const AS_LOGIN_USER = 'AS_LOGIN_USER';
export const AS_LOGIN_USER_SUCCESS = 'AS_LOGIN_USER_SUCCESS';
export const AS_LOGIN_USER_FAILURE = 'AS_LOGIN_USER_FAILURE';

export const AS_RESET_ERRORS = 'AS_RESET_ERRORS';

export const DISCONNECT_AUTOSYNC = 'DISCONNECT_AUTOSYNC';
export const DISCONNECT_AUTOSYNC_SUCCESS = 'DISCONNECT_AUTOSYNC_SUCCESS';
export const DISCONNECT_AUTOSYNC_FAILURE = 'DISCONNECT_AUTOSYNC_FAILURE';

export const DISCONNECT_ALL_AUTOSYNC = 'DISCONNECT_ALL_AUTOSYNC';
export const DISCONNECT_ALL_AUTOSYNC_SUCCESS =
  'DISCONNECT_ALL_AUTOSYNC_SUCCESS';
export const DISCONNECT_ALL_AUTOSYNC_FAILURE =
  'DISCONNECT_ALL_AUTOSYNC_FAILURE';

export const SYNC_BETS = 'SYNC_BETS';
export const SYNC_BETS_SUCCESS = 'SYNC_BETS_SUCCESS';
export const SYNC_BETS_FAILURE = 'SYNC_BETS_FAILURE';

export const SYNC_ALL_BETS = 'SYNC_ALL_BETS';
export const SYNC_ALL_BETS_SUCCESS = 'SYNC_ALL_BETS_SUCCESS';
export const SYNC_ALL_BETS_FAILURE = 'SYNC_ALL_BETS_FAILURE';

export const SYNC_ALL_ACCOUNTS = 'SYNC_ALL_ACCOUNTS';
export const SYNC_ALL_ACCOUNTS_SUCCESS = 'SYNC_ALL_ACCOUNTS_SUCCESS';
export const SYNC_ALL_ACCOUNTS_FAILURE = 'SYNC_ALL_ACCOUNTS_FAILURE';

export const REFRESH_HISTORY = 'REFRESH_HISTORY';
export const REFRESH_HISTORY_SUCCESS = 'REFRESH_HISTORY_SUCCESS';
export const REFRESH_HISTORY_FAILURE = 'REFRESH_HISTORY_FAILURE';

export const REFRESH_HISTORY_ALL = 'REFRESH_HISTORY_ALL';
export const REFRESH_HISTORY_ALL_SUCCESS = 'REFRESH_HISTORY_ALL_SUCCESS';
export const REFRESH_HISTORY_ALL_FAILURE = 'REFRESH_HISTORY_ALL_FAILURE';

export const PROMPT_TWO_FA = 'PROMPT_TWO_FA';
export const TWO_FA = 'TWO_FA';
export const TWO_FA_SUCCESS = 'TWO_FA_SUCCESS';
export const TWO_FA_FAILURE = 'TWO_FA_FAILURE';

export const UPDATE_SETTINGS = 'UPDATE_SETTINGS';
export const UPDATE_SETTINGS_SUCCESS = 'UPDATE_SETTINGS_SUCCESS';
export const UPDATE_SETTINGS_FAILURE = 'UPDATE_SETTINGS_FAILURE';

//flush all changes (mainly for testing purposes)
export const CLEAN_AUTOSYNC_STORE = 'CLEAN_AUTOSYNC_STORE';

export const getAccounts = params => ({
  type: GET_ACCOUNTS,
  params: params,
});

export const getAccountsSuccess = payload => ({
  type: GET_ACCOUNTS_SUCCESS,
  payload: payload,
});

export const getAccountsFailure = error => ({
  type: GET_ACCOUNTS_FAILURE,
  payload: error,
});

export const autoSyncLoginUser = params => ({
  type: AS_LOGIN_USER,
  params: params,
});

export const autoSyncLoginUserSuccess = payload => ({
  type: AS_LOGIN_USER_SUCCESS,
  payload: payload,
});

export const autoSyncLoginUserFailure = error => ({
  type: AS_LOGIN_USER_FAILURE,
  payload: error,
});

export const autoSyncResetErrors = () => ({
  type: AS_RESET_ERRORS,
});

export const disconnectAutoSync = params => ({
  type: DISCONNECT_AUTOSYNC,
  params: params,
});

export const disconnectAutoSyncSuccess = payload => ({
  type: DISCONNECT_AUTOSYNC_SUCCESS,
  payload: payload,
});

export const disconnectAutoSyncFailure = error => ({
  type: DISCONNECT_AUTOSYNC_FAILURE,
  payload: error,
});

export const disconnectAllAutoSync = params => ({
  type: DISCONNECT_ALL_AUTOSYNC,
  params: params,
});

export const disconnectAllAutoSyncSuccess = payload => ({
  type: DISCONNECT_ALL_AUTOSYNC_SUCCESS,
  payload: payload,
});

export const disconnectAllAutoSyncFailure = error => ({
  type: DISCONNECT_ALL_AUTOSYNC_FAILURE,
  payload: error,
});

export const syncBets = params => ({
  type: SYNC_BETS,
  params: params,
});

export const syncBetsSuccess = payload => ({
  type: SYNC_BETS_SUCCESS,
  payload: payload,
});

export const syncBetsFailure = error => ({
  type: SYNC_BETS_FAILURE,
  payload: error,
});

export const syncAllBets = params => ({
  type: SYNC_ALL_BETS,
  params: params,
});

export const syncAllBetsSuccess = (payload, params) => ({
  type: SYNC_ALL_BETS_SUCCESS,
  payload: payload,
  params,
});

export const syncAllBetsFailure = (error, params) => ({
  type: SYNC_ALL_BETS_FAILURE,
  payload: error,
  params,
});

export const syncAllAccounts = params => ({
  type: SYNC_ALL_ACCOUNTS,
  params: params,
});

export const syncAllAccountsSuccess = (payload, params) => ({
  type: SYNC_ALL_ACCOUNTS_SUCCESS,
  payload,
  params,
});

export const syncAllAccountsFailure = (error, params) => ({
  type: SYNC_ALL_ACCOUNTS_FAILURE,
  payload: error,
  params,
});

export const refreshHistory = params => ({
  type: REFRESH_HISTORY,
  params: params,
});

export const refreshHistorySuccess = payload => ({
  type: REFRESH_HISTORY_SUCCESS,
  payload: payload,
});

export const refreshHistoryFailure = error => ({
  type: REFRESH_HISTORY_FAILURE,
  payload: error,
});

export const refreshHistoryAll = params => ({
  type: REFRESH_HISTORY_ALL,
  params: params,
});

export const refreshHistoryAllSuccess = payload => ({
  type: REFRESH_HISTORY_ALL_SUCCESS,
  payload: payload,
});

export const refreshHistoryAllFailure = error => ({
  type: REFRESH_HISTORY_ALL_FAILURE,
  payload: error,
});

export const promptTwoFA = payload => ({
  type: PROMPT_TWO_FA,
  payload,
});
export const twoFA = params => ({
  type: TWO_FA,
  params,
});

export const twoFASuccess = payload => ({
  type: TWO_FA_SUCCESS,
  payload,
});

export const twoFAFailure = error => ({
  type: TWO_FA_FAILURE,
  error,
});

export const updateSettings = params => ({
  type: UPDATE_SETTINGS,
  params,
});

export const updateSettingsSuccess = payload => ({
  type: UPDATE_SETTINGS_SUCCESS,
  payload,
});

export const updateSettingsFailure = error => ({
  type: UPDATE_SETTINGS_FAILURE,
  error,
});

export const cleanAutoSyncStore = () => ({
  type: CLEAN_AUTOSYNC_STORE,
});
