import {
  GET_FIND_BETS,
  GET_FIND_BETS_SUCCESS,
  GET_FIND_BETS_FAILURE,
  GET_FIND_BETS_NEXT,
} from 'actions';

const initialState = {
  reccOdds: [],
  isLoadingReccOdds: false,
  reccOddFailure: null,
  reccOddsNext: null,
  isLoadingReccOddsNext: false,
};

export default function findBetsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_FIND_BETS:
      return Object.assign({}, state, {
        isLoadingReccOdds: true,
        reccOddFailure: null,
        reccOdds: [],
      });
    case GET_FIND_BETS_SUCCESS:
      return Object.assign({}, state, {
        isLoadingReccOdds: false,
        isLoadingReccOddsNext: false,
        reccOddFailure: null,
        reccOdds: state.reccOdds.concat(action.payload.results),
        reccOddsNext: action.payload.next,
      });
    case GET_FIND_BETS_FAILURE:
      return Object.assign({}, state, {
        isLoadingReccOdds: false,
        reccOddFailure: action.error,
      });
    case GET_FIND_BETS_NEXT:
      return Object.assign({}, state, {
        isLoadingReccOddsNext: true,
      });
    default:
      return state;
  }
}
