import { combineEpics } from 'redux-observable';

import autoSyncLoginUser from 'epics/autosync/loginUser.epic.js';
import disconnectAutoSync from 'epics/autosync/disconnectUser.epic';
import disconnectAllAutoSync from 'epics/autosync/disconnectAllUsers.epic';
import syncBets from 'epics/autosync/syncBets.epic';
import syncAllBets from 'epics/autosync/syncAllBets.epic';
import getAccounts from 'epics/autosync/getAccounts.epic';
import refreshHistory from 'epics/autosync/refreshHistory.epic';
import refreshHistoryAll from 'epics/autosync/refreshHistoryAll.epic';
import twoFA from 'epics/autosync/twoFA.epic';
import updateSettings from 'epics/autosync/updateSettings.epic';
import syncAllAccounts from './syncAllAccounts.epic';

export default combineEpics(
  autoSyncLoginUser,
  disconnectAutoSync,
  disconnectAllAutoSync,
  syncBets,
  syncAllBets,
  syncAllAccounts,
  getAccounts,
  refreshHistory,
  refreshHistoryAll,
  twoFA,
  updateSettings
);
