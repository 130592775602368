/**
 * Checks to see which competition options a user should be able to choose from.
 * @param {Object} competitions of the user.
 * @returns {Array} of competition choices for specific wager + "No Competition".
 */
export default function competitionChoices(competitions) {
  let choices = [];
  for (const property in competitions) {
    choices.push({ value: property, label: competitions[property].name });
  }

  choices.push({ value: '-1', label: 'No Competition' });

  return choices;
}
