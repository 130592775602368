import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { IoRefresh, IoAddSharp } from 'react-icons/io5';
import { BsArrowsCollapse, BsArrowsExpand } from 'react-icons/bs';
import moment from 'moment';
import { useUpdateEffect } from 'react-use';

// components
import {
  Row,
  MainAreaWrapper,
  InnerMainArea,
  Toolbar,
} from 'components/generic/Layout';
import TodaysAction2 from './Tabs/TodaysAction2';
import Historicals from './Tabs/Historicals';
// import LiveNow from './Tabs/LiveNow.toptab';
import DateRange from 'components/generic/DateRange';
import ActivityIndicator from 'components/generic/ActivityIndicator';
import ModalWrapper from 'components/generic/ModalWrapper';
import FAB from 'components/generic/FAB';
import SwitchSelector from 'components/generic/SwitchSelector';
import { IconButton } from 'components/AuthButton';

// modals
import AddUnsupported from 'components/modals/AddUnsupported';

// actions
import {
  getPicksStats,
  resetWagerEditDel,
  getWagers,
  removeAllBets,
  resetTransactionEditDel,
} from 'actions';

export default function MyPicksDesktop2(props) {
  const dispatch = useDispatch();
  const reduxProps = useSelector(state => ({
    isWagersLoading: state.wagerReducer.isWagersLoading['pendingToday'],
    pickStats: state.picksReducer.pickStats,
    isLoadingPickStats: state.picksReducer.isLoadingPickStats,
    hist_date_after: state.picksReducer.hist_date_after,
    hist_date_before: state.picksReducer.hist_date_before,
  }));
  const { isWagersLoading, hist_date_after, hist_date_before } = reduxProps;

  const [view, setView] = useState('all');

  const _refreshData = (refreshStats, refreshData) => {
    const today = moment()
      .subtract(4, 'hours')
      .hours(3)
      .minutes(59)
      .seconds(59)
      .utc();
    const tomorrow = moment.utc(today).add(1, 'days');

    const date_after = today;
    const date_before = tomorrow;

    if (refreshStats) {
      dispatch(getPicksStats(hist_date_after, hist_date_before));
    }

    if (refreshData) {
      if (view === 'all') {
        dispatch(
          getWagers({
            screen: 'todaysAction',
            date_after: date_after,
          })
        );
      } else if (view === 'graded today') {
        dispatch(
          getWagers({
            screen: 'todaysAction',
            date_after: date_after,
            date_before: date_before,
            is_graded: true,
          })
        );
      } else if (view === 'pending today') {
        dispatch(
          getWagers({
            screen: 'todaysAction',
            date_after: date_after,
            date_before: date_before,
            is_graded: false,
          })
        );
      } else if (view === 'futures') {
        dispatch(
          getWagers({
            screen: 'todaysAction',
            date_after: date_after.add(1, 'day'),
            is_graded: false,
          })
        );
      }
    }
  };

  useUpdateEffect(() => {
    _refreshData(false, true);
  }, [view]);

  const [modalVisible, setModalVisible] = useState(false);
  const [collapseAll, setCollapseAll] = useState(null);
  return (
    <>
      {modalVisible && (
        <ModalWrapper
          modalIsOpen={modalVisible}
          onClose={() => setModalVisible(false)}
          onRequestClose={() => setModalVisible(false)}
          title="Add Custom Bet"
        >
          <AddUnsupported
            mode={'custom bet'}
            hideModal={refresh => {
              dispatch(resetWagerEditDel());
              dispatch(resetTransactionEditDel());
              dispatch(removeAllBets());
              setModalVisible(false);
              if (refresh) _refreshData(true, true);
            }}
          />
        </ModalWrapper>
      )}
      <FAB onClick={() => setModalVisible(true)}>
        <IoAddSharp
          title="Add Bet"
          style={{
            fontSize: 'var(--text-xxxxl)',
            marginBottom: '-4px',
          }}
        />
      </FAB>
      <Toolbar>
        <Row style={{ justifyContent: 'center', alignItems: 'center' }}>
          <SwitchSelector
            options={['all', 'graded today', 'pending today', 'futures']}
            active={view}
            changeMode={opt => setView(opt)}
          />
          {/* <h6 style={{ margin: 0 }}>Today's Action</h6> */}
          {/* <SwitchSelector
            options={["today's action", 'live now']}
            active={view}
            changeMode={(opt) => setView(opt)}
          /> */}
          {isWagersLoading ? (
            <ActivityIndicator
              style={{
                alignSelf: 'center',
                fontSize: 'var(--text-xl)',
                margin: '0 var(--space-sm)',
                cursor: 'pointer',
              }}
            />
          ) : (
            <IconButton
              iconName={IoRefresh}
              iconTitle="Refresh Picks"
              containerStyle={{
                margin: '0 var(--space-sm)',
              }}
              iconSize={26}
              iconColor="var(--color-text)"
              onPress={() => _refreshData(true, true)}
            />
          )}
          <IconButton
            iconName={collapseAll ? BsArrowsExpand : BsArrowsCollapse}
            iconTitle={collapseAll ? 'Expand All Games' : 'Collapse All Games'}
            iconSize={26}
            iconColor="var(--color-text)"
            containerStyle={{
              margin: '0 var(--space-sm)',
            }}
            onPress={() => setCollapseAll(!collapseAll)}
          />
        </Row>
        <Row style={{ alignItems: 'center', justifyContent: 'space-around' }}>
          {view !== 'live now' && (
            <DateRange
              getItemsInRange={(d1, d2) => {
                dispatch(
                  getWagers({
                    screen: 'historicals',
                    date_after: d1,
                    date_before: d2,
                    is_graded: true,
                  })
                );
                dispatch(
                  getPicksStats(
                    d1 ? moment(d1).startOf('day') : null,
                    d2 ? moment(d2).endOf('day') : null
                  )
                );
              }}
              defaultStartDate={hist_date_after}
              defaultEndDate={hist_date_before}
            />
          )}
          <Link
            style={{
              padding: '0 var(--space-xs)',
              color: 'var(--color-primary)',
            }}
            to="/my-picks-hidden"
          >
            View Hidden
          </Link>
        </Row>
      </Toolbar>
      <MainAreaWrapper>
        <InnerMainArea>
          <div
            style={{
              width: '100%',
              height: '100%',
              overflowY: 'auto',
              padding: '0 var(--space-sm)',
              boxSizing: 'border-box',
              paddingBottom: 'var(--space-xxxxl)',
            }}
            id="infinite-scroll-target-desktop-ta"
          >
            {view !== 'live now' && (
              <TodaysAction2
                desktop
                collapseAll={collapseAll}
                view={view}
                scrollTarget="infinite-scroll-target-desktop-ta"
              />
            )}

            {/* {view === 'live now' && (
              <LiveNow scrollTarget="infinite-scroll-target-desktop-ta" />
            )} */}
          </div>
        </InnerMainArea>
        {view !== 'live now' && (
          <InnerMainArea>
            <div
              style={{
                width: '100%',
                height: '100%',
                overflowY: 'auto',
                padding: '0 var(--space-sm)',
                boxSizing: 'border-box',
                paddingBottom: 'var(--space-xxxxl)',
              }}
              id="infinite-scroll-target-desktop-hist"
            >
              <Historicals
                desktop
                scrollTarget="infinite-scroll-target-desktop-hist"
              />
            </div>
          </InnerMainArea>
        )}
      </MainAreaWrapper>
    </>
  );
}
