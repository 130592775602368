import { Observable } from 'rxjs';
import 'rxjs/add/operator/exhaustMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import { ajax } from 'rxjs/observable/dom/ajax';
import { BACKEND_API_URL, getHeaders } from 'utils';

import {
  TRACK_BETSLIP,
  trackBetslipSuccess,
  trackBetslipFailure,
} from 'actions';

export default function trackBetslip(action$, state$) {
  return action$.ofType(TRACK_BETSLIP).exhaustMap(() => {
    // only send necessary bet data
    const cleanedBets = state$.value.betslipReducer.bets.reduce((acc, curr) => {
      if (curr.tracked) return acc;
      acc.push({
        ...curr,
        book: {
          id: curr.book.id,
          name: curr.book.name,
        },
      });
      return acc;
    }, []);

    if (cleanedBets.length === 0) {
      return Observable.of(trackBetslipSuccess({ response: { bets: [] } }));
    }

    return ajax
      .post(
        BACKEND_API_URL + 'api/track_betslip/',
        {
          mode: state$.value.betslipReducer.mode,
          bets: cleanedBets,
          totalRisk: state$.value.betslipReducer.totalRisk,
          totalWin: state$.value.betslipReducer.totalWin,
          ...state$.value.betslipReducer.parlayValues,
        },
        getHeaders(state$)
      )
      .map(data => trackBetslipSuccess(data))
      .catch(error => Observable.of(trackBetslipFailure(error.xhr)));
  });
}
