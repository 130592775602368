import { Observable } from 'rxjs';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import { ajax } from 'rxjs/observable/dom/ajax';
import { getHeaders } from 'utils';

import {
  GET_FOLLOWERS_NEXT,
  getFollowersSuccess,
  getFollowersFailure,
} from 'actions';

export default function getFollowersNext(action$, state$) {
  return action$.ofType(GET_FOLLOWERS_NEXT).switchMap(() => {
    return ajax
      .getJSON(state$.value.publicUserReducer.followersNext, getHeaders(state$))
      .map(data => getFollowersSuccess(data))
      .catch(error => Observable.of(getFollowersFailure(error.xhr)));
  });
}
