import { Observable } from 'rxjs';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import { ajax } from 'rxjs/observable/dom/ajax';
import { BACKEND_API_URL, getHeaders } from 'utils';

import { UPDATE_WAGER, updateWagerSuccess, updateWagerFailure } from 'actions';

export default function updateWager(action$, state$) {
  return action$.ofType(UPDATE_WAGER).exhaustMap(action => {
    let url = `${BACKEND_API_URL}api/wagers/${action.wager.id}/`;

    return ajax
      .patch(url, action.wager, getHeaders(state$))
      .map(data => updateWagerSuccess(data.response))
      .catch(error => Observable.of(updateWagerFailure(error.xhr)));
  });
}
