// actions (excluding success and failure actions):
export const SEARCH = 'SEARCH';
export const UPDATE_FILTER_OPTIONS = 'UPDATE_FILTER_OPTIONS';
export const RESET_ERRORS_SEARCH = 'RESET_ERRORS_SEARCH';
export const FOLLOWED_USER_FROM_SEARCH = 'FOLLOWED_USER_FROM_SEARCH';

// SEARCH
export const SEARCH_SUCCESS = 'SEARCH_SUCCESS';
export const SEARCH_FAILURE = 'SEARCH_FAILURE';
export const GET_USERS_NEXT = 'GET_USERS_NEXT';

export const search = (searchTerm) => ({
  type: SEARCH,
  searchTerm: searchTerm,
});

export const searchSuccess = (payload) => ({
  type: SEARCH_SUCCESS,
  payload: payload,
});

export const searchFailure = (payload) => ({
  type: SEARCH_FAILURE,
  payload: payload,
});

// UPDATE FILTER OPTIONS
export const updateFilterOptions = (options) => ({
  type: UPDATE_FILTER_OPTIONS,
  options: options,
});

// RESET_ERRORS_SEARCH
export const resetErrorsSearch = () => ({
  type: RESET_ERRORS_SEARCH,
});

export const getUsersNext = () => ({
  type: GET_USERS_NEXT,
});

export const followedUserFromSearch = (payload, otherUserID) => ({
  type: FOLLOWED_USER_FROM_SEARCH,
  payload,
  otherUserID,
});
