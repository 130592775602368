import React from 'react';

// components
import { OnboardingWrapper, BlueBubble } from 'components/auth/onboarding';
import { Col } from 'components/generic/Layout';

export default function Meantime() {
  return (
    <OnboardingWrapper nextScreen="/onboarding/find-users" onNext={() => {}}>
      <Col style={{ flex: 0, width: '100%' }}>
        <BlueBubble
          style={{
            marginTop: 'var(--space-md)',
            marginBottom: 'var(--space-md)',
          }}
        >
          <h5
            style={{
              marginTop: 'var(--space-md)',
              marginBottom: 'var(--space-md)',
              color: 'white',
            }}
          >
            In the meantime, you can track any bets you would have made for free
            to see how you would have done. View live scores, & odds updates,
            and improve by using the analysis tool.
          </h5>
        </BlueBubble>
      </Col>
      <img
        style={{ margin: 'var(--space-md) 0', maxWidth: 'min(800px, 70%)' }}
        src={
          'https://betstamp-public.sfo2.cdn.digitaloceanspaces.com/signup-flow/track-bets.png'
        }
        alt={'Track Bets Manually'}
      />
    </OnboardingWrapper>
  );
}
