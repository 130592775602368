import { Observable } from 'rxjs';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import { ajax } from 'rxjs/observable/dom/ajax';
import { getHeaders } from 'utils';

import {
  VIEW_PURCHASES_NEXT,
  viewPurchasesSuccess,
  viewPurchasesFailure,
} from 'actions';

export default function purchasesNext(action$, state$) {
  return action$.ofType(VIEW_PURCHASES_NEXT).switchMap(() => {
    return ajax
      .getJSON(state$.value.stripeReducer.purchasesNext, getHeaders(state$))
      .map(data => viewPurchasesSuccess(data))
      .catch(error => Observable.of(viewPurchasesFailure(error.xhr)));
  });
}
