import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import {
  IoArrowForwardCircle,
  IoCheckmarkCircleOutline,
  IoEyeOffOutline,
  IoEyeOutline,
  IoLockClosedOutline,
  IoMailOutline,
} from 'react-icons/io5';
import Analytics from 'amplitude/Analytics';

// utils
import { isEmailValid } from 'utils';

// components
import { BetstampWordWhiteText } from 'components/generic/Logos';
import { Col } from 'components/generic/Layout';
import { AuthButton } from 'components/AuthButton';
import { AuthTextInput } from 'components/AuthTextInput';
import ErrorDisplay from 'components/generic/ErrorDisplay';

// actions
import { loginUser, resetErrors, getUser } from 'actions';

const Wrapper = styled.div`
  min-height: 100vh;
  background-color: var(--color-primary);
  color: white;
  flex: 1;
  display: flex;
  flex-flow: column wrap;
  justify-content: space-around;
  align-items: center;
`;

export default function LoginEmail() {
  const dispatch = useDispatch();
  const reduxProps = useSelector((state) => ({
    userToken: state.authReducer.userToken,
    isLoading: state.authReducer.isLoading,
    apiError: state.authReducer.apiError,
  }));
  const { isLoading, userToken, apiError } = reduxProps;

  const [valids, setValids] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [forceEmailInvalid, setForceEmailInvalid] = useState(null);

  const _validateEmail = (email) => {
    email = email.trim();
    const valid = isEmailValid(email);
    setValids({ ...valids, email: valid });
    setEmail(email);
    if (valid) {
      setForceEmailInvalid(null);
    }
  };

  const _validatePassword = (password) => {
    const valid = password.length > 0;
    setValids({ ...valids, password: valid });
    setPassword(password);
  };

  const _login = (ev) => {
    if (ev) ev.preventDefault();
    if (valids.email && valids.password) {
      dispatch(loginUser({ email: email, password: password }));
    }
  };

  const _reset = () => {
    dispatch(resetErrors());
    setPassword(null);
    setEmail(null);
    setValids({});
  };

  const history = useHistory();

  useEffect(() => {
    if (userToken) {
      _reset();
      dispatch(getUser());
      history.replace('/games');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userToken]);

  const loginDisabled = !valids.email || !valids.password;

  return (
    <Wrapper>
      <Col
        style={{
          maxWidth: '520px',
          width: '100%',
          margin: '0 auto',
          justifyContent: 'flex-start',
          flex: 0,
          padding: '0 var(--space-xs)',
        }}
      >
        <BetstampWordWhiteText height={128} />
        <form onSubmit={_login} style={{ width: '100%' }}>
          <AuthTextInput
            label="Email *"
            leftIcon={IoMailOutline}
            rightIcon={valids.email ? IoCheckmarkCircleOutline : null}
            rightIconColor={'var(--color-success)'}
            errorText={forceEmailInvalid}
            placeholder={'yourname@gmail.com'}
            textContentType={'emailAddress'}
            onChangeText={(text) => _validateEmail(text)}
            onEndEditing={() => {
              if (!valids.email) setForceEmailInvalid('Invalid email');
            }}
            value={email}
            autoFocus={true}
            type="email"
          />
          <AuthTextInput
            label="Password *"
            leftIcon={IoLockClosedOutline}
            rightIcon={showPassword ? IoEyeOutline : IoEyeOffOutline}
            rightIconOnPress={() => {
              if (showPassword) {
                Analytics.track(Analytics.events.HIDE_PASSWORD);
              } else {
                Analytics.track(Analytics.events.SHOW_PASSWORD);
              }
              setShowPassword(!showPassword);
            }}
            placeholder={'Password'}
            onChangeText={(text) => _validatePassword(text)}
            value={password}
            type={showPassword ? 'text' : 'password'}
          />
          {apiError ? (
            <ErrorDisplay
              error={apiError}
              basic={true}
              containerStyle={{
                textAlign: 'center',
                padding: 'var(--space-xs)',
                backgroundColor: 'var(--color-fg)',
                borderRadius: 'var(--std-border-radius)',
              }}
            />
          ) : (
            <div style={{ padding: 'var(--space-md)' }} />
          )}
          <AuthButton
            rightIcon={IoArrowForwardCircle}
            disabled={loginDisabled}
            isLoading={isLoading}
            onPress={_login}
            colorTheme="white"
          >
            Login
          </AuthButton>
        </form>
        <Link
          to="/forgot-password"
          style={{ textDecoration: 'none', width: '100%' }}
          onClick={() => dispatch(resetErrors())}
        >
          <AuthButton
            colorTheme="inverted"
            btnTheme="borderless"
            onPress={() => _validatePassword('')}
          >
            I forgot my password
          </AuthButton>
        </Link>
        <Link
          to="/signup"
          style={{ textDecoration: 'none', width: '100%' }}
          onClick={() => dispatch(resetErrors())}
        >
          <AuthButton
            colorTheme="inverted"
            containerStyle={{ marginTop: 'var(--space-xxl)' }}
            onPress={() => _reset()}
          >
            Signup Here
          </AuthButton>
        </Link>
      </Col>
    </Wrapper>
  );
}
