import {
  CREATE_USER,
  CREATE_USER_SUCCESS,
  CREATE_USER_FAILURE,
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAILURE,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  LOGOUT_USER_FAILURE,
  LOGOUT_ALL,
  LOGOUT_ALL_SUCCESS,
  LOGOUT_ALL_FAILURE,
  DEACTIVATE_USER,
  DEACTIVATE_USER_SUCCESS,
  DEACTIVATE_USER_FAILURE,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILURE,
  SEND_EMAIL_VERIFICATION,
  SEND_EMAIL_VERIFICATION_COMPLETE,
  CHECK_EMAIL_AVAILABLE,
  CHECK_EMAIL_AVAILABLE_SUCCESS,
  CHECK_EMAIL_AVAILABLE_FAILURE,
  CHECK_USERNAME_AVAILABLE,
  CHECK_USERNAME_AVAILABLE_SUCCESS,
  CHECK_USERNAME_AVAILABLE_FAILURE,
  RESET_ERRORS,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE,
  GET_USER,
  GET_USER_SUCCESS,
  GET_USER_FAILURE,
  ADD_PUSH_TOKEN,
  ADD_PUSH_TOKEN_SUCCESS,
  ADD_PUSH_TOKEN_FAILURE,
  ADD_PACKAGE,
  ADD_PACKAGE_SUCCESS,
  ADD_PACKAGE_FAILURE,
  RESET_ADD_PACKAGE_SUCCESS,
  UPDATE_NOTIFICATION_PREFERENCES,
  UPDATE_NOTIFICATION_PREFERENCES_SUCCESS,
  UPDATE_NOTIFICATION_PREFERENCES_FAILURE,
  RESET_TOKEN,
  UPDATE_PACKAGE,
  UPDATE_PACKAGE_SUCCESS,
  UPDATE_PACKAGE_FAILURE,
  DELETE_PACKAGE,
  DELETE_PACKAGE_SUCCESS,
  DELETE_PACKAGE_FAILURE,
  GET_ALL_BOOKS,
  GET_ALL_BOOKS_SUCCESS,
  GET_ALL_BOOKS_FAILURE,
  POST_BOOKS,
  POST_BOOKS_SUCCESS,
  POST_BOOKS_FAILURE,
  CREATE_CONNECT_STATE,
  CREATE_CONNECT_STATE_SUCCESS,
  CREATE_CONNECT_STATE_FAILURE,
  VERIFY_CONNECT_ACCOUNT,
  VERIFY_CONNECT_ACCOUNT_SUCCESS,
  VERIFY_CONNECT_ACCOUNT_FAILURE,
  RESET_VERIFY_CONNECT_ACCOUNT_SUCCESS,
  SET_SEEN_ONBOARDING,
  GET_STRIPE_EXPRESS_DASHBOARD_URL,
  GET_STRIPE_EXPRESS_DASHBOARD_URL_SUCCESS,
  GET_STRIPE_EXPRESS_DASHBOARD_URL_FAILURE,
  SET_USER_CHANGES,
  SET_PROFILE_SETUP_PAGE,
  CHECK_REFERRAL_CODE_EXISTS,
  CHECK_REFERRAL_CODE_EXISTS_SUCCESS,
  CHECK_REFERRAL_CODE_EXISTS_FAILURE,
  CHANGE_EMAIL,
  CHANGE_EMAIL_SUCCESS,
  CHANGE_EMAIL_FAILURE,
  SOCIAL_LOGIN_USER,
  SOCIAL_LOGIN_USER_SUCCESS,
  SOCIAL_LOGIN_USER_FAILURE,
  SOCIAL_CONNECT_USER,
  SOCIAL_CONNECT_USER_SUCCESS,
  SOCIAL_CONNECT_USER_FAILURE,
  SOCIAL_DISCONNECT_USER,
  SOCIAL_DISCONNECT_USER_SUCCESS,
  SOCIAL_DISCONNECT_USER_FAILURE,
  GET_TWITTER_REQUEST_TOKEN,
  GET_TWITTER_REQUEST_TOKEN_SUCCESS,
  GET_TWITTER_REQUEST_TOKEN_FAILURE,
  GET_TWITTER_ACCESS_TOKEN,
  GET_TWITTER_ACCESS_TOKEN_SUCCESS,
  GET_TWITTER_ACCESS_TOKEN_FAILURE,
  SET_TEMP_USER_STATES,
  GET_ALL_BOOKS_MAP_SUCCESS,
} from 'actions';

const initialState = {
  // things to persist
  userToken: null,
  odds_preference: 0,
  // don't persist
  user: null,
  tempUser: {
    email: null,
    username: null,
    password: null,
    state: null,
    experience_level: null,
    referral_code: null,
    isBookBannerPreloaded: false,
    linkBooksImgLoaded: false,
    meantimeImgLoaded: false,
    oddsCompareImgLoaded: false,
    forceLink: false,
    selectedBooks: {},
  },
  isLoading: false,
  isLoadingLogout: false,
  isLoadingLogoutAll: false,
  isLoadingDeactivate: false,
  apiError: null,
  createUserSuccess: null,
  updateUserSuccess: null,
  addPackageSuccess: null,
  updatePackageSuccess: null,
  deletePackageSuccess: null,
  emailIsAvailable: null,
  usernameIsAvailable: '',
  purchases: [],
  allBooks: [],
  allBooksMap: {},
  isLoadingAllBooks: false,
  allBooksError: null,
  isPostingBooks: false,
  postBooksError: null,
  connectState: null,
  connectStateLoading: null,
  verifyConnectLoading: null,
  verifyConnectSuccess: null,
  verifyConnectError: null,
  seenOnboarding: false,
  deletePackageError: null,
  forgotPasswordSuccess: null,
  checkingReferralCode: false,
  referralCodeExists: null,
  checkReferralCodeFailure: null,
  isDeletingPackage: false,
  isUpdatingPackage: false,
  isCreatingUser: false,
  createUserFailure: null,
  // social login/signup
  isSocialAuthingUser: false,
  socialLoginSuccess: null,
  socialLoginFailure: null,
  socialConnectSuccess: null,
  socialConnectFailure: null,
  isSocialDisconnectingUser: false,
  socialDisconnectSuccess: null,
  socialDisconnectFailure: null,
  isGettingTwitterRequestToken: false,
  twitterRequestToken: null,
  twitterRequestTokenError: null,
  isGettingTwitterAccessToken: false,
  twitterAccessToken: null,
  twitterAccessTokenError: null,
  // editing profile
  userChanges: {},
  profileSetupPage: 0,
  changingPassword: false,
  changePasswordError: null,
  passwordChangeSuccess: false,
  changingEmail: false,
  changeEmailError: null,
  emailChangeSuccess: null,

  userLoginSuccess: null,
};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case CREATE_USER:
      return Object.assign({}, state, {
        ...state,
        createUserFailure: null,
        createUserSuccess: false,
        isCreatingUser: true,
      });
    case CREATE_USER_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        user: action.payload.response,
        userToken: action.payload.response.token,
        createUserFailure: null,
        createUserSuccess: true,
        isCreatingUser: false,
        referralCodeExists: null,
      });
    case CREATE_USER_FAILURE:
      return Object.assign({}, state, {
        ...state,
        createUserFailure: action.payload,
        createUserSuccess: false,
        isCreatingUser: false,
      });

    case LOGIN_USER:
      return Object.assign({}, state, {
        ...state,
        isLoading: true,
        apiError: null,
        createUserSuccess: null,
        socialLoginFailure: null,
      });
    case LOGIN_USER_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        userToken: action.payload.response.token,
        user: action.payload.response.user,
        apiError: null,
        isLoading: false,
        userLoginSuccess: true,
      });
    case LOGIN_USER_FAILURE:
      return Object.assign({}, state, {
        ...state,
        apiError: action.payload,
        isLoading: false,
      });

    case LOGOUT_USER:
      return Object.assign({}, state, {
        ...state,
        isLoadingLogout: true,
        apiError: null,
      });
    case LOGOUT_USER_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        user: null,
        userToken: null,
        isLoadingLogout: false,
        apiError: null,
        createUserSuccess: null,
        socialLoginSuccess: null,
        twitterRequestToken: null,
        twitterRequestTokenError: null,
        isGettingTwitterAccessToken: false,
        twitterAccessToken: null,
        twitterAccessTokenError: null,
      });
    case LOGOUT_USER_FAILURE:
      return Object.assign({}, state, {
        ...state,
        apiError: action.payload,
        isLoadingLogout: false,
      });

    case DEACTIVATE_USER:
      return Object.assign({}, state, {
        ...state,
        isLoadingDeactivate: true,
        apiError: null,
      });
    case DEACTIVATE_USER_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        user: null,
        userToken: null,
        isLoadingDeactivate: false,
        apiError: null,
      });
    case DEACTIVATE_USER_FAILURE:
      return Object.assign({}, state, {
        ...state,
        isLoadingDeactivate: false,
        apiError: action.payload,
      });

    case LOGOUT_ALL:
      return Object.assign({}, state, {
        ...state,
        isLoadingLogoutAll: true,
        apiError: null,
      });
    case LOGOUT_ALL_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        user: null,
        userToken: null,
        isLoadingLogoutAll: false,
        apiError: null,
      });
    case LOGOUT_ALL_FAILURE:
      return Object.assign({}, state, {
        ...state,
        apiError: action.payload,
        isLoadingLogoutAll: false,
      });

    case FORGOT_PASSWORD:
      return Object.assign({}, state, {
        ...state,
        apiError: null,
        isLoading: true,
        forgotPasswordSuccess: null,
        socialLoginFailure: null,
      });
    case FORGOT_PASSWORD_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        apiError: null,
        isLoading: false,
        forgotPasswordSuccess: true,
      });
    case FORGOT_PASSWORD_FAILURE:
      return Object.assign({}, state, {
        ...state,
        apiError: action.payload,
        isLoading: false,
        forgotPasswordSuccess: false,
      });

    case CHANGE_PASSWORD:
      return Object.assign({}, state, {
        ...state,
        changePasswordError: null,
        changingPassword: true,
        passwordChangeSuccess: null,
      });
    case CHANGE_PASSWORD_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        changingPassword: false,
        changePasswordError: null,
        passwordChangeSuccess: true,
      });
    case CHANGE_PASSWORD_FAILURE:
      return Object.assign({}, state, {
        ...state,
        changingPassword: false,
        changePasswordError: action.payload,
        passwordChangeSuccess: false,
      });

    case SEND_EMAIL_VERIFICATION:
      return Object.assign({}, state, {
        ...state,
        isLoading: true,
      });
    case SEND_EMAIL_VERIFICATION_COMPLETE:
      return Object.assign({}, state, {
        ...state,
        isLoading: false,
      });

    case CHECK_EMAIL_AVAILABLE:
      return Object.assign({}, state, {
        ...state,
        emailIsAvailable: null,
      });
    case CHECK_EMAIL_AVAILABLE_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        emailIsAvailable: action.payload.response.available,
      });
    case CHECK_EMAIL_AVAILABLE_FAILURE:
      return Object.assign({}, state, {
        ...state,
        emailIsAvailable: action.payload.response
          ? action.payload.response.available
          : 'Error occurred',
      });

    case CHECK_USERNAME_AVAILABLE:
      return Object.assign({}, state, {
        ...state,
        usernameIsAvailable: '',
      });
    case CHECK_USERNAME_AVAILABLE_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        usernameIsAvailable: action.payload.response.available,
      });
    case CHECK_USERNAME_AVAILABLE_FAILURE:
      return Object.assign({}, state, {
        ...state,
        usernameIsAvailable: action.payload.response
          ? action.payload.response.available
          : action.payload.error,
      });

    case RESET_ERRORS:
      return Object.assign({}, state, {
        ...state,
        apiError: null,
        emailIsAvailable: null,
        usernameIsAvailable: '',
        forgotPasswordSuccess: null,
        passwordChangeSuccess: null,
        changePasswordError: null,
        changeEmailError: null,
        emailChangeSuccess: null,
        userChanges: {},
        updateUserSuccess: null,
        updateUserLoading: null,
        createUserFailure: null,
        socialLoginSuccess: null,
        socialLoginFailure: null,
        referralCodeExists: null,
        socialDisconnectSuccess: null,
        twitterRequestToken: null,
        twitterRequestTokenError: null,
        isGettingTwitterAccessToken: false,
        twitterAccessToken: null,
        twitterAccessTokenError: null,
      });

    case UPDATE_USER:
      let op = state.odds_preference;
      if (action.params.odds_preference !== undefined) {
        op = action.params.odds_preference;
      }
      return Object.assign({}, state, {
        ...state,
        apiError: null,
        updateUserSuccess: null,
        updateUserLoading: true,
        odds_preference: op,
      });
    case UPDATE_USER_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        user: action.payload.response,
        updateUserSuccess: true,
        updateUserLoading: false,
        userChanges: {},
      });
    case UPDATE_USER_FAILURE:
      return Object.assign({}, state, {
        ...state,
        apiError: action.payload._response,
        updateUserLoading: false,
      });

    case GET_USER:
      return Object.assign({}, state, {
        ...state,
        isLoading: true,
        apiError: null,
        deletePackageError: null,
      });
    case GET_USER_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        user: action.payload,
        isLoading: false,
      });
    case GET_USER_FAILURE:
      return Object.assign({}, state, {
        ...state,
        apiError: action.payload._response
          ? action.payload._response
          : action.payload,
        isLoading: false,
      });

    case ADD_PUSH_TOKEN:
      return Object.assign({}, state, {
        ...state,
        apiError: null,
      });
    case ADD_PUSH_TOKEN_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        user: { ...state.user, push_token: action.payload.response.token },
      });
    case ADD_PUSH_TOKEN_FAILURE:
      return Object.assign({}, state, {
        ...state,
        apiError: action.payload._response,
      });

    case ADD_PACKAGE:
      return Object.assign({}, state, {
        ...state,
        isCreatingPackage: true,
        apiError: null,
      });
    case ADD_PACKAGE_SUCCESS:
      state.user.packages.push(action.payload.response);
      return Object.assign({}, state, {
        ...state,
        addPackageSuccess: true,
        isCreatingPackage: false,
      });
    case ADD_PACKAGE_FAILURE:
      return Object.assign({}, state, {
        ...state,
        apiError: action.payload._response
          ? action.payload._response
          : action.payload,
        isCreatingPackage: false,
      });
    case RESET_ADD_PACKAGE_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        apiError: null,
        addPackageSuccess: null,
        updatePackageSuccess: null,
        isCreatingPackage: false,
        deletePackageSuccess: null,
        isUpdatingPackage: false,
      });

    case UPDATE_NOTIFICATION_PREFERENCES:
      return Object.assign({}, state, {
        ...state,
        updateUserLoading: true,
        user: {
          ...state.user,
          notification_settings: {
            ...state.user.notification_settings,
            ...action.params,
          },
        },
        apiError: null,
      });
    case UPDATE_NOTIFICATION_PREFERENCES_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        updateUserLoading: false,
        user: { ...state.user, notification_settings: action.payload.response },
      });
    case UPDATE_NOTIFICATION_PREFERENCES_FAILURE:
      return Object.assign({}, state, {
        ...state,
        apiError: action.payload._response,
        updateUserLoading: false,
      });

    case RESET_TOKEN:
      return Object.assign({}, state, {
        ...state,
        userToken: null,
        user: null,
        apiError: null,
      });

    case UPDATE_PACKAGE:
      return Object.assign({}, state, {
        ...state,
        isUpdatingPackage: true,
        apiError: null,
      });
    case UPDATE_PACKAGE_SUCCESS:
      const up_newPackages = state.user.packages.map(p => {
        if (p.id === action.payload.id) {
          return action.payload;
        } else return p;
      });
      const up_user = { ...state.user, packages: up_newPackages };
      return Object.assign({}, state, {
        ...state,
        updatePackageSuccess: true,
        user: up_user,
        isUpdatingPackage: false,
      });
    case UPDATE_PACKAGE_FAILURE:
      return Object.assign({}, state, {
        ...state,
        apiError: action.error,
        isUpdatingPackage: false,
      });

    case DELETE_PACKAGE:
      return Object.assign({}, state, {
        ...state,
        isDeletingPackage: true,
        deletePackageError: null,
      });
    case DELETE_PACKAGE_SUCCESS:
      const dp_newPackages = state.user.packages.filter(
        p => p.id !== action.packageid
      );
      const dp_user = { ...state.user, packages: dp_newPackages };
      return Object.assign({}, state, {
        ...state,
        deletePackageSuccess: true,
        isDeletingPackage: false,
        user: dp_user,
        deletePackageError: null,
      });
    case DELETE_PACKAGE_FAILURE:
      return Object.assign({}, state, {
        ...state,
        isDeletingPackage: false,
        deletePackageError: action.error,
      });

    case GET_ALL_BOOKS:
      return Object.assign({}, state, {
        ...state,
        isLoadingAllBooks: true,
        allBooksError: null,
      });
    case GET_ALL_BOOKS_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        isLoadingAllBooks: false,
        allBooksError: null,
        allBooks: action.payload,
      });
    case GET_ALL_BOOKS_FAILURE:
      return Object.assign({}, state, {
        ...state,
        isLoadingAllBooks: false,
        allBooksError: action.error,
      });

    case POST_BOOKS:
      return Object.assign({}, state, {
        ...state,
        isPostingBooks: true,
        postBooksError: null,
      });
    case POST_BOOKS_SUCCESS:
      const pb_user = { ...state.user, books: action.payload.response.books };
      return Object.assign({}, state, {
        ...state,
        isPostingBooks: false,
        postBooksError: null,
        user: pb_user,
      });
    case POST_BOOKS_FAILURE:
      return Object.assign({}, state, {
        ...state,
        isPostingBooks: false,
        postBooksError: action.error,
      });

    case CREATE_CONNECT_STATE:
      return Object.assign({}, state, {
        ...state,
        connectStateLoading: true,
        connectState: null,
      });
    case CREATE_CONNECT_STATE_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        connectStateLoading: false,
        connectState: action.payload.response.connect_state,
      });
    case CREATE_CONNECT_STATE_FAILURE:
      return Object.assign({}, state, {
        ...state,
        connectStateLoading: false,
      });

    case VERIFY_CONNECT_ACCOUNT:
      return Object.assign({}, state, {
        ...state,
        verifyConnectLoading: true,
        verifyConnectSuccess: false,
      });
    case VERIFY_CONNECT_ACCOUNT_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        verifyConnectLoading: false,
        verifyConnectSuccess: true,
        user: {
          ...state.user,
          stripe_connect_id: action.payload.stripe_connect_id,
        },
      });
    case VERIFY_CONNECT_ACCOUNT_FAILURE:
      return Object.assign({}, state, {
        ...state,
        verifyConnectLoading: false,
        verifyConnectSuccess: false,
        verifyConnectError: action.error,
      });
    case RESET_VERIFY_CONNECT_ACCOUNT_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        verifyConnectLoading: null,
        verifyConnectSuccess: null,
        verifyConnectError: null,
      });

    case SET_SEEN_ONBOARDING:
      return Object.assign({}, state, {
        ...state,
        seenOnboarding: action.seenOnboarding,
      });

    case GET_STRIPE_EXPRESS_DASHBOARD_URL:
      return Object.assign({}, state, {
        ...state,
        expressDashboardLoading: true,
        expressDashboardURL: null,
        expressDashboardError: null,
      });
    case GET_STRIPE_EXPRESS_DASHBOARD_URL_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        expressDashboardLoading: false,
        expressDashboardURL: action.payload.url,
        expressDashboardError: null,
      });
    case GET_STRIPE_EXPRESS_DASHBOARD_URL_FAILURE:
      return Object.assign({}, state, {
        ...state,
        expressDashboardLoading: false,
        expressDashboardURL: null,
        expressDashboardError: action.error,
      });

    case SET_USER_CHANGES:
      return Object.assign({}, state, {
        ...state,
        userChanges: action.changes,
      });

    case SET_PROFILE_SETUP_PAGE:
      return Object.assign({}, state, {
        ...state,
        profileSetupPage: action.page,
      });

    case CHECK_REFERRAL_CODE_EXISTS:
      return Object.assign({}, state, {
        ...state,
        checkingReferralCode: true,
        checkReferralCodeFailure: null,
        referralCodeExists: null,
      });
    case CHECK_REFERRAL_CODE_EXISTS_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        checkingReferralCode: false,
        checkReferralCodeFailure: null,
        referralCodeExists: action.payload.exists,
      });
    case CHECK_REFERRAL_CODE_EXISTS_FAILURE:
      return Object.assign({}, state, {
        ...state,
        checkingReferralCode: false,
        checkReferralCodeFailure: action.error,
        referralCodeExists: null,
      });

    case CHANGE_EMAIL:
      return Object.assign({}, state, {
        ...state,
        changeEmailError: null,
        changingEmail: true,
        emailChangeSuccess: null,
      });
    case CHANGE_EMAIL_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        user: action.payload.response,
        changingEmail: false,
        changeEmailError: null,
        emailChangeSuccess: true,
      });
    case CHANGE_EMAIL_FAILURE:
      return Object.assign({}, state, {
        ...state,
        changingEmail: false,
        changeEmailError: action.payload,
        emailChangeSuccess: false,
      });

    case SOCIAL_LOGIN_USER:
      return Object.assign({}, state, {
        ...state,
        isSocialAuthingUser: true,
        socialLoginSuccess: null,
        socialLoginFailure: null,
        createUserSuccess: null,
      });
    case SOCIAL_LOGIN_USER_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        isSocialAuthingUser: false,
        userToken: action.payload.response.token,
        user: action.payload.response.user,
        socialLoginSuccess: true,
        socialLoginFailure: null,
        twitterAccessToken: null,
        twitterRequestToken: null,
        referralCodeExists: null,
      });
    case SOCIAL_LOGIN_USER_FAILURE:
      return Object.assign({}, state, {
        ...state,
        isSocialAuthingUser: false,
        socialLoginFailure: action.error,
        twitterAccessToken: null,
        twitterRequestToken: null,
      });
    case SOCIAL_CONNECT_USER:
      return Object.assign({}, state, {
        ...state,
        isSocialAuthingUser: true,
        socialConnectSuccess: null,
        socialConnectFailure: null,
        createUserSuccess: null,
      });
    case SOCIAL_CONNECT_USER_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        isSocialAuthingUser: false,
        userToken: action.payload.response.token,
        user: action.payload.response.user,
        socialConnectSuccess: true,
        socialConnectFailure: null,
        createUserSuccess: true,
        twitterAccessToken: null,
        twitterRequestToken: null,
      });
    case SOCIAL_CONNECT_USER_FAILURE:
      return Object.assign({}, state, {
        ...state,
        isSocialAuthingUser: false,
        socialConnectFailure: action.error,
        createUserSuccess: null,
        twitterAccessToken: null,
        twitterRequestToken: null,
      });
    case SOCIAL_DISCONNECT_USER:
      return Object.assign({}, state, {
        ...state,
        isSocialDisconnectingUser: true,
        socialDisconnectSuccess: null,
        socialDisconnectFailure: null,
        createUserSuccess: null,
      });
    case SOCIAL_DISCONNECT_USER_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        isSocialAuthingUser: false,
        isSocialDisconnectingUser: false,
        socialDisconnectSuccess: true,
        socialDisconnectFailure: null,
      });
    case SOCIAL_DISCONNECT_USER_FAILURE:
      return Object.assign({}, state, {
        ...state,
        isSocialDisconnectingUser: false,
        socialDisconnectFailure: action.error,
        createUserSuccess: null,
      });
    case GET_TWITTER_REQUEST_TOKEN:
      return Object.assign({}, state, {
        ...state,
        isGettingTwitterRequestToken: true,
        twitterRequestToken: null,
        twitterRequestTokenError: null,
        socialLoginFailure: null,
      });
    case GET_TWITTER_REQUEST_TOKEN_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        isGettingTwitterRequestToken: false,
        twitterRequestToken: action.payload.token,
        twitterRequestTokenError: null,
      });
    case GET_TWITTER_REQUEST_TOKEN_FAILURE:
      return Object.assign({}, state, {
        ...state,
        isGettingTwitterRequestToken: false,
        twitterRequestToken: null,
        twitterRequestTokenError: action.error,
      });

    case GET_TWITTER_ACCESS_TOKEN:
      return Object.assign({}, state, {
        ...state,
        isGettingTwitterAccessToken: true,
        twitterRequestToken: null,
        twitterRequestTokenError: null,
      });
    case GET_TWITTER_ACCESS_TOKEN_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        isGettingTwitterAccessToken: false,
        twitterAccessToken: action.payload.token,
        twitterAccessTokenError: null,
      });
    case GET_TWITTER_ACCESS_TOKEN_FAILURE:
      return Object.assign({}, state, {
        ...state,
        isGettingTwitterAccessToken: false,
        twitterAccessToken: null,
        twitterAccessTokenError: action.error,
      });
    case SET_TEMP_USER_STATES:
      return {
        ...state,
        tempUser: action.payload,
      };

    case GET_ALL_BOOKS_MAP_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        allBooksMap: action.payload,
      });

    default:
      return state;
  }
}
