import React, { useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import {
  VictoryChart,
  VictoryAxis,
  VictoryVoronoiContainer,
  VictoryTooltip,
  VictoryLine,
  VictoryPie,
} from 'victory';
import moment from 'moment';
import { IoAlertCircleOutline, IoRefresh } from 'react-icons/io5';

// components
import StaffNavBar from 'components/nav/StaffNavBar';
import { Row, Col } from 'components/generic/Layout';
import Select from 'components/generic/Select';
import BooksDropdown from 'components/BooksDropdown';
import { AuthTextInput } from 'components/AuthTextInput';
import { AuthButton, IconButton } from 'components/AuthButton';
import ActivityIndicator from 'components/generic/ActivityIndicator';
import DateRange from 'components/generic/DateRange';
import CustomSelect from 'components/generic/Select';

// utils
import { STATE_OPTIONS } from 'utils';
import { useSelector } from 'react-redux';

const LINE_COLORS = [
  'purple',
  'seagreen',
  'coral',
  'chartreuse',
  'yellow',
  'slateblue',
  'red',
  'blueviolet',
  'cyan',
];

const MAX_PIE = 8;

const getUnusedColor = idx => {
  if (idx >= LINE_COLORS.length) {
    return LINE_COLORS[idx % (LINE_COLORS.length - 1)];
  } else {
    return LINE_COLORS[idx];
  }
};

export default function AffiliateStatsDashboard(props) {
  const [filters, setFilters] = useState({
    book_ids: [],
    states: [],
    ref_code: '',
    group: '',
    from_page: '',
    from_date: moment().subtract(1, 'month').startOf('month').toISOString(),
    to_date: moment().startOf('month').subtract(1, 'day').toISOString(),
  });
  const [filtersToBe, setFiltersToBe] = useState({
    book_ids: [],
    states: [],
    ref_code: '',
    group: '',
    from_page: '',
    from_date: moment().subtract(1, 'month').startOf('month').toISOString(),
    to_date: moment().startOf('month').subtract(1, 'day').toISOString(),
  });
  const [lineFilter, setLineFilter] = useState({
    filter: 'BOOK',
    linesToShow: 'SHOW_ALL',
  });

  const fetchMarketingGroups = useQuery({
    refetchOnWindowFocus: false,
    queryKey: [
      {
        endpoint: `marketing-groups`,
      },
    ],
  });

  const getStats = useQuery({
    refetchOnWindowFocus: false,
    queryKey: [
      {
        endpoint: 'aff-stats-dashboard',
        urlParams: filters,
      },
    ],
    retry: false,
  });

  const getGraph = useQuery({
    refetchOnWindowFocus: false,
    queryKey: [
      {
        endpoint: 'aff-stats-graph',
        urlParams: filters,
      },
    ],
    retry: false,
  });

  // TODO set tabKey = '' on filter change
  const [tabKey, setTabKey] = useState('');
  return (
    <>
      <StaffNavBar />
      <Row style={{ height: '100%', width: '100%' }}>
        <Col
          style={{
            flex: 1,
            padding: '0 var(--space-lg)',
            height: '100%',
            justifyContent: 'flex-start',
            alignItems: 'center',
            flexFlow: 'column nowrap',
          }}
        >
          <Col
            style={{
              position: 'fixed',
              maxWidth: '25%',
            }}
          >
            <Row
              style={{
                width: '100%',
                // width: '90%',
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}
            >
              <AuthButton
                onPress={() => {
                  setFilters(filtersToBe);
                  setTabKey('');
                }}
                disabled={filters === filtersToBe}
              >
                Apply Changes
              </AuthButton>

              <IconButton
                onPress={() => {
                  getStats.refetch();
                  getGraph.refetch();
                }}
                iconName={IoRefresh}
                subtext="refresh"
              />
            </Row>
            <BooksDropdown
              admin
              isMulti
              label="Book Name:"
              placeholder="Any"
              onChange={opt => {
                let newBooks = [];
                opt.map(item => newBooks.push(item.value));
                setFiltersToBe({
                  ...filtersToBe,
                  book_ids: newBooks,
                });
              }}
            />
            <Select
              isMulti
              label="States:"
              options={STATE_OPTIONS.map(s => ({ label: s, value: s }))}
              placeholder="Any"
              onChange={opt => {
                let newStates = [];
                opt.map(item => newStates.push(item.value));
                setFiltersToBe({
                  ...filtersToBe,
                  states: newStates,
                });
              }}
            />
            {fetchMarketingGroups.isSuccess && (
              <Select
                isMulti
                label="Group:"
                options={fetchMarketingGroups.data.map(g => ({
                  value: g.id,
                  label: g.name,
                }))}
                onChange={opt => {
                  let newGroups = [];
                  opt.map(item => newGroups.push(item.value));
                  setFiltersToBe({ ...filtersToBe, group: newGroups });
                }}
              />
            )}
            <AuthTextInput
              label="Referral Code:"
              placeholder="Any"
              type="text"
              onChangeText={t =>
                setFiltersToBe({ ...filtersToBe, ref_code: t })
              }
              helpText="If referral code is different from influencer slug, enter slug in 'From Page' field below"
              containerStyle={{ margin: 'var(--space-xs) 0 0 0' }}
            />
            <AuthTextInput
              label="From Page:"
              placeholder="Any"
              type="text"
              onChangeText={t =>
                setFiltersToBe({ ...filtersToBe, from_page: t })
              }
            />
          </Col>
        </Col>
        <Col style={{ flex: 3, height: 'fit-content' }}>
          {!getStats.isLoading && !!getStats.error ? (
            !!getStats.error?.message ? (
              <Row
                style={{
                  height: '256px',
                  width: '90%',
                  alignItems: 'center',
                  justifyContent: 'center',
                  border: '2px dashed var(--color-complement)',
                  borderRadius: 12,
                  margin: 'var(--space-sm)',
                  padding: 'var(--space-md) 0',
                }}
              >
                <Col style={{ flex: 2 }}>
                  <IoAlertCircleOutline
                    size={64}
                    color={'var(--color-complement)'}
                  />
                  <h6>{getStats.error.message}</h6>
                </Col>
              </Row>
            ) : (
              <Col>
                <h4 style={{ color: 'var(--color-danger)' }}>
                  Error getting Affiliate click data.
                </h4>
              </Col>
            )
          ) : (
            <Col
              style={{
                width: '100%',
                marginTop: 'var(--space-sm)',
                justifyContent: 'flex-start',
                borderLeft: '2px solid var(--color-text-light)',
              }}
            >
              <Col
                style={{
                  width: '100%',
                  borderBottom: '1px solid var(--color-text-light)',
                  flexFlow: 'column nowrap',
                }}
              >
                <Row
                  style={{
                    flex: 1,
                    width: '60%',
                    height: '100%',
                    padding: '0 var(--space-xs)',
                    justifyContent: 'flex-start',
                    flexFlow: 'row nowrap',
                  }}
                >
                  <Row style={{ margin: 'var(--space-xxs) var(--space-md)' }}>
                    <CustomSelect
                      placeholder={'Show All'}
                      options={[
                        { label: 'Show All', value: 'SHOW_ALL' },
                        { label: 'Show Only Total', value: 'ONLY_TOTAL' },
                        {
                          label: 'Show Only Breakdown',
                          value: 'ONLY_SUB_LINES',
                        },
                      ]}
                      onChange={opt => {
                        setLineFilter({
                          ...lineFilter,
                          linesToShow: opt.value,
                        });
                      }}
                    />
                  </Row>
                  <Row style={{ margin: 'var(--space-xxs) var(--space-md)' }}>
                    <CustomSelect
                      placeholder={'By Book'}
                      options={[
                        { label: 'By Book', value: 'BOOK' },
                        { label: 'By State', value: 'STATE' },
                      ]}
                      onChange={opt => {
                        setLineFilter({ ...lineFilter, filter: opt.value });
                      }}
                    />
                  </Row>
                </Row>
                <Row
                  style={{
                    flex: 3,
                    height: '100%',
                    width: '100%',
                    justifyContent: 'flex-start',
                  }}
                >
                  <DateRange
                    containerStyle={{
                      width: '100%',
                      margin: 0,
                      flexFlow: 'row nowrap',
                      padding: 'var(--space-sm) 0',
                    }}
                    getItemsInRange={(date1, date2) => {
                      let from_date = '';
                      let to_date = '';
                      if (date1 !== undefined) {
                        from_date = date1.toISOString();
                      }
                      if (date2 !== undefined) {
                        to_date = date2.toISOString();
                      }
                      setFiltersToBe({
                        ...filtersToBe,
                        from_date: from_date,
                        to_date: to_date,
                      });
                    }}
                    defaultStartDate={
                      filtersToBe.from_date === ''
                        ? null
                        : filtersToBe.from_date
                    }
                    defaultEndDate={
                      filtersToBe.to_date === '' ? null : filtersToBe.to_date
                    }
                    placeholder={
                      filtersToBe.from_date === '' && filtersToBe.to_date === ''
                        ? 'All Time'
                        : 'Previous Month'
                    }
                  />
                </Row>
              </Col>

              {(!!getStats.isLoading || !!getGraph.isLoading) && (
                <Col
                  style={{
                    width: '100%',
                    borderBottom: '1px solid var(--color-text-light)',
                    marginBottom: 'var(--space-md)',
                  }}
                >
                  <ActivityIndicator size={2} />
                </Col>
              )}
              {!!getGraph.data && (
                <Row
                  style={{
                    width: '100%',
                    minHeight: '270px',
                  }}
                >
                  <AffGraph
                    data={getGraph.data}
                    lineFilter={lineFilter}
                    currentFilters={filters}
                  />
                </Row>
              )}
              {!getStats.isLoading && !!getStats.data && (
                <Tabs
                  tabsContent={Object.entries(getStats.data)}
                  onChangeTab={dataKey => {
                    setTabKey(dataKey);
                  }}
                  tabKey={
                    tabKey === '' ? Object.keys(getStats.data)[0] : tabKey
                  }
                />
              )}
              <Col
                style={{
                  width: '100%',
                  alignItems: 'center',
                  padding: '0 var(--space-xs)',
                }}
              >
                {!!getStats.data &&
                  Object.entries(
                    getStats.data[
                      tabKey === '' ? Object.keys(getStats.data)[0] : tabKey
                    ]
                  ).map(([key, value], _key) => (
                    <SingleStat key={_key} dataKey={key} value={value} />
                  ))}
              </Col>
            </Col>
          )}
        </Col>
      </Row>
    </>
  );
}

function SingleStat(props) {
  const { dataKey, value } = props;
  const isRanking = Array.isArray(value);
  if (isRanking && value.length === 0) return props.children;
  if (value === 0) return props.children;
  return (
    <Row
      style={{
        width: '100%',
        height: 'fit-content',
        justifyContent: 'center',
        alignItems: 'flex-start',
        padding: '0 var(--space-xs)',
        margin: 'var(--space-xs)',
        flexFlow: 'row nowrap',
      }}
    >
      <Row
        style={{
          maxWidth: '60%',
          justifyContent: 'space-between',
          alignItems: isRanking ? 'flex-start' : 'center',
          border: '1px solid var(--color-text-light)',
          borderRadius: 'var(--std-border-radius)',
          padding: 'var(--space-xs)',
          backgroundColor: 'var(--color-fg)',
          maxHeight: '412px',
        }}
      >
        <Col
          style={{
            flex: 1,
            alignItems: 'flex-start',
          }}
        >
          <p
            style={{
              width: 'fit-content',
              height: 'fit-content',
              margin: 'var(--space-xxs)',
              fontSize: 'var(--text-md)',
            }}
          >
            {dataKey.toString().split('_').join(' ')}
          </p>
          {isRanking && value.length > 1 && (
            <Col style={{ maxWidth: '80%', margin: 'var(--space-sm)' }}>
              <VictoryPie
                labels={() => {}}
                data={value.slice(0, MAX_PIE).map(([key, val], _key) => {
                  return { x: key, y: val };
                })}
                padAngle={2}
                innerRadius={100}
                colorScale={LINE_COLORS}
              />
              {value.length > 8 && (
                <i
                  style={{
                    fontSize: 'var(--text-xs)',
                    color: 'var(--color-text-light)',
                    margin: 0,
                    padding: 0,
                    alignSelf: 'left',
                  }}
                >
                  Only top 8 values charted
                </i>
              )}
            </Col>
          )}
        </Col>
        <Col
          style={{
            flex: 1,
            alignItems: isRanking ? 'flex-start' : 'flex-end',
            padding: 'var(--space-xs)',
            height: '100%',
            borderLeft: isRanking && '1px solid var(--color-text-light)',
          }}
        >
          {isRanking ? (
            <ExpandList items={value} />
          ) : (
            <p
              style={{
                width: 'fit-content',
                height: 'fit-content',
                marginTop: 'var(--space-xxs)',
                fontSize: 'var(--text-md)',
              }}
            >
              <strong>{value}</strong>
            </p>
          )}
        </Col>
      </Row>
    </Row>
  );
}

function Tabs(props) {
  const { tabsContent, tabKey } = props;

  return (
    <>
      <Row
        style={{
          width: '100%',
          borderTop: '1px solid var(--color-fg)',
          borderBottom: '1px solid var(--color-fg)',
          justifyContent: 'flex-start',
          padding: '0 var(--space-xs)',
          marginBottom: 'var(--space-lg)',
        }}
      >
        {tabsContent.map(([tabName, tabVal], _key) => {
          if (Object.keys(tabVal).length !== 0) {
            return (
              <AuthButton
                key={`tab-item-${_key}`}
                onPress={() => {
                  props.onChangeTab(tabName);
                }}
                colorTheme={tabKey === tabName && 'inverted'}
                containerStyle={{
                  maxWidth: 'fit-content',
                  margin: 'var(--space-xs)',
                }}
              >
                <p
                  style={{
                    fontSize: 'var(--text-md)',
                    borderRadius: 'var(--std-border-radius)',
                    margin: 0,
                  }}
                >
                  {tabName.split('_').join(' ')}
                </p>
              </AuthButton>
            );
          }
        })}
      </Row>
    </>
  );
}

function ExpandList(props) {
  const { items } = props;
  return (
    <Col
      style={{
        width: '100%',
        maxHeight: '356px',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        overflow: 'auto',
        flexFlow: 'column nowrap',
        scrollBehaviour: 'contain',
      }}
    >
      {items.map(([key, val], _key) => (
        <p
          key={_key}
          style={{
            fontSize: 'var(--text-sm)',
            marginTop: 'var(--space-xs)',
            textAlign: 'left',
            paddingLeft: 'var(--space-xs)',
            borderLeft: _key < MAX_PIE && `4px solid ${LINE_COLORS[_key]}`,
          }}
        >
          {!key ? 'None' : key} <strong>({val})</strong>
        </p>
      ))}
    </Col>
  );
}

function AffGraph(props) {
  const reduxProps = useSelector(state => ({
    allBooksMap: state.authReducer.allBooksMap,
  }));
  const { allBooksMap } = reduxProps;
  const { data, lineFilter, currentFilters } = props;
  const { filter, linesToShow } = lineFilter;
  const _sortData = data => {
    let sortedBooks = { all: [...data] };
    let sortedStates = { all: [...data] };
    if (!!data) {
      data.forEach(item => {
        // by book
        if (item.book__id in sortedBooks) {
          sortedBooks[item.book__id].push(item);
        } else {
          sortedBooks[item.book__id] = [item];
        }
        // by state
        if (item.state in sortedStates) {
          sortedStates[item.state].push(item);
        } else {
          sortedStates[item.state] = [item];
        }
      });
    }
    // add dummy points to all sub arrays
    Object.keys(sortedBooks).forEach(data_key =>
      sortedBooks[data_key].push({
        book__id: data_key !== 'all' ? parseInt(data_key) : null,
        created_at:
          currentFilters.to_date === ''
            ? moment().unix() * 1000
            : moment(currentFilters.to_date).unix() * 1000,
        cumulative: sortedBooks[data_key]?.at(-1)?.cumulative || 0,
        book_cumulative: sortedBooks[data_key]?.at(-1)?.book_cumulative || 0,
      })
    );
    // dummies for state
    Object.keys(sortedStates).forEach(data_key =>
      sortedStates[data_key].push({
        state: data_key !== 'all' ? data_key : null,
        created_at:
          currentFilters.to_date === ''
            ? moment().unix() * 1000
            : moment(currentFilters.to_date).unix() * 1000,
        cumulative: sortedStates[data_key]?.at(-1)?.cumulative || 0,
        state_cumulative: sortedStates[data_key]?.at(-1)?.state_cumulative || 0,
      })
    );

    return [sortedBooks, sortedStates];
  };

  const [allBookData, allStateData] = useMemo(() => _sortData(data), [data]);
  const selectedData =
    filter === 'BOOK' ? allBookData : filter === 'STATE' ? allStateData : {};

  if (!data || data.length < 2) {
    return (
      <Row
        style={{
          height: '256px',
          alignItems: 'center',
          justifyContent: 'center',
          border: '2px dashed var(--color-complement)',
          borderRadius: 12,
          margin: 'var(--space-sm)',
        }}
      >
        <Col style={{ flex: 2 }}>
          <IoAlertCircleOutline size={64} color={'var(--color-complement)'} />
          <h6>Not enough data to map graph.</h6>
        </Col>
      </Row>
    );
  }
  return (
    <div style={{ height: '40vw', width: '100%' }}>
      <VictoryChart
        containerComponent={
          <VictoryVoronoiContainer
            mouseFollowTooltips
            labels={({ datum }) => {
              if (datum.childName !== 'total-area') {
                if (filter === 'BOOK') {
                  return `book: ${
                    !!allBooksMap && !!datum.book__id
                      ? allBooksMap[datum.book__id]?.name
                      : datum.book__id
                  } \n 
                clicks: ${datum.book_cumulative} \n
                time: ${moment(datum.created_at).format('HH:mm DD/MM/YY')}
                \n`;
                } else if (filter === 'STATE') {
                  return `state: ${datum.state} \n clicks: ${
                    datum.state_cumulative
                  } \n time: ${moment(datum.created_at).format(
                    'HH:mm DD/MM/YY'
                  )}`;
                }
              } else {
                return `clicks: ${datum.cumulative} \n time: ${moment(
                  datum.created_at
                ).format('HH:mm DD/MM/YY')} \n`;
              }
            }}
            labelComponent={
              <VictoryTooltip
                constrainToVisibleArea
                centerOffset={{ y: -30 }}
                style={{ fontSize: 7 }}
                pointerWidth={1}
              />
            }
          />
        }
      >
        <VictoryAxis
          scale="time"
          style={{
            tickLabels: { fontSize: 7, fill: 'var(--color-text)' },
            axis: { stroke: 'var(--color-text-light)' },
          }}
          tickFormat={x => moment(x).format("MMMM DD 'YY")}
          fixLabelOverlap
        />
        <VictoryAxis
          dependentAxis
          style={{
            tickLabels: { fontSize: 7, fill: 'var(--color-text)' },
            axis: { stoke: 'var(--color-text-light)' },
          }}
        />
        {linesToShow !== 'ONLY_SUB_LINES' && (
          // total
          <VictoryLine
            name="total-area"
            y="cumulative"
            x="created_at"
            data={selectedData.all}
            style={{
              data: {
                fill: 'transparent',
                stroke: 'var(--color-complement)',
                strokeWidth: 1,
              },
            }}
          />
        )}
        {linesToShow !== 'ONLY_TOTAL' &&
          Object.keys(selectedData)
            .sort((a, b) => selectedData[b].length - selectedData[a].length)
            .map((data_key, _key) => {
              // sub lines (either state or book)
              // only render 15 + 1 lines for performance reasons
              if (
                data_key !== 'all' &&
                selectedData[data_key].length > 2 &&
                _key < 15
              ) {
                return (
                  <VictoryLine
                    name={`book-area-${_key}`}
                    key={_key}
                    y={
                      filter === 'BOOK'
                        ? 'book_cumulative'
                        : filter === 'STATE'
                        ? 'state_cumulative'
                        : ''
                    }
                    x="created_at"
                    data={selectedData[data_key]}
                    style={{
                      data: {
                        fill: 'transparent',
                        stroke: getUnusedColor(_key),
                        strokeWidth: 1,
                      },
                    }}
                  />
                );
              }
            })}
      </VictoryChart>
    </div>
  );
}
