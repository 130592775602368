import {
  RESET_EDIT_DEL,
  RESET_ERRORS_PICKS,
  FIND_BETTER_ODDS,
  FIND_BETTER_ODDS_SUCCESS,
  FIND_BETTER_ODDS_FAILURE,
  GET_PICKS_STATS,
  GET_PICKS_STATS_SUCCESS,
  GET_PICKS_STATS_FAILURE,
  CLEAR_AD,
  FAKE_TRACK_WAGER, // in tour actions
  SET_SEEN_TOUR,
} from 'actions';
import moment from 'moment';

// utils
import { calculateBetPayout, kFormatter } from 'utils';

const initialState = {
  isRetrievingPick: {
    bet: false,
    parlay: false,
    unsupported_bet: false,
    transaction: false,
  },
  retrievePickSuccess: {
    bet: null,
    parlay: null,
    unsupported_bet: null,
    transaction: null,
  },
  retrievePickFailure: {
    bet: null,
    parlay: null,
    unsupported_bet: null,
    transaction: null,
  },

  // picks stats stuff
  isLoadingPickStats: false,
  picksStatsError: null,
  pickStats: {
    graded_today: [],
    pending_today: [],
    all_pending: [],
    historicals: [],
  },
  hist_date_after: moment().subtract(1, 'day').startOf('day'),
  hist_date_before: moment().subtract(1, 'day').endOf('day'),
  recentTags: [],
  // ad
  ad_book: null,
  ad_text: null,
  betterOdds: null,
};

export default function picksReducer(state = initialState, action) {
  switch (action.type) {
    case RESET_EDIT_DEL:
      return Object.assign({}, state, {
        ...state,

        isEditingPick: {
          bet: false,
          parlay: false,
          unsupportedBet: false,
          transaction: false,
        },

        editPickSuccess: {
          bet: null,
          parlay: null,
          unsupportedBet: null,
          transaction: null,
        },

        editPickFailure: {
          bet: null,
          parlay: null,
          unsupportedBet: null,
          transaction: null,
        },

        isDeletingPick: {
          bet: false,
          parlay: false,
          unsupported_bet: false,
          transaction: false,
        },

        deletePickSuccess: {
          bet: null,
          parlay: null,
          unsupported_bet: null,
          transaction: null,
        },

        deletePickFailure: {
          bet: null,
          parlay: null,
          unsupported_bet: null,
          transaction: null,
        },

        isCreatingPick: {
          bet: false,
          parlay: false,
          unsupported_bet: false,
          transaction: false,
        },

        createPickSuccess: {
          bet: null,
          parlay: null,
          unsupported_bet: null,
          transaction: null,
        },

        createPickFailure: {
          bet: null,
          parlay: null,
          unsupported_bet: null,
          transaction: null,
        },
      });

    case RESET_ERRORS_PICKS:
      return Object.assign({}, state, {
        ...state,
        fetchTodayError: null,
        fetchHistoricalError: null,
        fetchAllPendingError: null,
        editFailure: null,
        deleteError: null,

        postUnsupportedError: null,
      });

    case FIND_BETTER_ODDS:
      return Object.assign({}, state, {
        ...state,
        ad_book: null,
        ad_text: null,
        betterOdds: null,
      });
    case FIND_BETTER_ODDS_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        ad_book: action.payload.response.ad_book,
        ad_text: action.payload.response.ad_text,
        betterOdds: action.payload.response.better_odds,
      });
    case FIND_BETTER_ODDS_FAILURE:
      return Object.assign({}, state, {
        ...state,
        ad_book: null,
        ad_text: null,
        betterOdds: null,
      });

    case GET_PICKS_STATS:
      return Object.assign({}, state, {
        ...state,
        isLoadingPickStats: true,
        picksStatsError: null,
        hist_date_after: action.date_after,
        hist_date_before: action.date_before,
      });
    case GET_PICKS_STATS_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        isLoadingPickStats: false,
        pickStats: action.payload,
      });
    case GET_PICKS_STATS_FAILURE:
      return Object.assign({}, state, {
        ...state,
        isLoadingPickStats: false,
        picksStatsError: action.payload,
      });

    case CLEAR_AD:
      return Object.assign({}, state, {
        ...state,
        ad_book: null,
        ad_text: null,
        betterOdds: null,
      });

    case FAKE_TRACK_WAGER:
      const { wager } = action;
      let payout = calculateBetPayout(wager.risk_amount, wager.odds);
      payout = kFormatter(Math.round(parseFloat(payout) + 10));
      return Object.assign({}, state, {
        ...state,
        pickStats: {
          ...state.pickStats,
          graded_today: [
            { text: 'count', num: 1 },
            { text: 'net', num: 10, symbol: '$' },
            { text: 'roi', num: 96, symbol: '%' },
          ],
          pending_today: [
            { text: 'count', num: 1 },
            { text: 'risk', num: `$${Math.round(wager.risk_amount + 12)}` },
            { text: 'to win', num: `$${payout}` },
          ],
          historicals: [
            { text: 'count', num: 3 },
            { text: 'net', num: 10, symbol: '$' },
            { text: 'roi', num: 30, symbol: '%' },
            { text: 'clv', num: 2.8, symbol: '%' },
          ],
        },
      });

    case SET_SEEN_TOUR:
      return Object.assign({}, state, {
        ...state,
        pickStats: {
          graded_today: [],
          pending_today: [],
          all_pending: [],
          historicals: [],
        },
      });

    default:
      return state;
  }
}
