import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// utils
import { stringToArray } from 'utils';

// components
import { Col } from 'components/generic/Layout';
import ListOptionBtn from '../ListOptionBtn';
import { AuthButton } from 'components/AuthButton';

// actions
import { getTags, getTagsNext } from 'actions';
import ActivityIndicator from 'components/generic/ActivityIndicator';

export default function Tags(props) {
  const dispatch = useDispatch();
  const reduxProps = useSelector(state => ({
    tags: state.analysisReducer.tags,
    tagsNext: state.analysisReducer.tagsNext,
    isLoadingTags: state.analysisReducer.isLoadingTags,
    isLoadingTagsNext: state.analysisReducer.isLoadingTagsNext,
    tagsFetchError: state.analysisReducer.tagsFetchError,
    tagsCount: state.analysisReducer.tagsCount,
  }));

  const {
    tags,
    tagsNext,
    isLoadingTags,
    isLoadingTagsNext,
    tagsFetchError,
    tagsCount,
  } = reduxProps;

  useEffect(() => {
    dispatch(getTags());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [selectedTags, setSelectedTags] = useState(
    props.defaultValue ? stringToArray(props.defaultValue) : []
  );

  useEffect(() => {
    setSelectedTags(
      props.defaultValue ? stringToArray(props.defaultValue) : []
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.defaultValue]);

  return (
    <>
      <h6 style={{ margin: 'var(--space-xs) 0' }}>Tags</h6>
      <p style={{ margin: 'var(--space-xs) 0' }}>
        Include only bets with these tags. Showing {tagsCount} tags
      </p>
      <Col
        style={{
          flexFlow: 'column nowrap',
          justifyContent: 'flex-start',
          alignItems: 'stretch',
          overflowY: 'auto',
        }}
      >
        {isLoadingTags && <ActivityIndicator size={2} />}
        {tagsFetchError && (
          <span style={{ color: 'var(--color-danger)' }}>
            There was an error loading your tags!
          </span>
        )}
        {tags.map(item => (
          <ListOptionBtn
            key={`analysis-filter-tags-${item}`}
            onClick={() => {
              let newOptions = Array.from(selectedTags);
              let index = newOptions.findIndex(
                opt => item === decodeURIComponent(opt)
              );
              if (index > -1) {
                newOptions.splice(index, 1);
              } else {
                newOptions.push(encodeURIComponent(item));
              }

              if (newOptions.length === 0) newOptions = [];

              setSelectedTags(newOptions);
            }}
            selected={
              selectedTags.filter(opt => decodeURIComponent(opt) === item)
                .length > 0
            }
            text={decodeURIComponent(item)}
          />
        ))}
        {tagsNext && (
          <AuthButton
            btnTheme="borderless"
            containerStyle={{
              margin: 0,
              flex: 0,
            }}
            onPress={() => dispatch(getTagsNext())}
          >
            Load More
          </AuthButton>
        )}
        {isLoadingTagsNext && <ActivityIndicator size={1.5} />}
      </Col>
      <br />
      <AuthButton
        containerStyle={{
          margin: 0,
          flex: 0,
        }}
        onPress={() => {
          let slug = selectedTags.join(', ');
          let value =
            '[' + selectedTags.map(opt => '"' + opt + '"').toString() + ']';
          if (value === '[""]' || value === '[]') {
            value = '[]';
            slug = 'All';
          }

          props.onDone({
            filter: 'Tags',
            value: value,
            slug: slug,
          });
        }}
      >
        Done
      </AuthButton>
      <br />
    </>
  );
}
