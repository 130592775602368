import { Observable } from 'rxjs';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/of';
import { ajax } from 'rxjs/observable/dom/ajax';
import { BACKEND_API_URL, getHeaders } from 'utils';

import {
  SOCIAL_CONNECT_USER,
  socialConnectUserSuccess,
  socialConnectUserFailure,
} from 'actions';

export default function socialConnectUser(action$, state$) {
  return action$.ofType(SOCIAL_CONNECT_USER).mergeMap(action => {
    let token;
    if (action.provider === 'apple') {
      token = { id_token: action.access_token, code: action.code };
    } else if (action.provider === 'facebook') {
      token = { access_token: action.access_token };
    } else if (action.provider === 'twitter') {
      token = {
        access_token: action.access_token,
        token_secret: action.code,
      };
    } else if (action.provider === 'twitter2') {
      token = {
        code: action.code,
        code_verifier: action?.extras?.code_verifier,
      };
    } else {
      //token = { access_token: action.access_token };
      token = { code: action.access_token };
    }
    return ajax
      .post(
        `${BACKEND_API_URL}api/social_connect/${action.provider}/`,
        token,
        getHeaders(state$)
      )
      .map(data => socialConnectUserSuccess(data))
      .catch(error => Observable.of(socialConnectUserFailure(error.xhr)));
  });
}
