import React, { useState, useEffect } from 'react';

// components
import { Row } from 'components/generic/Layout';
import SwitchSelector from 'components/generic/SwitchSelector';
import { AuthTextInput } from 'components/AuthTextInput';
import { AuthButton } from 'components/AuthButton';

export default function TimePeriod(props) {
  const [timePeriod, setTimePeriod] = useState(props.defaultValue);
  const [next, setNext] = useState(
    props.defaultValue && props.defaultValue < 0 ? true : false
  );

  useEffect(() => {
    setTimePeriod(props.defaultValue !== 'null' ? props.defaultValue : null);

    let n = false;
    if (props.defaultValue && props.defaultValue < 0) n = true;
    setNext(n);
  }, [props.defaultValue]);

  return (
    <>
      <h6 style={{ margin: 'var(--space-xs) 0' }}>Time Period</h6>
      <p style={{ margin: 'var(--space-xs) 0' }}>
        Get bets from the last or next <i>x</i> days. If this filter is set, the
        Date Range filter will be ignored.
      </p>
      <Row style={{ flex: 0, justifyContent: 'center' }}>
        <SwitchSelector
          options={['Last x days', 'Next x days']}
          active={next ? 'Next x days' : 'Last x days'}
          changeMode={mode => {
            setNext(mode === 'Next x days' ? true : false);
          }}
        />
      </Row>
      <br />
      <div style={{ flex: 0, width: '100%' }}>
        <AuthTextInput
          label={next ? 'Next x days' : 'Last x days'}
          type={'number'}
          step={1}
          inputRowStyle={{
            backgroundColor: 'var(--color-bg)',
          }}
          placeholder={next ? 'Next x days' : 'Last x days'}
          value={
            timePeriod
              ? timePeriod < 0
                ? (-1 * timePeriod).toString()
                : timePeriod.toString()
              : null
          }
          onChangeText={newTimePeriod =>
            setTimePeriod(newTimePeriod.replace(/\D/g, ''))
          }
        />
      </div>
      <br />
      <AuthButton
        onPress={() => {
          let tpvalue = timePeriod;
          let slug = '';
          let tpslug = timePeriod ? Math.abs(timePeriod).toString() : '';
          if (timePeriod && next) {
            tpvalue = timePeriod > 0 ? timePeriod * -1 : timePeriod;
            slug += 'Next ' + tpslug + ' days';
          } else if (timePeriod && !next) {
            tpvalue = timePeriod < 0 ? timePeriod * -1 : timePeriod;
            slug += 'Last ' + tpslug + ' days';
          } else {
            tpvalue = 'null';
            slug = 'All Time';
          }

          props.onDone({
            filter: 'Time_Period',
            value: tpvalue,
            slug: slug,
          });
          // reset the date range filter when time period is set
          props.onDone({
            filter: 'Date_Range',
            title: 'Date Range',
            value: '[null,null]',
            slug: 'All TIme',
          });
        }}
      >
        Done
      </AuthButton>
    </>
  );
}
