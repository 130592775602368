// Refresh all betlinked books
export const REFRESH_SHARPSPORTS_ALL = 'REFRESH_SHARPSPORTS_ALL';
// refresh, optionally reverify, a single betlinked book
export const REFRESH_SHARPSPORTS_BOOK = 'REFRESH_SHARPSPORTS_BOOK';
// delete the sharpsportsbook
export const UPDATE_SHARPSPORTS_BOOK = 'UPDATE_SHARPSPORTS_BOOK';
// Get all sharpsports books for a user
export const GET_SHARPSPORTS_BOOKS = 'GET_SHARPSPORTS_BOOKS';
// setup betlink/sharpsports book (mostly just for app)
export const FETCH_INTEGRATION_SS = 'FETCH_INTEGRATION_SS';
export const CLEAR_SS_INTEGRATION_DATA = 'CLEAR_SS_INTEGRATION_DATA';
export const RESET_SS_ERRORS = 'RESET_SS_ERRORS';
export const REFRESH_SS_BOOK_HISTORY = 'REFRESH_SS_BOOK_HISTORY';

export const REFRESH_SHARPSPORTS_ALL_SUCCESS =
  'REFRESH_SHARPSPORTS_ALL_SUCCESS';
export const REFRESH_SHARPSPORTS_ALL_FAILURE =
  'REFRESH_SHARPSPORTS_ALL_FAILURE';

export const refreshSharpsportsAll = () => ({
  type: REFRESH_SHARPSPORTS_ALL,
});

export const refreshSharpsportsAllSuccess = (message) => ({
  type: REFRESH_SHARPSPORTS_ALL_SUCCESS,
  message,
});

export const refreshSharpsportsAllFailure = (error) => ({
  type: REFRESH_SHARPSPORTS_ALL_FAILURE,
  error,
});

export const GET_SHARPSPORTS_BOOKS_SUCCESS = 'GET_SHARPSPORTS_BOOKS_SUCCESS';
export const GET_SHARPSPORTS_BOOKS_FAILURE = 'GET_SHARPSPORTS_BOOKS_FAILURE';

export const getSharpsportsBooks = () => ({
  type: GET_SHARPSPORTS_BOOKS,
});

export const getSharpsportsBooksSuccess = (payload) => ({
  type: GET_SHARPSPORTS_BOOKS_SUCCESS,
  payload,
});

export const getSharpsportsBooksFailure = (error) => ({
  type: GET_SHARPSPORTS_BOOKS_FAILURE,
  error,
});

export const REFRESH_SHARPSPORTS_BOOK_SUCCESS =
  'REFRESH_SHARPSPORTS_BOOK_SUCCESS';
export const REFRESH_SHARPSPORTS_BOOK_FAILURE =
  'REFRESH_SHARPSPORTS_BOOK_FAILURE';

export const refreshSharpsportsBook = (bettorAccount, reverify) => ({
  type: REFRESH_SHARPSPORTS_BOOK,
  bettorAccount,
  reverify,
});

export const refreshSharpsportsBookSuccess = (message) => ({
  type: REFRESH_SHARPSPORTS_BOOK_SUCCESS,
  message,
});

export const refreshSharpsportsBookFailure = (error) => ({
  type: REFRESH_SHARPSPORTS_BOOK_FAILURE,
  error,
});

export const FETCH_INTEGRATION_SS_SUCCESS = 'FETCH_INTEGRATION_SS_SUCCESS';
export const FETCH_INTEGRATION_SS_FAILURE = 'FETCH_INTEGRATION_SS_FAILURE';

export const fetchIntegrationSS = () => ({
  type: FETCH_INTEGRATION_SS,
});

export const fetchIntegrationSSSuccess = (payload) => ({
  type: FETCH_INTEGRATION_SS_SUCCESS,
  payload,
});

export const fetchIntegrationSSFailure = (error) => ({
  type: FETCH_INTEGRATION_SS_FAILURE,
  error,
});

export const clearSSIntegrationData = () => ({
  type: CLEAR_SS_INTEGRATION_DATA,
});

export const UPDATE_SHARPSPORTS_BOOK_SUCCESS =
  'UPDATE_SHARPSPORTS_BOOK_SUCCESS';
export const UPDATE_SHARPSPORTS_BOOK_FAILURE =
  'UPDATE_SHARPSPORTS_BOOK_FAILURE';
export const updateSSBook = (book) => ({
  type: UPDATE_SHARPSPORTS_BOOK,
  book,
});

export const updateSSBookSuccess = (payload) => ({
  type: UPDATE_SHARPSPORTS_BOOK_SUCCESS,
  payload,
});

export const updateSSBookFailure = (error) => ({
  type: UPDATE_SHARPSPORTS_BOOK_FAILURE,
  error,
});

export const resetSSErrors = () => ({
  type: RESET_SS_ERRORS,
});

export const REFRESH_SS_BOOK_HISTORY_SUCCESS =
  'REFRESH_SS_BOOK_HISTORY_SUCCESS';
export const REFRESH_SS_BOOK_HISTORY_FAILURE =
  'REFRESH_SS_BOOK_HISTORY_FAILURE';

export const refreshSSBookHistory = (bettor_account_id) => ({
  type: REFRESH_SS_BOOK_HISTORY,
  bettor_account_id,
});

export const refreshSSBookHistorySuccess = () => ({
  type: REFRESH_SS_BOOK_HISTORY_SUCCESS,
});

export const refreshSSBookHistoryFailure = (error) => ({
  type: REFRESH_SS_BOOK_HISTORY_FAILURE,
  error,
});
