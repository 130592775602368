import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useEffectOnce, useUpdateEffect } from 'react-use';

// components
import ActivityIndicator from 'components/generic/ActivityIndicator';
import { Col } from 'components/generic/Layout';
import OddBtnLine from 'components/OddBtnLine';
import GameBAHeader from 'components/GameBAHeader';

// actions
import { get2HalfOdds, get2HalfOddsNext, get2HalfBA } from 'actions';

export default function TwoHalf() {
  const dispatch = useDispatch();
  const reduxProps = useSelector(state => ({
    game: state.gameReducer.game,
    twoHalfOdds: state.gameReducer.twoHalfOdds,
    isLoadingTwoHalfOdds: state.gameReducer.isLoadingTwoHalfOdds,
    twoHalfOddsError: state.gameReducer.twoHalfOddsError,
    twoHalfOddsNext: state.gameReducer.twoHalfOddsNext,
    isLoadingTwoHalfOddsNext: state.gameReducer.isLoadingTwoHalfOddsNext,
    twoHalfBA: state.gameReducer.twoHalfBA,
    isLoadingTwoHalfBA: state.gameReducer.isLoadingTwoHalfBA,
    twoHalfBAError: state.gameReducer.twoHalfBAError,
  }));
  const {
    game,
    twoHalfOdds,
    isLoadingTwoHalfOdds,
    twoHalfOddsError,
    twoHalfOddsNext,
    isLoadingTwoHalfOddsNext,
    twoHalfBA,
    isLoadingTwoHalfBA,
    twoHalfBAError,
  } = reduxProps;

  useEffectOnce(() => {
    if (game) {
      dispatch(get2HalfOdds({ game_id: game.id }));
      dispatch(get2HalfBA(game.id));
    }
  });

  const _getNext = () => {
    if (twoHalfOddsNext && !isLoadingTwoHalfOddsNext) {
      dispatch(get2HalfOddsNext());
    }
  };
  useUpdateEffect(() => {
    _getNext();
  }, [twoHalfOddsNext]);

  if (isLoadingTwoHalfOdds) {
    return (
      <Col style={{ height: '75%', justifyContent: 'center' }}>
        <ActivityIndicator size={3} />
      </Col>
    );
  }

  if (twoHalfOddsError) {
    return (
      <h5 style={{ textAlign: 'center' }}>
        We ran into an error getting second half odds
      </h5>
    );
  }

  return (
    <div style={{ minHeight: '100vh' }}>
      <GameBAHeader
        BA={twoHalfBA}
        isLoading={isLoadingTwoHalfBA}
        error={twoHalfBAError}
      />
      {twoHalfOdds &&
        twoHalfOdds.map((odd, index) => (
          <OddBtnLine key={`twohalf-list-odd-${index}`} odd={odd} />
        ))}
    </div>
  );
}
