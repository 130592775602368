import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { useEffectOnce, useUpdateEffect } from 'react-use';
import moment from 'moment';

// utils
import {
  toAmericanOdds,
  roundOdds,
  roundNumber,
  calculateBetPayout,
  periodChoices,
} from 'utils';

// components
import Snackbar from '@material-ui/core/Snackbar';
import { Row, Col } from 'components/generic/Layout';
import BetLineLogos from 'components/BetLineLogos';
import RiskWinDisplay from 'components/RiskWinDisplay';
import PeriodScores from 'components/PeriodScores';
import CenterTextLine from 'components/generic/CenterTextLine';
import Wager from 'components/Wager';
import AutoColoredAmount from 'components/generic/AutoColoredAmount';
import BooksDropdown from 'components/BooksDropdown';
import Select from 'components/generic/Select';
import EditRiskResultOdds from 'components/EditRiskResultOdds';
import SpreadTotalInput from 'components/SpreadTotalInput';
import { AuthTextInput } from 'components/AuthTextInput';
import Switch from 'components/generic/Switch';
import AddEditTags from 'components/AddEditTags';
import AddEditDescription from 'components/AddEditDescription';
import ErrorDisplay from 'components/generic/ErrorDisplay';
import CalendarInput from 'components/generic/CalendarInput';
import { AuthButton } from 'components/AuthButton';

//actions
import {
  resetWagerEditDel,
  updateWager,
  deleteWager,
  getPicksStats,
  refreshSSBookHistory,
} from 'actions';

const InputsWrapper = styled.div`
  flex: 1 0 350px;
  height: 100%;
  display: flex;
  flex-flow: column wrap;
  justify-content: flex-start;
  align-items: center;
  & > div {
    flex: 0;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    max-width: 350px;
    margin: var(--space-xxs) 0;
  }
  & > div > b {
    flex: 0.5;
    text-align: right;
    padding: 0 var(--space-sm);
  }
`;

const BASE_STATUS_OPTS = [
  { value: 'PENDING', label: 'PENDING' },
  { value: 'WON', label: 'WON' },
  { value: 'LOST', label: 'LOST' },
  { value: 'PUSHED', label: 'PUSHED' },
  { value: 'VOID', label: 'VOID' },
  { value: 'HALF_WON', label: 'HALF WON' },
  { value: 'HALF_LOST', label: 'HALF LOST' },
  { value: 'CASHED_OUT', label: 'CASHED OUT' },
  { value: 'FREE_BET_WON', label: 'FREE BET WON' },
  { value: 'FREE_BET_LOST', label: 'FREE BET LOST' },
];

export default function ViewWager(props) {
  const dispatch = useDispatch();
  const reduxProps = useSelector(state => ({
    user: state.authReducer.user,
    isUpdatingWager: state.wagerReducer.isUpdatingWager,
    updateWagerFailure: state.wagerReducer.updateWagerFailure,
    updateWagerSuccess: state.wagerReducer.updateWagerSuccess,
    isDeletingWager: state.wagerReducer.isDeletingWager,
    deleteWagerFailure: state.wagerReducer.deleteWagerFailure,
    hist_date_after: state.picksReducer.hist_date_after,
    hist_date_before: state.picksReducer.hist_date_before,
  }));
  const {
    user,
    isUpdatingWager,
    updateWagerFailure,
    updateWagerSuccess,
    isDeletingWager,
    deleteWagerFailure,
    hist_date_after,
    hist_date_before,
  } = reduxProps;
  const { odds_preference } = user;

  const { wager } = props;
  const {
    game,
    is_edited,
    is_verified,
    clv_applicable,
    book,
    is_live,
    clv_book,
    type_name,
    clv_ba,
    is_graded,
    side,
    prop_type,
    latest_odds,
    bets,
    bet_type,
    is_public,
    is_future,
  } = wager;

  const [changes, setChanges] = useState({});
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [noEdits, setNoEdits] = useState(true);
  const [showSnackbar, setShowSnackbar] = useState(false);

  const mergedwager = {
    ...wager,
    ...changes,
  };

  const choices = periodChoices(mergedwager);

  const labels = {
    FT: 'Full Game',
    '1H': '1st Half',
    '2H': '2nd Half',
    '1S': '1st Set',
    '1P': '1st Period',
    F5: '1st 5 Innings',
  };

  const _handleChange = (value, item) => {
    let newChanges = {};
    if (item === 'riskresultodds') {
      newChanges.risk_amount = value.risk_amount;
      newChanges.odds = value.odds;
      if (
        mergedwager.status === 'WON' ||
        mergedwager.status === 'FREE_BET_WON'
      ) {
        newChanges.result = value.result;
      } else if (mergedwager.status === 'LOST') {
        newChanges.result = -value.risk_amount;
      } else if (mergedwager.status === 'HALF_LOST') {
        newChanges.result = -(value.risk_amount * 0.5);
      } else if (mergedwager.status === 'HALF_WON') {
        newChanges.result = value.result * 0.5;
      } else if (
        mergedwager.status === 'PUSHED' ||
        mergedwager.status === 'FREE_BET_LOST'
      ) {
        newChanges.result = 0;
      }
    } else if (item === 'status') {
      newChanges.status = value;
      if (value === 'PENDING') {
        newChanges.is_graded = false;
        newChanges.result = 0;
      } else if (value === 'WON' || value === 'FREE_BET_WON') {
        newChanges.is_graded = true;
        newChanges.result = calculateBetPayout(
          mergedwager.risk_amount,
          mergedwager.odds
        );
      } else if (value === 'LOST') {
        newChanges.is_graded = true;
        newChanges.result = -mergedwager.risk_amount;
      } else if (value === 'PUSHED' || value === 'FREE_BET_LOST') {
        newChanges.is_graded = true;
        newChanges.result = 0;
      } else if (value === 'HALF_WON') {
        newChanges.is_graded = true;
        newChanges.result = calculateBetPayout(
          mergedwager.risk_amount * 0.5,
          mergedwager.odds
        );
      } else if (value === 'HALF_LOST') {
        newChanges.is_graded = true;
        newChanges.result = -(mergedwager.risk_amount * 0.5);
      }
    } else {
      newChanges[item] = value;
    }
    if (noEdits) setNoEdits(false);

    setChanges({ ...changes, ...newChanges });
  };

  const _saveWager = () => {
    const bookid = mergedwager.book.id ? mergedwager.book.id : mergedwager.book;
    dispatch(updateWager({ ...mergedwager, book: bookid }));
  };

  useUpdateEffect(() => {
    if (updateWagerSuccess) {
      dispatch(resetWagerEditDel());
      dispatch(getPicksStats(hist_date_after, hist_date_before));
    }
  }, [updateWagerSuccess]);

  useEffectOnce(() => {
    dispatch(resetWagerEditDel());
  });

  const _saveTags = newTags => {
    dispatch(updateWager({ id: wager.id, tags: newTags }));
  };

  const _saveBreakdown = newBreakdown => {
    dispatch(updateWager({ id: wager.id, breakdown: newBreakdown }));
  };

  const saveDisabled = noEdits || mergedwager.number === '';

  let step = 0.5;
  if (mergedwager.sports[0] === 'Soccer') step = 0.25;

  return (
    <>
      {(updateWagerFailure || deleteWagerFailure) && (
        <ErrorDisplay
          error={updateWagerFailure || deleteWagerFailure}
          message={
            updateWagerFailure
              ? 'editing this bet! Try again later.'
              : 'deleting this bet! Try again later.'
          }
        />
      )}
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={showSnackbar}
        autoHideDuration={5000}
        onClose={() => setShowSnackbar(false)}
        message="Your wagers will sync over the next minute"
      />
      <Row style={{ alignItems: 'flex-start' }}>
        <Col
          style={{
            flex: '1.33 0 350px',
            justifyContent: 'flex-start',
            alignItems: 'stretch',
          }}
        >
          <BetLineLogos
            large
            bet={wager}
            odds_preference={odds_preference}
            showVerifiedStamp
            showGraded
            showSyncBadge
          />
          <small>
            Placed {moment(wager?.created_at).format('ddd MMM D @ h:mma')} at{' '}
            {wager?.book?.name}
            {wager.is_free_bet && <> - Free Bet</>}
          </small>
          {is_future && <small>{wager?.season?.name}</small>}
          {game && game?.sport?.toUpperCase() === 'GOLF' && (
            <small>
              {game?.season?.name}
              {' | '}
              {game.length_of_match} vs.{' '}
              {side === game.away_team.name
                ? game.home_team.name
                : game.away_team.name}
            </small>
          )}
          <RiskWinDisplay bet={wager} />
          {wager.game && <PeriodScores bet={wager} game={game} />}
          <Row style={{ margin: 'var(--space-sm)' }}>
            {clv_applicable && (
              <Col style={{ alignSelf: 'center' }}>
                <b style={{ lineHeight: 1 }}>
                  <AutoColoredAmount symbol="%" bold="true">
                    {clv_book !== null ? clv_book * 100 : clv_ba * 100}
                  </AutoColoredAmount>
                </b>
                {book.is_custom ? (
                  <small style={{ lineHeight: 1 }}>Best Available CLV</small>
                ) : (
                  <small style={{ lineHeight: 1 }}>
                    {is_live ||
                      type_name === 'Player Prop' ||
                      type_name === 'Game Prop'
                      ? ''
                      : book.name}{' '}
                    CLV
                  </small>
                )}
              </Col>
            )}
            {is_graded && !book.is_custom && clv_applicable && (
              <Col style={{ alignSelf: 'center' }}>
                <b style={{ lineHeight: 1 }}>
                  {clv_ba !== null ? (
                    <AutoColoredAmount symbol="%" bold="true">
                      {clv_ba * 100}
                    </AutoColoredAmount>
                  ) : (
                    '--'
                  )}
                </b>
                <small style={{ lineHeight: 1 }}>Best Available CLV</small>
              </Col>
            )}
            {latest_odds && (
              <>
                <Col>
                  <b style={{ lineHeight: '1.1' }}>
                    {latest_odds.number
                      ? `${type_name === 'Spread'
                        ? latest_odds.number > 0
                          ? '+'
                          : ''
                        : side === 'Over'
                          ? 'o'
                          : 'u'
                      }${roundNumber(latest_odds.number)} `
                      : ''}
                    {odds_preference === 0
                      ? toAmericanOdds(latest_odds.odds)
                      : roundOdds(latest_odds.odds)}
                  </b>
                  <small>latest odds</small>
                </Col>
              </>
            )}
          </Row>
          {bets &&
            bets.map((child, i) => (
              <Wager
                key={`view-wager-child-${child.multi_wager_id}-${i}`}
                wager={child}
                disableModal
                style={{ backgroundColor: 'var(--color-bg)' }}
                hideBetStats
              />
            ))}
          {bet_type === 'CUSTOM' && <WagerEditStats wager={wager} />}
          {is_verified && is_edited && (
            <>
              <CenterTextLine
                text={'Original Verified Wager'}
                color={'var(--color-primary)'}
              />
              <div>
                <Wager
                  disableModal={true}
                  todaysAction={true}
                  wager={{
                    ...wager,
                    odds: wager.original_odds,
                    number: wager.original_number,
                    side: wager.original_side,
                    status: wager.original_status,
                    risk_amount: wager.original_risk_amount,
                    result: wager.original_result,
                    book: wager.original_book,
                    is_edited: null,
                    latest_odds: wager.original_latest_odds,
                    is_live: wager.original_is_live,
                    is_graded: wager.original_is_graded,
                  }}
                  style={{ backgroundColor: 'var(--color-bg)' }}
                />
              </div>
            </>
          )}
          <AddEditTags
            existingTags={mergedwager.tags}
            onTagsChange={tags => _saveTags(tags)}
          />
          <AddEditDescription
            defaultDescription={mergedwager.breakdown || ''}
            onDescChange={bd => _saveBreakdown(bd)}
          />
        </Col>
        <InputsWrapper>
          <div>
            <h6>
              {is_verified && !is_edited ? 'Adjust for Tracking' : 'Edit Bet'}
            </h6>
          </div>
          <div>
            <b>Book</b>
            <div style={{ flex: 2 }}>
              <BooksDropdown
                defaultValue={{
                  label: wager.book.name,
                  value: wager.book?.id ? wager.book.id : wager.book,
                }}
                onChange={opt => _handleChange(opt.value, 'book')}
              />
            </div>
          </div>
          <div>
            <b>Status</b>
            <div style={{ flex: 2 }}>
              <Select
                defaultValue={
                  mergedwager.status === 'DEAD_HEAT'
                    ? {
                      value: 'DEAD_HEAT',
                      label: 'WON (DEAD HEAT)',
                    }
                    : {
                      value: mergedwager.status,
                      label: mergedwager.status,
                    }
                }
                options={
                  bet_type === 'CUSTOM'
                    ? [
                      ...BASE_STATUS_OPTS,
                      { value: 'DEAD_HEAT', label: 'WON (DEAD HEAT)' },
                    ]
                    : BASE_STATUS_OPTS
                }
                onChange={opt => _handleChange(opt.value, 'status')}
              />
            </div>
          </div>
          {bet_type === 'CUSTOM' && (
            <>
              <div>
                <b>Date</b>
                <div style={{ flex: 2 }}>
                  <CalendarInput
                    value={new Date(mergedwager.first_date)}
                    onChange={date => {
                      const hour = moment(mergedwager.first_date).hours();
                      const min = moment(mergedwager.first_date).minutes();
                      let newDate = moment(date).hour(hour).minute(min);
                      _handleChange(newDate.toDate(), 'first_date');
                    }}
                  />
                </div>
              </div>
              <div>
                <b>Time</b>
                <div style={{ flex: 2 }}>
                  <AuthTextInput
                    containerStyle={{ flex: 2 }}
                    type="time"
                    defaultValue={moment(mergedwager.first_date).format(
                      'HH:mm'
                    )}
                    inputRowStyle={{
                      backgroundColor: 'var(--color-bg)',
                    }}
                    onChangeText={newTime => {
                      if (!newTime || newTime === '') {
                        return;
                      }
                      const hour = newTime.split(':')[0];
                      const min = newTime.split(':')[1];
                      let newDate = moment(mergedwager.first_date)
                        .hour(hour)
                        .minute(min);
                      _handleChange(newDate.toDate(), 'first_date');
                    }}
                  />
                </div>
              </div>
              <div>
                <b>Sport</b>
                <AuthTextInput
                  containerStyle={{ flex: 2 }}
                  inputRowStyle={{
                    backgroundColor: 'var(--color-bg)',
                  }}
                  defaultValue={mergedwager.sports.join(', ')}
                  autoCapitalize={'words'}
                  onChangeText={newSports => {
                    const sportArray = newSports.trim().split(',');
                    _handleChange(sportArray, 'sports');
                  }}
                />
              </div>
              <div>
                <b>CLV (%)</b>
                <AuthTextInput
                  containerStyle={{ flex: 2 }}
                  type="number"
                  inputmode="numeric"
                  defaultValue={clv_book * 100}
                  inputRowStyle={{
                    backgroundColor: 'var(--color-bg)',
                  }}
                  onChangeText={(input, inputRef) => {
                    let clv_book_val = input * (1.0 / 100);
                    _handleChange(+clv_book_val.toFixed(4), 'clv_book');
                  }}
                />
              </div>
            </>
          )}
          {wager.side && wager?.side_against !== null && (
            <div>
              <b>Side</b>
              <div style={{ flex: 2 }}>
                <Select
                  defaultValue={{
                    label: wager.side,
                    value: wager.side,
                  }}
                  options={[
                    {
                      label: wager.side,
                      value: wager.side,
                    },
                    {
                      label: wager.side_against,
                      value: wager.side_against,
                    },
                  ]}
                  onChange={opt => {
                    if (side !== opt.value) {
                      _handleChange(opt.value, 'side');
                    }
                  }}
                />
              </div>
            </div>
          )}
          {bet_type === 'BET' &&
            mergedwager.type_name !== 'Game Prop' &&
            mergedwager.type_name !== 'Player Prop' &&
            !mergedwager.is_future &&
            !['UFC', 'GOLF', 'BELL'].includes(mergedwager?.game?.league) && (
              <div>
                <b>Period</b>
                <div style={{ flex: 2 }}>
                  <Select
                    defaultValue={{
                      value: mergedwager.period,
                      label: labels[mergedwager.period],
                    }}
                    options={choices}
                    onChange={opt => {
                      _handleChange(opt.value, 'period');
                    }}
                  />
                </div>
              </div>
            )}
          <div>
            <EditRiskResultOdds
              defaultRisk={mergedwager.risk_amount}
              defaultResult={calculateBetPayout(
                mergedwager.risk_amount,
                mergedwager.odds
              )}
              defaultOdds={mergedwager.odds}
              handleChange={changes => _handleChange(changes, 'riskresultodds')}
            />
          </div>
          {type_name !== 'Moneyline' && mergedwager.number !== null && (
            <div>
              <b>
                {type_name === 'Player Prop' || type_name === 'Game Prop'
                  ? prop_type
                  : type_name}
              </b>
              <SpreadTotalInput
                step={step}
                inputContainerStyle={{ flex: 2, maxWidth: '350px' }}
                defaultValue={mergedwager.number}
                handleChange={value => _handleChange(value, 'number')}
              />
            </div>
          )}

          {bet_type === 'BET' && (
            <div>
              <b>Live Bet</b>
              <div
                style={{ display: 'flex', flex: 2, justifyContent: 'flex-end' }}
              >
                <Switch
                  defaultChecked={mergedwager.is_live}
                  onChange={input =>
                    _handleChange(input.target.checked, 'is_live')
                  }
                />
              </div>
            </div>
          )}
          <div>
            <b style={{ whiteSpace: 'nowrap' }}>Free Bet</b>
            <div
              style={{ display: 'flex', flex: 2, justifyContent: 'flex-end' }}
            >
              <Switch
                defaultChecked={mergedwager.is_free_bet}
                onChange={input =>
                  _handleChange(input.target.checked, 'is_free_bet')
                }
              />
            </div>
          </div>

          <div>
            <b style={{ whiteSpace: 'nowrap' }}>Hide From My Tracking</b>
            <div
              style={{
                display: 'flex',
                flex: 0.5,
                justifyContent: 'flex-end',
              }}
            >
              <Switch
                defaultChecked={wager.is_hidden_from_tracking}
                onChange={input =>
                  _handleChange(input.target.checked, 'is_hidden_from_tracking')
                }
              />
            </div>
          </div>
          <div>
            <AuthButton
              containerStyle={{
                margin: '0 var(--space-sm)',
              }}
              isLoading={isUpdatingWager}
              disabled={saveDisabled && !showConfirmDelete}
              onPress={
                showConfirmDelete
                  ? () => setShowConfirmDelete(false)
                  : () => _saveWager()
              }
            >
              {showConfirmDelete
                ? 'No, keep it'
                : is_verified && !is_edited && is_public
                  ? 'Adjust for Tracking'
                  : 'Save Wager'}
            </AuthButton>
            {!(is_public && is_verified) && (
              <AuthButton
                colorTheme="danger"
                containerStyle={{
                  margin: '0 var(--space-sm)',
                }}
                isLoading={isDeletingWager}
                onPress={
                  showConfirmDelete
                    ? () => dispatch(deleteWager(wager.id))
                    : () => setShowConfirmDelete(true)
                }
              >
                {showConfirmDelete ? 'Yes, delete it' : 'Delete Bet'}
              </AuthButton>
            )}
          </div>
        </InputsWrapper>
      </Row>
    </>
  );
}

function WagerEditStats({ wager }) {
  const gradedAt = wager.graded_at
    ? moment(wager.graded_at).format('ddd MMM D @ h:mma')
    : 'Not Graded';
  const updatedAt = wager.updated_at
    ? moment(wager.updated_at).format('ddd MMM D @ h:mma')
    : 'Never Edited';
  return (
    <>
      <CenterTextLine text="betstamp Statistics" />
      <table style={{ width: '100%', textAlign: 'center' }}>
        <tbody>
          <tr>
            <b>Created at:</b>
            <td>{moment(wager.created_at).format('ddd MMM D @ h:mma')}</td>
          </tr>
          <tr>
            <b>Graded at:</b>
            <td>{gradedAt}</td>
          </tr>
          <tr>
            <b>Updated at:</b>
            <td
              style={{
                color: wager.updated_at
                  ? 'var(--color-danger)'
                  : 'var(--color-success)',
              }}
            >
              {updatedAt}
            </td>
          </tr>
          <tr>
            <b>Number of Edits:</b>
            <td
              style={{
                color:
                  wager.num_edits === 0
                    ? 'var(--color-success)'
                    : wager.num_edits === 1
                      ? 'var(--color-complement)'
                      : 'var(--color-danger)',
              }}
            >
              {wager.num_edits}
            </td>
          </tr>
          <tr>
            <b>CLV:</b>
            <td>
              <AutoColoredAmount symbol="%">
                {wager.clv_book * 100}
              </AutoColoredAmount>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
}
