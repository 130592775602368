import React from 'react';
import { useSelector } from 'react-redux';

// components
import OddBtn2 from 'components/generic/OddBtn2';

export default function OddsBtnArray(props) {
  const reduxProps = useSelector(state => ({
    allBooksMap: state.authReducer.allBooksMap,
  }));
  const { allBooksMap } = reduxProps;

  const { game, odds } = props;
  let awayMLWon = game.winner === game.away_team.name;
  let homeMLWon = game.winner === game.home_team.name;
  let tieMLWon = game.winner === 'Tie';

  let home_score = game.home_score;
  let away_score = game.away_score;
  if (odds.period !== 'FT') {
    if (odds.period === '2H') {
      home_score = game.home_score - game.home_half_score;
      away_score = game.away_score - game.away_half_score;
      homeMLWon = home_score > away_score;
      awayMLWon = away_score > home_score;
    } else {
      home_score = game.home_half_score;
      away_score = game.away_half_score;
    }
    awayMLWon = away_score > home_score;
    homeMLWon = away_score < home_score;
  }

  let homeSpreadWon, awaySpreadWon, overTotalWon, underTotalWon;
  const spread = home_score - away_score;
  const spread_home = odds.home_spread ? odds.home_spread : odds.spread;
  const spread_away = odds.away_spread ? odds.away_spread : -1 * odds.spread;

  if (spread > -spread_home && !game.is_postponed) homeSpreadWon = true;
  else homeSpreadWon = false;

  if (spread >= spread_away || game.is_postponed) awaySpreadWon = false;
  else awaySpreadWon = true;

  const total = away_score + home_score;
  const total_over = odds.over_total ? odds.over_total : odds.total;
  const total_under = odds.under_total ? odds.under_total : odds.total;

  if (total > total_over && !game.is_postponed) overTotalWon = true;
  else overTotalWon = false;

  if (total < total_under && !game.is_postponed) underTotalWon = true;
  else underTotalWon = false;

  let awayData = [];
  let homeData = [];
  let tieData = [];

  let awayMLBookID = odds.book_id || odds.away_ml_book_id;
  const awayMLBook = awayMLBookID ? allBooksMap[awayMLBookID] : null;

  let homeMLBookID = odds.book_id || odds.home_ml_book_id;
  const homeMLBook = homeMLBookID ? allBooksMap[homeMLBookID] : null;

  let tieMLBookID = odds.book_id || odds.tie_ml_book_id;
  const tieMLBook = tieMLBookID ? allBooksMap[tieMLBookID] : null;

  let homeSPBookID = odds.book_id || odds.home_spread_book_id;
  const homeSPBook = homeSPBookID ? allBooksMap[homeSPBookID] : null;

  let awaySPBookID = odds.book_id || odds.away_spread_book_id;
  const awaySPBook = awaySPBookID ? allBooksMap[awaySPBookID] : null;

  let overBookID = odds.book_id || odds.over_book_id;
  const overBook = overBookID ? allBooksMap[overBookID] : null;

  let underBookID = odds.book_id || odds.under_book_id;
  const underBook = underBookID ? allBooksMap[underBookID] : null;

  // moneylines
  awayData.push({
    oddID: odds.id,
    odds: odds.away_ml,
    number: null,
    type_name: 'Moneyline',
    side: game.away_team.name,
    period: odds.period,
    //book: odds.book ? odds.book : odds.away_ml_book ? odds.away_ml_book : null,
    book: awayMLBook,
    won: game.is_complete ? awayMLWon : null,
    game: game,
    bestAvailable: Boolean(odds.away_ml_book_id),
    is_live: odds.is_live,
  });
  homeData.push({
    oddID: odds.id,
    odds: odds.home_ml,
    number: null,
    type_name: 'Moneyline',
    side: game.home_team.name,
    period: odds.period,
    //book: odds.book ? odds.book : odds.home_ml_book ? odds.home_ml_book : null,
    book: homeMLBook,
    won: game.is_complete ? homeMLWon : null,
    game: game,
    bestAvailable: Boolean(odds.home_ml_book_id),
    is_live: odds.is_live,
  });

  if (game.sport === 'Soccer') {
    tieData.push({
      oddID: odds.id,
      odds: odds.tie_ml,
      number: null,
      type_name: 'Moneyline',
      side: 'Tie',
      period: odds.period,
      //book: odds.book ? odds.book : odds.tie_ml_book ? odds.tie_ml_book : null,
      book: tieMLBook,
      won: game.is_complete ? tieMLWon : null,
      game: game,
      bestAvailable: Boolean(odds.home_ml_book_id),
      is_live: odds.is_live,
    });
  }

  // spreads
  if (game.sport !== 'MMA') {
    awayData.push({
      oddID: odds.id,
      odds: odds.away_spread_odds,
      number:
        odds.away_spread !== null && odds.away_spread !== undefined
          ? odds.away_spread
          : odds.spread !== null && odds.spread !== undefined
          ? -1 * odds.spread
          : '',
      type_name: 'Spread',
      side: game.away_team.name,
      period: odds.period,
      //book: odds.book
      //  ? odds.book
      //  : odds.away_spread_book
      //  ? odds.away_spread_book
      //  : null,
      book: awaySPBook,
      won: game.is_complete ? awaySpreadWon : null,
      game: game,
      bestAvailable: Boolean(odds.away_spread_book_id),
      is_live: odds.is_live,
    });
    homeData.push({
      oddID: odds.id,
      odds: odds.home_spread_odds,
      number:
        odds.home_spread !== null && odds.home_spread !== undefined
          ? odds.home_spread
          : odds.spread !== null && odds.spread !== undefined
          ? odds.spread
          : '',
      type_name: 'Spread',
      side: game.home_team.name,
      period: odds.period,
      //book: odds.book
      //  ? odds.book
      //  : odds.home_spread_book
      //  ? odds.home_spread_book
      //  : null,
      book: homeSPBook,
      won: game.is_complete ? homeSpreadWon : null,
      game: game,
      bestAvailable: Boolean(odds.home_spread_book_id),
      is_live: odds.is_live,
    });
  }
  // totals
  if (game.league !== 'GOLF') {
    awayData.push({
      oddID: odds.id,
      odds: odds.over_odds,
      number: odds.over_total
        ? odds.over_total
        : odds.total
        ? odds.total
        : '--',
      type_name: 'Total',
      side: 'Over',
      period: odds.period,
      //book: odds.book ? odds.book : odds.over_book ? odds.over_book : null,
      book: overBook,
      won: game.is_complete ? overTotalWon : null,
      game: game,
      bestAvailable: Boolean(odds.over_book_id),
      is_live: odds.is_live,
    });
    homeData.push({
      oddID: odds.id,
      odds: odds.under_odds,
      number: odds.under_total
        ? odds.under_total
        : odds.total
        ? odds.total
        : '--',
      type_name: 'Total',
      side: 'Under',
      period: odds.period,
      //book: odds.book ? odds.book : odds.under_book ? odds.under_book : null,
      book: underBook,
      won: game.is_complete ? underTotalWon : null,
      game: game,
      bestAvailable: Boolean(odds.under_book_id),
      is_live: odds.is_live,
    });
  }

  let topData = awayData;
  let botData = homeData;
  if (['BUND', 'SERA', 'LIGA', 'LIG1', 'UCL', 'EPL'].includes(game.league)) {
    topData = [...homeData];
    topData[2] = awayData[2]; //dont flip total
    botData = [...awayData];
    botData[2] = homeData[2]; //dont flip total
  }

  return (
    <div
      style={{
        flex: 1,
        display: 'grid',
        gridTemplateColumns: ['UFC', 'GOLF', 'BELL'].includes(game.league)
          ? '1fr 1fr'
          : '1fr 1fr 1fr',
        gridTemplateRows: '1fr 1fr',
        gridGap: '1px',
        width: '100%',
        ...props.style,
      }}
    >
      {topData.map((odd, i) => (
        <OddBtn2
          containerStyle={{ borderRadius: 0, height: '100%' }}
          btnStyle={{ borderRadius: 0, height: '100%' }}
          btnTheme="borderless"
          highlightColor={props.highlightColor ? props.highlightColor : null}
          key={`oddsbtn-array-away-${game.id}-${i}`}
          odd={odd}
        />
      ))}

      {botData.map((odd, i) => (
        <OddBtn2
          containerStyle={{ borderRadius: 0, height: '100%' }}
          btnStyle={{ borderRadius: 0, height: '100%' }}
          btnTheme="borderless"
          highlightColor={props.highlightColor ? props.highlightColor : null}
          key={`oddsbtn-array-home-${game.id}-${i}`}
          odd={odd}
        />
      ))}

      {tieData &&
        tieData.map((odd, i) => (
          <OddBtn2
            containerStyle={{ borderRadius: 0, height: '100%' }}
            btnStyle={{ borderRadius: 0, height: '100%' }}
            btnTheme="borderless"
            highlightColor={props.highlightColor ? props.highlightColor : null}
            key={`oddsbtn-array-tie-${game.id}-${i}`}
            odd={odd}
          />
        ))}
    </div>
  );
}
