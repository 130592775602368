import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useUpdateEffect } from 'react-use';
import { IoStar, IoStarOutline, IoRefresh } from 'react-icons/io5';
import styled from 'styled-components';
import InfiniteScroll from 'react-infinite-scroll-component';
import moment from 'moment';

// utils
import { LEAGUES } from 'utils';

// components
import ActivityIndicator from 'components/generic/ActivityIndicator';
import { Row, Col } from 'components/generic/Layout';
import {
  Toolbar,
  MainAreaWrapper,
  InnerMainArea,
} from 'components/generic/Layout';
import BetLineLogos from 'components/BetLineLogos';
import { AuthButton, IconButton } from 'components/AuthButton';
//import ExternalPlaceBetAtBook from 'components/ExternalPlaceBetAtBook';
import BooksDropdown from 'components/BooksDropdown';
import LeagueSelector from 'components/LeagueSelector';
import TeamLeagueLogo from 'components/TeamLeagueLogo';
import CalendarInput from 'components/generic/CalendarInput';
import CustomSelect from 'components/generic/Select';

// actions
import {
  getFindBets,
  getFindBetsNext,
  getAllBooks,
  getAffiliates,
} from 'actions';

const ScrollContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding: 0 var(--space-xxs);
  padding-bottom: var(--space-xl);
`;

export default function FindBets() {
  const dispatch = useDispatch();
  const reduxProps = useSelector(state => ({
    user: state.authReducer.user,
    reccOdds: state.findbetsReducer.reccOdds,
    isLoadingReccOdds: state.findbetsReducer.isLoadingReccOdds,
    reccOddsNext: state.findbetsReducer.reccOddsNext,
    isLoadingReccOddsNext: state.findbetsReducer.isLoadingReccOddsNext,
    gameSelectedLeagues: state.scheduleReducer.selectedLeagues,
  }));
  const {
    user,
    reccOdds,
    isLoadingReccOdds,
    reccOddsNext,
    isLoadingReccOddsNext,
    gameSelectedLeagues,
  } = reduxProps;

  const [selectedLeagues, setSelectedLeagues] = useState(gameSelectedLeagues);
  const [selectedBooks, setSelectedBooks] = useState(['My Sportsbooks']);
  const [selectedPeriods, setSelectedPeriods] = useState('FT');
  const [calendarOpen, setCalendarOpen] = useState(false);
  const [date, setDate] = useState(null);
  const [selectedOddTypes, setSelectedOddTypes] = useState(['regular']);

  const _initialLoad = () => {
    dispatch(
      getFindBets({
        book_ids:
          selectedBooks[0] === 'My Sportsbooks'
            ? user.books.map(b => b.id)
            : selectedBooks,
        leagues:
          selectedLeagues.length === 0
            ? LEAGUES.map(l => l.league)
            : selectedLeagues,
        periods: selectedPeriods.split(','),
        date: date,
        odd_types: selectedOddTypes,
      })
    );
  };
  const _getNext = () => {
    if (reccOddsNext) {
      dispatch(getFindBetsNext());
    }
  };

  // force get the second page because components arent big enough to start the infinite scroll
  useUpdateEffect(() => {
    if (new URLSearchParams(reccOddsNext).get('page') === '2') {
      _getNext();
    }
  }, [reccOddsNext]);

  useEffect(() => {
    dispatch(getAllBooks());
    dispatch(getAffiliates());
    _initialLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let formattedShowingDate = 'All dates';
  if (date) {
    formattedShowingDate = moment(date).format('ddd MMM D');
  }

  return (
    <Col style={{ height: '100vh', flexWrap: 'nowrap', overflow: 'hidden' }}>
      <Toolbar style={{ height: '58px' }}>
        <IconButton
          colorTheme="text"
          iconName={IoRefresh}
          onPress={() => {
            _initialLoad();
          }}
        />
        <AuthButton
          colorTheme="text"
          btnTheme="borderless"
          containerStyle={{ flex: 0.5 }}
          onPress={() => setCalendarOpen(!calendarOpen)}
        >
          {formattedShowingDate}
        </AuthButton>
        <CalendarInput
          extraClassNames={['hide-calendar-inputs']}
          onChange={newDate => {
            setCalendarOpen(false);
            setDate(moment(newDate).toISOString(true));
            dispatch(
              getFindBets({
                book_ids:
                  selectedBooks[0] === 'My Sportsbooks'
                    ? user.books.map(b => b.id)
                    : selectedBooks,
                leagues:
                  selectedLeagues.length === 0
                    ? LEAGUES.map(l => l.league)
                    : selectedLeagues,
                periods: selectedPeriods.split(','),
                date: moment(newDate).toISOString(true),
                odd_types: selectedOddTypes,
              })
            );
          }}
          isOpen={calendarOpen}
        />
        <Col style={{ flex: 0.66, paddingRight: 'var(--space-sm)' }}>
          <BooksDropdown
            defaultValue={{ value: selectedBooks, label: 'My Sportsbooks' }}
            additionalOptions={[
              { value: 'My Sportsbooks', label: 'My Sportsbooks' },
            ]}
            onChange={val => {
              setSelectedBooks([val.value]);
              dispatch(
                getFindBets({
                  book_ids:
                    val.value === 'My Sportsbooks'
                      ? user.books.map(b => b.id)
                      : [val.value],
                  leagues:
                    selectedLeagues.length === 0
                      ? LEAGUES.map(l => l.league)
                      : selectedLeagues,
                  periods: selectedPeriods.split(','),
                  date: date,
                  odd_types: selectedOddTypes,
                })
              );
            }}
          />
        </Col>
        <Col style={{ flex: 0.5, paddingRight: 'var(--space-sm)' }}>
          <CustomSelect
            defaultValue={{ value: selectedOddTypes[0], label: 'Regular Odds' }}
            options={[
              { value: 'regular', label: 'Regular Odds' },
              { value: 'playerprop', label: 'Player Props' },
            ]}
            onChange={val => {
              setSelectedOddTypes([val.value]);
              dispatch(
                getFindBets({
                  book_ids:
                    selectedBooks[0] === 'My Sportsbooks'
                      ? user.books.map(b => b.id)
                      : selectedBooks,
                  leagues:
                    selectedLeagues.length === 0
                      ? LEAGUES.map(l => l.league)
                      : selectedLeagues,
                  periods: selectedPeriods.split(','),
                  date: date,
                  odd_types: [val.value],
                })
              );
            }}
          />
        </Col>
        <Col style={{ flex: 0.33, paddingRight: 'var(--space-sm)' }}>
          <CustomSelect
            defaultValue={{ value: selectedPeriods[0], label: 'Fulltime' }}
            options={[
              { value: 'FT', label: 'Fulltime' },
              { value: '1H,F5,1P,1S', label: 'Halftime' },
            ]}
            onChange={val => {
              setSelectedPeriods(val.value);
              dispatch(
                getFindBets({
                  book_ids:
                    selectedBooks[0] === 'My Sportsbooks'
                      ? user.books.map(b => b.id)
                      : selectedBooks,
                  leagues:
                    selectedLeagues.length === 0
                      ? LEAGUES.map(l => l.league)
                      : selectedLeagues,
                  periods: val.value.split(','),
                  date: date,
                  odd_types: selectedOddTypes,
                })
              );
            }}
          />
        </Col>
        <LeagueSelector
          schedule
          callback={leagues => {
            if (leagues.length === 0) leagues = LEAGUES.map(l => l.league);
            setSelectedLeagues(leagues);
            dispatch(
              getFindBets({
                book_ids:
                  selectedBooks[0] === 'My Sportsbooks'
                    ? user.books.map(b => b.id)
                    : selectedBooks,
                leagues: leagues,
                periods: selectedPeriods.split(','),
                date: date,
                odd_types: selectedOddTypes,
              })
            );
          }}
        />
      </Toolbar>
      <MainAreaWrapper>
        <InnerMainArea>
          <ScrollContainer id="infinite-scroll-target-games">
            <InfiniteScroll
              style={{ minHeight: '100vh', padding: 'var(--space-xs) 0' }}
              scrollableTarget={'infinite-scroll-target-games'}
              scrollThreshold={0.5}
              next={reccOddsNext ? () => _getNext() : null}
              hasMore={reccOddsNext !== null}
              dataLength={reccOdds?.length}
              loader={<ActivityIndicator />}
            >
              {isLoadingReccOdds && <ActivityIndicator />}
              {!isLoadingReccOdds && reccOdds?.length === 0 && (
                <p>No good odds. Try another sportsbook or league.</p>
              )}
              {reccOdds &&
                reccOdds.map((ro, i) => (
                  <ReccOdd key={`rec-odd-${i}`} odd={ro} user={user} />
                ))}
              {!isLoadingReccOdds &&
                reccOdds?.length > 0 &&
                reccOddsNext &&
                isLoadingReccOddsNext && <AuthButton>Load More</AuthButton>}
            </InfiniteScroll>
          </ScrollContainer>
        </InnerMainArea>
      </MainAreaWrapper>
    </Col>
  );
}

function ReccOdd({ odd, user }) {
  const reduxProps = useSelector(state => ({
    allBooksMap: state.authReducer.allBooksMap,
  }));
  const { allBooksMap } = reduxProps;

  if (!allBooksMap) return null;

  if (odd.is_blurred) return null;

  return (
    <Row
      style={{
        borderBottom: '1px solid var(--color-fg)',
        padding: 'var(--space-md) var(--space-xs)',
        borderRadius: 'var(--std-border-radius)',
        alignItems: 'center',
      }}
    >
      <StarRating
        style={{
          flex: 0,
          padding: 'var(--space-xxs)',
          paddingRight: 'var(--space-md)',
          alignItems: 'flex-start',
          flexWrap: 'nowrap',
        }}
        rating={odd.rating}
        edge={odd.edge}
      />
      <BetLineLogos
        style={{ flex: 2 }}
        large={odd.type_name !== 'Player Prop'}
        bet={{
          ...odd,
          player: { id: odd?.player_id, name: odd?.player_name },
          game: {
            id: odd?.game_id,
            league: odd.league,
            home_team: { name: odd?.home_team },
            away_team: { name: odd?.away_team },
          },
        }}
        odds_preference={user.odds_preference}
      />
      <Row
        style={{ flex: 0.5, alignItems: 'center', justifyContent: 'center' }}
      >
        <TeamLeagueLogo style={{ flex: 0 }} league={odd?.league} />
        <span>
          {odd.away_team} @ {odd?.home_team}
        </span>
      </Row>
      <Col style={{ flex: 1, alignItems: 'center' }}>
        <a
          title={`View Game | ${odd.away_team} @ ${odd?.home_team}`}
          href={`/games/${odd.game_id}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          View Game
        </a>
      </Col>
      {odd.book_ids.map(bid => (
        <img
          key={`rec-odd-book-${bid}`}
          alt={allBooksMap[odd.book_id]?.name}
          width={32}
          height={32}
          style={{ borderRadius: '4px', marginRight: 'var(--space-xs)' }}
          src={allBooksMap[bid].logo}
        />
      ))}

      <span style={{ flex: 0.75 }}>
        {moment(odd.date).format('ddd, MMM D @ h:mma')}
      </span>
      {/*<OddBtn
        odd={{
          ...odd,
          book: allBooksMap[odd.book_id],
          game: {
            id: odd?.game_id,
            league: odd.league,
            home_team: { name: odd?.home_team },
            away_team: { name: odd?.away_team },
          },
        }}
        textOverride={'Track'}
        btnTheme="borderless"
      />*/}
      {/*<ExternalPlaceBetAtBook
        book_id={odd.book_id}
        betslipInfo={{ ...odd, risk_amount: 10 }}
        showExternalLinkIcon
        colorTheme="inverted"
        containerStyle={{ flex: 1.5 }}
        fallbackComponent={<div style={{ flex: 1.5 }} />}
      />*/}
    </Row>
  );
}

function StarRating({ rating, style, edge }) {
  const One = rating > 0 ? IoStar : IoStarOutline;
  const Two = rating > 1 ? IoStar : IoStarOutline;
  const Three = rating > 2 ? IoStar : IoStarOutline;
  return (
    <>
      <Row
        style={style}
        title={`${Math.round((edge + Number.EPSILON) * 100) / 100}% Edge`}
      >
        <One
          color={'var(--color-complement'}
          name={rating > 0 ? 'star' : 'star-outline'}
          size={22}
        />
        <Two
          color={'var(--color-complement'}
          name={rating > 1 ? 'star' : 'star-outline'}
          size={22}
        />
        <Three
          color={'var(--color-complement'}
          name={rating > 2 ? 'star' : 'star-outline'}
          size={22}
        />
      </Row>
      <Row style={{ flex: 0.6, justifyContent: 'flex-start' }}>
        <span>({Math.round((edge + Number.EPSILON) * 100) / 100}% edge)</span>
      </Row>
    </>
  );
}
