import { Observable } from 'rxjs';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import { ajax } from 'rxjs/observable/dom/ajax';
import { BACKEND_API_URL, getHeaders } from 'utils';

import { GET_ADS, getAdsSuccess, getAdsFailure } from 'actions';

export default function getAds(action$, state$) {
  return action$.ofType(GET_ADS).switchMap(() => {
    let url = BACKEND_API_URL + `api/ad_banners/`;
    return ajax
      .getJSON(url, getHeaders(state$))
      .map(data => getAdsSuccess(data))
      .catch(error => Observable.of(getAdsFailure(error.xhr)));
  });
}
