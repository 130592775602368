export default {
  link_to_video: '',
  content: [
    {
      type: 'youtube-video',
      url: 'https://www.youtube.com/watch?v=yQzFAx8br9U',
    },
    {
      type: 'paragraph',
      text: 'How do pro bettors "win"?',
    },
    {
      type: 'paragraph',
      text: 'Do they accurately predict the outcomes of every single game?',
    },
    {
      type: 'paragraph',
      text: 'Do they watch public / sharp money come into each event like a hawk?',
    },
    {
      type: 'paragraph',
      text: 'Do they have inside information?',
    },
    {
      type: 'paragraph',
      text: 'Besides the myth that is public vs. sharp money, the answer may be a combination of handicapping and being quick to inside information. But for us at betstamp…',
    },
    {
      type: 'paragraph',
      text: 'We help bettors make money betting markets like player props. In fact, a long-time betstamp user won over $40,000 simply using a tool available to all of you bettors, TODAY. On our app and website, YOU can now use our player prop comparison tool to find good bets that put you in a position to win more MONEY in the long term. The player prop comparison tool can be found on our app or website by clicking into a “game”, clicking “player props” and then sifting through the various markets on players from either team within that respective matchup.',
    },
    {
      type: 'image',
      url: 'https://betstamp-public.sfo2.cdn.digitaloceanspaces.com/tutorials/props-1.jpeg',
      maxWidth: '80%',
    },
    {
      type: 'paragraph',
      text: 'Let’s give an example on just how useful our player prop comparison tool is.',
    },
    {
      type: 'paragraph',
      text: 'Take Jose Ramirez, as an example. Fantastic ball player, MVP candidate, on a “meh” Guardians roster.',
    },
    {
      type: 'paragraph',
      text: 'Let’s say I’m looking to bet Jose Anytime HR for today’s game in Colorado versus the Rockies. Sifting through the various books listed under betstamp’s prop comp tool, I find it for +325 at Book A and +500 at Book B.',
    },
    {
      type: 'paragraph',
      text: 'On a $100 wager, Book A returns $325. Book B returns $500. That is an extra $175 just by having a few sportsbooks and shopping for the BEST price in market. +325 gives an implied probability of 23.5%, meaning this bet needs to hit around 24% of the time to make us money. However, +500 gives an implied probability of only 16.67%, meaning this SAME bet only needs to hit around 17% of the time to make us money. There is SERIOUS value in line shopping for the best price – betstamp makes this possible (and easy).',
    },
    {
      type: 'paragraph',
      text: 'So throughout this baseball season, and into football season, use our player prop comparison tool. Look for bets you want to make, or look for off market prices. Doesn’t matter to us. What matters is making money and having fun, and you can’t have fun without making money.',
    },
  ],
};
