export default function parlayCompatCheck(bets) {
  let newBets = [];
  let seen_parlay_compat_ids = {};
  let isValid = true;
  for (let i = 0; i < bets.length; i++) {
    const bet = bets[i];

    if (seen_parlay_compat_ids[bet.parlayCompatID]) {
      seen_parlay_compat_ids[bet.parlayCompatID] += 1;

      isValid = false;
    } else {
      seen_parlay_compat_ids[bet.parlayCompatID] = 1;
    }
    // reset errors, they'll get set after
    newBets.push({ ...bet, requires_action: false, errors: null });
  }

  newBets = newBets.map(bet => {
    if (seen_parlay_compat_ids[bet.parlayCompatID] > 1) {
      return {
        ...bet,
        requires_action: true,
        errors: {
          Error: `This bet conflicts with another bet on your betslip`,
        },
      };
    }
    return bet;
  });
  return { newBets: newBets, isValid: isValid };
}
