import React, { useEffect } from 'react';
import { Redirect, useLocation, useHistory } from 'react-router';

// components
import ActivityIndicator from 'components/generic/ActivityIndicator';
import { Col } from 'components/generic/Layout';
import { AuthButton } from 'components/AuthButton';

// constants
import { AUTH_BUTTON_DEFAULT_MAX_WIDTH_STYLE } from 'utils';

export default function Outlink() {
  const history = useHistory();
  const location = useLocation();

  const { state } = location;
  const bookname = state?.bookname;
  const link = state?.link;

  // I really, really hate that this is here, technically it could get run every
  // re-render. Apple doesnt allow window.open in async functions (like useEffect)
  // therefore this has to go here. Luckily, this page only seems to render once
  // so everything seems to work
  const openAd = l => window.open(l, '_blank');
  openAd(link);

  useEffect(() => {
    if (link) {
      //window.open(link, '_blank');
      const to = setTimeout(() => {
        history.goBack();
      }, 5000);
      return () => clearTimeout(to);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [link]);

  if (!link) {
    return <Redirect to="/games" />;
  }

  return (
    <Col style={{ height: '100%', justifyContent: 'center' }}>
      <div>
        <ActivityIndicator size={2} />
      </div>
      <h4 style={{ margin: 0, textAlign: 'center' }}>
        Please wait while we redirect you to {bookname}
      </h4>
      <p style={{ textAlign: 'center' }}>
        Please disable your adblocker and try again if you are not automatically
        redirected after 3 seconds or try clicking the button below
      </p>

      <AuthButton
        containerStyle={{
          ...AUTH_BUTTON_DEFAULT_MAX_WIDTH_STYLE,
          flex: 0,
        }}
        onPress={() => window.open(link, '_blank')}
      >
        Sign up at {bookname}
      </AuthButton>

      <br />
      <br />
      <AuthButton
        containerStyle={{
          ...AUTH_BUTTON_DEFAULT_MAX_WIDTH_STYLE,
          flex: 0,
        }}
        onPress={() => history.goBack()}
      >
        Go Back
      </AuthButton>
    </Col>
  );
}
