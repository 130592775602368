import { Observable } from 'rxjs';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import { ajax } from 'rxjs/observable/dom/ajax';
import moment from 'moment';
import { BACKEND_API_URL, getHeaders } from 'utils';

import { GET_SCHED, setSched, getSchedSuccess, getSchedFailure } from 'actions';

function _schedIsCached(date, cachedSchedule, newCacheMetaData) {
  const isCached = cachedSchedule[date.substr(0, 10)] !== undefined;

  // cache miss!
  if (!isCached) return 'miss';

  const oldCachedMetadata = cachedSchedule[date.substr(0, 10)].cacheMetaData;

  if (!oldCachedMetadata) return 'miss';

  // it's in the cache, check how hold it is
  const cachedDate = oldCachedMetadata.cachedAt;
  const rightNow = moment();
  const duration = moment.duration(rightNow.diff(cachedDate));
  const cacheIsOld = duration.asSeconds() > 60;

  // too old, cache miss!
  if (cacheIsOld) return 'old';

  if (
    oldCachedMetadata.searchTerm !== newCacheMetaData.searchTerm ||
    oldCachedMetadata.selectedBook !== newCacheMetaData.selectedBook ||
    oldCachedMetadata.sort !== newCacheMetaData.sort ||
    oldCachedMetadata.filter !== newCacheMetaData.filter
  ) {
    return 'miss';
  }

  // cache hit!
  return 'hit';
}

export default function getSched(action$, state$) {
  return action$.ofType(GET_SCHED).mergeMap(action => {
    const { schedule, selectedLeagues, selectedBook, searchterm } =
      state$.value.scheduleReducer;

    let { leagues, date, book, searchTerm, preload } = action;

    let act_leagues = leagues || selectedLeagues;
    let act_book = book || selectedBook;
    let act_search =
      Boolean(searchTerm) || searchTerm === '' ? searchTerm : searchterm;

    date = moment(action.date).toISOString(true);

    let url =
      BACKEND_API_URL + `api/schedule/?date=${date.replace('+', '%2B')}`;

    url += `&leagues=${JSON.stringify(act_leagues)}`;

    if (act_book.id === -1) {
      // THIS NEVER NEEDS TO BE TRUE ON THE APP SINCE
      // WE DON'T SHOW HALFTIME ON THE MAIN SCREEN
      url += `&forceBAHT=true`;
    } else {
      url += `&book=${act_book.id}`;
    }

    url += `&forceBA=true`;

    const { defaultGameSortBy, defaultGameFilter, gameSortBy, gameFilter } =
      state$.value.settingsReducer;

    const currGameSortBy = gameSortBy ? gameSortBy : defaultGameSortBy;
    const currGameFilter = gameFilter ? gameFilter : defaultGameFilter;

    url += `&sort_by=${currGameSortBy}`;

    if (currGameFilter === 'only_live_games') {
      url += `&is_live=${true}`;
    } else if (currGameFilter === 'only_unstarted_games') {
      url += `&is_live=${false}&is_complete=${false}`;
    }

    if (act_search && act_search.trim() !== '') {
      url += `&search=${act_search.trim()}`;
    }

    if (action.exact) {
      url += '&exact=true';
    }

    const cacheMetaData = {
      cachedAt: moment(),
      searchTerm: act_search,
      selectedBook: act_book,
      sort: currGameSortBy,
      filter: currGameFilter,
    };

    // if one of those things is given, don't check the cache, just re-get it
    if (
      !(
        action.leagues ||
        action.book ||
        action.searchTerm ||
        action.searchTerm === '' ||
        action.refresh === true
      )
    ) {
      let cacheStatus = _schedIsCached(action.date, schedule, cacheMetaData);

      if (cacheStatus === 'hit') {
        return Observable.of(setSched(action.date));
      }
    }

    return ajax
      .getJSON(url, getHeaders(state$))
      .map(data => getSchedSuccess(data, preload, false, cacheMetaData))
      .catch(error => Observable.of(getSchedFailure(error.xhr)));
  });
}
