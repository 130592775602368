import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { IoIosSearch } from 'react-icons/io';
import InfiniteScroll from 'react-infinite-scroll-component';

// components
import { AuthTextInput } from 'components/AuthTextInput';
import ActivityIndicator from 'components/generic/ActivityIndicator';

import { search, getFollowing, getUsersNext } from 'actions';
import UsersListItem from './UsersListItem';

const Wrapper = styled.div`
  flex: 1;
  position: relative;
  // margin-top: var(--space-sm);
`;

const ItemsWrapper = styled.div`
  position: relative;
`;

const Items = styled.div`
  position: absolute;
  top: calc(100% + var(--space-xs));
  width: 100%;
  padding-bottom: 1rem;
  padding-top: 1rem;
  z-index: 1000;
  background: var(--color-fg);
  border: 1px solid var(--color-shadow);
  border-radius: var(--btn-border-radius);
  box-shadow: 0 2px 6px var(--color-shadow);
  transition: all var(--std-transition);
  max-height: 500px;
  overflow-y: auto;
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.075);
`;

export default function SearchBarUsers(props) {
  // redux props
  const reduxProps = useSelector(state => ({
    searchResults: state.searchReducer.searchResults,
    isLoading: state.searchReducer.isLoading,
    searchUsersNext: state.searchReducer.searchUsersNext,
  }));

  // redux dispatch
  const dispatch = useDispatch();

  const { searchResults, isLoading, searchUsersNext } = reduxProps;

  const [searchTerm, setSearchTerm] = useState('');
  const [followedUser, setFollowedUser] = useState(false);
  const open = searchTerm.trim() !== '';

  // only search after user stops typing for 0.5 seconds
  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(search(searchTerm));
    }, 500);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  const _getUsersNext = () => {
    if (searchUsersNext) {
      dispatch(getUsersNext());
    }
  };

  return (
    <>
      {open && (
        <Overlay
          onClick={() => {
            setSearchTerm('');
            dispatch(search(''));
            if (followedUser) {
              dispatch(getFollowing());
              setFollowedUser(false);
            }
          }}
        />
      )}
      <Wrapper>
        <AuthTextInput
          placeholder="Search Users"
          onChangeText={newSearchVal => {
            setSearchTerm(newSearchVal);
          }}
          rightIcon={IoIosSearch}
          rightIconColor={'var(--color-text)'}
        />
        {isLoading && (
          <ItemsWrapper>
            <Items>
              <ActivityIndicator size={1.5} />
            </Items>
          </ItemsWrapper>
        )}

        {searchResults.length === 0 && open && !isLoading && (
          <ItemsWrapper>
            <Items>
              <h6
                style={{
                  marginTop: 'var(--space-xxs)',
                  marginLeft: 'var(--space-sm)',
                }}
              >
                No results. Search betstamp users by name or username.
              </h6>
            </Items>
          </ItemsWrapper>
        )}
        {open && searchResults.length !== 0 && !isLoading && (
          <ItemsWrapper>
            <Items id="scrollableItems">
              <InfiniteScroll
                scrollableTarget="scrollableItems"
                next={_getUsersNext}
                hasMore={searchUsersNext}
                dataLength={searchResults.length}
                loader={<ActivityIndicator size={1.5} />}
                style={{ overflow: 'hidden', padding: '0 var(--space-sm)' }}
              >
                {searchResults.map(item => (
                  <UsersListItem
                    key={`user-search-result-${item.id}`}
                    user={item}
                    showFollowButton
                    showStats={[]}
                  />
                ))}
              </InfiniteScroll>
            </Items>
          </ItemsWrapper>
        )}
      </Wrapper>
    </>
  );
}
