import React from 'react';

// components
import { Row } from 'components/generic/Layout';
import { AuthButton } from 'components/AuthButton';

const VERT_PADD = 'var(--space-xs)';
const HORI_PADD = 'var(--space-xxs)';
const RADIUS = '8px';

export default function DisplayToggle(props) {
  const { options, active, flexValues } = props;
  return (
    <Row
      style={{
        flex: 0,
        backgroundColor: 'var(--color-fg)',
        padding: `${VERT_PADD} ${HORI_PADD}`,
        borderRadius: RADIUS,
        alignItems: 'stretch',
        ...props.style,
      }}
    >
      {options.map((option, i) => {
        return (
          <AuthButton
            key={`display-toggle-btn-${option}`}
            btnTheme="borderless"
            colorTheme={active === option ? 'inverted' : 'primary'}
            containerStyle={{
              flex: flexValues ? flexValues[i] : 1,
              margin: 0,
              borderRadius: RADIUS,
              zIndex: 2,
              ...props.containerStyle,
            }}
            btnStyle={{
              padding: 'var(--space-xs) 0',
              borderRadius: RADIUS,
              ...props.btnStyle,
            }}
            textStyle={{ textAlign: 'center' }}
            onPress={() => {
              props.onPress(option);
            }}
          >
            {option.toUpperCase()}
          </AuthButton>
        );
      })}
    </Row>
  );
}
