import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useEffectOnce, useUpdateEffect } from 'react-use';

// actual screens
import GamesDesktop from 'pages/Games/GamesDesktop';
import GamesMobile from 'pages/Games/GamesMobile';

// components
import { Col } from 'components/generic/Layout';

// actions
import { getSched, setParlayValue, betslipChangeBookParlay } from 'actions';

export default function Games() {
  const dispatch = useDispatch();
  const reduxProps = useSelector((state) => ({
    showingDate: state.scheduleReducer.showingDate,
    schedule: state.scheduleReducer.schedule,
    mode: state.betslipReducer.mode,
    user: state.authReducer.user,
    allBooks: state.authReducer.allBooks,
    bets: state.betslipReducer.bets,
  }));

  const { showingDate, schedule, mode, user, allBooks, bets } = reduxProps;

  useEffectOnce(() => {
    dispatch(
      getSched(
        null, // leagues
        showingDate, // date
        null, // book
        null // search term
      )
    );
  });

  // preload prev
  // useUpdateEffect(() => {
  //   if (schedule[showingDate.substr(0, 10)]) {
  //     const prevDate = schedule[showingDate.substr(0, 10)].prevEventDate;
  //     if (prevDate && !schedule[prevDate.substr(0, 10)]) {
  //       dispatch(getSched(null, prevDate, null, null, true));
  //     }
  //   }
  // }, [showingDate]);

  // // preload next
  // useUpdateEffect(() => {
  //   if (schedule[showingDate.substr(0, 10)]) {
  //     const nextDate = schedule[showingDate.substr(0, 10)].nextEventDate;
  //     if (nextDate && !schedule[nextDate.substr(0, 10)]) {
  //       dispatch(getSched(null, nextDate, null, null, true));
  //     }
  //   }
  // }, [showingDate]);

  const findDefaultBook = () => {
    let defaultParlayBook = allBooks[3];
    if (user) {
      for (let x = 0; x < user.books.length; x++) {
        if (!user.books[x].is_custom) {
          defaultParlayBook = user.books[x];
          break;
        }
      }
    }
    return defaultParlayBook;
  };

  useUpdateEffect(() => {
    if (mode === 'parlay') {
      let parlayBook;

      // check to see if all the bets on the slip are already at the same book
      let allBetsSameBook = true;
      let currBookID = null;
      for (let i = 0; i < bets.length; i++) {
        if (currBookID === null) currBookID = bets[i].book.id;
        if (currBookID !== bets[i].book.id) allBetsSameBook = false;
      }

      if (allBetsSameBook && currBookID !== null) parlayBook = bets[0].book;
      else parlayBook = findDefaultBook();

      dispatch(setParlayValue('book', parlayBook));

      dispatch(
        getSched(
          null, // leagues
          showingDate, // date
          parlayBook, // book
          null // search term
        )
      );

      if (bets.length > 0) {
        dispatch(betslipChangeBookParlay(parlayBook));
      }
    }
  }, [mode]);

  return (
    <>
      <Col
        desktop
        style={{ height: '100vh', flexWrap: 'nowrap', overflow: 'hidden' }}
      >
        <GamesDesktop />
      </Col>
      <Col mobile style={{ padding: '0 var(--space-xxxs)' }}>
        <GamesMobile />
      </Col>
    </>
  );
}
