import { Observable } from 'rxjs';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import { ajax } from 'rxjs/observable/dom/ajax';
import { BACKEND_API_URL, getHeaders } from 'utils';

import {
  RETRIEVE_WAGER,
  retrieveWagerSuccess,
  retrieveWagerFailure,
} from 'actions';

export default function retrieveWager(action$, state$) {
  return action$.ofType(RETRIEVE_WAGER).exhaustMap(action => {
    let url = `${BACKEND_API_URL}api/wagers/${action.wager_id}/`;

    return ajax
      .getJSON(url, getHeaders(state$))
      .map(data => retrieveWagerSuccess(data))
      .catch(error => Observable.of(retrieveWagerFailure(error.xhr)));
  });
}
