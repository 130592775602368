import { Observable } from 'rxjs';
import 'rxjs/add/operator/exhaustMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import { ajax } from 'rxjs/observable/dom/ajax';
import { BACKEND_API_URL, getHeaders } from 'utils';

import { LOGOUT_USER, logoutUserSuccess, logoutUserFailure } from 'actions';

export default function logoutUser(action$, state$) {
  return action$.ofType(LOGOUT_USER).exhaustMap(() => {
    return ajax
      .post(BACKEND_API_URL + 'api/logout/', {}, getHeaders(state$))
      .map(data => logoutUserSuccess(data))
      .catch(error => Observable.of(logoutUserFailure(error.xhr)));
  });
}
