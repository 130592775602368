import React from 'react';
import styled from 'styled-components';

// components
import { Row } from 'components/generic/Layout';
import ProfilePic from 'components/generic/ProfilePic';

const Wrapper = styled.button`
  font-size: var(--text-base-size);
  outline: none;
  cursor: pointer;
  text-align: left;
  font-weight: normal;
  color: var(--color-text);

  border-radius: var(--std-border-radius);
  padding: var(--space-xs) var(--space-sm);
  border: 2px solid var(--color-complement);
  background-color: var(--color-fg);
  transition: all var(--std-transition);

  flex: 1;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: flex-start;

  &:hover {
    box-shadow: 0 2px 8px 0 var(--color-shadow);
  }

  &:active {
    box-shadow: inset 0 0 0 100vh rgba(0, 0, 0, 0.1);
  }
`;

export default function FeaturedPackage(props) {
  const { pack } = props;
  return (
    <Wrapper style={props.style} onClick={props.onClick}>
      <Row
        style={{
          flex: 0,
          alignItems: 'center',
          justifyContent: 'flex-start',
          marginBottom: 'var(--space-xs)',
        }}
      >
        <ProfilePic
          user={{
            username: pack.package_owner,
            profile_picture: pack.package_owner_profile_pic,
          }}
        />
        <span>&nbsp;{pack.package_owner}</span>
      </Row>

      <h6 style={{ flex: 0, fontWeight: 'bold' }}>
        {pack.title.substr(0, 78)}
        {pack.title.length > 78 ? '...' : ''}
      </h6>

      <Row style={{ flex: 1, alignItems: 'flex-end' }}>
        <b>${pack.cost}</b>/{pack.billing_freq}
      </Row>
    </Wrapper>
  );
}
