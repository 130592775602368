import { Observable } from 'rxjs';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import { ajax } from 'rxjs/observable/dom/ajax';
import { getHeaders } from 'utils';

import {
  GET_FUTURES_SCHED_NEXT,
  getFuturesSchedSuccess,
  getFuturesSchedFailure,
} from 'actions';

export default function getFuturesSchedNext(action$, state$) {
  return action$.ofType(GET_FUTURES_SCHED_NEXT).switchMap(() => {
    const { futuresSchedNext } = state$.value.scheduleReducer;
    return ajax
      .getJSON(futuresSchedNext, getHeaders(state$))
      .map(data => getFuturesSchedSuccess(data, false, true))
      .catch(error => Observable.of(getFuturesSchedFailure(error.xhr)));
  });
}
