import {
  GET_ANALYSIS,
  GET_ANALYSIS_SUCCESS,
  GET_ANALYSIS_FAILURE,
  GET_ANALYSIS_PRESETS_NEXT,
  RESET_ERRORS_ANALYSIS,
  GET_ANALYSIS_PRESETS,
  GET_ANALYSIS_PRESETS_SUCCESS,
  GET_ANALYSIS_PRESETS_FAILURE,
  SET_ANALYSIS_FILTER,
  RESET_ANALYSIS_FILTER,
  CREATE_ANALYSIS_PRESET,
  CREATE_ANALYSIS_PRESET_SUCCESS,
  CREATE_ANALYSIS_PRESET_FAILURE,
  RESET_CREATE_ANALYSIS_PRESET_SUCCESS,
  EDIT_ANALYSIS_PRESET,
  EDIT_ANALYSIS_PRESET_SUCCESS,
  EDIT_ANALYSIS_PRESET_FAILURE,
  DELETE_ANALYSIS_PRESET,
  DELETE_ANALYSIS_PRESET_SUCCESS,
  DELETE_ANALYSIS_PRESET_FAILURE,
  SET_OTHER_USER,
  SET_ANALYSIS,
  GET_BET_SELECTION,
  GET_BET_SELECTION_SUCCESS,
  GET_BET_SELECTION_FAILURE,
  GET_BET_SELECTION_NEXT,
  GET_GRAPH_DATA,
  GET_GRAPH_DATA_SUCCESS,
  GET_GRAPH_DATA_FAILURE,
  GET_TAGS,
  GET_TAGS_SUCCESS,
  GET_TAGS_FAILURE,
  GET_TAGS_NEXT,
} from 'actions';

import { ANALYSIS_FILTERS } from 'utils/ANALYSIS_CONSTANTS';
import { paramsToFilterObject } from 'utils';
import moment from 'moment';

const initialState = {
  // the params currently being displayed
  currentUrlParams: '',
  currentAnalysis: null,
  shouldFetchBetsInitial: false,
  cachedAnalysis: {},
  // presets
  presets: [],
  presetsNext: null,
  presetCount: 0,
  isLoadingPresets: false,
  isLoadingPresetsNext: false,
  selectedPresetIndex: -1,
  presetsFetchError: null,
  isPostingPreset: false,
  postPresetError: null,
  postPresetSuccess: null,
  isDeletingPreset: false,
  deletePresetError: null,
  deletePresetSuccess: false,
  isLoading: false,
  filters: {
    ...ANALYSIS_FILTERS,
  },
  // betselection
  isLoadingBets: false,
  fetchError: null,
  isLoadingBetSelection: false,
  isLoadingBetSelectionNext: false,
  betSelectionFailure: null,
  //graph data
  graphData: null,
  isLoadingGraphData: false,
  graphDataFailure: null,
  graphExtraFilters: null,
  graphExtraFiltersSlug: '',
  graphData2: null,
  // the params being built up
  urlparams: '?',
  // tags
  tags: [],
  tagsNext: null,
  isLoadingTags: false,
  isLoadingTagsNext: false,
  tagsFetchError: null,
  tagsCount: 0,
};

export default function analysisReducer(state = initialState, action) {
  switch (action.type) {
    case SET_ANALYSIS:
      return Object.assign({}, state, {
        ...state,
        fetchError: null,
        currentUrlParams: action.urlparams,
        urlparams: action.urlparams,
        filters: paramsToFilterObject(action.urlparams),
        isLoading: false,
        isLoadingBets: false,
        currentAnalysis: action.analysis,
        shouldFetchBetsInitial:
          action.analysis.bets === undefined ||
          action.analysis.bets.length === 0,
      });

    case GET_ANALYSIS:
      return Object.assign({}, state, {
        ...state,
        fetchError: null,
        currentUrlParams: action.urlparams,
        urlparams: action.urlparams,
        filters: paramsToFilterObject(action.urlparams),
        isLoading: action.tab === 'table',
        isLoadingBets: action.tab === 'bets',
        shouldFetchBetsInitial:
          action.tab === undefined || action.tab === 'table',
        selectedPresetIndex: action.selectedPresetIndex,
      });
    case GET_ANALYSIS_SUCCESS:
      let newCurrentAnalysis = {
        ...action.payload,
        cachedAt: moment(),
        user: action.payload.user,
        bets: [], // the bets sent from the server for backwards compat reasons need to be nullified here
      };

      let newCachedAnalysis = { ...state.cachedAnalysis };
      newCachedAnalysis[action.fullURL] = newCurrentAnalysis;

      return Object.assign({}, state, {
        ...state,
        isLoading: false,
        isLoadingBets: false,
        currentAnalysis: newCurrentAnalysis,
        cachedAnalysis: newCachedAnalysis,
      });
    case GET_ANALYSIS_FAILURE:
      return Object.assign({}, state, {
        ...state,
        fetchError: action.error,
        isLoading: false,
        isLoadingBets: false,
      });

    case RESET_ERRORS_ANALYSIS:
      return Object.assign({}, state, {
        ...state,
        fetchError: null,
        isLoading: false,
        presetsFetchError: null,
      });

    case GET_ANALYSIS_PRESETS:
      return Object.assign({}, state, {
        ...state,
        presetsFetchError: null,
        isLoadingPresets: true,
        presets: [],
      });

    case GET_ANALYSIS_PRESETS_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        presets: state.presets.concat(action.payload.results),
        isLoadingPresets: false,
        presetsNext: action.payload.next,
        presetCount: action.payload.count,
        isLoadingPresetsNext: false,
      });
    case GET_ANALYSIS_PRESETS_FAILURE:
      return Object.assign({}, state, {
        ...state,
        presetsFetchError: action.payload,
        isLoadingPresets: false,
      });
    case GET_ANALYSIS_PRESETS_NEXT:
      return Object.assign({}, state, {
        ...state,
        presetsFetchError: null,
        isLoadingPresetsNext: true,
      });

    case SET_ANALYSIS_FILTER:
      const newFilters = { ...state.filters };
      newFilters[action.data.filter] = { ...action.data };
      const filterName = action.data.filter.toLowerCase();
      let newParams = '';
      if (state.urlparams.includes(filterName)) {
        const re = new RegExp(filterName + '(.*?&)', 'g');
        newParams = state.urlparams.replace(
          re,
          filterName + '=' + action.data.value.toString() + '&'
        );
      } else {
        newParams =
          state.urlparams +
          filterName +
          '=' +
          action.data.value.toString() +
          '&';
      }
      return Object.assign({}, state, {
        ...state,
        filters: newFilters,
        urlparams: newParams,
      });

    case RESET_ANALYSIS_FILTER:
      return Object.assign({}, state, {
        ...state,
        filters: { ...ANALYSIS_FILTERS },
        urlparams: '?',
      });

    case CREATE_ANALYSIS_PRESET:
      return Object.assign({}, state, {
        ...state,
        isPostingPreset: true,
        postPresetError: null,
        postPresetSuccess: null,
      });
    case CREATE_ANALYSIS_PRESET_SUCCESS:
      let prependPreset = Array.from(state.presets);
      prependPreset.unshift(action.payload.response);
      return Object.assign({}, state, {
        ...state,
        isPostingPreset: false,
        postPresetError: null,
        presets: prependPreset,
        postPresetSuccess: true,
      });
    case CREATE_ANALYSIS_PRESET_FAILURE:
      return Object.assign({}, state, {
        ...state,
        isPostingPreset: false,
        postPresetError: action.error,
        postPresetSuccess: null,
      });
    case RESET_CREATE_ANALYSIS_PRESET_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        isPostingPreset: false,
        postPresetError: null,
        postPresetSuccess: null,
        isDeletingPreset: false,
        deletePresetError: null,
        deletePresetSuccess: false,
      });

    case EDIT_ANALYSIS_PRESET:
      return Object.assign({}, state, {
        ...state,
        isPostingPreset: true,
        postPresetError: null,
        postPresetSuccess: null,
      });
    case EDIT_ANALYSIS_PRESET_SUCCESS:
      let editPresets = Array.from(state.presets);
      editPresets = editPresets.map((p) => {
        if (p.id !== action.payload.id) {
          return p;
        } else return action.payload;
      });
      return Object.assign({}, state, {
        ...state,
        isPostingPreset: false,
        postPresetError: null,
        postPresetSuccess: true,
        presets: editPresets,
      });
    case EDIT_ANALYSIS_PRESET_FAILURE:
      return Object.assign({}, state, {
        ...state,
        isPostingPreset: false,
        postPresetError: action.error,
        postPresetSuccess: null,
      });

    case DELETE_ANALYSIS_PRESET:
      return Object.assign({}, state, {
        ...state,
        isDeletingPreset: true,
        deletePresetError: null,
        deletePresetSuccess: false,
      });
    case DELETE_ANALYSIS_PRESET_SUCCESS:
      let deletePreset = Array.from(state.presets);
      deletePreset = deletePreset.filter((p) => p.id !== action.presetid);
      return Object.assign({}, state, {
        ...state,
        isDeletingPreset: false,
        deletePresetError: null,
        deletePresetSuccess: true,
        presets: deletePreset,
      });
    case DELETE_ANALYSIS_PRESET_FAILURE:
      return Object.assign({}, state, {
        ...state,
        isDeletingPreset: false,
        deletePresetError: action.error,
        deletePresetSuccess: false,
      });

    case SET_OTHER_USER:
      return Object.assign({}, state, {
        ...state,
        currentAnalysis: null,
      });

    case GET_BET_SELECTION:
      return Object.assign({}, state, {
        ...state,
        isLoadingBetSelection: true,
      });
    case GET_BET_SELECTION_SUCCESS:
      let newCurrentAnalysis_bs = {
        ...state.currentAnalysis,
        betCount: action.payload.count,
        bets: action.isNext
          ? state.currentAnalysis.bets.concat(action.payload.results)
          : action.payload.results,
        next: action.payload.next,
        initialBetsLoaded: true,
      };

      let newCachedAnalysis_bs = { ...state.cachedAnalysis };
      newCachedAnalysis_bs[action.fullURL] = newCurrentAnalysis_bs;

      return Object.assign({}, state, {
        ...state,
        currentAnalysis: newCurrentAnalysis_bs,
        cachedAnalysis: newCachedAnalysis_bs,
        isLoadingBetSelection: false,
        isLoadingBetSelectionNext: false,
        betSelectionFailure: null,
      });
    case GET_BET_SELECTION_FAILURE:
      return Object.assign({}, state, {
        ...state,
        betSelectionFailure: action.error,
      });
    case GET_BET_SELECTION_NEXT:
      return Object.assign({}, state, {
        ...state,
        isLoadingBetSelectionNext: true,
      });

    case GET_GRAPH_DATA:
      return Object.assign({}, state, {
        ...state,
        isLoadingGraphData: true,
        graphDataFailure: null,
        graphExtraFilters: action.extraFilters,
        graphExtraFiltersSlug: action.extraFiltersSlug,
      });
    case GET_GRAPH_DATA_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        isLoadingGraphData: false,
        graphDataFailure: null,
        graphData:
          action.forGraph === 1 ? action.payload.data : state.graphData,
        graphData2:
          action.forGraph === 2 ? action.payload.data : state.graphData2,
      });
    case GET_GRAPH_DATA_FAILURE:
      return Object.assign({}, state, {
        ...state,
        isLoadingGraphData: false,
        graphDataFailure: action.error,
      });

    case GET_TAGS:
      return Object.assign({}, state, {
        ...state,
        tagsFetchError: null,
        isLoadingTags: true,
        tags: [],
      });
    case GET_TAGS_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        tags: state.tags.concat(action.payload.results),
        isLoadingTags: false,
        tagsNext: action.payload.next,
        tagsCount: action.payload.count,
      });
    case GET_TAGS_FAILURE:
      return Object.assign({}, state, {
        ...state,
        tagsFetchError: action.error,
        isLoadingTags: false,
      });
    case GET_TAGS_NEXT:
      return Object.assign({}, state, {
        ...state,
        tagsFetchError: null,
        isLoadingTagsNext: true,
      });

    default:
      return state;
  }
}
