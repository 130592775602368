import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  IoCheckmarkCircleOutline,
  IoPersonCircleOutline,
} from 'react-icons/io5';

import ResetEmail from 'components/modals/ResetEmail';
import DeactivateAccount from 'components/modals/DeactivateAccount';
import ChangePassword from 'components/modals/ChangePassword';
import ModalWrapper from 'components/generic/ModalWrapper';
import { AuthTextInput } from 'components/AuthTextInput';
import { AuthButton } from 'components/AuthButton';
import { Row, Col } from 'components/generic/Layout';
import { SwitchSetting } from 'components/SettingsBtn';
import ExperienceBox from 'components/ExperienceBox';
import ActivityIndicator from 'components/generic/ActivityIndicator';
import ProfilePic from 'components/generic/ProfilePic';
import Select from 'components/generic/Select';
import { sanitizeUrlText } from '../../../utils/urlTextModifiers';

import { STATE_OPTIONS, BACKEND_API_URL } from 'utils';

import {
  checkUsernameAvailable,
  resetErrors,
  setUserChanges,
  updateUser,
  logoutAll,
  cleanAutoSyncStore,
} from 'actions';

export default function EditProfile(props) {
  const dispatch = useDispatch();
  const reduxProps = useSelector(state => ({
    user: state.authReducer.user,
    usernameIsAvailable: state.authReducer.usernameIsAvailable,
    userToken: state.authReducer.userToken,
    isLoadingDeactivate: state.authReducer.isLoadingDeactivate,
    updateUserLoading: state.authReducer.updateUserLoading,
    userChanges: state.authReducer.userChanges,
    referralCodeExists: state.authReducer.referralCodeExists,
    updateUserSuccess: state.authReducer.updateUserSuccess,
  }));

  const {
    user,
    userToken,
    updateUserLoading,
    usernameIsAvailable,
    userChanges,
    referralCodeExists,
    updateUserSuccess,
    isLoadingDeactivate,
    isLoadingLogoutAll,
  } = reduxProps;

  const mergedUser = {
    ...user,
    ...userChanges,
  };

  const [changePasswordView, setChangePasswordView] = useState(false);
  const [changeEmailView, setChangeEmailView] = useState(false);
  const [deactivateModalOpen, setDeactivateModalOpen] = useState(false);
  const [linkErrorText, setLinkErrorText] = useState('');

  const disableSave =
    Object.keys(userChanges).length === 0 ||
    mergedUser.username.trim() === '' ||
    mergedUser.username.length < 4 ||
    mergedUser.username.length > 16 ||
    usernameIsAvailable !== '' ||
    linkErrorText.length > 0 ||
    (userChanges.referral_code && !referralCodeExists);

  let cantMakePrivate = user.packages.length > 0;

  const [profilePic, setProfilePic] = useState(null);
  const [profilePicIsLoading, setProfilePicIsLoading] = useState(false);

  useEffect(() => {
    if (updateUserSuccess) {
      const to = setTimeout(() => {
        dispatch(resetErrors());
      }, 800);
      return () => clearTimeout(to);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateUserSuccess]);

  const _selectExperience = level => {
    _setUserChanges({ ...userChanges, experience_level: level });
  };

  const _usernameValid = username => {
    _setUserChanges({ ...userChanges, username: username });

    if (username !== user.username) {
      dispatch(checkUsernameAvailable(username));
    }
  };

  const _setUserChanges = latestChanges => {
    dispatch(setUserChanges(latestChanges));
  };

  const _startImageUpload = file => {
    setProfilePicIsLoading(true);
    setProfilePic(file);

    let fd = new FormData();

    fd.append('profile_picture', file);

    fetch(BACKEND_API_URL + 'api/users/' + user.id + '/', {
      method: 'PATCH',
      body: fd,
      headers: { Authorization: 'Token ' + userToken },
    })
      .then(result => {
        setProfilePicIsLoading(false);
      })
      .catch(error => {
        setProfilePicIsLoading(false);
      });
  };

  const _linkValidUrl = string => {
    let url;
    url = string;
    var pattern = new RegExp(
      '^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$',
      'i'
    ); // fragment locator
    return !!pattern.test(url) || !!pattern.test('https://' + url);
  };
  const handleLinkChange = text => {
    if (_linkValidUrl(text) || text.length === 0) {
      setLinkErrorText('');
    } else {
      setLinkErrorText('Please enter a valid URL');
    }
  };

  return (
    <>
      <ModalWrapper
        modalIsOpen={changeEmailView}
        onClose={() => setChangeEmailView(false)}
        onRequestClose={() => setChangeEmailView(false)}
        title="Change Email"
        modalSize="small"
      >
        <ResetEmail
          hideModal={() => {
            setChangeEmailView(false);
          }}
        />
      </ModalWrapper>
      <ModalWrapper
        modalIsOpen={changePasswordView}
        onClose={() => setChangePasswordView(false)}
        onRequestClose={() => setChangePasswordView(false)}
        title="Change Password"
        modalSize="medium"
      >
        <ChangePassword
          hideModal={() => {
            setChangePasswordView(false);
          }}
        />
      </ModalWrapper>

      <ModalWrapper
        modalIsOpen={deactivateModalOpen}
        onClose={() => setDeactivateModalOpen(false)}
        onRequestClose={() => setDeactivateModalOpen(false)}
        title="Deactivate Account"
        modalSize="small"
      >
        <DeactivateAccount hideModal={() => setDeactivateModalOpen(false)} />
      </ModalWrapper>
      <Col
        style={{
          justifyContent: 'flex-start',
          alignItems: 'stretch',
          ...props.style,
        }}
      >
        <Row
          style={{
            alignItems: 'center',
            justifyContent: 'space-between',
            position: 'sticky',
            top: 0,
            backgroundColor: 'var(--color-bg)',
            zIndex: 101,
            borderBottom: '1px solid #9b9b9b',
            padding: 'var(--space-sm) 0',
          }}
        >
          <h3 style={{ margin: 0, flex: 2 }}>Edit Profile</h3>
          <Col style={{ flex: 0.5 }}>
            <AuthButton
              containerStyle={{ margin: 0 }}
              btnTheme="borderless"
              disabled={disableSave}
              onPress={() => dispatch(updateUser(userChanges))}
              isLoading={updateUserLoading}
            >
              Save
            </AuthButton>
          </Col>
        </Row>
        <Col
          style={{
            width: '100%',
            justifyContent: 'space-between',
          }}
        >
          <Row
            style={{
              justifyContent: 'flex-start',
              alignItems: 'center',
              padding: 'var(--space-sm) 0',
              width: '100%',
            }}
          >
            {profilePicIsLoading ? (
              <Col
                style={{
                  flex: '0 0 48px',
                  width: '48px',
                  height: '48px',
                  border: 'none',
                  marginRight: 'var(--space-sm)',
                }}
              >
                <ActivityIndicator size={1.5} />
              </Col>
            ) : (
              <ProfilePic
                user={
                  profilePic
                    ? {
                        ...mergedUser,
                        profile_picture: URL.createObjectURL(profilePic),
                      }
                    : user
                }
                style={{
                  width: '64px',
                  height: '64px',
                  border: 'none',
                  marginRight: 'var(--space-sm)',
                }}
              />
            )}
            <AuthTextInput
              label="Profile Picture"
              containerStyle={{ flex: 1, margin: 0, alignItems: 'flex-start' }}
              inputRowStyle={{
                width: 'fit-content',
                height: 'fit-content',
              }}
              type="file"
              id="profile_picture"
              name="profile_picture"
              accept="image/png, image/jpeg"
              onChangeText={(text, input) => {
                _startImageUpload(input.target.files[0]);
              }}
            />
          </Row>
          <AuthTextInput
            label="Username *"
            leftIcon={IoPersonCircleOutline}
            rightIcon={
              usernameIsAvailable === '' ? IoCheckmarkCircleOutline : null
            }
            rightIconColor={'var(--color-success)'}
            errorText={usernameIsAvailable}
            placeholder={'example'}
            maxLength={16}
            defaultValue={mergedUser.username}
            onChangeText={text => _usernameValid(text)}
          />
          <AuthTextInput
            label="Name (help your friends find you)"
            placeholder={'John Smith'}
            textContentType={'name'}
            importantForAutofill={'no'}
            maxLength={40}
            defaultValue={mergedUser.full_name}
            onChangeText={text =>
              _setUserChanges({ ...userChanges, full_name: text })
            }
          />
          <AuthTextInput
            label={`Biography ${
              mergedUser.is_public
                ? '(other users can see this)'
                : '(only you can see this)'
            }`}
            maxLength={256}
            multiline={true}
            numberOfLines={6}
            placeholder={'Let other users know who you are!'}
            defaultValue={mergedUser.bio}
            onChangeText={text =>
              _setUserChanges({ ...userChanges, bio: text })
            }
          />
          <AuthTextInput
            label="Website (share your own content)"
            placeholder={'https://twitter.com/betstamp'}
            errorText={linkErrorText}
            importantForAutofill={'no'}
            maxLength={2048}
            defaultValue={mergedUser.link}
            onChangeText={text => {
              let sanitizedText = sanitizeUrlText(text);
              _setUserChanges({ ...userChanges, link: sanitizedText });
              handleLinkChange(text);
            }}
          />
          <Col
            style={{
              width: '100%',
              alignItems: 'flex-start',
              margin: 'var(--space-sm) 0',
            }}
          >
            <span style={{ margin: '0 var(--space-sm)' }}>Region</span>
            <Select
              options={STATE_OPTIONS.map(s => ({ label: s, value: s }))}
              defaultValue={{ label: user.state, value: user.state }}
              onChange={opt => {
                _setUserChanges({ ...userChanges, state: opt.value });
              }}
            />
          </Col>
          <Row
            style={{
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
              margin: 'var(--space-sm) 0',
            }}
          >
            <span style={{ flex: 2 }}>{user.email}</span>
            <AuthButton
              colorTheme="complement"
              btnTheme="borderless"
              onPress={() => setChangeEmailView(true)}
            >
              Change Email
            </AuthButton>
            <AuthButton
              colorTheme="complement"
              btnTheme="borderless"
              onPress={() => {
                setChangePasswordView(true);
              }}
            >
              Change Password
            </AuthButton>
          </Row>
        </Col>
        <SectionHeading title="Experience" />
        <Row style={{ justifyContent: 'space-evenly' }}>
          <ExperienceBox
            text={"I'm new to sports betting"}
            level={'Rookie'}
            experienceLevel={mergedUser.experience_level}
            onPress={() => _selectExperience('Rookie')}
          />
          <ExperienceBox
            text={"I'm always looking to improve"}
            level={'Experienced'}
            experienceLevel={mergedUser.experience_level}
            onPress={() => _selectExperience('Experienced')}
          />
          <ExperienceBox
            text={"I know what I'm doing"}
            level={'Professional'}
            experienceLevel={mergedUser.experience_level}
            onPress={() => _selectExperience('Professional')}
          />
        </Row>
        <SectionHeading title="Profile Settings" />
        <Col>
          {!cantMakePrivate ? (
            <SwitchSetting
              heading="Private Profile"
              value={!mergedUser.is_public}
              onValueChange={() =>
                _setUserChanges({
                  ...userChanges,
                  is_public: !mergedUser.is_public,
                })
              }
              style={{
                padding: '0 var(--space-xs)',
                width: '100%',
                fontWeight: 'bold',
              }}
            />
          ) : (
            <p>
              You can't make your profile private while you have packages for
              sale
            </p>
          )}
          {!cantMakePrivate && mergedUser.is_public && (
            <SwitchSetting
              heading="Display Pending Bets on my Public Profile"
              value={!mergedUser.hide_pending_bets}
              onValueChange={() =>
                _setUserChanges({
                  ...userChanges,
                  hide_pending_bets: !mergedUser.hide_pending_bets,
                })
              }
              style={{
                padding: '0 var(--space-xs)',
                borderBottomWidth: 0,
                width: '100%',
                fontWeight: 'bold',
              }}
            />
          )}
        </Col>
        <SectionHeading
          title="Danger Zone"
          style={{ color: 'var(--color-danger)' }}
        />
        <Row>
          <Col
            style={{
              flex: 2,
              alignItems: 'flex-start',
              justifyContent: 'center',
            }}
          >
            <span>Log out from everywhere</span>
          </Col>
          <AuthButton
            containerStyle={{ maxWidth: '310px' }}
            colorTheme={'danger'}
            //colorTheme={'danger'}
            //btnTheme="borderless"
            isLoading={isLoadingLogoutAll}
            onPress={() => {
              dispatch(logoutAll());
              dispatch(cleanAutoSyncStore());
            }}
          >
            Logout of All Devices
          </AuthButton>
        </Row>
        <Row>
          <Col
            style={{
              flex: 2,
              alignItems: 'flex-start',
              justifyContent: 'center',
            }}
          >
            <span>Deactivate Account Forever</span>
            <small>
              If you no longer want your betstamp account, you can deactivate it
            </small>
          </Col>
          <AuthButton
            containerStyle={{ maxWidth: '310px' }}
            colorTheme={'danger'}
            isLoading={isLoadingDeactivate}
            onPress={() => setDeactivateModalOpen(true)}
          >
            Deactivate Account
          </AuthButton>
        </Row>
      </Col>
    </>
  );
}

function SectionHeading({ title, style }) {
  return (
    <h5
      style={{
        opacity: 0.7,
        marginTop: 'var(--space-lg)',
        marginBottom: 'var(--space-sm)',
        paddingVertical: 'var(--space-xxxs)',
        ...style,
      }}
    >
      {title}
    </h5>
  );
}
