import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { IoChevronForward, IoChevronBack } from 'react-icons/io5';

// components
import { Row } from 'components/generic/Layout';
import PresetBtn from 'components/analysis/PresetBtn';
import { IconButton } from 'components/AuthButton';

// actions
import { getAnalysisPresetsNext } from 'actions';

const PAGE_SIZE = 4; // this is not the backend pagination size and has nothing to do with it

export default function PresetToolbar(props) {
  const dispatch = useDispatch();
  const reduxProps = useSelector(state => ({
    presets: state.analysisReducer.presets,
    presetsNext: state.analysisReducer.presetsNext,
    presetCount: state.analysisReducer.presetCount,
  }));
  const { presets, presetsNext, presetCount } = reduxProps;

  const [offset, setOffset] = useState(0);

  return (
    <Row style={{ alignItems: 'center', flexFlow: 'row nowrap' }}>
      <IconButton
        iconName={IoChevronBack}
        iconSize={22}
        iconColor={'var(--color-text)'}
        containerStyle={{
          opacity: offset === 0 ? 0.5 : 1,
          minWidth: '7rem',
          display: 'flex',
          justifyContent: 'center',
        }}
        disabled={offset === 0}
        onPress={() => {
          if (offset === 0) return;
          setOffset(offset - PAGE_SIZE);
        }}
      />
      <Row>
        {presets.slice(offset, offset + PAGE_SIZE).map((preset, index) => (
          <PresetBtn
            key={`desktop-table-home-preset-load-list-${index}`}
            preset={preset}
            index={index}
            ID={preset.id}
          />
        ))}
      </Row>
      <IconButton
        iconName={IoChevronForward}
        iconSize={22}
        iconColor={'var(--color-text)'}
        containerStyle={{
          opacity: presetCount - offset < PAGE_SIZE ? 0.5 : 1,
          minWidth: '7rem',
          display: 'flex',
          justifyContent: 'center',
        }}
        disabled={presetCount - offset < PAGE_SIZE}
        onPress={() => {
          if (presetCount - offset < PAGE_SIZE) return;
          if (presetsNext) {
            dispatch(getAnalysisPresetsNext());
          }
          setOffset(offset + PAGE_SIZE);
        }}
      />
    </Row>
  );
}
