import calculateBetPayout from 'utils/calculateBetPayout';
import commaSeparateNumber from 'utils/commaSeparateNumber';
import formatTime from 'utils/formatTime';
import formatDate from 'utils/formatDate';
import calculateRiskRequired from 'utils/calculateRiskRequired';
import toAmericanOdds from 'utils/toAmericanOdds';
import toDecimalOdds from 'utils/toDecimalOdds';
import isEmailValid from 'utils/isEmailValid';
import cleanAmericanOdds from 'utils/cleanAmericanOdds';
import isAmericanOddsValid from 'utils/isAmericanOddsValid';
import cleanDecimalOdds from 'utils/cleanDecimalOdds';
import isDecimalOddsValid from 'utils/isDecimalOddsValid';
import cleanDollarAmount from 'utils/cleanDollarAmount';
import roundNumber from 'utils/roundNumber';
import paramsToFilterObject from 'utils/paramsToFilterObject';
import roundOdds from 'utils/roundOdds';
import betLine from 'utils/betLine';
import kFormatter from 'utils/kFormatter';
import parlayCompatCheck from 'utils/parlayCompatCheck';
import stringToArray from 'utils/stringToArray';
import oddsGreaterThan from 'utils/oddsGreaterThan';
import normalizeLocation from 'utils/normalizeLocation';
import fakeWagersTour from 'utils/fakeWagersTour';
import periodChoices from './periodChoices';
import competitionChoices from './competitionChoices';
import getLastName from 'utils/getLastName';
import genGame from 'utils/tour/genGame';
import genBA from 'utils/tour/genBA';
import genOdds from 'utils/tour/genOdds';
import getHeaders from './getHeaders';
import humanDate from './humanDate';
import humanDatetime from './humanDatetime';
import humanTime from './humanTime';
import formatMoney from './formatMoney';

export const __DEV__ = process.env.NODE_ENV !== 'production';

export const PLATFORM = 'web';

export const BACKEND_API_URL = __DEV__
  ? 'http://localhost:8000/'
  : 'https://api.betstamp.app/';

export {
  calculateBetPayout,
  commaSeparateNumber,
  formatTime,
  formatDate,
  calculateRiskRequired,
  toAmericanOdds,
  toDecimalOdds,
  isEmailValid,
  cleanAmericanOdds,
  isAmericanOddsValid,
  cleanDecimalOdds,
  isDecimalOddsValid,
  cleanDollarAmount,
  roundNumber,
  paramsToFilterObject,
  roundOdds,
  betLine,
  kFormatter,
  parlayCompatCheck,
  stringToArray,
  oddsGreaterThan,
  periodChoices,
  normalizeLocation,
  fakeWagersTour,
  competitionChoices,
  getHeaders,
  getLastName,
  genGame,
  genBA,
  genOdds,
  humanDate,
  humanDatetime,
  humanTime,
  formatMoney,
};

export const STRIPE = {
  PUBLIC_KEY: __DEV__
    ? 'pk_test_51H4smEGjtuAzfK2fvQeDH11E1XJiZOMtE2HFwr6AYYmlv0xXOVj7XpUMuxav8uhSJyIynw1hhERzpccbdmY4E6Gf00HE6ZfdoM'
    : 'pk_live_51H4smEGjtuAzfK2fUXaRqxZr9Iwu5CjltNAY03Y0gr4tzNbqoB0RLSPhuX0MC9rsb4Yn5Ih1jBEt4xuVfaZ3x56C00EVY3Vb95',
  DOMAIN: 'betstamp.app',
  OTHER_DOMAIN: 'betstamp.co',
  SUCCESS_END_URL: 'transaction-processed',
  CANCELLED_END_URL: 'transaction-cancelled',
  CLIENT_ID: __DEV__
    ? 'ca_HeqlUB39Qkbg0UOWfxCT3tcUcBzZ8Ajk'
    : 'ca_HeqlDbyvu1eUkaUMAJ4IwSRqr8ERSUvv',
};

export const AMPLITUDE = {
  API_KEY: __DEV__
    ? '7cf56c3858f6448ce8b1a1c8a29eea87'
    : '6bd10b0ecfa3799da56d277413bd878d',
};

export const RELATIONSHIP_STATUS = {
  NONE: 0,
  FOLLOWING: 1,
  PURCHASED: 2,
};

export const SHARPSPORTS = {
  API_KEY: __DEV__
    ? 'e6446c348276f2e66fbac6010097306980c80490'
    : '780f25bf1888c0092425afd2f0a4baec5b06c385',
};

// uses MaterialCommunityIcons
// displayed in this order in app
export const LEAGUES = [
  { league: 'NFL', iconName: 'football', hidden: false },
  { league: 'NCAAF', iconName: 'football', hidden: false },
  { league: 'UFC', iconName: 'karate', hidden: false },
  { league: 'NHL', iconName: 'hockey-sticks', hidden: false },
  { league: 'NBA', iconName: 'basketball', hidden: false },
  { league: 'NCAAB', iconName: 'basketball', hidden: false },
  { league: 'WNBA', iconName: 'basketball', hidden: false },
  { league: 'MLB', iconName: 'baseball-bat', hidden: false },
  { league: 'ATP', iconName: 'tennis', hidden: false },
  { league: 'WTA', iconName: 'tennis', hidden: false },
  { league: 'CFL', iconName: 'football', hidden: false },
  { league: 'MLS', iconName: 'football', hidden: false },
  { league: 'EPL', iconName: 'football', hidden: false },
  { league: 'SERA', iconName: 'football', hidden: false },
  { league: 'BUND', iconName: 'football', hidden: false },
  { league: 'LIGA', iconName: 'football', hidden: false },
  { league: 'LIG1', iconName: 'football', hidden: false },
  { league: 'UCL', iconName: 'football', hidden: false },
  { league: 'EURO', iconName: 'football', hidden: false },
  { league: 'COPA', iconName: 'football', hidden: false },
  { league: 'WCUP', iconName: 'football', hidden: false },
  { league: 'GOLF', iconName: 'golf', hidden: false },
  { league: 'BELL', iconName: 'karate', hidden: false },
];

export const SECOND_HALF_LEAGUES = [
  'NBA',
  'NCAAB',
  'NFL',
  'NCAAF',
  'CFL',
  'MLS',
  'WNBA',
];
export const LIVE_LEAGUES = [
  'NFL',
  'NCAAF',
  'MLB',
  'NHL',
  'NBA',
  'WNBA',
  'NCAAB',
  'CFL',
  'MLS',
  'EPL',
];
export const PLAYER_PROP_LEAGUES = [
  'NFL',
  'MLB',
  'NHL',
  'NBA',
  'NCAAF',
  'NCAAB',
  'WCUP',
  'SERA',
  'LIG1',
  'BUND',
  'LIGA',
  'MLS',
  'UCL',
  'EPL',
];

export const LEAGUES_COMING_SOON = [
  { league: 'LOL', iconName: 'football', hidden: false },
  { league: 'CSGO', iconName: 'football', hidden: false },
];

const PROVINCES = {
  AB: 'Alberta',
  BC: 'British Columbia',
  MB: 'Manitoba',
  NB: 'New Brunswick',
  NL: 'Newfoundland and Labrador',
  NS: 'Nova Scotia',
  NT: 'Northwest Territories',
  NU: 'Nunavut',
  ON: 'Ontario',
  PE: 'Prince Edward Island',
  QC: 'Quebec',
  SK: 'Saskatchewan',
  YT: 'Yukon',
};

const STATES = {
  AL: 'Alabama',
  AK: 'Alaska',
  // AS: 'American Samoa',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  DC: 'District Of Columbia',
  // FM: 'Federated States Of Micronesia',
  FL: 'Florida',
  GA: 'Georgia',
  GU: 'Guam',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  // MH: 'Marshall Islands',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  // MP: 'Northern Mariana Islands',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  // PW: 'Palau',
  PA: 'Pennsylvania',
  // PR: 'Puerto Rico',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  // VI: 'Virgin Islands',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming',
};

export const OTHER_STATE_OPTIONS = {
  MEX: 'Mexico',
  GER: 'Germany',
  ITA: 'Italy',
  RUS: 'Russia',
  TUR: 'Turkey',
  FRA: 'France',
  UK: 'United Kingdom',
  SPA: 'Spain',
  UKR: 'Ukraine',
  POL: 'Poland',
  ROM: 'Romania',
  EUR: 'Europe',
  ASIA: 'Asia',
  AUS: 'Australia',
  OCE: 'Oceania',
  BRA: 'Brazil',
  SAMER: 'South America',
  AFR: 'Africa',
  OTHER: 'Other',
};

export const US_STATES = Object.values(STATES);
export const PROVINCE_OPTIONS = Object.values(PROVINCES);
export const ALL_STATE_OBJECT = {
  ...PROVINCES,
  ...STATES,
  ...OTHER_STATE_OPTIONS,
};

export const CANADA_FLAG =
  'https://betstamp-public.sfo2.cdn.digitaloceanspaces.com/flags/svgs/canada.svg';
export const USA_FLAG =
  'https://betstamp-public.sfo2.cdn.digitaloceanspaces.com/flags/svgs/usa.svg';

const CANADA_REGION_FLAGS = PROVINCE_OPTIONS.reduce((acc, curr) => {
  acc[curr] = CANADA_FLAG;
  return acc;
}, {});
const USA_REGION_FLAGS = US_STATES.reduce((acc, curr) => {
  acc[curr] = USA_FLAG;
  return acc;
}, {});
export const REGION_FLAGS = { ...CANADA_REGION_FLAGS, ...USA_REGION_FLAGS };

export const STATE_OPTIONS = Object.values(STATES)
  .concat(Object.values(PROVINCES))
  .concat(Object.values(OTHER_STATE_OPTIONS));

// if theres more than this, we have to get next in historicals endpoint
export const MIN_PLAYS = 9;

export const LEGAL_STATES = [
  // { name: 'Arizona', abbr: 'AZ' },
  { name: 'Colorado', abbr: 'CO' },
  { name: 'Illinois', abbr: 'IL' },
  { name: 'Indiana', abbr: 'IN' },
  { name: 'Iowa', abbr: 'IA' },
  { name: 'Michigan', abbr: 'MI' },
  { name: 'New Jersey', abbr: 'NJ' },
  { name: 'New York', abbr: 'NY' },
  // { name: 'Pennsylvania', abbr: 'PA' },
  { name: 'Tennessee', abbr: 'TN' },
  // { name: 'Virginia', abbr: 'VA' },
  // { name: 'West Virginia', abbr: 'WV' },
  { name: 'Wyoming', abbr: 'WY' },
  { name: 'Ontario', abbr: 'ON' },
  { name: 'Mississippi', abbr: 'MS' },
  { name: 'Connecticut', abbr: 'CT' },
  { name: 'Ohio', abbr: 'OH' },
];

export const GROUPED_LEAGUES = [
  {
    groupName: 'Football',
    // groupIcon: LEAGUE_ICONS['NFL'],
    leagues: ['NFL', 'NCAAF', 'CFL'],
  },
  {
    groupName: 'Basketball',
    // groupIcon: LEAGUE_ICONS['NBA'],
    leagues: ['NBA', 'NCAAB', 'WNBA'],
  },
  {
    groupName: 'Hockey',
    // groupIcon: LEAGUE_ICONS['NHL'],
    leagues: ['NHL'],
  },
  {
    groupName: 'Baseball',
    // groupIcon: LEAGUE_ICONS['MLB'],
    leagues: ['MLB'],
  },
  {
    groupName: 'Soccer',
    // groupIcon: LEAGUE_ICONS['MLS'],
    leagues: [
      'MLS',
      'EPL',
      'BUND',
      'LIGA',
      'LIG1',
      'UCL',
      'SERA',
      'EURO',
      'COPA',
      'WCUP',
    ],
  },
  {
    groupName: 'Tennis',
    // groupIcon: LEAGUE_ICONS['ATP'],
    leagues: ['ATP', 'WTA'],
  },
  {
    groupName: 'Fighting',
    // groupIcon: LEAGUE_ICONS['UFC'],
    leagues: ['UFC', 'BELL'],
  },
  {
    groupName: 'Golf',
    // groupIcon: LEAGUE_ICONS['GOLF'],
    leagues: ['GOLF'],
  },
];

export const REGULATED_STATES = {
  Colorado: true,
  Illinois: true,
  Indiana: true,
  Iowa: true,
  Michigan: true,
  'New Jersey': true,
  Wyoming: true,
  Ontario: true,
  Tennessee: true,
  Connecticut: true,
  'New York': true,
};

export const DEFAULT_FETCH_COUNT = 2;

export const FEATURE_CONTENT_HEIGHT = 180;

export const FEATURE_CONTENT_WIDTH = 284;

export const DEFAULT_FEATURE_LOADER_CONTENT = [1, 2, 3, 4, 5];
export const DEFAULT_MAX_NUMBER_RECOMMENDED_USERS_DISPLAYED = 10;

export const AUTH_BUTTON_DEFAULT_CONTAINER_STYLE = {
  margin: 'var(--space-xs) var(--space-sm)',
};

export const AUTH_BUTTON_DEFAULT_MAX_WIDTH_STYLE = {
  maxWidth: '10rem',
};

export const MAX_SCREEN_HEIGHT_SIGNUP_ONBOARD = '1200px';

export const preloadImageHelper = (url, onImageLoaded) =>
  new Promise((resolve, reject) => {
    const img = new Image();
    img.src = url;
    img.onload = () => {
      if (onImageLoaded) {
        onImageLoaded();
      }
      resolve();
    };
    img.onerror = () => reject();
  });

export const loadImageFirst = imageURLs =>
  Promise.all(imageURLs.map(url => preloadImageHelper(url)));

const DEFAULT_LEAGUE = {
  0: 'NFL', // Jan
  1: 'NBA', // Feb
  2: 'NBA', // Mar
  3: 'NBA', // Apr
  4: 'NBA', // May
  5: 'MLB', // Jun
  6: 'MLB', // Jul
  7: 'MLB', // Aug
  8: 'NFL', // Sep
  9: 'NFL', // Oct
  10: 'NFL', // Nov
  11: 'NFL', // Dec
};

export const DEFAULT_SELECTED_LEAGUE = DEFAULT_LEAGUE[new Date().getMonth()];

export const BETA_AUTOSYNC_BOOKS = [];

export const INFLUENCER_PAGE_TAGS = [
  'Player Props',
  'Best Odds',
  'Best VIP Program',
  'Best Bonus',
  'Casino',
  'Daily Fantasy',
];

export const CURRENCY_OPTIONS = ['USD', 'CAD', 'EUR', 'GBP'];
export const MONTHS = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'June',
  'July',
  'Aug',
  'Sept',
  'Oct',
  'Nov',
  'Dec',
];
