export default function humanDatetime(d, short, showTimezone, showWeekday) {
  let options = {
    day: 'numeric',
    month: 'short',
    hour: 'numeric',
    minute: 'numeric',
  };
  if (!short) {
    options.month = 'long';
    options.year = 'numeric';
  }
  if (showTimezone) {
    options.timeZoneName = 'long';
  }
  if (showWeekday) {
    options.weekday = 'long';
  }
  return new Date(d).toLocaleDateString('en-US', options);
}
