import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useClickAway } from 'react-use';
import { IoFilter, IoSearch, IoClose } from 'react-icons/io5';

// components
import { Row, Col } from 'components/generic/Layout';
import ActivityIndicator from 'components/generic/ActivityIndicator';
import { AuthTextInput } from 'components/AuthTextInput';
import { AuthButton, IconButton } from 'components/AuthButton';
import Select from 'components/generic/Select';

const Item = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid var(--color-text-light);
  padding: var(--space-md);
  transition: all var(--std-transition);
  cursor: pointer;
  background: ${props =>
    props.selected ? 'var(--color-active)' : 'transparent'};
  :hover {
    background: var(--color-text-light);
  }
  span {
    opacity: 0.8;
  }
`;

export default function MailboxLayout(props) {
  const {
    formatText,
    showSearchButton,
    modelOptions,
    endpoints,
    modelNames,
    titleKeys,
    descKeys,
    searchPlaceholders,
    headerChildren,
    defaultSelectedKey,
    forceSelectedKey,
  } = props;

  const PanelChildren = props.PanelChildren;

  const [selectedKey, setSelectedKey] = useState(
    defaultSelectedKey || modelOptions[0].value
  );

  useEffect(() => {
    if (forceSelectedKey) setSelectedKey(forceSelectedKey);
  }, [forceSelectedKey]);

  const modelName = modelNames[selectedKey];
  const titleKey = titleKeys[selectedKey];
  const descKey = descKeys[selectedKey];
  const endpoint = endpoints[selectedKey].endpoint;
  const urlParams = endpoints[selectedKey].urlParams;
  const searchPlaceholder = searchPlaceholders
    ? searchPlaceholders[selectedKey]
    : '';
  const HeaderChildren = headerChildren ? headerChildren[selectedKey] : null;

  const [selectedObj, setSelectedObj] = useState(null);
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [searchTerm, setSearchTerm] = useState(null);
  //const [showFilterPopup, setShowFilterPopup] = useState(false);
  //const [selectedFilterLabel, setSelectedFilterLabel] = useState(
  //  defaultSelectedFilterLabel
  //);

  const fetchData = useInfiniteQuery({
    queryKey: [
      {
        endpoint: endpoint,
        urlParams: searchTerm
          ? { ...urlParams, search: searchTerm }
          : urlParams,
      },
    ],
    getNextPageParam: (lastPage, allPages) => lastPage.next,
    getPreviousPageParam: (firstPage, allPages) => firstPage.prev,
  });

  const pages = fetchData?.data?.pages;

  return (
    <Row
      style={{
        flexWrap: 'nowrap',
        overflowX: 'hidden',
        overflowY: 'hidden',
        height: 'calc(100vh - 64px)',
        ...props.containerStyle,
      }}
    >
      <Col
        style={{
          flex: '1 0 256px',
          borderRight: '1px solid var(--color-text-light)',
          overflowY: 'auto',
          minHeight: '100vh',
          justifyContent: 'flex-start',
          alignItems: 'stretch',
          flexWrap: 'nowrap',
          paddingBottom: '256px',
        }}
      >
        {headerChildren && <HeaderChildren />}
        <Row
          style={{
            flex: 0,
            width: '100%',
            alignSelf: 'center',
            borderBottom: '1px solid var(--color-text-light)',
            padding: 'var(--space-xs)',
            gap: 'var(--space-xs)',
            position: 'sticky',
            top: 0,
            zIndex: 10,
            background: 'var(--color-bg)',
          }}
        >
          {!showSearchBar && (
            <Select
              key={`key-mailbox-select-${selectedKey}`}
              styles={{
                container: base => ({
                  ...base,
                  flex: 1,
                  margin: 0,
                  alignSelf: 'center',
                  padding: '5.5px 0',
                }),
              }}
              options={modelOptions}
              onChange={opt => {
                setSelectedKey(opt.value);
                setSearchTerm(null);
                setSelectedObj(null);
                if (props.onSelectedKeyChange) {
                  props.onSelectedKeyChange(opt.value);
                }
              }}
              defaultValue={modelOptions.find(o => o.value === selectedKey)}
            />
          )}
          {showSearchBar && (
            <AuthTextInput
              containerStyle={{
                flex: 1,
                margin: 0,
              }}
              defaultValue={urlParams?.search}
              placeholder={searchPlaceholder}
              onChangeText={text => setSearchTerm(text)}
              autoFocus
            />
          )}
          {showSearchButton && (
            <IconButton
              colorTheme="text"
              iconName={showSearchBar ? IoClose : IoSearch}
              onPress={() => {
                setShowSearchBar(!showSearchBar);
              }}
            />
          )}
          {/*
          {showFilters && (
            <IconButton
              colorTheme="text"
              iconName={IoFilter}
              onPress={() => {
                if (!showFilterPopup) {
                  setShowFilterPopup(true);
                }
              }}
            />
          )}
          {!!filterOptions && (
            <FilterOptions
              show={showFilterPopup}
              options={filterOptions}
              selectedLabel={selectedFilterLabel}
              onClick={o => {
                setSelectedFilterLabel(o.label);
                setURLParams({ ...urlParams, ...o.value });
              }}
              closePopup={() => setShowFilterPopup(false)}
            />
          )}
      */}
        </Row>
        {!fetchData.isLoading &&
          pages?.length === 1 &&
          pages[0]?.results.length === 0 && (
            <h5 style={{ alignSelf: 'center' }}>No {modelName}s</h5>
          )}
        {fetchData.isLoading && (
          <ActivityIndicator size={3} style={{ marginTop: '20vh' }} />
        )}
        {pages?.length > 0 && pages[0]?.results?.length > 0 && (
          <>
            {pages.map((page, i) => (
              <React.Fragment key={`onb-mailbox-page-${modelName}-${i}`}>
                {page.results.map((obj, j) => (
                  <Item
                    key={`onb-mailbox-item-${j}-${modelName}`}
                    onClick={() => setSelectedObj(obj)}
                    selected={selectedObj?.id === obj.id}
                  >
                    <b>
                      {formatText && formatText[titleKey]
                        ? formatText[titleKey](obj[titleKey])
                        : obj[titleKey]}
                    </b>
                    {descKey && (
                      <span key={`onb-mailbox-desc-key-${descKey}`}>
                        {formatText && formatText[descKey]
                          ? formatText[descKey](obj[descKey])
                          : obj[descKey]}
                      </span>
                    )}
                  </Item>
                ))}
              </React.Fragment>
            ))}
          </>
        )}
        {fetchData.hasNextPage && (
          <AuthButton
            containerStyle={{ marginTop: 'var(--space-lg)' }}
            btnTheme="borderless"
            onPress={() => fetchData.fetchNextPage()}
            isLoading={fetchData.isFetchingNextPage}
          >
            Load More
          </AuthButton>
        )}
      </Col>
      <Col
        style={{
          flex: '7 0 256px',
          overflowY: 'auto',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          flexWrap: 'nowrap',
        }}
      >
        {fetchData.isError && <p>Error {fetchData?.error?.message}</p>}
        {selectedObj ? (
          <PanelChildren
            key={`mailbox-panel-${selectedObj?.id}-${selectedKey}`}
            selectedObj={selectedObj}
            selectedKey={selectedKey}
            urlParams={
              searchTerm ? { ...urlParams, search: searchTerm } : urlParams
            }
            clearSelected={() => setSelectedObj(null)}
          />
        ) : (
          <>
            {pages?.length >= 1 && pages[0]?.results.length > 0 && (
              <h4 style={{ alignSelf: 'center' }}>
                Select{' '}
                {modelName &&
                  ['a', 'e', 'i', 'o', 'u'].includes(modelName[0].toLowerCase())
                  ? 'an'
                  : 'a'}{' '}
                {modelName}
              </h4>
            )}
          </>
        )}
      </Col>
    </Row>
  );
}

const FilterOptionsWrapper = styled.div`
  position: absolute;
  background: var(--color-fg);
  border-radius: var(--std-border-radius);
  padding: var(--space-sm);
  right: 0;
  box-shadow: 0 2px 8px 0 var(--color-shadow);

  top: ${props => {
    if (props.show) {
      return '85%';
    }
    return '75%';
  }};

  opacity: ${props => {
    if (props.show) {
      return '1';
    }
    return '0';
  }};

  z-index: ${props => {
    if (props.show) {
      return '9999';
    }
    return '1';
  }};

  pointer-events: ${props => {
    if (props.show) {
      return 'auto';
    }
    return 'none';
  }};

  &:after {
    content: '';
    display: block;
    position: absolute;
    right: 21px;
    bottom: 100%;
    width: 0;
    height: 0;
    border-bottom: 10px solid var(--color-fg);
    border-top: 10px solid transparent;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
  }
  @keyframes AnimateOpen {
    0% {
      opacity: 0;
      top: 75%;
    }
    100% {
      opacity: 1;
      top: 85%;
    }
  }
  @keyframes AnimateClose {
    0% {
      opacity: 1;
      top: 85%;
    }
    100% {
      opacity: 0;
      top: 75%;
    }
  }

  animation-duration: 250ms;
  animation-timing-function: ease-in-out;
  animation-name: ${props => {
    if (props.show) return 'AnimateOpen';
    else return 'AnimateClose';
  }};
`;

function FilterOptions(props) {
  const { show, options, selectedLabel } = props;

  const ref = useRef(null);
  useClickAway(
    ref,
    () => {
      if (props.closePopup) props.closePopup();
    },
    ['mouseup']
  );

  return (
    <FilterOptionsWrapper ref={ref} show={show}>
      {options.map(o => (
        <AuthButton
          key={`mailbox-layout-filter-option-${o.label}`}
          btnTheme="borderless"
          colorTheme={selectedLabel === o.label ? 'primary' : 'text'}
          onPress={() => props.onClick(o)}
        >
          {o.label}
        </AuthButton>
      ))}
    </FilterOptionsWrapper>
  );
}
