import React, { useState } from 'react';
import SwipeableViews from 'react-swipeable-views';

// mui imports
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

// components
// import TodaysAction from './Tabs/TodaysAction';
import TodaysAction2 from './Tabs/TodaysAction2';
// import Accounting from './Tabs/Accounting';
import Historicals from './Tabs/Historicals';

// mui stuff
const StyledTabs = withStyles({
  root: {
    paddingBottom: 'var(--space-xxxxs)',
  },
  indicator: {
    backgroundColor: 'var(--color-primary)',
  },
})((props) => <Tabs {...props} />);

const StyledTab = withStyles((theme) => ({
  root: {
    flex: 1,
    color: 'var(--color-text)',
    transition: 'all var(--std-transition)',
    '&:hover': {
      backgroundColor: 'var(--color-fg)',
    },
    '&:focus': {
      opacity: 1,
    },
  },
}))((props) => <Tab disableRipple {...props} />);

export default function MyPicksMobile() {
  const [tab, setTab] = useState(0);

  const _handleChange = (event, showTab) => {
    setTab(showTab);
  };

  const _handleChangeIndex = (showTab) => {
    setTab(showTab);
  };

  return (
    <>
      <StyledTabs
        value={tab}
        onChange={_handleChange}
        centered
        variant="fullWidth"
      >
        <StyledTab label="Today" />
        <StyledTab label="Historicals" />
        {/* <StyledTab label="Accounting" /> */}
      </StyledTabs>

      <SwipeableViews axis={'x'} index={tab} onChangeIndex={_handleChangeIndex}>
        <div>
          <TodaysAction2 />
        </div>
        <div style={{ minHeight: '100vh' }}>
          <Historicals />
        </div>
      </SwipeableViews>
    </>
  );
}
