// components
import StaffNavBar from 'components/nav/StaffNavBar';
import Troubleshooter from 'components/onboarding/Troubleshooter';

export default function TrouberShooterAdmin(props) {
  return (
    <>
      <StaffNavBar />
      <Troubleshooter admin />
    </>
  );
}
