// analysis filter options and their defaults
export const ANALYSIS_FILTERS = {
  Xaxis: {
    value:
      '["ROI","CLV","CLV Best Available","Net","Volume","Record","Average Bet Size","Sample Size"]',
    slug: 'ROI, CLV, CLV Best Available, Net, Volume, Record, Average Bet Size, Sample Size',
    is_premium: false,
    title: 'Calculations',
  },
  Yaxis: {
    value: 'League',
    slug: 'League',
    is_premium: false,
    title: 'Group',
  },
  Yaxis2: {
    value: 'Bet Type',
    slug: 'Bet Type',
    is_premium: false,
    title: 'Sub Group',
  },
  User: {
    value: null,
    slug: 'You',
    is_premium: false,
    title: 'User',
  },
  Books: {
    value: '[]',
    slug: 'All sportsbooks',
    is_premium: false,
    title: 'Sportsbooks',
  },
  Verified: {
    value: 'false',
    slug: 'All bets',
    is_premium: false,
    title: 'Verified',
  },
  Bet_Status: {
    value: null,
    slug: 'All',
    is_premium: false,
    title: 'Bet Status',
  },
  Date_Range: {
    value: '[null,null]',
    slug: 'All Time',
    is_premium: false,
    title: 'Date Range',
  },
  Time_Period: {
    value: null,
    slug: 'All Time',
    is_premium: false,
    title: 'Time Period',
  },
  Leagues: {
    value: null,
    slug: 'All',
    is_premium: false,
    title: 'Leagues',
  },
  Bet_Types: {
    value: null,
    slug: 'All',
    is_premium: false,
    title: 'Bet Types',
  },
  Tags: {
    value: null,
    slug: 'All',
    is_premium: false,
    title: 'Tags',
  },
  Live_Bets: {
    value: null,
    slug: 'All Bets',
    is_premium: false,
    title: 'Live Bets',
  },
  Game_Period: {
    value: null,
    slug: 'All Periods',
    is_premium: false,
    title: 'Game Period',
  },
};

export const CALCULATION_OPTIONS = [
  'ROI',
  'CLV',
  'CLV Best Available',
  'Net',
  'Record',
  'Volume',
  'Average Bet Size',
  'Sample Size',
];

// export const YAXIS_OPTIONS = ['Book', 'League', 'Tag'];

export const GROUPING_OPTIONS = [
  'Book',
  'League',
  'Tags',
  'Bet Type',
  'Favorite/Underdog',
  'Over/Under',
  'Game Period',
];
