import { Observable } from 'rxjs';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import { ajax } from 'rxjs/observable/dom/ajax';
import { BACKEND_API_URL, getHeaders } from 'utils';

import {
  GET_STRIPE_EXPRESS_DASHBOARD_URL,
  getStripeExpressDashboardUrlSuccess,
  getStripeExpressDashboardUrlFailure,
} from 'actions';

export default function getExpressDashboardURL(action$, state$) {
  return action$.ofType(GET_STRIPE_EXPRESS_DASHBOARD_URL).switchMap(() => {
    return ajax
      .getJSON(
        BACKEND_API_URL + 'api/users/express_dashboard_url/',
        getHeaders(state$)
      )
      .map(data => getStripeExpressDashboardUrlSuccess(data))
      .catch(error =>
        Observable.of(getStripeExpressDashboardUrlFailure(error.xhr))
      );
  });
}
