// actions (excluding success and failure actions):
export const GET_WAGERS = 'GET_WAGERS';
export const UPDATE_WAGER = 'UPDATE_WAGER';
export const RESET_WAGER_EDIT_DEL = 'RESET_WAGER_EDIT_DEL';
export const DELETE_WAGER = 'DELETE_WAGER';
export const RETRIEVE_WAGER = 'RETRIEVE_WAGER';

export const GET_WAGERS_SUCCESS = 'GET_WAGERS_SUCCESS';
export const GET_WAGERS_FAILURE = 'GET_WAGERS_FAILURE';
export const GET_WAGERS_NEXT = 'GET_WAGERS_NEXT';

// Note how this takes in an objects as a param instead of x individual
// params. It's the closest things to named parameters in python
export const getWagers = ({
  screen,
  date_after,
  date_before,
  is_verified,
  is_graded,
  exclude_types,
  book,
  user,
  show_only_hidden,
  game_id,
}) => ({
  type: GET_WAGERS,
  screen,
  date_after,
  date_before,
  is_verified,
  is_graded,
  exclude_types,
  book,
  user,
  show_only_hidden,
  game_id,
});

export const getWagersSuccess = (payload, screen) => ({
  type: GET_WAGERS_SUCCESS,
  payload,
  screen,
});

export const getWagersFailure = (error, screen) => ({
  type: GET_WAGERS_FAILURE,
  error,
  screen,
});

export const getWagersNext = screen => ({
  type: GET_WAGERS_NEXT,
  screen,
});

export const UPDATE_WAGER_SUCCESS = 'UPDATE_WAGER_SUCCESS';
export const UPDATE_WAGER_FAILURE = 'UPDATE_WAGER_FAILURE';
export const updateWager = wager => ({
  type: UPDATE_WAGER,
  wager,
});
export const updateWagerSuccess = payload => ({
  type: UPDATE_WAGER_SUCCESS,
  payload,
});
export const updateWagerFailure = error => ({
  type: UPDATE_WAGER_FAILURE,
  error,
});

export const resetWagerEditDel = () => ({ type: RESET_WAGER_EDIT_DEL });

export const DELETE_WAGER_SUCCESS = 'DELETE_WAGER_SUCCESS';
export const DELETE_WAGER_FAILURE = 'DELETE_WAGER_FAILURE';
export const deleteWager = wager_id => ({
  type: DELETE_WAGER,
  wager_id,
});
export const deleteWagerSuccess = (payload, wager_id) => ({
  type: DELETE_WAGER_SUCCESS,
  payload,
  wager_id,
});
export const deleteWagerFailure = error => ({
  type: DELETE_WAGER_FAILURE,
  error,
});

export const RETRIEVE_WAGER_SUCCESS = 'RETRIEVE_WAGER_SUCCESS';
export const RETRIEVE_WAGER_FAILURE = 'RETRIEVE_WAGER_FAILURE';
export const retrieveWager = wager_id => ({
  type: RETRIEVE_WAGER,
  wager_id,
});
export const retrieveWagerSuccess = payload => ({
  type: RETRIEVE_WAGER_SUCCESS,
  payload,
});
export const retrieveWagerFailure = error => ({
  type: RETRIEVE_WAGER_FAILURE,
  error,
});
