import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// components
import { Row, Col } from 'components/generic/Layout';
import ErrorDisplay from '../generic/ErrorDisplay';
import { AuthTextInput } from 'components/AuthTextInput';
import Textarea from 'components/generic/Textarea';
import { AuthButton } from 'components/AuthButton';

// actions
import {
  createAnalysisPreset,
  resetCreateAnalysisPresetSuccess,
  editAnalysisPreset,
  deleteAnalysisPreset,
} from 'actions';

export default function SaveAnalysisPreset(props) {
  const dispatch = useDispatch();
  const reduxProps = useSelector(state => ({
    filters: state.analysisReducer.filters,
    urlparams: state.analysisReducer.urlparams,
    isPostingPreset: state.analysisReducer.isPostingPreset,
    postPresetError: state.analysisReducer.postPresetError,
    postPresetSuccess: state.analysisReducer.postPresetSuccess,
    isDeletingPreset: state.analysisReducer.isDeletingPreset,
    deletePresetSuccess: state.analysisReducer.deletePresetSuccess,
  }));

  const {
    filters,
    urlparams,
    isPostingPreset,
    postPresetError,
    postPresetSuccess,
    isDeletingPreset,
    deletePresetSuccess,
  } = reduxProps;
  const { mode, ID } = props;

  const [name, setName] = useState('');
  const [desc, setDesc] = useState('');

  const saveDisabled = name.trim() === '' && mode !== 'edit';

  if (postPresetSuccess || deletePresetSuccess) {
    setTimeout(() => {
      setName('');
      setDesc('');
      dispatch(resetCreateAnalysisPresetSuccess());
      props.dismissModal();
    }, 250);
  }

  return (
    <Row style={{ paddingTop: 'var(--space-md)' }}>
      <Col style={{ alignItems: 'flex-start' }}>
        <table style={{ width: '100%' }}>
          <tbody>
            {Object.keys(filters).map((filter, i) => (
              <tr>
                <td style={{ width: '33%%', verticalAlign: 'top' }}>
                  <b>
                    {filter
                      .replace('Xaxis', 'Columns')
                      .replace('Yaxis', 'Rows')
                      .replace('_', ' ')}
                    :
                  </b>
                </td>
                <td style={{ width: '66%', verticalAlign: 'top' }}>
                  {filters[filter].slug.replace('Book', 'Sportsbook')}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Col>
      <Col style={{ flex: '0.6 0 325px', justifyContent: 'flex-start' }}>
        <Row style={{ flex: 0, width: '100%', maxWidth: '512px' }}>
          <AuthTextInput
            autoFocus={true}
            containerStyle={{
              flex: 1,
            }}
            inputRowStyle={{ backgroundColor: 'var(--color-bg)' }}
            label={'Analysis Name *'}
            placeholder={'Weekly NFL Analysis'}
            defaultValue={mode === 'edit' ? props.name : null}
            onChangeText={newAnalysisName => {
              setName(newAnalysisName);
            }}
          />
        </Row>
        <br />
        <Row style={{ flex: 0, width: '100%', maxWidth: '512px' }}>
          <Textarea
            inputStyle={{ backgroundColor: 'var(--color-bg)' }}
            maxLength={1024}
            rows={10}
            placeholder={'Description of what this preset analyses'}
            onChange={input => setDesc(input.target.value)}
            defaultValue={mode === 'edit' ? props.desc : null}
          />
        </Row>
        <br />
        <Row style={{ flex: 0, width: '100%', maxWidth: '512px' }}>
          <AuthButton
            containerStyle={{
              maxWidth: '50%',
            }}
            disabled={saveDisabled || isPostingPreset || postPresetSuccess}
            isLoading={isPostingPreset}
            onPress={() => {
              if (mode === 'edit') {
                dispatch(
                  editAnalysisPreset({
                    id: props.ID,
                    name: name.trim() || props.name,
                    description: desc.trim() || props.desc,
                  })
                );
              } else {
                dispatch(
                  createAnalysisPreset({
                    name: name.trim(),
                    description: desc.trim(),
                    url_params: urlparams,
                  })
                );
              }
            }}
          >
            {postPresetSuccess ? (
              <span style={{ color: 'var(--color-success)' }}>Success!</span>
            ) : (
              'Save'
            )}
          </AuthButton>
          {mode === 'edit' && (
            <AuthButton
              colorTheme="danger"
              containerStyle={{
                marginLeft: 'var(--space-sm)',
              }}
              isLoading={isDeletingPreset}
              onPress={() => dispatch(deleteAnalysisPreset(ID))}
            >
              Delete
            </AuthButton>
          )}
        </Row>
        {postPresetError && <ErrorDisplay error={postPresetError} basic />}
      </Col>
    </Row>
  );
}
