import { Observable } from 'rxjs';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/map';
import { ajax } from 'rxjs/observable/dom/ajax';
import { getHeaders } from 'utils';

import { GET_USERS_NEXT, searchSuccess, searchFailure } from 'actions';

export default function getUsersNext(action$, state$) {
  return action$.ofType(GET_USERS_NEXT).switchMap(() => {
    return ajax
      .getJSON(state$.value.searchReducer.searchUsersNext, getHeaders(state$))
      .map(data => searchSuccess(data))
      .catch(error => Observable.of(searchFailure(error.xhr)));
  });
}
