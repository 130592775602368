import React, { useEffect, useState, useMemo } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import moment from 'moment';
import { IoChevronBack, IoRefresh } from 'react-icons/io5';
import {
  BsLayoutSidebarInsetReverse,
  BsLayoutSidebarReverse,
} from 'react-icons/bs';
import { useUpdateEffect } from 'react-use';

// utils
import {
  getLastName,
  SECOND_HALF_LEAGUES,
  LIVE_LEAGUES,
  PLAYER_PROP_LEAGUES,
} from 'utils';

// toptabs
import FullTime from './game/FullTime.toptab';
import OneHalf from './game/OneHalf.toptab';
import TwoHalf from './game/TwoHalf.toptab';
import GameProps from './game/GameProps.toptab';
import PlayerPropPlayers from './game/PlayerPropPlayers.toptab';
import History from './game/History.toptab';
import Live from './game/Live.toptab';
import Settings from './game/Settings.toptab';
import Boxscores from './game/Boxscores.toptab';

// components
import ActivityIndicator from 'components/generic/ActivityIndicator';
import { Row, Col } from 'components/generic/Layout';
import { AuthButton, LinkButton, IconButton } from 'components/AuthButton';
import { MainAreaWrapper } from 'components/generic/Layout';
import BetSlip from 'components/BetSlip';
import TeamLeagueLogo from 'components/TeamLeagueLogo';
import PeriodScores from 'components/PeriodScores';
import OddsBtnArray from 'components/OddsBtnArray';
import ContentLoader from 'components/generic/ContentLoader';

// actions
import {
  getGameData,
  clearGameData,
  getFullTimeOdds,
  getFullTimeBA,
  getGamePropList,
  getPlayerPropPlayerList,
  getLiveOdds,
  getLiveBA,
  get1HalfOdds,
  get1HalfBA,
  getGameProps,
  getPlayerProps,
  getWagers,
  get2HalfOdds,
  get2HalfBA,
  clearPlayerPropOdds,
  clearPlayerPropPropList,
  getAffiliates,
  getPlayerBoxscores,
  getSched,
  setParlayValue,
  betslipChangeBookParlay,
  getAllBooks,
} from 'actions';

const ScrollContainer = styled.div`
  height: 100vh;
  flex: 3 0 600px;
  overflow-y: auto;
  padding-bottom: var(--space-xl);
`;

const StaticContainer = styled.div`
  height: 100vh;
  flex: 3 0 600px;
  overflow-y: hidden;
  padding-bottom: var(--space-xl);
`;

const NavBar = styled.div`
  height: 100vh;
  flex: 1 0 300px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: stretch;
  padding: var(--space-xs);
  overflow-y: auto;
  @media only screen and (max-width: 1000px) {
    height: auto;
    flex-flow: column wrap;
    max-height: 42vh;
  }
`;

const StyledTabs = styled.div`
  flex: 0;
  cursor: pointer;
  background-color: ${props =>
    props.highlight ? 'var(--color-active)' : 'null'};
  border-right: ${props =>
    props.highlight && !props.mobile
      ? '2px solid var(--color-primary)'
      : 'null'};
  border-bottom: ${props =>
    props.highlight && props.mobile
      ? '2px solid var(--color-primary)'
      : 'null'};
  font-weight: ${props => (props.highlight ? 700 : 200)};
  padding: var(--space-xs) var(--space-md);
  transition: all var(--std-transition);
  &:hover {
    background-color: var(--color-active);
  }
  @media only screen and (max-width: 1000px) {
    flex: 1;
  }
`;

const LiveDotFlash = styled.div`
  width: ${props => props.size || 10}px;
  height: ${props => props.size || 10}px;
  border-radius: 100%;
  background-color: var(--color-success);
  color: ${props => (props.live ? 'var(color-success)' : 'var(--color-text)')};
  animation: flash 2s infinite ease-in-out;
  @keyframes flash {
    0% {
      box-shadow: 0 0 0 0 var(--color-success);
    }
    50% {
      box-shadow: 0 0 16px 1px var(--color-success);
    }
    100% {
      box-shadow: 0 0 32px 2px transparent;
    }
  }
`;

export default function Game() {
  const params = useParams();
  const dispatch = useDispatch();
  const reduxProps = useSelector(state => ({
    game: state.gameReducer.game,
    isLoadingGameData: state.gameReducer.isLoadingGameData,
    fetchGameDataError: state.gameReducer.fetchGameDataError,
    defaultTopTab: state.settingsReducer.defaultTopTab,
    selectedLeagues: state.scheduleReducer.selectedLeagues,
    fullTimeBA: state.gameReducer.fullTimeBA,
    isLoadingFullTimeBA: state.gameReducer.isLoadingFullTimeBA,
    liveBA: state.gameReducer.liveBA,
    isLoadingLiveBA: state.gameReducer.isLoadingLiveBA,
    // game props
    gamePropType: state.gameReducer.gamePropType,
    // player props
    playerPropPlayerName: state.gameReducer.playerPropPlayerName,
    playerPropPropType: state.gameReducer.playerPropPropType,
    // for entering parlay mode
    showingDate: state.scheduleReducer.showingDate,
    mode: state.betslipReducer.mode,
    user: state.authReducer.user,
    allBooks: state.authReducer.allBooks,
    bets: state.betslipReducer.bets,
  }));
  const {
    game,
    isLoadingGameData,
    fetchGameDataError,
    defaultTopTab,
    selectedLeagues,
    fullTimeBA,
    isLoadingFullTimeBA,
    liveBA,
    isLoadingLiveBA,
    // game props
    gamePropType,
    // player props
    playerPropPlayerName,
    playerPropPropType,
    // for entering parlay mode
    showingDate,
    mode,
    user,
    allBooks,
    bets,
  } = reduxProps;

  let initialScreen = useMemo(() => {
    let is = 'FullTime';
    if (selectedLeagues[0] !== 'GOLF' && defaultTopTab !== 'FullTime') {
      is = defaultTopTab;
      if (
        !PLAYER_PROP_LEAGUES.includes(selectedLeagues[0]) &&
        is === 'PlayerPropPlayers'
      ) {
        is = 'FullTime';
      }
    }
    if (game?.is_live && LIVE_LEAGUES.includes(game.league)) {
      is = 'Live';
    }
    return is;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [game]);

  const [tab, setTab] = useState(initialScreen);
  const [lastRefresh, setLastRefresh] = useState(moment());
  const [showBetslip, setShowBetslip] = useState(true);

  const findDefaultBook = () => {
    let defaultParlayBook = allBooks[3];
    if (user) {
      for (let x = 0; x < user.books.length; x++) {
        if (!user.books[x].is_custom) {
          defaultParlayBook = user.books[x];
          break;
        }
      }
    }
    return defaultParlayBook;
  };

  useUpdateEffect(() => {
    if (mode === 'parlay') {
      let parlayBook;

      // check to see if all the bets on the slip are already at the same book
      let allBetsSameBook = true;
      let currBookID = null;
      for (let i = 0; i < bets.length; i++) {
        if (currBookID === null) currBookID = bets[i].book.id;
        if (currBookID !== bets[i].book.id) allBetsSameBook = false;
      }

      if (allBetsSameBook && currBookID !== null) parlayBook = bets[0].book;
      else parlayBook = findDefaultBook();

      dispatch(setParlayValue('book', parlayBook));

      dispatch(
        getSched(
          null, // leagues
          showingDate, // date
          parlayBook, // book
          null // search term
        )
      );

      if (bets.length > 0) {
        dispatch(betslipChangeBookParlay(parlayBook));
      }
    }
  }, [mode]);

  const _refresh = () => {
    setLastRefresh(moment());
    if (game) {
      dispatch(getGameData(game.id));
      dispatch(getFullTimeBA(game.id));
      if (game?.is_live) {
        dispatch(getLiveBA(game.id));
      }

      if (tab === 'FullTime') {
        dispatch(getFullTimeOdds({ game_id: game.id }));
      }

      if (tab === 'OneHalf') {
        let period = '1H';
        if (game?.league === 'NHL') {
          period = '1P';
        }
        if (game?.league === 'MLB') {
          period = 'F5';
        }
        if (['ATP', 'WTA'].includes(game?.league)) {
          period = '1S';
        }
        dispatch(get1HalfBA(game.id, period));
        dispatch(get1HalfOdds({ game_id: game.id, period: period }));
      }

      if (tab === 'TwoHalf') {
        dispatch(get2HalfBA(game.id));
        dispatch(get2HalfOdds({ game_id: game.id }));
      }

      if (tab === 'GamePropProps' && gamePropType) {
        dispatch(
          getGameProps({
            game_id: game.id,
            prop_type: gamePropType,
          })
        );
      }

      if (
        tab === 'PlayerPropPlayers' &&
        playerPropPlayerName &&
        playerPropPropType
      ) {
        dispatch(
          getPlayerProps({
            game_id: game.id,
            player_name: playerPropPlayerName,
            prop_type: playerPropPropType,
          })
        );
      }

      if (tab === 'History') {
      }

      if (tab === 'Boxscores') {
        dispatch(getPlayerBoxscores(game.id));
      }

      if (tab === 'Live') {
        dispatch(getLiveOdds({ game_id: game.id }));
        dispatch(getWagers({ screen: 'game', game_id: game.id }));
      }
    }
  };

  useEffect(() => {
    if (!params?.id) return null;
    const id = params.id;

    dispatch(getGameData(id));
    dispatch(getFullTimeBA(id));
    dispatch(getFullTimeOdds({ game_id: id }));
    dispatch(getGamePropList(id));
    dispatch(getPlayerPropPlayerList(id));
    dispatch(getLiveOdds({ game_id: id }));
    dispatch(getLiveBA(id));
    dispatch(getAffiliates());
    dispatch(getAllBooks());

    // will clear the game reducer on umount as long as the array is empty
    return () => {
      dispatch(clearPlayerPropOdds());
      dispatch(clearPlayerPropPropList());
      dispatch(clearGameData());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fullTimeTitle = useMemo(() => {
    if (game?.sport === 'Golf' && game?.length_of_match) {
      return `${game?.length_of_match} Matchup`;
    }
    return 'Full Game';
  }, [game]);

  const oneHalfTitle = useMemo(() => {
    if (game?.league === 'NHL') {
      return '1st Period';
    }
    if (game?.league === 'MLB') {
      return 'First 5';
    }
    if (['ATP', 'WTA'].includes(game?.league)) {
      return '1st Set';
    }
    if (game?.sport === 'MMA' || game?.sport === 'Golf') {
      return null;
    }
    return '1st Half';
  }, [game]);

  const gameTitle = useMemo(() => {
    if (!game) return 'Loading';
    let mid = '@';
    if (game.sport === 'Tennis' || game.sport === 'MMA') {
      mid = 'vs';
    }
    return `${getLastName(game.away_team.name)} ${mid} ${getLastName(
      game.home_team.name
    )}`;
  }, [game]);

  if (fetchGameDataError) {
    return (
      <Col style={{ justifyContent: 'center' }}>
        <h6>Sorry, we ran into an error getting this game</h6>
        <AuthButton onPress={() => dispatch(getGameData(params.id))}>
          Try Again
        </AuthButton>
      </Col>
    );
  }

  if (!game) {
    return (
      <Col style={{ height: '75%', justifyContent: 'center' }}>
        <ActivityIndicator size={3} />
      </Col>
    );
  }

  return (
    <Col style={{ height: '100vh', flexWrap: 'nowrap', overflow: 'hidden' }}>
      {game?.away_team && game?.home_team ? (
        <Helmet>
          <title>{gameTitle}</title>
        </Helmet>
      ) : (
        <Helmet>
          <title>Loading</title>
        </Helmet>
      )}
      <MainAreaWrapper>
        <MainAreaWrapper style={{ flexFlow: 'row wrap' }}>
          <NavBar>
            <Row
              style={{
                flex: 0,
                alignItems: 'center',
                justifyContent: 'space-between',
                marginBottom: 'var(--space-xs)',
                minHeight: '48px',
              }}
            >
              <LinkButton
                to="/games"
                colorTheme="text"
                btnTheme="borderless"
                leftIcon={IoChevronBack}
                containerStyle={{ margin: 0 }}
                btnStyle={{ justifyContent: 'flex-start' }}
                textStyle={{ flex: 0 }}
              >
                Back To Games
              </LinkButton>
              <div style={{ flex: 1 }} />
              <IconButton
                iconName={
                  showBetslip
                    ? BsLayoutSidebarInsetReverse
                    : BsLayoutSidebarReverse
                }
                colorTheme="text"
                title={showBetslip ? 'Hide Betslip' : 'Show Betslip'}
                onPress={() => setShowBetslip(!showBetslip)}
              />
              <IconButton
                iconName={IoRefresh}
                colorTheme="text"
                title={`Last Refresh ${lastRefresh.format('hh:mma')}`}
                onPress={_refresh}
                isLoading={isLoadingGameData}
              />
            </Row>

            <GameData game={game} />
            {game && game.is_live && (
              <BA
                isLoading={isLoadingLiveBA}
                game={game}
                ba={liveBA}
                title="Live Odds"
              />
            )}
            {game && (
              <BA
                game={game}
                ba={fullTimeBA}
                isLoading={isLoadingFullTimeBA}
                title="Best Available Full Game"
              />
            )}

            <StyledTabs
              highlight={tab === 'FullTime'}
              onClick={() => setTab('FullTime')}
            >
              {fullTimeTitle}
            </StyledTabs>

            {oneHalfTitle && (
              <StyledTabs
                highlight={tab === 'OneHalf'}
                onClick={() => setTab('OneHalf')}
              >
                {oneHalfTitle}
              </StyledTabs>
            )}

            {(game?.is_live || game?.is_complete) &&
              SECOND_HALF_LEAGUES.includes(game?.league) && (
                <StyledTabs
                  highlight={tab === 'TwoHalf'}
                  onClick={() => setTab('TwoHalf')}
                >
                  2nd Half
                </StyledTabs>
              )}

            <StyledTabs
              highlight={tab === 'GamePropProps'}
              onClick={() => setTab('GamePropProps')}
            >
              {game?.sport === 'MMA' ? 'Fighter Props' : 'Game Props'}
            </StyledTabs>

            {PLAYER_PROP_LEAGUES.includes(game?.league) && (
              <StyledTabs
                highlight={tab === 'PlayerPropPlayers'}
                onClick={() => setTab('PlayerPropPlayers')}
              >
                Player Props
              </StyledTabs>
            )}

            <StyledTabs
              highlight={tab === 'History'}
              onClick={() => setTab('History')}
            >
              Line History
            </StyledTabs>

            <StyledTabs
              highlight={tab === 'Live'}
              onClick={() => setTab('Live')}
            >
              <Row
                style={{ alignItems: 'center', justifyContent: 'flex-start' }}
              >
                {game?.is_live && (
                  <>
                    <LiveDotFlash live={game?.is_live} />
                    &nbsp;&nbsp;
                  </>
                )}
                Live
              </Row>
            </StyledTabs>
            {game.is_complete && (
              <StyledTabs
                highlight={tab === 'Boxscores'}
                onClick={() => setTab('Boxscores')}
              >
                Boxscore
              </StyledTabs>
            )}

            <StyledTabs
              highlight={tab === 'Settings'}
              onClick={() => setTab('Settings')}
            >
              Settings
            </StyledTabs>
          </NavBar>
          {tab === 'FullTime' && (
            <ScrollContainer>
              <FullTime />
            </ScrollContainer>
          )}
          {tab === 'OneHalf' && (
            <ScrollContainer>
              <OneHalf />
            </ScrollContainer>
          )}
          {tab === 'TwoHalf' && (
            <ScrollContainer>
              <TwoHalf />
            </ScrollContainer>
          )}
          {tab === 'GamePropProps' && (
            <StaticContainer>
              <GameProps setTab={setTab} />
            </StaticContainer>
          )}
          {tab === 'PlayerPropPlayers' && (
            <StaticContainer>
              <PlayerPropPlayers />
            </StaticContainer>
          )}
          {tab === 'History' && (
            <ScrollContainer>
              <History />
            </ScrollContainer>
          )}
          {tab === 'Live' && (
            <ScrollContainer>
              <Live />
            </ScrollContainer>
          )}
          {tab === 'Boxscores' && (
            <ScrollContainer>
              <Boxscores />
            </ScrollContainer>
          )}
          {tab === 'Settings' && (
            <ScrollContainer>
              <Settings />
            </ScrollContainer>
          )}
        </MainAreaWrapper>
        {showBetslip && <BetSlip />}
      </MainAreaWrapper>
    </Col>
  );
}

function GameData({ game }) {
  const reduxProps = useSelector(state => ({
    showRotationNumber: state.settingsReducer.showRotationNumber,
  }));
  const { showRotationNumber } = reduxProps;

  const gameIsToday = useMemo(() => {
    return moment(game?.date).isSame(moment(), 'day');
  }, [game]);

  if (!game) return null;
  const {
    home_team,
    away_team,
    away_score,
    home_score,
    league,
    date,
    is_live,
    is_live_text,
    is_complete,
    is_postponed,
    sport,
    season,
  } = game;

  let gameFinishString =
    'Final ' +
    (game.finish_type ? '(' + game.finish_type + ')' : '') +
    ' | ' +
    moment(game.date).format('MMM Do, YYYY');

  const gameIsStartingNow =
    moment(game.date).isBefore(moment()) &&
    !game.is_live &&
    !game.is_complete &&
    !game.is_postponed;

  const gameStartingInMinutes = moment(game.date).diff(moment(), 'minutes');

  const mid =
    sport === 'MMA' || sport === 'Tennis' || sport === 'Golf' ? 'vs' : '@';

  return (
    <>
      <div
        style={{
          backgroundColor: 'var(--color-fg)',
          borderRadius: 'var(--std-border-radius)',
          padding: 'var(--space-sm)',
          textAlign: 'center',
          boxShadow: '0 2px 8px 0 var(--color-shadow)',
          marginBottom: 'var(--space-sm)',
        }}
      >
        {is_live && is_live_text ? (
          <Row style={{ alignItems: 'center', justifyContent: 'center' }}>
            <LiveDotFlash live={is_live} />
            <span style={{ color: 'var(--color-success)' }}>
              &nbsp;&nbsp;{is_live_text.replace('●', '')}
            </span>
          </Row>
        ) : (
          <span>
            {gameIsStartingNow
              ? ''
              : is_postponed
              ? 'Postponed'
              : is_complete
              ? gameFinishString
              : gameIsToday
              ? 'Today'
              : moment(date).format('dddd MMMM DD @ h:mma')}
          </span>
        )}
        <Row style={{ flex: 0, alignItems: 'center' }}>
          <TeamLeagueLogo
            style={{ flex: 0 }}
            iconSize={50}
            team={away_team.name}
            league={league}
            displayTeam
          />
          {gameIsStartingNow ? (
            <Row style={{ alignItems: 'center', justifyContent: 'center' }}>
              <LiveDotFlash size={16} />
              <h4 style={{ margin: 0 }}>&nbsp;&nbsp;Starting Now</h4>
            </Row>
          ) : gameStartingInMinutes >= 0 && gameStartingInMinutes <= 30 ? (
            <h6>
              Starts in {gameStartingInMinutes}{' '}
              {gameStartingInMinutes === 1 ? 'minute' : 'minutes'}
            </h6>
          ) : gameIsToday && !(game.is_live || game.is_complete) ? (
            <h3 style={{ margin: 0, flex: 1, textAlign: 'center' }}>
              {moment(date).format('h:mma')}
            </h3>
          ) : is_complete || is_live ? (
            <h3 style={{ margin: 0, flex: 1, textAlign: 'center' }}>
              {away_score}&nbsp;&nbsp; - &nbsp;&nbsp;{home_score}
            </h3>
          ) : (
            <h3 style={{ margin: 0, flex: 1, textAlign: 'center' }}>{mid}</h3>
          )}
          <TeamLeagueLogo
            style={{ flex: 0 }}
            iconSize={50}
            team={home_team.name}
            league={league}
            displayTeam
          />
        </Row>

        <Row style={{ flex: 0, alignItems: 'center' }}>
          <span style={{ flex: 1, textAlign: 'left' }}>
            {away_team.full_name}
            {showRotationNumber && game.away_rotation_number && (
              <small>&nbsp;({game.away_rotation_number})</small>
            )}
          </span>
          <span style={{ flex: 1, textAlign: 'right' }}>
            {showRotationNumber && game.home_rotation_number && (
              <small>({game.home_rotation_number})&nbsp;</small>
            )}
            {home_team.full_name}
          </span>
        </Row>
        {season && season.name && (
          <small>
            <b>{season.name}</b>
          </small>
        )}
        {game.length_of_match && (
          <small>
            <b>&nbsp;{game.length_of_match}</b>
          </small>
        )}
      </div>
      {game.period_scores && game.period_scores !== {} && (
        <PeriodScores
          game={game}
          style={{
            border: 'none',
            backgroundColor: 'var(--color-fg)',
            boxShadow: '0 2px 8px 0 var(--color-shadow)',
            marginBottom: 'var(--space-sm)',
          }}
          hideNames
          hideLiveText
        />
      )}
    </>
  );
}

function BA(props) {
  const { game, ba, title, isLoading } = props;
  if (isLoading) {
    return (
      <ContentLoader
        height="145px"
        style={{
          borderRadius: 'var(--std-border-radius)',
          marginBottom: 'var(--space-sm)',
        }}
      />
    );
  }
  return (
    <Col
      style={{
        flex: 0,
        backgroundColor: 'var(--color-fg)',
        borderRadius: 'var(--std-border-radius)',
        boxShadow: '0 2px 8px 0 var(--color-shadow)',
        padding: 'var(--space-sm)',
        marginBottom: 'var(--space-sm)',
      }}
    >
      <span style={{ marginBottom: 'var(--space-xs)' }}>{title}</span>
      <Row style={{ width: '100%' }}>
        <Col style={{ flex: 0 }}>
          {['BUND', 'SERA', 'LIGA', 'LIG1', 'UCL', 'EPL'].includes(
            game.league
          ) ? (
            <>
              <TeamLeagueLogo
                iconSize={28}
                displayTeam
                league={game.league}
                team={game.home_team.name}
              />
              <TeamLeagueLogo
                iconSize={28}
                displayTeam
                league={game.league}
                team={game.away_team.name}
              />
            </>
          ) : (
            <>
              <TeamLeagueLogo
                iconSize={28}
                displayTeam
                league={game.league}
                team={game.away_team.name}
              />
              <TeamLeagueLogo
                iconSize={28}
                displayTeam
                league={game.league}
                team={game.home_team.name}
              />
            </>
          )}

          {game.sport === 'Soccer' && (
            <h6 style={{ textAlign: 'center' }}>TIE</h6>
          )}
        </Col>

        <OddsBtnArray game={game} odds={ba} />
      </Row>
    </Col>
  );
}
