import { Observable } from 'rxjs';
import 'rxjs/add/operator/exhaustMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/of';
import { ajax } from 'rxjs/observable/dom/ajax';
import { BACKEND_API_URL, getHeaders } from 'utils';

import {
  REFRESH_HISTORY_ALL,
  refreshHistoryAllSuccess,
  refreshHistoryAllFailure,
} from 'actions';

export default function refreshHistoryAll(action$, state$) {
  return action$.ofType(REFRESH_HISTORY_ALL).exhaustMap(action => {
    return ajax
      .post(
        BACKEND_API_URL + 'api/autosync/refresh_all/',
        action.params,
        getHeaders(state$)
      )
      .map(data => refreshHistoryAllSuccess(data))
      .catch(error => Observable.of(refreshHistoryAllFailure(error.xhr)));
  });
}
