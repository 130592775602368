import { Observable } from 'rxjs';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import { ajax } from 'rxjs/observable/dom/ajax';
import { BACKEND_API_URL, getHeaders } from 'utils';

import {
  REFRESH_SHARPSPORTS_BOOK,
  refreshSharpsportsBookSuccess,
  refreshSharpsportsBookFailure,
} from 'actions';

export default function refreshSharpsportsBook(action$, state$) {
  return action$.ofType(REFRESH_SHARPSPORTS_BOOK).switchMap(action => {
    let url = `${BACKEND_API_URL}api/sharpsports/refreshresponse/?bettor_account=${action.bettorAccount}`;
    if (action.reverify) {
      url += `&reverify=true`;
    }
    return ajax
      .getJSON(url, getHeaders(state$))
      .map(data => refreshSharpsportsBookSuccess(data))
      .catch(error => Observable.of(refreshSharpsportsBookFailure(error.xhr)));
  });
}
