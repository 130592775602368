import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';

// components
import ActivityIndicator from 'components/generic/ActivityIndicator';
import ContentLoader from 'components/generic/ContentLoader';
import Wager from 'components/Wager';

// actions
import { getBetSelectionNext } from 'actions';

const placeholders = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

export default function BetSelection() {
  const dispatch = useDispatch();
  const reduxProps = useSelector(state => ({
    currentAnalysis: state.analysisReducer.currentAnalysis,
    currentUrlParams: state.analysisReducer.currentUrlParams,
    isLoadingBets: state.analysisReducer.isLoadingBets,
    // fetchError: state.analysisReducer.fetchError,
  }));

  const {
    currentAnalysis,
    isLoadingBets,
    // fetchError,
    currentUrlParams,
  } = reduxProps;

  if (!currentAnalysis) {
    return <></>;
  }

  if (isLoadingBets) {
    return (
      <>
        {placeholders.map(p => (
          <div
            key={`bet-selection-loading-placeholder-${p}`}
            style={{
              boxSizing: 'border-box',
              margin: 'var(--space-sm) var(--space-xxs)',
            }}
          >
            <ContentLoader
              style={{
                borderRadius: 'var(--std-border-radius)',
              }}
              height={'50px'}
            />
          </div>
        ))}
      </>
    );
  }

  return (
    <>
      <InfiniteScroll
        scrollableTarget={'analysis-infinite-scroll-target'}
        scrollThreshold={0.66}
        next={() => {
          if (currentAnalysis.next) {
            dispatch(
              getBetSelectionNext(currentAnalysis.next, currentUrlParams)
            );
          }
        }}
        hasMore={currentAnalysis.next}
        dataLength={currentAnalysis.bets}
        loader={
          <ActivityIndicator
            size={1.5}
            style={{ margin: 'var(--space-xxs) auto' }}
          />
        }
        style={{
          overflow: 'hidden',
          padding: 'var(--space-xxs)',
          minHeight: '100vh',
        }}
      >
        {currentAnalysis.bets.length === 0 && (
          <p style={{ textAlign: 'center' }}>No bets match these filters!</p>
        )}
        {currentAnalysis.bets.map((wager, i) => (
          <Wager
            key={`bet-selection-list-${wager.id}`}
            wager={wager}
            disableModal
          />
        ))}
      </InfiniteScroll>
    </>
  );
}
