import { Observable } from 'rxjs';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import { ajax } from 'rxjs/observable/dom/ajax';
import { BACKEND_API_URL, getHeaders } from 'utils';
import moment from 'moment';

import {
  GET_ANALYSIS,
  setAnalysis,
  getAnalysisSuccess,
  getAnalysisFailure,
} from 'actions';

function _analysisIsCached(url, tab, cachedAnalysis) {
  const isCached = cachedAnalysis[url] !== undefined;
  if (isCached) {
    // partial cache hit
    // is it too old?
    const cachedDate = cachedAnalysis[url].cachedAt;
    const rightNow = moment();
    const duration = moment.duration(rightNow.diff(cachedDate));
    const cacheIsOld = duration.asMinutes() > 30;

    if (cacheIsOld) {
      // cache miss
      return false;
    } else {
      // potential cache hit
      // does the cache contain the information we want?
      if (tab && tab === 'bets' && cachedAnalysis[url].bets.length === 0) {
        // you're asking for bets but we haven't got them yet
        // but we do have the table. cache miss
        return false;
      } else {
        // your asking for the table and we have it. Because the table is always
        // requested first, you won't have a cache hit if you don't have the table.
        // full cache hit
        return true;
      }
    }
  } else {
    // complete cache miss (no table, no bets, never requested ever)
    return false;
  }
}

export default function getAnalysis(action$, state$) {
  return action$.ofType(GET_ANALYSIS).switchMap(action => {
    let url = BACKEND_API_URL + `api/analysis/${action.urlparams}`;
    if (!action.force) {
      const { cachedAnalysis } = state$.value.analysisReducer;
      if (_analysisIsCached(url, action.tab, cachedAnalysis)) {
        return Observable.of(
          setAnalysis(cachedAnalysis[url], action.urlparams)
        );
      }
    }

    url += `ref_date=${moment().toISOString(true)}&`;
    let urlWithTab = url + `tab=${action.tab}`;

    return ajax
      .getJSON(urlWithTab, getHeaders(state$))
      .map(data => getAnalysisSuccess(data, url))
      .catch(error => Observable.of(getAnalysisFailure(error.xhr)));
  });
}
