import React from 'react';
import { Link } from 'react-router-dom';

// components
import { Row, Col } from 'components/generic/Layout';
import { BetstampStampInverted } from 'components/generic/Logos';

export default function TermsAndConditions() {
  return (
    <div className="container" style={{ padding: 'var(--space-xxl)' }}>
      <Row>
        <Col>
          <BetstampStampInverted height={128} />
          <h1 style={{ marginTop: 0 }}>Terms {'&'} Conditions of Use</h1>
        </Col>
      </Row>

      <h3>Acceptance of Agreement</h3>
      <p>
        The Terms and Conditions of Use on this page represent a legal document
        for the websites, betstamp.app, betstamp.com, and any subdomains of
        those websites, and our Mobile Application, betstamp, owned by Betstamp
        Inc. operating under the business name of Cashew Tech. Both the web and
        mobile versions of betstamp will be referred to in this document as
        “Platform” or “the Platform”. The company Betstamp Inc. operating under
        the business name of Cashew Tech, will be referred to as “we”, “our”,
        “betstamp” or “Betstamp”.
        <br />
        <br />
        Using, accessing, or visiting the Platform, or browsing any of its
        content constitutes acceptance of these Terms and Conditions. By using
        the Platform, you have agreed, without limitation or qualification, to
        be bound by these terms and conditions of use and any other terms that
        may apply. If you do not agree with these terms, please exit the
        Platform and do not use it any further.
        <br />
        <br />
        <b>Changes to the Terms and Conditions of Use</b>
        : We reserve the right to change these Terms and Conditions of Use at
        any time by giving notice of changes by email and on the Platform. You
        are encouraged to review these Terms and Conditions periodically to
        remain informed. Your continued use of the Platform after receiving
        notification of changes to the Terms and Conditions will constitute your
        acceptance of such change. If you do not agree with any changes to the
        terms and conditions of use, you can choose to discontinue use of the
        Platform. Unless otherwise stated, any additional terms and conditions
        will be incorporated into this agreement.
        <br />
        <br />
        <b>Privacy Policy</b>: The Privacy Policy for the Platform can be found{' '}
        <Link to="/privacy-policy">here</Link>. For information on how we
        collect, use and safeguard your data, please refer to the Privacy
        Policy. For questions, comments and concerns, please contact us at
        contact@betstamp.app.
      </p>

      <h3>Intellectual Property and Content Rights</h3>
      <p>
        Unless otherwise indicated, the Platform and its contents are
        proprietary property and all source code, databases, functionality,
        software, website designs, audio, video, text, photographs, and graphics
        on the Platform (collectively, the “Content”) are controlled by us. When
        accessing the Platform, you agree to act within legal guidelines for
        intellectual property.
        <br />
        <br />
        Any trademarks and logos contained in the Platform are owned or
        controlled by us, have been licensed to us, or have been deemed fair use
        by law. These trademarks are protected by copyright and trademark laws.
        The content and the trademarks are provided on the Platform for personal
        use only. Unless expressly noted in these terms and conditions of use,
        no part of the Platform may be copied, reproduced, aggregated,
        republished, uploaded, posted, publicly displayed, encoded, translated,
        transmitted, distributed, sold, licensed, or otherwise exploited for any
        commercial purpose whatsoever, without our express prior written
        permission.
        <br />
        <br />
        If you believe that any material available on the Platform infringes
        upon any copyright you own or control, please notify us immediately by
        emailing contact@betstamp.app. Please be advised that pursuant to
        federal law you may be held liable for damages if you make material
        misrepresentations in a claim.
      </p>

      <h3>Use of Platform - Restrictions</h3>
      <p>
        You may use the platform and its content for personal use only
        <br />
        <br />
        <b>User Registration</b>: To use the platform, you are required to
        create an account. To create an account, you will be required to provide
        information including, but not limited to, your email address. In some
        cases, you will be required to enter a username. We reserve the right to
        remove, reclaim, or change a username you select if we determine, in our
        sole discretion, that such username is inappropriate or for any other
        reason we deem fit.
        <br />
        <br />
        <b>Protecting Your Account</b>: We currently implement bank level
        encryption and follow industry standards for safeguarding your
        information, including physical and technological security measures.
        However, we cannot safeguard information which is within your
        possession. You are solely responsible for keeping your account
        information confidential and secure. We assume that all transactions
        made through your account were made and managed by you. Any failure by
        you to protect your account information is at your own risk and expense.
        We recommend keeping your password confidential and following best
        practices for account security, including, but not limited to, creating
        a complex password, not using the same password for multiple platforms,
        and storing your password in a secure location. If you suspect your
        account is being accessed by somebody other than you, please email us
        immediately at contact@betstamp.app.
        <br />
        <br />
        <b>User Representation</b>: By using the platform, you represent and
        warrant that: All registration information you submit will be true,
        accurate, current, and complete. You will maintain the accuracy of such
        information and promptly update the information as necessary. You have
        the legal capacity and you agree to comply with these Terms of Use. You
        are not a minor in the jurisdiction in which you reside. You will not
        access the Platform through automated or non-human means, whether
        through a bot, script or otherwise. You will not use the Platform for
        any illegal or unauthorized purpose. Your use of the Platform will not
        violate any applicable law or regulation. If you provide any information
        that is untrue, inaccurate, not current, or incomplete, we have the
        right to suspend or terminate your account and refuse any and all
        current or future use of the Platform.
        <br />
        <br />
        <b>Multi-Account Usage</b>: We do not allow multi-accounting. The
        creation or use of more than one account per person is strictly
        prohibited. Any account created on the Platform is non-transferrable and
        to be used for personal use only. Any attempt to transfer, sell, buy or
        otherwise collude or collaborate on multiple accounts is strictly
        prohibited. If suspected of having multiple accounts under common
        control, all accounts under common control may be suspended and or
        permanently terminated.
        <br />
        <br />
        <b>Age Restriction Notice</b>: This application is intended for use by
        adults only. By using this application, you certify that you are at
        least 18 years of age. We do not knowingly or intentionally collect
        information from anyone under the age of 18. If you are under the age of
        18, do not submit any Personal Information on the application. If you
        are under the age of 18 and have submitted Personal Information to the
        application, please contact us to have this information removed at
        contact@betstamp.app.
        <br />
        <br />
        <b>As a user of the platform, you agree not to</b>:
        <br />
        <li style={{ paddingLeft: 15 }}>
          Systematically retrieve data or other content from the Platform to
          create or compile, directly or indirectly, a collection, compilation,
          database, or directory without written permission from us.
        </li>
        <li style={{ paddingLeft: 15 }}>
          Make any unauthorized use of the Platform, including collecting
          usernames and/or email addresses of users by electronic or other means
          for the purpose of sending unsolicited email, or creating user
          accounts by automated means or under false pretenses.
        </li>
        <li style={{ paddingLeft: 15 }}>
          Circumvent, disable, or otherwise interfere with security-related
          features of the Platform, including features that prevent or restrict
          the use or copying of any Content or enforce limitations on the use of
          the Platform and/or the Content contained therein.
        </li>
        <li style={{ paddingLeft: 15 }}>
          Engage in unauthorized framing of or linking to the Platform.
        </li>
        <li style={{ paddingLeft: 15 }}>
          Trick, defraud, or mislead us and other users, especially in any
          attempt to learn sensitive account information such as user passwords.
        </li>
        <li style={{ paddingLeft: 15 }}>
          Make improper use of our support services or submit false reports of
          abuse or misconduct.
        </li>
        <li style={{ paddingLeft: 15 }}>
          Engage in any automated use of the Platform, such as using scripts to
          send comments or messages, or using any data mining, robots, or
          similar data gathering and extraction tools.
        </li>
        <li style={{ paddingLeft: 15 }}>
          Interfere with, disrupt, or create an undue burden on the Platform or
          the networks or services connected to the Platform. Attempt to
          impersonate another user to Post any Content that violates any local,
          state, federal, or international laws.
        </li>
        <li style={{ paddingLeft: 15 }}>
          Attempt to impersonate another user or person or use the username of
          another user.
        </li>
        <li style={{ paddingLeft: 15 }}>
          Post any Content that violates any local, state, federal, or
          international laws.
        </li>
        <li style={{ paddingLeft: 15 }}>
          Upload or transmit (or attempt to upload or to transmit) viruses,
          Trojan horses, or other material that interferes with the Platforms
          ability to operate.
        </li>
        <li style={{ paddingLeft: 15 }}>
          Clone or replicate any part of the platform for any purpose.
        </li>
        <li style={{ paddingLeft: 15 }}>
          Utilize automated methods to access the Platform, including but not
          limited to, utilizing scripts to directly access underlying APIs, send
          comments or messages, or employing data mining, robots, or similar
          tools for gathering and extracting data.
        </li>
        <li style={{ paddingLeft: 15 }}>
          Engage in any unauthorized or unlawful extraction, scraping, or
          duplication of data or content from the platform.
        </li>
        <li style={{ paddingLeft: 15 }}>
          Distribute your betstamp account or betstamp pro subscription to any
          third party. This encompasses sharing your login credentials,
          providing screenshots of content that is accessible only behind
          paywalls, as well as sharing calculations, suggested bet
          recommendations, and any other content.
        </li>
        <br />
        <b>Unlawful Activity</b>: We reserve the right to investigate and take
        any action we deem necessary in regard to suspected unlawful activity
        occurring on the Platform, including, but not limited to, actively
        contacting law enforcement agencies.
      </p>

      <h3>Use of Mobile Application on Apple and Android Devices</h3>
      <p>
        The following terms apply when you use a mobile application obtained
        from either the Apple Store or Google Play (each an “App Distributor”)
        to access the Platform: (1) the license granted to you for our mobile
        application is limited to a non-transferable license to use the
        application on a device that utilizes the Apple iOS or Android operating
        systems, as applicable, and in accordance with the usage rules set forth
        in the applicable App Distributor’s terms of service; (2) we are
        responsible for providing any maintenance and support services with
        respect to the mobile application as specified in the terms and
        conditions of this mobile application license contained in these Terms
        of Use or as otherwise required under applicable law, and you
        acknowledge that each App Distributor has no obligation whatsoever to
        furnish any maintenance and support services with respect to the mobile
        application; (3) in the event of any failure of the mobile application
        to conform to any applicable warranty, you may notify the applicable App
        Distributor, and the App Distributor, in accordance with its terms and
        policies, may refund the purchase price, if any, paid for the mobile
        application, and to the maximum extent permitted by applicable law, the
        App Distributor will have no other warranty obligation whatsoever with
        respect to the mobile application; (4) you represent and warrant that
        (i) you are not located in a country that is subject to a Canadian or
        U.S. government embargo, or that has been designated by the Canadian or
        U.S. government as a “terrorist supporting” country and (ii) you are not
        listed on any Canadian or U.S. government list of prohibited or
        restricted parties; (5) you must comply with applicable third-party
        terms of agreement when using the mobile application, e.g., if you have
        a VoIP application, then you must not be in violation of their wireless
        data service agreement when using the mobile application; and (6) you
        acknowledge and agree that the App Distributors are third-party
        beneficiaries of the terms and conditions in this mobile application
        license contained in these Terms of Use, and that each App Distributor
        will have the right (and will be deemed to have accepted the right) to
        enforce the terms and conditions in this mobile application license
        contained in these Terms of Use against you as a third-party beneficiary
        thereof.
      </p>

      <h3>Feedback and User Content</h3>
      <p>
        <b>Feedback</b>
        : Any comments, feedback, ideas, concepts, or notes regarding the
        Platform or that you have provided to betstamp are deemed to be property
        of betstamp. The Platform may use this information for any purpose,
        including improvement of the Platform. Personal information is not
        included in this feedback.
        <br />
        <br />
        <b>User Content</b>: The Platform may contain profiles, interactive
        features, messages, groups and other interactive features. By using
        these features to post messages, photos, descriptions, breakdowns,
        written articles, and other content (considered User Content) you hereby
        grant betstamp the right to non-exclusive, irrevocable, unrestricted,
        perpetual, transferable, worldwide, royalty-free and fully paid-up right
        and license to use, reproduce, modify, perform, display, and distribute
        the User Content for any purpose. betstamp is not liable or responsible
        for any third-party content or User Content that is posted to or
        available on the Platform. Personal information is not included in this
        user content.
      </p>

      <h3>Services</h3>
      <h6 style={{ marginBottom: -10 }}>
        <b>Our Platform Does Not Accept Real Money Wagers</b>
      </h6>
      <p>
        Betstamp does not accept real money wagers of any kind. All content,
        odds, and any information on betstamp are for entertainment purposes
        only. betstamp does not recommend or endorse illegal or irresponsible
        gambling. betstamp features are for tracking purposes only and are not
        for real money. All betstamp users should refer to local gambling laws
        before gambling with real money. If you or someone you know has a
        gambling problem and wants help, call 1-800-522-4700.
      </p>
      <h6 style={{ marginBottom: -10 }}>
        <b>Selling Sports Betting Information and Predictions</b>
      </h6>
      <p>
        Any user can register to sell the information or predictions they post
        on the Platform by setting their account as such in the Platform. In
        order for a prediction to be sold, it must be classified as “Verified”
        by betstamp’s software. In order to be classified as “verified” the
        prediction must be tracked before the scheduled start time of the game,
        and at odds that are currently posted on the Platform. Once a bet is
        tracked and verified by the Platform, it can never be undone or deleted.
        Therefore, it is your responsibility to ensure all data is entered
        accurately. We are not responsible for inaccuracies or mistakes when
        entering predictions.
        <br />
        <br />
        By registering to sell your predictions through the Platform, you
        confirm that the predictions are original, or that you have expressed
        consent from the originator to post these predictions. Copying other
        users on the Platform or off the Platform and selling their predictions
        for financial gain or other reasons is strictly prohibited.
        <br />
        <br />
        By registering to sell your predictions through the Platform, you
        confirm you are not deemed ineligible due to a conflict of interest with
        your employer, governing agency, or through a non-disclosure agreement
        of any sort.
        <br />
        <br />
        <b>Proceeds & Taxes</b>: betstamp has no obligation to report any
        earnings from the sale of sports betting predictions and information. By
        using the Platform, you acknowledge and agree that you are solely
        responsible for reporting and paying any and all taxes that may be
        applicable to you as a result of using the Platform.
        <br />
        <br />
        <b>Payment Processing</b>: In order to receive payment for selling
        predictions through the platform, users must register for a Stripe
        payment account. Stripe is a third-party payment processor; to read
        their terms and conditions, as well as their privacy policy, visit
        www.stripe.com. In order to pay for sports betting predictions, users
        can do so via credit card through our third-party payment processor,
        Stripe. We are not responsible for any erroneous purchases.
      </p>

      <h6 style={{ marginBottom: -10 }}>
        <b>Corrections</b>
      </h6>
      <p>
        There may be information on the Platform that contains errors,
        inaccuracies, or omissions that may relate to the Platform, including
        descriptions, pricing, availability, and various other information. We
        reserve the right to correct any errors, inaccuracies, or omissions and
        to change or update the information on the Platform at any time, without
        prior notice.
        <br />
        <br />
        At times, odds displayed on the Platform may be inaccurate or delayed.
        We are not responsible for these inaccuracies and reserve the right to
        correct these inaccuracies at any time for any reason. This may include,
        but is not limited to, adjusting your public record and profile to
        re-grade tracked bets, change odds, or remove tracked bets.
      </p>

      <h3>Betstamp Pro Subscription</h3>
      <p>
        <b>Subscription Fees and Taxes</b>: The fees associated with Betstamp
        Pro subscriptions and related services will be clearly communicated to
        you at the time of your purchase or sign-up. Please be aware that these
        fees are subject to change at our discretion. Additionally, it's
        important to note that fees may be subject to applicable taxes, which
        could be collected by us or a third-party service provider facilitating
        the transaction. Subscription fees are typically non-refundable, except
        as explicitly described in these Terms.
        <b></b>:
      </p>
      <p>
        <b>Renewal</b>: By continuing with your subscription, you acknowledge
        and agree that unless you explicitly notify us prior to a scheduled
        charge that you wish to cancel your subscription or opt out of automatic
        renewal, your subscription will automatically renew. Through this
        process, you authorize us or our designated third-party payment
        processor to collect the relevant fees and any applicable taxes using
        the payment method on file. Should all payment methods on file be
        declined, we reserve the right to cancel your subscription unless
        alternative payment details are provided. If you update your payment
        information and a successful charge is processed before the cancellation
        of your subscription, the new subscription period will commence from the
        original renewal date, not the date of the successful charge.
      </p>
      <p>
        <b>Subscription Cancellation</b>: You have the option to cancel your
        Betstamp Pro subscription at any time. This can be accomplished by
        accessing your account settings and navigating to the Subscription
        section, or by reaching out to our Customer Service team for assistance.
        Please be aware that upon cancellation, you will not be entitled to a
        refund for any fees already paid. Your subscription will remain active
        until the conclusion of the current billing cycle, at which point it
        will terminate.
      </p>
      <p>
        <b>Termination by Us</b>: We reserve the right to cancel the Service and
        any subscriptions, in whole or in part, and to terminate your
        subscription and use of the Service at our discretion, without prior
        notice. Upon such termination, we will provide you with a prorated
        refund based on the number of days remaining in your subscription. You
        will not be entitled to a refund if the termination is due to any of the
        following reasons:
        <li style={{ paddingLeft: 15 }}>
          Conduct that violates the Terms of Service
        </li>
        <li style={{ paddingLeft: 15 }}>Violation of applicable law</li>
        <li style={{ paddingLeft: 15 }}>Fraud or misuse of the Service</li>
        <li style={{ paddingLeft: 15 }}>
          Actions deemed harmful to our interests or to other users
        </li>
        Please note that our failure to insist upon or enforce strict compliance
        with the Terms of Service does not constitute a waiver of any of our
        rights.
      </p>

      <h4>DISCLAIMER</h4>
      <p>
        THE SITE IS PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU AGREE THAT
        YOUR USE OF THE SITE SERVICES WILL BE AT YOUR SOLE RISK. TO THE FULLEST
        EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED,
        IN CONNECTION WITH THE SITE AND YOUR USE THEREOF, INCLUDING, WITHOUT
        LIMITATION, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
        PARTICULAR PURPOSE, AND NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR
        REPRESENTATIONS ABOUT THE ACCURACY OR COMPLETENESS OF THE SITE’S CONTENT
        OR THE CONTENT OF ANY WEBSITES LINKED TO THIS SITE AND WE WILL ASSUME NO
        LIABILITY OR RESPONSIBILITY FOR ANY (1) ERRORS, MISTAKES, OR
        INACCURACIES OF CONTENT AND MATERIALS, (2) PERSONAL INJURY OR PROPERTY
        DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE
        OF THE SITE, (3) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS
        AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION
        STORED THEREIN, (4) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR
        FROM THE SITE, (5) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH
        MAY BE TRANSMITTED TO OR THROUGH THE SITE BY ANY THIRD PARTY, AND/OR (6)
        ANY ERRORS OR OMISSIONS IN ANY CONTENT AND MATERIALS OR FOR ANY LOSS OR
        DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY CONTENT
        POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE SITE. WE DO NOT
        WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY PRODUCT OR
        SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH THE SITE, ANY
        HYPERLINKED WEBSITE, OR ANY WEBSITE OR MOBILE APPLICATION FEATURED IN
        ANY BANNER OR OTHER ADVERTISING, AND WE WILL NOT BE A PARTY TO OR IN ANY
        WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND ANY
        THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES. AS WITH THE PURCHASE OF A
        PRODUCT OR SERVICE THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT, YOU SHOULD
        USE YOUR BEST JUDGMENT AND EXERCISE CAUTION WHERE APPROPRIATE.
      </p>

      <h4>LIMITATIONS OF LIABILITY</h4>
      <p>
        IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE TO
        YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL,
        EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING LOST
        PROFIT, LOST REVENUE, LOSS OF DATA, OR OTHER DAMAGES ARISING FROM YOUR
        USE OF THE PLATFORM, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF
        SUCH DAMAGES. NOTHING IN THESE TERMS AND CONDITIONS EXCLUDES LIABILITY
        FOR DEATH OR PERSONAL INJURY, FRAUD OR FRAUDULENT MISREPRESENTATION, OR
        OTHER STATUTORY RIGHTS AS REQUIRED BY LAW. NOTWITHSTANDING ANYTHING TO
        THE CONTRARY CONTAINED HEREIN, OUR LIABILITY TO YOU FOR ANY CAUSE
        WHATSOEVER AND REGARDLESS OF THE FORM OF THE ACTION, WILL AT ALL TIMES
        BE LIMITED TO THE AMOUNT PAID, IF ANY, BY YOU TO US FOR SERVICES
        ACCESSED DURING THE PREVIOUS THREE MONTHS OF YOUR MEMBERSHIP PRIOR TO
        THE EVENT GIVING RISE TO LIABILITY.
      </p>
      <h4>INDEMNIFICATION</h4>
      <p>
        To the maximum extent permissible under the law, you agree to defend,
        indemnify, and hold us harmless, including our subsidiaries, affiliates,
        and all of our respective officers, agents, partners, and employees,
        from and against any loss, damage, liability, claim, or demand,
        including reasonable attorneys’ fees and expenses, made by any third
        party due to or arising out of your contributions or use of the
        Platform; your breach of these Terms and Conditions of Use; any breach
        of your representations and warranties set forth in these Terms and
        Conditions of Use; your violation of the rights of a third party,
        including but not limited to intellectual property rights; or any overt
        harmful act toward any other user of the Platform with whom you
        connected via the Platform. Notwithstanding the foregoing, we reserve
        the right, at your expense, to assume the exclusive defense and control
        of any matter for which you are required to indemnify us, and you agree
        to cooperate, at your expense, with our defense of such claims. We will
        use reasonable efforts to notify you of any such claim, action, or
        proceeding which is subject to this indemnification upon becoming aware
        of it.
      </p>
      <h3>Severability</h3>
      <p>
        If any part of these Terms and Conditions of Use are deemed invalid or
        unenforceable, that portion will be null and void and the remaining
        portions will remain in effect.
      </p>
      <h3>Contact Us</h3>
      <p>
        Please contact us by email at contact@betstamp.app to ask any questions
        regarding the platform or to receive further information regarding the
        use of the platform.
      </p>
      <h4>Last Updated: November 9, 2023</h4>
    </div>
  );
}
