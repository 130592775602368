export default {
  link_to_video: '',
  content: [
    {
      type: 'paragraph',
      text:
        'Edit your sportsbook settings by going to the setting page and looking at the Sportsbooks section as shown below:',
    },
    {
      type: 'image',
      url:
        'https://betstamp-public.sfo2.cdn.digitaloceanspaces.com/tutorials/my-sportsbooks/settings-sportsbook.jpg',
    },
    {
      type: 'paragraph',
      text:
        'To add Sportsbooks, click on “My Sportsbooks” and tap the blue plus button in the bottom right hand corner of your screen as shown in the video below. Here you can add any Sportsbook in the list or choose to add your own Sportsbook. Note, if you add your own Sportsbook, betstamp will not have odds for this Sportsbook in the app.',
    },
    {
      type: 'video',
      url:
        'https://betstamp-public.sfo2.cdn.digitaloceanspaces.com/tutorials/my-sportsbooks/sportbooks-settings-video.mp4',
    },
    {
      type: 'paragraph',
      text:
        'Tapping and holding one Sportsbook enables you to drag and reorder. The sportsbook you order first (top of your screen), will be labeled as your primary book. To change this, simply drag the sportsbook you would like to identify as your primary to the top of the page as shown in the video below.',
    },
    {
      type: 'paragraph',
      text:
        'Use the Additional and Best available settings to determine which odds will be displayed in your games screen.',
    },
    {
      type: 'image',
      url:
        'https://betstamp-public.sfo2.cdn.digitaloceanspaces.com/tutorials/my-sportsbooks/full-screenshot.png',
    },
    {
      type: 'paragraph',
      text:
        'For your additional sportsbooks, select if you would like all sportsbooks to be displayed by selecting all, or just the sportsbooks available in your state or province by selecting state.',
    },
    {
      type: 'paragraph',
      text:
        'For your best available odds, select if you would like the best available odds to be aggregated from all sportsbooks, sportsbooks in your state or region, or from just the sportsbooks you have selected in my books.',
    },
    {
      type: 'image',
      url:
        'https://betstamp-public.sfo2.cdn.digitaloceanspaces.com/tutorials/my-sportsbooks/settings-sportsbook.jpg',
    },
    {
      type: 'paragraph',
      text:
        'Note. if you have selected “My Books” under the Best Available setting, but you have not selected any Sportsbooks in “My Sportsbooks” then the odds on your game screen will not appear. You must select at least one Sportsbooks.',
    },
  ],
};
