import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

// components
import ActivityIndicator from 'components/generic/ActivityIndicator';
import {
  Row,
  Col,
  MainAreaWrapper,
  InnerMainArea,
  Toolbar,
} from 'components/generic/Layout';
import OddBtn2 from 'components/generic/OddBtn2';
import TeamLeagueLogo from 'components/TeamLeagueLogo';
import BetSlip from 'components/BetSlip';
import AffiliateLink from 'components/AffiliateLink';
import FAB from 'components/generic/FAB';
import { IconButton, AuthButton } from 'components/AuthButton';

import {
  IoChevronForward,
  IoChevronBack,
  IoRefresh,
  IoTodayOutline,
  IoPencilSharp,
  IoEnterOutline,
  IoExitOutline,
} from 'react-icons/io5';

// actions
import { getFuturesOdds, getAffiliates, getAllBooks } from 'actions';

export default function Future() {
  const dispatch = useDispatch();
  const reduxProps = useSelector(state => ({
    user: state.authReducer.user,
    isLoadingFuturesOdds: state.scheduleReducer.isLoadingFuturesOdds,
    futuresOddsError: state.scheduleReducer.futuresOddsError,
    futuresOdds: state.scheduleReducer.futuresOdds,
    affiliates: state.affiliatesReducer.affiliates,
    allBooks: state.authReducer.allBooks,
    bets: state.betslipReducer.bets,
  }));

  const {
    isLoadingFuturesOdds,
    futuresOddsError,
    futuresOdds,
    affiliates,
    allBooks,
    bets,
  } = reduxProps;

  const urlParams = useLocation().search;
  const params = useMemo(() => new URLSearchParams(urlParams), [urlParams]);

  const [showBetslip, setShowBetslip] = useState(false);

  const _initialLoad = () => {
    dispatch(
      getFuturesOdds({
        prop_types: [params.get('prop_type')],
        leagues: [params.get('league')],
        season: params.get('season_id'),
      })
    );
  };
  useEffect(() => {
    if (allBooks.length === 0) {
      dispatch(getAllBooks());
    }
    if (affiliates.length === 0) {
      dispatch(getAffiliates());
    }
    _initialLoad();
  }, [params]);

  if (futuresOddsError) {
    return (
      <Col className="container">
        <h5 style={{ color: 'var(--color-danger)' }}>
          Sorry, we ran into an error getting the{' '}
          {params.get('prop_type') ? params.get('prop_type') : 'future odds'}.
          Try again later
        </h5>
        <AuthButton
          containerStyle={{ maxWidth: '256px' }}
          onPress={() => _initialLoad()}
        >
          Retry
        </AuthButton>
      </Col>
    );
  }

  return (
    <Col
      desktop
      style={{
        height: '100vh',
        flexWrap: 'nowrap',
        overflow: 'hidden',
      }}
    >
      {!showBetslip && bets.length > 0 && (
        <Link to="/edit-betslip">
          <FAB style={{ width: '174px' }} onClick={() => null}>
            <Row style={{ alignItems: 'center', justifyContent: 'center' }}>
              <IoPencilSharp
                size={22}
                color="white"
                style={{ padding: '0 var(--space-xs)' }}
              />
              <span style={{ fontSize: 'var(--text-base-size)' }}>
                Edit Betslip ({bets.length})
              </span>
            </Row>
          </FAB>
        </Link>
      )}
      <Toolbar>
        {showBetslip ? (
          <div
            style={{
              position: 'absolute',
              right: 0,
            }}
          >
            <IconButton
              iconName={IoEnterOutline}
              iconSize={22}
              iconTitle="Collapse betslip"
              containerStyle={{
                height: '100%',
                padding: '0 var(--space-sm)',
                borderLeft: '1px solid var(--color-text)',
                display: 'flex',
                alignItems: 'center',
              }}
              iconColor="var(--color-text)"
              onPress={() => setShowBetslip(false)}
            />
            {bets.length > 0 && (
              <span
                style={{
                  height: '18px',
                  width: '18px',
                  position: 'absolute',
                  top: 0,
                  right: 2,
                  background: 'var(--color-danger)',
                  color: 'white',
                  borderRadius: '50%',
                  textAlign: 'center',
                  lineHeight: 1.2,
                  fontSize: '14px',
                }}
              >
                {bets.length}
              </span>
            )}
          </div>
        ) : (
          <div
            style={{
              position: 'absolute',
              right: 0,
            }}
          >
            <IconButton
              iconName={IoExitOutline}
              iconSize={22}
              iconTitle="Expand betslip"
              containerStyle={{
                height: '100%',
                padding: '0 var(--space-sm)',
                borderRight: '1px solid var(--color-text)',
                transform: 'rotate(180deg)',
              }}
              iconColor="var(--color-text)"
              onPress={() => setShowBetslip(true)}
            />
            {bets.length > 0 && (
              <span
                style={{
                  height: '18px',
                  width: '18px',
                  position: 'absolute',
                  top: 0,
                  right: 2,
                  background: 'var(--color-danger)',
                  color: 'white',
                  borderRadius: '50%',
                  textAlign: 'center',
                  lineHeight: 1.2,
                  fontSize: '14px',
                }}
              >
                {bets.length}
              </span>
            )}
          </div>
        )}
      </Toolbar>
      <Helmet title={params.get('prop_type')} />
      <MainAreaWrapper>
        <InnerMainArea>
          <div
            style={{
              width: '100%',
              height: '100%',
              overflowY: 'auto',
              padding: '0',
              boxSizing: 'border-box',
              paddingBottom: 'var(--space-xl)',
              margin: 'auto',
            }}
          >
            {isLoadingFuturesOdds && (
              <Col
                style={{
                  justifyContent: 'center',
                  height: '100%',
                }}
              >
                <div>
                  <ActivityIndicator size={2} />
                </div>
                <h6>Loading {params.get('prop_type')}</h6>
              </Col>
            )}
            {futuresOdds?.futures !== undefined &&
              Object.keys(futuresOdds.futures).length === 0 && (
                <h6
                  style={{
                    textAlign: 'center',
                    marginTop: 'var(--space-lg)',
                  }}
                >
                  Sorry, we couldn't get odds for this future. Check back later!
                </h6>
              )}
            {!isLoadingFuturesOdds &&
              futuresOdds?.season.name &&
              futuresOdds?.futures[params.get('prop_type')] && (
                <>
                  <Row
                    style={{
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      position: 'sticky',
                      left: 0,
                      padding: 'var(--space-sm) var(--space-xs)',
                    }}
                  >
                    {futuresOdds.season.logo && (
                      <img
                        style={{
                          flex: 0,
                          width: '64px',
                          height: 'auto',
                          marginRight: 'var(--space-xs)',
                        }}
                        src={futuresOdds.season.logo}
                        alt={futuresOdds.season.name}
                      />
                    )}
                    <h3 style={{ margin: 0, flex: 1 }}>
                      {futuresOdds.season.name} - {params.get('prop_type')}
                    </h3>
                  </Row>
                  {futuresOdds?.futures[params.get('prop_type')]?.rows
                    ?.length === 0 && (
                    <h6
                      style={{
                        textAlign: 'center',
                        marginTop: 'var(--space-lg)',
                      }}
                    >
                      Sorry, we couldn't get odds for this future. Check back
                      later!
                    </h6>
                  )}

                  <table
                    cellSpacing={0}
                    style={{
                      margin: '1',
                      borderTop: '2px solid var(--color-text-light)',
                      marginTop: 'var(--space-xxs)',
                      tableLayout: 'auto',
                      display: 'block',
                    }}
                  >
                    <tbody>
                      {futuresOdds.futures[params.get('prop_type')].rows.map(
                        (row, i) => (
                          <tr
                            key={`futures-table-row-${i}`}
                            style={
                              i === 0
                                ? {
                                    textAlign: 'center',
                                    position: 'sticky',
                                    top: 0,
                                    zIndex: 99,
                                  }
                                : { textAlign: 'center' }
                            }
                          >
                            {row.cells.map((cell, j) => (
                              <React.Fragment
                                key={`futures-table-text-cell-${i}-${j}`}
                              >
                                {(cell.text || !cell.odds) && (
                                  <CustomCell
                                    cell={cell}
                                    league={
                                      i > 0 ? futuresOdds?.season?.league : null
                                    }
                                  />
                                )}
                                {cell.odds && (
                                  <td
                                    style={{
                                      height: '50px',
                                      minWidth: '120px',
                                      maxWidth: '240px',
                                      borderRight:
                                        '3px solid var(--color-text-light)',
                                      borderBottom:
                                        '3px solid var(--color-text-light)',
                                      borderLeft: cell['Best Available']
                                        ? '3px solid var(--color-text-light)'
                                        : '0 solid',
                                      position: cell['Best Available']
                                        ? 'sticky'
                                        : 'relative',
                                      left: cell['Best Available']
                                        ? '150px'
                                        : undefined,
                                      zIndex: cell['Best Available'] ? 11 : 10,
                                      background: cell['Best Available']
                                        ? 'var(--color-bg)'
                                        : undefined,
                                    }}
                                  >
                                    <OddBtn2
                                      containerStyle={{
                                        borderRadius: 0,
                                        borderBottomWidth: 1,
                                        borderColor: 'rgba(127,127,127,0.5)',
                                        height: '100%',
                                      }}
                                      btnStyle={{
                                        borderRadius: 0,
                                        minHeight: 0,
                                        height: '100%',
                                      }}
                                      btnTheme="borderless"
                                      odd={{
                                        is_future: true,
                                        odds: cell.odds,
                                        side: cell.side,
                                        book: {
                                          name: cell.book,
                                          id: cell.book_id,
                                          load_betslip: cell.book_load_betslip,
                                          states: cell.book_states,
                                          aff_exclude_states:
                                            cell.book_aff_exclude_states,
                                        },
                                        game: {},
                                        bestAvailable: cell['Best Available'],
                                        oddID: `${params.get('prop_type')}-${
                                          cell.side
                                        }-${cell.book}-futures`,
                                        season_id: futuresOdds.season.id,
                                        prop_type: params.get('prop_type'),
                                        season: futuresOdds.season,
                                      }}
                                    />
                                  </td>
                                )}
                              </React.Fragment>
                            ))}
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                </>
              )}
          </div>
        </InnerMainArea>
        {showBetslip && <BetSlip />}
      </MainAreaWrapper>
    </Col>
  );
}

function CustomCell(props) {
  //const { colors } = useTheme();
  const reduxProps = useSelector(state => ({
    affiliates: state.affiliatesReducer.affiliates,
    allBooks: state.authReducer.allBooks,
  }));
  const { affiliates, allBooks } = reduxProps;

  const { cell, league } = props;

  const affBook = useMemo(() => {
    return affiliates.find(a => a.name === cell.text);
  }, [cell.text, affiliates]);

  const genBook = useMemo(() => {
    return allBooks.find(a => a.name === cell.text);
  }, [cell.text, allBooks]);

  const book = affBook ? affBook : genBook;

  return (
    <td
      style={{
        height: '25px',
        minWidth: '150px',
        maxWidth: '300px',
        position: cell?.text || cell?.text === 'Best Available' ? 'sticky' : '',
        zIndex: cell?.text
          ? cell.text === 'Side' || cell.text === 'Best Available'
            ? 100
            : 11
          : 10,
        left: cell?.text === 'Best Available' ? '150px' : 0,
        backgroundColor: 'var(--color-fg)',
        borderBottom: '1px solid var(--color-text-light)',
        borderRight: '1px solid var(--color-text-light)',
      }}
    >
      <Row style={{ alignItems: 'center', justifyContent: 'center' }}>
        {league && cell.type === 'heading' && cell.text !== 'Side' && (
          <TeamLeagueLogo
            league={league}
            team={cell.text.split(' - ')[0]}
            style={{ marginRight: 'var(--space-xs)' }}
          />
        )}
        {book && (
          <>
            <b style={{ fontSize: '12px' }}>{book.name}</b>
            <AffiliateLink
              shape="box"
              book={book}
              fromPage="Futures Table - Web"
              src={book.generic_banner ? book.generic_banner : null}
              hideBetlink
              hideText
              fallbackToGeneric
            />
          </>
        )}
        {(cell?.text === 'Best Available' || cell.text === 'Best Odds') && (
          <>
            <b style={{ fontSize: '12px', color: 'transparent' }}>Best Odds</b>
            <img
              alt="Best Available Odds"
              title="Best Available Odds"
              src={
                'https://betstamp-public.sfo2.cdn.digitaloceanspaces.com/ba_banner.png'
              }
              style={{
                height: 'auto',
                width: '100%',
                resizeMode: 'cover',
                borderRadius: '4px',
              }}
            />
          </>
        )}
        {!book &&
          !(cell?.text === 'Best Available' || cell.text === 'Best Odds') && (
            <b>
              {cell?.text?.includes('-') ? (
                <>
                  {cell.text.split('-')[0]}
                  <br />
                  {cell.text.split('-')[1]}
                </>
              ) : (
                cell.text
              )}
            </b>
          )}
      </Row>
    </td>
  );
}
