export default function humanTime(t) {
  if (t === '12:00:00') return 'Noon';
  if (t === '00:00:00') return 'Midnight';
  let hh = parseInt(t.split(':')[0]);
  let mm = t.split(':')[1];
  let end = 'am';
  if (hh >= 12) end = 'pm';
  hh = hh % 12;
  return `${hh}:${mm}${end}`;
}
