import { Observable } from 'rxjs';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import { ajax } from 'rxjs/observable/dom/ajax';
import { BACKEND_API_URL, getHeaders } from 'utils';

import {
  GET_GRAPH_DATA,
  getGraphDataSuccess,
  getGraphDataFailure,
} from 'actions';

export default function getGraphData(action$, state$) {
  return action$.ofType(GET_GRAPH_DATA).mergeMap(action => {
    let url =
      BACKEND_API_URL +
      `api/analysis_graph/${action.urlparams}y1=${action.y1}&interval=${action.interval}`;

    if (action.extraFilters) {
      url += `&${action.extraFilters}`;
    }

    return ajax
      .getJSON(url, getHeaders(state$))
      .map(data => getGraphDataSuccess(data, action.forGraph))
      .catch(error => Observable.of(getGraphDataFailure(error.xhr)));
  });
}
