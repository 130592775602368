import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { useMeasure } from 'react-use';
import moment from 'moment';
import display_prop_info from 'utils/display_prop_info';

// components
import ModalWrapper from 'components/generic/ModalWrapper';
import { Row, Col } from 'components/generic/Layout';
import BetStats from 'components/BetStats';
import BetLineLogos from 'components/BetLineLogos';
import ViewWager from 'components/modals/ViewWager';
import TeamLeagueLogo from './TeamLeagueLogo';
import { IconButton } from 'components/AuthButton';
import { IoCopyOutline } from 'react-icons/io5';
import AddUnsupported from 'components/modals/AddUnsupported';

// actions
import { resetTransactionEditDel, resetWagerEditDel } from 'actions';

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-flow: row;
  justify-content: stretch;
  background-color: var(--color-fg);
  border-radius: var(--std-border-radius);
  padding: var(--space-xs);
  margin-bottom: var(--space-sm);
  box-sizing: border-box;
  transition: all var(--std-transition);
  margin: 8px;
  cursor: ${props => {
    if (props.disableModal) return 'auto';
    return 'pointer';
  }};
  border: ${props => {
    if (
      props.status === 'WON' ||
      props.status === 'HALF_WON' ||
      props.status === 'DEAD_HEAT' ||
      props.status == 'FREE_BET_WON'
    ) {
      return '1px solid var(--color-dollar-green)';
    }
    if (
      props.status === 'LOST' ||
      props.status === 'HALF_LOST' ||
      props.status === 'FREE_BET_LOST'
    ) {
      return '1px solid var(--color-dollar-red)';
    }
    if (props.status === 'PUSHED' || props.status === 'VOID') {
      return '1px solid var(--color-text)';
    }
    return 'none';
  }};
  &:hover {
    transition: all var(--std-transition);
    box-shadow: ${props => {
    if (props.disableModal) return 'none';
    if (props.status === 'WON' || props.status === 'HALF_WON') {
      return '0 2px 8px 0 var(--color-dollar-green)';
    }
    if (props.status === 'LOST' || props.status === 'HALF_LOST') {
      return '0 2px 8px 0 var(--color-dollar-red)';
    }
    if (props.status === 'PUSHED' || props.status === 'VOID') {
      return '0 2px 8px 0 var(--color-text)';
    }
    return '0 2px 8px 0 var(--color-shadow)';
  }};
  }
  &:active {
    box-shadow: ${props => {
    if (props.disableModal) return 'none';
    return 'inset 0 0 0 100vh var(--color-active)';
  }};
  }

  @media only screen and (max-width: 625px) {
    padding: var(--space-xxs);
  }
`;

export default function Wager(props) {
  const dispatch = useDispatch();
  const reduxProps = useSelector(state => ({
    user: state.authReducer.user,
  }));
  const { user } = reduxProps;

  const { wager, hideBetStats, disableModal, todaysAction, viewingOtherUser } =
    props;
  const {
    game,
    status,
    latest_odds,
    type_name,
    book,
    prop_type,
    period,
    first_date,
    last_date,
    created_at,
    is_live,
    side,
    bets, // child bets
  } = wager;

  const [ref, { width }] = useMeasure();

  const [tailBetModal, setTailBetModal] = useState(false);
  const [viewBetModalVisible, setViewBetModalVisible] = useState(false);
  const _dismissModal = () => {
    // dispatch(resetEditDel());
    setViewBetModalVisible(false);
  };

  let bookName = book?.name;

  // calculate home / away scores depending on the period bet
  let h_score, a_score;
  if (period === 'FT') {
    h_score = game?.home_score;
    a_score = game?.away_score;
  } else if (
    period === '1H' ||
    period === '1P' ||
    period === 'F5' ||
    period === '1S'
  ) {
    h_score = game?.home_half_score;
    a_score = game?.away_half_score;
  } else if (period === '2H') {
    h_score = game?.home_score - game?.home_half_score;
    a_score = game?.away_score - game?.away_half_score;
  }

  return (
    <>
      {!disableModal && viewBetModalVisible && (
        <ModalWrapper
          modalIsOpen={viewBetModalVisible}
          onClose={() => _dismissModal()}
          onRequestClose={() => _dismissModal()}
        >
          <ViewWager wager={wager} dismissModal={() => _dismissModal()} />
        </ModalWrapper>
      )}
      <Wrapper
        ref={ref}
        style={{ ...props.style }}
        status={status}
        disableModal={disableModal}
        onClick={() => setViewBetModalVisible(true)}
      >
        <Col style={{ justifyContent: 'center', alignItems: 'flex-start' }}>
          {game?.is_live && !todaysAction && (
            <small
              style={{
                color:
                  game?.is_live_text && game?.is_live_text.indexOf('Delay') >= 0
                    ? 'var(--color-text)'
                    : 'var(--color-success)',
                fontWeight: 'bold',
              }}
            >
              {game?.is_live_text ? game.is_live_text : ''}
            </small>
          )}
          <BetLineLogos
            bet={wager}
            odds_preference={user.odds_preference}
            showVerifiedStamp
            iconSize={28}
            showPublicBadge={!disableModal}
            showSyncBadge
          />
          <Row>
            <Col style={{ alignItems: 'flex-start' }}>
              {game?.is_complete || game?.is_live ? (
                // score if not mma
                <>
                  {type_name !== 'Player Prop' && type_name !== 'Game Prop' && (
                    <>
                      {game.sport === 'MMA' ? (
                        <small>
                          {game.away_team.name} @ {game.home_team.name}{' '}
                          {moment(first_date).format('ddd MMM D')}
                        </small>
                      ) : (
                        <>
                          {period === 'FT' ? (
                            <>
                              {!todaysAction && (
                                <small>
                                  {game.away_team.name} {game.away_score} -{' '}
                                  {game.home_score} {game.home_team.name}
                                  {game.is_complete ? ' | Final ' : ''}
                                  {game.is_complete && game.finish_type
                                    ? '(' + game.finish_type + ')'
                                    : ''}
                                  {' - '}
                                  {moment(first_date).format('ddd MMM D')}
                                </small>
                              )}
                            </>
                          ) : (
                            <small>
                              {period} | {game.away_team.name} {a_score} -{' '}
                              {h_score} {game.home_team.name}{' '}
                              {moment(first_date).format('ddd MMM D')}
                            </small>
                          )}
                          {game?.sport === 'Golf' && (
                            <>
                              <small>
                                {game.length_of_match} vs.{' '}
                                {side === game.away_team.name
                                  ? game.home_team.name
                                  : game.away_team.name}
                              </small>
                              {!todaysAction && (
                                <small>{game?.season?.name}</small>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                  <small>
                    Placed {moment(created_at).format('ddd MMM D @ h:mma')} at{' '}
                    {bookName}
                    {wager.is_free_bet && <small> - Free Bet</small>}
                  </small>

                  {latest_odds &&
                    (game?.is_complete || game?.is_live) &&
                    (type_name === 'Player Prop' ||
                      type_name === 'Game Prop') && (
                      <Row
                        style={{
                          alignItems: 'center',
                          justifyContent: 'flex-start',
                        }}
                      >
                        <small>{prop_type}</small>:
                        <small
                          style={{
                            fontWeight: 'bold',
                            paddingLeft: 'var(--space-xxxs)',
                            color:
                              status === 'WON' || status === 'HALF_WON'
                                ? 'var(--color-dollar-green)'
                                : status === 'LOST' || status === 'HALF_LOST'
                                  ? 'var(--color-dollar-red)'
                                  : 'var(--color-text)',
                          }}
                        >
                          {display_prop_info(wager, game.is_live)}
                        </small>
                      </Row>
                    )}
                </>
              ) : (
                <>
                  {!todaysAction && (
                    <>
                      {game && !wager.is_future && game.sport !== 'Golf' && (
                        <Row style={{ alignItems: 'center' }}>
                          <TeamLeagueLogo
                            league={game.league}
                            iconSize={20}
                            style={{ marginRight: 'var(--space-xxxs)' }}
                          />
                          <small>
                            {game.away_team.name} @ {game.home_team.name}
                          </small>
                        </Row>
                      )}
                      <small>
                        {moment(first_date).format('ddd MMM D @ h:mma')}
                        {first_date !== last_date &&
                          ` - ${moment(last_date).format('ddd MMM D @ h:mma')}`}
                      </small>
                    </>
                  )}
                  {game && game.sport === 'Golf' && (
                    <>
                      <small>
                        {game.length_of_match} vs.{' '}
                        {side === game.away_team.name
                          ? game.home_team.name
                          : game.away_team.name}
                      </small>
                      {!todaysAction && <small>{game?.season?.name}</small>}
                    </>
                  )}
                  {wager.is_future && (
                    <>
                      {!todaysAction && wager?.season && (
                        <small>{wager.season.name}</small>
                      )}
                    </>
                  )}
                  <small>
                    Placed {moment(created_at).format('ddd MMM D @ h:mma')} at{' '}
                    {bookName}
                    {wager.is_free_bet && <small> - Free Bet</small>}
                  </small>
                </>
              )}
            </Col>
          </Row>
          {bets &&
            bets.map((child, i) => (
              <BetLineLogos
                key={`child-wager-${wager.id}-${i}`}
                bet={child}
                odds_preference={user.odds_preference}
                // showVerifiedStamp
                iconSize={28}
                showPublicBadge={false}
                showSyncBadge={false}
                showGraded
                showGameIsLive
              />
            ))}
        </Col>
        {!wager.is_verified && viewingOtherUser && wager.bet_type === 'CUSTOM' && (
          <>
            <ModalWrapper
              modalIsOpen={tailBetModal}
              onClose={() => setTailBetModal(false)}
              onRequestClose={() => setTailBetModal(false)}
              title="Tail this Wager"
            >
              <AddUnsupported
                mode={'custom bet'}
                preloadBet={wager}
                hideModal={() => {
                  dispatch(resetTransactionEditDel());
                  dispatch(resetWagerEditDel());
                  setTailBetModal(false);
                }}
              />
            </ModalWrapper>
            <Row
              style={{
                margin: '0 var(--space-sm)',
                alignItems: 'center',
                justifyContent: 'flex-end',
              }}
            >
              <IconButton
                onPress={() => {
                  // set modal true
                  setTailBetModal(true);
                }}
                iconName={IoCopyOutline}
                subtext="Tail"
              />
            </Row>
          </>
        )}
        {!hideBetStats && (
          <BetStats
            bet={wager}
            hideLatestOdds={width < 640}
            totalWidth={width}
          />
        )}
      </Wrapper>
    </>
  );
}
