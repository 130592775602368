import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { useEffectOnce } from 'react-use';
import { IoCheckmarkCircle, IoRadioButtonOffSharp } from 'react-icons/io5';

// components
import { Row } from 'components/generic/Layout';
import ModalWrapper from 'components/generic/ModalWrapper';
import { AuthButton } from 'components/AuthButton';

// constants
import { AUTH_BUTTON_DEFAULT_CONTAINER_STYLE } from 'utils';

// actions
import { getAllBooks } from 'actions';
import { AuthTextInput } from 'components/AuthTextInput';
import ActivityIndicator from 'components/generic/ActivityIndicator';

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-flow: column wrap;
  justify-content: space-around;
  align-items: stretch;
  height: 100%;
  overflow: hidden;
  max-width: 796px;
  margin: 0 auto;
`;

const InnerWrapper = styled.div`
  flex: 4;
  box-sizing: border-box;
  overflow: auto;
`;

const BookItem = styled.button`
  width: 100%;
  padding: var(--space-sm);
  outline: none;
  border: none;
  background: transparent;
  font-size: var(--text-base-size);
  cursor: pointer;
  flex: 1;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
  color: var(--color-text);
  &:hover {
    background-color: var(--color-text-light);
  }
`;

export default function Books(props) {
  const dispatch = useDispatch();
  const reduxProps = useSelector(state => ({
    user: state.authReducer.user,
    allBooks: state.authReducer.allBooks,
    isLoadingAllBooks: state.authReducer.isLoadingAllBooks,
    allBooksError: state.authReducer.allBooksError,
  }));

  const { allBooks, isLoadingAllBooks, allBooksError } = reduxProps;

  const { showModal, initialBooks } = props;

  const [selectedBooks, setSelectedBooks] = useState(initialBooks);
  const [customBookName, setCustomBookName] = useState('');
  const [books, setBooks] = useState(allBooks);

  useEffectOnce(() => {
    if (allBooks.length === 0) {
      dispatch(getAllBooks());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  });

  useEffect(() => {
    setBooks(
      allBooks.map(b => {
        if (initialBooks.find(ib => ib.id === b.id)) {
          return { ...b, selected: true };
        }
        return b;
      })
    );
    setSelectedBooks(initialBooks);
  }, [allBooks, initialBooks]);

  const _onClickBook = book => {
    setBooks(
      books.map(b => {
        if (b.id === book.id) {
          return { ...b, selected: !b.selected };
        }
        return b;
      })
    );
    setSelectedBooks([...selectedBooks, book]);
  };

  const _addCustomBook = () => {
    if (customBookName.trim() === '') return;
    let newCustomBook = {
      name: customBookName.trim(),
      id: customBookName + Math.random().toString(),
      selected: true,
    };
    setBooks([newCustomBook, ...books]);
    setSelectedBooks([...selectedBooks, newCustomBook]);
    setCustomBookName('');
  };

  if (isLoadingAllBooks) {
    return <ActivityIndicator size={2} />;
  }

  if (allBooksError) {
    return (
      <h6 style={{ textAlign: 'center' }}>
        Sorry, we ran into an error getting sportsbooks
      </h6>
    );
  }

  return (
    <ModalWrapper
      modalIsOpen={showModal}
      onClose={() => props.dismissModal()}
      onRequestClose={() => props.dismissModal()}
      // title="Add Sportsbooks"
    >
      <Wrapper>
        <InnerWrapper>
          {books.length === 0 && (
            <h6 style={{ textAlign: 'center' }}>
              No sportsbooks! (There may be no available sportsbooks in your
              region)
            </h6>
          )}
          {books.map(book => (
            <BookItem
              key={`books-modal-book-${book.id}`}
              onClick={() => _onClickBook(book)}
            >
              {book.selected ? (
                <IoCheckmarkCircle size={22} color={'var(--color-success)'} />
              ) : (
                <IoRadioButtonOffSharp size={22} />
              )}
              <span>{book.name}</span>
            </BookItem>
          ))}
        </InnerWrapper>
        <form
          onSubmit={ev => {
            ev.preventDefault();
            _addCustomBook();
          }}
        >
          <Row style={{ flex: 0 }}>
            <AuthTextInput
              placeholder={'Add a custom book'}
              value={customBookName}
              containerStyle={{
                flex: 1,
                margin: 0,
              }}
              inputRowStyle={{ backgroundColor: 'var(--color-bg)' }}
              onChangeText={newBookName => setCustomBookName(newBookName)}
            />
            <AuthButton
              btnTheme="borderless"
              containerStyle={{
                flex: 0,
              }}
              onPress={() => {}}
              title={'You can also hit enter to add the book'}
            >
              Add
            </AuthButton>
          </Row>
        </form>
        <Row style={{ flex: 0, padding: 'var(--space-lg) 0' }}>
          <AuthButton
            colorTheme="danger"
            containerStyle={AUTH_BUTTON_DEFAULT_CONTAINER_STYLE}
            onPress={() => {
              setBooks(allBooks);
              props.dismissModal();
            }}
          >
            Cancel
          </AuthButton>
          <AuthButton
            containerStyle={AUTH_BUTTON_DEFAULT_CONTAINER_STYLE}
            onPress={() => props.returnSelectedBooks(selectedBooks)}
          >
            Done
          </AuthButton>
        </Row>
      </Wrapper>
    </ModalWrapper>
  );
}
