import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// components
import { AuthButton } from 'components/AuthButton';
import { AuthTextInput } from 'components/AuthTextInput';
import ErrorDisplay from 'components/generic/ErrorDisplay';

// actions
import { changePassword, resetErrors, logoutAll } from 'actions';
import {
  IoEyeOffOutline,
  IoEyeOutline,
  IoLockClosedOutline,
} from 'react-icons/io5';

export default function ChangePassword(props) {
  const dispatch = useDispatch();
  const reduxProps = useSelector((state) => ({
    user: state.authReducer.user,
    changingPassword: state.authReducer.changingPassword,
    changePasswordError: state.authReducer.changePasswordError,
    passwordChangeSuccess: state.authReducer.passwordChangeSuccess,
  }));
  const {
    changingPassword,
    changePasswordError,
    passwordChangeSuccess,
  } = reduxProps;

  const [valids, setValids] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [passwords, setPasswords] = useState({});

  const _validatePassword = (pass, index) => {
    let valid = pass.trim() !== '';
    if (index === 'newPassword2') {
      valid = valid && pass === passwords.newPassword1;
    }
    setValids({ ...valids, [index]: valid });
    setPasswords({ ...passwords, [index]: pass });
  };

  const submitDisabled =
    !valids.oldPassword || !valids.newPassword1 || !valids.newPassword2;

  useEffect(() => {
    if (passwordChangeSuccess) {
      dispatch(resetErrors());
      dispatch(logoutAll());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [passwordChangeSuccess]);

  return (
    <div>
      {changePasswordError && (
        <ErrorDisplay error={changePasswordError} basic={true} />
      )}
      <AuthTextInput
        label="Old Password *"
        leftIcon={IoLockClosedOutline}
        rightIcon={showPassword ? IoEyeOutline : IoEyeOffOutline}
        rightIconOnPress={() => setShowPassword(!showPassword)}
        rightIconColor={'var(--color-text)'}
        placeholder={'Password'}
        type={showPassword ? 'text' : 'password'}
        onChangeText={(text) => _validatePassword(text, 'oldPassword')}
      />
      <AuthTextInput
        label="New Password (min. 8 characters) *"
        leftIcon={IoLockClosedOutline}
        rightIcon={showPassword ? IoEyeOutline : IoEyeOffOutline}
        rightIconOnPress={() => setShowPassword(!showPassword)}
        rightIconColor={'var(--color-text)'}
        placeholder={'Password'}
        type={showPassword ? 'text' : 'password'}
        onChangeText={(text) => _validatePassword(text, 'newPassword1')}
      />
      <AuthTextInput
        label="Confirm New Password *"
        leftIcon={IoLockClosedOutline}
        rightIcon={showPassword ? IoEyeOutline : IoEyeOffOutline}
        rightIconOnPress={() => setShowPassword(!showPassword)}
        rightIconColor={'var(--color-text)'}
        errorText={
          valids.newPassword2 === false ? 'Passwords must match' : null
        }
        placeholder={'Password'}
        type={showPassword ? 'text' : 'password'}
        onChangeText={(text) => _validatePassword(text, 'newPassword2')}
      />

      <AuthButton
        disabled={submitDisabled}
        isLoading={changingPassword}
        onPress={() =>
          dispatch(
            changePassword({
              new_password1: passwords.newPassword1,
              new_password2: passwords.newPassword2,
              old_password: passwords.oldPassword,
            })
          )
        }
      >
        Change Password
      </AuthButton>
      <small style={{ textAlign: 'center' }}>
        This will log you out of all currently logged in devices. You will be
        required to re-login.
      </small>
    </div>
  );
}
