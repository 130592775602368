import React from 'react';
import styled from 'styled-components';

const ExpLabel = styled.button`
  flex: 1;
  padding: var(--space-lg) var(--space-sm);
  font-size: var(--text-base-size);
  font-family: 'Noto Sans', sans-serif;
  text-decoration: none;
  outline: none;
  transition: all var(--std-transition);
  cursor: pointer;
  border-radius: var(--std-border-radius);
  text-align: center;
  margin: 0 var(--space-xs);
  box-sizing: border-box;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;

  border: ${(props) => {
    if (props.selected) {
      return '2px solid var(--color-primary)';
    }
    return '2px solid var(--color-text)';
  }};
  color: ${(props) => {
    if (props.selected) {
      return 'var(--color-primary)';
    }
    return 'var(--color-text)';
  }};
  opacity: ${(props) => {
    if (props.selected) {
      return '1';
    }
    return '0.75';
  }};
  background-color: ${(props) => {
    if (props.selected) {
      return 'var(--color-fg)';
    }
    return 'var(--color-bg)';
  }};
  @media only screen and (max-width: 625px) {
    font-size: 80% !important;
    padding: var(--space-md) var(--space-xxs);
    margin: 0 var(--space-xxxs);
    & > p {
      font-size: 80% !important;
    }
  }
`;

export default function ExperienceBox(props) {
  const { text, level, experienceLevel } = props;
  return (
    <ExpLabel
      style={{
        borderColor:
          experienceLevel === level
            ? 'var(--color-primary)'
            : 'var(--color-text)',
        opacity: experienceLevel === level ? 1 : 0.75,
        backgroundColor:
          experienceLevel === level ? 'var(--color-primary)' : 'transparent',
      }}
      onClick={() => {
        props.onPress();
      }}
    >
      <p
        style={{
          fontWeight: 'bold',
          margin: 0,
          color: experienceLevel === level ? 'white' : 'var(--color-text)',
        }}
      >
        {level}
      </p>
      <small
        style={{
          fontStyle: 'italic',
          textAlign: 'center',
          color: experienceLevel === level ? 'white' : 'var(--color-text)',
        }}
      >
        {text}
      </small>
    </ExpLabel>
  );
}
