export default function display_prop_info(bet, is_live) {
  if (
    is_live ||
    (bet.type_name === 'Player Prop' && bet.latest_odds?.result !== null)
  ) {
    if (bet.prop_type.includes('Money Line')) {
      return ' LIVE';
    }
    return ' ' + (bet.latest_odds?.result ? bet.latest_odds.result : 0);
  } else if (bet?.latest_odds?.is_graded && 'winner' in bet?.latest_odds) {
    if (bet.latest_odds.winner !== null) {
      if (
        'result' in bet.latest_odds &&
        (bet.latest_odds.winner === 'Over' ||
          bet.latest_odds.winner === 'Under')
      ) {
        return (
          ' ' +
          bet.latest_odds.result +
          ', ' +
          bet.latest_odds.winner?.replace(/,/g, ', ')
        );
      } else {
        return ' ' + bet.latest_odds.winner?.replace(/,/g, ', ');
      }
    }
  }
  return '';
}
