import React, { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// components
import {
  BlueBubble,
  FollowUser,
  OnboardingWrapper,
} from 'components/auth/onboarding';
import { Col, HorizontalScroller } from 'components/generic/Layout';

//utils
import { FEATURE_CONTENT_HEIGHT, DEFAULT_FEATURE_LOADER_CONTENT } from 'utils';

// actions
import { getFeatured, getRecommendedUsers } from 'actions';

export default function FindUsers(props) {
  const dispatch = useDispatch();
  const reduxProps = useSelector(state => ({
    featured: state.featuredReducer.featured,
    featuredIsLoading: state.featuredReducer.isLoading,
    recommended: state.featuredReducer.recommended,
    isLoadingRecommended: state.featuredReducer.isLoadingRecommended,
  }));

  const { featured, featuredIsLoading, isLoadingRecommended, recommended } =
    reduxProps;
  const featuredUsers = useMemo(
    () =>
      featured
        .filter(f => f.is_featured || f.media_account)
        .sort((a, b) => {
          if (a.media_account && b.is_featured) return -1;
          if (a.is_featured && b.media_account) return 1;
          return 0;
        }),
    [featured]
  );

  useEffect(() => {
    if (featuredUsers.length === 0) {
      dispatch(getFeatured());
    }
    if (recommended.length === 0) {
      dispatch(getRecommendedUsers());
    }
  }, []);

  const _recommendationRenderer = (
    recommendations,
    isLoading,
    emptyRecommendationText
  ) => {
    let itemsToRender = <span>{emptyRecommendationText}</span>;
    if (isLoading) {
      itemsToRender = DEFAULT_FEATURE_LOADER_CONTENT.map(i => (
        <FollowUser isLoading key={`feature-user-loader-${i}`} />
      ));
    } else if (recommendations?.length) {
      itemsToRender = recommendations.map(recommendation => (
        <FollowUser
          user={recommendation}
          key={`feature-user-content-${recommendation.username}-${recommendation.id}`}
        />
      ));
    }

    return (
      <HorizontalScroller
        style={{
          width: '100%',
          minHeight: `calc(${FEATURE_CONTENT_HEIGHT}px + 2 * var(--space-xs))`,
        }}
      >
        {itemsToRender}
      </HorizontalScroller>
    );
  };

  return (
    <OnboardingWrapper nextScreen="/games" onNext={() => {}} nextText="Finish">
      <Col style={{ flex: 0, width: '100%', alignItems: 'flex-start' }}>
        <BlueBubble
          style={{
            marginTop: 'var(--space-md)',
            marginBottom: 'var(--space-md)',
            flex: 0,
          }}
        >
          <h5
            style={{
              marginTop: 'var(--space-md)',
              marginBottom: 'var(--space-md)',
              color: 'white',
            }}
          >
            Follow other bettors so you can get notified whenever they track a
            bet
          </h5>
        </BlueBubble>
        <h5
          style={{
            marginTop: 'var(--space-md)',
            marginBottom: 'var(--space-md)',
            fontWeight: 'bold',
            color: 'var(--color-primary)',
          }}
        >
          Follow Friends
        </h5>
        {_recommendationRenderer(
          recommended,
          isLoadingRecommended,
          "Sorry, we couldn't find any users"
        )}
        <h5
          style={{
            marginTop: 'var(--space-md)',
            marginBottom: 'var(--space-md)',
            fontWeight: 'bold',
            color: 'var(--color-primary)',
          }}
        >
          Follow Bettors
        </h5>
        {_recommendationRenderer(
          featuredUsers,
          featuredIsLoading,
          "Sorry, we couldn't find any bettors"
        )}
      </Col>
    </OnboardingWrapper>
  );
}
