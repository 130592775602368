import React, { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import moment from 'moment';

// components
import ActivityIndicator from 'components/generic/ActivityIndicator';
import StaffOnboardingBase from 'components/StaffOnboardingBase';
import GenericAdminFilter from 'components/onboarding/GenericAdminFilter';
import DateRange from 'components/generic/DateRange';
import { SummaryEntry } from 'pages/AffiliateEarningDashboard/AffiliateEarningDashboard';
import { LinkButton } from 'components/AuthButton';
import { Row } from 'components/generic/Layout';

import { formatMoney, PROVINCE_OPTIONS, US_STATES } from 'utils';

const SUPPORTED_GROUPINGS = [
  'book',
  'region',
  'referral_code',
  'marketing_group',
];
const GROUP_MAP = {
  book: 'book__name',
  region: 'state',
  referral_code: 'referral_code__code',
  marketing_group: 'group__name',
  //
  book__name: 'book',
  referral_code__code: 'referral_code',
  group__name: 'marketing_group',
  state: 'region',
};

const specialHeaderFormat = (t, obj, groupings, group) => {
  let text = obj[group];
  if (obj.depth !== groupings.indexOf(GROUP_MAP[group]) + 1) {
    return ' ';
  }
  if (groupings[obj.depth - 1] !== GROUP_MAP[group]) return ' ';
  if (obj.depth === 1) text = '⦿ ' + text;
  else text = '⤷ ' + text;
  return text;
};

const specialFormatMoney = t => {
  if (t === undefined || t === null) return '-';
  return formatMoney(t, 'CAD', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
};

export default function AffiliateEarningMetrics(props) {
  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);

  const [groupings, setGroupings] = useState(
    searchParams.get('groupings')
      ? JSON.parse(searchParams.get('groupings'))
      : ['book']
  );
  const url = useLocation().search;
  const summaryParams = useMemo(() => {
    const searchParams = new URLSearchParams(url);
    let newParams = {
      date_after: moment().startOf('month').toISOString(),
      date_before: moment().toISOString(),
    };
    for (const [key, value] of searchParams.entries()) {
      if (key === 'date_after' || key === 'date_before' || key === 'search') {
        newParams[key] = value;
      } else if (key !== 'sort') {
        newParams[key] = JSON.parse(value);
      }
    }
    return newParams;
  }, [url]);

  const getSummary = useQuery({
    queryKey: [
      {
        endpoint: 'aff-earnings-dashboard',
        urlParams: { ...summaryParams, summary: true },
      },
    ],
    retry: false,
    refetchOnWindowFocus: false,
  });

  return (
    <>
      <StaffOnboardingBase
        endpoint="aff-earnings-metrics"
        queryOptions={{
          retryOnWindowFocus: false,
          retry: false,
        }}
        modelName="Metric"
        headings={groupings
          .map(group => `${group.replaceAll('_', '').toUpperCase()}`)
          .concat([
            'Clicks',
            'Signups',
            // 'FTD',
            'CPA Count',
            'CPA',
            'Rev Share',
            'Deposits',
            'NGR',
          ])}
        objKeys={groupings
          .map(group => GROUP_MAP[group.toLowerCase()])
          .concat([
            'clicks',
            'registrations',
            // 'ftd',
            'cpa_commission_count',
            'converted_cpa',
            'converted_rev_share',
            'converted_deposits',
            'converted_ngr',
          ])}
        defaultUrlParams={{
          groupings: JSON.stringify(groupings),
          date_after: moment().startOf('month').toISOString(),
          date_before: moment().toISOString(),
        }}
        extraHeaderComponent={params => (
          <LinkButton to="/staff/affiliate-metrics/time-travel">
            View Historical Data
          </LinkButton>
        )}
        extraButtons={SUPPORTED_GROUPINGS.map(group => ({
          text: group.toUpperCase().replaceAll('_', ' '),
          //urlParams: { groupings: JSON.stringify('group') },
          deriveUrlParams: oldUrlParams => {
            let newGroupings = Array.from(groupings);
            if (groupings.includes(group)) {
              newGroupings.splice(groupings.indexOf(group), 1);
            } else {
              newGroupings.push(group);
            }
            setGroupings(newGroupings);
            return {
              urlParams: {
                ...oldUrlParams,
                groupings: JSON.stringify(newGroupings),
              },
              selectedQuickFilters: newGroupings.map(g =>
                g.toUpperCase().replaceAll('_', ' ')
              ),
            };
          },
        }))}
        formatText={{
          [GROUP_MAP['book']]: (t, obj) =>
            specialHeaderFormat(t, obj, groupings, GROUP_MAP['book']),
          [GROUP_MAP['region']]: (t, obj) =>
            specialHeaderFormat(t, obj, groupings, GROUP_MAP['region']),
          [GROUP_MAP['referral_code']]: (t, obj) =>
            specialHeaderFormat(t, obj, groupings, GROUP_MAP['referral_code']),
          [GROUP_MAP['marketing_group']]: (t, obj) =>
            specialHeaderFormat(
              t,
              obj,
              groupings,
              GROUP_MAP['marketing_group']
            ),
          converted_deposits: t =>
            specialFormatMoney(t, 'CAD', {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            }),
          converted_ngr: t =>
            specialFormatMoney(t, 'CAD', {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            }),
          converted_cpa: t =>
            specialFormatMoney(t, 'CAD', {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            }),
          converted_rev_share: t =>
            specialFormatMoney(t, 'CAD', {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            }),
        }}
        defaultSelectedQuickFilter={'BOOK'}
        sortKeys={{
          registrations: 'Signups',
          // ftd: 'FTD',
          converted_deposits: 'Deposits',
          converted_ngr: 'NGR',
          cpa_commission_count: 'CPA Count',
          converted_cpa: 'CPA',
          converted_rev_share: 'Rev Share',
          clicks: 'Clicks',
          Signups: 'registrations',
          // FTD: 'ftd',
          Clicks: 'clicks',
          Deposits: 'converted_deposits',
          NGR: 'converted_ngr',
          'CPA Count': 'cpa_commission_count',
          CPA: 'converted_cpa',
          'Rev Share': 'converted_rev_share',
        }}
        hideCreate
        showSearch={true}
        searchPlaceholder={`Search by ${groupings[0]}`}
        PanelChildren={() => <></>}
        panelTabs={['Details']}
        hidePanelTabs={true}
        panelSkipNullValues={true}
        panelSkipKeys={['depth', 'parent_key', 'children_key', 'id']}
        panelTitleOverride={'Metric details'}
        FilterChildren={FilterChildren}
        extraHeaderRow={page => {
          if (getSummary.isLoading) {
            return <ActivityIndicator size={2} />;
          } else if (getSummary.isSuccess && getSummary.data) {
            let summary = getSummary.data;
            return (
              <Row style={{ position: 'sticky', right: 0 }}>
                {summary.map((item, i) => (
                  <SummaryEntry
                    key={`summary-metrics-${i}`}
                    entry={item}
                    containerStyle={{
                      flexBasis: '15%',
                      flexWrap: 'column',
                      padding: 'var(--space-xs)',
                      minHeight: '108px',
                    }}
                  />
                ))}
              </Row>
            );
          } else {
            return null;
          }
        }}
        extraHeaderRowStyle={{
          position: 'relative',
          borderBottom: '1px solid var(--color-fg)',
          borderTop: '1px solid var(--color-fg)',
          zIndex: 1,
          flexWrap: 'none',
          overflowX: 'none',
          minHeight: 'none',
        }}
      />
    </>
  );
}

function FilterChildren(props) {
  const reduxProps = useSelector(state => ({
    allBooksMap: state.authReducer.allBooksMap,
  }));
  const { allBooksMap } = reduxProps;
  const { urlParams } = props;

  const fetchMarketingGroups = useQuery({
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 2,
    queryKey: [
      {
        endpoint: `marketing-groups`,
      },
    ],
  });

  const marketingGroupOptions = useMemo(() => {
    let opts = [];
    if (
      fetchMarketingGroups?.isSuccess &&
      fetchMarketingGroups?.data?.length > 0
    ) {
      for (const group of fetchMarketingGroups.data) {
        opts.push({
          label: group.name,
          value: group.id,
        });
      }
    }
    return opts;
  }, [fetchMarketingGroups?.isSuccess, fetchMarketingGroups?.data]);

  const defaultFilters = useMemo(() => {
    let filters = {};
    if (urlParams.states) {
      let d = {};
      for (const r of JSON.parse(urlParams.states)) {
        d[r] = true;
      }
      filters.states = d;
    }
    if (urlParams.book_ids) {
      let d = {};
      for (const r of JSON.parse(urlParams.book_ids)) {
        d[r] = true;
      }
      filters.book_ids = d;
    }

    if (urlParams.marketing_group_ids) {
      let d = {};
      for (const r of JSON.parse(urlParams.marketing_group_ids)) {
        d[r] = true;
      }
      filters.marketing_group_ids = d;
    }
    if (urlParams.date_after) {
      filters.date_after = urlParams.date_after;
    }
    if (urlParams.date_before) {
      filters.date_before = urlParams.date_before;
    }
    return filters;
  }, [urlParams]);

  return (
    <div style={{ width: '100%' }}>
      <DateRange
        filterTitle="Time Range"
        filterKey="time_range"
        getItemsInRange={(date1, date2) => {
          if (date1 === 'ALL_TIME' || date2 === 'ALL_TIME') {
            props.onFilter({
              date_after: date1,
              date_before: date2,
            });
            return;
          }
          let from_date = '';
          let to_date = '';
          if (date1 !== undefined) {
            date1 = moment(new Date(date1));
            from_date = date1.set('hour', 0).set('minute', 0).toISOString();
          }
          if (date2 !== undefined) {
            date2 = moment(new Date(date2));
            to_date = date2.set('hour', 23).set('minute', 59).toISOString();
          }
          props.onFilter({
            date_after: from_date,
            date_before: to_date,
          });
        }}
        defaultStartDate={
          defaultFilters?.date_after
            ? defaultFilters?.date_after === 'ALL_TIME'
              ? null
              : defaultFilters?.date_after
            : moment().startOf('month').toISOString()
        }
        defaultEndDate={
          defaultFilters?.date_before
            ? defaultFilters?.date_before === 'ALL_TIME'
              ? null
              : defaultFilters?.date_before
            : moment().toISOString()
        }
        allTimeReturnValue={'ALL_TIME'}
        placeholder={
          defaultFilters?.date_after === 'ALL_TIME' &&
          defaultFilters?.date_before === 'ALL_TIME'
            ? 'All Time'
            : 'Month to Date'
        }
      />
      <GenericAdminFilter
        filterTitle="Sportsbook"
        filterKey="book_ids"
        options={{
          groups: [
            {
              title: 'Books',
              options: Object.entries(allBooksMap)
                .map(([k, v], i) => ({
                  label: (
                    <Row
                      style={{
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        gap: 'var(--space-xs)',
                      }}
                    >
                      <img
                        width={22}
                        height={22}
                        style={{ borderRadius: 4 }}
                        src={allBooksMap[k]?.logo}
                        alt={v.name}
                      />
                      <span>{v.name}</span>
                    </Row>
                  ),
                  rawLabel: v.name,
                  value: k,
                }))
                .sort((a, b) => {
                  if (a.rawLabel.toLowerCase() > b.rawLabel.toLowerCase())
                    return 1;
                  if (a.rawLabel.toLowerCase() < b.rawLabel.toLowerCase())
                    return -1;
                  return 0;
                }),
            },
          ],
        }}
        onFilter={selections => {
          props.onFilter({
            book_ids: JSON.stringify(selections),
          });
        }}
        defaultDict={defaultFilters.book_ids}
      />
      {marketingGroupOptions?.length > 0 && (
        <GenericAdminFilter
          filterTitle="Group"
          filterKey="marketing_group_ids"
          options={{
            groups: [{ title: 'Group', options: marketingGroupOptions }],
          }}
          onFilter={selections => {
            props.onFilter({ marketing_group_ids: JSON.stringify(selections) });
          }}
          defaultDict={defaultFilters?.marketing_group_ids}
        />
      )}
      <GenericAdminFilter
        filterTitle="Region"
        filterKey="states"
        options={{
          groups: [
            {
              title: 'Canada',
              options: PROVINCE_OPTIONS.map(p => ({
                label: p,
                value: p,
              })).concat([
                { label: 'Canada', value: 'Canada' },
                { label: 'ROC', value: 'ROC' },
              ]),
            },
            {
              title: 'USA',
              options: US_STATES.map(p => ({ label: p, value: p })).concat([
                { label: 'USA', value: 'USA' },
              ]),
            },
          ],
        }}
        onFilter={selections => {
          props.onFilter({
            states: JSON.stringify(selections),
          });
        }}
        defaultDict={defaultFilters?.states}
      />
    </div>
  );
}
