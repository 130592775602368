import { combineEpics } from 'redux-observable';

import getAnalysis from 'epics/analysis/getAnalysis.epic';
import getAnalysisPresetsNext from 'epics/analysis/getAnalysisPresetsNext.epic';
import getAnalysisPresets from 'epics/analysis/getAnalysisPresets.epic';
import createPreset from 'epics/analysis/createPreset.epic';
import editPreset from 'epics/analysis/editPreset.epic';
import deletePreset from 'epics/analysis/deletePreset.epic';
import getBetSelection from 'epics/analysis/getBetSelection.epic';
import getBetSelectionNext from 'epics/analysis/getBetSelectionNext.epic';
import getGraphData from 'epics/analysis/getGraphData.epic';
import getTags from 'epics/analysis/getTags.epic';
import getTagsNext from 'epics/analysis/getTagsNext.epic';

export default combineEpics(
  getAnalysis,
  getAnalysisPresets,
  getAnalysisPresetsNext,
  createPreset,
  editPreset,
  deletePreset,
  getBetSelection,
  getBetSelectionNext,
  getGraphData,
  getTags,
  getTagsNext
);
