import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

// components
import DisplayToggle from 'components/generic/DisplayToggle';
import { SwitchSetting } from 'components/SettingsBtn';

// actions
import { setAppSetting, updateUser } from 'actions';

const DEFAULT_TAB_MAP = {
  'Full Game': 'FullTime',
  'Game Props': 'GamePropProps',
  'Player Props': 'PlayerPropPlayers',
  FullTime: 'Full Game',
  GamePropProps: 'Game Props',
  PlayerPropPlayers: 'Player Props',
};

const BA_MAP = {
  ALL: 'All Books',
  REGION: 'My State',
  MY: 'My Books',
  'All Books': 'ALL',
  'My State': 'REGION',
  'My Books': 'MY',
};

export default function Settings() {
  const dispatch = useDispatch();
  const reduxProps = useSelector(state => ({
    user: state.authReducer.user,
    showML: state.settingsReducer.showML,
    showSpread: state.settingsReducer.showSpread,
    showTotal: state.settingsReducer.showTotal,
    defaultTopTab: state.settingsReducer.defaultTopTab,
  }));
  const { user, showML, showSpread, showTotal, defaultTopTab } = reduxProps;
  return (
    <div
      style={{
        maxWidth: '800px',
        alignItems: 'stretch',
        margin: 'auto',
      }}
    >
      <h5>Default Tab</h5>
      <DisplayToggle
        options={['Full Game', 'Game Props', 'Player Props']}
        active={DEFAULT_TAB_MAP[defaultTopTab]}
        onPress={opt =>
          dispatch(setAppSetting('defaultTopTab', DEFAULT_TAB_MAP[opt]))
        }
      />

      <h5>Show Best Available Odds From</h5>
      <DisplayToggle
        options={['All Books', 'My State', 'My Books']}
        active={BA_MAP[user.show_books_ba]}
        onPress={opt => {
          dispatch(updateUser({ show_books_ba: BA_MAP[opt] }));
        }}
      />

      <h5>Show Additional Odds From</h5>
      <DisplayToggle
        options={['All Books', 'My State']}
        active={BA_MAP[user.show_books]}
        onPress={opt => {
          dispatch(updateUser({ show_books: BA_MAP[opt] }));
        }}
      />
      <br />
      <SwitchSetting
        heading="Show Moneyline Odds"
        description="Show moneyline odds on the single game screen"
        setting="showML"
        value={showML}
      />
      <SwitchSetting
        heading="Show Game Spread"
        description="Show game spread on the single game screen"
        setting="showSpread"
        value={showSpread}
      />
      <SwitchSetting
        heading="Show Game Total"
        description="Show game total on the sigle game screen"
        setting="showTotal"
        value={showTotal}
      />
    </div>
  );
}
