import { Observable } from 'rxjs';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import { ajax } from 'rxjs/observable/dom/ajax';
import { BACKEND_API_URL, getHeaders } from 'utils';

import {
  GET_LINE_HISTORY_GRAPH,
  getLineHistoryGraphSuccess,
  getLineHistoryGraphFailure,
} from 'actions';

export default function getLineHistoryGraph(action$, state$) {
  return action$.ofType(GET_LINE_HISTORY_GRAPH).switchMap(action => {
    let url =
      BACKEND_API_URL +
      `api/gameline_graph/?game_id=${
        action.game_id
      }&period=FT&history=true&book_ids=${JSON.stringify(
        action.book_ids
      )}&line=${action.line}`;

    return ajax
      .getJSON(url, getHeaders(state$))
      .map(data => getLineHistoryGraphSuccess(data))
      .catch(error => Observable.of(getLineHistoryGraphFailure(error.xhr)));
  });
}
