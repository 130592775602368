import React from 'react';

// components
import { Row } from 'components/generic/Layout';
import AffiliateLink from 'components/AffiliateLink';
import { AuthButton } from 'components/AuthButton';

// checkmark images
import EmptyCircleCheckmark from 'images/icons/empty-circle-checkmark.png';
import FilledCircleCheckmark from 'images/icons/filled-circle-checkmark.png';

export default function BookListItem(props) {
  const { book, selected, onPressCheckmark } = props;
  if (!book?.affiliate_links) {
    return null;
  }
  if (book?.affiliate_links?.length < 1) {
    return null;
  }

  return (
    <Row
      style={{
        alignItems: 'center',
        margin: 'var(--space-xs)',
      }}
    >
      <AffiliateLink
        book={book}
        shape="bar"
        hideLinkable
        fromPage="Rookie Web Onboarding"
        containerStyle={{ marginRight: 'var(--space-sm)' }}
      />
      <AuthButton
        overrideChildren
        colorTheme="text"
        btnTheme="borderless"
        containerStyle={{ borderRadius: 100, flex: 0 }}
        btnStyle={{
          borderRadius: 100,
          padding: 'var(--space-xs)',
        }}
        onPress={onPressCheckmark}
      >
        <img
          src={selected ? FilledCircleCheckmark : EmptyCircleCheckmark}
          style={{
            flex: 0,
            width: 64,
            height: 64,
            opacity: selected ? 1 : 0.75,
          }}
          alt={selected ? 'Checked Checkmark' : 'Empty Checkmark'}
        />
      </AuthButton>
    </Row>
  );
}
