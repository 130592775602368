import { Observable } from 'rxjs';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import { ajax } from 'rxjs/observable/dom/ajax';
import { BACKEND_API_URL, getHeaders } from 'utils';

import {
  VIEW_PURCHASES,
  viewPurchasesSuccess,
  viewPurchasesFailure,
} from 'actions';

export default function viewPurchases(action$, state$) {
  return action$.ofType(VIEW_PURCHASES).switchMap(() => {
    return ajax
      .getJSON(BACKEND_API_URL + 'api/purchases/', getHeaders(state$))
      .map(data => viewPurchasesSuccess(data))
      .catch(error => Observable.of(viewPurchasesFailure(error.xhr)));
  });
}
