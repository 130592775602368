// actions (excluding success and failure actions):
export const GET_TRANSACTIONS = 'GET_TRANSACTIONS';
export const GET_TRANSACTION_SUMMARY = 'GET_TRANSACTION_SUMMARY';
export const SET_SORT_BY = 'SET_SORT_BY';
export const SET_ACCOUNTING_BOOK = 'SET_ACCOUNTING_BOOK';
export const DELETE_TRANSACTION = 'DELETE_TRANSACTION';
export const RESET_TRANSACTION_EDIT_DEL = 'RESET_TRANSACTION_EDIT_DEL';
export const CREATE_TRANSACTION = 'CREATE_TRANSACTION';
export const UPDATE_TRANSACTION = 'UPDATE_TRANSACTION';

export const GET_TRANSACTIONS_SUCCESS = 'GET_TRANSACTIONS_SUCCESS';
export const GET_TRANSACTIONS_FAILURE = 'GET_TRANSACTIONS_FAILURE';
export const GET_TRANSACTIONS_NEXT = 'GET_TRANSACTIONS_NEXT';
export const getTransactions = book => ({ type: GET_TRANSACTIONS, book });
export const getTransactionsSuccess = payload => ({
  type: GET_TRANSACTIONS_SUCCESS,
  payload,
});
export const getTransactionsFailure = error => ({
  type: GET_TRANSACTIONS_FAILURE,
  error,
});
export const getTransactionsNext = () => ({ type: GET_TRANSACTIONS_NEXT });

export const GET_TRANSACTION_SUMMARY_SUCCESS =
  'GET_TRANSACTION_SUMMARY_SUCCESS';
export const GET_TRANSACTION_SUMMARY_FAILURE =
  'GET_TRANSACTION_SUMMARY_FAILURE';

export const getTransactionSummary = sortBy => ({
  type: GET_TRANSACTION_SUMMARY,
  sortBy,
});

export const getTransactionSummarySuccess = payload => ({
  type: GET_TRANSACTION_SUMMARY_SUCCESS,
  payload: payload,
});

export const getTransactionSummaryFailure = error => ({
  type: GET_TRANSACTION_SUMMARY_FAILURE,
  payload: error,
});

export const setSortBy = sortBy => ({
  type: SET_SORT_BY,
  sortBy,
});

export const setAccountingBook = book => ({
  type: SET_ACCOUNTING_BOOK,
  book,
});

export const DELETE_TRANSACTION_SUCCESS = 'DELETE_TRANSACTION_SUCCESS';
export const DELETE_TRANSACTION_FAILURE = 'DELETE_TRANSACTION_FAILURE';

export const deleteTransaction = id => ({ type: DELETE_TRANSACTION, id });
export const deleteTransactionSuccess = (payload, deletedID) => ({
  type: DELETE_TRANSACTION_SUCCESS,
  payload,
  deletedID,
});
export const deleteTransactionFailure = (error, deletedID) => ({
  type: DELETE_TRANSACTION_FAILURE,
  error,
  deletedID,
});

export const resetTransactionEditDel = () => ({
  type: RESET_TRANSACTION_EDIT_DEL,
});

export const CREATE_TRANSACTION_SUCCESS = 'CREATE_TRANSACTION_SUCCESS';
export const CREATE_TRANSACTION_FAILURE = 'CREATE_TRANSACTION_FAILURE';
export const createTransaction = tx => ({ type: CREATE_TRANSACTION, tx });
export const createTransactionSuccess = payload => ({
  type: CREATE_TRANSACTION_SUCCESS,
  payload,
});
export const createTransactionFailure = error => ({
  type: CREATE_TRANSACTION_FAILURE,
  error,
});

export const UPDATE_TRANSACTION_SUCCESS = 'UPDATE_TRANSACTION_SUCCESS';
export const UPDATE_TRANSACTION_FAILURE = 'UPDATE_TRANSACTION_FAILURE';
export const updateTransaction = tx => ({ type: UPDATE_TRANSACTION, tx });
export const updateTransactionSuccess = payload => ({
  type: UPDATE_TRANSACTION_SUCCESS,
  payload,
});
export const updateTransactionFailure = error => ({
  type: UPDATE_TRANSACTION_FAILURE,
  error,
});
