import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// components
import { BetstampWordWhiteText } from 'components/generic/Logos';
import { Row, Col, Grid } from 'components/generic/Layout';

// actions
import { getAffiliates } from 'actions';

import { MAX_SCREEN_HEIGHT_SIGNUP_ONBOARD } from 'utils';

// local images
import AppStoreImg from 'images/download_app_store.png';
import PlayStoreImg from 'images/download_play_store.png';

export default function Wrapper(props) {
  const dispatch = useDispatch();
  const reduxProps = useSelector(state => ({
    user: state.authReducer.user,
  }));
  const { user } = reduxProps;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => dispatch(getAffiliates()), [user?.referral_code]);

  return (
    <Row style={{ minHeight: '100vh', overflow: 'hidden' }}>
      <Col
        desktop
        style={{
          flex: 1.33,
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          backgroundColor: 'var(--color-primary',
          color: 'white',
        }}
      >
        <div style={{ maxWidth: '720px', margin: '0 auto' }}>
          <Col>
            <BetstampWordWhiteText height={96} />
          </Col>
          <h3>Odds Comparison</h3>
          <p>
            Compare odds across dozens of sportsbooks to ensure you always get
            the best line.
          </p>

          <h3>Marketplace</h3>
          <p>
            Join the world's first verified buy/sell marketplace for sports
            betting picks. Keep a verified public record to build credibility
            and set yourself apart in the sports betting industry.
          </p>

          <h3>Analytics</h3>
          <p>
            Keep track of your gains and see where you can improve with betstamp
            analytics.
          </p>

          <h3>Follow the action</h3>
          <p>
            Follow the action on the go with the betstamp app for iOS and
            Android.
          </p>
          <Grid
            style={{
              width: '100%',
              margin: 'var(--space-md) 0',
              gridTemplateColumns: 'repeat(auto-fit,  minmax(164px, 210px))',
            }}
          >
            <a href="https://apps.apple.com/us/app/id1525948689">
              <img
                src={AppStoreImg}
                alt="Download from the App Store"
                style={{ width: '100%', height: 'auto' }}
              />
            </a>
            <a href="https://play.google.com/store/apps/details?id=app.cashew.betstamp">
              <img
                src={PlayStoreImg}
                alt="Download from the Play Store"
                style={{ width: '100%', height: 'auto' }}
              />
            </a>
          </Grid>
        </div>
      </Col>
      <Col
        style={{
          justifyContent: 'flex-start',
          padding: 'var(--space-sm) var(--space-xs)',
          height: `min(100vh, ${MAX_SCREEN_HEIGHT_SIGNUP_ONBOARD})`,
          width: '100%',
          overflowY: 'auto',
          overflowX: 'hidden',
        }}
      >
        {props.children}
      </Col>
    </Row>
  );
}
