import React from 'react';

// components
import { Row } from 'components/generic/Layout';
import { AuthButton } from 'components/AuthButton';

import { AUTH_BUTTON_DEFAULT_CONTAINER_STYLE } from 'utils';

export default function ConfirmDelete(props) {
  return (
    <>
      <p>{props.children}</p>

      <Row style={{ marginTop: 'var(--space-xl)' }}>
        <AuthButton
          containerStyle={AUTH_BUTTON_DEFAULT_CONTAINER_STYLE}
          onPress={() => props.dismissModal()}
        >
          Cancel
        </AuthButton>
        <AuthButton
          colorTheme="danger"
          containerStyle={AUTH_BUTTON_DEFAULT_CONTAINER_STYLE}
          onPress={() => props.onDelete()}
          isLoading={props.isDeleting}
        >
          {props.deleteBtnText || 'Delete'}
        </AuthButton>
      </Row>
    </>
  );
}
