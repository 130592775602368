import { Observable } from 'rxjs';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import { ajax } from 'rxjs/observable/dom/ajax';
import { getHeaders } from 'utils';

import { GET_SCHED_NEXT, getSchedSuccess, getSchedFailure } from 'actions';

export default function getSchedNext(action$, state$) {
  return action$.ofType(GET_SCHED_NEXT).switchMap(() => {
    const { showingDate, schedule } = state$.value.scheduleReducer;
    const nextURL = schedule[showingDate.substr(0, 10)].next;

    return ajax
      .getJSON(nextURL, getHeaders(state$))
      .map(data => getSchedSuccess(data, false, true))
      .catch(error => Observable.of(getSchedFailure(error.xhr)));
  });
}
