import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useEffectOnce } from 'react-use';

// components
import { Grid, Row } from 'components/generic/Layout';
import ErrorDisplay from 'components/generic/ErrorDisplay';
import ActivityIndicator from 'components/generic/ActivityIndicator';
import UsersListItem from 'components/UsersListItem';
import { LinkButton } from 'components/AuthButton';

// actions
import { getFollowing, getFollowingNext } from 'actions';

export default function Following(props) {
  const dispatch = useDispatch();
  const reduxProps = useSelector(state => ({
    following: state.publicUserReducer.following,
    fetchError: state.publicUserReducer.fetchError,
    isLoadingFollowingFollowing:
      state.publicUserReducer.isLoadingFollowingFollowing,
    isNextFollowLoading: state.publicUserReducer.isNextFollowLoading,
    followingNext: state.publicUserReducer.followingNext,
  }));

  const { following, fetchError, isLoadingFollowing, followingNext } =
    reduxProps;

  const _getNextFollowing = () => {
    if (followingNext) {
      dispatch(getFollowingNext());
    }
  };

  const _initialLoad = () => {
    dispatch(getFollowing());
  };

  useEffectOnce(() => {
    _initialLoad();
  });

  if (fetchError) {
    return <ErrorDisplay error={fetchError} retry={() => _initialLoad()} />;
  }

  if (isLoadingFollowing) {
    return (
      <Row>
        <ActivityIndicator size={2} />
      </Row>
    );
  }
  return (
    <InfiniteScroll
      scrollableTarget={'following-modal-wrapper'}
      scrollThreshold={0.5}
      next={_getNextFollowing}
      hasMore={following}
      dataLength={following.length}
      loader={<ActivityIndicator size={1.5} />}
    >
      <Grid style={{ gridTemplateColumns: '1fr 1fr' }}>
        {following.map(item => (
          <UsersListItem
            key={`following-user-list-item-${item.id}`}
            user={item}
            showStats={['roi']}
            showFollowButton
          />
        ))}
      </Grid>

      {following.length === 0 && (
        <>
          <h6 style={{ marginTop: 'var(--space-md)' }}>
            You aren't following anyone! Check out the betstamp Marketplace to
            follow friends and other bettors and view what others are betting.
          </h6>
          <br />
          <LinkButton
            to="/find-bettors"
            containerStyle={{
              maxWidth: '21rem',
            }}
          >
            Find Bettors
          </LinkButton>
        </>
      )}
    </InfiniteScroll>
  );
}
