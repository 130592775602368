import React, { useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';

// utils
import { humanTime, humanDatetime } from 'utils';

// components
import StaffOnboardingBase from 'components/StaffOnboardingBase';
import { Row } from 'components/generic/Layout';
import { AuthTextInput } from 'components/AuthTextInput';
import { AuthButton } from 'components/AuthButton';
import Select from 'components/generic/Select';
import HostSelector from 'components/onboarding/HostSelector';

const SESSION_TYPE_LABELS = {
  OPEN: 'Open',
  INVITE_ONLY: 'Invite Only',
  PLACEHOLDER: 'Placeholder',
};

export default function Schedule() {
  return (
    <>
      <StaffOnboardingBase
        endpoint="onboarding-schedules"
        modelName="Schedule"
        headings={[
          'Day',
          'Start',
          'Duration (hours)',
          'Repeat',
          'Session Type',
          'Active',
          '',
        ]}
        objKeys={[
          'day',
          'time_start',
          'duration',
          'repeat',
          'session_type',
          'is_active',
        ]}
        formatText={{
          time_start: humanTime,
          created_at: humanDatetime,
          is_active: t => (t ? '✓' : '✕'),
        }}
        PanelChildren={Panel}
        panelTitle="Schedule"
        deleteBaseEndpoint="onboarding-schedules"
      />
    </>
  );
}

function Panel(props) {
  const schedule = props.selectedObj;
  const { tab } = props;

  const [formData, setFormData] = useState(
    schedule?.createNew
      ? { hosts: [], host_ids: [], is_active: true, duration: 3 }
      : { ...schedule, hosts: schedule.host_ids.map(id => ({ id: id })) }
  );

  const queryClient = useQueryClient();
  const submitSched = useMutation({
    mutationKey: 'submit-schedule',
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [
          {
            endpoint: 'onboarding-schedules',
          },
        ],
      });
      props.closePanel();
    },
  });

  const submitDisabled =
    !formData.repeat ||
    !formData.day ||
    !formData.time_start ||
    !formData.duration ||
    formData.host_ids?.length <= 0;

  return (
    <>
      {(tab === 'Edit' || schedule.createNew) && (
        <form
          key={schedule?.id || 'new-schedule-form'}
          style={{ width: '100%' }}
          onSubmit={ev => {
            ev.preventDefault();
            const fd = new FormData(ev.target);
            //formData.host_ids.map(id => fd.append('host_ids', id));
            submitSched.mutate({
              endpoint: `onboarding-schedules${
                schedule.createNew ? '' : '/' + schedule.id
              }`,
              body: fd,
            });
          }}
        >
          <Select
            label="Day *"
            labelStyle={{ margin: 0 }}
            name="day"
            options={[
              { label: 'SUNDAY', value: 'SUNDAY' },
              { label: 'MONDAY', value: 'MONDAY' },
              { label: 'TUESDAY', value: 'TUESDAY' },
              { label: 'WEDNESDAY', value: 'WEDNESDAY' },
              { label: 'THURSDAY', value: 'THURSDAY' },
              { label: 'FRIDAY', value: 'FRIDAY' },
              { label: 'SATURDAY', value: 'SATURDAY' },
            ]}
            defaultValue={
              schedule?.createNew
                ? undefined
                : { label: schedule.day, value: schedule.day }
            }
            onChange={opt => setFormData({ ...formData, day: opt.value })}
          />
          <Row style={{ gap: '1rem' }}>
            <AuthTextInput
              containerStyle={{ flex: 1 }}
              name="time_start"
              label="Start Time (Eastern Time) *"
              type="time"
              defaultValue={
                schedule?.createNew ? undefined : schedule.time_start
              }
              onChangeText={text => {
                setFormData({ ...formData, time_start: text });
              }}
            />
            <AuthTextInput
              containerStyle={{ flex: 1 }}
              name="duration"
              label="Duration (hours)"
              type="number"
              defaultValue={schedule?.createNew ? 3 : schedule.duration}
              onChangeText={text =>
                setFormData({ ...formData, duration: parseFloat(text) })
              }
            />
          </Row>

          <Select
            label="Repeat *"
            labelStyle={{ margin: 0 }}
            name="repeat"
            options={[
              { label: 'Weekly', value: 'WEEKLY' },
              {
                label: `First ${formData.day || '{day}'} of every month`,
                value: 'FIRST_OF_MONTH',
              },
              {
                label: `Second ${formData.day || '{day}'} of every month`,
                value: 'SECOND_OF_MONTH',
              },
              {
                label: `Third ${formData.day || '{day}'} of every month`,
                value: 'THIRD_OF_MONTH',
              },
              {
                label: `Fourth ${formData.day || '{day}'} of every month`,
                value: 'FOURTH_OF_MONTH',
              },
            ]}
            defaultValue={
              schedule?.createNew
                ? undefined
                : { label: schedule.repeat, value: schedule.repeat }
            }
            onChange={opt => setFormData({ ...formData, repeat: opt.value })}
          />

          <Select
            label="Session Type *"
            name="session_type"
            options={[
              { label: 'Open', value: 'OPEN' },
              { label: 'Invite Only', value: 'INVITE_ONLY' },
              { label: 'Placeholder', value: 'PLACEHOLDER' },
            ]}
            defaultValue={
              schedule?.createNew
                ? { label: SESSION_TYPE_LABELS['OPEN'], value: 'OPEN' }
                : {
                    label: SESSION_TYPE_LABELS[schedule.session_type],
                    value: schedule.session_type,
                  }
            }
            onChange={opt => {
              setFormData({ ...formData, session_type: opt.value });
            }}
          />

          <br />
          <input
            type="checkbox"
            name="is_active"
            id="is_active"
            checked={formData.is_active}
            onChange={input => {
              setFormData({ ...formData, is_active: input.target.checked });
            }}
          />
          <label htmlFor="is_active">Is Active</label>
          <br />
          <br />

          <HostSelector
            selectedHostIDs={formData.host_ids}
            selectedMainHostID={formData.main_host_id}
            onChange={host_ids => {
              let newMainHost = formData.main_host_id;
              if (newMainHost && !host_ids.includes(newMainHost)) {
                newMainHost = null;
              }
              setFormData({
                ...formData,
                host_ids: host_ids,
                main_host_id: newMainHost,
              });
            }}
            onChangeMainHost={host_id => {
              setFormData({
                ...formData,
                main_host_id: host_id,
              });
            }}
          />

          <AuthButton
            containerStyle={{ marginTop: 'var(--space-xl)' }}
            type="submit"
            isLoading={submitSched.isLoading}
            disabled={submitDisabled}
          >
            {schedule.createNew ? 'Create' : 'Edit'} Schedule
          </AuthButton>
          {submitSched.isError && (
            <span style={{ color: 'var(--color-danger)' }}>
              {submitSched?.error?.message}
            </span>
          )}
        </form>
      )}
    </>
  );
}
