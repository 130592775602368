import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  useQuery,
  useInfiniteQuery,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import styled from 'styled-components';
import { IoOpenOutline, IoChevronUp, IoChevronDown } from 'react-icons/io5';

// utils
import { REGION_FLAGS, humanDatetime, formatMoney } from 'utils';

// components
import StaffNavBar from 'components/nav/StaffNavBar';
import { Row, Col } from 'components/generic/Layout';
import ActivityIndicator from 'components/generic/ActivityIndicator';
import { AuthButton, IconButton } from 'components/AuthButton';
import MailboxLayout from 'components/generic/MailboxLayout';
import ProgressListDisplay from 'components/onboarding/ProgressListDisplay';
import PaymentList from 'components/onboarding/PaymentList';
import OnboardingPayouts from 'components/onboarding/OnboardingPayouts';
import DisplayToggle from 'components/generic/DisplayToggle';
import StageDropdown from 'components/onboarding/StageDropdown';
import ScreenshotViewer from 'components/onboarding/ScreenshotViewer';
// gross hacky imports
import { Panel as StaffOnboardingBasePanel } from 'components/StaffOnboardingBase';
import { Panel as SessionPanelFromAllSessions } from 'pages/onboarding/AllSessions';

// react query custom hooks
import { useUserGroups } from 'react-query/global-hooks';

const GCAL_STATUS_MEANING = {
  needsAction: 'Awaiting Response',
  declined: 'Declined Invite',
  tentative: 'Tentative',
  accepted: 'Accepted Invite',
};

const Tbody = styled.tbody`
  > tr {
    transition: all var(--std-transition);
  }
  > tr:hover {
    background-color: var(--color-text-light);
  }
  > tr:hover:first-child {
    border-radius: var(--std-border-radius);
  }
  > tr > td {
    padding: var(--space-xxs) var(--space-sm);
  }
  > tr:not(:last-child) > td {
    border-bottom: 1px solid var(--color-text-light);
  }
  > tr > td:last-child {
    text-align: right;
  }
`;

const Session = styled.a`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-decoration: none;
  cursor: pointer;
  transition: all var(--std-transition);
  :hover {
    color: var(--color-primary);
  }
`;

// can't be inside the component or else it will change on every rerender
// which will cause react-query to refetch the data in an infinite loop
const rightNow = new Date();

export default function MyDashboard() {
  const reduxProps = useSelector(state => ({
    user: state.authReducer.user,
    allBooksMap: state.authReducer.allBooksMap,
  }));
  const { user, allBooksMap } = reduxProps;

  const [forceSelectedKey, setForceSelectedKey] = useState(null);

  const fetchSessions = useInfiniteQuery({
    queryKey: [
      {
        endpoint: `onboarding-sessions`,
        urlParams: {
          date_after: rightNow.toISOString(),
          host_ids: JSON.stringify([user.id]),
          sort: JSON.stringify(['date']),
        },
      },
    ],
    getNextPageParam: (lastPage, allPages) => lastPage.next,
    getPreviousPageParam: (firstPage, allPages) => firstPage.prev,
  });

  const sessionPages = fetchSessions?.data?.pages;

  let nextSession = null;
  let nextSessions = [];
  if (sessionPages && sessionPages?.length > 0 && sessionPages[0]?.results) {
    nextSession = sessionPages[0].results[0];
    nextSessions = sessionPages[0]?.results.slice(1, 4);
  }

  const userGroups = useUserGroups();

  const fetchFollowUpHosts = useQuery({
    enabled:
      userGroups.isExecutive ||
      userGroups.isFullDeveloper ||
      userGroups.isOnboardingSalesTeam ||
      userGroups?.isOnboardingFollowUpHost,
    queryKey: [
      {
        endpoint: 'onboarding-hosts',
        urlParams: { groups: JSON.stringify('Onboarding Follow Up Host') },
      },
    ],
  });

  const [viewingStatsFor, setViewingStatsFor] = useState(null);
  const [statsUrlParams, setStatsUrlParams] = useState({
    date_after: '2024-05-01T00:00:00.000Z',
    stats: JSON.stringify([
      'screenshots_to_review',
      'attendees_to_pay',
      'attendees_to_receive',
      //'conversion_rate',
      //'utilization_rate',
      'upsell_opportunities',
      'num_of_books_require_assistance',
    ]),
  });

  const fetchStats = useQuery({
    // note: you really need to make sure you give enabled a boolean false
    // undefined doesnt count
    enabled: !!(
      userGroups.isOnboardingFollowUpHost ||
      ((userGroups.isExecutive || userGroups.isFullDeveloper) &&
        !!statsUrlParams.user_ids)
    ),
    queryKey: [
      {
        endpoint: 'onboarding-stats',
        urlParams: statsUrlParams,
      },
    ],
  });
  const stats = fetchStats?.data;
  if (userGroups.isLoading) {
    return (
      <ActivityIndicator size={3} style={{ marginTop: 'var(--space-xxl)' }} />
    );
  }

  let extraEndpoints = {};
  let extraModelNames = {};
  let extraTitleKeys = {};
  let extraDescKeys = {};
  let modelOptions = [
    { label: 'My Upcoming Sessions', value: 'myUpcomingSessions' },
    { label: 'Attended My Session', value: 'attendedMySession' },
    { label: 'All Attendees', value: 'allAttendees' },
  ];

  if (userGroups.isOnboardingFollowUpHost) {
    const upsellKey = 'myUpsellOpportunities';
    const payKey = 'myAttendeesToPay';
    const collectKey = 'myAttendeesToReceive';
    const unpaidSessionKey = 'myUnpaidSessions';
    modelOptions = [
      { label: 'My Follow Ups', value: 'myFollowUps' },
      { label: 'My Screenshots to Review', value: 'myScreenshotsToReview' },
      //{ label: "My Attendee's Books Need Help", value: 'myNeedsHelp' },
      { label: "My Attendee's Who Need Help", value: 'myAttendeeNeedsHelp' },
      //
      { label: 'Attendees to Pay', value: payKey },
      { label: 'Attendee to Collect From', value: collectKey },
      //
      { label: 'My Upsell Opportunities', value: upsellKey },
      { label: 'My Upcoming Sessions', value: 'myUpcomingSessions' },
      { label: 'My Unpaid Sessions', value: unpaidSessionKey },
      { label: 'Attended My Session', value: 'attendedMySession' },
      { label: 'All Attendees', value: 'allAttendees' },
    ];

    // unpaid session
    extraEndpoints[unpaidSessionKey] = {
      endpoint: `onboarding-sessions`,
      urlParams: {
        session_not_paid: true,
        date_before: rightNow.toISOString(),
        date_after: '2024-05-01T00:00:00.000Z',
        host_ids: JSON.stringify([user.id]),
        sort: JSON.stringify(['-date']),
      },
    };
    extraModelNames[unpaidSessionKey] = 'Session';
    extraTitleKeys[unpaidSessionKey] = 'id';
    extraDescKeys[unpaidSessionKey] = 'date';

    // upsell
    extraEndpoints[upsellKey] = {
      endpoint: `onboarding-attendees`,
      urlParams: {
        upsell_opportunities: true,
        follow_up_host_ids: JSON.stringify([user.id]),
        created_at_after: '2024-05-01T00:00:00.000Z',
      },
    };
    extraModelNames[upsellKey] = 'Attendee';
    extraTitleKeys[upsellKey] = 'full_name';
    extraDescKeys[upsellKey] = 'last_follow_up_date';

    // pay
    extraEndpoints[payKey] = {
      endpoint: `onboarding-attendees`,
      urlParams: {
        follow_up_host_ids: JSON.stringify([user.id]),
        balance_less_than: 0,
        created_at_after: '2024-05-01T00:00:00.000Z',
        //stages: JSON.stringify([
        //  'ONBOARDING_ATTENDED',
        //  'VERIFYING_SUBMISSIONS_PENDING',
        //  'VERIFYING_SUBMISSIONS_PARTIAL_APPROVED',
        //  'VERIFYING_SUBMISSIONS_FULLY_APPROVED',
        //  'VERIFYING_SUBMISSIONS_PAID',
        //  'SUBMISSIONS_DECLINED',
        //  'ONBOARDING_COMPLETE',
        //]),
      },
    };
    extraModelNames[payKey] = 'Attendee';
    extraTitleKeys[payKey] = 'full_name';
    extraDescKeys[payKey] = 'last_follow_up_date';

    // collect
    extraEndpoints[collectKey] = {
      endpoint: `onboarding-attendees`,
      urlParams: {
        follow_up_host_ids: JSON.stringify([user.id]),
        created_at_after: '2024-05-01T00:00:00.000Z',
        balance_greater_than: 0,
        stages: JSON.stringify([
          'ONBOARDING_ATTENDED',
          'VERIFYING_SUBMISSIONS_PENDING',
          'VERIFYING_SUBMISSIONS_PARTIAL_APPROVED',
          'VERIFYING_SUBMISSIONS_FULLY_APPROVED',
          'VERIFYING_SUBMISSIONS_PAID',
          'SUBMISSIONS_DECLINED',
          'ONBOARDING_COMPLETE',
        ]),
      },
    };
    extraModelNames[collectKey] = 'Attendee';
    extraTitleKeys[collectKey] = 'full_name';
    extraDescKeys[collectKey] = 'last_follow_up_date';
  }

  if (
    fetchFollowUpHosts.isSuccess &&
    fetchFollowUpHosts?.data.results?.length > 0 &&
    (userGroups.isExecutive ||
      userGroups.isFullDeveloper ||
      userGroups.isOnboardingSalesTeam ||
      userGroups?.isOnboardingFollowUpHost)
  ) {
    for (const host of fetchFollowUpHosts?.data?.results) {
      // hosts follow ups
      // dont change this key
      const hostsFollowUps = `hostsFollowUps-${host.id}-${host.full_name || host.email
        }`;
      modelOptions.push({
        label: `${host.full_name
            ? host.full_name.split(' ')[0]
            : host.email.split('@')[0]
          }'s Follow Ups`,
        value: hostsFollowUps,
      });
      extraEndpoints[hostsFollowUps] = {
        endpoint: `onboarding-attendees`,
        urlParams: {
          follow_up_host_ids: JSON.stringify([host.id]),
          created_at_after: '2024-05-01T00:00:00.000Z',
          stages: JSON.stringify([
            'ONBOARDING_ATTENDED',
            'VERIFYING_SUBMISSIONS_PENDING',
            'VERIFYING_SUBMISSIONS_PARTIAL_APPROVED',
            'VERIFYING_SUBMISSIONS_FULLY_APPROVED',
            'VERIFYING_SUBMISSIONS_PAID',
            'SUBMISSIONS_DECLINED',
          ]),
        },
      };
      extraModelNames[hostsFollowUps] = 'Attendee';
      extraTitleKeys[hostsFollowUps] = 'full_name';
      extraDescKeys[hostsFollowUps] = 'last_follow_up_date';
      // hosts screenshots to review
      // dont change this key
      const hostsScreenshotsToReview = `hostsScreenshotsToReview-${host.id}-${host.full_name || host.email
        }`;
      modelOptions.push({
        label: `${host.full_name
            ? host.full_name.split(' ')[0]
            : host.email.split('@')[0]
          }'s Screenshots to Review`,
        value: hostsScreenshotsToReview,
      });
      extraEndpoints[hostsScreenshotsToReview] = {
        endpoint: 'onboarding-attendee-progresses',
        urlParams: {
          follow_up_host_ids: JSON.stringify([host.id]),
          current_steps: JSON.stringify(['PENDING_SCREENSHOT_APPROVAL']),
          sort: JSON.stringify(['-created_at']),
        },
      };
      extraModelNames[hostsScreenshotsToReview] = 'Progress';
      extraTitleKeys[hostsScreenshotsToReview] = 'attendee';
      extraDescKeys[hostsScreenshotsToReview] = 'book';
      // hosts upsell opportunities
      // dont change this key
      const hostsUpsellOpps = `hostsUpsellOpps-${host.id}-${host.full_name || host.email
        }`;
      modelOptions.push({
        label: `${host.full_name
            ? host.full_name.split(' ')[0]
            : host.email.split('@')[0]
          }'s Upsell Opportunities`,
        value: hostsUpsellOpps,
      });
      extraEndpoints[hostsUpsellOpps] = {
        endpoint: 'onboarding-attendees',
        urlParams: {
          follow_up_host_ids: JSON.stringify([host.id]),
          created_at_after: '2024-05-01T00:00:00.000Z',
          upsell_opportunities: true,
        },
      };
      extraModelNames[hostsUpsellOpps] = 'Attendee';
      extraTitleKeys[hostsUpsellOpps] = 'full_name';
      extraDescKeys[hostsUpsellOpps] = 'last_follow_up_date';
      // hosts attendees to pay
      // dont change this key
      const hostsAtdesToPay = `hostsAtdesToPay-${host.id}-${host.full_name || host.email
        }`;
      modelOptions.push({
        label: `${host.full_name
            ? host.full_name.split(' ')[0]
            : host.email.split('@')[0]
          }'s Attendees to Pay`,
        value: hostsAtdesToPay,
      });
      extraEndpoints[hostsAtdesToPay] = {
        endpoint: 'onboarding-attendees',
        urlParams: {
          follow_up_host_ids: JSON.stringify([host.id]),
          created_at_after: '2024-05-01T00:00:00.000Z',
          balance_less_than: 0,
          //stages: JSON.stringify([
          //  'ONBOARDING_ATTENDED',
          //  'VERIFYING_SUBMISSIONS_PENDING',
          //  'VERIFYING_SUBMISSIONS_PARTIAL_APPROVED',
          //  'VERIFYING_SUBMISSIONS_FULLY_APPROVED',
          //  'VERIFYING_SUBMISSIONS_PAID',
          //  'SUBMISSIONS_DECLINED',
          //  'ONBOARDING_COMPLETE',
          //]),
        },
      };
      extraModelNames[hostsAtdesToPay] = 'Attendee';
      extraTitleKeys[hostsAtdesToPay] = 'full_name';
      extraDescKeys[hostsAtdesToPay] = 'last_follow_up_date';
      // hosts attendees to collect from
      // dont change this key
      const hostsAtdesToCollect = `hostsAtdesToCollect-${host.id}-${host.full_name || host.email
        }`;
      modelOptions.push({
        label: `${host.full_name
            ? host.full_name.split(' ')[0]
            : host.email.split('@')[0]
          }'s Attendees to Collect From`,
        value: hostsAtdesToCollect,
      });
      extraEndpoints[hostsAtdesToCollect] = {
        endpoint: 'onboarding-attendees',
        urlParams: {
          follow_up_host_ids: JSON.stringify([host.id]),
          balance_greater_than: 0,
          created_at_after: '2024-05-01T00:00:00.000Z',
          stages: JSON.stringify([
            'ONBOARDING_ATTENDED',
            'VERIFYING_SUBMISSIONS_PENDING',
            'VERIFYING_SUBMISSIONS_PARTIAL_APPROVED',
            'VERIFYING_SUBMISSIONS_FULLY_APPROVED',
            'VERIFYING_SUBMISSIONS_PAID',
            'SUBMISSIONS_DECLINED',
            'ONBOARDING_COMPLETE',
          ]),
        },
      };
      extraModelNames[hostsAtdesToCollect] = 'Attendee';
      extraTitleKeys[hostsAtdesToCollect] = 'full_name';
      extraDescKeys[hostsAtdesToCollect] = 'last_follow_up_date';
      // hosts unpaid sessions
      // dont change this key
      const hostsUnpaidSessions = `hostsUnpaidSessions-${host.id}-${host.full_name || host.email
        }`;
      modelOptions.push({
        label: `${host.full_name
            ? host.full_name.split(' ')[0]
            : host.email.split('@')[0]
          }'s Unpaid Sessions`,
        value: hostsUnpaidSessions,
      });
      extraEndpoints[hostsUnpaidSessions] = {
        endpoint: 'onboarding-attendees',
        urlParams: {
          follow_up_host_ids: JSON.stringify([host.id]),
          created_at_after: '2024-05-01T00:00:00.000Z',
          balance_greater_than: 0,
          stages: JSON.stringify([
            'ONBOARDING_ATTENDED',
            'VERIFYING_SUBMISSIONS_PENDING',
            'VERIFYING_SUBMISSIONS_PARTIAL_APPROVED',
            'VERIFYING_SUBMISSIONS_FULLY_APPROVED',
            'VERIFYING_SUBMISSIONS_PAID',
            'SUBMISSIONS_DECLINED',
            'ONBOARDING_COMPLETE',
          ]),
        },
      };
      extraModelNames[hostsUnpaidSessions] = 'Session';
      extraTitleKeys[hostsUnpaidSessions] = 'id';
      extraDescKeys[hostsUnpaidSessions] = 'date';
    }
  }

  let defaultSelectedKey = 'myUpcomingSessions';
  if (userGroups.isOnboardingFollowUpHost) {
    defaultSelectedKey = 'myFollowUps';
  } else if (userGroups.isExecutive) {
    defaultSelectedKey = 'allAttendees';
  }

  return (
    <>
      <StaffNavBar />
      <small
        style={{
          padding: 'var(--space-xs) var(--space-sm)',
        }}
      >
        Since May 1st, 2024
      </small>
      <Row
        style={{
          flexWrap: 'nowrap',
          overflowX: 'auto',
          gap: '1rem',
          justifyContent: 'flex-start',
          height: '90px',
          padding: 'var(--space-xs) var(--space-sm)',
          paddingTop: 0,
        }}
      >
        <div style={{ flex: '1 0 220px' }}>
          <small>Next Session</small>
          {nextSession ? (
            <Session
              title={`Open ${nextSession.name || nextSession.id} in new tab`}
              href={`${window.location.origin}/staff-session/${nextSession.id}`}
              rel="noopener noreferrer"
              target="_blank"
            >
              <Row>
                <b>{humanDatetime(nextSession.date)}&nbsp;</b>
                <IoOpenOutline size={22} />
              </Row>
              <span style={{ opacity: 0.8 }}>
                Attendees: {nextSession.num_attendees}
              </span>
            </Session>
          ) : (
            <h5 style={{ margin: 0 }}>
              {fetchSessions.isLoading ? (
                <>Loading</>
              ) : (
                <>No scheduled sessions</>
              )}
            </h5>
          )}
        </div>
        {!userGroups.isOnboardingFollowUpHost &&
          nextSessions?.length > 0 &&
          !(
            (userGroups.isExecutive || userGroups.isFullDeveloper) &&
            viewingStatsFor &&
            fetchStats.isSuccess &&
            stats
          ) && (
            <Row style={{ flex: '5 0 220px' }}>
              {nextSessions.map(sess => (
                <div
                  key={`next-sess-3-${sess.id}`}
                  style={{ flex: '1 0 220px' }}
                >
                  <small>Upcoming</small>
                  <Session
                    title={`Open ${sess.name || sess.id} in new tab`}
                    href={`${window.location.origin}/staff-session/${sess.id}`}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <Row>
                      <b>{humanDatetime(sess.date)}&nbsp;</b>
                      <IoOpenOutline size={22} />
                    </Row>
                    <span style={{ opacity: 0.8 }}>
                      Attendees: {sess.num_attendees}
                    </span>
                  </Session>
                </div>
              ))}
            </Row>
          )}
        {(userGroups.isOnboardingFollowUpHost ||
          ((userGroups.isExecutive || userGroups.isFullDeveloper) &&
            fetchStats.isSuccess &&
            !!viewingStatsFor &&
            stats)) && (
            <>
              <div
                className={
                  userGroups.isOnboardingFollowUpHost ? 'color-on-hover' : ''
                }
                style={{
                  flex: '1 0 220px',
                  cursor: userGroups.isOnboardingFollowUpHost
                    ? 'pointer'
                    : undefined,
                }}
                title="The number of your attendees with a negative balance (we owe them)"
                onClick={
                  userGroups.isOnboardingFollowUpHost
                    ? () => setForceSelectedKey('myAttendeesToPay')
                    : null
                }
              >
                <small>
                  {!!viewingStatsFor && `${viewingStatsFor} `}Attendees To Pay
                </small>
                <h5 style={{ margin: 0 }}>
                  {fetchStats?.data?.attendees_to_pay}
                </h5>
              </div>
              <div
                className={
                  userGroups.isOnboardingFollowUpHost ? 'color-on-hover' : ''
                }
                style={{
                  flex: '1 0 220px',
                  cursor: userGroups.isOnboardingFollowUpHost
                    ? 'pointer'
                    : undefined,
                }}
                title="The number of your attendees with a positive balance (they owe us)"
                onClick={
                  userGroups.isOnboardingFollowUpHost
                    ? () => setForceSelectedKey('myAttendeesToReceive')
                    : null
                }
              >
                <small>
                  {!!viewingStatsFor && `${viewingStatsFor} `}Attendees To Collect
                  From
                </small>
                <h5 style={{ margin: 0 }}>
                  {fetchStats?.data?.attendees_to_receive}
                </h5>
              </div>
              <div
                className={
                  userGroups.isOnboardingFollowUpHost ? 'color-on-hover' : ''
                }
                style={{
                  flex: '1 0 220px',
                  cursor: userGroups.isOnboardingFollowUpHost
                    ? 'pointer'
                    : undefined,
                }}
                title="The number of screenshots you have in the PENDING_SCREENSHOT_APPROVAL status"
                onClick={
                  userGroups.isOnboardingFollowUpHost
                    ? () => setForceSelectedKey('myScreenshotsToReview')
                    : null
                }
              >
                <small>
                  {!!viewingStatsFor && `${viewingStatsFor} `}Screenshots to
                  Review
                </small>
                <h5 style={{ margin: 0 }}>
                  {fetchStats?.data?.screenshots_to_review}
                </h5>
              </div>
              <div
                className={
                  userGroups.isOnboardingFollowUpHost ? 'color-on-hover' : ''
                }
                style={{
                  flex: '1 0 220px',
                  cursor: userGroups.isOnboardingFollowUpHost
                    ? 'pointer'
                    : undefined,
                }}
                title="The total number of books minus sum of the target num books or completed books (whichever is higher)"
                onClick={
                  userGroups.isOnboardingFollowUpHost
                    ? () => setForceSelectedKey('myUpsellOpportunities')
                    : null
                }
              >
                <small>
                  {!!viewingStatsFor && `${viewingStatsFor} `}Upsell Opportunities
                </small>
                <h5 style={{ margin: 0 }}>
                  {fetchStats?.data?.upsell_opportunities}
                </h5>
              </div>

              <div
                className={
                  userGroups.isOnboardingFollowUpHost ? 'color-on-hover' : ''
                }
                style={{
                  flex: '1 0 220px',
                  cursor: userGroups.isOnboardingFollowUpHost
                    ? 'pointer'
                    : undefined,
                }}
                title="The number of books that require your assistance"
                onClick={
                  userGroups.isOnboardingFollowUpHost
                    ? () => setForceSelectedKey('myAttendeeNeedsHelp')
                    : null
                }
              >
                <small>
                  {!!viewingStatsFor && `${viewingStatsFor} `}Requires Assistance
                </small>
                <h5 style={{ margin: 0 }}>
                  {fetchStats?.data?.num_of_books_require_assistance}
                </h5>
              </div>
            </>
          )}
      </Row>
      <MailboxLayout
        containerStyle={{
          borderTop: '1px solid var(--color-text-light)',
          height: 'calc(100vh - 64px - 90px)',
        }}
        defaultSelectedKey={defaultSelectedKey}
        forceSelectedKey={forceSelectedKey}
        onSelectedKeyChange={key => {
          if (
            (userGroups.isExecutive || userGroups.isFullDeveloper) &&
            key.includes('hosts')
          ) {
            const split = key.split('-');
            const forUserId = split[1];
            const forUserFullName = split[2];
            setViewingStatsFor(
              forUserFullName ? forUserFullName.split(' ')[0] + "'s" : null
            );
            setStatsUrlParams({
              ...statsUrlParams,
              user_ids: JSON.stringify([forUserId]),
            });
          } else {
            setViewingStatsFor(null);
          }
        }}
        modelOptions={modelOptions}
        endpoints={{
          myFollowUps: {
            endpoint: `onboarding-attendees`,
            urlParams: {
              follow_up_host_ids: JSON.stringify([user.id]),
              stages: JSON.stringify([
                'ONBOARDING_ATTENDED',
                'VERIFYING_SUBMISSIONS_PENDING',
                'VERIFYING_SUBMISSIONS_PARTIAL_APPROVED',
                'VERIFYING_SUBMISSIONS_FULLY_APPROVED',
                'VERIFYING_SUBMISSIONS_PAID',
                'SUBMISSIONS_DECLINED',
              ]),
            },
          },
          myUpcomingSessions: {
            endpoint: 'onboarding-sessions',
            urlParams: {
              date_after: rightNow.toISOString(),
              host_ids: JSON.stringify([user.id]),
              sort: JSON.stringify(['date']),
            },
          },
          myPastSessions: {
            endpoint: 'onboarding-sessions',
            urlParams: {
              date_before: rightNow.toISOString(),
              host_ids: JSON.stringify([user.id]),
              sort: JSON.stringify(['-date']),
            },
          },
          myScreenshotsToReview: {
            endpoint: 'onboarding-attendee-progresses',
            urlParams: {
              current_steps: JSON.stringify(['PENDING_SCREENSHOT_APPROVAL']),
              follow_up_host_ids: JSON.stringify([user.id]),
              sort: JSON.stringify(['-created_at']),
            },
          },
          myNeedsHelp: {
            endpoint: 'onboarding-attendee-progresses',
            urlParams: {
              current_steps: JSON.stringify(['NEEDS_HELP']),
              follow_up_host_ids: JSON.stringify([user.id]),
              sort: JSON.stringify(['-created_at']),
            },
          },
          myAttendeeNeedsHelp: {
            endpoint: `onboarding-attendees`,
            urlParams: {
              for_host_ids: JSON.stringify([user.id]),
              needs_help: true,
            },
          },
          attendedMySession: {
            endpoint: `onboarding-attendees`,
            urlParams: {
              for_host_ids: JSON.stringify([user.id]),
              stages: JSON.stringify([
                'ONBOARDING_ATTENDED',
                'VERIFYING_SUBMISSIONS_PENDING',
                'VERIFYING_SUBMISSIONS_PARTIAL_APPROVED',
                'VERIFYING_SUBMISSIONS_FULLY_APPROVED',
                'VERIFYING_SUBMISSIONS_PAID',
                'SUBMISSIONS_DECLINED',
              ]),
            },
          },
          allAttendees: {
            endpoint: `onboarding-attendees`,
            urlParams: {
              follow_up_host_ids: null,
              stages: null,
            },
          },
          ...extraEndpoints,
        }}
        modelNames={{
          myFollowUps: 'Attendee',
          myUpcomingSessions: 'Session',
          myPastSessions: 'Session',
          myScreenshotsToReview: 'Progress',
          myNeedsHelp: 'Progress',
          myAttendeeNeedsHelp: 'Attendee',
          attendedMySession: 'Attendee',
          allAttendees: 'Attendee',
          ...extraModelNames,
        }}
        titleKeys={{
          myFollowUps: 'full_name',
          myUpcomingSessions: 'id',
          myPastSessions: 'id',
          myScreenshotsToReview: 'attendee',
          myNeedsHelp: 'attendee',
          myAttendeeNeedsHelp: 'full_name',
          attendedMySession: 'full_name',
          allAttendees: 'full_name',
          ...extraTitleKeys,
        }}
        descKeys={{
          myFollowUps: 'last_follow_up_date',
          myUpcomingSessions: 'date',
          myPastSessions: 'date',
          myScreenshotsToReview: 'book',
          myNeedsHelp: 'book',
          myAttendeeNeedsHelp: 'last_follow_up_date',
          attendedMySession: 'last_follow_up_date',
          allAttendees: 'last_follow_up_date',
          ...extraDescKeys,
        }}
        formatText={{
          last_follow_up_date: t =>
            `Last follow up: ${t ? humanDatetime(t) : 'Never'}`,
          date: t => `${t ? humanDatetime(t) : '-'}`,
          created_at: t => `Created At: ${t ? humanDatetime(t) : '-'}`,
          book: t => `${t ? allBooksMap[t]?.name : t}`,
        }}
        showSearchButton
        searchPlaceholders={{
          myFollowUps: 'Search name, email, source, id',
          myUpcomingSessions: 'Search by name or id',
          myPastSessions: 'Search by name or id',
          myScreenshotsToReview: 'Search by book name or attendee name',
          attendedMySession: 'Search name, email, source, id',
          allAttendees: 'Search name, email, source, id',
        }}
        PanelChildren={Panel}
      />
    </>
  );
}

function SessionMailboxHeader({ session }) {
  return (
    <table
      style={{
        border: '1px solid var(--color-text-light)',
        borderRadius: 'var(--std-border-radius)',
        margin: '0 var(--space-xs)',
        marginTop: 'var(--space-xs)',
      }}
      cellSpacing={0}
    >
      <Tbody>
        <tr>
          <td>ID</td>
          <td>
            <b>
              <a
                style={{ color: 'var(--color-primary)' }}
                title={`Open ${session.name || session.id} in new tab`}
                href={`${window.location.origin}/staff-session/${session.id}`}
                rel="noopener noreferrer"
                target="_blank"
              >
                {session.id}
              </a>
            </b>
          </td>
        </tr>
        {session?.name && (
          <tr>
            <td>Name</td>
            <td>
              <b>{session.name || '-'}</b>
            </td>
          </tr>
        )}
        <tr>
          <td>Type</td>
          <td>
            <b>{session.session_type}</b>
          </td>
        </tr>
        <tr>
          <td>Date</td>
          <td>
            <b>{humanDatetime(session.date)}</b>
          </td>
        </tr>
        <tr>
          <td>Attendees</td>
          <td>
            <b>{session.num_attendees}</b>
          </td>
        </tr>
        {session.referral_code_code?.code && (
          <tr>
            <td>Referral Code</td>
            <td>
              <b>{session.referral_code_code?.code || '-'}</b>
            </td>
          </tr>
        )}
      </Tbody>
    </table>
  );
}

function Panel(props) {
  const { selectedKey, selectedObj } = props;

  if (
    selectedKey === 'myUnpaidSessions' ||
    selectedKey.includes('hostsUnpaidSessions')
  ) {
    return (
      <div style={{ width: '100%' }}>
        <StaffOnboardingBasePanel
          PanelChildren={SessionPanelFromAllSessions}
          selectedObj={selectedObj}
          panelTabs={['Details', 'Edit', 'Links', 'Signups', 'Payments']}
          modelName="Session"
        />
      </div>
    );
  }

  if (
    selectedKey === 'myUpcomingSessions' ||
    selectedKey === 'myPastSessions'
  ) {
    return (
      <MailboxLayout
        containerStyle={{
          height: 'calc(100vh - 64px - 96px)',
          width: '100%',
        }}
        headerChildren={{
          all: () => <SessionMailboxHeader session={selectedObj} />,
          attended: () => <SessionMailboxHeader session={selectedObj} />,
          missed: () => <SessionMailboxHeader session={selectedObj} />,
        }}
        modelOptions={[
          { label: 'All Attendees', value: 'all' },
          { label: 'Attendees Attended', value: 'attended' },
          { label: 'Attendees Missed', value: 'missed' },
        ]}
        endpoints={{
          all: {
            endpoint: `onboarding-attendees`,
            urlParams: {
              session_id: selectedObj.id,
            },
          },
          attended: {
            endpoint: `onboarding-attendees`,
            urlParams: {
              session_id: selectedObj.id,
              stages: JSON.stringify([
                'ONBOARDING_ATTENDED',
                'VERIFYING_SUBMISSIONS_PENDING',
                'VERIFYING_SUBMISSIONS_PARTIAL_APPROVED',
                'VERIFYING_SUBMISSIONS_FULLY_APPROVED',
                'VERIFYING_SUBMISSIONS_PAID',
                'ONBOARDING_COMPLETE',
              ]),
            },
          },
          missed: {
            endpoint: `onboarding-attendees`,
            urlParams: {
              session_id: selectedObj.id,
              stages: JSON.stringify(['ONBOARDING_MISSED']),
            },
          },
        }}
        modelNames={{
          all: 'Attendee',
          attended: 'Attendee',
          missed: 'Attendee',
        }}
        titleKeys={{
          all: 'full_name',
          attended: 'full_name',
          missed: 'full_name',
        }}
        descKeys={{
          all: 'last_follow_up_date',
          attended: 'last_follow_up_date',
          missed: 'last_follow_up_date',
        }}
        formatText={{
          last_follow_up_date: t =>
            `Last follow up: ${t ? humanDatetime(t) : 'Never'}`,
        }}
        PanelChildren={AttendeePanel}
      />
    );
  }

  if (
    selectedKey === 'myScreenshotsToReview' ||
    selectedKey.includes('hostsScreenshotsToReview')
  ) {
    return (
      <ScreenshotPanel
        progress={props.selectedObj}
        clearSelected={props.clearSelected}
      />
    );
  }

  // TODO: the selectedObj here is an OnboardingAttendeeProgress
  if (selectedKey === 'myNeedsHelp') {
    return <BookNeedHelpPanel progress={selectedObj} />;
  }

  return (
    <AttendeePanel attendee={props.selectedObj} urlParams={props.urlParams} />
  );
}

function ScreenshotPanel(props) {
  const reduxProps = useSelector(state => ({
    user: state.authReducer.user,
  }));
  const { user } = reduxProps;

  const { progress } = props;

  const queryClient = useQueryClient();

  return (
    <ScreenshotViewer
      progress={progress}
      attendee_id={progress.attendee}
      containerStyle={{ padding: 'var(--space-md)' }}
      onChangeCallback={() => {
        queryClient.invalidateQueries({
          queryKey: [
            {
              endpoint: 'onboarding-attendee-progresses',
              urlParams: {
                current_steps: JSON.stringify(['PENDING_SCREENSHOT_APPROVAL']),
                follow_up_host_ids: JSON.stringify([user.id]),
                sort: JSON.stringify(['-created_at']),
              },
            },
          ],
        });
        if (props.clearSelected) {
          props.clearSelected();
        }
      }}
    />
  );
}

function AttendeePanel(props) {
  const attendee = props.attendee || props.selectedObj;
  const [tab, setTab] = useState('Progress');

  const queryClient = useQueryClient();
  const fetchProgresses = useQuery({
    staleTime: 1000 * 60 * 2,
    queryKey: [
      {
        endpoint: 'onboarding-attendee-progresses',
        urlParams: { attendee_id: attendee.id },
      },
    ],
  });

  const submitAttendee = useMutation({
    mutationKey: 'submit-attendee-verifiy',
    onSuccess: data => {
      queryClient.invalidateQueries({
        queryKey: [
          {
            endpoint: 'onboarding-attendees',
            urlParams: props.urlParams,
          },
        ],
      });
    },
  });

  return (
    <>
      <Row
        style={{
          alignItems: 'flex-start',
          width: '100%',
          marginTop: 'var(--space-sm)',
          gap: 'var(--space-md)',
          padding: '0 var(--space-md)',
        }}
      >
        <Col style={{ flex: 1 }}>
          <table style={{ width: '100%', marginBottom: 'var(--space-md)' }}>
            <tbody>
              <tr>
                <td colSpan={2} style={{ paddingBottom: 'var(--space-xs)' }}>
                  <h4 style={{ margin: 0 }}>
                    <b title={`View ${attendee?.full_name} (${attendee?.id})`}>
                      <Link to={`/staff/attendees?search=${attendee?.id}`}>
                        {attendee?.full_name}
                      </Link>
                    </b>
                  </h4>
                </td>
              </tr>
              <tr>
                <td>
                  <b>Follow Up Host</b>
                </td>
                <td>
                  {attendee.follow_up_host
                    ? attendee?.follow_up_host?.full_name ||
                    attendee?.follow_up_host?.email
                    : '-'}
                </td>
              </tr>
              <tr>
                <td>
                  <b>Last Follow Up Date</b>
                </td>
                <td>
                  {attendee.last_follow_up_date
                    ? humanDatetime(attendee?.last_follow_up_date)
                    : 'Never'}
                </td>
              </tr>
              <tr>
                <td>
                  <b>Last Follow Up By</b>
                </td>
                <td>
                  {attendee.last_follow_up_by
                    ? attendee?.last_follow_up_by
                    : '-'}
                </td>
              </tr>
              <tr>
                <td>
                  <b>Region</b>
                </td>
                <td>
                  {REGION_FLAGS[attendee?.region] && (
                    <img
                      src={REGION_FLAGS[attendee?.region]}
                      alt={attendee?.region}
                      width={32}
                      style={{ verticalAlign: 'middle' }}
                    />
                  )}
                  <span style={{ verticalAlign: 'middle' }}>
                    &nbsp;{attendee?.region}
                  </span>
                </td>
              </tr>
              <tr>
                <td>
                  <b>Contact Email</b>
                </td>
                <td>{attendee?.email}</td>
              </tr>
              <tr>
                <td>
                  <b>Phone Number</b>
                </td>
                <td>{attendee?.phone_number || '-'}</td>
              </tr>
              <tr>
                <td>
                  <b>Target Number Signups</b>
                </td>
                <td>{attendee?.target_num_signups}</td>
              </tr>
              <tr>
                <td>
                  <b>Source</b>
                </td>
                <td>{attendee?.source || '-'}</td>
              </tr>
              <tr>
                <td>
                  <b>Last Airtable sync</b>
                </td>
                <td>
                  {attendee.last_airtable_sync
                    ? humanDatetime(attendee?.last_airtable_sync)
                    : 'Never'}
                </td>
              </tr>
              <tr>
                <td>
                  <b>Stage</b>
                </td>
                <td>
                  <StageDropdown
                    hideLabel
                    attendee={attendee}
                    onChange={opt =>
                      submitAttendee.mutate({
                        endpoint: `onboarding-attendees/${attendee.id}`,
                        body: {
                          stage: opt.value,
                        },
                      })
                    }
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <OnboardingPayouts attendee={attendee} />
          <AuthButton
            containerStyle={{ marginTop: 'var(--space-md)' }}
            btnTheme="borderless"
            isLoading={submitAttendee.isLoading}
            onPress={() =>
              submitAttendee.mutate({
                endpoint: `onboarding-attendees/${attendee.id}`,
                body: {
                  last_follow_up_date: new Date().toISOString(),
                },
              })
            }
          >
            Update last follow up to today
          </AuthButton>
          <AuthButton
            containerStyle={{ marginTop: 'var(--space-md)' }}
            btnTheme="borderless"
            isLoading={submitAttendee.isLoading}
            onPress={() =>
              submitAttendee.mutate({
                endpoint: `onboarding-attendees/${attendee.id}`,
                body: {
                  last_airtable_sync: new Date().toISOString(),
                },
              })
            }
          >
            Sync to Airtable
          </AuthButton>
          {submitAttendee.isError && (
            <p style={{ color: 'var(-color-danger)' }}>
              {submitAttendee.error.message}
            </p>
          )}
        </Col>

        <Col style={{ flex: 1 }}>
          <DisplayToggle
            style={{
              width: '100%',
              marginBottom: 'var(--space-sm)',
              position: 'sticky',
              zIndex: 8,
              top: 12,
            }}
            options={['Progress', 'Payments', 'Sessions']}
            active={tab}
            onPress={opt => setTab(opt)}
          />
          {tab === 'Progress' && (
            <>
              {fetchProgresses.isLoading && (
                <ActivityIndicator
                  size={3}
                  style={{ marginTop: 'var(--space-xl)' }}
                />
              )}
              {fetchProgresses.isSuccess && (
                <ProgressListDisplay
                  attendee_id={attendee.id}
                  progresses={fetchProgresses.data}
                  onSuccessChangeCallback={() => {
                    queryClient.invalidateQueries({
                      queryKey: [
                        {
                          endpoint: `onboarding-payouts`,
                          urlParams: { attendee_id: attendee.id },
                        },
                      ],
                    });
                    queryClient.invalidateQueries({
                      queryKey: [
                        {
                          endpoint: 'onboarding-attendee-progresses',
                          urlParams: {
                            attendee_id: attendee.id,
                          },
                        },
                      ],
                    });
                  }}
                />
              )}
            </>
          )}
          {tab === 'Payments' && <PaymentList attendee_id={attendee.id} />}
          {tab === 'Sessions' && <AppointmentsTab attendee={attendee} />}
        </Col>
      </Row>
    </>
  );
}

function BookNeedHelpPanel({ progress }) {
  const reduxProps = useSelector(state => ({
    allBooksMap: state.authReducer.allBooksMap,
  }));
  const { allBooksMap } = reduxProps;
  const fetchAttendee = useQuery({
    queryKey: [
      {
        endpoint: `onboarding-attendees`,
        objectID: progress?.attendee,
      },
    ],
  });
  return (
    <div style={{ padding: 'var(--space-md)', width: '100%' }}>
      <Row style={{ gap: '0.5rem' }}>
        <img
          style={{ flex: 0, borderRadius: '4px' }}
          width={32}
          height={32}
          src={allBooksMap[progress.book]?.logo}
          alt={allBooksMap[progress.book]?.name}
        />
        <h4 style={{ flex: 1, margin: 0 }}>
          {allBooksMap[progress.book]?.name}
        </h4>
      </Row>
      <p>
        <b>Current Issue:</b> {progress.current_issue || 'unknown'}
      </p>
      <Row style={{ gap: '1rem' }}>
        <table
          style={{
            flex: '1 0 300px',
            border: '1px solid var(--color-text-light)',
            borderRadius: 'var(--std-border-radius)',
            marginTop: 'var(--space-xs)',
            width: '100%',
          }}
          cellSpacing={0}
        >
          <Tbody>
            <tr>
              <td>Attendee ID</td>
              <td>
                <Link
                  to={`/staff/attendees?search=${progress.attendee}`}
                  style={{ color: 'var(--color-primary)' }}
                >
                  {progress.attendee}
                </Link>
              </td>
            </tr>
            <tr>
              <td>Attendee Name</td>
              <td>{fetchAttendee?.data?.attendee?.full_name}</td>
            </tr>
            <tr>
              <td>Attendee Email</td>
              <td>{fetchAttendee?.data?.attendee?.email}</td>
            </tr>
            <tr>
              <td>Attendee Payment email</td>
              <td>{fetchAttendee?.data?.attendee?.payment_email}</td>
            </tr>
            <tr>
              <td>Attendee Phone Number</td>
              <td>{fetchAttendee?.data?.attendee?.phone_number}</td>
            </tr>
          </Tbody>
        </table>
        <table
          style={{
            flex: '1 0 300px',
            border: '1px solid var(--color-text-light)',
            borderRadius: 'var(--std-border-radius)',
            marginTop: 'var(--space-xs)',
            width: '100%',
          }}
          cellSpacing={0}
        >
          <Tbody>
            <tr>
              <td>Already had book</td>
              <td>{progress.already_had_book ? 'Yes' : 'No'}</td>
            </tr>
            <tr>
              <td>Amount bet</td>
              <td>{formatMoney(progress.amount_bet, 'CAD')}</td>
            </tr>
            <tr>
              <td>Amount deposited</td>
              <td>{formatMoney(progress.amount_deposited, 'CAD')}</td>
            </tr>
          </Tbody>
        </table>
      </Row>
      <h6 style={{ marginTop: 'var(--space-sm)' }}>Timeline</h6>
      <div style={{ overflowX: 'auto' }}>
        <table style={{ width: '100%', minWidth: '800px' }}>
          <thead style={{ fontWeight: 'bold' }}>
            <tr>
              <td>#</td>
              <td>Step</td>
              <td>Timestamp</td>
              <td>Session ID</td>
              <td>Staff Edit</td>
            </tr>
          </thead>
          <tbody>
            {progress?.timeline?.map((ev, i) => (
              <tr
                key={`prog-help-${i}-${progress.attendee}`}
                style={{ justifyContent: 'flex-start', gap: '1rem' }}
                title={JSON.stringify(ev)}
              >
                <td>{i + 1}</td>
                <td>
                  {ev?.current_step}
                  {ev.clicked_affiliate_link ? ' (clicked "open account")' : ''}
                </td>
                <td>{humanDatetime(ev?.timestamp)}</td>
                <td>{ev?.session_id || '-'}</td>
                <td>{ev?.staff_edit ? 'Yes' : 'No'}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

function AppointmentsTab({ attendee }) {
  const fetchAppointments = useInfiniteQuery({
    staleTime: 1000 * 60 * 2,
    queryKey: [
      {
        endpoint: 'onboarding-appointments',
        urlParams: { attendee_id: attendee.id },
      },
    ],
    getNextPageParam: (lastPage, allPages) => lastPage.next,
    getPreviousPageParam: (firstPage, allPages) => firstPage.prev,
  });

  const [expanded, setExpanded] = useState({});

  if (fetchAppointments.isLoading) {
    return (
      <ActivityIndicator size={3} style={{ marginTop: 'var(--space-xl)' }} />
    );
  }

  if (fetchAppointments.isError) {
    return <p>{fetchAppointments.error?.message}</p>;
  }

  return (
    <>
      {fetchAppointments.data.pages.map((page, i) => (
        <React.Fragment key={`appt-onb-page-${i}`}>
          {page.results.map(appt => (
            <React.Fragment key={`appt-onb-page-appt-${appt.id}`}>
              <Row
                style={{
                  width: '100%',
                  flex: 0,
                  alignItems: 'center',
                  border: expanded[appt.id]
                    ? '1px solid var(--color-text-light)'
                    : '1px solid transparent',
                  borderTopRightRadius: 'var(--std-border-radius)',
                  borderTopLeftRadius: 'var(--std-border-radius)',
                  padding: 'var(--space-xxs)',
                  gap: 'var(--space-xxs)',
                }}
              >
                <Col style={{ flex: 2, alignItems: 'flex-start' }}>
                  {!appt.onboarding_session ? (
                    <>Session cancelled</>
                  ) : (
                    <>
                      <b>{appt?.onboarding_session?.id}</b>
                      <small>
                        {humanDatetime(appt?.onboarding_session?.date)}
                      </small>
                    </>
                  )}
                </Col>
                <span style={{ flex: 1, textAlign: 'right' }}>
                  {appt.rejected ? (
                    'Rejected'
                  ) : (
                    <>
                      {new Date(appt?.onboarding_session?.date) > new Date() ? (
                        <>
                          {appt?.gcal_respond_status
                            ? GCAL_STATUS_MEANING[appt?.gcal_respond_status]
                            : ''}
                        </>
                      ) : (
                        <>{appt.attended ? 'Attended' : 'Did not attend'}</>
                      )}
                    </>
                  )}
                </span>
                <IconButton
                  colorTheme="text"
                  iconName={expanded[appt.id] ? IoChevronUp : IoChevronDown}
                  onPress={() =>
                    setExpanded({ ...expanded, [appt.id]: !expanded[appt.id] })
                  }
                />
              </Row>
              {expanded[appt.id] && (
                <table
                  style={{
                    width: '100%',
                    border: '1px solid var(--color-text-light)',
                    borderTop: 'none',
                    borderBottomRightRadius: 'var(--std-border-radius)',
                    borderBottomLeftRadius: 'var(--std-border-radius)',
                    padding: 'var(--space-xs) var(--space-xxs)',
                    marginBottom: 'var(--space-xs)',
                  }}
                >
                  <tbody>
                    <tr>
                      <td>Invite Response</td>
                      <td style={{ textAlign: 'right' }}>
                        {appt?.gcal_respond_status
                          ? GCAL_STATUS_MEANING[appt?.gcal_respond_status]
                          : '-'}
                      </td>
                    </tr>
                    <tr>
                      <td>Attended</td>
                      <td style={{ textAlign: 'right' }}>
                        {appt?.attended.toString()}
                      </td>
                    </tr>
                    <tr>
                      <td>Session Link</td>
                      <td style={{ textAlign: 'right' }}>
                        <small>
                          {appt.onboarding_session && (
                            <>
                              {window.location.origin}/session/
                              {appt?.onboarding_session?.id}/{attendee.id}
                            </>
                          )}
                        </small>
                      </td>
                    </tr>
                    <tr>
                      <td>Rejected</td>
                      <td style={{ textAlign: 'right' }}>
                        {appt?.rejected.toString()}
                      </td>
                    </tr>
                  </tbody>
                </table>
              )}
            </React.Fragment>
          ))}
        </React.Fragment>
      ))}
    </>
  );
}
