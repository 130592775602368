import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

// reducers
import authReducer from 'reducers/auth/auth.reducer';
import autosyncReducer from 'reducers/autosync/autosync.reducer';
import publicUserReducer from 'reducers/user/publicUser.reducer';
import searchReducer from 'reducers/search/search.reducer';
import picksReducer from 'reducers/picks/picks.reducer';
import featuredReducer from 'reducers/featured/featured.reducer';
import stripeReducer from 'reducers/stripe/stripe.reducer';
import analysisReducer from 'reducers/analysis/analysis.reducer';
import affiliatesReducer from 'reducers/affiliates/affiliates.reducer';
import competitionsReducer from 'reducers/competitions/competitions.reducer';
import settingsReducer from 'reducers/settings/settings.reducer';
import scheduleReducer from 'reducers/schedule/schedule.reducer';
import oddscompReducer from 'reducers/oddscomp/oddscomp.reducer';
import betslipReducer from 'reducers/betslip/betslip.reducer';
import wagerReducer from './wager/wager.reducer';
import transactionReducer from './transaction/transaction.reducer';
import surveyReducer from 'reducers/survey/survey.reducer';
import sharpsportsReducer from 'reducers/sharpsports/sharpsports.reducer';
import tourReducer from 'reducers/tour/tour.reducer';
import adReducer from 'reducers/ads/ads.reducer';
import gameReducer from 'reducers/game/game.reducer';
import findbetsReducer from 'reducers/findbets/findbets.reducer';
import influencerReducer from 'reducers/influencers/influencers.reducer';

const authPersistConfig = {
  key: 'auth',
  storage,
  whitelist: ['userToken', 'odds_preference', 'user', 'darkModeOn'],
  stateReconciler: autoMergeLevel2,
};

const schedulePersistConfig = {
  key: 'schedule',
  storage,
  whitelist: ['selectedLeagues'],
  stateReconciler: autoMergeLevel2,
};
const oddscompPersistConfig = {
  key: 'oddscomp',
  storage,
  whitelist: ['sizeIndex', 'oddTypes'],
  stateReconciler: autoMergeLevel2,
};

const settingsPersistConfig = {
  key: 'settings',
  storage,
  blacklist: ['gameSortBy', 'gameFilter'],
  stateReconciler: autoMergeLevel2,
};

const tourPersistConfig = {
  key: 'tour',
  storage,
  blacklist: ['tourIsActive'],
  stateReconciler: autoMergeLevel2,
};

export default combineReducers({
  authReducer: persistReducer(authPersistConfig, authReducer),
  autosyncReducer,
  publicUserReducer,
  searchReducer,
  picksReducer,
  featuredReducer,
  analysisReducer,
  affiliatesReducer,
  stripeReducer,
  settingsReducer: persistReducer(settingsPersistConfig, settingsReducer),
  scheduleReducer: persistReducer(schedulePersistConfig, scheduleReducer),
  oddscompReducer: persistReducer(oddscompPersistConfig, oddscompReducer),
  betslipReducer,
  tourReducer: persistReducer(tourPersistConfig, tourReducer),
  wagerReducer,
  transactionReducer,
  sharpsportsReducer,
  surveyReducer,
  competitionsReducer,
  adReducer,
  gameReducer,
  findbetsReducer,
  influencerReducer,
});
