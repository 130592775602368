import React from 'react';
import {
  IoLogoInstagram,
  IoLogoTwitter,
  IoLogoLinkedin,
  IoMdMail,
} from 'react-icons/io';

// components
import { Row, Col } from 'components/generic/Layout';
import { BetstampStamp } from 'components/generic/Logos';

export default function MobileTransactionCancelled(props) {
  return (
    <Col
      style={{
        minHeight: '100vh',
        backgroundColor: 'var(--color-primary)',
        color: 'white',
        justifyContent: 'flex-start',
        textAlign: 'center',
      }}
    >
      <h1>Transction Cancelled</h1>
      <BetstampStamp width={256} />
      <h3>Your transaction has been cancelled.</h3>

      <Row>
        <a href="https://www.instagram.com/betstamp/">
          <IoLogoInstagram
            title="betstamp Instagram"
            style={{
              fontSize: 'var(--text-xl)',
              padding: 'var(--space-sm)',
              color: 'white',
            }}
          />
        </a>
        <a href="https://twitter.com/BetstampApp">
          <IoLogoTwitter
            title="betstamp Twitter"
            style={{
              fontSize: 'var(--text-xl)',
              padding: 'var(--space-sm)',
              color: 'white',
            }}
          />
        </a>
        <a href="https://www.linkedin.com/company/betstamp/">
          <IoLogoLinkedin
            title="betstamp LinkedIn"
            style={{
              fontSize: 'var(--text-xl)',
              padding: 'var(--space-sm)',
              color: 'white',
            }}
          />
        </a>
        <a href="mailto:contact@betstamp.app">
          <IoMdMail
            title="betstamp Email"
            style={{
              fontSize: 'var(--text-xl)',
              padding: 'var(--space-sm)',
              color: 'white',
            }}
          />
        </a>
      </Row>
    </Col>
  );
}
