import { Observable } from 'rxjs';
import 'rxjs/add/operator/exhaustMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import { ajax } from 'rxjs/observable/dom/ajax';
import { BACKEND_API_URL, getHeaders } from 'utils';

import {
  UPDATE_PAYMENT_METHOD,
  updatePaymentSuccess,
  updatePaymentFailure,
} from 'actions';

export default function udpatePaymentMethod(action$, state$) {
  return action$.ofType(UPDATE_PAYMENT_METHOD).exhaustMap(action => {
    let header = { 'Content-Type': 'application/json' };
    if (state$.value.authReducer.userToken) {
      header.Authorization = 'Token ' + state$.value.authReducer.userToken;
    }
    return ajax
      .post(
        BACKEND_API_URL + 'api/purchases/update_payment_method/',
        {
          sub_id: action.sub_id,
          payment_method_id: action.pm_id,
        },
        getHeaders(state$)
      )
      .map(data => updatePaymentSuccess(data))
      .catch(error => Observable.of(updatePaymentFailure(error.xhr)));
  });
}
