import { PLATFORM } from 'utils';

export function getBaseHeaders() {
  const ls = JSON.parse(localStorage.getItem('persist:auth'));
  let userToken = ls?.userToken;
  if (!userToken || userToken === 'null') userToken = null;

  let headers = { PLATFORM: PLATFORM };
  if (userToken) {
    headers.Authorization = 'Token ' + JSON.parse(userToken);
  }
  return headers;
}

export async function handleRes(res) {
  if (!res.ok) {
    if (res.status === 500) throw new Error('We ran into an error');
    let resJson = res;
    try {
      resJson = await res.json();
    } catch (error) {
      if (typeof resJson === 'object') {
        resJson = { message: res.detail || res.message || res.statusText };
      } else {
        resJson = { message: 'We ran into an error' };
      }
    }
    throw new Error(
      resJson?.detail ||
        resJson?.message ||
        res.statusText ||
        resJson.toString()
    );
  }
  return await res.json();
}
