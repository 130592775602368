import React from 'react';
import {
  IoLinkOutline,
  IoCloseCircle,
  IoCheckmarkCircle,
} from 'react-icons/io5';

// utils
import { betLine, toAmericanOdds, roundOdds } from 'utils';

// components
import { BetstampStampInverted, BetlinkStamp } from 'components/generic/Logos';
import { Row, Col } from 'components/generic/Layout';
import TeamLeagueLogo from 'components/TeamLeagueLogo';
import Badge from 'components/generic/Badge';
import CompetitionLogo from 'components/generic/CompetitionLogo';

export default function BetLineLogos(props) {
  const {
    bet,
    large,
    odds_preference,
    // modalVisible,
    showVerifiedStamp,
    showGraded,
    iconSize,
    showPublicBadge,
    showGameIsLive,
    showSyncBadge,
    competition,
  } = props;
  const {
    game,
    // side,
    // type_name,
    is_live,
    // subtype,
    is_verified,
    // linked_bet,
    is_graded,
    result,
    status,
    is_edited,
    is_public,
    is_autosync,
    // is_future,
    season,
  } = bet;

  const betComp = competition ? competition : bet.competition;

  const league = game?.league || season?.league;
  let firstLogo = null;
  let secondLogo = null;
  if (league) {
    firstLogo = decideFirstLogo(props);
    secondLogo = decideSecondLogo(props);
  }

  const compLogo = betComp?.logo;
  const compName = betComp?.name;

  const SHOW_PUBLIC = is_public && showPublicBadge;
  const SHOW_BETLINK = showSyncBadge && is_autosync;
  const SHOW_BETSTAMP =
    !SHOW_BETLINK && is_verified && !is_edited && showVerifiedStamp;

  if (bet.bet_type === 'CUSTOM') {
    return (
      <>
        {bet.game && (
          <Row style={{ alignItems: 'center' }}>
            <TeamLeagueLogo
              iconSize={iconSize ? iconSize : large ? 32 : 22}
              league={league}
              team={firstLogo}
            />
            <small>vs</small>
            <TeamLeagueLogo
              iconSize={iconSize ? iconSize : large ? 32 : 22}
              league={league}
              team={secondLogo}
            />
          </Row>
        )}
        <Row
          style={{
            width: '100%',
            alignItems: 'center',
            justifyContent: 'flex-start',
            ...props.style,
          }}
        >
          {!bet.game && (
            <TeamLeagueLogo
              iconSize={iconSize ? iconSize : large ? 32 : 22}
              league={bet.sports[0]}
            />
          )}
          <b>
            {bet.custom_desc}&nbsp;
            {odds_preference === 0
              ? toAmericanOdds(bet.odds)
              : roundOdds(bet.odds)}
          </b>
          {SHOW_BETSTAMP && (
            <BetstampStampInverted
              title="This wager is betstamp verified"
              width={20}
              height={20}
              style={{
                marginLeft: 'var(--space-xxs)',
              }}
            />
          )}
          {SHOW_BETLINK && (
            <BetlinkStamp
              blue
              style={{
                width: '20px',
                height: '20px',
                margin: 'var(--space-xxxs)',
              }}
            />
          )}
          <PublicBadge wager={bet} showPublicBadge={SHOW_PUBLIC} />
          {compLogo && (
            <CompetitionLogo
              style={{ width: '20px', height: '20px' }}
              compLogo={compLogo}
              compName={compName}
            />
          )}
        </Row>
      </>
    );
  }

  if (bet.bet_type === 'PARLAY') {
    return (
      <Row
        style={{
          width: '100%',
          alignItems: 'center',
          justifyContent: 'flex-start',
          ...props.style,
        }}
      >
        <b>
          {bet.parlay_length}x Parlay&nbsp;
          {odds_preference === 0
            ? toAmericanOdds(bet.odds)
            : roundOdds(bet.odds)}
        </b>
        {SHOW_BETSTAMP && (
          <BetstampStampInverted
            title="This wager is betstamp verified"
            width={20}
            height={20}
            style={{
              marginLeft: 'var(--space-xxs)',
            }}
          />
        )}
        {SHOW_BETLINK && (
          <BetlinkStamp
            blue
            style={{
              width: '20px',
              height: '20px',
              margin: 'var(--space-xxxs)',
            }}
          />
        )}
        <PublicBadge wager={bet} showPublicBadge={SHOW_PUBLIC} />
        {compLogo && (
          <CompetitionLogo
            style={{ width: '20px', height: '20px' }}
            compLogo={compLogo}
            compName={compName}
          />
        )}
      </Row>
    );
  }

  return (
    <Row
      style={{
        width: '100%',
        alignItems: 'center',
        justifyContent: 'flex-start',
        ...props.style,
      }}
    >
      <TeamLeagueLogo
        iconSize={iconSize ? iconSize : large ? 32 : 22}
        displayTeam
        league={league}
        team={firstLogo}
      />

      {secondLogo && (
        <>
          <small>vs</small>
          <TeamLeagueLogo
            iconSize={iconSize ? iconSize : large ? 32 : 22}
            displayTeam
            league={league}
            team={secondLogo}
          />
        </>
      )}
      {large ? (
        <h5 style={{ margin: 0, fontWeight: 'bold' }}>
          {betLine(props.bet, odds_preference)}
        </h5>
      ) : (
        <b>{betLine(props.bet, odds_preference)}</b>
      )}
      {SHOW_BETSTAMP && (
        <BetstampStampInverted
          title="This wager is betstamp verified"
          width={20}
          height={20}
          style={{
            marginLeft: 'var(--space-xxs)',
          }}
        />
      )}
      {is_verified && is_edited && (
        <IoLinkOutline
          style={{
            fontSize: '22px',
            marginLeft: 'var(--space-xxs)',
            color: 'var(--color-primary)',
          }}
        />
      )}
      {is_live && (
        <Badge
          style={{
            color: 'var(--color-success)',
            borderColor: 'var(--color-success)',
            padding: '0 var(--space-xxs)',
            marginLeft: 'var(--space-xxs)',
          }}
          text={'LIVE'}
        />
      )}
      {is_graded && showGraded && (
        <>
          {status === 'WON' ? (
            <IoCheckmarkCircle
              style={{
                paddingLeft: 'var(--space-xxxs)',
                color: 'var(--color-success)',
              }}
              alt="Bet won"
              size={22}
            />
          ) : status === 'PUSH' || status === 'VOID' ? (
            <span> - {status}</span>
          ) : (
            <>
              {result < 0 ? (
                <IoCloseCircle
                  style={{
                    paddingLeft: 'var(--space-xxxs)',
                    color: 'var(--color-danger)',
                  }}
                  alt="Bet lost"
                  size={22}
                />
              ) : (
                <></>
              )}
            </>
          )}
        </>
      )}
      {SHOW_BETLINK && (
        <BetlinkStamp
          blue
          style={{
            width: '20px',
            height: '20px',
            margin: 'var(--space-xxxs)',
          }}
        />
      )}
      {SHOW_PUBLIC && (
        <PublicBadge wager={bet} showPublicBadge={showPublicBadge} />
      )}
      {compLogo && (
        <CompetitionLogo
          style={{ width: '20px', height: '20px' }}
          compLogo={compLogo}
          compName={compName}
        />
      )}
      {showGameIsLive && game?.is_live && game?.is_live_text && (
        <small style={{ color: 'var(--color-success)' }}>
          &nbsp;{game.is_live_text}
        </small>
      )}
    </Row>
  );
}

function PublicBadge() {
  return (
    <Col
      style={{
        flex: '0 0 22px',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '100px',
        width: '20px',
        height: '20px',
        border: '1px solid var(--color-complement)',
        margin: 'var(--space-xxxs)',
      }}
    >
      <small
        style={{
          color: 'var(--color-complement)',
          fontWeight: 'bold',
        }}
        title={'This wager is public'}
      >
        P
      </small>
    </Col>
  );
}

function decideFirstLogo(props) {
  const { bet } = props;
  if (bet.is_future) {
    return bet.side;
  }

  const { game, side, type_name } = bet;
  const { home_team, away_team } = game;

  if (type_name === 'Player Prop') {
    return away_team.name;
  } else if (side && side?.includes(home_team.name)) {
    return home_team.name;
  } else if (side && side?.includes(away_team.name)) {
    return away_team.name;
  } else if (type_name === 'Game Prop') {
    if (bet?.prop_type.includes(away_team.name)) {
      return away_team.name;
    } else if (bet?.prop_type.includes(home_team.name)) {
      return home_team.name;
    } else {
      return away_team.name;
    }
  } else if (type_name === 'Total') {
    return away_team.name;
  } else if (game && !side) {
    return away_team.name;
  } else {
    return null;
  }
}

function decideSecondLogo(props) {
  const { bet } = props;
  if (bet.is_future) {
    return null;
  }

  const { game, side, type_name } = bet;
  const { home_team, away_team } = game;

  if (type_name === 'Player Prop') {
    return home_team.name;
  } else if (
    type_name === 'Total' ||
    (type_name === 'Game Prop' &&
      side &&
      !side?.includes(home_team.name) &&
      !side?.includes(away_team.name) &&
      !bet?.prop_type.includes(home_team.name) &&
      !bet?.prop_type.includes(away_team.name))
  ) {
    return home_team.name;
  } else if (game && !side) {
    return home_team.name;
  } else {
    return null;
  }
}
