import React, { useState, useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  useQuery,
  useInfiniteQuery,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { IoChevronDown, IoChevronUp } from 'react-icons/io5';

// utils
import {
  humanDatetime,
  PROVINCE_OPTIONS,
  STATE_OPTIONS,
  US_STATES,
  REGION_FLAGS,
  formatMoney,
} from 'utils';

// components
import StaffOnboardingBase from 'components/StaffOnboardingBase';
import { Row, Col } from 'components/generic/Layout';
import ActivityIndicator from 'components/generic/ActivityIndicator';
import { AuthButton, IconButton } from 'components/AuthButton';
import Select from 'components/generic/Select';
import { AuthTextInput } from 'components/AuthTextInput';
import CalendarInput from 'components/generic/CalendarInput';
import ProgressListDisplay from 'components/onboarding/ProgressListDisplay';
import PaymentList from 'components/onboarding/PaymentList';
import OnboardingPayouts from 'components/onboarding/OnboardingPayouts';
import GenericAdminFilter from 'components/onboarding/GenericAdminFilter';
import StageDropdown from 'components/onboarding/StageDropdown';
import BooksDropdown from 'components/BooksDropdown';

const MONTH_NAMES = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
const GCAL_STATUS_MEANING = {
  needsAction: 'Awaiting Response',
  declined: 'Declined Invite',
  tentative: 'Tentative',
  accepted: 'Accepted Invite',
};

const STAGE_MAP = {
  ONBOARDING_SCHEDULED: 'Onboarding scheduled',
  ONBOARDING_ATTENDED: 'Onboarding attended',
  ONBOARDING_MISSED: 'Onboarding missed',
  ONBOARDING_DECLINED: 'Onboarding declined',
  VERIFYING_SUBMISSIONS_PENDING: 'Submissions pending',
  VERIFYING_SUBMISSIONS_PARTIAL_APPROVED: 'Submissions partially approved',
  VERIFYING_SUBMISSIONS_FULLY_APPROVED: 'Submissions fully approved',
  VERIFYING_SUBMISSIONS_PAID: 'Submissions paid',
  SUBMISSIONS_DECLINED: 'Submissions declined',
  SUBMISSIONS_ARCHIVED: 'Submissions archived',
  ONBOARDING_COMPLETE: 'Onboarding complete',
};

export default function Attendees(params) {
  return (
    <>
      <StaffOnboardingBase
        endpoint="onboarding-attendees"
        modelName="Attendee"
        headings={[
          'ID',
          'Name',
          'Email',
          'Stage',
          'Region',
          'Referral Code',
          //'Group',
        ]}
        objKeys={[
          'id',
          'full_name',
          'email',
          'stage',
          'region',
          //'signed_up_with_referral_code.code',
          'signed_up_with_referral_code',
        ]}
        formatText={{
          phone_number: t => {
            if (t && t?.length >= 10) {
              return t.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
            }
            return t;
          },
          created_at: humanDatetime,
          modified_at: humanDatetime,
          graph_group_by_date: t => (t ? humanDatetime(t) : '-'),
          fully_completed_on: t => (t ? humanDatetime(t) : '-'),
          submissions_paid_on: t => (t ? humanDatetime(t) : '-'),
          last_follow_up_date: t => (t ? humanDatetime(t) : 'Never'),
          last_airtable_sync: t => (t ? humanDatetime(t) : 'Never'),
          signed_up_with_referral_code: obj =>
            `${obj?.code || '-'} ${obj?.group?.name ? `(${obj?.group?.name})` : ''
            } - ${obj?.code_status || ''}` || '-',
          photo_id: t => (t ? 'View Photo ID' : null),
          stage: t => STAGE_MAP[t],
          follow_up_host: (t, obj) =>
            obj.follow_up_host?.full_name || obj.follow_up_host?.email || '-',
          region: region => (
            <>
              {REGION_FLAGS[region] && (
                <img
                  src={REGION_FLAGS[region]}
                  alt={region}
                  width={32}
                  style={{ verticalAlign: 'middle' }}
                />
              )}
              <span style={{ verticalAlign: 'middle' }}>&nbsp;{region}</span>
            </>
          ),
        }}
        externalLinks={{ photo_id: true }}
        PanelChildren={Panel}
        panelTitleKey="full_name"
        showSearch={true}
        searchPlaceholder="Search by name, email, source, or id"
        FilterChildren={FilterChildren}
        panelTabs={[
          'Details',
          'Edit',
          'Sessions',
          'Progress',
          'Payments',
          'Betlink',
        ]}
        DetailsPanelExtra={DetailsExtra}
      />
    </>
  );
}

function DetailsExtra(props) {
  const attendee = props.selectedObj;
  return (
    <Row style={{ width: '100%' }}>
      <b style={{ flex: 1 }}>reschedule Link</b>
      <span style={{ flex: 2, textAlign: 'right' }}>
        {window.location.origin}/reschedule?attendee_id={attendee.id}
      </span>
    </Row>
  );
}

function FilterChildren(props) {
  const { urlParams } = props;

  const [followUpHostOptions, setFollowUpHostOptions] = useState(null);

  const fetchHosts = useInfiniteQuery({
    staleTime: 1000 * 60 * 2,
    queryKey: [
      {
        endpoint: `onboarding-hosts`,
      },
    ],
    getNextPageParam: (lastPage, allPages) => lastPage.next,
    getPreviousPageParam: (firstPage, allPages) => firstPage.prev,
  });

  const fetchMarketingGroups = useQuery({
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 2,
    queryKey: [
      {
        endpoint: `marketing-groups`,
      },
    ],
  });

  const marketingGroupOptions = useMemo(() => {
    let opts = [];
    if (
      fetchMarketingGroups?.isSuccess &&
      fetchMarketingGroups?.data?.length > 0
    ) {
      for (const group of fetchMarketingGroups.data) {
        opts.push({
          label: group.name,
          value: group.id,
        });
      }
    }
    return opts;
  }, [fetchMarketingGroups?.isSuccess, fetchMarketingGroups?.data]);
  const hostPages = fetchHosts?.data?.pages;
  useEffect(() => {
    let newFollowUpHosts = [];
    if (hostPages) {
      for (const page of hostPages) {
        for (const host of page?.results) {
          newFollowUpHosts.push({
            label: host.full_name || host.email,
            value: host.id,
          });
        }
      }
    }
    setFollowUpHostOptions(newFollowUpHosts);
  }, [hostPages]);

  const defaultFilters = useMemo(() => {
    let filters = {};
    if (urlParams.regions) {
      let d = {};
      for (const r of JSON.parse(urlParams.regions)) {
        d[r] = true;
      }
      filters.regions = d;
    }

    if (urlParams.created_at_after) {
      filters.created_at_after = new Date(urlParams.created_at_after);
    }

    if (urlParams.created_at_before) {
      filters.created_at_before = new Date(urlParams.created_at_before);
    }

    if (urlParams.verification_statuses) {
      let d = {};
      for (const r of JSON.parse(urlParams.verification_statuses)) {
        d[r] = true;
      }
      filters.verification_statuses = d;
    }

    if (urlParams.follow_up_host_ids) {
      let d = {};
      for (const r of JSON.parse(urlParams.follow_up_host_ids)) {
        d[r] = true;
      }
      filters.follow_up_host_ids = d;
    }

    return filters;
  }, [urlParams]);

  return (
    <div style={{ width: '100%' }}>
      <GenericAdminFilter
        filterTitle="Region"
        filterKey="regions"
        options={{
          groups: [
            {
              title: 'Canada',
              options: PROVINCE_OPTIONS.map(p => ({ label: p, value: p })),
            },
            {
              title: 'USA',
              options: US_STATES.map(p => ({ label: p, value: p })),
            },
          ],
        }}
        onFilter={selections => {
          props.onFilter({
            regions: JSON.stringify(selections),
          });
        }}
        defaultDict={defaultFilters.regions}
      />
      <GenericAdminFilter
        filterTitle="Verification Status"
        filterKey="verification_statuses"
        options={{
          groups: [
            {
              title: 'Verification Statuses',
              options: [
                { label: 'Needs Verification', value: 'NEEDS_VERIFICATION' },
                { label: 'Verified', value: 'VERIFIED' },
                { label: 'Invalid', value: 'INVALID' },
              ],
            },
          ],
        }}
        onFilter={selections => {
          props.onFilter({
            verification_statuses: JSON.stringify(selections),
          });
        }}
        defaultDict={defaultFilters.verification_statuses}
      />
      <GenericAdminFilter
        filterTitle="Stage"
        filterKey="stages"
        options={{
          groups: [
            {
              title: 'Stages',
              options: [
                {
                  value: 'ONBOARDING_SCHEDULED',
                  label: STAGE_MAP['ONBOARDING_SCHEDULED'],
                },
                {
                  value: 'ONBOARDING_ATTENDED',
                  label: STAGE_MAP['ONBOARDING_ATTENDED'],
                },
                {
                  value: 'ONBOARDING_MISSED',
                  label: STAGE_MAP['ONBOARDING_MISSED'],
                },
                {
                  value: 'ONBOARDING_DECLINED',
                  label: STAGE_MAP['ONBOARDING_DECLINED'],
                },
                {
                  value: 'VERIFYING_SUBMISSIONS_PENDING',
                  label: STAGE_MAP['VERIFYING_SUBMISSIONS_PENDING'],
                },
                {
                  value: 'VERIFYING_SUBMISSIONS_PARTIAL_APPROVED',
                  label: STAGE_MAP['VERIFYING_SUBMISSIONS_PARTIAL_APPROVED'],
                },
                {
                  value: 'VERIFYING_SUBMISSIONS_FULLY_APPROVED',
                  label: STAGE_MAP['VERIFYING_SUBMISSIONS_FULLY_APPROVED'],
                },
                {
                  value: 'VERIFYING_SUBMISSIONS_PAID',
                  label: STAGE_MAP['VERIFYING_SUBMISSIONS_PAID'],
                },
                {
                  value: 'SUBMISSIONS_DECLINED',
                  label: STAGE_MAP['SUBMISSIONS_DECLINED'],
                },
                {
                  value: 'SUBMISSIONS_ARCHIVED',
                  label: STAGE_MAP['SUBMISSIONS_ARCHIVED'],
                },
                {
                  value: 'ONBOARDING_COMPLETE',
                  label: STAGE_MAP['ONBOARDING_COMPLETE'],
                },
              ],
            },
          ],
        }}
        onFilter={selections => {
          props.onFilter({
            stages: JSON.stringify(selections),
          });
        }}
        defaultDict={defaultFilters.stages}
      />

      {followUpHostOptions?.length > 0 && (
        <GenericAdminFilter
          filterTitle="Follow Up Hosts"
          filterKey="follow_up_host_ids"
          options={{
            groups: [
              {
                title: 'Follow Up Hosts',
                options: followUpHostOptions,
              },
            ],
          }}
          onFilter={selections => {
            props.onFilter({
              follow_up_host_ids: JSON.stringify(selections),
            });
          }}
          defaultDict={defaultFilters.follow_up_host_ids}
        />
      )}
      {marketingGroupOptions?.length > 0 && (
        <GenericAdminFilter
          filterTitle="Group"
          filterKey="marketing_group_ids"
          options={{
            groups: [{ title: 'Group', options: marketingGroupOptions }],
          }}
          onFilter={selections => {
            props.onFilter({ marketing_group_ids: JSON.stringify(selections) });
          }}
          defaultDict={defaultFilters?.marketing_group_ids}
        />
      )}

      <GenericAdminFilter
        filterTitle="Created After Date"
        filterKey="created_at_after"
        type="date"
        defaultDate={defaultFilters.created_at_after}
        onFilter={date => {
          props.onFilter({ created_at_after: date.toISOString() });
        }}
      />
      <GenericAdminFilter
        filterTitle="Created Before Date"
        filterKey="created_at_before"
        type="date"
        defaultDate={defaultFilters.created_at_before}
        onFilter={date => {
          props.onFilter({ created_at_before: date.toISOString() });
        }}
      />
    </div>
  );
}

function Panel(props) {
  const attendee = props.selectedObj;
  const { tab } = props;

  return (
    <>
      {(tab === 'Edit' || attendee.createNew) && (
        <EditTab {...props} attendee={attendee} />
      )}
      {tab === 'Sessions' && <AppointmentsTab {...props} attendee={attendee} />}
      {tab === 'Progress' && <ProgressTab {...props} attendee={attendee} />}
      {tab === 'Payments' && (
        <>
          <OnboardingPayouts attendee={attendee} />
          <PaymentList attendee_id={attendee.id} />
        </>
      )}
      {tab === 'Betlink' && <BetlinkTab {...props} attendee={attendee} />}
    </>
  );
}

function EditTab(props) {
  const { attendee } = props;

  const [followUpHostOptions, setFollowUpHostOptions] = useState(null);

  const fetchHosts = useInfiniteQuery({
    staleTime: 1000 * 60 * 2,
    queryKey: [
      {
        endpoint: `onboarding-hosts`,
      },
    ],
    getNextPageParam: (lastPage, allPages) => lastPage.next,
    getPreviousPageParam: (firstPage, allPages) => firstPage.prev,
  });
  const hostPages = fetchHosts?.data?.pages;
  useEffect(() => {
    let newFollowUpHosts = [];
    if (hostPages) {
      for (const page of hostPages) {
        for (const host of page?.results) {
          newFollowUpHosts.push({
            label: host.full_name || host.email,
            value: host.id,
          });
        }
      }
    }
    setFollowUpHostOptions(newFollowUpHosts);
  }, [hostPages]);

  const [formData, setFormData] = useState(
    attendee.createNew
      ? { verification_status: 'NEEDS_VERIFICATION', existing_books: [] }
      : { ...attendee }
  );
  const [formErrors, setFormErrors] = useState({});

  const disableSubmit = !formData.full_name || !formData.region;

  const queryClient = useQueryClient();
  const submitAttendee = useMutation({
    mutationKey: 'submit-attendee',
    onSuccess: data => {
      queryClient.invalidateQueries({
        queryKey: [
          {
            endpoint: 'onboarding-attendees',
          },
        ],
      });
      queryClient.invalidateQueries({
        queryKey: [
          {
            endpoint: 'onboarding-attendee-progresses',
            urlParams: { attendee_id: attendee.id },
          },
        ],
      });
      props.closePanel();
    },
  });

  const checkRefCodeExists = useQuery({
    refetchOnWindowFocus: false,
    enabled: !!formData.signed_up_with_referral_code,
    queryKey: [
      {
        endpoint: 'referral_codes/exists',
        urlParams: { code: formData.signed_up_with_referral_code?.code },
      },
    ],
  });

  return (
    <>
      <form
        key={attendee?.id || 'new-attendee-form'}
        style={{ width: '100%' }}
        onSubmit={ev => {
          ev.preventDefault();
          const fd = new FormData(ev.target);
          submitAttendee.mutate({
            endpoint: `onboarding-attendees${attendee.createNew ? '' : '/' + attendee.id
              }`,
            body: {
              ...formData,
              date_of_birth: `${fd.get('bday_year')}-${fd.get(
                'bday_month'
              )}-${fd.get('bday_day')}`,
            },
          });
        }}
      >
        <StageDropdown
          attendee={attendee}
          onChange={opt => setFormData({ ...formData, stage: opt.value })}
        />

        <AuthTextInput
          label="Full Name"
          name="full_name"
          defaultValue={formData.full_name}
          onChangeText={t => setFormData({ ...formData, full_name: t })}
        />
        <AuthTextInput
          label="Email"
          name="email"
          defaultValue={formData.email}
          onChangeText={t => setFormData({ ...formData, email: t })}
        />
        <AuthTextInput
          label="Payment Email"
          name="payment_email"
          defaultValue={formData.payment_email}
          onChangeText={t => setFormData({ ...formData, payment_email: t })}
        />
        <Select
          label="Region"
          name="region"
          options={STATE_OPTIONS.map(s => ({ label: s, value: s }))}
          onChange={opt => {
            setFormData({ ...formData, region: opt.value });
          }}
          defaultValue={
            formData.region
              ? { label: formData.region, value: formData.region }
              : undefined
          }
        />

        {followUpHostOptions?.length > 0 && (
          <Select
            label="Follow Up Host"
            name="follow_up_host_id"
            options={followUpHostOptions}
            onChange={opt => {
              setFormData({ ...formData, follow_up_host_id: opt.value });
            }}
            defaultValue={
              formData.follow_up_host
                ? {
                  label:
                    formData?.follow_up_host?.full_name ||
                    formData?.follow_up_host?.email,
                  value: formData?.follow_up_host?.id,
                }
                : undefined
            }
          />
        )}

        <div
          style={{
            marginTop: 'var(--space-sm)',
            border: '1px solid var(--color-text-light)',
            borderRadius: 'var(--std-border-radius)',
            padding: '4px',
          }}
        >
          <span>Date of Birth *</span>
          <Select
            label="Month"
            labelStyle={{ margin: 0 }}
            id="bday_month"
            name="bday_month"
            containerStyle={{ flex: 1 }}
            options={[
              { label: 'January', value: '01' },
              { label: 'Feburary', value: '02' },
              { label: 'March', value: '03' },
              { label: 'April', value: '04' },
              { label: 'May', value: '05' },
              { label: 'June', value: '06' },
              { label: 'July', value: '07' },
              { label: 'August', value: '08' },
              { label: 'September', value: '09' },
              { label: 'October', value: '10' },
              { label: 'November', value: '11' },
              { label: 'December', value: '12' },
            ]}
            onChange={opt => {
              setFormData({ ...formData, bday_month: opt.value });
            }}
            autoComplete="bday-month"
            defaultValue={
              attendee.createNew
                ? undefined
                : attendee.date_of_birth
                  ? {
                    //label:
                    //  MONTH_NAMES[new Date(attendee.date_of_birth).getMonth()],
                    //value: new Date(attendee.date_of_birth).getMonth(),
                    label:
                      MONTH_NAMES[
                      parseInt(attendee.date_of_birth.split('-')[1]) - 1
                      ],
                    value: attendee.date_of_birth.split('-')[1],
                  }
                  : undefined
            }
          />
          <Row
            style={{
              alignItems: 'center',
              gap: 'var(--space-sm)',
              //marginBottom: 'var(--space-md)',
            }}
          >
            <AuthTextInput
              step={1}
              max={31}
              min={1}
              name="bday_day"
              label="Day (ex. 23)"
              maxLength={2}
              placeholder="10"
              containerStyle={{ flex: 1, margin: 0 }}
              onChangeText={text => {
                let d = parseInt(text);

                setFormData({ ...formData, bday_day: text });
                if (isNaN(d)) {
                  setFormErrors({
                    ...formErrors,
                    bday_day: true,
                    bday_day_text: 'Must be a number',
                  });
                  return;
                }

                if (d > 31 || d < 1) {
                  setFormErrors({
                    ...formErrors,
                    bday_day: true,
                    bday_day_text: 'Must be between 1 and 31',
                  });
                } else {
                  setFormErrors({
                    ...formErrors,
                    bday_day: false,
                    bday_day_text: null,
                  });
                }
              }}
              errorText={formErrors.bday_day ? formErrors.bday_day_text : null}
              autoComplete="bday-day"
              defaultValue={
                attendee.createNew
                  ? undefined
                  : attendee.date_of_birth
                    ? attendee.date_of_birth.split('-')[2]
                    : undefined
              }
            />
            <AuthTextInput
              name="bday_year"
              label="Year (ex. 1994)"
              maxLength={4}
              max={2023}
              min={1900}
              placeholder="1983"
              containerStyle={{ flex: 1, margin: 0 }}
              onChangeText={text => {
                let d = parseInt(text);

                setFormData({ ...formData, bday_year: text });
                if (isNaN(d)) {
                  setFormErrors({
                    ...formErrors,
                    bday_year: true,
                    bday_year_text: 'Must be a number',
                  });
                  return;
                }

                if (d > 2023 || d < 1900) {
                  setFormErrors({
                    ...formErrors,
                    bday_year: true,
                    bday_year_text: 'Must be between 1900 and 2023',
                  });
                } else {
                  setFormErrors({
                    ...formErrors,
                    bday_year: false,
                    bday_year_text: null,
                  });
                }
              }}
              errorText={
                formErrors.bday_year ? formErrors.bday_year_text : null
              }
              autoComplete="bday-year"
              defaultValue={
                attendee.createNew
                  ? undefined
                  : attendee.date_of_birth
                    ? attendee.date_of_birth.split('-')[0]
                    : undefined
              }
            />
          </Row>
        </div>

        <AuthTextInput
          label="Street Address"
          name="street_address"
          defaultValue={formData.street_address}
          onChangeText={t => setFormData({ ...formData, street_address: t })}
        />
        <AuthTextInput
          label="Target Number of Signups"
          name="target_num_signups"
          defaultValue={formData.target_num_signups}
          onChangeText={t =>
            setFormData({ ...formData, target_num_signups: t })
          }
        />
        <div style={{ margin: 'var(--space-sm) 0' }}>
          <input
            key={`prevent_automatic_changes_to_target-atde-${attendee.id}`}
            type="checkbox"
            name="prevent_automatic_changes_to_target"
            id="prevent_automatic_changes_to_target"
            defaultChecked={formData.prevent_automatic_changes_to_target}
            onChange={ev => {
              setFormData({
                ...formData,
                prevent_automatic_changes_to_target: ev.target.checked,
              });
            }}
          />
          <label htmlFor="prevent_automatic_changes_to_target">
            Prevent automatic changes to target
          </label>
        </div>
        <Row style={{ marginTop: 'var(--space-md)', alignItems: 'center' }}>
          <Col style={{ flex: 2, alignItems: 'stretch' }}>
            <span>Last follow up date</span>
            <Row
              style={{
                backgroundColor: 'var(--color-fg)',
                border: '1px solid var(--color-text-light)',
                borderRadius: '32px',
                padding: 'var(--space-xxxs)',
              }}
            >
              <CalendarInput
                value={formData.last_follow_up_date}
                onChange={date =>
                  setFormData({ ...formData, last_follow_up_date: date })
                }
              />
            </Row>
          </Col>
          <AuthButton
            type="button"
            btnTheme="borderless"
            containerStyle={{ alignSelf: 'flex-end' }}
            onPress={() =>
              setFormData({ ...formData, last_follow_up_date: new Date() })
            }
          >
            Now
          </AuthButton>
        </Row>
        <AuthTextInput
          label="Source"
          name="source"
          defaultValue={formData.source}
          onChangeText={t => setFormData({ ...formData, source: t })}
        />
        <AuthTextInput
          label="Signed up with referral code"
          name="signed_up_with_referral_code"
          defaultValue={formData.signed_up_with_referral_code?.code}
          errorText={
            !checkRefCodeExists?.isFetched || checkRefCodeExists?.data?.exists
              ? null
              : 'Referral code does not exist'
          }
          onChangeText={t =>
            setFormData({
              ...formData,
              signed_up_with_referral_code: { code: t.toUpperCase() },
            })
          }
        />
        <Select
          label="Verification status"
          name="verification_status"
          options={[
            { label: 'Needs Verification', value: 'NEEDS_VERIFICATION' },
            { label: 'Verified', value: 'VERIFIED' },
            { label: 'Invalid', value: 'INVALID' },
          ]}
          onChange={opt =>
            setFormData({ ...formData, verification_status: opt.value })
          }
          defaultValue={
            attendee.createNew
              ? {
                label: 'Needs Verification',
                value: 'NEEDS_VERIFICATION',
              }
              : {
                label: attendee.verification_status,
                value: attendee.verification_status,
              }
          }
        />
        <BooksDropdown
          admin
          isMulti
          label="Existing Books"
          defaultValue={
            attendee.existing_books
              ? attendee.existing_books.map(b => ({
                label: b,
                value: b,
              }))
              : []
          }
          onChange={opt => {
            let newBooks = [];
            opt.map(item => newBooks.push(item.label));
            setFormData({ ...formData, existing_books: newBooks });
          }}
        />
        <AuthTextInput
          label="Notes"
          name="notes"
          multiline
          defaultValue={formData.notes}
          onChangeText={t => setFormData({ ...formData, notes: t })}
        />
        <AuthButton
          type="submit"
          disabled={disableSubmit}
          isLoading={submitAttendee.isLoading}
          containerStyle={{ marginTop: 'var(--space-xl)' }}
          defaultValue={formData.street_address}
        >
          {attendee.createNew ? 'Create' : 'Edit'} Attendee
        </AuthButton>
        {submitAttendee.isError ? (
          <small style={{ textAlign: 'center', color: 'var(--color-danger)' }}>
            {submitAttendee.error.message}
          </small>
        ) : (
          <br />
        )}
      </form>
    </>
  );
}

function ProgressTab({ attendee }) {
  const fetchProgresses = useQuery({
    staleTime: 1000 * 60 * 2,
    queryKey: [
      {
        endpoint: 'onboarding-attendee-progresses',
        urlParams: { attendee_id: attendee.id },
      },
    ],
  });
  const queryClient = useQueryClient();

  if (fetchProgresses.isLoading) {
    return (
      <ActivityIndicator size={3} style={{ marginTop: 'var(--space-xl)' }} />
    );
  }

  if (fetchProgresses.isError) {
    return <p>{fetchProgresses.error?.message}</p>;
  }

  return (
    <ProgressListDisplay
      attendee_id={attendee.id}
      attendee={attendee}
      progresses={fetchProgresses.data}
      onSuccessChangeCallback={() => {
        queryClient.invalidateQueries({
          queryKey: [
            {
              endpoint: 'onboarding-attendee-progresses',
              urlParams: {
                attendee_id: attendee.id,
              },
            },
          ],
        });
      }}
    />
  );
}

function AppointmentsTab({ attendee }) {
  const fetchAppointments = useInfiniteQuery({
    staleTime: 1000 * 60 * 2,
    queryKey: [
      {
        endpoint: 'onboarding-appointments',
        urlParams: { attendee_id: attendee.id },
      },
    ],
    getNextPageParam: (lastPage, allPages) => lastPage.next,
    getPreviousPageParam: (firstPage, allPages) => firstPage.prev,
  });

  const [expanded, setExpanded] = useState({});

  if (fetchAppointments.isLoading) {
    return (
      <ActivityIndicator size={3} style={{ marginTop: 'var(--space-xl)' }} />
    );
  }

  if (fetchAppointments.isError) {
    return <p>{fetchAppointments.error?.message}</p>;
  }

  return (
    <>
      {fetchAppointments.data.pages.map((page, i) => (
        <React.Fragment key={`appt-onb-page-${i}`}>
          {page.results.map(appt => (
            <React.Fragment key={`appt-onb-page-appt-${appt.id}`}>
              <Row
                style={{
                  width: '100%',
                  flex: 0,
                  alignItems: 'center',
                  border: expanded[appt.id]
                    ? '1px solid var(--color-text-light)'
                    : '1px solid transparent',
                  borderTopRightRadius: 'var(--std-border-radius)',
                  borderTopLeftRadius: 'var(--std-border-radius)',
                  padding: 'var(--space-xxs)',
                  gap: 'var(--space-xxs)',
                }}
              >
                <Col style={{ flex: 2, alignItems: 'flex-start' }}>
                  {!appt.onboarding_session ? (
                    <>Session cancelled</>
                  ) : (
                    <>
                      <b>{appt?.onboarding_session?.id}</b>
                      <small>
                        {humanDatetime(appt?.onboarding_session?.date)}
                      </small>
                    </>
                  )}
                </Col>
                <span style={{ flex: 1, textAlign: 'right' }}>
                  {appt.rejected ? (
                    'Rejected'
                  ) : (
                    <>
                      {new Date(appt?.onboarding_session?.date) > new Date() ? (
                        <>
                          {appt?.gcal_respond_status
                            ? GCAL_STATUS_MEANING[appt?.gcal_respond_status]
                            : ''}
                        </>
                      ) : (
                        <>{appt.attended ? 'Attended' : 'Did not attend'}</>
                      )}
                    </>
                  )}
                </span>
                <IconButton
                  colorTheme="text"
                  iconName={expanded[appt.id] ? IoChevronUp : IoChevronDown}
                  onPress={() =>
                    setExpanded({ ...expanded, [appt.id]: !expanded[appt.id] })
                  }
                />
              </Row>
              {expanded[appt.id] && (
                <table
                  style={{
                    width: '100%',
                    border: '1px solid var(--color-text-light)',
                    borderTop: 'none',
                    borderBottomRightRadius: 'var(--std-border-radius)',
                    borderBottomLeftRadius: 'var(--std-border-radius)',
                    padding: 'var(--space-xs) var(--space-xxs)',
                    marginBottom: 'var(--space-xs)',
                  }}
                >
                  <tbody>
                    <tr>
                      <td>Invite Response</td>
                      <td style={{ textAlign: 'right' }}>
                        {appt?.gcal_respond_status
                          ? GCAL_STATUS_MEANING[appt?.gcal_respond_status]
                          : '-'}
                      </td>
                    </tr>
                    <tr>
                      <td>Attended</td>
                      <td style={{ textAlign: 'right' }}>
                        {appt?.attended.toString()}
                      </td>
                    </tr>
                    <tr>
                      <td>Session Link</td>
                      <td style={{ textAlign: 'right' }}>
                        <small>
                          {appt.onboarding_session && (
                            <>
                              {window.location.origin}/session/
                              {appt?.onboarding_session?.id}/{attendee.id}
                            </>
                          )}
                        </small>
                      </td>
                    </tr>
                    <tr>
                      <td>Rejected</td>
                      <td style={{ textAlign: 'right' }}>
                        {appt?.rejected.toString()}
                      </td>
                    </tr>
                  </tbody>
                </table>
              )}
            </React.Fragment>
          ))}
        </React.Fragment>
      ))}
    </>
  );
}

function BetlinkTab(props) {
  const reduxProps = useSelector(state => ({
    allBooksMap: state.authReducer.allBooksMap,
  }));
  const { allBooksMap } = reduxProps;

  const { attendee } = props;

  const betlinkAccounts = useInfiniteQuery({
    enabled: !!attendee?.betstamp_user,
    staleTime: 1000 * 60 * 2,
    refetchOnWindowFocus: false,
    queryKey: [
      {
        endpoint: `admin-autosync-accounts`,
        urlParams: { user_id: attendee?.betstamp_user },
      },
    ],
    getNextPageParam: (lastPage, allPages) => lastPage.next,
    getPreviousPageParam: (firstPage, allPages) => firstPage.prev,
  });

  if (!attendee.betstamp_user) {
    return (
      <p>
        Attendee has no betstamp account connected to their attendee profile
      </p>
    );
  }

  if (betlinkAccounts?.isLoading) {
    return <ActivityIndicator />;
  }
  const pages = betlinkAccounts?.data?.pages;

  if (pages?.length > 0 && pages[0]?.results?.length === 0) {
    return <p>No betlinked accounts</p>;
  }

  return (
    <>
      {pages.map((page, i) => (
        <React.Fragment key={`betlink-attendee-page-${attendee.id}-${i}`}>
          {page.results?.map((acc, j) => (
            <Row
              key={`betlink-attendee-acc-${acc.id}`}
              style={{
                width: '100%',
                justifyContent: 'flex-start',
                alignItems: 'center',
                gap: 'var(--space-xxs)',
                flex: 0,
                padding: 'var(--space-sm) var(--space-xxs)',
                marginBottom: 'var(--space-xs)',
                borderRadius: '8px',
              }}
            >
              <img
                style={{ borderRadius: 4 }}
                width={32}
                height={32}
                src={allBooksMap[acc.book]?.logo}
                alt={allBooksMap[acc.book]?.name}
              />
              <span style={{ flex: 1 }}>
                {allBooksMap[acc.book]?.name}
                {!!acc.is_preregister ? ' (Preregister)' : null}
              </span>
              <Col style={{ alignItems: 'flex-start' }}>
                <span>Linked On {humanDatetime(acc.created_at, true)}</span>
                {(!!acc.current_balance || acc.current_balance === 0) && (
                  <span>
                    Balance: {formatMoney(acc.current_balance, 'CAD')}
                  </span>
                )}
                {(!!acc.bonus_balance || acc.bonus_balance === 0) && (
                  <span>
                    Bonus Balance: {formatMoney(acc.bonus_balance, 'CAD')}
                  </span>
                )}
              </Col>
            </Row>
          ))}
        </React.Fragment>
      ))}
      {betlinkAccounts.hasNextPage && (
        <AuthButton
          containerStyle={{ marginTop: 'var(--space-lg)' }}
          btnTheme="borderless"
          onPress={() => betlinkAccounts.fetchNextPage()}
        >
          Load More
        </AuthButton>
      )}
    </>
  );
}
