import { Observable } from 'rxjs';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import { ajax } from 'rxjs/observable/dom/ajax';
import { BACKEND_API_URL, getHeaders } from 'utils';

import {
  GET_FOLLOWING,
  getFollowingSuccess,
  getFollowingFailure,
} from 'actions';

export default function getFollowing(action$, state$) {
  return action$.ofType(GET_FOLLOWING).switchMap(() => {
    let url = 'api/users/get_following/';
    return ajax
      .getJSON(BACKEND_API_URL + url, getHeaders(state$))
      .map(data => getFollowingSuccess(data))
      .catch(error => Observable.of(getFollowingFailure(error.xhr)));
  });
}
