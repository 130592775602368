import { Observable } from 'rxjs';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import { ajax } from 'rxjs/observable/dom/ajax';
import { BACKEND_API_URL, getHeaders } from 'utils';

import { GET_FEATURED, getFeaturedSuccess, getFeaturedFailure } from 'actions';

export default function getFeatured(action$, state$) {
  return action$.ofType(GET_FEATURED).switchMap(() => {
    return ajax
      .getJSON(BACKEND_API_URL + `api/featured/`, getHeaders(state$))
      .map(data => getFeaturedSuccess(data))
      .catch(error => Observable.of(getFeaturedFailure(error.xhr)));
  });
}
