export default function genBA(period) {
  return {
    home_ml_book: {
      id: 54,
      name: 'Betway',
      is_affiliate: true,
      generic_link: 'https://betway.com/',
      color: '#666666',
    },
    away_ml_book: {
      id: 1,
      name: 'bet365',
      is_affiliate: true,
      generic_link: 'https://www.bet365.com/',
      color: '#027b5b',
    },
    tie_ml_book: null,
    home_spread_book: {
      id: 7,
      name: 'BetMGM',
      is_affiliate: true,
      generic_link: 'https://sports.betmgm.com/en/sports',
      color: '#173533',
    },
    away_spread_book: {
      id: 7,
      name: 'BetMGM',
      is_affiliate: true,
      generic_link: 'https://sports.betmgm.com/en/sports',
      color: '#173533',
    },
    over_book: {
      id: 3,
      name: 'DraftKings',
      is_affiliate: true,
      generic_link: 'https://www.draftkings.com/',
      color: '#888585',
      min_balance_banner: null,
    },
    under_book: {
      id: 482,
      name: 'WynnBet',
      is_affiliate: false,
      generic_link: 'https://www.wynnbet.com/',
      color: '#0a1837',
      min_balance_banner: null,
    },
    win_pct: {
      home_win_pct: 46,
      away_win_pct: 54,
      tie_win_pct: '',
    },
    created_at: null,
    is_fullgame: true,
    home_ml: 5.55,
    away_ml: 4.72,
    tie_ml: null,
    home_spread: 7.0,
    home_spread_odds: 3.91,
    away_spread: 4.0,
    away_spread_odds: 4.83,
    over_total: 2.5,
    over_odds: 5.84,
    under_total: 6.5,
    under_odds: 4.5,
    period: period,
    is_live: false,
  };
}
