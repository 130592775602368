/**
 * Returns the amount you must risk to win the win amount.
 * @param {Number} win The amount you want to win.
 * @param {Number} odds The odds of the bet.
 * @returns {String} Amount required to risk to obtain win to 2 decimal places.
 */
export default function calculateRiskRequired(win, odds) {
  if (isNaN(win) || isNaN(odds) || (win === null) | (odds === null)) {
    throw new Error('calculateRiskRequired was expecting numbers');
  }
  if (win <= 0) return '0.00';
  return (win / (odds - 1)).toFixed(2);
}
