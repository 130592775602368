/**
 * Calculates the maximum payout for the given odds and stake.
 * @param {Number} stake The amount of money put up
 * @param {Number} odds The odds of the bet in decimal (ex 1.4)
 * @returns {String} Payout if bet wins given stake and odd to 2 decimal places.
 */
export default function calculateBetPayout(stake, odds) {
  if (isNaN(stake) || isNaN(odds) || (stake === null) | (odds === null)) {
    throw new Error(
      'calculateBetPayout was expecting numbers ' + stake + ' ' + odds
    );
  }
  // if (stake <= 0) return '0.00';
  return (stake * (odds - 1)).toFixed(2);
}
