import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useEffectOnce, useUpdateEffect } from 'react-use';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

//utils
import {
  roundOdds,
  toAmericanOdds,
  competitionChoices,
  AUTH_BUTTON_DEFAULT_CONTAINER_STYLE,
} from 'utils';

// components
import {
  Toolbar,
  MainAreaWrapper,
  InnerMainArea,
  Col,
  Row,
} from 'components/generic/Layout';
// import BetSlip from 'components/BetSlip';
import { AuthButton, LinkButton } from 'components/AuthButton';
import ModalWrapper from 'components/generic/ModalWrapper';
import EditBetslipBet from 'components/EditBetslipBet';
import { AuthTextInput } from 'components/AuthTextInput';
import { BetstampStampInverted } from 'components/generic/Logos';
import AddEditTags from 'components/AddEditTags';
import AddEditDescription from 'components/AddEditDescription';
import BooksDropdown from 'components/BooksDropdown';
import CurrencyInput from 'components/generic/CurrencyInput';
import Switch from 'components/generic/Switch';
import Select from 'components/generic/Select';
import DisplayToggle from 'components/generic/DisplayToggle';

// actions
import {
  getUser,
  addTagToAll,
  removeAllBets,
  setParlayValue,
  trackBetslip,
  changeBetslipMode,
  betslipChangeBookParlay,
  reuseBets,
} from 'actions';

const BreakpointCol = styled.div`
  flex: 1;
  display: flex;
  flex-flow: column wrap;
  justify-content: space-around;
  align-items: center;
  @media only screen and (max-width: 1000px) {
    display: none;
  }
`;

export default function EditBetslip() {
  const dispatch = useDispatch();
  const reduxProps = useSelector(state => ({
    user: state.authReducer.user,
    bets: state.betslipReducer.bets,
    mode: state.betslipReducer.mode,
    parlayValues: state.betslipReducer.parlayValues,
    isTrackingBets: state.betslipReducer.isTrackingBets,
    allBooks: state.authReducer.allBooks,
  }));

  const { user, bets, mode, parlayValues, isTrackingBets, allBooks } =
    reduxProps;

  const [showTagsModal, setShowTagsModal] = useState(false);
  const [newTag, setNewTag] = useState(null);

  const [addedTags, setAddedTags] = useState([]);

  let filteredRecentTags = [...new Set([...user.recent_tags])];
  filteredRecentTags = filteredRecentTags.filter(t => !addedTags.includes(t));

  let allBetsTracked = false;
  allBetsTracked = Boolean(bets.find(b => b.tracked));

  let anyBetZeroRisk = false;
  anyBetZeroRisk = Boolean(
    bets.find(
      b =>
        !b.risk_amount ||
        (b.changes?.risk_amount !== undefined && !b.changes?.risk_amount)
    )
  );

  let trackBtnDisabled = true;

  const compChoices = competitionChoices(user.competitions);
  let startingComp = null;
  if (compChoices) {
    startingComp = compChoices[0];
  }
  const [competition, setCompetition] = useState(
    user.competitions && user.competitions.length > 0
      ? user.competitions[startingComp.value]
      : null
  );

  if (mode === 'singles') {
    trackBtnDisabled =
      allBetsTracked ||
      anyBetZeroRisk ||
      bets.length === 0 ||
      bets.find(b => b.changes?.number === '');
  } else if (mode === 'parlay') {
    trackBtnDisabled =
      !parlayValues.isValid ||
      allBetsTracked ||
      anyBetZeroRisk ||
      bets.length < 2 ||
      bets.find(b => b.changes?.number === '');
  }

  // stuff for changing parlay mode, exactly equal to the thing in Games.js
  const findDefaultBook = () => {
    let defaultParlayBook = allBooks[3];
    if (user) {
      for (let x = 0; x < user.books.length; x++) {
        if (!user.books[x].is_custom) {
          defaultParlayBook = user.books[x];
          break;
        }
      }
    }
    return defaultParlayBook;
  };

  useEffectOnce(() => {
    // force get user to get recent tags
    dispatch(getUser());
    dispatch(setParlayValue('competition', competition?.id));
  });

  useUpdateEffect(() => {
    if (mode === 'parlay') {
      let parlayBook;

      // check to see if all the bets on the slip are already at the same book
      let allBetsSameBook = true;
      let currBookID = null;
      for (let i = 0; i < bets.length; i++) {
        if (currBookID === null) currBookID = bets[i].book.id;
        if (currBookID !== bets[i].book.id) allBetsSameBook = false;
      }

      if (allBetsSameBook && currBookID !== null) parlayBook = bets[0].book;
      else parlayBook = findDefaultBook();

      dispatch(setParlayValue('book', parlayBook));

      // dispatch(
      //   getSched(
      //     null, // leagues
      //     showingDate, // date
      //     parlayBook, // book
      //     null // search term
      //   )
      // );

      dispatch(setParlayValue('competition', competition?.id));

      if (bets.length > 0) {
        dispatch(betslipChangeBookParlay(parlayBook));
      }
    }
  }, [mode]);

  return (
    <>
      <ModalWrapper
        modalIsOpen={bets.length === 0}
        dontCloseOnEscapeOrOutsideClick
        modalSize={'small'}
        title={'No Bets'}
      >
        <br />
        <h6>
          There are no bets to edit! Add more bets on the games screen or see
          your bets on the my picks screen.
        </h6>
        <br />
        <Row>
          <LinkButton
            to="/my-picks"
            btnTheme="borderless"
            overrideChildren
            containerStyle={{
              marginTop: 'var(--space-xxxxs)',
              marginBottom: 'var(--space-xs)',
            }}
          >
            Go to My Picks
          </LinkButton>
          <LinkButton
            to="/games"
            btnTheme="borderless"
            overrideChildren
            containerStyle={{
              marginTop: 'var(--space-xxxxs)',
              marginBottom: 'var(--space-xs)',
            }}
          >
            Go to Games
          </LinkButton>
          <LinkButton
            to="/odds-comparison"
            btnTheme="borderless"
            overrideChildren
            containerStyle={{
              marginTop: 'var(--space-xxxxs)',
              marginBottom: 'var(--space-xs)',
            }}
          >
            Go to Odds Comparison
          </LinkButton>
        </Row>
      </ModalWrapper>
      <ModalWrapper
        modalIsOpen={showTagsModal}
        onClose={() => setShowTagsModal(false)}
        onRequestClose={() => setShowTagsModal(false)}
        modalSize={'small'}
        title={'Add Tag To All'}
      >
        {filteredRecentTags.map((t, i) => (
          <AuthButton
            key={`editbetslip-bet-recent-tag-${t}-${i}`}
            containerStyle={{
              maxWidth: 'fit-content',
              margin: 'var(--space-md) var(--space-sm) var(--space-md) 0',
              display: 'inline-flex',
            }}
            onPress={() => {
              if (mode === 'singles') {
                dispatch(addTagToAll(t));
                setAddedTags([...addedTags, t]);
              } else {
                dispatch(setParlayValue('tags', [...parlayValues.tags, t]));
                setAddedTags([...addedTags, t]);
              }
            }}
          >
            {t}
          </AuthButton>
        ))}

        <AuthTextInput
          type="text"
          placeholder={'Type your tag here'}
          onChangeText={newTag => setNewTag(newTag)}
          maxLength={16}
          inputRowStyle={{ backgroundColor: 'var(--color-bg)' }}
        />
        <br />
        <Row>
          <AuthButton
            containerStyle={AUTH_BUTTON_DEFAULT_CONTAINER_STYLE}
            onPress={() => {
              if (mode === 'singles') {
                dispatch(addTagToAll(newTag));
              } else {
                dispatch(
                  setParlayValue('tags', [...parlayValues.tags, newTag])
                );
              }
              setShowTagsModal(false);
            }}
          >
            Add
          </AuthButton>
          <AuthButton
            colorTheme="danger"
            btnTheme="borderless"
            containerStyle={AUTH_BUTTON_DEFAULT_CONTAINER_STYLE}
            onPress={() => setShowTagsModal(false)}
          >
            Cancel
          </AuthButton>
        </Row>
      </ModalWrapper>
      <Col style={{ height: '100vh', flexWrap: 'nowrap', overflow: 'hidden' }}>
        <Toolbar style={{ padding: '0 var(--space-sm)' }}>
          {bets.length > 0 && (
            <>
              <SlipStats />

              {/* placeholder */}
              {mode === 'singles' && <Col />}

              {mode === 'singles' && !allBetsTracked && (
                <Col style={{ alignItems: 'flex-start' }}>
                  <AuthButton
                    themeColor="primary"
                    btnTheme="borderless"
                    onPress={() => setShowTagsModal(true)}
                  >
                    Add Tag To All
                  </AuthButton>
                </Col>
              )}

              <Row style={{ justifyContent: 'center', flexFlow: 'row nowrap' }}>
                {!allBetsTracked && (
                  <DisplayToggle
                    style={{
                      flex: 1,
                      backgroundColor: 'var(--color-bg)',
                      margin: '0 var(--space-xs)',
                    }}
                    options={['singles', 'parlay']}
                    active={mode}
                    onPress={newMode => {
                      if (newMode === 'singles') {
                        dispatch(changeBetslipMode('singles'));
                      } else if (newMode === 'parlay') {
                        let rob =
                          user && user.risk_or_base
                            ? user.risk_or_base
                            : 'base';
                        let bs = user && user.bet_size ? user.bet_size : 10;
                        dispatch(changeBetslipMode('parlay', rob, bs));
                      }
                    }}
                  />
                )}
                {allBetsTracked && bets.length > 0 && (
                  <AuthButton
                    btnTheme="borderless"
                    onPress={() => dispatch(reuseBets())}
                  >
                    Retain Selections
                  </AuthButton>
                )}
              </Row>

              <BreakpointCol>
                <AuthButton
                  colorTheme="danger"
                  onPress={() => {
                    dispatch(removeAllBets());
                    if (mode === 'parlay') {
                      dispatch(changeBetslipMode('singles'));
                    }
                  }}
                >
                  Remove All
                </AuthButton>
              </BreakpointCol>

              <BreakpointCol>
                <AuthButton
                  containerStyle={{
                    marginLeft: 'var(--space-xs)',
                  }}
                  disabled={trackBtnDisabled}
                  isLoading={isTrackingBets}
                  onPress={() => dispatch(trackBetslip())}
                >
                  {`Track ${mode === 'parlay' ? 'Parlay' : 'Betslip'}`}
                </AuthButton>
              </BreakpointCol>
            </>
          )}
        </Toolbar>
        <MainAreaWrapper>
          <InnerMainArea>
            <div
              style={{
                width: '100%',
                height: '100%',
                overflowY: 'auto',
                padding: '0 var(--space-sm)',
                boxSizing: 'border-box',
                paddingBottom: 'var(--space-xxxxl)',
              }}
            >
              <div style={{ maxWidth: '868px', margin: 'auto' }}>
                {allBetsTracked && (
                  <>
                    <br />
                    <h6 style={{ textAlign: 'center' }}>
                      Bets tracked!{' '}
                      <Link to="/games">Return to the games screen</Link>
                    </h6>
                    <br />
                  </>
                )}
                {mode === 'parlay' &&
                  bets.length > 0 &&
                  parlayValues.book &&
                  !allBetsTracked && (
                    <>
                      <Row
                        style={{
                          margin: 'var(--space-md) auto',
                          maxWidth: '600px',
                        }}
                      >
                        <Col>
                          <b>Risk</b>
                          <CurrencyInput
                            commaSeparate
                            containerStyle={{
                              marginRight: 'var(--space-sm)',
                            }}
                            inputStyle={{ textAlign: 'center' }}
                            defaultValue={parlayValues.risk_amount}
                            onChange={dollars => {
                              dispatch(
                                setParlayValue(
                                  'risk_amount',
                                  parseFloat(dollars)
                                )
                              );
                            }}
                          />
                        </Col>
                        <Col>
                          <b>To Win</b>
                          <CurrencyInput
                            commaSeparate
                            containerStyle={{
                              marginLeft: 'var(--space-sm)',
                            }}
                            inputStyle={{ textAlign: 'center' }}
                            defaultValue={parlayValues.win}
                            onChange={dollars => {
                              dispatch(
                                setParlayValue('win', parseFloat(dollars))
                              );
                            }}
                          />
                        </Col>
                      </Row>
                      <Row
                        style={{
                          alignItems: 'center',
                          margin: 'auto',
                          maxWidth: '600px',
                        }}
                      >
                        <b style={{ flex: 0.5, textAlign: 'center' }}>
                          Parlay Book
                        </b>
                        <div style={{ flex: 1 }}>
                          <BooksDropdown
                            value={{
                              value: parlayValues.book.id,
                              label: parlayValues.book.name,
                            }}
                            onChange={selectedOption => {
                              dispatch(
                                betslipChangeBookParlay({
                                  id: selectedOption.value,
                                  name: selectedOption.label,
                                })
                              );
                            }}
                          />
                        </div>
                      </Row>
                      {user.competitions && user.competitions.length > 0 && (
                        <Row
                          style={{
                            alignItems: 'center',
                            margin: 'auto',
                            maxWidth: '600px',
                            marginTop: 'var(--space-xs)',
                          }}
                        >
                          <b style={{ flex: 0.5, textAlign: 'center' }}>
                            Competition
                          </b>
                          <div style={{ flex: 1 }}>
                            <Select
                              defaultValue={{
                                value: competition,
                                label:
                                  competition !== '-1' && competition
                                    ? compChoices[0].label
                                    : '',
                              }}
                              options={compChoices}
                              onChange={opt => {
                                dispatch(
                                  setParlayValue('competition', opt.value)
                                );
                                setCompetition(user.competitions[opt.value]);
                              }}
                            />
                          </div>
                        </Row>
                      )}
                      <Row
                        style={{
                          alignItems: 'center',
                          margin: 'auto',
                          maxWidth: '600px',
                          marginTop: 'var(--space-xs)',
                        }}
                      >
                        <b style={{ flex: 0.5, textAlign: 'center' }}>Public</b>
                        <div style={{ flex: 1 }}>
                          <Switch
                            defaultChecked={true}
                            onChange={input =>
                              dispatch(
                                setParlayValue(
                                  'is_public',
                                  input.target.checked
                                )
                              )
                            }
                          />
                        </div>
                      </Row>
                      <Row
                        style={{
                          alignItems: 'center',
                          margin: 'auto',
                          maxWidth: '600px',
                          marginTop: 'var(--space-xs)',
                        }}
                      >
                        <b style={{ flex: 0.5, textAlign: 'center' }}>
                          Free Bet
                        </b>
                        <div style={{ flex: 1 }}>
                          <Switch
                            defaultChecked={false}
                            onChange={input =>
                              dispatch(
                                setParlayValue(
                                  'is_free_bet',
                                  input.target.checked
                                )
                              )
                            }
                          />
                        </div>
                      </Row>

                      <hr />
                    </>
                  )}

                {/* {bets.length === 0 && (
                  <>
                    <h5
                      style={{
                        textAlign: 'center',
                        marginTop: 'var(--space-xl)',
                      }}
                    >
                      No bets! Go to the <Link to="/games">Games screen</Link>{' '}
                      to add bets to your betslip
                    </h5>
                  </>
                )} */}

                {bets.map((bet, i) => (
                  <EditBetslipBet
                    key={`editbetslip-main-${i}-${bet.tracked}`}
                    i={i}
                    bet={bet}
                  />
                ))}

                {mode === 'parlay' && bets.length > 0 && !allBetsTracked && (
                  <>
                    <br />
                    <AddEditTags
                      existingTags={[]}
                      onTagsChange={tags =>
                        dispatch(setParlayValue('tags', tags))
                      }
                    />
                    <AddEditDescription
                      defaultDescription=""
                      onDescChange={desc =>
                        dispatch(setParlayValue('description', desc))
                      }
                    />
                  </>
                )}

                {bets.length > 0 && (
                  <>
                    <hr />
                    <br />
                    <Row>
                      <SlipStats />
                    </Row>
                    <br />
                    <br />
                    <Row>
                      <AuthButton
                        containerStyle={{
                          maxWidth: '21rem',
                        }}
                        disabled={trackBtnDisabled}
                        isLoading={isTrackingBets}
                        onPress={() => dispatch(trackBetslip())}
                      >
                        {`Track ${mode === 'parlay' ? 'Parlay' : 'Betslip'}`}
                      </AuthButton>
                    </Row>
                    <br />
                    {allBetsTracked && bets.length > 0 && (
                      <Row>
                        <AuthButton
                          containerStyle={{
                            maxWidth: '21rem',
                          }}
                          btnTheme="borderless"
                          onPress={() => dispatch(reuseBets())}
                        >
                          Retain Selections
                        </AuthButton>
                      </Row>
                    )}
                  </>
                )}
              </div>
            </div>
          </InnerMainArea>
          {/* <BetSlip
            onClickBetslipBet={(localID) =>
              document
                .getElementById(`edit-betslip-anchor-${localID}`)
                .scrollIntoView({ behavior: 'smooth' })
            }
          /> */}
        </MainAreaWrapper>
      </Col>
    </>
  );
}

function SlipStats() {
  const reduxProps = useSelector(state => ({
    user: state.authReducer.user,
    bets: state.betslipReducer.bets,
    mode: state.betslipReducer.mode,
    totalRisk: state.betslipReducer.totalRisk,
    totalWin: state.betslipReducer.totalWin,
    parlayValues: state.betslipReducer.parlayValues,
  }));

  const { user, bets, mode, totalRisk, totalWin, parlayValues } = reduxProps;
  const { odds_preference } = user;
  return (
    <>
      <Col style={{ alignItems: 'flex-start' }}>
        <small style={{ opacity: 0.75, lineHeight: 1, fontWeight: 'bold' }}>
          TOTAL RISK
        </small>
        <span>
          $
          {mode === 'parlay'
            ? parseFloat(parlayValues.risk_amount).toFixed(2)
            : totalRisk.toFixed(2)}
        </span>
      </Col>
      <Col style={{ alignItems: 'flex-start' }}>
        <small style={{ opacity: 0.75, fontWeight: 'bold' }}>TOTAL WIN</small>
        <span>
          $
          {mode === 'parlay'
            ? parseFloat(parlayValues.win).toFixed(2)
            : totalWin.toFixed(2)}
        </span>
      </Col>
      <Col style={{ alignItems: 'flex-start' }}>
        <small style={{ opacity: 0.75, fontWeight: 'bold' }}>BETS</small>
        <span>{bets.length}</span>
      </Col>
      {mode === 'parlay' && (
        <Col style={{ alignItems: 'flex-start' }}>
          <small style={{ opacity: 0.75, fontWeight: 'bold' }}>
            PARLAY ODDS
          </small>
          <span>
            {odds_preference
              ? roundOdds(parlayValues.odds)
              : toAmericanOdds(parlayValues.odds)}
          </span>
        </Col>
      )}
      {mode === 'parlay' && (
        <Col style={{ alignItems: 'flex-start' }}>
          {Boolean(parlayValues.is_verified) && (
            <Row style={{ alignItems: 'center' }}>
              <BetstampStampInverted
                title={'Bet Verified'}
                width={32}
                height={32}
                style={{ flex: 0, padding: '0 var(--space-xxxs)' }}
              />
              <h5
                style={{
                  margin: 0,
                  color: 'var(--color-primary)',
                  fontWeight: 'bold',
                }}
              >
                Verified
              </h5>
            </Row>
          )}
        </Col>
      )}
    </>
  );
}
