import { combineEpics } from 'redux-observable';

import getCompetitions from 'epics/competitions/getCompetitions.epic';
import getCompetition from 'epics/competitions/getCompetition.epic';
import getCompetitionLeaderboard from 'epics/competitions/getCompetitionLeaderboard.epic';

export default combineEpics(
  getCompetitions,
  getCompetition,
  getCompetitionLeaderboard
);
