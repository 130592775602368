export const SET_SCHED = 'SET_SCHED';
export const GET_SCHED = 'GET_SCHED';
export const PRELOAD_SCHED = 'PRELAOD_SCHED';
export const GET_SCHED_SUCCESS = 'GET_SCHED_SUCCESS';
export const GET_SCHED_FAILURE = 'GET_SCHED_FAILURE';
export const GET_SCHED_NEXT = 'GET_SCHED_NEXT';
export const GET_FUTURES_SCHED = 'GET_FUTURES_SCHED';
export const GET_FUTURES_SCHED_SUCCESS = 'GET_FUTURES_SCHED_SUCCESS';
export const GET_FUTURES_SCHED_FAILURE = 'GET_FUTURES_SCHED_FAILURE';
export const GET_FUTURES_SCHED_NEXT = 'GET_FUTURES_SCHED_NEXT';
export const GET_FUTURES_ODDS = 'GET_FUTURES_ODDS';
export const GET_FUTURES_ODDS_SUCCESS = 'GET_FUTURES_ODDS_SUCCESS';
export const GET_FUTURES_ODDS_FAILURE = 'GET_FUTURES_ODDS_FAILURE';
export const FUTURES_ODDS_SEARCH = 'FUTURES_ODDS_SEARCH';

export const setSched = date => ({
  type: SET_SCHED,
  date,
});

export const getSched = (
  leagues,
  date,
  book,
  searchTerm,
  preload,
  refresh,
  exact,
  reset
) => ({
  type: GET_SCHED,
  leagues,
  date,
  book,
  searchTerm,
  preload,
  refresh,
  exact,
  reset,
});

export const getSchedSuccess = (
  payload,
  preload,
  concatGames,
  cacheMetaData
) => ({
  type: GET_SCHED_SUCCESS,
  payload,
  preload,
  concatGames,
  cacheMetaData,
});

export const getSchedFailure = error => ({
  type: GET_SCHED_FAILURE,
  error,
});

export const getSchedNext = () => ({
  type: GET_SCHED_NEXT,
});

// futures schedule
export const getFuturesSched = ({
  leagues,
  season,
  seasonComplete,
  inProgress,
  hasNotStarted,
}) => ({
  type: GET_FUTURES_SCHED,
  leagues,
  season,
  seasonComplete,
  inProgress,
  hasNotStarted,
});

export const getFuturesSchedSuccess = payload => ({
  type: GET_FUTURES_SCHED_SUCCESS,
  payload,
});

export const getFuturesSchedFailure = error => ({
  type: GET_FUTURES_SCHED_FAILURE,
  error,
});

export const getFuturesSchedNext = () => ({
  type: GET_FUTURES_SCHED_NEXT,
});

// futures odds
export const getFuturesOdds = ({
  leagues,
  is_graded,
  season,
  season_complete,
  prop_types,
}) => ({
  type: GET_FUTURES_ODDS,
  leagues,
  is_graded,
  season,
  season_complete,
  prop_types,
});

export const getFuturesOddsSuccess = payload => ({
  type: GET_FUTURES_ODDS_SUCCESS,
  payload,
});

export const getFuturesOddsFailure = error => ({
  type: GET_FUTURES_ODDS_FAILURE,
  error,
});

export const futuresOddsSearch = searchterm => ({
  type: FUTURES_ODDS_SEARCH,
  searchterm,
});
