import {
  GET_TRANSACTIONS,
  GET_TRANSACTIONS_SUCCESS,
  GET_TRANSACTIONS_FAILURE,
  GET_TRANSACTIONS_NEXT,
  GET_TRANSACTION_SUMMARY,
  GET_TRANSACTION_SUMMARY_SUCCESS,
  GET_TRANSACTION_SUMMARY_FAILURE,
  SET_SORT_BY,
  SET_ACCOUNTING_BOOK,
  DELETE_TRANSACTION,
  DELETE_TRANSACTION_SUCCESS,
  DELETE_TRANSACTION_FAILURE,
  RESET_TRANSACTION_EDIT_DEL,
  CREATE_TRANSACTION,
  CREATE_TRANSACTION_SUCCESS,
  CREATE_TRANSACTION_FAILURE,
  UPDATE_TRANSACTION,
  UPDATE_TRANSACTION_SUCCESS,
  UPDATE_TRANSACTION_FAILURE,
} from 'actions';

const initialState = {
  transactionSummary: {},
  isLoadingTransactionSummary: false,
  isLoadingTransactions: false,
  transactions: [],
  loadingTransactionsFailure: null,
  isLoadingTransactionsNext: false,
  transactionsNext: null,
  accountingBook: null,
  isDeletingTransaction: true,
  deleteTransactionsFailure: null,
  deleteTransactionSuccess: null,
  isCreatingTransaction: false,
  createTransactionFailure: null,
  createTransactionSuccess: null,
  isUpdatingTransaction: false,
  updateTransactionFailure: null,
  updateTransactionSuccess: null,
  sortBy: 'total',
};

export default function transactionReducer(state = initialState, action) {
  switch (action.type) {
    case SET_ACCOUNTING_BOOK:
      return Object.assign({}, state, {
        ...state,
        accountingBook: action.book,
      });

    case GET_TRANSACTIONS:
      return Object.assign({}, state, {
        ...state,
        isLoadingTransactions: true,
        loadingTransactionsFailure: null,
        transactions: [],
      });
    case GET_TRANSACTIONS_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        isLoadingTransactions: false,
        loadingTransactionsFailure: null,
        transactions: state.transactions.concat(action.payload.results),
        transactionsNext: action.payload.next,
      });
    case GET_TRANSACTIONS_FAILURE:
      return Object.assign({}, state, {
        ...state,
        isLoadingTransactions: false,
        loadingTransactionsFailure: action.error,
      });
    case GET_TRANSACTIONS_NEXT:
      return Object.assign({}, state, {
        ...state,
        isLoadingTransactionsNext: true,
      });

    case GET_TRANSACTION_SUMMARY:
      return Object.assign({}, state, {
        ...state,
        isLoadingTransactionSummary: true,
        fetchTransactionSummaryError: null,
        sortBy: action.sortBy ? action.sortBy : state.sortBy,
      });
    case GET_TRANSACTION_SUMMARY_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        isLoadingTransactionSummary: false,
        transactionSummary: action.payload,
        fetchTransactionSummaryError: null,
      });
    case GET_TRANSACTION_SUMMARY_FAILURE:
      return Object.assign({}, state, {
        ...state,
        isLoadingTransactionSummary: false,
        fetchTransactionSummaryError: action.payload,
      });
    case SET_SORT_BY:
      return Object.assign({}, state, {
        ...state,
        sortBy: action.sortBy,
      });

    case DELETE_TRANSACTION:
      return Object.assign({}, state, {
        ...state,
        isDeletingTransaction: true,
        deleteTransactionsFailure: null,
        deleteTransactionSuccess: null,
      });
    case DELETE_TRANSACTION_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        isDeletingTransaction: false,
        deleteTransactionsFailure: null,
        deleteTransactionSuccess: true,
        transactions: state.transactions.filter(t => t.id !== action.deletedID),
      });
    case DELETE_TRANSACTION_FAILURE:
      return Object.assign({}, state, {
        ...state,
        isDeletingTransaction: false,
        deleteTransactionsFailure: action.error,
        deleteTransactionSuccess: false,
      });

    case CREATE_TRANSACTION:
      return Object.assign({}, state, {
        ...state,
        isCreatingTransaction: true,
        createTransactionFailure: null,
        createTransactionSuccess: null,
      });
    case CREATE_TRANSACTION_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        isCreatingTransaction: false,
        createTransactionFailure: null,
        createTransactionSuccess: true,
        transactions: state.transactions.concat(action.payload),
      });
    case CREATE_TRANSACTION_FAILURE:
      return Object.assign({}, state, {
        ...state,
        isCreatingTransaction: false,
        createTransactionFailure: action.error,
        createTransactionSuccess: null,
      });

    case UPDATE_TRANSACTION:
      return Object.assign({}, state, {
        ...state,
        isUpdatingTransaction: true,
        updateTransactionFailure: null,
        updateTransactionSuccess: null,
      });
    case UPDATE_TRANSACTION_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        isUpdatingTransaction: false,
        updateTransactionFailure: null,
        updateTransactionSuccess: true,
        transactions: state.transactions.map(t => {
          if (t.id === action.payload.id) {
            return action.payload;
          }
          return t;
        }),
      });
    case UPDATE_TRANSACTION_FAILURE:
      return Object.assign({}, state, {
        ...state,
        isUpdatingTransaction: false,
        updateTransactionFailure: action.error,
        updateTransactionSuccess: true,
      });

    case RESET_TRANSACTION_EDIT_DEL:
      return Object.assign({}, state, {
        ...state,
        isDeletingTransaction: false,
        deleteTransactionsFailure: null,
        deleteTransactionSuccess: null,
        isCreatingTransaction: false,
        createTransactionFailure: null,
        createTransactionSuccess: null,
        isUpdatingTransaction: false,
        updateTransactionFailure: null,
        updateTransactionSuccess: null,
      });

    default:
      return state;
  }
}
