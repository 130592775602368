import React, { useState } from 'react';
import { IoBookOutline, IoPersonCircleSharp } from 'react-icons/io5';
import { BiStore } from 'react-icons/bi';
import { useHistory } from 'react-router-dom';

// mui imports
import { withStyles } from '@material-ui/core/styles';
import { BottomNavigation, BottomNavigationAction } from '@material-ui/core';

// mui stuff
const StyledBottmNav = withStyles({
  root: { backgroundColor: 'var(--color-fg)', color: 'var(--color-text)' },
  selected: { color: 'var(--color-primary)' },
})(props => <BottomNavigation {...props} />);

const StyledBottomNavAction = withStyles({
  root: {
    color: 'var(--color-text)',
    fill: 'var(--color-text)',
    stroke: 'var(--color-text)',
  },
  selected: {
    color: 'var(--color-primary)',
    stroke: 'var(--color-primary)',
    fill: 'var(--color-primary)',
  },
  iconOnly: {
    color: 'var(--color-text)',
    fill: 'var(--color-text)',
  },
})(props => <BottomNavigationAction {...props} />);

export default function BottomNav() {
  const [value, setValue] = useState(0);
  const history = useHistory();
  return (
    <StyledBottmNav
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue);
        history.push(newValue);
      }}
      showLabels
    >
      <StyledBottomNavAction
        label="Games"
        value="/games"
        icon={<IoBookOutline stroke={null} fill={null} />}
      />
      <StyledBottomNavAction
        label="My Picks"
        value="/my-picks"
        icon={<IoBookOutline stroke={null} fill={null} />}
      />
      <StyledBottomNavAction
        label="Marketplace"
        value="/marketplace"
        icon={<BiStore stroke={null} fill={null} />}
      />
      <StyledBottomNavAction
        label="Account"
        value="/settings"
        icon={<IoPersonCircleSharp stroke={null} fill={null} />}
      />
    </StyledBottmNav>
  );
}
