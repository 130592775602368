import { Observable } from 'rxjs';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import { ajax } from 'rxjs/observable/dom/ajax';
import { BACKEND_API_URL, getHeaders } from 'utils';

import {
  GET_TWITTER_ACCESS_TOKEN,
  getTwitterAccessTokenSuccess,
  getTwitterAccessTokenFailure,
} from 'actions';

export default function getTwitterAccessToken(action$, state$) {
  return action$.ofType(GET_TWITTER_ACCESS_TOKEN).switchMap(action => {
    let url =
      BACKEND_API_URL +
      `api/get_twitter_access_token/?oauth_token=${action.token}&oauth_token_secret=${action.token_secret}&oauth_verifier=${action.verifier}`;

    return ajax
      .getJSON(url, getHeaders(state$))
      .map(data => getTwitterAccessTokenSuccess(data))
      .catch(error => Observable.of(getTwitterAccessTokenFailure(error.xhr)));
  });
}
