import React, { useState, useEffect } from 'react';

// utils
import { stringToArray } from 'utils';

import { CALCULATION_OPTIONS } from 'utils/ANALYSIS_CONSTANTS';
import ListOptionBtn from '../ListOptionBtn';
import { AuthButton } from 'components/AuthButton';

export default function Xaxis(props) {
  const [selectedOptions, setSelectedOptions] = useState(
    props.defaultValue ? stringToArray(props.defaultValue) : []
  );

  useEffect(() => {
    setSelectedOptions(
      props.defaultValue ? stringToArray(props.defaultValue) : []
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.defaultValue]);

  return (
    <>
      <h6 style={{ margin: 'var(--space-xs) 0' }}>Calculations</h6>
      <p style={{ margin: 'var(--space-xs) 0' }}>
        Choose which calculations you want to perform
      </p>
      {CALCULATION_OPTIONS.map(item => (
        <ListOptionBtn
          key={`xaxis-list-option-${item}`}
          onClick={() => {
            let newOptions = Array.from(selectedOptions);
            let index = newOptions.findIndex(opt => item === opt);
            if (index > -1) {
              newOptions.splice(index, 1);
            } else {
              newOptions.push(item);
            }

            if (newOptions.length === 0)
              newOptions = Array.from(CALCULATION_OPTIONS);

            setSelectedOptions(newOptions);
          }}
          selected={selectedOptions.filter(opt => opt === item).length > 0}
          text={item}
        />
      ))}
      <br />
      <AuthButton
        onPress={() => {
          let slug = selectedOptions.join(', ');
          let value =
            '[' + selectedOptions.map(opt => '"' + opt + '"').toString() + ']';
          if (value === '[""]' || value === '[]') {
            value = '[]';
            slug = 'All';
          }
          props.onDone({
            filter: 'Xaxis',
            value: value,
            slug: slug,
          });
        }}
      >
        Done
      </AuthButton>
    </>
  );
}
