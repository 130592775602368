import moment from 'moment';

export default function fakeWagersTour(type) {
  if (type === 'playerprop') return playerprop;
  if (type === 'graded') return graded;
  if (type === 'hist1') {
    return {
      ...graded,
      game: {
        ...graded.game,
        date: moment()
          .subtract(1, 'day')
          .set('hour', 10)
          .set('minute', 0)
          .toISOString(true),
      },
    };
  }
  if (type === 'hist2') {
    return {
      ...graded,
      game: {
        away_team: {
          full_name: 'Atlanta Hawks',
          name: 'ATL',
        },
        away_score: 1,
        is_complete: true,
        date: moment()
          .subtract(1, 'day')
          .set('hour', 10)
          .set('minute', 0)
          .toISOString(true),
        home_team: {
          full_name: 'Boston Celtics',
          name: 'BOS',
        },
        home_score: 3,
        id: 365,
        league: 'NBA',
        sport: 'Basketball',
      },
      number: -7,
      type_name: 'Spread',
      side: 'ATL',
      status: 'LOST',
      result: -10,
    };
  }
  if (type === 'hist3') {
    return {
      ...graded,
      game: {
        away_team: {
          full_name: 'Atlanta Braves',
          name: 'ATL',
        },
        away_score: 1,
        date: moment()
          .subtract(1, 'day')
          .set('hour', 17)
          .set('minute', 0)
          .toISOString(true),
        is_complete: true,
        home_team: {
          full_name: 'Houston Astros',
          name: 'HOU',
        },
        home_score: 3,
        id: 365,
        league: 'MLB',
        sport: 'Baseball',
      },
      number: 8.5,
      odds: 1.91,
      type_name: 'Total',
      side: 'Over',
    };
  }
}

const graded = {
  bet_type: 'BET',
  type_name: 'Moneyline',
  book: {
    id: 0,
    name: 'Sportsbook',
  },
  breakdown: '',
  game: {
    away_team: {
      full_name: 'Montreal Canadiens',
      name: 'MTL',
    },
    away_score: 1,
    date: moment().set('hour', 10).set('minute', 0).toISOString(true),
    is_complete: true,
    home_team: {
      full_name: 'Toronto Maple Leafs',
      name: 'TOR',
    },
    home_score: 3,
    id: 355,
    league: 'NHL',
    sport: 'Hockey',
  },
  is_live: false,
  is_public: true,
  is_verified: true,
  is_graded: true,
  status: 'WON',
  number: undefined,
  odds: 1.96,
  period: 'FT',
  result: 10,
  risk_amount: 10.42,
  side: 'TOR',
  tags: [],
};

const playerprop = {
  bet_type: 'BET',
  type_name: 'Player Prop',
  book: {
    id: 0,
    name: 'Sportsbook',
  },
  breakdown: '',
  game: {
    away_team: {
      full_name: 'Los Angeles Rams',
      name: 'LAR',
    },
    away_score: 6,
    date: moment().set('hour', 17).set('minute', 0).toISOString(true),
    is_live: true,
    is_live_text: '● LIVE - 2nd 3:45',
    is_live_period: 2,
    home_team: {
      full_name: 'Cincinnati Bengals',
      name: 'CIN',
    },
    home_score: 7,
    id: 355,
    league: 'NFL',
    sport: 'Football',
  },
  is_live: false,
  is_public: true,
  is_verified: true,
  number: 68.5,
  odds: 1.8333333333,
  period: 'FT',
  player: {
    id: 123,
    name: 'Cam Akers',
  },
  prop_type: 'Rush Yds',
  result: 10,
  risk_amount: 12,
  side: 'Over',
  tags: [],
  latest_odds: { is_graded: false, result: 24 },
};
