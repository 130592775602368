// Ignore eslint == instead of ===
/* eslint eqeqeq: 0 */
import {
  ADD_BET,
  REMOVE_ALL_BETS,
  CHANGE_BETSLIP_MODE,
  REMOVE_ONE_BET,
  EDIT_BETSLIP_BET,
  RISK_BASE_FOR_ALL,
  ADD_TAG_TO_ALL,
  TRACK_BETSLIP,
  TRACK_BETSLIP_SUCCESS,
  TRACK_BETSLIP_FAILURE,
  SET_PARLAY_VALUE,
  BETSLIP_CHANGE_BOOK_PARLAY,
  BETSLIP_CHANGE_BOOK_PARLAY_SUCCESS,
  BETSLIP_CHANGE_BOOK_PARLAY_FAILURE,
  BETSLIP_CHANGE_BOOK_BET,
  BETSLIP_CHANGE_BOOK_BET_SUCCESS,
  BETSLIP_CHANGE_BOOK_BET_FAILURE,
  REUSE_BETS,
  CLEAR_TRACKED_BETS,
  FAKE_TRACK_WAGER,
  PLACE_BET,
  PLACE_BET_SUCCESS,
  PLACE_BET_FAILURE, // in tour actions
} from 'actions';
import {
  calculateBetPayout,
  calculateRiskRequired,
  parlayCompatCheck,
} from 'utils';

const initialState = {
  mode: 'singles',
  bets: [],
  localIDCount: 0,
  selectedOdds: {},
  disabledOdds: {},
  riskOrBaseOverride: null,
  betSizeOverride: null,
  totalRisk: 0,
  totalWin: 0,

  // used for switching between parlays and singles
  betHistory: [],
  selectedOddsHistory: [],

  parlayValues: {
    risk_amount: 100,
    win: 100,
    book: null,
    odds: 0,
    is_verified: false,
    tags: [],
    breakdown: '',
    isValid: false,
    is_public: true,
  },

  // api states
  isTrackingBets: false,
  trackingFailure: null,
  changingBookParlay: false,
  changeBookParlayFailure: null,

  // vars for loading up pre-filled betslips
  isLoadingBetSlip: false,
  isLoadingBetSlipSuccess: null,
  isLoadingBetSlipFailure: null,
  loadedBetslipUrl: null,
};

function calculateTotals(bets) {
  let new_totals = bets.reduce(
    (acc, curr) => {
      const mergedCurr = { ...curr, ...curr.changes };
      acc.risk += parseFloat(mergedCurr.risk_amount);
      acc.win += parseFloat(mergedCurr.result);
      acc.parlayOdds *= mergedCurr.odds;
      acc.parlayVerified &= mergedCurr.is_verified;
      return acc;
    },
    { risk: 0, win: 0, parlayOdds: 1, parlayVerified: true }
  );
  return new_totals;
}

export default function betslipReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_BET:
      const bet = action.data;

      // to determine which specifc odd is selected
      let selectedID = bet.selectedID;

      // to determine which other odds should be disabled (disabled all away ml once any one away ml is selected)
      let disabledID = bet.disabledID;

      // to determine which bets can be parlayed together
      let parlayCompatID = bet.parlayCompatID;

      let new_SO_add = {};
      let new_DO_add = {};

      let new_bets_add = [];

      // calc these here instead of in the function to avoid another
      // loop. more efficient
      let new_TRTW_add = {
        risk: 0,
        win: 0,
        parlayOdds: 1,
        parlayVerified: true,
      };
      if (!state.selectedOdds[selectedID]) {
        new_bets_add = [
          {
            ...bet,
            localID: state.localIDCount,
            selectedID: selectedID,
            disabledID: disabledID,
            parlayCompatID: parlayCompatID,
          },
        ];
        new_SO_add[selectedID] = true;
        new_DO_add[disabledID] = true;
        new_TRTW_add.risk += parseFloat(bet.risk_amount);
        new_TRTW_add.win += parseFloat(bet.result);
        new_TRTW_add.parlayOdds *= bet.odds;
        new_TRTW_add.parlayVerified &= bet.is_verified;
      }

      for (let i = 0; i < state.bets.length; i++) {
        const currbet = state.bets[i];
        if (currbet.disabledID === disabledID || currbet.tracked) continue;
        if (
          state.mode === 'parlay' &&
          currbet.parlayCompatID === parlayCompatID
        ) {
          continue;
        }

        new_bets_add.push(currbet);

        new_SO_add[currbet.selectedID] = true;
        new_DO_add[currbet.disabledID] = true;

        // totals
        const mergedCurr = { ...currbet, ...currbet.changes };
        new_TRTW_add.risk += parseFloat(mergedCurr.risk_amount);
        new_TRTW_add.win += parseFloat(mergedCurr.result);
        new_TRTW_add.parlayOdds *= mergedCurr.odds;
        new_TRTW_add.parlayVerified &=
          mergedCurr.is_verified && bet.period === mergedCurr.period;
        if (
          bet.is_future ||
          (bet.game.id === mergedCurr.game.id &&
            (['Game Prop', 'Player Prop'].includes(mergedCurr.type_name) ||
              ['Game Prop', 'Player Prop'].includes(bet.type_name)))
        ) {
          new_TRTW_add.parlayVerified = false;
        }
      }

      return Object.assign({}, state, {
        ...state,
        bets: new_bets_add,
        localIDCount: state.localIDCount + 1,
        selectedOdds: new_SO_add,
        disabledOdds: new_DO_add,
        totalRisk: new_TRTW_add.risk,
        totalWin: new_TRTW_add.win,
        parlayValues: {
          ...state.parlayValues,
          odds: new_TRTW_add.parlayOdds,
          win: calculateBetPayout(
            state.parlayValues.risk_amount,
            new_TRTW_add.parlayOdds
          ),
          is_verified: new_TRTW_add.parlayVerified,
        },
      });

    case REMOVE_ALL_BETS:
      return Object.assign({}, state, {
        ...state,
        bets: [],
        betHistory: [],
        selectedOddsHistory: [],
        selectedOdds: {},
        disabledOdds: {},
        riskOrBaseOverride: null,
        betSizeOverride: null,
        trackingFailure: null,
      });

    case PLACE_BET:
      return Object.assign({}, state, {
        ...state,
        bets: state.bets,
        isLoadingBetSlip: true,
        isLoadingBetSlipFailure: null,
        isLoadingBetSlipSuccess: null,
      });

    case PLACE_BET_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        isLoadingBetSlipSuccess: true,
        isLoadingBetSlip: false,
        isLoadingBetSlipFailure: null,
        loadedBetslipUrl: action.payload.response.url,
      });

    case PLACE_BET_FAILURE:
      return Object.assign({}, state, {
        ...state,
        isLoadingBetSlipSuccess: null,
        isLoadingBetSlip: false,
        isLoadingBetSlipFailure: action.error,
        loadedBetslipUrl: null,
      });

    case REMOVE_ONE_BET:
      let new_SO_removeOne = { ...state.selectedOdds };
      new_SO_removeOne[action.bet.selectedID] = false;

      let new_DO_removeOne = { ...state.disabledOdds };
      new_DO_removeOne[action.bet.disabledID] = false;

      let new_ROB_bets = state.bets.filter(
        b => action.bet.localID !== b.localID
      );

      let parlayValid_rob = state.parlayValues.isValid;
      if (state.mode === 'parlay') {
        let compatCheck_rob = parlayCompatCheck(new_ROB_bets);
        new_ROB_bets = compatCheck_rob.newBets;
        parlayValid_rob = compatCheck_rob.isValid;
      }

      const new_TRTW_removeOne = calculateTotals(new_ROB_bets);

      return Object.assign({}, state, {
        ...state,
        bets: new_ROB_bets,
        selectedOdds: new_SO_removeOne,
        disabledOdds: new_DO_removeOne,
        totalRisk: new_TRTW_removeOne.risk,
        totalWin: new_TRTW_removeOne.win,
        parlayValues: {
          ...state.parlayValues,
          odds: new_TRTW_removeOne.parlayOdds,
          win: calculateBetPayout(
            state.parlayValues.risk_amount,
            new_TRTW_removeOne.parlayOdds
          ),
          is_verified: new_TRTW_removeOne.parlayVerified,
          isValid: parlayValid_rob,
        },
      });

    case CHANGE_BETSLIP_MODE:
      let new_bets_cm, new_bethist_cm, new_SO_cm, new_SOHist_cm;
      let parlayValid_cm = state.parlayValues.isValid;

      if (state.mode === action.mode) {
        new_bethist_cm = state.betHistory;
        new_bets_cm = [...state.bets];
        new_SOHist_cm = state.selectedOddsHistory;
        new_SO_cm = state.selectedOdds;
      } else if (state.mode === 'singles' && action.mode === 'parlay') {
        new_bethist_cm = [state.bets];
        new_bets_cm = [...state.bets];
        new_SOHist_cm = [state.selectedOdds];
        new_SO_cm = state.selectedOdds; // will get set by change book
        let compatCheck_cm = parlayCompatCheck(new_bets_cm);
        new_bets_cm = compatCheck_cm.newBets;
        parlayValid_cm = compatCheck_cm.isValid;
      } else if (state.mode === 'parlay' && action.mode === 'singles') {
        new_bethist_cm = [];
        new_bets_cm = state.betHistory[0] ? [...state.betHistory[0]] : [];
        new_SO_cm = state.selectedOddsHistory[0]
          ? { ...state.selectedOddsHistory[0] }
          : {};
      }

      let competition = null;
      let book = null;
      if (state.bets && state.bets[0]) {
        competition = state.bets[0]?.competition;
        book = state.bets[0].book;
      }

      const new_TRTW_cm = calculateTotals(new_bets_cm);

      let parlayWin,
        parlayRisk = 0;
      if (state.mode === 'singles' && action.mode === 'parlay') {
        if (new_TRTW_cm.parlayOdds === 1) {
          parlayRisk = action.betSize;
          parlayWin = action.betSize;
        } else {
          if (new_TRTW_cm.parlayOdds >= 2 || action.riskOrBase === 'risk') {
            //set risk
            parlayRisk = action.betSize;
            parlayWin = calculateBetPayout(
              action.betSize,
              new_TRTW_cm.parlayOdds
            );
          } else {
            // set result
            parlayWin = action.betSize;
            parlayRisk = calculateRiskRequired(
              action.betSize,
              new_TRTW_cm.parlayOdds
            );
          }
        }
      }

      return Object.assign({}, state, {
        ...state,
        mode: action.mode,
        bets: new_bets_cm,
        selectedOdds: new_SO_cm,
        betHistory: new_bethist_cm,
        selectedOddsHistory: new_SOHist_cm,
        parlayValues: {
          ...state.parlayValues,
          odds: new_TRTW_cm.parlayOdds,
          risk_amount: parlayRisk,
          win: parlayWin,
          is_verified: new_TRTW_cm.parlayVerified,
          isValid: parlayValid_cm,
          competition: competition,
          book: book,
        },
      });

    case EDIT_BETSLIP_BET:
      const { changes, whatJustChanged, recalc } = action;
      let editedBets = [...state.bets];

      let mergedBet = {
        ...editedBets[action.index],
        ...editedBets[action.index]?.changes,
        ...changes,
      };

      if (whatJustChanged === 'risk_amount') {
        mergedBet.result = calculateBetPayout(
          mergedBet.risk_amount,
          mergedBet.odds
        );
      } else if (whatJustChanged === 'result') {
        mergedBet.risk_amount = calculateRiskRequired(
          mergedBet.result,
          mergedBet.odds
        );
      } else if (whatJustChanged === 'odds' && recalc === 'risk_amount') {
        mergedBet.risk_amount = calculateRiskRequired(
          mergedBet.result,
          mergedBet.odds
        );
      } else if (whatJustChanged === 'odds' && recalc === 'result') {
        mergedBet.result = calculateBetPayout(
          mergedBet.risk_amount,
          mergedBet.odds
        );
      } else if (whatJustChanged === 'reset') {
        mergedBet = {
          odds: editedBets[action.index].odds,
          number: editedBets[action.index].number,
          risk_amount: mergedBet.risk_amount,
          result: calculateBetPayout(
            mergedBet.risk_amount,
            editedBets[action.index].odds
          ),
        };
      }

      if (
        parseFloat(mergedBet.odds) !==
          parseFloat(editedBets[action.index].odds) ||
        mergedBet.number != editedBets[action.index].number || // this is intentionally != (single equal)
        mergedBet.risk_amount > 50000 ||
        mergedBet.period !== editedBets[action.index].period ||
        !editedBets[action.index].is_verified
      ) {
        mergedBet.is_verified = false;
      } else if (
        parseFloat(mergedBet.odds) ===
          parseFloat(editedBets[action.index].odds) &&
        mergedBet.number == editedBets[action.index].number && // this is intentionally == (double equal, NOT triple equal)
        mergedBet.risk_amount <= 50000 &&
        mergedBet.period === editedBets[action.index].period &&
        editedBets[action.index].is_verified
      ) {
        mergedBet.is_verified = true;
      }

      if (whatJustChanged === 'track_original') {
        editedBets[action.index] = {
          ...editedBets[action.index],
          latest_changes: null,
          requires_action: false,
          is_verified: false,
        };
      } else if (whatJustChanged === 'track_verified') {
        editedBets[action.index] = {
          ...editedBets[action.index],
          ...editedBets[action.index].latest_changes,
          latest_changes: null,
          requires_action: false,
          is_verified: true,
        };
        // recalc risk/result, we don't really know what the users settings are
        // at this point, so we'll assume base. In the future we want to fix this
        if (editedBets[action.index].odds >= 2) {
          editedBets[action.index] = {
            ...editedBets[action.index],
            result: calculateBetPayout(
              editedBets[action.index].risk_amount,
              editedBets[action.index].odds
            ),
          };
        } else {
          editedBets[action.index] = {
            ...editedBets[action.index],
            risk_amount: calculateRiskRequired(
              editedBets[action.index].result,
              editedBets[action.index].odds
            ),
          };
        }
      } else {
        editedBets[action.index] = {
          ...editedBets[action.index],
          changes: { ...mergedBet },
        };
        delete editedBets[action.index]?.changes.tracked;
      }

      let new_TRTW_edit = calculateTotals(editedBets);

      return Object.assign({}, state, {
        ...state,
        bets: editedBets,
        totalRisk: new_TRTW_edit.risk,
        totalWin: new_TRTW_edit.win,
        parlayValues: {
          ...state.parlayValues,
          odds: new_TRTW_edit.parlayOdds,
          win: calculateBetPayout(
            state.parlayValues.risk_amount,
            new_TRTW_edit.parlayOdds
          ),
          is_verified: new_TRTW_edit.parlayVerified,
        },
      });

    case RISK_BASE_FOR_ALL:
      let new_bets_rbfa = [...state.bets];
      let new_betsize = action.betSize;
      new_bets_rbfa = state.bets.reduce((acc, curr) => {
        if (curr.odds >= 2 || action.riskOrBase === 'risk') {
          // set risk
          let new_is_verified = true;
          let mergedBet_rba = { ...curr, ...curr.changes };
          if (
            mergedBet_rba.odds !== curr.odds ||
            mergedBet_rba.number !== curr.number ||
            action.betSize > 50000
          ) {
            new_is_verified = false;
          }
          acc.push({
            ...curr,
            changes: {
              ...curr.changes,
              risk_amount: action.betSize,
              result: calculateBetPayout(
                action.betSize,
                curr.changes?.odds ? curr.changes.odds : curr.odds
              ),
              is_verified: new_is_verified,
            },
          });
        } else {
          // set win
          let newRisk = calculateRiskRequired(
            action.betSize,
            curr.changes?.odds ? curr.changes.odds : curr.odds
          );
          let new_is_verified = true;
          let mergedBet_rba = { ...curr, ...curr.changes };
          if (
            mergedBet_rba.odds !== curr.odds ||
            mergedBet_rba.number !== curr.number ||
            newRisk > 50000
          ) {
            new_is_verified = false;
          }
          acc.push({
            ...curr,
            changes: {
              ...curr.changes,
              result: action.betSize,
              risk_amount: newRisk,
              is_verified: new_is_verified,
            },
          });
        }
        return acc;
      }, []);

      let new_TRTW_rbfa = calculateTotals(new_bets_rbfa);

      return Object.assign({}, state, {
        ...state,
        riskOrBaseOverride: action.riskOrBase,
        betSizeOverride: new_betsize,
        bets: new_bets_rbfa,
        totalRisk: new_TRTW_rbfa.risk,
        totalWin: new_TRTW_rbfa.win,
        parlayValues: {
          ...state.parlayValues,
          odds: new_TRTW_rbfa.parlayOdds,
          win: calculateBetPayout(
            state.parlayValues.risk_amount,
            new_TRTW_rbfa.parlayOdds
          ),
        },
      });

    case ADD_TAG_TO_ALL:
      let new_bets_tfa = state.bets.map(bet => ({
        ...bet,
        changes: {
          ...bet.changes,
          tags: bet.changes.tags
            ? bet.changes.tags.concat([action.tag]).slice(0, 5)
            : [action.tag],
        },
      }));

      return Object.assign({}, state, { ...state, bets: new_bets_tfa });

    case TRACK_BETSLIP:
      let untracked_bets = [];
      let new_SO_trackbets = {};
      let new_DO_trackbets = {};

      state.bets.forEach(b => {
        if (!b.tracked) {
          untracked_bets.push({ ...b, errors: null, requires_action: false });
          new_SO_trackbets[b.selectedID] = true;
          new_DO_trackbets[b.disabledID] = true;
        }
      });

      return Object.assign({}, state, {
        ...state,
        isTrackingBets: true,
        trackingFailure: null,
        bets: untracked_bets,
        selectedOdds: new_SO_trackbets,
        disabledOdds: new_DO_trackbets,
      });

    case TRACK_BETSLIP_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        isTrackingBets: false,
        trackingFailure: null,
        bets: action.payload.response.bets,
      });

    case TRACK_BETSLIP_FAILURE:
      return Object.assign({}, state, {
        ...state,
        isTrackingBets: false,
        trackingFailure: action.error,
      });

    case SET_PARLAY_VALUE:
      let new_parlayValues = { ...state.parlayValues };
      new_parlayValues[action.item] = action.value;
      if (action.item === 'risk_amount' && state.bets.length > 0) {
        new_parlayValues['win'] = calculateBetPayout(
          action.value,
          new_parlayValues.odds
        );
      } else if (action.item === 'win' && state.bets.length > 0) {
        new_parlayValues['risk_amount'] = parseFloat(
          calculateRiskRequired(action.value, new_parlayValues.odds)
        );
      }

      return Object.assign({}, state, {
        ...state,
        parlayValues: new_parlayValues,
      });

    case BETSLIP_CHANGE_BOOK_PARLAY:
      return Object.assign({}, state, {
        ...state,
        changingBookParlay: true,
        changeBookParlayFailure: null,
        parlayValues: {
          ...state.parlayValues,
          book: action.book,
        },
      });

    case BETSLIP_CHANGE_BOOK_PARLAY_SUCCESS:
      let new_bets_cbp = [...state.bets];
      const new_SO_cbp = {};

      let equalBets = action.payload.response;
      for (let i = 0; i < equalBets.length; i++) {
        const sid = `${equalBets[i].book ? equalBets[i].book.id : 'n/a'}-${
          equalBets[i].game?.id
        }-${equalBets[i].side}-${equalBets[i].type_name}-false-${
          equalBets[i].is_live
        }-${equalBets[i].prop_type}-${equalBets[i].prop_name}`;
        new_bets_cbp[i] = {
          ...new_bets_cbp[i],
          ...equalBets[i],
          selectedID: sid,
        };
        new_SO_cbp[sid] = true;
      }

      const new_TRTW_cbp = calculateTotals(new_bets_cbp);

      return Object.assign({}, state, {
        ...state,
        changingBookParlay: false,
        bets: new_bets_cbp,
        selectedOdds: new_SO_cbp,
        parlayValues: {
          ...state.parlayValues,
          odds: new_TRTW_cbp.parlayOdds,
          win: calculateBetPayout(
            state.parlayValues.risk_amount,
            new_TRTW_cbp.parlayOdds
          ),
          is_verified: new_TRTW_cbp.parlayVerified,
        },
      });

    case BETSLIP_CHANGE_BOOK_PARLAY_FAILURE:
      return Object.assign({}, state, {
        ...state,
        changingBookParlay: false,
        changeBookParlayFailure: action.error,
      });

    case BETSLIP_CHANGE_BOOK_BET:
      let new_bets_cbb = [...state.bets];
      new_bets_cbb[action.index] = {
        ...new_bets_cbb[action.index],
        changingBook: true,
      };
      return Object.assign({}, state, {
        ...state,
        bets: new_bets_cbb,
      });

    case BETSLIP_CHANGE_BOOK_BET_SUCCESS:
      let new_bets_cbbs = [...state.bets];
      const equalBet = action.payload.response[0];
      const sid_cbb = `${equalBet.book ? equalBet.book.id : 'n/a'}-${
        equalBet.game?.id
      }-${equalBet.side}-${equalBet.type_name}-false-${equalBet.prop_type}--${
        equalBet.prop_name
      }`;

      let new_SO_cbb = { ...state.selectedOdds };
      new_SO_cbb[new_bets_cbbs[action.index].selectedID] = false;
      new_SO_cbb[sid_cbb] = true;

      let realRisk =
        new_bets_cbbs[action.index]?.changes?.risk_amount ||
        new_bets_cbbs[action.index].risk_amount;

      let realOdds = {
        ...new_bets_cbbs[action.index],
        ...new_bets_cbbs[action.index]?.changes,
        ...equalBet,
      };
      realOdds = realOdds.odds;

      new_bets_cbbs[action.index] = {
        ...new_bets_cbbs[action.index],
        ...equalBet,
        changingBook: false,
        selectedID: sid_cbb,
        changes: {
          risk_amount: realRisk,
          result: calculateBetPayout(realRisk, realOdds),
        },
      };

      return Object.assign({}, state, {
        ...state,
        bets: new_bets_cbbs,
        selectedOdds: new_SO_cbb,
      });

    case BETSLIP_CHANGE_BOOK_BET_FAILURE:
      let new_bets_cbbf = [...state.bets];
      new_bets_cbbf[action.index] = {
        ...new_bets_cbbf[action.index],
        changingBook: false,
        changeBookError: action.error,
      };
      return Object.assign({}, state, {
        ...state,
        bets: new_bets_cbbf,
      });

    case REUSE_BETS:
      return Object.assign({}, state, {
        ...state,
        bets: state.bets.map(b => ({ ...b, tracked: false })),
      });

    case CLEAR_TRACKED_BETS:
      let untracked_bets_ctb = [];
      let new_SO_ctb = {};
      let new_DO_ctb = {};

      state.bets.forEach(b => {
        if (!b.tracked) {
          untracked_bets_ctb.push(b);
          new_SO_ctb[b.selectedID] = true;
          new_DO_ctb[b.disabledID] = true;
        }
      });

      return Object.assign({}, state, {
        ...state,
        bets: untracked_bets_ctb,
        selectedOdds: new_SO_ctb,
        disabledOdds: new_DO_ctb,
      });

    case FAKE_TRACK_WAGER:
      return Object.assign({}, state, {
        ...state,
        bets: [{ ...action.wager, tracked: true }],
      });

    default:
      return state;
  }
}
