export default {
  link_to_video: '',
  content: [
    {
      type: 'paragraph',
      text:
        'Transactions are used in conjunction with our sports betting accounting software in order to help you manage multiple sportsbook accounts and your total sports betting bankroll.',
    },
    {
      type: 'paragraph',
      text:
        'To add a transaction, click the blue plus button at the bottom right corner of the accounting screen.',
    },
    {
      type: 'video',
      url:
        'https://betstamp-public.sfo2.cdn.digitaloceanspaces.com/tutorials/transactions/transaction-video.mp4',
    },
    {
      type: 'paragraph',
      text:
        'The "Initial" transaction should only be used once, when you set up the accounting function. Example: If your FanDuel Sportsbook account has a balance of $287.5 when you start using betstamp to track your bets, you can log an “Initial” transaction of $287.5. Then your sportsbook balance will be accurate on the accounting screen.',
    },
    {
      type: 'paragraph',
      text:
        'Once you have set up the initial balance, you wont need to log any transactions unless you have deposited or withdrew money from your Sportsbook. As long as you continue to track your bets and select the correct sportsbook, your balances will automatically update.',
    },
    {
      type: 'paragraph',
      text:
        'If your sportsbook has offered you a bonus you can add this bonus in the transaction screen.',
    },
  ],
};
