import React, { useState } from 'react';
import styled from 'styled-components';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { useEffectOnce } from 'react-use';
import {
  IoTrash,
  IoPencilSharp,
  IoChevronBack,
  IoChevronUp,
  IoChevronDown,
} from 'react-icons/io5';
import QRCode from 'react-qr-code';
import Analytics from 'amplitude/Analytics';

// components
import { Row, Col, Grid } from 'components/generic/Layout';
import ActivityIndicator from 'components/generic/ActivityIndicator';
import { AuthTextInput } from 'components/AuthTextInput';
import { AuthButton, IconButton } from 'components/AuthButton';
import ModalWrapper from 'components/generic/ModalWrapper';
import ConfirmDelete from 'components/modals/ConfirmDelete';

const TwoXLtoLG = styled.p`
  color: var(--color-obj-blue);
  font-size: var(--text-xxl);
  line-height: 1.1;
  @media only screen and (max-width: 1367px) {
    font-size: var(--text-xl);
  }
  @media only screen and (max-width: 625px) {
    font-size: var(--text-lg);
  }
`;

export default function Troubleshooter(props) {
  const {
    admin,
    defaultSelectedGroup,
    attendee_id,
    session_id,
    book_id,
    book_name,
    current_step,
  } = props;

  const [modal, setModal] = useState({ show: false });
  const [issueText, setIssueText] = useState(null);

  const [optionsHistory, setOptionsHistory] = useState([]);
  const [selectedGroup, setSeletedGroup] = useState(
    defaultSelectedGroup || 'REGISTRATION'
  );

  const fetchOptions = useQuery({
    staleTime: admin ? 0 : 1000 * 60 * 10,
    refetchOnWindowFocus: false,
    queryKey: [
      {
        endpoint: `onboarding-troubleshoot-options`,
      },
    ],
    select: data => ({
      list: data,
      map: data.reduce((acc, curr) => {
        acc[curr.id] = curr;
        return acc;
      }, {}),
    }),
    onSuccess: data => {
      if (data?.list?.length > 0 && optionsHistory?.length === 0) {
        const init = data?.list?.find(
          o => o.option_type === 'INITIAL' && o.group === selectedGroup
        );
        setOptionsHistory(init ? [init.id] : []);
      }
    },
  });

  const optionsMap = fetchOptions?.data?.map || {};
  const optionsList = fetchOptions?.data?.list || [];

  useEffectOnce(() => {
    if (optionsHistory.length === 0 && optionsList.length > 0) {
      const init = optionsList?.find(
        o => o.option_type === 'INITIAL' && o.group === selectedGroup
      );
      setOptionsHistory(init ? [init.id] : []);
    }
  }, []);

  let showOptions = [];
  if (fetchOptions.data?.list?.length > 0 && optionsHistory.length > 0) {
    showOptions = [
      optionsMap[optionsHistory[0]]?.option_1,
      optionsMap[optionsHistory[0]]?.option_2,
      optionsMap[optionsHistory[0]]?.option_3,
      optionsMap[optionsHistory[0]]?.option_4,
      optionsMap[optionsHistory[0]]?.option_5,
      optionsMap[optionsHistory[0]]?.option_6,
    ];
  }

  const queryClient = useQueryClient();
  const deleteMut = useMutation({
    mutationKey: `delete-onboarding-troubleshoot-option`,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [
          {
            endpoint: `onboarding-troubleshoot-options`,
          },
        ],
      });
      setModal({ ...modal, show: false });
    },
  });

  const submitForOrdering = useMutation({
    mutationKey: `submit-onboarding-troubleshoot-option-order`,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [
          {
            endpoint: 'onboarding-troubleshoot-options',
          },
        ],
      });
    },
  });

  return (
    <>
      <ModalWrapper
        modalIsOpen={modal.show}
        onClose={() => setModal({ ...modal, show: false })}
        onRequestClose={() => setModal({ ...modal, show: false })}
        title={modal.modalTitle || modal.modalName}
        modalSize={modal.size || modal.delete ? 'small' : 'large'}
      >
        {modal.delete ? (
          <>
            <ConfirmDelete
              onDelete={() => {
                deleteMut.mutate({
                  endpoint: `onboarding-troubleshoot-options/${modal?.option?.id}`,
                  method: 'DELETE',
                });
              }}
              dismissModal={() => setModal({ ...modal, show: false })}
              isDeleting={deleteMut.isLoading}
            >
              Are you sure you want to delete this option?
            </ConfirmDelete>

            {deleteMut.isError && (
              <p style={{ color: 'var(--color-danger)', textAlign: 'center' }}>
                {deleteMut.error?.message}
              </p>
            )}
          </>
        ) : (
          <EditOptionModal
            {...modal.props}
            closeModal={() => setModal({ ...modal, show: false })}
          />
        )}
      </ModalWrapper>

      {admin && (
        <Grid
          style={{
            padding: '0 var(--space-sm)',
            gridTemplateColumns: 'repeat(auto-fill,minmax(256px,1fr))',
          }}
        >
          <AuthButton
            colorTheme={
              optionsMap[optionsHistory[0]]?.group === 'REGISTRATION'
                ? 'inverted'
                : 'primary'
            }
            onPress={() => {
              const opt = optionsList.find(
                o => o.option_type === 'INITIAL' && o.group === 'REGISTRATION'
              );
              setOptionsHistory(opt ? [opt.id] : []);
              setSeletedGroup('REGISTRATION');
            }}
          >
            Registration Troubleshooter
          </AuthButton>
          <AuthButton
            colorTheme={
              optionsMap[optionsHistory[0]]?.group === 'DEPOSIT'
                ? 'inverted'
                : 'primary'
            }
            onPress={() => {
              const opt = optionsList.find(
                o => o.option_type === 'INITIAL' && o.group === 'DEPOSIT'
              );
              setOptionsHistory(opt ? [opt.id] : []);
              setSeletedGroup('DEPOSIT');
            }}
          >
            Deposit Troubleshooter
          </AuthButton>
          <AuthButton
            colorTheme={
              optionsMap[optionsHistory[0]]?.group === 'PLACE_BET'
                ? 'inverted'
                : 'primary'
            }
            onPress={() => {
              const opt = optionsList.find(
                o => o.option_type === 'INITIAL' && o.group === 'PLACE_BET'
              );
              setOptionsHistory(opt ? [opt.id] : []);
              setSeletedGroup('PLACE_BET');
            }}
          >
            Place Bet Troubleshooter
          </AuthButton>
        </Grid>
      )}

      {fetchOptions.isLoading && (
        <ActivityIndicator size={3} style={{ marginTop: '15vw' }} />
      )}
      {fetchOptions.isSuccess && (
        <div
          style={{
            margin: 'var(--space-sm) auto',
            maxWidth: '1024px',
            textAlign: 'center',
          }}
        >
          {admin && optionsHistory.length === 0 ? (
            <>
              <p>Nothing here</p>
              <AuthButton
                onPress={() =>
                  setModal({
                    show: true,
                    modalTitle: `Start ${selectedGroup}`,
                    props: {
                      type: 'createNewInitial',
                      option: {
                        group: selectedGroup,
                        option_type: 'INITIAL',
                        parent_id: null,
                      },
                    },
                  })
                }
              >
                Create {selectedGroup} Troubleshooter
              </AuthButton>
            </>
          ) : (
            <>
              <Row style={{ justifyContent: 'center', alignItems: 'center' }}>
                <TwoXLtoLG
                  style={{
                    margin: 0,
                    color: optionsMap[optionsHistory[0]]?.header_text
                      ? undefined
                      : 'var(--color-danger)',
                  }}
                >
                  {optionsMap[optionsHistory[0]]?.header_text}
                </TwoXLtoLG>
                {admin && (
                  <IconButton
                    iconName={IoPencilSharp}
                    title="Edit Header Text"
                    subtext="Edit/Add Header Text"
                    onPress={() =>
                      setModal({
                        show: true,
                        modalTitle: 'Edit header text',
                        props: {
                          type: 'editHeader',
                          option: optionsMap[optionsHistory[0]],
                        },
                      })
                    }
                  />
                )}
              </Row>
              <Row style={{ justifyContent: 'center', alignItems: 'center' }}>
                <h5
                  style={{
                    fontWeight: 'bold',
                    marginBottom: 0,
                    marginTop: '1vw',
                    color: optionsMap[optionsHistory[0]]?.desc_text
                      ? undefined
                      : 'var(--color-danger)',
                  }}
                >
                  {optionsMap[optionsHistory[0]]?.desc_text}
                </h5>
                {admin && (
                  <IconButton
                    iconName={IoPencilSharp}
                    title="Edit Description Text"
                    subtext="Edit/Add Description Text"
                    onPress={() =>
                      setModal({
                        show: true,
                        modalTitle: 'Edit description text',
                        props: {
                          type: 'editDesc',
                          option: optionsMap[optionsHistory[0]],
                        },
                      })
                    }
                  />
                )}
              </Row>
              {admin && (
                <AuthButton
                  containerStyle={{ marginTop: 'var(--space-md)' }}
                  onPress={() =>
                    setModal({
                      show: true,
                      modalTitle: 'Upload images',
                      props: {
                        type: 'uploadImages',
                        option: optionsMap[optionsHistory[0]],
                      },
                    })
                  }
                >
                  Add/Edit/Remove Images and Image Text
                </AuthButton>
              )}

              {(optionsMap[optionsHistory[0]]?.image_1 ||
                optionsMap[optionsHistory[0]]?.image_2 ||
                optionsMap[optionsHistory[0]]?.image_3) && (
                <Row
                  style={{
                    gap: '1rem',
                    alignItems: 'flex-start',
                    borderBottom: '3px solid rgba(0,0,0,0.25)',
                    paddingBottom: 'var(--space-md)',
                  }}
                >
                  {optionsMap[optionsHistory[0]]?.image_1 && (
                    <ImageDisplayCol
                      option={optionsMap[optionsHistory[0]]}
                      admin={admin}
                      num="1"
                    />
                  )}
                  {optionsMap[optionsHistory[0]]?.image_2 && (
                    <ImageDisplayCol
                      option={optionsMap[optionsHistory[0]]}
                      admin={admin}
                      num="2"
                    />
                  )}
                  {optionsMap[optionsHistory[0]]?.image_3 && (
                    <ImageDisplayCol
                      option={optionsMap[optionsHistory[0]]}
                      admin={admin}
                      num="3"
                    />
                  )}
                </Row>
              )}

              {optionsMap[optionsHistory[0]]?.option_type ===
                'QR_CODE_TO_SIGNUP_TOOL' && (
                <div
                  style={{
                    height: 'auto',
                    margin: '0 auto',
                    maxWidth: 256,
                    width: '100%',
                  }}
                >
                  <QRCode
                    size={256}
                    style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
                    value={window.location.href}
                    viewBox={`0 0 256 256`}
                  />
                </div>
              )}

              <br />
              {showOptions.map((o, i) => (
                <React.Fragment key={`troubleshooter-option-${o?.id}-${i}`}>
                  {o && (
                    <Row
                      style={{
                        alignItems: 'center',
                        maxWidth: admin ? '1024px' : '768px',
                        margin: '0 auto',
                      }}
                    >
                      {admin && (
                        <>
                          <IconButton
                            colorTheme="text"
                            iconName={IoChevronUp}
                            disabled={i === 0 || submitForOrdering.isLoading}
                            title="Move Down"
                            onPress={() => {
                              submitForOrdering.mutate({
                                endpoint: `onboarding-troubleshoot-options/${optionsHistory[0]}`,
                                body: {
                                  move: true,
                                  direction: 'up',
                                  option_number: i + 1,
                                },
                              });
                            }}
                          />
                          <IconButton
                            colorTheme="text"
                            iconName={IoChevronDown}
                            disabled={
                              i === showOptions.filter(o => !!o)?.length - 1 ||
                              submitForOrdering.isLoading
                            }
                            title="Move Up"
                            onPress={() => {
                              submitForOrdering.mutate({
                                endpoint: `onboarding-troubleshoot-options/${optionsHistory[0]}`,
                                body: {
                                  move: true,
                                  direction: 'down',
                                  option_number: i + 1,
                                },
                              });
                            }}
                          />
                        </>
                      )}
                      <AuthButton
                        btnTheme="onb"
                        colorTheme="onbblue2"
                        title={admin ? `Option ${o.id}` : ''}
                        ampData={{
                          event: Analytics.events.TROUBLESHOOTER_CLICK,
                          data: {
                            slide: optionsMap[optionsHistory[0]]?.header_text,
                            optionId: o.id,
                            optionType: o.option_type,
                            option: o.option_text,
                            attendee_id: attendee_id,
                            session_id: session_id,
                            book_name: book_name,
                            book_id: book_id,
                            current_step: current_step,
                          },
                        }}
                        onPress={() => {
                          if (
                            o.option_type === 'REGULAR' ||
                            o.option_type === 'ISSUE_NOT_LISTED' ||
                            o.option_type === 'QR_CODE_TO_SIGNUP_TOOL'
                          ) {
                            setOptionsHistory([o.id].concat(optionsHistory));
                          } else if (
                            o.option_type === 'GO_TO_NEXT_BOOK' &&
                            props.goToNextBook &&
                            !admin
                          ) {
                            props.goToNextBook(o.step);
                          } else if (
                            o.option_type === 'GO_TO_OPTION' &&
                            o.go_to_option
                          ) {
                            setOptionsHistory(
                              [o.go_to_option].concat(optionsHistory)
                            );
                          } else if (
                            o.option_type === 'SET_CURRENT_TO_STEP' &&
                            !admin
                          ) {
                            props.updateCurrentProgress(o.step);
                          }
                        }}
                      >
                        {o.option_text}
                      </AuthButton>
                      {admin && (
                        <>
                          <IconButton
                            iconName={IoPencilSharp}
                            title="Edit Option Text"
                            onPress={() =>
                              setModal({
                                show: true,
                                modalTitle: 'Edit option text',
                                props: {
                                  type: 'editOptionText',
                                  option: optionsMap[o.id],
                                },
                              })
                            }
                          />
                          <IconButton
                            colorTheme="danger"
                            iconName={IoTrash}
                            title="Delete Option"
                            onPress={() =>
                              setModal({
                                show: true,
                                delete: true,
                                option: optionsMap[o.id],
                              })
                            }
                          />
                        </>
                      )}
                    </Row>
                  )}
                </React.Fragment>
              ))}
              <br />
              {admin &&
                optionsMap[optionsHistory[0]]?.option_type !==
                  'ISSUE_NOT_LISTED' &&
                showOptions[5] === null && (
                  <AuthButton
                    btnTheme="borderless"
                    onPress={() =>
                      setModal({
                        show: true,
                        modalTitle: 'New option',
                        props: {
                          type: 'createNew',
                          option: {
                            group: selectedGroup,
                            parent_id:
                              optionsMap[optionsHistory[0]]?.id || null,
                            option_type: optionsMap[optionsHistory[0]]?.id
                              ? 'REGULAR'
                              : 'INITIAL',
                          },
                        },
                      })
                    }
                  >
                    New Option
                  </AuthButton>
                )}
              {optionsMap[optionsHistory[0]]?.option_type ===
                'ISSUE_NOT_LISTED' && (
                <div
                  style={{
                    background: 'var(--color-onb-blue)',
                    padding: 'var(--space-lg) var(--space-md)',
                  }}
                >
                  <AuthTextInput
                    colorTheme={admin ? undefined : 'onb'}
                    multiline
                    disabled={admin}
                    placeholder="Write your issue here"
                    onChangeText={text => setIssueText(text)}
                  />
                  <br />
                  <AuthButton
                    type="submit"
                    btnTheme="onb"
                    colorTheme="inverted"
                    disabled={admin || !issueText}
                    onPress={
                      props.onSubmitIssueText
                        ? () => {
                            props.onSubmitIssueText(issueText);
                          }
                        : () => {}
                    }
                  >
                    Submit Issue
                  </AuthButton>
                </div>
              )}
              {!admin &&
                optionsMap[optionsHistory[0]]?.option_type !==
                  'ISSUE_NOT_LISTED' &&
                !optionsMap[optionsHistory[0]]?.header_text &&
                !optionsMap[optionsHistory[0]]?.desc_text &&
                showOptions.filter(o => !!o).length === 0 && (
                  <p>
                    Sorry, we couldn't load the help options for this issue. Try
                    again later or contact your host.
                  </p>
                )}
              <br />
              {optionsMap[optionsHistory[0]]?.option_type !== 'INITIAL' && (
                <AuthButton
                  containerStyle={{
                    maxWidth: admin ? '1024px' : '768px',
                    margin: 'auto',
                  }}
                  btnTheme="onb"
                  colorTheme={admin ? 'primary' : 'onbbluetext'}
                  leftIcon={IoChevronBack}
                  rightIcon={IoChevronUp}
                  rightIconColor="transparent"
                  title={`Back to ${
                    optionsMap[optionsHistory[1]]?.option_text || 'help'
                  }`}
                  disabled={optionsHistory.length === 0}
                  onPress={() => {
                    let newHist = Array.from(optionsHistory);
                    newHist.splice(0, 1);
                    setOptionsHistory(newHist);
                  }}
                >
                  Back
                </AuthButton>
              )}
            </>
          )}
        </div>
      )}
    </>
  );
}

function EditOptionModal(props) {
  const { type, option } = props;

  const [newOptionType, setNewOptionType] = useState(option.option_type);
  const [searchTerm, setSearchTerm] = useState('REGULAR');
  const [goToOption, setGoToOption] = useState(null);

  const queryClient = useQueryClient();
  const submitOption = useMutation({
    mutationKey: `submit-onboarding-troubleshoot-option`,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [
          {
            endpoint: 'onboarding-troubleshoot-options',
          },
        ],
      });
      props.closeModal();
    },
  });

  const searchOptions = useQuery({
    refetchOnWindowFocus: false,
    queryKey: [
      {
        endpoint: 'onboarding-troubleshoot-options',
        urlParams: { search: searchTerm },
      },
    ],
  });

  return (
    <>
      <form
        onSubmit={ev => {
          ev.preventDefault();
          const fd = new FormData(ev.target);
          if (type.includes('create')) {
            fd.append('group', option.group);
            fd.append('parent_id', option.parent_id);
          }
          if (goToOption && fd.get('option_type') === 'GO_TO_OPTION') {
            fd.append('go_to_option', goToOption);
          }
          if (type === 'createNewInitial') {
            fd.delete('option_type');
            fd.append('option_type', 'INITIAL');
          }

          if (
            fd.get('image_1') &&
            fd.get('remove_image_1') !== 'on' &&
            fd.get('image_1')?.size === 0
          ) {
            fd.delete('image_1');
          }
          if (
            fd.get('image_2') &&
            fd.get('remove_image_2') !== 'on' &&
            fd.get('image_2')?.size === 0
          ) {
            fd.delete('image_2');
          }
          if (
            fd.get('image_3') &&
            fd.get('remove_image_3') !== 'on' &&
            fd.get('image_3')?.size === 0
          ) {
            fd.delete('image_3');
          }

          submitOption.mutate({
            endpoint: `onboarding-troubleshoot-options${
              !type.includes('create') ? '/' + option.id : ''
            }`,
            body: fd,
          });
        }}
      >
        {type === 'createNewInitial' && (
          <>
            <AuthTextInput
              name="header_text"
              placeholder="The header text for this slide"
              onChangeText={() => {}}
              autoFocus
              maxLength={256}
            />
          </>
        )}
        {type !== 'uploadImages' && (
          <AuthTextInput
            multiline
            autoFocus={type !== 'createNewInitial'}
            maxLength={1024}
            name={
              {
                editHeader: 'header_text',
                editDesc: 'desc_text',
                editOptionText: 'option_text',
                createNew: 'option_text',
                createNewInitial: 'desc_text',
              }[type]
            }
            placeholder={
              {
                editHeader: 'The header text for this "slide"',
                editDesc: 'The description/sub text for this "slide"',
                editOptionText: 'The text that goes in the button',
                createNew: 'The text that goes in the button',
                createNewInitial: 'The description/sub text for this "slide"',
              }[type]
            }
            defaultValue={
              {
                editHeader: option?.header_text,
                editDesc: option?.desc_text,
                editOptionText: option?.option_text,
                createNew: option?.option_text,
                createNewInitial: null,
              }[type]
            }
            onChangeText={() => {}}
          />
        )}

        {type === 'uploadImages' && (
          <>
            <ImageInputRow num="1" option={option} />
            <ImageInputRow num="2" option={option} />
            <ImageInputRow num="3" option={option} />
          </>
        )}

        {type !== 'createNewInitial' &&
          type !== 'editHeader' &&
          type !== 'uploadImages' &&
          type !== 'editDesc' && (
            <>
              <div style={{ margin: 'var(--space-sm) 0' }}>
                <span>Option type: </span>
                <select
                  name="option_type"
                  defaultValue={option.option_type}
                  onChange={input => setNewOptionType(input.target.value)}
                >
                  <option value="REGULAR">
                    Regular option (moves forward)
                  </option>
                  <option value="ISSUE_NOT_LISTED">Issue not listed</option>
                  <option value="SET_CURRENT_TO_STEP">
                    Set the current book to a new step
                  </option>
                  <option value="GO_TO_NEXT_BOOK">Start the next book</option>
                  <option value="GO_TO_OPTION">
                    The option redirects to another part of the flow
                  </option>
                  <option value="QR_CODE_TO_SIGNUP_TOOL">
                    Show QR code to attendee's session
                  </option>
                </select>
              </div>
              {newOptionType === 'GO_TO_OPTION' && (
                <b>Currently redirects to option {option.go_to_option}</b>
              )}
            </>
          )}

        {(newOptionType === 'GO_TO_NEXT_BOOK' ||
          newOptionType === 'SET_CURRENT_TO_STEP') && (
          <div style={{ margin: 'var(--space-sm) 0' }}>
            <span>Which step should the current book be moved to? </span>
            <select name="step" defaultValue={option.step}>
              <option value="NOT_STARTED">Not Started</option>
              <option value="DEPOSIT">Deposit</option>
              <option value="PENDING_BOOK_DOC_APPROVAL">
                Pending Book Doc Approval
              </option>
              <option value="PENDING_DEPOSIT">Pending Deposit</option>
              <option value="PLACE_BET">Place Bet</option>
              <option value="PENDING_SCREENSHOT_APPROVAL">
                Pending Screenshot Approval
              </option>
              <option value="SCREENSHOTS_INVALID">Screenshots Invalid</option>
              <option value="FULLY_COMPLETE">Fully Complete</option>
              <option value="NEEDS_HELP">Needs Help</option>
            </select>
          </div>
        )}

        {newOptionType === 'GO_TO_OPTION' && (
          <div style={{ margin: 'var(--space-sm) 0' }}>
            <small>
              This option type is for directing off to some other part of the
              flow. For example, they're in the deposit flow but we determine
              they need to verify their account, we can redirect them off to
              that part of the flow.
            </small>
            <AuthTextInput
              placeholder="Search options by header text"
              onChangeText={text => setSearchTerm(text)}
            />

            {searchOptions?.data?.map(result => (
              <AuthButton
                btnTheme="borderless"
                title={`header: ${result.header_text}\nbutton: ${result.option_text}`}
                onPress={() => setGoToOption(result.id)}
              >
                Option {result.id}: {result.header_text || result.option_text}
              </AuthButton>
            ))}
          </div>
        )}
        <br />

        <AuthButton type="submit" isLoading={submitOption.isLoading}>
          {type.includes('create') ? 'Create Option' : 'Save Changes'}
        </AuthButton>
        {submitOption.isError && (
          <span style={{ color: 'var(--color-danger)' }}>
            {submitOption.error?.message}
          </span>
        )}
      </form>
    </>
  );
}

function ImageInputRow({ num, option }) {
  return (
    <Row style={{ gap: '1rem', alignItems: 'flex-start' }}>
      <Col>
        <AuthTextInput
          containerStyle={{ flex: 1 }}
          label={`Image ${num}`}
          id={`image_${num}`}
          name={`image_${num}`}
          type="file"
          accept="image/png, image/jpeg"
          onChangeText={() => {}}
        />
        <div style={{ alignSelf: 'flex-start' }}>
          <input
            type="checkbox"
            name={`remove_image_${num}`}
            id={`remove_image_${num}`}
          />
          <label htmlFor={`remove_image_${num}`}>Remove image {num}</label>
        </div>
      </Col>
      <AuthTextInput
        containerStyle={{ flex: 1 }}
        label={`Image ${num} Header`}
        id={`image_${num}_header`}
        name={`image_${num}_header`}
        onChangeText={() => {}}
        defaultValue={option[`image_${num}_header`]}
      />
      <AuthTextInput
        containerStyle={{ flex: 1 }}
        label={`Image ${num} Description`}
        id={`image_${num}_desc`}
        name={`image_${num}_desc`}
        multiline
        onChangeText={() => {}}
        defaultValue={option[`image_${num}_desc`]}
      />
    </Row>
  );
}

function ImageDisplayCol({ option, admin, num }) {
  return (
    <Col style={{ flex: '1 0 190px' }}>
      <img
        style={{
          width: '100%',
          height: 'auto',
          maxWidth: '300px',
          objectFit: 'contain',
        }}
        src={option[`image_${num}`]}
        alt={
          option[`image_${num}_alt`]
            ? option[`image_${num}_alt`]
            : `Help ${num}`
        }
      />
      <Col
        style={{
          background: 'var(--color-onb-blue)',
          borderRadius: '100%',
          color: 'white',
          fontSize: 'var(--text-xl)',
          padding: 'var(--space-sm)',
          aspectRatio: '1/1',
          marginBottom: 'var(--space-sm)',
          width: '46px',
          height: '46px',
        }}
      >
        {num}
      </Col>
      <b
        style={{
          color: admin ? 'var(--color-text)' : 'var(--color-onb-blue)',
          fontSize: 'var(--text-md)',
        }}
      >
        {option[`image_${num}_header`]}
      </b>
      <span>{option[`image_${num}_desc`]}</span>
    </Col>
  );
}
