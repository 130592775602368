import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  IoIosCheckmarkCircleOutline,
  IoIosCloseCircleOutline,
} from 'react-icons/io';

// components
import ActivityIndicator from 'components/generic/ActivityIndicator';

// actions
import { verifyConnectAccount } from 'actions';
import { Row, Col } from 'components/generic/Layout';
import { LinkButton } from 'components/AuthButton';

import { AUTH_BUTTON_DEFAULT_MAX_WIDTH_STYLE } from 'utils';

export default function StripeConnectSetup(props) {
  const [code, setCode] = useState(null);
  const [error, setError] = useState(null);
  const [state, setState] = useState(null);

  const dispatch = useDispatch();

  const reduxProps = useSelector(state => ({
    verifyConnectLoading: state.authReducer.verifyConnectLoading,
    verifyConnectError: state.authReducer.verifyConnectError,
    verifyConnectSuccess: state.authReducer.verifyConnectSuccess,
  }));

  const { verifyConnectLoading, verifyConnectSuccess, verifyConnectError } =
    reduxProps;

  useEffect(() => {
    const url_string = window.location.href;
    const url = new URL(url_string);

    const c = url.searchParams.get('code');
    const e = url.searchParams.get('error');
    const s = url.searchParams.get('state');

    if (c && s) {
      setCode(c);
      setState(s);
      dispatch(verifyConnectAccount(c, s));
    } else if (error === 'access_denied') {
      setError('Cancelled');
    } else if (error) {
      setError(e.replace(/\+/g, ' '));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!code && !error && !state && !verifyConnectLoading) {
    return (
      <div className="container">
        <h5>To start selling picks, setup a Stripe Connect account.</h5>
        <br />
        <LinkButton
          to="/sell-picks"
          containerStyle={AUTH_BUTTON_DEFAULT_MAX_WIDTH_STYLE}
        >
          Sell My Picks
        </LinkButton>
      </div>
    );
  }

  if (error) {
    return (
      <div className="container">
        <Row
          style={{
            alignItems: 'center',
            justifyContent: 'flex-start',
          }}
        >
          <IoIosCloseCircleOutline
            title="Stripe Connect Success"
            style={{
              fontSize: 'calc(var(--text-xxxxl))',
              marginBottom: '-24px',
              padding: '0 var(--space-md)',
              color: 'var(--color-danger)',
            }}
          />
          <h3>Sorry, Stripe ran into an error creating your account.</h3>
        </Row>
      </div>
    );
  }

  if (verifyConnectError) {
    return (
      <div className="container">
        <Row
          style={{
            alignItems: 'center',
            justifyContent: 'flex-start',
          }}
        >
          <IoIosCloseCircleOutline
            title="Stripe Connect Success"
            style={{
              fontSize: 'calc(var(--text-xxxxl))',
              marginBottom: '-24px',
              padding: '0 var(--space-md)',
              color: 'var(--color-danger)',
            }}
          />
          <h3>
            Sorry, we ran into an error connecting your Stripe account to
            betstamp.
          </h3>
        </Row>
        <p>Contact us at contact@betstamp.app</p>
      </div>
    );
  }

  if (verifyConnectSuccess) {
    return (
      <div className="container">
        <Row
          style={{
            height: '80vh',
            alignItems: 'center',
          }}
        >
          <Col style={{ textAlign: 'center' }}>
            <IoIosCheckmarkCircleOutline
              title="Stripe Connect Success"
              style={{
                fontSize: 'calc(var(--text-xxxxl) * 4)',
                marginBottom: 'calc(-1 * var(--space-lg))',
                color: 'var(--color-success)',
              }}
            />
            <h3>Stripe Connected!</h3>
            <h6>
              Congratulations, you can now sell picks on the betstamp
              Marketplace!
            </h6>
            <Row style={{ marginTop: 'var(--space-sm)' }}>
              <LinkButton
                to="/sell-picks"
                containerStyle={{
                  paddingRight: 'var(--space-sm)',
                }}
              >
                Create a Package
              </LinkButton>
              <LinkButton
                to="/find-bettors"
                containerStyle={{
                  paddingLeft: 'var(--space-sm)',
                }}
              >
                Find Bettors
              </LinkButton>
            </Row>
          </Col>
        </Row>
      </div>
    );
  }

  return (
    <div className="container">
      <Row
        style={{
          height: '80vh',
          alignItems: 'center',
        }}
      >
        <Col style={{ textAlign: 'center' }}>
          <ActivityIndicator size={3} />
          <h3>Connecting your stripe account to betstamp . . .</h3>
        </Col>
      </Row>
    </div>
  );
}
