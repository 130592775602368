import React from 'react';

import CustomSelect from 'components/generic/Select';

const OPTIONS = [
  { value: 'exclude_live', label: 'Exclude Live Bets' },
  { value: 'all', label: 'All Bets' },
  { value: 'only_live', label: 'Only Live Bets' },
];

const valueToLabel = {
  exclude_live: 'Exclude Live Bets',
  all: 'All Bets',
  only_live: 'Only Live Bets',
};

export default function LiveBets({ defaultValue, onDone }) {
  return (
    <>
      <CustomSelect
        onChange={(opt) => {
          onDone({
            filter: 'Live_Bets',
            value: opt.value,
            slug: opt.label,
          });
        }}
        value={
          defaultValue
            ? {
                value: defaultValue,
                label: valueToLabel[defaultValue],
              }
            : { value: 'all', label: 'All' }
        }
        options={OPTIONS}
      />
    </>
  );
}
