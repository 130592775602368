import { Observable } from 'rxjs';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import { ajax } from 'rxjs/observable/dom/ajax';
import { BACKEND_API_URL, getHeaders } from 'utils';

import {
  GET_SHARPSPORTS_BOOKS,
  getSharpsportsBooksSuccess,
  getSharpsportsBooksFailure,
} from 'actions';

export default function getSharpsportsBooks(action$, state$) {
  return action$.ofType(GET_SHARPSPORTS_BOOKS).switchMap(() => {
    let url = BACKEND_API_URL + `api/sharpsportsbook/`;
    return ajax
      .getJSON(url, getHeaders(state$))
      .map(data => getSharpsportsBooksSuccess(data))
      .catch(error => Observable.of(getSharpsportsBooksFailure(error.xhr)));
  });
}
