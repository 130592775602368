export const GET_GAME_DATA = 'GET_GAME_DATA';
export const CLEAR_GAME_DATA = 'CLEAR_GAME_DATA';
export const CLEAR_GAME_PROP_DATA = 'CLEAR_GAME_PROP_DATA';
export const CLEAR_PLAYER_PROP_PROP_LIST = 'CLEAR_PLAYER_PROP_PROP_LIST';
export const CLEAR_PLAYER_PROP_ODDS = 'CLEAR_PLAYER_PROP_ODDS';
export const GET_FULL_TIME_ODDS = 'GET_FULL_TIME_ODDS';
export const GET_FULL_TIME_BA = 'GET_FULL_TIME_BA';
export const GET_GAME_PROP_LIST = 'GET_GAME_PROP_LIST';
export const GET_GAME_PROP_OPTIONS = 'GET_GAME_PROP_OPTIONS';
export const GET_GAME_PROPS = 'GET_GAME_PROPS';
export const GET_1_HALF_ODDS = 'GET_1_HALF_ODDS';
export const GET_1_HALF_BA = 'GET_1_HALF_BA';
export const GET_PLAYER_PROP_PLAYER_LIST = 'GET_PLAYER_PROP_PLAYER_LIST';
export const GET_PLAYER_PROP_PROP_LIST = 'GET_PLAYER_PROP_PROP_LIST';
export const GET_PLAYER_PROPS = 'GET_PLAYER_PROPS';
export const GET_PLAYER_PROP_BA = 'GET_PLAYER_PROP_BA';
export const GET_LIVE_BA = 'GET_LIVE_BA';
export const GET_LIVE_ODDS = 'GET_LIVE_ODDS';
export const GET_2_HALF_ODDS = 'GET_2_HALF_ODDS';
export const GET_2_HALF_BA = 'GET_2_HALF_BA';
export const GET_LINE_HISTORY = 'GET_LINE_HISTORY';
export const GET_LINE_HISTORY_GRAPH = 'GET_LINE_HISTORY_GRAPH';
export const GET_PLAYER_BOXSCORES = 'GET_PLAYER_BOXSCORES';

export const clearGameData = () => ({ type: CLEAR_GAME_DATA });
export const clearGamePropData = () => ({ type: CLEAR_GAME_PROP_DATA });
export const clearPlayerPropPropList = () => ({
  type: CLEAR_PLAYER_PROP_PROP_LIST,
});
export const clearPlayerPropOdds = () => ({ type: CLEAR_PLAYER_PROP_ODDS });

export const GET_GAME_DATA_SUCCESS = 'GET_GAME_DATA_SUCCESS';
export const GET_GAME_DATA_FAILURE = 'GET_GAME_DATA_FAILURE';
export const getGameData = game_id => ({
  type: GET_GAME_DATA,
  game_id,
});
export const getGameDataSuccess = payload => ({
  type: GET_GAME_DATA_SUCCESS,
  payload,
});
export const getGameDataFailure = error => ({
  type: GET_GAME_DATA_FAILURE,
  error,
});

export const GET_FULL_TIME_ODDS_SUCCESS = 'GET_FULL_TIME_ODDS_SUCCESS';
export const GET_FULL_TIME_ODDS_FAILURE = 'GET_FULL_TIME_ODDS_FAILURE';
export const GET_FULL_TIME_ODDS_NEXT = 'GET_FULL_TIME_ODDS_NEXT';
export const getFullTimeOdds = ({ game_id, history, book }) => ({
  type: GET_FULL_TIME_ODDS,
  game_id,
  history,
  book,
});
export const getFullTimeOddsSuccess = payload => ({
  type: GET_FULL_TIME_ODDS_SUCCESS,
  payload,
});
export const getFullTimeOddsFailure = error => ({
  type: GET_FULL_TIME_ODDS_FAILURE,
  error,
});
export const getFullTimeOddsNext = () => ({
  type: GET_FULL_TIME_ODDS_NEXT,
});

export const GET_FULL_TIME_BA_SUCCESS = 'GET_FULL_TIME_BA_SUCCESS';
export const GET_FULL_TIME_BA_FAILURE = 'GET_FULL_TIME_BA_FAILURE';
export const getFullTimeBA = game_id => ({
  type: GET_FULL_TIME_BA,
  game_id,
});
export const getFullTimeBASuccess = payload => ({
  type: GET_FULL_TIME_BA_SUCCESS,
  payload,
});
export const getFullTimeBAFailure = error => ({
  type: GET_FULL_TIME_BA_FAILURE,
  error,
});

export const GET_GAME_PROP_LIST_SUCCESS = 'GET_GAME_PROP_LIST_SUCCESS';
export const GET_GAME_PROP_LIST_FAILURE = 'GET_GAME_PROP_LIST_FAILURE';
export const GET_GAME_PROP_LIST_NEXT = 'GET_GAME_PROP_LIST_NEXT';
export const getGamePropList = game_id => ({
  type: GET_GAME_PROP_LIST,
  game_id,
});
export const getGamePropListSuccess = payload => ({
  type: GET_GAME_PROP_LIST_SUCCESS,
  payload,
});
export const getGamePropListFailure = error => ({
  type: GET_GAME_PROP_LIST_FAILURE,
  error,
});
export const getGamePropListNext = () => ({
  type: GET_GAME_PROP_LIST_NEXT,
});

export const GET_GAME_PROP_OPTIONS_SUCCESS = 'GET_GAME_PROP_OPTIONS_SUCCESS';
export const GET_GAME_PROP_OPTIONS_FAILURE = 'GET_GAME_PROP_OPTIONS_FAILURE';
export const getGamePropOptions = ({ game_id, prop_type }) => ({
  type: GET_GAME_PROP_OPTIONS,
  game_id,
  prop_type,
});
export const getGamePropOptionsSuccess = payload => ({
  type: GET_GAME_PROP_OPTIONS_SUCCESS,
  payload,
});
export const getGamePropOptionsFailure = error => ({
  type: GET_GAME_PROP_OPTIONS_FAILURE,
  error,
});

export const GET_GAME_PROPS_SUCCESS = 'GET_GAME_PROPS_SUCCESS';
export const GET_GAME_PROPS_FAILURE = 'GET_GAME_PROPS_FAILURE';
export const GET_GAME_PROPS_NEXT = 'GET_GAME_PROPS_NEXT';
export const getGameProps = ({ game_id, prop_type }) => ({
  type: GET_GAME_PROPS,
  game_id,
  prop_type,
});
export const getGamePropsSuccess = payload => ({
  type: GET_GAME_PROPS_SUCCESS,
  payload,
});
export const getGamePropsFailure = error => ({
  type: GET_GAME_PROPS_FAILURE,
  error,
});
export const getGamePropsNext = () => ({
  type: GET_GAME_PROPS_NEXT,
});

export const GET_1_HALF_ODDS_SUCCESS = 'GET_1_HALF_ODDS_SUCCESS';
export const GET_1_HALF_ODDS_FAILURE = 'GET_1_HALF_ODDS_FAILURE';
export const GET_1_HALF_ODDS_NEXT = 'GET_1_HALF_ODDS_NEXT';
export const get1HalfOdds = ({ game_id, history, book, period }) => ({
  type: GET_1_HALF_ODDS,
  game_id,
  history,
  book,
  period,
});
export const get1HalfOddsSuccess = payload => ({
  type: GET_1_HALF_ODDS_SUCCESS,
  payload,
});
export const get1HalfOddsFailure = error => ({
  type: GET_1_HALF_ODDS_FAILURE,
  error,
});
export const get1HalfOddsNext = () => ({
  type: GET_1_HALF_ODDS_NEXT,
});

export const GET_1_HALF_BA_SUCCESS = 'GET_1_HALF_BA_SUCCESS';
export const GET_1_HALF_BA_FAILURE = 'GET_1_HALF_BA_FAILURE';
export const GET_1_HALF_BA_NEXT = 'GET_1_HALF_BA_NEXT';
export const get1HalfBA = (game_id, period) => ({
  type: GET_1_HALF_BA,
  game_id,
  period,
});
export const get1HalfBASuccess = payload => ({
  type: GET_1_HALF_BA_SUCCESS,
  payload,
});
export const get1HalfBAFailure = error => ({
  type: GET_1_HALF_BA_FAILURE,
  error,
});

export const GET_PLAYER_PROP_PLAYER_LIST_SUCCESS =
  'GET_PLAYER_PROP_PLAYER_LIST_SUCCESS';
export const GET_PLAYER_PROP_PLAYER_LIST_FAILURE =
  'GET_PLAYER_PROP_PLAYER_LIST_FAILURE';
export const GET_PLAYER_PROP_PLAYER_LIST_NEXT =
  'GET_PLAYER_PROP_PLAYER_LIST_NEXT';
export const getPlayerPropPlayerList = game_id => ({
  type: GET_PLAYER_PROP_PLAYER_LIST,
  game_id,
});
export const getPlayerPropPlayerListSuccess = payload => ({
  type: GET_PLAYER_PROP_PLAYER_LIST_SUCCESS,
  payload,
});
export const getPlayerPropPlayerListFailure = error => ({
  type: GET_PLAYER_PROP_PLAYER_LIST_FAILURE,
  error,
});
export const getPlayerPropPlayerListNext = () => ({
  type: GET_PLAYER_PROP_PLAYER_LIST_NEXT,
});

export const GET_PLAYER_PROP_PROP_LIST_SUCCESS =
  'GET_PLAYER_PROP_PROP_LIST_SUCCESS';
export const GET_PLAYER_PROP_PROP_LIST_FAILURE =
  'GET_PLAYER_PROP_PROP_LIST_FAILURE';
export const GET_PLAYER_PROP_PROP_LIST_NEXT = 'GET_PLAYER_PROP_PROP_LIST_NEXT';
export const getPlayerPropPropList = ({ game_id, player_name }) => ({
  type: GET_PLAYER_PROP_PROP_LIST,
  game_id,
  player_name,
});
export const getPlayerPropPropListSuccess = payload => ({
  type: GET_PLAYER_PROP_PROP_LIST_SUCCESS,
  payload,
});
export const getPlayerPropPropListFailure = error => ({
  type: GET_PLAYER_PROP_PROP_LIST_FAILURE,
  error,
});
export const getPlayerPropPropListNext = () => ({
  type: GET_PLAYER_PROP_PROP_LIST_NEXT,
});

export const GET_PLAYER_PROPS_SUCCESS = 'GET_PLAYER_PROPS_SUCCESS';
export const GET_PLAYER_PROPS_FAILURE = 'GET_PLAYER_PROPS_FAILURE';
export const GET_PLAYER_PROPS_NEXT = 'GET_PLAYER_PROPS_NEXT';
export const getPlayerProps = ({ game_id, prop_type, player_name }) => ({
  type: GET_PLAYER_PROPS,
  game_id,
  prop_type,
  player_name,
});
export const getPlayerPropsSuccess = payload => ({
  type: GET_PLAYER_PROPS_SUCCESS,
  payload,
});
export const getPlayerPropsFailure = error => ({
  type: GET_PLAYER_PROPS_FAILURE,
  error,
});
export const getPlayerPropsNext = () => ({
  type: GET_PLAYER_PROPS_NEXT,
});

export const GET_PLAYER_PROP_BA_SUCCESS = 'GET_PLAYER_PROP_BA_SUCCESS';
export const GET_PLAYER_PROP_BA_FAILURE = 'GET_PLAYER_PROP_BA_FAILURE';
export const getPlayerPropBA = ({ game_id, player_name, prop_type }) => ({
  type: GET_PLAYER_PROP_BA,
  game_id,
  player_name,
  prop_type,
});
export const getPlayerPropBASuccess = payload => ({
  type: GET_PLAYER_PROP_BA_SUCCESS,
  payload,
});
export const getPlayerPropBAFailure = error => ({
  type: GET_PLAYER_PROP_BA_FAILURE,
  error,
});

export const GET_LIVE_ODDS_SUCCESS = 'GET_LIVE_ODDS_SUCCESS';
export const GET_LIVE_ODDS_FAILURE = 'GET_LIVE_ODDS_FAILURE';
export const GET_LIVE_ODDS_NEXT = 'GET_LIVE_ODDS_NEXT';
export const getLiveOdds = ({ game_id, history, book }) => ({
  type: GET_LIVE_ODDS,
  game_id,
  history,
  book,
});
export const getLiveOddsSuccess = payload => ({
  type: GET_LIVE_ODDS_SUCCESS,
  payload,
});
export const getLiveOddsFailure = error => ({
  type: GET_LIVE_ODDS_FAILURE,
  error,
});
export const getLiveOddsNext = () => ({
  type: GET_LIVE_ODDS_NEXT,
});

export const GET_LIVE_BA_SUCCESS = 'GET_LIVE_BA_SUCCESS';
export const GET_LIVE_BA_FAILURE = 'GET_LIVE_BA_FAILURE';
export const getLiveBA = game_id => ({
  type: GET_LIVE_BA,
  game_id,
});
export const getLiveBASuccess = payload => ({
  type: GET_LIVE_BA_SUCCESS,
  payload,
});
export const getLiveBAFailure = error => ({
  type: GET_LIVE_BA_FAILURE,
  error,
});

export const GET_2_HALF_ODDS_SUCCESS = 'GET_2_HALF_ODDS_SUCCESS';
export const GET_2_HALF_ODDS_FAILURE = 'GET_2_HALF_ODDS_FAILURE';
export const GET_2_HALF_ODDS_NEXT = 'GET_2_HALF_ODDS_NEXT';
export const get2HalfOdds = ({ game_id, history, book }) => ({
  type: GET_2_HALF_ODDS,
  game_id,
  history,
  book,
});
export const get2HalfOddsSuccess = payload => ({
  type: GET_2_HALF_ODDS_SUCCESS,
  payload,
});
export const get2HalfOddsFailure = error => ({
  type: GET_2_HALF_ODDS_FAILURE,
  error,
});
export const get2HalfOddsNext = () => ({
  type: GET_2_HALF_ODDS_NEXT,
});

export const GET_2_HALF_BA_SUCCESS = 'GET_2_HALF_BA_SUCCESS';
export const GET_2_HALF_BA_FAILURE = 'GET_2_HALF_BA_FAILURE';
export const get2HalfBA = game_id => ({
  type: GET_2_HALF_BA,
  game_id,
});
export const get2HalfBASuccess = payload => ({
  type: GET_2_HALF_BA_SUCCESS,
  payload,
});
export const get2HalfBAFailure = error => ({
  type: GET_2_HALF_BA_FAILURE,
  error,
});

export const GET_LINE_HISTORY_SUCCESS = 'GET_LINE_HISTORY_SUCCESS';
export const GET_LINE_HISTORY_FAILURE = 'GET_LINE_HISTORY_FAILURE';
export const GET_LINE_HISTORY_NEXT = 'GET_LINE_HISTORY_NEXT';
export const getLineHistory = ({ game_id, book_id, sort_history }) => ({
  type: GET_LINE_HISTORY,
  game_id,
  book_id,
  sort_history,
});
export const getLineHistorySuccess = payload => ({
  type: GET_LINE_HISTORY_SUCCESS,
  payload,
});
export const getLineHistoryFailure = error => ({
  type: GET_LINE_HISTORY_FAILURE,
  error,
});
export const getLineHistoryNext = () => ({
  type: GET_LINE_HISTORY_NEXT,
});

export const GET_LINE_HISTORY_GRAPH_SUCCESS = 'GET_LINE_HISTORY_GRAPH_SUCCESS';
export const GET_LINE_HISTORY_GRAPH_FAILURE = 'GET_LINE_HISTORY_GRAPH_FAILURE';
export const getLineHistoryGraph = ({ game_id, book_ids, line }) => ({
  type: GET_LINE_HISTORY_GRAPH,
  game_id,
  book_ids,
  line,
});
export const getLineHistoryGraphSuccess = payload => ({
  type: GET_LINE_HISTORY_GRAPH_SUCCESS,
  payload,
});
export const getLineHistoryGraphFailure = error => ({
  type: GET_LINE_HISTORY_GRAPH_FAILURE,
  error,
});

export const GET_PLAYER_BOXSCORES_SUCCESS = 'GET_PLAYER_BOXSCORES_SUCCESS';
export const GET_PLAYER_BOXSCORES_FAILURE = 'GET_PLAYER_BOXSCORES_FAILURE';
export const GET_PLAYER_BOXSCORES_NEXT = 'GET_PLAYER_BOXSCORES_NEXT';
export const getPlayerBoxscores = game_id => ({
  type: GET_PLAYER_BOXSCORES,
  game_id,
});
export const getPlayerBoxscoresSuccess = payload => ({
  type: GET_PLAYER_BOXSCORES_SUCCESS,
  payload,
});
export const getPlayerBoxscoresFailure = error => ({
  type: GET_PLAYER_BOXSCORES_FAILURE,
  error,
});
export const getPlayerBoxscoresNext = () => ({
  type: GET_PLAYER_BOXSCORES_NEXT,
});
