import { GET_ADS, GET_ADS_SUCCESS, GET_ADS_FAILURE } from 'actions';

const initialState = {
  // this is for the logged in user's state
  ads: null,
  isLoadingAds: false,
  fetchAdsError: null,
  // this is for a selected state (user may or may not be logged in)
  selectedState: 'New Jersey',
};

export default function adsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ADS:
      return Object.assign({}, state, {
        ...state,
        isLoadingAds: true,
        fetchAdsError: null,
      });
    case GET_ADS_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        isLoadingAds: false,
        fetchAdsError: null,
        ads: action.payload.ad_banners,
      });
    case GET_ADS_FAILURE:
      return Object.assign({}, state, {
        ...state,
        isLoadingAds: false,
        fetchAdsError: action.error,
      });

    default:
      return state;
  }
}
