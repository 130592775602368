import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import {
  IoCheckmarkCircle,
  IoCheckmarkCircleOutline,
  IoMailOutline,
} from 'react-icons/io5';

// utils
import { isEmailValid } from 'utils';

// components
import { BetstampWordWhiteText } from 'components/generic/Logos';
import { Col, Row } from 'components/generic/Layout';
import { AuthButton } from 'components/AuthButton';
import { AuthTextInput } from 'components/AuthTextInput';

// actions
import { forgotPassword, resetErrors } from 'actions';

const Wrapper = styled.div`
  min-height: 100vh;
  background-color: var(--color-primary);
  color: white;
  flex: 1;
  display: flex;
  flex-flow: column wrap;
  justify-content: space-around;
  align-items: center;
`;

export default function ForgotPassword() {
  const dispatch = useDispatch();
  const reduxProps = useSelector((state) => ({
    user: state.authReducer.user,
    userToken: state.authReducer.userToken,
    isLoading: state.authReducer.isLoading,
    forgotPasswordSuccess: state.authReducer.forgotPasswordSuccess,
  }));
  const { isLoading, forgotPasswordSuccess } = reduxProps;

  const [email, setEmail] = useState(null);
  const [valids, setValids] = useState({});

  const _validateEmail = (email) => {
    email = email.trim();
    const valid = isEmailValid(email);
    setValids({ ...valids, email: valid });
    setEmail(email);
  };

  const _sendMail = (ev) => {
    if (ev) ev.preventDefault();
    dispatch(forgotPassword({ email: email }));
  };

  const _reset = () => {
    dispatch(resetErrors());
    setEmail(null);
    setValids({});
  };

  const submitDisabled = !valids.email;

  return (
    <Wrapper>
      <Col
        style={{
          maxWidth: '520px',
          width: '100%',
          margin: '0 auto',
          justifyContent: 'flex-start',
          flex: 0,
          padding: '0 var(--space-xs)',
          minHeight: '66vh',
        }}
      >
        <BetstampWordWhiteText height={128} />
        {forgotPasswordSuccess ? (
          <>
            <IoCheckmarkCircle
              color={'var(--color-success)'}
              size={128}
              style={{ background: 'white', borderRadius: '100%' }}
            />
            <p>
              Success! We sent you an email with password reset instructions.
              (Make sure to check your spam folder if you don't see the email)
            </p>
          </>
        ) : (
          <>
            <p>
              Enter your email below and if it's associated with a betstamp
              account we'll send a password reset email
            </p>
            <form onSubmit={_sendMail} style={{ width: '100%' }}>
              <AuthTextInput
                label="Email *"
                leftIcon={IoMailOutline}
                rightIcon={valids.email ? IoCheckmarkCircleOutline : null}
                rightIconColor={'var(--color-success)'}
                placeholder={'yourname@gmail.com'}
                textContentType={'emailAddress'}
                onChangeText={(text) => _validateEmail(text)}
              />

              <AuthButton
                containerStyle={{ marginTop: 'var(--space-sm)' }}
                colorTheme="white"
                disabled={submitDisabled}
                isLoading={isLoading}
                onPress={_sendMail}
              >
                Send Reset Email
              </AuthButton>
            </form>
          </>
        )}
        <Row style={{ marginTop: 'var(--space-md)', width: '100%' }}>
          <Link
            to="/signup"
            style={{ textDecoration: 'none', flex: 1 }}
            onClick={() => dispatch(resetErrors())}
          >
            <AuthButton colorTheme="inverted" onPress={() => _reset()}>
              Signup Here
            </AuthButton>
          </Link>
          <Link
            to="/login"
            style={{ textDecoration: 'none', flex: 1 }}
            onClick={() => dispatch(resetErrors())}
          >
            <AuthButton colorTheme="inverted" onPress={() => _reset()}>
              Login Here
            </AuthButton>
          </Link>
        </Row>
      </Col>
    </Wrapper>
  );
}
