import React, { useState, useEffect } from 'react';
import { useInfiniteQuery } from '@tanstack/react-query';

// components
import { Grid } from 'components/generic/Layout';

export default function HostSelector(props) {
  const {
    selectedHostIDs,
    selectedMainHostID,
    disableMainHostEditing,
    fixedMainHostId,
  } = props;

  const [hostMap, setHostMap] = useState({});

  const fetchHosts = useInfiniteQuery({
    staleTime: 1000 * 60 * 2,
    queryKey: [
      {
        endpoint: `onboarding-hosts`,
      },
    ],
    getNextPageParam: (lastPage, allPages) => lastPage.next,
    getPreviousPageParam: (firstPage, allPages) => firstPage.prev,
  });

  const pages = fetchHosts?.data?.pages;

  useEffect(() => {
    let newHostMap = {};
    if (pages) {
      for (const page of pages) {
        for (const host of page?.results) {
          newHostMap[host.id] = host;
        }
      }
    }
    setHostMap(newHostMap);
  }, [pages]);

  return (
    <>
      <span>Hosts *</span>
      <div style={{ maxHeight: '200px', overflowY: 'auto' }}>
        {pages && pages?.length > 0 && (
          <>
            {pages.map((page, i) => (
              <Grid
                key={`host-selector-page-${i}`}
                style={{
                  gridTemplateColumns: '1fr 1fr',
                  gridGap: 'var(--space-xs)',
                }}
              >
                {page.results.map(host => (
                  <div key={`host-selector-host-${host.id}`}>
                    <input
                      type="checkbox"
                      id={`host_id-${host.id}`}
                      name="host_ids"
                      value={host.id}
                      defaultChecked={selectedHostIDs?.includes(host.id)}
                      disabled={
                        disableMainHostEditing && host.id === fixedMainHostId
                      }
                      onChange={input => {
                        let newHosts = Array.from(selectedHostIDs);
                        if (input.target.checked) {
                          newHosts.push(host.id);
                          props.onChange(newHosts);
                        } else if (!input.target.checked) {
                          newHosts = newHosts.filter(hid => hid !== host.id);
                          props.onChange(newHosts);
                        }
                      }}
                    />
                    <label htmlFor={`host_id-${host.id}`}>
                      {host?.full_name || host?.email || host?.id}
                      {disableMainHostEditing && host.id === fixedMainHostId
                        ? ' (main host)'
                        : ''}
                    </label>
                  </div>
                ))}
              </Grid>
            ))}
          </>
        )}
      </div>
      {!disableMainHostEditing && selectedHostIDs?.length > 0 && (
        <>
          <div style={{ marginTop: 'var(--space-sm)' }}>Main Host * </div>
          <small>
            Appears in Google invites and emails. Once a session is created, the
            main host cannot change. Changing the main host here will not effect
            existing sessions.
          </small>

          <div style={{ maxHeight: '200px', overflowY: 'auto' }}>
            <Grid
              style={{
                gridTemplateColumns: '1fr 1fr',
                gridGap: 'var(--space-xs)',
              }}
            >
              {selectedHostIDs.map(hostid => (
                <div key={`main-host-selector-host-${hostid}`}>
                  <input
                    type="radio"
                    id={`main_host_id-${hostid}`}
                    name="main_host_id"
                    value={hostid}
                    defaultChecked={selectedMainHostID === hostid}
                    onChange={input => {
                      props.onChangeMainHost(hostid);
                    }}
                  />
                  <label htmlFor={`main_host_id-${hostid}`}>
                    {hostMap[hostid]?.full_name ||
                      hostMap[hostid]?.email ||
                      hostid}
                  </label>
                </div>
              ))}
            </Grid>
          </div>
        </>
      )}
    </>
  );
}
