import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

// components
import FollowBtn from 'components/FollowBtn';
import { Row, Col } from 'components/generic/Layout';
import AutoColoredAmount from 'components/generic/AutoColoredAmount';
import { IconButton } from 'components/AuthButton';

// actions
import { editRelationship } from 'actions';
import ProfilePic from './generic/ProfilePic';

export default function UsersListItem(props) {
  const dispatch = useDispatch();
  const reduxProps = useSelector(state => ({
    loggedInUser: state.authReducer.user,
  }));
  const { loggedInUser } = reduxProps;

  const { user, showStats, showNotificationsIcon, showFollowButton, index } =
    props;
  const { username, stats } = user;

  return (
    <Row style={{ alignItems: 'center' }}>
      <Link
        to={`/u/${username}`}
        style={{
          textDecoration: 'none',
          flex: 2,
          display: 'flex',
          flexFlow: 'row',
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}
      >
        <ProfilePic
          user={user}
          style={{
            width: 'var(--text-xxxl)',
            height: 'var(--text-xxxl)',
          }}
        />
        {index && (
          <h6
            style={{
              flex: 0,
              fontWeight: 'bold',
              margin: '0 var(--space-xs)',
              textAlign: 'left',
            }}
          >
            {index}
          </h6>
        )}

        <h6
          style={{
            flex: 1,
            fontWeight: 'bold',
            margin: '0 var(--space-sm)',
            textAlign: 'left',
          }}
        >
          {username}
        </h6>

        {showStats.map((stat, i) => (
          <Col
            key={`marketplace-following-stat-${i}-${user.id}`}
            style={{ flex: 1, justifyContent: 'center' }}
          >
            {
              {
                wagers: (
                  <small style={{ textAlign: 'center' }}>{stats[stat]}</small>
                ),
                pending: (
                  <small style={{ textAlign: 'center' }}>{stats[stat]}</small>
                ),
                roi: (
                  <small>
                    <AutoColoredAmount
                      symbol={'%'}
                      style={{ textAlign: 'center' }}
                    >
                      {stats[stat]}
                    </AutoColoredAmount>
                  </small>
                ),
                clv: (
                  <small>
                    <AutoColoredAmount
                      symbol={'%'}
                      style={{ textAlign: 'center' }}
                    >
                      {stats[stat]}
                    </AutoColoredAmount>
                  </small>
                ),
                total_profit: (
                  <AutoColoredAmount
                    symbol={'$'}
                    style={{ textAlign: 'center' }}
                  >
                    {stats[stat]}
                  </AutoColoredAmount>
                ),
                W: <small style={{ textAlign: 'center' }}>{stats[stat]}</small>,
                bet_size: (
                  <small style={{ textAlign: 'center' }}>{stats[stat]}</small>
                ),
                total_bets: (
                  <small style={{ textAlign: 'center' }}>{stats[stat]}</small>
                ),
                w: <small style={{ textAlign: 'center' }}>{stats['W']}</small>,
                record: (
                  <small style={{ textAlign: 'center' }}>
                    {stats['W']}-{stats['L']}-{stats['P']}
                  </small>
                ),
              }[stat]
            }
            <small style={{ textAlign: 'center' }}>
              {stat === 'w' ? 'wins' : stat.replace('_', ' ')}
            </small>
          </Col>
        ))}
      </Link>
      {showNotificationsIcon && (
        <IconButton
          iconName={
            user.relationship_to_user.notifications
              ? 'notifications-outline'
              : 'notifications-off-outline'
          }
          onPress={() => {
            const newStatus = !user.relationship_to_user.notifications;
            dispatch(editRelationship(user.id, newStatus));
          }}
        />
      )}
      {showFollowButton && (
        <FollowBtn
          user={user}
          isLoggedInUser={loggedInUser.id === user.id}
          containerStyle={{ marginVertical: 0, minWidth: 106 }}
        />
      )}
    </Row>
  );
}
