import {
  SET_TOUR_STEP,
  SET_SEEN_TOUR,
  GET_GAME_DATA,
  GET_GAME_DATA_SUCCESS,
  GET_GAME_DATA_FAILURE,
  CLEAR_GAME_DATA,
  CLEAR_GAME_PROP_DATA,
  CLEAR_PLAYER_PROP_PROP_LIST,
  CLEAR_PLAYER_PROP_ODDS,
  GET_FULL_TIME_ODDS,
  GET_FULL_TIME_ODDS_SUCCESS,
  GET_FULL_TIME_ODDS_FAILURE,
  GET_FULL_TIME_ODDS_NEXT,
  GET_FULL_TIME_BA,
  GET_FULL_TIME_BA_SUCCESS,
  GET_FULL_TIME_BA_FAILURE,
  GET_GAME_PROP_LIST,
  GET_GAME_PROP_LIST_SUCCESS,
  GET_GAME_PROP_LIST_FAILURE,
  GET_GAME_PROP_LIST_NEXT,
  GET_GAME_PROP_OPTIONS,
  GET_GAME_PROP_OPTIONS_SUCCESS,
  GET_GAME_PROP_OPTIONS_FAILURE,
  GET_GAME_PROPS,
  GET_GAME_PROPS_SUCCESS,
  GET_GAME_PROPS_FAILURE,
  GET_GAME_PROPS_NEXT,
  GET_1_HALF_ODDS,
  GET_1_HALF_ODDS_SUCCESS,
  GET_1_HALF_ODDS_FAILURE,
  GET_1_HALF_ODDS_NEXT,
  GET_1_HALF_BA,
  GET_1_HALF_BA_SUCCESS,
  GET_1_HALF_BA_FAILURE,
  GET_PLAYER_PROP_PLAYER_LIST,
  GET_PLAYER_PROP_PLAYER_LIST_SUCCESS,
  GET_PLAYER_PROP_PLAYER_LIST_FAILURE,
  GET_PLAYER_PROP_PLAYER_LIST_NEXT,
  GET_PLAYER_PROP_PROP_LIST,
  GET_PLAYER_PROP_PROP_LIST_SUCCESS,
  GET_PLAYER_PROP_PROP_LIST_FAILURE,
  GET_PLAYER_PROP_PROP_LIST_NEXT,
  GET_PLAYER_PROPS,
  GET_PLAYER_PROPS_SUCCESS,
  GET_PLAYER_PROPS_FAILURE,
  GET_PLAYER_PROPS_NEXT,
  GET_PLAYER_PROP_BA,
  GET_PLAYER_PROP_BA_SUCCESS,
  GET_PLAYER_PROP_BA_FAILURE,
  GET_LIVE_BA,
  GET_LIVE_BA_SUCCESS,
  GET_LIVE_BA_FAILURE,
  GET_LIVE_ODDS,
  GET_LIVE_ODDS_SUCCESS,
  GET_LIVE_ODDS_FAILURE,
  GET_LIVE_ODDS_NEXT,
  GET_2_HALF_ODDS,
  GET_2_HALF_ODDS_SUCCESS,
  GET_2_HALF_ODDS_FAILURE,
  GET_2_HALF_ODDS_NEXT,
  GET_2_HALF_BA,
  GET_2_HALF_BA_SUCCESS,
  GET_2_HALF_BA_FAILURE,
  GET_LINE_HISTORY,
  GET_LINE_HISTORY_SUCCESS,
  GET_LINE_HISTORY_FAILURE,
  GET_LINE_HISTORY_NEXT,
  GET_LINE_HISTORY_GRAPH,
  GET_LINE_HISTORY_GRAPH_SUCCESS,
  GET_LINE_HISTORY_GRAPH_FAILURE,
  GET_PLAYER_BOXSCORES,
  GET_PLAYER_BOXSCORES_SUCCESS,
  GET_PLAYER_BOXSCORES_FAILURE,
  GET_PLAYER_BOXSCORES_NEXT,
} from 'actions';
import { genGame, genBA, genOdds } from 'utils';

const initialState = {
  // game data
  game: null,
  isLoadingGameData: false,
  fetchGameDataError: null,
  // full time odds
  fullTimeOdds: null,
  isLoadingFullTimeOdds: false,
  fullTimeOddsError: null,
  isLoadingFullTimeOddsNext: false,
  fullTimeOddsNext: null,
  // full time best available
  fullTimeBA: null,
  isLoadingFullTimeBA: false,
  fullTimeBAError: null,
  // game prop list
  gamePropList: null,
  isLoadingGamePropList: false,
  fetchGamePropListError: null,
  isLoadingGamePropListNext: false,
  gamePropListNext: null,
  // game prop options
  gamePropOptions: null,
  isLoadingGamePropOptions: false,
  fetchGamePropOptionsError: null,
  // game props
  gamePropType: null,
  gameProps: null,
  isLoadingGames: false,
  fetchGamePropsError: null,
  isLoadingGamePropsNext: false,
  gamePropsNext: null,
  // one half odds
  oneHalfOdds: null,
  isLoadingOneHalfOdds: false,
  oneHalfOddsError: null,
  isLoadingOneHalfOddsNext: false,
  oneHalfOddsNext: null,
  // one half best available
  oneHalfBA: null,
  isLoadingOneHalfBA: false,
  oneHalfBAError: null,
  // player prop player list
  playerPropPlayerList: null,
  isLoadingPlayerPropPlayerList: false,
  fetchPlayerPropPlayerListError: null,
  isLoadingPlayerPropPlayerListNext: false,
  playerPropPlayerListNext: null,
  // player prop prop list
  playerPropPlayerName: null,
  playerPropPropList: null,
  isLoadingPlayerPropPropList: false,
  fetchPlayerPropPropListError: null,
  isLoadingPlayerPropPropListNext: false,
  playerPropPropNext: null,
  // player props
  playerPropsPropType: null,
  playerProps: null,
  isLoadingPlayerProps: false,
  fetchPlayerPropsError: null,
  isLoadingPlayerPropsNext: false,
  playerPropsNext: null,
  // player prop ba
  playerPropBA: null,
  isLoadingPlayerPropBA: false,
  playerPropBAError: null,
  // live best available
  liveBA: null,
  isLoadingLiveBA: false,
  liveBAError: null,
  // live odds
  liveOdds: null,
  isLoadingLiveOdds: false,
  liveOddsError: null,
  isLoadingLiveOddsNext: false,
  liveeOddsNext: null,
  // two (second) half odds
  twoHalfOdds: null,
  isLoadingTwoHalfOdds: false,
  twoHalfOddsError: null,
  isLoadingTwoHalfOddsNext: false,
  twoHalfOddsNext: null,
  // two (second) half best available
  twoHalfBA: null,
  isLoadingTwoHalfBA: false,
  twoHalfBAError: null,
  // line history
  lineHistory: null,
  lineHistoryCount: null,
  isLoadingLineHistory: false,
  lineHistoryError: null,
  isLoadingLineHistoryNext: false,
  lineHistoryNext: null,
  // line history graph
  lineHistoryGraph: null,
  isLoadingLineHistoryGraph: false,
  lineHistoryGraphError: null,
  // player boxscores
  playerBoxscores: null,
  isLoadingPlayerBoxscores: false,
  playerBoxscoresError: null,
  isLoadingPlayerBoxscoresNext: false,
  playerBoxscoresNext: null,
};

export default function gameReducer(state = initialState, action) {
  switch (action.type) {
    case GET_GAME_DATA:
      return Object.assign({}, state, {
        isLoadingGameData: true,
        fetchGameDataError: null,
      });
    case GET_GAME_DATA_SUCCESS:
      return Object.assign({}, state, {
        game: action.payload,
        isLoadingGameData: false,
        fetchGameDataError: null,
      });
    case GET_GAME_DATA_FAILURE:
      return Object.assign({}, state, {
        game: null,
        isLoadingGameData: false,
        fetchGameDataError: action.error,
      });

    case CLEAR_GAME_DATA:
      return Object.assign({}, state, {
        game: null,
        fetchGameDataError: null,
        fullTimeOdds: null,
        fullTimeBA: null,
        gamePropList: null,
        gamePropType: null,
        gameProps: null,
        oneHalfOdds: null,
        oneHalfBA: null,
        liveBA: null,
        twoHalfBA: null,
        lineHistory: null,
        lineHistoryGraph: null,
        playerBoxscores: null,
      });

    case CLEAR_GAME_PROP_DATA:
      return Object.assign({}, state, {
        gameProps: null,
        gamePropOptions: null,
        gamePropType: null,
      });

    case CLEAR_PLAYER_PROP_PROP_LIST:
      return Object.assign({}, state, {
        playerPropPropList: null,
        playerPropPlayerName: null,
      });

    case CLEAR_PLAYER_PROP_ODDS:
      return Object.assign({}, state, {
        playerProps: null,
        playerPropPropType: null,
      });

    case GET_FULL_TIME_ODDS:
      return Object.assign({}, state, {
        fullTimeOdds: null,
        isLoadingFullTimeOdds: true,
        fullTimeOddsError: null,
        isLoadingFullTimeOddsNext: false,
        fullTimeOddsNext: null,
      });
    case GET_FULL_TIME_ODDS_SUCCESS:
      return Object.assign({}, state, {
        fullTimeOdds: state.fullTimeOdds
          ? state.fullTimeOdds.concat(action.payload.results)
          : action.payload.results,
        isLoadingFullTimeOdds: false,
        fullTimeOddsError: null,
        isLoadingFullTimeOddsNext: false,
        fullTimeOddsNext: action.payload.next,
      });
    case GET_FULL_TIME_ODDS_FAILURE:
      return Object.assign({}, state, {
        fullTimeOdds: null,
        isLoadingFullTimeOdds: false,
        fullTimeOddsError: action.error,
        isLoadingFullTimeOddsNext: false,
      });
    case GET_FULL_TIME_ODDS_NEXT:
      return Object.assign({}, state, {
        isLoadingFullTimeOddsNext: true,
      });

    case GET_FULL_TIME_BA:
      return Object.assign({}, state, {
        fullTimeBA: null,
        isLoadingFullTimeBA: true,
        fullTimeBAError: null,
      });
    case GET_FULL_TIME_BA_SUCCESS:
      return Object.assign({}, state, {
        fullTimeBA: action.payload,
        isLoadingFullTimeBA: false,
        fullTimeBAError: null,
      });
    case GET_FULL_TIME_BA_FAILURE:
      return Object.assign({}, state, {
        fullTimeBA: null,
        isLoadingFullTimeBA: false,
        fullTimeBAError: action.error,
      });

    case GET_GAME_PROP_LIST:
      return Object.assign({}, state, {
        gamePropList: null,
        isLoadingGamePropList: true,
        fetchGamePropListError: null,
        isLoadingGamePropListNext: false,
      });
    case GET_GAME_PROP_LIST_SUCCESS:
      return Object.assign({}, state, {
        gamePropList: state.gamePropList
          ? state.gamePropList.concat(action.payload.results)
          : action.payload.results,
        isLoadingGamePropList: false,
        fetchGamePropListError: null,
        isLoadingGamePropListNext: false,
        gamePropListNext: action.payload.next,
      });
    case GET_GAME_PROP_LIST_FAILURE:
      return Object.assign({}, state, {
        gamePropList: null,
        isLoadingGamePropList: false,
        fetchGamePropListError: action.error,
        isLoadingGamePropListNext: false,
      });
    case GET_GAME_PROP_LIST_NEXT:
      return Object.assign({}, state, {
        isLoadingGamePropListNext: true,
      });

    case GET_GAME_PROP_OPTIONS:
      return Object.assign({}, state, {
        gamePropOptions: null,
        isLoadingGamePropOptions: true,
        fetchGamePropOptionsError: null,
      });
    case GET_GAME_PROP_OPTIONS_SUCCESS:
      return Object.assign({}, state, {
        gamePropOptions: action.payload.results,
        isLoadingGamePropOptions: false,
        fetchGamePropOptionsError: null,
      });
    case GET_GAME_PROP_OPTIONS_FAILURE:
      return Object.assign({}, state, {
        gamePropOptions: null,
        isLoadingGamePropOptions: false,
        fetchGamePropOptionsError: action.error,
      });

    case GET_GAME_PROPS:
      return Object.assign({}, state, {
        gamePropType: action.prop_type,
        gameProps: null,
        isLoadingGameProps: true,
        fetchGamePropsError: null,
        isLoadingGamePropsNext: false,
      });
    case GET_GAME_PROPS_SUCCESS:
      return Object.assign({}, state, {
        gameProps: state.gameProps
          ? state.gameProps.concat(action.payload.results)
          : action.payload.results,
        isLoadingGameProps: false,
        fetchGamePropsError: null,
        isLoadingGamePropsNext: false,
        gamePropsNext: action.payload.next,
      });
    case GET_GAME_PROPS_FAILURE:
      return Object.assign({}, state, {
        gameProps: null,
        isLoadingGameProps: false,
        fetchGamePropsError: action.error,
        isLoadingGamePropsNext: false,
      });
    case GET_GAME_PROPS_NEXT:
      return Object.assign({}, state, {
        isLoadingGamePropsNext: true,
      });

    case GET_1_HALF_ODDS:
      return Object.assign({}, state, {
        oneHalfOdds: null,
        isLoadingOneHalfOdds: true,
        oneHalfOddsError: null,
        isLoadingOneHalfOddsNext: false,
        oneHalfOddsNext: null,
      });
    case GET_1_HALF_ODDS_SUCCESS:
      return Object.assign({}, state, {
        oneHalfOdds: state.oneHalfOdds
          ? state.oneHalfOdds.concat(action.payload.results)
          : action.payload.results,
        isLoadingOneHalfOdds: false,
        oneHalfOddsError: null,
        isLoadingOneHalfOddsNext: false,
        oneHalfOddsNext: action.payload.next,
      });
    case GET_1_HALF_ODDS_FAILURE:
      return Object.assign({}, state, {
        oneHalfOdds: null,
        isLoadingOneHalfOdds: false,
        oneHalfOddsError: action.error,
        isLoadingOneHalfOddsNext: false,
      });
    case GET_1_HALF_ODDS_NEXT:
      return Object.assign({}, state, {
        isLoadingOneHalfOddsNext: true,
      });

    case GET_1_HALF_BA:
      return Object.assign({}, state, {
        oneHalfBA: null,
        isLoadingOneHalfBA: true,
        oneHalfBAError: null,
      });
    case GET_1_HALF_BA_SUCCESS:
      return Object.assign({}, state, {
        oneHalfBA: action.payload,
        isLoadingOneHalfBA: false,
        oneHalfBAError: null,
      });
    case GET_1_HALF_BA_FAILURE:
      return Object.assign({}, state, {
        oneHalfBA: null,
        isLoadingOneHalfBA: false,
        oneHalfBAError: action.error,
      });

    case GET_PLAYER_PROP_PLAYER_LIST:
      return Object.assign({}, state, {
        playerPropPlayerList: null,
        isLoadingPlayerPropPlayerList: true,
        fetchPlayerPropPlayerListError: null,
        isLoadingPlayerPropPlayerListNext: false,
        playerPropPlayerListNext: null,
      });
    case GET_PLAYER_PROP_PLAYER_LIST_SUCCESS:
      return Object.assign({}, state, {
        playerPropPlayerList: state.playerPropPlayerList
          ? state.playerPropPlayerList.concat(action.payload.results)
          : action.payload.results,
        isLoadingPlayerPropPlayerList: false,
        fetchPlayerPropPlayerListError: null,
        isLoadingPlayerPropPlayerListNext: false,
        playerPropPlayerListNext: action.payload.next,
      });
    case GET_PLAYER_PROP_PLAYER_LIST_FAILURE:
      return Object.assign({}, state, {
        playerPropPlayerList: null,
        isLoadingPlayerPropPlayerList: false,
        fetchPlayerPropPlayerListError: action.error,
        isLoadingPlayerPropPlayerListNext: false,
      });
    case GET_PLAYER_PROP_PLAYER_LIST_NEXT:
      return Object.assign({}, state, {
        isLoadingPlayerPropPlayerListNext: true,
      });

    case GET_PLAYER_PROP_PROP_LIST:
      return Object.assign({}, state, {
        playerPropPlayerName: action.player_name,
        playerPropPropList: null,
        isLoadingPlayerPropPropList: true,
        fetchPlayerPropPropListError: null,
        isLoadingPlayerPropPropListNext: false,
        playerPropPropNext: null,
      });
    case GET_PLAYER_PROP_PROP_LIST_SUCCESS:
      return Object.assign({}, state, {
        playerPropPropList: state.playerPropPropList
          ? state.playerPropPropList.concat(action.payload.results)
          : action.payload.results,
        isLoadingPlayerPropPropList: false,
        fetchPlayerPropPropListError: null,
        isLoadingPlayerPropPropListNext: false,
        playerPropPropListNext: action.payload.next,
      });
    case GET_PLAYER_PROP_PROP_LIST_FAILURE:
      return Object.assign({}, state, {
        isLoadingPlayerPropPropList: false,
        fetchPlayerPropPropListError: action.error,
        isLoadingPlayerPropPropListNext: false,
      });
    case GET_PLAYER_PROP_PROP_LIST_NEXT:
      return Object.assign({}, state, {
        isLoadingPlayerPropPropListNext: true,
      });

    case GET_PLAYER_PROPS:
      return Object.assign({}, state, {
        playerPropPropType: action.prop_type,
        playerProps: null,
        isLoadingPlayerProps: true,
        fetchPlayerPropsError: null,
        isLoadingPlayerPropsNext: false,
        playerPropsNext: null,
      });
    case GET_PLAYER_PROPS_SUCCESS:
      return Object.assign({}, state, {
        playerProps: state.playerProps
          ? state.playerProps.concat(action.payload.results)
          : action.payload.results,
        isLoadingPlayerProps: false,
        fetchPlayerPropsError: null,
        isLoadingPlayerPropsNext: false,
        playerPropsNext: action.payload.next,
      });
    case GET_PLAYER_PROPS_FAILURE:
      return Object.assign({}, state, {
        isLoadingPlayerProps: false,
        fetchPlayerPropsError: action.error,
        isLoadingPlayerPropsNext: false,
      });
    case GET_PLAYER_PROPS_NEXT:
      return Object.assign({}, state, {
        isLoadingPlayerPropsNext: true,
      });
      
    case GET_PLAYER_PROP_BA:
      return Object.assign({}, state, {
        playerPropBA: null,
        isLoadingPlayerPropBA: true,
        playerPropBAError: null,
      });
    case GET_PLAYER_PROP_BA_SUCCESS:
      return Object.assign({}, state, {
        playerPropBA: action.payload,
        isLoadingPlayerPropBA: false,
        playerPropBAError: null,
      });
    case GET_PLAYER_PROP_BA_FAILURE:
      return Object.assign({}, state, {
        playerPropBA: null,
        isLoadingPlayerPropB: false,
        playerPropBAError: action.error,
      });

    case GET_LIVE_BA:
      return Object.assign({}, state, {
        liveBA: null,
        isLoadingLiveBA: true,
        liveBAError: null,
      });
    case GET_LIVE_BA_SUCCESS:
      return Object.assign({}, state, {
        liveBA: action.payload,
        isLoadingLiveBA: false,
        liveBAError: null,
      });
    case GET_LIVE_BA_FAILURE:
      return Object.assign({}, state, {
        liveBA: null,
        isLoadingLiveBA: false,
        liveBAError: action.error,
      });
    case GET_LIVE_ODDS:
      return Object.assign({}, state, {
        liveOdds: null,
        isLoadingLiveOdds: true,
        liveOddsError: null,
        isLoadingLiveOddsNext: false,
        liveOddsNext: null,
      });
    case GET_LIVE_ODDS_SUCCESS:
      return Object.assign({}, state, {
        liveOdds: state.liveOdds
          ? state.liveOdds.concat(action.payload.results)
          : action.payload.results,
        isLoadingLiveOdds: false,
        liveOddsError: null,
        isLoadingLiveOddsNext: false,
        liveOddsNext: action.payload.next,
      });
    case GET_LIVE_ODDS_FAILURE:
      return Object.assign({}, state, {
        liveOdds: null,
        isLoadingLiveOdds: false,
        liveOddsError: action.error,
        isLoadingLiveOddsNext: false,
      });
    case GET_LIVE_ODDS_NEXT:
      return Object.assign({}, state, {
        isLoadingLiveOddsNext: true,
      });

    case GET_2_HALF_ODDS:
      return Object.assign({}, state, {
        twoHalfOdds: null,
        isLoadingTwoHalfOdds: true,
        twoHalfOddsError: null,
        isLoadingTwoHalfOddsNext: false,
        twoHalfOddsNext: null,
      });
    case GET_2_HALF_ODDS_SUCCESS:
      return Object.assign({}, state, {
        twoHalfOdds: state.twoHalfOdds
          ? state.twoHalfOdds.concat(action.payload.results)
          : action.payload.results,
        isLoadingTwoHalfOdds: false,
        twoHalfOddsError: null,
        isLoadingTwoHalfOddsNext: false,
        twoHalfOddsNext: action.payload.next,
      });
    case GET_2_HALF_ODDS_FAILURE:
      return Object.assign({}, state, {
        twoHalfOdds: null,
        isLoadingTwoHalfOdds: false,
        twoHalfOddsError: action.error,
        isLoadingTwoHalfOddsNext: false,
      });
    case GET_2_HALF_ODDS_NEXT:
      return Object.assign({}, state, {
        isLoadingTwoHalfOddsNext: true,
      });

    case GET_2_HALF_BA:
      return Object.assign({}, state, {
        twoHalfBA: null,
        isLoadingTwoHalfBA: true,
        twoHalfBAError: null,
      });
    case GET_2_HALF_BA_SUCCESS:
      return Object.assign({}, state, {
        twoHalfBA: action.payload,
        isLoadingTwoHalfBA: false,
        twoHalfBAError: null,
      });
    case GET_2_HALF_BA_FAILURE:
      return Object.assign({}, state, {
        twoHalfBA: null,
        isLoadingTwoHalfBA: false,
        twoHalfBAError: action.error,
      });

    case GET_LINE_HISTORY:
      return Object.assign({}, state, {
        lineHistory: null,
        lineHistoryCount: null,
        isLoadingLineHistory: true,
        lineHistoryError: null,
        isLoadingLineHistoryNext: false,
        lineHistoryNext: null,
      });
    case GET_LINE_HISTORY_SUCCESS:
      return Object.assign({}, state, {
        lineHistory: state.lineHistory
          ? state.lineHistory.concat(action.payload.results)
          : action.payload.results,
        isLoadingLineHistory: false,
        lineHistoryError: null,
        isLoadingLineHistoryNext: false,
        lineHistoryNext: action.payload.next,
        lineHistoryCount: action.payload.count,
      });
    case GET_LINE_HISTORY_FAILURE:
      return Object.assign({}, state, {
        isLoadingLineHistory: false,
        lineHistoryError: action.error,
        isLoadingLineHistoryNext: false,
      });
    case GET_LINE_HISTORY_NEXT:
      return Object.assign({}, state, {
        isLoadingLineHistoryNext: true,
      });

    case GET_LINE_HISTORY_GRAPH:
      return Object.assign({}, state, {
        lineHistoryGraph: null,
        isLoadingLineHistoryGraph: true,
        lineHistoryGraphError: null,
      });
    case GET_LINE_HISTORY_GRAPH_SUCCESS:
      return Object.assign({}, state, {
        lineHistoryGraph: action.payload.graph_data,
        isLoadingLineHistoryGraph: false,
        lineHistoryGraphError: null,
      });
    case GET_LINE_HISTORY_GRAPH_FAILURE:
      return Object.assign({}, state, {
        lineHistoryGraph: null,
        isLoadingLineHistoryGraph: false,
        lineHistoryGraphError: action.error,
      });

    case GET_PLAYER_BOXSCORES:
      return Object.assign({}, state, {
        playerBoxscores: null,
        isLoadingPlayerBoxscores: true,
        playerBoxscoresError: null,
        isLoadingPlayerBoxscoresNext: false,
        playerBoxscoresNext: null,
      });
    case GET_PLAYER_BOXSCORES_SUCCESS:
      return Object.assign({}, state, {
        playerBoxscores: state.playerBoxscores
          ? state.playerBoxscores.concat(action.payload.results)
          : action.payload.results,
        isLoadingPlayerBoxscores: false,
        playerBoxscoresError: null,
        isLoadingPlayerBoxscoresNext: false,
        playerBoxscoresNext: action.payload.next,
      });
    case GET_PLAYER_BOXSCORES_FAILURE:
      return Object.assign({}, state, {
        playerBoxscores: null,
        isLoadingPlayerBoxscores: false,
        playerBoxscoresError: action.error,
        isLoadingPlayerBoxscoresNext: false,
      });
    case GET_PLAYER_BOXSCORES_NEXT:
      return Object.assign({}, state, {
        isLoadingPlayerBoxscoresNext: true,
      });

    case SET_TOUR_STEP:
      if (action.stepID !== 'games7') return state;
      return Object.assign({}, state, {
        game: genGame(),
        fullTimeBA: genBA('FT'),
        fullTimeOdds: genOdds('FT'),
      });
    case SET_SEEN_TOUR:
      return Object.assign({}, state, initialState);

    default:
      return state;
  }
}
