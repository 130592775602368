import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import display_prop_info from 'utils/display_prop_info';

// components
import { Row } from 'components/generic/Layout';
import TeamLeagueLogo from 'components/TeamLeagueLogo';

const Wrapper = styled.div`
  background-color: var(--color-fg);
  border: 2px solid var(--color-primary);
  border-radius: var(--std-border-radius);
  padding: var(--space-xxs) var(--space-sm);
  width: 100%;
  box-sizing: border-box;
  background-color: var(--color-bg);
  @media only screen and (max-width: 625px) {
    padding: var(--space-xxxs) var(--space-xxs);
    margin-top: var(--space-xxs);
  }
`;

export default function PeriodScores(props) {
  const { game, bet, hideLiveText, hideNames } = props;
  if (!game) return null;
  const { period_scores } = game;
  const periods = period_scores
    ? period_scores.set_score
      ? period_scores.set_score
      : period_scores.periods
      ? period_scores.periods
      : null
    : null;
  let gameFinishString =
    'Final ' +
    (game.finish_type ? '(' + game.finish_type + ')' : '') +
    ' | ' +
    moment(game.date).format('MMM Do, YYYY');

  const away_lost =
    game.is_complete &&
    (game.winner === game.home_team.name || game.winner === 'Tie');
  const home_lost =
    game.is_complete &&
    (game.winner === game.away_team.name || game.winner === 'Tie');

  let topTeam = game.away_team;
  let topScore = game.away_score;
  let topKey = 'away_score';
  let topTieBreakKey = 'away_tiebreak_score';
  let topLost = away_lost;
  let botTeam = game.home_team;
  let botScore = game.home_score;
  let botKey = 'home_score';
  let botTieBreakKey = 'home_tiebreak_score';
  let botLost = home_lost;
  if (['BUND', 'SERA', 'LIGA', 'LIG1', 'UCL', 'EPL'].includes(game.league)) {
    topTeam = game.home_team;
    topScore = game.home_score;
    topKey = 'home_score';
    topTieBreakKey = 'home_tiebreak_score';
    topLost = home_lost;
    botTeam = game.away_team;
    botScore = game.away_score;
    botKey = 'away_score';
    botTieBreakKey = 'away_tiebreak_score';
    botLost = away_lost;
  }

  return (
    <Wrapper style={props.style}>
      <Row style={{ alignItems: 'center', margin: 'var(--space-xxxxs)' }}>
        {!hideLiveText ? (
          <>
            <TeamLeagueLogo
              style={{ flex: 0 }}
              league={game.league}
              iconSize={32}
            />
            <b
              style={{
                flex: 2,
                color: game.is_live
                  ? 'var(--color-success)'
                  : 'var(--color-text)',
              }}
            >
              {game.is_live
                ? game.is_live_text
                : game.is_complete
                ? gameFinishString
                : moment(game.date).format('ddd MMM D @ h:mma')}
            </b>
          </>
        ) : (
          <>
            <div style={{ flex: '0 1 31px', width: '31px' }} />
            {!hideNames && <b style={{ flex: 2 }} />}
          </>
        )}
        {(game.is_live || game.is_complete) &&
          period_scores &&
          periods &&
          periods.map((period, i) => {
            if (i > 9) return null;
            return (
              <b
                key={`period-score-title-${i}`}
                style={{
                  flex: 1,
                  textAlign: 'right',
                }}
              >
                <small>{get_period_name(i + 1, game)}</small>
              </b>
            );
          })}
        {!['WTA', 'ATP', 'UFC', 'BELL'].includes(game.league) &&
          (game.is_live || game.is_complete) && (
            <b style={{ flex: 1, textAlign: 'right' }}>
              <small>T</small>
            </b>
          )}
      </Row>
      <Row style={{ alignItems: 'center', margin: 'var(--space-xxxxs)' }}>
        <TeamLeagueLogo
          style={{ flex: 0 }}
          displayTeam
          league={game.league}
          team={topTeam.name}
          iconSize={32}
        />

        {!hideNames && (
          <h6
            style={{
              flex: 2,
              margin: 0,
              fontWeight: topLost ? 'normal' : 'bold',
            }}
          >
            {(game.is_live || game.is_complete) &&
            period_scores &&
            periods &&
            periods.length > 5
              ? topTeam.name
              : topTeam.full_name}
          </h6>
        )}
        {(game.is_live || game.is_complete) &&
          period_scores &&
          periods &&
          periods.map((period, i) => {
            const topSetWon = period[topKey] > period[botKey];
            //const botSetWon = period[botKey] > period[topKey];
            if (i > 9) return null;
            return (
              <h6
                key={`period-score-score-top-${i}`}
                style={{
                  flex: 1,
                  opacity: topSetWon === false ? 0.75 : 1,
                  margin: 0,
                  textAlign: 'right',
                  fontWeight: topSetWon ? 'bold' : 'normal',
                  color: topSetWon
                    ? 'var(--color-primary)'
                    : 'var(--color-text)',
                }}
              >
                {period[topKey]}
                <small>
                  {period[topTieBreakKey] && ` (${period[topTieBreakKey]})`}
                </small>
              </h6>
            );
          })}

        {!['WTA', 'ATP', 'UFC', 'BELL'].includes(game.league) &&
          (game.is_live || game.is_complete) && (
            <h6
              style={{
                flex: 1,
                opacity: topLost ? 0.66 : 1,
                margin: 0,
                textAlign: 'right',
                fontWeight: !topLost ? 'bold' : 'normal',
                color: !topLost ? 'var(--color-primary)' : 'var(--color-text)',
              }}
            >
              {topScore}
            </h6>
          )}
      </Row>
      <Row style={{ alignItems: 'center', margin: 'var(--space-xxxxs)' }}>
        <TeamLeagueLogo
          style={{ flex: 0 }}
          league={game.league}
          team={botTeam.name}
          iconSize={32}
        />

        {!hideNames && (
          <h6
            style={{
              flex: 2,
              margin: 0,
              fontWeight: botLost ? 'normal' : 'bold',
            }}
          >
            {(game.is_live || game.is_complete) &&
            period_scores &&
            periods &&
            periods.length > 5
              ? botTeam.name
              : botTeam.full_name}
          </h6>
        )}
        {(game.is_live || game.is_complete) &&
          period_scores &&
          periods &&
          periods.map((period, i) => {
            //const topSetWon = period[topKey] > period[botKey];
            const botSetWon = period[botKey] > period[topKey];
            if (i > 9) return null;
            return (
              <h6
                key={`period-score-score-bottom-${i}`}
                style={{
                  flex: 1,
                  opacity: botSetWon === false ? 0.75 : 1,
                  margin: 0,
                  textAlign: 'right',
                  fontWeight: botSetWon ? 'bold' : 'normal',
                  color: botSetWon
                    ? 'var(--color-primary)'
                    : 'var(--color-text)',
                }}
              >
                {period[botKey]}
                <small>
                  {period[botTieBreakKey] && ` (${period[botTieBreakKey]})`}
                </small>
              </h6>
            );
          })}

        {!['WTA', 'ATP', 'UFC', 'BELL'].includes(game.league) &&
          (game.is_live || game.is_complete) && (
            <h6
              style={{
                flex: 1,
                opacity: botLost ? 0.66 : 1,
                margin: 0,
                textAlign: 'right',
                fontWeight: !botLost ? 'bold' : 'normal',
                color: !botLost ? 'var(--color-primary)' : 'var(--color-text)',
              }}
            >
              {botScore}
            </h6>
          )}
      </Row>
      {game.season && ['ATP', 'WTA'].indexOf(game.league) >= 0 && (
        <small style={{ textAlign: 'center', paddingTop: 'var(-space-xs)' }}>
          {game.season.name}
          {game.season.surface && ' - ' + game.season.surface}
        </small>
      )}
      {game.sport === 'Golf' && (
        <small style={{ textAlign: 'center', paddingTop: 'var(--space-xs)' }}>
          {game.season.name} | {game.length_of_match}
        </small>
      )}
      {bet &&
        (bet.type_name === 'Player Prop' || bet.type_name === 'Game Prop') &&
        (game.is_live || game.is_complete) && (
          <Row
            style={{
              alignItems: 'center',
              justifyContent: 'flex-start',
              margin: 'var(--space-xxxxs)',
            }}
          >
            <span>
              {bet.type_name === 'Player Prop' ? bet.prop_name : ''}{' '}
              {bet.prop_type ? bet.prop_type + ':' : ''}
              <b
                style={{
                  color:
                    bet.status === 'WON' || bet.status === 'HALF_WON'
                      ? 'var(--color-dollar-green)'
                      : bet.status === 'LOST' || bet.status === 'HALF_LOST'
                      ? 'var(--color-dollar-red)'
                      : 'var(--color-text)',
                }}
              >
                {display_prop_info(bet, game.is_live)}
              </b>
            </span>
          </Row>
        )}
    </Wrapper>
  );
}

function get_period_name(period, game) {
  const league = game.league;
  const playoffs = game.season ? game.season.season_type === 'PLY' : false;
  if (
    ['NBA', 'NCAAF', 'NFL', 'WNBA', 'CFL'].indexOf(league) >= 0 &&
    period > 4
  ) {
    if (period === 5) return 'OT';
    return period - 4 + 'OT';
  }
  if (league === 'NHL' && period > 3) {
    if (period === 5 && !playoffs) return 'SO';
    if (period === 4) return 'OT';
    return period - 3 + 'OT';
  }
  if (league === 'NCAAB' && period > 2) {
    if (period === 3) return 'OT';
    return period - 2 + 'OT';
  }
  return period;
}
