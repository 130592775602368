import { useState } from 'react';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { IoChevronBack } from 'react-icons/io5';

// utils
import { humanDatetime } from 'utils';

// components
import StaffOnboardingBase from 'components/StaffOnboardingBase';
import { AuthTextInput } from 'components/AuthTextInput';
import { AuthButton } from 'components/AuthButton';

export default function Hosts(params) {
  return (
    <>
      <StaffOnboardingBase
        endpoint="onboarding-hosts"
        modelName="Host"
        headings={['User ID', 'Full Name', 'Email', 'Groups']}
        objKeys={['id', 'full_name', 'email', 'groups']}
        PanelChildren={Panel}
        panelTitleKey="full_name"
        showSearch={true}
        searchPlaceholder="Search by name or email"
        extraDeleteText={
          'This will not remove them as a staff member. If they are no longer a staff member, please remove them as a staff member from the other admin panel as well. If this host is in on any Schedules, they will be removed. Any future sessions this host is already scheduled for will need to be manually updated to a new host.'
        }
        //deleteBaseEndpoint="onboarding-hosts"
        formatText={{
          created_at: humanDatetime,
          groups: t => t.map(g => g.name).join(', '),
        }}
      />
    </>
  );
}

function Panel(props) {
  const host = props.selectedObj;
  const { tab } = props;

  return (
    <>
      {(tab === 'Edit' || host.createNew) && <CreateNewHost {...props} />}
      {false && tab === 'Edit' && !host.createNew && <p>TODO</p>}
    </>
  );
}

function CreateNewHost(props) {
  const host = props.selectedObj;
  const [searchText, setSearchText] = useState(null);
  const [selectedUser, setSelectedUser] = useState(
    host.createNew ? null : host
  );
  const [confirmedStaff, setConfirmedStaff] = useState(false);

  const fetchUsers = useQuery({
    enabled: !!searchText,
    queryKey: [
      {
        endpoint: 'onboarding-hosts',
        urlParams: {
          make_host: true,
          search: searchText,
        },
      },
    ],
  });

  const queryClient = useQueryClient();
  const submitHost = useMutation({
    mutationKey: `submit-host`,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [
          {
            endpoint: 'onboarding-hosts',
            urlParams: props.urlParams,
          },
        ],
      });
      props.closePanel();
    },
  });

  let isOnboardingHost = false;
  let isOnboardingFollowUpHost = false;
  let isOnboardingSalesTeam = false;
  let isVA = false;
  if (!host.createNew && selectedUser && selectedUser.groups?.length > 0) {
    selectedUser.groups.forEach(group => {
      if (group.name === 'Onboarding Host') isOnboardingHost = true;
      if (group.name === 'Onboarding Follow Up Host')
        isOnboardingFollowUpHost = true;
      if (group.name === 'Onboarding Sales Team') isOnboardingSalesTeam = true;
      if (group.name === 'VA') isVA = true;
    });
  }

  return (
    <>
      {selectedUser ? (
        <>
          {selectedUser.createNew && (
            <AuthButton
              containerStyle={{
                flex: 0,
                maxWidth: '92px',
                alignSelf: 'flex-start',
              }}
              leftIcon={IoChevronBack}
              leftIconSize={22}
              colorTheme="text"
              btnTheme="borderless"
              onPress={() => setSelectedUser(null)}
            >
              Back
            </AuthButton>
          )}
          {selectedUser && (
            <AuthTextInput
              key={`selected-user-email-create-host-disabled`}
              disabled
              label="Email"
              value={selectedUser.email}
              containerStyle={{ opacity: 0.75 }}
            />
          )}
          <form
            style={{ width: '100%' }}
            onSubmit={ev => {
              ev.preventDefault();
              // doing this as formdata in case we want to upload a photo in the future
              const fd = new FormData(ev.target);
              if (host.createNew) fd.append('make_host', true);
              submitHost.mutate({
                endpoint: `onboarding-hosts/${selectedUser.id}`,
                body: fd,
              });
            }}
          >
            <AuthTextInput
              name="full_name"
              label="Full Name"
              defaultValue={host.full_name}
              placeholder={'Full name'}
              onChangeText={() => { }}
            />
            {!selectedUser.is_staff && (
              <div style={{ marginTop: 'var(--space-lg)' }}>
                <input
                  type="checkbox"
                  name="confirm-make-staff"
                  id="confirm-make-staff"
                  onChange={input => setConfirmedStaff(input?.target?.checked)}
                />
                <label htmlFor="confirm-make-staff">
                  This user is not a staff user. By making them a host, they
                  will become a staff user and have access to the betstamp
                  backend. Click to confirm this.
                </label>
              </div>
            )}

            <p style={{ marginLeft: 'var(--space-sm)', marginBottom: 0 }}>
              Groups
            </p>
            <div style={{ marginLeft: 'var(--space-xs)' }}>
              <input
                type="checkbox"
                name="groups"
                id="onboarding-host"
                value="Onboarding Host"
                defaultChecked={host.createNew ? true : isOnboardingHost}
              />
              <label htmlFor="onboarding-host">Onboarding Host</label>
            </div>
            <div style={{ marginLeft: 'var(--space-xs)' }}>
              <input
                type="checkbox"
                name="groups"
                id="onboarding-follow-up-host"
                value="Onboarding Follow Up Host"
                defaultChecked={isOnboardingFollowUpHost}
              />
              <label htmlFor="onboarding-follow-up-host">Follow Up Host</label>
            </div>
            <div style={{ marginLeft: 'var(--space-xs)' }}>
              <input
                type="checkbox"
                name="groups"
                id="onboarding-sales-team"
                value="Onboarding Sales Team"
                defaultChecked={isOnboardingSalesTeam}
              />
              <label htmlFor="onboarding-sales-team">
                Onboarding Sales Team
              </label>
            </div>
            <div style={{ marginLeft: 'var(--space-xs)' }}>
              <input
                type="checkbox"
                name="groups"
                id="va"
                value="VA"
                defaultChecked={isVA}
              />
              <label htmlFor="va">VA</label>
            </div>

            <AuthButton
              type="submit"
              containerStyle={{ flex: 0, marginTop: 'var(--space-xl)' }}
              disabled={!selectedUser.is_staff && !confirmedStaff}
              isLoading={submitHost.isLoading}
            >
              {host.createNew ? 'Create Host' : 'Save Changes'}
            </AuthButton>
            {submitHost.isError && (
              <span
                style={{ color: 'var(--color-danger)', textAlign: 'center' }}
              >
                {submitHost.error.message}
              </span>
            )}
          </form>
        </>
      ) : (
        <>
          <p>
            Search the betstamp database for existing users to turn them into a
            host.
          </p>
          <AuthTextInput
            autoFocus={true}
            containerStyle={{ position: 'sticky', top: '48px', margin: 0 }}
            placeholder="Search by email or name"
            onChangeText={text => setSearchText(text)}
          />
          {fetchUsers?.data?.results.map(user => (
            <AuthButton
              key={`search-result-host-onb-${user.id}`}
              containerStyle={{ flex: 0 }}
              btnTheme="borderless"
              colorTheme="text"
              onPress={() => setSelectedUser(user)}
            >
              {user.full_name || user.email || user.id}
            </AuthButton>
          ))}
        </>
      )}
    </>
  );
}
