import {
  GET_COMPETITION_LEADERBOARD,
  GET_COMPETITION_LEADERBOARD_SUCCESS,
  GET_COMPETITION_LEADERBOARD_FAILURE,
  GET_COMPETITIONS,
  GET_COMPETITIONS_SUCCESS,
  GET_COMPETITIONS_FAILURE,
  GET_COMPETITION,
  GET_COMPETITION_SUCCESS,
  GET_COMPETITION_FAILURE,
} from 'actions';

const initialState = {
  competition: null,
  competitions: [],
  leaderboard: [],
  isLoadingLeaderboard: false,
  fetchLeaderboardError: null,
  isLoadingCompetitions: false,
  fetchCompetitionsError: null,
  isLoadingCompetition: false,
  fetchCompetitionError: null,
};

export default function competitionsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_COMPETITIONS:
      return Object.assign({}, state, {
        ...state,
        isLoadingCompetitions: true,
        fetchCompetitionsError: null,
      });
    case GET_COMPETITIONS_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        isLoadingCompetitions: false,
        fetchCompetitionsError: null,
        competitions: action.payload.results,
      });
    case GET_COMPETITIONS_FAILURE:
      return Object.assign({}, state, {
        ...state,
        isLoadingCompetitions: false,
        fetchCompetitionsError: action.error,
      });

    case GET_COMPETITION:
      return Object.assign({}, state, {
        ...state,
        isLoadingCompetition: true,
        fetchCompetitionError: null,
      });
    case GET_COMPETITION_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        isLoadingCompetition: false,
        fetchCompetitionError: null,
        competition: action.payload,
      });
    case GET_COMPETITION_FAILURE:
      return Object.assign({}, state, {
        ...state,
        isLoadingCompetition: false,
        fetchCompetitionError: action.error,
      });

    case GET_COMPETITION_LEADERBOARD:
      return Object.assign({}, state, {
        ...state,
        isLoadingLeaderboard: true,
        fetchLeaderboardError: null,
      });
    case GET_COMPETITION_LEADERBOARD_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        isLoadingLeaderboard: false,
        leaderboard: action.payload,
      });
    case GET_COMPETITION_LEADERBOARD_FAILURE:
      return Object.assign({}, state, {
        ...state,
        isLoadingLeaderboard: false,
        fetchLeaderboardError: action.error,
      });

    default:
      return state;
  }
}
