import React from 'react';
import styled from 'styled-components';
import {
  IoLogoInstagram,
  IoLogoTwitter,
  IoLogoLinkedin,
  IoLogoTiktok,
} from 'react-icons/io5';

// components
import { BetstampWordWhiteText } from 'components/generic/Logos';
import { Row } from 'components/generic/Layout';
import { LinkButton } from 'components/AuthButton';

const Hero = styled.div`
  width: 100%;
  background-color: var(--color-primary);
  color: white;
  padding: var(--space-xl);
  box-sizing: border-box;
  @media only screen and (max-width: 625px) {
    padding: var(--space-sm);
    padding-bottom: var(--space-xl);
  }
`;

export default function About() {
  return (
    <>
      <Hero>
        <div
          className="container"
          style={{ minHeight: 0, textAlign: 'center' }}
        >
          <BetstampWordWhiteText height={96} />
          <h1>The Best Sports Betting Tracker on the Market</h1>
        </div>
      </Hero>
      <div className="container" style={{ padding: '0 var(--space-xs)' }}>
        <h3>
          <b>Our Story</b>
        </h3>
        <p>
          Betstamp is the best sports betting utility in market, designed to
          help bettors increase their profits and easily manage their process.
          Users cannot wager directly through the betstamp platform, but
          betstamp aids users in determining what to bet on, where to bet it,
          and how to track it.
        </p>
        <p>
          Betstamp also features the first ever verified peer-to-peer
          marketplace, where any user can buy or sell sports betting
          predicitions (picks). The app ensures that each individual pick
          sellers’ betting history is fully verified and cannot be manipulated
          for the purposes of misleading a pick buyer.
        </p>
        <p>
          The company was founded in 2020 by co-founders Johnny and Julian with
          the intention of building an application that met their own day-to-day
          betting needs. In April of 2021, Rob Pizzola joined the Executive Team
          as the company continued its expansion. Backed by an executive team
          with experience betting on sports as their professions, combined with
          experience in marketing and software development at Fortune 500
          leaders, the team is equipped to forecast industry trends and
          understand what bettors need and want.
        </p>

        <br />
        <h3>
          <b>Our Mission</b>
        </h3>
        <p>
          Betstamp is dedicated to serving all types of bettors, whether that be
          a recreational bettor, a sharp bettor, or the in-between bettor.
          Betstamp’s founding team has years of experience in each of the bettor
          segments. Betstamp is committed to help users better work to solve the
          following problems:
        </p>
        <ul>
          <li>
            Saving money and time by comparing different sportsbook odds and
            finding the best available price.
          </li>
          <li>
            Identifying strengths and weaknesses in their betting history to
            help improve their process and increase profits.
          </li>
          <li>
            Find useful connections and partnerships with other bettors through
            a trusted medium.
          </li>
        </ul>
        <p>
          Betstamp is focused on education and improvement, rather than pure
          entertainment to generate clicks.
        </p>

        <br />
        <h3>
          <b>Join The Team!</b>
        </h3>
        <p>
          At betstamp, we are looking to establish a brand that is viewed as
          authentic and trustworthy in the sports betting space. As a company
          that is growing quickly, we are looking to hire creative people who
          are knowledgeable in the sports betting space to join a rapidly
          evolving team.
        </p>
        <LinkButton
          to="/careers"
          containerStyle={{
            maxWidth: '13rem',
          }}
        >
          View Open Positions
        </LinkButton>

        <br />
        <br />
        <h3>
          <b>Contact Us</b>
        </h3>
        <p>
          Send us an email at{' '}
          <a
            href="mailto:contact@betstamp.app"
            target="_blank"
            rel="noopener noreferrer"
          >
            contact@betstamp.app
          </a>{' '}
          or connect with us on social media
        </p>
        <Row style={{ flexDirection: 'reverse', maxWidth: '600px' }}>
          <a
            href="https://twitter.com/Betstamp"
            target="_blank"
            rel="noopener noreferrer"
            style={{ width: '32px' }}
          >
            <IoLogoTwitter
              title="betstamp Twitter"
              style={{ width: '100%', height: 'auto' }}
            />
          </a>
          <a
            href="https://www.instagram.com/betstamp/"
            target="_blank"
            rel="noopener noreferrer"
            style={{ width: '32px' }}
          >
            <IoLogoInstagram
              title="betstamp Instagram"
              style={{ width: '100%', height: 'auto' }}
            />
          </a>
          <a
            href="https://vm.tiktok.com/ZMe9VSXm1/"
            target="_blank"
            rel="noopener noreferrer"
            style={{ width: '32px' }}
          >
            <IoLogoTiktok
              title="betstamp Tik Tok"
              style={{ width: '100%', height: 'auto' }}
            />
          </a>
          <a
            href="https://www.linkedin.com/company/betstamp/"
            target="_blank"
            rel="noopener noreferrer"
            style={{ width: '32px' }}
          >
            <IoLogoLinkedin
              title="betstamp LinkedIn"
              style={{ width: '100%', height: 'auto' }}
            />
          </a>
        </Row>
      </div>
    </>
  );
}
