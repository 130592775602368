import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';

// utils
import { commaSeparateNumber } from 'utils';

// components
import { Row } from 'components/generic/Layout';
import ErrorDisplay from 'components/generic/ErrorDisplay';
import UserSummaryCircles from 'components/UserSummaryCircles';
import ActivityIndicator from 'components/generic/ActivityIndicator';
import { LinkButton } from 'components/AuthButton';

import { AUTH_BUTTON_DEFAULT_CONTAINER_STYLE } from 'utils';
// actions
import {
  getUser,
  getPublicProfile,
  // setOtherUserAnalysis
} from 'actions';

export default function UserAnalysis(props) {
  const dispatch = useDispatch();

  const { viewingOtherUser } = props;

  // redux props
  const reduxProps = useSelector(state => ({
    user: state.authReducer.user,
    isLoadingPrivate: state.authReducer.isLoading,
    fetchErrorPrivate: state.authReducer.apiError,

    isLoadingPublic: state.publicUserReducer.isLoading,
    publicProfile: state.publicUserReducer.publicProfile,
    fetchErrorPublic: state.publicUserReducer.fetchError,
  }));

  let user = null;
  let isLoading = null;
  let error = null;
  if (viewingOtherUser) {
    user = reduxProps.publicProfile;
    isLoading = reduxProps.isLoadingPublic;
    error = reduxProps.fetchErrorPublic;
  } else {
    user = reduxProps.user;
    isLoading = reduxProps.isLoadingPrivate;
    error = reduxProps.fetchErrorPrivate;
  }

  if (isLoading || !user) {
    return <ActivityIndicator size={2} />;
  }

  if (error) {
    return (
      <div style={{ flex: 1, backgroundColor: 'var(--color-bg)' }}>
        <ErrorDisplay
          error={error}
          message={'Error getting user stats!'}
          retry={
            viewingOtherUser
              ? () => dispatch(getPublicProfile(user.id))
              : () => dispatch(getUser())
          }
        />
      </div>
    );
  }

  return (
    <div>
      <Row>
        <UserSummaryCircles user={user} />
      </Row>
      <br />
      <Row style={{ marginTop: 'var(--space-lg)' }}>
        <h6 style={{ flex: '1 0 300px', textAlign: 'center' }}>
          Bet Size Stats (Base Amount)
          <br />
          <small>Last updated {moment(user.stats.updated_at).fromNow()}</small>
        </h6>
        <h6 style={{ flex: '1 0 300px', textAlign: 'center' }}>
          Avg*: ${commaSeparateNumber(user.stats.bet_size)}
        </h6>
        <h6 style={{ flex: '1 0 300px', textAlign: 'center' }}>
          Max: ${commaSeparateNumber(user.stats.max_bet_size)}
        </h6>
        <h6 style={{ flex: '1 0 300px', textAlign: 'center' }}>
          Min: ${commaSeparateNumber(user.stats.min_bet_size)}
        </h6>
      </Row>

      {user && (
        <Row desktop>
          <LinkButton
            to={`/analysis?user=${user.id}&time_period=7&date_range=[null,null]&verified=true&`}
            containerStyle={AUTH_BUTTON_DEFAULT_CONTAINER_STYLE}
          >
            {viewingOtherUser
              ? `ANALYZE ${
                  user.username ? user.username.toUpperCase() : 'USER'
                }'S PICKS`
              : 'ANALYZE MY PICKS'}
          </LinkButton>
        </Row>
      )}
    </div>
  );
}
