import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  IoArrowForwardCircle,
  IoCheckmarkCircleOutline,
  IoEyeOffOutline,
  IoEyeOutline,
  IoLockClosedOutline,
  IoMailOutline,
  IoPersonCircleOutline,
  IoGiftOutline,
} from 'react-icons/io5';
import Analytics from 'amplitude/Analytics';
import { useLocation, useHistory } from 'react-router-dom';
import { useEffectOnce, useUpdateEffect } from 'react-use';

// utils
import { isEmailValid, STATE_OPTIONS, __DEV__ } from 'utils';

// components
import Select from 'components/generic/Select';
import { Col } from 'components/generic/Layout';
import SignupWrapper from 'components/SignupWrapper';
import { AuthButton } from 'components/AuthButton';
import { AuthTextInput } from 'components/AuthTextInput';
import { SignupHeader, TOS, SignupFooter } from 'components/auth/signup';
import AccountCreated from 'pages/Auth/AccountCreated';

// actions
import {
  checkEmailAvailable,
  checkUsernameAvailable,
  resetErrors,
  createUser,
  checkReferralCodeExists,
  setTempUserStates,
  setAppSetting,
} from 'actions';

export default function SignupEmail(props) {
  const dispatch = useDispatch();
  const params = useLocation().search;
  const history = useHistory();
  const reduxProps = useSelector(state => ({
    tempUser: state.authReducer.tempUser,
    userToken: state.authReducer.userToken,
    emailIsAvailable: state.authReducer.emailIsAvailable,
    usernameIsAvailable: state.authReducer.usernameIsAvailable,
    isCreatingUser: state.authReducer.isCreatingUser,
    createUserSuccess: state.authReducer.createUserSuccess,
    createUserFailure: state.authReducer.createUserFailure,
    referralCodeExists: state.authReducer.referralCodeExists,
    theme: state.settingsReducer.theme,
  }));
  const {
    tempUser,
    userToken,
    emailIsAvailable,
    usernameIsAvailable,
    isCreatingUser,
    createUserSuccess,
    createUserFailure,
    referralCodeExists,
    theme,
  } = reduxProps;

  const [newUser, setNewUser] = useState({
    is_public: true,
    accepted_TC: false,
    referral_code: new URLSearchParams(params).get('referral_code'),

    // for preserving user inputs
    email: tempUser.email,
    username: tempUser.username,
    password: tempUser.password,
    state: tempUser.state,
  });
  const [valids, setValids] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [autoUpdateUsername, setAutoUpdateUsername] = useState(true);
  const [forceEmailInvalid, setForceEmailInvalid] = useState(null);
  const [forcePasswordInvalid, setForcePasswordInvalid] = useState(null);
  const [tracked, setTracked] = useState({});

  useEffectOnce(() => {
    if (userToken) {
      dispatch(resetErrors());
      history.push('/games');
    } else {
      if (newUser.referral_code) {
        dispatch(checkReferralCodeExists(newUser.referral_code));
      }

      // validate email and username in case we have values pre-poluated
      validateAllCredentials();
    }
  });

  useUpdateEffect(() => {
    if (createUserSuccess) {
      history.push('/onboarding/select-experience');
    }
  }, [createUserSuccess]);

  useUpdateEffect(() => {
    if (createUserFailure) {
      // validate email and username just in case login credentials are stored in backend but returned error
      validateAllCredentials();
    }
  }, [createUserFailure]);

  useEffect(
    // store temp credentials in redux
    () =>
      history.listen(_ => {
        if (history.action === 'POP') {
          dispatch(
            setTempUserStates({
              ...tempUser,
              email: newUser.email,
              username: newUser.username,
              password: newUser.password,
              state: newUser.state,
            })
          );
        }
      }),
    [newUser.email, newUser.username, newUser.password, newUser.state]
  );

  function validateAllCredentials() {
    let emailValid = false;
    let usernameValid = Boolean(newUser.username);
    const passwordValid = Boolean(newUser.password?.length >= 8);
    if (newUser.password && !passwordValid) {
      setForcePasswordInvalid('Invalid password');
    }
    if (newUser.email) {
      const email = newUser.email.trim();
      emailValid = isEmailValid(email);
      if (emailValid) {
        dispatch(checkEmailAvailable(email));
      } else {
        setForceEmailInvalid('Invalid email');
      }
    }
    if (newUser.username) {
      if (
        !newUser.username ||
        newUser.username.trim() === '' ||
        newUser.username.length > 16
      ) {
        usernameValid = false;
      }
      if (usernameValid) {
        dispatch(checkUsernameAvailable(newUser.username));
      }
    }
    setValids({
      ...valids,
      email: emailValid,
      username: usernameValid,
      password: passwordValid,
    });
  }

  const _validateEmail = email => {
    email = email.trim();
    const valid = isEmailValid(email);
    const newValids = { ...valids, email: valid };
    const nu = { ...newUser, email: email };

    if (valid) {
      setForceEmailInvalid(null);
      dispatch(checkEmailAvailable(email));
    }

    if (autoUpdateUsername) {
      const username = email.split('@')[0].substr(0, 16);
      nu.username = username;
      newValids.username = usernameValidateHelper(username);
    }

    setValids(newValids);
    setNewUser(nu);
  };

  const usernameValidateHelper = username => {
    let valid = true;
    if (!username || username.trim() === '' || username.length > 16) {
      valid = false;
    }
    if (valid) {
      dispatch(checkUsernameAvailable(username));
    }

    return valid;
  };

  const _validateUsername = username => {
    const valid = usernameValidateHelper(username);

    setValids({ ...valids, username: valid });
    setNewUser({ ...newUser, username: username });

    if (autoUpdateUsername) {
      setAutoUpdateUsername(false);
    }
  };

  const _validatePassword = password => {
    const valid = password.length >= 8;
    setValids({ ...valids, password: valid });
    setNewUser({ ...newUser, password: password });
    if (valid && forcePasswordInvalid) {
      setForcePasswordInvalid(null);
    }
  };

  const _reset = () => {
    dispatch(resetErrors());
    setNewUser({
      is_public: true,
      accepted_TC: false,
    });
    setValids({});
    setForceEmailInvalid(null);
    setForcePasswordInvalid(null);
    history.push('/login');
  };

  const _createUser = () => {
    dispatch(createUser(newUser));
  };

  if (isCreatingUser || createUserFailure) {
    return <AccountCreated />;
  }

  const createDisabled =
    !valids.email ||
    !valids.username ||
    !valids.password ||
    !newUser.state ||
    !newUser.accepted_TC ||
    emailIsAvailable !== '' ||
    usernameIsAvailable !== '' ||
    (referralCodeExists === false && newUser.referral_code);

  return (
    <SignupWrapper>
      <Col style={{ flex: 0 }}>
        <SignupHeader
          label="Sign Up"
          logoSize={48}
          style={{ fontWeight: 'bold' }}
        />
        <AuthTextInput
          label="Email *"
          leftIcon={IoMailOutline}
          leftIconColor="var(--color-primary)"
          rightIcon={
            emailIsAvailable === '' && valids.email
              ? IoCheckmarkCircleOutline
              : null
          }
          rightIconColor={'var(--color-success'}
          errorText={emailIsAvailable || forceEmailInvalid}
          placeholder={'example@gmail.com'}
          onChangeText={text => _validateEmail(text)}
          autoFocus={true}
          onEndEditing={() => {
            if (!valids.email) setForceEmailInvalid('Invalid email');
          }}
          value={newUser.email}
          type="email"
        />
        <AuthTextInput
          label="Username *"
          leftIcon={IoPersonCircleOutline}
          leftIconColor="var(--color-primary)"
          rightIcon={
            usernameIsAvailable === '' && valids.username
              ? IoCheckmarkCircleOutline
              : () => <div style={{ height: '24px', width: '48px' }}></div>
          }
          rightIconColor={'var(--color-success)'}
          errorText={usernameIsAvailable}
          placeholder={'example'}
          type="text"
          maxLength={16}
          value={newUser.username}
          onChangeText={text => {
            if (!tracked.manualEditUsername) {
              Analytics.track(Analytics.events.MANUALLY_EDIT_USERNAME);
              setTracked({ ...tracked, manualEditUsername: true });
            }
            _validateUsername(text);
          }}
        />
        <AuthTextInput
          label="Password (min. 8 characters) *"
          leftIcon={IoLockClosedOutline}
          leftIconColor="var(--color-primary)"
          rightIcon={showPassword ? IoEyeOutline : IoEyeOffOutline}
          rightIconOnPress={() => {
            if (showPassword) {
              Analytics.track(Analytics.events.HIDE_PASSWORD);
            } else {
              Analytics.track(Analytics.events.SHOW_PASSWORD);
            }
            setShowPassword(!showPassword);
          }}
          rightIconColor={'var(--color-text)'}
          errorText={forcePasswordInvalid}
          placeholder={'Password'}
          type={showPassword ? 'text' : 'password'}
          passwordRules={'minlength: 8;'}
          onChangeText={text => _validatePassword(text)}
          onEndEditing={() => {
            if (!valids.password) {
              setForcePasswordInvalid('Invalid password');
            }
          }}
          value={newUser.password}
        />
        <span style={{ padding: '0 var(--space-sm)', width: '100%' }}>
          Region *{' '}
        </span>
        <Select
          options={STATE_OPTIONS.map(s => ({ label: s, value: s }))}
          onChange={opt => setNewUser({ ...newUser, state: opt.value })}
          value={
            newUser.state
              ? {
                  label: newUser.state,
                  value: newUser.state,
                }
              : null
          }
        />
        {newUser.referral_code !== undefined &&
          newUser.referral_code !== null &&
          newUser.referral_code !== '' && (
            <AuthTextInput
              label="Referral Code"
              leftIcon={IoGiftOutline}
              placeholder="CODE1"
              onChangeText={text => {
                dispatch(checkReferralCodeExists(text));
                setNewUser({ ...newUser, referral_code: text.toUpperCase() });
              }}
              errorText={
                referralCodeExists === false && "Referral code doesn't exist"
              }
              rightIcon={referralCodeExists ? IoCheckmarkCircleOutline : null}
              rightIconColor={'var(--color-success)'}
              value={newUser.referral_code || ''}
              containerStyle={{ marginTop: 'var(--space-md)' }}
            />
          )}
        <TOS
          acceptedTOS={newUser?.accepted_TC}
          setAcceptedTOS={val => {
            setNewUser({ ...newUser, accepted_TC: val });
          }}
        />
        <AuthButton
          rightIcon={IoArrowForwardCircle}
          disabled={createDisabled}
          onPress={_createUser}
          colorTheme={theme === 'dark' ? 'fg' : 'inverted'}
          containerStyle={{ flex: 0, marginTop: 'var(--space-md)' }}
          textStyle={
            theme === 'dark' && !createDisabled
              ? { color: 'var(--color-primary)' }
              : {}
          }
        >
          Create Account
        </AuthButton>
        <br />
        <SignupFooter
          onPress={() => _reset()}
          footerStyle={{ marginTop: 'var(--space-xs)', width: '100%' }}
        />
        {__DEV__ && (
          <>
            <AuthButton
              onPress={() => {
                const rng = Math.round(Math.random() * 12345).toString();
                setNewUser({
                  ...newUser,
                  email: `${rng}@example.com`,
                  username: rng,
                  password: 'password',
                  state: 'Ontario',
                });
              }}
            >
              DEV CREATE RANDOM DATA
            </AuthButton>
            <AuthButton onPress={_createUser}>DEV CREATE USER</AuthButton>
            <AuthButton
              onPress={() => {
                if (theme === 'dark') {
                  dispatch(setAppSetting('theme', 'light'));
                } else {
                  dispatch(setAppSetting('theme', 'dark'));
                }
              }}
            >
              TOGGLE THEME
            </AuthButton>
          </>
        )}
      </Col>
    </SignupWrapper>
  );
}
