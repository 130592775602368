import React, { useState } from 'react';
import { useSelector } from 'react-redux';

// utils
import { calculateRiskRequired, calculateBetPayout } from 'utils';

// components
import { Row, Col } from 'components/generic/Layout';
import CurrencyInput from './generic/CurrencyInput';
import OddsInput from './OddsInput';

export default function EditRiskResultOdds(props) {
  const reduxProps = useSelector(state => ({
    user: state.authReducer.user,
  }));
  const { user } = reduxProps;

  const [state, setState] = useState({
    risk_amount: props.defaultRisk || 0,
    result: props.defaultResult || 0,
    odds: props.defaultOdds,
  });

  let actualRiskOrBase = null;
  if (user && user.risk_or_base) {
    actualRiskOrBase = user.risk_or_base.toLowerCase();
  } else {
    actualRiskOrBase = 'base';
  }

  const _handleChange = (value, item) => {
    let newState = { ...state };
    newState[item] = value;
    if (item === 'odds') {
      if (newState['odds'] >= 2 || actualRiskOrBase === 'risk') {
        // set win (hold risk constant)
        newState.result = calculateBetPayout(
          newState.risk_amount,
          newState.odds
        );
      } else {
        // set risk
        newState.risk_amount = calculateRiskRequired(
          newState.result,
          newState.odds
        );
      }
    } else if (item === 'risk_amount') {
      newState.result = calculateBetPayout(newState.risk_amount, newState.odds);
    } else if (item === 'result') {
      newState.risk_amount = calculateRiskRequired(
        newState.result,
        newState.odds
      );
    }

    setState(newState);

    props.handleChange(newState);
  };

  return (
    <Col style={props.style}>
      <Row
        style={{
          alignItems: 'center',
          width: '100%',
          marginBottom: 'var(--space-xxs)',
        }}
      >
        <b
          style={{
            flex: props.labelFlex ? props.labelFlex : 0.5,
            textAlign: 'right',
            padding: '0 var(--space-sm)',
          }}
        >
          Risk
        </b>
        <CurrencyInput
          containerStyle={{
            flex: 2,
            // maxWidth: '350px',
            maxWidth: props.inputMaxWidth ? props.inputMaxWidth : '350px',
          }}
          inputRowStyle={{
            backgroundColor: 'var(--color-bg)',
          }}
          defaultValue={state.risk_amount}
          onChange={dollars => {
            _handleChange(parseFloat(dollars), 'risk_amount');
          }}
        />
      </Row>
      <Row
        style={{
          alignItems: 'center',
          width: '100%',
          margin: 'var(--space-xxs) 0',
        }}
      >
        <b
          style={{
            flex: props.labelFlex ? props.labelFlex : 0.5,
            textAlign: 'right',
            padding: '0 var(--space-sm)',
          }}
        >
          To Win
        </b>
        <CurrencyInput
          containerStyle={{
            flex: 2,
            maxWidth: props.inputMaxWidth ? props.inputMaxWidth : '350px',
          }}
          commaSeparate
          inputRowStyle={{
            backgroundColor: 'var(--color-bg)',
          }}
          defaultValue={state.result}
          onChange={dollars => {
            _handleChange(parseFloat(dollars), 'result');
          }}
        />
      </Row>
      <Row
        style={{
          alignItems: 'center',
          width: '100%',
          margin: 'var(--space-xxs) 0',
        }}
      >
        <b
          style={{
            flex: props.labelFlex ? props.labelFlex : 0.5,
            textAlign: 'right',
            padding: '0 var(--space-sm)',
          }}
        >
          Odds
        </b>
        <OddsInput
          inputContainerStyle={{
            flex: 2,
            maxWidth: props.inputMaxWidth ? props.inputMaxWidth : '350px',
          }}
          defaultValue={state.odds}
          handleChange={(odds, changed, isValid) => {
            if (isValid) _handleChange(odds, 'odds');
          }}
        />
      </Row>
    </Col>
  );
}
