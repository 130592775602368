import { Observable } from 'rxjs';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import { ajax } from 'rxjs/observable/dom/ajax';
import { BACKEND_API_URL, getHeaders } from 'utils';
import moment from 'moment';

import {
  GET_PICKS_STATS,
  getPicksStatsSuccess,
  getPicksStatsFailure,
} from 'actions';

export default function getPicksStats(action$, state$) {
  return action$.ofType(GET_PICKS_STATS).switchMap(action => {
    // send reference date so the backend know what timezone we're in
    let url =
      BACKEND_API_URL +
      `api/picks-stats/?ref_date=${moment().toISOString(true)}&`;

    if (action.date_after && action.date_before) {
      url += `date_after=${action.date_after.toISOString(
        true
      )}&date_before=${action.date_before.toISOString(true)}`;
    }

    return ajax
      .getJSON(url, getHeaders(state$))
      .map(data => getPicksStatsSuccess(data))
      .catch(error => Observable.of(getPicksStatsFailure(error.xhr)));
  });
}
