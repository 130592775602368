import React from 'react';
import { useSelector } from 'react-redux';
import { useMedia } from 'react-use';

// utils
import {
  toAmericanOdds,
  calculateBetPayout,
  roundOdds,
  kFormatter,
  roundNumber,
} from 'utils';

// components
import { Col } from 'components/generic/Layout';
import AutoColoredAmount from 'components/generic/AutoColoredAmount';

// the right side of bets, parlays, unsupported
// contains the risk, win, result, clv, etc
export default function BetStats(props) {
  const {
    side,
    odds,
    risk_amount,
    is_graded,
    result,
    clv_book,
    clv_ba,
    clv_applicable,
    type_name,
    is_live,
    live_stats,
    status,
  } = props.bet;
  const { hideLatestOdds, totalWidth } = props;

  const latest_odds = props.bet.latest_odds || {};

  const reduxProps = useSelector(state => ({
    user: state.authReducer.user,
  }));

  const oddsPreference = reduxProps.user.odds_preference;

  const payout = calculateBetPayout(risk_amount, odds);

  const isMobile = useMedia('(max-width: 625px)') || totalWidth < 960;

  // let blockWidth = isMobile ? '15vw' : '6vw';
  let blockWidth = isMobile ? '15%' : '6%';

  if (totalWidth > 960) {
    blockWidth = '9vw';
  }

  const blockStyle = {
    flex: `0 0 ${blockWidth}`,
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: totalWidth > 1000 ? '240px' : '140px',
  };

  return (
    <>
      <Col style={{ flex: 0, width: '25px' }} />
      {props.teamLogo ? (
        <Col desktop style={blockStyle}>
          {props.teamLogo}
        </Col>
      ) : (
        <></>
      )}

      {live_stats?.win_pct && !hideLatestOdds ? (
        <Col desktop style={blockStyle}>
          <p
            title={'In Game Win Probability'}
            style={{
              color:
                live_stats.win_pct >= 50
                  ? 'var(--color-dollar-green)'
                  : 'var(--color-dollar-red)',
              flex: 0.2,
              textAlign: 'center',
              margin: 0,
              fontWeight: 'bold',
            }}
          >
            {live_stats.win_pct + '%'}
          </p>
          <small>win probability</small>
        </Col>
      ) : (
        <></>
      )}
      {live_stats?.live_odds && !hideLatestOdds ? (
        <Col desktop style={blockStyle}>
          <p
            title={'Current live odds'}
            style={{
              flex: 0.2,
              textAlign: 'center',
              margin: 0,
              fontWeight: 'bold',
            }}
          >
            {live_stats.number ? live_stats.number : ''}&nbsp;
            {oddsPreference
              ? roundOdds(live_stats.live_odds)
              : toAmericanOdds(live_stats.live_odds)}
          </p>
          <small>live odds</small>
        </Col>
      ) : (
        <>
          {clv_applicable && !hideLatestOdds ? (
            <Col desktop style={blockStyle}>
              <AutoColoredAmount
                style={{ lineHeight: '1.1' }}
                bold={'true'}
                symbol={'%'}
              >
                {clv_book !== null ? clv_book * 100 : clv_ba * 100}
              </AutoColoredAmount>
              <small>clv</small>
            </Col>
          ) : (
            <></>
          )}
          {!hideLatestOdds &&
            (latest_odds.number || latest_odds.odds) &&
            !is_live && (
              <Col desktop style={blockStyle}>
                <b style={{ lineHeight: '1.1' }}>
                  {latest_odds.number
                    ? `${
                        type_name === 'Spread'
                          ? latest_odds.number > 0
                            ? '+'
                            : ''
                          : side === 'Over'
                          ? 'o'
                          : 'u'
                      }${roundNumber(latest_odds.number)} `
                    : ''}
                  {oddsPreference === 0
                    ? toAmericanOdds(latest_odds.odds)
                    : roundOdds(latest_odds.odds)}
                </b>
                <small>latest odds</small>
              </Col>
            )}
        </>
      )}

      {is_graded && (
        <Col
          style={{
            ...blockStyle,
            borderLeft: '1px solid var(--color-bg)',
          }}
        >
          {status === 'VOID' ? (
            <h6 style={{ margin: 0 }}>
              <b style={{ lineHeight: '1.1' }}>VOID</b>
            </h6>
          ) : (
            <h6 style={{ margin: 0 }}>
              <AutoColoredAmount
                bold={'true'}
                symbol={'$'}
                style={{ lineHeight: '1.1' }}
              >
                {result}
              </AutoColoredAmount>
            </h6>
          )}
        </Col>
      )}
      {/*
      {!is_graded && live_stats && (
        <Col
          style={{
            ...blockStyle,
            borderLeft: '1px solid var(--color-bg)',
          }}
        >
          <h6 style={{ margin: 0 }}>
            <AutoColoredAmount
              bold={'true'}
              symbol={'$'}
              style={{ lineHeight: '1.1' }}
            >
              {live_stats.expected_value}
            </AutoColoredAmount>
          </h6>
        </Col>
      )}
      */}

      {!is_graded && ( // && !live_stats && (
        <>
          <Col
            style={{
              ...blockStyle,
              borderLeft: '1px solid var(--color-bg)',
            }}
          >
            <b style={{ lineHeight: '1.1' }}>
              $
              {risk_amount >= 10
                ? kFormatter(Math.round(risk_amount))
                : risk_amount}
            </b>
            <small>risk</small>
          </Col>
          <Col style={blockStyle}>
            <b style={{ lineHeight: '1.1' }}>
              ${payout >= 10 ? kFormatter(Math.round(payout)) : payout}
            </b>
            <small>to win</small>
          </Col>
        </>
      )}
    </>
  );
}
