import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import styled from 'styled-components';

// components
import { AuthTextInput } from 'components/AuthTextInput';
import CustomSelect from 'components/generic/Select';
import AddEditTags from 'components/AddEditTags';
import CalendarInput from 'components/generic/CalendarInput';
import Textarea from 'components/generic/Textarea';
import { AuthButton } from 'components/AuthButton';

// constants
import { AUTH_BUTTON_DEFAULT_CONTAINER_STYLE } from 'utils';

// actions
import {
  getUser,
  resetTransactionEditDel,
  updateTransaction,
  getTransactionSummary,
} from 'actions';

const InputsWrapper = styled.div`
  flex: 1 0 350px;
  height: 100%;
  display: flex;
  flex-flow: column wrap;
  justify-content: flex-start;
  align-items: center;
  & > div {
    flex: 0;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    max-width: 500px;
    margin: var(--space-xs) 0;
  }
  & > div > b {
    flex: 0.5;
    text-align: right;
    padding: 0 var(--space-sm);
  }
`;

export default function EditTransaction(props) {
  const { passedTransaction } = props;
  const reduxProps = useSelector(state => ({
    user: state.authReducer.user,
    isUpdatingTransaction: state.transactionReducer.isUpdatingTransaction,
    updateTransactionFailure: state.transactionReducer.updateTransactionFailure,
    updateTransactionSuccess: state.transactionReducer.updateTransactionSuccess,
  }));

  const {
    user,
    isUpdatingTransaction,
    updateTransactionFailure,
    updateTransactionSuccess,
  } = reduxProps;

  const dispatch = useDispatch();

  const intialTransactionType =
    passedTransaction.transaction_type.charAt(0).toUpperCase() +
    passedTransaction.transaction_type.slice(1);

  const [transactionType, setTransactionType] = useState(intialTransactionType);
  const [date, setDate] = useState(moment(passedTransaction.date).toDate());
  const [book, setBook] = useState(passedTransaction.book);
  const [amount, setAmount] = useState(passedTransaction.amount);
  const [description, setDescription] = useState(passedTransaction.description);
  const [tags, setTags] = useState(passedTransaction.tags);

  function resetFields() {
    setDate(moment(passedTransaction.date).toDate());
    setBook(passedTransaction.book);
    setAmount(passedTransaction.amount);
    setDescription(passedTransaction.description);
    setTags(passedTransaction.tags);
  }

  useEffect(() => {
    setTransactionType(
      passedTransaction.transaction_type.charAt(0).toUpperCase() +
        passedTransaction.transaction_type.slice(1)
    );
    setDate(moment(passedTransaction.date).toDate());
    setBook(passedTransaction.book);
    setAmount(passedTransaction.amount);
    setDescription(passedTransaction.description);
    setTags(passedTransaction.tags);
  }, [passedTransaction]);

  useEffect(() => {
    if (transactionType !== 'Adjustment') setAmount(Math.abs(amount));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionType]);

  useEffect(() => {
    if (updateTransactionSuccess) {
      dispatch(getTransactionSummary());
      dispatch(resetTransactionEditDel());
      props.hideModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateTransactionSuccess]);

  function submit() {
    if (!user) {
      dispatch(getUser());
    }
    let newAmount = amount.toString().replace('$', '');
    if (transactionType === 'Withdraw' && newAmount[0] !== '-') {
      newAmount = '-' + newAmount;
    }

    let newTransaction = {
      id: passedTransaction.id,
      user: user.id,
      book: book,
      transaction_type: transactionType.toLowerCase(),
      amount: newAmount,
      date: date,
      description: description,
      tags: tags,
    };

    dispatch(updateTransaction(newTransaction));
  }

  return (
    <div style={{ maxWidth: '750px', margin: 'auto' }}>
      <br />
      <InputsWrapper>
        <div>
          <b>Type</b>
          <div style={{ flex: 2 }}>
            <CustomSelect
              onChange={itemValue => {
                setAmount(amount.toString().replace('-', ''));
                console.log(itemValue);
                setTransactionType(itemValue.value);
              }}
              placeholder={intialTransactionType}
              value={
                transactionType
                  ? { value: transactionType, label: transactionType }
                  : ''
              }
              options={[
                {
                  label: 'Initial',
                  value: 'Initial',
                },
                {
                  label: 'Withdraw',
                  value: 'Withdraw',
                },
                {
                  label: 'Deposit',
                  value: 'Deposit',
                },
                {
                  label: 'Bonus',
                  value: 'Bonus',
                },
                {
                  label: 'Adjustment',
                  value: 'Adjustment',
                },
              ]}
            />
          </div>
        </div>
        <div>
          <b>Date</b>
          <div style={{ flex: 2 }}>
            <CalendarInput value={date} onChange={date => setDate(date)} />
          </div>
        </div>
        <div>
          <b>Amount</b>
          <div style={{ flex: 2 }}>
            <AuthTextInput
              type="number"
              value={amount}
              step={0.01}
              onChangeText={newAmount => setAmount(newAmount)}
              inputRowStyle={{
                backgroundColor: 'var(--color-bg)',
              }}
            />
          </div>
        </div>
        <div>
          <b>Description</b>
          <div style={{ flex: 2 }}>
            <Textarea
              maxLength={140}
              inputStyle={{
                backgroundColor: 'var(--color-bg)',
                height: 'var(--space-xl)',
                marginTop: 'var(--space-sm)',
              }}
              value={description}
              placeholder={'Description (ex. Initial site balance)'}
              onChange={text => {
                setDescription(text.target.value);
              }}
            />
          </div>
        </div>
        <div>
          <AddEditTags
            existingTags={tags}
            onTagsChange={newTags => setTags(newTags)}
          />
        </div>
        <div>
          <AuthButton
            colorTheme="danger"
            containerStyle={AUTH_BUTTON_DEFAULT_CONTAINER_STYLE}
            disabled={isUpdatingTransaction}
            onPress={() => {
              resetFields();
              dispatch(resetTransactionEditDel());
              props.hideModal();
            }}
          >
            Cancel
          </AuthButton>
          <AuthButton
            containerStyle={AUTH_BUTTON_DEFAULT_CONTAINER_STYLE}
            disabled={isUpdatingTransaction || updateTransactionFailure}
            isLoading={isUpdatingTransaction}
            onPress={() => submit()}
          >
            Save
          </AuthButton>
        </div>
      </InputsWrapper>
      {updateTransactionFailure && (
        <h6 style={{ color: 'var(--color-danger)', textAlign: 'center' }}>
          There was an error updating your transaction. Please try again later.
        </h6>
      )}
    </div>
  );
}
