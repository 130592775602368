import { Observable } from 'rxjs';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import { ajax } from 'rxjs/observable/dom/ajax';
import { BACKEND_API_URL, getHeaders } from 'utils';

import {
  GET_PURCHASE_SESSION,
  getPurchaseSessionSuccess,
  getPurchaseSessionFailure,
} from 'actions';

export default function getPurchaseSession(action$, state$) {
  return action$.ofType(GET_PURCHASE_SESSION).switchMap(action => {
    return ajax
      .getJSON(
        BACKEND_API_URL + 'api/purchase_sessions/' + action.id,
        getHeaders(state$)
      )
      .map(data => getPurchaseSessionSuccess(data))
      .catch(error => Observable.of(getPurchaseSessionFailure(error.xhr)));
  });
}
