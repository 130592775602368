import { Observable } from 'rxjs';
import 'rxjs/add/operator/exhaustMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import { ajax } from 'rxjs/observable/dom/ajax';
import { BACKEND_API_URL, getHeaders } from 'utils';

import {
  CREATE_SUBSCRIPTION,
  createSubscriptionSuccess,
  createSubscriptionFailure,
} from 'actions';

export default function createSubscription(action$, state$) {
  return action$.ofType(CREATE_SUBSCRIPTION).exhaustMap(action => {
    let data = { purchase_session_id: action.purchaseSessionID };
    if (action.paymentMethodID) {
      data['payment_method_id'] = action.paymentMethodID;
    }
    if (action.subscriptionID) {
      data['sub_id'] = action.subscriptionID;
    }

    return ajax
      .post(
        BACKEND_API_URL + 'api/purchase_sessions/subscribe/',
        data,
        getHeaders(state$)
      )
      .map(data => createSubscriptionSuccess(data))
      .catch(error => Observable.of(createSubscriptionFailure(error.xhr)));
  });
}
