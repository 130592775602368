import { combineEpics } from 'redux-observable';

import getFeatured from 'epics/featured/getFeatured.epic';
import getRecommendedUsers from 'epics/featured/getRecommendedUsers.epic';
import getRecommendedUsersNext from 'epics/featured/getRecommendedUsersNext.epic';
import getHammerUsers from 'epics/featured/getHammerUsers.epic';
import getHammerUsersNext from 'epics/featured/getHammerUsersNext.epic';

export default combineEpics(
  getFeatured,
  getRecommendedUsers,
  getRecommendedUsersNext,
  getHammerUsers,
  getHammerUsersNext
);
