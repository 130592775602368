import React from 'react';
import styled from 'styled-components';

const StyledProfilePic = styled.img`
  width: 24px;
  height: 24px;
  box-sizing: border-box;
  max-width: 100%;
  border-radius: 50%;
  display: block;

  background: var(--color-bg);

  border: 2px solid var(--color-primary);

  &:hover + .user-popover {
    opacity: 1;
    pointer-events: auto;
  }
`;

export default function ProfilePic(props) {
  const { user } = props;
  return (
    <StyledProfilePic
      style={props.style}
      src={
        user?.profile_picture
          ? user.profile_picture
          : 'https://betstamp-public.sfo2.digitaloceanspaces.com/default-account.png'
      }
      alt={`${user?.username}'s profile`}
    />
  );
}

export function InfluencerPic(props) {
  return (
    <StyledProfilePic
      style={props.style}
      src={
        props.src
          ? props.src
          : 'https://betstamp-public.sfo2.digitaloceanspaces.com/default-account.png'
      }
      alt={props.altText}
    />
  );
}
