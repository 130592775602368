import React from 'react';
import styled from 'styled-components';
import { IoLogoTwitter } from 'react-icons/io5';

import { Row, Col } from 'components/generic/Layout';
import AutoColoredAmount from 'components/generic/AutoColoredAmount';
import FollowBtn from 'components/FollowBtn';
import ProfilePic from 'components/generic/ProfilePic';
import ContentLoader from 'components/generic/ContentLoader';

import { FEATURE_CONTENT_HEIGHT, FEATURE_CONTENT_WIDTH } from 'utils';

export default function FollowUser(props) {
  const { user, isLoading } = props;
  const twitterLogoSize = 12;

  if (isLoading) {
    return (
      <ContentLoader
        height={`${FEATURE_CONTENT_HEIGHT}px`}
        style={{
          minWidth: `${FEATURE_CONTENT_WIDTH}px`,
          borderRadius: 'var(--std-border-radius)',
          margin: 0,
          marginRight: 'var(--space-sm)',
          marginBottom: 'var(--space-sm)',
        }}
      />
    );
  }

  function statsGenerator(statSymbolMap) {
    let result = [];
    for (const [stat, symbol] of Object.entries(statSymbolMap)) {
      result.push(
        <span
          key={`auto-colored-amount-${user.id}-${stat}`}
          style={{ color: 'var(--color-primary)' }}
        >
          <AutoColoredAmount symbol={symbol}>
            {user?.stats[stat]}
          </AutoColoredAmount>
          {' ' + stat.toUpperCase()}
        </span>
      );
    }

    return result;
  }

  return (
    <Wrapper style={props.style}>
      <Row
        style={{
          alignItems: 'center',
          justifyContent: 'space-around',
          height: '100%',
        }}
      >
        <Col style={{ justifyContent: 'center', alignItems: 'center' }}>
          <ProfilePic
            user={user}
            style={{ height: '64px', width: '64px' }}
            src={
              user.profile_picture
                ? user.profile_picture
                : 'https://betstamp-public.sfo2.digitaloceanspaces.com/default-account.png'
            }
            alt={`${user.username}'s profile`}
            title={`${user.username}'s profile`}
          />
          <FollowBtn user={user} btnTheme="slim" />
        </Col>
        <Col style={{ alignItems: 'center' }}>
          <h5
            style={{
              fontWeight: 'bold',
              marginBottom: 'var(--space-xs)',
              marginTop: 0,
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              maxWidth: '128px',
            }}
          >
            {user.username}
          </h5>
          {user.twitter !== null && user.twitter !== '' && (
            <Row style={{ alignItems: 'center', position: 'relative' }}>
              <IoLogoTwitter
                size={twitterLogoSize}
                color={'var(--color-text)'}
                style={{
                  position: 'absolute',
                  backgroundColor: 'var(--color-fg)',
                  padding: '--(space-xxxs)',
                  borderRadius: 32,
                  left: 0,
                }}
              />
              <small
                style={{
                  paddingLeft: twitterLogoSize,
                }}
              >
                @{user.twitter}
              </small>
            </Row>
          )}
          {statsGenerator({ roi: '%', clv: '%' })}
          <span style={{ color: 'var(--color-primary)' }}>
            <span
              style={{
                color:
                  user.stats.total_profit / user.stats.bet_size > 0
                    ? 'var(--color-dollar-green)'
                    : 'var(--color-dollar-red)',
              }}
            >
              {user.stats.total_profit / user.stats.bet_size > 0 ? '+' : ''}
            </span>
            <AutoColoredAmount symbol="">
              {Math.round(
                (user.stats.total_profit / user.stats.bet_size) * 10
              ) / 10}
            </AutoColoredAmount>{' '}
            UNITS
          </span>
          <span>{user?.stats?.record}</span>
        </Col>
      </Row>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  border-radius: var(--std-border-radius);
  padding: 0 var(--space-sm);
  border: 2px solid var(--color-primary);
  background-color: var(--color-fg);
  transition: all var(--std-transition);
  margin: 0;
  margin-right: var(--space-sm);
  margin-bottom: var(--space-sm);
  min-width: ${FEATURE_CONTENT_WIDTH}px;

  position: relative;

  &:hover {
    box-shadow: 0 2px 8px 0 var(--color-shadow);
  }

  &:active {
    box-shadow: inset 0 0 0 100vh rgba(0, 0, 0, 0.1);
  }
`;
