import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { IoCloseCircleOutline, IoAdd } from 'react-icons/io5';

// components
import ModalWrapper from 'components/generic/ModalWrapper';
import { Row, Col } from 'components/generic/Layout';
import { AuthButton, IconButton } from 'components/AuthButton';
import { AuthTextInput } from 'components/AuthTextInput';
import { AUTH_BUTTON_DEFAULT_CONTAINER_STYLE } from 'utils';

export default function AddEditTags(props) {
  const reduxProps = useSelector(state => ({
    user: state.authReducer.user,
    recentTags: state.picksReducer.recentTags,
  }));
  const { user, recentTags } = reduxProps;

  const [showModal, setShowModal] = useState(false);
  const [mode, setMode] = useState('view');
  const [tags, setTags] = useState([...props.existingTags]);
  const [editTagOG, setEditTagOG] = useState('');
  const [firstTime, setFirstTime] = useState(true);
  const [showRecentTagsModal, setShowRecentTagsModal] = useState(false);

  useEffect(() => {
    if (firstTime) setFirstTime(false);
    else props.onTagsChange(tags);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tags]);

  let allRecentTags = [...new Set([...recentTags, ...user.recent_tags])];

  let filteredRecentTags = [...new Set([...recentTags, ...user.recent_tags])];
  filteredRecentTags = filteredRecentTags.filter(t => !tags.includes(t));

  return (
    <>
      {showModal && (
        <ModalWrapper
          modalIsOpen={showModal}
          onClose={() => {
            setMode('view');
            setShowModal(false);
          }}
          onRequestClose={() => {
            setMode('view');
            setShowModal(false);
          }}
          modalSize={'small'}
          title={mode === 'edit' ? 'Edit Tag' : 'Add Tag'}
        >
          <AddEditModal
            defaultValue={mode === 'edit' ? editTagOG : ''}
            mode={mode}
            dismissModal={() => {
              setMode('view');
              setShowModal(false);
            }}
            onDone={newTag => {
              if (newTag.trim() !== '') {
                newTag = newTag.trim();
                setTags(tags => [...tags, newTag.replace(/!/g, '')]);
              }
              setMode('view');
              setShowModal(false);
            }}
            onDoneEdit={newEditTag => {
              if (newEditTag.trim() === '') {
                setTags(tags => [...tags.filter(tag => tag !== editTagOG)]);
              } else {
                newEditTag = newEditTag.trim();
                setTags(tags => [
                  ...tags.filter(tag => tag !== editTagOG),
                  newEditTag.replace(/^!*/g, ''),
                ]);
              }
              setMode('view');
              setShowModal(false);
            }}
          />
        </ModalWrapper>
      )}

      <ModalWrapper
        modalIsOpen={showRecentTagsModal}
        onClose={() => setShowRecentTagsModal(false)}
        onRequestClose={() => setShowRecentTagsModal(false)}
        modalSize={'small'}
      >
        <RecentTagsModal
          recentTags={allRecentTags}
          tags={tags}
          addTag={t => setTags(tags => [...tags, t])}
          removeTag={t => {
            setTags(tags => [...tags.filter(tag => tag !== t)]);
          }}
          addNewTag={() => {
            setShowRecentTagsModal(false);
            setMode('add');
            setShowModal(true);
          }}
          dismissModal={() => setShowRecentTagsModal(false)}
        />
      </ModalWrapper>

      {tags.length > 0 && (
        <Row style={{ flexWrap: 'wrap', justifyContent: 'flex-start' }}>
          {tags.map(t => (
            <Tag
              tag={t}
              key={`added-tag-${t}`}
              onDelete={() => {
                setTags(tags => [...tags.filter(tag => tag !== t)]);
              }}
            />
          ))}
        </Row>
      )}

      {filteredRecentTags.length > 0 && tags.length < 5 && (
        <Row
          style={{
            flexWrap: 'wrap',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}
        >
          <span>Recent Tags: </span>
          {filteredRecentTags
            .slice(0, 5)
            .filter(rtag => !tags.includes(rtag))
            .map(t => (
              <AuthButton
                containerStyle={{
                  margin: 'var(--space-xs)',
                  minWidth: 'fit-content',
                }}
                onPress={() => setTags(tags => [...tags, t])}
              >
                {t}
              </AuthButton>
            ))}
        </Row>
      )}
      <Row>
        <AuthButton
          onPress={() => setShowRecentTagsModal(true)}
          btnTheme="borderless"
        >
          More Recent Tags
        </AuthButton>
        <AuthButton
          leftIcon={IoAdd}
          leftIconSize={18}
          colorTheme="inverted"
          onPress={() => {
            setMode('add');
            setShowModal(true);
          }}
        >
          Create Tag
        </AuthButton>
      </Row>
    </>
  );
}

function Tag(props) {
  return (
    <AuthButton
      rightIcon={IoCloseCircleOutline}
      colorTheme="inverted"
      btnTheme="slim"
      containerStyle={{
        minWidth: 'fit-content',
        margin: 'var(--space-xs)',
      }}
      btnStyle={{ padding: 0 }}
      onPress={props.onDelete}
    >
      {props.tag}
    </AuthButton>
  );
}

function RecentTagsModal(props) {
  const { recentTags, tags } = props;
  return (
    <>
      <Row style={{ alignItems: 'center' }}>
        <h4 style={{ margin: 0, flex: 2.5 }}>25 Most Recent Tags</h4>
        <AuthButton
          containerStyle={AUTH_BUTTON_DEFAULT_CONTAINER_STYLE}
          onPress={() => props.dismissModal()}
        >
          DONE
        </AuthButton>
      </Row>
      <br />
      {tags.length >= 5 && (
        <span>
          You can only set 5 tags. Click a selected tag to deselect it.
        </span>
      )}
      {recentTags.length === 0 && (
        <>
          <span style={{ marginBottom: 'var(--space-md)' }}>
            You have no recent tags
          </span>
          <AuthButton onPress={props.addNewTag}>Create New Tag</AuthButton>
        </>
      )}
      <Row style={{ justifyContent: 'flex-start' }}>
        {recentTags.map(tag => (
          <AuthButton
            key={`recent-tag-modal-${tag}`}
            containerStyle={{
              margin: 'var(--space-xs)',
              maxWidth: 'fit-content',
            }}
            colorTheme={tags.includes(tag) ? 'inverted' : 'primary'}
            onPress={
              tags.includes(tag)
                ? () => props.removeTag(tag)
                : () => props.addTag(tag)
            }
            disabled={tags.length >= 5 && !tags.includes(tag)}
          >
            {tag}
          </AuthButton>
        ))}
      </Row>
    </>
  );
}

function AddEditModal(props) {
  const { mode } = props;
  const [newTag, setNewTag] = useState(
    props.defaultValue ? props.defaultValue : ''
  );
  return (
    <>
      <br />
      <AuthTextInput
        type="text"
        defaultValue={newTag}
        placeholder={'Type your tag here'}
        onChangeText={newTag => setNewTag(newTag)}
        maxLength={16}
        inputRowStyle={{ backgroundColor: 'var(--color-bg)' }}
      />
      <br />
      <Row>
        <AuthButton
          containerStyle={{
            ...AUTH_BUTTON_DEFAULT_CONTAINER_STYLE,
          }}
          onPress={
            mode === 'edit'
              ? () => {
                  props.onDoneEdit(newTag);
                }
              : () => {
                  setNewTag('');
                  props.onDone(newTag);
                }
          }
        >
          Save
        </AuthButton>
        <AuthButton
          colorTheme="danger"
          containerStyle={{
            ...AUTH_BUTTON_DEFAULT_CONTAINER_STYLE,
          }}
          onPress={() => props.dismissModal()}
        >
          Cancel
        </AuthButton>
      </Row>
    </>
  );
}
