import React from 'react';
//import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import 'index.css';
import App from 'App';
import * as serviceWorker from './serviceWorker';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import { BACKEND_API_URL, PLATFORM } from 'utils';

// redux
import { createStore, applyMiddleware } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import { Provider } from 'react-redux';
import rootReducer from './reducers/index';
import { rootEpic } from './epics/index';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import { PersistGate } from 'redux-persist/integration/react';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import { amplitudeMiddleware } from 'amplitude/amplitudeMiddleware';

// react query
import { defaultQueryFn, defaultMutationFn } from './react-query';

// actions
import { resetToken } from 'actions';

const rootPersistConfig = {
  key: 'root',
  storage,
  whitelist: [],
  stateReconciler: autoMergeLevel2,
};

const persistedReducer = persistReducer(rootPersistConfig, rootReducer);

const epicMiddleware = createEpicMiddleware();

const logoutMiddleware = store => next => action => {
  // unfortunately, the reducers are inconsistent. Some use payload, some error
  if (
    (action.payload && action.payload.status === 403) ||
    (action.error && action.error.status === 403)
  ) {
    // the ProtectedRoute section of App.js ends up taking care of the redirect
    store.dispatch(resetToken());
  }
  next(action);
};

const store = createStore(
  persistedReducer,
  applyMiddleware(logoutMiddleware, epicMiddleware, amplitudeMiddleware)
);
const persistor = persistStore(store);

epicMiddleware.run(rootEpic);

const container = document.getElementById('root');
const root = createRoot(container);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: { queryFn: defaultQueryFn },
    mutations: { mutationFn: defaultMutationFn },
  },
});

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <QueryClientProvider client={queryClient}>
          <App />
        </QueryClientProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
