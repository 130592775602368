import React, { useMemo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

// components
import {
  OnboardingWrapper,
  BlueBubble,
  BookListItem,
} from 'components/auth/onboarding';
import { Col, Row } from 'components/generic/Layout';
import { AuthButton } from 'components/AuthButton';
import ModalWrapper from 'components/generic/ModalWrapper';
import ActivityIndicator from 'components/generic/ActivityIndicator';

// utils
import { REGULATED_STATES, loadImageFirst } from 'utils';

// actions
import { postBooks } from 'actions';

export default function SelectSBRookie(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const reduxProps = useSelector(state => ({
    user: state.authReducer.user,
    affiliates: state.affiliatesReducer.affiliates,
  }));
  const { affiliates, user } = reduxProps;
  const [selectedBooks, setSelectedBooks] = useState({});
  const [selectSportsBook, setSelectSportsBook] = useState(null);
  const [booksBanners, setBooksBanners] = useState(null);

  useEffect(() => {
    if (user?.books) {
      const newBooks = user.books.reduce((obj, item) => {
        return {
          ...obj,
          [item.id]: item,
        };
      }, {});
      setSelectedBooks(newBooks);
    }
  }, [user.books]);

  useEffect(
    // Post selected books when users press back/forward browser button
    () =>
      history.listen(_ => {
        if (history.action === 'POP') {
          dispatch(postBooks(Object.values(selectedBooks)));
        }
      }),
    [selectedBooks]
  );

  const _toggleBook = item => {
    const newSelectedBooks = selectedBooks ? { ...selectedBooks } : {};
    if (item.id in newSelectedBooks) {
      delete newSelectedBooks[item.id];
    } else {
      newSelectedBooks[item.id] = item;
    }
    setSelectedBooks(newSelectedBooks);
  };

  const books = useMemo(() => {
    if (REGULATED_STATES[user.state]) {
      return affiliates.filter(
        b => b.states.includes(user.state) && !b.is_casino
      );
    }
    return affiliates.filter(b => !b.is_casino);
  }, [affiliates, user.state]);

  useEffect(() => {
    if (books?.length) {
      const booksToShow = books.slice(0, 2);
      const promiseHandler = () =>
        setBooksBanners(booksToShow.map(book => _renderBook({ book })));

      loadImageFirst(booksToShow.map(book => book.open_banner)).then(
        promiseHandler,
        promiseHandler
      );
    }
  }, [books, selectedBooks]);

  const _renderBook = ({ book }) => (
    <BookListItem
      key={`selectSBRookie-onboarding-${book.id}-${book.name}`}
      book={book}
      selected={book.id in selectedBooks}
      onPressCheckmark={() => {
        if (book.id in selectedBooks) {
          _toggleBook(book);
        } else {
          setSelectSportsBook(book);
        }
      }}
    />
  );

  return (
    <OnboardingWrapper
      nextScreen="/onboarding/find-users"
      onNext={() => dispatch(postBooks(Object.values(selectedBooks)))}
      showSkip
    >
      {selectSportsBook && (
        <ModalWrapper
          modalIsOpen={Boolean(selectSportsBook)}
          onClose={() => {
            setSelectSportsBook(false);
          }}
          onRequestClose={() => {
            setSelectSportsBook(false);
          }}
          modalSize={'small'}
          title={'Confirmation'}
        >
          <Col
            style={{
              height: 'calc(100% - 30px)',
              alignItems: 'initial',
              paddingBottom: 'var(--space-sm)',
            }}
          >
            <span
              style={{
                display: 'flex',
                flex: 1,
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {`I have an account with ${selectSportsBook.name}`}
            </span>
            <Row style={{ flex: 0 }}>
              <AuthButton
                colorTheme="danger"
                onPress={() => setSelectSportsBook(false)}
              >
                Cancel
              </AuthButton>
              <AuthButton
                btnTheme="borderless"
                containerStyle={{
                  marginLeft: 'var(--space-sm)',
                }}
                onPress={() => {
                  _toggleBook(selectSportsBook);
                  setSelectSportsBook(false);
                }}
              >
                Confirm
              </AuthButton>
            </Row>
          </Col>
        </ModalWrapper>
      )}
      <Col style={{ flex: 0, width: '100%' }}>
        <BlueBubble style={{ marginTop: 'var(--space-md)' }}>
          <h5
            style={{
              marginTop: 'var(--space-md)',
              marginBottom: 'var(--space-md)',
              color: 'white',
            }}
          >
            The first step to successful betting is opening multiple accounts so
            you always get the{' '}
            <span style={{ fontWeight: 'bold' }}>best odds</span>
          </h5>
        </BlueBubble>
        <span style={{ padding: 'var(--space-sm) var(--space-xs)' }}>
          To start, sign up at the sportsbooks below. By clicking the link, you
          get access to betstamp's exclusive signup bonus. Click the blue circle
          to add it to your books.
        </span>
      </Col>
      <Col
        style={{
          flex: 0,
          marginTop: 'var(--space-xs)',
          maxWidth: 'min(100%, 400px)',
        }}
      >
        {booksBanners || <ActivityIndicator size={2} />}
      </Col>
      <Col>
        {affiliates.length > 2 ? (
          <AuthButton
            colorTheme="text"
            btnTheme="borderless"
            containerStyle={{
              display: 'flex',
              alignItems: 'flex-end',
            }}
            onPress={() => {
              dispatch(postBooks(Object.values(selectedBooks)));
              history.push('/onboarding/view-allSB');
            }}
          >
            View all {affiliates.length} betstamp preferred sportsbooks
          </AuthButton>
        ) : (
          <span>Sorry, No Sportbooks Available in Your Region</span>
        )}
      </Col>
    </OnboardingWrapper>
  );
}
