import React, { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  useQuery,
  useMutation,
  useQueryClient,
  useInfiniteQuery,
} from '@tanstack/react-query';
import { IoCopyOutline } from 'react-icons/io5';
import { useCopyToClipboard } from 'react-use';

// utils
import { humanDatetime } from 'utils';

// components
import StaffOnboardingBase from 'components/StaffOnboardingBase';
import { Row, Col } from 'components/generic/Layout';
import Select from 'components/generic/Select';
import { AuthTextInput } from 'components/AuthTextInput';
import { AuthButton, IconButton } from 'components/AuthButton';
import CalendarInput from 'components/generic/CalendarInput';
import HostSelector from 'components/onboarding/HostSelector';
import GenericAdminFilter from 'components/onboarding/GenericAdminFilter';
import ModalWrapper from 'components/generic/ModalWrapper';
import ConfirmDelete from 'components/modals/ConfirmDelete';
import ActivityIndicator from 'components/generic/ActivityIndicator';
import PaymentList from 'components/onboarding/PaymentList';

const SESSION_TYPE_LABELS = {
  OPEN: 'Open',
  INVITE_ONLY: 'Invite Only',
  PLACEHOLDER: 'Placeholder',
};

export default function AllSessions(params) {
  return (
    <>
      <StaffOnboardingBase
        endpoint="onboarding-sessions"
        modelName="Session"
        headings={['id', 'Name', 'Start', 'Attendees', 'Type', 'Active']}
        objKeys={[
          'id',
          'name',
          'date',
          'num_attendees',
          'session_type',
          'is_active',
        ]}
        sortKeys={{
          date: 'Start',
          Start: 'date',
        }}
        formatText={{
          date: humanDatetime,
          original_date: humanDatetime,
          created_at: humanDatetime,
          is_active: t => (t ? '✓' : '✕'),
          referral_code_code: obj => (obj ? `${obj?.code}` : null),
        }}
        linkTo={{ id: t => `/staff-session/${t}` }}
        PanelChildren={Panel}
        searchPlaceholder="Search by id or name"
        showSearch={true}
        FilterChildren={FilterChildren}
        DetailsPanelExtra={DetailsExtra}
        panelTabs={['Details', 'Edit', 'Links', 'Signups']}
        defaultSelectedQuickFilter="All"
        extraButtons={[
          {
            text: 'All',
            urlParams: {
              date_after: null,
              date_before: null,
              sort: null,
            },
          },
          {
            text: 'Yesterday',
            urlParams: {
              date_after: zeroDate(-1),
              date_before: zeroDate(0),
              sort: JSON.stringify(['date']),
            },
          },
          {
            text: 'Today',
            urlParams: {
              date_after: zeroDate(0),
              date_before: zeroDate(1),
              sort: JSON.stringify(['date']),
            },
          },
          {
            text: 'Tomorrow',
            urlParams: {
              date_after: zeroDate(1),
              date_before: zeroDate(2),
              sort: JSON.stringify(['date']),
            },
          },
        ]}
      />
    </>
  );
}

function DetailsExtra(props) {
  const session = props.selectedObj;

  const [showCancelModal, setShowCancelModal] = useState(false);

  const queryClient = useQueryClient();
  const deleteMut = useMutation({
    mutationKey: `onboarding-sessions-delete-${session?.id}`,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [
          {
            endpoint: 'onboarding-sessions',
            //urlParams: urlParams,
          },
        ],
      });
      setShowCancelModal(false);
    },
  });

  return (
    <>
      <ModalWrapper
        modalIsOpen={showCancelModal}
        onClose={() => setShowCancelModal(false)}
        onRequestClose={() => setShowCancelModal(false)}
        title="Confirm Cancellation"
        modalSize="small"
      >
        <ConfirmDelete
          onDelete={() => {
            deleteMut.mutate({
              endpoint: `onboarding-sessions/${session.id}`,
              method: 'DELETE',
            });
          }}
          dismissModal={() => setShowCancelModal(false)}
          isDeleting={deleteMut.isLoading}
        >
          Are you sure you want to cancel this session?
          {!!session.num_signups && (
            <>
              <br />
              <br />
              <b>
                {session.num_signups} attendee(s) will need to re-schedule an
                appointment. They will be automatically sent an email.
              </b>
            </>
          )}
          {deleteMut.isError && (
            <>
              <br />
              <br />
              <span style={{ color: 'var(--color-danger)' }}>
                {deleteMut?.error?.message}
              </span>
            </>
          )}
        </ConfirmDelete>
      </ModalWrapper>
      {/*<Row style={{ width: '100%', justifyContent: 'space-between' }}>
        <b>Invite Link</b>
        <span>
          {window.location.origin}/intake?session_id={session?.id}
        </span>
      </Row>*/}
      <AuthButton
        containerStyle={{ marginTop: 'var(--space-xl)' }}
        colorTheme="danger"
        onPress={() => setShowCancelModal(true)}
      >
        Cancel Session
      </AuthButton>
    </>
  );
}

function FilterChildren(props) {
  const { urlParams } = props;

  const defaultFilters = useMemo(() => {
    let filters = {};
    if (urlParams.session_types) {
      let d = {};
      for (const r of JSON.parse(urlParams.session_types)) {
        d[r] = true;
      }
      filters.session_types = d;
    }

    if (urlParams.host_ids) {
      let d = {};
      for (const r of JSON.parse(urlParams.host_ids)) {
        d[r] = true;
      }
      filters.host_ids = d;
    }

    if (urlParams.date_after) {
      filters.date_after = new Date(urlParams.date_after);
    }

    if (urlParams.date_before) {
      filters.date_before = new Date(urlParams.date_before);
    }

    if (urlParams.is_active) {
      filters.is_active = urlParams.is_active;
    }

    if (urlParams.session_not_paid) {
      filters.session_not_paid = urlParams.session_not_paid;
    }

    if (urlParams.marketing_group_ids) {
      let d = {};
      for (const r of JSON.parse(urlParams.marketing_group_ids)) {
        d[r] = true;
      }
      filters.marketing_group_ids = d;
    }

    return filters;
  }, [urlParams]);

  const fetchHosts = useInfiniteQuery({
    staleTime: 1000 * 60 * 2,
    queryKey: [
      {
        endpoint: `onboarding-hosts`,
      },
    ],
    getNextPageParam: (lastPage, allPages) => lastPage.next,
    getPreviousPageParam: (firstPage, allPages) => firstPage.prev,
  });

  const hostOptions = useMemo(() => {
    if (fetchHosts.isSuccess && fetchHosts?.data?.pages?.length > 0) {
      let opts = [];
      for (const page of fetchHosts.data.pages) {
        for (const host of page.results) {
          opts.push({
            label: host.full_name || host.email || host.id,
            value: host.id,
          });
        }
      }
      return opts;
    }
  }, [fetchHosts?.isSuccess, fetchHosts?.data?.pages]);

  const fetchMarketingGroups = useQuery({
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 2,
    queryKey: [
      {
        endpoint: `marketing-groups`,
      },
    ],
  });
  const marketingGroupOptions = useMemo(() => {
    let opts = [];
    if (
      fetchMarketingGroups?.isSuccess &&
      fetchMarketingGroups?.data?.length > 0
    ) {
      for (const group of fetchMarketingGroups.data) {
        opts.push({
          label: group.name,
          value: group.id,
        });
      }
    }
    return opts;
  }, [fetchMarketingGroups?.isSuccess, fetchMarketingGroups?.data]);

  return (
    <div style={{ width: '100%' }}>
      <GenericAdminFilter
        filterTitle="Type"
        filterKey="session_type"
        options={{
          groups: [
            {
              title: 'Types',
              options: [
                { label: 'Open', value: 'OPEN' },
                { label: 'Invite Only', value: 'INVITE_ONLY' },
                { label: 'Placeholder', value: 'PLACEHOLDER' },
              ],
            },
          ],
        }}
        onFilter={selections => {
          props.onFilter({
            session_types: JSON.stringify(selections),
          });
        }}
        defaultDict={defaultFilters.session_types}
      />
      <GenericAdminFilter
        filterTitle="After Date"
        filterKey="date_after"
        type="date"
        defaultDate={defaultFilters.date_after}
        onFilter={date => {
          props.onFilter({ date_after: date.toISOString() });
        }}
      />
      <GenericAdminFilter
        filterTitle="Before Date"
        filterKey="date_before"
        type="date"
        defaultDate={defaultFilters.date_before}
        onFilter={date => {
          props.onFilter({ date_before: date.toISOString() });
        }}
      />
      {hostOptions?.length > 0 && (
        <GenericAdminFilter
          filterTitle="Hosts"
          filterKey="host_ids"
          options={{ groups: [{ title: 'Hosts', options: hostOptions }] }}
          onFilter={selections => {
            props.onFilter({
              host_ids: JSON.stringify(selections),
            });
          }}
          defaultDict={defaultFilters.host_ids}
        />
      )}
      <GenericAdminFilter
        filterTitle="Inactive"
        filterKey="is_active"
        options={{
          groups: [
            {
              title: 'Is Active',
              options: [{ label: 'Show Inactive', value: false }],
            },
          ],
        }}
        onFilter={selections => {
          props.onFilter({
            is_active: selections?.length === 0 ? true : false,
          });
        }}
        defaultDict={defaultFilters.session_types}
      />
      <GenericAdminFilter
        filterTitle="Session Paid"
        filterKey="session_not_paid"
        options={{
          groups: [
            {
              title: 'Session Paid',
              options: [{ label: 'Session not paid', value: true }],
            },
          ],
        }}
        onFilter={selections => {
          props.onFilter({
            session_not_paid: selections?.length === 0 ? false : true,
          });
        }}
        defaultDict={defaultFilters.session_not_paid}
      />
      {marketingGroupOptions?.length > 0 && (
        <GenericAdminFilter
          filterTitle="Referral Code Group"
          filterKey="marketing_group_ids"
          options={{
            groups: [
              { title: 'Referral Code Group', options: marketingGroupOptions },
            ],
          }}
          onFilter={selections => {
            props.onFilter({
              marketing_group_ids: JSON.stringify(selections),
            });
          }}
          defaultDict={defaultFilters.marketing_group_ids}
        />
      )}
    </div>
  );
}

export function Panel(props) {
  const session = props.selectedObj;
  const { tab } = props;

  const [formData, setFormData] = useState(
    session?.createNew
      ? {
        //hosts: [],
        host_ids: [],
        date: new Date(),
        max_num_attendees: 20,
        duration: 3,
        session_type: 'OPEN',
        referral_code_code: null,
      }
      : {
        ...session,
        time_start: stripTime(session.date),
        date: new Date(session.date),
        referral_code_code: session?.referral_code_code?.code,
      }
  );

  // needed for the onSuccess to fill out formData
  useQuery({
    refetchOnWindowFocus: false,
    enabled: !!session.id && !session.createNew,
    queryKey: [
      { endpoint: 'onboarding-hosts', urlParams: { session_id: session.id } },
    ],
    onSuccess: data => {
      const main = data.results.find(h => h.is_main_host);
      setFormData({
        ...formData,
        host_ids: data.results.map(h => h.id),
        main_host_id: main?.id,
      });
    },
  });

  const checkRefCodeExists = useQuery({
    refetchOnWindowFocus: false,
    enabled: !!formData.referral_code_code,
    queryKey: [
      {
        endpoint: 'referral_codes/exists',
        urlParams: { code: formData.referral_code_code },
      },
    ],
  });

  const disableSubmit =
    !formData.date ||
    !formData.time_start ||
    !formData.duration ||
    parseFloat(formData.duration) < 0.5 ||
    !formData.max_num_attendees ||
    formData.host_ids?.length <= 0 ||
    !formData.session_type ||
    !formData.main_host_id ||
    (session.session_type === 'PLACEHOLDER' &&
      formData.session_type === 'PLACEHOLDER') ||
    (formData.referral_code_code && !checkRefCodeExists.data?.exists);

  const queryClient = useQueryClient();
  const submitSession = useMutation({
    mutationKey: 'submit-session',
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [
          {
            endpoint: 'onboarding-sessions',
          },
        ],
      });
      //props.closePanel();
    },
  });

  const [linkBuilder, setLinkBuilder] = useState(
    session?.referral_code_code
      ? {
        session_id: session.id,
        referral_code: session.referral_code_code?.code,
      }
      : { session_id: session.id }
  );
  const [copyToClipboardState, copyToClipboard] = useCopyToClipboard();

  const refCodeExists = useQuery({
    enabled: !!linkBuilder.referral_code,
    queryKey: [
      {
        endpoint: `referral_codes/exists`,
        urlParams: { code: linkBuilder.referral_code },
      },
    ],
  });

  const link = `${window.location.origin}/intake?${new URLSearchParams(
    linkBuilder
  ).toString()}`;

  if (tab === 'Payments') {
    return <></>;
    //return <PaymentList session_id={session.id} collectReciever />;
  }

  if (tab === 'Signups') {
    return <CompletedProgresses session={session} />;
  }

  if (tab === 'Links') {
    return (
      <>
        <AuthTextInput
          placeholder="Add referral/promo code"
          label="Referral Code"
          defaultValue={session?.referral_code_code?.code}
          onChangeText={text => {
            if (!text) {
              setLinkBuilder({ session_id: session.id });
            } else {
              setLinkBuilder({
                ...linkBuilder,
                referral_code: text.toUpperCase(),
              });
            }
          }}
          errorText={
            refCodeExists?.data?.exists === false
              ? 'Referral code does not exitst'
              : null
          }
        />

        <p style={{ textAlign: 'center' }}>
          Send this link people to invite them to this specific session.
          Optionally add a referral code.
        </p>

        <Row
          style={{
            flex: 0,
            width: '100%',
            alignItems: 'center',
            border: '1px solid var(--color-text-light)',
            borderRadius: 'var(--std-border-radius)',
            padding: 'var(--space-sm) var(--space-xs)',
          }}
        >
          <span style={{ flex: 1 }}>
            {refCodeExists?.data?.exists === false
              ? 'Referral Code not valid'
              : link}
          </span>
          <IconButton
            colorTheme="text"
            iconName={IoCopyOutline}
            onPress={() => copyToClipboard(link)}
            subtext={
              copyToClipboardState?.error
                ? 'Error'
                : copyToClipboardState?.value
                  ? 'Copied!'
                  : 'Copy'
            }
          />
        </Row>
      </>
    );
  }

  if (submitSession.isSuccess) {
    return (
      <>
        <p>
          Session {session.createNew ? 'created: ' : 'updated: '}
          {submitSession.data?.id}
        </p>

        <p>Send this link to people to invite them to the session:</p>
        <p>
          {window.location.origin}/intake?session_id={submitSession.data?.id}
          {submitSession?.data?.referral_code_code?.code
            ? `&referral_code=${submitSession?.data?.referral_code_code?.code}`
            : ``}
        </p>

        <AuthButton
          onPress={() => {
            submitSession.reset();
            props.closePanel();
          }}
        >
          Finish
        </AuthButton>
      </>
    );
  }

  return (
    <>
      {(tab === 'Edit' || session.createNew) && (
        <form
          key={session?.id || 'new-session-form'}
          style={{ width: '100%' }}
          onSubmit={ev => {
            ev.preventDefault();
            const fd = new FormData(ev.target);
            submitSession.mutate({
              endpoint: `onboarding-sessions${session.createNew ? '' : '/' + session.id
                }`,
              body: { ...formData, name: fd.get('name'), date: fd.get('date') },
            });
          }}
        >
          <Select
            label="Session Type *"
            name="session_type"
            options={[
              { label: 'Open', value: 'OPEN' },
              { label: 'Invite Only', value: 'INVITE_ONLY' },
              { label: 'Placeholder', value: 'PLACEHOLDER' },
            ]}
            defaultValue={
              session?.createNew
                ? { label: SESSION_TYPE_LABELS['OPEN'], value: 'OPEN' }
                : {
                  label: SESSION_TYPE_LABELS[session.session_type],
                  value: session.session_type,
                }
            }
            onChange={opt => {
              setFormData({ ...formData, session_type: opt.value });
            }}
          />
          <AuthTextInput
            label="Name"
            name="name"
            placeholder="Name for this specific session"
            defaultValue={formData.name}
            onChangeText={text => setFormData({ ...formData, name: text })}
          />
          <Col style={{ marginTop: 'var(--space-sm)', alignItems: 'stretch' }}>
            <span>Date *</span>
            <Row
              style={{
                backgroundColor: 'var(--color-fg)',
                border: '1px solid var(--color-text-light)',
                borderRadius: '32px',
                padding: 'var(--space-xxxs)',
              }}
            >
              <CalendarInput
                value={formData.date}
                onChange={date => setFormData({ ...formData, date: date })}
              />
            </Row>
          </Col>
          <Row style={{ gap: '1rem' }}>
            <AuthTextInput
              containerStyle={{ flex: 1 }}
              name="time_start"
              label="Start Time (Eastern Time) *"
              type="time"
              defaultValue={
                session?.createNew ? undefined : stripTime(session.date)
              }
              onChangeText={text =>
                setFormData({ ...formData, time_start: text })
              }
            />
            <AuthTextInput
              containerStyle={{ flex: 1 }}
              name="duration"
              label="Duration (hours) *"
              type="number"
              min={0.5}
              step={0.5}
              defaultValue={session?.createNew ? 3 : session.duration}
              onChangeText={text =>
                setFormData({ ...formData, duration: text })
              }
            />
          </Row>

          {/*<br />
          <input
            type="checkbox"
            name="is_active"
            id="is_active"
            checked={formData.is_active}
            onChange={input => {
              setFormData({ ...formData, is_active: input.target.checked });
            }}
          />
          <label htmlFor="is_active">Is Active</label>
          <br />
          <br />*/}

          <AuthTextInput
            label="Referral Code"
            name="referral_code_code"
            defaultValue={formData.referral_code_code}
            errorText={
              checkRefCodeExists?.data?.exists
                ? null
                : 'Referral code does not exist'
            }
            onChangeText={text =>
              setFormData({ ...formData, referral_code_code: text })
            }
          />

          <AuthTextInput
            label="Max Number of Attendees *"
            type="number"
            name="max_num_attendees"
            defaultValue={formData.max_num_attendees}
            onChangeText={text =>
              setFormData({ ...formData, max_num_attendees: parseInt(text) })
            }
          />

          <HostSelector
            key={`host-selector-${session.createNew ? 'new' : session?.id}`}
            selectedHostIDs={formData.host_ids}
            onChange={host_ids => {
              if (session.createNew) {
                let newMainHost = formData.main_host_id;
                if (newMainHost && !host_ids.includes(newMainHost)) {
                  newMainHost = null;
                }
                setFormData({
                  ...formData,
                  host_ids: host_ids,
                  main_host_id: newMainHost,
                });
              } else {
                setFormData({
                  ...formData,
                  host_ids: host_ids,
                });
              }
            }}
            disableMainHostEditing={!session.createNew}
            fixedMainHostId={
              session.createNew ? undefined : formData.main_host_id
            }
            onChangeMainHost={host_id => {
              setFormData({
                ...formData,
                main_host_id: host_id,
              });
            }}
          />

          <AuthButton
            type="submit"
            disabled={disableSubmit}
            isLoading={submitSession.isLoading}
            containerStyle={{ marginTop: 'var(--space-xl)' }}
          >
            {session.createNew ? 'Create Session' : 'Save Changes'}
          </AuthButton>
          {session.session_type === 'PLACEHOLDER' &&
            formData.session_type === 'PLACEHOLDER' && (
              <span style={{ display: 'block', textAlign: 'center' }}>
                Don't forget to change the session type
              </span>
            )}
          {submitSession.isError && (
            <small
              style={{ textAlign: 'center', color: 'var(--color-danger)' }}
            >
              {submitSession.error.message}
            </small>
          )}
        </form>
      )}
    </>
  );
}

function CompletedProgresses(props) {
  const reduxProps = useSelector(state => ({
    allBooksMap: state.authReducer.allBooksMap,
  }));
  const { allBooksMap } = reduxProps;

  const { session } = props;

  const fetchCompleteProgresses = useInfiniteQuery({
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    staleTime: 60 * 1000, // 1 minute
    queryKey: [
      {
        endpoint: `onboarding-complete-progresses`,
        urlParams: { session_id: session.id },
      },
    ],
    getNextPageParam: (lastPage, allPages) => lastPage.next,
    getPreviousPageParam: (firstPage, allPages) => firstPage.prev,
  });

  const pages = fetchCompleteProgresses?.data?.pages;

  return (
    <>
      {fetchCompleteProgresses.isLoading && (
        <ActivityIndicator style={{ margin: 0 }} size={3} />
      )}

      {fetchCompleteProgresses.isError && (
        <h5 style={{ alignSelf: 'center', color: 'var(--color-danger)' }}>
          {fetchCompleteProgresses?.error?.message}
        </h5>
      )}

      {pages?.length === 1 && pages[0]?.results.length === 0 && (
        <h5 style={{ alignSelf: 'center' }}>
          No complete progresses (signups)
        </h5>
      )}

      {pages?.length > 0 && pages[0]?.results?.length > 0 && (
        <>
          <h6 style={{ margin: 'var(--space-md) 0' }}>
            <b>{pages[0]?.count} total signups</b>
          </h6>
          {pages.map((page, i) => (
            <React.Fragment key={`onb-session-complete-progress-${i}`}>
              {page.results.map((p, j) => (
                <Row
                  key={`onb-complete-progress-${i}-${p.id}`}
                  style={{
                    flex: 0,
                    width: '100%',
                    alignItems: 'center',
                    padding: 'var(--space-xxs)',
                    justifyContent: 'space-between',
                    gap: 'var(--space-xxs)',
                  }}
                >
                  <small style={{ flex: 0 }}>{j + 1}</small>
                  <img
                    style={{ flex: '0 0 22px', borderRadius: '4px' }}
                    src={allBooksMap[p.book]?.logo}
                    width={28}
                    alt={allBooksMap[p.book]?.name}
                  />
                  <span style={{ flex: 1 }}>
                    {allBooksMap[p.book]?.name} (min. ${p.min_deposit})
                  </span>
                  <span>{p.attendee?.full_name}</span>
                </Row>
              ))}
            </React.Fragment>
          ))}
        </>
      )}
      {fetchCompleteProgresses.hasNextPage && (
        <AuthButton
          containerStyle={{ flex: 0 }}
          btnTheme="borderless"
          onPress={() => fetchCompleteProgresses.fetchNextPage()}
          isLoading={fetchCompleteProgresses.isFetchingNextPage}
        >
          Load More
        </AuthButton>
      )}

      <small style={{ marginTop: 'var(--space-md)', textAlign: 'center' }}>
        Books that were completed by attendees who attended this session where
        the completion date on the progress (book) is after the session start
        date
      </small>
    </>
  );
}

function stripTime(datetime) {
  datetime = new Date(datetime);
  return datetime.toLocaleTimeString('en-US', {
    timeZone: 'America/New_York',
    hour12: false,
    hour: '2-digit',
    minute: '2-digit',
  });
}

function zeroDate(move) {
  return new Date(
    new Date(new Date().setHours(0, 0, 0, 0)).setDate(
      new Date().getDate() + move
    )
  ).toISOString();
}
