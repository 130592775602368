import React from 'react';
import { useSelector } from 'react-redux';

// component
import { Row, Col } from 'components/generic/Layout';
import OddBtn from 'components/generic/OddBtn2';
import AffiliateLink from 'components/AffiliateLink';

export default function OddBtnLine(props) {
  const reduxProps = useSelector(state => ({
    game: state.gameReducer.game,
    showML: state.settingsReducer.showML,
    showSpread: state.settingsReducer.showSpread,
    showTotal: state.settingsReducer.showTotal,
    allBooksMap: state.authReducer.allBooksMap,
  }));
  const { game, showML, showSpread, showTotal, allBooksMap } = reduxProps;

  const {
    odd,
    bestAvailable,
    isLoading,
    error,
    hideBook,
    forceShowAll,
    forceNotVerified,
  } = props;

  const showAll = forceShowAll || (!showML && !showSpread && !showTotal);

  if (error) {
    return (
      <Row
        style={
          bestAvailable
            ? { backgroundColor: 'var(--color-bg)' }
            : { backgroundColor: 'var(--color-bg)' }
        }
      >
        <small style={{ flex: 1 }} center>
          We couldn&apos;t get the best available odds
        </small>
      </Row>
    );
  }

  if (!odd && isLoading) {
    return (
      <Row
        style={bestAvailable ? { minHeight: '67px' } : { minHeight: '44px' }}
      />
    );
  }

  if (!odd) return <Row sytle={{ minHeight: '44px' }} />;

  const COMMON = {
    oddID: odd.id,
    book: odd.book_id ? allBooksMap[odd.book_id] : null,
    game: game,
    period: odd.period,
    is_live: odd.is_live,
    bet_type: 'BET',
    bestAvailable: bestAvailable,
    won: true, // no winner highlighting for now
    forceNotVerified: forceNotVerified,
  };

  let awayMLBookID = odd.book_id || odd.away_ml_book_id;
  const awayMLBook = awayMLBookID ? allBooksMap[awayMLBookID] : null;

  let homeMLBookID = odd.book_id || odd.home_ml_book_id;
  const homeMLBook = homeMLBookID ? allBooksMap[homeMLBookID] : null;

  let tieMLBookID = odd.book_id || odd.tie_ml_book_id;
  const tieMLBook = tieMLBookID ? allBooksMap[tieMLBookID] : null;

  let homeSPBookID = odd.book_id || odd.home_spread_book_id;
  const homeSPBook = homeSPBookID ? allBooksMap[homeSPBookID] : null;

  let awaySPBookID = odd.book_id || odd.away_spread_book_id;
  const awaySPBook = awaySPBookID ? allBooksMap[awaySPBookID] : null;

  let overBookID = odd.book_id || odd.over_book_id;
  const overBook = overBookID ? allBooksMap[overBookID] : null;

  let underBookID = odd.book_id || odd.under_book_id;
  const underBook = underBookID ? allBooksMap[underBookID] : null;

  return (
    <>
      <Row
        style={
          bestAvailable
            ? {
                alignItems: 'center',
                backgroundColor: 'var(--color-bg)',
                borderBottom: '1px solid var(--color-primary)',
                marginBottom: 'var(--space-xs)',
                minHeight: '53px',
                ...props.style,
              }
            : {
                margin: 'var(--space-xxxxs) 0',
                marginTop: 'var(--space-xxxs)',
                alignItems: 'center',
                ...props.style,
              }
        }
      >
        {!hideBook && (
          <Col
            style={{
              alignItems: 'center',
              minHeight: '42px',
              maxHeight: '64px',
            }}
          >
            {bestAvailable ? (
              <img
                alt="Best Available Odds"
                title="Best Available Odds"
                src={
                  'https://betstamp-public.sfo2.cdn.digitaloceanspaces.com/ba_banner.png'
                }
                style={{
                  height: 'auto',
                  width: '100%',
                  resizeMode: 'cover',
                  borderRadius: '4px',
                }}
              />
            ) : (
              <AffiliateLink
                book={COMMON.book}
                fromPage="Gameline Web"
                shape="bar"
                hideLinkable
                hideText
                fallbackToGeneric
                containerStyle={{ width: '100%', margin: 0, padding: 0 }}
                imgStyle={{
                  width: '100%',
                  height: 'auto',
                  maxHeight: '64px',
                  borderRadius: '4px',
                }}
                usePreferred
              />
            )}
          </Col>
        )}
        {(showML || showAll) && (
          <Col>
            <StyledOddBtn
              odd={{
                ...COMMON,
                odds: odd.away_ml,
                type_name: 'Moneyline',
                side: game.away_team.name,
                period: odd.period,
                book: COMMON.book || awayMLBook,
              }}
            />
          </Col>
        )}
        {game.sport === 'Soccer' && (showML || showAll) && (
          <Col>
            <StyledOddBtn
              odd={{
                ...COMMON,
                odds: odd.tie_ml,
                type_name: 'Moneyline',
                side: 'Tie',
                period: odd.period,
                book: COMMON.book || tieMLBook,
              }}
            />
          </Col>
        )}
        {(showML || showAll) && (
          <Col>
            <StyledOddBtn
              odd={{
                ...COMMON,
                odds: odd.home_ml,
                type_name: 'Moneyline',
                side: game.home_team.name,
                period: odd.period,
                book: COMMON.book || homeMLBook,
              }}
            />
          </Col>
        )}
        {(showSpread || showAll) && game.sport !== 'MMA' && (
          <Col>
            <StyledOddBtn
              odd={{
                ...COMMON,
                odds: odd.away_spread_odds,
                number: bestAvailable ? odd.away_spread : -1 * odd.spread,
                type_name: 'Spread',
                side: game.away_team.name,
                period: odd.period,
                book: COMMON.book || awaySPBook,
              }}
            />
          </Col>
        )}
        {(showSpread || showAll) && game.sport !== 'MMA' && (
          <Col>
            <StyledOddBtn
              odd={{
                ...COMMON,
                odds: odd.home_spread_odds,
                number: bestAvailable ? odd.home_spread : odd.spread,
                type_name: 'Spread',
                side: game.home_team.name,
                period: odd.period,
                book: COMMON.book || homeSPBook,
              }}
            />
          </Col>
        )}
        {(showTotal || showAll) && game.sport !== 'Golf' && (
          <Col>
            <StyledOddBtn
              odd={{
                ...COMMON,
                odds: odd.over_odds,
                number: bestAvailable ? odd.over_total : odd.total,
                type_name: 'Total',
                side: 'Over',
                period: odd.period,
                book: COMMON.book || overBook,
              }}
            />
          </Col>
        )}
        {(showTotal || showAll) && game.sport !== 'Golf' && (
          <Col>
            <StyledOddBtn
              odd={{
                ...COMMON,
                odds: odd.under_odds,
                number: bestAvailable ? odd.under_total : odd.total,
                type_name: 'Total',
                side: 'Under',
                period: odd.period,
                book: COMMON.book || underBook,
              }}
            />
          </Col>
        )}
      </Row>
    </>
  );
}

function StyledOddBtn({ odd }) {
  if (!odd.odds) {
    return <span style={{ textAlign: 'center' }}>—</span>;
  }
  return (
    <OddBtn
      containerStyle={{ width: '100%', borderRadius: 0 }}
      btnStyle={{
        padding: 0,
        margin: 0,
        borderRadius: 0,
      }}
      btnTheme="borderless"
      odd={odd}
    />
  );
}
