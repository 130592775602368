import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useEffectOnce } from 'react-use';

// components
import { Grid, Row } from 'components/generic/Layout';
import ErrorDisplay from 'components/generic/ErrorDisplay';
import ActivityIndicator from 'components/generic/ActivityIndicator';
import UsersListItem from 'components/UsersListItem';

// actions
import { getFollowers, getFollowersNext } from 'actions';

export default function Followers(props) {
  const dispatch = useDispatch();
  const reduxProps = useSelector(state => ({
    followers: state.publicUserReducer.followers,
    fetchError: state.publicUserReducer.fetchError,
    isFollowersLoading: state.publicUserReducer.isFollowersLoading,
    followersNext: state.publicUserReducer.followersNext,
    user: state.authReducer.user,
    publicProfile: state.publicUserReducer.publicProfile,
  }));

  const {
    fetchError,
    followers,
    isFollowersLoading,
    followersNext,
    user,
    publicProfile,
  } = reduxProps;

  const { viewingSelf } = props;

  const _getNextFollowers = () => {
    if (followersNext) {
      dispatch(getFollowersNext());
    }
  };

  const _initialLoad = () => {
    if (publicProfile && !viewingSelf) {
      dispatch(getFollowers(publicProfile.id));
    } else {
      dispatch(getFollowers());
    }
  };

  useEffectOnce(() => {
    _initialLoad();
  });

  if (fetchError) {
    return <ErrorDisplay error={fetchError} retry={() => _initialLoad()} />;
  }

  if (isFollowersLoading) {
    return (
      <Row>
        <ActivityIndicator size={2} />
      </Row>
    );
  }

  return (
    <InfiniteScroll
      scrollableTarget={'followers-modal-wrapper'}
      scrollThreshold={0.5}
      next={_getNextFollowers}
      hasMore={followersNext}
      dataLength={followers.length}
      loader={<ActivityIndicator size={1.5} />}
    >
      <Grid style={{ gridTemplateColumns: '1fr 1fr' }}>
        {followers.map(item => (
          <UsersListItem
            key={`followers-user-list-item-${item.id}`}
            user={item}
            showStats={['roi']}
            showFollowButton
          />
        ))}
      </Grid>

      {!viewingSelf && followers.length === 0 && (
        <h6 style={{ marginTop: 'var(--space-md)' }}>
          No <b>public</b> users are following {publicProfile.username}
        </h6>
      )}

      {viewingSelf && followers.length === 0 && (
        <>
          <h6 style={{ marginTop: 'var(--space-md)' }}>
            No users are following you! No worries, here's what you can do:
          </h6>
          <ul>
            {!user.is_public && (
              <li>Make your profile public so other users can find you.</li>
            )}
            <li>Go to the Marketplace and follow other users.</li>
            <li>
              Promote yourself on other platforms so people can find you on
              betstamp.
            </li>
          </ul>
        </>
      )}
    </InfiniteScroll>
  );
}
