import React, { useState, useMemo } from 'react';
import {
  useInfiniteQuery,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { IoClose, IoTrash, IoFilter } from 'react-icons/io5';
import styled from 'styled-components';
import { useUpdateEffect } from 'react-use';

// components
import StaffNavBar from 'components/nav/StaffNavBar';
import { Row, Col } from 'components/generic/Layout';
import ActivityIndicator from 'components/generic/ActivityIndicator';
import { AuthTextInput } from 'components/AuthTextInput';
import { AuthButton, IconButton } from 'components/AuthButton';
import AdminTable from 'components/generic/AdminTable';
import ModalWrapper from 'components/generic/ModalWrapper';
import ConfirmDelete from 'components/modals/ConfirmDelete';
import DisplayToggle from 'components/generic/DisplayToggle';

const Tbody = styled.tbody`
  > tr {
    transition: all var(--std-transition);
  }
  > tr:hover {
    background-color: var(--color-text-light);
  }
`;

export default function StaffOnboardingBase(props) {
  const {
    endpoint,
    modelName,
    headings,
    objKeys,
    panelTitleKey,
    formatText,
    linkTo,
    deleteBaseEndpoint,
    showSearch,
    searchPlaceholder,
    PanelChildren,
    FilterChildren,
    panelTabs,
    externalLinks,
    sortKeys,
    extraButtons,
    extraHeaderComponent,
    defaultUrlParams,
    requiredParams,
    extraHeaderRow,
    panelTitleOverride,
  } = props;

  const history = useHistory();
  const url = useLocation().search;
  const params = useMemo(() => {
    const searchParams = new URLSearchParams(url);
    if (searchParams?.size === 0 && defaultUrlParams) return defaultUrlParams;
    let newParams = requiredParams ? requiredParams : {};
    for (const [key, value] of searchParams.entries()) {
      newParams[key] = value;
    }
    return newParams;
  }, [url]);

  const [selectedObj, setSelectedObj] = useState(null);
  const [urlParams, setURLParams] = useState(params);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteObj, setDeleteObj] = useState(null);
  const [showFilters, setShowFilters] = useState(false);

  useUpdateEffect(() => {
    let paramString = '';
    for (const [key, value] of Object.entries(urlParams)) {
      if (value || value === 0 || value === false) {
        paramString += `${encodeURIComponent(key)}=${encodeURIComponent(
          value
        )}&`;
      }
    }

    history.replace(`${history.location.pathname}?${paramString}`);
  }, [urlParams]);

  const fetchData = useInfiniteQuery({
    keepPreviousData: true,
    queryKey: [
      {
        endpoint: endpoint,
        urlParams: urlParams,
      },
    ],
    getNextPageParam: (lastPage, allPages) => lastPage.next,
    getPreviousPageParam: (firstPage, allPages) => firstPage.prev,
    ...props.queryOptions,
  });

  const queryClient = useQueryClient();
  const deleteMut = useMutation({
    mutationKey: deleteBaseEndpoint,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [
          {
            endpoint: endpoint,
            urlParams: urlParams,
          },
        ],
      });
      setShowDeleteModal(false);
    },
  });

  const pages = fetchData?.data?.pages;

  const [selectedQuickFilter, setSelectedQuickFilter] = useState(
    props.defaultSelectedQuickFilter
  );

  return (
    <>
      <StaffNavBar />

      {deleteBaseEndpoint && (
        <ModalWrapper
          modalIsOpen={showDeleteModal}
          onClose={() => setShowDeleteModal(false)}
          onRequestClose={() => setShowDeleteModal(false)}
          title="Confirm Delete"
          modalSize="small"
        >
          <ConfirmDelete
            onDelete={() => {
              deleteMut.mutate({
                endpoint: `${deleteBaseEndpoint}/${deleteObj.id}`,
                method: 'DELETE',
              });
            }}
            dismissModal={() => setShowDeleteModal(false)}
            isDeleting={deleteMut.isLoading}
          >
            Are you sure you want to delete this {modelName}?{' '}
            {props.extraDeleteText}
          </ConfirmDelete>
          {deleteMut.isError && (
            <p style={{ color: 'var(--color-danger)', textAlign: 'center' }}>
              {deleteMut.error?.message}
            </p>
          )}
        </ModalWrapper>
      )}

      <Row style={{ width: '100%', alignItems: 'flex-start' }}>
        <div
          style={{
            flex: 3,
            height: 'calc(100vh - 64px)',
            overflowX: 'hidden',
          }}
        >
          <Col
            style={{
              height: '100%',
              overflowY: 'auto',
              overflowX: 'auto',
              flexWrap: 'nowrap',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
            }}
          >
            <Row
              style={{
                flex: 0,
                alignItems: 'center',
                padding: 'var(--space-xs)',
                width: '100%',
                gap: 'var(--space-sm)',
                position: 'sticky',
                top: 0,
                left: 0,
                background: 'var(--color-bg)',
                zIndex: 10,
              }}
            >
              {!!extraHeaderComponent && extraHeaderComponent(urlParams)}
              {showSearch ? (
                <>
                  <AuthTextInput
                    defaultValue={urlParams?.search}
                    placeholder={searchPlaceholder}
                    containerStyle={{ flex: 1.5, margin: 0 }}
                    onChangeText={text =>
                      setURLParams({ ...urlParams, search: text })
                    }
                  />
                  {urlParams?.search &&
                    fetchData.isFetching &&
                    !fetchData?.isInitialLoading && (
                      <ActivityIndicator
                        size={2}
                        style={{ flex: '0 0 32px', marginLeft: '-62px' }}
                      />
                    )}
                  {extraButtons ? (
                    <>
                      {extraButtons.map((b, i) => (
                        <AuthButton
                          key={`extra-btn-${b?.text}-${i}`}
                          containerStyle={{ flex: 0.5 }}
                          btnTheme={
                            selectedQuickFilter.includes(b.text)
                              ? 'std'
                              : 'borderless'
                          }
                          colorTheme={
                            selectedQuickFilter.includes(b.text)
                              ? 'inverted'
                              : 'primary'
                          }
                          onPress={() => {
                            if (b.urlParams) {
                              setURLParams({ ...urlParams, ...b.urlParams });
                              setSelectedQuickFilter(b.text);
                            } else if (b.deriveUrlParams) {
                              let newStuff = b.deriveUrlParams(urlParams);
                              setURLParams(newStuff.urlParams);
                              setSelectedQuickFilter(
                                newStuff.selectedQuickFilters
                              );
                            }
                            if (b.onPress) {
                              b.onPress();
                            }
                          }}
                        >
                          {b.text}
                        </AuthButton>
                      ))}
                    </>
                  ) : (
                    <div style={{ flex: 1 }} />
                  )}
                </>
              ) : (
                <div style={{ flex: 3 }} />
              )}
              {!props.hideCreate && (
                <AuthButton
                  containerStyle={{ flex: 1, maxWidth: '420px' }}
                  onPress={() => {
                    setSelectedObj({ createNew: true });
                    setShowFilters(false);
                  }}
                >
                  {!!props.createButtonText
                    ? props.createButtonText
                    : `Create ${modelName}`}
                </AuthButton>
              )}
              {FilterChildren && (
                <IconButton
                  colorTheme="text"
                  iconName={IoFilter}
                  onPress={() => setShowFilters(!showFilters)}
                />
              )}
            </Row>
            {!!extraHeaderRow && (
              <Row
                style={{
                  flex: 0,
                  flexWrap: 'nowrap',
                  overflowX: 'auto',
                  alignItems: 'center',
                  padding: 'var(--space-xs)',
                  width: '100%',
                  minHeight: '92px',
                  gap: 'var(--space-sm)',
                  position: 'sticky',
                  top: 0,
                  left: 0,
                  background: 'var(--color-bg)',
                  zIndex: 10,
                  ...props.extraHeaderRowStyle,
                }}
              >
                {extraHeaderRow(pages)}
              </Row>
            )}

            {fetchData.isLoading && (
              <ActivityIndicator size={3} style={{ marginTop: '20vh' }} />
            )}

            {fetchData.isError && (
              <h5 style={{ alignSelf: 'center', color: 'var(--color-danger)' }}>
                {fetchData?.error?.message}
              </h5>
            )}

            {pages?.length === 1 && pages[0]?.results.length === 0 && (
              <h5 style={{ alignSelf: 'center' }}>No {modelName}s</h5>
            )}

            {pages?.length > 0 && pages[0]?.results?.length > 0 && (
              <AdminTable
                headings={headings}
                objKeys={objKeys}
                onSort={sort => setURLParams({ ...urlParams, ...sort })}
                sortKeys={sortKeys}
                sort={urlParams?.sort}
              >
                {pages.map((page, i) => (
                  <React.Fragment key={`onb-base-page-${modelName}-${i}`}>
                    {page.results.map(obj => (
                      <tr
                        style={
                          selectedObj?.id === obj.id
                            ? { backgroundColor: 'var(--color-text-light)' }
                            : {}
                        }
                        key={`onb-base-tr-${modelName}-${obj.id}`}
                      >
                        {objKeys.map(k => {
                          let t = k.includes('.')
                            ? obj[k.split('.')[0]][k.split('.')[1]]
                            : obj[k];
                          if (formatText && formatText[k]) {
                            t = formatText[k](t, obj);
                          }
                          t = t || '-';
                          return (
                            <td
                              key={`obj-key-td-${modelName}-${k}`}
                              onClick={() => {
                                setSelectedObj(obj);
                                setShowFilters(false);
                              }}
                            >
                              {linkTo && linkTo[k] ? (
                                <Link
                                  title={`Go to betstamp.app${linkTo[k](
                                    obj[k]
                                  )}`}
                                  style={{
                                    textDecoration: 'none',
                                    color: 'var(--color-primary)',
                                  }}
                                  to={linkTo[k](obj[k])}
                                >
                                  {t}
                                </Link>
                              ) : (
                                t
                              )}
                            </td>
                          );
                        })}
                        {deleteBaseEndpoint && (
                          <td>
                            <IconButton
                              btnStyle={{ padding: 'var(--space-xxs)' }}
                              iconName={IoTrash}
                              colorTheme="danger"
                              onPress={() => {
                                setShowDeleteModal(true);
                                setDeleteObj(obj);
                              }}
                            />
                          </td>
                        )}
                      </tr>
                    ))}
                  </React.Fragment>
                ))}
              </AdminTable>
            )}
            <Row
              style={{
                flex: 0,
                width: 'calc(100% - 64px)',
                gap: 'var(--space-xs)',
                alignItems: 'center',
                margin: 'var(--space-lg) 0',
              }}
            >
              <div style={{ flex: 1, textAlign: 'center' }}>
                {fetchData.hasNextPage && (
                  <AuthButton
                    containerStyle={{
                      flex: 1,
                      margin: '0',
                      marginLeft: 'var(--space-xs)',
                      maxWidth: '256px',
                    }}
                    btnTheme="borderless"
                    extStyle={{ textAlign: 'left' }}
                    onPress={() => fetchData.fetchNextPage()}
                    isLoading={fetchData.isFetchingNextPage}
                  >
                    Load More
                  </AuthButton>
                )}
              </div>
              <i style={{ flex: 1, textAlign: 'center' }}>
                {!!fetchData.data?.pages &&
                  fetchData.data.pages?.length > 0 &&
                  fetchData.data.pages[0] && (
                    <>
                      {fetchData.data.pages[0]?.count} Total {modelName}s
                    </>
                  )}
              </i>
              <div style={{ flex: 1, textAlign: 'right' }}>
                {!!fetchData.data?.pages &&
                  fetchData.data.pages?.length > 0 &&
                  fetchData.data.pages[0] && (
                    <>
                      {fetchData.data.pages?.length} page
                      {fetchData.data.pages?.length > 1 ? 's' : ''} loaded
                    </>
                  )}
              </div>
            </Row>
          </Col>
        </div>
        {(PanelChildren || (showFilters && FilterChildren)) && (
          <Panel
            modelName={modelName}
            panelTitleKey={panelTitleKey}
            selectedObj={selectedObj}
            showFilters={showFilters}
            setShowFilters={setShowFilters}
            setSelectedObj={setSelectedObj}
            PanelChildren={showFilters ? FilterChildren : PanelChildren}
            urlParams={urlParams}
            setURLParams={setURLParams}
            formatText={formatText}
            panelTabs={panelTabs}
            externalLinks={externalLinks}
            panelSkipNullValues={props.panelSkipNullValues}
            panelSkipKeys={props.panelSkipKeys}
            DetailsPanelExtra={props.DetailsPanelExtra}
            panelTitleOverride={panelTitleOverride}
            hidePanelTabs={props.hidePanelTabs}
          />
        )}
      </Row>
    </>
  );
}

const PanelWrapper = styled.div`
  flex: 1.5;
  border-left: 1px solid var(--color-text-light);
  height: calc(100vh - 64px);
  @media only screen and (max-width: 1266px) {
    flex: 2;
  }
  @media only screen and (max-width: 625px) {
    position: absolute;
    background-color: var(--color-bg);
    width: 100vw;
    z-index: 99999999;
    top: 0;
  }
`;

export function Panel(props) {
  const {
    showFilters,
    setShowFilters,
    panelTitleKey,
    modelName,
    selectedObj,
    setSelectedObj,
    urlParams,
    setURLParams,
    formatText,
    panelTabs,
    externalLinks,
    panelTitleOverride,
    hidePanelTabs,
  } = props;

  const [tab, setTab] = useState(
    panelTabs && panelTabs?.length > 0 ? panelTabs[0] : 'Details'
  );

  if (!showFilters && !selectedObj) return null;

  const PanelChildren = props.PanelChildren;
  const DetailsPanelExtra = props.DetailsPanelExtra || React.Fragment;
  let detailsPanelProps = { selectedObj: selectedObj };
  if (!props.DetailsPanelExtra) {
    detailsPanelProps = {};
  }

  return (
    <PanelWrapper>
      <Col
        style={{
          height: '100%',
          overflowY: 'auto',
          flexWrap: 'nowrap',
          justifyContent: 'flex-start',
          padding: '0 var(--space-sm)',
        }}
      >
        <Row
          style={{
            flex: 0,
            width: '100%',
            alignItems: 'center',
            position: 'sticky',
            top: 0,
            background: 'var(--color-bg)',
            zIndex: 10,
          }}
        >
          <h5 style={{ flex: 1, margin: 0 }}>
            {panelTitleOverride
              ? panelTitleOverride
              : showFilters
                ? 'Filters'
                : selectedObj?.createNew
                  ? `New ${modelName}`
                  : selectedObj[panelTitleKey] ||
                  `Edit ${modelName}${selectedObj.id ? ` (${selectedObj.id})` : ''
                  }`}
          </h5>
          <IconButton
            colorTheme="text"
            iconName={IoClose}
            onPress={() => {
              setSelectedObj(null);
              setShowFilters(false);
            }}
          />
        </Row>

        {!selectedObj?.createNew && !showFilters && !hidePanelTabs && (
          <Row
            style={{
              flex: 0,
              width: '100%',
              position: 'sticky',
              top: '48px',
              background: 'var(--color-bg)',
              zIndex: 10,
              padding: 'var(--space-xs) 0',
            }}
          >
            <DisplayToggle
              style={{ flex: 1 }}
              containerStyle={
                panelTabs && panelTabs.length && panelTabs?.length >= 5
                  ? //? { minWidth: panelTabs.length % 2 === 0 ? '25%' : '33%' }
                  { flex: '1 0 165px' }
                  : {}
              }
              options={panelTabs || ['Details', 'Edit']}
              active={tab}
              onPress={opt => setTab(opt)}
            />
          </Row>
        )}

        {tab === 'Details' && !showFilters && !selectedObj.createNew && (
          <>
            <table
              cellSpacing={0}
              style={{ width: '100%', tableLayout: 'fixed' }}
            >
              <Tbody>
                {Object.entries(selectedObj).map(([key, value], i) => {
                  let valueText = value;
                  if (
                    props.panelSkipNullValues &&
                    (valueText === '' ||
                      valueText === null ||
                      valueText === undefined)
                  )
                    return null;
                  if (props.panelSkipKeys && props.panelSkipKeys.includes(key))
                    return null;
                  if (formatText && formatText[key]) {
                    valueText = formatText[key](valueText, selectedObj);
                  }
                  if (Array.isArray(valueText)) {
                    valueText = valueText.join(', ');
                  }
                  if (!React.isValidElement(valueText)) {
                    valueText = valueText?.toString() || '-';
                    if (valueText === '[object Object]') return null;
                  }
                  return (
                    <tr key={`admin-full-dta-display-${key}-${i}`}>
                      <td
                        style={{
                          padding: 'var(--space-xxxs) 0',
                          fontWeight: 'bold',
                        }}
                      >
                        {key.replace(/_/g, ' ')}
                      </td>
                      <td
                        style={{
                          padding: 'var(--space-xxxs) 0',
                          textAlign: 'right',
                        }}
                      >
                        {externalLinks && externalLinks[key] && value ? (
                          <a
                            href={value}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ color: 'var(--color-primary)' }}
                          >
                            {valueText}
                          </a>
                        ) : (
                          <>{valueText}</>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </Tbody>
            </table>
            <DetailsPanelExtra {...detailsPanelProps} />
          </>
        )}

        <PanelChildren
          key={
            showFilters
              ? `filter-${modelName}`
              : selectedObj.createNew
                ? `new-selected-obj-${modelName}`
                : `${modelName}-${selectedObj.id}`
          }
          selectedObj={selectedObj}
          closePanel={() => {
            setSelectedObj(null);
            setShowFilters(false);
          }}
          urlParams={props.urlParams}
          onFilter={newFilterBy => {
            setURLParams({ ...urlParams, ...newFilterBy });
          }}
          tab={tab}
        />
      </Col>
    </PanelWrapper>
  );
}
