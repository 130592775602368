import { Observable } from 'rxjs';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import { ajax } from 'rxjs/observable/dom/ajax';
import { BACKEND_API_URL, getHeaders } from 'utils';

import {
  REFRESH_SHARPSPORTS_ALL,
  refreshSharpsportsAllSuccess,
  refreshSharpsportsAllFailure,
} from 'actions';

export default function refreshSharpsportsAll(action$, state$) {
  return action$.ofType(REFRESH_SHARPSPORTS_ALL).switchMap(() => {
    let url = BACKEND_API_URL + `api/sharpsports/refreshresponse/`;
    return ajax
      .getJSON(url, getHeaders(state$))
      .map(data => refreshSharpsportsAllSuccess(data))
      .catch(error => Observable.of(refreshSharpsportsAllFailure(error.xhr)));
  });
}
