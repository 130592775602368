import { combineEpics } from 'redux-observable';
import 'rxjs-compat';

import allAuthEpics from 'epics/auth';
import allUserEpics from 'epics/user';
import allPicksEpics from 'epics/picks';
import allFeaturedEpics from 'epics/featured';
import allAnalysisEpics from 'epics/analysis';
import allAffiliatesEpics from 'epics/affiliates';
import allStripeEpics from 'epics/stripe';
import allScheduleEpics from 'epics/schedule';
import allOddscompEpics from 'epics/oddscomp';
import allBetslipEpics from 'epics/betslip';
import allSharpsportsEpics from 'epics/sharpsports';
import allWagerEpics from 'epics/wager';
import allTansactionEpics from 'epics/transaction';
import allSurveyEpics from 'epics/survey';
import allCompetitionsEpics from 'epics/competitions';
import allAdsEpics from 'epics/ads';
import allGameEpics from 'epics/game';
import allAutoSyncEpics from 'epics/autosync';
import allFindbetsEpics from 'epics/findbets';
import allInfluencerEpics from 'epics/influencers';

export const rootEpic = combineEpics(
  allUserEpics,
  allAuthEpics,
  allPicksEpics,
  allFeaturedEpics,
  allAnalysisEpics,
  allAffiliatesEpics,
  allStripeEpics,
  allScheduleEpics,
  allOddscompEpics,
  allBetslipEpics,
  allSharpsportsEpics,
  allWagerEpics,
  allTansactionEpics,
  allSurveyEpics,
  allCompetitionsEpics,
  allAdsEpics,
  allGameEpics,
  allAutoSyncEpics,
  allFindbetsEpics,
  allInfluencerEpics
);
