import React, { useState } from 'react';
import DatePicker from 'react-date-picker';

export default function CalendarInput(props) {
  return (
    <DatePicker
      className={['calendar', ...(props.extraClassNames || '')]}
      clearIcon={null}
      format={'d MMMM y'}
      {...props}
    />
  );
}
