import React from 'react';

import CustomSelect from 'components/generic/Select';

const OPTIONS = [
  { value: 'all', label: 'All' },
  { value: 'all_pending', label: 'All Pending' },
  { value: 'all_graded', label: 'All Graded' },
  { value: 'graded_win_loss', label: 'Graded (Win, Loss)' },
  { value: 'graded_win', label: 'Graded (Win)' },
  { value: 'graded_loss', label: 'Graded (Loss)' },
  { value: 'graded_push', label: 'Graded (Push)' },
];

const valueToLabel = {
  all: 'All',
  all_pending: 'All Pending',
  all_graded: 'All Graded',
  graded_win_loss: 'Graded (Win, Loss)',
  graded_win: 'Graded (Win)',
  graded_loss: 'Graded (Loss)',
  graded_push: 'Graded (Push)',
};

export default function BetStatus(props) {
  const { defaultValue } = props;
  return (
    <>
      <CustomSelect
        onChange={(opt) => {
          props.onDone({
            filter: 'Bet_Status',
            value: opt.value,
            slug: opt.label,
          });
        }}
        value={
          defaultValue
            ? {
                value: defaultValue,
                label: valueToLabel[defaultValue],
              }
            : { value: 'all', label: 'All' }
        }
        options={OPTIONS}
      />
    </>
  );
}
