import {
  GET_AFFILIATES,
  GET_AFFILIATES_SUCCESS,
  GET_AFFILIATES_FAILURE,
  GET_SPORTSBOOKS,
  GET_SPORTSBOOKS_SUCCESS,
  GET_SPORTSBOOKS_FAILURE,
  SET_SELECTED_STATE_AFF,
  GET_SPORTSBOOK_REVIEW,
  GET_SPORTSBOOK_REVIEW_SUCCESS,
  GET_SPORTSBOOK_REVIEW_FAILURE,
  GET_SPORTSBOOK_INFO_MAP,
  GET_SPORTSBOOK_INFO_MAP_SUCCESS,
  GET_SPORTSBOOK_INFO_MAP_FAILURE,
} from 'actions';

const initialState = {
  // this is for the logged in user's state
  affiliates: [],
  isLoadingAffiliates: false,
  fetchAffiliatesError: null,
  // this is for a selected state (user may or may not be logged in)
  selectedState: '',
  sportsbooks: [],
  isLoadingSportsbooks: false,
  fetchSportsbooksError: null,
  // sportsbook review(s)
  isLoadingSportsbookReview: false,
  fetchSportsbookReviewError: null,
  sportsbookReview: null,
  referralCode: null,
  // for bonuses, right now only on /groups page
  isLoadingSportsbookInfos: false,
  fetchSportsbookInfosError: null,
  sportsbookInfos: null,
  sportsbookInfosMap: null,
  sportsbookInfosNext: null,
  isLoadingSportsbookInfosNext: false,
  // sportsbook infos
  sportsbookInfoMap: {},
  isLoadingSportsbookInfoMap: false,
  sportsbookInfoMapError: null,
};

export default function affiliatesReducer(state = initialState, action) {
  switch (action.type) {
    case GET_AFFILIATES:
      return Object.assign({}, state, {
        ...state,
        isLoadingAffiliates: true,
        fetchAffiliatesError: null,
        referralCode: action.refCode ? action.refCode : state.referralCode,
        selectedState: action.state ? action.state : state.selectedState,
      });
    case GET_AFFILIATES_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        isLoadingAffiliates: false,
        fetchAffiliatesError: null,
        affiliates: action.payload.affiliate_books,
      });
    case GET_AFFILIATES_FAILURE:
      return Object.assign({}, state, {
        ...state,
        isLoadingAffiliates: false,
        fetchAffiliatesError: action.error,
      });

    case GET_SPORTSBOOKS:
      return Object.assign({}, state, {
        ...state,
        isLoadingSportsbooks: true,
        fetchSportsbooksError: null,
      });
    case GET_SPORTSBOOKS_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        isLoadingSportsbooks: false,
        fetchSportsbooksError: null,
        sportsbooks: action.payload,
      });
    case GET_SPORTSBOOKS_FAILURE:
      return Object.assign({}, state, {
        ...state,
        isLoadingSportsbooks: false,
        fetchSportsbooksError: action.error,
      });

    case SET_SELECTED_STATE_AFF:
      return Object.assign({}, state, {
        ...state,
        selectedState: action.state,
      });

    case GET_SPORTSBOOK_REVIEW:
      return Object.assign({}, state, {
        ...state,
        isLoadingSportsbookReview: true,
        fetchSportsbookReviewError: null,
      });
    case GET_SPORTSBOOK_REVIEW_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        isLoadingSportsbookReview: false,
        fetchSportsbookReviewError: null,
        sportsbookReview: action.payload,
      });
    case GET_SPORTSBOOK_REVIEW_FAILURE:
      return Object.assign({}, state, {
        ...state,
        isLoadingSportsbookReview: false,
        fetchSportsbookReviewError: action.error,
      });

    case GET_SPORTSBOOK_INFO_MAP:
      return Object.assign({}, state, {
        ...state,
        isLoadingSportsbookInfoMap: true,
        sportsbookInfoMapError: null,
      });
    case GET_SPORTSBOOK_INFO_MAP_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        isLoadingSportsbookInfoMap: false,
        sportsbookInfoMapError: null,
        sportsbookInfoMap: action.payload,
      });
    case GET_SPORTSBOOK_INFO_MAP_FAILURE:
      return Object.assign({}, state, {
        ...state,
        isLoadingSportsbookInfoMap: false,
        sportsbookInfoMapError: action.error,
      });

    default:
      return state;
  }
}
