import { Observable } from 'rxjs';
import 'rxjs/add/operator/exhaustMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import { ajax } from 'rxjs/observable/dom/ajax';
import { BACKEND_API_URL, getHeaders } from 'utils';

import {
  EDIT_RELATIONSHIP,
  editRelationshipSuccess,
  editRelationshipFailure,
} from 'actions';

export default function editRelationship(action$, state$) {
  return action$.ofType(EDIT_RELATIONSHIP).exhaustMap(action => {
    return ajax
      .post(
        BACKEND_API_URL + 'api/users/edit_relationship_notifications/',
        {
          other_user: action.otherUserID,
          notifications: action.notifications,
        },
        getHeaders(state$)
      )
      .map(data => editRelationshipSuccess(data, action.otherUserID))
      .catch(error => Observable.of(editRelationshipFailure(error.xhr)));
  });
}
