import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { IoPencilSharp, IoTrashOutline } from 'react-icons/io5';
import moment from 'moment';

// components
import AutoColoredAmount from 'components/generic/AutoColoredAmount.js';
import { Row, Col } from 'components/generic/Layout';
import ActivityIndicator from 'components/generic/ActivityIndicator';
import ModalWrapper from 'components/generic/ModalWrapper';
import EditTransaction from 'components/modals/EditTransaction';
import ConfirmDelete from 'components/modals/ConfirmDelete';
import { IconButton } from 'components/AuthButton';

// actions
import { deleteTransaction } from 'actions';

const TransactionWrapper = styled.div`
  border-radius: var(--std-border-radius);
  padding: var(--space-sm);
  background-color: var(--color-fg);
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  box-sizing: border-box;
  margin: 8px;
  margin-bottom: var(--space-sm);
  transition: all var(--std-transition);
  @media only screen and (max-width: 625px) {
    padding: var(--space-xxs);
  }
  &:hover {
    box-shadow: 0 2px 8px 0 var(--color-shadow);
  }
  &:active {
    // box-shadow: inset 0 0 0 100vh rgba(0, 0, 0, 0.1);
    box-shadow: inset 0 0 0 100vh var(--color-active);
  }
`;

export default function Transaction(props) {
  const [modalVisible, setModalVisible] = useState(false);
  const [confirmDeleteVisible, setConfirmDeleteVisible] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const { transaction } = props;

  const book = transaction.book.name;

  const dispatch = useDispatch();

  return (
    <>
      {/* modals */}
      <ModalWrapper
        modalIsOpen={modalVisible}
        onClose={() => setModalVisible(false)}
        onRequestClose={() => setModalVisible(false)}
        title={`Edit Transaction`}
      >
        <EditTransaction
          hideModal={() => setModalVisible(false)}
          passedTransaction={transaction}
          book={book}
        />
      </ModalWrapper>

      <ModalWrapper
        modalIsOpen={confirmDeleteVisible}
        onClose={() => setConfirmDeleteVisible(false)}
        onRequestClose={() => setConfirmDeleteVisible(false)}
        title="Confirm Delete"
        modalSize="small"
      >
        <ConfirmDelete
          dismissModal={() => setConfirmDeleteVisible(false)}
          onDelete={() => {
            setDeleting(true);
            dispatch(deleteTransaction(transaction.id));
            // setDeleting(false);
          }}
        >
          <p>Are you sure you want to delete this transaction?</p>
        </ConfirmDelete>
      </ModalWrapper>

      <TransactionWrapper>
        <Row
          style={{ cursor: 'pointer' }}
          onClick={() => setModalVisible(true)}
        >
          <Col
            style={{
              flex: 3,
              justifyContent: 'center',
              alignItems: 'flex-start',
            }}
          >
            <b>
              {transaction.transaction_type &&
                transaction.transaction_type.charAt(0).toUpperCase() +
                  transaction.transaction_type.slice(1)}
            </b>

            <small>{moment(transaction.date).format('ddd MMM D')}</small>
            <small>{transaction.description || 'No description'}</small>
          </Col>

          <Col>
            {/* using AutoColoredAmount for its dollar rounding */}
            <h6 style={{ margin: 0 }}>
              <AutoColoredAmount
                symbol="$"
                style={{
                  color: 'var(--color-text)',
                }}
                bold="true"
              >
                {transaction.amount}
              </AutoColoredAmount>
            </h6>
          </Col>
        </Row>

        {deleting ? (
          <ActivityIndicator size={1.5} />
        ) : (
          <>
            <Col style={{ flex: 0 }}>
              <IconButton
                iconName={IoPencilSharp}
                btnTheme="slim"
                iconSize={22}
                colorTheme="text"
                containerStyle={{
                  minWidth: '7rem',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                onPress={() => setModalVisible(true)}
              />
            </Col>
            <Col style={{ flex: 0 }}>
              <IconButton
                iconName={IoTrashOutline}
                btnTheme="slim"
                colorTheme="danger"
                iconSize={22}
                containerStyle={{
                  minWidth: '7rem',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                onPress={() => setConfirmDeleteVisible(true)}
              />
            </Col>
          </>
        )}
      </TransactionWrapper>
    </>
  );
}
