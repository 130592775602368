// actions for view other users things
// ex. leaderboard, public profile, following another user

// actions (excluding success and failure actions):
export const GET_LEADERBOARD = 'GET_LEADERBOARD';
export const GET_PUBLIC_PROFILE = 'GET_PUBLIC_PROFILE';
export const GET_FOLLOWING = 'GET_FOLLOWING';
export const GET_FOLLOWERS = 'GET_FOLLOWERS';
export const FOLLOW_USER = 'FOLLOW_USER';
export const UNFOLLOW_USER = 'UNFOLLOW_USER';
export const SET_PACKAGE = 'SET_PACKAGE';
export const BUY_PACKAGE = 'BUY_PACKAGE';
export const RESET_PURCHASE_SUCCESS = 'RESET_PURCHASE_SUCCESS';
export const EDIT_RELATIONSHIP = 'EDIT_RELATIONSHIP';
export const RESET_ERRORS_USER = 'RESET_ERRORS_USER';
export const GET_PUBLIC_PROFILE_BY_USERNAME = 'GET_PUBLIC_PROFILE_BY_USERNAME';

// GET LEADERBOARD
export const GET_LEADERBOARD_SUCCESS = 'GET_LEADERBOARD_SUCCESS';
export const GET_LEADERBOARD_FAILURE = 'GET_LEADERBOARD_FAILURE';

export const getLeaderboard = (
  limit,
  sport,
  order_by,
  date_after,
  date_before,
  only_following,
  sample_size
) => ({
  type: GET_LEADERBOARD,
  limit,
  sport,
  order_by,
  date_after,
  date_before,
  only_following,
  sample_size,
});

export const getLeaderboardSuccess = payload => ({
  type: GET_LEADERBOARD_SUCCESS,
  payload,
});

export const getLeaderboardFailure = error => ({
  type: GET_LEADERBOARD_FAILURE,
  error,
});

// GET PUBLIC PROFILE
export const GET_PUBLIC_PROFILE_SUCCESS = 'GET_PUBLIC_PROFILE_SUCCESS';
export const GET_PUBLIC_PROFILE_FAILURE = 'GET_PUBLIC_PROFILE_FAILURE';

export const getPublicProfile = user_id => ({
  type: GET_PUBLIC_PROFILE,
  user_id,
});

export const getPublicProfileSuccess = payload => ({
  type: GET_PUBLIC_PROFILE_SUCCESS,
  payload,
});

export const getPublicProfileFailure = error => ({
  type: GET_PUBLIC_PROFILE_FAILURE,
  error,
});

// GET PUBLIC PROFILE BY USERNAME
export const GET_PUBLIC_PROFILE_BY_USERNAME_SUCCESS =
  'GET_PUBLIC_PROFILE_BY_USERNAME_SUCCESS';
export const GET_PUBLIC_PROFILE_BY_USERNAME_FAILURE =
  'GET_PUBLIC_PROFILE_BY_USERNAME_FAILURE';

export const getPublicProfileByUsername = username => ({
  type: GET_PUBLIC_PROFILE_BY_USERNAME,
  username,
});

export const getPublicProfileByUsernameSuccess = payload => ({
  type: GET_PUBLIC_PROFILE_BY_USERNAME_SUCCESS,
  payload,
});

export const getPublicProfileByUsernameFailure = error => ({
  type: GET_PUBLIC_PROFILE_BY_USERNAME_FAILURE,
  error,
});

// GET FOLLOWERS
export const GET_FOLLOWERS_SUCCESS = 'GET_FOLLOWERS_SUCCESS';
export const GET_FOLLOWERS_FAILURE = 'GET_FOLLOWERS_FAILURE';
export const GET_FOLLOWERS_NEXT = 'GET_FOLLOWERS_NEXT';

export const getFollowers = user => ({
  type: GET_FOLLOWERS,
  user: user,
});

export const getFollowersSuccess = payload => ({
  type: GET_FOLLOWERS_SUCCESS,
  payload,
});

export const getFollowersFailure = error => ({
  type: GET_FOLLOWERS_FAILURE,
  error,
});

export const getFollowersNext = () => ({
  type: GET_FOLLOWERS_NEXT,
});

// GET FOLLOWING
export const GET_FOLLOWING_SUCCESS = 'GET_FOLLOWING_SUCCESS';
export const GET_FOLLOWING_FAILURE = 'GET_FOLLOWING_FAILURE';
export const GET_FOLLOWING_NEXT = 'GET_FOLLOWING_NEXT';

export const getFollowing = () => ({
  type: GET_FOLLOWING,
});

export const getFollowingSuccess = payload => ({
  type: GET_FOLLOWING_SUCCESS,
  payload,
});

export const getFollowingFailure = error => ({
  type: GET_FOLLOWING_FAILURE,
  error,
});

export const getFollowingNext = () => ({
  type: GET_FOLLOWING_NEXT,
});

// FOLLOW USER
export const FOLLOW_USER_SUCCESS = 'FOLLOW_USER_SUCCESS';
export const FOLLOW_USER_FAILURE = 'FOLLOW_USER_FAILURE';

export const followUser = otherUserID => ({
  type: FOLLOW_USER,
  otherUserID,
});

export const followUserSuccess = (payload, otherUserID) => ({
  type: FOLLOW_USER_SUCCESS,
  payload,
  otherUserID,
});

export const followUserFailure = error => ({
  type: FOLLOW_USER_FAILURE,
  error,
});

// UNFOLLOW USER
export const UNFOLLOW_USER_SUCCESS = 'UNFOLLOW_USER_SUCCESS';
export const UNFOLLOW_USER_FAILURE = 'UNFOLLOW_USER_FAILURE';

export const unfollowUser = otherUserID => ({
  type: UNFOLLOW_USER,
  otherUserID,
});

export const unfollowUserSuccess = (payload, otherUserID) => ({
  type: UNFOLLOW_USER_SUCCESS,
  payload,
  otherUserID,
});

export const unfollowUserFailure = error => ({
  type: UNFOLLOW_USER_FAILURE,
  error,
});

// SET PACKAGE
export const setPackage = userPackage => ({
  type: SET_PACKAGE,
  userPackage: userPackage,
});

// BUY PACKAGE
export const BUY_PACKAGE_SUCCESS = 'BUY_PACKAGE_SUCCESS';
export const BUY_PACKAGE_FAILURE = 'BUY_PACKAGE_FAILURE';

export const buyPackage = (userPackage, sessionId) => ({
  type: BUY_PACKAGE,
  userPackage,
  sessionId,
});

export const buyPackageSuccess = payload => ({
  type: BUY_PACKAGE_SUCCESS,
  payload,
});

export const buyPackageFailure = error => ({
  type: BUY_PACKAGE_FAILURE,
  error,
});

// RESET PURCHASE SUCCESS
export const resetPurchaseSuccess = () => ({
  type: RESET_PURCHASE_SUCCESS,
});

// EDIT_RELATIONSHIP
export const EDIT_RELATIONSHIP_SUCCESS = 'EDIT_RELATIONSHIP_SUCCESS';
export const EDIT_RELATIONSHIP_FAILURE = 'EDIT_RELATIONSHIP_FAILURE';

export const editRelationship = (otherUserID, notifications) => ({
  type: EDIT_RELATIONSHIP,
  otherUserID,
  notifications,
});

export const editRelationshipSuccess = (payload, otherUserID) => ({
  type: EDIT_RELATIONSHIP_SUCCESS,
  payload,
  otherUserID,
});

export const editRelationshipFailure = error => ({
  type: EDIT_RELATIONSHIP_FAILURE,
  error,
});

// RESET_ERRORS_USER
export const resetErrorsUser = () => ({
  type: RESET_ERRORS_USER,
});
