import { combineEpics } from 'redux-observable';

import getPurchaseSession from 'epics/stripe/getPurchaseSession.epic';
import startPurchaseSession from 'epics/stripe/startPurchaseSession.epic';
import createSubscription from 'epics/stripe/createSubscription.epic';
import viewPurchases from 'epics/stripe/viewPurchases.epic.js';
import cancelSubscription from 'epics/stripe/cancelSubscription.epic';
import getPurchase from 'epics/stripe/getPurchase.epic.js';
import updatePaymentMethod from 'epics/stripe/updatePaymentMethod.epic.js';
import purchasesNext from 'epics/stripe/purchasesNext.epic.js';

export default combineEpics(
  getPurchaseSession,
  startPurchaseSession,
  createSubscription,
  viewPurchases,
  cancelSubscription,
  getPurchase,
  updatePaymentMethod,
  purchasesNext
);
