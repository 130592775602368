import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import {
  IoAnalyticsSharp,
  IoLogoTwitter,
  IoNotifications,
  IoNotificationsOff,
  IoLinkOutline,
} from 'react-icons/io5';

// utils
import { commaSeparateNumber } from 'utils';

// components
import { Row, Col } from 'components/generic/Layout';
import { BetstampStamp, HammerLogo } from 'components/generic/Logos';
import FollowBtn from '../FollowBtn';
import ProfilePic from 'components/generic/ProfilePic';
import AutoColoredAmount from 'components/generic/AutoColoredAmount';
import ModalWrapper from 'components/generic/ModalWrapper';
import { AuthButton, IconButton, LinkButton } from 'components/AuthButton';
import { deSanitizeUrlText } from '../../utils/urlTextModifiers';
// import OpenProfileInApp from 'components/OpenProfileInApp';

// modals
import Followers from 'components/modals/Followers';
import Following from 'components/modals/Following';

// actions
import { getStripeExpressDashboardUrl, editRelationship } from 'actions';

const BluePart = styled.div`
  width: 100%;
  background-color: var(--color-primary);
  color: white;
  padding: var(--space-xl);
  box-sizing: border-box;
  height: 10rem;
  @media only screen and (max-width: 625px) {
    padding: var(--space-sm);
    height: 5rem;
  }
`;

const Wrapper = styled.div`
  position: relative;
  width: 70vw;
  margin: 0 auto;
  box-sizing: border-box;

  flex: 1;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;

  margin-top: -4rem;

  @media only screen and (max-width: 1366px) {
    width: 100%;
    padding: 0 var(--space-xs);
  }

  @media only screen and (max-width: 625px) {
    width: 100%;
    padding: 0;
    margin-top: -2.5rem;
    padding: 0 var(--space-xs);
  }
`;

const UsernameCol = styled.div`
  flex: 0.8;
  display: flex;
  flex-flow: column wrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: var(--space-xs);
  margin-top: 0;

  @media only screen and (max-width: 625px) {
    margin-top: -4px;
    & > div > h2 {
      font-size: var(--text-xl);
    }
  }
`;

export default function UserProfileHeader(props) {
  const dispatch = useDispatch();

  const [followersModalVisible, setFollowersModalVisible] = useState(false);
  const [followingModalVisible, setFollowingModalVisible] = useState(false);

  const {
    user,
    isPublic,
    showFollowButton,
    showExpressDashboardButton,
    viewingSelf,
    hideViewMyProfileButton,
  } = props;

  const reduxProps = useSelector(state => ({
    userToken: state.authReducer.userToken,
    expressDashboardLoading: state.authReducer.expressDashboardLoading,
    expressDashboardURL: state.authReducer.expressDashboardURL,
    expressDashboardError: state.authReducer.expressDashboardError,
  }));

  const {
    userToken,
    expressDashboardLoading,
    expressDashboardURL,
    expressDashboardError,
  } = reduxProps;

  useEffect(() => {
    if (expressDashboardURL && expressDashboardURL.trim() !== '') {
      window.location.href = expressDashboardURL;
    }
  }, [expressDashboardURL]);

  return (
    <>
      <Helmet>
        <title>{user.username}</title>
      </Helmet>

      {userToken && (
        <ModalWrapper
          modalIsOpen={followersModalVisible}
          onClose={() => setFollowersModalVisible(false)}
          onRequestClose={() => setFollowersModalVisible(false)}
          title="Followers"
          id="followers-modal-wrapper"
        >
          <Followers viewingSelf={viewingSelf} />
        </ModalWrapper>
      )}

      {userToken && isPublic === false && (
        <ModalWrapper
          modalIsOpen={followingModalVisible}
          onClose={() => setFollowingModalVisible(false)}
          onRequestClose={() => setFollowingModalVisible(false)}
          title="Following"
          id="following-modal-wrapper"
        >
          <Following viewingSelf={viewingSelf} />
        </ModalWrapper>
      )}

      <BluePart />

      <Wrapper>
        <Col style={{ flex: '0 0 8rem' }} desktop>
          <ProfilePic user={user} style={{ width: '8rem', height: '8rem' }} />
        </Col>
        <Col style={{ flex: '0 0 5rem' }} mobile>
          <ProfilePic user={user} style={{ width: '5rem', height: '5rem' }} />
        </Col>
        <UsernameCol>
          <Row style={{ alignItems: 'center' }}>
            <h2 style={{ margin: 0, color: 'white' }}>{user.username}</h2>
            {user.is_verified && (
              <BetstampStamp
                width={32}
                height={32}
                style={{
                  marginLeft: 'var(--space-xxxs)',
                }}
              />
            )}
          </Row>
          <Row mobile>
            <Twitter user={user} />
            {!!user.hammer_link && <Hammer hammer_link={user.hammer_link} />}
          </Row>
          {userToken && isPublic === true && showFollowButton ? (
            <Row style={{ marginTop: 'var(--space-sm)' }} desktop>
              <FollowBtn user={user} />
            </Row>
          ) : (
            <Row
              style={{
                marginTop: 'var(--space-sm)',
                alignItems: 'center',
                minHeight: 42,
              }}
              desktop
            >
              {userToken && !hideViewMyProfileButton && (
                <LinkButton to={`/u/${user.username}`}>
                  View My Profile
                </LinkButton>
              )}
              {showExpressDashboardButton && user.stripe_connect_id && (
                <>
                  {expressDashboardError ? (
                    <Col style={{ marginLeft: 'var(--space-xs)' }}>
                      <small>
                        Sorry, we ran into an error! Refresh the page and try
                        again.
                      </small>
                    </Col>
                  ) : (
                    <AuthButton
                      containerStyle={{
                        margin: '0 0 0 var(--space-xs)',
                      }}
                      isLoading={expressDashboardLoading}
                      disabled={expressDashboardLoading}
                      onPress={() => dispatch(getStripeExpressDashboardUrl())}
                    >
                      Payment Account
                    </AuthButton>
                  )}
                </>
              )}
            </Row>
          )}
        </UsernameCol>

        <UserStats
          user={user}
          userToken={userToken}
          isPublic={isPublic}
          openFollowers={() => setFollowersModalVisible(true)}
          openFollowing={() => setFollowingModalVisible(true)}
        />
      </Wrapper>

      <Row
        mobile
        style={{ justifyContent: 'flex-start', alignItems: 'center' }}
      >
        <div style={{ margin: '0 var(--space-sm)' }}>
          <b>{commaSeparateNumber(user.following)}</b> Following
        </div>
        {isPublic === true && showFollowButton && <FollowBtn user={user} />}
      </Row>
      {user.twitter && (
        <Wrapper style={{ marginTop: 0 }}>
          <Row desktop>
            <Twitter user={user} />
          </Row>
        </Wrapper>
      )}
      {!!user.hammer_link && (
        <Wrapper style={{ marginTop: 'var(--space-xs)' }}>
          <Hammer hammer_link={user.hammer_link} />
        </Wrapper>
      )}

      {user.bio && (
        <Wrapper style={{ justifyContent: 'flex-start', marginTop: '0' }}>
          <p>{user.bio}</p>
        </Wrapper>
      )}
      {user.link && (
        <Wrapper style={{ justifyContent: 'flex-start', marginTop: '0' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <IoLinkOutline size={24} />
            {/* Using css instead of inline to achieve hover affect */}
            <a
              className="websiteLink"
              target="_blank"
              rel="noopener noreferrer"
              href={user.link}
              style={{ margin: '0 var(--space-xxs)' }}
            >
              {deSanitizeUrlText(user.link).length < 28
                ? deSanitizeUrlText(user.link)
                : `${deSanitizeUrlText(user.link).slice(0, 25)}...`}
            </a>
          </div>
        </Wrapper>
      )}
    </>
  );
}

function Twitter({ user }) {
  return (
    <Row style={{ alignItems: 'center', justifyContent: 'flex-start' }}>
      <IoLogoTwitter
        alt={`${user.username}'s Twitter`}
        size={28}
        color={user.twitter ? 'var(--color-text)' : 'transparent'}
      />
      {user.twitter && (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`https://twitter.com/${user.twitter}`}
        >
          {user.twitter}
        </a>
      )}
    </Row>
  );
}

function Hammer({ hammer_link }) {
  return (
    <Row style={{ alignItems: 'center', justifyContent: 'flex-start' }}>
      <a href={hammer_link} target="_blank" rel="noopener noreferrer">
        <HammerLogo style={{ margin: '0 var(--space-xs) 0 0' }} height={18} />
        Hammer Profile
      </a>
    </Row>
  );
}

function UserStats(props) {
  const dispatch = useDispatch();
  const { user, isPublic, userToken } = props;
  if (!user) return null;

  let units = user.stats.total_profit / user.stats.bet_size;
  if (isNaN(units)) {
    units = 0;
  }

  return (
    <Col desktop style={{ width: '100%', marginTop: '-8rem' }}>
      <Row
        style={{
          width: '100%',
          backgroundColor: 'var(--color-bg)',
          borderRadius: 'var(--std-border-radius)',
          padding: 'var(--space-sm)',
        }}
      >
        <Col>
          <small style={{ opacity: 0.75, fontWeight: 'bold' }}>RECORD</small>
          <b style={{ margin: 0 }}>
            {user.stats.W}-{user.stats.L}-{user.stats.P}
          </b>
        </Col>
        <Col>
          <small style={{ opacity: 0.75, fontWeight: 'bold' }}>
            TOTAL UNITS
          </small>
          <b
            style={{
              // fontSize: 'var(--text-xl)',
              color:
                units > 0
                  ? 'var(--color-dollar-green)'
                  : units === 0
                  ? 'var(--color-text)'
                  : 'var(--color-dollar-red)',
            }}
          >
            {units > 0 ? '+' : ''}
            {Math.round(units * 10) / 10}
          </b>
        </Col>
        <Col>
          <small style={{ opacity: 0.75, fontWeight: 'bold' }}>ROI</small>
          <AutoColoredAmount bold={'true'} symbol={'%'}>
            {user.stats.roi}
          </AutoColoredAmount>
        </Col>
        <Col>
          <small style={{ opacity: 0.75, fontWeight: 'bold' }}>CLV</small>
          <AutoColoredAmount bold={'true'} symbol={'%'}>
            {user.stats.clv}
          </AutoColoredAmount>
        </Col>
        <Col style={{ flex: 0 }}>
          <Link
            to={`/analysis?user=${user.id}&time_period=7&date_range=[null,null]&verified=true&`}
          >
            <IoAnalyticsSharp
              size={32}
              title={`ANALYZE ${
                user.username ? user.username.toUpperCase() : 'USER'
              }'S PICKS`}
              style={{ padding: '0 var(--space-sm)' }}
            />
          </Link>
        </Col>
      </Row>
      <Row
        style={{
          width: '100%',
          color: 'white',
          justifyContent: 'space-evenly',
          padding: 'var(--space-sm)',
          alignItems: 'center',
        }}
      >
        <AuthButton
          onPress={
            userToken && isPublic === false ? () => props.openFollowing() : null
          }
          colorTheme={'inverted'}
          btnStyle={
            isPublic
              ? { color: 'white', cursor: 'not-allowed', boxShadow: 'none' }
              : { color: 'white' }
          }
        >
          <span>
            <b>{commaSeparateNumber(user.following)}</b> Following
          </span>
        </AuthButton>
        <AuthButton
          onPress={userToken ? () => props.openFollowers() : null}
          colorTheme={'inverted'}
          btnStyle={
            !userToken
              ? { color: 'white', cursor: 'not-allowed', boxShadow: 'none' }
              : { color: 'white' }
          }
        >
          <span>
            <b>{commaSeparateNumber(user.followers)}</b> Followers
          </span>
        </AuthButton>
        {user.relationship_to_user.status === 1 ? (
          <IconButton
            colorTheme="inverted"
            iconSize={26}
            onPress={() =>
              dispatch(
                editRelationship(
                  user.id,
                  !user.relationship_to_user.notifications
                )
              )
            }
            iconName={
              isPublic && user.relationship_to_user.notifications
                ? IoNotifications
                : IoNotificationsOff
            }
          />
        ) : (
          ''
        )}
      </Row>
    </Col>
  );
}
