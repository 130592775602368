import React, { useState } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { IoChevronForward } from 'react-icons/io5';

// components
import { Col } from 'components/generic/Layout';
import { AuthButton } from 'components/AuthButton';
import ModalWrapper from 'components/generic/ModalWrapper';
import SaveAnalysisPreset from 'components/modals/SaveAnalysisPreset';

// filters
import Xaxis from 'components/analysis/filters/Xaxis.filter';
import Yaxis from 'components/analysis/filters/Yaxis.filter';
import IsVerified from 'components/analysis/filters/IsVerified.filter';
import BetStatus from 'components/analysis/filters/BetStatus.filter';
import LiveBets from 'components/analysis/filters/LiveBets.filter';
import Sportsbook from 'components/analysis/filters/Sportsbook.filter';
import AnalysisDateRange from 'components/analysis/filters/AnalysisDateRange.filter';
import TimePeriod from 'components/analysis/filters/TimePeriod.filter';
import League from 'components/analysis/filters/League.filter';
import BetType from 'components/analysis/filters/BetType.filter';
import GamePeriod from 'components/analysis/filters/GamePeriod.filter';
import Tags from 'components/analysis/filters/Tags.filter';
import AnalysisFilterUser from 'components/analysis/filters/AnalysisFilterUser.filter';

// actions
import { getAnalysis, setAnalysisFilter } from 'actions';

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  background-color: var(--color-fg);
  height: 100%;

  flex: 1;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: stretch;
  box-sizing: border-box;
  width: 100%;
`;

const filterNameSlugs = {
  Xaxis: 'Columns',
  Yaxis: 'Rows',
  Books: 'Sportsbooks',
  Verified: 'Verified',
  Bet_Status: 'Bet Status',
  Game_Period: 'Game Period',
  Date_Range: 'Date Range',
  Time_Period: 'Time Period',
  Leagues: 'Leagues',
  Bet_Types: 'Bet Types',
  Tags: 'Tags',
  Live_Bets: 'Live Bets',
};

export default function AnalysisFilters(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const reduxProps = useSelector(state => ({
    user: state.authReducer.user,
    filters: state.analysisReducer.filters,
    urlparams: state.analysisReducer.urlparams,
    currentAnalysis: state.analysisReducer.currentAnalysis,
    currentUrlParams: state.analysisReducer.currentUrlParams,
  }));

  const { user, filters, urlparams, currentAnalysis } =
    reduxProps;

  const [showing, setShowing] = useState(null);
  const [singleFilterShowing, setSingleFilterShowing] = useState(null);

  const _onDone = data => {
    dispatch(setAnalysisFilter(data));
    setShowing('list');
  };

  const { searchTerm } = props;
  let filteredFilters = Object.keys(filters);
  if (searchTerm) {
    filteredFilters = filteredFilters.filter(filter =>
      filterNameSlugs[filter].toLowerCase().includes(searchTerm.toLowerCase())
    );
  }

  const [presetModalVisible, setPresetModalVisible] = useState(false);

  return (
    <>
      {presetModalVisible && (
        <ModalWrapper
          modalIsOpen={presetModalVisible}
          onClose={() => setPresetModalVisible(false)}
          onRequestClose={() => setPresetModalVisible(false)}
          title={'Save Analysis Preset'}
        >
          <SaveAnalysisPreset
            dismissModal={() => setPresetModalVisible(false)}
          />
        </ModalWrapper>
      )}
      <div style={{ flex: 1, position: 'relative', overflowX: 'hidden' }}>
        <Wrapper
          className={
            showing === null
              ? ''
              : showing === 'list'
              ? 'animate-analysis-all-filters-open'
              : 'animate-analysis-all-filters-close'
          }
        >
          {filteredFilters.map((filter, i) => (
            <React.Fragment key={`filter-list-item-${i}`}>
              {filter === 'Tags' &&
              filters['User'].value &&
              filters['User'].value !== user.id ? (
                <></>
              ) : (
                <>
                  <FilterListItem
                    filterName={filter}
                    filter={filters[filter]}
                    setShow={show => {
                      setShowing(show);
                      setSingleFilterShowing(show);
                    }}
                    onDone={_onDone}
                    analysingUser={currentAnalysis?.user?.username}
                  />
                </>
              )}
            </React.Fragment>
          ))}
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
        </Wrapper>

        <Wrapper
          style={{ padding: '0 var(--space-xs)' }}
          className={
            showing === null
              ? 'initial-one-filter-position'
              : showing !== 'list'
              ? 'animate-analysis-filter-open'
              : 'animate-analysis-filter-close'
          }
        >
          {singleFilterShowing === 'Xaxis' && (
            <Xaxis
              defaultValue={filters['Xaxis'].value}
              defaultSlug={filters['Xaxis'].slug}
              onDone={data => _onDone({ title: 'Calculations', ...data })}
            />
          )}
          {singleFilterShowing === 'Books' && (
            <Sportsbook
              defaultValue={filters['Books'].value}
              defaultSlug={filters['Books'].slug}
              onDone={data => _onDone({ title: 'Sportsbooks', ...data })}
            />
          )}
          {singleFilterShowing === 'User' && (
            <AnalysisFilterUser
              defaultValue={filters['User'].value}
              defaultSlug={filters['User'].slug}
              onDone={data => _onDone({ title: 'User', ...data })}
            />
          )}
          {singleFilterShowing === 'Date_Range' && (
            <AnalysisDateRange
              defaultValue={filters['Date_Range'].value}
              defaultSlug={filters['Date_Range'].slug}
              onDone={data => _onDone({ title: 'Date Range', ...data })}
            />
          )}
          {singleFilterShowing === 'Time_Period' && (
            <TimePeriod
              defaultValue={filters['Time_Period'].value}
              defaultSlug={filters['Time_Period'].slug}
              onDone={data => _onDone({ title: 'Time Period', ...data })}
            />
          )}
          {singleFilterShowing === 'Leagues' && (
            <League
              defaultValue={filters['Leagues'].value}
              defaultSlug={filters['Leagues'].slug}
              onDone={data => _onDone({ title: 'Leagues', ...data })}
            />
          )}
          {singleFilterShowing === 'Bet_Types' && (
            <BetType
              defaultValue={filters['Bet_Types'].value}
              defaultSlug={filters['Bet_Types'].slug}
              onDone={data => _onDone({ title: 'Bet Types', ...data })}
            />
          )}
          {singleFilterShowing === 'Tags' && (
            <Tags
              defaultValue={filters['Tags'].value}
              defaultSlug={filters['Tags'].slug}
              onDone={data => _onDone({ title: 'Tags', ...data })}
            />
          )}
          {singleFilterShowing === 'Game_Period' && (
            <GamePeriod
              defaultValue={filters['Game_Period'].value}
              defaultSlug={filters['Game_Period'].slug}
              onDone={data => _onDone({ title: 'Game Period', ...data })}
            />
          )}
        </Wrapper>
      </div>
      {(showing === 'list' || showing === null) && (
        <>
          <AuthButton
            btnTheme="borderless"
            containerStyle={{
              margin: 'var(--space-sm) var(--space-xs)',
              flex: 0,
              width: 'auto',
            }}
            onPress={() => {
              setPresetModalVisible(true);
            }}
          >
            Save Filters as Preset
          </AuthButton>
          <AuthButton
            containerStyle={{
              margin: 'var(--space-xs) var(--space-xs)',
              flex: 0,
              width: 'auto',
            }}
            onPress={() => {
              history.push(`${urlparams}`);
              dispatch(getAnalysis(urlparams, 'table', false, -1));
            }}
          >
            Apply Filters
          </AuthButton>
        </>
      )}
    </>
  );
}

const FilterListItemWrapper = styled.button`
  flex: 0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  align-items: stretch;
  box-sizing: border-box;
  background-color: var(--color-fg);
  outline: none;
  padding: var(--space-xs);
  margin: var(--space-xxs) var(--space-xxxs);
  cursor: pointer;
  transition: all var(--std-transition);
  border-radius: var(--std-border-radius);
  border: none;
  color: var(--color-text);
  &:active {
    background-color: var(--color-bg);
  }
`;

function FilterListItem(props) {
  const { filterName, filter, onDone, analysingUser } = props;
  const { is_premium, slug } = filter;

  let FilterAction = IoChevronForward;
  let flexAmount = 0;
  let _click = () => props.setShow(filterName);

  if (filterName === 'Yaxis' || filterName === 'Yaxis2') {
    FilterAction = Yaxis;
    flexAmount = 1;
    _click = null;
  } else if (filterName === 'Verified') {
    FilterAction = IsVerified;
    flexAmount = 1;
    _click = null;
  } else if (filterName === 'Bet_Status') {
    FilterAction = BetStatus;
    flexAmount = 1;
    _click = null;
  } else if (filterName === 'Live_Bets') {
    FilterAction = LiveBets;
    flexAmount = 1;
    _click = null;
  }

  let actionProps = {
    size: 22,
    color: 'var--color-text)',
  };

  if (_click === null) {
    actionProps = {
      // props for other
      filterName: filterName,
      filterTitle: filter.title,
      defaultValue: filter.value,
      onDone: data => onDone({ title: filter.title, ...data }),
    };
  }

  if (is_premium) return <></>;

  return (
    <FilterListItemWrapper onClick={_click}>
      <Col style={{ alignItems: 'flex-start', justifyContent: 'center' }}>
        <p style={{ margin: 0, textAlign: 'left', fontWeight: 'bold' }}>
          {filter.title}
        </p>
        <span
          style={{ lineHeight: 1, textAlign: 'left', lineBreak: 'anywhere' }}
        >
          {filterName === 'User' && !isNaN(slug) && analysingUser
            ? analysingUser
            : slug}
        </span>
      </Col>
      <Col style={{ flex: flexAmount, alignItems: 'flex-end' }}>
        <FilterAction {...actionProps} />
      </Col>
    </FilterListItemWrapper>
  );
}
