export const sanitizeUrlText = text => {
  if (text.length === 0) {
    return text;
  } else if (
    text.slice(0, 7) === 'http://' ||
    text.slice(0, 8) === 'https://'
  ) {
    return text;
  } else {
    return 'https://' + text;
  }
};

export const deSanitizeUrlText = text => {
  if (text.slice(0, 7) === 'http://') {
    return text.slice(7);
  } else if (text.slice(0, 8) === 'https://') {
    return text.slice(8);
  } else {
    return text;
  }
};
