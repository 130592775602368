import {
  SET_SEEN_TOUR,
  SET_TOUR_STEP,
  MODIFY_TOUR_STEP,
  SET_TOUR_CUTOUT,
} from 'actions';

const TOURS = {
  // 1. start, tap league selector
  games1: {
    id: 'games1',
    modal: false,
    top: false,
    cutoutRadius: 8,
    showSkip: true,
    fadeIn: true,
  },

  // 2. add nfl to faves
  games2: {
    id: 'games2',
    modal: true,
    top: true,
    cutoutRadius: 8,
    reFadeIn: true,
  },

  // 3. blurb about faves, star icon
  games3: { id: 'games3', modal: true, cutoutRadius: 8, reFadeIn: true },

  // 4. tap nba in league selector
  games4: {
    id: 'games4',
    modal: true,
    top: true,
    cutoutRadius: 8,
  },

  // 5. nba main, percents, best avail
  games5: {
    id: 'games5',
    modal: false,
    top: false,
    cutoutRadius: 8,
    part: 1,
    bgOpacity: [0.01],
    reFadeIn: true,
  },

  // 6. tap on nba game
  games6: {
    id: 'games6',
    modal: false,
    top: false,
    cutoutRadius: 8,
    showSkip: true,
    skipTop: 200,
  },

  // 7. best avail box single game
  games7: {
    id: 'games7',
    modal: false,
    top: false,
    cutoutRadius: 8,
    reFadeIn: true,
  },

  // 8. open account at book (single game screen)
  games8: {
    id: 'games8',
    modal: false,
    top: true,
    cutoutRadius: 8,
    reFadeIn: true,
  },

  // 9. lets track a fake play (tap spread)
  games9: {
    id: 'games9',
    modal: false,
    top: false,
    cutoutRadius: 8,
    reFadeIn: true,
  },

  // 10. inside EditBetslipBet
  games10: {
    id: 'games10',
    modal: true,
    top: false,
    cutoutRadius: 100,
    part: 1,
    bgOpacity: [0.01],
    reFadeIn: true,
  },

  // 11. actually tap on "Track"
  games11: {
    id: 'games11',
    modal: true,
    hideBlueOverlay: true,
    top: true,
    cutoutRadius: 24,
  },

  // 12. congrats on first bet, tap "Go Back"
  games12: {
    id: 'games12',
    modal: true,
    top: false,
    cutoutRadius: 24,
  },

  // 13. Tap My Pick in bottom nav
  games13: {
    id: 'games13',
    modal: false,
    top: false,
    cutoutRadius: 8,
    reFadeIn: true,
  },

  // 14. my picks screen tour
  games14: {
    id: 'games14',
    modal: false,
    top: false,
    cutoutRadius: 8,
    part: 1,
    bgOpacity: [0.01, 0.01],
    reFadeIn: true,
    showSkip: true,
  },

  // 15. head over to historicals
  games15: {
    id: 'games15',
    modal: false,
    top: false,
    cutoutRadius: 8,
  },

  // 16. in historicals, part2: highlight analysis
  games16: {
    id: 'games16',
    modal: false,
    top: false,
    cutoutRadius: 8,
    part: 1,
    bgOpacity: [0.01],
  },

  // 17. highlight betlink
  games17: {
    id: 'games17',
    modal: false,
    top: false,
    cutoutRadius: 8,
  },

  // 18. on betlink screen
  games18: {
    id: 'games18',
    modal: false,
    top: false,
    cutoutRadius: 8,
    bgOpacity: [0.01],
    part: 1,
  },

  // 19. tour over
  games19: {
    id: 'games19',
    modal: false,
    top: false,
    cutoutRadius: 8,
  },
};

const initialState = {
  // old tour, not yet adapted
  seenGameOddsTour: false,
  seenTrackBetTour: false,
  seenMarketplaceTour: false,
  seenMyPicksTour: false,
  seenMyPicksTour2: false,

  // new
  seenGamesScreenTour: false, // going to reuse, to applies to id: games#
  currentTourStep: null,
  cutoutData: {},
};

export default function settingsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_SEEN_TOUR:
      const newState = { ...state };
      newState[action.tour] = action.seen;
      return Object.assign({}, state, {
        ...newState,
        currentTourStep: null,
      });

    case SET_TOUR_STEP:
      // im going to count making it to step 2 as seen, so if the tour crashes or
      // something they wont be sent into and endless loop of the tour making
      // the app unusable
      const seenGames = state.seenGamesScreenTour || action.stepID === 'games2';
      return Object.assign({}, state, {
        currentTourStep: action.stepID ? TOURS[action.stepID] : null,
        seenGamesScreenTour: seenGames,
      });

    case MODIFY_TOUR_STEP:
      let moddedStep = null;
      if (state.currentTourStep) {
        moddedStep = { ...state.currentTourStep, ...action.mod };
      }
      return Object.assign({}, state, {
        currentTourStep: moddedStep,
      });

    case SET_TOUR_CUTOUT:
      return Object.assign({}, state, {
        cutoutData: {
          ...state.cutoutData,
          [action.stepID]: {
            ...state.cutoutData[action.stepID],
            ...action.cutoutData,
          },
        },
      });

    default:
      return state;
  }
}
