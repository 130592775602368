import {
  SET_GAME_SORT_BY,
  SET_GAME_FILTER,
  SET_APP_SETTING,
  TOGGLE_LEAGUE_FAV,
} from 'actions';
import { LEAGUES } from 'utils';

const initialState = {
  // fetching games defaults
  defaultGameSortBy: 'date',
  defaultGameFilter: 'all_games',
  gameSortBy: null, // overrides default if set, doesn't persist
  gameFilter: null, // overrides default if set, doesn't persist

  // games display settings
  showRotationNumber: false,
  showWinPercentage: true,
  showLiveOdds: true,
  // single game display settings
  showML: true,
  showSpread: true,
  showTotal: true,
  defaultTopTab: 'FullTime', // FullTime, GameProps, PlayerProps
  oddsDisplayView: 'compact',

  // league selector
  orderedLeagues: LEAGUES,
  faveLeagues: [],

  // general
  theme: 'dark',

  // placing bets, these are more like fallback defaults now
  riskOrBase: 'base',
  betSize: 10,
};

export default function settingsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_APP_SETTING:
      const newState = { ...state };
      newState[action.setting] = action.value;
      return Object.assign({}, state, {
        ...newState,
      });
    case SET_GAME_SORT_BY:
      return Object.assign({}, state, {
        ...state,
        gameSortBy: action.gameSortBy,
      });
    case SET_GAME_FILTER:
      return Object.assign({}, state, {
        ...state,
        gameFilter: action.gameFilter,
      });
    case TOGGLE_LEAGUE_FAV:
      let newFavLeagues = [...state.faveLeagues];
      if (newFavLeagues.includes(action.league)) {
        newFavLeagues = newFavLeagues.filter(l => l !== action.league);
      } else {
        newFavLeagues.push(action.league);
      }

      return Object.assign({}, state, {
        ...state,
        faveLeagues: newFavLeagues,
      });
    default:
      return state;
  }
}
