import React from 'react';

// app icon, blue bg, white b and circle
import Stamp from 'images/logos/betstamp_app_icon.svg';

// app icon, transparent bg, blue b and circle
import InvertedStamp from 'images/logos/betstamp_b_blue_trans.svg';

// full word, transparent bg, white text
import WordWhiteText from 'images/logos/betstamp_wordmark_white_trans.svg';

// full word, white bg, blue text
import WordBlueText from 'images/logos/betstamp_wordmark_blue_trans.svg';

// betlink stamp, blue
import BetlinkStampBlueSrc from 'images/logos/betlink_stamp_blue.svg';

// betlink stamp, gray
import BetlinkStampGraySrc from 'images/logos/betlink_stamp_gray.svg';

// betlink stamp, blue empty
import BetlinkStampBlueEmptySrc from 'images/logos/betlink_stamp_blue_empty.svg';

// betlink word, bluey
import BetlinkWordSrc from 'images/logos/betlink_word.svg';

// hammer logo, green
import HammerLogoSrc from 'images/logos/hammer_logo.svg';

export const BetstampStamp = props => (
  <img src={Stamp} {...props} alt="betstamp logo" />
);

export const BetstampStampInverted = props => (
  <img src={InvertedStamp} {...props} alt="betstamp logo" />
);

export const BetstampWordWhiteText = props => (
  <img src={WordWhiteText} {...props} alt="betstamp logo" />
);

export const BetstampWordBlueText = props => (
  <img src={WordBlueText} {...props} alt="betstamp logo" />
);

export const BetlinkStamp = props => {
  const { blue, ...otherprops } = props;
  return (
    <img
      {...otherprops}
      src={blue ? BetlinkStampBlueSrc : BetlinkStampGraySrc}
      alt="betlink"
    />
  );
};

export const BetlinkWord = props => (
  <img {...props} src={BetlinkWordSrc} alt="betlink" />
);

export const BetlinkStampEmptyBlue = props => (
  <img {...props} src={BetlinkStampBlueEmptySrc} alt="betlink" />
);

export const HammerLogo = props => (
  <img {...props} src={HammerLogoSrc} alt="the hammer" />
);
