export default {
  link_to_video: '',
  content: [
    {
      type: 'paragraph',
      text:
        'To edit your profile settings go to My Profile on the Settings Tab and click Edit Profile.',
    },
    {
      type: 'paragraph',
      text:
        'Use the edit profile screen to add a biography that other users can view, or change your name and region. Here you can also toggle to make your profile private or public.',
    },
    { type: 'heading', size: 5, text: 'Private Users' },
    {
      type: 'list-item',
      text:
        'Other users cannot find you in the app and cannot view any content or picks you have tracked. You will not show up on the public leaderboard',
    },
    {
      type: 'list-item',
      text:
        'You can still use the app for all of its features but you will not be able to post picks for sale if you are a private user',
    },
    { type: 'heading', size: 5, text: 'Public Users' },
    {
      type: 'list-item',
      text:
        'Any user can search for your name in the marketplace or on the leaderboard and find your profile',
    },
    {
      type: 'list-item',
      text:
        'Anybody can see any verified bets that you have made by viewing your profile and they can conduct an analysis of your verified bets',
    },
    {
      type: 'list-item',
      text:
        'You can post a package to the marketplace to sell your picks at any time if you are a public user',
    },
    {
      type: 'image',
      url:
        'https://betstamp-public.sfo2.cdn.digitaloceanspaces.com/tutorials/edit-profile/edit-profile.jpg',
    },
  ],
};
