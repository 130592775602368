import { Observable } from 'rxjs';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import { ajax } from 'rxjs/observable/dom/ajax';
import { BACKEND_API_URL, getHeaders } from 'utils';

import {
  CANCEL_SUBSCRIPTION,
  cancelSubscriptionSuccess,
  cancelSubscriptionFailure,
} from 'actions';

export default function cancelSubscription(action$, state$) {
  return action$.ofType(CANCEL_SUBSCRIPTION).switchMap(action => {
    return ajax
      .patch(
        BACKEND_API_URL + 'api/purchases/' + action.purchaseid + '/',
        action.purchase,
        getHeaders(state$)
      )
      .map(data => cancelSubscriptionSuccess(data))
      .catch(error => Observable.of(cancelSubscriptionFailure(error.xhr)));
  });
}
