import React, { useState } from 'react';
import {
  useInfiniteQuery,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';

// components
import { Col } from 'components/generic/Layout';
import { AuthTextInput } from 'components/AuthTextInput';
import StaffNavBar from 'components/nav/StaffNavBar';
import { AuthButton } from 'components/AuthButton';
import { RowTable } from 'pages/AffiliateEarningDashboard/AffiliateEarningDashboard';

import { humanDatetime } from 'utils';

export default function EmailReports(props) {
  const [filters, setFilters] = useState();

  const queryClient = useQueryClient();
  const submitEmailReport = useMutation({
    mutationKey: 'submit-email-report',
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [
          {
            endpoint: 'email-affiliate-reports',
            urlParams: filters,
          },
        ],
      });
    },
  });
  const getEmailReports = useInfiniteQuery({
    keepPreviousData: true,
    // change url path to something more generic
    queryKey: [{ endpoint: 'email-affiliate-reports', urlParams: filters }],
    getNextPageParam: (lastPage, allPages) => lastPage.next,
    getPreviousPageParam: (firstPage, allPages) => firstPage.prev,
    retry: false,
  });
  const pages = getEmailReports?.data?.pages;
  return (
    <>
      <StaffNavBar />
      <Col
        style={{
          width: '100%',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <form
          onSubmit={ev => {
            ev.preventDefault();
            submitEmailReport.mutate({
              endpoint: 'email-affiliate-reports',
              body: new FormData(ev.target),
            });
          }}
        >
          <AuthTextInput
            name="email_report"
            label="Upload Affiliate Report* (.csv)"
            type="file"
            accept=".csv"
          />
          <AuthButton
            type="submit"
            isLoading={
              submitEmailReport.isLoading || submitEmailReport.isFetching
            }
          >
            Submit Email Report
          </AuthButton>
          {submitEmailReport.isError && (
            <small
              style={{ textAlign: 'center', color: 'var(--color-danger)' }}
            >
              {submitEmailReport.error.message}
            </small>
          )}
          {submitEmailReport.isSuccess && (
            <small
              style={{
                textAlign: 'center',
                color: 'var(--color-success)',
              }}
            >
              {submitEmailReport.data.message}
            </small>
          )}
        </form>
        <Col
          style={{
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: 'var(--space-sm)',
          }}
        >
          {!getEmailReports.isLoading && !!pages && (
            <RowTable
              modelName={'Report'}
              headings={['File', 'Upload Time', 'Processed', '']}
              pages={pages}
              objKeys={['file_name', 'uploaded_at', 'is_processed']}
              onSort={sorts => setFilters({ ...filters, sort: sorts })}
              sortKeys={{
                File: 'file_name',
                file_name: 'File',
                'Upload Time': 'uploaded_at',
                uploaded_at: 'Upload Time',
                Processed: 'is_processed',
                is_processed: 'Processed',
              }}
              formatText={{
                uploaded_at: humanDatetime,
                is_processed: t => (
                  <div
                    style={{
                      width: '10px',
                      height: '10px',
                      borderRadius: '50%',
                      backgroundColor: t
                        ? 'var(--color-success)'
                        : 'var(--color-danger)',
                    }}
                  />
                ),
              }}
              sort={filters?.sort}
              queryObj={getEmailReports}
              isLoadingNext={getEmailReports.isFetchingNextPage}
              deleteBaseEndpoint={`email-affiliate-reports`}
            />
          )}
        </Col>
      </Col>
    </>
  );
}
