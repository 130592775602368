import React from 'react';
import { useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  useQuery,
  useMutation,
  useQueries,
  useQueryClient,
  useInfiniteQuery,
} from '@tanstack/react-query';
import { useParams, Redirect } from 'react-router-dom';
import { useEffectOnce, useCopyToClipboard, useUpdateEffect } from 'react-use';
import {
  IoClose,
  IoCopyOutline,
  IoCheckmark,
  IoChevronDown,
  IoChevronUp,
} from 'react-icons/io5';
import styled from 'styled-components';

// utils
import { formatMoney, humanDate, humanDatetime, STATE_OPTIONS } from 'utils';

// components
import { AuthButton, IconButton } from 'components/AuthButton';
import { Grid, Row, Col } from 'components/generic/Layout';
import ActivityIndicator from 'components/generic/ActivityIndicator';
import DisplayToggle from 'components/generic/DisplayToggle';
import ProgressListDisplay from 'components/onboarding/ProgressListDisplay';
import OnboardingPayouts from 'components/onboarding/OnboardingPayouts';
import PaymentList from 'components/onboarding/PaymentList';
import StageDropdown from 'components/onboarding/StageDropdown';
import Select from 'components/generic/Select';
import { AuthTextInput } from 'components/AuthTextInput';

// actions
import { getAllBooks } from 'actions';

const Tbody = styled.tbody`
  > tr {
    transition: all var(--std-transition);
  }
  > tr:hover {
    background-color: var(--color-text-light);
  }
`;

export default function StaffSession() {
  const dispatch = useDispatch();
  const reduxProps = useSelector(state => ({
    allBooksMap: state.authReducer.allBooksMap,
  }));
  const { allBooksMap } = reduxProps;

  useEffectOnce(() => {
    dispatch(getAllBooks());
  });

  const params = useParams();
  const session_id = params?.session_id;

  const [selectedAttendee, setSelectedAttendee] = useState(null);
  // might use this one day
  //const [mapData, setMapData] = useState({ attendeeMap: {} });

  const fetchSession = useQuery({
    enabled: !!session_id,
    refetchOnWindowFocus: false,
    queryKey: [
      {
        endpoint: 'onboarding-sessions',
        objectID: session_id,
      },
    ],
  });

  const fetchAttendees = useQuery({
    enabled: !!session_id,
    refetchOnWindowFocus: false,
    queryKey: [
      {
        endpoint: 'onboarding-attendees',
        urlParams: {
          session_id: session_id,
          sort: JSON.stringify(['signed_up_with_referral_code', 'full_name']),
        },
      },
    ],
    // might use this one day
    //onSuccess: data => {
    //  // despite having the shape of a paginated response, it's not actually paginated
    //  let newMaps = { ...mapData };
    //  if (data.results) {
    //    for (const atde of data.results) {
    //      newMaps.attendeeMap[atde.id] = atde;
    //    }
    //  }
    //  setMapData(newMaps);
    //},
  });
  //console.log(mapData);

  const queryClient = useQueryClient();
  const submitAttendee = useMutation({
    mutationKey: 'submit-attendee-verifiy',
    onSuccess: data => {
      if (data?.attendee) {
        setSelectedAttendee(data?.attendee);
      }
      queryClient.invalidateQueries({
        queryKey: [
          {
            endpoint: 'onboarding-attendees',
            urlParams: { session_id: session_id },
          },
        ],
      });
    },
  });

  const fetchExtras = useQuery({
    enabled: !!session_id && !!fetchAttendees?.data?.results,
    refetchInterval: 10 * 1000,
    queryKey: [
      {
        endpoint: 'onboarding-extras',
        urlParams: {
          session_id: session_id,
          attendee_ids: JSON.stringify(
            fetchAttendees?.data?.results.map(a => a.id)
          ),
        },
      },
    ],
  });

  const fetchProgresses = useQueries({
    queries: !fetchAttendees?.data?.results
      ? []
      : fetchAttendees?.data?.results.map((attendee, i) => ({
        // 10 seconds plus some random time just to vary it
        refetchInterval: () => {
          return 10_000 + Math.floor(Math.random() * 500);
        },
        queryKey: [
          {
            endpoint: 'onboarding-attendee-progresses',
            urlParams: { attendee_id: attendee.id, session_id: session_id },
          },
        ],
      })),
  });

  const progressData = useMemo(() => {
    return fetchProgresses?.reduce(
      (acc, curr) => {
        if (curr.data && curr.data?.length > 0) {
          acc.map[curr.data[0].attendee] = curr.data;
          for (const p of curr.data) {
            if (p?.current_step === 'FULLY_COMPLETE' && !p?.already_had_book) {
              acc.numFullyComplete++;
            }
            if (p?.current_step === 'PENDING_SCREENSHOT_APPROVAL') {
              acc.numWaitingVerification++;
              if (!acc.attendeesIDSWaitingVerificiation.includes(p?.attendee)) {
                acc.attendeesIDSWaitingVerificiation.push(p?.attendee);
              }
            }
            if (
              p?.current_step !== 'NOT_STARTED' &&
              !p?.already_had_book &&
              p?.num_times_clicked_affiliate_link > 0
            ) {
              acc.roughNumAccountsCreated++;
            }
            if (
              p?.current_step !== 'NOT_STARTED' &&
              !p?.already_had_book &&
              p?.num_times_clicked_affiliate_link > 0 &&
              p?.amount_bet > 0
            ) {
              acc.roughNumAccountsBet++;
              acc.roughValueOfBets += p.amount_bet;
            }
            if (
              p?.current_step !== 'NOT_STARTED' &&
              !p?.already_had_book &&
              p?.num_times_clicked_affiliate_link > 0 &&
              p?.amount_deposited > 0
            ) {
              acc.roughNumAccountsDeposit++;
              acc.roughValueOfDeposits += p.amount_deposited;
            }
          }
        }
        return acc;
      },
      {
        map: {},
        numWaitingVerification: 0,
        attendeesIDSWaitingVerificiation: [],
        numFullyComplete: 0,
        roughNumAccountsCreated: 0,
        roughNumAccountsBet: 0,
        roughValueOfBets: 0,
        roughNumAccountsDeposit: 0,
        roughValueOfDeposits: 0,
      }
    );
  }, [fetchProgresses]);
  const progressMap = progressData.map;

  const attendees = fetchAttendees?.data?.results;

  const groupedAttendees = useMemo(() => {
    const extras = fetchExtras?.data;
    const groups = {
      'Screenshots to Review': [],
      'Needs Help': [],
      'Initial Payment Sent': [],
      'No Payment Sent': [],
      Other: [],
    };
    if (attendees && extras) {
      for (const atde of attendees) {
        const needsHelp = progressMap[atde?.id]
          ? progressMap[atde.id].find(p => p.current_step === 'NEEDS_HELP')
          : false;
        if (progressData.attendeesIDSWaitingVerificiation.includes(atde.id)) {
          groups['Screenshots to Review'].push(atde);
        } else if (needsHelp) {
          groups['Needs Help'].push(atde);
        } else if (atde.graph_group_by_date === null) {
          groups['No Payment Sent'].push(atde);
        } else if (
          atde.graph_group_by_date ||
          atde?.stage === 'VERIFYING_SUBMISSIONS_PAID'
        ) {
          groups['Initial Payment Sent'].push(atde);
        } else {
          groups['Other'].push(atde);
        }
      }
    }
    return groups;
  }, [attendees, fetchExtras?.data, progressMap]);

  if (!session_id) {
    return <Redirect exact to="/sessions" />;
  }

  if (fetchSession.isLoading || fetchAttendees.isLoading) {
    return <ActivityIndicator size={3} style={{ marginTop: '25vh' }} />;
  }

  return (
    <Row style={{ width: '100%', alignItems: 'flex-start' }}>
      <Col
        style={{
          flex: 3,
          alignItems: 'flex-start',
          //padding: 'var(--space-xs)',
        }}
      >
        <Row
          style={{
            justifyContent: 'space-around',
            background: 'var(--color-fg)',
            padding: 'var(--space-sm) var(--space-xs)',
            width: '100%',
          }}
        >
          <span title="The approximate number of sportsbook accounts created">
            Approx. Signups: {progressData.roughNumAccountsCreated}
          </span>
          <span title="The approximate number of sportsbook account deposits and the sum of their dollar amounts">
            Approx. Deposits: {progressData.roughNumAccountsDeposit} (
            {formatMoney(progressData.roughValueOfDeposits, 'CAD')})
          </span>
          <span title="The approximate number of sportsbook account bets and the sum of their dollar amounts">
            Approx. Bets: {progressData.roughNumAccountsBet} (
            {formatMoney(progressData.roughValueOfBets, 'CAD')})
          </span>
          <span title="The number of sportsbooks awaiting screenshot approval">
            Waiting Screenshot Verification:{' '}
            {progressData.numWaitingVerification}
          </span>
          <span title='The number of sportsbook marked "Fully Complete"'>
            Fully Complete: {progressData.numFullyComplete}
          </span>
          <a
            href={fetchSession?.data?.onboarding_session?.google_meet_link}
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: 'var(--color-primary)' }}
          >
            Join Google Meet
          </a>
        </Row>
        {Object.keys(groupedAttendees).map(key => (
          <React.Fragment key={`staff-sess-group-${key}`}>
            <h6
              style={{
                margin: 'var(--space-md) var(--space-xs) var(--space-xxxs)',
              }}
            >
              <b>
                {key} ({groupedAttendees[key]?.length})
              </b>
            </h6>
            <Grid
              style={{
                flex: 3,
                width: '100%',
                padding: 'var(--space-xs)',
                gridTemplateColumns: selectedAttendee
                  ? '1fr 1fr 1fr'
                  : '1fr 1fr 1fr 1fr',
              }}
            >
              {groupedAttendees[key].length === 0 && (
                <p>No attendees {key.toLowerCase()}</p>
              )}
              {groupedAttendees[key].map(attendee => (
                <AttendeeBox
                  key={`session-attendee-${attendee.id}`}
                  attendee={attendee}
                  progresses={progressMap[attendee.id]}
                  selected={selectedAttendee?.id === attendee.id}
                  extra={
                    fetchExtras?.data ? fetchExtras?.data[attendee.id] : null
                  }
                  onPress={
                    selectedAttendee?.id === attendee.id
                      ? () => setSelectedAttendee(null)
                      : () => setSelectedAttendee(attendee)
                  }
                />
              ))}
            </Grid>
          </React.Fragment>
        ))}
      </Col>

      <AttendeePanel
        key={`staff-session-attendee-panel-${selectedAttendee?.id}`}
        session_id={session_id}
        selectedAttendee={selectedAttendee}
        setSelectedAttendee={setSelectedAttendee}
        progresses={selectedAttendee ? progressMap[selectedAttendee.id] : null}
        submitAttendee={submitAttendee}
      />
    </Row>
  );
}

function AttendeeBox(props) {
  const reduxProps = useSelector(state => ({
    allBooksMap: state.authReducer.allBooksMap,
  }));
  const { allBooksMap } = reduxProps;

  const { attendee, progresses, selected, extra, onPress } = props;

  let currentProgress = null;
  let needsHelpWith = null;
  let numDone = 0;
  if (progresses && progresses?.length > 0) {
    for (const p of progresses) {
      if (p?.book === extra?.current_book && !currentProgress) {
        currentProgress = p;
      }
      if (p?.current_step === 'NEEDS_HELP' && !needsHelpWith) {
        needsHelpWith = p;
      }
      if (p?.current_step === 'FULLY_COMPLETE' && !p?.already_had_book) {
        numDone++;
      }
    }
  }

  return (
    <AuthButton
      colorTheme="text"
      onPress={onPress}
      overrideChildren
      containerStyle={{ borderRadius: 'var(--std-border-radius)', margin: 0 }}
      btnStyle={{
        border: `1px solid ${attendee.stage === 'ONBOARDING_DECLINED'
            ? 'var(--color-danger)'
            : selected
              ? 'var(--color-primary)'
              : 'var(--color-text-light)'
          }`,
        borderRadius: 'var(--std-border-radius)',
      }}
      title={
        needsHelpWith
          ? `Needs help with ${allBooksMap[needsHelpWith?.book]?.name}`
          : currentProgress
            ? `${attendee?.full_name} currently working on ${allBooksMap[currentProgress?.book]?.name
            }`
            : `${attendee.full_name}`
      }
    >
      <Col>
        <Row
          style={{
            width: '100%',
            alignItems: 'center',
            justifyContent: 'flex-start',
            gap: 'var(--space-xs)',
          }}
        >
          <div
            style={{
              width: '20px',
              height: '20px',
              flex: '0 0 20px',
              borderRadius: '20px',
              backgroundColor: needsHelpWith
                ? 'var(--color-danger)'
                : extra?.alive
                  ? 'var(--color-success)'
                  : 'var(--color-text-light)',
            }}
          />
          <h5 style={{ flex: 1, margin: 0, textAlign: 'left' }}>
            {attendee?.full_name}
          </h5>
          <small>
            Done/Target: {numDone}/{attendee.target_num_signups || '-'}
          </small>
        </Row>
        <span
          style={{ alignSelf: 'flex-start', marginBottom: 'var(--space-sm)' }}
        >
          <b>
            {attendee.stage === 'ONBOARDING_DECLINED' ? (
              'REJECTED'
            ) : (
              <>
                {attendee?.payment_email || attendee?.email || <i>No email</i>}
              </>
            )}
          </b>
        </span>
        {currentProgress && (
          <Row
            style={{
              width: '100%',
              gap: 'var(--space-xs)',
              alignItems: 'center',
              margin: 'var(--space-xxs) 0',
            }}
          >
            <img
              style={{ borderRadius: '4px' }}
              src={allBooksMap[currentProgress?.book]?.logo}
              width={22}
              alt={allBooksMap[currentProgress?.book]?.name}
            />
            <span style={{ flex: 1, textAlign: 'left' }}>
              {allBooksMap[extra?.current_book]?.name}
            </span>
            <span>{currentProgress?.current_step}</span>
          </Row>
        )}
        <span style={{ alignSelf: 'flex-start' }}>
          {attendee?.signed_up_with_referral_code?.code || 'no referral code'}
        </span>
      </Col>
    </AuthButton>
  );
}

function AttendeePanel(props) {
  const { session_id, selectedAttendee, setSelectedAttendee, progresses } =
    props;

  const [copyState, copyToClipboard] = useCopyToClipboard();
  const [copySuccess, setCopySuccess] = useState(false);
  const [tab, setTab] = useState('Progress');

  const queryClient = useQueryClient();

  useUpdateEffect(() => {
    if (copyState.value) {
      setCopySuccess(true);
    }
    const to = setTimeout(() => {
      setCopySuccess(false);
    }, 1333);
    return () => clearTimeout(to);
  }, [copyState]);

  if (!selectedAttendee) return null;

  return (
    <Col
      style={{
        flex: 1.5,
        borderLeft: '1px solid var(--color-text-light)',
        justifyContent: 'flex-start',
        padding: '0 var(--space-sm)',
        paddingBottom: 'var(--space-xxxl)',
        height: '100vh',
        overflowY: 'auto',
        flexWrap: 'nowrap',
      }}
    >
      <Row
        style={{
          flex: 0,
          width: '100%',
          alignItems: 'center',
          position: 'sticky',
          top: 0,
          background: 'var(--color-bg)',
          zIndex: 10,
        }}
      >
        <h2 style={{ flex: 1, margin: 0 }}>{selectedAttendee?.full_name}</h2>
        <IconButton
          colorTheme="text"
          iconName={IoClose}
          onPress={() => setSelectedAttendee(null)}
        />
      </Row>
      <Row
        style={{
          flex: 0,
          width: '100%',
          alignItems: 'center',
          position: 'sticky',
          top: 48,
          background: 'var(--color-bg)',
          zIndex: 9,
        }}
      >
        <span
          style={{
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            flex: 1,
          }}
        >
          {window.location.origin}/session/{session_id}/{selectedAttendee.id}
        </span>
        <IconButton
          containerStyle={{ flex: 0 }}
          colorTheme={copySuccess ? 'success' : 'text'}
          iconName={copySuccess ? IoCheckmark : IoCopyOutline}
          onPress={() =>
            copyToClipboard(
              `${window.location.origin}/session/${session_id}/${selectedAttendee.id}`
            )
          }
        />
      </Row>

      <StageDropdown
        hideLabel
        attendee={selectedAttendee}
        onChange={opt =>
          props.submitAttendee.mutate({
            endpoint: `onboarding-attendees/${selectedAttendee.id}`,
            body: {
              stage: opt.value,
              session_id: session_id,
            },
          })
        }
      />
      {selectedAttendee.stage === 'ONBOARDING_DECLINED' && (
        <span
          style={{
            marginTop: 'var(--space-xs)',
            textAlign: 'left',
            width: '100%',
            border: '1px solid var(--color-danger)',
            borderRadius: 'var(--std-border-radius)',
            padding: 'var(--space-sm) var(--space-xs)',
          }}
        >
          REJECTED: {selectedAttendee.rejection_reasons?.join(', ')}
        </span>
      )}

      <DisplayToggle
        style={{
          width: '100%',
          marginTop: 'var(--space-sm)',
          position: 'sticky',
          top: 98,
        }}
        containerStyle={{ flex: '1 0 165px' }}
        options={['Progress', 'Payments', 'Details', 'Edit', 'Sessions']}
        active={tab}
        onPress={opt => setTab(opt)}
      />

      {tab === 'Progress' && (
        <ProgressListDisplay
          progresses={progresses}
          attendee_id={selectedAttendee.id}
          session_id={session_id}
          onSuccessChangeCallback={() => {
            queryClient.invalidateQueries({
              queryKey: [
                {
                  endpoint: `onboarding-payouts`,
                  urlParams: {
                    attendee_id: selectedAttendee.id,
                    session_id: session_id,
                  },
                },
              ],
            });
            queryClient.invalidateQueries({
              queryKey: [
                {
                  endpoint: 'onboarding-attendee-progresses',
                  urlParams: {
                    attendee_id: selectedAttendee.id,
                    session_id: session_id,
                  },
                },
              ],
            });
          }}
        />
      )}
      {tab === 'Payments' && (
        <>
          <OnboardingPayouts
            style={{ marginTop: 'var(--space-sm)' }}
            attendee={selectedAttendee}
            session_id={session_id}
          />
          <PaymentList
            attendee_id={selectedAttendee.id}
            session_id={session_id}
            onSuccessSubmitCallback={() => {
              queryClient.invalidateQueries({
                queryKey: [
                  {
                    endpoint: 'onboarding-attendees',
                    urlParams: {
                      session_id: session_id,
                      sort: JSON.stringify([
                        'signed_up_with_referral_code',
                        'full_name',
                      ]),
                    },
                  },
                ],
              });
            }}
          />
        </>
      )}
      {tab === 'Details' && (
        <DetailsTab attendee={selectedAttendee} session_id={session_id} />
      )}
      {tab === 'Edit' && (
        <EditTab
          attendee={selectedAttendee}
          session_id={session_id}
          submitAttendee={props.submitAttendee}
        />
      )}
      {tab === 'Sessions' && <AppointmentsTab attendee={selectedAttendee} />}
    </Col>
  );
}

function DetailsTab(props) {
  const { attendee } = props;
  return (
    <>
      <table style={{ width: '100%' }} cellSpacing={0}>
        <Tbody>
          <tr>
            <td>Full Name</td>
            <td style={{ textAlign: 'right' }}>{attendee.full_name}</td>
          </tr>
          <tr>
            <td>Attendee ID</td>
            <td style={{ textAlign: 'right' }}>{attendee.id}</td>
          </tr>
          <tr>
            <td>Referral Code</td>
            <td style={{ textAlign: 'right' }}>
              {attendee?.signed_up_with_referral_code?.code} (
              {attendee?.signed_up_with_referral_code?.group?.name})
            </td>
          </tr>
          <tr>
            <td>Region</td>
            <td style={{ textAlign: 'right' }}>{attendee.region}</td>
          </tr>
          <tr>
            <td>Email</td>
            <td style={{ textAlign: 'right' }}>{attendee.email}</td>
          </tr>
          <tr>
            <td>Payment Email</td>
            <td style={{ textAlign: 'right' }}>{attendee.payment_email}</td>
          </tr>
          <tr>
            <td>Phone</td>
            <td style={{ textAlign: 'right' }}>
              {attendee.phone_number
                ? attendee.phone_number?.length >= 10
                  ? attendee.phone_number.replace(
                    /(\d{3})(\d{3})(\d{4})/,
                    '$1-$2-$3'
                  )
                  : attendee.phone_number
                : '-'}
            </td>
          </tr>
          <tr>
            <td>DOB</td>
            <td style={{ textAlign: 'right' }}>
              {humanDate(attendee.date_of_birth)}
            </td>
          </tr>
          <tr>
            <td>Existing Books</td>
            <td style={{ textAlign: 'right' }}>
              {attendee.existing_books.join(', ') || '-'}
            </td>
          </tr>
          <tr>
            <td>Target Signups</td>
            <td style={{ textAlign: 'right' }}>
              {attendee.target_num_signups || '-'}
            </td>
          </tr>
          <tr>
            <td>Is Verified</td>
            <td style={{ textAlign: 'right' }}>
              {attendee.is_verified ? 'Yes' : 'No'}
            </td>
          </tr>
          <tr>
            <td>Is Ambassador</td>
            <td style={{ textAlign: 'right' }}>
              {attendee.is_ambassador ? 'Yes' : 'No'}
            </td>
          </tr>
          <tr>
            <td>Is Influencer</td>
            <td style={{ textAlign: 'right' }}>
              {attendee.is_influencer ? 'Yes' : 'No'}
            </td>
          </tr>
          <tr>
            <td>betstamp User</td>
            <td style={{ textAlign: 'right' }}>
              {attendee.betstamp_user || '-'}
            </td>
          </tr>
          <tr>
            <td>Rejection Reasons</td>
            <td style={{ textAlign: 'right' }}>
              {attendee.rejection_reasons?.join(', ') || '-'}
            </td>
          </tr>
          <tr>
            <td>Notes</td>
            <td style={{ textAlign: 'right' }}>{attendee.notes || '-'}</td>
          </tr>
          <tr>
            <td colSpan={2}>
              {attendee?.photo_id ? (
                <img
                  src={attendee?.photo_id}
                  alt={`${attendee?.full_name}'s government ID`}
                  style={{ width: '100%', height: 'auto' }}
                />
              ) : (
                <p>No photo ID</p>
              )}
            </td>
          </tr>
        </Tbody>
      </table>
    </>
  );
}

function EditTab(props) {
  const { attendee, submitAttendee } = props;
  const [formData, setFormData] = useState({});
  const checkRefCodeExists = useQuery({
    refetchOnWindowFocus: false,
    enabled: !!formData.signed_up_with_referral_code,
    queryKey: [
      {
        endpoint: 'referral_codes/exists',
        urlParams: { code: formData.signed_up_with_referral_code?.code },
      },
    ],
  });
  return (
    <>
      <form
        key={attendee?.id || 'new-attendee-form'}
        style={{ width: '100%' }}
        onSubmit={ev => {
          ev.preventDefault();
          //const fd = new FormData(ev.target);
          //console.log(fd);
          submitAttendee.mutate({
            endpoint: `onboarding-attendees/${attendee.id}`,
            body: formData,
          });
        }}
      >
        <AuthTextInput
          label="Full Name"
          name="full_name"
          defaultValue={attendee.full_name}
          onChangeText={text => setFormData({ ...formData, full_name: text })}
        />
        <AuthTextInput
          label="Email"
          name="email"
          defaultValue={attendee.email}
          onChangeText={text => setFormData({ ...formData, email: text })}
        />
        <AuthTextInput
          label="Payment Email"
          name="payment_email"
          defaultValue={attendee.payment_email}
          onChangeText={text =>
            setFormData({ ...formData, payment_email: text })
          }
        />
        <Select
          label="Region"
          name="region"
          options={STATE_OPTIONS.map(s => ({ label: s, value: s }))}
          onChange={opt => setFormData({ ...formData, region: opt.value })}
          defaultValue={
            attendee.region
              ? { label: attendee.region, value: attendee.region }
              : undefined
          }
        />

        <AuthTextInput
          label="Target Number of Signups"
          name="target_num_signups"
          defaultValue={attendee.target_num_signups}
          onChangeText={text =>
            setFormData({ ...formData, target_num_signups: text })
          }
        />
        <AuthTextInput
          label="Signed up with referral code"
          name="signed_up_with_referral_code"
          defaultValue={attendee.signed_up_with_referral_code?.code}
          errorText={
            !checkRefCodeExists?.isFetched || checkRefCodeExists?.data?.exists
              ? null
              : 'Referral code does not exist'
          }
          onChangeText={t =>
            setFormData({
              ...formData,
              signed_up_with_referral_code: { code: t.toUpperCase() },
            })
          }
        />

        <AuthTextInput
          label="Notes"
          name="notes"
          multiline
          defaultValue={attendee.notes}
          onChangeText={text => setFormData({ ...formData, notes: text })}
        />
        <AuthButton
          type="submit"
          isLoading={submitAttendee.isLoading}
          disabled={
            checkRefCodeExists?.isFetched && !checkRefCodeExists?.data?.exists
          }
          containerStyle={{ marginTop: 'var(--space-xl)' }}
        >
          Edit Attendee
        </AuthButton>
        {submitAttendee.isError ? (
          <small style={{ textAlign: 'center', color: 'var(--color-danger)' }}>
            {submitAttendee.error.message}
          </small>
        ) : (
          <br />
        )}
      </form>
    </>
  );
}

function AppointmentsTab({ attendee }) {
  const fetchAppointments = useInfiniteQuery({
    staleTime: 1000 * 60 * 2,
    queryKey: [
      {
        endpoint: 'onboarding-appointments',
        urlParams: { attendee_id: attendee.id },
      },
    ],
    getNextPageParam: (lastPage, allPages) => lastPage.next,
    getPreviousPageParam: (firstPage, allPages) => firstPage.prev,
  });

  const [expanded, setExpanded] = useState({});

  if (fetchAppointments.isLoading) {
    return (
      <ActivityIndicator size={3} style={{ marginTop: 'var(--space-xl)' }} />
    );
  }

  if (fetchAppointments.isError) {
    return <p>{fetchAppointments.error?.message}</p>;
  }

  return (
    <>
      {fetchAppointments.data.pages.map((page, i) => (
        <React.Fragment key={`appt-onb-page-${i}`}>
          {page.results.map(appt => (
            <React.Fragment key={`appt-onb-page-appt-${appt.id}`}>
              <Row
                style={{
                  width: '100%',
                  flex: 0,
                  alignItems: 'center',
                  border: expanded[appt.id]
                    ? '1px solid var(--color-text-light)'
                    : '1px solid transparent',
                  borderTopRightRadius: 'var(--std-border-radius)',
                  borderTopLeftRadius: 'var(--std-border-radius)',
                  padding: 'var(--space-xxs)',
                  gap: 'var(--space-xxs)',
                }}
              >
                <Col style={{ flex: 2, alignItems: 'flex-start' }}>
                  {!appt.onboarding_session ? (
                    <>Session cancelled</>
                  ) : (
                    <>
                      <b>{appt?.onboarding_session?.id}</b>
                      <small>
                        {humanDatetime(appt?.onboarding_session?.date)}
                      </small>
                    </>
                  )}
                </Col>
                <span style={{ flex: 1, textAlign: 'right' }}>
                  {appt.rejected ? (
                    'Rejected'
                  ) : (
                    <>
                      {new Date(appt?.onboarding_session?.date) > new Date() ? (
                        <>
                          {appt?.gcal_respond_status
                            ? GCAL_STATUS_MEANING[appt?.gcal_respond_status]
                            : ''}
                        </>
                      ) : (
                        <>{appt.attended ? 'Attended' : 'Did not attend'}</>
                      )}
                    </>
                  )}
                </span>
                <IconButton
                  colorTheme="text"
                  iconName={expanded[appt.id] ? IoChevronUp : IoChevronDown}
                  onPress={() =>
                    setExpanded({ ...expanded, [appt.id]: !expanded[appt.id] })
                  }
                />
              </Row>
              {expanded[appt.id] && (
                <table
                  style={{
                    width: '100%',
                    border: '1px solid var(--color-text-light)',
                    borderTop: 'none',
                    borderBottomRightRadius: 'var(--std-border-radius)',
                    borderBottomLeftRadius: 'var(--std-border-radius)',
                    padding: 'var(--space-xs) var(--space-xxs)',
                    marginBottom: 'var(--space-xs)',
                  }}
                >
                  <tbody>
                    <tr>
                      <td>Invite Response</td>
                      <td style={{ textAlign: 'right' }}>
                        {appt?.gcal_respond_status
                          ? GCAL_STATUS_MEANING[appt?.gcal_respond_status]
                          : '-'}
                      </td>
                    </tr>
                    <tr>
                      <td>Attended</td>
                      <td style={{ textAlign: 'right' }}>
                        {appt?.attended.toString()}
                      </td>
                    </tr>
                    <tr>
                      <td>Session Link</td>
                      <td style={{ textAlign: 'right' }}>
                        <small>
                          {appt.onboarding_session && (
                            <>
                              {window.location.origin}/session/
                              {appt?.onboarding_session?.id}/{attendee.id}
                            </>
                          )}
                        </small>
                      </td>
                    </tr>
                    <tr>
                      <td>Rejected</td>
                      <td style={{ textAlign: 'right' }}>
                        {appt?.rejected.toString()}
                      </td>
                    </tr>
                  </tbody>
                </table>
              )}
            </React.Fragment>
          ))}
        </React.Fragment>
      ))}
    </>
  );
}
const GCAL_STATUS_MEANING = {
  needsAction: 'Awaiting Response',
  declined: 'Declined Invite',
  tentative: 'Tentative',
  accepted: 'Accepted Invite',
};
