import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';

// actual screens
import MyPicksMobile from 'pages/MyPicks/MyPicksMobile';
import MyPicksDesktop2 from './MyPicksDesktop2';

// components
import { Col, PhoneLayout } from 'components/generic/Layout';

// actions
import { getPicksStats, getWagers } from 'actions';

export default function MyPicks() {
  const dispatch = useDispatch();
  const reduxProps = useSelector((state) => ({
    hist_date_after: state.picksReducer.hist_date_after,
    hist_date_before: state.picksReducer.hist_date_before,
    allBooks: state.authReducer.allBooks,
  }));
  const { hist_date_after, hist_date_before } = reduxProps;

  const _initialLoad = () => {
    const today = moment()
      .subtract(4, 'hours')
      .hours(3)
      .minutes(59)
      .seconds(59)
      .utc();
    // const tomorrow = moment.utc(today).add(1, 'days');

    const date_after = today;
    // const date_before = tomorrow;

    dispatch(
      getWagers({
        screen: 'todaysAction',
        date_after: date_after,
        date_before: null,
      })
    );

    dispatch(
      getWagers({
        screen: 'historicals',
        date_after: hist_date_after,
        date_before: hist_date_before,
        is_graded: true,
      })
    );

    dispatch(getPicksStats(hist_date_after, hist_date_before));
  };

  useEffect(() => {
    _initialLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Col
        desktop
        style={{ height: '100vh', flexWrap: 'nowrap', overflow: 'hidden' }}
      >
        <MyPicksDesktop2 initialLoad={_initialLoad} />
      </Col>
      <PhoneLayout>
        <MyPicksMobile />
      </PhoneLayout>
    </>
  );
}
