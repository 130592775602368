import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  IoCheckmarkCircleOutline,
  IoAlertCircle,
  IoTrashOutline,
  IoOpenOutline,
  IoOpen,
} from 'react-icons/io5';
import styled from 'styled-components';

// utils
import {
  betLine,
  roundOdds,
  roundNumber,
  toAmericanOdds,
  periodChoices,
  competitionChoices,
  AUTH_BUTTON_DEFAULT_MAX_WIDTH_STYLE,
} from 'utils';

// components
import { Col, Row } from 'components/generic/Layout';
import { AuthButton, IconButton } from 'components/AuthButton';
import AddEditTags from 'components/AddEditTags';
import AddEditDescription from 'components/AddEditDescription';
import BooksDropdown from 'components/BooksDropdown';
import OddsInput from 'components/OddsInput';
import SpreadTotalInput from './SpreadTotalInput';
import CurrencyInput from 'components/generic/CurrencyInput';
import BetLineLogos from 'components/BetLineLogos';
import Switch from 'components/generic/Switch';
import Select from 'components/generic/Select';

//actions
import {
  editBetslipBet,
  removeOneBet,
  betslipChangeBookBet,
  placeBet,
} from 'actions';
import { useUpdateEffect } from 'react-use';

const Wrapper = styled.div`
  width: 100%;
  margin-top: var(--space-lg);
  margin-bottom: var(--space-md);
  padding: var(--space-md) var(--space-xs);
  padding-top: var(--space-xs);
  background-color: var(--color-fg);
  border-radius: var(--std-border-radius);
  box-shadow: 0 2px 4px 0 var(--color-shadow);
  box-sizing: border-box;
`;

const InputsWrapper = styled.div`
  flex: 1 0 350px;
  height: 100%;
  display: flex;
  flex-flow: column wrap;
  justify-content: flex-start;
  align-items: center;
  & > div {
    flex: 0;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    max-width: 475px;
    margin: var(--space-xs) 0;
  }
  & > div > b {
    flex: 0.75;
    text-align: right;
    padding: 0 var(--space-sm);
  }
`;

export default function EditBetslipBet(props) {
  const { i, bet } = props;

  const dispatch = useDispatch();
  const reduxProps = useSelector(state => ({
    user: state.authReducer.user,
    bets: state.betslipReducer.bets,
    mode: state.betslipReducer.mode,
    riskOrBaseForAllAmt: state.betslipReducer.riskOrBaseForAllAmt,
    totalRisk: state.betslipReducer.totalRisk,
    totalWin: state.betslipReducer.totalWin,
    parlayOdds: state.betslipReducer.parlayOdds,
    isTrackingBets: state.betslipReducer.isTrackingBets,
    isLoadingBetSlip: state.betslipReducer.isLoadingBetSlip,
    isLoadingBetSlipSuccess: state.betslipReducer.isLoadingBetSlipSuccess,
    isLoadingBetSlipFailure: state.betslipReducer.isLoadingBetSlipFailure,
    loadedBetslipUrl: state.betslipReducer.loadedBetslipUrl,
  }));

  const {
    user,
    mode,
    isTrackingBets,
    isLoadingBetslip,
    isLoadingBetSlipSuccess,
    isLoadingBetSlipFailure,
    loadedBetslipUrl,
  } = reduxProps;
  const { odds_preference } = user;
  useUpdateEffect(() => {
    if (isLoadingBetSlipSuccess) {
      window.open(loadedBetslipUrl, '_blank');
    }
  }, [isLoadingBetSlipSuccess]);

  const compChoices = competitionChoices(user.competitions);
  const mergedBet = { ...bet, ...bet.changes };

  const choices = periodChoices({ ...mergedBet, bet_type: 'BET' });

  const labels = {
    FT: 'Full Game',
    '1H': '1st Half',
    '2H': '2nd Half',
    '1S': '1st Set',
    '1P': '1st Period',
    F5: '1st 5 Innings',
  };
  let step = 0.5;
  if (mergedBet?.game?.sport === 'Soccer') step = 0.25;

  return (
    <Wrapper
      id={`edit-betslip-anchor-${mergedBet.localID}`}
      style={{
        opacity: isTrackingBets || mergedBet.changingBook ? 0.5 : 1,
        pointerEvents:
          isTrackingBets || mergedBet.changingBook ? 'none' : 'auto',
      }}
    >
      <Row style={{ justifyContent: 'space-between' }}>
        <BetLineLogos
          large
          bet={mergedBet}
          odds_preference={odds_preference}
          showVerifiedStamp
          showPublicBadge={mode === 'singles'}
          competition={
            user.competitions
              ? user.competitions.find(c => c.id === mergedBet.competition)
              : null
          }
        />

        {mergedBet.tracked && (
          <>
            <br />
            <Row
              style={{
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <IoCheckmarkCircleOutline
                size={38}
                color="var(--color-success)"
              />
              <h6
                style={{
                  margin: 0,
                  color: 'var(--color-success)',
                  fontWeight: 'bold',
                }}
              >
                Bet Tracked
              </h6>
            </Row>
          </>
        )}

        {bet.is_verified && !mergedBet.is_verified && !mergedBet.tracked && (
          <AuthButton
            containerStyle={AUTH_BUTTON_DEFAULT_MAX_WIDTH_STYLE}
            onPress={() =>
              dispatch(editBetslipBet(i, { reset: true }, 'reset'))
            }
          >
            Reset to Verified
          </AuthButton>
        )}
        <IconButton
          iconName={IoTrashOutline}
          btnTheme="slim"
          colorTheme="danger"
          containerStyle={{
            minWidth: '4rem',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          onPress={() => dispatch(removeOneBet(mergedBet))}
        />
      </Row>

      {!mergedBet.tracked && (
        <InputsWrapper>
          {mode === 'singles' && (
            <>
              <div>
                <BooksDropdown
                  value={{
                    value: mergedBet.book.id,
                    label: mergedBet.book.name.replace(
                      'Live_Internal_betstamp_963',
                      'Consensus'
                    ),
                  }}
                  isLoading={mergedBet.changingBook}
                  onChange={selectedOption => {
                    dispatch(
                      betslipChangeBookBet(
                        {
                          id: selectedOption.value,
                          name: selectedOption.label,
                        },
                        props.i
                      )
                    );
                  }}
                />
              </div>

              <Row style={{ flexWrap: 'nowrap' }}>
                <Col style={{ alignItems: 'stretch', maxWidth: '50%' }}>
                  <b style={{ alignSelf: 'center' }}>Risk</b>
                  <CurrencyInput
                    containerStyle={{
                      marginRight: 'var(--space-sm)',
                    }}
                    inputRowStyle={{
                      backgroundColor: 'var(--color-bg)',
                    }}
                    inputStyle={{
                      textAlign: 'center',
                    }}
                    defaultValue={mergedBet.risk_amount}
                    commaSeparate={true}
                    onChange={dollars => {
                      dispatch(
                        editBetslipBet(
                          i,
                          { risk_amount: parseFloat(dollars) },
                          'risk_amount',
                          null
                        )
                      );
                      // setLastChanged('risk_amount');
                    }}
                  />
                </Col>
                <Col style={{ alignItems: 'stretch', maxWidth: '50%' }}>
                  <b style={{ alignSelf: 'center' }}>To Win</b>
                  <CurrencyInput
                    containerStyle={{
                      marginLeft: 'var(--space-sm)',
                    }}
                    inputRowStyle={{
                      backgroundColor: 'var(--color-bg)',
                    }}
                    inputStyle={{
                      textAlign: 'center',
                    }}
                    defaultValue={mergedBet.result}
                    commaSeparate={true}
                    onChange={dollars => {
                      dispatch(
                        editBetslipBet(
                          i,
                          { result: parseFloat(dollars) },
                          'result',
                          null
                        )
                      );
                      // setLastChanged('result');
                    }}
                  />
                </Col>
              </Row>
            </>
          )}
          <Row style={{ flexWrap: 'nowrap' }}>
            <b>Odds</b>
            <div style={{ flex: 2 }}>
              <OddsInput
                defaultValue={mergedBet.odds}
                handleChange={(odds, whatChanged, isValid) => {
                  if (!isValid) return;
                  if (odds >= 2 || user.risk_or_base === 'RISK') {
                    dispatch(
                      editBetslipBet(i, { odds: odds }, 'odds', 'result')
                    );
                  } else {
                    dispatch(
                      editBetslipBet(i, { odds: odds }, 'odds', 'risk_amount')
                    );
                  }
                }}
              />
            </div>
          </Row>
          {mergedBet.number !== null && mergedBet.number !== undefined && (
            <div>
              <b>
                {mergedBet.type_name === 'Player Prop' ||
                mergedBet.type_name === 'Game Prop'
                  ? mergedBet.prop_type
                  : mergedBet.type_name}
              </b>
              <div style={{ flex: 2 }}>
                <SpreadTotalInput
                  defaultValue={mergedBet.number}
                  handleChange={value =>
                    dispatch(editBetslipBet(i, { number: value }, 'number'))
                  }
                  step={step}
                />
              </div>
            </div>
          )}
          {!mergedBet?.is_future &&
            mergedBet.type_name !== 'Game Prop' &&
            mergedBet.type_name !== 'Player Prop' &&
            mergedBet.game.sport !== 'MMA' && (
              <div>
                <b>Period</b>
                <div style={{ flex: 2 }}>
                  <Select
                    defaultValue={{
                      value: mergedBet.period,
                      label: labels[mergedBet.period],
                    }}
                    options={choices}
                    onChange={opt =>
                      dispatch(
                        editBetslipBet(i, { period: opt.value }, 'period')
                      )
                    }
                  />
                </div>
              </div>
            )}
          {mode === 'parlay' && (
            <Row
              style={{
                alignItems: 'center',
                margin: 'auto',
                maxWidth: '600px',
                marginTop: 'var(--space-xs)',
              }}
            >
              <b style={{ flex: 1 }}>Live Bet</b>
              <div style={{ flex: 2 }}>
                <Switch
                  defaultChecked={mergedBet?.is_live || false}
                  onChange={input =>
                    dispatch(
                      editBetslipBet(
                        i,
                        { is_live: input.target.checked },
                        'is_live'
                      )
                    )
                  }
                />
              </div>
            </Row>
          )}
          {mode == 'singles' &&
            mergedBet.book.load_betslip &&
            !mergedBet.game?.is_live &&
            !mergedBet.game?.is_complete &&
            !mergedBet.season?.is_complete &&
            mergedBet.book.states.includes(user.state) &&
            !mergedBet.book.aff_exclude_states.includes(user.state) && (
              <AuthButton
                rightIcon={IoOpenOutline}
                isLoading={isLoadingBetslip}
                onPress={() => {
                  const betslip_info = {
                    game: mergedBet['game'],
                    season: mergedBet['season'],
                    risk_amount: mergedBet['risk_amount'],
                    prop_type: mergedBet['prop_type'],
                    period: mergedBet['period'],
                    type_name: mergedBet['type_name'],
                    side: mergedBet['side'],
                    number: mergedBet['number'],
                    odds: mergedBet['odds'],
                    book_name: mergedBet['book']['name'],
                    generic_link: mergedBet['book']['generic_link'],
                    mode: mode,
                    is_future: mergedBet['is_future'],
                    player: mergedBet['player'],
                  };
                  dispatch(placeBet(betslip_info));
                }}
              >
                Place Bet at {mergedBet.book.name}
              </AuthButton>
            )}
          {mode === 'singles' && (
            <>
              <AddEditTags
                // key={`betslipeditbet-${mergedBet.localID}-tags-${mergedBet.tags.length}`}
                key={`betslipeditbet-${mergedBet.localID}-tags`}
                existingTags={mergedBet.tags}
                onTagsChange={tags =>
                  dispatch(editBetslipBet(i, { tags: tags }, 'tags'))
                }
              />
              <Row style={{ justifyContent: 'center' }}>
                <Col
                  style={{ alignItems: 'flex-start', justifyContent: 'center' }}
                >
                  Make this wager public
                </Col>
                <Col>
                  <Switch
                    defaultChecked={mergedBet.is_public}
                    onChange={input =>
                      dispatch(
                        editBetslipBet(
                          i,
                          { is_public: input.target.checked },
                          'is_public'
                        )
                      )
                    }
                  />
                </Col>
              </Row>
              <ExpandDiv>
                <AddEditDescription
                  defaultDescription={
                    mergedBet.breakdown ? mergedBet.breakdown : ''
                  }
                  onDescChange={d =>
                    dispatch(editBetslipBet(i, { breakdown: d }, 'breakdown'))
                  }
                />
                <Row style={{ width: '100%', margin: 'var(--space-xxs) 0' }}>
                  <Col
                    style={{
                      alignItems: 'flex-start',
                      justifyContent: 'center',
                    }}
                  >
                    Track as Live Wager
                  </Col>
                  <Col>
                    <Switch
                      defaultChecked={mergedBet?.is_live || false}
                      onChange={input =>
                        dispatch(
                          editBetslipBet(
                            i,
                            { is_live: input.target.checked },
                            'is_live'
                          )
                        )
                      }
                    />
                  </Col>
                </Row>
                <Row
                  style={{
                    width: '100%',
                    margin: 'var(--space-xxs) 0',
                  }}
                >
                  <Col
                    style={{
                      alignItems: 'flex-start',
                      justifyContent: 'center',
                    }}
                  >
                    Track as Free Bet
                  </Col>
                  <Col>
                    <Switch
                      defaultChecked={mergedBet?.is_free_bet || false}
                      onChange={input =>
                        dispatch(
                          editBetslipBet(
                            i,
                            { is_free_bet: input.target.checked },
                            'is_free_bet'
                          )
                        )
                      }
                    />
                  </Col>
                </Row>
              </ExpandDiv>
              {user.competitions && user.competitions.length > 0 && (
                <div>
                  <b>Competition</b>
                  <div style={{ flex: 2 }}>
                    <Select
                      defaultValue={{
                        value: mergedBet.competition,
                        label:
                          mergedBet.competition !== '-1' &&
                          mergedBet.competition
                            ? compChoices[0].label
                            : '',
                      }}
                      options={compChoices}
                      onChange={opt => {
                        dispatch(
                          editBetslipBet(
                            i,
                            { competition: opt.value },
                            'competition'
                          )
                        );
                        //setCompetition(user.competitions[opt.value]);
                      }}
                    />
                  </div>
                </div>
              )}
            </>
          )}
        </InputsWrapper>
      )}

      {mergedBet.requires_action && mergedBet.latest_changes && (
        <>
          <br />
          <Row style={{ width: '100%' }}>
            <Col style={{ flex: 0 }}>
              <IoAlertCircle
                size={28}
                color="var(--color-danger)"
                style={{ padding: '0 var(--space-xs)' }}
              />
            </Col>
            <Col style={{ alignItems: 'flex-start' }}>
              <p
                style={{
                  margin: 0,
                  color: 'var(--color-danger)',
                }}
              >
                {mergedBet.latest_changes && 'The odds have changed!'}
                {mergedBet.error && 'Error tracking bet'}
              </p>

              {mergedBet.latest_changes &&
                mergedBet.latest_changes.odds &&
                mergedBet.latest_changes.odds !== mergedBet.odds && (
                  <span style={{ lineHeight: 1 }}>
                    New odds:{' '}
                    {odds_preference
                      ? roundOdds(mergedBet.latest_changes.odds)
                      : toAmericanOdds(mergedBet.latest_changes.odds)}
                  </span>
                )}
              {mergedBet.latest_changes &&
                mergedBet.latest_changes.number &&
                mergedBet.latest_changes.number !== mergedBet.number && (
                  <span style={{ lineHeight: 1 }}>
                    New number:{' '}
                    {mergedBet.latest_changes.number > 0 &&
                    mergedBet.type_name === 'Spread'
                      ? `+${roundNumber(mergedBet.latest_changes.number)}`
                      : roundNumber(mergedBet.latest_changes.number)}
                  </span>
                )}
            </Col>
            <Col style={{ marginRight: 'var(--space-sm)' }}>
              <AuthButton
                onPress={() =>
                  dispatch(editBetslipBet(i, {}, 'track_original'))
                }
              >
                Select Original
              </AuthButton>
              <span>{betLine(mergedBet, odds_preference)}</span>
            </Col>
            <Col>
              <AuthButton
                onPress={() =>
                  dispatch(editBetslipBet(i, {}, 'track_verified'))
                }
              >
                Select Verified
              </AuthButton>
              <span>
                {betLine(
                  { ...mergedBet, ...mergedBet.latest_changes },
                  odds_preference
                )}
              </span>
            </Col>
          </Row>
        </>
      )}

      {mergedBet.requires_action && mergedBet.errors && (
        <>
          <br />
          <Row style={{ width: '100%' }}>
            <Col style={{ flex: 0 }}>
              <IoAlertCircle
                size={28}
                color="var(--color-danger)"
                style={{ padding: '0 var(--space-xs)' }}
              />
            </Col>
            <Col style={{ alignItems: 'flex-start' }}>
              <p
                style={{
                  margin: 0,
                  color: 'var(--color-danger)',
                }}
              >
                {mergedBet.errors && 'There was an error tracking this bet!'}
              </p>
            </Col>
            <Col>
              {Object.keys(mergedBet.errors).map(k => (
                <p key={`ebb-track-error-${k}`} style={{ margin: 0 }}>
                  <b>{k}</b>: {mergedBet.errors[k]}
                </p>
              ))}
            </Col>
          </Row>
        </>
      )}
    </Wrapper>
  );
}

function ExpandDiv(props) {
  const [expand, setExpand] = useState(false);

  if (!expand) {
    return (
      <Row
        style={{
          width: '100%',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: 'var(--space-sm)',
        }}
      >
        <div
          style={{
            width: '40%',
            borderBottom: '1px solid var(--color-primary)',
          }}
        />
        <AuthButton
          containerStyle={{ maxWidth: '20%', margin: 0 }}
          btnStyle={{ margin: 0 }}
          onPress={() => setExpand(true)}
        >
          More
        </AuthButton>
        <div
          style={{
            width: '40%',
            borderBottom: '1px solid var(--color-primary)',
          }}
        />
      </Row>
    );
  }

  return (
    <Col
      style={{
        borderTop: '1px solid var(--color-primary)',
        marginTop: 'var(--space-sm)',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {props.children}
      <AuthButton
        containerStyle={{ maxWidth: '20%' }}
        colorTheme="inverted"
        onPress={() => setExpand(false)}
      >
        Less
      </AuthButton>
    </Col>
  );
}
