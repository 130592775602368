import roundOdds from 'utils/roundOdds';
import roundNumber from 'utils/roundNumber';
import toAmericanOdds from 'utils/toAmericanOdds';

/**
 * Build the bet line string from a bet
 * @param {Object} bet Objects representing a bet/linked bet (not parlay)
 * @param {Number} oddsPreference 0 = American, 1 = decimal
 * @returns {String} A string of the bet line ex. TOR Over 5.5 -115 (1P)
 */
export default function betLine(bet, oddsPreference, propBreakChar) {
  const {
    type_name,
    side,
    odds,
    number,
    prop_type,
    player,
    period,
    is_future,
  } = bet;

  if (propBreakChar === undefined) propBreakChar = '\n';

  let betLine = '';

  if (type_name === 'Player Prop') {
    betLine += player?.name + ' ';
  }

  if (type_name !== 'Game Prop') {
    betLine += side.split(' - ')[0] + ' ';
  }

  if (type_name === 'Spread') {
    betLine += number > 0 ? '+' : '';
    betLine += number !== 0 ? `${roundNumber(number)} ` : '';
  } else if (type_name === 'Total') betLine += `${roundNumber(number)} `;

  if (type_name === 'Player Prop') {
    betLine += `${propBreakChar}${roundNumber(number)} ${prop_type} `;
  } else if (type_name === 'Game Prop') {
    betLine += `${prop_type}${propBreakChar}${side}${
      number ? ' ' + roundNumber(number) : ''
    } `;
  }

  if (oddsPreference === 0) {
    betLine += toAmericanOdds(odds);
  } else {
    betLine += roundOdds(odds);
  }

  if (period !== 'FT' && !is_future) {
    betLine += ` (${period})`;
  }

  if (is_future) {
    betLine += '\n' + prop_type;
    if (side.includes('-')) {
      betLine += ': ' + side.split('-')[1] + ' ';
    } else {
      betLine += ' ';
    }
  }

  return betLine;
}
