import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useEffectOnce } from 'react-use';

// actual screens
import FuturesSchedDesktop from 'pages/Futures/FuturesSchedDesktop';
//import FuturesMobile from 'pages/Futures/FuturesMobile';

// components
import { Col } from 'components/generic/Layout';

// actions
import { getFuturesSched } from 'actions';

export default function FuturesSched() {
  const dispatch = useDispatch();
  const reduxProps = useSelector(state => ({
    futuresSched: state.scheduleReducer.futuresSched,
    selectedLeagues: state.scheduleReducer.selectedLeagues,
    isLoadingFutures: state.scheduleReducer.isLoadingFutures,
    futuresSchedNext: state.scheduleReducer.futuresSchedNext,
    hasNotStarted: state.scheduleReducer.hasNotStarted,
    inProgress: state.scheduleReducer.inProgress,
    seasonComplete: state.scheduleReducer.seasonComplete,
  }));

  const { selectedLeagues, hasNotStarted, inProgress, seasonComplete } =
    reduxProps;

  useEffectOnce(() => {
    dispatch(
      getFuturesSched({
        leagues: selectedLeagues,
        hasNotStarted: hasNotStarted,
        inProgress: inProgress,
        seasonComplete: seasonComplete,
      })
    );
  });

  return (
    <>
      <Col
        desktop
        style={{ height: '100vh', flexWrap: 'nowrap', overflow: 'hidden' }}
      >
        <FuturesSchedDesktop />
      </Col>
      <Col mobile style={{ padding: '0 var(--space-xxxs)' }}>
        <h5>
          Sorry, futures aren't supported on mobile. Try the betstamp app.
        </h5>
      </Col>
    </>
  );
}
