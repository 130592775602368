import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const SlideIn = styled.div`
  position: fixed;
  bottom: 20px;
  @keyframes AnimateOpen {
    0% {
      opacity: 0.25;
      bottom: -50px;
    }
    100% {
      opacity: 1;
      bottom: 20px;
    }
  }
  animation-name: AnimateOpen;
  animation-duration: 500ms;
  animation-timing-function: ease-in-out;
`;

const SlideOut = styled.div`
  position: fixed;
  bottom: -50px;
  @keyframes AnimateClose {
    0% {
      opacity: 1;
      bottom: 20px;
    }
    100% {
      opacity: 0.25;
      bottom: -50px;
    }
  }
  animation-name: AnimateClose;
  animation-duration: 500ms;
  animation-timing-function: ease-in-out;
`;

const StyledSnackbar = styled.div`
  padding: var(--space-xs);
  padding-right: var(--space-md);
  min-width: 250px;
  background-color: ${props => {
    if (props.theme === 'text') {
      return 'var(--color-text)';
    }
    return 'var(--color-danger)';
  }};
  color: ${props => {
    if (props.theme === 'text') {
      return 'var(--color-bg)';
    }
    return 'white';
  }};
  border-radius: var(--std-border-radius);
  transition: all var(--std-transition);
  text-align: left;
  box-shadow: 0 2px 8px 0 var(--color-shadow);
`;

export default function Snackbar(props) {
  const [show, setShow] = useState(true);

  useEffect(() => {
    if (props.autoHide) {
      const to = setTimeout(() => {
        setShow(false);
      }, props.autoHide);
      return () => clearTimeout(to);
    }
  }, [props.autoHide]);

  const theme = props.theme || 'danger';

  if (!show) {
    return (
      <SlideOut>
        <StyledSnackbar theme={theme}>{props.children}</StyledSnackbar>
      </SlideOut>
    );
  }

  return (
    <SlideIn>
      <StyledSnackbar theme={theme}>{props.children}</StyledSnackbar>
    </SlideIn>
  );
}
