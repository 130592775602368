import { Observable } from 'rxjs';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import { ajax } from 'rxjs/observable/dom/ajax';
import { getHeaders } from 'utils';

import {
  GET_RECOMMENDED_USERS_NEXT,
  getRecommendedUsersSuccess,
  getRecommendedUsersFailure,
} from 'actions';

export default function getRecommendedUsersNext(action$, state$) {
  return action$.ofType(GET_RECOMMENDED_USERS_NEXT).switchMap(() => {
    return ajax
      .getJSON(state$.value.featuredReducer.recommendedNext, getHeaders(state$))
      .map(data => getRecommendedUsersSuccess(data))
      .catch(error => Observable.of(getRecommendedUsersFailure(error.xhr)));
  });
}
