/**
 * Test a string to see if valid Decimal odds.
 * @param {String} odds String of decimal odds.
 * @returns {Boolean} True if valud decima odds.
 */
export default function isDecimalOddsValid(odds) {
  // start with some number of digits (can't start with 0)
  // optional .
  // optional digits after the .
  const reValid = /^[1-9]+\.?\d?/.test(odds);
  const isGreaterThanOne = parseFloat(odds) > 1;
  return reValid && isGreaterThanOne;
}
