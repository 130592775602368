import { Observable } from 'rxjs';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import { ajax } from 'rxjs/observable/dom/ajax';
import { BACKEND_API_URL, getHeaders } from 'utils';

import { UPDATE_USER, updateUserSuccess, updateUserFailure } from 'actions';

export default function updateUser(action$, state$) {
  return action$.ofType(UPDATE_USER).switchMap(action => {
    return ajax
      .patch(
        BACKEND_API_URL + 'api/users/' + state$.value.authReducer.user.id + '/',
        action.params,
        getHeaders(state$)
      )
      .map(data => updateUserSuccess(data))
      .catch(error => Observable.of(updateUserFailure(error.xhr)));
  });
}
