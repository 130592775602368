import { Observable } from 'rxjs';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import { ajax } from 'rxjs/observable/dom/ajax';
import { getHeaders } from 'utils';

import {
  GET_HAMMER_USERS_NEXT,
  getHammerUsersSuccess,
  getHammerUsersFailure,
} from 'actions';

export default function getHammerUsersNext(action$, state$) {
  return action$.ofType(GET_HAMMER_USERS_NEXT).switchMap(() => {
    return ajax
      .getJSON(state$.value.featuredReducer.hammerNext, getHeaders(state$))
      .map(data => getHammerUsersSuccess(data))
      .catch(error => Observable.of(getHammerUsersFailure(error.xhr)));
  });
}
