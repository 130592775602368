import {
  SET_SCHED,
  GET_SCHED,
  GET_SCHED_SUCCESS,
  GET_SCHED_FAILURE,
  GET_SCHED_NEXT,
  SET_TOUR_STEP,
  GET_FUTURES_SCHED,
  GET_FUTURES_SCHED_SUCCESS,
  GET_FUTURES_SCHED_FAILURE,
  GET_FUTURES_SCHED_NEXT,
  GET_FUTURES_ODDS,
  GET_FUTURES_ODDS_SUCCESS,
  GET_FUTURES_ODDS_FAILURE,
  FUTURES_ODDS_SEARCH,
} from 'actions';

import moment from 'moment';
import { DEFAULT_SELECTED_LEAGUE } from 'utils';

// default we would change based on time of year
const initialState = {
  selectedLeagues: [DEFAULT_SELECTED_LEAGUE],
  selectedBook: { id: -1, name: 'Best Available 1st Half' }, // -1 for best available halftime
  showingDate: moment().subtract(4, 'hours').toISOString(true),
  fetchSchedError: null,
  searchterm: '',
  // dictionary format
  // <iso string date in device timezone>: {
  //   next: <url>,
  //   prevEventDate: <iso string date in device timezone>,
  //   date: <iso string date  in device timezone>,
  //   nextEventDate: <iso string date in device timezone>,
  //   cacheMetaData: <object with some cache data>,
  //   games: [<game objects in selected leagues>],
  // },
  schedule: {},
  refreshingAll: false,
  isSearchingOrFiltering: false,
  isLoadingNextSched: false,
  isChangingLeague: false,
  // futures schedule
  isLoadingFutures: false,
  isLoadingFuturesNext: false,
  fetchFuturesError: null,
  futuresSched: null,
  futuresSchedNext: null,
  futuresLeague: null,
  // futures odds
  isLoadingFuturesOdds: false,
  futuresOddsError: null,
  futuresOdds: null,
  futuresSearchterm: null,
  // futures filtering options
  hasNotStarted: true,
  inProgress: true,
  seasonComplete: false,
};

export default function scheduleReducer(state = initialState, action) {
  switch (action.type) {
    case SET_SCHED:
      return Object.assign({}, state, {
        ...state,
        fetchSchedError: null,
        showingDate: action.date,
        isSearchingOrFiltering: false,
        isLoadingNextSched: false,
      });
    case GET_SCHED:
      return Object.assign({}, state, {
        ...state,
        fetchSchedError: null,
        showingDate: action.preload ? state.showingDate : action.date,
        selectedBook: action.book ? action.book : state.selectedBook,
        selectedLeagues: action.leagues
          ? action.leagues
          : state.selectedLeagues,
        searchterm:
          Boolean(action.searchTerm) || action.searchTerm === ''
            ? action.searchTerm
            : state.searchterm,
        changingBook: Boolean(action.book),
        refreshingAll: action.refresh,
        isSearchingOrFiltering:
          Boolean(action.searchTerm) || action.searchTerm === '',
        isChangingLeague: Boolean(action.leagues),
        schedule:
          Boolean(action.leagues) || action.exact || action.reset
            ? {}
            : state.schedule,
      });
    case GET_SCHED_SUCCESS:
      let newSched = { ...state.schedule };

      const dateIndex = action.payload.showing_date.substr(0, 10);

      newSched[dateIndex] = {
        games: action.concatGames
          ? newSched[dateIndex].games.concat(action.payload.results)
          : action.payload.results,
        next: action.payload.next,
        prevEventDate: action.payload.prev_event_date,
        date: action.payload.showing_date,
        nextEventDate: action.payload.next_event_date,
        cacheMetaData: action.cacheMetaData,
      };

      return Object.assign({}, state, {
        ...state,
        fetchSchedError: null,
        schedule: newSched,
        showingDate: action.preload
          ? state.showingDate
          : action.payload.showing_date,
        changingBook: false,
        refreshingAll: false,
        isSearchingOrFiltering: false,
        isLoadingNextSched: false,
        isChangingLeague: false,
      });
    case GET_SCHED_FAILURE:
      return Object.assign({}, state, {
        ...state,
        fetchSchedError: action.error,
        refreshingAll: false,
        isLoadingNextSched: false,
      });

    case GET_SCHED_NEXT:
      return Object.assign({}, state, {
        ...state,
        fetchSchedError: action.error,
        isLoadingNextSched: true,
      });

    case SET_TOUR_STEP:
      let data = {};
      if (action.stepID === 'games5') {
        const today = moment()
          .set('hour', 19)
          .set('minute', 0)
          .toISOString(true);
        const dateIndex = today.substr(0, 10);
        data = {
          showingDate: today,
          selectedLeagues: ['NBA'],
          schedule: {
            [dateIndex]: {
              next: null,
              prevEventDate: null,
              date: today,
              nextEventDate: null,
              cacheMetaData: {},
              games: [genGame(0, today), genGame(1, today), genGame(2, today)],
            },
          },
        };
      }
      return Object.assign({}, state, {
        ...state,
        ...data,
      });

    case GET_FUTURES_SCHED:
      return Object.assign({}, state, {
        ...state,
        fetchFuturesError: null,
        isLoadingFutures: true,
        isLoadingFuturesNext: false,
        futuresSched: null,
        futuresSchedNext: null,
        selectedLeagues: action.leagues
          ? action.leagues
          : state.selectedLeagues,
        hasNotStarted: action.hasNotStarted,
        inProgress: action.inProgress,
        seasonComplete: action.seasonComplete,
      });
    case GET_FUTURES_SCHED_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        fetchFuturesError: null,
        isLoadingFutures: false,
        isLoadingFuturesNext: false,
        futuresSched: state.futuresSched
          ? state.futuresSched.concat(action.payload.results)
          : action.payload.results,
        futuresSchedNext: action.payload.next,
      });
    case GET_FUTURES_SCHED_FAILURE:
      return Object.assign({}, state, {
        ...state,
        fetchFuturesError: action.error,
        isLoadingFutures: false,
        isLoadingFuturesNext: false,
      });
    case GET_FUTURES_SCHED_NEXT:
      return Object.assign({}, state, {
        ...state,
        fetchFuturesError: null,
        isLoadingFutures: false,
        isLoadingFuturesNext: true,
      });

    case GET_FUTURES_ODDS:
      return Object.assign({}, state, {
        ...state,
        isLoadingFuturesOdds: true,
        futuresOddsError: null,
        futuresOdds: null,
      });
    case GET_FUTURES_ODDS_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        isLoadingFuturesOdds: false,
        futuresOddsError: null,
        futuresOdds: action.payload,
      });
    case GET_FUTURES_ODDS_FAILURE:
      return Object.assign({}, state, {
        ...state,
        isLoadingFuturesOdds: false,
        futuresOddsError: action.error,
      });

    case FUTURES_ODDS_SEARCH:
      return Object.assign({}, state, {
        ...state,
        futuresSearchterm: action.searchterm,
      });

    default:
      return state;
  }
}

function genGame(g, today) {
  if (g === 0) {
    return {
      id: 0,
      away_team: {
        full_name: 'Toronto Raptors',
        name: 'TOR',
      },
      home_team: {
        full_name: 'Houston Rockets',
        name: 'HOU',
      },
      date: today,
      finish_type: '',
      is_complete: false,
      is_live: false,
      is_live_text: null,
      is_postponed: false,
      league: 'NBA',
      live_odds: {},
      odds: null,
      period_scores: null,
      season: null,
      sport: 'Basketball',
      winner: null,
      best_available: {
        away_ml: 1.35,
        away_spread: -7,
        away_spread_odds: 1.96,
        home_ml: 3.8,
        home_spread: 7.5,
        home_spread_odds: 1.91,
        over_odds: 1.91,
        over_total: 223,
        under_odds: 2.01,
        under_total: 223,
        win_pct: {
          away_win_pct: 74,
          home_win_pct: 26,
          tie_win_pct: '',
        },
      },
    };
  }
  if (g === 1) {
    return {
      id: 1,
      away_team: {
        full_name: 'LA Clippers',
        name: 'LAC',
      },
      home_team: {
        full_name: 'Dallas Mavericks',
        name: 'DAL',
      },
      date: today,
      finish_type: '',
      is_complete: false,
      is_live: false,
      is_live_text: null,
      is_postponed: false,
      league: 'NBA',
      live_odds: {},
      odds: null,
      period_scores: null,
      season: null,
      sport: 'Basketball',
      winner: null,
      best_available: {
        away_ml: 2.92,
        away_spread: 5.5,
        away_spread_odds: 1.95,
        home_ml: 1.47,
        home_spread: -5.5,
        home_spread_odds: 1.97,
        over_odds: 1.98,
        over_total: 221,
        under_odds: 1.98,
        under_total: 221,
        win_pct: {
          away_win_pct: 34,
          home_win_pct: 66,
          tie_win_pct: '',
        },
      },
    };
  }
  return {
    id: 2,
    away_team: {
      full_name: 'San Antonio Spurs',
      name: 'SAS',
    },
    home_team: {
      full_name: 'Indiana Pacers',
      name: 'IND',
    },
    date: today,
    finish_type: '',
    is_complete: false,
    is_live: false,
    is_live_text: null,
    is_postponed: false,
    league: 'NBA',
    live_odds: {},
    odds: null,
    period_scores: null,
    season: null,
    sport: 'Basketball',
    winner: null,
    best_available: {
      away_ml: 4.83,
      away_spread: 11,
      away_spread_odds: 1.98,
      home_ml: 1.22,
      home_spread: -11,
      home_spread_odds: 1.95,
      over_odds: 1.91,
      over_total: 220,
      under_odds: 1.95,
      under_total: 220,
      win_pct: {
        away_win_pct: 21,
        home_win_pct: 79,
        tie_win_pct: '',
      },
    },
  };
}
