import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useParams } from 'react-router-dom';

// utils
import { RELATIONSHIP_STATUS } from 'utils';

// components
import ProfileTab from 'components/user_profile/ProfileTab';
import ActivityIndicator from 'components/generic/ActivityIndicator';
import {
  Grid,
  HorizontalScroller,
  DesktopLayout,
  PhoneLayout,
} from 'components/generic/Layout';
import ModalWrapper from 'components/generic/ModalWrapper';
import UserProfileHeader from 'components/user_profile/UserProfileHeader';
import ErrorDisplay from 'components/generic/ErrorDisplay';
// import OpenProfileInApp from 'components/OpenProfileInApp';
import { useLocation } from 'react-router-dom';
import FeaturedPackage from 'components/FeaturedPackage';

// modals
import BuyPackage from 'components/modals/BuyPackage';

// actions
import {
  getPublicProfileByUsername,
  resetStripeErrors,
  getWagers,
} from 'actions';

export default function PublicProfile(props) {
  const [modalIsOpen, setModalisOpen] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState(null);

  const dispatch = useDispatch();
  const location = useLocation();

  const params = useParams();

  // redux props
  const reduxProps = useSelector(state => ({
    user: state.authReducer.user,
    userToken: state.authReducer.userToken,
    isLoading: state.publicUserReducer.isLoading,
    apiError: state.authReducer.apiError,
    publicProfile: state.publicUserReducer.publicProfile,
    userPicks: state.publicUserReducer.userPicks,
    userPicksNext: state.publicUserReducer.userPicksNext,
    fetchError: state.publicUserReducer.fetchError,
    isPicksLoading: state.publicUserReducer.isPicksLoading,
    isNextPicksLoading: state.publicUserReducer.isNextPicksLoading,
    odds_preference: state.authReducer.odds_preference,
    // searchSuccess: state.searchReducer.searchSuccess,

    couldNotFindUser: state.publicUserReducer.couldNotFindUser,
  }));

  const {
    publicProfile,
    isLoading,
    couldNotFindUser,
    fetchError,
    user,
    userToken,
  } = reduxProps;

  const _initialPicksLoad = id => {
    dispatch(
      getWagers({
        screen: 'publicUserPending',
        is_graded: false,
        user: id,
      })
    );
    dispatch(
      getWagers({
        screen: 'publicUserHistory',
        is_graded: true,
        user: id,
      })
    );
  };

  useEffect(() => {
    if (params.username) {
      dispatch(getPublicProfileByUsername(params.username));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const isInitialMount = useRef(true);
  useEffect(() => {
    if (isInitialMount.current) {
      // this is to stop the loading of the previous users bets
      // since the publicProfile will stil contain an id and useEffect
      // runs on every mount
      isInitialMount.current = false;
    } else {
      if (publicProfile && publicProfile.id && userToken) {
        _initialPicksLoad(publicProfile.id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicProfile.id]);

  if (couldNotFindUser) {
    return <Redirect to="/404" />;
  }

  if (fetchError) {
    return (
      <div className="container">
        <ErrorDisplay
          error={fetchError}
          message={'getting this user.'}
          retry={() =>
            dispatch(getPublicProfileByUsername(props.match.params.username))
          }
        />
      </div>
    );
  }

  if (
    isLoading ||
    !publicProfile ||
    !publicProfile.packages ||
    Object.keys(publicProfile).length === 0
  ) {
    return (
      <div className="container">
        <ActivityIndicator size={3} style={{ marginTop: 'var(--space-xxl)' }} />
        <p style={{ textAlign: 'center' }}>Loading user</p>
      </div>
    );
  }

  return (
    <>
      {publicProfile.relationship_to_user.status !==
        RELATIONSHIP_STATUS.PURCHASED &&
        publicProfile.packages[0] &&
        userToken !== null && (
          <ModalWrapper
            modalIsOpen={modalIsOpen}
            onClose={() => {
              dispatch(resetStripeErrors());
              setModalisOpen(false);
            }}
            onRequestClose={() => {
              dispatch(resetStripeErrors());
              setModalisOpen(false);
            }}
            // title={selectedPackage ? `${selectedPackage.title}` : ''}
            // dontCloseOnEscapeOrOutsideClick
            modalSize={'small'}
          >
            <BuyPackage
              selectedPackage={selectedPackage}
              dismissModal={refresh => {
                setModalisOpen(false);
                dispatch(resetStripeErrors());
                if (refresh) {
                  dispatch(
                    getPublicProfileByUsername(props.match.params.username)
                  );
                }
              }}
            />
          </ModalWrapper>
        )}

      <UserProfileHeader
        user={publicProfile}
        isPublic={userToken ? publicProfile.id !== user.id : true}
        showNotifications={
          userToken
            ? publicProfile.relationship_to_user.status !==
            RELATIONSHIP_STATUS.NONE
            : false
        }
        notificationsOn={
          userToken ? publicProfile.relationship_to_user.notifications : true
        }
        showFollowButton={userToken !== null}
        hideViewMyProfileButton={true}
      />

      <div className="container" style={{ paddingBottom: 'var(--space-xxxl)' }}>
        {/*{publicProfile.relationship_to_user.status !==
          RELATIONSHIP_STATUS.PURCHASED &&
          publicProfile.packages[0] &&
          userToken !== null &&
          user.id !== publicProfile.id && (
            <>
              <DesktopLayout>
                <Grid
                  style={{
                    margin: 'var(--space-sm) 0',
                    gridTemplateColumns: 'repeat(auto-fit, 400px)',
                  }}
                >
                  {publicProfile.packages.map((p, i) => (
                    <FeaturedPackage
                      key={`user-profile-package-${i}`}
                      pack={p}
                      onClick={() => {
                        setSelectedPackage(p);
                        setModalisOpen(true);
                      }}
                    />
                  ))}
                </Grid>
              </DesktopLayout>

              <PhoneLayout style={{ margin: 'var(--space-sm) 0' }}>
                <HorizontalScroller>
                  {publicProfile.packages.map((p, i) => (
                    <FeaturedPackage
                      style={{
                        minWidth: '158px',
                        margin: '0 var(--space-xs)',
                      }}
                      key={`user-profile-package-${i}`}
                      pack={p}
                      onClick={() => {
                        setSelectedPackage(p);
                        setModalisOpen(true);
                      }}
                    />
                  ))}
                </HorizontalScroller>
              </PhoneLayout>
            </>
          )}*/}

        {/*<OpenProfileInApp username={publicProfile.username} />*/}
        <ProfileTab
          publicProfile_id={publicProfile.id}
          publicProfile_name={publicProfile.username}
          publicProfile_pending={publicProfile.stats.pending}
          pendingName={'publicUserPending'}
          historyName={'publicUserHistory'}
          analysisName={'publicUserAnalysis'}
          viewingOtherUser={true}
          showPublicBets={true}
          hidePendingPlays={
            publicProfile.relationship_to_user.status !==
            RELATIONSHIP_STATUS.PURCHASED &&
            (publicProfile.packages[0] || publicProfile.hide_pending_bets)
          }
        />
      </div>
    </>
  );
}
