import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { useLocation } from 'react-router-dom';
import moment from 'moment';

// components
import ActivityIndicator from 'components/generic/ActivityIndicator';
import { AuthButton } from 'components/AuthButton';
import { AuthTextInput } from 'components/AuthTextInput';
import PaymentMethod from 'components/generic/PaymentMethod';
import { Row, DesktopLayout, PhoneLayout } from 'components/generic/Layout';

// actions
import { createSubscription } from 'actions';

export default function CompletePurchase(props) {
  const [sessionID, setSessionID] = useState(null);
  const [newCard, setNewCard] = useState(false);

  // payment method stuff
  const [processingPM, setProcessingPM] = useState(false);
  const [PMError, setPMError] = useState(null);

  // card input stuff
  const [error, setError] = useState(null);
  const [disabled, setDisabled] = useState(true);
  const [fullName, setFullName] = useState('');

  // existing payment method
  const [selectedCard, setSelectedCard] = useState(null);

  // extra auth stuff
  const [isLoadingExtraAuth, setIsLoadingExtraAuth] = useState(null);
  const [extraAuthError, setExtraAuthError] = useState(null);

  const stripe = useStripe();
  const elements = useElements();
  const location = useLocation();

  const dispatch = useDispatch();
  const reduxProps = useSelector(state => ({
    purchaseSession: state.stripeReducer.purchaseSession,
    isSubscribing: state.stripeReducer.isSubscribing,
    createSubError: state.stripeReducer.createSubError,
    createSubSuccess: state.stripeReducer.createSubSuccess,
    userToken: state.authReducer.userToken,
    furtherAction: state.stripeReducer.furtherAction,
    pi_cs: state.stripeReducer.pi_cs,
    sub_id: state.stripeReducer.sub_id,
    createSubErrorReason: state.stripeReducer.createSubErrorReason,
    createSubErrorMsg: state.stripeReducer.createSubErrorMsg,
  }));

  const {
    purchaseSession,
    isSubscribing,
    createSubError,
    createSubSuccess,
    userToken,
    pi_cs,
    sub_id,
    createSubErrorReason,
    createSubErrorMsg,
  } = reduxProps;

  useEffect(() => {
    if (purchaseSession && purchaseSession.payment_methods.length > 0) {
      setSelectedCard(purchaseSession.payment_methods[0].id);
    }
    if (purchaseSession) {
      setSessionID(purchaseSession.id);
    }
  }, [purchaseSession]);

  const handleChange = event => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setPMError(null);
    setDisabled(!event.complete);
    setError(event.error ? event.error.message : '');
  };

  const handleSubmit = async ev => {
    if (ev) {
      ev.preventDefault();
    }
    setProcessingPM(true);
    const payload = await stripe.confirmCardSetup(
      purchaseSession.setup_intent_id,
      {
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: {
            name: fullName,
          },
        },
      }
    );
    if (payload.error) {
      setProcessingPM(false);
      setPMError(payload.error);
    } else {
      dispatch(
        createSubscription(sessionID, payload.setupIntent.payment_method, null)
      );
    }
  };

  const subWithExistingPM = () => {
    dispatch(createSubscription(sessionID, selectedCard, null));
  };

  useEffect(() => {
    if (createSubSuccess) {
      let redirectTo = '';
      if (location.search.includes('?mobile=true') || !userToken) {
        redirectTo = '/transaction-processed';
      }
      if (userToken) {
        redirectTo = `/u/${purchaseSession.package_info.package_owner}`;
      }

      window.location.href = redirectTo;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createSubSuccess]);

  useEffect(() => {
    if (createSubErrorReason === 'need_extra_auth' && pi_cs) {
      setIsLoadingExtraAuth(true);
      stripe
        .confirmCardPayment(pi_cs, {
          payment_method: selectedCard,
        })
        .then(result => {
          setIsLoadingExtraAuth(false);
          if (result.error) {
            setExtraAuthError(result.error);
          }
          if (result.paymentIntent.status === 'succeeded') {
            dispatch(createSubscription(sessionID, null, sub_id));
            setIsLoadingExtraAuth(false);
          }
        })
        .catch(error => {
          setIsLoadingExtraAuth(true);
          setExtraAuthError(error);
        });
    } else if (createSubErrorReason === 'invalid_payment_method') {
      setProcessingPM(false);
      setPMError(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createSubErrorReason]);

  if (createSubError) {
    return (
      <>
        <h6>Payment failed!</h6>
        {createSubError.response && (
          <>
            {Object.entries(createSubError.response).map((entry, i) => (
              <>{entry[0] !== 'reason' && <p>{entry[1]}</p>}</>
            ))}
          </>
        )}
      </>
    );
  }

  if (extraAuthError) {
    return <h6>Payment failed!</h6>;
  }

  if (!stripe) {
    return <ActivityIndicator size={3} />;
  }

  const cardStyle = {
    style: {
      backgroundColor: 'var(--color-danger)',
      base: {
        color: '#222831',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {},
      },
      invalid: {
        color: '#eb0a36',
        iconColor: '#eb0a36',
      },
    },
  };

  if (stripe && purchaseSession) {
    const p = purchaseSession.package_info;
    const payments = purchaseSession.payment_methods;

    return (
      <>
        {newCard || payments.length === 0 ? (
          <form id="payment-form">
            <PackageInfoDisplay p={p} />

            <Row
              style={{
                justifyContent: 'space-between',
                marginTop: 'var(--space-sm)',
                marginBottom: 'var(--space-xxs)',
              }}
            >
              <h6 style={{ marginTop: 'var(--space-xs)' }}>Payment Method</h6>
              {payments.length > 0 && (
                <AuthButton
                  btnTheme="borderless"
                  containerStyle={{
                    maxWidth: 'fit-content',
                    marginRight: 'calc(-1*var(--space-sm))',
                  }}
                  onPress={() => {
                    setNewCard(false);
                  }}
                >
                  Use Existing Card
                </AuthButton>
              )}
            </Row>

            <AuthTextInput
              placeholder={'Cardholder Full Name *'}
              onChangeText={fullName => setFullName(fullName)}
            />
            <div
              style={{
                marginTop: 'var(--space-sm)',
                backgroundColor: '#ffffff',
                border: '1px solid var(--color-bg)',
                borderRadius: 'var(--btn-border-radius)',
                padding: 'var(--space-sm)',
              }}
            >
              <CardElement
                id="card-element"
                options={cardStyle}
                onChange={handleChange}
              />
            </div>
            {error && (
              <div
                style={{
                  color: 'var(--color-danger)',
                  margin: 'var(--space-sm)',
                }}
                role="alert"
              >
                {error}
              </div>
            )}
            {PMError && (
              <div
                style={{
                  color: 'var(--color-danger)',
                  margin: 'var(--space-sm)',
                }}
                role="alert"
              >
                {PMError.message}
              </div>
            )}
            {createSubErrorReason === 'invalid_payment_method' && (
              <div
                style={{
                  color: 'var(--color-danger)',
                  margin: 'var(--space-sm)',
                }}
                role="alert"
              >
                {createSubErrorMsg}
              </div>
            )}
            <AuthButton
              containerStyle={{
                margin: 'var(--space-sm) 0',
              }}
              disabled={
                isSubscribing ||
                disabled ||
                processingPM ||
                createSubSuccess ||
                createSubError ||
                PMError ||
                error ||
                !purchaseSession.setup_intent_id ||
                isLoadingExtraAuth ||
                fullName.trim() === ''
              }
              isLoading={isSubscribing || processingPM || isLoadingExtraAuth}
              onPress={handleSubmit}
            >
              {createSubSuccess
                ? 'Success'
                : `Subscribe $${p.cost}/${p.billing_freq}`}
            </AuthButton>

            <small>
              <i>&nbsp;&nbsp;&nbsp;* All payments in USD</i>
            </small>
          </form>
        ) : (
          <>
            <PackageInfoDisplay p={p} />

            <Row
              style={{
                justifyContent: 'space-between',
                marginTop: 'var(--space-sm)',
                marginBottom: 'var(--space-xxs)',
              }}
            >
              <h6 style={{ marginTop: 'var(--space-xs)' }}>Payment Method</h6>
              <AuthButton
                btnTheme="borderless"
                containerStyle={{
                  maxWidth: 'fit-content',
                  marginRight: 'calc(-1*var(--space-sm))',
                }}
                onPress={() => {
                  setSelectedCard(null);
                  setNewCard(true);
                }}
              >
                Use Another Card
              </AuthButton>
            </Row>

            {payments.map(method => (
              <PaymentMethod
                key={`payment-method-${method.id}`}
                checked={selectedCard === method.id}
                onClick={() => {
                  setSelectedCard(method.id);
                  setPMError(null);
                }}
                method={method}
              />
            ))}

            {createSubErrorReason === 'invalid_payment_method' ? (
              <div
                style={{
                  color: 'var(--color-danger)',
                  margin: 'var(--space-sm)',
                }}
                role="alert"
              >
                {createSubErrorMsg}
              </div>
            ) : (
              <br />
            )}
            <AuthButton
              containerStyle={{
                margin: 0,
              }}
              disabled={
                isSubscribing ||
                createSubSuccess ||
                createSubError ||
                isLoadingExtraAuth ||
                !selectedCard ||
                PMError
              }
              isLoading={isSubscribing || isLoadingExtraAuth}
              onPress={() => subWithExistingPM()}
            >
              {createSubSuccess
                ? 'Success'
                : `Subscribe $${p.cost}/${p.billing_freq}`}
            </AuthButton>
          </>
        )}
      </>
    );
  }

  return (
    <>
      <ActivityIndicator size={3} />
      <h5 style={{ textAlign: 'center', marginTop: 0 }}>Loading Purchase</h5>
    </>
  );
}

function PackageInfoDisplay(props) {
  const { p } = props;
  const renewDate = moment()
    .add(1, p.billing_freq + 's')
    .format('MMMM D, YYYY');
  return (
    <>
      <DesktopLayout>
        <h3 style={{ marginTop: 0 }}>
          ${p.cost}/{p.billing_freq}
        </h3>
      </DesktopLayout>
      <PhoneLayout>
        <h4 style={{ marginTop: 0 }}>
          ${p.cost}/{p.billing_freq}
        </h4>
      </PhoneLayout>
      <div style={{ textAlign: 'left' }}>
        <DesktopLayout>
          <h4 style={{ marginBottom: 0 }}>{p.title}</h4>
          <h6 style={{ marginTop: 0 }}>by {p.package_owner}</h6>
        </DesktopLayout>
        <PhoneLayout>
          <h4 style={{ marginBottom: 0 }}>{p.title}</h4>
          <h6 style={{ marginTop: 0 }}>by {p.package_owner}</h6>
        </PhoneLayout>
        <p style={{ marginTop: 0 }}>{p.description}</p>
        <p>
          <b>
            Your subscription will renew on {renewDate} for USD${p.cost}
          </b>
        </p>
      </div>
    </>
  );
}
