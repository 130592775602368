/**
 * Tests odds to see if they are valid American odds.
 * @param {String} odds American odds.
 * @returns {Boolean} True if valid odds.
 */
export default function isAmericanOddsValid(odds) {
  // must start with + or -
  // end with 3 to 5 digits
  return /^[-+]\d{3,5}$/.test(odds);
}
