import styled from 'styled-components';
import { useSelector } from 'react-redux';
import {
  IoListSharp,
  IoCheckmarkCircleOutline,
  IoHelpCircleOutline,
  IoCallOutline,
} from 'react-icons/io5';
import { useParams, Link } from 'react-router-dom';

// components
import { BetstampWordBlueText } from 'components/generic/Logos';

const Sidebar = styled.div`
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  position: sticky;
  top: 0;
  flex: 0 0 360px;
  background: var(--color-onb-blue);
  padding-bottom: var(--space-md);
  scrollbar-width: none; /* Firefox */
  overscroll-behavior: contain;
  max-width: 20vw;
  @media only screen and (max-width: 1500px) {
    flex: 0 0 330px;
  }
  @media only screen and (max-width: 1400px) {
    flex: 0 0 290px;
  }
  @media only screen and (max-width: 1250px) {
    flex: 0 0 260px;
  }
  @media screen and (max-width: 650px) {
    display: none;
    padding-bottom: var(--space-xxxxl);
  }
`;

const NavLink = styled.div`
  display: flex;
  flex-flow: row nowrap;
  gap: var(--space-xs);
  align-items: center;
  justify-content: flex-start;
  padding: var(--space-md) var(--space-sm);
  font-size: var(--text-md);
  transition: 0.25s all ease-in-out;
  border-left: 8px solid
    ${props => (props.selected ? 'var(--color-primary)' : 'transparent')};
  background: ${props =>
    props.selected ? 'var(--color-onb-blue-dark)' : 'transparent'};
  margin-bottom: var(--space-xxxxs);
  color: white;
  cursor: pointer;
  :hover {
    background-color: black;
  }
`;

export default function OnbSidebar(props) {
  const reduxProps = useSelector(state => ({
    allBooksMap: state.authReducer.allBooksMap,
  }));
  const { allBooksMap } = reduxProps;

  const { currentProgress, numNeedAttn, session } = props;

  const params = useParams();
  const page = params?.page;

  return (
    <Sidebar>
      <div style={{ flex: 0.15 }} />
      <BetstampWordBlueText height={116} />
      <div style={{ flex: 0.25 }} />
      <Link
        to={`/session/${params?.session_id}/${params?.attendee_id}/current`}
        style={{ textDecoration: 'none' }}
      >
        <NavLink selected={!page || page === 'current'}>
          <IoCheckmarkCircleOutline size={32} color="white" /> Current Task
          {currentProgress &&
            allBooksMap &&
            allBooksMap[currentProgress.book]?.logo && (
              <>
                <div style={{ flex: 1 }} />
                <img
                  style={{ borderRadius: '4px' }}
                  width={32}
                  src={allBooksMap[currentProgress.book]?.logo}
                  alt={allBooksMap[currentProgress.book]?.name}
                  title={allBooksMap[currentProgress.book]?.name}
                />
              </>
            )}
        </NavLink>
      </Link>
      <Link
        to={`/session/${params?.session_id}/${params?.attendee_id}/all`}
        style={{ textDecoration: 'none' }}
      >
        <NavLink selected={page === 'all'}>
          <IoListSharp size={32} color="white" /> All Tasks
          {!!numNeedAttn && (
            <>
              <div style={{ flex: 1 }} />
              <span
                style={{
                  padding: '0 var(--space-md)',
                  borderRadius: '12px',
                  backgroundColor: 'var(--color-danger)',
                }}
              >
                {numNeedAttn}
              </span>
            </>
          )}
        </NavLink>
      </Link>
      <div style={{ flex: 0.33 }} />
      <hr
        style={{
          borderColor: 'rgba(255,255,255,0.33)',
          width: '90%',
          alignSelf: 'center',
        }}
      />
      {/*<NavLink>
        <IoHelpCircleOutline size={22} color="white" /> Contact Host
      </NavLink>*/}
      {session?.google_meet_link && (
        <a
          href={session?.google_meet_link}
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: 'none' }}
        >
          <NavLink>
            <IoCallOutline size={32} color="white" /> Join Google Meet
          </NavLink>
        </a>
      )}
      <div style={{ flex: 2 }} />
    </Sidebar>
  );
}
