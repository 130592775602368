import { Observable } from 'rxjs';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import { ajax } from 'rxjs/observable/dom/ajax';
import { BACKEND_API_URL, getHeaders } from 'utils';

import {
  UPDATE_SHARPSPORTS_BOOK,
  updateSSBookSuccess,
  updateSSBookFailure,
} from 'actions';

export default function updateSSBook(action$, state$) {
  return action$.ofType(UPDATE_SHARPSPORTS_BOOK).exhaustMap(action => {
    let url = `${BACKEND_API_URL}api/sharpsportsbook/${action.book.id}/`;

    return ajax
      .patch(url, { access: action.book.access }, getHeaders(state$))
      .map(data => updateSSBookSuccess(data.response))
      .catch(error => Observable.of(updateSSBookFailure(error.xhr)));
  });
}
