import accounting from 'content/tutorials/accounting.js';
import analysis from 'content/tutorials/analysis.js';
import customBet from 'content/tutorials/custom-bet.js';
import editingBets from 'content/tutorials/editing-bets.js';
import transaction from 'content/tutorials/transaction.js';
import verifiedBets from 'content/tutorials/verified-bets.js';
import marketplace from 'content/tutorials/marketplace.js';
import packages from 'content/tutorials/packages.js';
import sellMyPicks from 'content/tutorials/sell-my-picks.js';
import defaults from 'content/tutorials/defaults.js';
import myProfile from 'content/tutorials/my-profile.js';
import mySportsbooks from 'content/tutorials/my-sportsbooks.js';
import settings from 'content/tutorials/settings';
import bets from 'content/tutorials/bets';
import playerProp from 'content/tutorials/player-prop';

export default {
  Accounting: accounting,
  Analysis: analysis,
  CustomBet: customBet,
  EditingBets: editingBets,
  Transaction: transaction,
  VerifiedBets: verifiedBets,
  Marketplace: marketplace,
  Packages: packages,
  SellMyPicks: sellMyPicks,
  Defaults: defaults,
  MyProfile: myProfile,
  MySportsbooks: mySportsbooks,
  Settings: settings,
  Bets: bets,
  PlayerProp: playerProp,
};
