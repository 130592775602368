import { Observable } from 'rxjs';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import { ajax } from 'rxjs/observable/dom/ajax';
import { BACKEND_API_URL, getHeaders } from 'utils';

import {
  GET_PLAYER_PROP_PROP_LIST,
  getPlayerPropPropListSuccess,
  getPlayerPropPropListFailure,
} from 'actions';

export default function getPlayerPropPropList(action$, state$) {
  return action$.ofType(GET_PLAYER_PROP_PROP_LIST).switchMap(action => {
    let url =
      BACKEND_API_URL +
      `api/player_prop_list/?game_id=${action.game_id}&player_name=${action.player_name}`;

    return ajax
      .getJSON(url, getHeaders(state$))
      .map(data => getPlayerPropPropListSuccess(data))
      .catch(error => Observable.of(getPlayerPropPropListFailure(error.xhr)));
  });
}
