import React, { useEffect } from 'react';
import { Row, Col } from 'components/generic/Layout';
import { useDispatch, useSelector } from 'react-redux';
import { IoGiftOutline, IoCheckmarkCircleOutline } from 'react-icons/io5';
import { Link } from 'react-router-dom';
import {
  IoLogoInstagram,
  IoLogoTiktok,
  IoLogoTwitter,
  IoMailOutline,
  IoGitNetworkOutline,
} from 'react-icons/io5';

//components
import { AuthTextInput } from 'components/AuthTextInput';
import { AuthButton } from 'components/AuthButton';

//actions
import { setUserChanges, checkReferralCodeExists, updateUser } from 'actions';

export default function ReferralSupport(props) {
  const dispatch = useDispatch();
  const reduxProps = useSelector(state => ({
    user: state.authReducer.user,
    userChanges: state.authReducer.userChanges,
    referralCodeExists: state.authReducer.referralCodeExists,
  }));

  const { user, userChanges, referralCodeExists } = reduxProps;

  const mergedUser = {
    ...user,
    ...userChanges,
  };

  const _setUserChanges = latestChanges => {
    dispatch(setUserChanges(latestChanges));
  };

  //reset referral code for testing purposes
  useEffect(() => {
    _setUserChanges({ ...userChanges, referral_code: null });
    const to = setTimeout(() => {
      dispatch(updateUser(userChanges));
    }, 1000);
    return () => clearTimeout(to);
  }, []);

  return (
    <Col
      style={{
        flex: 1,
        justifyContent: 'flex-start',
        alignItems: 'stretch',
        ...props.style,
      }}
    >
      <Row
        style={{
          alignItems: 'center',
          justifyContent: 'space-between',
          position: 'sticky',
          top: 0,
          backgroundColor: 'var(--color-bg)',
          zIndex: 101,
          borderBottom: '1px solid #9b9b9b',
          padding: 'var(--space-sm) 0',
        }}
      >
        <h3 style={{ margin: 0, flex: 2 }}>Referrals and Support</h3>
      </Row>
      <SectionHeading title="Referral Code" style={{ marginBottom: 0 }} />

      {user.referral_code ? (
        <p>{user.referral_code}</p>
      ) : (
        <Row style={{ justifyContent: 'space-between', alignItems: 'center' }}>
          <AuthTextInput
            containerStyle={{ flex: 3, paddingHorizontal: 'var(--space-sm)' }}
            leftIcon={IoGiftOutline}
            placeholder="CODE1"
            onChangeText={text => {
              dispatch(checkReferralCodeExists(text));
              _setUserChanges({
                ...userChanges,
                referral_code: text.toUpperCase(),
              });
            }}
            errorText={
              referralCodeExists === false && "Referral code doesn't exist"
            }
            rightIcon={referralCodeExists ? IoCheckmarkCircleOutline : null}
            rightIconColor={'var(--color-success)'}
            value={mergedUser.referral_code || ''}
          />
          <AuthButton
            containerStyle={{ flex: 1 }}
            disabled={!referralCodeExists}
            btnTheme="borderless"
            onPress={() => dispatch(updateUser(userChanges))}
          >
            Apply
          </AuthButton>
        </Row>
      )}
      <SectionHeading title="Become a Betstamp Ambassador" />
      <Row style={{ justifyContent: 'space-evenly', alignItems: 'center' }}>
        <p style={{ flex: 2, margin: 'var(--space-sm)' }}>
          Join on the ground level of the best Sports Betting aggregator on the
          market. Become a betstamp ambassador today!
        </p>
        <a
          href="https://jobs.lever.co/Betstamp?department=PT%20Roles&team=Ambassadors"
          style={{
            flex: 1,
            margin: 'var(--space-sm)',
            color: 'var(--color-primary)',
            textDecoration: 'none',
          }}
        >
          View Ambassador Openings
        </a>
      </Row>
      <SectionHeading title="Support" />
      <Row
        style={{
          padding: 'var(--space-md)',
          justifyContent: 'space-around',
        }}
      >
        <Link to="/tutorial-all" style={{ textDecoration: 'none' }}>
          <b>Tutorials&nbsp;</b>
        </Link>
        <Link to="/faq" style={{ textDecoration: 'none' }}>
          <b>FAQ&nbsp;</b>
        </Link>
        <Link to="/terms-and-conditions" style={{ textDecoration: 'none' }}>
          <b>Terms and Conditions&nbsp;</b>
        </Link>
        <Link to="/privacy-policy" style={{ textDecoration: 'none' }}>
          <b>Privacy Policy&nbsp;</b>
        </Link>
      </Row>
      <Row
        style={{
          backgroundColor: 'var(--color-complement)',
          padding: 'var(--space-md)',
          justifyContent: 'flex-start',
          borderRadius: 'var(--std-border-radius)',
          color: 'black',
        }}
      >
        <b>Gambling Problem?&nbsp;</b>
        <Link to="/gambling-problem" style={{ color: 'black' }}>
          <small style={{ color: 'black' }}>
            Click here to find resources available in your region
          </small>
        </Link>{' '}
      </Row>

      <SectionHeading title="Contact Us" />
      <Row>
        <a
          href="mailto: contact@betstamp.app"
          style={{
            display: 'flex',
            flexFlow: 'column',
            alignItems: 'center',
            marginTop: 'var(--space-sm)',
            fontSize: 'var(--text-sm)',
          }}
        >
          <IoMailOutline
            title="betstamp email"
            style={{
              fontSize: 'var(--text-xxxl)',
              padding: 'var(--space-xs)  var(--space-sm)',
            }}
          />
          contact@betstamp.app
        </a>
        <a
          href="https://www.instagram.com/betstamp/"
          target="_blank"
          rel="noopener noreferrer"
          style={{
            display: 'flex',
            flexFlow: 'column',
            alignItems: 'center',
            marginTop: 'var(--space-sm)',
            fontSize: 'var(--text-sm)',
          }}
        >
          <IoLogoInstagram
            title="betstamp Instagram"
            style={{
              fontSize: 'var(--text-xxxl)',
              padding: 'var(--space-xs)  var(--space-sm)',
            }}
          />
          @betstamp
        </a>
        <a
          href="https://twitter.com/Betstamp"
          target="_blank"
          rel="noopener noreferrer"
          style={{
            display: 'flex',
            flexFlow: 'column',
            alignItems: 'center',
            marginTop: 'var(--space-sm)',
            fontSize: 'var(--text-sm)',
          }}
        >
          <IoLogoTwitter
            title="betstamp Twitter"
            style={{
              fontSize: 'var(--text-xxxl)',
              padding: 'var(--space-xs) var(--space-sm)',
            }}
          />
          @betstamp
        </a>
        <a
          href="https://vm.tiktok.com/ZMe9VSXm1/"
          target="_blank"
          rel="noopener noreferrer"
          style={{
            display: 'flex',
            flexFlow: 'column',
            alignItems: 'center',
            marginTop: 'var(--space-sm)',
            fontSize: 'var(--text-sm)',
          }}
        >
          <IoLogoTiktok
            title="betstamp Tik TOk"
            style={{
              fontSize: 'var(--text-xxxl)',
              padding: 'var(--space-xs) var(--space-sm)',
            }}
          />
          @betstamp
        </a>
        <a
          href="https://jobs.lever.co/Betstamp?"
          style={{
            display: 'flex',
            flexFlow: 'column',
            alignItems: 'center',
            marginTop: 'var(--space-sm)',
            fontSize: 'var(--text-sm)',
          }}
        >
          <IoGitNetworkOutline
            title="betstamp careers"
            style={{
              fontSize: 'var(--text-xxxl)',
              padding: 'var(--space-xs) var(--space-sm)',
            }}
          />
          jobs.lever.co/betstamp
        </a>
      </Row>
    </Col>
  );
}

function SectionHeading(props) {
  return (
    <h6
      style={{
        opacity: 0.7,
        marginTop: 'var(--space-lg)',
        marginBottom: 'var(--space-sm)',
        paddingVertical: 'var(--space-xxxs)',
        ...props.style,
      }}
    >
      {props.title}
    </h6>
  );
}
