import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import Analytics from 'amplitude/Analytics';

// components
import { Row, Col } from 'components/generic/Layout';
import Switch from 'components/generic/Switch';
import Select from 'components/generic/Select';
import DisplayToggle from 'components/generic/DisplayToggle';

// actions
import { setAppSetting, updateUser } from 'actions';

const RowWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: stretch;
  padding: var(--space-xs) 0;
`;

export function SwitchSetting(props) {
  const dispatch = useDispatch();
  return (
    <RowWrapper style={{ ...props.style }}>
      <Col style={{ alignItems: 'flex-start', justifyContent: 'center' }}>
        <span>{props.heading}</span>
        {props.description && <small>{props.description}</small>}
      </Col>
      <Col style={{ alignItems: 'flex-end', justifyContent: 'center' }}>
        <Switch
          defaultChecked={props.value}
          onChange={
            props.onValueChange
              ? input => props.onValueChange(input.target.checked)
              : input =>
                  dispatch(setAppSetting(props.setting, input.target.checked))
          }
        />
      </Col>
    </RowWrapper>
  );
}

export function DropdownSetting(props) {
  const dispatch = useDispatch();
  const reduxProps = useSelector(state => ({ user: state.authReducer.user }));
  const { user } = reduxProps;
  return (
    <RowWrapper>
      <Col style={{ alignItems: 'flex-start', justifyContent: 'center' }}>
        <span>{props.heading}</span>
        {props.description && <small>{props.description}</small>}
      </Col>
      <Col style={{ alignItems: 'flex-end', justifyContent: 'center' }}>
        <Select
          value={props.value}
          options={props.options}
          onChange={opt => {
            if (props.ampData) {
              Analytics.track(props.ampData.event, user?.id, {
                setting: props.setting,
                value: opt.value,
              });
            }
            dispatch(setAppSetting(props.setting, opt.value));
          }}
        />
      </Col>
    </RowWrapper>
  );
}

export function ToggleSetting(props) {
  const dispatch = useDispatch();
  const { map, active, options, setting } = props;
  return (
    <RowWrapper>
      <Col style={{ alignItems: 'flex-start', justifyContent: 'center' }}>
        <span>{props.heading}</span>
        {props.description && <small>{props.description}</small>}
      </Col>
      <Row style={{ flex: '1 0 300px' }}>
        <Col>
          <DisplayToggle
            style={{ width: '100%' }}
            options={options}
            active={map[active]}
            onPress={opt => {
              if (props.updateUser) {
                dispatch(updateUser({ [props.userSetting]: map[opt] }));
                return;
              }
              dispatch(setAppSetting(setting, map[opt]));
            }}
          />
        </Col>
        {props.children}
      </Row>
    </RowWrapper>
  );
}
