import React from 'react';
import styled from 'styled-components';

const Loader = styled.span`
  width: 100%;
  height: ${(props) => {
    if (props.height) {
      return props.height;
    }
    return '100%';
  }};
  display: block;
  position: relative;
  background-image: linear-gradient(
      100deg,
      transparent,
      var(--color-bg),
      transparent 80%
    ),
    linear-gradient(var(--color-text-light) 100%, transparent 0);

  background-repeat: no-repeat;

  background-size: 75px 100%, 100% 100%;

  background-position: 0% 0, 0% 0%;

  box-sizing: border-box;
  animation: animloadercontent ${(props) =>
    props.speed ? props.speed : '500ms'} linear infinite;

  @keyframes animloadercontent {
    0% {
      background-position: 0% 0, 0% 0%;
    }
    100% {
      background-position: 100% 0, 0% 0%;
  }
`;

export default function ContentLoader(props) {
  return <Loader {...props} />;
}
