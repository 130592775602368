import {
  GET_INFLUENCER_PAGE,
  GET_INFLUENCER_PAGE_SUCCESS,
  GET_INFLUENCER_PAGE_FAILURE,
} from 'actions';

const initialState = {
  influencerData: null,
  isLoadingInfluencer: false,
  influencerFailure: null,
};

export default function influencerReducer(state = initialState, action) {
  switch (action.type) {
    case GET_INFLUENCER_PAGE:
      return Object.assign({}, state, {
        isLoadingInfluencer: true,
        influencerFailure: null,
      });
    case GET_INFLUENCER_PAGE_SUCCESS:
      return Object.assign({}, state, {
        isLoadingInfluencer: false,
        influencerData: action.payload.data,
      });
    case GET_INFLUENCER_PAGE_FAILURE:
      return Object.assign({}, state, {
        isLoadingInfluencer: false,
        influencerFailure: action.error,
      });
    default:
      return state;
  }
}
