// actions (excluding success and failure actions):
export const LOGIN_USER = 'LOGIN_USER';
export const SOCIAL_LOGIN_USER = 'SOCIAL_LOGIN_USER';
export const SOCIAL_CONNECT_USER = 'SOCIAL_CONNECT_USER';
export const SOCIAL_DISCONNECT_USER = 'SOCIAL_DISCONNECT_USER';
export const LOGOUT_USER = 'LOGOUT_USER';
export const LOGOUT_ALL = 'LOGOUT_ALL';
export const CHECK_EMAIL_AVAILABLE = 'CHECK_EMAIL_AVAILABLE';
export const CHECK_USERNAME_AVAILABLE = 'CHECK_USERNAME_AVAILABLE';
export const CREATE_USER = 'CREATE_USER';
export const DEACTIVATE_USER = 'DEACTIVATE_USER';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const SEND_EMAIL_VERIFICATION = 'SEND_EMAIL_VERIFICATION';
export const RESET_ERRORS = 'RESET_ERRORS';
export const GET_USER = 'GET_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const ADD_PUSH_TOKEN = 'ADD_PUSH_TOKEN';
export const ADD_PACKAGE = 'ADD_PACKAGE';
export const RESET_ADD_PACKAGE_SUCCESS = 'RESET_ADD_PACKAGE_SUCCESS';
export const UPDATE_NOTIFICATION_PREFERENCES =
  'UPDATE_NOTIFICATION_PREFERENCES';
export const RESET_TOKEN = 'RESET_TOKEN';
export const UPDATE_PACKAGE = 'UPDATE_PACKAGE';
export const DELETE_PACKAGE = 'DELETE_PACKAGE';
export const GET_ALL_BOOKS = 'GET_ALL_BOOKS';
export const POST_BOOKS = 'POST_BOOKS';
export const CREATE_CONNECT_STATE = 'CREATE_CONNECT_STATE';
export const VERIFY_CONNECT_ACCOUNT = 'VERIFY_CONNECT_ACCOUNT';
export const RESET_VERIFY_CONNECT_ACCOUNT_SUCCESS =
  'RESET_VERIFY_CONNECT_ACCOUNT_SUCCESS';
export const SET_SEEN_ONBOARDING = 'SET_SEEN_ONBOARDING';
export const GET_STRIPE_EXPRESS_DASHBOARD_URL =
  'GET_STRIPE_EXPRESS_DASHBOARD_URL';
export const SET_SAVE_ON_EDIT_PROFILE_DISABLED =
  'SET_SAVE_ON_EDIT_PROFILE_DISABLED';
export const SET_USER_CHANGES = 'SET_USER_CHANGES';
export const SET_PROFILE_SETUP_PAGE = 'SET_PROFILE_SETUP_PAGE';
export const CHECK_REFERRAL_CODE_EXISTS = 'CHECK_REFERRAL_CODE_EXISTS';
export const CHANGE_EMAIL = 'CHANGE_EMAIL';
export const GET_TWITTER_REQUEST_TOKEN = 'GET_TWITTER_REQUEST_TOKEN';
export const GET_TWITTER_ACCESS_TOKEN = 'GET_TWITTER_ACCESS_TOKEN';
export const GET_ALL_BOOKS_MAP = 'GET_ALL_BOOKS_MAP';

// SIGN UP
export const SET_TEMP_USER_STATES = 'SET_TEMP_USER_STATES';

// LOGIN
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAILURE = 'LOGIN_USER_FAILURE';

// SIGN UP
export const setTempUserStates = payload => ({
  type: SET_TEMP_USER_STATES,
  payload,
});

export const loginUser = params => ({
  type: LOGIN_USER,
  params: params,
});

export const loginUserSuccess = payload => ({
  type: LOGIN_USER_SUCCESS,
  payload: payload,
});

export const loginUserFailure = error => ({
  type: LOGIN_USER_FAILURE,
  payload: error,
});

// LOGOUT USER
export const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS';
export const LOGOUT_USER_FAILURE = 'LOGOUT_USER_FAILURE';

export const logoutUser = () => ({
  type: LOGOUT_USER,
});

export const logoutUserSuccess = payload => ({
  type: LOGOUT_USER_SUCCESS,
  payload: payload,
});

export const logoutUserFailure = error => ({
  type: LOGOUT_USER_FAILURE,
  payload: error,
});

// LOGOUT ALL
export const LOGOUT_ALL_SUCCESS = 'LOGOUT_ALL_SUCCESS';
export const LOGOUT_ALL_FAILURE = 'LOGOUT_ALL_FAILURE';

export const logoutAll = () => ({
  type: LOGOUT_ALL,
});

export const logoutAllSuccess = payload => ({
  type: LOGOUT_ALL_SUCCESS,
  payload: payload,
});

export const logoutAllFailure = error => ({
  type: LOGOUT_ALL_FAILURE,
  payload: error,
});

// CREATE USER
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_FAILURE = 'CREATE_USER_FAILURE';

export const createUser = params => ({
  type: CREATE_USER,
  params,
});

export const createUserSuccess = payload => ({
  type: CREATE_USER_SUCCESS,
  payload: payload,
});

export const createUserFailure = error => ({
  type: CREATE_USER_FAILURE,
  payload: error,
});

// DEACTIVATE USER
export const DEACTIVATE_USER_SUCCESS = 'DEACTIVATE_USER_SUCCESS';
export const DEACTIVATE_USER_FAILURE = 'DEACTIVATE_USER_FAILURE';

export const deactivateUser = params => ({
  type: DEACTIVATE_USER,
  params,
});

export const deactivateUserSuccess = payload => ({
  type: DEACTIVATE_USER_SUCCESS,
  payload: payload,
});

export const deactivateUserFailure = error => ({
  type: DEACTIVATE_USER_FAILURE,
  payload: error,
});

// FORGOT PASSWORD
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAILURE = 'FORGOT_PASSWORD_FAILURE';

export const forgotPassword = params => ({
  type: FORGOT_PASSWORD,
  params,
});

export const forgotPasswordSuccess = payload => ({
  type: FORGOT_PASSWORD_SUCCESS,
  payload: payload,
});

export const forgotPasswordFailure = error => ({
  type: FORGOT_PASSWORD_FAILURE,
  payload: error,
});

// CHANGE PASSWORD
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE';

export const changePassword = params => ({
  type: CHANGE_PASSWORD,
  params,
});

export const changePasswordSuccess = payload => ({
  type: CHANGE_PASSWORD_SUCCESS,
  payload: payload,
});

export const changePasswordFailure = error => ({
  type: CHANGE_PASSWORD_FAILURE,
  payload: error,
});

// SEND EMAIL VERIFICATION
export const SEND_EMAIL_VERIFICATION_COMPLETE =
  'SEND_EMAIL_VERIFICATION_COMPLETE';

export const sendEmailVerification = () => ({
  type: SEND_EMAIL_VERIFICATION,
});

export const sendEmailVerificationComplete = () => ({
  type: SEND_EMAIL_VERIFICATION_COMPLETE,
});

export const CHECK_EMAIL_AVAILABLE_SUCCESS = 'CHECK_EMAIL_AVAILABLE_SUCCESS';
export const CHECK_EMAIL_AVAILABLE_FAILURE = 'CHECK_EMAIL_AVAILABLE_FAILURE';

export const checkEmailAvailable = email => ({
  type: CHECK_EMAIL_AVAILABLE,
  email: email,
});

export const checkEmailAvailableSuccess = payload => ({
  type: CHECK_EMAIL_AVAILABLE_SUCCESS,
  payload: payload,
});

export const checkEmailAvailableFailure = error => ({
  type: CHECK_EMAIL_AVAILABLE_FAILURE,
  payload: error,
});

export const CHECK_USERNAME_AVAILABLE_SUCCESS =
  'CHECK_USERNAME_AVAILABLE_SUCCESS';
export const CHECK_USERNAME_AVAILABLE_FAILURE =
  'CHECK_USERNAME_AVAILABLE_FAILURE';

export const checkUsernameAvailable = username => ({
  type: CHECK_USERNAME_AVAILABLE,
  username: username,
});

export const checkUsernameAvailableSuccess = payload => ({
  type: CHECK_USERNAME_AVAILABLE_SUCCESS,
  payload: payload,
});

export const checkUsernameAvailableFailure = error => ({
  type: CHECK_USERNAME_AVAILABLE_FAILURE,
  payload: error,
});

export const resetErrors = () => ({
  type: RESET_ERRORS,
});

// actions for doing things with the currently logged in in user
// ex. changing personal info
// GET USER
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAILURE = 'GET_USER_FAILURE';

export const getUser = () => ({
  type: GET_USER,
});

export const getUserSuccess = payload => ({
  type: GET_USER_SUCCESS,
  payload: payload,
});

export const getUserFailure = error => ({
  type: GET_USER_FAILURE,
  payload: error,
});

// UPDATE USER
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE';

export const updateUser = params => ({
  type: UPDATE_USER,
  params,
});

export const updateUserSuccess = payload => ({
  type: UPDATE_USER_SUCCESS,
  payload: payload,
});

export const updateUserFailure = error => ({
  type: UPDATE_USER_FAILURE,
  payload: error,
});

// ADD PUSH TOKEN
export const ADD_PUSH_TOKEN_SUCCESS = 'ADD_PUSH_TOKEN_SUCCESS';
export const ADD_PUSH_TOKEN_FAILURE = 'ADD_PUSH_TOKEN_FAILURE';

export const addPushToken = params => ({
  type: ADD_PUSH_TOKEN,
  params,
});

export const addPushTokenSuccess = payload => ({
  type: ADD_PUSH_TOKEN_SUCCESS,
  payload: payload,
});

export const addPushTokenFailure = error => ({
  type: ADD_PUSH_TOKEN_FAILURE,
  payload: error,
});

// ADD_PACKAGE
export const ADD_PACKAGE_SUCCESS = 'ADD_PACKAGE_SUCCESS';
export const ADD_PACKAGE_FAILURE = 'ADD_PACKAGE_FAILURE';

export const addPackage = userPackage => ({
  type: ADD_PACKAGE,
  userPackage: userPackage,
});

export const addPackageSuccess = payload => ({
  type: ADD_PACKAGE_SUCCESS,
  payload: payload,
});

export const addPackageFailure = error => ({
  type: ADD_PACKAGE_FAILURE,
  payload: error,
});

// RESET ADD PACKAGE SUCCESS
export const resetAddPackageSuccess = () => ({
  type: RESET_ADD_PACKAGE_SUCCESS,
});

// UPDATE_NOTIFICATION_PREFERENCES
export const UPDATE_NOTIFICATION_PREFERENCES_SUCCESS =
  'UPDATE_NOTIFICATION_PREFERENCES_SUCCESS';
export const UPDATE_NOTIFICATION_PREFERENCES_FAILURE =
  'UPDATE_NOTIFICATION_PREFERENCES_FAILURE';

export const updateNotificationPreferences = params => ({
  type: UPDATE_NOTIFICATION_PREFERENCES,
  params: params,
});

// UPDATE_NOTIFICATION_PREFERENCES_SUCCESS
export const updateNotificationPreferencesSuccess = payload => ({
  type: UPDATE_NOTIFICATION_PREFERENCES_SUCCESS,
  payload: payload,
});

// UPDATE_NOTIFICATION_PREFERENCES_FAILURE
export const updateNotificationPreferencesFailure = error => ({
  type: UPDATE_NOTIFICATION_PREFERENCES_FAILURE,
  payload: error,
});

// RESET TOKEN
export const resetToken = () => ({ type: RESET_TOKEN });

// UPDATE PACKAGE
export const UPDATE_PACKAGE_SUCCESS = 'UPDATE_PACKAGE_SUCCESS';
export const UPDATE_PACKAGE_FAILURE = 'UPDATE_PACKAGE_FAILURE';

export const updatePackage = (packageid, userPackage) => ({
  type: UPDATE_PACKAGE,
  userPackage,
  packageid,
});

export const updatePackageSuccess = payload => ({
  type: UPDATE_PACKAGE_SUCCESS,
  payload,
});

export const updatePackageFailure = error => ({
  type: UPDATE_PACKAGE_FAILURE,
  error,
});

// DELETE PACKAGE
export const DELETE_PACKAGE_SUCCESS = 'DELETE_PACKAGE_SUCCESS';
export const DELETE_PACKAGE_FAILURE = 'DELETE_PACKAGE_FAILURE';

export const deletePackage = packageid => ({
  type: DELETE_PACKAGE,
  packageid,
});

export const deletePackageSuccess = (payload, packageid) => ({
  type: DELETE_PACKAGE_SUCCESS,
  payload,
  packageid,
});

export const deletePackageFailure = error => ({
  type: DELETE_PACKAGE_FAILURE,
  error,
});

// GET_ALL BOOKS
export const GET_ALL_BOOKS_SUCCESS = 'GET_ALL_BOOKS_SUCCESS';
export const GET_ALL_BOOKS_FAILURE = 'GET_ALL_BOOKS_FAILURE';

export const getAllBooks = () => ({
  type: GET_ALL_BOOKS,
});

export const getAllBooksSuccess = payload => ({
  type: GET_ALL_BOOKS_SUCCESS,
  payload,
});

export const getAllBooksFailure = error => ({
  type: GET_ALL_BOOKS_FAILURE,
  error,
});

// POST BOOKS
export const POST_BOOKS_SUCCESS = 'POST_BOOKS_SUCCESS';
export const POST_BOOKS_FAILURE = 'POST_BOOKS_FAILURE';

export const postBooks = books => ({
  type: POST_BOOKS,
  books,
});

export const postBooksSuccess = payload => ({
  type: POST_BOOKS_SUCCESS,
  payload,
});

export const postBooksFailure = error => ({
  type: POST_BOOKS_FAILURE,
  error,
});

// CREATE CONNECT STATE
export const CREATE_CONNECT_STATE_SUCCESS = 'CREATE_CONNECT_STATE_SUCCESS';
export const CREATE_CONNECT_STATE_FAILURE = 'CREATE_CONNECT_STATE_FAILURE';

export const createConnectState = () => ({
  type: CREATE_CONNECT_STATE,
});

export const createConnectStateSuccess = payload => ({
  type: CREATE_CONNECT_STATE_SUCCESS,
  payload,
});

export const createConnectStateFailure = error => ({
  type: CREATE_CONNECT_STATE_FAILURE,
  error,
});

// CREATE CONNECT STATE
export const VERIFY_CONNECT_ACCOUNT_SUCCESS = 'VERIFY_CONNECT_ACCOUNT_SUCCESS';
export const VERIFY_CONNECT_ACCOUNT_FAILURE = 'VERIFY_CONNECT_ACCOUNT_FAILURE';

export const verifyConnectAccount = (code, state) => ({
  type: VERIFY_CONNECT_ACCOUNT,
  code,
  state,
});

export const verifyConnectAccountSuccess = payload => ({
  type: VERIFY_CONNECT_ACCOUNT_SUCCESS,
  payload,
});

export const verifyConnectAccountFailure = error => ({
  type: VERIFY_CONNECT_ACCOUNT_FAILURE,
  error,
});

export const resetVerifyConnectAccountSuccess = () => ({
  type: RESET_VERIFY_CONNECT_ACCOUNT_SUCCESS,
});

export const setSeenOnboarding = seenOnboarding => ({
  type: SET_SEEN_ONBOARDING,
  seenOnboarding,
});

export const GET_STRIPE_EXPRESS_DASHBOARD_URL_SUCCESS =
  'GET_STRIPE_EXPRESS_DASHBOARD_URL_SUCCESS';
export const GET_STRIPE_EXPRESS_DASHBOARD_URL_FAILURE =
  'GET_STRIPE_EXPRESS_DASHBOARD_URL_FAILURE';

export const getStripeExpressDashboardUrl = () => ({
  type: GET_STRIPE_EXPRESS_DASHBOARD_URL,
});

export const getStripeExpressDashboardUrlSuccess = payload => ({
  type: GET_STRIPE_EXPRESS_DASHBOARD_URL_SUCCESS,
  payload,
});

export const getStripeExpressDashboardUrlFailure = error => ({
  type: GET_STRIPE_EXPRESS_DASHBOARD_URL_FAILURE,
  error,
});

// for editing your profile
export const setUserChanges = changes => ({
  type: SET_USER_CHANGES,
  changes,
});

export const setProfileSetupChange = page => ({
  type: SET_PROFILE_SETUP_PAGE,
  page,
});

export const CHECK_REFERRAL_CODE_EXISTS_SUCCESS =
  'CHECK_REFERRAL_CODE_EXISTS_SUCCESS';
export const CHECK_REFERRAL_CODE_EXISTS_FAILURE =
  'CHECK_REFERRAL_CODE_EXISTS_FAILURE';

export const checkReferralCodeExists = code => ({
  type: CHECK_REFERRAL_CODE_EXISTS,
  code,
});

export const checkReferralCodeExistsSuccess = payload => ({
  type: CHECK_REFERRAL_CODE_EXISTS_SUCCESS,
  payload,
});

export const checkReferralCodeExistsFailure = error => ({
  type: CHECK_REFERRAL_CODE_EXISTS_FAILURE,
  error,
});

// CHANGE EMAIL
export const CHANGE_EMAIL_SUCCESS = 'CHANGE_EMAIL_SUCCESS';
export const CHANGE_EMAIL_FAILURE = 'CHANGE_EMAIL_FAILURE';

export const changeEmail = email => ({
  type: CHANGE_EMAIL,
  email,
});

export const changeEmailSuccess = payload => ({
  type: CHANGE_EMAIL_SUCCESS,
  payload: payload,
});

export const changeEmailFailure = error => ({
  type: CHANGE_EMAIL_FAILURE,
  payload: error,
});

// SOCIAL LOGIN
export const SOCIAL_LOGIN_USER_SUCCESS = 'SOCIAL_LOGIN_USER_SUCCESS';
export const SOCIAL_LOGIN_USER_FAILURE = 'SOCIAL_LOGIN_USER_FAILURE';

export const socialLoginUser = (
  provider,
  access_token,
  code,
  email,
  extras
) => ({
  type: SOCIAL_LOGIN_USER,
  provider,
  access_token,
  code,
  email,
  extras,
});

export const socialLoginUserSuccess = payload => ({
  type: SOCIAL_LOGIN_USER_SUCCESS,
  payload,
});

export const socialLoginUserFailure = error => ({
  type: SOCIAL_LOGIN_USER_FAILURE,
  error,
});

// SOCIAL LOGIN
export const SOCIAL_CONNECT_USER_SUCCESS = 'SOCIAL_CONNECT_USER_SUCCESS';
export const SOCIAL_CONNECT_USER_FAILURE = 'SOCIAL_CONNECT_USER_FAILURE';

export const socialConnectUser = (provider, access_token, code) => ({
  type: SOCIAL_CONNECT_USER,
  provider,
  access_token,
  code,
});

export const socialConnectUserSuccess = payload => ({
  type: SOCIAL_CONNECT_USER_SUCCESS,
  payload,
});

export const socialConnectUserFailure = error => ({
  type: SOCIAL_CONNECT_USER_FAILURE,
  error,
});

export const SOCIAL_DISCONNECT_USER_SUCCESS = 'SOCIAL_DISCONNECT_USER_SUCCESS';
export const SOCIAL_DISCONNECT_USER_FAILURE = 'SOCIAL_DISCONNECT_USER_FAILURE';

export const socialDisconnectUser = (provider, password, email) => ({
  type: SOCIAL_DISCONNECT_USER,
  provider,
  password,
  email,
});

export const socialDisconnectUserSuccess = payload => ({
  type: SOCIAL_DISCONNECT_USER_SUCCESS,
  payload,
});

export const socialDisconnectUserFailure = error => ({
  type: SOCIAL_DISCONNECT_USER_FAILURE,
  error,
});

// GET_TWITTER_REQUEST_TOKEN
export const GET_TWITTER_REQUEST_TOKEN_SUCCESS =
  'GET_TWITTER_REQUEST_TOKEN_SUCCESS';
export const GET_TWITTER_REQUEST_TOKEN_FAILURE =
  'GET_TWITTER_REQUEST_TOKEN_FAILURE';

export const getTwitterRequestToken = platform => ({
  type: GET_TWITTER_REQUEST_TOKEN,
  platform,
});

export const getTwitterRequestTokenSuccess = payload => ({
  type: GET_TWITTER_REQUEST_TOKEN_SUCCESS,
  payload,
});

export const getTwitterRequestTokenFailure = error => ({
  type: GET_TWITTER_REQUEST_TOKEN_FAILURE,
  error,
});

// GET_TWITTER_ACCESS_TOKEN
export const GET_TWITTER_ACCESS_TOKEN_SUCCESS =
  'GET_TWITTER_ACCESS_TOKEN_SUCCESS';
export const GET_TWITTER_ACCESS_TOKEN_FAILURE =
  'GET_TWITTER_ACCESS_TOKEN_FAILURE';

export const getTwitterAccessToken = (token, token_secret, verifier) => ({
  type: GET_TWITTER_ACCESS_TOKEN,
  token,
  token_secret,
  verifier,
});

export const getTwitterAccessTokenSuccess = payload => ({
  type: GET_TWITTER_ACCESS_TOKEN_SUCCESS,
  payload,
});

export const getTwitterAccessTokenFailure = error => ({
  type: GET_TWITTER_ACCESS_TOKEN_FAILURE,
  error,
});

// GET_ALL_BOOKS_MAP
export const GET_ALL_BOOKS_MAP_SUCCESS = 'GET_ALL_BOOKS_MAP_SUCCESS';
export const GET_ALL_BOOKS_MAP_FAILURE = 'GET_ALL_BOOKS_MAP_FAILURE';

export const getAllBooksMap = () => ({
  type: GET_ALL_BOOKS_MAP,
});
export const getAllBooksMapSuccess = payload => ({
  type: GET_ALL_BOOKS_MAP_SUCCESS,
  payload,
});
export const getAllBooksMapFailure = error => ({
  type: GET_ALL_BOOKS_MAP_FAILURE,
  error,
});
