import { ANALYSIS_FILTERS } from 'utils/ANALYSIS_CONSTANTS';
import formatDate from 'utils/formatDate';
/**
 *
 * @param {String} params URL params of the analysis
 * @returns {Object} Objects representing the analysis filters
 */
export default function paramsToFilterObject(
  params,
  prevFilter = { ...ANALYSIS_FILTERS }
) {
  let filters = { ...prevFilter };
  params = params.replace('?', '').split('&');
  params.forEach(param => {
    if (param === '') return;
    let kv = param.split('=');
    const filterName = kv[0];
    if (filterName in filterNameMap) {
      const data = {
        ...filters[filterNameMap[filterName]],
        value: kv[1],
        slug: filterSlugMap[filterName](kv[1]),
      };
      filters[filterNameMap[filterName]] = { ...data };
    }
  });
  return { ...filters };
}

// map of urls filter name (all lowercase) to object filter names
const filterNameMap = {
  yaxis: 'Yaxis',
  yaxis2: 'Yaxis2',
  xaxis: 'Xaxis',
  // calculations: 'Calculations',
  user: 'User',
  // group1: 'Group1',
  // group2: 'Group2',
  verified: 'Verified',
  books: 'Books',
  date_range: 'Date_Range',
  time_period: 'Time_Period',
  leagues: 'Leagues',
  bet_types: 'Bet_Types',
  tags: 'Tags',
  bet_status: 'Bet_Status',
  live_bets: 'Live_Bets',
  game_period: 'Game_Period',
};

// map of functions to derive slugs from values
const filterSlugMap = {
  yaxis: value => {
    return value.replace(/%20/g, ' ');
  },
  yaxis2: value => {
    return value.replace(/%20/g, ' ');
  },
  xaxis: value => {
    let slug = value
      .replace(/"|\[|%22|\]/g, '')
      .replace(/%20/g, ' ')
      .split(',');
    slug = slug.join(', ');
    if (slug === '[""]' || slug === '') {
      slug =
        'ROI, CLV, CLV Best Available, Net, Record, Volume, Average Bet Size, Sample Size';
    }
    return slug;
  },
  user: value => {
    return value.replace(/%20/g, ' ');
  },
  verified: value => {
    if (value) return 'All bets';
    return 'Verified Only';
  },
  books: value => {
    if (value === '[]') return 'All sportsbooks';
    let slug = value
      .replace(/"|\[|%22|\]/g, '')
      .replace(/%20/g, ' ')
      .split(',');
    let slugLength = slug.length;
    slug = slug.join(', ');
    if (slug === '[""]' || slug === '') slug = 'All';
    if (slugLength > 0) slug = `${slugLength} books selected`;
    return slug;
  },
  date_range: value => {
    if (value === '[null,null]') return 'All Time';
    // const jsonvalue = JSON.parse(value.replace(/\%20/g, ' '));
    const jsonvalue = value
      .replace(/"|\[|%22|\]/g, '')
      .replace(/%20/g, ' ')
      .split(',');

    const date_after = jsonvalue[0] === 'null' ? null : jsonvalue[0];
    const date_before = jsonvalue[1] === 'null' ? null : jsonvalue[1];
    let slug = '';
    if (date_after && date_before) {
      slug = formatDate(date_after) + ' - ' + formatDate(date_before);
    } else if (date_after) {
      slug = formatDate(date_after) + ' - End of Time';
    } else if (date_before) {
      slug = 'Beginning of Time - ' + formatDate(date_before);
    } else {
      slug = 'All Time';
    }
    return slug;
  },
  time_period: value => {
    if (!value || value === 'null') return 'All Time';
    if (parseInt(value) < 0) return 'Next ' + -1 * parseInt(value) + ' days';
    return 'Last ' + value + ' days';
  },
  leagues: value => {
    let slug = value
      .replace(/"|\[|%22|\]/g, '')
      .replace(/%20/g, ' ')
      .split(',');
    slug = slug.join(', ');
    if (slug === '[""]' || slug === '') slug = 'All';
    return slug;
  },
  bet_types: value => {
    let slug = value
      .replace(/"|\[|%22|\]/g, '')
      .replace(/%20/g, ' ')
      .split(',');
    slug = slug.join(', ');
    if (slug === '[""]' || slug === '') slug = 'All';
    return slug;
  },
  tags: value => {
    let slug = value
      .replace(/"|\[|%22|\]/g, '')
      .replace(/%20/g, ' ')
      .split(',');
    slug = slug.join(', ');
    if (slug === '[""]' || slug === '') slug = 'All';
    return slug;
  },
  live_bets: value => {
    value = value
      .split('_')
      .map(word => {
        return word[0].toUpperCase() + word.substr(1);
      })
      .join(' ');
    value += ' Bets';
    return value;
  },
  bet_status: value => {
    value = value
      .split('_')
      .map((word, i, arr) => {
        if (i === 0) {
          return word[0].toUpperCase() + word.substr(1);
        }

        if (i === 1 && (word === 'pending' || word === 'graded')) {
          return word[0].toUpperCase() + word.substr(1);
        }

        if (i === 1 && i === arr.length - 1) {
          return '(' + word[0].toUpperCase() + word.substr(1) + ')';
        }

        if (i === 1) {
          return '(' + word[0].toUpperCase() + word.substr(1) + ',';
        }

        if (i === arr.length - 1) {
          return word[0].toUpperCase() + word.substr(1) + ')';
        }

        return word[0].toUpperCase() + word.substr(1) + ',';
      })
      .join(' ');
    return value;
  },
  game_period: value => {
    let slug = value
      .replace(/"|\[|%22|\]/g, '')
      .replace(/%20/g, ' ')
      .split(',');
    slug = slug.join(', ');
    if (slug === '[""]' || slug === '') slug = 'All';
    return slug;
  },
};
