import { Observable } from 'rxjs';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import { ajax } from 'rxjs/observable/dom/ajax';
import { BACKEND_API_URL, getHeaders } from 'utils';

import { GET_FIND_BETS, getFindBetsSuccess, getFindBetsFailure } from 'actions';

export default function getFindBets(action$, state$) {
  return action$.ofType(GET_FIND_BETS).switchMap(action => {
    let url = BACKEND_API_URL + `api/find_bets/?`;

    if (action.book_ids) {
      url += `book_ids=${JSON.stringify(action.book_ids)}&`;
    }
    if (action.leagues) {
      url += `leagues=${JSON.stringify(action.leagues)}&`;
    }
    if (action.periods) {
      url += `periods=${JSON.stringify(action.periods)}&`;
    }
    if (action.date) {
      url += `date=${action.date.replace('+', '%2B')}&`;
    }
    if (action.odd_types) {
      url += `odd_types=${JSON.stringify(action.odd_types)}&`;
    }

    return ajax
      .getJSON(url, getHeaders(state$))
      .map(data => getFindBetsSuccess(data))
      .catch(error => Observable.of(getFindBetsFailure(error.xhr)));
  });
}
