import React from 'react';
import { useSelector } from 'react-redux';

// local images
import BetstampActivityIndicatorDay from 'images/gif/betstamp_activity_indicator_day.gif';
import BetstampActivityIndicatorNight from 'images/gif/betstamp_activity_indicator_night.gif';

const DEFAULT_GIF_SIZE = 24;
const ACTUAL_SIZES = {
  1: 24,
  1.5: 36,
  2: 50,
  3: 75,
  4: 100,
};

export default function ActivityIndicator(props) {
  const reduxProps = useSelector(state => ({
    theme: state.settingsReducer.theme,
  }));

  const { theme } = reduxProps;
  const { style, size, ...otherProps } = props;

  // For easier migration in the future
  const actualWidth = size ? ACTUAL_SIZES[size] : DEFAULT_GIF_SIZE;

  return (
    <img
      width={actualWidth}
      src={
        theme === 'dark' && !props.blue
          ? BetstampActivityIndicatorNight
          : BetstampActivityIndicatorDay
      }
      alt="Loading Content, Please Be Patient..."
      style={{
        display: 'block',
        margin: 'auto',
        ...style,
      }}
      {...otherProps}
    />
  );
}
