// ads on the accounting screen
export const GET_ADS = 'GET_ADS';

export const GET_ADS_SUCCESS = 'GET_ADS_SUCCESS';
export const GET_ADS_FAILURE = 'GET_ADS_FAILURE';

export const getAds = () => ({
  type: GET_ADS,
});

export const getAdsSuccess = (ads) => ({
  type: GET_ADS_SUCCESS,
  payload: ads,
});

export const getAdsFailure = (error) => ({
  type: GET_ADS_FAILURE,
  error,
});
