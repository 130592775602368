import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

// components
import { Row } from 'components/generic/Layout';
import ErrorDisplay from 'components/generic/ErrorDisplay';
import ProfilePic from 'components/generic/ProfilePic';
import { AuthButton } from 'components/AuthButton';

// actions
import { startPurchaseSession } from 'actions';

export default function BuyPackage(props) {
  const [redirecting, setRedirecting] = useState(false);
  const { selectedPackage } = props;

  const dispatch = useDispatch();
  const reduxProps = useSelector(state => ({
    isStartingPurchaseSession: state.stripeReducer.isStartingPurchaseSession,
    startPurchaseSessionError: state.stripeReducer.startPurchaseSessionError,
    startPurchaseSessionID: state.stripeReducer.startPurchaseSessionID,
  }));

  const {
    isStartingPurchaseSession,
    startPurchaseSessionError,
    startPurchaseSessionID,
  } = reduxProps;

  useEffect(() => {
    if (startPurchaseSessionID) {
      setRedirecting(true);
      window.location.href = `/complete-purchase/${startPurchaseSessionID}`;
    }
  }, [startPurchaseSessionID]);

  return (
    <>
      {selectedPackage && (
        <>
          <h5 style={{ fontWeight: 'bold', marginTop: 0 }}>
            {selectedPackage.title}
          </h5>
          <Link
            style={{
              textDecoration: 'none',
              display: 'flex',
              flexFlow: 'row wrap',
              alignItems: 'center',
              width: 'fit-content',
            }}
            to={`/u/${selectedPackage.package_owner}`}
          >
            <ProfilePic
              user={{
                username: selectedPackage.package_owner,
                profile_picture: selectedPackage.package_owner_profile_pic,
              }}
            />
            <span>&nbsp;{selectedPackage.package_owner}</span>
          </Link>
          <br />
          <h6>
            ${selectedPackage.cost} per {selectedPackage.billing_freq}
          </h6>

          <p>{selectedPackage.description}</p>

          {startPurchaseSessionError ? (
            <>
              <ErrorDisplay
                basic
                error={startPurchaseSessionError}
                message={'creating this purchase session'}
              />
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <AuthButton
                  colorTheme="danger"
                  containerStyle={{
                    maxWidth: '50%',
                  }}
                  disabled={isStartingPurchaseSession || redirecting}
                  onPress={() => props.dismissModal(false)}
                >
                  Cancel
                </AuthButton>
              </div>
            </>
          ) : (
            <Row style={{ marginTop: 'var(--space-sm)' }}>
              <AuthButton
                colorTheme="danger"
                containerStyle={{
                  flex: '1 0 190px',
                  margin: 'var(--space-xs) 0',
                  marginRight: 'var(--space-sm)',
                }}
                disabled={isStartingPurchaseSession || redirecting}
                onPress={() => props.dismissModal(false)}
              >
                Cancel
              </AuthButton>
              <AuthButton
                containerStyle={{
                  flex: '1 0 190px',
                  marginLeft: 'var(--space-sm)',
                }}
                isLoading={isStartingPurchaseSession || redirecting}
                onPress={() =>
                  dispatch(
                    startPurchaseSession({
                      package: selectedPackage.id,
                    })
                  )
                }
              >
                Checkout
              </AuthButton>
            </Row>
          )}
        </>
      )}
    </>
  );
}
