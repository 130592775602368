// actions (excluding success and failure actions):
export const GET_PURCHASE_SESSION = 'GET_PURCHASE_SESSION';
// unfortunately, create purchase session was used for creating a checkout session
// this is very confusing, I know
export const START_PURCHASE_SESSION = 'START_PURCHASE_SESSION';
export const CREATE_SUBSCRIPTION = 'CREATE_SUBSCRIPTION';
export const VIEW_PURCHASES = 'VIEW_PURCHASES';
export const CANCEL_SUBSCRIPTION = 'CANCEL_SUBSCRIPTION';
export const GET_PURCHASE = 'GET_PURCHASE';
export const UPDATE_PAYMENT_METHOD = 'UPDATE_PAYMENT_METHOD';
export const RESET_PAYMENT_METHOD_SUCCESS = 'RESET_PAYMENT_METHOD_SUCCESS';
export const RESET_STRIPE_ERRORS = 'RESET_STRIPE_ERRORS';

// GET_PURCHASE_SESSION
export const GET_PURCHASE_SESSION_SUCCESS = 'GET_PURCHASE_SESSION_SUCCESS';
export const GET_PURCHASE_SESSION_FAILURE = 'GET_PURCHASE_SESSION_FAILURE';
export const getPurchaseSession = (id) => ({
  type: GET_PURCHASE_SESSION,
  id,
});

export const getPurchaseSessionSuccess = (payload) => ({
  type: GET_PURCHASE_SESSION_SUCCESS,
  payload,
});

export const getPurchaseSessionFailure = (error) => ({
  type: GET_PURCHASE_SESSION_FAILURE,
  error,
});

// START PURCHASE SESSION
export const START_PURCHASE_SESSION_SUCCESS = 'START_PURCHASE_SESSION_SUCCESS';
export const START_PURCHASE_SESSION_FAILURE = 'START_PURCHASE_SESSION_FAILURE';
export const startPurchaseSession = (purchaseSession) => ({
  type: START_PURCHASE_SESSION,
  purchaseSession,
});

export const startPurchaseSessionSuccess = (payload) => ({
  type: START_PURCHASE_SESSION_SUCCESS,
  payload,
});

export const startPurchaseSessionFailure = (error) => ({
  type: START_PURCHASE_SESSION_FAILURE,
  error,
});

// CREATE SUBSCRIPTION
export const CREATE_SUBSCRIPTION_SUCCESS = 'CREATE_SUBSCRIPTION_SUCCESS';
export const CREATE_SUBSCRIPTION_FAILURE = 'CREATE_SUBSCRIPTION_FAILURE';
export const createSubscription = (
  purchaseSessionID,
  paymentMethodID,
  subscriptionID
) => ({
  type: CREATE_SUBSCRIPTION,
  purchaseSessionID,
  paymentMethodID,
  subscriptionID,
});

export const createSubscriptionSuccess = (payload) => ({
  type: CREATE_SUBSCRIPTION_SUCCESS,
  payload,
});

export const createSubscriptionFailure = (error) => ({
  type: CREATE_SUBSCRIPTION_FAILURE,
  error,
});

// VIEW PURCHASES
export const VIEW_PURCHASES_SUCCESS = 'VIEW_PURCHASES_SUCCESS';
export const VIEW_PURCHASES_FAILURE = 'VIEW_PURCHASES_FAILURE';
export const VIEW_PURCHASES_NEXT = 'VIEW_PURCHASES_NEXT';

export const viewPurchases = () => ({
  type: VIEW_PURCHASES,
});

export const viewPurchasesSuccess = (payload) => ({
  type: VIEW_PURCHASES_SUCCESS,
  payload: payload,
});

export const viewPurchasesFailure = (error) => ({
  type: VIEW_PURCHASES_FAILURE,
  payload: error,
});

export const viewPurchasesNext = () => ({
  type: VIEW_PURCHASES_NEXT,
});

// CANCEL_SUBSCRIPTION
export const CANCEL_SUBSCRIPTION_SUCCESS = 'CANCEL_SUBSCRIPTION_SUCCESS';
export const CANCEL_SUBSCRIPTION_FAILURE = 'CANCEL_SUBSCRIPTION_FAILURE';
export const cancelSubscription = (purchaseid, purchase) => ({
  type: CANCEL_SUBSCRIPTION,
  purchaseid,
  purchase,
});

export const cancelSubscriptionSuccess = (payload) => ({
  type: CANCEL_SUBSCRIPTION_SUCCESS,
  payload,
});

export const cancelSubscriptionFailure = (error) => ({
  type: CANCEL_SUBSCRIPTION_FAILURE,
  error,
});

// GET_PURCHASE
export const GET_PURCHASE_SUCCESS = 'GET_PURCHASE_SUCCESS';
export const GET_PURCHASE_FAILURE = 'GET_PURCHASE_FAILURE';
export const getPurchase = (purchaseid) => ({
  type: GET_PURCHASE,
  purchaseid,
});

export const getPurchaseSuccess = (payload) => ({
  type: GET_PURCHASE_SUCCESS,
  payload,
});

export const getPurchaseFailure = (error) => ({
  type: GET_PURCHASE_FAILURE,
  error,
});

// UPDATE_PAYMENT_METHOD
export const UPDATE_PAYMENT_METHOD_SUCCESS = 'UPDATE_PAYMENT_METHOD_SUCCESS';
export const UPDATE_PAYMENT_METHOD_FAILURE = 'UPDATE_PAYMENT_METHOD_FAILURE';
export const updatePaymentMethod = (sub_id, pm_id) => ({
  type: UPDATE_PAYMENT_METHOD,
  sub_id,
  pm_id,
});

export const updatePaymentSuccess = (payload) => ({
  type: UPDATE_PAYMENT_METHOD_SUCCESS,
  payload,
});

export const updatePaymentFailure = (error) => ({
  type: UPDATE_PAYMENT_METHOD_FAILURE,
  error,
});

export const resetUpdatePMSuccess = () => ({
  type: RESET_PAYMENT_METHOD_SUCCESS,
});

// RESET_STRIPE_ERRORS
export const resetStripeErrors = () => ({ type: RESET_STRIPE_ERRORS });
