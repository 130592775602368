import { Observable } from 'rxjs';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import { ajax } from 'rxjs/observable/dom/ajax';
import { BACKEND_API_URL, getHeaders } from 'utils';

import { GET_PURCHASE, getPurchaseSuccess, getPurchaseFailure } from 'actions';

export default function getPurchase(action$, state$) {
  return action$.ofType(GET_PURCHASE).switchMap(action => {
    return ajax
      .getJSON(
        BACKEND_API_URL + 'api/purchases/' + action.purchaseid + '/',
        getHeaders(state$)
      )
      .map(data => getPurchaseSuccess(data))
      .catch(error => Observable.of(getPurchaseFailure(error.xhr)));
  });
}
