import { Observable } from 'rxjs';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import { ajax } from 'rxjs/observable/dom/ajax';
import { BACKEND_API_URL, getHeaders } from 'utils';

import {
  CHECK_USERNAME_AVAILABLE,
  checkUsernameAvailableSuccess,
  checkUsernameAvailableFailure,
} from 'actions';

export default function checkUsernameAvailable(action$, state$) {
  return action$.ofType(CHECK_USERNAME_AVAILABLE).switchMap(action => {
    return ajax
      .get(
        BACKEND_API_URL + `api/check_username/?username=${action.username}`,
        getHeaders(state$)
      )
      .map(res => checkUsernameAvailableSuccess(res))
      .catch(error => Observable.of(checkUsernameAvailableFailure(error.xhr)));
  });
}
