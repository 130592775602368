import styled from 'styled-components';

export const Table = styled.table`
  width: 100%;
  min-width: 1200px;
  /*table-layout: fixed;*/
`;

export const Thead = styled.thead`
  position: sticky;
  top: 64px;
  background: var(--color-bg);
`;

export const Th = styled.th`
  text-transform: uppercase;
  font-weight: semibold;
  text-align: left;
  padding: var(--space-xs) var(--space-md);
  opacity: 0.8;
  border-bottom: 1px solid var(--color-text-light);
  border-top: 2px solid var(--color-text-light);
`;

export const Tbody = styled.tbody`
  > tr > td {
    padding: var(--space-sm) var(--space-md);
    border-bottom: 1px solid var(--color-text-light);
  }
  > tr {
    transition: all var(--std-transition);
    cursor: pointer;
  }
  > tr:hover {
    background-color: var(--color-text-light);
  }
`;
