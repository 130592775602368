import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { IoMail } from 'react-icons/io5';

// components
import { BetstampWordWhiteText } from 'components/generic/Logos';
import { Col } from 'components/generic/Layout';
import { LinkButton } from 'components/AuthButton';
import GoogleAuthButton from 'components/GoogleAuthButton';
import AppleAuthButton from 'components/AppleAuthButton';
import TwitterAuthButton from 'components/TwitterAuthButton';

// actions
import { getUser } from 'actions';

const Wrapper = styled.div`
  min-height: 100vh;
  background-color: var(--color-primary);
  color: white;
  flex: 1;
  display: flex;
  flex-flow: column wrap;
  justify-content: space-around;
  align-items: center;
`;

export default function Login() {
  const history = useHistory();
  const dispatch = useDispatch();
  const reduxProps = useSelector(state => ({
    userToken: state.authReducer.userToken,
    theme: state.settingsReducer.theme,
  }));
  const { userToken, theme } = reduxProps;
  useEffect(() => {
    if (userToken) {
      dispatch(getUser());
      history.replace('/games');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userToken]);
  return (
    <Wrapper>
      <Col
        style={{
          maxWidth: '375px',
          width: '100%',
          margin: '0 auto',
          justifyContent: 'flex-start',
          flex: 0,
          padding: '0 var(--space-xs)',
        }}
      >
        <BetstampWordWhiteText height={128} />

        <AppleAuthButton login />
        <GoogleAuthButton login />
        <TwitterAuthButton login />
        <LinkButton
          to="/login-email"
          linkStyle={{ width: '100%' }}
          btnStyle={{
            backgroundColor: 'var(--color-fg)',
            borderColor: 'inherit',
          }}
          textStyle={{
            flex: 0,
            marginLeft: 'var(--space-sm)',
            color:
              theme === 'dark' ? 'var(--color-text)' : 'var(--color-primary)',
          }}
          leftIcon={IoMail}
          // disabled={loginDisabled}
          // isLoading={isLoading}
          // onPress={_login}
          colorTheme="white"
        >
          Continue With Email
        </LinkButton>

        <LinkButton
          to="/signup"
          colorTheme="inverted"
          linkStyle={{ width: '100%' }}
          containerStyle={{ marginTop: 'var(--space-xxl)' }}
        >
          Signup Here
        </LinkButton>
        <div style={{ height: '128px' }} />
      </Col>
    </Wrapper>
  );
}
