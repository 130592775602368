import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col, Grid } from 'components/generic/Layout';
import { IoAddSharp, IoBook, IoChevronBack, IoAdd } from 'react-icons/io5';
import InfiniteScroll from 'react-infinite-scroll-component';

//components
import AccountingBook from 'components/AccountingBookBar';
import TransactionSummary from 'components/TransactionSummary';
import FAB from 'components/generic/FAB';
import ErrorDisplay from 'components/generic/ErrorDisplay';
import Switcher from 'components/generic/SwitchSelector';
import ModalWrapper from 'components/generic/ModalWrapper';
import ActivityIndicator from 'components/generic/ActivityIndicator';
import ContentLoader from 'components/generic/ContentLoader';
import Books from 'components/modals/Books';
import Wager from 'components/Wager';
import Transaction from 'components/Transaction';
import AffiliateLink from 'components/AffiliateLink';
import Select from 'components/generic/Select';
import { AuthButton } from 'components/AuthButton';
import BGA from 'images/begambleaware.png';
import BGAWhite from 'images/begambleaware_white.png';

// modals
import AddUnsupported from 'components/modals/AddUnsupported';

// actions
import {
  getTransactionSummary,
  getAllBooks,
  getAffiliates,
  setAccountingBook,
  postBooks,
  getWagers,
  getWagersNext,
  getTransactions,
  getTransactionsNext,
  resetTransactionEditDel,
  setSortBy,
} from 'actions';

const PanelWrapper = styled.div`
  height: 100vh;
  flex: 1;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  overflow: hidden;
`;

const AllBooksPanel = styled.div`
  flex: 1 0 300px;
  box-sizing: border-box;
  // padding: 0 var(--space-sm);
  height: 100%;
  overflow-y: hidden;
  display: ${props => {
    if (props.accountingBook) {
      return 'none';
    }
    return 'flex';
  }};
  flex-flow: column wrap;
  justify-content: flex-start;
  align-items: stretch;
  background: var(--color-bg);

  @media only screen and (min-width: 960px) {
    display: flex;
  }
`;

const OneBookPanel = styled.div`
  flex: 1 0 300px;
  box-sizing: border-box;
  // padding: 0 var(--space-sm);
  height: 100%;
  overflow-y: hidden;
  display: ${props => {
    if (props.accountingBook) {
      return 'flex';
    }
    return 'none';
  }};
  flex-flow: column wrap;
  justify-content: flex-start;
  align-items: stretch;
  background: var(--color-bg);

  & > .halfscreen-mode-back-button {
    display: none;
  }

  @media only screen and (min-width: 960px) {
    display: flex;
  }

  @media only screen and (max-width: 960px) {
    & > .halfscreen-mode-back-button {
      display: flex;
    }
  }
`;

const ScrollableArea = styled.div`
  flex: 1;
  overflow: auto;
  box-sizing: border-box;
  padding: 0 var(--space-xs);
  padding-bottom: var(--space-xxxxl);
`;

export default function Accounting() {
  const [modalVisible, setModalVisible] = useState(false);
  const [transactionFilter, setTransactionFilter] = useState('Wagers');

  const reduxProps = useSelector(state => ({
    allBooks: state.authReducer.allBooks,
    affiliates: state.affiliatesReducer.affiliates,
    user: state.authReducer.user,
    wagers: state.wagerReducer.wagers['accounting'],
    isWagersLoading: state.wagerReducer.isWagersLoading['accounting'],
    wagersError: state.wagerReducer.wagersError['accounting'],
    wagersNext: state.wagerReducer.wagersNext['accounting'],
    transactionSummary: state.transactionReducer.transactionSummary,
    isLoadingTransactionSummary:
      state.transactionReducer.isLoadingTransactionSummary,
    accountingBook: state.transactionReducer.accountingBook,
    isLoadingTransactions: state.transactionReducer.isLoadingTransactions,
    transactions: state.transactionReducer.transactions,
    loadingTransactionsFailure:
      state.transactionReducer.loadingTransactionsFailure,
    transactionsNext: state.transactionReducer.transactionsNext,
    deleteTransactionSuccess: state.transactionReducer.deleteTransactionSuccess,
    deleteTransactionsFailure:
      state.transactionReducer.deleteTransactionsFailure,
    sortBy: state.transactionReducer.sortBy,
    theme: state.settingsReducer.theme,
  }));

  const dispatch = useDispatch();

  const {
    accountingBook,
    isLoadingTransactionSummary,
    affiliates,
    allBooks,
    transactionSummary,
    user,
    wagers,
    isWagersLoading,
    wagersError,
    wagersNext,
    isLoadingTransactions,
    transactions,
    loadingTransactionsFailure,
    transactionsNext,
    deleteTransactionSuccess,
    deleteTransactionsFailure,
    sortBy,
    theme,
  } = reduxProps;

  let age = '18+';
  let rg_url = 'https://www.gambleaware.org';
  let rg_string = 'www.gambleaware.org';
  if (user.state === 'Ontario') {
    age = '19+';
    rg_string = 'Connexontario.ca';
    rg_url = 'https://www.connexontario.ca/';
  }

  const _loadTransactions = () => {
    if (accountingBook) {
      dispatch(getWagers({ screen: 'accounting', book: accountingBook }));
      dispatch(getTransactions(accountingBook));
    }
  };

  useEffect(() => {
    if (deleteTransactionSuccess) {
      dispatch(getTransactionSummary(sortBy));
      dispatch(resetTransactionEditDel());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteTransactionSuccess]);

  useEffect(() => {
    _loadTransactions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountingBook]);

  useEffect(() => {
    refreshData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function refreshData() {
    dispatch(getTransactionSummary());
    if (!allBooks || allBooks.length === 0) dispatch(getAllBooks());
    dispatch(getAffiliates());
  }

  const _getTransactionsNext = () => {
    if (transactionFilter === 'Transactions' && transactionsNext) {
      dispatch(getTransactionsNext());
    }
    if (transactionFilter === 'Wagers' && wagersNext) {
      dispatch(getWagersNext('accounting'));
    }
  };

  const bookData = Object.keys(transactionSummary).map(key => ({
    book: key,
    ...transactionSummary[key],
  }));

  const [hideBooks, setHideBooks] = useState(true);
  const [booksModalVisibile, setBooksModalVisible] = useState(false);

  if (loadingTransactionsFailure || wagersError) {
    return (
      <div style={{ flex: 1, backgroundColor: 'var(--color-bg)' }}>
        <ErrorDisplay
          error={loadingTransactionsFailure || wagersError}
          message={'getting your wagers or transactions'}
          retry={_loadTransactions}
        />
      </div>
    );
  }

  return (
    <>
      <Books
        showModal={booksModalVisibile}
        dismissModal={() => setBooksModalVisible(false)}
        initialBooks={user.books}
        returnSelectedBooks={selectedBooks => {
          dispatch(
            postBooks(
              selectedBooks.map(b => {
                if (typeof b.id !== 'number') {
                  return b;
                }
                return b.id;
              })
            )
          );
          setBooksModalVisible(false);
        }}
      />

      {modalVisible && (
        <ModalWrapper
          modalIsOpen={modalVisible}
          onClose={() => setModalVisible(false)}
          onRequestClose={() => setModalVisible(false)}
          title="Add Transaction"
        >
          <AddUnsupported
            mode="transaction"
            currentBook={accountingBook}
            hideModal={refresh => {
              setModalVisible(false);
              dispatch(resetTransactionEditDel());
              if (refresh) {
                refreshData();
              }
            }}
          />
        </ModalWrapper>
      )}
      <PanelWrapper>
        <AllBooksPanel accountingBook={accountingBook}>
          <div style={{ flex: 0 }}>
            <TransactionSummary
              bookSummary={transactionSummary._x_TOTAL$}
              isLoading={isLoadingTransactionSummary}
              kFormat
            />
            <Row
              style={{
                alignItems: 'center',
                justifyContent: 'space-between',
                minHeight: '42px',
                padding: '0 var(--space-sm)',
              }}
            >
              <Col style={{ alignItems: 'flex-start' }}>
                <h6 style={{ margin: 0 }}>Sportsbooks</h6>
              </Col>

              <Col>
                <Select
                  value={{
                    value: sortBy,
                    label: sortBy
                      ? sortBy.charAt(0).toUpperCase() + sortBy.slice(1)
                      : '',
                  }}
                  options={[
                    { label: 'Total', value: 'total' },
                    { label: 'Book', value: 'book' },
                  ]}
                  onChange={selectedOption => {
                    dispatch(setSortBy(selectedOption.value));
                    dispatch(getTransactionSummary(selectedOption.value));
                  }}
                />
              </Col>
              <Col style={{ alignItems: 'flex-end' }}>
                <AuthButton
                  btnTheme="borderless"
                  containerStyle={{
                    maxWidth: '11rem',
                  }}
                  btnStyle={{
                    // Add Sportsbook and the plus icon are not aligned vertically
                    alignItems: 'end',
                  }}
                  leftIcon={IoAdd}
                  leftIconSize={18}
                  onPress={() => setBooksModalVisible(true)}
                >
                  Add Sportsbook
                </AuthButton>
              </Col>
            </Row>
          </div>
          <ScrollableArea>
            {isLoadingTransactionSummary ? (
              <>
                {[1, 2, 3, 4].map(i => (
                  <ContentLoader
                    key={`content-loader-accounting-${i}`}
                    height={'65px'}
                    style={{ margin: 'var(--space-sm) 0' }}
                  />
                ))}
              </>
            ) : (
              <>
                {bookData.slice(0, hideBooks ? 5 : undefined).map(item => {
                  if (item.book === '_x_TOTAL$') return null;
                  return (
                    <AccountingBook
                      key={`accounting-book-summary-list-${item.book}`}
                      bookSummary={item}
                      onClick={() => {
                        dispatch(resetTransactionEditDel());
                        dispatch(setAccountingBook(item.book));
                      }}
                    />
                  );
                })}
              </>
            )}
            {bookData.length > 5 && (
              <AuthButton
                btnTheme="borderless"
                containerStyle={{
                  maxWidth: 'fit-content',
                }}
                onPress={() => setHideBooks(!hideBooks)}
              >
                {hideBooks
                  ? `Show ${bookData.length - 5} More Books`
                  : 'Hide Books'}
              </AuthButton>
            )}
            <br />
            {affiliates.length > 0 && (
              <>
                <br />
                <br />
                <h6>Open More Sportsbooks</h6>
                <Grid
                  style={{
                    gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
                  }}
                >
                  {affiliates.map(book => (
                    <AffiliateLink
                      book={book}
                      fromPage="Accounting Web"
                      shape="bar"
                      key={book.name}
                    />
                  ))}
                </Grid>
                <p>
                  Opening
                  <b> as many sportsbook accounts </b>
                  as possible guarantees you get the best odds and the biggest
                  bonuses.
                </p>
                <p>
                  <i>
                    When you sign up by clicking above, betstamp gets a referral
                    fee and you get the signup bonus. This helps us improve the
                    app and keep it free to use.
                  </i>
                </p>
                <p>
                  <b>Thanks for your support!</b>
                </p>

                <br />

                <Row
                  style={{ alignItems: 'center', marginTop: 'var(--space-md)' }}
                >
                  <Col
                    style={{
                      flex: '0 0 42px',
                      width: '42px',
                      height: '42px',
                      border: '2px solid var(--color-danger)',
                      borderRadius: '42px',
                      fontWeight: 'bold',
                      marginRight: 'var(--space-xs)',
                    }}
                  >
                    {age}
                  </Col>

                  <span style={{ flex: 1 }}>
                    Please Gamble Responsibly. Visit{' '}
                    <a href={rg_url}>{rg_string}</a> for more info.
                  </span>
                </Row>
                {user.state != 'Ontario' &&
                  (theme === 'dark' ? (
                    <img
                      style={{ height: '32px', width: 'auto' }}
                      src={BGAWhite}
                      alt="Be Gamble Aware"
                    />
                  ) : (
                    <img
                      style={{ height: '32px', width: 'auto' }}
                      src={BGA}
                      alt="Be Gamble Aware"
                    />
                  ))}
              </>
            )}
          </ScrollableArea>
        </AllBooksPanel>

        <OneBookPanel accountingBook={accountingBook}>
          <div className={'halfscreen-mode-back-button'}>
            <AuthButton
              colorTheme="text"
              btnTheme="borderless"
              overrideChildren
              containerStyle={{
                maxWidth: 'max-content',
              }}
              onPress={() => dispatch(setAccountingBook(null))}
            >
              <Row style={{ alignItems: 'center', justifyContent: 'center' }}>
                <IoChevronBack size={22} color="var(--color-text)" />
                <span
                  style={{ color: 'var(--color-text)', fontWeight: 'bold' }}
                >
                  Back to All Books
                </span>
              </Row>
            </AuthButton>
          </div>
          <div style={{ flex: 0 }}>
            {deleteTransactionsFailure && (
              <ErrorDisplay
                error={deleteTransactionsFailure}
                message={'deleting that transaction.'}
              />
            )}

            {accountingBook && (
              <>
                <TransactionSummary
                  bookSummary={transactionSummary[accountingBook]}
                  isLoading={isLoadingTransactionSummary}
                />
                <Row
                  style={{
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    minHeight: '42px',
                    padding: '0 var(--space-sm)',
                  }}
                >
                  <h6 style={{ margin: 0 }}>{accountingBook}</h6>
                  <Row style={{ justifyContent: 'flex-end' }}>
                    <Switcher
                      options={['Wagers', 'Transactions']}
                      active={transactionFilter}
                      changeMode={setTransactionFilter}
                    />
                  </Row>
                </Row>
              </>
            )}
          </div>

          {accountingBook ? (
            <ScrollableArea id="infinite-scroll-accounting">
              <InfiniteScroll
                scrollableTarget={'infinite-scroll-accounting'}
                scrollThreshold={0.66}
                next={() => _getTransactionsNext()}
                hasMore={
                  transactionFilter === 'Wagers' ? wagersNext : transactionsNext
                }
                dataLength={
                  transactionFilter === 'Wagers'
                    ? wagers.length
                    : transactions.length
                }
                loader={
                  <ActivityIndicator
                    size={1.5}
                    style={{ margin: 'var(--space-xxs) auto' }}
                  />
                }
                style={{ overflow: 'hidden' }}
              >
                {isWagersLoading && (
                  <ActivityIndicator
                    size={2}
                    style={{ margin: 'var(--space-xxs) auto' }}
                  />
                )}

                {transactionFilter === 'Wagers' &&
                  !isWagersLoading &&
                  wagers.length === 0 && (
                    <p style={{ textAlign: 'center' }}>
                      This book has no wagers
                    </p>
                  )}

                {transactionFilter === 'Transactions' &&
                  !isLoadingTransactions &&
                  transactions.length === 0 && (
                    <p style={{ textAlign: 'center' }}>
                      This book has no transactions
                    </p>
                  )}

                {transactionFilter === 'Transactions' &&
                  transactions.map((item, index) => (
                    <Transaction
                      key={`view-accounting-book-item-${item.id}-${index}`}
                      transaction={item}
                    />
                  ))}
                {transactionFilter === 'Wagers' &&
                  wagers.map((wager, i) => (
                    <Wager
                      key={`accounting-wager-${wager.id}-${i}`}
                      wager={wager}
                    />
                  ))}
              </InfiniteScroll>
            </ScrollableArea>
          ) : (
            <Col style={{ justifyContent: 'center' }}>
              <IoBook size={96} />
              <h6>Click a sportsbook on the left to see more details</h6>
              <div style={{ height: '30vh' }} />
            </Col>
          )}
        </OneBookPanel>
      </PanelWrapper>
      <FAB onClick={() => setModalVisible(true)}>
        <IoAddSharp
          title="Add Transaction"
          style={{
            fontSize: 'var(--text-xxxxl)',
            marginBottom: '-4px',
          }}
        />
      </FAB>
    </>
  );
}
