import React, { useState } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { IoPencilOutline } from 'react-icons/io5';

// components
import { Col } from 'components/generic/Layout';
import { BetstampStampInverted } from 'components/generic/Logos';
import ModalWrapper from 'components/generic/ModalWrapper';
import SaveAnalysisPreset from 'components/modals/SaveAnalysisPreset';
import { IconButton } from 'components/AuthButton';

// actions
import { getAnalysis } from 'actions';

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  align-items: stretch;
  box-sizing: border-box;
  border: 1px solid var(--color-text-light);
  background-color: var(--color-fg);
  outline: none;
  // padding: var(--space-sm);
  margin: var(--space-xs);
  cursor: pointer;
  transition: all var(--std-transition);
  border-radius: var(--std-border-radius);
  color: var(--color-text);
  border: ${props => {
    if (props.selected) {
      return '1px solid var(--color-primary)';
    }
    return '1px solid var(--color-text-light)';
  }};

  &:hover {
    box-shadow: 0 2px 8px 0 var(--color-shadow);
  }
  &:active {
    background-color: var(--color-bg);
  }
  @keyframes FadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  animation-name: FadeIn;
  animation-duration: 333ms;
  animation-timing-function: ease-in-out;
`;

const StyledPresetBtn = styled.button`
  flex: 1;
  width: 100%;
  box-sizing: border-box;
  border: none;
  background-color: var(--color-fg);
  outline: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  transition: all var(--std-transition);
  color: var(--color-text);
  padding: var(--space-sm);
  border-radius: var(--std-border-radius);
  &:active {
    background-color: var(--color-bg);
  }
`;

export default function PresetBtn(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const reduxProps = useSelector(state => ({
    selectedPresetIndex: state.analysisReducer.selectedPresetIndex,
    filters: state.analysisReducer.filters,
  }));
  const { selectedPresetIndex, filters } = reduxProps;

  const { preset, index } = props;

  const [showSavePresetModal, setShowSavePresetModal] = useState(false);

  return (
    <>
      {showSavePresetModal && (
        <ModalWrapper
          modalIsOpen={showSavePresetModal}
          onClose={() => setShowSavePresetModal(false)}
          onRequestClose={() => setShowSavePresetModal(false)}
          title={'Edit Analysis Preset'}
        >
          <SaveAnalysisPreset
            mode={'edit'}
            ID={preset.id}
            name={preset.name}
            desc={preset.description}
            dismissModal={() => setShowSavePresetModal(false)}
          />
        </ModalWrapper>
      )}
      <Wrapper selected={selectedPresetIndex === index} style={props.style}>
        <Col>
          <StyledPresetBtn
            {...props}
            onClick={() => {
              let url = preset.url_params;
              const analysingUser = filters.User.value;
              if (analysingUser) {
                url += `user=${analysingUser}&`;
              }

              history.push(`${url}`);
              dispatch(getAnalysis(url, 'table', false, index));
            }}
          >
            <Col style={{ alignItems: 'flex-start' }}>
              <h6 style={{ margin: 0, textAlign: 'left' }}>{preset.name}</h6>
              <span
                style={{
                  lineHeight: 1,
                  textAlign: 'left',
                  // whiteSpace: 'nowrap',
                  // overflow: "hidden",
                  // textOverflow: 'ellipsis',
                  // width: '200px',
                }}
              >
                {/* {preset.description || 'No description'} */}
                {preset.description
                  ? `${preset.description.substr(0, 38)}${
                      preset.description.length > 38 ? '...' : ''
                    }`
                  : 'No description'}
              </span>
            </Col>
          </StyledPresetBtn>
        </Col>
        <Col style={{ flex: 0 }}>
          {preset.user === null ? (
            <BetstampStampInverted
              width={22}
              height={22}
              style={{ margin: '0 var(--space-sm)' }}
            />
          ) : (
            <IconButton
              iconName={IoPencilOutline}
              containerStyle={{ margin: '0 var(--space-sm)' }}
              iconColor={'var(--color-text)'}
              iconSize={22}
              onPress={() => setShowSavePresetModal(true)}
            />
          )}
        </Col>
      </Wrapper>
    </>
  );
}
