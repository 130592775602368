import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// utils
import { isEmailValid } from 'utils';

// components
import { AuthButton } from 'components/AuthButton';
import { AuthTextInput } from 'components/AuthTextInput';
import GoogleAuthButton from 'components/GoogleAuthButton';
import AppleAuthButton from 'components/AppleAuthButton';
import TwitterAuthButton from 'components/TwitterAuthButton';
import {
  IoEyeOffOutline,
  IoEyeOutline,
  IoLockClosedOutline,
  IoMailOutline,
  IoCheckmarkCircleOutline,
  IoCloseCircleOutline,
} from 'react-icons/io5';
import ActivityIndicator from 'components/generic/ActivityIndicator';
import { Row, Col } from 'components/generic/Layout';

// actions
import {
  socialDisconnectUser,
  resetErrors,
  logoutAll,
  checkEmailAvailable,
} from 'actions';

const PROVIDERS = { google: 'Google', apple: 'Apple', twitter: 'Twitter' };

export default function ConnectedAccounts(props) {
  const reduxProps = useSelector(state => ({
    user: state.authReducer.user,
    isSocialAuthingUser: state.authReducer.isSocialAuthingUser,
  }));

  const { user, isSocialAuthingUser } = reduxProps;

  const [disconnectProvider, setDisconnectProvider] = useState(null);

  if (disconnectProvider) {
    return <DisconnectProvider provider={disconnectProvider} />;
  }

  const socProvider = user.social_accounts[0]?.provider;

  if (isSocialAuthingUser) {
    return (
      <div style={{ maxWidth: '420px', width: '100%', margin: 'auto' }}>
        <ActivityIndicator size={2} />
        <br />
        <h2 style={{ margin: 0, textAlign: 'center' }}>
          Connecting to your {PROVIDERS[socProvider]} account
        </h2>
      </div>
    );
  }

  return (
    <Col
      style={{
        justifyContent: 'flex-start',
        alignItems: 'stretch',
        ...props.style,
      }}
    >
      <Row
        style={{
          alignItems: 'center',
          justifyContent: 'space-between',
          position: 'sticky',
          top: 0,
          backgroundColor: 'var(--color-bg)',
          zIndex: 101,
          borderBottom: '1px solid #9b9b9b',
          padding: 'var(--space-sm) 0',
        }}
      >
        <h3 style={{ margin: 0, flex: 2 }}>Connected Accounts</h3>
      </Row>
      <Row style={{ maxWidth: '420px', margin: 'auto' }}>
        {user.social_accounts.length > 0 ? (
          <>
            <p>
              Your betstamp account is currrently connected to{' '}
              {PROVIDERS[socProvider]}. Use your {PROVIDERS[socProvider]}{' '}
              account to log in to betstamp. Click the button below to
              disconnect your {PROVIDERS[socProvider]} account from your
              betstamp account.
            </p>
            <AuthButton onPress={() => setDisconnectProvider(socProvider)}>
              Disconnect {PROVIDERS[socProvider]}
            </AuthButton>
          </>
        ) : (
          <>
            <p>
              Sign in to betstamp with a third party by using the buttons below.
              You will no longer be able to sign in with your current email and
              password.
            </p>
            <Col>
              <GoogleAuthButton connectExisting />
              <AppleAuthButton connectExisting />
              <TwitterAuthButton connectExisting />
            </Col>
          </>
        )}
      </Row>
    </Col>
  );
}

function DisconnectProvider(props) {
  const dispatch = useDispatch();
  const reduxProps = useSelector(state => ({
    user: state.authReducer.user,
    socialDisconnectSuccess: state.authReducer.socialDisconnectSuccess,
    isSocialDisconnectingUser: state.authReducer.isSocialDisconnectingUser,
    emailIsAvailable: state.authReducer.emailIsAvailable,
  }));
  const {
    user,
    socialDisconnectSuccess,
    isSocialDisconnectingUser,
    emailIsAvailable,
  } = reduxProps;

  const [showPassword, setShowPassword] = useState(false);
  const [valids, setValids] = useState({});
  const [forcePasswordInvalid, setForcePasswordInvalid] = useState(null);
  const [password, setPassword] = useState(null);
  const [forceEmailInvalid, setForceEmailInvalid] = useState(null);
  const [newEmail, setNewEmail] = useState(null);

  const _validatePassword = password => {
    const valid = password.length >= 8;
    setValids({ ...valids, password: valid });
    setPassword(password);
    if (valid && forcePasswordInvalid) {
      setForcePasswordInvalid(null);
    }
  };

  const _validateEmail = email => {
    email = email.trim();
    const valid = isEmailValid(email);
    setValids({ ...valids, email: valid });
    if (valid) {
      setForceEmailInvalid(null);
      dispatch(checkEmailAvailable(email));
    }
    setNewEmail(email);
  };

  let disconnectDisabled = !valids.password;
  if (user.email === null) {
    disconnectDisabled =
      disconnectDisabled || !valids.email || emailIsAvailable !== '';
  }

  useEffect(() => {
    if (socialDisconnectSuccess) {
      dispatch(resetErrors());
      dispatch(logoutAll());
    }
  }, [socialDisconnectSuccess]);

  const { provider } = props;

  return (
    <div style={{ maxWidth: '420px', width: '100%', margin: 'auto' }}>
      <h5>Disconnect {PROVIDERS[provider]}</h5>
      <p>
        Disconnecting {PROVIDERS[provider]} will not delete your betstamp
        account. You will still be able to login using {user.email} and the
        password you set below. You must set a password to disconnect your{' '}
        {PROVIDERS[provider]} account.
      </p>
      {user.email === null && (
        <AuthTextInput
          label="Email *"
          leftIcon={IoMailOutline}
          rightIcon={
            emailIsAvailable === '' && valids.email
              ? IoCheckmarkCircleOutline
              : IoCloseCircleOutline
          }
          rightIconColor={
            emailIsAvailable === '' && valids.email
              ? 'var(--color-success)'
              : 'var(--color-danger)'
          }
          errorText={emailIsAvailable || forceEmailInvalid}
          placeholder={'yourname@gmail.com'}
          textContentType={'emailAddress'}
          onChangeText={text => _validateEmail(text)}
          onEndEditing={() => {
            if (!valids.email) setForceEmailInvalid('Invalid email');
          }}
          value={newEmail}
          autoFocus={true}
          type="email"
        />
      )}
      <AuthTextInput
        label="New Password (min. 8 characters) *"
        leftIcon={IoLockClosedOutline}
        rightIcon={showPassword ? IoEyeOutline : IoEyeOffOutline}
        rightIconOnPress={() => setShowPassword(!showPassword)}
        rightIconColor={'var(--color-text)'}
        errorText={forcePasswordInvalid}
        placeholder={'Password'}
        type={showPassword ? 'text' : 'password'}
        passwordRules={'minlength: 8;'}
        onChangeText={text => _validatePassword(text)}
        value={password}
      />

      <AuthButton
        disabled={disconnectDisabled}
        isLoading={isSocialDisconnectingUser}
        onPress={() =>
          dispatch(socialDisconnectUser(provider, password, newEmail))
        }
      >
        Disconnect {PROVIDERS[provider]}
      </AuthButton>
    </div>
  );
}
