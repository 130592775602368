import { Observable } from 'rxjs';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/of';
import { ajax } from 'rxjs/observable/dom/ajax';
import { BACKEND_API_URL, getHeaders } from 'utils';
import { encode } from 'base-64';

import { LOGIN_USER, loginUserSuccess, loginUserFailure } from 'actions';

export default function loginUser(action$, state$) {
  return action$.ofType(LOGIN_USER).mergeMap(action => {
    let headers = getHeaders(state$);

    const base64auth = encode(
      action.params.email.toLowerCase() + ':' + action.params.password
    );

    headers.Authorization = `Basic ${base64auth}`;

    return ajax
      .post(BACKEND_API_URL + 'api/login/', {}, headers)
      .map(data => loginUserSuccess(data))
      .catch(error => Observable.of(loginUserFailure(error.xhr)));
  });
}
