import { Observable } from 'rxjs';
import 'rxjs/add/operator/exhaustMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import { ajax } from 'rxjs/observable/dom/ajax';
import { BACKEND_API_URL, getHeaders } from 'utils';

import {
  BETSLIP_CHANGE_BOOK_PARLAY,
  betslipChangeBookParlaySuccess,
  betslipChangeBookParlayFailure,
} from 'actions';

export default function betslipChangeBookParlay(action$, state$) {
  return action$.ofType(BETSLIP_CHANGE_BOOK_PARLAY).exhaustMap(action => {
    if (state$.value.betslipReducer.bets.length === 0) {
      return Observable.of(
        betslipChangeBookParlaySuccess({
          response: state$.value.betslipReducer.bets,
        })
      );
    }
    return ajax
      .post(
        BACKEND_API_URL + `api/changebook/`,
        {
          book: action.book.id,
          bets: state$.value.betslipReducer.bets,
        },
        getHeaders(state$)
      )
      .map(data => betslipChangeBookParlaySuccess(data))
      .catch(error => Observable.of(betslipChangeBookParlayFailure(error.xhr)));
  });
}
