export default {
  link_to_video: '',
  content: [
    {
      type: 'paragraph',
      text:
        'Betstamp’s custom betting accounting software makes it easy to manage multiple sportsbook accounts. This tool will help you manage your total bankroll, and let you know instantly when different sportsbooks may need a deposit or withdraw.',
    },
    {
      type: 'image',
      url:
        'https://betstamp-public.sfo2.cdn.digitaloceanspaces.com/tutorials/accounting/accounting-image-1.png',
    },
    {
      type: 'paragraph',
      text:
        'If you are fed up with the hassle of managing deposits, withdrawals, and balances at multiple sportsbooks, you need to try betstamp’s accounting software.',
    },
    {
      type: 'paragraph',
      text:
        'To start using the accounting software, add the sportsbooks that you have accounts with and currently bet at by clicking the plus button beside My Sportsbooks.',
    },
    {
      type: 'paragraph',
      text:
        'To get set up, first add your initial balance to the site. You should add an initial balance if you started using the betstamp app after already having some money in your account.',
    },
    {
      type: 'paragraph',
      text:
        'Once you track a bet at this sportsbook account, once the game is completed, the bet will automatically grade and your sportsbook balance will be reflected of that.',
    },
    {
      type: 'paragraph',
      text:
        'Tap the blue plus button to add a transaction at the bottom such as a deposit or a withdraw to or from this account.',
    },
    {
      type: 'paragraph',
      text:
        'Click on any sportsbook to see a list of all financial transactions in and out of this account.',
    },
  ],
};
