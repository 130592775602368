import React, { useMemo, useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';

// utils
import {
  REGULATED_STATES,
  MAX_SCREEN_HEIGHT_SIGNUP_ONBOARD,
  loadImageFirst,
} from 'utils';

// components
import {
  BlueBubble,
  OnboardingWrapper,
  BookListItem,
} from 'components/auth/onboarding';
import { Col, Row } from 'components/generic/Layout';
import { AuthButton } from 'components/AuthButton';
import ModalWrapper from 'components/generic/ModalWrapper';
import ActivityIndicator from 'components/generic/ActivityIndicator';

// actions
import { postBooks } from 'actions';

export default function ViewALLSB(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const reduxProps = useSelector(state => ({
    user: state.authReducer.user,
    affiliates: state.affiliatesReducer.affiliates,
  }));
  const { affiliates, user } = reduxProps;
  const { shouldOpenMoreBooks } = props;
  const headerRef = useRef(null);
  const [selectedBooks, setSelectedBooks] = useState({});
  const [selectSportsBook, setSelectSportsBook] = useState(null);
  const [booksBanners, setBooksBanners] = useState(null);

  useEffect(() => {
    if (user?.books) {
      const newBooks = user.books.reduce((obj, item) => {
        return {
          ...obj,
          [item.id]: item,
        };
      }, {});
      setSelectedBooks(newBooks);
    }
  }, [user.books]);

  useEffect(
    // Post selected books when users press back/forward browser button
    () =>
      history.listen(_ => {
        if (history.action === 'POP') {
          dispatch(postBooks(Object.values(selectedBooks)));
        }
      }),
    [selectedBooks]
  );

  const _toggleBook = item => {
    const newSelectedBooks = selectedBooks ? { ...selectedBooks } : {};
    if (item.id in newSelectedBooks) {
      delete newSelectedBooks[item.id];
    } else {
      newSelectedBooks[item.id] = item;
    }
    setSelectedBooks(newSelectedBooks);
  };

  const books = useMemo(() => {
    if (REGULATED_STATES[user.state]) {
      return affiliates.filter(
        b => b.states.includes(user.state) && !b.is_casino
      );
    }
    return affiliates.filter(b => !b.is_casino);
  }, [affiliates, user.state]);

  useEffect(() => {
    if (books?.length) {
      const promiseHandler = () =>
        setBooksBanners(books.map(book => _renderBook({ book })));

      loadImageFirst(books.map(book => book.open_banner)).then(
        promiseHandler,
        promiseHandler
      );
    }
  }, [books, selectedBooks]);

  const _renderBook = ({ book }) => (
    <BookListItem
      key={`viewAllSB-onboarding-${book.id}-${book.name}`}
      book={book}
      selected={book.id in selectedBooks}
      onPressCheckmark={() => {
        if (book.id in selectedBooks) {
          _toggleBook(book);
        } else {
          setSelectSportsBook(book);
        }
      }}
    />
  );

  const selectedBooksCount = Object.keys(selectedBooks).length;
  const headerHeight = headerRef?.current?.getBoundingClientRect().height;

  return (
    <OnboardingWrapper
      nextScreen="/onboarding/find-users"
      onNext={() => dispatch(postBooks(Object.values(selectedBooks)))}
      showSkip
    >
      {selectSportsBook && (
        <ModalWrapper
          modalIsOpen={Boolean(selectSportsBook)}
          onClose={() => {
            setSelectSportsBook(false);
          }}
          onRequestClose={() => {
            setSelectSportsBook(false);
          }}
          modalSize={'small'}
          title={'Confirmation'}
        >
          <Col
            style={{
              height: 'calc(100% - 30px)',
              alignItems: 'initial',
              paddingBottom: 'var(--space-sm)',
            }}
          >
            <span
              style={{
                display: 'flex',
                flex: 1,
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {`I have an account with ${selectSportsBook.name}`}
            </span>
            <Row style={{ flex: 0 }}>
              <AuthButton
                colorTheme="danger"
                onPress={() => setSelectSportsBook(false)}
              >
                Cancel
              </AuthButton>
              <AuthButton
                btnTheme="borderless"
                containerStyle={{
                  marginLeft: 'var(--space-sm)',
                }}
                onPress={() => {
                  _toggleBook(selectSportsBook);
                  setSelectSportsBook(false);
                }}
              >
                Confirm
              </AuthButton>
            </Row>
          </Col>
        </ModalWrapper>
      )}
      <Col style={{ flex: 0, width: '100%' }}>
        {shouldOpenMoreBooks ? (
          <BlueBubble
            style={{
              marginTop: 'var(--space-md)',
              marginBottom: 'var(--space-sm)',
              flex: 0,
            }}
          >
            <h5
              style={{
                marginTop: 'var(--space-md)',
                marginBottom: 'var(--space-md)',
                color: 'white',
              }}
              ref={headerRef}
            >
              You are {selectedBooksCount > 0 ? 'only' : ''} using{' '}
              {selectedBooksCount}{' '}
              {selectedBooksCount === 1 ? 'sportsbook' : 'sportsbooks'}. Opening
              an additional sportsbook increases your annual profit by $
              {Math.max(1000 - Math.max(selectedBooksCount - 2, 0) * 200, 200)}{' '}
              on average
            </h5>
          </BlueBubble>
        ) : (
          <span
            style={{
              padding: '0 var(--space-xs)',
              margin: 'var(--space-md) 0',
              textAlign: 'center',
              alignSelf: 'center',
            }}
            ref={headerRef}
          >
            Signup by tapping on the banner below to get an exclusive signup
            bonus
          </span>
        )}
        {books?.length > 0 ? (
          booksBanners ? (
            <Col
              id="infinite-scroll-target-view-allSB"
              style={{
                maxWidth: 'min(100%, 400px)',
                // Betstamp logo = 64px tall, skip/next buttons = 50px tall
                // header text = depends on content
                // rest is paddings/margins
                maxHeight: `calc(min(100vh, ${MAX_SCREEN_HEIGHT_SIGNUP_ONBOARD}) - 2 * var(--space-sm) - 64px - 50px - ${headerHeight}px - ${shouldOpenMoreBooks
                    ? '3 * var(--space-md) - var(--space-sm)'
                    : '2 * var(--space-md)'
                  })`,
                overflow: 'hidden scroll',
                minHeight: '150px',
                justifyContent: 'flex-start',
              }}
            >
              <InfiniteScroll
                scrollableTarget={'infinite-scroll-target-view-allSB'}
                hasMore={false}
                dataLength={books?.length || 0}
              >
                {booksBanners}
              </InfiniteScroll>
            </Col>
          ) : (
            <ActivityIndicator size={2} />
          )
        ) : (
          <span
            style={{
              height: `calc(100vh - 2 * var(--space-sm) - 64px - 50px - ${headerHeight}px - ${shouldOpenMoreBooks
                  ? '3 * var(--space-md) - var(--space-sm)'
                  : '2 * var(--space-md)'
                })`,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            Sorry, No Sportsbooks For Your Region
          </span>
        )}
      </Col>
    </OnboardingWrapper>
  );
}
