import { Observable } from 'rxjs';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import { ajax } from 'rxjs/observable/dom/ajax';
import { BACKEND_API_URL, getHeaders } from 'utils';

import {
  CHECK_REFERRAL_CODE_EXISTS,
  checkReferralCodeExistsSuccess,
  checkReferralCodeExistsFailure,
} from 'actions';

export default function checkEmailAvailable(action$, state$) {
  return action$.ofType(CHECK_REFERRAL_CODE_EXISTS).switchMap(action => {
    return ajax
      .get(
        BACKEND_API_URL + `api/referral_codes/exists/?code=${action.code}`,
        getHeaders(state$)
      )
      .map(res => checkReferralCodeExistsSuccess(res.response))
      .catch(error => Observable.of(checkReferralCodeExistsFailure(error.xhr)));
  });
}
