import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useMedia } from 'react-use';
import { useEffectOnce, useUpdateEffect } from 'react-use';
import Analytics from 'amplitude/Analytics';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

//components
import { AuthButton, IconButton } from 'components/AuthButton';
import { Row, Col } from 'components/generic/Layout';
import { BetlinkStamp } from 'components/generic/Logos';
import Badge from 'components/generic/Badge';
import ConfirmDelete from 'components/modals/ConfirmDelete';
import ModalWrapper from 'components/generic/ModalWrapper';
import ManageAutoSync from 'components/modals/ManageAutoSync';
import { BetlinkWord } from 'components/generic/Logos';
import { AuthTextInput } from 'components/AuthTextInput';
import AutoSync from 'components/modals/AutoSync';
import AutoColoredAmount from 'components/generic/AutoColoredAmount';
import {
  IoSync,
  IoEllipsisHorizontalSharp,
  IoAlertCircle,
  IoSyncOutline,
  IoRemoveCircleSharp,
  IoCheckmarkCircleOutline,
} from 'react-icons/io5';

import {
  autoSyncResetErrors,
  syncAllBets,
  refreshSharpsportsBook,
  fetchIntegrationSS,
  updateSSBook,
  getAccounts,
  getSharpsportsBooks,
  twoFA,
} from 'actions';

const StyledAutoSyncBook = styled.div`
  flex: 1;
  display: flex;
  flex-flow: row;
  width: 100%;
  justify-content: space-around;
  border: 1px solid var(--color-text-light);
  border-radius: var(--std-border-radius);
  padding: var(--space-xxs);
  transition: all var(--std-transition);
  margin: var(--space-xs) 0;
`;

const StyledLinkedBook = styled.div`
  flex: 1;
  display: flex;
  width: 100%;
  flex-flow: row;
  justify-content: space-around;
  border: 1px solid var(--color-text-light);
  border-radius: var(--std-border-radius);
  padding: var(--space-xxs);
  transition: all var(--std-transition);
  margin: var(--space-xs) 0;
`;

const StyledBookImg = styled.img`
  height: 91px;
  width: 91px;
  object-fit: cover;
  border-radius: var(--std-border-radius);
`;

export default function AutoSyncController(props) {
  const dispatch = useDispatch();
  const reduxProps = useSelector(state => ({
    user: state.authReducer.user,
    autoSyncBooksAccounts: state.autosyncReducer.autoSyncBooksAccounts,
    isLoadingAutoSyncLoginSuccess:
      state.autosyncReducer.isLoadingAutoSyncLoginSuccess,
    isLoadingAutoSyncLogin: state.autosyncReducer.isLoadingAutoSyncLogin,
    isLoadingGetAccounts: state.autosyncReducer.isLoadingGetAccounts,
    isLoadingSyncBets: state.autosyncReducer.isLoadingSyncBets,
    isLoadingSyncAllBets: state.autosyncReducer.isLoadingSyncAllBets,
    isLoadingSyncAllBetsSuccess:
      state.autosyncReducer.isLoadingSyncAllBetsSuccess,
    isLoadingSyncAllBetsFailure:
      state.autosyncReducer.isLoadingSyncAllBetsFailure,
    isLoadingSyncAllAccounts: state.autosyncReducer.isLoadingSyncAllAccounts,
    isLoadingSyncAllAccountsSuccess:
      state.autosyncReducer.isLoadingSyncAllAccountsSuccess,
    isLoadingSyncAllAccountsFailure:
      state.autosyncReducer.isLoadingSyncAllAccountsFailure,
    isLoadingRefreshHistory: state.autosyncReducer.isLoadingRefreshHistory,
    isLoadingRefreshHistoryAll:
      state.autosyncReducer.isLoadingRefreshHistoryAll,
    isLoadingRefreshHistoryAllSuccess:
      state.autosyncReducer.isLoadingRefreshHistoryAllSuccess,
    isLoadingRefreshHistoryAllFailure:
      state.autosyncReducer.isLoadingRefreshHistoryAllFailure,
    isLoadingAutoSyncDisconnect:
      state.autosyncReducer.isLoadingAutoSyncDisconnect,
    isLoadingAutoSyncDisconnectAll:
      state.autosyncReducer.isLoadingAutoSyncDisconnectAll,
    latestRefresh: state.autosyncReducer.latestRefresh,
    isLoading2FA: state.autosyncReducer.isLoading2FA,
    needs2FA: state.autosyncReducer.needs2FA,
    isLoading2FASuccess: state.autosyncReducer.isLoading2FASuccess,
    isLoading2FAFailure: state.autosyncReducer.isLoading2FAFailure,
  }));

  const isMobile = useMedia('(max-width: 768px)');

  const { book, small, compact, prefillEmail, theme } = props;
  const {
    autoSyncBooksAccounts,
    isLoadingAutoSyncLoginSuccess,
    isLoadingAutoSyncLogin,
    isLoadingGetAccounts,
    isLoadingSyncBets,
    isLoadingSyncAllBets,
    isLoadingSyncAllBetsSuccess,
    isLoadingSyncAllBetsFailure,
    isLoadingSyncAllAccounts,
    isLoadingSyncAllAccountsSuccess,
    isLoadingSyncAllAccountsFailure,
    isLoadingRefreshHistory,
    isLoadingRefreshHistoryAll,
    isLoadingRefreshHistoryAllSuccess,
    isLoadingRefreshHistoryAllFailure,
    isLoadingAutoSyncDisconnect,
    isLoadingAutoSyncDisconnectAll,
    latestRefresh,
    needs2FA,
    isLoading2FA,
    isLoading2FASuccess,
    isLoading2FAFailure,
    user,
  } = reduxProps;

  const [showLoginModal, setShowLoginModal] = useState(false);
  const [hideSync, setHideSync] = useState(false);
  const [loginMessage, setLoginMessage] = useState(false);
  const [twoFactorModal, setTwoFactorModal] = useState(false);

  const [totalStats, setTotalStats] = useState({
    book: book,
    balance: 0,
    bonus: 0,
    refreshes: 0,
    accounts: 0,
    record: { W: 0, L: 0, D: 0 },
    wagers: 0,
  });

  const _calculateTotals = () => {
    //looks expensive but is actually constant if user has only one account per book. probably can get rid of this once autosync accounts serializer is set up for multiple books
    let bonus = 0;
    let balance = 0;
    let refreshes = 0;
    let numAccounts = 0;
    let currRecord = { W: 0, L: 0, D: 0 };
    let totalWagers = 0;

    for (let userName in autoSyncBooksAccounts[book.name]) {
      for (let reg in autoSyncBooksAccounts[book.name][userName]) {
        balance +=
          autoSyncBooksAccounts[book.name][userName][reg].currentBalance;
        bonus += autoSyncBooksAccounts[book.name][userName][reg].currentBonus;
        refreshes +=
          autoSyncBooksAccounts[book.name][userName][reg].totalRefreshes;
        numAccounts += 1;
        currRecord['W'] +=
          autoSyncBooksAccounts[book.name][userName][reg].record['W'];
        currRecord['L'] +=
          autoSyncBooksAccounts[book.name][userName][reg].record['L'];
        currRecord['D'] +=
          autoSyncBooksAccounts[book.name][userName][reg].record['D'];
        totalWagers +=
          autoSyncBooksAccounts[book.name][userName][reg].record['W'] +
          autoSyncBooksAccounts[book.name][userName][reg].record['L'] +
          autoSyncBooksAccounts[book.name][userName][reg].record['D'];
      }
      setTotalStats({
        balance: balance,
        bonus: bonus,
        refreshes: refreshes,
        accounts: numAccounts,
        record: currRecord,
        wagers: totalWagers,
      });
    }
  };
  useEffectOnce(() => {
    _calculateTotals();
    if (isLoadingAutoSyncLoginSuccess || isLoading2FASuccess) {
      setHideSync(true);
      setLoginMessage(true);
      let to = setTimeout(() => {
        setHideSync(false);
        setLoginMessage(false);
        dispatch(autoSyncResetErrors());
      }, 30 * 1000);
      return () => {
        clearTimeout(to);
      };
    }
  });

  useUpdateEffect(() => {
    if (
      book.name in isLoadingSyncAllBetsSuccess ||
      isLoadingRefreshHistoryAllSuccess ||
      isLoadingSyncAllAccountsSuccess ||
      isLoading2FASuccess
    ) {
      const to = setTimeout(() => setHideSync(false), 30 * 1000);
      _calculateTotals();
      return () => {
        clearTimeout(to);
      };
    } else if (
      book.name in isLoadingSyncAllBetsFailure ||
      isLoadingRefreshHistoryAllFailure ||
      isLoadingSyncAllAccountsFailure ||
      isLoading2FAFailure
    ) {
      setHideSync(false);
    }
  }, [
    isLoadingSyncAllBetsSuccess,
    isLoadingSyncAllBetsFailure,
    isLoadingRefreshHistoryAllSuccess,
    isLoadingRefreshHistoryAllFailure,
    isLoadingSyncAllAccountsSuccess,
    isLoadingSyncAllAccountsFailure,
  ]);

  useEffect(() => {
    if (needs2FA === book.name && !showLoginModal) {
      setTwoFactorModal(true);
    }
  }, [needs2FA]);

  let status = 'Linked';
  let badgeColor = 'var(--color-success)';

  if (
    book.autosync_down_for_maintenance ||
    book.autosync_maintenance_states?.includes(user?.state)
  ) {
    status = 'Down for Maintenance';
    badgeColor = 'var(--color-complement)';
  } else if (
    (book.name in isLoadingSyncAllBetsSuccess ||
      book.name in isLoadingSyncAllAccountsSuccess) &&
    hideSync
  ) {
    status = 'Saving Wagers';
    badgeColor = 'var(--color-complement)';
  } else if (
    book.name in isLoadingSyncAllBets ||
    book.name in isLoadingSyncAllAccounts ||
    loginMessage ||
    hideSync
  ) {
    status = 'Sync in Progress';
    badgeColor = 'var(--color-complement)';
  } else if (
    book.name in isLoadingSyncAllBetsFailure ||
    book.name in isLoadingSyncAllAccountsFailure
  ) {
    status = 'Sync Unsuccessful';
    badgeColor = 'var(--color-complement)';
  } else if (isLoadingRefreshHistoryAll) {
    status = 'Refresh in Progress';
    badgeColor = 'var(--color-complement)';
  } else if (isLoadingRefreshHistoryAllFailure) {
    status = 'Refresh Unsuccessful';
    badgeColor = 'var(--color-complement)';
  }

  if (compact) {
    return (
      <StyledAutoSyncBook>
        <TwoFactorModal
          modalIsOpen={twoFactorModal}
          onRequestClose={() => {
            dispatch(autoSyncResetErrors());
            setTwoFactorModal(false);
          }}
          book={book}
        />
        <img
          style={{
            height: '91px',
            width: '91px',
            borderRadius: 'var(--std-border-radius)',
          }}
          src={book.logo}
          alt={book.name}
        />
        <Col
          style={{
            alignItems: 'stretch',
            justifyContent: 'flex-start',
            margin: '0 var(--space-xxs)',
          }}
        >
          {!isMobile && (
            <Row
              style={{
                justifyContent: 'flex-start',
                alignItems: 'center',
                padding: 0,
              }}
            >
              <b>{book.name}</b>
              <Link
                style={{ padding: 0, margin: 0 }}
                to={{
                  pathname: '/settings',
                  startState: 'BETLINK SETTINGS',
                }}
              >
                <IconButton
                  containerStyle={{
                    margin: '0 var(--space-sm)',
                  }}
                  btnStyle={{
                    margin: 0,
                    padding: 0,
                  }}
                  iconName={IoEllipsisHorizontalSharp}
                  onPress={() => {}}
                />
              </Link>
            </Row>
          )}
          {totalStats.accounts === 1 ? (
            isMobile ? (
              <b>{Object.keys(autoSyncBooksAccounts[book.name])}</b>
            ) : (
              <small>{Object.keys(autoSyncBooksAccounts[book.name])}</small>
            )
          ) : (
            <small>{totalStats.accounts} accounts</small>
          )}

          {loginMessage && (
            <small style={{ color: 'var(--color-complement)' }}>
              Please allow up to 60 seconds to sync your wagers.
            </small>
          )}
          {book.name in isLoadingSyncAllBetsFailure && (
            <small
              style={{
                color: 'var(--color-danger)',
                margin: '0 var(--space-xxxs)',
              }}
            >
              {isLoadingSyncAllBetsFailure[book.name].response}
            </small>
          )}
          {isLoadingRefreshHistoryAllFailure && (
            <small
              style={{
                color: 'var(--color-danger)',
                margin: '0 var(--space-xxxs)',
              }}
            >
              {isLoadingRefreshHistoryAllFailure.response}
            </small>
          )}
          {latestRefresh &&
            !loginMessage &&
            !(
              book.name in isLoadingSyncAllBetsFailure ||
              isLoadingRefreshHistoryAllFailure
            ) &&
            (isMobile ? (
              <small>Synced {moment(latestRefresh[book.name]).fromNow()}</small>
            ) : (
              <small>
                Synced on{' '}
                {moment(latestRefresh[book.name]).format(
                  'ddd MMM D, YYYY @ h:mma'
                )}
              </small>
            ))}
          <Row
            style={{
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            <Badge
              text={status}
              style={{
                color: badgeColor,
                borderColor: badgeColor,
                marginTop: 'var(--space-xxs)',
              }}
            />
          </Row>
        </Col>
        <AutoSyncStats
          book={book}
          totalBalance={totalStats.balance}
          totalBonus={totalStats.bonus}
          totalRecord={totalStats.record}
          totalWagers={totalStats.wagers}
          mobile={isMobile}
        />
        <Row
          style={{
            flex: 0.3,
            justifyContent: 'flex-end',
            alignItems: 'center',
            padding: '0 var(--space-lg)',
          }}
        >
          {isMobile && (
            <Link
              style={{ padding: 0, margin: 0 }}
              to={{
                pathname: '/settings',
                startState: 'BETLINK SETTINGS',
              }}
            >
              <IconButton
                iconName={IoEllipsisHorizontalSharp}
                onPress={() => {}}
              />
            </Link>
          )}
          <IconButton
            iconName={IoSync}
            isLoading={book.name in isLoadingSyncAllBets && hideSync}
            disabled={
              hideSync ||
              book.autosync_down_for_maintenance ||
              book.autosync_maintenance_states.includes(user?.state)
            }
            onPress={() => {
              setHideSync(true);
              dispatch(syncAllBets({ book: book.name }));
            }}
            subtext="Sync"
          />
        </Row>
      </StyledAutoSyncBook>
    );
  }

  return (
    <>
      <TwoFactorModal
        modalIsOpen={twoFactorModal}
        onRequestClose={() => {
          dispatch(autoSyncResetErrors());
          setTwoFactorModal(false);
        }}
        book={book}
      />
      <ModalWrapper
        modalIsOpen={showLoginModal}
        onRequestClose={() => {
          setShowLoginModal(false);
          if (
            !(isLoadingAutoSyncLogin || isLoadingGetAccounts || isLoading2FA)
          ) {
            dispatch(autoSyncResetErrors());
            dispatch(getAccounts());
          }
        }}
        backgroundColor={theme === 'onboarding' ? 'white' : undefined}
        color={theme === 'onboarding' ? 'black' : undefined}
        marginLeft={theme === 'onboarding' ? '16vw' : undefined}
        boxShadow={
          theme === 'onboarding'
            ? '0 2px 32px var(--color-onb-text)'
            : undefined
        }
        showX={theme === 'onboarding'}
      >
        <AutoSync
          book={book}
          prefillEmail={prefillEmail}
          theme={theme}
          dismissModal={() => {
            setShowLoginModal(false);
            if (props.onDone) props.onDone();
          }}
        />
      </ModalWrapper>
      {autoSyncBooksAccounts[book.name] ? (
        <Row
          style={{
            width: '100%',
            padding: small ? '0' : 'var(--space-md)',
            alignItems: 'center',
          }}
        >
          <Link
            to={{
              pathname: '/settings',
              startState: 'BETLINK SETTINGS',
            }}
            style={{ textDecoration: 'none' }}
          >
            <IconButton
              iconName={IoEllipsisHorizontalSharp}
              onPress={() => {}}
              subtext="Manage"
            />
          </Link>
        </Row>
      ) : (
        <AuthButton
          overrideChildren
          containerStyle={{
            borderRadius: '8px',
            margin: small ? '0' : '0 var(--space-xxs)',
          }}
          btnStyle={{
            height: '100%',
            backgroundColor: 'transparent',
            borderColor:
              theme === 'onboarding' ? 'transparent' : 'var(--color-primary)',
            flexDirection: theme === 'onboarding' && !small ? 'row' : 'column',
            gap:
              theme === 'onboarding' && !small ? 'var(--space-md)' : undefined,
            borderRadius: '8px',
            padding: '0 var(--space-md)',
          }}
          btnTheme={small && 'borderless'}
          disabled={
            isLoadingAutoSyncLogin || isLoadingGetAccounts || isLoading2FA
          }
          onPress={() => {
            setShowLoginModal(true);
            dispatch(autoSyncResetErrors());
          }}
          ampData={{
            event: Analytics.events.CLICK_AUTOSYNC_CONNECT,
            data: {
              book: book.name,
            },
          }}
        >
          <BetlinkStamp
            blue={!(isLoadingAutoSyncLogin || isLoadingGetAccounts)}
            style={
              theme === 'onboarding' && !small
                ? { height: 64, width: 64 }
                : { height: small ? 32 : 54, width: small ? 32 : 54 }
            }
          />
          <span
            style={{
              textAlign: 'center',
              fontSize:
                theme === 'onboarding' && !small ? 'var(--text-xl)' : undefined,
            }}
          >
            Link{theme === 'onboarding' && !small ? ` ${book?.name}` : ''}
          </span>
        </AuthButton>
      )}
    </>
  );
}

export function LinkedBook(props) {
  const dispatch = useDispatch();
  const { item, refreshing, refreshingSelf, totalBalance, totalRecord } = props;

  const isMobile = useMedia('(max-width: 768px)');

  const nextSync = useMemo(() => {
    let nextSync = 0;
    let lastSync = moment(item.last_sync);
    if (moment().diff(lastSync, 'minutes') < 5) {
      nextSync = lastSync.add(5, 'minutes').format('h:mma');
    }
    return nextSync;
  }, [item]);

  let status = 'Linked';
  let badgeColor = 'var(--color-success)';
  let iconOnPress = () => {
    dispatch(refreshSharpsportsBook(item.bettor_account_id));
  };

  let Icon = null;
  let iconTheme = null;
  let iconText = null;

  if (item.book.sharpsports_down_for_maintenance) {
    status = 'Down For Maintenance';
    badgeColor = 'var(--color-complement)';
  } else if (item.is_unverifiable || !item.access) {
    status = item.access ? 'Unlinked' : 'Revoked Access';
    badgeColor = 'var(--color-danger)';
    Icon = IoAlertCircle;
    iconTheme = 'danger';
    iconText = 'Relink';
    iconOnPress = item.access
      ? () => dispatch(fetchIntegrationSS())
      : () => dispatch(updateSSBook({ id: item.id, access: true }));
  } else if (!item.verified) {
    status = 'Needs Reverification';
    badgeColor = 'var(--color-complement)';
    Icon = IoAlertCircle;
    iconTheme = 'complement';
    iconText = 'Reverify';
    iconOnPress = () => {
      dispatch(refreshSharpsportsBook(item.bettor_account_id, true));
    };
  }

  if (refreshing) {
    iconOnPress = () => {};
  }

  const [confirmDelete, setConfirmDelete] = useState(false);

  return (
    <>
      <ModalWrapper
        modalIsOpen={confirmDelete}
        onClose={() => setConfirmDelete(false)}
        onRequestClose={() => setConfirmDelete(false)}
        title="Confirm Unlink"
        modalSize="small"
      >
        <ConfirmDelete
          dismissModal={() => setConfirmDelete(false)}
          onDelete={() => {
            dispatch(updateSSBook({ id: item.id, access: false }));
            setConfirmDelete(false);
          }}
          deleteBtnText="Unlink"
        >
          Are you sure you want to unlink {item.book.name}?
        </ConfirmDelete>
      </ModalWrapper>

      <StyledLinkedBook>
        <Col style={{ flex: 0, width: '91px' }}>
          <StyledBookImg src={item.book.logo || item.book.generic_banner} />
        </Col>

        <Col
          style={{
            alignItems: 'flex-start',
            margin: '0 var(--space-xxs)',
            justifyContent: 'center',
          }}
        >
          {!isMobile && (
            <Row
              style={{
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}
            >
              <b>{item.book.name}</b>
              <Link
                to={{
                  pathname: '/settings',
                  startState: 'BETLINK SETTINGS',
                }}
              >
                <IconButton
                  containerStyle={{ margin: '0 var(--space-xxs)' }}
                  iconName={IoEllipsisHorizontalSharp}
                  onPress={() => {}}
                />
              </Link>
            </Row>
          )}

          <small>
            Synced {item.last_sync ? moment(item.last_sync).fromNow() : 'Never'}
          </small>
          <small>Linked {moment(item.created_at).format('MMM D, YYYY')}</small>
          <Badge
            text={status}
            style={{
              color: badgeColor,
              borderColor: badgeColor,
              marginTop: 'var(--space-xxs)',
            }}
          />
        </Col>

        <Row
          style={{
            width: '100%',
            margin: '0 var(--space-sm)',
          }}
        >
          <Col style={{ justifyContent: 'center' }}>
            <small>Balance</small>
            <AutoColoredAmount symbol={'$'}>{totalBalance}</AutoColoredAmount>
          </Col>
          {!isMobile && (
            <Col style={{ justifyContent: 'center' }}>
              <small>Recent W/L/D</small>
              {totalRecord['W'] +
                '-' +
                totalRecord['L'] +
                '-' +
                totalRecord['D']}
              <RecentStats
                totalRecord={totalRecord}
                totalWagers={
                  totalRecord['W'] + totalRecord['D'] + totalRecord['L']
                }
                containerStyle={{ flex: 0.1 }}
              />
            </Col>
          )}
        </Row>
        {item.book.sharpsports_down_for_maintenance && (
          <Row
            style={{
              flex: 0.3,
              justifyContent: 'flex-end',
              alignItems: 'center',
              padding: '0 var(--space-lg)',
            }}
          >
            <IconButton
              iconName={IoRemoveCircleSharp}
              colorTheme={'danger'}
              subtext={'Unlink'}
              onPress={() => {
                dispatch(
                  updateSSBook({ id: item.bettor_account_id, access: false })
                );
                dispatch(getSharpsportsBooks());
              }}
            />
          </Row>
        )}
        {item.access && !item.book.sharpsports_down_for_maintenance && (
          <Row
            style={{
              flex: 0.3,
              justifyContent: 'flex-end',
              alignItems: 'center',
              padding: '0 var(--space-lg)',
            }}
          >
            {Icon && !isMobile && (
              <Col>
                <IconButton
                  iconName={Icon}
                  subtext={iconText}
                  colorTheme={iconTheme}
                  isLoading={refreshingSelf}
                  onPress={iconOnPress}
                  ampData={{
                    event: Analytics.events.BETLINK_REVERIFY_BOOK,
                    data: { book: item.book.name },
                  }}
                />
              </Col>
            )}
            <IconButton
              iconName={IoSyncOutline}
              subtext={'Sync'}
              isLoading={refreshingSelf}
              disabled={
                refreshingSelf ||
                item.book.sharpsports_down_for_maintenance ||
                nextSync
              }
              onPress={() => {
                dispatch(refreshSharpsportsBook(item.bettor_account_id));
              }}
            />
          </Row>
        )}
      </StyledLinkedBook>
    </>
  );
}

function AutoSyncStats(props) {
  const { book, totalBalance, totalBonus, totalRecord, totalWagers, mobile } =
    props;
  return (
    <Col>
      <Row
        style={{
          width: '100%',
          margin: '0 var(--space-sm)',
        }}
      >
        <Col style={{ justifyContent: 'center' }}>
          <small>Balance</small>
          {book.autosync_no_balance ? (
            <small>Unavailable</small>
          ) : (
            <AutoColoredAmount symbol={'$'}>{totalBalance}</AutoColoredAmount>
          )}
        </Col>

        {!mobile && (
          <>
            <Col style={{ justifyContent: 'center' }}>
              <small>Bonus</small>
              {book.autosync_no_balance ? (
                <small>Unavailable</small>
              ) : (
                <AutoColoredAmount symbol={'$'}>{totalBonus}</AutoColoredAmount>
              )}
            </Col>

            <Col style={{ justifyContent: 'center' }}>
              <small>Recent W/L/D</small>
              {totalRecord['W'] +
                '-' +
                totalRecord['L'] +
                '-' +
                totalRecord['D']}
              <RecentStats
                totalRecord={totalRecord}
                totalWagers={totalWagers}
                containerStyle={{ flex: 0.1 }}
              />
            </Col>
          </>
        )}
      </Row>
    </Col>
  );
}

function RecentStats(props) {
  const { totalRecord, totalWagers } = props;

  const [displayedStats, setDisplayedStats] = useState(totalRecord);

  const _calculateRatio = () => {
    setDisplayedStats({
      W: Math.floor((totalRecord['W'] / totalWagers) * 10),
      L: Math.floor((totalRecord['L'] / totalWagers) * 10),
      D: Math.floor((totalRecord['D'] / totalWagers) * 10),
    });
  };

  useEffect(() => {
    setDisplayedStats(totalRecord);
    if (totalWagers > 10) {
      _calculateRatio();
    }
  }, [totalWagers, totalRecord]);

  return (
    <Row
      style={{
        ...props.containerStyle,
      }}
    >
      {[...Array(displayedStats['W'])].map((_, i) => {
        return (
          <span
            key={`won-${i}`}
            style={{
              width: '7px',
              height: '5px',
              backgroundColor: 'var(--color-success)',
              border: '1px solid var(--color-text-light)',
              margin: '0 1px',
            }}
          ></span>
        );
      })}
      {[...Array(displayedStats['L'])].map((_, i) => {
        return (
          <span
            key={`lost-${i}`}
            style={{
              width: '7px',
              height: '5px',
              backgroundColor: 'var(--color-danger)',
              border: '1px solid var(--color-text-light)',
              margin: '0 1px',
            }}
          ></span>
        );
      })}
      {[...Array(displayedStats['D'])].map((_, i) => {
        return (
          <span
            key={`draw-${i}`}
            style={{
              width: '7px',
              height: '5px',
              backgroundColor: 'var(--color-complement)',
              border: '1px solid var(--color-text-light)',
              margin: '0 1px',
            }}
          ></span>
        );
      })}
    </Row>
  );
}

export function TwoFactorModal(props) {
  const dispatch = useDispatch();
  const reduxProps = useSelector(state => ({
    twoFAData: state.autosyncReducer.twoFAData,
    isLoading2FA: state.autosyncReducer.isLoading2FA,
    isLoading2FASuccess: state.autosyncReducer.isLoading2FASuccess,
    isLoading2FAFailure: state.autosyncReducer.isLoading2FAFailure,
  }));
  const { book, theme } = props;
  const { twoFAData, isLoading2FA, isLoading2FASuccess, isLoading2FAFailure } =
    reduxProps;
  const [twoFACode, setTwoFACode] = useState('');
  const [expiry, setExpiry] = useState(null);

  useEffect(() => {
    // set initial time
    setExpiry(twoFAData['expires']);
    let count = twoFAData['expires'];
    const timer = setInterval(() => {
      if (count <= 0) {
        clearInterval(timer);
      } else if (count > 0) {
        setExpiry(expiry => expiry - 1);
        count--;
      }
    }, 1000);
    return () => clearInterval(timer);
  }, [twoFAData]);

  if (isLoading2FASuccess) {
    return (
      <ModalWrapper
        modalIsOpen={props.modalIsOpen}
        onClose={() => props.onClose()}
        onRequestClose={() => props.onRequestClose()}
        modalSize="medium"
      >
        <Col
          style={{
            width: '100%',
            height: '95%',
            justifyContent: 'space-evenly',
            flexFlow: 'column nowrap',
          }}
        >
          <BetlinkWord height={70} />
          <img
            src={book.generic_banner}
            style={{
              width: '30%',
              borderRadius: 5,
              margin: 'var(--space-md)',
            }}
            alt={book.name}
          />
          <IoCheckmarkCircleOutline size={128} color={'var(--color-success)'} />
          <h4 style={{ color: 'var(--color-success)', marginTop: 0 }}>
            {book.name} Account Synced!
          </h4>
          <b
            style={{
              marginTop: 'var(--space-md)',
              width: '50%',
              textAlign: 'center',
            }}
          >
            It may take up to a minute to sync your {book.name} bets. Betstamp
            will automatically sync your bets from {book.name} regularly.
          </b>
        </Col>
      </ModalWrapper>
    );
  }

  return (
    <ModalWrapper
      modalIsOpen={props.modalIsOpen}
      onClose={() => props.onClose()}
      onRequestClose={() => props.onRequestClose()}
      modalSize="medium"
    >
      <Col
        style={{
          width: '100%',
          height: '95%',
          justifyContent: 'space-evenly',
          flexFlow: 'column nowrap',
        }}
      >
        <BetlinkWord height={70} />
        <img
          src={book.generic_banner}
          style={{ width: '40%', borderRadius: 5, margin: 'var(--space-md)' }}
          alt={book.name}
        />
        <Col style={{ width: '100%' }}>
          <AuthTextInput
            placeholder={'Two-Factor Authentication Code'}
            containerStyle={{
              width: '40%',
              marginTop: 'var(--space-lg)',
            }}
            inputRowStyle={{ backgroundColor: 'var(--color-bg)' }}
            onChangeText={(text, input) => setTwoFACode(text)}
            required
            colorTheme={theme === 'onboarding' ? 'onb' : undefined}
          />
          <AuthButton
            containerStyle={{
              width: '40%',
              marginTop: 'var(--space-lg)',
              marginBottom: 0,
            }}
            disabled={!twoFACode}
            onPress={() => {
              dispatch(twoFA({ ...twoFAData, twoFACode }));
            }}
            isLoading={isLoading2FA}
            colorTheme={theme === 'onboarding' ? 'onbblue' : undefined}
            btnTheme={theme === 'onboarding' ? 'onb' : undefined}
          >
            Send
          </AuthButton>
          <p
            style={{ width: '40%', textAlign: 'center', justifySelf: 'start' }}
          >
            Please enter the 2FA code you received to continue syncing your
            bets.
          </p>
          {isLoading2FAFailure && (
            <p
              style={{
                width: '30%',
                textAlign: 'center',
                justifySelf: 'start',
                color: 'var(--color-complement)',
              }}
            >
              Invalid Code
            </p>
          )}
          <Col style={{ marginTop: 'var(--space-md)' }}>
            <b
              style={{
                justifySelf: 'center',
                fontSize: 'var(--text-xxl)',
                color:
                  expiry < twoFAData['expires'] / 2 &&
                  'var(--color-complement)',
              }}
            >
              {expiry}
            </b>
            {expiry === 0 && (
              <b
                style={{
                  marginTop: 'var(--space-xs)',
                  color: 'var(--color-complement)',
                }}
              >
                Session has expired. Please try syncing again.
              </b>
            )}
          </Col>
        </Col>
        <Row
          style={{
            marginTop: 'var(--space-md)',
            width: '50%',
            height: '100%',
            alignItems: 'flex-end',
          }}
        >
          <small style={{ textAlign: 'center' }}>
            By clicking Send, you agree to betstamp's{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={'/terms-and-conditions'}
            >
              Terms and Conditions
            </a>
          </small>
        </Row>
      </Col>
    </ModalWrapper>
  );
}
