import React from 'react';
import { Row, Col } from 'components/generic/Layout';

export default function CenterTextLine(props) {
  const { text, color, fontSize } = props;
  return (
    <Row
      style={{
        width: '100%',
        justifyContent: 'center',
        paddingTop: 'var(--space-sm)',
        paddingBottom: 'var(space-xs)',
        alignItems: 'center',
        flexFlow: 'row nowrap',
      }}
    >
      <Col
        style={{
          borderTop: '1px solid var(--color-primary)',
          borderTopColor: color ? color : 'var(--color-primary)',
          margin: '0 var(--space-sm)',
        }}
      />
      <Col
        style={{
          justifyContent: 'center',
          flex: 0,
        }}
      >
        <span
          style={{
            whiteSpace: 'nowrap',
            fontWeight: 'bold',
            color: color ? color : 'var(--color-primary)',
            textAlign: 'center',
            fontSize: fontSize ? fontSize : 'var(--text-sm)' + 2.5,
          }}
        >
          {text}
        </span>
      </Col>
      <Col
        style={{
          borderTop: '1px solid var(--color-primary)',
          borderTopColor: color ? color : 'var(--color-primary)',
          margin: '0 var(--space-sm)',
        }}
      />
    </Row>
  );
}
