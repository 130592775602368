import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

// utils
import { kFormatter } from 'utils';

// components
import { Col } from 'components/generic/Layout';
import AutoColoredAmount from './generic/AutoColoredAmount';

const Wrapper = styled.button`
  width: 100%;
  flex: 1;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  align-items: center;
  padding: var(--space-sm) var(--space-md);
  margin: var(--space-xs) 0;
  outline: none;
  background-color: var(--color-fg);
  border-radius: var(--std-border-radius);
  cursor: pointer;
  font-size: var(--text-base-size);
  transition: all var(--std-transition);
  color: var(--color-text);
  border: ${props => {
    if (props.selected) {
      return '2px solid var(--color-primary)';
    }
    return '2px solid transparent';
  }};

  &:hover {
    box-shadow: 0 2px 8px 0 var(--color-shadow);
  }
  &:active {
    box-shadow: inset 0 0 0 100vh var(--color-active);
    border: 2px solid var(--color-active);
  }
`;

export default function AccountingBook(props) {
  const { bookSummary } = props;
  const { book, pending, profit, total } = bookSummary;

  const reduxProps = useSelector(state => ({
    accountingBook: state.picksReducer.accountingBook,
  }));
  const { accountingBook } = reduxProps;

  return (
    <Wrapper onClick={props.onClick} selected={accountingBook === book}>
      <h6 style={{ flex: 1, fontWeight: 'bold', margin: 0, textAlign: 'left' }}>
        {book}
      </h6>
      <Col
        style={{
          flex: 1,
          justifyContent: 'center',
          lineHeight: 1,
        }}
      >
        <b
          style={{
            color: total < 0 ? 'var(--color-danger)' : 'var(--color-text)',
          }}
        >
          {total >= 0
            ? '$' + kFormatter(total)
            : '-$' + kFormatter(total).replace('-', '')}
        </b>
        <small>current balance</small>
      </Col>
      <Col
        style={{
          flex: 1,
          justifyContent: 'center',
          lineHeight: 1,
        }}
      >
        <b>
          {pending >= 0
            ? '$' + kFormatter(pending)
            : '-$' + kFormatter(pending).replace('-', '')}
        </b>
        <small>pending</small>
      </Col>
      <Col
        style={{
          flex: 1,
          justifyContent: 'center',
          lineHeight: 1,
        }}
      >
        <AutoColoredAmount
          style={{ lineHeight: '1.1' }}
          symbol={'$'}
          bold={'true'}
        >
          {Math.abs(profit) >= 10 ? Math.round(profit) : profit}
        </AutoColoredAmount>
        <small>net profit</small>
      </Col>
    </Wrapper>
  );
}
