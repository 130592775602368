import React, { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useEffectOnce } from 'react-use';
import { IoGameControllerOutline } from 'react-icons/io5';

// utils
import {
  humanDatetime,
  REGION_FLAGS,
  PROVINCE_OPTIONS,
  US_STATES,
  OTHER_STATE_OPTIONS,
} from 'utils';

// components
import StaffOnboardingBase from 'components/StaffOnboardingBase';
import GenericAdminFilter from 'components/onboarding/GenericAdminFilter';
import SportsbookInfoWizard from 'components/admin/SportsbookInfoWizard';

// actions
import { getAllBooks } from 'actions';
import { Row } from 'components/generic/Layout';

export default function SportsbookInfos() {
  const dispatch = useDispatch();
  const reduxProps = useSelector(state => ({
    allBooksMap: state.authReducer.allBooksMap,
  }));
  const { allBooksMap } = reduxProps;

  useEffectOnce(() => {
    dispatch(getAllBooks());
  });

  return (
    <>
      <StaffOnboardingBase
        endpoint="sportsbook-infos"
        modelName="Sportsbook Info"
        headings={[
          'Book',
          'Region',
          'Min Deposit',
          'Bonus',
          //'Group Min Deposit',
          'CPA',
          'Bonus EV',
          'Start Date',
          //'End Date',
          'Status',
          '',
        ]}
        objKeys={[
          'book',
          'region',
          'affiliate_min_deposit',
          'bonus',
          //'group_affiliate_min_deposit',
          'cpa',
          'bonus_ev',
          'start_date',
          //'end_date',
          'status',
        ]}
        sortKeys={{
          Book: 'book__name',
          Region: 'region',
          'Min Deposit': 'affiliate_min_deposit',
          'Group Min Deposit': 'groupaffiliate_min_deposit',
          book__name: 'Book',
          region: 'Region',
          affiliate_min_deposit: 'Min Deposit',
          group_affiliate_min_deposit: 'Group Min Deposit',
          start_date: 'Start Date',
          'Start Date': 'start_date',
          end_date: 'End Date',
          'End Date': 'end_date',
          CPA: 'cpa',
          cpa: 'CPA',
          bonus_ev: 'Bonus EV',
          'Bonus EV': 'bonus_ev',
        }}
        externalLinks={{
          betlink_banner: true,
          book_banner_offer: true,
          book_banner_min: true,
          book_banner_offer_and_min: true,
          subaffiliate_banner: true,
        }}
        formatText={{
          created_at: humanDatetime,
          modified_at: humanDatetime,
          affiliate_min_deposit: t => `$${t}`,
          group_affiliate_min_deposit: t => `$${t}`,
          bonus_min_deposit: t => `$${t}`,
          cpa: t => `$${t}`,
          bonus_ev: t => `$${t}`,
          recommended_deposit: t => `$${t}`,
          betlink_banner: t => (t ? 'View Betlink Banner' : null),
          book_banner_offer: t => (t ? 'View Book Banner Offer' : null),
          book_banner_min: t => (t ? 'View Book Banner Min Deposit' : null),
          book_banner_offer_and_min: t =>
            t ? 'View Book Banner Offer And Min Deposit' : null,
          subaffiliate_banner: t => (t ? 'View Subaffiliate Banner' : null),
          book: (book_id, obj) =>
            allBooksMap && allBooksMap[book_id] ? (
              <>
                <img
                  src={allBooksMap[book_id].logo}
                  alt={allBooksMap[book_id].name}
                  width={22}
                  style={{ borderRadius: '2px', verticalAlign: 'middle' }}
                />
                <span style={{ verticalAlign: 'middle' }}>
                  &nbsp;{allBooksMap[book_id].name}&nbsp;
                </span>
                {!!obj?.controlled_traffic && (
                  <IoGameControllerOutline
                    style={{ verticalAlign: 'middle' }}
                    color="var(--color-text)"
                    size={16}
                  />
                )}
              </>
            ) : (
              book_id
            ),
          region: region => (
            <>
              {REGION_FLAGS[region] && (
                <img
                  src={REGION_FLAGS[region]}
                  alt={region}
                  width={32}
                  style={{
                    verticalAlign: 'middle',
                    marginRight: 'var(--space-xxxs)',
                  }}
                />
              )}
              <span style={{ verticalAlign: 'middle' }}>&nbsp;{region}</span>
            </>
          ),
          status: status => (
            <>
              <span
                style={{
                  borderRadius: 'var(--std-border-radius)',
                  backgroundColor:
                    status === 'ACTIVE'
                      ? 'var(--color-success)'
                      : status === 'INACTIVE'
                        ? 'var(--color-danger'
                        : status === 'FUTURE'
                          ? 'var(--color-complement)'
                          : 'transparent',
                  color: 'black',
                  fontSize: 'var(--text-xs)',
                  padding: 'var(--space-xxxs)',
                }}
              >
                {status}
              </span>
            </>
          ),
        }}
        PanelChildren={Panel}
        deleteBaseEndpoint="sportsbook-infos"
        showSearch={true}
        searchPlaceholder="Search by book name or region"
        FilterChildren={FilterChildren}
        createButtonText="Create/Update Sportsbook Infos"
        panelTabs={['Details']}
      />
    </>
  );
}

function FilterChildren(props) {
  const reduxProps = useSelector(state => ({
    allBooksMap: state.authReducer.allBooksMap,
  }));
  const { allBooksMap } = reduxProps;

  const { urlParams } = props;

  const defaultFilters = useMemo(() => {
    let filters = {};
    if (urlParams.regions) {
      let d = {};
      for (const r of JSON.parse(urlParams.regions)) {
        d[r] = true;
      }
      filters.regions = d;
    }

    if (urlParams.book_ids) {
      let d = {};
      for (const r of JSON.parse(urlParams.book_ids)) {
        d[r] = true;
      }
      filters.book_ids = d;
    }

    if (urlParams.controlled_traffic) {
      filters.controlled_traffic = { [urlParams.controlled_traffic]: true };
    }

    filters.historical = urlParams?.historical;
    return filters;
  }, [urlParams]);

  return (
    <div style={{ width: '100%' }}>
      <GenericAdminFilter
        filterTitle="Region"
        filterKey="regions"
        options={{
          groups: [
            {
              title: '🇨🇦Canada',
              options: PROVINCE_OPTIONS.map(p => ({ label: p, value: p })),
            },
            {
              title: '🇺🇸USA',
              options: US_STATES.map(p => ({ label: p, value: p })),
            },
            {
              title: '🌎 Global',
              options: Object.values(OTHER_STATE_OPTIONS).map(p => ({
                label: p,
                value: p,
              })),
            },
          ],
        }}
        onFilter={selections => {
          props.onFilter({
            regions: JSON.stringify(selections),
          });
        }}
        defaultDict={defaultFilters.regions}
      />
      <GenericAdminFilter
        filterTitle="Sportsbook"
        filterKey="book_ids"
        options={{
          groups: [
            {
              title: 'Books',
              options: Object.entries(allBooksMap)
                .map(([k, v], i) => ({
                  label: (
                    <Row
                      style={{
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        gap: 'var(--space-xs)',
                      }}
                    >
                      <img
                        width={22}
                        height={22}
                        style={{ borderRadius: 4 }}
                        src={allBooksMap[k]?.logo}
                        alt={v.name}
                      />
                      <span>{v.name}</span>
                    </Row>
                  ),
                  rawLabel: v.name,
                  value: k,
                }))
                .sort((a, b) => {
                  if (a.rawLabel.toLowerCase() > b.rawLabel.toLowerCase())
                    return 1;
                  if (a.rawLabel.toLowerCase() < b.rawLabel.toLowerCase())
                    return -1;
                  return 0;
                }),
            },
          ],
        }}
        onFilter={selections => {
          props.onFilter({
            book_ids: JSON.stringify(selections),
          });
        }}
        defaultDict={defaultFilters.book_ids}
      />
      <GenericAdminFilter
        filterTitle="Filter by status"
        filterKey="historical"
        type="toggleSelect"
        options={['Show Active', 'Show Inactive']}
        onFilter={opt => {
          props.onFilter({
            historical: opt === 'Show Inactive',
          });
        }}
        defaultActive={
          defaultFilters.historical ? 'Show Inactive' : 'Show Active'
        }
      />
      <GenericAdminFilter
        filterTitle="Controlled Traffic"
        filterKey="controlled_traffic"
        options={{
          groups: [
            {
              title: 'Controlled Traffic',
              options: [
                { label: 'Controlled', value: true },
                { label: 'Unlimited', value: false },
              ],
            },
          ],
        }}
        singleChoice
        onFilter={selections => {
          props.onFilter({
            controlled_traffic: selections[0],
          });
        }}
        defaultDict={defaultFilters.controlled_traffic}
      />
    </div>
  );
}

function Panel(props) {
  if (props.tab === 'Edit' || props.selectedObj?.createNew) {
    return <SportsbookInfoWizard {...props} bookId={props.selectedObj?.book} />;
  }
  return <></>;
}
