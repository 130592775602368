import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from 'react-router-dom';
import { useEffectOnce } from 'react-use';
import { GoogleOAuthProvider } from '@react-oauth/google';

////// PAGES //////
// import DownForMaint from 'pages/DownForMaint';
import Home from 'pages/Home';
import Betlink from 'pages/Betlink';
import About from 'pages/About';
import Careers from 'pages/Careers';
import Games from 'pages/Games/Games';
import OddsComp from 'pages/OddsComp';
import FuturesSched from 'pages/Futures/FuturesSched';
import Future from 'pages/Futures/Future';
import EditBetslip from 'pages/Games/EditBetslip';
import Game from 'pages/Games/Game';
import Settings from 'pages/Settings/Settings';
import MyPicks from 'pages/MyPicks/MyPicks';
import MyPicksHidden from 'pages/MyPicks/MyPicksHidden';
import Analysis from 'pages/Analysis/Analysis';
import Marketplace from 'pages/Marketplace/Marketplace';
import PublicProfile from 'pages/PublicProfile';
import FourOhFour from 'pages/404';
import Leaderboard from 'pages/Leaderboard';
import Accounting from 'pages/Accounting';
//import Survey from 'pages/Survey';
//import Sportsbooks from 'pages/Sportsbooks/Sportsbooks';
//import BonusHunter from 'pages/Sportsbooks/BonusHunter';
//import Review from 'pages/Sportsbooks/Review';
import Outlink from 'pages/Outlink';
//import Competition from 'pages/Competition';
import Groups from 'pages/Groups';
import Vip from 'pages/Vip';
import Events from 'pages/Events';
import Books from 'pages/Books';
import Onboarding from 'pages/Onboarding';
import FindBets from 'pages/FindBets';
import InfluencerBooks from 'pages/InfluencerBooks';

// new onboarding
import Intake from 'pages/onboarding/Intake';
import AllSessions from 'pages/onboarding/AllSessions';
import StaffSession from 'pages/onboarding/StaffSession';
import AttendeeSession from 'pages/onboarding/AttendeeSession';
import Session from 'pages/onboarding/Session';
import MyOnboardingDashboard from 'pages/onboarding/MyDashboard';
import MyMetrics from 'pages/onboarding/MyMetrics';
import Attendees from 'pages/onboarding/Attendees';
import Hosts from 'pages/onboarding/Hosts';
import Schedule from 'pages/onboarding/Schedule';
import Payments from 'pages/onboarding/Payments';
import SportsbookInfos from 'pages/onboarding/SportsbookInfos';
import VerifyAttendees from 'pages/onboarding/VerifyAttendees';
import TroubleShooterAdmin from 'pages/onboarding/TroubleShooterAdmin';
import SyncToAirtable from 'pages/onboarding/SyncToAirtable';
import CheckBookData from 'pages/onboarding/CheckBookData';
import SalesTeamMetrics from 'pages/onboarding/SalesTeamMetrics';
import OverallMetrics from 'pages/onboarding/OverallMetrics';
import OverallMetrics3 from 'pages/onboarding/OverallMetrics3';
import CSMMetrics from 'pages/onboarding/CSMMetrics';
import StaffReferralCodes from 'pages/onboarding/StaffReferralCodes';
import AffiliateEarningDashboard from 'pages/AffiliateEarningDashboard/AffiliateEarningDashboard';
import AffiliateEarningMetrics from 'pages/AffiliateEarningDashboard/AffiliateEarningMetrics';
import AffiliateEarningSnapshots from 'pages/AffiliateEarningDashboard/AffiliateEarningSnapshots';
import EmailReports from 'pages/EmailReports/EmailReports';

// stats dashboard within onboarding
import AffiliateStatsDashboard from 'pages/StatsDashboard/AffiliateStatsDashboard';

// auth pages
import Signup from 'pages/Auth/Signup';
import SignupEmail from 'pages/Auth/SignupEmail';
import Login from 'pages/Auth/Login';
import LoginEmail from 'pages/Auth/LoginEmail';
import ForgotPassword from 'pages/Auth/ForgotPassword';
import SocialCallback from 'pages/Auth/SocialCallback';
import SelectExperience from 'pages/Auth/Onboarding/SelectExperience';
import OddsCompare from 'pages/Auth/Onboarding/OddsCompare';
import SelectSBPro from 'pages/Auth/Onboarding/SelectSBPro';
import ShouldOpenMoreBooks from 'pages/Auth/Onboarding/ShouldOpenMoreBooks';
import LinkBooks from 'pages/Auth/Onboarding/LinkBooks';
import FindUsers from 'pages/Auth/Onboarding/FindUsers';
import SelectSBRookie from 'pages/Auth/Onboarding/SelectSBRookie';
import ViewAllSB from 'pages/Auth/Onboarding/ViewAllSB';
import UnregulatedRookie from 'pages/Auth/Onboarding/UnregulatedRookie';
import Meantime from 'pages/Auth/Onboarding/Meantime';
import GamblingProblem from 'pages/GamblingProblem';
import Unsub from 'pages/Unsubscribe';

// tutorials
import Help from 'pages/Help';
import TutorialAll from 'pages/Tutorials/TutorialAll';
import TutorialGeneric from 'pages/Tutorials/TutorialGeneric';
import FAQ from 'pages/Tutorials/FAQ';

// legals
import TermsAndConditions from 'pages/Legal/TermsAndConditions';
import PrivacyPolicy from 'pages/Legal/PrivacyPolicy';

// stripe transactions
import MobileTransactionCancelled from 'pages/Stripe/MobileTransactionCancelled';
import MobileTransactionProcessed from 'pages/Stripe/MobileTransactionProcessed';
import StripeConnnectSetup from 'pages/Stripe/StripeConnectSetup';

// components
import Nav from 'components/nav/Nav';
import ScrollToTop from 'components/ScrollToTop';
import SetupPurchaseSession from 'components/SetupPurchaseSession';

// utils
import { preloadImageHelper } from 'utils';

// loading indicator
import BetstampActivityIndicatorDay from 'images/gif/betstamp_activity_indicator_day.gif';
import BetstampActivityIndicatorNight from 'images/gif/betstamp_activity_indicator_night.gif';

// actions
import {
  getUser,
  getAllBooksMap,
  getAllBooks,
  getSportsbookInfoMap,
  syncAllAccounts,
} from 'actions';

// custom react query hooks
import { useUserGroups } from './react-query/global-hooks';

// for route requiring login
const ProtectedRoute = props => {
  const reduxProps = useSelector(state => ({
    userToken: state.authReducer.userToken,
  }));

  const { userToken } = reduxProps;
  if (userToken) {
    return (
      <Route exact path={props.path}>
        {props.children}
      </Route>
    );
  } else {
    return <Redirect exact to="/login" />;
  }
};

const StaffProtectedRoute = props => {
  const reduxProps = useSelector(state => ({
    userToken: state.authReducer.userToken,
    user: state.authReducer.user,
  }));

  const { user, userToken } = reduxProps;

  if (userToken && user?.is_staff) {
    return (
      <Route exact path={props.path}>
        {props.children}
      </Route>
    );
  }
  if (userToken) {
    return <Redirect exact to="/games" />;
  } else {
    return <Redirect exact to="/login" />;
  }
};

export default function App() {
  const dispatch = useDispatch();
  const reduxProps = useSelector(state => ({
    theme: state.settingsReducer.theme,
    userToken: state.authReducer.userToken,
  }));

  const { theme, userToken } = reduxProps;

  // fetch groups so they exist in react-query's cache
  useUserGroups();

  useEffectOnce(() => {
    dispatch(getAllBooksMap());
    dispatch(getAllBooks());
    dispatch(getSportsbookInfoMap());
  });

  useEffect(() => {
    if (userToken) {
      dispatch(getUser());
      //dispatch(syncAllAccounts({ userSynced: false }));
    }
  }, [userToken]);

  useEffect(() => {
    preloadImageHelper(
      theme === 'dark'
        ? BetstampActivityIndicatorNight
        : BetstampActivityIndicatorDay
    );
  }, [theme]);

  return (
    <GoogleOAuthProvider clientId="684605109812-lvpiej86e0em46osn5kcugqrp39tnr8o.apps.googleusercontent.com">
      <Router>
        <ScrollToTop />
        {theme === 'dark' ? (
          <style>{`
            :root {
                --color-bg: var(--color-night-bg);
                --color-fg: var(--color-night-fg);
                --color-fg2: var(--color-night-fg2);
                --color-text: var(--color-night-text);
                --color-primary: var(--color-night-primary);
                --color-complement: var(--color-night-complement);
                --color-complement-2: var(--color-night-complement-2);
                --color-danger: #d30930;
                --color-success: #14CC67;
                --color-shadow: var(--color-night-shadow);
                --color-active: var(--color-night-active);
                --color-text-light: var(--color-night-text-light);
            }
        `}</style>
        ) : (
          <style>{`
            :root {
                --color-bg: var(--color-day-bg);
                --color-fg: var(--color-day-fg);
                --color-fg2: var(--color-day-fg2);
                --color-text: var(--color-day-text);
                --color-primary: var(--color-day-primary);
                --color-complement: var(--color-day-complement);
                --color-complement-2: var(--color-day-complement-2);
                --color-danger: #d30930;
                --color-success: #14CC67;
                --color-shadow: var(--color-day-shadow);
                --color-active: var(--color-day-active);
                --color-text-light: var(--color-day-text-light);
            }
        `}</style>
        )}

        <Nav>
          <Switch>
            {/* Static Pages */}
            <Route exact path="/">
              <Home />
            </Route>
            <Route exact path="/about">
              <About />
            </Route>
            <Route exact path="/careers">
              <Careers />
            </Route>
            <Route exact path="/help">
              <Help />
            </Route>
            <Route exact path="/books">
              <Books />
            </Route>
            <Route exact path="/groups">
              <Groups />
            </Route>
            <Route exact path="/vip">
              <Vip />
            </Route>
            <Route exact path="/events">
              <Events />
            </Route>
            <Route exact path="/onboarding">
              <Onboarding />
            </Route>
            <Route exact path="/ontario">
              <Redirect to="onboarding" />
            </Route>
            <Route exact path="/pntario">
              <Redirect to="onboarding" />
            </Route>
            <Route exact path="/ontairo">
              <Redirect to="onboarding" />
            </Route>
            <Route exact path="/ontraio">
              <Redirect to="onboarding" />
            </Route>
            <Route exact path="/tutorial-all">
              <TutorialAll />
            </Route>
            <Route exact path="/tutorial">
              <Redirect to="tutorial-all" />
            </Route>
            <Route exact path="/tutorial-edit-profile">
              <Redirect to="tutorial-my-profile" />
            </Route>
            <Route exact path="/tutorial-*">
              <TutorialGeneric />
            </Route>
            <Route exact path="/faq">
              <FAQ />
            </Route>
            <Route exact path="/gambling-problem">
              <GamblingProblem />
            </Route>
            <Route exact path="/terms-and-conditions">
              <TermsAndConditions />
            </Route>
            <Route exact path="/privacy-policy">
              <PrivacyPolicy />
            </Route>
            {/* NON STATIC PAGES */}
            <Route exact path="/sportsbook-survey">
              <Redirect to="/questionnaires/2" />
            </Route>
            {/*<ProtectedRoute exact path="/betlink">*/}
            <Redirect exact from="/betlink" to="/best-sportsbooks/" />
            {/*</ProtectedRoute>/*}

            {/* games and odds */}
            <ProtectedRoute exact path="/games">
              <Games />
            </ProtectedRoute>
            <ProtectedRoute exact path="/edit-betslip">
              <EditBetslip />
            </ProtectedRoute>
            <ProtectedRoute exact path="/games/:id">
              <Game />
            </ProtectedRoute>
            <ProtectedRoute exact path="/odds-comparison">
              <OddsComp />
            </ProtectedRoute>
            <ProtectedRoute exact path="/futures">
              <FuturesSched />
            </ProtectedRoute>
            <ProtectedRoute exact path="/futures/future">
              <Future />
            </ProtectedRoute>
            {/* auth */}
            <Route exact path="/login">
              <Login />
            </Route>
            <Route exact path="/login-email">
              <LoginEmail />
            </Route>
            <Route exact path="/signup">
              <Signup />
            </Route>
            <Route exact path="/social-callback/:provider">
              <SocialCallback />
            </Route>
            <Route exact path="/signup-email">
              <SignupEmail />
            </Route>
            <Route exact path="/forgot-password">
              <ForgotPassword />
            </Route>
            <ProtectedRoute exact path="/onboarding/select-experience">
              <SelectExperience />
            </ProtectedRoute>
            <ProtectedRoute exact path="/onboarding/odds-compare">
              <OddsCompare />
            </ProtectedRoute>
            <ProtectedRoute exact path="/onboarding/select-SBPRO">
              <SelectSBPro />
            </ProtectedRoute>
            <ProtectedRoute exact path="/onboarding/should-open-more-books">
              <ShouldOpenMoreBooks />
            </ProtectedRoute>
            <ProtectedRoute exact path="/onboarding/link-books">
              <LinkBooks />
            </ProtectedRoute>
            <ProtectedRoute exact path="/onboarding/find-users">
              <FindUsers />
            </ProtectedRoute>
            <ProtectedRoute exact path="/onboarding/select-SBRookie">
              <SelectSBRookie />
            </ProtectedRoute>
            <ProtectedRoute exact path="/onboarding/view-allSB">
              <ViewAllSB />
            </ProtectedRoute>
            <ProtectedRoute exact path="/onboarding/unregulated-rookie">
              <UnregulatedRookie />
            </ProtectedRoute>
            <ProtectedRoute exact path="/onboarding/meantime">
              <Meantime />
            </ProtectedRoute>
            <Route
              exact
              path="/u/:username"
              render={props => <PublicProfile {...props} />}
            />
            <ProtectedRoute exact path="/find-bettors">
              <Marketplace />
            </ProtectedRoute>
            <ProtectedRoute exact path="/settings">
              <Settings />
            </ProtectedRoute>
            <ProtectedRoute exact path="/my-picks">
              <MyPicks />
            </ProtectedRoute>
            <ProtectedRoute exact path="/my-picks-hidden">
              <MyPicksHidden />
            </ProtectedRoute>
            <ProtectedRoute exact path="/analysis">
              <Analysis />
            </ProtectedRoute>
            <ProtectedRoute exact path="/accounting">
              <Accounting />
            </ProtectedRoute>
            <ProtectedRoute exact path="/leaderboard">
              <Leaderboard />
            </ProtectedRoute>
            {/*
            <ProtectedRoute exact path="/find-bets">
              <FindBets />
            </ProtectedRoute>
            */}
            <Route exact path="/outlink">
              <Outlink />
            </Route>
            {/* New onboarding session routes*/}
            <Route exact path="/intake">
              <Intake />
            </Route>
            <Route exact path="/reschedule">
              <Intake />
            </Route>
            <Route
              exact
              path={[
                '/session/:session_id/:attendee_id',
                '/session/:session_id/:attendee_id/:page',
              ]}
            >
              <AttendeeSession />
            </Route>
            <Route exact path="/session/:session_id/">
              <Session />
            </Route>
            <StaffProtectedRoute exact path="/staff-session/:session_id/">
              <StaffSession />
            </StaffProtectedRoute>
            <StaffProtectedRoute exact path="/staff/my-dashboard">
              <MyOnboardingDashboard />
            </StaffProtectedRoute>
            {/*<StaffProtectedRoute exact path="/staff / my - metrics">
          <MyMetrics />
        </StaffProtectedRoute>*/}
            <StaffProtectedRoute exact path="/staff/csm-metrics">
              <CSMMetrics />
            </StaffProtectedRoute>
            <StaffProtectedRoute exact path="/staff/sales-metrics">
              <SalesTeamMetrics />
            </StaffProtectedRoute>
            <StaffProtectedRoute exact path="/staff/overall-metrics">
              <OverallMetrics3 />
            </StaffProtectedRoute>
            <StaffProtectedRoute exact path="/staff/attendees/:id?">
              <Attendees />
            </StaffProtectedRoute>
            <StaffProtectedRoute exact path="/staff/hosts">
              <Hosts />
            </StaffProtectedRoute>
            <StaffProtectedRoute exact path="/staff/schedule">
              <Schedule />
            </StaffProtectedRoute>
            <StaffProtectedRoute exact path="/staff/sessions">
              <AllSessions />
            </StaffProtectedRoute>
            <StaffProtectedRoute exact path="/staff/payments">
              <Payments />
            </StaffProtectedRoute>
            <StaffProtectedRoute exact path="/staff/sportsbook-infos">
              <SportsbookInfos />
            </StaffProtectedRoute>
            <StaffProtectedRoute exact path="/staff/referral-codes">
              <StaffReferralCodes />
            </StaffProtectedRoute>
            <StaffProtectedRoute exact path="/staff/verify-attendees">
              <VerifyAttendees />
            </StaffProtectedRoute>
            <StaffProtectedRoute exact path="/staff/troubleshooter">
              <TroubleShooterAdmin />
            </StaffProtectedRoute>
            <StaffProtectedRoute exact path="/staff/analysis/clickstats">
              <AffiliateStatsDashboard />
            </StaffProtectedRoute>
            <StaffProtectedRoute exact path="/staff/analysis/earnings">
              <AffiliateEarningDashboard />
            </StaffProtectedRoute>
            <StaffProtectedRoute exact path="/staff/affiliate-metrics">
              <AffiliateEarningMetrics />
            </StaffProtectedRoute>
            <StaffProtectedRoute
              exact
              path="/staff/affiliate-metrics/time-travel"
            >
              <AffiliateEarningSnapshots />
            </StaffProtectedRoute>
            <StaffProtectedRoute exact path="/staff/sync-to-airtable">
              <SyncToAirtable />
            </StaffProtectedRoute>
            <StaffProtectedRoute exact path="/staff/check-book-data">
              <CheckBookData />
            </StaffProtectedRoute>
            <StaffProtectedRoute exact path="/staff/upload-email-reports">
              <EmailReports />
            </StaffProtectedRoute>
            {/*
          <Route exact path="/sportsbooks/bonus-hunter">
            <BonusHunter />
          </Route>
          <Route exact path="/sportsbooks">
            <Sportsbooks />
          </Route>
          <Route exact path="/sportsbooks/reviews/:slug">
            <Review />
          </Route>
          <Route exact path="/questionnaires/:id">
            <Survey />
          </Route>

          <Route
            exact
            path="/competitions/:id"
            render={props => <Competition {...props} />}
          />
          */}
            {/* completed transactions & stripe things */}
            <Route exact path="/transaction-processed">
              <MobileTransactionProcessed />
            </Route>
            <Route exact path="/transaction-cancelled">
              <MobileTransactionCancelled />
            </Route>
            <Route exact path="/stripe-connect-setup">
              <StripeConnnectSetup />
            </Route>
            <Route
              exact
              path="/complete-purchase/:purchaseSessionID"
              render={props => <SetupPurchaseSession {...props} />}
            />
            <ProtectedRoute exact path="/unsubscribe">
              <Unsub />
            </ProtectedRoute>
            {/* influencer pages */}
            {/*<Route exact path="/best-sportsbooks/:slug?">
              <InfluencerBooks />
            </Route>
            <Redirect exact from="/:slug" to="/best-sportsbooks/:slug?" />
            */}
            <Route path="/best-sportsbooks">
              {() => {
                window.location.href =
                  'https://betstamp.com/sportsbook-reviews';
                return <></>;
              }}
            </Route>
            {/* 404 not found */}
            <Route>
              <FourOhFour />
            </Route>
          </Switch>
        </Nav>
      </Router>
    </GoogleOAuthProvider>
  );
}
