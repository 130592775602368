import React from 'react';
import styled from 'styled-components';

// components
import { Col } from 'components/generic/Layout';
import AutoColoredAmount from 'components/generic/AutoColoredAmount';

export default function UserSummaryCircles(props) {
  const { user } = props;

  let units = user.stats.total_profit / user.stats.bet_size;
  if (isNaN(units)) {
    units = 0;
  }

  return (
    <>
      <Circle title="Total Units">
        <span
          style={{
            color:
              units > 0
                ? 'var(--color-dollar-green)'
                : units === 0
                ? 'var(--color-text)'
                : 'var(--color-dollar-red)',
          }}
        >
          {units > 0 ? '+' : ''}
          {Math.round(units * 10) / 10}
        </span>
        <small>
          ({user.stats.W}-{user.stats.L}-{user.stats.P})
        </small>
      </Circle>

      <Circle title="ROI">
        <AutoColoredAmount bold={'true'} symbol={'%'}>
          {user.stats.roi}
        </AutoColoredAmount>
      </Circle>

      <Circle title="CLV">
        <AutoColoredAmount bold={'true'} symbol={'%'}>
          {user.stats.clv}
        </AutoColoredAmount>
      </Circle>
    </>
  );
}

const StyledCircle = styled.div`
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  border: 2px solid var(--color-primary);
  font-weight: bold;
  background-color: var(--color-fg);
  border-radius: 100%;

  flex: 1;

  width: 40%;
  padding-top: 40%;
  position: relative;

  & > div > * {
    font-size: var(--text-xl);
  }
  & > div > small {
    font-size: var(--text-md);
  }

  @media only screen and (max-width: 625px) {
    width: 80%;
    padding-top: 80%;

    & > div > * {
      font-size: var(--text-md);
    }
    & > div > small {
      font-size: var(--text-sm);
    }
  }
`;

function Circle(props) {
  return (
    <Col>
      <StyledCircle>
        <Col
          style={{
            width: '100%',
            height: '100%',
            position: 'absolute',
            top: 0,
            justifyContent: 'center',
          }}
        >
          {props.children}
        </Col>
      </StyledCircle>
      <h6>{props.title}</h6>
    </Col>
  );
}
