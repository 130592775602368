import React, { useState, useMemo, useEffect } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { useUpdateEffect } from 'react-use';

// component
import ActivityIndicator from 'components/generic/ActivityIndicator';
import { Row, Col } from 'components/generic/Layout';
import { AuthButton } from 'components/AuthButton';
import OddBtn from 'components/generic/OddBtn2';
import AffiliateLink from 'components/AffiliateLink';

// actions
import {
  getGamePropListNext,
  getGameProps,
  getGamePropsNext,
  getGamePropOptions,
  clearGamePropData,
} from 'actions';

const NavBar = styled.div`
  height: 100vh;
  flex: 1 0 128px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: stretch;
  padding: var(--space-xs) 0;
  overflow-y: auto;
`;

const OddsContainer = styled.div`
  height: 100vh;
  flex: 2 0 256px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  overflow-y: auto;
  padding-left: var(--space-xs);
`;

const StyledTab = styled.div`
  flex: 0;
  width: 100%;
  cursor: pointer;
  background-color: ${props =>
    props.highlight ? 'var(--color-active)' : 'null'};
  border-right: ${props =>
    props.highlight && !props.mobile
      ? '2px solid var(--color-primary)'
      : 'null'};
  border-bottom: ${props =>
    props.highlight && props.mobile
      ? '2px solid var(--color-primary)'
      : 'null'};
  font-weight: ${props => (props.highlight ? 700 : 200)};
  padding: var(--space-xs) var(--space-md);
  transition: all var(--std-transition);
  &:hover {
    background-color: var(--color-active);
  }
`;

function getDisplayType(prop_type, game) {
  if (!prop_type) return null;
  if (
    prop_type.includes('Money Line') ||
    prop_type.includes('Moneyline') ||
    prop_type.includes('MoneyLine') ||
    prop_type.includes('Team to Score First') ||
    prop_type.includes('Team to Score Last') ||
    prop_type.includes('Most Hits') ||
    prop_type.includes('Draw No Bet')
  ) {
    return 'ML';
  } else if (
    prop_type.includes('Touchdown') ||
    prop_type.includes('touchdown') ||
    prop_type.includes('TD')
  ) {
    return 'TD';
  } else if (
    prop_type.includes('Both Teams To Score') ||
    prop_type.includes('Extra Innings') ||
    game?.sport === 'MMA'
  ) {
    return 'YN';
  } else if (prop_type.includes('Set Market')) {
    return 'SET';
  } else {
    return 'TOT';
  }
}

export default function GameLine(props) {
  const dispatch = useDispatch();
  const reduxProps = useSelector(state => ({
    user: state.authReducer.user,
    game: state.gameReducer.game,
    // prop list
    gamePropList: state.gameReducer.gamePropList,
    isLoadingGamePropList: state.gameReducer.isLoadingGamePropList,
    fetchGamePropListError: state.gameReducer.fetchGamePropListError,
    gamePropListNext: state.gameReducer.gamePropListNext,
    isLoadingGamePropListNext: state.gameReducer.isLoadingGamePropListNext,
    // props
    gameProps: state.gameReducer.gameProps,
    isLoadingGameProps: state.gameReducer.isLoadingGameProps,
    fetchGamePropError: state.gameReducer.fetchGamePropsError,
    gamePropsNext: state.gameReducer.gamePropsNext,
    isLoadingGamePropNext: state.gameReducer.isLoadingGamePropsNext,
    // options (for touchdown props)
    gamePropOptions: state.gameReducer.gamePropOptions,
    isLoadingGamePropOptions: state.gameReducer.isLoadingGamePropOptions,
    fetchGamePropOptionsError: state.gameReducer.fetchGamePropOptionsError,
    allBooksMap: state.authReducer.allBooksMap,
  }));
  const {
    user,
    game,
    // prop list
    gamePropList,
    isLoadingGamePropList,
    fetchGamePropListError,
    gamePropListNext,
    isLoadingGamePropListNext,
    // props
    gameProps,
    fetchGamePropError,
    isLoadingGameProps,
    gamePropsNext,
    isLoadingGamePropsNext,
    // options
    gamePropOptions,
    isLoadingGamePropOptions,
    fetchGamePropOptionsError,
    allBooksMap,
  } = reduxProps;

  useEffect(() => {
    return () => dispatch(clearGamePropData());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [propType, setPropType] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState(null);

  useEffect(() => {
    if (!propType && gamePropList && gamePropList?.length) {
      const gp = gamePropList[0];
      setPropType(gp);
      let dt = getDisplayType(gp, game);
      _initialLoad(gp, dt);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propType, gamePropList]);

  const displayType = useMemo(() => {
    return getDisplayType(propType, game);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propType]);

  const _initialLoad = (prop_type, displayType) => {
    if ((displayType !== 'TD' || selectedOptions) && game.id && prop_type) {
      dispatch(
        getGameProps({
          game_id: game.id,
          prop_type: prop_type,
        })
      );
    } else if (displayType === 'TD' && game.id && prop_type) {
      dispatch(getGamePropOptions({ game_id: game.id, prop_type: prop_type }));
    }
  };

  // prop list
  const _getNext = () => {
    if (gamePropListNext && !isLoadingGamePropListNext) {
      dispatch(getGamePropListNext());
    }
  };
  useEffect(() => {
    _getNext();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gamePropListNext]);

  // game props
  const _getNextGameProps = () => {
    if (gamePropsNext && !isLoadingGamePropsNext) {
      dispatch(getGamePropsNext());
    }
  };
  useUpdateEffect(() => {
    _getNextGameProps();
  }, [gamePropsNext]);

  // renders odds
  const _renderItem = ({ item }) => {
    if (displayType === 'TD' && !selectedOptions) {
      return null;
    }

    const COMMON = {
      id: item.id,
      book: allBooksMap[item.book_id],
      game: game,
      prop_type: item.prop_type,
      type_name: 'Game Prop',
      number: item.number,
      period: 'FT',
      is_live: false,
      bet_type: 'BET',
      subtype: 'Total',
    };
    let side1 = 'Over';
    let side2 = 'Under';
    let side3 = null;

    if (displayType === 'ML') {
      side1 = game.home_team.name;
      side2 = null;
      if ('Tie' in item.options && item.options['Tie']) {
        side2 = 'Tie';
      }
      side3 = game.away_team.name;
    }
    if (displayType === 'YN') {
      side1 = 'Yes';
      side2 = 'No';
    }
    if (displayType === 'SET') {
      let obj = Object.keys(item.options);
      if (obj[0].includes(game.home_team.name)) {
        side1 = obj[0];
        side2 = obj[1];
      } else {
        side1 = obj[1];
        side2 = obj[0];
      }
    }
    if (displayType === 'TD' && selectedOptions) {
      side1 = selectedOptions[0];
      side2 = selectedOptions[1];
      side3 = selectedOptions[2];
    }
    return (
      <Row
        key={`game-prop-prop-key-${item.id}`}
        style={{
          flex: 0,
          margin: 'var(--space-xxxxs) 0',
          alignItems: 'center',
        }}
      >
        <Row
          style={{ flex: 0.8, alignItems: 'center', justifyContent: 'center' }}
        >
          {COMMON.book.is_affiliate ? (
            <AffiliateLink
              book={COMMON.book}
              fromPage="Gameline Game Props Web"
              shape="bar"
              hideLinkable
              hideText
              fallbackToGeneric
              imgStyle={{
                width: '100%',
                height: 'auto',
                maxHeight: '64px',
                borderRadius: '4px',
              }}
              usePreferred
            />
          ) : COMMON.book.generic_banner ? (
            <img
              alt={COMMON.book.name}
              src={COMMON.book.generic_banner}
              style={{
                width: '100%',
                height: 'auto',
                borderRadius: '4px',
              }}
            />
          ) : (
            <Row
              style={{
                width: '100%',
                alignItems: 'center',
                justifyContent: 'flex-start',
                border: '1px solid var(--color-text-light)',
                borderRadius: '4px',
                padding: 'var(--space-xxs)',
              }}
            >
              <Col
                style={{
                  flex: '0 0 24px',
                  width: '24px',
                  height: '24px',
                  borderRadius: '24px',
                  backgroundColor: 'var(--color-text)',
                  border: '2px solid var(--color-primary)',
                  textAlign: 'center',
                }}
              >
                <span
                  style={{
                    textAlign: 'center',
                    color: 'var(--color-bg)',
                  }}
                >
                  {COMMON.book.name[0]}
                </span>
              </Col>
              <small style={{ margin: '0 var(--space-xxs)' }}>
                {COMMON.book.name}
              </small>
            </Row>
          )}
        </Row>

        <OddBtn
          key={`oddbtn-game-prop-1-${item.id}-${side1}`}
          btnTheme="borderless"
          odd={{ ...COMMON, side: side1, odds: item.options[side1] }}
        />
        {displayType === 'TOT' && (
          <b style={{ flex: 1, textAlign: 'center' }}>{item.number}</b>
        )}
        {side2 && (
          <OddBtn
            key={`oddbtn-game-prop-2-${item.id}-${side2}`}
            btnTheme="borderless"
            odd={{ ...COMMON, side: side2, odds: item.options[side2] }}
          />
        )}
        {side3 && (
          <OddBtn
            key={`oddbtn-game-prop-3-${item.id}-${side3}`}
            btnTheme="borderless"
            odd={{ ...COMMON, side: side3, odds: item.options[side3] }}
          />
        )}
      </Row>
    );
  };

  if (fetchGamePropListError && fetchGamePropOptionsError) {
    return (
      <h5 style={{ textAlign: 'center' }}>
        We ran into an error getting game props
      </h5>
    );
  }

  return (
    <Row style={{ flexFlow: 'row nowrap' }}>
      {gamePropList && gamePropList?.length > 0 && !isLoadingGamePropList && (
        <NavBar>
          {isLoadingGamePropList && <ActivityIndicator size={3} />}
          {gamePropList &&
            gamePropList.map(gp => (
              <StyledTab
                key={`gp-prop-list-prop-${gp}`}
                highlight={propType === gp}
                onClick={() => {
                  setPropType(gp);
                  let dt = getDisplayType(gp, game);
                  _initialLoad(gp, dt);
                }}
              >
                {gp}
              </StyledTab>
            ))}
        </NavBar>
      )}
      {displayType === 'TD' && (
        <NavBar>
          {isLoadingGamePropOptions && <ActivityIndicator size={3} />}
          {gamePropOptions &&
            gamePropOptions.map(item => (
              <StyledTab
                key={`gp-options-list-prop-${item}`}
                highlight={selectedOptions?.includes(item)}
                onClick={() => {
                  if (!selectedOptions) {
                    dispatch(
                      getGameProps({ game_id: game.id, prop_type: propType })
                    );
                    setSelectedOptions([item]);
                  } else {
                    if (selectedOptions.includes(item)) {
                      if (selectedOptions.length === 1) {
                        setSelectedOptions(null);
                      } else {
                        setSelectedOptions(
                          selectedOptions.filter(o => o !== item)
                        );
                      }
                    } else {
                      setSelectedOptions(
                        [item, ...selectedOptions].slice(0, 3)
                      );
                    }
                  }
                }}
              >
                {item}
              </StyledTab>
            ))}
        </NavBar>
      )}
      <OddsContainer>
        {fetchGamePropError && (
          <h5 style={{ textAlign: 'center' }}>
            We ran into an error getting {propType} props
          </h5>
        )}
        {!propType && gamePropList?.length > 0 && (
          <h6 style={{ textAlign: 'center', marginTop: 'var(--space-md)' }}>
            Select a prop on the left
          </h6>
        )}
        {propType &&
          displayType === 'TD' &&
          !selectedOptions &&
          gamePropList?.length > 0 && (
            <h6 style={{ textAlign: 'center', marginTop: 'var(--space-md)' }}>
              Select a prop option on the left
            </h6>
          )}
        {gamePropList?.length === 0 && (
          <h6 style={{ textAlign: 'center', marginTop: 'var(--space-md)' }}>
            We don't have game props for this game yet, check back later!
          </h6>
        )}
        {!fetchGamePropError && propType && (
          <>
            {(displayType !== 'TD' || selectedOptions) &&
              gameProps?.length > 0 && (
                <Row
                  style={{
                    flex: 0,
                    padding: 'var(--space-sm) 0',
                    backgroundColor: 'var(--color-fg)',
                    alignItems: 'center',
                  }}
                >
                  <b style={{ flex: 0.8, textAlign: 'center' }}>Book</b>
                  {displayType !== 'TD' && (
                    <b style={{ flex: 1, textAlign: 'center' }}>
                      {displayType === 'TOT' && 'Over'}
                      {displayType === 'YN' && 'Yes'}
                      {(displayType === 'ML' || displayType === 'SET') &&
                        game.home_team.name}
                      {displayType === null && '-'}
                    </b>
                  )}
                  {(displayType === 'TOT' ||
                    (displayType === 'ML' &&
                      gameProps &&
                      gameProps?.length > 0 &&
                      'Tie' in gameProps[0]?.options)) && (
                    <b style={{ flex: 1, textAlign: 'center' }}>
                      {displayType === 'TOT' && '#'}
                      {displayType === 'ML' && 'Tie'}
                    </b>
                  )}
                  {displayType === 'TD' && (
                    <>
                      {selectedOptions.map(opt => (
                        <b
                          key={`sel-opt-game-prop-${opt}`}
                          style={{ flex: 1, textAlign: 'center' }}
                        >
                          {opt}
                        </b>
                      ))}
                    </>
                  )}
                  {displayType !== 'TD' && (
                    <b style={{ flex: 1, textAlign: 'center' }}>
                      {displayType === 'TOT' && 'Under'}
                      {displayType === 'YN' && 'No'}
                      {(displayType === 'ML' || displayType === 'SET') &&
                        game.away_team.name}
                      {displayType === null && '-'}
                    </b>
                  )}
                </Row>
              )}
          </>
        )}

        {isLoadingGameProps && <ActivityIndicator size={3} />}

        {gameProps && gameProps.map(item => _renderItem({ item }))}

        {(fetchGamePropOptionsError ||
          fetchGamePropListError ||
          (((!isLoadingGamePropOptions || !isLoadingGameProps) &&
            displayType !== 'TD') ||
          selectedOptions
            ? gameProps?.length === 0
            : gamePropOptions?.length === 0)) && (
          <>
            <h6 style={{ textAlign: 'center', margin: 'var(--space-md)' }}>
              We couldn&apos;t find game props for {game.home_team.name} vs{' '}
              {game.away_team.name}
            </h6>
            {user.show_books !== 'ALL' && (
              <Col style={{ margin: '0 auto', maxWidth: '472px' }}>
                <span style={{ textAlign: 'center' }}>
                  Try changing your settings for{' '}
                  <b>"Show Additional Books From"</b> to
                  <b> "All Books"</b> to see more game props.
                </span>
                <AuthButton onPress={() => props.setTab('Settings')}>
                  Click here to change books settings
                </AuthButton>
              </Col>
            )}
          </>
        )}
      </OddsContainer>
    </Row>
  );
}
