import React from 'react';
import styled from 'styled-components';
import { IoCheckmarkCircle, IoRadioButtonOff } from 'react-icons/io5';

const OptionBtn = styled.button`
  flex: 0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  background-color: var(--color-fg);
  outline: none;
  padding: var(--space-xs);
  margin: var(--space-xxxxs) var(--space-xxxs);
  cursor: pointer;
  transition: all var(--std-transition);
  border-radius: var(--std-border-radius);
  border: none;
  color: var(--color-text);
  &:hover {
    box-shadow: 0 2px 8px 0 var(--color-shadow);
    background-color: var(--color-text-light);
  }
  &:active {
    background-color: var(--color-bg);
  }
`;

export default function ListOptionBtn(props) {
  const { selected, text, Icon } = props;
  return (
    <OptionBtn {...props}>
      {selected ? (
        <IoCheckmarkCircle size={32} color={'var(--color-success)'} />
      ) : (
        <IoRadioButtonOff size={32} color={'var(--color-text)'} />
      )}
      {Icon && <Icon />}
      <p
        style={{
          flex: 1,
          margin: 0,
          marginLeft: 'var(--space-xs)',
          textAlign: 'left',
        }}
      >
        {text}
      </p>
    </OptionBtn>
  );
}
