import { Observable } from 'rxjs';
import 'rxjs/add/operator/exhaustMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import { ajax } from 'rxjs/observable/dom/ajax';
import { BACKEND_API_URL, getHeaders } from 'utils';

import {
  FORGOT_PASSWORD,
  forgotPasswordSuccess,
  forgotPasswordFailure,
} from 'actions';

export default function forgotPassword(action$, state$) {
  return action$.ofType(FORGOT_PASSWORD).exhaustMap(action => {
    return ajax
      .post(
        BACKEND_API_URL + 'api/rest-auth/password/reset/',
        action.params,
        getHeaders(state$)
      )
      .map(res => forgotPasswordSuccess(res))
      .catch(error => Observable.of(forgotPasswordFailure(error.xhr)));
  });
}
