import { Observable } from 'rxjs';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/of';
import { ajax } from 'rxjs/observable/dom/ajax';
import { BACKEND_API_URL, getHeaders } from 'utils';

import {
  SYNC_ALL_ACCOUNTS,
  syncAllAccountsSuccess,
  syncAllAccountsFailure,
  promptTwoFA,
} from 'actions';

export default function syncAllAccounts(action$, state$) {
  return action$.ofType(SYNC_ALL_ACCOUNTS).switchMap(action => {
    return ajax
      .post(
        BACKEND_API_URL + 'api/autosync/sync_all_accounts/',
        action.params,
        getHeaders(state$)
      )
      .map(data => {
        return syncAllAccountsSuccess(data, action.params);
      })
      .catch(error =>
        Observable.of(syncAllAccountsFailure(error.xhr, action.params))
      );
  });
}
