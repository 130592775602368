/**
 * Rounds decimal odds to 2 decimal places
 * @param {String} odds String version of the odds.
 * @returns {String} Rounded odds.
 */
export default function roundOdds(odds) {
  let num = (Math.round(parseFloat(odds) * 100) / 100).toString();
  if (num.includes('.')) {
    let afterDecimal = num.split('.')[1];
    if (afterDecimal.length === 1) {
      num += '0';
    }
  } else {
    num += '.00';
  }

  return num;
}
