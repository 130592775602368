import React from 'react';

// components
import { Row, Col } from 'components/generic/Layout';
import { BetstampStampInverted } from 'components/generic/Logos';

export default function PrivacyPolicy() {
  return (
    <div className="container" style={{ padding: 'var(--space-xxl)' }}>
      <Row>
        <Col>
          <BetstampStampInverted height={128} />
          <h1 style={{ marginTop: 0 }}>Privacy Policy</h1>
        </Col>
      </Row>

      <p>
        By visiting our platform or submitting your Personal Information (as
        defined below) to us by any means, you are giving your consent to our
        collection, use and disclosure of your Personal Information as described
        in this Privacy Policy. If you do not agree to the terms of this Privacy
        Policy, please exit our Site and do not provide Personal Information to
        us.
      </p>
      <p>
        Notice: This application is intended for use by adults only. By using
        this application you certify that you are at least 18 years of age. We
        do not knowingly or intentionally collect information from anyone under
        the age of 18. If you are under the age of 18, do not submit any
        Personal Information on the application. If you are under the age of 18
        and have submitted Personal Information to the application, please
        contact us to have this information removed at contact@betstamp.app.​
      </p>

      <h3>Collection of Your Information</h3>
      <p>
        We may collect information about you in a variety of ways. The
        information we may collect via the Application depends on the content
        and materials you use, and includes:
      </p>

      <InfoType
        title="Personal Information"
        text="Demographic and other personally identifiable
        information (such as your name, email address, IP address and
        country/region) that you voluntarily give to us when choosing to
        participate in various activities related to the Application. If you choose
        to share data about yourself via your profile, or other interactive areas
        of the Application, please be advised that all data you disclose in these
        areas is public and your data will be accessible to anyone who accesses
        the Application."
      />
      <InfoType
        title="Derivative Data"
        text="
        Information our servers automatically collect when you
        access the Application, such as your native actions that are integral to
        the Application, including liking, re-blogging, or replying to a post, as
        well as other interactions with the Application and other users via
        server log files."
      />
      <InfoType
        title="Financial Data"
        text="Financial information, such as data related to your payment method (e.g.
        valid credit card number, card brand, expiration date) that we may
        collect when you make a purchase is not stored by betstamp. All
        financial information is stored by our payment processor, Stripe, and
        you are encouraged to review their privacy policy and contact them
        directly for responses to your questions."
      />
      <InfoType
        title="Geo-Location Information"
        text="We may request access or permission to and
        track location-based information from your mobile device, either
        continuously or while you are using the Application, to provide location-based services. If you wish to change our access or permissions, you may do so in your device’s settings."
      />
      <InfoType
        title="Mobile Device Access"
        text="We may request access or permission to certain
        features from your mobile device, including your mobile device’s
        camera for the purposes of voluntary upload of profile photos. If you
        wish to change our access or permissions, you may do so in your
        device’s settings."
      />
      <InfoType
        title="Mobile Device Data"
        text="Device information such as your mobile device ID
        number, model, and manufacturer, version of your operating system,
        phone number, country, location, and any other data you choose to
        provide."
      />
      <InfoType
        title="Push Notifications"
        text="We may request to send you push notifications
        regarding your account or the Application. If you wish to opt out from
        receiving these types of communications, you may turn them off in your
        device’s settings."
      />
      <InfoType
        title="Third-Party Data"
        text="Information from third parties, such as Personal
        Information or network friends, if you connect your account to the third
        party and grant the Application permission to access this information."
      />
      <InfoType
        title="Data From Contests, Giveaways, and Surveys"
        text="Personal and other information you may provide when entering contests or giveaways and/or responding to surveys."
      />
      <h6>Information Collected Automatically:</h6>
      <InfoType
        title="Cookies and Web Beacons"
        text="We may use cookies, web beacons, tracking pixels, and other tracking technologies on the Application to help customize the Application and improve your experience. When you access the Application, your personal information is not collected through the use of tracking technology."
      />
      <InfoType
        title="Usage Information, Website & App Analytics"
        text="
        We may track your usage information while in the application, including profile views, page clicks and in-app usage such as search criteria. We may also track usage data, such as the source address from which the page request originates, the date and time of the page request, the referring tracking URL and other parameters in the URL. We may also partner with selected third-party vendors to allow tracking technologies and remarketing services on the Application through the use of first party cookies and third-party cookies, to, among other things, analyze and track users’ use of the Application, determine the popularity of certain content, and better understand online activity. By accessing the Application, you consent to the collection and use of your information by third-party vendors. We do not transfer Personal Information to any third-party vendor, only usage information."
      />
      <InfoType
        title="Third-Party Websites"
        text="The Application may contain links to third-party websites and applications of interest, including advertisements and external services, that may or may not be affiliated with us. Once you have used these links to leave the Application, any information you provide to these third parties is not covered by this Privacy Policy, and we cannot guarantee the safety and privacy of your information. Before visiting and providing any information to any third-party websites, you should inform yourself of the privacy policies and practices (if any) of the third party responsible for that website or platform, and should take those steps necessary to, in your discretion, protect the privacy of your information. We are not responsible for the content or privacy and security practices and policies of any third parties, including other sites, services or applications that may be linked to or from the Application."
      />

      <h3>Use of Your Information and Limiting Collection</h3>
      <p>
        Betstamp collects and uses Personal Information to improve and market
        its platform and deliver or carry out transactions requested. Uses may
        include providing you with better customer service, performing research
        and analysis aimed at bettering our platform, and displaying advertising
        or content specific to your preferences. Specific instances may include:
      </p>
      <ul>
        <li>To assist with account creation that you may or may not request</li>

        <li>To assist with account support that you may or may not request</li>

        <li>When you register for content, info, or materials</li>

        <li>
          When you participate in contests or surveys, which we may have from
          time to time
        </li>

        <li>To assist you in buying or selling sports predictions</li>

        <li>To enforce our terms and conditions</li>

        <li>To conduct investigations to protect Betstamp and its customers</li>

        <li>To notify you of any updates or improvements to our product</li>
        <li>
          To send out periodic emails advertising Betstamp’s products that you
          may or may not request
        </li>

        <li>When submitting comments or concerns to us via contact methods</li>
      </ul>
      <p>
        Betstamp will limit its collection of your Personal Information to that
        which is necessary for the purposes identified in this Policy. Your
        information will only be retained for the duration necessary to fulfill
        these purposes, except where required by statute or legislation with
        respect to retention periods.
      </p>

      <h3>Sharing With 3rd Parties</h3>
      <p>
        We may share information we have collected about you in certain
        situations. We do not share Personal Information with third parties, but
        may share usage data and other information. Your information may be
        disclosed as follows:
      </p>
      <InfoType
        title="By Law or to Protect Rights"
        text="If we believe the release of information
about you is necessary to respond to legal process, to investigate or
remedy potential violations of our policies, or to protect the rights,
property, and safety of others, we may share your information as
permitted or required by any applicable law, rule, or regulation. This
includes exchanging information with other entities for fraud protection
and credit risk reduction."
      />
      <InfoType
        title="Third-Party Service Providers"
        text="We may share your information with third
parties that perform services for us or on our behalf, including payment
processing, data analysis, email delivery, hosting services, customer
service, and marketing assistance. We do not share Personal
Information with third parties but may share usage data and
information."
      />
      <InfoType
        title="Marketing Communications"
        text="With your consent, or with an opportunity
for you to withdraw consent, we may share your information with third
parties for marketing purposes, as permitted by law."
      />
      <InfoType
        title="Interactions with Other Users"
        text="If you interact with other users of the
Application, those users may see your name, profile photo, and
descriptions of your activity. If you wish to hide all Personal Information,
you can make your profile private under your &#39;My Profile&#39; settings. If
your profile is private, other users will not be able to view your Personal
Information on the platform."
      />
      <InfoType
        title="Online Postings"
        text="When you post comments, contributions or other
content to the Application, your posts may be viewed by all users and
may be publicly distributed outside the Application in perpetuity."
      />
      <InfoType
        title="Affiliates"
        text="We may share your information with our affiliates, in which
case we will require those affiliates to honour this Privacy Policy.
Affiliates include our parent company and any subsidiaries, joint venture
partners or other companies that we control or that are under common
control with us. We will not share Personal Information with affiliates,
we may share usage data and information."
      />
      <InfoType
        title="Other Third Parties"
        text="We may share your information with advertisers
and investors for the purpose of conducting general business analysis.
We may also share your information with such third parties for
marketing purposes, as permitted by law. We will not share Personal
Information with other third parties, we may share usage data and
information."
      />
      <InfoType
        title="Sale or Bankruptcy"
        text="If we reorganize or sell all or a portion of our assets,
undergo a merger, or are acquired by another entity, we may transfer
your information to the successor entity. If we go out of business or
enter bankruptcy, your information would be an asset transferred or
acquired by a third party. You acknowledge that such transfers may
occur and that the transferee may decline to honour commitments we
made in this Privacy Policy. We are not responsible for the actions of
third parties with whom you share personal or sensitive data, and we
have no authority to manage or control third-party solicitations. If you
no longer wish to receive correspondence, emails or other
communications from third parties, you are responsible for contacting
the third party directly."
      />

      <InfoType
        title="International Transfers"
        text="Information collected under this Policy may be
stored and processed in any country in which we or our affiliates
maintain facilities. We endeavor to protect your information in
accordance with this Policy and with all applicable laws within each
relevant jurisdiction."
      />

      <h3>Privacy Choices, Your Rights, and Do-Not-Track Signals</h3>
      <InfoType
        title="Deleting Your Account and Data"
        text="Betstamp allows you to deactivate
your account and any data associated with it for any reason at any time.
In order to deactivate your account, go to the edit profile section of the
Betstamp app and select deactivate account. In order to completely
delete your account and all of the data associated with it, contact us at
contact@betstamp.app and request for your data to be deleted. We will
respond with a confirmation within 21 days. Betstamp may retain and
use your email address after deactivation to send you periodic
advertisements and offers relating to Betstamp’s products."
      />
      <InfoType
        title="Right to Access Personal Information"
        text="You have the right to access your
Personal Information which has been collected under this Policy. If you
wish to access, challenge or amend your Personal Information, please
contact us at contact@betstamp.app and we will respond within 21
days."
      />
      <InfoType
        title="Do-Not-Track Notice"
        text="We do not currently recognize or respond to Do-Not-Track signals."
      />
      <InfoType
        title="Communication Settings"
        text="You have the right to opt out of receiving
promotional materials or other forms of contact which may include
email or directly in the app. If you wish to opt out of receiving these
materials, you can do so directly by following the unsubscribe prompts
in the materials. This option does not apply to communications
primarily for the purpose of administering orders, contracts, support,
product safety warnings, software updates, or other administrative and
transactional notices."
      />
      <InfoType
        title="Option to Opt Out Of Sharing"
        text="You have the option to opt out of having
your information shared with third parties such as our partners. If you
wish to opt out of having your personal information shared, email us
directly at contact@betstamp.app and let us know. We will respond
with a confirmation within 21 days."
      />

      <h3>Updating and Safeguarding Your Information</h3>
      <p>
        Update your personal information including your email, name, username,
        or location any time in the My Profile page under the settings tab.
        Alternatively you can email us at any time at contact@betstamp.app to
        request to have your personal information changed.
      </p>
      <p>
        Personal information which is used on an ongoing basis shall be as
        accurate, complete and up-to-date as necessary for the purposes for
        which it is to be used. We will not routinely update personal
        information which is not necessary to fulfill the purposes outlined in
        this Policy.
      </p>
      <p>
        We currently implement bank-level encryption and follow industry
        standards for safeguarding your information, including physical and
        technological security measures. Any employees with access to Personal
        Information are trained and aware of the importance of maintaining the
        security of your Personal Information. We are committed to protecting
        your information, with due regard for the sensitivity of the information
        in our possession as required and appropriate.
      </p>
      <p>
        Given that this information is collected over the internet we cannot
        guarantee the security of any information you transmit. Any voluntary
        transmission of information, including your Personal Information is at
        your own risk. We maintain appropriate safeguards for the protection of
        your information, but we are not responsible for the circumvention of
        any security measures on the platform.
      </p>
      <h3>Changes</h3>
      <p>
        We reserve the right to make changes to this Privacy Policy at any time
        and for any reason. We will alert you about any changes by updating the
        “Last updated” date of this Privacy Policy. You are encouraged to
        periodically review this Privacy Policy to stay informed of updates. You
        will be deemed to have been made aware of, will be subject to, and will
        be deemed to have accepted the changes in any revised Privacy Policy by
        your continued use of the Application after the date such revised
        Privacy Policy is posted.
      </p>
      <p>
        This Privacy Policy does not apply to the third-party online/mobile
        store from which you install the Application or make payments, which may
        also collect and use data about you. We are not responsible for any of
        the data collected by any such third party.
      </p>
      <p>
        For questions or concerns please email us directly at
        contact@betstamp.app.
      </p>

      <h4>Last Updated: November 9, 2023</h4>
    </div>
  );
}

function InfoType({ title, text }) {
  return (
    <p>
      <span style={{ textDecoration: 'underline' }}>{title}:</span> {text}
    </p>
  );
}
