import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

// components
import { Row, Col } from 'components/generic/Layout';
import AutoColoredAmount from 'components/generic/AutoColoredAmount';
// import FollowBtn from 'components/FollowBtn';
import ProfilePic from 'components/generic/ProfilePic';
import { HammerLogo } from './generic/Logos';

const Wrapper = styled.div`
  border-radius: var(--std-border-radius);
  padding: var(--space-xs) var(--space-sm);
  border: 2px solid var(--color-primary);
  background-color: var(--color-fg);
  transition: all var(--std-transition);

  position: relative;

  &:hover {
    box-shadow: 0 2px 8px 0 var(--color-shadow);
  }

  &:active {
    box-shadow: inset 0 0 0 100vh rgba(0, 0, 0, 0.1);
  }
`;

// const POPOVER_WIDTH = 246;
// const Popover = styled.div`
//   width: ${POPOVER_WIDTH}px;
//   box-shadow: 0 2px 8px 0 var(--color-shadow);
//   pointer-events: none;
//   position: absolute;
//   top: 52px;
//   left: 24px;
//   z-index: 1000;
//   background-color: var(--color-fg);
//   margin-top: 12px;
//   border-radius: var(--std-border-radius);
//   opacity: 0;
//   padding: var(--space-xs);
//   transition: all var(--std-transition);

//   display: flex;
//   flex-flow: column wrap;
//   justify-content: flex-start;
//   align-items: flex-start;

//   &:after {
//     content: '';
//     position: absolute;
//     top: -12px;
//     left: 111px;
//     border-style: solid;
//     border-width: 0 12px 12px;
//     border-color: var(--color-fg) transparent;
//     display: block;
//     width: 0;
//     z-index: 0;
//   }

//   &:hover {
//     pointer-events: auto;
//     opacity: 1;
//   }
// `;

const STAT_SYMBOL_MAP = {
  roi: '%',
  clv: '%',
  pending: null,
};

export default function FeaturedUser(props) {
  const { user, hightlightStats, mobile } = props;
  // NOTE: typo     ^

  if (mobile) {
    return <MobileFeaturedUser {...props} />;
  }

  return (
    <Link style={{ textDecoration: 'none' }} to={`/u/${user.username}`}>
      <Wrapper style={props.style}>
        <Row
          style={{
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          {!props.noPic && (
            <ProfilePic
              user={user}
              style={{
                height: '64px',
                width: '64px',
              }}
              src={
                user.profile_picture
                  ? user.profile_picture
                  : 'https://betstamp-public.sfo2.digitaloceanspaces.com/default-account.png'
              }
              alt={`${user.username}'s profile`}
            />
          )}
          {/* {!disablePopup && (
          <Popover className="user-popover">
          <b>{user.username}</b>
          <span style={{ marginBottom: '4px' }}>
          {user.stats.pending} pending
          </span>
          {user.bio !== '' && <span>{user.bio}</span>}
          <br />
          <FollowBtn user={user} />
          </Popover>
        )} */}

          {hightlightStats.map(stat => (
            <Col key={`feature-stat-${stat}-${user.id}`} style={{ flex: 0 }}>
              <small style={{ opacity: 0.75 }}>{stat.toUpperCase()}</small>

              {STAT_SYMBOL_MAP[stat] ? (
                <AutoColoredAmount bold="true" symbol={STAT_SYMBOL_MAP[stat]}>
                  {user.stats[stat]}
                </AutoColoredAmount>
              ) : (
                <b>{user.stats[stat]}</b>
              )}
            </Col>
          ))}
        </Row>

        <Row
          style={{
            alignItems: 'center',
            justifyContent: 'flex-start',
          }}
        >
          <span style={{ maxWidth: '80%' }}>
            <h5
              style={{
                fontWeight: 'bold',
                margin: 'var(--space-xs) 0',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
              }}
            >
              {user.username}
            </h5>
          </span>
          {!!user.hammer_link && (
            <HammerLogo style={{ margin: '0 var(--space-xs)' }} height={12} />
          )}
        </Row>

        <Row style={{ alignItems: 'center', justifyContent: 'space-between' }}>
          <span>{user.stats.record}</span>
          {props.featured && <span>{user.stats.pending} pending</span>}
        </Row>
      </Wrapper>
    </Link>
  );
}

function MobileFeaturedUser(props) {
  const { user, hightlightStats } = props;
  return (
    <Wrapper style={{ padding: 'var(--space-xxxs)', ...props.style }}>
      <Link
        style={{
          flex: 1,
          width: '100%',
          position: 'relative',
          textDecoration: 'none',
        }}
        className={'underline-on-hover'}
        to={`/u/${user.username}`}
      >
        <Col>
          {!props.noPic && (
            <ProfilePic
              user={user}
              style={{ height: '64px', width: '64px' }}
              src={
                user.profile_picture
                  ? user.profile_picture
                  : 'https://betstamp-public.sfo2.digitaloceanspaces.com/default-account.png'
              }
              alt={`${user.username}'s profile`}
            />
          )}

          <h6
            style={{
              marginTop: 'var(--space-xxs)',
              fontWeight: 'bold',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              width: '100%',
              textAlign: 'center',
            }}
          >
            {user.username}
          </h6>

          <Row style={{ width: '100%' }}>
            {hightlightStats.map(stat => (
              <Col key={`feature-stat-${stat}-${user.id}`} style={{ flex: 0 }}>
                <small style={{ opacity: 0.75 }}>{stat.toUpperCase()}</small>

                {STAT_SYMBOL_MAP[stat] ? (
                  <AutoColoredAmount bold="true" symbol={STAT_SYMBOL_MAP[stat]}>
                    {user.stats[stat]}
                  </AutoColoredAmount>
                ) : (
                  <b>{user.stats[stat]}</b>
                )}
              </Col>
            ))}
          </Row>

          {props.featured && (
            <span style={{ margin: 'var(--space-xxs) 0' }}>
              {user.stats.pending} pending
            </span>
          )}
        </Col>
      </Link>
    </Wrapper>
  );
}
