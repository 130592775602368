// this reducer is for doing this with other users
// ex. get other users profile, view leaderboard, follow other users
import {
  GET_LEADERBOARD,
  GET_LEADERBOARD_SUCCESS,
  GET_LEADERBOARD_FAILURE,
  GET_PUBLIC_PROFILE,
  GET_PUBLIC_PROFILE_SUCCESS,
  GET_PUBLIC_PROFILE_FAILURE,
  GET_PUBLIC_PROFILE_BY_USERNAME,
  GET_PUBLIC_PROFILE_BY_USERNAME_SUCCESS,
  GET_PUBLIC_PROFILE_BY_USERNAME_FAILURE,
  GET_FOLLOWERS,
  GET_FOLLOWERS_SUCCESS,
  GET_FOLLOWERS_FAILURE,
  GET_FOLLOWERS_NEXT,
  GET_FOLLOWING,
  GET_FOLLOWING_SUCCESS,
  GET_FOLLOWING_FAILURE,
  GET_FOLLOWING_NEXT,
  FOLLOW_USER,
  FOLLOW_USER_SUCCESS,
  FOLLOW_USER_FAILURE,
  UNFOLLOW_USER,
  UNFOLLOW_USER_SUCCESS,
  UNFOLLOW_USER_FAILURE,
  SET_PACKAGE,
  BUY_PACKAGE,
  BUY_PACKAGE_SUCCESS,
  BUY_PACKAGE_FAILURE,
  RESET_PURCHASE_SUCCESS,
  EDIT_RELATIONSHIP,
  EDIT_RELATIONSHIP_SUCCESS,
  EDIT_RELATIONSHIP_FAILURE,
  RESET_ERRORS_USER,
} from 'actions';

const initialState = {
  leaderboard: [],
  following: [],
  followers: [],
  publicProfile: {},
  userPicks: {},
  userPackage: {},
  sessionId: null,
  purchaseSuccess: false,
  fetchError: null,
  isLoading: false,
  isFollowersLoading: false,
  isPicksLoading: false,
  isNextPicksLoading: false,
  isNextFollowLoading: false,
  followLoading: false,
  isPurchaseLoading: false,
  userPicksNext: null,
  followingNext: null,
  followersNext: null,
  couldNotFindUser: false,
  isLoadingFollowing: false,
  // to keep track of user relationship after follow/unfollow
  // use this dict to override the relationship status in
  // leaderboard, publicProfile, etc, without needed to refresh
  // leadboard, publicProfile, etc
  userRels: {},
};

export default function publicUserReducer(state = initialState, action) {
  switch (action.type) {
    case GET_LEADERBOARD:
      return Object.assign({}, state, {
        ...state,
        isLoading: true,
        fetchError: null,
      });
    case GET_LEADERBOARD_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        isLoading: false,
        leaderboard: action.payload,
      });
    case GET_LEADERBOARD_FAILURE:
      return Object.assign({}, state, {
        ...state,
        isLoading: false,
        fetchError: action.error,
      });

    case GET_PUBLIC_PROFILE:
      return Object.assign({}, state, {
        ...state,
        isLoading: true,
        fetchError: null,
        publicProfile: {},
      });
    case GET_PUBLIC_PROFILE_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        isLoading: false,
        publicProfile: action.payload,
      });
    case GET_PUBLIC_PROFILE_FAILURE:
      return Object.assign({}, state, {
        ...state,
        isLoading: false,
        fetchError: action.error,
      });

    case GET_PUBLIC_PROFILE_BY_USERNAME:
      return Object.assign({}, state, {
        ...state,
        isLoading: true,
        fetchError: null,
        couldNotFindUser: false,
        publicProfile: {},
      });
    case GET_PUBLIC_PROFILE_BY_USERNAME_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        isLoading: false,
        publicProfile: action.payload,
        couldNotFindUser: false,
      });
    case GET_PUBLIC_PROFILE_BY_USERNAME_FAILURE:
      return Object.assign({}, state, {
        ...state,
        isLoading: false,
        fetchError: action.error,
        couldNotFindUser: action.error === null,
      });

    case GET_FOLLOWERS:
      return Object.assign({}, state, {
        ...state,
        isFollowersLoading: true,
        isNextFollowLoading: false,
        fetchError: null,
        followers: [],
      });
    case GET_FOLLOWERS_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        isFollowersLoading: false,
        isNextFollowLoading: false,
        followers: state.followers.concat(action.payload.results),
        followersNext: action.payload.next,
      });
    case GET_FOLLOWERS_FAILURE:
      return Object.assign({}, state, {
        ...state,
        isFollowersLoading: false,
        isNextFollowLoading: false,
        fetchError: action.error,
      });
    case GET_FOLLOWERS_NEXT:
      return Object.assign({}, state, {
        ...state,
        isNextFollowLoading: true,
        fetchError: action.error,
      });

    case GET_FOLLOWING:
      return Object.assign({}, state, {
        ...state,
        isLoadingFollowing: true,
        isNextFollowLoading: false,
        fetchError: null,
        following: [],
      });
    case GET_FOLLOWING_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        isLoadingFollowing: false,
        isNextFollowLoading: false,
        following: state.following.concat(action.payload.results),
        followingNext: action.payload.next,
      });
    case GET_FOLLOWING_FAILURE:
      return Object.assign({}, state, {
        ...state,
        isLoadingFollowing: false,
        isNextFollowLoading: false,
        fetchError: action.error,
      });
    case GET_FOLLOWING_NEXT:
      return Object.assign({}, state, {
        ...state,
        isNextFollowLoading: true,
        fetchError: action.error,
      });

    case FOLLOW_USER:
      return Object.assign({}, state, {
        ...state,
        followLoading: action.otherUserID,
        fetchError: null,
      });
    case FOLLOW_USER_SUCCESS:
      const addition =
        state.publicProfile.id ===
        action.payload.response.relationship_to_user.user
          ? 1
          : 0;

      let newUserRels = { ...state.userRels };
      newUserRels[action.otherUserID] =
        action.payload.response.relationship_to_user;

      return Object.assign({}, state, {
        ...state,
        publicProfile: {
          ...state.publicProfile,
          followers: state.publicProfile.followers + addition,
        },
        followLoading: false,
        userRels: newUserRels,
      });

    case FOLLOW_USER_FAILURE:
      return Object.assign({}, state, {
        ...state,
        followLoading: false,
        fetchError: action.error,
      });

    case UNFOLLOW_USER:
      return Object.assign({}, state, {
        ...state,
        followLoading: action.otherUserID,
        fetchError: null,
      });
    case UNFOLLOW_USER_SUCCESS:
      const subtraction =
        state.publicProfile.id ===
        action.payload.response.relationship_to_user.user
          ? 1
          : 0;

      let newUserRelsUnfollow = { ...state.userRels };
      newUserRelsUnfollow[action.otherUserID] =
        action.payload.response.relationship_to_user;

      return Object.assign({}, state, {
        ...state,
        publicProfile: {
          ...state.publicProfile,
          followers: state.publicProfile.followers - subtraction,
        },
        followLoading: false,
        userRels: newUserRelsUnfollow,
      });
    case UNFOLLOW_USER_FAILURE:
      return Object.assign({}, state, {
        ...state,
        followLoading: false,
        fetchError: action.error,
      });

    case SET_PACKAGE:
      return Object.assign({}, state, {
        ...state,
        userPackage: action.userPackage,
      });

    case BUY_PACKAGE:
      return Object.assign({}, state, {
        ...state,
        isPurchaseLoading: true,
        purchaseError: null,
        purchaseSuccess: null,
      });
    case BUY_PACKAGE_SUCCESS:
      let newUserRelsBuy = { ...state.userRels };
      newUserRelsBuy[action.otherUserID] =
        action.payload.response.relationship_to_user;
      return Object.assign({}, state, {
        ...state,
        publicProfile: {
          ...state.publicProfile,
        },
        isPurchaseLoading: false,
        purchaseSuccess: true,
        userRels: newUserRelsBuy,
      });
    case BUY_PACKAGE_FAILURE:
      return Object.assign({}, state, {
        ...state,
        isPurchaseLoading: false,
        purchaseError: action.error,
      });
    case RESET_PURCHASE_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        purchaseError: null,
        isPurchaseLoading: null,
        purchaseSuccess: null,
      });

    case EDIT_RELATIONSHIP:
      return Object.assign({}, state, {
        ...state,
        fetchError: null,
        publicProfile: {
          ...state.publicProfile,
          relationship_to_user: {
            ...state.publicProfile.relationship_to_user,
            notifications: action.notifications,
          },
        },
      });
    case EDIT_RELATIONSHIP_SUCCESS:
      let newUserRelsEditRelSucc = { ...state.userRels };
      newUserRelsEditRelSucc[action.otherUserID] =
        action.payload.response.relationship_to_user;

      return Object.assign({}, state, {
        ...state,
        publicProfile: {
          ...state.publicProfile,
          relationship_to_user: action.payload.response.relationship_to_user,
        },
        userRels: newUserRelsEditRelSucc,
      });
    case EDIT_RELATIONSHIP_FAILURE:
      return Object.assign({}, state, {
        ...state,
        fetchError: action.error,
      });

    case RESET_ERRORS_USER:
      return Object.assign({}, state, {
        ...state,
        fetchError: action.error,
        couldNotFindUser: false,
        publicProfile: {},
      });

    default:
      return state;
  }
}
