import React from 'react';
import ReactModal from 'react-modal';
import styled from 'styled-components';
import { IoClose } from 'react-icons/io5';
import { IconButton } from 'components/AuthButton';
import { Row } from 'components/generic/Layout';

const Wrapper = styled.div`
  background: transparent;
  width: 100%;
  display: flex;
  flex-flow: nowrap;
  justify-content: space-between;
  box-sizing: border-box;
`;

const LeftAlgin = styled.div`
  justify-self: flex-start;
`;

const desktopModalStyle = {
  content: {
    left: '16vw',
    right: '16vw',
    top: '10vh',
    bottom: '12vh',
    padding: 'var(--space-md) var(--space-md) 0 var(--space-md)',
    borderRadius: 'var(--std-border-radius)',
    borderColor: 'var(--color-shadow)',
    backgroundColor: 'var(--color-fg)',
    boxShadow: '0 2px 32px var(--color-shadow)',
    boxSizing: 'border-box',
  },
  overlay: {
    backgroundColor: 'transparent',
    backdropFilter: 'blur(4px)',
  },
};

const desktopSmallModalStyle = {
  content: {
    left: '30vw',
    right: '30vw',
    top: '18vh',
    bottom: '40vh',
    padding: 'var(--space-md) var(--space-md) 0 var(--space-md)',
    borderRadius: 'var(--text-xs)',
    borderColor: 'var(--color-shadow)',
    backgroundColor: 'var(--color-fg)',
    boxShadow: '0 2px 32px var(--color-shadow)',
    boxSizing: 'border-box',
  },
  overlay: {
    backgroundColor: 'transparent',
    backdropFilter: 'blur(4px)',
  },
};

const tabletModalStyle = {
  content: {
    left: '3vw',
    right: '3vw',
    top: '8vh',
    bottom: '5vh',
    padding: 'var(--space-md)',
    borderRadius: 'var(--std-border-radius)',
    borderColor: 'var(--color-shadow)',
    backgroundColor: 'var(--color-fg)',
    boxShadow: '0 2px 32px var(--color-shadow)',
    boxSizing: 'border-box',
  },
  overlay: {
    backgroundColor: 'transparent',
    backdropFilter: 'blur(4px)',
  },
};

const mobileModalStyle = {
  content: {
    left: '0',
    right: '0',
    top: '10vh',
    bottom: '0',
    padding: 'var(--space-sm)',
    paddingTop: 'var(--space-md)',
    borderRadius: '1rem',
    borderBottomLeftRadius: '0',
    borderBottomRightRadius: '0',
    borderColor: 'var(--color-shadow)',
    backgroundColor: 'var(--color-fg)',
    boxShadow: '0 2px 32px var(--color-shadow)',
    boxSizing: 'border-box',
  },
  overlay: {
    backgroundColor: 'transparent',
    backdropFilter: 'blur(4px)',
  },
};

ReactModal.setAppElement('#root');

export default function ModalWrapper(props) {
  let modalStyle = null;
  let width = window.innerWidth;
  if (width > 1024) {
    if (props.modalSize === 'small') modalStyle = desktopSmallModalStyle;
    else modalStyle = desktopModalStyle;
  } else if (width > 768) modalStyle = tabletModalStyle;
  else modalStyle = mobileModalStyle;

  if (props.backgroundColor) {
    modalStyle.content.backgroundColor = props.backgroundColor;
  }
  if (props.color) {
    modalStyle.content.color = props.color;
  }
  if (props.marginLeft && width > 1024) {
    modalStyle.content.marginLeft = props.marginLeft;
  }
  if (props.marginRight && width > 1024) {
    modalStyle.content.marginRight = props.marginRight;
  }
  if (props.marginTop && width > 1024) {
    modalStyle.content.marginTop = props.marginTop;
  }
  if (props.marginBottom && width > 1024) {
    modalStyle.content.marginBottom = props.marginBottom;
  }
  if (props.boxShadow) {
    modalStyle.content.boxShadow = props.boxShadow;
  }

  return (
    <ReactModal
      isOpen={props.modalIsOpen}
      style={
        props.style
          ? {
              ...modalStyle,
              content: { ...modalStyle.content, ...props.style.content },
            }
          : modalStyle
      }
      closeTimeoutMS={250}
      onRequestClose={props.onRequestClose}
      shouldCloseOnOverlayClick={!props.dontCloseOnEscapeOrOutsideClick}
      shouldCloseOnEsc={!props.dontCloseOnEscapeOrOutsideClick}
      id={props.id ? props.id : null}
    >
      <Wrapper>
        <LeftAlgin>
          {props.titleComponent ? (
            <>{props.title}</>
          ) : (
            <h4 style={{ margin: 0 }}>{props.title}</h4>
          )}
        </LeftAlgin>
      </Wrapper>
      {props.showX && (
        <Row style={{ justifyContent: 'flex-end', flex: 0 }}>
          <IconButton
            colorTheme="onbblue2"
            iconName={IoClose}
            iconSize={38}
            onPress={() => props.onRequestClose()}
          />
        </Row>
      )}
      {props.children}
    </ReactModal>
  );
}
