import React from 'react';
import styled from 'styled-components';

// components
import { Col } from 'components/generic/Layout';

const StyledTextarea = styled.textarea`
  min-width: 0;
  padding: var(--space-sm);
  outline: none;
  border: none;
  border-radius: var(--btn-border-radius);
  background: var(--color-fg);
  color: var(--color-text);
  font-size: var(--text-base-size);
  transition: all var(--std-transition);
  font-family: 'Lato', sans-serif;
  &:focus {
    box-shadow: 0 2px 4px var(--color-shadow);
  }
  &:hover {
    opacity: 0.95;
  }
`;

export default function Textarea(props) {
  const { type, placeholder, defaultValue, onChange, label } = props;
  return (
    <Col
      style={{
        alignItems: 'stretch',
        // margin: 'var(--space-md) var(--space-sm)',
        ...props.containerStyle,
      }}
    >
      {label && <label>&nbsp;&nbsp;{label}</label>}

      <StyledTextarea
        style={props.inputStyle}
        rows="5"
        type={type}
        placeholder={placeholder}
        defaultValue={defaultValue}
        onChange={onChange}
        {...props}
      />
    </Col>
  );
}
