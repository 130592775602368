// actions (excluding success and failure actions):
export const GET_ANALYSIS = 'GET_ANALYSIS';
export const SET_ANALYSIS = 'SET_ANALYSIS';
export const RESET_ERRORS_ANALYSIS = 'RESET_ERRORS_ANALYSIS';
export const GET_ANALYSIS_PRESETS = 'GET_ANALYSIS_PRESETS';
export const GET_ANALYSIS_PRESETS_NEXT = 'GET_ANALYSIS_PRESETS_NEXT';
export const SET_ANALYSIS_FILTER = 'SET_ANALYSIS_FILTER';
export const RESET_ANALYSIS_FILTER = 'RESET_ANALYSIS_FILTER';
export const CREATE_ANALYSIS_PRESET = 'CREATE_ANALYSIS_PRESET';
export const EDIT_ANALYSIS_PRESET = 'EDIT_ANALYSIS_PRESET';
export const DELETE_ANALYSIS_PRESET = 'DELETE_ANALYSIS_PRESET';
export const SET_OTHER_USER = 'SET_OTHER_USER';
export const GET_BET_SELECTION = 'GET_BET_SELECTION';
export const GET_BET_SELECTION_SUCCESS = 'GET_BET_SELECTION_SUCCESS';
export const GET_BET_SELECTION_FAILURE = 'GET_BET_SELECTION_FAILURE';
export const GET_BET_SELECTION_NEXT = 'GET_BET_SELECTION_NEXT';
export const GET_BET_SELECTION_CACHED = 'GET_BET_SELECTION_CACHED';
export const GET_GRAPH_DATA = 'GET_GRAPH_DATA';
export const GET_GRAPH_DATA_SUCCESS = 'GET_GRAPH_DATA_SUCCESS';
export const GET_GRAPH_DATA_FAILURE = 'GET_GRAPH_DATA_FAILURE';
export const GET_TAGS = 'GET_TAGS';

// GET ANALYSIS
export const GET_ANALYSIS_SUCCESS = 'GET_ANALYSIS_SUCCESS';
export const GET_ANALYSIS_FAILURE = 'GET_ANALYSIS_FAILURE';
export const getAnalysis = (urlparams, tab, force, selectedPresetIndex) => ({
  type: GET_ANALYSIS,
  urlparams,
  tab,
  force,
  selectedPresetIndex,
});
export const getAnalysisSuccess = (payload, fullURL) => ({
  type: GET_ANALYSIS_SUCCESS,
  payload,
  fullURL,
});
export const getAnalysisFailure = (error) => ({
  type: GET_ANALYSIS_FAILURE,
  error,
});

export const setAnalysis = (analysis, urlparams) => ({
  type: SET_ANALYSIS,
  analysis,
  urlparams,
});

// RESET ERRORS ANALYSIS
export const resetErrosAnalysis = () => ({
  type: RESET_ERRORS_ANALYSIS,
});

// GET ANALYSIS PRESETS
export const GET_ANALYSIS_PRESETS_SUCCESS = 'GET_ANALYSIS_PRESETS_SUCCESS';
export const GET_ANALYSIS_PRESETS_FAILURE = 'GET_ANALYSIS_PRESETS_FAILURE';
export const getAnalysisPresets = () => ({
  type: GET_ANALYSIS_PRESETS,
});
export const getAnalysisPresetsSuccess = (payload) => ({
  type: GET_ANALYSIS_PRESETS_SUCCESS,
  payload,
});
export const getAnalysisPresetsFailure = (error) => ({
  type: GET_ANALYSIS_PRESETS_FAILURE,
  error,
});
export const getAnalysisPresetsNext = () => ({
  type: GET_ANALYSIS_PRESETS_NEXT,
});

// SET ANALYSIS FILTER
export const setAnalysisFilter = (data) => ({
  type: SET_ANALYSIS_FILTER,
  data,
});

// RESET ANALYSIS FILTER
export const resetAnalysisFilter = () => ({
  type: RESET_ANALYSIS_FILTER,
});

// CREATE ANALYSIS PRESET
export const CREATE_ANALYSIS_PRESET_SUCCESS = 'CREATE_ANALYSIS_PRESET_SUCCESS';
export const CREATE_ANALYSIS_PRESET_FAILURE = 'CREATE_ANALYSIS_PRESET_FAILURE';
export const createAnalysisPreset = (preset) => ({
  type: CREATE_ANALYSIS_PRESET,
  preset,
});
export const createAnalysisPresetSuccess = (payload) => ({
  type: CREATE_ANALYSIS_PRESET_SUCCESS,
  payload,
});
export const createAnalysisPresetFailure = (error) => ({
  type: CREATE_ANALYSIS_PRESET_FAILURE,
  error,
});

// RESET CREATE ANALYSIS PRESET SUCCESS
export const RESET_CREATE_ANALYSIS_PRESET_SUCCESS =
  'RESET_CREATE_ANALYSIS_PRESET_SUCCESS';
export const resetCreateAnalysisPresetSuccess = () => ({
  type: RESET_CREATE_ANALYSIS_PRESET_SUCCESS,
});

// EDIT ANALYSIS PRESET
export const EDIT_ANALYSIS_PRESET_SUCCESS = 'EDIT_ANALYSIS_PRESET_SUCCESS';
export const EDIT_ANALYSIS_PRESET_FAILURE = 'EDIT_ANALYSIS_PRESET_FAILURE';
export const editAnalysisPreset = (preset) => ({
  type: EDIT_ANALYSIS_PRESET,
  preset,
});
export const editAnalysisPresetSuccess = (payload) => ({
  type: EDIT_ANALYSIS_PRESET_SUCCESS,
  payload,
});
export const editAnalysisPresetFailure = (error) => ({
  type: EDIT_ANALYSIS_PRESET_FAILURE,
  error,
});

// DELETE ANALYSIS PRESET
export const DELETE_ANALYSIS_PRESET_SUCCESS = 'DELETE_ANALYSIS_PRESET_SUCCESS';
export const DELETE_ANALYSIS_PRESET_FAILURE = 'DELETE_ANALYSIS_PRESET_FAILURE';
export const deleteAnalysisPreset = (presetid) => ({
  type: DELETE_ANALYSIS_PRESET,
  presetid,
});
export const deleteAnalysisPresetSuccess = (payload, presetid) => ({
  type: DELETE_ANALYSIS_PRESET_SUCCESS,
  payload,
  presetid,
});
export const deleteAnalysisPresetFailure = (error) => ({
  type: DELETE_ANALYSIS_PRESET_FAILURE,
  error,
});

// SET OTHER USER
export const setOtherUserAnalysis = (otherUser) => ({
  type: SET_OTHER_USER,
  otherUser,
});

// GET_BET_SELECTION
export const getBetSelection = (urlparams) => ({
  type: GET_BET_SELECTION,
  urlparams,
});

export const getBetSelectionSuccess = (fullURL, payload, isNext) => ({
  type: GET_BET_SELECTION_SUCCESS,
  fullURL,
  payload,
  isNext,
});

export const getBetSelectionFailure = (error) => ({
  type: GET_BET_SELECTION_FAILURE,
  error,
});

export const getBetSelectionNext = (nexturl, urlparams) => ({
  type: GET_BET_SELECTION_NEXT,
  nexturl,
  urlparams,
});

export const getBetSelectionCached = (urlparams) => ({
  type: GET_BET_SELECTION_CACHED,
  urlparams,
});

// GET_GRAPH_DATA
export const getGraphData = (
  urlparams,
  y1,
  interval,
  extraFilters,
  extraFiltersSlug,
  forGraph
) => ({
  type: GET_GRAPH_DATA,
  urlparams,
  y1,
  interval,
  extraFilters,
  extraFiltersSlug,
  forGraph,
});

export const getGraphDataSuccess = (payload, forGraph) => ({
  type: GET_GRAPH_DATA_SUCCESS,
  payload,
  forGraph,
});

export const getGraphDataFailure = (error) => ({
  type: GET_GRAPH_DATA_FAILURE,
  error,
});

// TAGS
export const GET_TAGS_SUCCESS = 'GET_TAGS_SUCCESS';
export const GET_TAGS_FAILURE = 'GET_TAGS_FAILURE';
export const GET_TAGS_NEXT = 'GET_TAGS_NEXT';

export const getTags = () => ({
  type: GET_TAGS,
});
export const getTagsSuccess = (payload) => ({
  type: GET_TAGS_SUCCESS,
  payload,
});
export const getTagsFailure = (error) => ({
  type: GET_TAGS_FAILURE,
  error,
});
export const getTagsNext = () => ({
  type: GET_TAGS_NEXT,
});
