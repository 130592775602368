import {
  GET_FEATURED,
  GET_FEATURED_SUCCESS,
  GET_FEATURED_FAILURE,
  RESET_ERRORS_FEATURED,
  GET_RECOMMENDED_USERS,
  GET_RECOMMENDED_USERS_SUCCESS,
  GET_RECOMMENDED_USERS_FAILURE,
  GET_RECOMMENDED_USERS_NEXT,
  GET_HAMMER_USERS,
  GET_HAMMER_USERS_SUCCESS,
  GET_HAMMER_USERS_FAILURE,
} from 'actions';

const initialState = {
  featured: [],
  recommended: [],
  hammerUsers: [],
  isLoading: false,
  fetchError: null,
  fetchRecommendedError: null,
  isLoadingRecommended: null,
  isLoadingRecommendedNext: null,
  recommendedNext: null,
  isLoadingHammer: false,
  fetchHammerError: null,
};

export default function featuredReducer(state = initialState, action) {
  switch (action.type) {
    case GET_FEATURED:
      return Object.assign({}, state, {
        ...state,
        fetchError: null,
        isLoading: true,
      });

    case GET_FEATURED_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        featured: action.payload,
        isLoading: false,
      });
    case GET_FEATURED_FAILURE:
      return Object.assign({}, state, {
        ...state,
        fetchError: action.error,
        isLoading: false,
      });

    case RESET_ERRORS_FEATURED:
      return Object.assign({}, state, {
        ...state,
        fetchError: null,
        isLoading: false,
      });

    case GET_RECOMMENDED_USERS:
      return Object.assign({}, state, {
        ...state,
        fetchRecommendedError: null,
        isLoadingRecommended: true,
        isLoadingRecommendedNext: false,
      });

    case GET_RECOMMENDED_USERS_NEXT:
      return Object.assign(
        {},
        {
          ...state,
          isLoadingRecommended: false,
          isLoadingRecommendedNext: true,
        }
      );

    case GET_RECOMMENDED_USERS_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        recommended: state.recommended.concat(action.payload.results),
        recommendedNext: action.payload.next,
        isLoadingRecommended: false,
        isLoadingRecommendedNext: false,
      });
    case GET_RECOMMENDED_USERS_FAILURE:
      return Object.assign({}, state, {
        ...state,
        fetchRecommendedError: action.error,
        isLoadingRecommended: false,
        isLoadingRecommendedNext: false,
      });

    case GET_HAMMER_USERS:
      return Object.assign({}, state, {
        ...state,
        isLoadingHammer: true,
        fetchHammerError: null,
      });

    case GET_HAMMER_USERS_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        hammerUsers: action.payload.results,
        isLoadingHammer: false,
      });

    case GET_HAMMER_USERS_FAILURE:
      return Object.assign({}, state, {
        ...state,
        fetchHammerError: action.error,
        isLoadingHammer: false,
      });

    default:
      return state;
  }
}
