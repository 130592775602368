import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

// components
import BetSelection from 'components/analysis/BetSelection';
import AnalysisFilters from 'components/analysis/AnalysisFilters';
import { Row } from 'components/generic/Layout';

// actions
import { getBetSelection } from 'actions';

const Wrapper = styled.div`
  flex: 0.22;
  min-width: 296px;
  max-width: 396px;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: stretch;
  background-color: var(--color-fg);
  box-sizing: border-box;
  overflow-y: hidden;
  overflow-x: hidden;
`;

const ListWrapper = styled.div`
  flex: 1;
  overflow-y: auto;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: stretch;
`;

const Tab = styled.button`
  flex: 1;
  font-size: var(--text-sm);
  text-align: center;
  text-decoration: none;
  outline: none;
  transition: all var(--std-transition);
  color: var(--text-color);
  box-sizing: border-box;
  padding: var(--space-sm) 0;
  line-height: 1;
  background: transparent;
  border: none;
  border-bottom: ${(props) => {
    if (props.selected) {
      return '2px solid var(--color-primary)';
    }
    return '2px solid transparent';
  }};

  opacity: ${(props) => {
    if (props.selected) {
      return '1';
    }
    return '0.75';
  }};

  cursor: pointer;

  &:hover {
    background-color: var(--color-bg);
  }
  &:active {
    opacity: 0.5;
    box-shadow: inset 0 0 0 100vh var(--color-bg);
  }
`;

export default function AnalysisSidebar(props) {
  const dispatch = useDispatch();
  const reduxProps = useSelector((state) => ({
    currentAnalysis: state.analysisReducer.currentAnalysis,
    currentUrlParams: state.analysisReducer.currentUrlParams,
  }));

  const { currentAnalysis, currentUrlParams } = reduxProps;

  useEffect(() => {
    if (
      currentAnalysis &&
      currentUrlParams &&
      currentAnalysis.bets.length === 0 &&
      !currentAnalysis.initialBetsLoaded
    ) {
      dispatch(getBetSelection(currentUrlParams));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAnalysis, currentUrlParams]);

  const [tab, setTab] = useState('filters');

  return (
    <Wrapper>
      <Row style={{ flex: 0, width: '100%' }}>
        <Tab
          selected={tab === 'bets'}
          onClick={() => {
            setTab('bets');
          }}
        >
          WAGERS{' '}
          {currentAnalysis && !isNaN(currentAnalysis.betCount)
            ? `(${currentAnalysis.betCount})`
            : ''}
        </Tab>
        <Tab
          selected={tab === 'filters'}
          onClick={() => {
            setTab('filters');
          }}
        >
          FILTERS
        </Tab>
      </Row>
      <ListWrapper id="analysis-infinite-scroll-target">
        {
          {
            bets: <BetSelection />,
            filters: <AnalysisFilters />,
          }[tab]
        }
      </ListWrapper>
    </Wrapper>
  );
}
