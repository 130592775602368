import React from 'react';
import { IoStar } from 'react-icons/io5';

// components
import { Row, Col } from 'components/generic/Layout';
import { AuthButton } from 'components/AuthButton';

const EXP = {
  Rookie: {
    title: 'New Sports Bettor',
    text: "I don't have my own betting account, I rarely bet",
    stars: ['fill', 'empty', 'empty'],
  },
  Experienced: {
    title: 'Casual Sports Bettor',
    text: 'I have my own betting account, I bet every so often',
    stars: ['fill', 'fill', 'empty'],
  },
  Professional: {
    title: 'Pro Sports Bettor',
    text: 'I have one or more of my own betting accounts, I bet all the time',
    stars: ['fill', 'fill', 'fill'],
  },
};

export default function ExperienceBox(props) {
  const { exp, userExp, onPress } = props;

  return (
    <AuthButton
      overrideChildren
      colorTheme={userExp === exp ? 'inverted' : 'fg'}
      containerStyle={{
        borderRadius: 8,
        minHeight: 86,
        marginBottom: 'var(--space-md)',
      }}
      btnStyle={{
        borderRadius: 8,
        borderColor: 'var(--color-primary)',
        height: 86,
      }}
      onPress={onPress}
    >
      <Row style={{ alignItems: 'center' }}>
        <Col style={{ flex: 3, alignItems: 'flex-start', textAlign: 'left' }}>
          <h6
            style={{
              fontWeight: 'bold',
              marginBottom: 0,
              color: userExp === exp ? 'white' : 'var(--color-primary)',
            }}
          >
            {EXP[exp].title}
          </h6>
          <i
            style={{
              color: userExp === exp ? 'white' : 'var(--color-text)',
              padding: 'var(--space-xxxxs)',
              paddingRight: 'var(--space-sm)',
              paddingLeft: 0,
            }}
          >
            {EXP[exp].text}
          </i>
        </Col>
        <Row style={{ flex: 1.1, justifyContent: 'space-evenly' }}>
          {EXP[exp].stars.map((star, i) => (
            <span
              key={`exp-box-${i}-${EXP[exp]}`}
              style={{
                backgroundColor: 'var(--color-fg)',
                borderRadius: 16,
                padding: 'var(--space-xxxxs)',
                margin: '0 var(--space-xxxxs)',
                width: 21,
                height: 21,
              }}
            >
              <IoStar
                size={16}
                color={
                  star === 'fill'
                    ? 'var(--color-primary)'
                    : 'var(--color-text-light)'
                }
              />
            </span>
          ))}
        </Row>
      </Row>
    </AuthButton>
  );
}
