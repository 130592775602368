import React, { useState, useEffect } from 'react';

// components
import { AuthTextInput } from 'components/AuthTextInput';

export default function SpreadTotalInput(props) {
  const [number, setNumber] = useState(props.defaultValue);

  // a change to the default value should cause a "reset"
  // this change was needed to properly support change book
  // on parlay bet
  useEffect(() => {
    setNumber(props.defaultValue);
  }, [props.defaultValue]);

  return (
    <AuthTextInput
      containerStyle={props.inputContainerStyle}
      type={'number'}
      step={props.step || 0.5}
      value={number}
      label={number === '' ? 'Please enter a number' : ''}
      inputRowStyle={
        props.inputStyle
          ? props.inputStyle
          : { backgroundColor: 'var(--color-bg)' }
      }
      onChangeText={newNumber => {
        setNumber(newNumber);
        props.handleChange(newNumber);
      }}
    />
  );
}
