import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

// utils
import {
  toAmericanOdds,
  cleanAmericanOdds,
  isAmericanOddsValid,
  toDecimalOdds,
  cleanDecimalOdds,
  isDecimalOddsValid,
  roundOdds,
} from 'utils';

// components
import { AuthTextInput } from 'components/AuthTextInput';

export default function OddsInput(props) {
  const reduxProps = useSelector(state => ({
    user: state.authReducer.user,
  }));
  const { user } = reduxProps;
  const { odds_preference } = user;

  const [odds, setOdds] = useState(
    odds_preference === 0
      ? toAmericanOdds(props.defaultValue)
      : roundOdds(props.defaultValue)
  );

  useEffect(() => {
    if (parseFloat(props.defaultValue) !== parseFloat(odds)) {
      setOdds(
        odds_preference === 0
          ? toAmericanOdds(props.defaultValue)
          : roundOdds(props.defaultValue)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.defaultValue, odds_preference]);

  return (
    <AuthTextInput
      containerStyle={props.inputContainerStyle}
      inputRowStyle={
        props.inputStyle
          ? props.inputStyle
          : { backgroundColor: 'var(--color-bg)' }
      }
      value={odds}
      type={odds_preference ? 'number' : 'text'}
      step={odds_preference ? 0.01 : 1}
      pattern={'/^[d +-]+$/'}
      onChangeText={oddsValue => {
        const text = oddsValue;
        if (odds_preference === 0) {
          const cleaned = cleanAmericanOdds(text);
          const isValid = isAmericanOddsValid(cleaned);
          let decimal = '';

          if (isValid) decimal = toDecimalOdds(cleaned);
          props.handleChange(decimal, 'odds', isValid);

          setOdds(cleaned);
        } else {
          let cleaned = cleanDecimalOdds(text);

          const isValid = isDecimalOddsValid(cleaned);

          props.handleChange(cleaned, 'odds', isValid);

          setOdds(cleaned);
        }
      }}
    />
  );
}
