import { Observable } from 'rxjs';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import { ajax } from 'rxjs/observable/dom/ajax';
import { BACKEND_API_URL, getHeaders } from 'utils';

import {
  GET_FULL_TIME_ODDS,
  getFullTimeOddsSuccess,
  getFullTimeOddsFailure,
} from 'actions';

export default function getFullTimeOdds(action$, state$) {
  return action$.ofType(GET_FULL_TIME_ODDS).switchMap(action => {
    let url =
      BACKEND_API_URL + `api/gameline/?game_id=${action.game_id}&period=FT`;

    return ajax
      .getJSON(url, getHeaders(state$))
      .map(data => getFullTimeOddsSuccess(data))
      .catch(error => Observable.of(getFullTimeOddsFailure(error.xhr)));
  });
}
