import { Observable } from 'rxjs';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import { ajax } from 'rxjs/observable/dom/ajax';
import { BACKEND_API_URL, getHeaders } from 'utils';

import { GET_TAGS, getTagsSuccess, getTagsFailure } from 'actions';

export default function getTags(action$, state$) {
  return action$.ofType(GET_TAGS).switchMap(() => {
    let url = BACKEND_API_URL + `api/tags/`;
    return ajax
      .getJSON(url, getHeaders(state$))
      .map(data => getTagsSuccess(data))
      .catch(error => Observable.of(getTagsFailure(error.xhr)));
  });
}
