import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

// components
import Cell from 'components/Cell';

const TR = styled.tr`
  cursor: ${props => {
    if (props.clickable) {
      return 'pointer';
    }
    return 'auto';
  }};
  background-color: ${props => {
    if (props.selected) {
      return 'var(--color-text-light)';
    }
    return 'transparent';
  }};
  &:hover {
    background-color: var(--color-text-light);
  }
`;

export default function SortableTable(props) {
  const { rows } = props;

  const [sortBy, setSortBy] = useState(null);
  const [sortedRows, setSortedRows] = useState(rows);
  const [expandedRows, setExpandedRows] = useState({});
  const [selectedRow, setSelectedRow] = useState('');

  useEffect(() => {
    setSortedRows(rows);
  }, [rows]);

  const _sortRows = (sortKey, sortDirection) => {
    setSortBy(sortKey);
    let middleRows = rows.slice(1, rows.length - 1);

    middleRows.sort((a, b) => {
      let aValue = a.cells.find(d => d.calculation === sortKey).text;
      let bValue = b.cells.find(d => d.calculation === sortKey).text;

      aValue = parseFloat(aValue.replace('$', '').replace('%', ''));
      bValue = parseFloat(bValue.replace('$', '').replace('%', ''));

      if (sortDirection === 'up') {
        if (aValue < bValue) return -1;
        return 1;
      }

      if (aValue < bValue) return 1;
      return -1;
    });

    setSortedRows([
      { ...rows[0] },
      ...middleRows,
      { ...rows[rows.length - 1] },
    ]);
  };

  return (
    <table
      cellSpacing={0}
      style={{
        margin: 'auto',
        borderTop: '2px solid var(--color-text-light)',
        marginTop: 'var(--space-sm)',
        width: '100%',
        ...props.style,
      }}
    >
      <thead>
        {sortedRows.slice(0, 1).map((row, i) => (
          <TR key={`analysis-row-${i}`}>
            {row.cells.map((cell, j) => (
              <Cell
                lastRow={i === sortedRows.length - 1}
                key={`analysis-cell-headers-${j}`}
                rowType={row.type}
                cell={cell}
                numCols={row.cells.length}
                showSortIcon
                onClickSort={_sortRows}
                isSortingByThis={cell.text === sortBy}
                GROUP_SPLIT_CHAR={row.GROUP_SPLIT_CHAR}
              />
            ))}
          </TR>
        ))}
      </thead>
      <tbody>
        {sortedRows.slice(1).map((row, i) => (
          <React.Fragment key={`analysis-row-${i}`}>
            <TR
              title={`Click to filter the line graph by ${row.urlFilterSlug}`}
              clickable={row.urlFilter || row.group === 'Overall'}
              selected={selectedRow === row.group}
              onClick={() => {
                if (row.urlFilter || row.group === 'Overall') {
                  if (selectedRow === row.group) {
                    props.onUnClickRow();
                    setSelectedRow('');
                  } else {
                    props.onClickRow(row);
                    setSelectedRow(row.group);
                  }
                }
              }}
            >
              {row.cells.map((cell, j) => (
                <Cell
                  lastRow={i === sortedRows.length - 2}
                  key={`analysis-cell-parent-row-${j}`}
                  rowType={row.type}
                  cell={cell}
                  numCols={row.cells.length}
                  is_childrow={row.is_childrow}
                  rowExpandable={row.subrows.length > 0}
                  rowExpanded={expandedRows[row.group]}
                  toggleExpandRow={() => {
                    let er = { ...expandedRows };
                    er[row.group] = !er[row.group];
                    setExpandedRows(er);
                  }}
                  GROUP_SPLIT_CHAR={row.GROUP_SPLIT_CHAR}
                />
              ))}
            </TR>
            {expandedRows[row.group] && (
              <>
                {row.subrows.map((subrow, i) => (
                  <TR
                    key={`analysis-subrow-${i}`}
                    title={`Click to filter the line graph by ${subrow.urlFilterSlug}`}
                    clickable={subrow.urlFilter || subrow.group === 'Overall'}
                    selected={selectedRow === subrow.group}
                    onClick={() => {
                      // if (subrow.urlFilter || subrow.group === 'Overall') {
                      //   props.onClickParentRow(subrow);
                      //   setSelectedRow(subrow.group);
                      // }
                      if (subrow.urlFilter || subrow.group === 'Overall') {
                        if (selectedRow === subrow.group) {
                          props.onUnClickRow();
                          setSelectedRow('');
                        } else {
                          props.onClickRow(subrow);
                          setSelectedRow(subrow.group);
                        }
                      }
                    }}
                  >
                    {subrow.cells.map((cell, j) => (
                      <Cell
                        key={`analysis-cell-subrow-${j}-${i}`}
                        rowType={subrow.type}
                        cell={cell}
                        numCols={subrow.cells.length}
                        is_childrow={subrow.is_childrow}
                        GROUP_SPLIT_CHAR={row.GROUP_SPLIT_CHAR}
                      />
                    ))}
                  </TR>
                ))}
              </>
            )}
          </React.Fragment>
        ))}
      </tbody>
    </table>
  );
}
