import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { IoCheckmarkCircleOutline, IoMailOutline } from 'react-icons/io5';

// utils
import { isEmailValid } from 'utils';

// components
import { AuthButton } from 'components/AuthButton';
import { AuthTextInput } from 'components/AuthTextInput';
import ErrorDisplay from 'components/generic/ErrorDisplay';

// actions
import {
  changeEmail,
  checkEmailAvailable,
  resetErrors,
  sendEmailVerification,
} from 'actions';

export default function ResetEmail(props) {
  const dispatch = useDispatch();
  const reduxProps = useSelector(state => ({
    emailIsAvailable: state.authReducer.emailIsAvailable,
    changingEmail: state.authReducer.changingEmail,
    changeEmailError: state.authReducer.changeEmailError,
    emailChangeSuccess: state.authReducer.emailChangeSuccess,
  }));
  const {
    emailIsAvailable,
    changingEmail,
    changeEmailError,
    emailChangeSuccess,
  } = reduxProps;

  const [valid, setValid] = useState(null);
  const [newEmail, setNewEmail] = useState('');
  const [forceEmailInvalid, setForceEmailInvalid] = useState(null);

  const _validateEmail = email => {
    email = email.trim();
    const valid = isEmailValid(email);
    setValid(valid);

    if (valid) {
      setForceEmailInvalid(null);
      dispatch(checkEmailAvailable(email));
    }

    setNewEmail(email);
  };

  useEffect(() => {
    if (emailChangeSuccess) {
      dispatch(sendEmailVerification());
      dispatch(resetErrors());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailChangeSuccess]);

  return (
    <>
      {changeEmailError && (
        <ErrorDisplay error={changeEmailError} basic={true} />
      )}
      <AuthTextInput
        label="New Email *"
        leftIcon={IoMailOutline}
        rightIcon={
          emailIsAvailable === '' && valid ? IoCheckmarkCircleOutline : null
        }
        rightIconColor={'var(--color-success'}
        errorText={emailIsAvailable || forceEmailInvalid}
        placeholder={'example@gmail.com'}
        onChangeText={text => _validateEmail(text)}
      />

      <AuthButton
        disabled={
          emailIsAvailable !== '' ||
          newEmail.trim() === '' ||
          emailChangeSuccess
        }
        onPress={() => dispatch(changeEmail(newEmail))}
        isLoading={changingEmail}
      >
        {emailChangeSuccess ? 'Success!' : 'Reset Email'}
      </AuthButton>
      {emailChangeSuccess && (
        <AuthButton
          onPress={() => {
            if (emailChangeSuccess) {
              dispatch(resetErrors());
            }
            props.dismissModal();
          }}
        >
          Go Back
        </AuthButton>
      )}
    </>
  );
}
