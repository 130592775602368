export default function genOdds(period) {
  return [
    {
      id: 381264,
      book: {
        id: 23,
        name: 'theScore Bet',
        is_affiliate: true,
        generic_banner:
          'https://sfo2.digitaloceanspaces.com/betstamp-public/generic_book_banners/theScore_Bet.jpeg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=HMJQSQGRD7PZ4ERMOH4I%2F20220502%2Fsfo2%2Fs3%2Faws4_request&X-Amz-Date=20220502T174424Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=169a4507c267efc8e0929e923b068116f55c8aca5c6c19c9618195025340aa1b',
        generic_link: 'https://www.thescore.bet/',
        color: null,
      },
      created_at: '2022-04-28T19:51:10.201268Z',
      home_ml: 1.95,
      away_ml: null,
      tie_ml: null,
      spread: null,
      home_spread_odds: null,
      away_spread_odds: null,
      total: null,
      over_odds: null,
      under_odds: null,
      is_fullgame: true,
      period: period,
      is_live: false,
      game: 347,
    },
    {
      id: 300545,
      book: {
        id: 19,
        name: 'SugarHouse',
        is_affiliate: true,
        generic_link: 'https://www.playsugarhouse.com/',
        color: '#286193',
        min_balance_banner: null,
      },
      created_at: '2022-04-27T18:47:48.895836Z',
      home_ml: 3.27,
      away_ml: 4.49,
      tie_ml: null,
      spread: 1.0,
      home_spread_odds: 4.7,
      away_spread_odds: 5.27,
      total: 0.5,
      over_odds: 2.44,
      under_odds: 2.24,
      is_fullgame: true,
      period: period,
      is_live: false,
      game: 347,
    },
    {
      id: 300455,
      book: {
        id: 1,
        name: 'bet365',
        is_affiliate: true,
        generic_link: 'https://www.bet365.com/',
        color: '#027b5b',
      },
      created_at: '2022-04-27T18:47:48.890242Z',
      home_ml: 3.23,
      away_ml: 4.72,
      tie_ml: null,
      spread: -1.0,
      home_spread_odds: 4.17,
      away_spread_odds: 3.95,
      total: 2.0,
      over_odds: 3.44,
      under_odds: 3.17,
      is_fullgame: true,
      period: 'FT',
      is_live: false,
      game: 347,
    },
    {
      id: 300499,
      book: {
        id: 3,
        name: 'DraftKings',
        is_affiliate: true,
        generic_link: 'https://www.draftkings.com/',
        color: '#888585',
        min_balance_banner: null,
      },
      created_at: '2022-04-27T18:47:48.893003Z',
      home_ml: 1.53,
      away_ml: 2.69,
      tie_ml: null,
      spread: 3.0,
      home_spread_odds: 1.19,
      away_spread_odds: 3.34,
      total: 2.5,
      over_odds: 5.84,
      under_odds: 4.0,
      is_fullgame: true,
      period: period,
      is_live: false,
      game: 347,
    },
    {
      id: 300549,
      book: {
        id: 325,
        name: 'Tipico',
        is_affiliate: true,
        generic_link: null,
        color: null,
        min_balance_banner: null,
      },
      created_at: '2022-04-27T18:47:48.896081Z',
      home_ml: 3.44,
      away_ml: 2.45,
      tie_ml: null,
      spread: 0.0,
      home_spread_odds: 5.32,
      away_spread_odds: 4.09,
      total: 3.0,
      over_odds: 1.77,
      under_odds: 2.97,
      is_fullgame: true,
      period: period,
      is_live: false,
      game: 347,
    },
    {
      id: 300483,
      book: {
        id: 22,
        name: 'Borgata',
        is_affiliate: true,
        generic_link: 'https://sports.borgataonline.com/',
        color: '#dcaf10',
        min_balance_banner: null,
      },
      created_at: '2022-04-27T18:47:48.891996Z',
      home_ml: 5.06,
      away_ml: 1.78,
      tie_ml: null,
      spread: 5.0,
      home_spread_odds: 3.61,
      away_spread_odds: 2.42,
      total: 2.0,
      over_odds: 2.8,
      under_odds: 2.41,
      is_fullgame: true,
      period: period,
      is_live: false,
      game: 347,
    },
    {
      id: 300477,
      book: {
        id: 54,
        name: 'Betway',
        is_affiliate: true,
        generic_link: 'https://betway.com/',
        color: '#666666',
      },
      created_at: '2022-04-27T18:47:48.891617Z',
      home_ml: 5.55,
      away_ml: 1.67,
      tie_ml: null,
      spread: -1.5,
      home_spread_odds: 3.92,
      away_spread_odds: 1.82,
      total: 3.5,
      over_odds: 1.05,
      under_odds: 2.79,
      is_fullgame: true,
      period: period,
      is_live: false,
      game: 347,
    },
    {
      id: 300465,
      book: {
        id: 7,
        name: 'BetMGM',
        is_affiliate: true,
        generic_link: 'https://sports.betmgm.com/en/sports',
        color: '#c0a971',
      },
      created_at: '2022-04-27T18:47:48.890851Z',
      home_ml: 1.82,
      away_ml: 3.66,
      tie_ml: null,
      spread: 7.0,
      home_spread_odds: 3.91,
      away_spread_odds: 3.94,
      total: 7.0,
      over_odds: 1.63,
      under_odds: 2.31,
      is_fullgame: true,
      period: period,
      is_live: false,
      game: 347,
    },
    {
      id: 300489,
      book: {
        id: 30,
        name: 'Caesars',
        is_affiliate: true,
        generic_link: 'https://www.williamhill.com/us',
        color: '#173533',
      },
      created_at: '2022-04-27T18:47:48.892371Z',
      home_ml: 5.38,
      away_ml: 1.87,
      tie_ml: null,
      spread: -4.0,
      home_spread_odds: 2.08,
      away_spread_odds: 4.83,
      total: 2.0,
      over_odds: 4.88,
      under_odds: 1.24,
      is_fullgame: true,
      period: period,
      is_live: false,
      game: 347,
    },
    {
      id: 300561,
      book: {
        id: 482,
        name: 'WynnBet',
        is_affiliate: false,
        generic_link: 'https://www.wynnbet.com/',
        color: '#0a1837',
        min_balance_banner: null,
      },
      created_at: '2022-04-27T18:47:48.896813Z',
      home_ml: 5.2,
      away_ml: 2.19,
      tie_ml: null,
      spread: 1.0,
      home_spread_odds: 1.9,
      away_spread_odds: 1.5,
      total: 6.5,
      over_odds: 2.93,
      under_odds: 4.5,
      is_fullgame: true,
      period: period,
      is_live: false,
      game: 347,
    },
  ];
}
