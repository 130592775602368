import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  useQueryClient,
  useMutation,
  useInfiniteQuery,
} from '@tanstack/react-query';
import styled from 'styled-components';
import { IoPencil } from 'react-icons/io5';

// utils
import { humanDate } from 'utils';

// components
import { AuthButton, IconButton } from 'components/AuthButton';
import { AuthTextInput } from 'components/AuthTextInput';
import Select from 'components/generic/Select';
import ActivityIndicator from 'components/generic/ActivityIndicator';
import CalendarInput from 'components/generic/CalendarInput';
import { Row, Col } from 'components/generic/Layout';

const Tbody = styled.tbody`
  > tr {
    transition: all var(--std-transition);
  }
  > tr:hover {
    background-color: var(--color-text-light);
  }
`;

export default function PaymentList(props) {
  const reduxProps = useSelector(state => ({ user: state.authReducer.user }));
  const { user } = reduxProps;

  const { attendee_id, session_id, collectReciever } = props;

  const [showForm, setShowForm] = useState(false);
  const [formData, setFormData] = useState({ fees: 0, currency: 'CAD' });

  const fetchPayments = useInfiniteQuery({
    refetchOnWindowFocus: false,
    queryKey: [
      {
        endpoint: 'onboarding-payments',
        urlParams: {
          attendee_id: attendee_id || null,
          session_id: session_id && !attendee_id ? session_id : null,
        },
        extra: 'infinite',
      },
    ],
    getNextPageParam: (lastPage, allPages) => lastPage.next,
    getPreviousPageParam: (firstPage, allPages) => firstPage.prev,
  });

  const queryClient = useQueryClient();
  const submitPayment = useMutation({
    mutationKey: `onboarding-submit-payment-${attendee_id}`,
    onSuccess: () => {
      setShowForm(false);
      queryClient.invalidateQueries({
        queryKey: [
          {
            endpoint: 'onboarding-payments',
            urlParams: {
              attendee_id: attendee_id || null,
              session_id: session_id && !attendee_id ? session_id : null,
            },
            extra: 'infinite',
          },
        ],
      });
      queryClient.invalidateQueries({
        queryKey: [
          {
            endpoint: `onboarding-payouts`,
            urlParams: { attendee_id: attendee_id },
          },
        ],
      });
      if (props.onSuccessSubmitCallback) {
        props.onSuccessSubmitCallback();
      }
    },
  });

  const submitDisabled =
    !formData.amount ||
    formData.amount === 0 ||
    !formData.bank ||
    !formData.reason;

  if (fetchPayments.isError) {
    return <p>Error fetching payments {fetchPayments?.error?.message}</p>;
  }

  if (fetchPayments.isLoading) {
    return <ActivityIndicator size={2} style={{ marginTop: '20vh' }} />;
  }

  const pages = fetchPayments?.data?.pages;

  return (
    <>
      {showForm ? (
        <>
          <form
            style={{ width: '100%' }}
            onSubmit={ev => {
              ev.preventDefault();
              const fd = new FormData(ev.target);
              if (attendee_id) {
                fd.append('attendee', attendee_id);
              }
              if (session_id) {
                fd.append('session', session_id);
              }
              let paymentID = formData.id || null;
              fd.set('date', fd.get('date') + 'T12:00:00.000Z');
              if (!fd.has('is_cancelled')) {
                fd.set('is_cancelled', 'off');
              }
              submitPayment.mutate({
                endpoint: `onboarding-payments${paymentID ? '/' + paymentID : ''
                  }`,
                body: fd,
              });
            }}
          >
            <AuthTextInput
              placeholder="20"
              name="amount"
              label="Amount *"
              //type="number"
              required={true}
              defaultValue={formData?.amount}
              onChangeText={text =>
                setFormData({ ...formData, amount: parseFloat(text) })
              }
            />
            <AuthTextInput
              placeholder="20"
              name="fees"
              label="Fees *"
              //type="number"
              required={true}
              defaultValue={formData?.fees || 0}
              onChangeText={text => setFormData({ ...formData, fees: text })}
            />

            <AuthTextInput
              placeholder="Email or name of the person who sent this payment"
              name="sender"
              label="Sender"
              defaultValue={formData?.sender || user.full_name}
              onChangeText={text => setFormData({ ...formData, sender: text })}
            />

            {!!collectReciever && (
              <AuthTextInput
                placeholder="Email or name of the person recieving this session payment"
                name="reciever"
                label="Reciever"
                defaultValue={formData?.reciever}
                onChangeText={text =>
                  setFormData({ ...formData, reciever: text })
                }
              />
            )}

            <Select
              label="Bank *"
              name="bank"
              options={[
                { value: 'BMO', label: 'BMO' },
                { value: 'TD', label: 'TD' },
                { value: 'PAYPAL', label: 'PayPal' },
                { value: 'FLOAT', label: 'Float' },
                { value: 'OTHER', label: 'Other' },
              ]}
              onChange={opt => setFormData({ ...formData, bank: opt.value })}
              defaultValue={
                formData?.bank
                  ? { value: formData.bank, label: formData.bank }
                  : null
              }
            />
            <Select
              label="Reason *"
              name="reason"
              options={[
                {
                  value: 'SETUP_SESSION_INITIAL_TRANSFER',
                  label: 'Setup session initial transfer',
                },
                {
                  value: 'POST_ONBOARDING_BONUS',
                  label: 'Post onboarding bonus',
                },
                { value: 'BETSTAMP_101_BONUS', label: 'betstamp 101 bonus' },
                { value: 'GROUP_TARGET_BONUS', label: 'Group target bonus' },
                { value: 'MONTHLY_FEE', label: 'Monthly fee' },
                // there's a space in this option value, there was a typo in the backend
                // so now there's forever a space until someone fixes it
                // quite possibly the worst tech debt we've ever taken on
                { value: 'EVENT_FUNDING ', label: 'Event funding ' },
                { value: 'EVENT_FUNDING_BONUS', label: 'Event funding bonus' },
                // space is here       ^
                { value: 'CHALLENGE_350', label: '350 Challenge' },
                {
                  value: 'SOCIAL_LADDER_CASHOUT',
                  label: 'Social Ladder Cashout',
                },
                { value: 'RETURNED_MONEY', label: 'Returned Money' },
                { value: 'OTHER', label: 'Other' },
              ]}
              onChange={opt => setFormData({ ...formData, reason: opt.value })}
              defaultValue={
                formData?.reason
                  ? { value: formData.reason, label: formData.reason }
                  : null
              }
            />
            <Select
              label="Direction *"
              name="direction"
              defaultValue={
                formData?.direction
                  ? { value: formData.direction, label: formData.direction }
                  : { value: 'TO_ATTENDEE', label: 'To Attendee/Group' }
              }
              options={[
                { value: 'TO_ATTENDEE', label: 'To Attendee/Group' },
                { value: 'TO_BETSTAMP', label: 'To betstamp' },
              ]}
              onChange={opt =>
                setFormData({ ...formData, direction: opt.value })
              }
            />
            <Select
              label="Currency *"
              name="currency"
              defaultValue={
                formData?.currency
                  ? { value: formData.currency, label: formData.currency }
                  : { value: 'CAD', label: 'CAD' }
              }
              options={[
                { value: 'CAD', label: 'CAD' },
                { value: 'USD', label: 'USD' },
                { value: 'OTHER', label: 'Other' },
              ]}
              onChange={opt =>
                setFormData({ ...formData, currency: opt.value })
              }
            />
            <Col
              style={{ marginTop: 'var(--space-sm)', alignItems: 'stretch' }}
            >
              <span>Date *</span>
              <Row
                style={{
                  backgroundColor: 'var(--color-fg)',
                  border: '1px solid var(--color-text-light)',
                  borderRadius: '32px',
                  padding: 'var(--space-xxxs)',
                }}
              >
                <CalendarInput
                  value={
                    formData.date
                      ? new Date(formData.date)
                      : new Date(new Date().setHours(12))
                  }
                  onChange={date => {
                    let d = date;
                    d.setHours(12);
                    setFormData({ ...formData, date: d });
                  }}
                />
              </Row>
            </Col>

            <div style={{ margin: 'var(--space-sm) 0' }}>
              <input
                key={`payment-is_cancelled-${formData.id}-${formData.is_cancelled}`}
                type="checkbox"
                name="is_cancelled"
                id="is_cancelled"
                defaultChecked={formData.is_cancelled}
                onChange={ev => {
                  setFormData({ ...formData, is_cancelled: ev.target.checked });
                }}
              />
              <label htmlFor="is_cancelled">Payment was cancelled</label>
            </div>

            <AuthTextInput
              name="notes"
              label="Misc. Notes"
              multiline
              onChangeText={() => { }}
              defaultValue={formData?.notes}
            />
            <br />
            <AuthButton
              type="submit"
              disabled={submitDisabled}
              isLoading={submitPayment.isLoading}
            >
              {formData?.id ? 'Save Changes' : 'Create Payment'}
            </AuthButton>
            {submitPayment.isError && (
              <small
                style={{ textAlign: 'center', color: 'var(--color-danger)' }}
              >
                {submitPayment.error.message}
              </small>
            )}
          </form>
          <AuthButton
            colorTheme="danger"
            onPress={() => {
              setShowForm(false);
              setFormData({ fees: 0, currency: 'CAD' });
            }}
          >
            Cancel
          </AuthButton>
        </>
      ) : (
        <>
          <AuthButton
            containerStyle={{ flex: 0 }}
            onPress={() => {
              setShowForm(true);
              setFormData({ fees: 0, currency: 'CAD' });
            }}
          >
            Add Payment
          </AuthButton>
          {pages?.length === 1 && pages[0]?.results.length === 0 && (
            <p>No payments yet</p>
          )}

          {pages?.length > 0 && pages[0]?.results?.length > 0 && (
            <>
              <table style={{ width: '100%' }} cellSpacing={0}>
                {/*<thead>
                  <tr>
                    <th style={{ textAlign: 'left' }}>Reason</th>
                    <th style={{ textAlign: 'right' }}>Amount</th>
                    <th style={{ textAlign: 'right' }}></th>
                  </tr>
                </thead>*/}
                <Tbody>
                  {pages.map((page, i) => (
                    <React.Fragment
                      key={`onb-payment-list-${attendee_id}-${i}`}
                    >
                      {page.results?.map(p => (
                        <tr
                          key={`payment-table-${p.id}`}
                          title={`${p.sender} - ${p.reason} for ${p.currency}$${p.amount
                            }  on ${humanDate(p.date)} from ${p.bank} (id: ${p.id
                            })`}
                        >
                          <td
                            style={{
                              display: 'flex',
                              flexFlow: 'column nowrap',
                              justifyContent: 'center',
                              height: '100%',
                            }}
                          >
                            <span>
                              {p.reason.replace(/_/g, ' ')}
                              {p.is_cancelled ? ' (CANCELLED)' : ''}
                            </span>
                            <small>{humanDate(p.date)}</small>
                          </td>
                          <td style={{ textAlign: 'right' }}>
                            <span style={{ opacity: 0.8 }}>{p.currency}</span>$
                            {p.amount}
                          </td>
                          <td>
                            <IconButton
                              containerStyle={{ float: 'right' }}
                              iconName={IoPencil}
                              colorTheme="text"
                              title="Edit Payment"
                              onPress={() => {
                                setFormData({ ...p });
                                setShowForm(true);
                              }}
                            />
                          </td>
                        </tr>
                      ))}
                    </React.Fragment>
                  ))}
                </Tbody>
              </table>
              {fetchPayments.hasNextPage && (
                <AuthButton
                  btnTheme="borderless"
                  onPress={() => fetchPayments.fetchNextPage()}
                  isLoading={fetchPayments.isFetchingNextPage}
                >
                  Load More
                </AuthButton>
              )}
            </>
          )}
        </>
      )}
    </>
  );
}
