import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'auto' });

    // if on desktop with sidebar, the window scroll is not the main scrollbar
    const mainArea = document.getElementById('infinite-scroll-target');

    if (mainArea) {
      mainArea.scrollTo({ top: 0, left: 0, behavior: 'auto' });
    }
  }, [pathname]);

  return null;
}
