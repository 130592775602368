import { Observable } from 'rxjs';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import { ajax } from 'rxjs/observable/dom/ajax';
import { BACKEND_API_URL, getHeaders } from 'utils';
import moment from 'moment';

import {
  GET_BET_SELECTION,
  getBetSelectionSuccess,
  getBetSelectionFailure,
  getBetSelectionCached,
} from 'actions';

function _analysisIsCached(url, tab, cachedAnalysis) {
  const isCached = cachedAnalysis[url] !== undefined;
  if (isCached) {
    // partial cache hit
    // is it too old?
    const cachedDate = cachedAnalysis[url].cachedAt;
    const rightNow = moment();
    const duration = moment.duration(rightNow.diff(cachedDate));
    const cacheIsOld = duration.asMinutes() > 30;

    if (cacheIsOld || cachedAnalysis[url].bets.length === 0) {
      // cache miss
      return false;
    }
  } else {
    // complete cache miss (no table, no bets, never requested ever)
    return false;
  }
}

export default function getBetSelection(action$, state$) {
  return action$.ofType(GET_BET_SELECTION).switchMap(action => {
    let url = BACKEND_API_URL + `api/wager_selection/${action.urlparams}`;

    if (!action.force) {
      const { cachedAnalysis } = state$.value.analysisReducer;
      if (
        _analysisIsCached(
          url.replace('bet_selection', 'analysis'),
          action.tab,
          cachedAnalysis
        )
      ) {
        // nothing to do
        return Observable.of(getBetSelectionCached(action.urlparams));
      }
    }

    url += `ref_date=${moment().toISOString(true)}&`;

    return ajax
      .getJSON(url, getHeaders(state$))
      .map(data =>
        getBetSelectionSuccess(url.replace('bet_selection', 'analysis'), data)
      )
      .catch(error => Observable.of(getBetSelectionFailure(error.xhr)));
  });
}
