import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { useQuery, useMutation } from '@tanstack/react-query';
import { Link } from 'react-router-dom';
import {
  IoChevronForward,
  IoChevronBack,
  IoCloudUploadOutline,
} from 'react-icons/io5';
import { useDropArea } from 'react-use';

// utils
import { humanDatetime, formatMoney } from 'utils';

// components
import { Row, Col, Grid } from 'components/generic/Layout';
import { AuthButton, IconButton } from 'components/AuthButton';
import { AuthTextInput } from 'components/AuthTextInput';
import ActivityIndicator from 'components/generic/ActivityIndicator';

const Tbody = styled.tbody`
  > tr {
    transition: all var(--std-transition);
  }
  > tr:hover {
    background-color: var(--color-text-light);
  }
  > tr:hover:first-child {
    border-radius: var(--std-border-radius);
  }
  > tr > td {
    padding: var(--space-xxs) var(--space-sm);
  }
  > tr:not(:last-child) > td {
    border-bottom: 1px solid var(--color-text-light);
  }
  > tr > td:last-child {
    text-align: right;
  }
`;

export default function ScreenshotViewer(props) {
  const reduxProps = useSelector(state => ({
    allBooksMap: state.authReducer.allBooksMap,
  }));
  const { allBooksMap } = reduxProps;

  const { progress, attendee_id } = props;

  const fetchScreenshots = useQuery({
    enabled: !!progress,
    refetchOnWindowFocus: false,
    queryKey: [
      {
        endpoint: `onboarding-screenshots`,
        urlParams: { progress_id: progress?.id },
      },
    ],
  });

  const fetchAttendee = useQuery({
    enabled: false,
    queryKey: [
      {
        endpoint: `onboarding-attendees`,
        objectID: attendee_id,
      },
    ],
  });

  const [view, setView] = useState('screenshots');

  const screens = fetchScreenshots?.data;

  if (!progress) return null;

  return (
    <Row
      style={{
        flex: 1,
        height: '100%',
        width: '100%',
        ...props.containerStyle,
      }}
    >
      <Col style={{ flex: 2, justifyContent: 'flex-start' }}>
        <table
          style={{
            width: '100%',
            border: '1px solid var(--color-text-light)',
            borderRadius: 'var(--std-border-radius)',
          }}
          cellSpacing={0}
        >
          <Tbody>
            <tr>
              <td>Attendee ID</td>
              <td>
                <b>
                  <Link to={`/staff/attendees?search=${progress.attendee}`}>
                    {progress.attendee}
                  </Link>
                </b>
              </td>
            </tr>
            {fetchAttendee?.data ? (
              <>
                <tr>
                  <td>Full Name</td>
                  <td>
                    <b>{fetchAttendee?.data?.attendee?.full_name}</b>
                  </td>
                </tr>
                <tr>
                  <td>Referral Code</td>
                  <td>
                    <b>
                      {fetchAttendee?.data?.attendee
                        ?.signed_up_with_referral_code?.code || '-'}
                    </b>
                  </td>
                </tr>
                <tr>
                  <td>Target</td>
                  <td>
                    <b>
                      {fetchAttendee?.data?.attendee?.target_num_signups || '-'}
                    </b>
                  </td>
                </tr>
                <tr>
                  <td>Stage</td>
                  <td>
                    <b>
                      {fetchAttendee?.data?.attendee?.stage
                        ?.replace(/_/g, ' ')
                        .toLowerCase()}
                    </b>
                  </td>
                </tr>
              </>
            ) : (
              <tr>
                <td colSpan={2} style={{ textAlign: 'center' }}>
                  {fetchAttendee?.isFetching ? (
                    <ActivityIndicator size={1} />
                  ) : (
                    <span
                      style={{
                        cursor: 'pointer',
                        textDecoration: 'underline',
                        color: 'var(--color-primary)',
                      }}
                      onClick={() => fetchAttendee.refetch()}
                    >
                      Click to load attendee data
                    </span>
                  )}
                </td>
              </tr>
            )}
            <tr>
              <td>Book</td>
              <td>
                <Row
                  style={{
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    gap: '4px',
                  }}
                >
                  <img
                    src={allBooksMap[progress.book]?.logo}
                    width={22}
                    style={{ flex: 0, borderRadius: '4px' }}
                    alt={allBooksMap[progress.book]?.name}
                  />

                  <b>{allBooksMap[progress.book]?.name}</b>
                </Row>
              </td>
            </tr>
            <tr>
              <td>Min Deposit</td>
              <td>
                <b>{formatMoney(progress.min_deposit, 'CAD')}</b>
              </td>
            </tr>
            <tr>
              <td>Affiliate Clicked Times</td>
              <td>
                <b>{progress.num_times_clicked_affiliate_link}</b>
              </td>
            </tr>
            <tr>
              <td>Claimed Deposit</td>
              <td>
                <b>{formatMoney(progress.amount_deposited, 'CAD')}</b>
              </td>
            </tr>
            <tr>
              <td>Claimed Amount Bet</td>
              <td>
                <b>{formatMoney(progress.amount_bet, 'CAD')}</b>
              </td>
            </tr>
            <tr>
              <td>Verified Amount Bet</td>
              <td>
                <b>{formatMoney(progress.verified_amount_bet, 'CAD')}</b>
              </td>
            </tr>
            {progress.rejection_reason && (
              <tr>
                <td>Rejection Reason</td>
                <td>
                  <b>{progress.rejection_reason}</b>
                </td>
              </tr>
            )}
          </Tbody>
        </table>
        <AuthButton
          containerStyle={{ flex: 0 }}
          colorTheme={view === 'screenshots' ? 'inverted' : 'primary'}
          onPress={() => setView('screenshots')}
        >
          View Screenshots
        </AuthButton>
        <AuthButton
          containerStyle={{ flex: 0 }}
          title="Upload screenshots on behalf on the attendee"
          colorTheme={view === 'upload' ? 'inverted' : 'primary'}
          onPress={() => setView('upload')}
        >
          Upload Screenshots
        </AuthButton>
        <AuthButton
          containerStyle={{ flex: 0 }}
          disabled={screens?.length === 0}
          colorTheme={view === 'approve' ? 'inverted' : 'primary'}
          onPress={() => setView('approve')}
        >
          Approve Screenshots
        </AuthButton>
        <AuthButton
          containerStyle={{ flex: 0 }}
          colorTheme="danger"
          disabled={screens?.length === 0}
          onPress={() => setView('reject')}
        >
          Reject Screenshots
        </AuthButton>
      </Col>
      <Col
        style={{
          flex: 4,
          justifyContent: 'flex-start',
          padding: '0 var(--space-md)',
          flexWrap: 'nowrap',
        }}
      >
        {view === 'screenshots' && (
          <ScreenshotCarousel
            progress={progress}
            screens={screens}
            isLoading={fetchScreenshots.isLoading}
          />
        )}
        {view === 'upload' && (
          <UploadScreenshots
            progress={progress}
            attendee_id={attendee_id}
            setView={setView}
            fetchScreenshots={fetchScreenshots}
            onChangeCallback={props.onChangeCallback}
          />
        )}
        {view === 'reject' && (
          <RejectionForm
            progress={progress}
            attendee_id={attendee_id}
            setView={setView}
            onChangeCallback={props.onChangeCallback}
          />
        )}
        {view === 'approve' && (
          <ApprovalForm
            progress={progress}
            attendee_id={attendee_id}
            setView={setView}
            fetchScreenshots={fetchScreenshots}
            onChangeCallback={props.onChangeCallback}
          />
        )}
      </Col>
    </Row>
  );
}

function ScreenshotCarousel(props) {
  const { screens } = props;
  const [selectedImgIndex, setSelectedImgIndex] = useState(0);
  if (props.isLoading) {
    return <ActivityIndicator size={2} />;
  }
  return (
    <>
      {screens?.length === 0 && (
        <h4 style={{ textAlign: 'center', marginTop: 'var(--space-xl)' }}>
          No screenshots
        </h4>
      )}

      {screens?.length > 0 && (
        <>
          <Row
            style={{
              flex: 0,
              width: '100%',
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: '1rem',
            }}
          >
            <span>
              Uploaded {humanDatetime(screens[selectedImgIndex].created_at)} (
              {screens[selectedImgIndex]?.kind || 'unknown kind'})
            </span>
            <a
              style={{ color: 'powderblue' }}
              href={screens[selectedImgIndex].screenshot}
              target="_blank"
              rel="noopener noreferrer"
            >
              Direct Image Link (click this if the image won't load)
            </a>
            <Row
              style={{
                justifyContent: 'flex-end',
                gap: '1rem',
                alignItems: 'center',
              }}
            >
              <IconButton
                colorTheme="text"
                iconName={IoChevronBack}
                disabled={screens?.length === 1}
                onPress={() => {
                  let newIdx = selectedImgIndex - 1;
                  if (newIdx < 0) newIdx = screens.length - 1;
                  setSelectedImgIndex(newIdx);
                }}
              />
              <span>
                {selectedImgIndex + 1}/{screens?.length}
              </span>
              <IconButton
                colorTheme="text"
                iconName={IoChevronForward}
                disabled={screens?.length === 1}
                onPress={() => {
                  setSelectedImgIndex((selectedImgIndex + 1) % screens?.length);
                }}
              />
            </Row>
          </Row>

          <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
            <img
              style={{
                // this is pretty specifc to the current MyDashboard layout
                maxHeight: 'calc(100vh - 64px - 92px - 68px - 12px)',
                backgroundCover: 'contain',
                maxWidth: '100%',
              }}
              src={screens[selectedImgIndex].screenshot}
              alt="Sportsbook Screenshot"
            />
          </div>
        </>
      )}
    </>
  );
}

function UploadScreenshots(props) {
  const { progress, attendee_id } = props;
  const [formData, setFormData] = useState({ screenshots: [] });
  const [formErrors, setFormErrors] = useState({});

  const [dropBond, dropState] = useDropArea({
    onFiles: files => {
      let filesTooBig = false;
      for (const f of files) {
        if (f.size / 1_000_000 >= 10) {
          filesTooBig = true;
        }
      }
      if (filesTooBig) {
        setFormErrors({
          ...formErrors,
          screenshots: 'Each screenshot must be less than 10mb',
        });
      } else {
        setFormErrors({
          ...formErrors,
          screenshots: null,
        });
      }
      setFormData({
        ...formData,
        screenshots: Array.from(files).filter(
          f => f.type.includes('image') && f.size / 1_000_000 < 10
        ),
      });
    },
  });

  const submitProgress = useMutation({
    mutationKey: `onboarding-attendee-progress-upload-screenshots-${progress.attendee}`,
    onSuccess: () => {
      props.setView('screenshots');
      props.fetchScreenshots.refetch();
      if (props.onChangeCallback) {
        props.onChangeCallback();
      }
    },
  });

  return (
    <form
      style={{
        width: '100%',
        minHeight: '90%',
        ...props.uploadFormStyle,
      }}
      onSubmit={ev => {
        ev.preventDefault();
        const fd = new FormData(ev.target);
        fd.append('attendee_id', attendee_id);
        fd.append('screenshot_kind', 'host uploaded');
        fd.append('current_step', 'FULLY_COMPLETE');
        for (const file of formData.screenshots) {
          fd.append('screenshots', file);
        }
        submitProgress.mutate({
          endpoint: `onboarding-attendee-progresses/${progress.id}`,
          body: fd,
        });
      }}
    >
      <h3 style={{ marginTop: 0 }}>
        Upload screenshots on behalf of an attendee
      </h3>
      <AuthTextInput
        id="verified_amount_bet"
        name="verified_amount_bet"
        placeholder="Verified amount bet"
        label={`Verified Amount Bet (min. deposit $${progress.min_deposit})`}
        errorText={
          formData.amount_bet && isNaN(formData.amount_bet)
            ? `Must be a number`
            : null
        }
        onChangeText={text => {
          setFormData({ ...formData, verified_amount_bet: text });
        }}
      />
      <div
        style={{
          transition: 'all var(--std-transition)',
          height: '66%',
          display: 'flex',
          flexFlow: 'column nowrap',
          justifyContent: formData.screenshots?.length
            ? 'flex-start'
            : 'center',
          zIndex: 10,
        }}
      >
        <label
          {...dropBond}
          htmlFor="file_upload_input"
          style={{
            cursor: 'pointer',
            fontSize: '18px',
            border: '2px dashed white',
            borderRadius: '16px',
            padding: 'var(--space-sm)',
            flex: 1,
          }}
        >
          <Row
            style={{
              flex: 1,
              height: '100%',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '1rem',
            }}
          >
            <IoCloudUploadOutline size={48} color="var(--color-text)" />
            <span>
              <u>Click to upload</u> or drag & drop to upload file(s)
            </span>
          </Row>

          <input
            id="file_upload_input"
            style={{
              display: 'none',
              margin: '0 var(--space-sm)',
            }}
            multiple
            type="file"
            accept="image/png, image/gif, image/jpeg, image/jpg, image/webp"
            onChange={input => {
              let filesTooBig = false;
              for (const f of input.target.files) {
                if (f.size / 1_000_000 >= 10) {
                  filesTooBig = true;
                }
              }
              if (filesTooBig) {
                setFormErrors({
                  ...formErrors,
                  screenshots: 'Each screenshot must be less than 10mb',
                });
              } else {
                setFormErrors({
                  ...formErrors,
                  screenshots: null,
                });
              }
              setFormData({
                ...formData,
                screenshots: Array.from(input.target.files).filter(
                  f => f.type.includes('image') && f.size / 1_000_000 < 10
                ),
              });
            }}
          />
        </label>
        {formData.screenshots?.length > 0 && (
          <Grid
            style={{
              flex: 1,
              marginTop: 'var(--space-sm)',
              gridTemplateColumns: 'repeat(auto-fill, minmax(128px, 1fr))',
            }}
          >
            {formData.screenshots?.map((file, i) => (
              <img
                key={`place-bet-preview-screenshot-${i}-${file.name}`}
                alt={`File Upload ${file.name}`}
                src={URL.createObjectURL(file)}
                style={{ width: '100%' }}
              />
            ))}
          </Grid>
        )}

        {!!formErrors.screenshots ? (
          <p style={{ color: 'var(--color-danger)' }}>
            {formErrors.screenshots}
          </p>
        ) : null}
        <AuthButton
          containerStyle={{ flex: 0 }}
          type="submit"
          isLoading={submitProgress.isLoading}
          disabled={
            formData.screenshots?.length === 0 ||
            !formData.verified_amount_bet ||
            !!isNaN(formData.verified_amount_bet) ||
            !!formErrors.screenshots
          }
        >
          Upload & Approve Screenshots
        </AuthButton>
      </div>
    </form>
  );
}

function RejectionForm(props) {
  const { progress, attendee_id } = props;
  const submitProgress = useMutation({
    mutationKey: `onboarding-attendee-progress-reject-screenshots-${progress.attendee}`,
    onSuccess: () => {
      props.setView('screenshots');
      if (props.onChangeCallback) {
        props.onChangeCallback();
      }
    },
  });
  return (
    <form
      style={{
        width: '100%',
        ...props.rejectionFormStyle,
      }}
      onSubmit={ev => {
        ev.preventDefault();
        const fd = new FormData(ev.target);
        fd.append('attendee_id', attendee_id);
        fd.append('current_step', 'SCREENSHOTS_INVALID');
        submitProgress.mutate({
          endpoint: `onboarding-attendee-progresses/${progress?.id}`,
          body: fd,
        });
      }}
    >
      <h3 style={{ marginTop: 0 }}>Reject Screenshots</h3>
      <AuthTextInput
        name="rejection_reason"
        label="Why are these screenshots not valid?"
        placeholder="Does not meet minimum deposit requirements"
        multiline
        onChangeText={() => { }}
      />
      <AuthButton type="submit">Reject</AuthButton>
    </form>
  );
}

function ApprovalForm(props) {
  const { progress, attendee_id } = props;

  const [formData, setFormData] = useState({ screenshots: [] });

  const submitProgress = useMutation({
    mutationKey: `onboarding-attendee-progress-upload-screenshots-${progress.attendee}`,
    onSuccess: () => {
      //if (props.onSuccessChangeCallback) {
      //  props.onSuccessChangeCallback();
      //}
      props.setView('screenshots');
      props.fetchScreenshots.refetch();
      if (props.onChangeCallback) {
        props.onChangeCallback();
      }
    },
  });

  return (
    <form
      style={{ width: '100%' }}
      onSubmit={ev => {
        ev.preventDefault();
        const fd = new FormData(ev.target);
        fd.append('attendee_id', attendee_id);
        fd.append('current_step', 'FULLY_COMPLETE');
        submitProgress.mutate({
          endpoint: `onboarding-attendee-progresses/${progress.id}`,
          body: fd,
        });
      }}
    >
      <h3 style={{ marginTop: 0 }}>Approve Screenshots</h3>
      <AuthTextInput
        id="verified_amount_bet"
        name="verified_amount_bet"
        placeholder={progress.min_deposit}
        label={`Verified Amount Bet (min. deposit $${progress.min_deposit})`}
        errorText={
          formData.amount_bet && isNaN(formData.amount_bet)
            ? `Must be a number`
            : null
        }
        onChangeText={text => {
          setFormData({ ...formData, verified_amount_bet: text });
        }}
      />
      <AuthButton type="submit" isLoading={submitProgress?.isLoading}>
        Approve
      </AuthButton>
    </form>
  );
}
