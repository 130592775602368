import { Observable } from 'rxjs';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import { ajax } from 'rxjs/observable/dom/ajax';
import { BACKEND_API_URL, getHeaders } from 'utils';

import {
  EDIT_ANALYSIS_PRESET,
  editAnalysisPresetSuccess,
  editAnalysisPresetFailure,
} from 'actions';

export default function editPreset(action$, state$) {
  return action$.ofType(EDIT_ANALYSIS_PRESET).switchMap(action => {
    return ajax
      .patch(
        `${BACKEND_API_URL}api/analysispresets/${action.preset.id}/`,
        action.preset,
        getHeaders(state$)
      )
      .map(data => editAnalysisPresetSuccess(data.response))
      .catch(error => Observable.of(editAnalysisPresetFailure(error.xhr)));
  });
}
