import {
  GET_WAGERS,
  GET_WAGERS_SUCCESS,
  GET_WAGERS_FAILURE,
  GET_WAGERS_NEXT,
  UPDATE_WAGER,
  UPDATE_WAGER_SUCCESS,
  UPDATE_WAGER_FAILURE,
  RESET_WAGER_EDIT_DEL,
  DELETE_WAGER,
  DELETE_WAGER_SUCCESS,
  DELETE_WAGER_FAILURE,
  RETRIEVE_WAGER,
  RETRIEVE_WAGER_SUCCESS,
  RETRIEVE_WAGER_FAILURE,
  FAKE_TRACK_WAGER, // in tour actions
  SET_SEEN_TOUR,
} from 'actions';

import { fakeWagersTour } from 'utils';

const initialState = {
  wagers: {
    todaysAction: [],
    historicals: [],
    userHistory: [],
    userPending: [],
    publicUserPending: [],
    publicUserHistory: [],
    accounting: [],
    hidden: [],
    game: [],
  },
  isWagersLoading: {
    todaysAction: false,
    historicals: false,
    userHistory: false,
    userPending: false,
    publicUserPending: false,
    publicUserHistory: false,
    accounting: false,
    hidden: false,
    game: false,
  },
  wagersError: {
    todaysAction: null,
    historicals: null,
    userHistory: null,
    userPending: null,
    publicUserPending: null,
    publicUserHistory: null,
    accounting: null,
    hidden: null,
    game: null,
  },
  isWagersNextLoading: {
    todaysAction: false,
    historicals: false,
    userHistory: false,
    userPending: false,
    publicUserPending: false,
    publicUserHistory: false,
    accounting: false,
    hidden: false,
    game: false,
  },
  wagersNext: {
    todaysAction: null,
    historical: null,
    userHistory: null,
    userPending: null,
    publicUserPending: null,
    publicUserHistory: null,
    accounting: null,
    hidden: null,
    game: null,
  },

  isUpdatingWager: false,
  updateWagerFailure: null,
  updateWagerSuccess: null,

  isDeletingWager: false,
  deleteWagerFailure: null,
  deleteWagerSuccess: null,

  isRetrievingWager: false,
  retrieveWagerFailure: null,
};

export default function wagerReducer(state = initialState, action) {
  switch (action.type) {
    case GET_WAGERS:
      return Object.assign({}, state, {
        ...state,
        wagers: { ...state.wagers, [action.screen]: [] },
        isWagersLoading: { ...state.isWagersLoading, [action.screen]: true },
        wagersError: { ...state.wagersError, [action.screen]: null },
        isWagersNextLoading: {
          ...state.isWagersNextLoading,
          [action.screen]: false,
        },
        wagersNext: { ...state.wagersNext, [action.screen]: null },
      });
    case GET_WAGERS_SUCCESS:
      // steps below ensure action.screen doesn't make duplicate calls.
      let wagerFilter = state.wagers[action.screen].map(item => item.id);
      return Object.assign({}, state, {
        ...state,
        wagers: {
          ...state.wagers,
          [action.screen]: state.wagers[action.screen].concat(
            action.payload?.results?.filter(
              item => !wagerFilter.includes(item.id)
            ) || []
          ),
        },
        isWagersLoading: { ...state.isWagersLoading, [action.screen]: false },
        wagersError: { ...state.wagersError, [action.screen]: null },
        isWagersNextLoading: {
          ...state.isWagersNextLoading,
          [action.screen]: false,
        },
        wagersNext: {
          ...state.wagersNext,
          [action.screen]: action.payload?.next,
        },
      });
    case GET_WAGERS_FAILURE:
      return Object.assign({}, state, {
        ...state,
        isWagersLoading: { ...state.isWagersLoading, [action.screen]: false },
        wagersError: { ...state.wagersError, [action.screen]: action.error },
        isWagersNextLoading: {
          ...state.isWagersNextLoading,
          [action.screen]: false,
        },
        wagersNext: { ...state.wagersNext, [action.screen]: null },
      });
    case GET_WAGERS_NEXT:
      return Object.assign({}, state, {
        ...state,
        isWagersLoading: { ...state.isWagersLoading, [action.screen]: false },
        wagersError: { ...state.wagersError, [action.screen]: null },
        isWagersNextLoading: {
          ...state.isWagersNextLoading,
          [action.screen]: true,
        },
      });
    case UPDATE_WAGER:
      return Object.assign({}, state, {
        ...state,
        isUpdatingWager: true,
        updateWagerFailure: null,
        updateWagerSuccess: false,
      });
    case UPDATE_WAGER_SUCCESS:
      let new_succ_update_wagers = { ...state.wagers };
      for (const key in state.wagers) {
        new_succ_update_wagers[key] = new_succ_update_wagers[key].reduce(
          (acc, wager) => {
            if (wager.id === action.payload.id) {
              if (
                (key === 'hidden' && !action.payload.is_hidden_from_tracking) ||
                (action.payload.is_hidden_from_tracking && key !== 'hidden')
              ) {
                return acc;
              }
              acc.push(action.payload);
            } else {
              acc.push(wager);
            }

            return acc;
          },
          []
        );
      }

      return Object.assign({}, state, {
        ...state,
        wagers: new_succ_update_wagers,
        isUpdatingWager: false,
        updateWagerFailure: null,
        updateWagerSuccess: true,
      });
    case UPDATE_WAGER_FAILURE:
      return Object.assign({}, state, {
        ...state,
        isUpdatingWager: false,
        updateWagerFailure: action.error,
        updateWagerSuccess: false,
      });
    case RESET_WAGER_EDIT_DEL:
      return Object.assign({}, state, {
        ...state,
        isUpdatingWager: false,
        updateWagerFailure: null,
        updateWagerSuccess: null,
        isDeletingWager: false,
        deleteWagerFailure: null,
        deleteWagerSuccess: null,
      });

    case DELETE_WAGER:
      return Object.assign({}, state, {
        ...state,
        isDeletingWager: true,
        deleteWagerFailure: null,
        deleteWagerSuccess: true,
      });
    case DELETE_WAGER_SUCCESS:
      let new_del_update_wagers = { ...state.wagers };
      for (const key in state.wagers) {
        new_del_update_wagers[key] = new_del_update_wagers[key].filter(
          wager => {
            if (wager.id === action.wager_id) {
              return false;
            }
            return true;
          }
        );
      }
      return Object.assign({}, state, {
        ...state,
        wagers: new_del_update_wagers,
        isDeletingWager: false,
        deleteWagerFailure: null,
        deleteWagerSuccess: true,
      });
    case DELETE_WAGER_FAILURE:
      return Object.assign({}, state, {
        ...state,
        isDeletingWager: false,
        deleteWagerFailure: action.error,
        deleteWagerSuccess: false,
      });

    case RETRIEVE_WAGER:
      return Object.assign({}, state, {
        ...state,
        isRetrievingWager: true,
        retrieveWagerFailure: null,
      });
    case RETRIEVE_WAGER_SUCCESS:
      let new_succ_retrieve_wagers = { ...state.wagers };
      for (const key in state.wagers) {
        new_succ_retrieve_wagers[key] = new_succ_retrieve_wagers[key].map(
          wager => {
            if (wager.id === action.payload.id) {
              return action.payload;
            }
            return wager;
          }
        );
      }

      return Object.assign({}, state, {
        ...state,
        wagers: new_succ_retrieve_wagers,
        isRetrievingWager: false,
        retrieveWagerFailure: null,
      });
    case RETRIEVE_WAGER_FAILURE:
      return Object.assign({}, state, {
        ...state,
        isRetrievingWager: false,
        retrieveWagerFailure: action.error,
      });

    case FAKE_TRACK_WAGER:
      return Object.assign({}, state, {
        ...state,
        wagers: {
          ...state.wagers,
          todaysAction: [
            fakeWagersTour('graded'),
            fakeWagersTour('playerprop'),
            action.wager,
          ],
          historicals: [
            fakeWagersTour('hist1'),
            fakeWagersTour('hist2'),
            fakeWagersTour('hist3'),
          ],
        },
      });

    case SET_SEEN_TOUR:
      return Object.assign({}, state, {
        ...state,
        wagers: {
          todaysAction: [],
          historicals: [],
          userHistory: [],
          userPending: [],
          publicUserPending: [],
          publicUserHistory: [],
          accounting: [],
          hidden: [],
        },
      });
    default:
      return state;
  }
}
