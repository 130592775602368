import { Observable } from 'rxjs';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import { ajax } from 'rxjs/observable/dom/ajax';
import { BACKEND_API_URL, getHeaders } from 'utils';

import {
  GET_FULL_TIME_BA,
  getFullTimeBASuccess,
  getFullTimeBAFailure,
} from 'actions';

export default function getFullTimeBA(action$, state$) {
  return action$.ofType(GET_FULL_TIME_BA).switchMap(action => {
    let url =
      BACKEND_API_URL +
      `api/best_available/?game_id=${action.game_id}&period=FT`;

    return ajax
      .getJSON(url, getHeaders(state$))
      .map(data => getFullTimeBASuccess(data))
      .catch(error => Observable.of(getFullTimeBAFailure(error.xhr)));
  });
}
