import React from 'react';
import styled from 'styled-components';

// leagues we have svg/multiple pngs for
const HQLeagues = [
  'BUND',
  'CFL',
  'EPL',
  'LIG1',
  'LIGA',
  'MLB',
  'MLS',
  'NBA',
  'NFL',
  'NHL',
  'SERA',
];

const Image = styled.img`
  width: ${props => {
    if (props.iconSize) {
      return props.iconSize + 'px';
    } else {
      return '26px';
    }
  }};
  height: ${props => {
    if (props.iconSize) {
      return props.iconSize + 'px';
    } else {
      return '26px';
    }
  }};
  object-fit: contain;
  @media only screen and (max-width: 625px) {
    width: ${props => props.mobileIconSize || '17px'};
    height: ${props => props.mobileIconSize || '17px'};
  }
`;

export default function TeamLeagueLogo(props) {
  const { league, team, iconSize, mobileIconSize } = props;
  let url = 'https://betstamp-public.sfo2.cdn.digitaloceanspaces.com/';
  if (team) {
    if (HQLeagues.includes(league)) {
      url += `team_logos/${league}/svg/${team}.svg`;
    } else {
      url += `team_logos/${league}/${team}.png`;
    }
  } else if (league) {
    url += `league_logos/${league?.toUpperCase()}.png`;
  } else {
    return null;
  }

  return (
    <Image
      alt={team || league}
      style={props.style}
      iconSize={iconSize}
      mobileIconSize={mobileIconSize}
      src={url}
      onError={e => {
        if (
          e.currentTarget &&
          ['UFC', 'ATP', 'WTA', 'GOLF', 'BELL'].includes(league)
        ) {
          e.currentTarget.src =
            'https://betstamp-public.sfo2.cdn.digitaloceanspaces.com/league_logos/missing-ufc.png';
        } else {
          e.currentTarget.src =
            'https://betstamp-public.sfo2.cdn.digitaloceanspaces.com/league_logos/missing.png';
        }
      }}
    />
  );
}
