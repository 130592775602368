import React from 'react';
import { useMemo } from 'react';
import {
  VictoryGroup,
  VictoryBar,
  VictoryPie,
  VictoryChart,
  VictoryAxis,
  VictoryArea,
  VictoryVoronoiContainer,
  VictoryTooltip,
} from 'victory';
import moment from 'moment';

// utils
import { kFormatter } from 'utils';

export default function Graph(props) {
  const { type } = props;
  if (type === 'bar') {
    return <BarGraph {...props} />;
  } else if (type === 'pie') {
    return <PieGraph {...props} />;
  } else if (type === 'area') {
    return <Area {...props} />;
  }

  return <BarGraph {...props} />;
}

const _calcData = (data, data2) => {
  let maxima1 = Math.max(...data.map(d => Math.abs(d.y))); // absolute value of the largest value, for normalization
  maxima1 = Math.max(maxima1, 0.01); // maxima at least 0.01, mostly so clv doesn't look stupid
  let minimum1 = Math.min(...data.map(d => d.y));
  let maximum1 = Math.max(...data.map(d => d.y));

  let maxima2 = 0;
  let minimum2 = 0;
  let maximum2 = 0;
  if (data2) {
    maxima2 = Math.max(...data2.map(d => Math.abs(d.y)));
    maxima2 = Math.max(maxima2, 0.01); // maxima at least 0.01, mostly so clv doesn't look stupid
    minimum2 = Math.min(...data2.map(d => d.y));
    maximum2 = Math.min(...data2.map(d => d.y));
  }

  return {
    maxima1,
    minimum1,
    maximum1,
    maxima2,
    minimum2,
    maximum2,
  };
};

function Area(props) {
  const { data, data2, dataSymbol, data2Symbol } = props;

  // let maxima1 = Math.max(...data.map((d) => Math.abs(d.y))); // absolute value of the largest value, for normalization
  // maxima1 = Math.max(maxima1, 0.01); // maxima at least 0.01, mostly so clv doesn't look stupid
  // let minimum1 = Math.min(...data.map((d) => d.y));
  // let maximum1 = Math.max(...data.map((d) => d.y));

  // let maxima2 = 0;
  // let minimum2 = 0;
  // let maximum2 = 0;
  // if (data2) {
  //   maxima2 = Math.max(...data2.map((d) => Math.abs(d.y)));
  //   maxima2 = Math.max(maxima2, 0.01); // maxima at least 0.01, mostly so clv doesn't look stupid
  //   minimum2 = Math.min(...data2.map((d) => d.y));
  //   maximum2 = Math.min(...data2.map((d) => d.y));
  // }

  let calcs = useMemo(() => _calcData(data, data2), [data, data2]);
  let { maxima1, minimum1, maximum1, maxima2, minimum2, maximum2 } = calcs;

  let domainy = [-1, 1];

  // only show positive axis if there's only positive values
  if (minimum1 >= 0 && minimum2 >= 0) domainy[0] = 0;

  // only show negative axis if there's only negative values
  if (maximum1 <= 0 && maximum2 <= 0) domainy[1] = 0;

  const formatTick = (t, maxima, symbol) => {
    let val = 0;
    if (maxima < 1 || Math.abs(t * maxima) < 10) {
      val = (t * maxima).toFixed(2);
    } else {
      val = Math.round(t * maxima);
    }

    if (symbol === '$') {
      if (val < 0) {
        return `-$${kFormatter(val * -1)}`;
      }
      return `$${kFormatter(val)}`;
    } else if (symbol === '%') {
      return `${val}%`;
    }
  };

  return (
    <VictoryChart
      //animate={true}
      height={128}
      padding={{ top: 15, bottom: 10, left: 25, right: 25 }}
      domainPadding={{
        x: [0, 0],
        y: [10, 10],
      }}
      // domain={{ y: [-1, 1] }}
      domain={{ y: domainy }}
      containerComponent={
        <VictoryVoronoiContainer
          voronoiDimension="x"
          labels={({ datum }) =>
            `${datum.tooltip_label}: ${datum.y.toFixed(2)}`
          }
          labelComponent={
            <VictoryTooltip
              center={{ x: 40 + 25 + 5, y: 15 }}
              flyoutWidth={80}
              cornerRadius={2}
              pointerLength={0}
              pointerWidth={0}
              flyoutStyle={{ fill: 'rgba(0,0,0,0.75)', strokeWidth: 0 }}
            />
          }
        />
      }
    >
      {/* independant (x) axis, bottom */}
      <VictoryAxis
        orientation="bottom"
        padding={0}
        offsetY={6}
        style={{
          axis: { stroke: 'transparent' },
          tickLabels: { fontSize: 6, padding: 0, fill: 'var(--color-text)' },
        }}
        tickCount={Math.min(Math.max(data.length, 3), 10)}
        tickFormat={x => moment(x).format('MMM D YYYY')}
        fixLabelOverlap
      />
      <VictoryAxis
        tickLabelComponent={<></>}
        padding={0}
        style={{
          axis: { stroke: 'var(--color-text)' },
        }}
      />

      {/* depenedent (y) axis, dataset 1, left */}
      <VictoryAxis
        orientation={'left'}
        dependentAxis
        tickFormat={t => formatTick(t, maxima1, dataSymbol)}
        domain={[
          Math.min(...data.map(d => d.y)),
          Math.max(...data.map(d => d.y)),
        ]}
        tickCount={6}
        style={{
          axis: { stroke: 'var(--color-primary)', strokeWidth: 3 },
          ticks: { stroke: 'var(--color-primary)', size: 2 },
          tickLabels: { fontSize: 6, padding: 2, fill: 'var(--color-text)' },
        }}
      />

      {/* depenedent (x) axis, dataset 2, right */}
      {data2 && (
        <VictoryAxis
          orientation={'right'}
          dependentAxis
          tickFormat={t => formatTick(t, maxima2, data2Symbol)}
          domain={[
            Math.min(...data2.map(d => d.y)),
            Math.max(...data2.map(d => d.y)),
          ]}
          tickCount={6}
          style={{
            axis: { stroke: 'var(--color-complement)', strokeWidth: 3 },
            ticks: { stroke: 'var(--color-complement)', size: 2 },
            tickLabels: { fontSize: 6, padding: 2, fill: 'var(--color-text)' },
          }}
        />
      )}

      <defs>
        <linearGradient id={'chart-gradient1'} x1="0%" y="0%" x2="0%" y2="100%">
          <stop offset="0%" stopColor="var(--color-primary)" stopOpacity={1} />
          <stop
            offset="100%"
            stopColor="var(--color-primary)"
            stopOpacity={0}
          />
        </linearGradient>
      </defs>

      {/* line 1 */}
      <VictoryArea
        style={{
          data: {
            fill: data2 ? 'transparent' : 'url(#chart-gradient1)',
            stroke: 'var(--color-primary)',
            strokeWidth: 1,
          },
          labels: {
            fill: 'var(--color-primary)',
            fontSize: 6,
            //textAnchor: 'left',
          },
        }}
        interpolation="monotoneX"
        data={data}
        y={datum => datum.y / maxima1}
      />

      {/* line 2 */}
      {data2 && (
        <VictoryArea
          style={{
            data: {
              // fill: 'url(#chart-gradient2)',
              fill: 'transparent',
              stroke: 'var(--color-complement)',
              strokeWidth: 1,
            },
            labels: {
              fill: 'var(--color-complement)',
              fontSize: 6,
              //textAnchor: 'left',
            },
          }}
          interpolation="monotoneX"
          data={data2}
          y={datum => datum.y / maxima2}
        />
      )}
    </VictoryChart>
  );
}

const _transformData = (rows, accessor) => {
  return rows.map(row => {
    const dataPoint = row.cells.find(c => c.calculation === accessor);
    return {
      x: row.cells[0].text,
      y: parseFloat(dataPoint.text.replace('$', '').replace('%', '')),
      title: dataPoint.calculation,
    };
  });
};

const _groupData = (rows, accessors) => {
  let groups = [];
  accessors.forEach(accessor => {
    if (accessor) {
      let data = _transformData(rows, accessor);
      groups.push(data);
    }
  });
  return groups;
};

function BarGraph(props) {
  const { accessors, rows, symbol } = props;

  let groups = useMemo(() => _groupData(rows, accessors), [rows, accessors]);

  return (
    <VictoryChart
      domainPadding={{
        x: [50, 50],
        y: 10,
      }}
      animate={true}
      height={128}
      padding={{ top: 10, bottom: 15, left: 20, right: 0 }}
    >
      <VictoryAxis
        orientation="bottom"
        padding={0}
        offsetY={6}
        style={{
          axis: { stroke: 'transparent' },
          tickLabels: { fontSize: 6, padding: 0, fill: 'var(--color-text)' },
        }}
      />
      <VictoryAxis
        tickLabelComponent={<></>}
        padding={0}
        style={{
          axis: { stroke: 'var(--color-text)' },
        }}
      />
      <VictoryAxis
        dependentAxis
        padding={0}
        tickFormat={y =>
          `${symbol === '$' ? '$' : ''}${y}${symbol === '%' ? '%' : ''}`
        }
        style={{
          axis: { stroke: 'var(--color-text)' },
          ticks: { stroke: 'var(--color-text)', size: 2 },
          tickLabels: { fontSize: 6, padding: 2, fill: 'var(--color-text)' },
        }}
      />
      <VictoryGroup offset={25}>
        {groups.map((data, i) => (
          <VictoryBar
            key={`analysis-bar-graph-bar-${i}`}
            data={data}
            barWidth={20}
            labels={({ datum }) =>
              `${datum.title}\n${symbol === '$' ? '$' : ''}${datum.y.toFixed(
                2
              )}${symbol === '%' ? '%' : ''}`
            }
            style={{
              data: {
                fill: ({ datum }) =>
                  datum.y > 0
                    ? 'var(--color-dollar-green)'
                    : 'var(--color-dollar-red)',
              },
              labels: {
                fontSize: 6,
                padding: 4,
                fill: 'var(--color-text)',
              },
            }}
          />
        ))}
      </VictoryGroup>
    </VictoryChart>
  );
}

function PieGraph(props) {
  const { accessor, rows } = props;

  let data = useMemo(() => _transformData(rows, accessor), [rows, accessor]);

  return (
    <VictoryPie
      animate={true}
      data={data}
      innerRadius={20}
      height={100}
      padding={{ top: 10, bottom: 10, left: 0, right: 0 }}
      style={{
        labels: {
          fontSize: 6,
          padding: 4,
          fill: 'var(--color-text)',
        },
      }}
    />
  );
}
