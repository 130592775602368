import { Observable } from 'rxjs';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import { ajax } from 'rxjs/observable/dom/ajax';
import { BACKEND_API_URL, getHeaders } from 'utils';

import {
  GET_FUTURES_ODDS,
  getFuturesOddsSuccess,
  getFuturesOddsFailure,
} from 'actions';

export default function getFuturesOdds(action$, state$) {
  return action$.ofType(GET_FUTURES_ODDS).switchMap(action => {
    let url = BACKEND_API_URL + `api/futures_odds/?`;

    if (action.leagues) url += `&leagues=${JSON.stringify(action.leagues)}`;
    if (action.is_graded !== undefined) url += `&is_graded=${action.is_graded}`;
    if (action.season) url += `&season=${action.season}`;
    if (action.season_complete !== undefined) {
      url += `&season_complete=${action.season_complete}`;
    }
    if (action.prop_types)
      url += `&prop_types=${JSON.stringify(action.prop_types)}`;

    return ajax
      .getJSON(url, getHeaders(state$))
      .map(data => getFuturesOddsSuccess(data))
      .catch(error => Observable.of(getFuturesOddsFailure(error.xhr)));
  });
}
