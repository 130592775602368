import { Observable } from 'rxjs';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import { ajax } from 'rxjs/observable/dom/ajax';
import { BACKEND_API_URL, getHeaders } from 'utils';

import {
  GET_TRANSACTIONS,
  getTransactionsSuccess,
  getTransactionsFailure,
} from 'actions';

export default function getTransactions(action$, state$) {
  return action$.ofType(GET_TRANSACTIONS).mergeMap(action => {
    let url = `${BACKEND_API_URL}api/transactions/?`;

    if (action.book !== null && action.book !== undefined) {
      url += `book=${action.book.replace('+', '%2B')}&`;
    }

    return ajax
      .getJSON(url, getHeaders(state$))
      .map(data => getTransactionsSuccess(data, action.screen))
      .catch(error =>
        Observable.of(getTransactionsFailure(error.xhr, action.screen))
      );
  });
}
