/**
 * Turn numbers is shorter forms (10,000 -> 10k)
 * @param {Number} num Number to be formatted
 */
export default function kFormatter(num) {
  if (num === null || num.length === 0 || num === undefined) return '';

  let leadingSymbol = '';
  let endingSymbol = '';

  if (num[0] === '$') {
    leadingSymbol = '$';
    num = num.replace('$', '');
  } else if (num.length > 2 && num[0] === '-' && num[1] === '$') {
    leadingSymbol = '-$';
    num = num.replace('-$', '');
  }
  if (num[num.length - 1] === '%') {
    return num;
  }

  return Math.abs(num) > 9999
    ? leadingSymbol +
        Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) +
        'k' +
        endingSymbol
    : leadingSymbol + Math.sign(num) * Math.abs(num) + endingSymbol;
}
