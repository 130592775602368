/**
 * Removes things like '+', ' ', from Decimal odds.
 * @param {String} odds Dirty Decimal odds
 * @returns {String} Cleaned Decimal odds.
 */
export default function cleanDecimalOdds(odds) {
  // Up to six digits (1 - 9, not 0), one decimal place,
  // then up to three digits (0 - 9)
  odds = odds.match(/^[1-9][0-9]{0,5}\.?\d{0,3}/g);
  if (!odds) return '';
  return odds.join('');
}
