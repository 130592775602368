import { Observable } from 'rxjs';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import { ajax } from 'rxjs/observable/dom/ajax';
import { BACKEND_API_URL, getHeaders } from 'utils';

import {
  POST_SURVEY_RESULTS,
  postSurveyResultsSuccess,
  postSurveyResultsFailure,
} from 'actions';

export default function postSurveyResults(action$, state$) {
  return action$.ofType(POST_SURVEY_RESULTS).switchMap(action => {
    return ajax
      .post(
        BACKEND_API_URL + 'api/survey_results/',
        { survey: action.survey, answers: action.answers },
        getHeaders(state$)
      )
      .map(res => postSurveyResultsSuccess(res))
      .catch(error => Observable.of(postSurveyResultsFailure(error.xhr)));
  });
}
