import React, { useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useMutation, useQueryClient, useQuery } from '@tanstack/react-query';
import { useEffectOnce } from 'react-use';
import {
  IoInformationCircleOutline,
  IoChevronBack,
  IoTrash,
  IoArrowUndoSharp,
} from 'react-icons/io5';

// utils
import {
  PROVINCE_OPTIONS,
  US_STATES,
  STATE_OPTIONS,
  INFLUENCER_PAGE_TAGS,
  CURRENCY_OPTIONS,
  OTHER_STATE_OPTIONS,
  USA_FLAG,
  CANADA_FLAG,
} from 'utils';

// components
import { AuthTextInput } from 'components/AuthTextInput';
import { AuthButton, IconButton } from 'components/AuthButton';
import Select from 'components/generic/Select';
import { Col, Row, Grid } from 'components/generic/Layout';
import { Table, Tbody } from 'components/generic/Tables';
import CalendarInput from 'components/generic/CalendarInput';

// actions
import { getAllBooks } from 'actions';
import ActivityIndicator from 'components/generic/ActivityIndicator';

export default function SportsbookInfoWizard(props) {
  const dispatch = useDispatch();
  const reduxProps = useSelector(state => ({
    allBooksMap: state.authReducer.allBooksMap,
  }));
  const { allBooksMap } = reduxProps;

  const givenBookId = props.bookId;

  useEffectOnce(() => {
    dispatch(getAllBooks());
  });

  const [steps, setSteps] = useState(['book']);
  const [objectsToChange, setObjectsToChange] = useState([]);
  const [formData, setFormData] = useState({ book_id: givenBookId });
  const [bookSearch, setBookSearch] = useState(null);

  const step = steps[steps.length - 1];

  const existingInfos = useQuery({
    enabled: !!formData.book_id,
    queryKey: [
      {
        endpoint: 'sportsbook-infos',
        urlParams: {
          book_ids: JSON.stringify([formData.book_id]),
          page_size: 333, // to make sure we can get them all without paginating
        },
      },
    ],
  });

  const sortedBooks = useMemo(() => {
    let sortedBooks = [];
    if (allBooksMap) {
      sortedBooks = Object.values(allBooksMap).sort((a, b) => {
        if (a?.is_affiliate && !b?.is_affiliate) {
          return -1;
        }
        if (!a?.is_affiliate && b?.is_affiliate) {
          return 1;
        }
        if (a?.name > b?.name) {
          return 1;
        } else if (a?.name < b?.name) {
          return -1;
        }
        return 0;
      });
    }
    return sortedBooks;
  }, [allBooksMap]);

  const sortedFilteredBook = useMemo(() => {
    let filtered = Array.from(sortedBooks);
    if (bookSearch) {
      filtered = filtered.filter(b =>
        b?.name?.toLowerCase().includes(bookSearch)
      );
    }
    return filtered;
  }, [bookSearch, sortedBooks]);

  const _deriveObjectsToChange = givenFormData => {
    let infos = [];
    for (const region of givenFormData?.regions) {
      let existingInfo = existingInfos?.data?.results.find(
        i => i?.region === region
      );
      let info = null;
      if (existingInfo) {
        info = {
          region: region,
          ...givenFormData,
          existingInfo: existingInfo,
          discardChanges: [],
        };
      } else {
        info = {
          ...givenFormData,
          region: region,
          discardChanges: [],
        };
      }
      if (info.start_date) {
        info.start_date = info.start_date.toISOString().substring(0, 10);
      }
      if (info) {
        delete info['regions'];
        infos.push(info);
      }
    }
    setObjectsToChange(infos);
  };

  const queryClient = useQueryClient();
  const submitInfos = useMutation({
    mutationKey: 'submit-min-deposit',
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [
          {
            endpoint: `sportsbook-infos`,
          },
        ],
      });
      props.closePanel();
    },
  });

  const _getBase64 = async file => {
    return new Promise(resolve => {
      let baseURL = '';
      let reader = new FileReader(); // Convert the file to base64 text
      reader.readAsDataURL(file); // on reader load somthing...
      reader.onload = () => {
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  if (!formData?.book_id || step === 'book') {
    return (
      <form
        style={{ width: '100%' }}
        onSubmit={ev => {
          ev.preventDefault();
          setSteps([...steps, 'action']);
        }}
      >
        <Row
          style={{
            alignItems: 'center',
            gap: 'var(--space-md)',
            position: 'sticky',
            top: 48,
            backgroundColor: 'var(--color-bg)',
          }}
        >
          <AuthTextInput
            containerStyle={{ flex: 2 }}
            placeholder="Search by book name"
            onChangeText={text => setBookSearch(text)}
          />
          <AuthButton disabled={!formData?.book_id}>Next</AuthButton>
        </Row>
        <Grid>
          {sortedFilteredBook.map(book => (
            <AuthButton
              key={`book-wizard-${book.id}`}
              type="button"
              overrideChildren
              colorTheme={formData?.book_id === book.id ? 'inverted' : 'text'}
              btnTheme="borderless"
              containerStyle={{ borderRadius: 8, margin: 0 }}
              btnStyle={{ borderRadius: 9, padding: 0 }}
              title={book?.name}
              onPress={() => setFormData({ ...formData, book_id: book?.id })}
            >
              <Row
                style={{
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  gap: 'var(--space-md)',
                }}
              >
                <img
                  style={{ width: 64, height: 64, borderRadius: 8 }}
                  src={book?.logo}
                  alt={book?.name}
                />
                <b>{book?.name}</b>
              </Row>
            </AuthButton>
          ))}
        </Grid>
        <AuthButton disabled={!formData?.book_id}>Next</AuthButton>
      </form>
    );
  }

  if (step === 'action') {
    if (existingInfos?.isLoading) {
      return <ActivityIndicator />;
    }
    return (
      <Col style={{ flex: 0, width: '100%', gap: 'var(--space-xs)' }}>
        <Row
          style={{
            width: '100%',
            alignItems: 'center',
            gap: 'var(--space-md)',
          }}
        >
          <AuthButton
            onPress={() => setSteps([...steps.slice(0, steps.length - 1)])}
            containerStyle={{
              alignSelf: 'flex-start',
              flex: 1,
              width: 'unset',
            }}
            leftIcon={IoChevronBack}
            colorTheme="text"
            btnTheme="borderless"
          >
            Back
          </AuthButton>
          <Row
            style={{
              width: '100%',
              flex: 3,
              alignItems: 'center',
              gap: 'var(--space-sm)',
              justifyContent: 'center',
            }}
          >
            <img
              style={{ width: 48, height: 48, borderRadius: 8 }}
              src={allBooksMap[formData?.book_id]?.logo}
              alt={allBooksMap[formData?.book_id]?.name}
            />
            <h4 style={{ margin: 0 }}>
              {allBooksMap[formData?.book_id]?.name}
            </h4>
          </Row>
          <Col style={{ textAlign: 'right' }}>
            <small>
              {existingInfos?.data?.count} existing sportsbook infos
            </small>
          </Col>
        </Row>
        {existingInfos?.data?.results?.length > 0 && (
          <>
            <AuthButton
              containerStyle={{}}
              btnTheme="onb"
              colorTheme="text"
              onPress={() => {
                setSteps([...steps, 'newbanners']);
                setFormData({ ...formData });
              }}
            >
              New Banners & Promotions
            </AuthButton>
            <AuthButton
              btnTheme="onb"
              colorTheme="text"
              onPress={() => {
                setSteps([...steps, 'cpadata']);
                setFormData({ ...formData });
              }}
            >
              Update CPA and Related Data
            </AuthButton>
            <AuthButton
              btnTheme="onb"
              colorTheme="text"
              onPress={() => {
                setSteps([...steps, 'mindepositdata']);
                setFormData({ ...formData });
              }}
            >
              Update Min Deposit and Related Data
            </AuthButton>
            <AuthButton
              btnTheme="onb"
              colorTheme="text"
              onPress={() => {
                setSteps([...steps, 'infldata']);
                setFormData({ ...formData });
              }}
            >
              Update Influencer Page Data
            </AuthButton>
            <AuthButton
              btnTheme="onb"
              colorTheme="text"
              onPress={() => {
                setSteps([...steps, 'controlledtrafficdata']);
                setFormData({
                  ...formData,
                  controlled_traffic: true,
                });
              }}
            >
              Update Controlled Traffic Data
            </AuthButton>
          </>
        )}
        <AuthButton
          btnTheme="onb"
          colorTheme="text"
          onPress={() => {
            setSteps([...steps, 'newregionscopy']);
            setFormData({ ...formData });
          }}
        >
          Add New Regions
        </AuthButton>
      </Col>
    );
  }

  if (!formData?.regions || formData?.regions?.length === 0) {
    return (
      <>
        <CustomRegionSelector
          hideSelectAllWhereAvailable={
            (step === 'newregions' || step === 'newregionscopy') &&
            existingInfos?.data?.results?.length > 0
          }
          goBack={() => {
            setFormData({ ...formData, regions: [] });
            setSteps([...steps.slice(0, steps.length - 1)]);
          }}
          actualBook={allBooksMap[formData?.book_id]}
          confirmSelections={regions => {
            setFormData({ ...formData, regions: regions });
          }}
          existingInfos={existingInfos?.data?.results}
          step={step}
        //editType={formData?.editType}
        />
      </>
    );
  }

  if (step === 'newregionscopy') {
    return (
      <>
        <p style={{ textAlign: 'center' }}>
          Select a region to copy data from (you can edit the data on the next
          screen) or start completely fresh
        </p>
        <Grid style={{ width: '100%', gap: '0 1rem' }}>
          {existingInfos.data?.results.map(sbi => (
            <AuthButton
              key={`sbi-wizard-copy-region-${sbi.region}`}
              btnTheme="borderless"
              onPress={async () => {
                let copyValues = { ...sbi };
                try {
                  delete copyValues['id'];
                  delete copyValues['created_at'];
                  delete copyValues['modified_at'];
                  delete copyValues['start_date'];
                  delete copyValues['end_date'];
                  delete copyValues['status'];
                  delete copyValues['book_banner_offer_and_min'];
                  delete copyValues['book_banner_min'];
                  delete copyValues['book_banner_offer'];
                } catch (e) { }
                setFormData({ ...formData, ...copyValues });
                setSteps([...steps, 'newregions']);
              }}
            >
              {sbi.region}
            </AuthButton>
          ))}
        </Grid>
        <AuthButton onPress={() => setSteps([...steps, 'newregions'])}>
          Enter all fresh values
        </AuthButton>
      </>
    );
  }
  if (
    step === 'newbanners' ||
    step === 'mindepositdata' ||
    step === 'cpadata' ||
    step === 'controlledtrafficdata' ||
    step === 'infldata' ||
    step === 'newregions'
  ) {
    return (
      <div style={{ width: '100%' }}>
        {(step === 'mindepositdata' ||
          step === 'cpadata' ||
          step === 'newregions') && (
            <Col style={{ marginTop: 'var(--space-sm)', alignItems: 'stretch' }}>
              <span>Deal Start Date</span>
              <Row
                style={{
                  backgroundColor: 'var(--color-fg)',
                  border: '1px solid var(--color-text-light)',
                  borderRadius: '32px',
                  padding: 'var(--space-xxxs)',
                }}
              >
                <CalendarInput
                  onChange={date =>
                    setFormData({ ...formData, start_date: date })
                  }
                  value={formData.start_date}
                />
              </Row>
            </Col>
          )}
        {(step === 'mindepositdata' || step === 'newregions') && (
          <>
            <Select
              name="currency"
              label="Currency"
              placeholder="CAD"
              options={CURRENCY_OPTIONS.map(s => ({ label: s, value: s }))}
              defaultValue={
                formData?.currency
                  ? {
                    label: formData.currency,
                    value: formData.currency,
                  }
                  : { label: 'Select Currency', value: 'Select' }
              }
              onChange={opt => {
                setFormData({
                  ...formData,
                  currency: opt.value,
                });
              }}
            />
            <AuthTextInput
              name="affiliate_min_deposit"
              label="Base min deposit ($) *"
              placeholder="10"
              inputMode="numeric"
              pattern="[0-9]*"
              defaultValue={
                formData?.affiliate_min_deposit !== undefined
                  ? formData.affiliate_min_deposit
                  : null
              }
              onChangeText={text =>
                setFormData({ ...formData, affiliate_min_deposit: text })
              }
            />
          </>
        )}

        {(step === 'mindepositdata' ||
          step === 'newregions' ||
          step === 'newbanners') && (
            <>
              <AuthTextInput
                name="bonus"
                label="Bonus Description"
                placeholder="5 x $10 FREE bets and up to $1000 in Second Chance Bets"
                defaultValue={
                  formData?.bonus !== undefined ? formData.bonus : null
                }
                onChangeText={text => setFormData({ ...formData, bonus: text })}
              />
            </>
          )}

        {(step === 'mindepositdata' || step === 'newregions') && (
          <>
            <AuthTextInput
              name="affiliate_min_deposit_text"
              label="Base special min deposit (overrides base min deposit)"
              placeholder="Ex. 2 Bets of $10"
              type="text"
              defaultValue={
                formData?.affiliate_min_deposit_text !== undefined
                  ? formData.affiliate_min_deposit_text
                  : null
              }
              onChangeText={text =>
                setFormData({ ...formData, affiliate_min_deposit_text: text })
              }
            />
            <AuthTextInput
              name="group_affiliate_min_deposit"
              label="Group min deposit ($) *"
              placeholder="20"
              inputMode="numeric"
              pattern="[0-9]*"
              defaultValue={
                formData?.group_affiliate_min_deposit !== undefined
                  ? formData.group_affiliate_min_deposit
                  : null
              }
              onChangeText={text =>
                setFormData({ ...formData, group_affiliate_min_deposit: text })
              }
            />
          </>
        )}

        {(step === 'mindepositdata' ||
          step === 'newregions' ||
          step === 'newbanners') && (
            <>
              <AuthTextInput
                name="bonus_terms"
                label="Bonus terms"
                multiline
                placeholder="Sign-up and get 5 x $10 FREE bets and up to $1000 in Second Chance Bets. For 10 Days your 1st fixed odds cash bet is guaranteed up to $100!"
                defaultValue={
                  formData?.bonus_terms !== undefined
                    ? formData.bonus_terms
                    : null
                }
                onChangeText={text =>
                  setFormData({ ...formData, bonus_terms: text })
                }
              />
              <AuthTextInput
                name="bonus_min_deposit"
                label="Minumum deposit to get the bonus ($)"
                inputMode="numeric"
                pattern="[0-9]*"
                placeholder="10"
                defaultValue={
                  formData?.bonus_min_deposit !== undefined
                    ? formData.bonus_min_deposit
                    : null
                }
                onChangeText={text =>
                  setFormData({ ...formData, bonus_min_deposit: text })
                }
              />
              <AuthTextInput
                name="bonus_ev"
                label="Bonus expected value ($)"
                inputMode="numeric"
                pattern="[0-9]*"
                placeholder="10"
                defaultValue={
                  formData?.bonus_ev !== undefined ? formData.bonus_ev : null
                }
                onChangeText={text =>
                  setFormData({ ...formData, bonus_ev: text })
                }
              />
              <AuthTextInput
                name="recommended_deposit"
                label="Recommended deposit ($)"
                inputMode="numeric"
                pattern="[0-9]*"
                placeholder="10"
                defaultValue={
                  formData?.recommended_deposit !== undefined
                    ? formData.recommended_deposit
                    : null
                }
                onChangeText={text =>
                  setFormData({ ...formData, recommended_deposit: text })
                }
              />
            </>
          )}
        {(step === 'cpadata' || step === 'newregions') && (
          <>
            <Select
              name="cpa_currency"
              label="CPA Currency"
              placeholder="CAD"
              options={CURRENCY_OPTIONS.map(s => ({ label: s, value: s }))}
              defaultValue={
                formData?.currency
                  ? {
                    label: formData.currency,
                    value: formData.currency,
                  }
                  : { label: 'Select Currency', value: 'Select' }
              }
              onChange={opt => {
                setFormData({
                  ...formData,
                  cpa_currency: opt.value,
                });
              }}
            />
            <AuthTextInput
              name="cpa"
              label="CPA ($)"
              placeholder="CPA"
              inputMode="numeric"
              pattern="[0-9]*"
              defaultValue={formData?.cpa !== undefined ? formData.cpa : null}
              onChangeText={text => setFormData({ ...formData, cpa: text })}
            />
            <AuthTextInput
              name="revenue_share_percent"
              label="Revenue Share Ratio (0 to 1)"
              placeholder="0.1 = 10%"
              inputMode="numeric"
              defaultValue={
                formData?.revenue_share_percent !== undefined
                  ? formData.revenue_share_percent
                  : null
              }
              onChangeText={text =>
                setFormData({ ...formData, revenue_share_percent: text })
              }
            />
          </>
        )}
        {(step === 'controlledtrafficdata' || step === 'newregions') && (
          <>
            <div
              style={{ marginTop: 'var(--space-md)' }}
              title="Only assign this book to a certain number of attendees per month, and only if those attendees have a low target"
            >
              <input
                type="checkbox"
                name="controlled_traffic"
                id="controlled_traffic"
                defaultChecked={
                  formData?.controlled_traffic !== undefined
                    ? formData.controlled_traffic
                    : null
                }
                onChange={input =>
                  setFormData({
                    ...formData,
                    controlled_traffic: input?.target?.checked,
                  })
                }
              />
              <label htmlFor="controlled_traffic">
                Controlled Traffic Book{' '}
                <IoInformationCircleOutline
                  color="var(--color-text)"
                  size={16}
                  style={{ verticalAlign: 'middle' }}
                />
              </label>
            </div>
            <AuthTextInput
              name="monthly_signup_cap"
              label="Monthly Signup Cap"
              inputMode="numeric"
              pattern="[0-9]*"
              placeholder="The monthly cap on the number of attendee this book and region will be assigned to"
              defaultValue={
                formData?.monthly_signup_cap !== undefined
                  ? formData.monthly_signup_cap
                  : null
              }
              onChangeText={text =>
                setFormData({ ...formData, monthly_signup_cap: text })
              }
            />
          </>
        )}
        {(step === 'newbanners' || step === 'newregions') && (
          <>
            <div
              style={{
                width: '100%',
                border: '1px solid var(--color-text-light)',
                borderRadius: 'var(--std-border-radius)',
                padding: '0 var(--space-sm)',
              }}
            >
              <AuthTextInput
                name="betlink_banner"
                label="Betlink Banner/Signupexpert Banner (900x300)"
                type="file"
                onChangeText={(_, ev) => {
                  let file = ev.target.files[0];
                  _getBase64(file).then(result => {
                    file['base64'] = result;
                    setFormData({
                      ...formData,
                      betlink_banner: file,
                    });
                  });
                }}
              />
              <details style={{ cursor: 'pointer' }}>
                <summary>Where is this banner shown?</summary>
                At the time of writing:
                <br />
                <b>betstamp</b>
                <ul style={{ marginTop: 0 }}>
                  <li>app/web: in the main betlink tab</li>
                  <li>app: in the accounting screen</li>
                  <li>
                    app: when users are placing a bet if this book has better
                    odds
                  </li>
                </ul>
                <b>Signup Expert</b>
                <br />
                On the main all books screen of an influencer's page
                <br />
              </details>
            </div>
            <div
              style={{
                width: '100%',
                border: '1px solid var(--color-text-light)',
                borderRadius: 'var(--std-border-radius)',
                padding: '0 var(--space-sm)',
                marginTop: 'var(--space-sm)',
              }}
            >
              <AuthTextInput
                name="subaffiliate_banner"
                label="Subaffiliate Banner (2400x1600)"
                type="file"
                onChangeText={(_, ev) => {
                  let file = ev.target.files[0];
                  _getBase64(file).then(result => {
                    file['base64'] = result;
                    setFormData({
                      ...formData,
                      subaffiliate_banner: file,
                    });
                  });
                }}
              />
              <details style={{ cursor: 'pointer' }}>
                <summary>Where is this banner shown?</summary>
                At the time of writing:
                <br />
                <b>Signup Expert</b>
                <br />
                On the single book screen
                <br />
              </details>
            </div>
          </>
        )}
        {(step === 'infldata' || step === 'newregions') && (
          <>
            <AuthTextInput
              name="influencer_page_rating_stars"
              label="Rating (out of 5)"
              inputMode="numeric"
              pattern="[0-9]*"
              placeholder="The rating to show on the influencer page"
              defaultValue={
                formData?.influencer_page_rating_stars !== undefined
                  ? formData.influencer_page_rating_stars
                  : null
              }
              onChangeText={text =>
                setFormData({ ...formData, influencer_page_rating_stars: text })
              }
            />
            <AuthTextInput
              name="influencer_page_book_subtitle"
              label="Influencer Page Subtitle"
              placeholder="The subtitle to show under the book on the influencer page"
              defaultValue={
                formData?.influencer_page_book_subtitle !== undefined
                  ? formData.influencer_page_book_subtitle
                  : null
              }
              onChangeText={text =>
                setFormData({
                  ...formData,
                  influencer_page_book_subtitle: text,
                })
              }
            />
            <AuthTextInput
              name="influencer_page_book_desc"
              label="Influencer Page Description"
              multiline
              placeholder="Description to show with the book on the influencer page"
              defaultValue={
                formData?.influencer_page_book_desc !== undefined
                  ? formData.influencer_page_book_desc
                  : null
              }
              onChangeText={text =>
                setFormData({ ...formData, influencer_page_book_desc: text })
              }
            />
            <div style={{ margin: 'var(--space-xxs) 0' }}>
              <Select
                name="influencer_page_book_tags"
                label="Book tags"
                placeholder="Select all that describe this book"
                isMulti
                options={INFLUENCER_PAGE_TAGS.map(s => ({
                  label: s,
                  value: s,
                }))}
                onChange={opts => {
                  setFormData({
                    ...formData,
                    influencer_page_book_tags: opts.map(o => o.value),
                  });
                }}
              />
            </div>
          </>
        )}
        <AuthButton
          onPress={() => {
            _deriveObjectsToChange(formData);
            setSteps([...steps, 'confirm']);
          }}
        >
          Review Changes
        </AuthButton>
      </div>
    );
  }

  if (step === 'confirm') {
    return (
      <>
        <h6>Summary</h6>
        <p>
          The following changes will be applied to{' '}
          {allBooksMap[formData?.book_id]?.name}{' '}
        </p>
        <Table
          style={{
            minWidth: '0',
            border: '1px solid var(--color-text-light)',
            borderRadius: 8,
          }}
          cellSpacing={0}
        >
          <Tbody>
            {objectsToChange.map(info => (
              <tr
                key={`wizard-changes-${info?.existingInfo?.id}-${info.region}`}
              >
                <td>{info.region}</td>
                <td style={{ display: 'flex', flexDirection: 'column' }}>
                  <Table
                    cellSpacing={0}
                    style={{
                      minWidth: '0',
                      border: '1px solid var(--color-text-light)',
                      borderRadius: 8,
                    }}
                  >
                    <Tbody>
                      {Object.entries(info).map(([key, value]) => {
                        if (
                          [
                            'editType',
                            'book_id',
                            'regions',
                            'existingInfo',
                            'region',
                            'discardChanges',
                            'start_date',
                          ].includes(key)
                        ) {
                          return null;
                        }
                        let existingValue = info?.existingInfo
                          ? info?.existingInfo[key]
                          : null;
                        let newValue = value;
                        if (
                          existingValue === null ||
                          existingValue === undefined ||
                          existingValue === ''
                        ) {
                          existingValue = 'Null';
                        }
                        if (
                          newValue === null ||
                          newValue === undefined ||
                          newValue === ''
                        ) {
                          newValue = 'Null';
                        }
                        return (
                          <tr
                            key={`wizard-change-${info?.existingInfo?.id}-${key}:${value}`}
                          >
                            <td style={{ textAlign: 'right' }}>{key}: </td>
                            <td
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                flexWrap: 'nowrap',
                                gap: 'var(--space-xs)',
                              }}
                            >
                              {info.discardChanges.includes(key) ? (
                                <IconButton
                                  iconName={IoArrowUndoSharp}
                                  subtext="Restore Changes"
                                  title="Undo discard (restore changes)"
                                  onPress={() => {
                                    let newChanges = [];
                                    for (const obj of objectsToChange) {
                                      if (obj.region !== info.region) {
                                        newChanges.push(obj);
                                      } else {
                                        newChanges.push({
                                          ...obj,
                                          discardChanges:
                                            obj.discardChanges.filter(
                                              k => k !== key
                                            ),
                                        });
                                      }
                                    }
                                    setObjectsToChange(newChanges);
                                  }}
                                />
                              ) : (
                                <>
                                  {newValue instanceof File ? (
                                    <>
                                      {existingValue === 'Null' ? (
                                        'Null'
                                      ) : (
                                        <img
                                          width="200px"
                                          height="auto"
                                          src={existingValue}
                                          alt="old"
                                        />
                                      )}{' '}
                                      ⇒{' '}
                                      <img
                                        width="200px"
                                        height="auto"
                                        src={URL.createObjectURL(newValue)}
                                        alt="new"
                                      />
                                    </>
                                  ) : (
                                    <>
                                      <div style={{ flex: 1 }}>
                                        {existingValue?.toString()}
                                      </div>
                                      <div style={{ flex: 1 }}>⇒ </div>
                                      <div
                                        style={{ flex: 1, fontWeight: 'bold' }}
                                      >
                                        {newValue?.toString()}
                                      </div>
                                    </>
                                  )}
                                  <IconButton
                                    colorTheme="danger"
                                    title={`Discard this change (${key})`}
                                    iconName={IoTrash}
                                    onPress={() => {
                                      let newChanges = [];
                                      for (const obj of objectsToChange) {
                                        if (obj.region !== info.region) {
                                          newChanges.push(obj);
                                        } else {
                                          newChanges.push({
                                            ...obj,
                                            discardChanges: [
                                              ...obj.discardChanges,
                                              key,
                                            ],
                                          });
                                        }
                                      }
                                      setObjectsToChange(newChanges);
                                    }}
                                  />
                                </>
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </Tbody>
                  </Table>
                </td>
              </tr>
            ))}
          </Tbody>
        </Table>
        {submitInfos?.isError && (
          <span style={{ color: 'var(--color-danger)' }}>
            {submitInfos?.error?.message}
          </span>
        )}
        <AuthButton
          containerStyle={{ margin: 'var(--space-md) 0' }}
          isLoading={submitInfos.isLoading}
          onPress={async () => {
            let finalObjects = [];
            for (const obj of objectsToChange) {
              let cleanObj = { ...obj };
              cleanObj.existingInfoId = cleanObj?.existingInfo?.id;
              if (cleanObj?.existingInfo) {
                delete cleanObj.existingInfo;
              }
              for (const key of obj.discardChanges) {
                delete cleanObj[key];
              }
              delete cleanObj.discardChanges;
              for (const [key, value] of Object.entries(cleanObj)) {
                if (value instanceof File) {
                  cleanObj[key] = value?.base64;
                }
              }
              cleanObj.book = cleanObj.book_id;
              finalObjects.push(cleanObj);
            }

            submitInfos.mutate({
              endpoint: `sportsbook-infos`,
              body: finalObjects,
            });
          }}
        >
          Submit
        </AuthButton>
      </>
    );
  }

  return (
    <>
      <p>Something went wrong.</p>
      <b>debug data:</b>
      <br />
      <span>step: {step}</span>
      <br />
      <span>fd: {JSON.stringify(formData)}</span>
      <br />
    </>
  );
}

const REGION_GROUPS = [
  {
    title: 'Canada',
    countryFlag: CANADA_FLAG,
    options: PROVINCE_OPTIONS.map(p => ({
      label: p,
      value: p,
    })),
  },
  {
    title: 'USA',
    countryFlag: USA_FLAG,
    options: US_STATES.map(p => ({ label: p, value: p })),
  },
  {
    title: '🌎 Global',
    options: Object.values(OTHER_STATE_OPTIONS).map(p => ({
      label: p,
      value: p,
    })),
  },
];

function CustomRegionSelector(props) {
  const { actualBook, existingInfos, step } = props;
  const [selectedRegions, setSelectedRegions] = useState([]);

  const disabledRegions = useMemo(() => {
    let disReg = {};
    if (step === 'newregions' || step === 'newregionscopy') {
      for (const info of existingInfos) {
        disReg[info?.region] = true;
      }
    } else {
      let existingRegions = existingInfos.map(i => i.region);
      for (const r of STATE_OPTIONS) {
        if (!existingRegions.includes(r)) {
          disReg[r] = true;
        }
      }
    }
    return disReg;
  }, [step, existingInfos]);

  const _toggleRegions = (regions, noRemovals) => {
    let newRegions = Array.from(selectedRegions);
    for (const region of regions) {
      if (selectedRegions.includes(region.label) && !noRemovals) {
        newRegions = newRegions.filter(r => r !== region.label);
      } else {
        if (
          !disabledRegions[region.label] &&
          !newRegions.includes(region.label)
        ) {
          newRegions.push(region.label);
        }
      }
    }
    setSelectedRegions(newRegions);
  };

  return (
    <>
      <Row
        style={{
          width: '100%',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: 'var(--space-xxl)',
          top: '48px',
          position: 'sticky',
          backgroundColor: 'var(--color-bg)',
        }}
      >
        <AuthButton
          onPress={() => props.goBack()}
          containerStyle={{
            alignSelf: 'flex-start',
            flex: 0,
            width: 'unset',
          }}
          leftIcon={IoChevronBack}
          colorTheme="text"
          btnTheme="borderless"
        >
          Back
        </AuthButton>
        <AuthButton
          disabled={selectedRegions?.length === 0}
          onPress={() => {
            props.confirmSelections(selectedRegions);
          }}
        >
          Confirm Selections ({selectedRegions?.length})
        </AuthButton>
      </Row>
      <Row
        style={{
          marginTop: 'var(--space-sm)',
          width: '100%',
          alignItems: 'center',
          justifyContent: 'space-between',
          borderBottom: '1px solid var(--color-text-light)',
        }}
      >
        {!!actualBook?.logo && (
          <img
            src={actualBook?.logo}
            alt={actualBook?.name}
            width={32}
            style={{
              borderRadius: 4,
              verticalAlign: 'middle',
              marginRight: 'var(--space-xxxs)',
            }}
          />
        )}
        <AuthButton
          btnTheme="borderless"
          disabled={props.hideSelectAllWhereAvailable}
          onPress={() =>
            _toggleRegions(
              actualBook.states.map(s => ({ label: s })),
              true
            )
          }
        >
          Select All Where {actualBook?.name} Is Available
        </AuthButton>
      </Row>
      {step === 'newregions' ? (
        <small style={{ alignSelf: 'flex-start' }}>
          <i>*Only regions without existing sportsbook infos are available</i>
        </small>
      ) : (
        <small style={{ alignSelf: 'flex-start' }}>
          <i>*Only regions with existing sportsbook infos are available</i>
        </small>
      )}
      {REGION_GROUPS.map(group => (
        <React.Fragment key={`region-group-wizard-${group.title}`}>
          <Row
            style={{
              marginTop: 'var(--space-sm)',
              width: '100%',
              alignItems: 'center',
              justifyContent: 'space-between',
              borderBottom: '1px solid var(--color-text-light)',
            }}
          >
            {group.countryFlag && (
              <img
                src={group.countryFlag}
                alt={group.title}
                width={32}
                style={{
                  verticalAlign: 'middle',
                  marginRight: 'var(--space-xxxs)',
                }}
              />
            )}
            <b style={{ flex: 1 }}>{group.title}</b>
            <AuthButton
              btnTheme="borderless"
              onPress={() => _toggleRegions(group.options, true)}
            >
              Select All {group.title}
            </AuthButton>
          </Row>
          <Grid style={{ width: '100%', gap: '0 1rem' }}>
            {group.options.map(region => (
              <AuthButton
                key={`region-group-wizard-${group.title}-${region.label}`}
                btnTheme="borderless"
                colorTheme={
                  selectedRegions.includes(region.label) ? 'inverted' : 'text'
                }
                disabled={disabledRegions[region.label]}
                onPress={() => _toggleRegions([region], false)}
              >
                {region.label}
              </AuthButton>
            ))}
          </Grid>
        </React.Fragment>
      ))}
      <AuthButton
        disabled={selectedRegions?.length === 0}
        onPress={() => {
          props.confirmSelections(selectedRegions);
        }}
      >
        Confirm Selections
      </AuthButton>
    </>
  );
}
