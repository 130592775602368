import React from 'react';
import { Row, Col } from 'components/generic/Layout';
import { useSelector } from 'react-redux';

//components
import {
  DropdownSetting,
  SwitchSetting,
  ToggleSetting,
} from 'components/SettingsBtn';

const sortGamesByOptionsMap = {
  date: 'Game Start Time',
  home_rotation_number: 'Rotation Number',
};

const gamesFilterOptionsMap = {
  all_games: 'Show All Games',
  only_live_games: 'Show Only Live Games',
  only_unstarted_games: 'Show Only Unstarted Games',
};

const themeMap = {
  system: 'Automatic',
  dark: 'Dark',
  light: 'Light',
};

export default function DisplaySettings(props) {
  const reduxProps = useSelector(state => ({
    theme: state.settingsReducer.theme,
    defaultGameSortBy: state.settingsReducer.defaultGameSortBy,
    defaultGameFilter: state.settingsReducer.defaultGameFilter,
    showRotationNumber: state.settingsReducer.showRotationNumber,
    showWinPercentage: state.settingsReducer.showWinPercentage,
    showML: state.settingsReducer.showML,
    showSpread: state.settingsReducer.showSpread,
    showTotal: state.settingsReducer.showTotal,
    defaultTopTab: state.settingsReducer.defaultTopTab,
  }));

  const {
    theme,
    defaultGameSortBy,
    defaultGameFilter,
    showRotationNumber,
    showWinPercentage,
    showML,
    showSpread,
    showTotal,
    defaultTopTab,
  } = reduxProps;

  return (
    <Col
      style={{
        justifyContent: 'flex-start',
        alignItems: 'stretch',
        ...props.style,
      }}
    >
      <Row
        style={{
          alignItems: 'center',
          justifyContent: 'space-between',
          position: 'sticky',
          top: 0,
          backgroundColor: 'var(--color-bg)',
          zIndex: 101,
          borderBottom: '1px solid #9b9b9b',
          padding: 'var(--space-sm) 0',
        }}
      >
        <h3 style={{ margin: 0, flex: 2 }}>Display Settings</h3>
      </Row>

      <ToggleSetting
        heading="Theme"
        map={{
          Automatic: 'system',
          Dark: 'dark',
          Light: 'light',
          system: 'Automatic',
          dark: 'Dark',
          light: 'Light',
        }}
        active={theme}
        options={['Automatic', 'Dark', 'Light']}
        setting="theme"
      />

      <SwitchSetting
        heading="Show Rotation Numbers"
        description="Show rotation numbers on the Games screen"
        setting="showRotationNumber"
        value={showRotationNumber}
      />
      <SwitchSetting
        heading="Show Moneyline Odds"
        description="Show moneyline odds on the single games screen"
        setting="showML"
        value={showML}
      />
      <SwitchSetting
        heading="Show Game Spread"
        description="Show game spread on the single games screen"
        setting="showSpread"
        value={showSpread}
      />
      <SwitchSetting
        heading="Show Game Total"
        description="Show game total on the single games screen"
        setting="showTotal"
        value={showTotal}
      />
      <SwitchSetting
        heading="Show Win Percentage"
        description="Show implied win percentages on the Games screen"
        setting="showWinPercentage"
        value={showWinPercentage}
      />
      {/* <SwitchSetting
        heading="Show Live Odds"
        description="Show live odds on the Games screen once a game has started"
        setting="showLiveOdds"
        value={showLiveOdds}
      /> */}

      <DropdownSetting
        heading="Sort Games By"
        // description="The default way to sort games in the Games screen"
        setting="defaultGameSortBy"
        value={{
          label: sortGamesByOptionsMap[defaultGameSortBy],
          value: defaultGameSortBy,
        }}
        options={[
          {
            label: 'Game Start Time',
            value: 'date',
          },
          {
            label: 'Rotation Number',
            value: 'home_rotation_number',
          },
        ]}
      />
      <DropdownSetting
        heading="Games Filter"
        // description="The default way to filter games in the Games screen"
        setting="defaultGameFilter"
        value={{
          label: gamesFilterOptionsMap[defaultGameFilter],
          value: defaultGameFilter,
        }}
        options={[
          {
            label: 'Show All Games',
            value: 'all_games',
          },
          {
            label: 'Show Only Live Games',
            value: 'only_live_games',
          },
          {
            label: 'Show Only Unstarted Games',
            value: 'only_unstarted_games',
          },
        ]}
      />
      <ToggleSetting
        heading="Default Tab - Single Game Screen"
        map={{
          'Full Game': 'FullTime',
          'Game Props': 'GamePropProps',
          'Player Props': 'PlayerPropPlayers',
          FullTime: 'Full Game',
          GamePropProps: 'Game Props',
          PlayerPropPlayers: 'Player Props',
        }}
        active={defaultTopTab}
        options={['Full Game', 'Game Props', 'Player Props']}
        setting="defaultTopTab"
      />
    </Col>
  );
}
