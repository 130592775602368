import { Observable } from 'rxjs';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import { ajax } from 'rxjs/observable/dom/ajax';
import { BACKEND_API_URL, getHeaders } from 'utils';

import {
  DEACTIVATE_USER,
  deactivateUserSuccess,
  deactivateUserFailure,
} from 'actions';

export default function deactivateUser(action$, state$) {
  return action$.ofType(DEACTIVATE_USER).switchMap(() => {
    return ajax
      .delete(
        BACKEND_API_URL + 'api/users/' + state$.value.authReducer.user.id + '/',
        getHeaders(state$)
      )
      .map(data => deactivateUserSuccess(data))
      .catch(error => Observable.of(deactivateUserFailure(error.xhr)));
  });
}
