import { combineEpics } from 'redux-observable';

import getWagers from 'epics/wager/getWagers.epic';
import getWagersNext from 'epics/wager/getWagersNext.epic';
import updateWager from 'epics/wager/updateWager.epic';
import deleteWager from 'epics/wager/deleteWager.epic';
import retrieveWager from 'epics/wager/retrieveWager.epic';

export default combineEpics(
  getWagers,
  getWagersNext,
  updateWager,
  deleteWager,
  retrieveWager
);
