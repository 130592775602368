export const SET_SEEN_TOUR = 'SET_SEEN_TOUR';
export const SET_TOUR_STEP = 'SET_TOUR_STEP';
export const MODIFY_TOUR_STEP = 'MODIFY_TOUR_STEP';
export const SET_TOUR_CUTOUT = 'SET_TOUR_CUTOUT';
export const FAKE_TRACK_WAGER = 'FAKE_TRACK_WAGER';

export const setSeenTour = (tour, seen) => ({
  type: SET_SEEN_TOUR,
  tour,
  seen,
});

export const setTourStep = stepID => ({
  type: SET_TOUR_STEP,
  stepID,
});

// modifies the current tour step with some extra data
export const modifyTourStep = mod => ({
  type: MODIFY_TOUR_STEP,
  mod,
});

export const setTourCutout = (stepID, cutoutData) => ({
  type: SET_TOUR_CUTOUT,
  stepID,
  cutoutData,
});

export const fakeTrackWager = wager => ({
  type: FAKE_TRACK_WAGER,
  wager,
});
