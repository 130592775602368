import { useQuery } from '@tanstack/react-query';
import { Col } from 'components/generic/Layout';
import styled from 'styled-components';
import { formatMoney } from 'utils';

const Tbody = styled.tbody`
  > tr {
    transition: all var(--std-transition);
  }
  > tr:hover {
    background-color: var(--color-text-light);
  }
  > tr:hover:first-child {
    border-radius: var(--std-border-radius);
  }
  > tr > td {
    padding: var(--space-xxs) var(--space-sm);
  }
  > tr:not(:last-child) > td {
    border-bottom: 1px solid var(--color-text-light);
  }
  > tr > td:last-child {
    text-align: right;
  }
`;

export default function OnboardingPayouts(props) {
  const { attendee, session_id } = props;
  const sums = useQuery({
    // 5 minutes
    //staleTime: 1000 * 60 * 5,
    queryKey: [
      {
        endpoint: `onboarding-payouts`,
        urlParams: { attendee_id: attendee.id, session_id: session_id },
      },
    ],
  });

  return (
    <Col
      style={{
        flex: 0,
        justifyContent: 'space-between',
        border: '1px solid var(--color-text-light)',
        borderRadius: 'var(--std-border-radius)',
        width: '100%',
        marginBottom: 'var(--space-xs)',
        ...props.style,
      }}
    >
      {sums.isLoading ? (
        <></>
      ) : (
        <table style={{ width: '100%' }} cellSpacing={0}>
          <Tbody>
            <tr>
              <td>Payment Email</td>
              <td>
                <b>{attendee.payment_email}</b>
              </td>
            </tr>
            <tr>
              <td>Amount owed for min deposits</td>
              <td>
                <b>
                  {formatMoney(
                    sums.data?.amount_owed_for_deposits,
                    sums.data?.currency || 'CAD'
                  )}
                </b>
              </td>
            </tr>
            <tr>
              <td>How much we sent</td>
              <td>
                <b>
                  {formatMoney(
                    sums.data?.how_much_we_sent,
                    sums.data?.currency || 'CAD'
                  )}
                </b>
              </td>
            </tr>
            <tr>
              <td>How much was used</td>
              <td>
                <b>
                  {formatMoney(
                    sums.data?.how_much_was_used,
                    sums.data?.currency || 'CAD'
                  )}
                </b>
              </td>
            </tr>
            <tr title={sums.data?.balance > 0 ? 'They owe us' : 'We owe them'}>
              <td>
                Balance
                {sums.data?.balance !== 0
                  ? sums.data?.balance > 0
                    ? ' (They owe us)'
                    : ' (We owe them)'
                  : ''}
              </td>
              <td>
                <b>
                  {formatMoney(
                    sums.data?.balance,
                    sums.data?.currency || 'CAD'
                  )}
                </b>
              </td>
            </tr>
            <tr>
              <td>Individual Bonus Owed</td>
              <td>
                <b>
                  {formatMoney(
                    sums.data?.individual_bonus_owed,
                    sums.data?.currency || 'CAD'
                  )}
                </b>
              </td>
            </tr>
            <tr>
              <td>Individual Bonus Sent</td>
              <td>
                <b>
                  {formatMoney(
                    sums.data?.individual_bonus_sent,
                    sums.data?.currency || 'CAD'
                  )}
                </b>
              </td>
            </tr>
          </Tbody>
        </table>
      )}
    </Col>
  );
}
