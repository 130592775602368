import { Observable } from 'rxjs';
import 'rxjs/add/operator/exhaustMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import { ajax } from 'rxjs/observable/dom/ajax';
import { BACKEND_API_URL, getHeaders } from 'utils';

import {
  BETSLIP_CHANGE_BOOK_BET,
  betslipChangeBookBetSuccess,
  betslipChangeBookBetFailure,
} from 'actions';

export default function betslipChangeBookBet(action$, state$) {
  return action$.ofType(BETSLIP_CHANGE_BOOK_BET).exhaustMap(action => {
    return ajax
      .post(
        BACKEND_API_URL + `api/changebook/`,
        {
          book: action.book.id,
          bets: [state$.value.betslipReducer.bets[action.index]],
        },
        getHeaders(state$)
      )
      .map(data => betslipChangeBookBetSuccess(data, action.index))
      .catch(error =>
        Observable.of(betslipChangeBookBetFailure(error.xhr, action.index))
      );
  });
}
