export default {
  link_to_video: '',
  content: [
    {
      type: 'heading',
      size: 3,
      text: 'Sportsbooks and Odds',
    },
    {
      type: 'heading',
      size: 5,
      text: 'My Sportsbooks',
    },
    {
      type: 'paragraph',
      text: 'Open up this tab by clicking and run through the list of sportsbooks to add any that you use or want to see odds from. It’s important to use numerous sportsbooks to line shop and ensure you are getting the maximum value out of all of your bets.',
    },
    {
      type: 'paragraph',
      text: 'Tapping and holding a sportsbook allows you to drag and reorder your books.',
    },
    {
      type: 'video',
      url: 'https://betstamp-public.sfo2.cdn.digitaloceanspaces.com/tutorials/vid-1.mp4',
    },
    {
      type: 'heading',
      size: 5,
      text: 'Default Odds',
    },
    {
      type: 'paragraph',
      text: 'Toggle between American Odds (-110/+110 etc.) or Decimal Odds (1.9/2.1 etc.)',
    },
    {
      type: 'image',
      url: 'https://betstamp-public.sfo2.cdn.digitaloceanspaces.com/tutorials/settings-1.jpg',
    },
    {
      type: 'heading',
      size: 5,
      text: 'Default Bet Size',
    },
    {
      type: 'paragraph',
      text: 'Your default bet size will be pre-loaded onto your track bet screen everytime you track a bet, but can always be edited at that point.',
    },
    {
      type: 'paragraph',
      text: 'If you select “base” and use $100 as shown, the default bet size will be $100 on underdogs and “to win” $100 on favourites.',
    },
    {
      type: 'paragraph',
      text: 'If you select “risk” and use $100 as shown, your default bet size will always be $100.',
    },
    {
      type: 'image',
      url: 'https://betstamp-public.sfo2.cdn.digitaloceanspaces.com/tutorials/settings-2.jpg',
    },
    {
      type: 'heading',
      size: 5,
      text: 'Show Best Available Odds From',
    },
    {
      type: 'paragraph',
      text: 'Choose which odds you want betstamp to highlight as the best in market',
    },
    {
      type: 'paragraph',
      text: '“All Books” shows odds from every sportsbook that betstamp pulls data from',
    },
    {
      type: 'paragraph',
      text: '“My State” shows odds from every sportsbook available in your state or province',
    },
    {
      type: 'paragraph',
      text: '“My Books” shows odds from every sportsbook that you have previously selected in the “My Sportsbooks” tab',
    },
    {
      type: 'image',
      url: 'https://betstamp-public.sfo2.cdn.digitaloceanspaces.com/tutorials/settings-3.png',
    },
    {
      type: 'heading',
      size: 5,
      text: 'Show Additional Odds From',
    },
    {
      type: 'paragraph',
      text: 'These odds won’t be highlighted as best in market, but will still show up in the game’s page to assist you in line shopping and comparing odds',
    },
    {
      type: 'paragraph',
      text: 'Note: If you have selected “My Books” under the Best Available Odds setting but you have not selected any books in “My Sportsbooks” then the odds on your game screen will no longer appear. You must select at least one sportsbook.',
    },
    {
      type: 'image',
      url: 'https://betstamp-public.sfo2.cdn.digitaloceanspaces.com/tutorials/settings-4.jpg',
    },

    {
      type: 'heading',
      size: 3,
      text: 'My Profile',
    },
    {
      type: 'paragraph',
      text: 'To edit your profile settings, head to accounts tab and click on "Edit Profile"',
    },
    {
      type: 'paragraph',
      text: 'From this page you can edit your profile information with things like changing your username and location and adding a biography. You can also make your profile private or public and choose whether or not you want to hide your pending bets. You can also manage more about your account and subscriptions using this page.',
    },
    {
      type: 'image',
      url: 'https://betstamp-public.sfo2.cdn.digitaloceanspaces.com/tutorials/settings-5.png',
    },

    { type: 'heading', size: 5, text: 'Private Users' },
    {
      type: 'paragraph',
      text: 'Other users cannot find you in the app and cannot view any picks you have made. You will not show up in the public leaderboard.',
    },
    {
      type: 'paragraph',
      text: 'You can still use the app for all of its features but you will not be able to post picks for sale if you are a private user',
    },
    { type: 'heading', size: 5, text: 'Public Users' },
    {
      type: 'paragraph',
      text: 'Any user can search for your name in the marketplace or on the leaderboard and find your profile',
    },
    {
      type: 'paragraph',
      text: 'Anybody can see any verified bets that have been made by viewing your profile and they can conduct analysis of your verified bets',
    },
    {
      type: 'paragraph',
      text: 'You can post a package to the marketplace to sell your picks at any time if you are a public user',
    },
    {
      type: 'image',
      url: 'https://betstamp-public.sfo2.cdn.digitaloceanspaces.com/tutorials/settings-6.png',
    },
  ],
};
