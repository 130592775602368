import React from 'react';
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import {
  IoLogoInstagram,
  IoLogoTwitter,
  IoLogoLinkedin,
  IoLogoTiktok,
  IoMailOutline,
} from 'react-icons/io5';

// components
import { BetstampWordWhiteText } from 'components/generic/Logos';
import { Col, Row } from 'components/generic/Layout';
import { LinkButton } from 'components/AuthButton';

import AppStoreImg from 'images/download_app_store.png';
import PlayStoreImg from 'images/download_play_store.png';

const MAXWIDTH = '1280px';

const Wrapper = styled.div`
  background: var(--color-primary);
  color: white;
  width: 100%;
  min-height: 12vh;
  //margin-top: var(--space-xxxl);
  padding: var(--space-lg) 0;
`;

const SignupRow = styled.div`
  flex: 1;
  display: flex;
  max-width: ${MAXWIDTH};
  margin: var(--space-md) auto;
  padding: 0 var(--space-sm);
  justify-content: flex-start;
  align-items: center;
  @media only screen and (max-width: 625px) {
    flex-flow: column wrap;
    justify-content: space-around;
    & > a {
      margin: var(--space-sm) 0;
    }
  }
`;

export default function Footer() {
  const location = useLocation();
  // no header or footer if complete purchase from the app
  if (
    location.pathname.includes('/complete-purchase') &&
    location.search.includes('?mobile=true')
  ) {
    return <></>;
  }

  return (
    <Wrapper>
      <Row
        desktop
        style={{
          maxWidth: MAXWIDTH,
          margin: 'var(--space-md) auto',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Col style={{ flex: 0, alignItems: 'flex-start' }}>
          <BetstampWordWhiteText height={96} />
        </Col>
        <Row style={{ paddingTop: 'var(--space-sm)' }}>
          <a
            href="https://twitter.com/Betstamp"
            target="_blank"
            rel="noopener noreferrer"
            style={{ width: '32px' }}
          >
            <IoLogoTwitter
              title="betstamp Twitter"
              color="white"
              style={{ width: '100%', height: 'auto' }}
            />
          </a>
          <a
            href="https://www.instagram.com/betstamp/"
            target="_blank"
            rel="noopener noreferrer"
            style={{ width: '32px' }}
          >
            <IoLogoInstagram
              title="betstamp Instagram"
              color="white"
              style={{ width: '100%', height: 'auto' }}
            />
          </a>
          <a
            href="https://vm.tiktok.com/ZMe9VSXm1/"
            target="_blank"
            rel="noopener noreferrer"
            style={{ width: '32px' }}
          >
            <IoLogoTiktok
              title="betstamp Tik Tok"
              color="white"
              style={{ width: '100%', height: 'auto' }}
            />
          </a>
          <a
            href="https://www.linkedin.com/company/betstamp/"
            target="_blank"
            rel="noopener noreferrer"
            style={{ width: '32px' }}
          >
            <IoLogoLinkedin
              title="betstamp LinkedIn"
              color="white"
              style={{ width: '100%', height: 'auto' }}
            />
          </a>
          <a
            href="mailto:contact@betstamp.app"
            target="_blank"
            rel="noopener noreferrer"
            style={{ width: '32px' }}
          >
            <IoMailOutline
              title="betstamp Email"
              color="white"
              style={{ width: '100%', height: 'auto' }}
            />
          </a>
        </Row>
        <Row style={{ flex: 0.25 }} />
      </Row>
      <Row
        mobile
        style={{
          maxWidth: MAXWIDTH,
          margin: 'var(--space-md) auto',
          justifyContent: 'center',
        }}
      >
        <BetstampWordWhiteText height={72} />
      </Row>
      <Row mobile style={{ flexDirection: 'reverse' }}>
        <a
          href="https://twitter.com/Betstamp"
          target="_blank"
          rel="noopener noreferrer"
          style={{ width: '32px' }}
        >
          <IoLogoTwitter
            title="betstamp Twitter"
            color="white"
            style={{ width: '100%', height: 'auto' }}
          />
        </a>
        <a
          href="https://www.instagram.com/betstamp/"
          target="_blank"
          rel="noopener noreferrer"
          style={{ width: '32px' }}
        >
          <IoLogoInstagram
            title="betstamp Instagram"
            color="white"
            style={{ width: '100%', height: 'auto' }}
          />
        </a>
        <a
          href="https://vm.tiktok.com/ZMe9VSXm1/"
          target="_blank"
          rel="noopener noreferrer"
          style={{ width: '32px' }}
        >
          <IoLogoTiktok
            title="betstamp Tik Tok"
            color="white"
            style={{ width: '100%', height: 'auto' }}
          />
        </a>
        <a
          href="https://www.linkedin.com/company/betstamp/"
          target="_blank"
          rel="noopener noreferrer"
          style={{ width: '32px' }}
        >
          <IoLogoLinkedin
            title="betstamp LinkedIn"
            color="white"
            style={{ width: '100%', height: 'auto' }}
          />
        </a>
        <a
          href="mailto:contact@betstamp.app"
          target="_blank"
          rel="noopener noreferrer"
          style={{ width: '32px' }}
        >
          <IoMailOutline
            title="betstamp Email"
            color="white"
            style={{ width: '100%', height: 'auto' }}
          />
        </a>
      </Row>

      <SignupRow>
        <LinkButton
          to="/signup"
          btnStyle={{
            borderColor: 'white',
          }}
          textStyle={{
            color: 'white',
          }}
        >
          Start Tracking Bets
        </LinkButton>
        <a
          style={{ margin: '0 var(--space-sm)' }}
          href="https://apps.apple.com/us/app/id1525948689"
        >
          <img
            src={AppStoreImg}
            style={{ width: 'auto', height: '64px' }}
            alt="Download from the App Store"
          />
        </a>
        <a
          style={{ margin: '0 var(--space-sm)' }}
          href="https://play.google.com/store/apps/details?id=app.cashew.betstamp"
        >
          <img
            src={PlayStoreImg}
            style={{ width: 'auto', height: '64px' }}
            alt="Download from the Play Store"
          />
        </a>
      </SignupRow>

      <Row
        style={{
          maxWidth: MAXWIDTH,
          margin: 'var(--space-md) auto',
          padding: '0 var(--space-sm)',
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}
      >
        <Link
          style={{ marginRight: 'var(--space-md)', color: 'white' }}
          to="/about"
        >
          About Us
        </Link>
        <Link
          style={{ marginRight: 'var(--space-md)', color: 'white' }}
          to="/terms-and-conditions"
        >
          Terms
        </Link>
        <Link
          style={{ marginRight: 'var(--space-md)', color: 'white' }}
          to="/privacy-policy"
        >
          Privacy
        </Link>
        <Link
          style={{ marginRight: 'var(--space-md)', color: 'white' }}
          to="/faq"
        >
          FAQs
        </Link>
        <Link
          style={{ marginRight: 'var(--space-md)', color: 'white' }}
          to="/tutorial-all"
        >
          Tutorials
        </Link>
        <Link
          style={{ marginRight: 'var(--space-md)', color: 'white' }}
          to="/careers"
        >
          Careers
        </Link>
        <div style={{ flex: '1 0 300px' }} />
        <span>© {new Date().getFullYear()} betstamp</span>
      </Row>
    </Wrapper>
  );
}
