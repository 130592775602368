/**
 * Returns a string of the number comma separated.
 * https://stackoverflow.com/questions/2901102/how-to-print-a-number-with-commas-as-thousands-separators-in-javascript
 * @param {Number | String} x
 * @returns {String} A string of number, separated by commas (1000 => 1,000)
 */
 export default function commaSeparateNumber(x, skipPadding) {
  if (x !== 0 && !x) {
    return '';
  }
  if (isNaN(x)) {
    throw new Error('commaSeparateNumber expected a number');
  }
  x = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  if (skipPadding) return x;

  let arr = x.split('.');
  if (arr.length === 2) {
    if (arr[1].length === 1) {
      x = arr[0] + '.' + arr[1].substr(0, 2) + '0';
    } else {
      x = arr[0] + '.' + arr[1].substr(0, 2);
    }
  }

  return x;
}
