export default {
  link_to_video: '',
  content: [
    {
      type: 'paragraph',
      text: 'You can edit any bet at any time for any reason.​',
    },
    {
      type: 'paragraph',
      text:
        'Editing a bet will only change this bet for your personal record keeping and will not affect or change your public profile.​',
    },
    {
      type: 'paragraph',
      text: 'Reasons for editing a bet may include:',
    },
    {
      type: 'list-item',
      text:
        'I bet it earlier in the day or week and forgot to track it right away',
    },
    {
      type: 'list-item',
      text:
        'I tracked a verified bet by mistake and need to change or delete it from my personal record',
    },
    {
      type: 'list-item',
      text:
        'I got different odds at my Sportsbook so I want to change my verified bet to those odds for my personal record',
    },
    {
      type: 'list-item',
      text: 'I tracked the bet at a different Sportsbook',
    },
    {
      type: 'list-item',
      text:
        'The payout or grading I received from my Sportsbook was different than how betstamp graded the bet',
    },
    {
      type: 'paragraph',
      text:
        'To edit a bet, click on the bet in the my picks screen and click "adjust for tracking" on the bet.',
    },
    {
      type: 'paragraph',
      text:
        'You will be able to edit the risk and win amount, the spread, the team you selected, or the Sportsbook you entered.',
    },
    {
      type: 'video',
      url:
        'https://betstamp-public.sfo2.cdn.digitaloceanspaces.com/tutorials/editing-bets/editing-bets-video.mp4',
    },
    {
      type: 'paragraph',
      text:
        'Once the bet has been edited, it will show up in your personal record with any modifications you have made. To other public users viewing your profile, and in your public record, the bet will not show any of the edits that you have made.',
    },
    {
      type: 'paragraph',
      text:
        'Keeping your bets as accurate as possible is important if you want to get the most out of the betstamp app. By keeping your record clean you can accurately keep all of your Sportsbook balances up to date with our accounting feature, and you can determine where your strengths and weaknesses in betting are with our analysis feature.',
    },
  ],
};
