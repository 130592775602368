import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';

// components
import { AuthButton, LinkButton } from 'components/AuthButton';

// constants
import {
  AUTH_BUTTON_DEFAULT_CONTAINER_STYLE,
  AUTH_BUTTON_DEFAULT_MAX_WIDTH_STYLE,
} from 'utils';

import { updateUser } from 'actions';

export default function Unsub(props) {
  const dispatch = useDispatch();
  const reduxProps = useSelector(state => ({
    updateSuccess: state.authReducer.updateUserSuccess,
    updateFailure: state.authReducer.updateUserFailure,
  }));
  const { updateSuccess, updateFailure } = reduxProps;

  return (
    <>
      <Helmet>
        <title>Unsubscribe</title>
      </Helmet>
      <div
        className="container-full"
        style={{ maxWidth: '650px', textAlign: 'center' }}
      >
        <h3>Unsubscribe from betstamp mailing list?</h3>
        {updateSuccess ? (
          <p style={{ color: 'var(--color-success)' }}>Unsubscribed!</p>
        ) : updateFailure ? (
          <p style={{ color: 'var(--color-complement)' }}>
            Unsubscribe unsuccessful, please try again later.
          </p>
        ) : (
          <p>It's that simple.</p>
        )}
        <br />
        {!updateSuccess && (
          <AuthButton
            containerStyle={{
              ...AUTH_BUTTON_DEFAULT_CONTAINER_STYLE,
              ...AUTH_BUTTON_DEFAULT_MAX_WIDTH_STYLE,
              display: 'inline-flex',
              justifyContent: 'center',
            }}
            onPress={() => {
              dispatch(updateUser({ receive_marketing_emails: false }));
            }}
          >
            Confirm
          </AuthButton>
        )}
        <LinkButton
          to="/"
          colorTheme="inverted"
          containerStyle={{
            ...AUTH_BUTTON_DEFAULT_CONTAINER_STYLE,
            ...AUTH_BUTTON_DEFAULT_MAX_WIDTH_STYLE,
            display: 'inline-flex',
            justifyContent: 'center',
          }}
        >
          {updateSuccess ? 'Take Me Home' : 'Cancel'}
        </LinkButton>
        <br />
      </div>
    </>
  );
}
