import React from 'react';

export default function CompetitionLogo(props) {
  return (
    <img
      src={props.compLogo}
      title={props.compName}
      alt={props.compName}
      style={{ flex: 0, margin: 2, ...props.style }}
    />
  );
}
