import { Observable } from 'rxjs';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import { ajax } from 'rxjs/observable/dom/ajax';
import { BACKEND_API_URL, getHeaders } from 'utils';

import {
  GET_HAMMER_USERS,
  getHammerUsersSuccess,
  getHammerUsersFailure,
} from 'actions';

export default function getHammerUsers(action$, state$) {
  return action$.ofType(GET_HAMMER_USERS).switchMap(() => {
    return ajax
      .getJSON(BACKEND_API_URL + 'api/hammer_users/', getHeaders(state$))
      .map(data => getHammerUsersSuccess(data))
      .catch(error => Observable.of(getHammerUsersFailure(error.xhr)));
  });
}
