import { useState, useMemo } from 'react';
import { useQuery, useInfiniteQuery } from '@tanstack/react-query';
import styled from 'styled-components';
import { IoAnalyticsSharp, IoClose, IoFilter } from 'react-icons/io5';
import {
  VictoryBar,
  VictoryChart,
  VictoryAxis,
  VictoryLabel,
  VictoryVoronoiContainer,
  VictoryTooltip,
} from 'victory';

// utils
import { humanDatetime, PROVINCE_OPTIONS, US_STATES, formatMoney } from 'utils';

// components
import { Row, Col, Grid } from 'components/generic/Layout';
import ActivityIndicator from 'components/generic/ActivityIndicator';
import { AuthButton, IconButton } from 'components/AuthButton';
import GenericAdminFilter from 'components/onboarding/GenericAdminFilter';
import DisplayToggle from 'components/generic/DisplayToggle';

// react query custom hooks
import { useUserGroups } from 'react-query/global-hooks';
import StaffNavBar from 'components/nav/StaffNavBar';

const PanelWrapper = styled.div`
  flex: 1.5;
  border-left: 1px solid var(--color-text-light);
  height: calc(100vh - 64px);
  @media only screen and (max-width: 1266px) {
    flex: 2;
  }
  @media only screen and (max-width: 625px) {
    position: absolute;
    background-color: var(--color-bg);
    width: 100vw;
    z-index: 99999999;
    top: 0;
  }
`;

export default function MyMetrics() {
  const userGroups = useUserGroups();

  const [showFilters, setShowFilters] = useState(true);
  const [hostNames, setHostNames] = useState('All CSM');

  const fetchHosts = useInfiniteQuery({
    staleTime: 1000 * 60 * 2,
    queryKey: [
      {
        endpoint: `onboarding-hosts`,
      },
    ],
    getNextPageParam: (lastPage, allPages) => lastPage.next,
    getPreviousPageParam: (firstPage, allPages) => firstPage.prev,
  });

  const { hostOptions, hostDict } = useMemo(() => {
    if (fetchHosts.isSuccess && fetchHosts?.data?.pages?.length > 0) {
      let opts = [];
      let dict = {};
      for (const page of fetchHosts.data.pages) {
        for (const host of page.results) {
          dict[host.id] = host.full_name || host.email || host.id;
          opts.push({
            label: host.full_name || host.email || host.id,
            value: host.id,
          });
        }
      }
      return { hostOptions: opts, hostDict: dict };
    }
    return { hostOptions: [], hostDict: {} };
  }, [fetchHosts?.isSuccess, fetchHosts?.data?.pages]);

  const fetchRefCodeOptions = useQuery({
    staleTime: 1000 * 60 * 2,
    queryKey: [
      {
        endpoint: `onboarding-filter-options`,
        urlParams: { filter: 'attendee_ref_code' },
      },
    ],
  });
  //const fetchTrafficSourceOptions = useQuery({
  //  staleTime: 1000 * 60 * 2,
  //  queryKey: [
  //    {
  //      endpoint: `onboarding-filter-options`,
  //      urlParams: { filter: 'attendee_source' },
  //    },
  //  ],
  //});
  const fetchMarketingGroups = useQuery({
    refetchOnWindowFocus: false,
    queryKey: [
      {
        endpoint: `marketing-groups`,
      },
    ],
  });
  const marketingGroupOptions = useMemo(() => {
    let opts = [];
    if (
      fetchMarketingGroups?.isSuccess &&
      fetchMarketingGroups?.data?.length > 0
    ) {
      for (const group of fetchMarketingGroups.data) {
        opts.push({
          label: group.name,
          value: group.id,
        });
      }
    }
    return opts;
  }, [fetchMarketingGroups?.isSuccess, fetchMarketingGroups?.data]);

  const [urlParams, setUrlParams] = useState({ user_ids: JSON.stringify([]) });
  const fetchStats = useQuery({
    staleTime: 1000 * 60 * 2,
    queryKey: [
      {
        endpoint: 'onboarding-stats',
        urlParams: {
          ...urlParams,
          stats: JSON.stringify([
            'screenshots_to_review',
            'conversion_rate',
            'utilization_rate',
            'upsell_opportunities',
            'avg_time_to_onboard',
            'avg_time_to_onboard',
            'num_sessions',
            'num_of_attendees',
            'num_preregistered_autosync',
            'num_preregistered_autosync_unique',
            'num_of_betlinks',
            'sum_clicks',
            'total_deposits',
            'num_cpa',
            'total_cpa',
          ]),
        },
      },
    ],
  });
  const stats = fetchStats?.data;

  const [viewingGraph, setViewingGraph] = useState('conversion_rate');
  const [graphUrlParams, setGraphUrlParams] = useState({ truncate: 'week' });
  const fetchGraphData = useQuery({
    staleTime: 1000 * 60 * 2,
    queryKey: [
      {
        endpoint: 'onboarding-graphs',
        urlParams: {
          ...urlParams,
          ...graphUrlParams,
          stats: JSON.stringify([viewingGraph]),
        },
      },
    ],
  });

  const defaultFilters = useMemo(() => {
    let filters = {};

    if (urlParams.regions) {
      let d = {};
      for (const r of JSON.parse(urlParams.regions)) {
        d[r] = true;
      }
      filters.regions = d;
    }
    if (urlParams.date_after) {
      filters.date_after = new Date(urlParams.date_after);
    }

    if (urlParams.date_before) {
      filters.date_before = new Date(urlParams.date_before);
    }

    if (urlParams.user_ids) {
      let d = {};
      for (const r of JSON.parse(urlParams.user_ids)) {
        d[r] = true;
      }
      filters.user_ids = d;
    }

    if (urlParams.referral_codes) {
      let d = {};
      for (const r of JSON.parse(urlParams.referral_codes)) {
        d[r] = true;
      }
      filters.referral_codes = d;
    }

    if (urlParams.marketing_group_ids) {
      let d = {};
      for (const r of JSON.parse(urlParams.marketing_group_ids)) {
        d[r] = true;
      }
      filters.marketing_group_ids = d;
    }

    return filters;
  }, [urlParams]);

  return (
    <>
      <StaffNavBar />
      <Row style={{ width: '100%', alignItems: 'flex-start' }}>
        <div
          style={{
            flex: 3,
            height: 'calc(100vh - 64px)',
            overflowX: 'hidden',
          }}
        >
          <Col
            style={{
              height: '100%',
              overflowY: 'auto',
              overflowX: 'auto',
              flexWrap: 'nowrap',
              justifyContent: 'flex-start',
              alignItems: 'stretch',
            }}
          >
            <Row
              style={{
                flex: 0,
                justifyContent: 'flex-start',
              }}
            >
              <h4 style={{ margin: 'var(--space-xs) var(--space-sm)' }}>
                {hostNames} Metrics
              </h4>
              <IconButton
                colorTheme="text"
                iconName={IoFilter}
                onPress={() => setShowFilters(!showFilters)}
              />
            </Row>
            {fetchStats.isLoading && (
              <ActivityIndicator
                size={3}
                style={{ marginTop: 'var(--space-xxl)' }}
              />
            )}
            {fetchStats.isError && (
              <p style={{ textAlign: 'center', color: 'var(--color-danger)' }}>
                {fetchStats?.error?.message}
              </p>
            )}
            {stats && (
              <Grid
                style={{
                  padding: 'var(--space-xs)',
                  gridTemplateColumns: 'repeat(auto-fill, minmax(220px, 1fr))',
                }}
              >
                <Stat
                  label="Conversion Rate"
                  title="The number of fully complete books divided by the sum of the target across your attendees"
                  obj={stats.conversion_rate}
                  selected={viewingGraph === 'conversion_rate'}
                  onPress={() => setViewingGraph('conversion_rate')}
                />
                <Stat
                  label="Utilization"
                  title="The sum of the min deposits divided by the sum of the initial payments sent"
                  obj={stats.utilization_rate}
                  money
                  selected={viewingGraph === 'utilization_rate'}
                  onPress={() => setViewingGraph('utilization_rate')}
                />
                <Stat
                  label="Average Time to Onboard"
                  title={`The average number of days between an attendee's session and the attendee being marked at "Submissions Paid" or "Onboarding Complete"`}
                  value={`${
                    Math.round(100 * stats.avg_time_to_onboard) / 100
                  } days`}
                  selected={viewingGraph === 'avg_time_to_onboard'}
                  onPress={() => setViewingGraph('avg_time_to_onboard')}
                />
                <Stat
                  label="Screenshots To Review"
                  title="The number of screenshots you have in the PENDING_SCREENSHOT_APPROVAL status"
                  value={stats.screenshots_to_review}
                />
                <Stat
                  label="Upsell Opportunities"
                  title="The total number of books minus sum of the target num books or completed books (whichever is higher)"
                  value={stats.upsell_opportunities}
                />
                <Stat
                  label="Number of Sessions"
                  title={`The number of sessions for in this time period where these hosts are the main host and the someone in the session has been sent at least 1 initial payment`}
                  value={stats.num_sessions}
                  selected={viewingGraph === 'num_sessions'}
                  onPress={() => setViewingGraph('num_sessions')}
                />
                <Stat
                  label="Number of Attendees"
                  title="The number of attendees we've sent at least 1 initial payment to"
                  value={stats.num_of_attendees}
                  selected={viewingGraph === 'num_attendees'}
                  onPress={() => setViewingGraph('num_attendees')}
                />
                <Stat
                  label="Betlink Preregisters"
                  title="The number of betlink accounts preregistered"
                  value={stats.num_preregistered_autosync}
                  selected={viewingGraph === 'num_preregistered_autosync'}
                  onPress={() => setViewingGraph('num_preregistered_autosync')}
                />
                <Stat
                  label="Unique Betlink Preregisters"
                  title="The number of attendees who preregistered betlink accounts"
                  value={stats.num_preregistered_autosync_unique}
                  //selected={viewingGraph === 'num_preregistered_autosync_unique'}
                  //onPress={() => setViewingGraph('num_preregistered_autosync_unique')}
                />
                {/*
                <Stat
                  label="Number of Betlinks"
                  title="The number of attendees who betlinked a supported book"
                  value={stats.num_of_betlinks}
                  selected={viewingGraph === 'num_of_betlinks'}
                  onPress={() => setViewingGraph('num_of_betlinks')}
                />
                <Stat
                  label="Clicks (from Affiliates)"
                  title="Click data reported from our affiliate partners"
                  value={stats.sum_clicks}
                  select={viewingGraph === 'clicks'}
                  onPress={() => setViewingGraph('clicks')}
                />
                <Stat
                  label="Deposit Amount"
                  title="Deposit amount data reported from our affiliate partners"
                  value={stats.total_deposits}
                  select={viewingGraph === 'total_deposits'}
                  onPress={() => setViewingGraph('total_deposits')}
                />
                <Stat
                  label="CPA Count"
                  title="CPA data reported from our affiliates for these filters"
                  value={stats.num_cpa}
                  selected={viewingGraph === 'num_cpa'}
                  onPress={() => setViewingGraph('num_cpa')}
                />
                <Stat
                  label="CPA (CAD)"
                  title="CPA amount reported from our affiliates for these filters"
                  value={stats.total_cpa}
                  selected={viewingGraph === 'total_cpa'}
                  onPress={() => setViewingGraph('total_cpa')}
                />
                */}
              </Grid>
            )}
            {fetchGraphData?.isFetching && !fetchGraphData?.isFetched && (
              <ActivityIndicator size={2} />
            )}
            {fetchGraphData?.data && fetchGraphData?.data[viewingGraph] && (
              <>
                <Row
                  style={{
                    flex: 0,
                    width: '100%',
                    justifyContent: 'flex-end',
                    gap: '1rem',
                    padding: '0 var(--space-sm)',
                  }}
                >
                  <DisplayToggle
                    style={{ flex: 1, width: '100%', maxWidth: '360px' }}
                    options={['day', 'week', 'month', 'year']}
                    active={graphUrlParams?.truncate}
                    onPress={opt =>
                      setGraphUrlParams({ ...graphUrlParams, truncate: opt })
                    }
                  />
                </Row>
                <Row style={{ flex: 0 }}>
                  <VictoryChart
                    domainPadding={64}
                    padding={{ top: 0, bottom: 20, left: 32, right: 32 }}
                    height={176}
                    containerComponent={
                      <VictoryVoronoiContainer
                        voronoiDimension="x"
                        labels={({ datum }) =>
                          `${
                            graphUrlParams?.truncate[0]?.toUpperCase() +
                            graphUrlParams?.truncate?.slice(1)
                          } of ${datum.x}: ${
                            fetchGraphData?.data[viewingGraph]?.meta?.prefix
                          }${datum.y.toFixed(2)}${
                            fetchGraphData?.data[viewingGraph]?.meta?.symbol
                          } ${
                            datum.denominator
                              ? `(${datum.numerator}/${datum.denominator})`
                              : ''
                          }`
                        }
                        labelComponent={
                          <VictoryTooltip
                            center={{ x: 40 + 25 + 5, y: 15 }}
                            flyoutWidth={128}
                            cornerRadius={2}
                            pointerLength={0}
                            pointerWidth={0}
                            style={{
                              fill: 'var(--color-text)',
                              fontSize: 6,
                            }}
                            flyoutStyle={{
                              fill: 'var(--color-fg)',
                              strokeWidth: 0,
                            }}
                          />
                        }
                      />
                    }
                  >
                    {/* independant (x) axis, bottom */}
                    <VictoryAxis
                      orientation="bottom"
                      style={{
                        axis: { stroke: 'var(--color-text)', strokeWidth: 1 },
                        axisLabel: {
                          fontSize: 6,
                          padding: 0,
                          fill: 'var(--color-text)',
                          strokeWidth: 0.15,
                          stroke: 'var(--color-text)',
                        },
                        tickLabels: {
                          fontSize: 6,
                          padding: 0,
                          fill: 'var(--color-text)',
                        },
                      }}
                      //tickFormat={x => x}
                      fixLabelOverlap
                      label={`${
                        graphUrlParams?.truncate[0]?.toUpperCase() +
                        graphUrlParams?.truncate?.slice(1)
                      } Of`}
                      axisLabelComponent={<VictoryLabel dy={12} />}
                    />

                    {/* depenedent (y) axis, dataset 1, left */}
                    <VictoryAxis
                      orientation={'left'}
                      dependentAxis
                      tickFormat={t =>
                        `${fetchGraphData?.data[viewingGraph]?.meta?.prefix}${t}${fetchGraphData?.data[viewingGraph]?.meta?.symbol}`
                      }
                      style={{
                        axis: {
                          stroke: 'var(--color-text)',
                          strokeWidth: 1,
                        },
                        ticks: { stroke: 'var(--color-primary)', size: 2 },
                        tickLabels: {
                          fontSize: 6,
                          padding: 2,
                          fill: 'var(--color-text)',
                        },
                      }}
                    />

                    <VictoryBar
                      style={{
                        data: { fill: 'var(--color-primary)' },
                        labels: { fontSize: 6, fill: 'white' },
                      }}
                      labels={({ datum }) =>
                        `${fetchGraphData?.data[viewingGraph]?.meta?.prefix}${
                          fetchGraphData?.data[viewingGraph]?.meta?.symbol ===
                          ' days'
                            ? datum.y.toFixed(2)
                            : fetchGraphData?.data[viewingGraph]?.meta
                                ?.prefix === '$'
                            ? datum.y.toLocaleString()
                            : Math.round(datum.y)
                        }${fetchGraphData?.data[viewingGraph]?.meta?.symbol}`
                      }
                      labelComponent={<VictoryLabel dy={12} />}
                      data={fetchGraphData?.data[viewingGraph]?.data}
                    />
                  </VictoryChart>
                </Row>
              </>
            )}
          </Col>
        </div>
        {showFilters && (
          <PanelWrapper>
            <Col
              style={{
                height: '100%',
                overflowY: 'auto',
                flexWrap: 'nowrap',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                padding: '0 var(--space-sm)',
              }}
            >
              <Row
                style={{
                  flex: 0,
                  width: '100%',
                  alignItems: 'center',
                  position: 'sticky',
                  top: 0,
                  background: 'var(--color-bg)',
                  zIndex: 10,
                }}
              >
                <h5 style={{ flex: 1, margin: 0 }}>Filters</h5>
                <AuthButton
                  containerStyle={{ flex: 0.5 }}
                  btnTheme="borderless"
                  onPress={() => setUrlParams({ user_ids: JSON.stringify([]) })}
                >
                  Clear All
                </AuthButton>
                <IconButton
                  colorTheme="text"
                  iconName={IoClose}
                  onPress={() => setShowFilters(false)}
                />
              </Row>
              {hostOptions?.length > 0 && (
                <GenericAdminFilter
                  filterTitle="Hosts"
                  filterKey="users_ids"
                  options={{
                    groups: [
                      { title: 'All Hosts', options: hostOptions },
                      {
                        title: 'Individual Hosts',
                        hideSelectAllButton: true,
                        options: [],
                      },
                    ],
                  }}
                  defaultDict={defaultFilters.user_ids}
                  onFilter={selections => {
                    setHostNames(
                      selections.length === 0 ||
                        (selections.length === hostOptions.length &&
                          hostOptions?.length > 1)
                        ? 'All CSM'
                        : selections.reduce((acc, curr, idx) => {
                            let name = hostDict[curr].split(' ')[0];
                            if (
                              idx === selections.length - 2 &&
                              selections.length > 1
                            ) {
                              if (selections.length > 2) {
                                acc += name + ', and ';
                              } else {
                                acc += name + ' & ';
                              }
                            } else if (idx === selections?.length - 1) {
                              acc += name + "'s";
                            } else if (selections?.length > 1) {
                              acc += name + ', ';
                            }
                            return acc;
                          }, '')
                    );
                    setUrlParams({
                      ...urlParams,
                      user_ids: JSON.stringify(selections),
                    });
                  }}
                />
              )}
              <GenericAdminFilter
                filterTitle={`After Date${
                  urlParams.date_after
                    ? ` (${humanDatetime(new Date(urlParams.date_after))}) `
                    : ''
                }`}
                filterKey="date_after"
                type="date"
                defaultDate={defaultFilters.date_after}
                onFilter={date => {
                  setUrlParams({
                    ...urlParams,
                    date_after: date.toISOString(),
                  });
                }}
              />
              <GenericAdminFilter
                filterTitle={`Before Date${
                  urlParams.date_before
                    ? ` (${humanDatetime(new Date(urlParams.date_before))}) `
                    : ''
                }`}
                filterKey="date_before"
                type="date"
                defaultDate={defaultFilters.date_before}
                onFilter={date => {
                  setUrlParams({
                    ...urlParams,
                    date_before: date.toISOString(),
                  });
                }}
              />
              {/*<GenericAdminFilter
                filterTitle="Timeframe"
                filterKey="timeframe"
                defaultDict={defaultFilters.timeframe}
                options={{
                  groups: [
                    {
                      title: 'Timeframe',
                      options: [
                        {
                          value: 'current_month',
                          label: MONTH_NAMES[new Date().getMonth()],
                        },
                        { value: 'last_month', label: 'Last Month' },
                        { value: 'past_three_months', label: 'Past 3 Months' },
                        { value: 'year_to_date', label: 'Year to Date' },
                      ],
                    },
                  ],
                }}
              />*/}
              {fetchRefCodeOptions?.data?.length > 0 && (
                <GenericAdminFilter
                  filterTitle="Referral Code"
                  filterKey="referral_codes"
                  defaultDict={defaultFilters.referral_codes}
                  options={{
                    groups: [
                      {
                        title: 'Referral Code',
                        options: fetchRefCodeOptions?.data,
                      },
                    ],
                  }}
                  onFilter={selections => {
                    setUrlParams({
                      ...urlParams,
                      referral_codes: JSON.stringify(selections),
                    });
                  }}
                />
              )}
              {marketingGroupOptions?.length > 0 && (
                <GenericAdminFilter
                  filterTitle="Referral Code Group"
                  filterKey="marketing_group_ids"
                  options={{
                    groups: [
                      {
                        title: 'Referral Code Group',
                        options: marketingGroupOptions,
                      },
                    ],
                  }}
                  onFilter={selections => {
                    setUrlParams({
                      ...urlParams,
                      marketing_group_ids: JSON.stringify(selections),
                    });
                  }}
                  defaultDict={defaultFilters.marketing_group_ids}
                />
              )}
              <GenericAdminFilter
                filterTitle="Region"
                filterKey="regions"
                defaultDict={defaultFilters.regions}
                options={{
                  groups: [
                    {
                      title: 'Canada',
                      options: PROVINCE_OPTIONS.map(p => ({
                        label: p,
                        value: p,
                      })),
                    },
                    {
                      title: 'USA',
                      options: US_STATES.map(p => ({ label: p, value: p })),
                    },
                  ],
                }}
                onFilter={selections =>
                  setUrlParams({
                    ...urlParams,
                    regions: JSON.stringify(selections),
                  })
                }
              />
              {/*<GenericAdminFilter
                filterTitle="Traffic Source"
                filterKey="sources"
                defaultDict={defaultFilters.sources}
                options={{
                  groups: [
                    {
                      title: 'Traffic Source',
                      options: fetchTrafficSourceOptions?.data,
                    },
                  ],
                }}
                onFilter={selections => {
                  setUrlParams({
                    ...urlParams,
                    sources: JSON.stringify(selections),
                  });
                }}
              />*/}
            </Col>
          </PanelWrapper>
        )}
      </Row>
    </>
  );
}

function Stat({ value, obj, money, label, title, selected, onPress }) {
  let rate = '0% ';
  if (obj && obj.denominator) {
    rate = Math.floor((obj.numerator / obj.denominator) * 100) + '% ';
  }
  return (
    <AuthButton
      overrideChildren
      colorTheme="text"
      containerStyle={{
        borderRadius: 'var(--std-border-radius)',
        //border: '1px solid var(--color-text-light)',
        //padding: 'var(--space-xs) var(--space-sm)',
        margin: 0,
      }}
      btnStyle={{
        borderRadius: 'var(--std-border-radius)',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: 'var(--space-xs) var(--space-sm)',
        borderColor: selected
          ? 'var(--color-primary)'
          : 'var(--color-text-light)',
        cursor: !!onPress ? 'pointer' : 'default',
        boxShadow: !!onPress ? undefined : 'none',
      }}
      title={`${title}${!!onPress ? ' (Click to graph)' : ''}`}
      onPress={onPress}
    >
      <Row
        style={{
          width: '100%',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <small>{label}</small>
        {!!onPress ? (
          <IoAnalyticsSharp
            color={selected ? 'var(--color-primary)' : 'var(--color-text)'}
          />
        ) : null}
      </Row>
      <h5 style={{ margin: 0 }}>
        {value !== null && value !== undefined && value}
        {!!obj && rate}
        {!!obj && (
          <b
            style={{
              opacity: 0.75,
              fontSize: 'var(--text-xs)',
            }}
          >
            <>
              {money
                ? `(${formatMoney(obj.numerator, 'CAD', {
                    notation: 'compact',
                  })}/${formatMoney(obj.denominator, 'CAD', {
                    notation: 'compact',
                  })})`
                : `(${obj.numerator}/${obj.denominator})`}
            </>
          </b>
        )}
      </h5>
    </AuthButton>
  );
}
