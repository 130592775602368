import React, { useState } from 'react';
import { IoChevronDown, IoChevronUp } from 'react-icons/io5';
import { useUpdateEffect } from 'react-use';

// components
import { Row } from 'components/generic/Layout';
import { IconButton } from 'components/AuthButton';
import CalendarInput from 'components/generic/CalendarInput';
import DisplayToggle from 'components/generic/DisplayToggle';

export default function GenericAdminFilter(props) {
  const {
    options,
    filterKey,
    filterTitle,
    defaultDict,
    defaultDate,
    defaultActive,
    type,
    singleChoice,
  } = props;

  const [expanded, setExpanded] = useState(false);
  const [selectedDict, setSelectedDict] = useState(defaultDict || {});
  const [selectedDate, setSelectedDate] = useState(defaultDate || null);
  const [active, setActive] = useState(defaultActive || null);

  useUpdateEffect(() => {
    setSelectedDict(defaultDict || {});
    setSelectedDate(defaultDate || null);
    setActive(defaultActive || null);
  }, [defaultDict, defaultDate, defaultActive]);

  const _handleChange = (newDict, newArr) => {
    setSelectedDict(newDict);
    props.onFilter(
      Object.keys(newDict).reduce((acc, curr) => {
        if (newDict[curr]) acc.push(curr);
        return acc;
      }, [])
    );
  };

  return (
    <>
      <Row
        style={{
          flex: 0,
          width: '100%',
          alignItems: 'center',
          position: 'sticky',
          top: '36px',
          background: 'var(--color-bg)',
          cursor: 'pointer',
          ...props.rowStyle,
        }}
        onClick={() => setExpanded(!expanded)}
      >
        <b style={{ flex: 1 }}>{filterTitle}</b>
        <IconButton
          colorTheme="text"
          iconName={expanded ? IoChevronUp : IoChevronDown}
          onPress={() => setExpanded(!expanded)}
        />
      </Row>
      {expanded && type === 'date' && (
        <CalendarInput
          value={selectedDate}
          onChange={date => {
            setSelectedDate(date);
            props.onFilter(date);
          }}
        />
      )}
      {expanded && type === 'toggleSelect' && (
        <DisplayToggle
          options={options}
          active={active}
          onPress={opt => {
            setActive(opt);
            props.onFilter(opt);
          }}
        />
      )}
      {expanded && type !== 'date' && type !== 'toggleSelect' && (
        <>
          {options.groups.length > 1 && (
            <Row
              style={{
                flex: 0,
                width: '100%',
                marginBottom: 'var(--space-xs)',
              }}
            >
              {options.groups.map(group => (
                <React.Fragment
                  key={`generic-admin-filterKey-group-all-${group.title}`}
                >
                  {!group.hideSelectAllButton && (
                    <div style={{ flex: 1 }}>
                      <input
                        type="checkbox"
                        name={`generic-admin-filterKey-group-all-${group.title}`}
                        id={`generic-admin-filterKey-group-all-${group.title}`}
                        onChange={input => {
                          let newDict = { ...selectedDict };
                          for (const opt of group.options) {
                            newDict[opt.value] = input.target.checked;
                          }
                          _handleChange(newDict);
                        }}
                      />
                      <label
                        htmlFor={`generic-admin-filterKey-group-all-${group.title}`}
                      >
                        {group.title}
                      </label>
                    </div>
                  )}
                </React.Fragment>
              ))}
            </Row>
          )}
          {options.groups.map(group => (
            <React.Fragment
              key={`generic-admin-filterKey-${filterKey}-${group.title}`}
            >
              {group.options.map(opt => (
                <div
                  key={`${filterKey}-${group.title}-${opt.value}`}
                  style={{ width: '100%', display: 'flex' }}
                  title={opt.title}
                >
                  <input
                    type="checkbox"
                    id={`${filterKey}-${group.title}-${opt.value}`}
                    checked={selectedDict[opt.value] || false}
                    onChange={input => {
                      if (singleChoice) {
                        _handleChange({
                          [opt.value]: input.target.checked,
                        });
                      } else {
                        _handleChange({
                          ...selectedDict,
                          [opt.value]: input.target.checked,
                        });
                      }
                    }}
                  />
                  <label htmlFor={`${filterKey}-${group.title}-${opt.value}`}>
                    {' '}
                    {opt.label}
                  </label>
                </div>
              ))}
              <br />
            </React.Fragment>
          ))}
        </>
      )}
    </>
  );
}
