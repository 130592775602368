import React from 'react';
import { commaSeparateNumber, kFormatter } from 'utils';

export default function AutoColoredAmount(props) {
  let amt = parseFloat(props.children);
  // positive, negative, zero
  const NotANum = isNaN(amt);
  const PNZ = amt === 0 || NotANum ? 'zero' : amt > 0 ? 'positive' : 'negative';
  const color =
    amt === 0 || NotANum
      ? 'var(--color-text)'
      : amt > 0
      ? '#0f9e50'
      : '#cc141d';
  const symbol_before = props.symbol === '$' ? true : false;
  amt = NotANum ? 0 : Math.abs(amt);
  let div_number = props.symbol === '$' ? 100 : 10;
  let num = (Math.round(parseFloat(amt) * div_number) / div_number).toString();
  if (num.includes('.')) {
    let afterDecimal = num.split('.')[1];
    if (afterDecimal.length === 1 && div_number > 10) {
      num += '0';
    }
  }

  if (props.commaSeparate) {
    num = commaSeparateNumber(num);
  }

  if (props.symbol === '$' && num > 9999 && !props.dontKFormat) {
    num = kFormatter(num);
  }

  return (
    <span
      style={{
        color: color,
        fontWeight: props.bold === 'true' ? 'bold' : 'normal',
        ...props.style,
      }}
    >
      {PNZ === 'negative' && <>-</>}
      {symbol_before && <>{props.symbol}</>}
      <>{num}</>
      {!symbol_before && <>{props.symbol}</>}
    </span>
  );
}
