import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useQuery, useMutation } from '@tanstack/react-query';
import {
  IoChevronDown,
  IoChevronUp,
  IoAlertCircleOutline,
  IoCheckmarkCircleOutline,
  IoEyeOff,
  IoGameControllerOutline,
} from 'react-icons/io5';

// utils
import { humanDatetime } from 'utils';

// components
import { Row } from 'components/generic/Layout';
import { AuthButton, IconButton } from 'components/AuthButton';
import { AuthTextInput } from 'components/AuthTextInput';
import ModalWrapper from 'components/generic/ModalWrapper';
import ActivityIndicator from 'components/generic/ActivityIndicator';
import ConfirmDelete from 'components/modals/ConfirmDelete';
import BooksDropdown from 'components/BooksDropdown';
import ScreenshotViewer from 'components/onboarding/ScreenshotViewer';

export default function ProgressListDisplay(props) {
  const { attendee_id, progresses, attendee } = props;

  const reduxProps = useSelector(state => ({
    allBooksMap: state.authReducer.allBooksMap,
  }));
  const { allBooksMap } = reduxProps;

  const [expanded, setExpanded] = useState({});
  const [modal, setModal] = useState({});

  const submitProgress = useMutation({
    mutationKey: `onboarding-attendee-progress-${attendee_id}`,
    onSuccess: () => {
      if (props.onSuccessChangeCallback) {
        props.onSuccessChangeCallback();
      }
      setModal({ ...modal, show: false });
    },
  });

  const deleteMut = useMutation({
    mutationKey: `onboarding-attendee-progresses/delete`,
    onSuccess: () => {
      if (props.onSuccessChangeCallback) {
        props.onSuccessChangeCallback();
      }
      setModal({ ...modal, show: false });
    },
  });

  const fetchPeviewProgresses = useQuery({
    enabled: false,
    refetchOnWindowFocus: false,
    queryKey: [
      {
        endpoint: `onboarding-attendee-progresses`,
        urlParams: { preview: 'true', attendee_id: attendee_id },
      },
    ],
  });

  const regenMut = useMutation({
    mutationKey: `onboarding-attendee-progresses/regen`,
    onSuccess: () => {
      if (props.onSuccessChangeCallback) {
        props.onSuccessChangeCallback();
      }
      setModal({ ...modal, show: false });
    },
  });
  const createMut = useMutation({
    mutationKey: `onboarding-attendee-progresses/create`,
    onSuccess: () => {
      if (props.onSuccessChangeCallback) {
        props.onSuccessChangeCallback();
      }
      setModal({ ...modal, show: false });
    },
  });

  //if (!progresses || progresses?.length === 0) {
  //  return (
  //    <>
  //      {!fetchPeviewProgresses?.data && (
  //        <>
  //          <p>No progresses</p>
  //          <AuthButton
  //            containerStyle={{ flex: 0 }}
  //            btnTheme="borderless"
  //            onPress={() => fetchPeviewProgresses.refetch()}
  //            isLoading={fetchPeviewProgresses?.isFetching}
  //          >
  //            Click here to see which books would be added if the session were
  //            right now
  //          </AuthButton>
  //          <AuthButton
  //            containerStyle={{ flex: 0 }}
  //            onPress={() =>
  //              setModal({
  //                show: true,
  //                type: 'addbook',
  //                progress: undefined,
  //                size: 'small',
  //                marginBottom: undefined,
  //                marginRight: undefined,
  //                marginLeft: undefined,
  //                title: `Add A Book`,
  //              })
  //            }
  //          >
  //            Add A Specific Book
  //          </AuthButton>
  //        </>
  //      )}
  //      {fetchPeviewProgresses?.data && (
  //        <p style={{ textAlign: 'center' }}>
  //          These will be created automatically on the day of the session
  //        </p>
  //      )}
  //      {fetchPeviewProgresses?.data &&
  //        fetchPeviewProgresses.data.map(p => (
  //          <Row
  //            key={`preview-progress-${p.id}`}
  //            style={{
  //              alignItems: 'center',
  //              flex: 0,
  //              justifyContent: 'space-between',
  //              width: '100%',
  //              padding: 'var(--space-xxs)',
  //              gap: 'var(--space-xxs)',
  //            }}
  //          >
  //            <img
  //              style={{ flex: '0 0 22px', borderRadius: '4px' }}
  //              width={28}
  //              src={allBooksMap[p.book]?.logo}
  //              alt={allBooksMap[p.book]?.name}
  //            />
  //            <span style={{ flex: 1 }}>
  //              {allBooksMap[p.book]?.name} (min. ${p.min_deposit})
  //            </span>
  //          </Row>
  //        ))}
  //    </>
  //  );
  //}

  return (
    <>
      <ModalWrapper
        modalIsOpen={modal.show}
        onClose={() => setModal({ ...modal, show: false })}
        onRequestClose={() => setModal({ ...modal, show: false })}
        title={modal.title || ''}
        modalSize={modal.size || 'large'}
        marginLeft={modal.marginLeft}
        marginRight={modal.marginRight}
        marginBottom={modal.marginBottom}
      >
        {modal.type === 'confirmDelete' && (
          <>
            <ConfirmDelete
              onDelete={() => {
                deleteMut.mutate({
                  endpoint: `onboarding-attendee-progresses/${modal?.progress?.id}`,
                  method: 'DELETE',
                });
              }}
              dismissModal={() => setModal({ ...modal, show: false })}
              isDeleting={deleteMut.isLoading}
            >
              Are you sure you want to delete{' '}
              {allBooksMap[modal.progress?.book]?.name}?
            </ConfirmDelete>
            {deleteMut.isError && (
              <p style={{ color: 'var(--color-danger)', textAlign: 'center' }}>
                {deleteMut.error?.message}
              </p>
            )}
          </>
        )}
        {modal.type === 'edit' && (
          <FullEditModal
            progress={modal.progress}
            submitProgress={submitProgress}
          />
        )}
        {modal.type === 'screenshot' && (
          <ScreenshotViewer
            progress={modal.progress}
            attendee_id={attendee_id}
            submitProgress={submitProgress}
            onChangeCallback={() => {
              if (props.onSuccessChangeCallback) {
                props.onSuccessChangeCallback();
              }
              setModal({ ...modal, show: false });
            }}
          />
        )}
        {modal.type === 'regen' && (
          <>
            <br />
            <AuthButton
              isLoading={regenMut.isLoading}
              onPress={() => {
                regenMut.mutate({
                  endpoint: `onboarding-attendee-progresses/?attendee_id=${attendee_id}&regenerate=${true}`,
                  method: 'GET',
                  skipSlash: true,
                });
              }}
            >
              Keep existing books and generate any missing books
            </AuthButton>
            <br />
            <span>
              This option will also delete any progress they have made,
              including screenshots submitted
            </span>
            <AuthButton
              colorTheme="danger"
              isLoading={regenMut.isLoading}
              onPress={() => {
                regenMut.mutate({
                  endpoint: `onboarding-attendee-progresses/?attendee_id=${attendee_id}&regenerate=${true}&remove_existing=${true}`,
                  method: 'GET',
                  skipSlash: true,
                });
              }}
            >
              Remove existing books and regenerate
            </AuthButton>
          </>
        )}
        {modal.type === 'addbook' && (
          <form
            onSubmit={ev => {
              ev.preventDefault();
              const fd = new FormData(ev.target);
              fd.append('attendee_id', attendee_id);
              createMut.mutate({
                endpoint: `onboarding-attendee-progresses`,
                body: fd,
              });
            }}
          >
            <BooksDropdown label="Book *" name="book_id" admin />
            <p>
              Min deposits will be auto generated, but are available for editing
              afterwords
            </p>
            <AuthButton type="submit" isLoading={createMut.isLoading}>
              Create
            </AuthButton>
            {createMut.isError && (
              <span style={{ color: 'var(--color-danger)' }}>
                {createMut?.error?.message}
              </span>
            )}
          </form>
        )}
      </ModalWrapper>

      {(!progresses || progresses?.length === 0) && (
        <>
          {!fetchPeviewProgresses?.data && (
            <>
              <p>No progresses</p>
              <AuthButton
                containerStyle={{ flex: 0 }}
                btnTheme="borderless"
                onPress={() => fetchPeviewProgresses.refetch()}
                isLoading={fetchPeviewProgresses?.isFetching}
              >
                Click here to see which books would be added if the session were
                right now
              </AuthButton>
            </>
          )}
          {fetchPeviewProgresses?.data && (
            <p style={{ textAlign: 'center' }}>
              These will be created automatically on the day of the session
            </p>
          )}
          {fetchPeviewProgresses?.data &&
            fetchPeviewProgresses.data.map(p => (
              <Row
                key={`preview-progress-${p.id}`}
                style={{
                  alignItems: 'center',
                  flex: 0,
                  justifyContent: 'space-between',
                  width: '100%',
                  padding: 'var(--space-xxs)',
                  gap: 'var(--space-xxs)',
                }}
              >
                <img
                  style={{ flex: '0 0 22px', borderRadius: '4px' }}
                  width={28}
                  src={allBooksMap[p.book]?.logo}
                  alt={allBooksMap[p.book]?.name}
                />
                <span style={{ flex: 1 }}>
                  <span
                    style={
                      attendee?.existing_books.includes(
                        allBooksMap[p.book]?.name
                      )
                        ? { textDecoration: 'line-through' }
                        : {}
                    }
                  >
                    {allBooksMap[p.book]?.name} (min. ${p.min_deposit})
                  </span>
                  {attendee?.existing_books.includes(
                    allBooksMap[p.book]?.name
                  ) && <b> Already has book</b>}
                </span>
              </Row>
            ))}
        </>
      )}

      {progresses &&
        progresses?.map((p, i) => (
          <React.Fragment key={`progress-list-display-${p.id}`}>
            <Row
              key={`session-slide-out-progresses-${p.id}`}
              style={{
                alignItems: 'center',
                flex: 0,
                justifyContent: 'space-between',
                width: '100%',
                opacity:
                  p.current_step === 'FULLY_COMPLETE' || p.is_hidden ? 0.75 : 1,
                border: expanded[p.id]
                  ? '1px solid var(--color-text-light)'
                  : '1px solid transparent',
                borderTopRightRadius: 'var(--std-border-radius)',
                borderTopLeftRadius: 'var(--std-border-radius)',
                gap: 'var(--space-xxs)',
              }}
            >
              <small style={{ flex: 0 }}>{i + 1}</small>
              <img
                style={{ flex: '0 0 22px', borderRadius: '4px' }}
                width={22}
                src={allBooksMap[p.book]?.logo}
                alt={allBooksMap[p.book]?.name}
              />
              <Row
                style={{
                  flex: 1.66,
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  gap: 'var(--space-xxs)',
                }}
                title={`${allBooksMap[p.book]?.name} min deposit $${p.min_deposit
                  }`}
              >
                {allBooksMap[p.book]?.name} (min. ${p.min_deposit})
                {(p.current_step === 'PENDING_SCREENSHOT_APPROVAL' ||
                  p.current_step === 'NEEDS_HELP') && (
                    <IoAlertCircleOutline
                      title={
                        p.current_step === 'NEEDS_HELP'
                          ? 'Attendee requires assistance'
                          : 'Screenshots must be verified'
                      }
                      size={22}
                      color={
                        p.current_step === 'NEEDS_HELP'
                          ? 'var(--color-danger)'
                          : 'var(--color-text)'
                      }
                    />
                  )}
                {p.current_step === 'FULLY_COMPLETE' && (
                  <IoCheckmarkCircleOutline
                    title="Fully complete"
                    size={22}
                    color="var(--color-success)"
                  />
                )}
                {!!p.is_hidden && (
                  <IoEyeOff
                    title="Hidden from attendee"
                    size={22}
                    color="var(--color-text)"
                  />
                )}
                {!!p.was_controlled_traffic_book && (
                  <IoGameControllerOutline
                    title="Controlled traffic book at the time"
                    size={22}
                    color="var(--color-text)"
                  />
                )}
              </Row>
              <small
                style={{ flex: 1, textAlign: 'right' }}
                title={
                  p.is_hidden
                    ? 'This book is hidden from the attendee. It may no longer be an affiliate or is otherwise not available to this attendee.'
                    : undefined
                }
              >
                {p.already_had_book && 'ALREADY HAD BOOK'}
                {p.is_hidden && 'HIDDEN'}
                {!p.already_had_book &&
                  !p.is_hidden &&
                  p.current_step?.replace(/_/g, ' ')}
              </small>
              <IconButton
                containerStyle={{ flex: 0 }}
                iconName={expanded[p.id] ? IoChevronUp : IoChevronDown}
                colorTheme="text"
                onPress={() =>
                  setExpanded({ ...expanded, [p.id]: !expanded[p.id] })
                }
              />
            </Row>
            {expanded[p.id] && (
              <table
                style={{
                  width: '100%',
                  border: '1px solid var(--color-text-light)',
                  borderTop: 'none',
                  borderBottomRightRadius: 'var(--std-border-radius)',
                  borderBottomLeftRadius: 'var(--std-border-radius)',
                  padding: 'var(--space-xs) var(--space-xxs)',
                  marginBottom: 'var(--space-xs)',
                }}
              >
                <tbody>
                  <tr>
                    <td>Amount Deposited</td>
                    <td style={{ textAlign: 'right' }}>
                      ${p.amount_deposited}
                    </td>
                  </tr>
                  <tr>
                    <td>Amount Bet</td>
                    <td style={{ textAlign: 'right' }}>${p.amount_bet}</td>
                  </tr>
                  <tr>
                    <td>Clicked Affiliate</td>
                    <td style={{ textAlign: 'right' }}>
                      {p.num_times_clicked_affiliate_link || '-'}
                    </td>
                  </tr>
                  <tr>
                    <td>Min Deposit</td>
                    <td style={{ textAlign: 'right' }}>${p.min_deposit}</td>
                  </tr>
                  <tr>
                    <td>Already Had Book</td>
                    <td style={{ textAlign: 'right' }}>
                      {p.already_had_book ? 'Yes' : 'No'}
                    </td>
                  </tr>

                  <tr>
                    <td>Completed On</td>
                    <td style={{ textAlign: 'right' }}>
                      {p.completed_on ? humanDatetime(p.completed_on) : '-'}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Was Controlled Book&nbsp;
                      <IoGameControllerOutline
                        style={{ verticalAlign: 'middle' }}
                        color="var(--color-text)"
                        size={16}
                      />
                    </td>
                    <td style={{ textAlign: 'right' }}>
                      {p.was_controlled_traffic_book ? 'Yes' : 'No'}
                    </td>
                  </tr>
                  <tr>
                    <td>Step</td>
                    <td style={{ textAlign: 'right' }}>
                      <select
                        value={p.current_step}
                        onChange={ev => {
                          submitProgress.mutate({
                            endpoint: `onboarding-attendee-progresses/${p.id}`,
                            body: {
                              attendee_id: attendee_id,
                              current_step: ev.target.value,
                            },
                          });
                        }}
                      >
                        <option value="NOT_STARTED">Not Started</option>
                        <option value="DEPOSIT">Deposit</option>
                        <option value="PENDING_BOOK_DOC_APPROVAL">
                          Pending Book Doc Approval
                        </option>
                        <option value="PENDING_DEPOSIT">Pending Deposit</option>
                        <option value="PLACE_BET">Place Bet</option>
                        <option value="PENDING_SCREENSHOT_APPROVAL">
                          Pending Screenshot Approval
                        </option>
                        <option value="SCREENSHOTS_INVALID">
                          Screenshots Invalid
                        </option>
                        <option value="FULLY_COMPLETE">Fully Complete</option>
                        <option value="NEEDS_HELP">Needs Help</option>
                      </select>
                      {submitProgress.isError && (
                        <>
                          <br />
                          <small style={{ color: 'var(--color-danger)' }}>
                            {submitProgress.error.message}
                          </small>
                        </>
                      )}
                    </td>
                  </tr>
                  {p.current_step === 'SCREENSHOTS_INVALID' && (
                    <tr>
                      <td>Rejection Reason</td>
                      <td style={{ textAlign: 'right' }}>
                        {p.rejection_reason}
                      </td>
                    </tr>
                  )}
                  {p.current_step === 'NEEDS_HELP' && (
                    <tr>
                      <td>Issue</td>
                      <td style={{ textAlign: 'right' }}>{p.current_issue}</td>
                    </tr>
                  )}
                  <tr>
                    <td colSpan={2}>
                      <Row style={{ gap: '2rem' }}>
                        <AuthButton
                          title="Edit amount deposited, already had book, etc"
                          onPress={() => {
                            submitProgress.reset();
                            //setFullEditModal({ show: true, progress: p });
                            setModal({
                              show: true,
                              type: 'edit',
                              progress: p,
                              size: 'small',
                              marginBottom: '-256px',
                              marginRight: undefined,
                              marginLeft: undefined,
                              title: `Edit ${allBooksMap[p?.book]?.name
                                } Progress`,
                            });
                          }}
                        >
                          Edit {allBooksMap[p.book]?.name || 'Book'}
                        </AuthButton>
                        <AuthButton
                          colorTheme="danger"
                          onPress={() =>
                            setModal({
                              show: true,
                              type: 'confirmDelete',
                              progress: p,
                              size: 'small',
                              marginBottom: '0px',
                              marginRight: '0px',
                              marginLeft: '0px',
                              title: `Confirm Delete ${allBooksMap[p?.book]?.name || 'Book'
                                }`,
                            })
                          }
                        >
                          Delete {allBooksMap[p.book]?.name || 'Book'}
                        </AuthButton>
                      </Row>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2}>
                      <AuthButton
                        containerStyle={{ margin: 0 }}
                        btnTheme="borderless"
                        onPress={() => {
                          submitProgress.reset();
                          setModal({
                            show: true,
                            type: 'screenshot',
                            progress: p,
                            size: 'large',
                            marginBottom: '-75px',
                            marginRight: '-100px',
                            marginLeft: '-100px',
                            title: null,
                          });
                        }}
                      >
                        View Screenshots
                      </AuthButton>
                    </td>
                  </tr>
                </tbody>
              </table>
            )}
          </React.Fragment>
        ))}

      <AuthButton
        containerStyle={{ flex: 0 }}
        onPress={() =>
          setModal({
            show: true,
            type: 'addbook',
            progress: undefined,
            size: 'small',
            marginBottom: undefined,
            marginRight: undefined,
            marginLeft: undefined,
            title: `Add A Book`,
          })
        }
      >
        Add A Specific Book
      </AuthButton>

      {(!!progresses || progresses?.length > 0) && (
        <AuthButton
          containerStyle={{ flex: 0 }}
          onPress={() =>
            setModal({
              show: true,
              type: 'regen',
              progress: undefined,
              size: 'small',
              marginBottom: undefined,
              marginRight: undefined,
              marginLeft: undefined,
              title: `Regenerate Books`,
            })
          }
        >
          Regenerate Books
        </AuthButton>
      )}
    </>
  );
}

function FullEditModal(props) {
  const { progress, submitProgress } = props;
  const [formData, setFormData] = useState({});

  // get the absolute guaranteed latest version
  const latestProgress = useQuery({
    enabled: !!progress?.id,
    refetchOnWindowFocus: false,
    queryKey: [
      {
        endpoint: `onboarding-attendee-progresses`,
        objectID: progress?.id,
      },
    ],
  });

  if (!progress?.id) {
    return <p>No progress given</p>;
  }

  if (latestProgress.isLoading) {
    return <ActivityIndicator size={2} />;
  }

  if (latestProgress.isError) {
    return <p>{latestProgress.error?.message}</p>;
  }

  const p = latestProgress.data;

  return (
    <div style={{ margin: '0 auto', maxWidth: '512px' }}>
      <AuthTextInput
        name="amount_deposited"
        label="Amount Deposited"
        defaultValue={p.amount_deposited}
        onChangeText={t => setFormData({ ...formData, amount_deposited: t })}
      />
      <AuthTextInput
        name="amount_bet"
        label="Amount Bet"
        defaultValue={p.amount_bet}
        onChangeText={t => setFormData({ ...formData, amount_bet: t })}
      />
      <div
        style={{ margin: 'var(--space-sm) 0' }}
        title="Override the min deposit to a custom value. This will also prevent this from being automatically changed/updated as the actual min deposit changes"
      >
        <input
          key={`man-min-dep-prog-list-${p.id}-${p.manual_min_deposit}`}
          type="checkbox"
          name="manual_min_deposit"
          id="manual_min_deposit"
          defaultChecked={p.manual_min_deposit}
          onChange={ev => {
            setFormData({ ...formData, manual_min_deposit: ev.target.checked });
          }}
        />
        <label htmlFor="manual_min_deposit">Manually Set Min Deposit</label>
      </div>
      {(formData.manual_min_deposit ||
        (formData.manual_min_deposit === undefined &&
          p.manual_min_deposit)) && (
          <AuthTextInput
            name="min_deposit"
            label="Min Deposit"
            defaultValue={p.min_deposit}
            onChangeText={t => setFormData({ ...formData, min_deposit: t })}
          />
        )}
      <div style={{ margin: 'var(--space-sm) 0' }}>
        <input
          key={`alr-had-book-prog-list-${p.id}-${p.already_had_book}`}
          type="checkbox"
          name="already_had_book"
          id="already_had_book"
          defaultChecked={p.already_had_book}
          onChange={ev => {
            setFormData({ ...formData, already_had_book: ev.target.checked });
          }}
        />
        <label htmlFor="already_had_book">Already had book</label>
      </div>
      <div style={{ margin: 'var(--space-sm) 0' }}>
        <input
          key={`is-hidden-prog-list-${p.id}-${p.is_hidden}`}
          type="checkbox"
          name="is_hidden"
          id="is_hidden"
          defaultChecked={p.is_hidden}
          onChange={ev => {
            setFormData({ ...formData, is_hidden: ev.target.checked });
          }}
        />
        <label htmlFor="is_hidden">Hide from attendee</label>
      </div>
      <div style={{ margin: 'var(--space-sm) 0' }}>
        <input
          key={`manual_override_is_hidden-prog-list-${p.id}-${p.manual_override_is_hidden}`}
          type="checkbox"
          name="manual_override_is_hidden"
          id="manual_override_is_hidden"
          defaultChecked={p.manual_override_is_hidden}
          onChange={ev => {
            setFormData({
              ...formData,
              manual_override_is_hidden: ev.target.checked,
            });
          }}
        />
        <label htmlFor="manual_override_is_hidden">
          Prevent automatic changes to 'Hide from attendee'
        </label>
      </div>
      <span>Current Step: </span>
      <select
        defaultValue={p.current_step}
        onChange={ev =>
          setFormData({ ...formData, current_step: ev.target.value })
        }
      >
        <option value="NOT_STARTED">Not Started</option>
        <option value="DEPOSIT">Deposit</option>
        <option value="PENDING_BOOK_DOC_APPROVAL">
          Pending Book Doc Approval
        </option>
        <option value="PENDING_DEPOSIT">Pending Deposit</option>
        <option value="PLACE_BET">Place Bet</option>
        <option value="PENDING_SCREENSHOT_APPROVAL">
          Pending Screenshot Approval
        </option>
        <option value="SCREENSHOTS_INVALID">Screenshots Invalid</option>
        <option value="FULLY_COMPLETE">Fully Complete</option>
        <option value="NEEDS_HELP">Needs Help</option>
      </select>
      <br />
      <br />

      <AuthButton
        isLoading={submitProgress.isLoading}
        onPress={() =>
          submitProgress.mutate({
            endpoint: `onboarding-attendee-progresses/${p.id}`,
            body: {
              attendee_id: p.attendee,
              ...formData,
            },
          })
        }
      >
        Save Changes
      </AuthButton>

      {submitProgress.isError && (
        <>
          <br />
          <small style={{ color: 'var(--color-danger)' }}>
            {submitProgress.error.message}
          </small>
        </>
      )}
    </div>
  );
}
