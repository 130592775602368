import { Observable } from 'rxjs';
import 'rxjs/add/operator/exhaustMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import { ajax } from 'rxjs/observable/dom/ajax';
import { BACKEND_API_URL, getHeaders } from 'utils';

import {
  VERIFY_CONNECT_ACCOUNT,
  verifyConnectAccountSuccess,
  verifyConnectAccountFailure,
} from 'actions';

export default function verifyConnectAccount(action$, state$) {
  return action$.ofType(VERIFY_CONNECT_ACCOUNT).exhaustMap(action => {
    return ajax
      .post(
        BACKEND_API_URL + 'api/users/verify_connect_account/',
        {
          code: action.code,
          state: action.state,
        },
        getHeaders(state$)
      )
      .map(data => verifyConnectAccountSuccess(data))
      .catch(error => Observable.of(verifyConnectAccountFailure(error.xhr)));
  });
}
