export default {
  link_to_video: '',
  content: [
    {
      type: 'paragraph',
      text: 'Default settings can be configured in the settings tab.',
    },
    { type: 'heading', size: 5, text: 'Default Odds' },
    {
      type: 'paragraph',
      text: 'Toggle between American odds (-110 +110 etc), or decimal odds.',
    },
    { type: 'heading', size: 5, text: 'Default Bet Size' },
    {
      type: 'paragraph',
      text:
        'Your default bet size will be pre-loaded into your track bet screen every time you track a bet.',
    },
    {
      type: 'paragraph',
      text:
        'If you select “base” and “$30” as shown below, the default bet size will be risk $30 for underdogs, or win $30 for favourites.',
    },
    {
      type: 'paragraph',
      text:
        'If you select “risk” and “$30”, the default bet size will be risk $30.',
    },
    {
      type: 'image',
      url:
        'https://betstamp-public.sfo2.cdn.digitaloceanspaces.com/tutorials/defaults/settings-defaults.jpg',
    },
  ],
};
