import React from 'react';
import { Redirect, useLocation } from 'react-router-dom';

import CONTENT from 'content/tutorials';

// components
import { Row, Col, Grid } from 'components/generic/Layout';
import Footer from 'components/Footer';
import { LinkButton } from 'components/AuthButton';

export default function TutorialGeneric() {
  const location = useLocation();

  let tutorial = location.pathname.substr(10);
  tutorial = tutorial
    .split('-')
    .map(word => word.charAt(0).toUpperCase() + word.substr(1))
    .join(' ');

  if (CONTENT[tutorial.replace(/ /g, '')] === undefined) {
    return <Redirect to="/tutorial-all" />;
  }

  return (
    <>
      <div className="container" style={{ padding: '0 var(--space-sm)' }}>
        <h2>{tutorial} Tutorial</h2>

        {/* TODO: link to video stuff */}
        {CONTENT[tutorial.replace(/ /g, '')].content.map((bit, i) => {
          if (bit.type === 'heading') {
            return (
              <SizedHeader key={`${tutorial}-${i}`} size={bit.size}>
                {bit.text}
              </SizedHeader>
            );
          } else if (bit.type === 'paragraph') {
            return <p key={`${tutorial}-${i}`}>{bit.text}</p>;
          } else if (bit.type === 'image') {
            return (
              <Row
                key={`${tutorial}-${i}`}
                style={{ margin: 'var(--space-lg) 0' }}
              >
                <Col>
                  <img
                    src={bit.url}
                    style={{
                      width: '100%',
                      height: 'auto',
                      maxWidth: bit?.maxWidth || '350px',
                    }}
                    alt={'Tutorial'}
                  />
                </Col>
              </Row>
            );
          } else if (bit.type === 'image-grid') {
            return (
              <Grid key={`${tutorial}-${i}`}>
                {bit.images.map((img, j) => (
                  <img
                    key={`tutorial-image-${i}-${j}`}
                    src={img.url}
                    style={{
                      width: '100%',
                      height: 'auto',
                      maxWidth: img?.maxWidth || '350px',
                      margin: '0 auto',
                    }}
                    alt="Tutorial"
                  />
                ))}
              </Grid>
            );
          } else if (bit.type === 'list-item') {
            return (
              <ul key={`${tutorial}-${i}`}>
                <li>{bit.text}</li>
              </ul>
            );
          } else if (bit.type === 'video') {
            return (
              <Row
                key={`${tutorial}-${i}`}
                style={{ margin: 'var(--space-lg) 0' }}
              >
                <Col>
                  <video
                    width="300"
                    controls
                    autoPlay
                    loop
                    muted
                    style={{
                      border: '1px solid var(--color-text)',
                      borderRadius: '25px',
                    }}
                  >
                    <source src={bit.url} type="video/mp4" />
                    Sorry, your browser doesn't support embedded videos.
                  </video>
                </Col>
              </Row>
            );
          } else if (bit.type === 'youtube-video') {
            return (
              <Row
                key={`${tutorial}-${i}`}
                style={{ margin: 'var(--space-lg) 0' }}
              >
                <Col>
                  <iframe
                    src={`https://www.youtube.com/embed/${bit.url.slice(
                      -11
                    )}?autoplay=1`}
                    frameBorder="0"
                    allow="autoplay; clipboard-write; encrypted-media; picture-in-picture"
                    allowFullScreen
                    style={{ width: '60vw', height: '34vw' }}
                  />
                </Col>
              </Row>
            );
          } else {
            return <></>;
          }
        })}
        <Row style={{ marginTop: 'var(--space-xl)' }}>
          <Col>
            <LinkButton
              to="/tutorial-all"
              containerStyle={{
                maxWidth: '14rem',
              }}
            >
              Back to All Tutorials
            </LinkButton>
          </Col>
        </Row>
      </div>
      <Footer />
    </>
  );
}

function SizedHeader(props) {
  switch (props.size) {
    case 1:
      return <h1>{props.children}</h1>;
    case 2:
      return <h2>{props.children}</h2>;
    case 3:
      return <h3>{props.children}</h3>;
    case 4:
      return <h4>{props.children}</h4>;
    case 5:
      return <h5>{props.children}</h5>;
    case 6:
      return <h6>{props.children}</h6>;
    default:
      return <h4>{props.children}</h4>;
  }
}
