import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { useClickAway } from 'react-use';
import {
  IoAmericanFootball,
  IoBaseball,
  IoBasketball,
  IoChevronDown,
  IoChevronUp,
  IoFootball,
  IoStar,
  IoStarOutline,
} from 'react-icons/io5';
import {
  MdSportsHockey,
  MdSportsTennis,
  MdSportsKabaddi,
} from 'react-icons/md';
import moment from 'moment';

// utils
import { GROUPED_LEAGUES, LEAGUES } from 'utils';

// components
import { AuthButton, IconButton } from 'components/AuthButton';
import { Row, Grid } from 'components/generic/Layout';
import TeamLeagueLogo from 'components/TeamLeagueLogo';
import DisplayToggle from 'components/generic/DisplayToggle';

// actions
import {
  getSched,
  getOddscomp,
  setAppSetting,
  toggleLeagueFav,
  getFuturesSched,
} from 'actions';

const Popup = styled.div`
  flex: 1;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-around;
  align-items: flex-start;
  position: absolute;
  margin-left: -47px;
  top: 50px;
  width: 400px;
  height: 600px;
  background-color: var(--color-bg);
  border-radius: var(--std-border-radius);
  box-shadow: 0 2px 8px 0 var(--color-shadow);
  box-sizing: border-box;
  z-index: 9999;
  &:after {
    content: '';
    display: block;
    position: absolute;
    right: 33px;
    bottom: 100%;
    width: 0;
    height: 0;
    border-bottom: 10px solid var(--color-bg);
    border-top: 10px solid transparent;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
  }

  @keyframes AnimateOpen {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  animation-name: AnimateOpen;
  animation-duration: 333ms;
  animation-timing-function: ease-in-out;
`;

export default function LeagueSelector(props) {
  const dispatch = useDispatch();
  const reduxProps = useSelector(state => ({
    SCHED_selectedLeagues: state.scheduleReducer.selectedLeagues,
    FUT_selectedLeagues: state.scheduleReducer.selectedLeagues,
    ODDS_selectedLeagues: state.oddscompReducer.selectedLeagues,
    faveLeagues: state.settingsReducer.faveLeagues,
    leagueSelectMode: state.settingsReducer.leagueSelectMode,
    hasNotStarted: state.scheduleReducer.hasNotStarted,
    seasonComplete: state.scheduleReducer.seasonComplete,
    inProgress: state.scheduleReducer.inProgress,
  }));
  const {
    ODDS_selectedLeagues,
    SCHED_selectedLeagues,
    FUT_selectedLeagues,
    faveLeagues,
    leagueSelectMode,
    hasNotStarted,
    seasonComplete,
    inProgress,
  } = reduxProps;
  const { schedule, oddscomp, futures } = props;

  let selectedLeagues;
  if (schedule) {
    selectedLeagues = SCHED_selectedLeagues;
  } else if (oddscomp) {
    selectedLeagues = ODDS_selectedLeagues;
  } else if (futures) {
    selectedLeagues = FUT_selectedLeagues;
  }

  const [open, setOpen] = useState(false);
  const [hotbarLeagues, setHotbarLeagues] = useState(selectedLeagues);

  const ref = useRef(null);
  useClickAway(ref, () => {
    setOpen(false);
  });

  useEffect(() => {
    if (faveLeagues.length > 0) {
      let hbl = selectedLeagues.filter(l => !faveLeagues.includes(l));
      hbl = hbl.concat(faveLeagues);
      setHotbarLeagues(hbl);
    } else {
      setHotbarLeagues(LEAGUES.slice(0, 6).map(l => l.league));
    }
  }, [faveLeagues, selectedLeagues]);

  return (
    <>
      <div
        ref={ref}
        style={{
          flex: 2,
          display: 'flex',
          flexFlow: 'row nowrap',
          alignItems: 'center',
          justifyContent: 'flex-end',
          height: '100%',
        }}
      >
        {hotbarLeagues.map(l => (
          <LeagueBtn
            key={`league-selector-hotbar-${l}`}
            league={l}
            schedule={schedule}
            oddscomp={oddscomp}
            futures={futures}
            callback={props.callback}
          />
        ))}

        <AuthButton
          btnTheme="borderless"
          colorTheme="text"
          containerStyle={{ maxWidth: '256px' }}
          rightIcon={open ? IoChevronUp : IoChevronDown}
          onPress={() => setOpen(!open)}
        >
          All Leagues
        </AuthButton>
        {open && (
          <Popup>
            <div
              style={{
                height: '100%',
                width: '100%',
                padding: 'var(--space-sm)',
                overflowY: 'auto',
              }}
            >
              <Row
                style={{
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  marginBottom: 'var(--space-sm)',
                }}
              >
                <span style={{ flex: 2 }}>Selection Mode</span>
                <DisplayToggle
                  style={{ flex: 3 }}
                  options={['Single', 'Multi']}
                  active={leagueSelectMode}
                  onPress={mode =>
                    dispatch(setAppSetting('leagueSelectMode', mode))
                  }
                />
              </Row>
              <AuthButton
                containerStyle={{ marginBottom: 'var(--space-md)' }}
                onPress={() => {
                  dispatch(setAppSetting('leagueSelectMode', 'Multi'));
                  if (props.callback) {
                    props.callback([]);
                  }
                  if (schedule) {
                    dispatch(
                      getSched(
                        [],
                        moment().subtract(4, 'hours').toISOString(true),
                        null,
                        '' // reset search term to blank
                      )
                    );
                  } else if (oddscomp) {
                    dispatch(
                      getOddscomp(
                        [],
                        moment().subtract(4, 'hours').toISOString(true),
                        null,
                        '' // reset search term to blank
                      )
                    );
                  } else if (futures) {
                    dispatch(
                      getFuturesSched({
                        leagues: [],
                        season: null,
                        hasNotStarted: hasNotStarted,
                        inProgress: inProgress,
                        seasonComplete: seasonComplete,
                      })
                    );
                  }
                }}
              >
                Select All Leagues
              </AuthButton>
              {[{ groupName: 'Favorites', leagues: faveLeagues }]
                .concat(GROUPED_LEAGUES)
                .map(group => (
                  <React.Fragment
                    key={`league-selector-group-${group.groupName}`}
                  >
                    <Row
                      style={{
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        opacity: 0.66,
                        marginBottom: 'var(--space-xxxs)',
                      }}
                    >
                      <GroupIcon group={group.groupName} />
                      <b
                        style={{
                          marginLeft: 'var(--space-xxs)',
                          color:
                            group.groupName === 'Favorites'
                              ? 'var(--color-complement)'
                              : 'var(--color-text)',
                        }}
                      >
                        {group.groupName.toUpperCase()}
                      </b>
                    </Row>
                    {group.leagues.length > 0 ? (
                      <Grid
                        style={{
                          width: '100%',
                          marginBottom: 'var(--space-md)',
                          gridTemplateColumns:
                            'repeat(auto-fill, minmax(128px, 1fr))',
                        }}
                      >
                        {group.leagues.map(l => (
                          <Row
                            key={`league-selector-grid-${l}`}
                            style={{ alignItems: 'center' }}
                          >
                            <LeagueBtn
                              league={l}
                              showText
                              containerStyle={{ marginRight: 0 }}
                              schedule={schedule}
                              oddscomp={oddscomp}
                              futures={futures}
                              callback={props.callback}
                            />
                            <IconButton
                              iconName={
                                faveLeagues.includes(l) ? IoStar : IoStarOutline
                              }
                              btnStyle={{ padding: 'var(--space-sm)' }}
                              colorTheme="complement"
                              onPress={() => dispatch(toggleLeagueFav(l))}
                            />
                          </Row>
                        ))}
                      </Grid>
                    ) : (
                      <p style={{ marginTop: 0 }}>
                        Click the star to add a league to your favorites
                      </p>
                    )}
                  </React.Fragment>
                ))}
            </div>
          </Popup>
        )}
      </div>
    </>
  );
}

function LeagueBtn(props) {
  const { league, showText, schedule, oddscomp, futures } = props;
  const dispatch = useDispatch();
  const reduxProps = useSelector(state => ({
    SCHED_selectedLeagues: state.scheduleReducer.selectedLeagues,
    FUT_selectedLeagues: state.scheduleReducer.selectedLeagues,
    ODDS_selectedLeagues: state.oddscompReducer.selectedLeagues,
    showingDate: state.scheduleReducer.showingDate,
    leagueSelectMode: state.settingsReducer.leagueSelectMode,
    hasNotStarted: state.scheduleReducer.hasNotStarted,
    seasonComplete: state.scheduleReducer.seasonComplete,
    inProgress: state.scheduleReducer.inProgress,
  }));
  const {
    SCHED_selectedLeagues,
    ODDS_selectedLeagues,
    FUT_selectedLeagues,
    showingDate,
    leagueSelectMode,
    hasNotStarted,
    seasonComplete,
    inProgress,
  } = reduxProps;

  let selectedLeagues;
  if (schedule) {
    selectedLeagues = SCHED_selectedLeagues;
  } else if (oddscomp) {
    selectedLeagues = ODDS_selectedLeagues;
  } else if (futures) {
    selectedLeagues = FUT_selectedLeagues;
  }

  return (
    <AuthButton
      overrideChildren
      containerStyle={{
        maxWidth: '128px',
        margin: 0,
        marginRight: 'var(--space-sm)',
        borderRadius: '8px',
        height: '100%',
        ...props.containerStyle,
      }}
      btnStyle={{ borderRadius: '8px', padding: 0, height: '100%' }}
      colorTheme={selectedLeagues.includes(league) ? 'inverted' : 'text'}
      onPress={() => {
        if (leagueSelectMode === 'Single') {
          if (props.callback) {
            props.callback([league]);
          }
          if (schedule) {
            dispatch(
              getSched(
                [league],
                showingDate,
                null,
                '' // reset search term to blank
              )
            );
          } else if (oddscomp) {
            dispatch(
              getOddscomp(
                [league],
                showingDate,
                null,
                '' // reset search term to blank
              )
            );
          } else if (futures) {
            dispatch(
              getFuturesSched({
                leagues: [league],
                season: null,
                hasNotStarted: hasNotStarted,
                inProgress: inProgress,
                seasonComplete: seasonComplete,
              })
            );
          }
        } else if (selectedLeagues.includes(league)) {
          if (props.callback) {
            props.callback(selectedLeagues.filter(sl => sl !== league));
          }
          if (schedule) {
            dispatch(
              getSched(
                selectedLeagues.filter(sl => sl !== league),
                showingDate,
                null,
                '' // reset search term to blank
              )
            );
          } else if (oddscomp) {
            dispatch(
              getOddscomp(
                selectedLeagues.filter(sl => sl !== league),
                showingDate,
                null,
                '' // reset search term to blank
              )
            );
          } else if (futures) {
            dispatch(
              getFuturesSched({
                leagues: selectedLeagues.filter(sl => sl !== league),
                season: null,
                hasNotStarted: hasNotStarted,
                inProgress: inProgress,
                seasonComplete: seasonComplete,
              })
            );
          }
        } else {
          if (props.callback) {
            props.callback([...selectedLeagues, league]);
          }
          if (schedule) {
            dispatch(
              getSched(
                [...selectedLeagues, league],
                moment().subtract(4, 'hours').toISOString(true),
                null,
                '' // reset search term to blank
              )
            );
          } else if (oddscomp) {
            dispatch(
              getOddscomp(
                [...selectedLeagues, league],
                moment().subtract(4, 'hours').toISOString(true),
                null,
                '' // reset search term to blank
              )
            );
          } else if (futures) {
            dispatch(
              getFuturesSched({
                leagues: [...selectedLeagues, league],
                season: null,
                hasNotStarted: hasNotStarted,
                inProgress: inProgress,
                seasonComplete: seasonComplete,
              })
            );
          }
        }
      }}
    >
      <TeamLeagueLogo iconSize={32} league={league} style={{ margin: 0 }} />
      {showText && <span>{league}</span>}
    </AuthButton>
  );
}

function GroupIcon({ group }) {
  const iconProps = {
    color: 'var(--color-text)',
    size: 26,
  };
  if (group === 'Favorites') {
    return <IoStar color="var(--color-complement)" size={iconProps.size} />;
  }
  if (group === 'Football') {
    return <IoAmericanFootball {...iconProps} />;
  }
  if (group === 'Basketball') {
    return <IoBasketball {...iconProps} />;
  }
  if (group === 'Hockey') {
    return <MdSportsHockey {...iconProps} />;
  }
  if (group === 'Baseball') {
    return <IoBaseball {...iconProps} />;
  }
  if (group === 'Soccer') {
    return <IoFootball {...iconProps} />;
  }
  if (group === 'Tennis') {
    return <MdSportsTennis {...iconProps} />;
  }
  if (group === 'Fighting') {
    return <MdSportsKabaddi {...iconProps} />;
  }

  return null;
}
