import { BACKEND_API_URL } from 'utils';
import { getBaseHeaders, handleRes } from './privateFns.js';

export async function defaultQueryFn(params) {
  let { endpoint, objectID, filters } = params.queryKey[0];
  let urlParams = { ...params.queryKey[0]?.urlParams };

  if (filters) {
    console.log(endpoint, 'CONVERT TO URLPARAMS PLEASE');
  }

  let page = null;
  if (params.pageParam) {
    let urlsearchparams = new URLSearchParams(params.pageParam.split('?')[1]);
    page = urlsearchparams.get('page');
    if (page) {
      if (urlParams) urlParams.page = page;
      else urlParams = { page: page };
    } else {
      let cursor = urlsearchparams.get('cursor');
      if (urlParams) urlParams.cursor = cursor;
      else urlParams = { cursor: cursor };
    }
  }

  let url = `${BACKEND_API_URL}api/${endpoint}/`;

  if (objectID) {
    url += `${objectID}/`;
  }

  if (urlParams) {
    url += `?${new URLSearchParams(urlParams).toString()}`;
  }

  let headers = getBaseHeaders();
  headers['Content-Type'] = 'application/json';

  const res = await fetch(url, {
    headers: headers,
  });

  return handleRes(res);
}
