import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

// actual screens
import MarketplaceDesktop from 'pages/Marketplace/MarketplaceDesktop';
import MarketplaceMobile from 'pages/Marketplace/MarketplaceMobile';

// components
import { Col } from 'components/generic/Layout';

// actions
import {
  getFollowing,
  getFeatured,
  getAffiliates,
  getCompetitions,
  getHammerUsers,
  // getRecommendedUsers,
} from 'actions';

export default function Marketplace() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getFollowing());
    dispatch(getFeatured());
    dispatch(getAffiliates());
    dispatch(getCompetitions());
    // dispatch(getRecommendedUsers());
    dispatch(getHammerUsers());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Col
        desktop
        style={{ height: '100vh', flexWrap: 'nowrap', overflow: 'hidden' }}
      >
        <MarketplaceDesktop />
      </Col>
      <Col mobile>
        <MarketplaceMobile />
      </Col>
    </>
  );
}
