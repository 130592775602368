import { Observable } from 'rxjs';
import 'rxjs/add/operator/exhaustMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import { ajax } from 'rxjs/observable/dom/ajax';
import { BACKEND_API_URL, getHeaders } from 'utils';

import {
  SEND_EMAIL_VERIFICATION,
  sendEmailVerificationComplete,
} from 'actions';

export default function sendEmailVerification(action$, state$) {
  return action$.ofType(SEND_EMAIL_VERIFICATION).exhaustMap(() => {
    return ajax
      .post(
        BACKEND_API_URL + 'api/send_confirmation_email/',
        {},
        getHeaders(state$)
      )
      .map(() => sendEmailVerificationComplete())
      .catch(() => Observable.of(sendEmailVerificationComplete()));
  });
}
