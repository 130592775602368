import { Observable } from 'rxjs';
import 'rxjs/add/operator/exhaustMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import { ajax } from 'rxjs/observable/dom/ajax';
import { BACKEND_API_URL, getHeaders } from 'utils';

import {
  FIND_BETTER_ODDS,
  findBetterOddsSuccess,
  findBetterOddsFailure,
} from 'actions';

export default function findBetterOdds(action$, state$) {
  return action$.ofType(FIND_BETTER_ODDS).exhaustMap(action => {
    return ajax
      .post(
        BACKEND_API_URL + `api/find_better_odds/`,
        {
          bet_type: action.bet_type,
          bets: action.bets,
        },
        getHeaders(state$)
      )
      .map(data => findBetterOddsSuccess(data))
      .catch(error => Observable.of(findBetterOddsFailure(error.xhr)));
  });
}
