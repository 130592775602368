// actions (excluding success and failure actions):
export const RESET_EDIT_DEL = 'RESET_EDIT_DEL';
export const RESET_ERRORS_PICKS = 'RESET_ERRORS_PICKS';
export const FIND_BETTER_ODDS = 'FIND_BETTER_ODDS';
export const GET_PICKS_STATS = 'GET_PICKS_STATS';
export const CLEAR_AD = 'CLEAR_AD';

// RESET_ERRORS_PICKS
export const resetErrorsPicks = () => ({ type: RESET_ERRORS_PICKS });

export const FIND_BETTER_ODDS_SUCCESS = 'FIND_BETTER_ODDS_SUCCESS';
export const FIND_BETTER_ODDS_FAILURE = 'FIND_BETTER_ODDS_FAILURE';

export const findBetterOdds = (bet_type, bets) => ({
  type: FIND_BETTER_ODDS,
  bet_type,
  bets,
});

export const findBetterOddsSuccess = (payload) => ({
  type: FIND_BETTER_ODDS_SUCCESS,
  payload: payload,
});

export const findBetterOddsFailure = (error) => ({
  type: FIND_BETTER_ODDS_FAILURE,
  payload: error,
});

export const GET_PICKS_STATS_SUCCESS = 'GET_PICKS_STATS_SUCCESS';
export const GET_PICKS_STATS_FAILURE = 'GET_PICKS_STATS_FAILURE';
export const getPicksStats = (date_after, date_before) => ({
  type: GET_PICKS_STATS,
  date_after,
  date_before,
});

export const getPicksStatsSuccess = (payload) => ({
  type: GET_PICKS_STATS_SUCCESS,
  payload: payload,
});

export const getPicksStatsFailure = (error) => ({
  type: GET_PICKS_STATS_FAILURE,
  payload: error,
});

// CLEAR_AD
export const clearAd = () => ({ type: CLEAR_AD });
