import { Observable } from 'rxjs';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import { ajax } from 'rxjs/observable/dom/ajax';
import { BACKEND_API_URL, getHeaders } from 'utils';

import { GET_LIVE_BA, getLiveBASuccess, getLiveBAFailure } from 'actions';

export default function getLiveBA(action$, state$) {
  return action$.ofType(GET_LIVE_BA).switchMap(action => {
    let url =
      BACKEND_API_URL +
      `api/best_available/?game_id=${action.game_id}&period=FT&is_live=True`;

    return ajax
      .getJSON(url, getHeaders(state$))
      .map(data => getLiveBASuccess(data))
      .catch(error => Observable.of(getLiveBAFailure(error.xhr)));
  });
}
