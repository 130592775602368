import { Observable } from 'rxjs';
import 'rxjs/add/operator/exhaustMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import { ajax } from 'rxjs/observable/dom/ajax';
import { BACKEND_API_URL, getHeaders } from 'utils';
import { PLACE_BET, placeBetSuccess, placeBetFailure } from 'actions';

export default function placeBet(action$, state$) {
  return action$.ofType(PLACE_BET).exhaustMap(action => {
    return ajax
      .post(
        BACKEND_API_URL + 'api/load_betslip/',
        action.bet,
        getHeaders(state$)
      )

      .map(data => placeBetSuccess(data))
      .catch(error => Observable.of(placeBetFailure(error.xhr)));
  });
}
