import React from 'react';

import { Row } from 'components/generic/Layout';

export default function BlueBubble(props) {
  const { style, children } = props;

  return (
    <Row
      style={{
        backgroundColor: 'var(--color-primary)',
        width: '90%',
        paddingLeft: 'calc(var(--space-lg) + var(--space-sm))',
        paddingRight: 'var(--space-md)',
        borderBottomLeftRadius: 64,
        borderTopLeftRadius: 64,
        alignSelf: 'flex-end',
        marginRight: 'calc(-1 * var(--space-xs))', // for shifting BlueBubble to touch right edge of container
        ...style,
      }}
    >
      {children}
    </Row>
  );
}
