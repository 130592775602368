import React from 'react';

import AppStoreImg from 'images/download_app_store.png';
import PlayStoreImg from 'images/download_play_store.png';

export default function GamesMobile(props) {
  return (
    <>
      <br />
      <br />
      <h6 style={{ textAlign: 'center' }}>
        The games screen is not yet available on the mobile website but you can
        download the betstamp app to track your bets on the go
      </h6>
      <br />
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://apps.apple.com/us/app/id1525948689"
        style={{ width: '100%', maxWidth: '225px' }}
      >
        <img
          src={AppStoreImg}
          style={{
            width: '100%',
            maxWidth: '225px',
            marginLeft: '-3px',
          }}
          alt="Download from the App Store"
        />
      </a>
      <br />
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://play.google.com/store/apps/details?id=app.cashew.betstamp"
        style={{ width: '100%', maxWidth: '225px' }}
      >
        <img
          src={PlayStoreImg}
          style={{ width: '100%', maxWidth: '225px' }}
          alt="Download from the Play Store"
        />
      </a>
    </>
  );
}
