import { Observable } from 'rxjs';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import { ajax } from 'rxjs/observable/dom/ajax';
import { getHeaders } from 'utils';

import { GET_WAGERS_NEXT, getWagersSuccess, getWagersFailure } from 'actions';

export default function getWagersNext(action$, state$) {
  return action$.ofType(GET_WAGERS_NEXT).concatMap(action => {
    return ajax
      .getJSON(
        state$.value.wagerReducer.wagersNext[action.screen],
        getHeaders(state$)
      )
      .map(data => getWagersSuccess(data, action.screen))
      .catch(error =>
        Observable.of(getWagersFailure(error.xhr, action.screen))
      );
  });
}
