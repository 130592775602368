import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Analytics from 'amplitude/Analytics';

// components
import ActivityIndicator from './generic/ActivityIndicator';
import { Row } from 'components/generic/Layout';

// utils
import { preloadImageHelper } from 'utils';

// loading indicator
import BetstampActivityIndicatorDay from 'images/gif/betstamp_activity_indicator_day.gif';
import BetstampActivityIndicatorNight from 'images/gif/betstamp_activity_indicator_night.gif';

const StyledBtn = styled.button`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: var(--text-base-size);
  font-family: 'Lato', sans-serif;
  text-decoration: none;
  outline: none;
  transition: all var(--std-transition);

  cursor: ${props => {
    if (props.disabled) {
      return 'not-allowed';
    } else {
      return 'pointer';
    }
  }};

  &:hover {
    box-shadow: ${props => {
      if (!props.disabled) {
        return `inset 0 0 0 100vh rgba(255, 255, 255, 0.40);`;
      }
    }};
  }

  &:active {
    box-shadow: ${props => {
      if (!props.disabled) {
        return `inset 0 0 0 100vh rgba(0, 0, 0, 0.1);`;
      }
    }};
  }
`;

export function AuthButton(props) {
  const reduxProps = useSelector(state => ({
    user: state.authReducer.user,
    theme: state.settingsReducer.theme,
  }));
  const { user, theme } = reduxProps;

  const {
    leftIcon,
    leftIconColor,
    leftIconSize,
    rightIcon,
    rightIconColor,
    rightIconSize,
    disabled,
    isLoading,
    overrideChildren,
    ampData,
  } = props;

  const [forcedDisable, setForcedDisable] = useState(false);
  useEffect(() => {
    if (forcedDisable) {
      // Pre-load gif first before committing to handling user clicks
      const loadImageFirst = async () => {
        await preloadImageHelper(
          theme === 'dark'
            ? BetstampActivityIndicatorNight
            : BetstampActivityIndicatorDay
        );
      };
      loadImageFirst()
        .then(() => {
          props.onPress();
          setForcedDisable(false);
        })
        .catch(e => {
          props.onPress();
          setForcedDisable(false);
        });
    }
  }, [forcedDisable]);

  const internalDisabled = disabled || isLoading || forcedDisable;

  const colorTheme = useMemo(
    () => getColorTheme(props.colorTheme, disabled || forcedDisable),
    [props.colorTheme, disabled, forcedDisable]
  );

  const btnTheme = useMemo(() => getBtnTheme(props.btnTheme), [props.btnTheme]);

  const LeftIconComp = leftIcon || null;
  const RightIconComp = rightIcon || null;

  return (
    <div
      style={{
        width: '100%',
        flex: 1,
        margin: 'var(--space-xs) 0',
        ...props.containerStyle,
      }}
    >
      <StyledBtn
        type={props.type}
        colorTheme={colorTheme}
        disabled={internalDisabled}
        style={{
          ...btnTheme.btn,
          ...colorTheme,
          ...props.btnStyle,
        }}
        onClick={
          internalDisabled || !props.onPress
            ? null
            : () => {
                if (ampData) {
                  Analytics.track(ampData.event, user?.id, ampData.data);
                }

                // fake disabled state first then load activity indicator silently
                if ('isLoading' in props) {
                  setForcedDisable(true);
                } else {
                  props.onPress();
                }
              }
        }
        title={props.title}
      >
        {LeftIconComp && (
          <LeftIconComp
            color={leftIconColor || colorTheme.color}
            size={leftIconSize || 30}
            style={{ padding: 0, flex: `0 0 ${leftIconSize || 30}px` }}
          />
        )}

        {isLoading ? (
          <ActivityIndicator width={21} />
        ) : (
          <>
            {overrideChildren ? (
              <>{props.children}</>
            ) : (
              <span
                style={{
                  flex: 1,
                  color: colorTheme.color,
                  fontWeight: 'bold',
                  textDecoration: 'none',
                  ...props.textStyle,
                }}
              >
                {props.children}
              </span>
            )}
          </>
        )}

        {RightIconComp && (
          <RightIconComp
            color={rightIconColor || colorTheme.color}
            size={rightIconSize || 30}
            style={{ padding: 0, flex: '0 0 30px' }}
          />
        )}
      </StyledBtn>
    </div>
  );
}

export function LinkButton(props) {
  return (
    <Link
      to={props.disabled ? '#' : props.to}
      style={{
        textDecoration: 'none',
        whiteSpace: 'nowrap',
        ...props.linkStyle,
      }}
    >
      <AuthButton {...props} />
    </Link>
  );
}

const StyledIconBtn = styled.button`
  font-size: var(--text-base-size);
  font-family: 'Noto Sans', sans-serif;
  text-decoration: none;
  outline: none;
  transition: all var(--std-transition);
  cursor: pointer;
  border: none;
  flex: 0;
  border-radius: 32px;
  border-width: 1px;
  margin: 0;
  padding: 0;
  padding: var(--space-sm);
  display: flex;
  flex-flow: column wrap;
  justify-content: space-around;
  align-items: center;
  &:hover {
    box-shadow: ${props => {
      if (!props.disabled) {
        return `inset 0 0 0 100vh rgba(255, 255, 255, 0.40);`;
      }
    }};
  }

  &:active {
    box-shadow: ${props => {
      if (!props.disabled) {
        return `inset 0 0 0 100vh rgba(0, 0, 0, 0.1);`;
      }
    }};
  }

  cursor: ${props => {
    if (props.disabled) {
      return 'not-allowed';
    } else {
      return 'pointer';
    }
  }};
`;

export function IconButton(props) {
  const reduxProps = useSelector(state => ({ user: state.authReducer.user }));
  const { user } = reduxProps;
  const {
    subtext,
    iconName,
    iconSize,
    iconColor,
    disabled,
    isLoading,
    ampData,
    iconTitle,
  } = props;

  const internalDisabled = disabled || isLoading;

  const colorTheme = useMemo(
    () => getColorTheme(props.colorTheme, disabled),
    [props.colorTheme, disabled]
  );

  const IconComp = iconName;

  return (
    <div
      style={{
        ...props.containerStyle,
      }}
    >
      <StyledIconBtn
        style={{
          ...colorTheme,
          borderWidth: 0,
          backgroundColor: 'transparent',
          ...props.btnStyle,
        }}
        onClick={
          internalDisabled
            ? null
            : event => {
                if (ampData) {
                  Analytics.track(ampData.event, user?.id, ampData.data);
                }
                props.onPress(event);
              }
        }
        tabIndex={props.tabIndex}
        disabled={internalDisabled}
        type="button"
        title={props.title}
      >
        {isLoading ? (
          <ActivityIndicator />
        ) : (
          <>
            <IconComp
              style={{ textAlign: 'center' }}
              size={iconSize || 24}
              color={iconColor || colorTheme.color}
              title={iconTitle}
            />
            {subtext && (
              <small style={{ textAlign: 'center', color: colorTheme.color }}>
                {subtext}
              </small>
            )}
          </>
        )}
      </StyledIconBtn>
    </div>
  );
}

export function ConfirmAuthButton(props) {
  const [promptConfirm, setPromptConfirm] = useState(false);
  const { confirmText } = props;
  if (promptConfirm) {
    return (
      <Row
        style={{
          width: '100%',
          alignItems: 'flex-start',
          justifyContent: 'space-between',
          flexFlow: 'row nowrap',
          ...props.rowStyle,
        }}
      >
        <p style={{ margin: '0 var(--space-xxs)' }}>
          {!!confirmText ? confirmText : 'Are you sure?'}
        </p>
        <AuthButton {...props}>Confirm</AuthButton>
      </Row>
    );
  } else {
    return (
      <AuthButton {...props} onPress={() => setPromptConfirm(true)}>
        {props.children}
      </AuthButton>
    );
  }
}

function getColorTheme(theme, disabled) {
  const actualTheme = disabled ? 'disabled' : theme || 'primary';
  const THEMES = {
    primary: {
      backgroundColor: 'transparent',
      color: 'var(--color-primary)',
      borderColor: 'var(--color-primary)',
    },
    danger: {
      backgroundColor: 'transparent',
      color: 'var(--color-danger)',
      borderColor: 'var(--color-danger)',
    },
    inverted: {
      backgroundColor: 'var(--color-primary)',
      color: 'white',
      borderColor: 'var(--color-primary)',
    },
    text: {
      backgroundColor: 'transparent',
      color: 'var(--color-text)',
      borderColors: 'var(--color-text)',
    },
    disabled: {
      backgroundColor: 'var(--color-text-light)',
      color: 'var(--color-text-light)',
      borderColor: 'var(--color-text-light)',
    },
    white: {
      backgroundColor: 'white',
      color: 'var(--color-primary)',
      borderColor: 'white',
    },
    complement: {
      backgroundColor: 'transparent',
      color: 'var(--color-complement)',
      borderColor: 'var(--color-complement)',
    },
    fg: {
      backgroundColor: 'var(--color-fg)',
      color: 'var(--color-text)',
      borderColor: 'var(--color-fg)',
    },
    success: {
      backgroundColor: 'transparent',
      color: 'var(--color-success)',
      borderColor: 'var(--color-success)',
    },
    onbblue: {
      backgroundColor: 'var(--color-onb-blue)',
      color: 'white',
      borderColor: 'var(--color-onb-blue)',
    },
    onbblue2: {
      backgroundColor: 'var(--color-onb-blue-light)',
      color: 'var(--color-onb-blue)',
      borderColor: 'var(--color-onb-blue-light)',
    },
    onbbluetext: {
      backgroundColor: 'transparent',
      color: 'var(--color-onb-blue)',
      borderColor: 'transparent',
    },
  };
  return THEMES[actualTheme];
}

function getBtnTheme(theme) {
  const actualTheme = theme || 'std';

  const std = {
    btn: {
      borderStyle: 'solid',
      borderWidth: 2,
      borderRadius: 32,
      padding: 'var(--space-xxs) var(--space-sm)',
    },
    // container: { marginVertical: 'var(--space-xs)', borderRadius: 32 },
  };

  const THEMES = {
    std: std,
    modalBottom: {
      btn: { padding: 'var(--space-md)', borderWidth: 0, borderRadius: 0 },
    },
    borderless: {
      btn: { ...std.btn, borderWidth: 0 },
    },
    slim: {
      btn: {
        ...std.btn,
        paddingVertical: 'var(--space-xxs)',
        marginVertical: 'var(--space-xxxxs)',
      },
    },
    onb: {
      btn: {
        ...std.btn,
        borderRadius: '8px',
        padding: 'var(--space-md) var(--space-sm)',
        fontSize: 'var(--text-lg)',
      },
    },
  };
  return THEMES[actualTheme];
}
