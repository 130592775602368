/**
 * Removes things like '.', ' ', from American odds.
 * @param {String} odds Dirty American odds
 * @returns {String} Cleaned american odds.
 */
export default function cleanAmericanOdds(odds) {
  // One - or + then zero to three digits
  // odds = odds.match(/^[\-\+]\d{0,5}/g);
  odds = odds.match(/^[-+]?\d{0,5}/g);
  if (!odds) return '';
  odds = odds.join('');
  if (odds[0] !== '-' && odds[0] !== '+' && odds.trim() !== '') {
    odds = '+' + odds;
  }
  return odds;
}
