import React from 'react';
import { FaLongArrowAltRight } from 'react-icons/fa';

// utils
import { calculateBetPayout, commaSeparateNumber } from 'utils';

// components
import AutoColoredAmount from 'components/generic/AutoColoredAmount';
import { Row, Col } from 'components/generic/Layout';

export default function RiskWinDisplay(props) {
  const { bet } = props;
  const { risk_amount, is_graded, result, odds, status } = bet;
  const to_win = commaSeparateNumber(
    calculateBetPayout(risk_amount, odds).replace('.00', '')
  );
  return (
    <>
      <Row style={{ alignItems: 'center', margin: 'var(--space-sm) 0' }}>
        <Col>
          <h6 style={{ fontWeight: 'bold', margin: 0 }}>
            ${commaSeparateNumber(risk_amount.toString().replace('.00', ''))}
          </h6>
          <small>Risk</small>
        </Col>
        {is_graded && (
          <>
            <Col style={{ flex: 0 }}>
              <h6 style={{ fontWeight: 'bold', margin: 0 }}>/</h6>
            </Col>
            <Col
              style={{
                alignItems: 'center',
                justifyContent: 'center',
                flex: 1.5,
              }}
            >
              <h6 style={{ fontWeight: 'bold', margin: 0 }}>${to_win} </h6>
              <small>To Win</small>
            </Col>
          </>
        )}
        <Col style={{ flex: 0 }}>
          <FaLongArrowAltRight
            style={{
              color:
                is_graded && result > 0
                  ? 'var(--color-success)'
                  : is_graded && result < 0
                  ? 'var(--color-text)'
                  : 'var(--color-complement)',
              fontSize: '32px',
              padding: '0 var(--space-sm)',
            }}
          />
        </Col>
        <Col>
          <h6 style={{ fontWeight: 'bold', margin: 0 }}>
            {is_graded ? (
              <AutoColoredAmount
                bold="true"
                symbol="$"
                dontKFormat
                commaSeparate
              >
                {result}
              </AutoColoredAmount>
            ) : (
              <>${to_win}</>
            )}
          </h6>
          <small>
            {is_graded
              ? `${status === 'CASHED_OUT' ? 'Cashed Out' : 'Result'}`
              : 'To Win'}
          </small>
        </Col>
      </Row>
    </>
  );
}
