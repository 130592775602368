import { Observable } from 'rxjs';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import { ajax } from 'rxjs/observable/dom/ajax';
import { BACKEND_API_URL, getHeaders } from 'utils';

import {
  UPDATE_PACKAGE,
  updatePackageSuccess,
  updatePackageFailure,
} from 'actions';

export default function updatePackage(action$, state$) {
  return action$.ofType(UPDATE_PACKAGE).switchMap(action => {
    return ajax
      .patch(
        BACKEND_API_URL + 'api/packages/' + action.packageid + '/',
        action.userPackage,
        getHeaders(state$)
      )
      .map(data => updatePackageSuccess(data.response))
      .catch(error => Observable.of(updatePackageFailure(error.xhr)));
  });
}
