import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { IoClose } from 'react-icons/io5';
import { useMeasure } from 'react-use';

// components
import { Row, Col } from 'components/generic/Layout';
import OddsBtnArray from 'components/OddsBtnArray';
import TeamLeagueLogo from 'components/TeamLeagueLogo';

const Wrapper = styled.div`
  width: 100%;
  margin: var(--space-xxxxs) 0;
  box-sizing: border-box;
  border-left: 2px solid transparent;
  margin-bottom: var(--space-xxxxs);
  transition: all var(--std-transition);
  border-bottom: 1px solid var(--color-text-light);

  min-height: 95px;
  flex: 1;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;

  &:last-child {
    border-bottom: 1px solid transparent;
  }

  &:hover {
    border-left: 2px solid var(--color-primary);
  }

  @keyframes FadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  animation-name: FadeIn;
  animation-duration: 250ms;
  animation-timing-function: ease-in-out;
`;

const OddsLoader = styled.span`
  width: 100%;
  height: 100%;
  display: block;
  position: relative;
  background-image: linear-gradient(
      100deg,
      transparent,
      var(--color-bg),
      transparent 80%
    ),
    linear-gradient(var(--color-text-light) 100%, transparent 0),
    linear-gradient(var(--color-text-light) 100%, transparent 0),
    linear-gradient(var(--color-text-light) 100%, transparent 0),
    linear-gradient(var(--color-text-light) 100%, transparent 0),
    linear-gradient(var(--color-text-light) 100%, transparent 0),
    linear-gradient(var(--color-text-light) 100%, transparent 0);

  background-repeat: no-repeat;

  background-size: 75px 100%, 33% 49%, 33% 49%, 33% 49%, 33% 49%, 33% 49%,
    33% 49%;

  background-position: 0% 0, 0% 0%, 50% 0%, 100% 0%, 0% 100%, 50% 100%,
    100% 100%;

  box-sizing: border-box;
  animation: animloader 1s linear infinite;

  @keyframes animloader {
    0% {
      background-position: 0% 0, 0% 0%, 50% 0%, 100% 0%, 0% 100%, 50% 100%,
        100% 100%;
    }
    100% {
      background-position: 100% 0, 0% 0%, 50% 0%, 100% 0%, 0% 100%, 50% 100%,
        100% 100%;
    }
  }

  @media only screen and (max-width: 1200px) {
    min-height: 100px;
  }
`;

export default function GameListItem(props) {
  const reduxProps = useSelector(state => ({
    selectedBook: state.scheduleReducer.selectedBook,
    changingBook: state.scheduleReducer.changingBook,
    mode: state.betslipReducer.mode,
  }));

  const { selectedBook, changingBook, mode } = reduxProps;

  const { game } = props;

  // for normal mode
  const { best_available, odds, live_odds, best_available_ht } = game;
  const { win_pct } = best_available;

  const [ref, { width }] = useMeasure();

  // over 1200 show full team name
  // between 800 and 900 show full teamname
  // else abbr team name

  let showFullTeamName = false;
  if (width > 1200 || (width > 800 && width < 900)) {
    showFullTeamName = true;
  }

  // less than 900 show one odd set
  let mainOddSet = {};
  let secondOddSet = {};

  if (width > 900) {
    mainOddSet =
      game.is_live && game.sport !== 'Golf'
        ? live_odds
        : best_available
        ? best_available
        : {};

    switch (selectedBook.id) {
      case -1:
        secondOddSet = best_available_ht ? best_available_ht : {};
        break;
      case -2:
        secondOddSet = live_odds;
        break;
      case -3:
        secondOddSet = best_available ? best_available : {};
        break;
      default:
        if (selectedBook.id > 0) secondOddSet = odds ? odds : {};
        break;
    }
  } else {
    mainOddSet =
      mode === 'parlay'
        ? odds
          ? odds
          : {}
        : game.is_live && !game.sport === 'Golf'
        ? live_odds
        : best_available
        ? best_available
        : {};
  }

  return (
    <Wrapper ref={ref} style={props.style}>
      <TeamCol
        game={game}
        win_pct={win_pct}
        showFullTeamName={showFullTeamName}
      />

      <OddsBtnArray game={game} odds={mainOddSet} is_live={game.is_live} />

      {width > 900 && (
        <>
          {changingBook ? (
            <Row style={{ borderLeft: '1px solid var(--color-text-light)' }}>
              <OddsLoader />
            </Row>
          ) : (
            <OddsBtnArray
              game={game}
              odds={secondOddSet}
              style={{ borderLeft: '1px solid var(--color-text-light)' }}
            />
          )}
        </>
      )}
    </Wrapper>
  );
}

function GameTimeText2(props) {
  const { game } = props;

  if (game.is_complete && !game.is_postponed) {
    return (
      <>
        <TeamLeagueLogo iconSize={24} league={game.league} />
        <b>Final</b>
        <b
          style={{
            textAlign: 'center',
            fontSize:
              ['ATP', 'WTA', 'UFC', 'BELL'].indexOf(game.league) >= 0
                ? '80%'
                : '100%',
          }}
        >
          {game.finish_type && game.finish_type !== '' && (
            <>{game.finish_type}</>
          )}
        </b>
        {game.length_of_match && <small>{game.length_of_match}</small>}
        {game?.sport?.toUpperCase() === 'GOLF' && game.season && (
          <small style={{ textAlign: 'center' }}>{game.season.name}</small>
        )}
      </>
    );
  }
  if (game.is_complete && game.is_postponed) {
    return (
      <>
        <TeamLeagueLogo iconSize={24} league={game.league} />
        <b>PPD</b>
      </>
    );
  }

  if (game.is_live) {
    return (
      <>
        <TeamLeagueLogo iconSize={24} league={game.league} />
        <small
          style={{
            color: 'var(--color-success)',
            textAlign: 'center',
            fontWeight: 'bold',
          }}
        >
          {game.is_live_text ? game.is_live_text.replace('-', '') : ''}
        </small>
        {game.length_of_match && <small>{game.length_of_match}</small>}
        {game?.sport?.toUpperCase() === 'GOLF' && game.season && (
          <small style={{ textAlign: 'center' }}>{game.season.name}</small>
        )}
      </>
    );
  }

  return (
    <>
      <TeamLeagueLogo iconSize={24} league={game.league} />
      <b style={{ textAlign: 'center' }}>{moment(game.date).format('h:mma')}</b>
      {game.length_of_match && <small>{game.length_of_match}</small>}
      {game?.sport?.toUpperCase() === 'GOLF' && game.season && (
        <small style={{ textAlign: 'center' }}>{game.season.name}</small>
      )}
    </>
  );
}

function TeamNameText(props) {
  return (
    <p
      style={
        props.won
          ? {
              opacity: 1,
              fontWeight: 'bold',
              margin: 0,
              flex: 1,
            }
          : {
              fontWeight: 'normal',
              margin: 0,
              flex: 1,
              filter: 'grayscale(1)',
            }
      }
    >
      {props.showFullTeamName ? (
        <span style={{ letterSpacing: '0.01rem' }}>{props.teamNameFull}</span>
      ) : (
        <span>{props.teamNameAbbr}</span>
      )}
    </p>
  );
}

function ScoreWinPctText(props) {
  const reduxProps = useSelector(state => ({
    showWinPercentage: state.settingsReducer.showWinPercentage,
  }));
  const { showWinPercentage } = reduxProps;
  const { game, won, score, win_pct } = props;
  return (
    <>
      {(game.is_live || (game.is_complete && !game.is_postponed)) &&
      game.sport !== 'MMA' ? (
        <p
          style={
            won
              ? {
                  fontWeight: 'bold',
                  margin: 0,
                  flex: 0.2,
                  textAlign: 'center',
                }
              : {
                  fontWeight: 'normal',
                  margin: 0,
                  flex: 0.2,
                  filter: 'grayscale(1)',
                  textAlign: 'center',
                }
          }
        >
          {score}
        </p>
      ) : (
        <>
          {!game.is_complete && !game.is_live && showWinPercentage && (
            <p
              title={'Implied Win Probability'}
              style={{
                color:
                  win_pct >= 50
                    ? 'var(--color-dollar-green)'
                    : 'var(--color-dollar-red)',
                flex: 0.2,
                textAlign: 'center',
                margin: 0,
              }}
            >
              {win_pct !== '' ? win_pct + '%' : ''}
            </p>
          )}
        </>
      )}
    </>
  );
}

function TeamCol(props) {
  const reduxProps = useSelector(state => ({
    showRotationNumber: state.settingsReducer.showRotationNumber,
  }));

  const { showRotationNumber } = reduxProps;

  const { game, win_pct, showFullTeamName } = props;

  const away_lost =
    game.is_complete &&
    (game.winner === game.home_team.name || game.winner === 'Tie');
  const home_lost =
    game.is_complete &&
    (game.winner === game.away_team.name || game.winner === 'Tie');
  const tie_lost = game.is_complete && game.winner !== 'Tie';
  let home_score,
    away_score = 0;
  if (['ATP', 'WTA'].indexOf(game.league) >= 0 && game.period_scores) {
    home_score = game.period_scores['match_score']
      ? game.period_scores['match_score']['home']
      : 0;
    away_score = game.period_scores['match_score']
      ? game.period_scores['match_score']['away']
      : 0;
  } else {
    home_score = game.home_score;
    away_score = game.away_score;
  }

  let topTeam = game.away_team;
  let topLost = away_lost;
  let topPct = win_pct.away_win_pct;
  let topRotNum = game.away_rotation_number;
  let topScore = away_score;
  let botTeam = game.home_team;
  let botLost = home_lost;
  let botPct = win_pct.home_win_pct;
  let botRotNum = game.home_rotation_number;
  let botScore = home_score;
  if (['BUND', 'SERA', 'LIGA', 'LIG1', 'UCL', 'EPL'].includes(game.league)) {
    topTeam = game.home_team;
    topLost = home_lost;
    topPct = win_pct.home_win_pct;
    topRotNum = game.home_rotation_number;
    topScore = home_score;
    botTeam = game.away_team;
    botLost = away_lost;
    botPct = win_pct.away_win_pct;
    botRotNum = game.away_rotation_number;
    botScore = away_score;
  }

  return (
    <Link
      style={{
        flex: ['Golf'].includes(game.sport) ? 1 : 0.75,
        display: 'flex',
        flexFlow: 'column wrap',
        justifyContent: 'center',
        alignItems: 'stretch',
        textDecoration: 'none',
        boxSizing: 'border-box',
        borderRight: '2px solid transparent',
      }}
      to={`/games/${game.id}`}
    >
      <Row style={{ flexFlow: 'row nowrap' }}>
        <Col
          style={{
            flex: ['Golf'].includes(game.sport) ? 0.5 : 0.25,
            minWidth: '75px',
            justifyContent: 'center',
          }}
        >
          <GameTimeText2 game={game} />
        </Col>
        <Col>
          <Row
            style={{
              width: '100%',
              alignItems: 'center',
              justifyContent: 'flex-start',
              boxSizing: 'border-box',
              flexFlow: 'row nowrap',
            }}
          >
            {showRotationNumber && (
              <small style={{ opacity: 0.9 }}>&nbsp;{topRotNum}</small>
            )}

            <TeamLeagueLogo
              iconSize={32}
              displayTeam
              league={game.league}
              team={topTeam.name}
              style={{
                filter: topLost ? 'grayscale(1)' : 'none',
              }}
            />

            <TeamNameText
              won={!topLost}
              teamNameFull={topTeam.full_name}
              teamNameAbbr={topTeam.name}
              rotNum={topRotNum}
              showFullTeamName={showFullTeamName}
            />

            <ScoreWinPctText
              game={game}
              won={!topLost}
              score={topScore}
              win_pct={topPct}
            />
          </Row>

          <Row
            style={{
              width: '100%',
              alignItems: 'center',
              justifyContent: 'flex-start',
              boxSizing: 'border-box',
              flexFlow: 'row nowrap',
            }}
          >
            {showRotationNumber && (
              <small style={{ opacity: 0.9 }}>&nbsp;{botRotNum}</small>
            )}

            <TeamLeagueLogo
              iconSize={32}
              displayTeam
              league={game.league}
              team={botTeam.name}
              style={{
                filter: botLost ? 'grayscale(1)' : 'none',
              }}
            />

            <TeamNameText
              won={!botLost}
              teamNameFull={botTeam.full_name}
              teamNameAbbr={botTeam.name}
              rotNum={botRotNum}
              showFullTeamName={showFullTeamName}
            />

            <ScoreWinPctText
              game={game}
              won={!botLost}
              score={botScore}
              win_pct={botPct}
            />
          </Row>

          {game.sport === 'Soccer' && (
            <Row
              style={{
                width: '100%',
                alignItems: 'center',
                justifyContent: 'flex-start',
                boxSizing: 'border-box',
                flexFlow: 'row nowrap',
              }}
            >
              <IoClose
                fontSize="33px"
                style={{
                  filter: tie_lost ? 'grayscale(1)' : 'none',
                }}
              />

              <TeamNameText
                won={!tie_lost}
                teamNameFull="TIE"
                teamNameAbbr="TIE"
                showFullTeamName={showFullTeamName}
              />

              {!game.is_complete && (
                <ScoreWinPctText
                  game={game}
                  won={!tie_lost}
                  win_pct={win_pct.tie_win_pct}
                />
              )}
            </Row>
          )}
        </Col>
      </Row>
    </Link>
  );
}
