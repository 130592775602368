import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useEffectOnce, useUpdateEffect } from 'react-use';

//components
import { Row, Col } from 'components/generic/Layout';
import { AuthTextInput } from 'components/AuthTextInput';
import CustomSelect from 'components/generic/Select';
import { AuthButton } from 'components/AuthButton';
import { BetlinkWord } from 'components/generic/Logos';
import {
  IoEyeOutline,
  IoEyeOffOutline,
  IoCheckmarkCircleOutline,
} from 'react-icons/io5';

//actions
import { autoSyncLoginUser, twoFA } from 'actions';

export default function AutoSync(props) {
  const dispatch = useDispatch();
  const reduxProps = useSelector(state => ({
    user: state.authReducer.user,
    autoSyncBooksAccounts: state.autosyncReducer.autoSyncBooksAccounts,
    isLoadingAutoSyncLogin: state.autosyncReducer.isLoadingAutoSyncLogin,
    isLoadingAutoSyncLoginSuccess:
      state.autosyncReducer.isLoadingAutoSyncLoginSuccess,
    isLoadingAutoSyncLoginFailure:
      state.autosyncReducer.isLoadingAutoSyncLoginFailure,
    needs2FA: state.autosyncReducer.needs2FA,
    twoFAData: state.autosyncReducer.twoFAData,
    isLoading2FA: state.autosyncReducer.isLoading2FA,
    isLoading2FASuccess: state.autosyncReducer.isLoading2FASuccess,
    isLoading2FAFailure: state.autosyncReducer.isLoading2FAFailure,
    selectedState: state.affiliatesReducer.selectedState,
  }));
  const {
    user,
    isLoadingAutoSyncLogin,
    isLoadingAutoSyncLoginSuccess,
    isLoadingAutoSyncLoginFailure,
    needs2FA,
    twoFAData,
    isLoading2FASuccess,
    isLoading2FAFailure,
    isLoading2FA,
    selectedState,
  } = reduxProps;
  const { book, prefillEmail, theme } = props;
  const state = selectedState ? selectedState : user?.state;

  const [username, setUserName] = useState(prefillEmail || '');
  const [password, setPassword] = useState('');
  const [twoFACode, setTwoFACode] = useState('');
  const [regionVal, setRegionVal] = useState(state);
  const [showPassword, setShowPassword] = useState(false);
  const [expiry, setExpiry] = useState(null);

  useEffect(() => {
    if (book.name === needs2FA) {
      // set initial time
      setExpiry(twoFAData['expires']);
      let count = twoFAData['expires'];
      const timer = setInterval(() => {
        if (count <= 0) {
          clearInterval(timer);
        } else if (count > 0) {
          setExpiry(expiry => expiry - 1);
          count--;
        }
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [needs2FA]);

  const inputStyle = { width: '100%', maxWidth: '512px' };

  if (isLoadingAutoSyncLoginSuccess || isLoading2FASuccess) {
    return (
      <>
        <Col
          style={{
            width: '100%',
            height: '95%',
            justifyContent: 'space-evenly',
            flexFlow: 'column nowrap',
          }}
        >
          <BetlinkWord height={70} />
          <img
            src={book.generic_banner}
            style={{
              width: '30%',
              borderRadius: 5,
              margin: 'var(--space-md)',
            }}
            alt={book.name}
          />
          <IoCheckmarkCircleOutline size={128} color={'var(--color-success)'} />
          <h4 style={{ color: 'var(--color-success)', marginTop: 0 }}>
            {book.name} Account Synced!
          </h4>
          <b
            style={{
              marginTop: 'var(--space-md)',
              width: '50%',
              textAlign: 'center',
            }}
          >
            It may take up to a minute to sync your {book.name} bets. Betstamp
            will automatically sync your bets from {book.name} regularly.
          </b>
          <AuthButton
            containerStyle={{ width: '50%', marginTop: 'var(--space-lg)' }}
            onPress={() => {
              props.dismissModal();
            }}
            colorTheme={theme === 'onboarding' ? 'onbblue' : 'inverted'}
            btnTheme={theme === 'onboarding' ? 'onb' : 'std'}
          >
            DONE
          </AuthButton>
        </Col>
      </>
    );
  }

  if (book.name === needs2FA) {
    return (
      <Col
        style={{
          width: '100%',
          height: '95%',
          justifyContent: 'space-evenly',
          flexFlow: 'column nowrap',
        }}
      >
        <BetlinkWord height={70} />
        <img
          src={book.generic_banner}
          style={{ width: '30%', borderRadius: 5, margin: 'var(--space-md)' }}
          alt={book.name}
        />
        <Col style={{ width: '100%' }}>
          <AuthTextInput
            placeholder={'Two-Factor Authentication Code'}
            containerStyle={{
              width: '100%',
              maxWidth: '300px',
              marginTop: 'var(--space-lg)',
            }}
            inputRowStyle={
              theme === 'onboarding'
                ? { backgroundColor: 'rgba(230,230,230)' }
                : { backgroundColor: 'var(--color-bg)' }
            }
            onChangeText={(text, input) => setTwoFACode(text)}
            required
            colorTheme={theme === 'onboarding' ? 'onb' : undefined}
          />
          <AuthButton
            containerStyle={{
              width: '100%',
              maxWidth: '300px',
              marginTop: 'var(--space-lg)',
              marginBottom: 0,
            }}
            disabled={!twoFACode || expiry === 0}
            onPress={() => {
              dispatch(twoFA({ ...twoFAData, twoFACode }));
            }}
            isLoading={isLoading2FA}
            colorTheme={theme === 'onboarding' ? 'onbblue' : 'primary'}
            btnTheme={theme === 'onboarding' ? 'onb' : 'std'}
            btnStyle={
              (!twoFACode || expiry === 0) && theme === 'onboarding'
                ? { backgroundColor: 'rgba(230,230,230)' }
                : {}
            }
            textStyle={
              (!twoFACode || expiry === 0) && theme === 'onboarding'
                ? { color: 'rgba(180,180,180)' }
                : {}
            }
          >
            Send
          </AuthButton>
          {isLoading2FAFailure && (
            <p
              style={{
                width: '30%',
                textAlign: 'center',
                justifySelf: 'start',
                color: 'var(--color-complement)',
              }}
            >
              Invalid Code
            </p>
          )}
          <p
            style={{
              width: '30%',
              textAlign: 'center',
              justifySelf: 'start',
              color:
                theme === 'onboarding'
                  ? 'var(--color-onb-text)'
                  : 'var(--color-text)',
            }}
          >
            Please enter the 2FA code you received to continue syncing your
            bets.
          </p>
          <Col style={{ marginTop: 'var(--space-md)' }}>
            <b
              style={{
                justifySelf: 'center',
                fontSize: 'var(--text-xxl)',
                color:
                  expiry < twoFAData['expires'] / 2 &&
                  'var(--color-complement)',
              }}
            >
              {expiry}
            </b>
            {expiry === 0 && (
              <b
                style={{
                  marginTop: 'var(--space-xs)',
                  color: 'var(--color-complement)',
                }}
              >
                Session has expired. Please try logging in again.
              </b>
            )}
          </Col>
        </Col>
        <Row
          style={{
            marginTop: 'var(--space-md)',
            width: '50%',
            height: '100%',
            alignItems: 'flex-end',
            color:
              theme === 'var(--color-onb-text)'
                ? undefined
                : 'var(--color-text)',
          }}
        >
          <small
            style={{
              textAlign: 'center',
              color:
                theme === 'onboarding'
                  ? 'var(--color-onb-blue)'
                  : 'var(--color-text)',
            }}
          >
            By clicking Send, you agree to betstamp's{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={'/terms-and-conditions'}
              style={
                theme === 'onboarding'
                  ? { color: 'var(--color-primary)' }
                  : undefined
              }
            >
              Terms and Conditions
            </a>
          </small>
        </Row>
      </Col>
    );
  }

  return (
    <>
      {isLoadingAutoSyncLoginFailure && (
        <Row>
          <b
            style={{
              color: 'var(--color-danger)',
            }}
          >
            {isLoadingAutoSyncLoginFailure.response}
          </b>
        </Row>
      )}
      <Col
        style={{
          width: '100%',
          height: '95%',
          justifyContent: 'space-evenly',
          flexFlow: 'column nowrap',
          color:
            theme === 'onboarding'
              ? 'var(--color-onb-text)'
              : 'var(--color-text)',
        }}
      >
        <BetlinkWord height={70} />
        <img
          src={book.generic_banner}
          style={{
            width: '100%',
            maxWidth: '300px',
            borderRadius: 5,
            margin: 'var(--space-md)',
          }}
          alt={book.name}
        />
        <AuthTextInput
          placeholder={'Email / Username'}
          containerStyle={inputStyle}
          inputRowStyle={
            theme === 'onboarding'
              ? { backgroundColor: 'rgba(230,230,230)' }
              : { backgroundColor: 'var(--color-bg)' }
          }
          onChangeText={(text, input) => setUserName(text)}
          required
          defaultValue={prefillEmail}
          colorTheme={theme === 'onboarding' ? 'onb' : undefined}
        />
        <AuthTextInput
          placeholder={'Password'}
          type={showPassword ? 'text' : 'password'}
          rightIcon={showPassword ? IoEyeOffOutline : IoEyeOutline}
          rightIconOnPress={() => setShowPassword(!showPassword)}
          containerStyle={inputStyle}
          inputRowStyle={
            theme === 'onboarding'
              ? { backgroundColor: 'rgba(230,230,230)' }
              : {
                backgroundColor: 'var(--color-bg)',
                padding: '0 var(--space-sm) 0 0',
              }
          }
          onChangeText={(text, input) => setPassword(text)}
          required
          colorTheme={theme === 'onboarding' ? 'onb' : undefined}
        />
        <Col style={{ ...inputStyle, justifyContent: 'center' }}>
          <CustomSelect
            options={book.states
              .filter(state => !book.autosync_exclude_states.includes(state))
              .map(s => ({
                label: s,
                value: s,
              }))}
            defaultValue={{ label: regionVal, value: regionVal }}
            onChange={opt => {
              setRegionVal(opt.value);
            }}
          />
        </Col>
        <AuthButton
          containerStyle={{
            width: '100%',
            maxWidth: '512px',
            marginTop: 'var(--space-lg)',
          }}
          disabled={!(username && password)}
          btnStyle={
            !(username && password) && theme === 'onboarding'
              ? { backgroundColor: 'rgba(230,230,230)' }
              : {}
          }
          textStyle={
            !(username && password) && theme === 'onboarding'
              ? { color: 'rgba(180,180,180)' }
              : {}
          }
          isLoading={isLoadingAutoSyncLogin}
          onPress={() => {
            dispatch(
              autoSyncLoginUser({
                username: username,
                password: password,
                region: regionVal,
                book: book.name,
              })
            );
          }}
          colorTheme={theme === 'onboarding' ? 'onbblue' : 'inverted'}
          btnTheme={theme === 'onboarding' ? 'onb' : 'std'}
        >
          Sync with {book.name}
        </AuthButton>
        {!!book.affiliate_links && book.affiliate_links[0].state === state && (
          <Row style={{ alignItems: 'flex-start' }}>
            <p>Don't have an account?</p>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={book.affiliate_links[0].link}
              style={{ textDecoration: 'none' }}
            >
              <p
                style={{
                  marginLeft: 'var(--space-sm)',
                  color: 'var(--color-primary)',
                }}
              >
                Sign up
              </p>
            </a>
          </Row>
        )}
        {isLoadingAutoSyncLogin && (
          <Row>
            <b>Verifying Credentials...</b>
          </Row>
        )}
        <Row style={{ marginTop: 'var(--space-md)', width: '50%' }}>
          <small style={{ textAlign: 'center' }}>
            By clicking Login, you agree to betstamp's{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={'/terms-and-conditions'}
              style={
                theme === 'onboarding'
                  ? { color: 'var(--color-primary)' }
                  : undefined
              }
            >
              Terms and Conditions
            </a>
          </small>
        </Row>
      </Col>
    </>
  );
}
