export default {
  link_to_video: '',
  content: [
    {
      type: 'paragraph',
      text:
        'You can add a custom bets for leagues or bet types that are currently not supported. Currently, we support NFL, NBA, MLB, NHL, UFC, Bellator, ATP, WTA, WNBA, CFL, NCAAF, NCAAB, SERA, BUND, MLS, UCL, EPL, LIG1, LIGA, and Golf. We will be adding more sports in the upcoming months.',
    },
    {
      type: 'paragraph',
      text:
        "Custom bets can also be used to track bets types that we don't currently support such as future bets or obscure props.",
    },
    {
      type: 'paragraph',
      text:
        'To track a custom bet, click the blue circle with the plus sign in it found in the bottom right corner of the my picks tab.​',
    },
    {
      type: 'video',
      url:
        'https://betstamp-public.sfo2.cdn.digitaloceanspaces.com/tutorials/custom-bet/custom-bet-video-1.mp4',
    },
    {
      type: 'paragraph',
      text:
        'As shown above, you can input the sport, bet description, and the risk / win amount. Once you track this bet, it will show up in your my picks page so you can follow along on gameday. It will also show up in your analysis and accounting pages and count towards your personal record.',
    },
    {
      type: 'paragraph',
      text:
        'These custom bets will not be verified and do not count towards your public record. However, they can be posted to your public profile by clicking "Add Bet to Public Profile" when tracking the bet as public. Public custom bets can not be deleted, and the edits are tracked. This is so a user can determine the credibility of the bet you tracked (i.e. did you change the bet after grading).',
    },
    {
      type: 'paragraph',
      text: 'You must mark a custom bet as a win or a loss manually.',
    },
    {
      type: 'video',
      url:
        'https://betstamp-public.sfo2.cdn.digitaloceanspaces.com/tutorials/custom-bet/custom-bet-video-2.mp4',
    },
    {
      type: 'paragraph',
      text:
        'Since these bets are not supported yet, you must mark them as wins or losses or they will remain in your pending bets.',
    },
  ],
};
