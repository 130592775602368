import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { getLeaderboard } from 'actions';

//components
import DateRange from 'components/generic/DateRange';
import { Row, Col } from 'components/generic/Layout';
import UsersListItem from 'components/UsersListItem';
import ErrorDisplay from 'components/generic/ErrorDisplay';
import ActivityIndicator from 'components/generic/ActivityIndicator';
import CustomSelect from 'components/generic/Select';

// utils
import { LEAGUES } from 'utils';

export default function Leaderboard() {
  let today = moment();
  let lastWeekDate = moment(today).subtract(7, 'days');
  const [followingOnly, setFollowingOnly] = useState(false);
  const [sport, setSport] = useState(null);
  const [sortBy, setSortBy] = useState('roi');
  const [date1, setDate1] = useState(lastWeekDate);
  const [date2, setDate2] = useState(today);
  const [sampleSize, setSampleSize] = useState(10);

  useEffect(() => {
    dispatch(
      getLeaderboard(50, sport, sortBy, date1, date2, followingOnly, 10)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // redux props
  const reduxProps = useSelector(state => ({
    leaderboard: state.publicUserReducer.leaderboard,
    fetchError: state.publicUserReducer.fetchError,
    isLoading: state.publicUserReducer.isLoading,
  }));

  // redux dispatch
  const dispatch = useDispatch();

  const { leaderboard, fetchError, isLoading } = reduxProps;

  return (
    <div
      className="container"
      style={{ flex: 1, paddingBottom: 'var(--space-xxxl)' }}
    >
      <h3>Leaderboard - Top 10 - Past Week</h3>
      <br />
      {/* <div
        style={{
          padding: 'var(--space-sm)',
          backgroundColor: 'var(--color-fg)',
          borderRadius: 'var(--std-border-radius)',
          boxSizing: 'border-box',
        }}
      >
        <DateRange
          placeholder="Past Week"
          getItemsInRange={(d1, d2) => {
            setDate1(d1);
            setDate2(d2);
            dispatch(
              getLeaderboard(
                50,
                sport,
                sortBy,
                d1,
                d2,
                followingOnly,
                sampleSize
              )
            );
          }}
          defaultStartDate={date1}
          defaultEndDate={date2}
        />
      </div> */}

      <br />

      <Row>
        <Col style={{ margin: '0 var(--space-xs)' }}>
          <CustomSelect
            value={
              sport
                ? { value: sport, label: sport }
                : { value: sport, label: 'All' }
            }
            onChange={opt => {
              setSport(opt.value);
              dispatch(
                getLeaderboard(
                  50,
                  opt.value,
                  sortBy,
                  date1,
                  date2,
                  followingOnly,
                  sampleSize
                )
              );
            }}
            options={[{ label: 'All', value: null }].concat(
              LEAGUES.map(league => ({
                label: league.league,
                value: league.league,
                color: null,
              }))
            )}
            placeholder={'Sport'}
          />
        </Col>
        <Col style={{ margin: '0 var(--space-xs)' }}>
          <CustomSelect
            value={{ value: sortBy, label: sortBy.toUpperCase() }}
            onChange={opt => {
              setSortBy(opt.value);
              dispatch(
                getLeaderboard(
                  50,
                  sport,
                  opt.value,
                  date1,
                  date2,
                  followingOnly,
                  sampleSize
                )
              );
            }}
            options={[
              {
                label: 'ROI',
                value: 'roi',
              },
              {
                label: 'CLV',
                value: 'clv',
              },
            ]}
          />
        </Col>
        <Col style={{ margin: '0 var(--space-xs)' }}>
          <CustomSelect
            value={{ label: sampleSize, value: sampleSize }}
            onChange={opt => {
              setSampleSize(opt.value);
              dispatch(
                getLeaderboard(
                  50,
                  sport,
                  sortBy,
                  date1,
                  date2,
                  followingOnly,
                  opt.value
                )
              );
            }}
            options={[
              {
                label: '5',
                value: 5,
              },
              {
                label: '10',
                value: 10,
              },
              {
                label: '25',
                value: 25,
              },
              {
                label: '50',
                value: 50,
              },
              {
                label: '100',
                value: 100,
              },
              {
                label: '200',
                value: 200,
              },
              {
                label: '500',
                value: 500,
              },
            ]}
          />
        </Col>
        <Col style={{ margin: '0 var(--space-xs)' }}>
          <CustomSelect
            value={
              followingOnly
                ? { label: 'Following', value: 'Following' }
                : { label: 'All', value: 'All' }
            }
            onChange={itemValue => {
              const fo = itemValue.label === 'Following';
              setFollowingOnly(fo);
              dispatch(
                getLeaderboard(50, sport, sortBy, date1, date2, fo, sampleSize)
              );
            }}
            options={[
              {
                label: 'All',
                value: 'All',
              },
              {
                label: 'Following',
                value: 'Following',
              },
            ]}
          />
        </Col>
      </Row>

      <div>
        {leaderboard.length > 0 && (
          <small>
            <i>*Sample size of {sampleSize} bets</i>
          </small>
        )}
        {fetchError ? (
          <ErrorDisplay
            error={fetchError}
            message={'getting leaderboards! Check back later.'}
            retry={() => {
              dispatch(getLeaderboard());
            }}
          />
        ) : (
          <>
            {isLoading ? (
              <div style={{ margin: 'var(--space-xxl) 0' }}>
                <ActivityIndicator size={2} />
              </div>
            ) : (
              <>
                {leaderboard.length === 0 ? (
                  <p style={{ textAlign: 'center' }}>
                    No users fit the filter parameters!
                  </p>
                ) : (
                  <>
                    {leaderboard.map(item => {
                      return (
                        <UsersListItem
                          key={`leaderboard-list-item-${item.id}`}
                          user={item}
                          showNotificationsIcon={false}
                          showFollowButton={true}
                          showStats={[sortBy.toLowerCase()]}
                        />
                      );
                    })}
                  </>
                )}
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
}
