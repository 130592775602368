import moment from 'moment';
export default function genGame() {
  return {
    away_team: {
      full_name: 'Toronto Raptors',
      name: 'TOR',
    },
    home_team: {
      full_name: 'Houston Rockets',
      name: 'HOU',
    },
    date: moment().set('hour', 19).set('minute', 0).toISOString(true),
    id: 354,
    is_complete: false,
    is_live: false,
    is_live_text: null,
    is_postponed: false,
    league: 'NBA',
    period_scores: null,
    season: null,
    sport: 'Basketball',
    winner: null,
  };
}
