import { combineEpics } from 'redux-observable';

import getGameData from 'epics/game/getGameData.epic';
import getFullTimeOdds from 'epics/game/getFullTimeOdds.epic';
import getFullTimeOddsNext from 'epics/game/getFullTimeOddsNext.epic';
import getFullTimeBA from 'epics/game/getFullTimeBA.epic';
import getGamePropList from 'epics/game/getGamePropList.epic';
import getGamePropListNext from 'epics/game/getGamePropListNext.epic';
import getGamePropOptions from 'epics/game/getGamePropOptions.epic';
import getGameProps from 'epics/game/getGameProps.epic';
import getGamePropsNext from 'epics/game/getGamePropsNext.epic';
import get1HalfOdds from 'epics/game/get1HalfOdds.epic';
import get1HalfOddsNext from 'epics/game/get1HalfOddsNext.epic';
import get1HalfBA from 'epics/game/get1HalfBA.epic';
import getPlayerPropPlayerList from 'epics/game/getPlayerPropPlayerList.epic';
import getPlayerPropPlayerListNext from 'epics/game/getPlayerPropPlayerListNext.epic';
import getPlayerPropPropList from 'epics/game/getPlayerPropPropList.epic';
import getPlayerPropPropListNext from 'epics/game/getPlayerPropPropListNext.epic';
import getPlayerProps from 'epics/game/getPlayerProps.epic';
import getPlayerPropsNext from 'epics/game/getPlayerPropsNext.epic';
import getPlayerPropBA from 'epics/game/getPlayerPropBA.epic';
import getLiveBA from 'epics/game/getLiveBA.epic';
import getLiveOdds from 'epics/game/getLiveOdds.epic';
import getLiveOddsNext from 'epics/game/getLiveOddsNext.epic';
import get2HalfOdds from 'epics/game/get2HalfOdds.epic';
import get2HalfOddsNext from 'epics/game/get2HalfOddsNext.epic';
import get2HalfBA from 'epics/game/get2HalfBA.epic';
import getLineHistory from 'epics/game/getLineHistory.epic';
import getLineHistoryNext from 'epics/game/getLineHistoryNext.epic';
import getLineHistoryGraph from 'epics/game/getLineHistoryGraph.epic';
import getPlayerBoxscores from 'epics/game/getPlayerBoxscores.epic';
import getPlayerBoxscoresNext from 'epics/game/getPlayerBoxscoresNext.epic';

export default combineEpics(
  getGameData,
  getFullTimeOdds,
  getFullTimeOddsNext,
  getFullTimeBA,
  getGamePropList,
  getGamePropListNext,
  getGamePropOptions,
  getGameProps,
  getGamePropsNext,
  get1HalfOdds,
  get1HalfOddsNext,
  get1HalfBA,
  getPlayerPropPlayerList,
  getPlayerPropPlayerListNext,
  getPlayerPropPropList,
  getPlayerPropPropListNext,
  getPlayerProps,
  getPlayerPropBA,
  getPlayerPropsNext,
  getLiveBA,
  getLiveOdds,
  getLiveOddsNext,
  get2HalfOdds,
  get2HalfOddsNext,
  get2HalfBA,
  getLineHistory,
  getLineHistoryNext,
  getLineHistoryGraph,
  getPlayerBoxscores,
  getPlayerBoxscoresNext
);
