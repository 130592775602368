export const GET_ODDSCOMP = 'GET_ODDSCOMP';
export const GET_ODDSCOMP_SUCCESS = 'GET_ODDSCOMP_SUCCESS';
export const GET_ODDSCOMP_FAILURE = 'GET_ODDSCOMP_FAILURE';

export const GET_ODDSCOMP_BA = 'GET_ODDSCOMP_BA';
export const GET_ODDSCOMP_BA_SUCCESS = 'GET_ODDSCOMP_BA_SUCCESS';
export const GET_ODDSCOMP_BA_FAILURE = 'GET_ODDSCOMP_BA_FAILURE';

export const GET_ODDSCOMP_GAMES = 'GET_ODDSCOMP_GAMES';
export const GET_ODDSCOMP_GAMES_SUCCESS = 'GET_ODDSCOMP_GAMES_SUCCESS';
export const GET_ODDSCOMP_GAMES_FAILURE = 'GET_ODDSCOMP_GAMES_FAILURE';

export const SET_ODDSCOMP_SIZE = 'SET_ODDSCOMP_SIZE';

export const SET_ODDSCOMP_ODD_TYPES = 'SET_ODDSCOMP_ODD_TYPES';

export const getOddscomp = ({ game_ids, book_ids, period, since }) => ({
  type: GET_ODDSCOMP,
  game_ids,
  book_ids,
  period,
  since,
});

export const getOddscompSuccess = payload => ({
  type: GET_ODDSCOMP_SUCCESS,
  payload,
});

export const getOddscompFailure = error => ({
  type: GET_ODDSCOMP_FAILURE,
  error,
});

export const getOddsCompBA = ({ game_ids, period }) => ({
  type: GET_ODDSCOMP_BA,
  game_ids,
  period,
});

export const getOddsCompBASuccess = payload => ({
  type: GET_ODDSCOMP_BA_SUCCESS,
  payload,
});

export const getOddsCompBAFailure = error => ({
  type: GET_ODDSCOMP_BA_FAILURE,
  error,
});

export const getOddsCompGames = ({ game_ids }) => ({
  type: GET_ODDSCOMP_GAMES,
  game_ids,
});

export const getOddsCompGamesSuccess = payload => ({
  type: GET_ODDSCOMP_GAMES_SUCCESS,
  payload,
});

export const getOddsCompGamesFailure = error => ({
  type: GET_ODDSCOMP_GAMES_FAILURE,
  error,
});

export const setOddsCompSize = size => ({
  type: SET_ODDSCOMP_SIZE,
  size,
});

export const setOddsCompOddTypes = oddTypes => ({
  type: SET_ODDSCOMP_ODD_TYPES,
  oddTypes,
});
