import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';

export default function useUserGroups() {
  const fetchGroups = useQuery({
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 10, // 10 minutes
    queryKey: [{ endpoint: 'user-permission-groups' }],
  });

  return { ...fetchGroups, ...extractBools(fetchGroups) };
}

function extractBools(fetchGroups) {
  if (fetchGroups?.data?.groups) {
    let newBools = {
      isOnboardingHost: false,
      isOnboardingFollowUpHost: false,
      isOnboardingSalesTeam: false,
      isOnboardingDebug: false,
      isExecutive: false,
      isDeveloper: false,
      isFullDeveloper: false,
      isWagerOnly: false,
      isCustomerSupport: false,
      isVA: false,
      isAffiliateManager: false,
      isInfluencerPage: false,
      isEmailMarketing: false,
      isDataTeam: false,
      isUsersViewChange: false,
      isReferralCodeOnly: false,
      isAffiliateDashboard: false,
      isAffiliateEarnings: false,
      isAffiliateReports: false,
    };
    for (const group of fetchGroups?.data.groups) {
      if (group === 'Onboarding Host') {
        newBools.isOnboardingHost = true;
      }
      if (group === 'Onboarding Follow Up Host') {
        newBools.isOnboardingFollowUpHost = true;
      }
      if (group === 'Onboarding Sales Team') {
        newBools.isOnboardingSalesTeam = true;
      }
      if (group === 'Onboarding Debug') {
        newBools.isOnboardingDebug = true;
      }
      if (group === 'Executive') {
        newBools.isExecutive = true;
      }
      if (group === 'Affiliate Manager') {
        newBools.isAffiliateManager = true;
      }
      if (group === 'Customer Support') {
        newBools.isCustomerSupport = true;
      }
      if (group === 'Developer') {
        newBools.isDeveloper = true;
      }
      if (group === 'Full Developer') {
        newBools.isFullDeveloper = true;
      }
      if (group === 'Data Team') {
        newBools.isDataTeam = true;
      }
      if (group === 'Email Marketing') {
        newBools.isEmailMarketing = true;
      }
      if (group === 'Influencer Page') {
        newBools.isInfluencerPage = true;
      }
      if (group === 'Referral Code Only') {
        newBools.isReferralCodeOnly = true;
      }
      if (group === 'VA') {
        newBools.isVA = true;
      }
      if (group === 'Users View / Change') {
        newBools.isUsersViewChange = true;
      }
      if (group === 'Wager Only') {
        newBools.isWagerOnly = true;
      }
      if (group === 'Affiliate Dashboard') {
        newBools.isAffiliateDashboard = true;
      }
      if (group === 'Affiliate Earnings') {
        newBools.isAffiliateEarnings = true;
      }
      if (group === 'Affiliate Reports') {
        newBools.isAffiliateReports = true;
      }
    }
    return newBools;
  }
  return {};
}
