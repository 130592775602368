import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useUpdateEffect } from 'react-use';
import { IoCaretDown, IoCaretUp } from 'react-icons/io5';

// utils
import {
  toAmericanOdds,
  roundNumber,
  roundOdds,
  calculateBetPayout,
  calculateRiskRequired,
  betLine,
} from 'utils';

// components
import { AuthButton } from 'components/AuthButton';

// actions
import { addBet, setTourStep } from 'actions';

export default function OddBtn2({
  odd,
  displayOnly,
  containerStyle,
  onLayout,
  btnStyle,
  btnTheme,
  textStyle,
  oddscomp,
  textOverride,
  playerPropShowNumber,
}) {
  const dispatch = useDispatch();
  const reduxProps = useSelector(state => ({
    user: state.authReducer.user,
    selectedOdds: state.betslipReducer.selectedOdds,
    mode: state.betslipReducer.mode,
    parlayValues: state.betslipReducer.parlayValues,
    currentTourStep: state.tourReducer.currentTourStep,
    riskOrBaseOverride: state.betslipReducer.riskOrBaseOverride,
    betSizeOverride: state.betslipReducer.betSizeOverride,
  }));
  const {
    user,
    selectedOdds,
    mode,
    parlayValues,
    currentTourStep,
    riskOrBaseOverride,
    betSizeOverride,
  } = reduxProps;

  let odds_preference = user ? user.odds_preference : 0;

  // to determine which specifc odd is selected
  let selectedID = `${odd.book ? odd.book.id || odd.book.name : 'n/a'}-${
    odd.game?.id
  }-${odd.side}-${odd.type_name}-${odd.bestAvailable}-${odd.is_live}-${
    odd.prop_type
  }-${odd.prop_name}-${odd?.player?.id}-${odd.period}-${odd.is_future}`;
  if (odd.forceNotVerified) {
    selectedID += odd.oddID;
  }

  // to determine which other odds should be disabled (disabled all away ml once any one away ml is selected)
  // this is more like "switch to new clicked odd" than disabled
  let disabledID = `${odd.game?.id}-${odd.side}-${odd.type_name}-${odd.is_live}-${odd.prop_type}-${odd?.player?.id}-${odd.period}`;

  // to determine what can be parlayed together
  let pCompatTypeName = '';
  if (odd.type_name === 'Moneyline' || odd.type_name === 'Spread') {
    pCompatTypeName = 'mlspread';
  } else {
    pCompatTypeName = odd.type_name?.toLowerCase();
    if (odd.type_name === 'Player Prop' || odd.type_name === 'Game Prop') {
      pCompatTypeName += `-${odd.prop_type}-${odd?.player?.id}`;
    }
  }

  let parlayCompatID = `pcid-${odd.game?.id}-${pCompatTypeName}`;

  const _addBet = () => {
    const gameNotStarted =
      new Date(odd.game?.date) > new Date() &&
      !odd.game.is_complete &&
      !odd.game.is_live;

    let is_verified =
      (odd.is_future && new Date(odd.season.start_date) > new Date()) ||
      gameNotStarted ||
      (odd.period === 'FT' && odd.game.is_live && odd.is_live) ||
      (odd.period === '2H' && odd.game.is_live_text.includes('Halftime'));

    if (currentTourStep?.id === 'games9') {
      is_verified = true;
    }

    if (odd.forceNotVerified) is_verified = false;

    const is_public = is_verified || odd.is_live;

    let actualRiskOrBase = null;
    if (riskOrBaseOverride) {
      actualRiskOrBase = riskOrBaseOverride;
    } else if (user && user.risk_or_base) {
      actualRiskOrBase = user.risk_or_base.toLowerCase();
    }

    let actualBetSize = null;
    if (betSizeOverride) actualBetSize = betSizeOverride;
    else if (user && user.bet_size) actualBetSize = user.bet_size;

    let risk_amount,
      result = 0;

    if (actualRiskOrBase === 'risk' || odd.odds >= 2) {
      risk_amount = actualBetSize;
      result = calculateBetPayout(risk_amount, odd.odds).replace('.00', '');
    } else {
      result = actualBetSize;
      risk_amount = calculateRiskRequired(result, odd.odds);
    }

    const startingComp = user.competitions ? user.competitions[0] : undefined;

    let bet = {
      oddID: odd.oddID,
      selectedID: selectedID,
      disabledID: disabledID,
      parlayCompatID: parlayCompatID,
      odds: odd.odds,
      number: odd.number,
      type_name: odd.type_name,
      side: odd.side,
      period: odd.period,
      prop_type: odd.prop_type,
      player: { id: odd?.player?.id, name: odd?.player?.name },
      book: odd.book,
      game: odd.is_future
        ? null
        : {
            id: odd.game.id,
            league: odd.game.league,
            home_team: odd.game.home_team,
            away_team: odd.game.away_team,
            date: odd.game.date,
            sport: odd.game.sport,
            is_live: odd.game.is_live,
            is_complete: odd.game.is_complete,
          },
      risk_amount: parseFloat(risk_amount),
      is_verified: is_verified,
      result: parseFloat(result),
      changes: {},
      tags: [],
      breakdown: '',
      is_live: odd.is_live,
      subtype: odd.subtype,
      is_public: is_public,
      won: odd.won,
      bet_type: 'BET',
      is_future: odd.is_future,
      season_id: odd.season_id,
      season: odd?.season,
      competition: startingComp?.id,
    };
    dispatch(addBet(bet));
    if (currentTourStep?.id === 'games9') {
      dispatch(setTourStep('games10'));
    }
  };

  let disabled = odd ? !odd.odds : true;
  if (
    mode === 'parlay' &&
    parlayValues.book &&
    odd.book &&
    odd.book.id !== parlayValues.book.id
  ) {
    disabled = true;
  }

  let disabledOddBGOverride = {};
  if (!odd.odds) disabledOddBGOverride = { backgroundColor: 'transparent' };

  const [lastOdds, setLastOdds] = useState(odd.odds);
  const [lastNumber, setLastNumber] = useState(odd.number);
  const [flash, setFlash] = useState({});
  const flashTimeout = oddscomp ? 10000 : 5000;
  useUpdateEffect(() => {
    if (odd.odds !== lastOdds || odd.number !== lastNumber) {
      if (odd.side === 'Over' && odd.number > lastNumber) {
        setFlash({
          style: oddscomp ? {} : { color: 'var(--color-danger)' },
          contStyle: oddscomp
            ? { background: 'var(--color-danger-transparent)' }
            : {},
          icon: IoCaretDown,
        });
      } else if (odd.side === 'Over' && odd.number < lastNumber) {
        setFlash({
          style: oddscomp ? {} : { color: 'var(--color-dollar-green)' },
          contStyle: oddscomp
            ? { background: 'var(--color-dollar-green-transparent)' }
            : {},
          icon: IoCaretUp,
        });
      } else if (odd.number > lastNumber) {
        setFlash({
          style: oddscomp ? {} : { color: 'var(--color-dollar-green)' },
          contStyle: oddscomp
            ? { background: 'var(--color-dollar-green-transparent)' }
            : {},
          icon: IoCaretUp,
        });
      } else if (odd.number < lastNumber) {
        setFlash({
          style: oddscomp ? {} : { color: 'var(--color-danger)' },
          contStyle: oddscomp
            ? { background: 'var(--color-danger-transparent)' }
            : {},
          icon: IoCaretDown,
        });
      } else if (odd.odds > lastOdds) {
        setFlash({
          style: oddscomp ? {} : { color: 'var(--color-dollar-green)' },
          contStyle: oddscomp
            ? { background: 'var(--color-dollar-green-transparent)' }
            : {},
          icon: IoCaretUp,
        });
      } else if (odd.odds < lastOdds) {
        setFlash({
          style: oddscomp ? {} : { color: 'var(--color-danger)' },
          contStyle: oddscomp
            ? { background: 'var(--color-danger-transparent)' }
            : {},
          icon: IoCaretDown,
        });
      }
      setLastOdds(odd.odds);
      setLastNumber(odd.number);

      setTimeout(() => {
        setFlash({});
      }, flashTimeout);
    }
  });

  return (
    <AuthButton
      leftIcon={flash.icon && !oddscomp ? flash.icon : null}
      leftIconColor={flash?.style?.color}
      leftIconSize={oddscomp ? 10 : 20}
      // right icon here to keep things centered
      rightIcon={flash.icon && !oddscomp ? flash.icon : null}
      rightIconColor="transparent"
      rightIconSize={oddscomp ? 10 : 20}
      containerStyle={{
        flex: 1,
        marginTop: 0,
        marginBottom: 0,
        opacity: odd?.game?.is_complete && odd.odds ? (odd.won ? 1 : 0.75) : 1,
        ...flash?.contStyle,
        ...containerStyle,
      }}
      btnStyle={{
        flex: 1,
        minHeight:
          odd.type_name === 'Game Prop' || odd.type_name === 'Player Prop'
            ? 36
            : 44,
        padding: 0,
        ...disabledOddBGOverride,
        ...btnStyle,
      }}
      textStyle={{ textAlign: 'center', ...textStyle, ...flash.style }}
      colorTheme={
        selectedOdds[selectedID]
          ? 'inverted'
          : odd.is_live
          ? 'success'
          : 'primary'
      }
      btnTheme={btnTheme || undefined}
      onPress={displayOnly ? null : () => _addBet()}
      disabled={disabled}
      title={
        !odd.odds
          ? ''
          : disabled && mode === 'parlay'
          ? 'This bet cannot be parlayed'
          : betLine(
              {
                odds: odd.odds,
                number: odd.number,
                type_name: odd.type_name,
                side: odd.side,
                is_fullgame: odd.is_fullgame,
                prop_type: odd.prop_type,
                game: odd.game,
                is_live: odd.is_live,
                period: odd.period,
                is_future: odd.is_future,
                player: odd.player,
              },
              user?.odds_preference
            ) +
            '\n' +
            odd?.book?.name
      }
    >
      {!!textOverride ? (
        textOverride
      ) : (
        <>
          {odd.odds ? (
            <>
              <span
                style={{ color: 'var(--color-text)', fontWeight: 'normal' }}
              >
                {(odd.type_name === 'Total' || playerPropShowNumber) &&
                  `${odd.side[0].toLowerCase()}`}

                {odd.type_name === 'Game Prop' &&
                  odd.subtype !== 'Total' &&
                  `${odd.side} `}

                {odd.number !== null &&
                  odd.number !== undefined &&
                  (odd.type_name !== 'Player Prop' || playerPropShowNumber) &&
                  odd.type_name !== 'Game Prop' &&
                  `${
                    odd.number > 0 && odd.type_name === 'Spread'
                      ? `+${roundNumber(odd.number)}`
                      : roundNumber(odd.number)
                  }\n`}
              </span>
              {odds_preference ? roundOdds(odd.odds) : toAmericanOdds(odd.odds)}
              {odd.bestAvailable && odd?.book?.name && (
                <>
                  <br />
                  <small
                    style={{
                      color: selectedOdds[selectedID]
                        ? 'white'
                        : 'var(--color-text)',
                      fontWeight: 'normal',
                      lineHeight: 1,
                      opacity: 0.9,
                    }}
                  >
                    {odd.book.name}
                  </small>
                </>
              )}
            </>
          ) : (
            '—'
          )}
        </>
      )}
    </AuthButton>
  );
}
