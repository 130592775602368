import React from 'react';
import moment from 'moment';

// components
import { Row, Col } from 'components/generic/Layout';
import TeamLeagueLogo from 'components/TeamLeagueLogo';
import PeriodScores from 'components/PeriodScores';

export default function GameStats(props) {
  const {
    league,
    away_score,
    away_team,
    home_score,
    home_team,
    date,
    is_live,
    is_live_text,
    is_complete,
    finish_type,
    winner,
    sport
  } = props.game;
  const { teamIconSize, leagueIconSize, smallScore, usePeriodScores } = props;

  const awayWon = is_complete ? away_score > home_score : true;
  const homeWon = is_complete ? home_score > away_score : true;

  return (
    <>
      <Row
        style={{
          flex: 0,
          width: '100%',
          justifyContent: 'center',
          alignItems: 'center',
          padding: 'var(--space-sm)',
          paddingBottom: 0,
          boxSizing: 'border-box',
        }}
      >
        <TeamLeagueLogo
          iconSize={leagueIconSize ? leagueIconSize : 32}
          league={league}
        />
        {is_live && is_live_text ? (
          <h6
            style={{
              margin: 0,
              color: 'var(--color-success)',
              fontWeight: 'bold',
            }}
          >
            {is_live_text}
          </h6>
        ) : (
          <>{moment(date).format('dddd MMMM DD @ h:mma')}</>
        )}
      </Row>
      {sport === 'MMA' && is_complete && (
        <Row>
          <b>
            {winner} {finish_type}
          </b>
        </Row>
      )}
      {usePeriodScores ? (
        <PeriodScores
          hideLiveText={props.game?.is_live}
          game={props.game}
          style={{ border: 'none' }}
        />
      ) : (
        <Row
          style={{
            flex: 0,
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: smallScore ? 0 : 'var(--space-sm)',
            paddingTop: 0,
            boxSizing: 'border-box',
          }}
        >
          <Col>
            <Row
              style={{
                justifyContent: 'flex-start',
                alignItems: 'center',
                flexFlow: 'row wrap',
              }}
            >
              <TeamLeagueLogo
                iconSize={teamIconSize ? teamIconSize : 50}
                displayTeam
                league={league}
                team={away_team.name}
                style={{
                  flex: 0,
                  margin: smallScore
                    ? '0 var(--space-xxs)'
                    : '0 var(--space-sm)',
                }}
              />
              <h6
                style={{
                  margin: 0,
                  fontWeight:
                    sport === 'MMA' &&
                    is_complete &&
                    winner === away_team.full_name
                      ? 'bold'
                      : 'normal',
                }}
              >
                {away_team.full_name}
              </h6>
            </Row>
          </Col>

          {sport !== 'MMA' && (
            <Col style={{ flex: smallScore ? 0.33 : 0.5 }}>
              {smallScore ? (
                <h6 style={{ margin: 0 }}>
                  {is_live || is_complete ? (
                    <>
                      <span style={{ opacity: awayWon ? 1 : 0.7 }}>
                        {away_score}
                      </span>
                      &nbsp;&nbsp;-&nbsp;&nbsp;
                      <span style={{ opacity: homeWon ? 1 : 0.7 }}>
                        {home_score}
                      </span>
                    </>
                  ) : (
                    <>vs</>
                  )}
                </h6>
              ) : (
                <>
                  <h4 style={{ margin: 0 }}>
                    {is_live || is_complete ? (
                      <>
                        <span style={{ opacity: awayWon ? 1 : 0.7 }}>
                          {away_score}
                        </span>
                        &nbsp;&nbsp;-&nbsp;&nbsp;
                        <span style={{ opacity: homeWon ? 1 : 0.7 }}>
                          {home_score}
                        </span>
                      </>
                    ) : (
                      <>vs</>
                    )}
                  </h4>
                </>
              )}
            </Col>
          )}

          <Col>
            <Row
              style={{
                justifyContent: 'flex-end',
                alignItems: 'center',
                flexFlow: 'row wrap',
              }}
            >
              <h6
                style={{
                  margin: 0,
                  fontWeight:
                    sport === 'MMA' &&
                    is_complete &&
                    winner === home_team.full_name
                      ? 'bold'
                      : 'normal',
                }}
              >
                {home_team.full_name}
              </h6>
              <TeamLeagueLogo
                iconSize={teamIconSize ? teamIconSize : 50}
                displayTeam
                league={league}
                team={home_team.name}
                style={{
                  flex: 0,
                  margin: smallScore
                    ? '0 var(--space-xxs)'
                    : '0 var(--space-sm)',
                }}
              />
            </Row>
          </Col>
        </Row>
      )}
    </>
  );
}
