import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useEffectOnce } from 'react-use';
import { LinkButton } from 'components/AuthButton';

// utils
import { LEAGUES } from 'utils';

// components
import { Row, Col, Grid } from 'components/generic/Layout';
import { BetstampWordWhiteText } from 'components/generic/Logos';
import Footer from 'components/Footer';
import { BetlinkStamp } from 'components/generic/Logos';

// actions
//import { getAllBooks } from 'actions';

// images
import MainScreens from 'images/landingpage/main-screens.png';
import AppStoreImg from 'images/download_app_store.png';
import PlayStoreImg from 'images/download_play_store.png';
import FollowResults from 'images/landingpage/follow-results.png';

const MassiveText = styled.h1`
  font-size: 5rem;
  margin: 0;
  margin-top: var(--space-lg);
  @media only screen and (max-width: 1366px) {
    font-size: 4rem;
  }
  @media only screen and (max-width: 625px) {
    font-size: 3rem;
  }
  @media only screen and (max-width: 320px) {
    font-size: 2.5rem;
  }
`;

const MAXWIDTH = '1280px';

export default function Home() {
  const reduxProps = useSelector(state => ({
    userToken: state.authReducer.userToken,
    isLoading: state.authReducer.isLoading,
  }));
  const { userToken, isLoading } = reduxProps;

  const history = useHistory();

  useEffectOnce(() => {
    if (!isLoading) {
      if (userToken) {
        history.push('games');
      } else {
        // this might not work on safari, they don't allow redirects in use effect
        window.location.href = 'https://betstamp.com/app';
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);
  return (
    <>
      <Row
        style={{
          height: '12vh',
          backgroundColor: 'var(--color-primary)',
          color: 'white',
        }}
      >
        <BetstampWordWhiteText
          style={{ alignSelf: 'center' }}
          height={'100%'}
        />
      </Row>
      <Row
        style={{
          minHeight: '88vh',
          backgroundColor: 'var(--color-primary)',
          color: 'white',
        }}
      >
        <Col
          style={{
            flex: '1 0 300px',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            padding: '0 var(--space-sm)',
          }}
        >
          <MassiveText>Bet. Like a Pro.</MassiveText>
          <h3>
            Use the same app as the professionals to find bets, organize your
            accounts, and track your success.
          </h3>
          <LinkButton
            to="/signup"
            colorTheme="white"
            btnStyle={{
              padding: 'var(--space-sm) var(--space-md)',
              borderRadius: '64px',
            }}
            textStyle={{ fontSize: 'var(--text-lg)' }}
            linkStyle={{ alignSelf: 'stretch', maxWidth: '350px' }}
          >
            Sign Up for Free
          </LinkButton>
          <Row style={{ marginTop: 'var(--space-md)', gap: 'var(--space-sm)' }}>
            <a href="https://apps.apple.com/us/app/id1525948689">
              <img
                src={AppStoreImg}
                alt="Download from the App Store"
                style={{ width: '100%', height: 'auto' }}
              />
            </a>
            <a href="https://play.google.com/store/apps/details?id=app.cashew.betstamp">
              <img
                src={PlayStoreImg}
                alt="Download from the Play Store"
                style={{ width: '100%', height: 'auto' }}
              />
            </a>
          </Row>
        </Col>
        <Col
          style={{
            flex: '1 0 300px',
            justifyContent: 'flex-start',
            padding: 'var(--space-sm)',
          }}
        >
          <img
            src={MainScreens}
            style={{ width: '100%', height: 'auto' }}
            alt="betstamp screenshots"
          />
        </Col>
      </Row>
      <div style={{ backgroundColor: 'var(--color-night-bg)' }}>
        <Row
          style={{
            minHeight: '30vh',
            backgroundColor: 'var(--color-night-bg)',
            color: 'var(--color-night-text)',
            maxWidth: MAXWIDTH,
            margin: '0 auto',
            padding: 'var(--space-sm) var(--space-xs)',
            paddingTop: 'var(--space-xl)',
          }}
        >
          <Col
            style={{
              flex: '1 0 300px',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
            }}
          >
            <h4 style={{ marginTop: 0 }}>
              Betstamp shows you the odds from every sportsbooks in one spot.
            </h4>
            <p>
              Always get the best value when betting.
              <br />
              The average bettor makes an additional $1033 per year using
              betstamp.
            </p>
          </Col>
          <Col
            style={{
              flex: '1 0 300px',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
            }}
          >
            <h6 style={{ marginTop: 0 }}>Supported Leagues</h6>
            <Grid
              style={{
                marginTop: 'var(--space-sm)',
                width: '100%',
                gridTemplateColumns: 'repeat(auto-fit, 42px)',
                rowGap: '1rem',
                columnGap: '2rem',
              }}
            >
              {LEAGUES.map(l => (
                <Col key={`home-page-odds-comparison-league-${l.league}`}>
                  <img
                    src={`https://betstamp-public.sfo2.cdn.digitaloceanspaces.com/league_logos/${l.league}.png`}
                    width="100%"
                    height="auto"
                    alt={l.league}
                  />
                </Col>
              ))}
            </Grid>
            <p>
              <i>betstamp now compares odds for Player Props and Futures</i>
            </p>
          </Col>
        </Row>
        <Row
          style={{
            minHeight: '30vh',
            backgroundColor: 'var(--color-night-bg)',
            color: 'var(--color-night-text)',
            maxWidth: MAXWIDTH,
            margin: '0 auto',
            padding: 'var(--space-sm) var(--space-xs)',
            paddingTop: 'var(--space-xl)',
          }}
        >
          <Col
            style={{
              flex: '1 0 300px',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
            }}
          >
            <h4 style={{ marginTop: 0 }}>
              Bet like a pro with betstamp's free betting tools
            </h4>
            <ul>
              <li>Custom bet tracker</li>
              <li>Bets update with real time scores and odds</li>
              <li>
                Sports betting accounting - keep track of all your accounts in
                one place
              </li>
              <li>Bet history analysis tool</li>
            </ul>
          </Col>
          <Col
            style={{
              flex: '1 0 300px',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
            }}
          >
            <img
              src={FollowResults}
              style={{ width: '100%', height: 'auto' }}
              alt="betstamp odds comparison screen"
            />
          </Col>
        </Row>
        <Row
          style={{
            minHeight: '30vh',
            backgroundColor: 'var(--color-night-bg)',
            color: 'var(--color-night-text)',
            maxWidth: MAXWIDTH,
            margin: '0 auto',
            padding: 'var(--space-sm) var(--space-xs)',
            paddingTop: 'var(--space-xl)',
          }}
        >
          <Col
            style={{
              flex: '1 0 300px',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
            }}
          ></Col>
        </Row>
      </div>
      <Footer />
    </>
  );
}
