import React, { useState } from 'react';
import styled from 'styled-components';
import {
  IoCaretUp,
  IoCaretDown,
  IoChevronDownCircleSharp,
  IoChevronUpCircleSharp,
} from 'react-icons/io5';

// components
import AutoColoredAmount from 'components/generic/AutoColoredAmount';
import { Row } from './generic/Layout';
import { IconButton } from 'components/AuthButton';

const StyledCell = styled.td`
  padding: var(--space-sm) var(--space-xxs);
  box-sizing: border-box;
  width: ${props => {
    if (props.width) {
      return `${props.width}%`;
    }
    return 'unset';
  }};
  text-transform: ${props => {
    if (props.type === 'heading') {
      return 'uppercase';
    }
    return 'none';
  }};
  font-weight: ${props => {
    if (props.type === 'heading' || props.rowType === 'total') {
      return 'bold';
    }
    return 'normal';
  }};
  opacity: ${props => {
    if (props.isSortingByThis) {
      return '1';
    }
    if (props.type === 'heading') {
      return '0.75';
    }
    return '1';
  }};
  font-size: ${props => {
    if (props.type === 'heading') {
      return 'var(--text-sm)';
    }
    return 'var(--text-base-size)';
  }};
  border-bottom: ${props => {
    if (props.lastRow) {
      return 'none';
    }
    return '1px solid var(--color-text-light)';
  }};
  border-top: ${props => {
    if (props.rowType === 'total') {
      return '1px solid var(--color-text-light)';
    }
    return 'none';
  }};
  text-align: ${props => {
    if (
      props.type === 'placeholder' ||
      ((props.rowType === 'middle' || props.rowType === 'total') &&
        props.type === 'heading')
    ) {
      return 'left';
    }
    return 'center';
  }};
  whitespace: nowrap;
`;

export default function Cell(props) {
  const {
    numCols,
    lastRow,
    rowType,
    showSortIcon,
    isSortingByThis,
    is_childrow,
    rowExpandable,
    rowExpanded,
    GROUP_SPLIT_CHAR,
  } = props;
  let { text, type, calculation } = props.cell;
  let symbol = text.includes('%') ? '%' : text.includes('$') ? '$' : null;
  if (symbol) {
    text = text.replace(symbol, '');
  }

  const [sortDir, setSortDir] = useState('down');

  return (
    <StyledCell
      rowType={rowType}
      type={type}
      width={100 / numCols}
      lastRow={lastRow}
      onClick={
        props.onClickSort
          ? () => {
            let actualText = text;
            if (!actualText || actualText === '') {
              actualText = 'yaxis';
            }
            props.onClickSort(actualText, sortDir);
            setSortDir(sortDir === 'up' ? 'down' : 'up');
          }
          : null
      }
      isSortingByThis={isSortingByThis}
      style={props.style}
    >
      <Row
        style={{
          alignItems: 'center',
          justifyContent:
            type === 'placeholder' ||
              ((rowType === 'middle' || rowType === 'total') &&
                type === 'heading')
              ? 'flex-start'
              : 'center',
          flexFlow: 'row nowrap',
        }}
      >
        {symbol && calculation !== 'Average Bet Size' ? (
          <AutoColoredAmount
            bold={(rowType === 'total').toString()}
            symbol={symbol}
          >
            {text}
          </AutoColoredAmount>
        ) : (
          <span
            style={
              type === 'heading' && is_childrow
                ? { paddingLeft: '2rem', fontSize: '90%' }
                : {}
            }
          >
            {calculation === 'Average Bet Size' && <>$</>}
            {type === 'heading' && is_childrow
              ? text.split(GROUP_SPLIT_CHAR)[1]
              : text}
          </span>
        )}

        {type === 'heading' && rowType === 'middle' && rowExpandable && (
          <>
            {rowExpanded ? (
              <IconButton
                iconName={IoChevronUpCircleSharp}
                iconSize={22}
                iconColor="var(--color-text)"
                containerStyle={{
                  padding: '0 var(--space-xs)',
                }}
                iconTitle={'Collapse Row'}
                onPress={ev => {
                  ev.stopPropagation(true);
                  props.toggleExpandRow();
                }}
              />
            ) : (
              <IconButton
                iconName={IoChevronDownCircleSharp}
                iconSize={22}
                iconColor="var(--color-text)"
                containerStyle={{
                  padding: '0 var(--space-xs)',
                }}
                iconTitle={'Expand Row'}
                onPress={ev => {
                  ev.stopPropagation(true);
                  props.toggleExpandRow();
                }}
              />
            )}
          </>
        )}

        {showSortIcon && (
          <>
            {sortDir === 'up' && (
              <IoCaretUp size={16} style={{ color: 'var(--color-text)' }} />
            )}
            {sortDir === 'down' && (
              <IoCaretDown size={16} style={{ color: 'var(--color-text)' }} />
            )}
          </>
        )}
      </Row>
    </StyledCell>
  );
}
