import { Observable } from 'rxjs';
import 'rxjs/add/operator/exhaustMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import { ajax } from 'rxjs/observable/dom/ajax';
import { BACKEND_API_URL, getHeaders } from 'utils';

import { CREATE_USER, createUserSuccess, createUserFailure } from 'actions';

export default function createUser(action$, state$) {
  return action$.ofType(CREATE_USER).exhaustMap(action => {
    return ajax
      .post(BACKEND_API_URL + 'api/users/', action.params, getHeaders(state$))
      .map(res => createUserSuccess(res))
      .catch(error => Observable.of(createUserFailure(error.xhr)));
  });
}
