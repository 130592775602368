import { Observable } from 'rxjs';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import { ajax } from 'rxjs/observable/dom/ajax';
import { BACKEND_API_URL, getHeaders } from 'utils';

import {
  GET_BET_SELECTION_NEXT,
  getBetSelectionSuccess,
  getBetSelectionFailure,
} from 'actions';

export default function getBetSelectionNext(action$, state$) {
  return action$.ofType(GET_BET_SELECTION_NEXT).switchMap(action => {
    let url = BACKEND_API_URL + `api/bet_selection/${action.urlparams}`;
    if (state$.value.analysisReducer.otherUser) {
      url += `&user=${state$.value.analysisReducer.otherUser.id}&`;
    }

    return ajax
      .getJSON(action.nexturl, getHeaders(state$))
      .map(data =>
        getBetSelectionSuccess(
          url.replace('bet_selection', 'analysis'),
          data,
          true
        )
      )
      .catch(error => Observable.of(getBetSelectionFailure(error.xhr)));
  });
}
