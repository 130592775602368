export default {
  link_to_video: '',
  content: [
    { type: 'heading', size: 5, text: 'How Verified Picks Work' },
    {
      type: 'paragraph',
      text: 'When you track a bet on betstamp, our software will automatically classify this pick as verified or unverified. A verified pick will show up as such and will go towards your public record on your betstamp profile in the “Marketplace” tab of the app. An unverified pick will still be visible in your “Marketplace” page but it will not count towards your public record. It will however count towards your personal records in the “My Picks” section.',
    },
    {
      type: 'paragraph',
      text: 'In this video, we are trying to bet on the Arizona Cardinals at DraftKings. Our app has automatically checked DraftKings and determined that the odds they are offering on the Cardinals moneyline is +140.',
    },
    {
      type: 'paragraph',
      text: 'We can track this bet at these odds and change the bet stake to whatever we choose and after selecting “Track” this bet will show up as verified in your public profile and in your My Picks section.',
    },
    {
      type: 'video',
      url: 'https://betstamp-public.sfo2.cdn.digitaloceanspaces.com/tutorials/vid-2.mp4',
    },
    {
      type: 'paragraph',
      text: 'If we were to change the odds of this bet from +140 to +145 to give ourselves more favourable odds, we can still change the stake amount but this bet would not be verified in our public profile for tracking. You’ll also notice that if you attempt to track a game after its scheduled start time it will also show up as unverified in tracking pages.',
    },
    {
      type: 'video',
      url: 'https://betstamp-public.sfo2.cdn.digitaloceanspaces.com/tutorials/vid-3.mp4',
    },
    {
      type: 'paragraph',
      text: 'Our odds shown on the app are up to the minute so if you see odds that are different than on the site, refresh the games page to find the new numbers.',
    },
    {
      type: 'heading',
      size: 5,
      text: 'Why is Verified vs. Unverified Important?',
    },
    {
      type: 'paragraph',
      text: 'Verifying picks eliminates the misinformation in the sports betting space. By tracking a pick at odds currently available before that game has begun, you can build a credible record for the public to see and offer transparent information.',
    },
    {
      type: 'paragraph',
      text: 'Other users viewing your public profile will only be able to view your verified picks record and statistics. Once you track a verified bet, it will remain in your public profile forever and cannot be deleted.',
    },
    {
      type: 'paragraph',
      text: 'Verified picks can still be edited for your own personal record keeping but they will never change on your public profile.',
    },
    { type: 'heading', size: 5, text: 'Selling Picks' },
    {
      type: 'paragraph',
      text: 'If you decide to sell your picks through betstamp, only picks that are verified will be sent to your subscribers. Alternatively, if you are buying picks from a betstamp user, you are protected against falsified records and misinformation. The same goes for public profiles that you are interested in tailing.',
    },
    {
      type: 'heading',
      text: 'How Do I Identify Verified vs. Unverified Wagers?',
    },
    {
      type: 'paragraph',
      text: 'Verified picks will be marked with a blue betstamp logo while bets that are not verified will not have this logo. Example shown below:',
    },
    {
      type: 'image',
      url: 'https://betstamp-public.sfo2.cdn.digitaloceanspaces.com/tutorials/settings-7.jpg',
    },

    { type: 'heading', text: 'Editing Picks' },
    {
      type: 'paragraph',
      text: 'You can edit any bet at any time for any reason.​',
    },
    {
      type: 'paragraph',
      text: 'Editing the bet will only change the bet for your personal record keeping, but your verified pick will remain unchanged in your public profile.',
    },
    {
      type: 'paragraph',
      text: 'Reasons for editing a bet:',
    },
    {
      type: 'list-item',
      text: 'I bet earlier in the day or week and forgot to track it right away',
    },
    {
      type: 'list-item',
      text: 'I tracked a verified bet by mistake and need to change or delete from my personal record',
    },
    {
      type: 'list-item',
      text: 'I got different odds at my sportsbook and so I want to change my verified bet for personal record keeping and tracking',
    },
    {
      type: 'list-item',
      text: 'I tracked the bet at the incorrect sportsbook',
    },
    {
      type: 'list-item',
      text: 'The payout or grading I received from my sportsbook was different than how betstamp graded the bet',
    },
    {
      type: 'paragraph',
      text: 'To edit the bet, click on the bet in the my picks screen and click adjust for tracking on the bet.',
    },
    {
      type: 'paragraph',
      text: 'You will be able to edit the risk and win amount, the spread, the team you selected, or the sportsbook you entered.',
    },
    {
      type: 'image',
      url: 'https://betstamp-public.sfo2.cdn.digitaloceanspaces.com/tutorials/settings-8.jpg',
    },

    {
      type: 'paragraph',
      text: 'Once the bet has been edited it will show up in your personal record with the modifications that you have made. To other public users viewing your profile and in your public record, the bet will not show any of the edits that you have made.',
    },
    {
      type: 'paragraph',
      text: 'Keeping your bets as accurate as possible is important if you want to get the most out of betstamp. These verified tracking measures are put in place so users can not take advantage of the system and falsify their betting record. By keeping your record clean you can accurately keep all of your sportsbook balances up to date with our accountancy features and you can determine the strengths and weaknesses of your betting with our analysis feature.',
    },

    { type: 'heading', size: 5, text: 'Custom Picks' },
    {
      type: 'paragraph',
      text: 'You can add custom bets for leagues or bet types that are currently not supported. Currently we support NFL, NBA, MLB, NHL, UFC, Bellator, ATP, WTA, WNBA, CFL, NCAAF, NCAAB, SERA, BUND, MLS, UCL, EPL, LIG1, LIGA and Golf. We are continuing to add more leagues to bet on with verified tracking.',
    },
    {
      type: 'paragraph',
      text: 'Custom bets can always be used to track bet types that we do not currently support such as obscure futures or player prop markets.',
    },
    {
      type: 'paragraph',
      text: 'To Track a custom bet, click the blue circle with the plus sign found in the bottom right corner of the “My Picks” page.',
    },
    {
      type: 'video',
      url: 'https://betstamp-public.sfo2.cdn.digitaloceanspaces.com/tutorials/vid-4.mp4',
    },
    {
      type: 'paragraph',
      text: 'As shown in the video above, you can input the sport, bet description, risk amount, and the odds. Once you track this bet, it will show up in your my picks page so you can follow along on gameday. It will also show up in your analysis and accounting pages and count towards your personal record.',
    },
    {
      type: 'paragraph',
      text: 'These custom bets will not be verified and will not count towards your public record. However, they can be posted to your public profile by clicking “Add Bet To Public Profile” when tracking the bet as public. Public custom bets cannot be deleted and the amount of edits are tracked. This is so the user can determine the credibility of the bet you tracked. (i.e. did you change the bet after grading).',
    },
    {
      type: 'paragraph',
      text: 'You must mark a custom bet as a win, loss, etc. manually. They will remain as pending until you grade them yourself.',
    },
    {
      type: 'video',
      url: 'https://betstamp-public.sfo2.cdn.digitaloceanspaces.com/tutorials/vid-5.mp4',
    },
  ],
};
