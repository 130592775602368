import { Observable } from 'rxjs';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import { ajax } from 'rxjs/observable/dom/ajax';
import { BACKEND_API_URL, getHeaders } from 'utils';

import {
  GET_AFFILIATES,
  getAffiliatesSuccess,
  getAffiliatesFailure,
} from 'actions';

export default function getAffiliates(action$, state$) {
  return action$.ofType(GET_AFFILIATES).switchMap(action => {
    let url = BACKEND_API_URL + `api/affiliate_books/?`;
    if (action.linkType !== 'assist-tool') {
      url += `group_name=In-App&`;
    }
    if (action.refCode) url += `ref_code=${action.refCode}&`;
    if (action.promoPage) url += `promo_page=True&`;
    if (action.state) url += `state=${action.state}&`;
    if (action.linkType) {
      if (action.linkType === 'onboarding') url += `is_onboarding=True`;
      if (action.linkType === 'groups') url += `is_groups=True`;
      if (action.linkType === 'vip') url += `is_vip=True`;
      if (action.linkType === 'influencer') url += `is_influencer=True`;
      if (action.linkType === 'events') url += `is_events=True`;
    }
    return ajax
      .getJSON(url, getHeaders(state$))
      .map(data => getAffiliatesSuccess(data))
      .catch(error => Observable.of(getAffiliatesFailure(error.xhr)));
  });
}
