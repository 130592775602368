import { Observable } from 'rxjs';
import 'rxjs/add/operator/exhaustMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import { ajax } from 'rxjs/observable/dom/ajax';
import { BACKEND_API_URL, getHeaders } from 'utils';

import {
  CREATE_TRANSACTION,
  createTransactionSuccess,
  createTransactionFailure,
} from 'actions';

export default function createTransaction(action$, state$) {
  return action$.ofType(CREATE_TRANSACTION).exhaustMap(action => {
    let url = `${BACKEND_API_URL}api/transactions/`;

    return ajax
      .post(url, action.tx, getHeaders(state$))
      .map(data => createTransactionSuccess(data.response))
      .catch(error => Observable.of(createTransactionFailure(error.xhr)));
  });
}
