import React, { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useUpdateEffect, useEffectOnce } from 'react-use';

// component
import ActivityIndicator from 'components/generic/ActivityIndicator';
import { Col } from 'components/generic/Layout';
import OddBtnLine from 'components/OddBtnLine';
import GameBAHeader from 'components/GameBAHeader';

// actions
import { get1HalfOdds, get1HalfOddsNext, get1HalfBA } from 'actions';

export default function OneHalf() {
  const dispatch = useDispatch();
  const reduxProps = useSelector(state => ({
    game: state.gameReducer.game,
    oneHalfOdds: state.gameReducer.oneHalfOdds,
    isLoadingOneHalfOdds: state.gameReducer.isLoadingOneHalfOdds,
    oneHalfOddsError: state.gameReducer.oneHalfOddsError,
    oneHalfOddsNext: state.gameReducer.oneHalfOddsNext,
    isLoadingOneHalfOddsNext: state.gameReducer.isLoadingOneHalfOddsNext,
    oneHalfBA: state.gameReducer.oneHalfBA,
    isLoadingOneHalfBA: state.gameReducer.isLoadingOneHalfBA,
    oneHalfBAError: state.gameReducer.oneHalfBAError,
  }));
  const {
    game,
    oneHalfOdds,
    isLoadingOneHalfOdds,
    oneHalfOddsError,
    oneHalfOddsNext,
    isLoadingOneHalfOddsNext,
    oneHalfBA,
    isLoadingOneHalfBA,
    oneHalfBAError,
  } = reduxProps;

  const period = useMemo(() => {
    if (game?.league === 'NHL') {
      return '1P';
    }
    if (game?.league === 'MLB') {
      return 'F5';
    }
    if (['ATP', 'WTA'].includes(game?.league)) {
      return '1S';
    }
    return '1H';
  }, [game]);

  useEffectOnce(() => {
    if (game && !oneHalfOdds) {
      dispatch(get1HalfOdds({ game_id: game.id, period: period }));
      dispatch(get1HalfBA(game.id, period));
    }
  }, []);

  const _getNext = () => {
    if (oneHalfOddsNext && !isLoadingOneHalfOddsNext) {
      dispatch(get1HalfOddsNext());
    }
  };
  useUpdateEffect(() => {
    _getNext();
  }, [oneHalfOddsNext]);

  if (isLoadingOneHalfOdds) {
    return (
      <Col style={{ height: '75%', justifyContent: 'center' }}>
        <ActivityIndicator size={3} />
      </Col>
    );
  }

  if (oneHalfOddsError) {
    return (
      <h5 style={{ textAlign: 'center' }}>
        We ran into an error getting {period} odds
      </h5>
    );
  }

  return (
    <div style={{ minHeight: '100vh' }}>
      <GameBAHeader
        BA={oneHalfBA}
        isLoading={isLoadingOneHalfBA}
        error={oneHalfBAError}
      />
      {oneHalfOdds &&
        oneHalfOdds.map((odd, index) => (
          <OddBtnLine  key={`onehalf-list-odd-${index}`} odd={odd} />
        ))}
    </div>
  );
}
