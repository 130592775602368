import React from 'react';
import { useSelector } from 'react-redux';

// utils
import { getLastName } from 'utils';

// component
import { Row, Col } from 'components/generic/Layout';
import OddBtnLine from 'components/OddBtnLine';
import TeamLeagueLogo from 'components/TeamLeagueLogo';

export default function GameBAHeader(props) {
  const reduxProps = useSelector(state => ({
    game: state.gameReducer.game,
    // settings
    showML: state.settingsReducer.showML,
    showSpread: state.settingsReducer.showSpread,
    showTotal: state.settingsReducer.showTotal,
  }));
  const { game, showML, showSpread, showTotal } = reduxProps;

  const showAll = !showML && !showSpread && !showTotal;

  const { BA, isLoading, error } = props;

  return (
    <>
      <Row
        style={{
          padding: 'var(--space-xxs) 0',
          backgroundColor: 'var(--color-fg)',
          alignItems: 'center',
          position: 'sticky',
          height: '40px',
          top: 0,
          zIndex: 10,
        }}
      >
        <Col style={{ alignItems: 'center' }}>
          <b>Book</b>
        </Col>
        {(showML || showAll) && (
          <Row style={{ alignItems: 'center', justifyContent: 'center' }}>
            <Logo game={game} teamName={game.away_team.name} />
            <b>{getLastName(game.away_team.name)}</b>
          </Row>
        )}
        {game.sport === 'Soccer' && (showML || showAll) && (
          <small style={{ flex: 1, textAlign: 'center', fontWeight: 'bold' }}>
            X
          </small>
        )}
        {(showML || showAll) && (
          <Row style={{ alignItems: 'center', justifyContent: 'center' }}>
            <Logo game={game} teamName={game.home_team.name} />
            <b>{getLastName(game.home_team.name)}</b>
          </Row>
        )}
        {(showSpread || showAll) && game.sport !== 'MMA' && (
          <>
            <Row style={{ alignItems: 'center', justifyContent: 'center' }}>
              <Logo game={game} teamName={game.away_team.name} />
              <b>{getLastName(game.away_team.name)}</b>
            </Row>
            <Row style={{ alignItems: 'center', justifyContent: 'center' }}>
              <Logo game={game} teamName={game.home_team.name} />
              <b>{getLastName(game.home_team.name)}</b>
            </Row>
          </>
        )}
        {(showTotal || showAll) && game.sport !== 'Golf' && (
          <>
            <b style={{ flex: 1, textAlign: 'center' }}>Over</b>
            <b style={{ flex: 1, textAlign: 'center' }}>Under</b>
          </>
        )}
      </Row>
      <div style={{ position: 'sticky', top: '40px', zIndex: 11 }}>
        <OddBtnLine
          odd={BA ? { id: `ba-line-${game.id}`, ...BA } : null}
          game={game}
          bestAvailable={true}
          isLoading={isLoading}
          error={error}
        />
      </div>
    </>
  );
}

function Logo({ game, teamName }) {
  return (
    <TeamLeagueLogo
      iconSize={32}
      displayTeam
      league={game.league}
      team={teamName}
    />
  );
}
