import { Observable } from 'rxjs';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import { ajax } from 'rxjs/observable/dom/ajax';
import { BACKEND_API_URL, getHeaders } from 'utils';

import {
  GET_TWITTER_REQUEST_TOKEN,
  getTwitterRequestTokenSuccess,
  getTwitterRequestTokenFailure,
} from 'actions';

export default function getTwitterRequestToken(action$, state$) {
  return action$.ofType(GET_TWITTER_REQUEST_TOKEN).switchMap(action => {
    let url =
      BACKEND_API_URL +
      `api/get_twitter_request_token/?platform=${action.platform}`;

    return ajax
      .getJSON(url, getHeaders(state$))
      .map(data => getTwitterRequestTokenSuccess(data))
      .catch(error => Observable.of(getTwitterRequestTokenFailure(error.xhr)));
  });
}
