import {
  useInfiniteQuery,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { AuthButton } from 'components/AuthButton';

// components
import StaffNavBar from 'components/nav/StaffNavBar';

export default function SyncToAirtable() {
  const callSync = useMutation({
    mutationKey: 'onboarding-sync-all-to-airtable',
  });

  if (callSync.isSuccess) {
    return (
      <>
        <StaffNavBar />
        <div
          style={{
            width: '100%',
            maxWidth: '620px',
            padding: 'var(--space-md) var(--space-xs)',
            margin: '0 auto',
          }}
        >
          <p>Success! All attendees have been resynced to airtable</p>
        </div>
      </>
    );
  }

  if (callSync.isError) {
    return (
      <>
        <StaffNavBar />
        <div
          style={{
            width: '100%',
            maxWidth: '620px',
            padding: 'var(--space-md) var(--space-xs)',
            margin: '0 auto',
          }}
        >
          <p>There was an error syncing the attendees to airtable</p>
          <p>{callSync.error?.message || callSync.error.toString()}</p>
        </div>
      </>
    );
  }

  return (
    <>
      <StaffNavBar />
      <div
        style={{
          width: '100%',
          maxWidth: '620px',
          padding: 'var(--space-md) var(--space-xs)',
          margin: '0 auto',
        }}
      >
        <p>
          Resync all attendees to Airtable. This is useful after adding a new
          field to Airtable that we need to backdate to all existing attendees.
        </p>
        <p>This could take several minutes to complete.</p>
        <AuthButton
          isLoading={callSync.isLoading}
          onPress={() =>
            callSync.mutate({
              endpoint: 'onboarding-sync-to-airtable',
              body: {},
            })
          }
        >
          Sync All to Airtable
        </AuthButton>
      </div>
    </>
  );
}
