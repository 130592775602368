import { Observable } from 'rxjs';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import { ajax } from 'rxjs/observable/dom/ajax';
import { getHeaders } from 'utils';

import {
  GET_PLAYER_BOXSCORES_NEXT,
  getPlayerBoxscoresSuccess,
  getPlayerBoxscoresFailure,
} from 'actions';

export default function getPlayerBoxscoresNext(action$, state$) {
  return action$.ofType(GET_PLAYER_BOXSCORES_NEXT).switchMap(() => {
    let url = state$.value.gameReducer.playerBoxscoresNext;
    return ajax
      .getJSON(url, getHeaders(state$))
      .map(data => getPlayerBoxscoresSuccess(data))
      .catch(error => Observable.of(getPlayerBoxscoresFailure(error.xhr)));
  });
}
