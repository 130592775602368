import { combineEpics } from 'redux-observable';

import trackBetslip from 'epics/betslip/trackBetslip.epic';
import betslipChangeBookParlay from 'epics/betslip/betslipChangeBookParlay.epic';
import betslipChangeBookBet from 'epics/betslip/betslipChangeBookBet.epic';
import placeBet from 'epics/betslip/placeBet.epic';
export default combineEpics(
  trackBetslip,
  placeBet,
  betslipChangeBookParlay,
  betslipChangeBookBet
);
