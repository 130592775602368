import { Observable } from 'rxjs';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import { ajax } from 'rxjs/observable/dom/ajax';
import { BACKEND_API_URL, getHeaders } from 'utils';

import {
  GET_COMPETITIONS,
  getCompetitionsSuccess,
  getCompetitionsFailure,
} from 'actions';

export default function getCompetitions(action$, state$) {
  return action$.ofType(GET_COMPETITIONS).switchMap(() => {
    let url = BACKEND_API_URL + `api/competitions/`;
    return ajax
      .getJSON(url, getHeaders(state$))
      .map(data => getCompetitionsSuccess(data))
      .catch(error => Observable.of(getCompetitionsFailure(error.xhr)));
  });
}
