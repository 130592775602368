/**
 * Converts American odds into decimal odds.
 * @param {Number} american American odds as a integer (technically js will allow strings too)
 * @returns {String} Decimal odds as a string with 2 decimal places. Return ' ' for invalid input.
 */
export default function toDecimalOdds(american) {
  if (isNaN(american) || american === null) return ' ';
  let decimal;
  if (american > 0) {
    decimal = 1 + american / 100;
  } else {
    decimal = 1 + 100 / (-1 * american);
  }
  decimal = parseFloat(decimal).toString();
  if (decimal === '-Infinity' || decimal === 'Infinity') return ' ';
  return decimal;
}
