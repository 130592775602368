import { Observable } from 'rxjs';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import { ajax } from 'rxjs/observable/dom/ajax';
import { BACKEND_API_URL, getHeaders } from 'utils';

import {
  GET_2_HALF_ODDS,
  get2HalfOddsSuccess,
  get2HalfOddsFailure,
} from 'actions';

export default function get2HalfOdds(action$, state$) {
  return action$.ofType(GET_2_HALF_ODDS).switchMap(action => {
    let url =
      BACKEND_API_URL + `api/gameline/?game_id=${action.game_id}&period=2H`;

    return ajax
      .getJSON(url, getHeaders(state$))
      .map(data => get2HalfOddsSuccess(data))
      .catch(error => Observable.of(get2HalfOddsFailure(error.xhr)));
  });
}
