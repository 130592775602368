import { combineEpics } from 'redux-observable';

import getSched from 'epics/schedule/getSched.epic';
import getSchedNext from 'epics/schedule/getSchedNext.epic';
import getFuturesSched from 'epics/schedule/getFuturesSched.epic';
import getFuturesSchedNext from 'epics/schedule/getFuturesSchedNext.epic';
import getFuturesOdds from 'epics/schedule/getFuturesOdds.epic';

export default combineEpics(
  getSched,
  getSchedNext,
  getFuturesSched,
  getFuturesSchedNext,
  getFuturesOdds
);
