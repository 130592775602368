import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import {
  IoPower,
  IoChatboxEllipsesOutline,
  IoConstructSharp,
  IoFileTrayFullOutline,
  IoLogoInstagram,
  IoLogoTwitter,
  IoLogoLinkedin,
  IoLogoTiktok,
  IoMailOutline,
} from 'react-icons/io5';

// components
import { Grid } from 'components/generic/Layout';
import { BetstampStampInverted } from 'components/generic/Logos';
import MarketplaceLogo from 'images/logos/marketplace-beta.svg';
import AnalysisLogo from 'images/logos/analysis-beta.svg';

const Hero = styled.div`
  width: 100%;
  background-color: var(--color-primary);
  color: white;
  padding: var(--space-xl);
  box-sizing: border-box;
  @media only screen and (max-width: 625px) {
    padding: var(--space-sm);
    padding-bottom: var(--space-xl);
  }
`;

export default function Help() {
  return (
    <>
      <Hero>
        <div className="container" style={{ minHeight: 0 }}>
          <h1>How can we help you?</h1>
          <p>
            Use the links below to find help and tutorials on common topics.
            Still can't find what you're looking for? Send us an email at{' '}
            <a style={{ color: 'white' }} href="mailto:contact@betstamp.app">
              contact@betstamp.app
            </a>{' '}
            or DM us on social media.
          </p>
        </div>
      </Hero>

      <div
        className="container"
        style={{
          padding: '0 var(--space-sm)',
          paddingBottom: 'var(--space-xxxxl)',
        }}
      >
        <h4>General</h4>
        <Grid>
          <Card
            icon={IoPower}
            title={'Getting Started'}
            to="/tutorial-all"
            desc={'Coming soon'}
          />
          <Card
            icon={IoChatboxEllipsesOutline}
            title={'Frequently Asked Questions'}
            to="/faq"
          />
        </Grid>

        <h4>About betstamp</h4>
        <Grid>
          <Card
            icon={IoConstructSharp}
            title={'App Settings'}
            to="/tutorial-settings"
          />
          <Card
            img={() => <BetstampStampInverted height={64} />}
            title={'betstamp Verified'}
            to="/tutorial-bets"
          />
        </Grid>

        <h4>Bet Tools and Software</h4>
        <Grid>
          <Card
            img={() => (
              <img
                src={AnalysisLogo}
                style={{
                  height: '64px',
                  width: 'auto',
                  margin: '0 auto',
                }}
                alt={'betstamp Analysis'}
              />
            )}
            title={'Analysis Tool'}
            to="/tutorial-analysis"
          />
          <Card
            img={() => <BetstampStampInverted height={64} />}
            title={'betstamp Player Props Tool'}
            to="/tutorial-player-prop"
          />
          <Card
            icon={IoFileTrayFullOutline}
            title={'Accounting'}
            to="/tutorial-accounting"
          />
        </Grid>
        <h4>Gambling Problem?</h4>
        <a href={'/gambling-problem'}>Click here</a>
        <h4>Contact</h4>
        <p>
          Still haven't found what you're looking for? Reach out to us on social
          media or send us an email at contact@betstamp.app
        </p>
        <Grid
          style={{
            gridTemplateColumns: 'repeat(auto-fit, 32px)',
            gridGap: '2rem',
          }}
        >
          <a
            href="https://twitter.com/Betstamp"
            target="_blank"
            rel="noopener noreferrer"
          >
            <IoLogoTwitter
              title="betstamp Twitter"
              style={{ width: '100%', height: 'auto' }}
            />
          </a>
          <a
            href="https://www.instagram.com/betstamp/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <IoLogoInstagram
              title="betstamp Instagram"
              style={{ width: '100%', height: 'auto' }}
            />
          </a>
          <a
            href="https://vm.tiktok.com/ZMe9VSXm1/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <IoLogoTiktok
              title="betstamp Tik Tok"
              style={{ width: '100%', height: 'auto' }}
            />
          </a>
          <a
            href="https://www.linkedin.com/company/betstamp/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <IoLogoLinkedin
              title="betstamp LinkedIn"
              style={{ width: '100%', height: 'auto' }}
            />
          </a>
          <a
            href="mailto:contact@betstamp.app"
            target="_blank"
            rel="noopener noreferrer"
          >
            <IoMailOutline
              title="betstamp Email"
              style={{ width: '100%', height: 'auto' }}
            />
          </a>
        </Grid>
      </div>
    </>
  );
}

const StyledCard = styled.div`
  flex: 1;
  display: flex;
  flex-flow: column wrap;
  justify-content: space-around;
  align-items: center;
  padding: var(--space-md) var(--space-sm);
  border-radius: var(--std-border-radius);
  background-color: var(--color-fg);
  position: relative;
  transition: all var(--std-transition);

  height: 100%;

  &:hover {
    box-shadow: 0 2px 8px 0 var(--color-shadow);
  }
`;

function Card(props) {
  const { title, desc, to } = props;
  const Icon = props.icon;
  const Img = props.img;
  return (
    <Link to={to} style={{ textDecoration: 'none' }}>
      <StyledCard>
        {Icon && <Icon size={48} color="var(--color-text)" />}
        {Img && <Img />}
        <b style={{ textAlign: 'center', marginTop: 'var(--space-xxs)' }}>
          {title}
        </b>
        <p style={{ margin: 0 }}>{desc}</p>
      </StyledCard>
    </Link>
  );
}
