import { Observable } from 'rxjs';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import { ajax } from 'rxjs/observable/dom/ajax';
import { BACKEND_API_URL, getHeaders } from 'utils';

import {
  GET_PUBLIC_PROFILE_BY_USERNAME,
  getPublicProfileByUsernameSuccess,
  getPublicProfileByUsernameFailure,
} from 'actions';

export default function getPublicProfileByUsername(action$, state$) {
  return action$.ofType(GET_PUBLIC_PROFILE_BY_USERNAME).switchMap(action => {
    return ajax
      .getJSON(
        BACKEND_API_URL + 'api/users/?username=' + action.username,
        getHeaders(state$)
      )
      .map(data => {
        if (data.results.length === 1)
          return getPublicProfileByUsernameSuccess(data.results[0]);
        else return getPublicProfileByUsernameFailure(null);
      })
      .catch(error =>
        Observable.of(getPublicProfileByUsernameFailure(error.xhr))
      );
  });
}
