import { Observable } from 'rxjs';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import { ajax } from 'rxjs/observable/dom/ajax';
import { BACKEND_API_URL, getHeaders } from 'utils';

import {
  DELETE_ANALYSIS_PRESET,
  deleteAnalysisPresetSuccess,
  deleteAnalysisPresetFailure,
} from 'actions';

export default function deletePreset(action$, state$) {
  return action$.ofType(DELETE_ANALYSIS_PRESET).switchMap(action => {
    return ajax
      .delete(
        `${BACKEND_API_URL}api/analysispresets/${action.presetid}/`,
        getHeaders(state$)
      )
      .map(data => deleteAnalysisPresetSuccess(data, action.presetid))
      .catch(error => Observable.of(deleteAnalysisPresetFailure(error.xhr)));
  });
}
