import React, { useState, useEffect } from 'react';

// utils
import { cleanDollarAmount, commaSeparateNumber } from 'utils';

// components
import { AuthTextInput } from 'components/AuthTextInput';

export default function CurrencyInput(props) {
  const { commaSeparate } = props;
  const [value, setValue] = useState(
    commaSeparateNumber(props.defaultValue, true) || 100
  );
  const [lastSentValue, setLastSentValue] = useState(null);

  useEffect(() => {
    let text = props.defaultValue;
    let textNoDollar = cleanDollarAmount(text).replace('$', '');
    let formattedText = textNoDollar;
    if (commaSeparate) {
      formattedText = commaSeparateNumber(formattedText, true);
    }

    setValue(`${formattedText}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.defaultValue]);

  // send value debounced (wait 150ms after they stop typing)
  // really just prevents fast typers from causing
  // some unnecessary calculations. not sure if we want this
  useEffect(() => {
    const handler = setTimeout(() => {
      if (lastSentValue !== null) {
        props.onChange(lastSentValue);
      }
    }, 100);
    return () => clearTimeout(handler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastSentValue]);

  return (
    <AuthTextInput
      {...props}
      type={'text'}
      value={value}
      onChangeText={newText => {
        let text = newText;
        // remove ALL commas because comma separator
        if (commaSeparate) {
          text = text.replace(/,/g, '');
        }

        // remove anything that shouldn't be in a dollar amount and
        // extra decimal places. this adds in a dollar sign
        let textNoDollar = cleanDollarAmount(text).replace('$', '');

        // too large, > $100,000,000
        if (parseFloat(textNoDollar) > 100000000) return;

        let formattedText = textNoDollar;
        if (commaSeparate) {
          formattedText = commaSeparateNumber(formattedText, true);
        }

        setValue(`${formattedText}`);

        let number = parseFloat(textNoDollar);

        // don't send this value up if it's invalid or we already sent it
        if (isNaN(number) || lastSentValue === number) {
          setLastSentValue(null);
          return;
        }

        setLastSentValue(number);
      }}
    />
  );
}
