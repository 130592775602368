import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import {
  IoAnalyticsSharp,
  IoLogoTwitter,
  IoNotifications,
  IoNotificationsOff,
} from 'react-icons/io5';

// utils
import { commaSeparateNumber } from 'utils';

// components
import { Row, Col } from 'components/generic/Layout';
import { BetstampStamp } from 'components/generic/Logos';
import FollowBtn from '../FollowBtn';
import ProfilePic from 'components/generic/ProfilePic';
import AutoColoredAmount from 'components/generic/AutoColoredAmount';
import ModalWrapper from 'components/generic/ModalWrapper';
import { AuthButton, IconButton, LinkButton } from 'components/AuthButton';

// modals
import Followers from 'components/modals/Followers';
import Following from 'components/modals/Following';

// actions
import {
  logoutUser,
  cleanAutoSyncStore,
  getStripeExpressDashboardUrl,
  editRelationship,
} from 'actions';

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;

  flex: 1;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;

  @media only screen and (max-width: 1366px) {
    width: 100%;
    padding: 0 var(--space-xs);
  }

  @media only screen and (max-width: 625px) {
    width: 100%;
    padding: 0;
    margin-top: -4rem;
    padding: 0 var(--space-md);
  }
`;

const UsernameCol = styled.div`
  flex: 0.8;
  display: flex;
  flex-flow: column wrap;
  justify-content: space-evenly;
  align-items: flex-start;
  margin-left: var(--space-xs);
  margin-top: 1rem;
  padding-bottom: 0;

  @media only screen and (max-width: 625px) {
    margin-top: -4px;
    & > div > h2 {
      font-size: var(--text-xl);
    }
  }
`;

export default function SelfUserProfileHeader(props) {
  const dispatch = useDispatch();

  const [followersModalVisible, setFollowersModalVisible] = useState(false);
  const [followingModalVisible, setFollowingModalVisible] = useState(false);

  const {
    user,
    onMobile,
    isPublic,
    showFollowButton,
    showExpressDashboardButton,
    viewingSelf,
    hideViewMyProfileButton,
  } = props;

  const reduxProps = useSelector(state => ({
    userToken: state.authReducer.userToken,
    expressDashboardLoading: state.authReducer.expressDashboardLoading,
    expressDashboardURL: state.authReducer.expressDashboardURL,
    expressDashboardError: state.authReducer.expressDashboardError,
    isLoadingLogout: state.authReducer.isLoadingLogout,
  }));

  const {
    userToken,
    isLoadingLogout,
    expressDashboardLoading,
    expressDashboardURL,
    expressDashboardError,
  } = reduxProps;

  useEffect(() => {
    if (expressDashboardURL && expressDashboardURL.trim() !== '') {
      window.location.href = expressDashboardURL;
    }
  }, [expressDashboardURL]);

  return (
    <>
      <Helmet>
        <title>{user.username}</title>
      </Helmet>

      {userToken && (
        <ModalWrapper
          modalIsOpen={followersModalVisible}
          onClose={() => setFollowersModalVisible(false)}
          onRequestClose={() => setFollowersModalVisible(false)}
          title="Followers"
          id="followers-modal-wrapper"
        >
          <Followers viewingSelf={viewingSelf} />
        </ModalWrapper>
      )}

      {userToken && (
        <ModalWrapper
          modalIsOpen={followingModalVisible}
          onClose={() => setFollowingModalVisible(false)}
          onRequestClose={() => setFollowingModalVisible(false)}
          title="Following"
          id="following-modal-wrapper"
        >
          <Following viewingSelf={viewingSelf} />
        </ModalWrapper>
      )}

      <Wrapper>
        <Col style={{ flex: '0 0 8rem' }} desktop>
          <ProfilePic user={user} style={{ width: '8rem', height: '8rem' }} />
        </Col>
        <Col style={{ flex: '0 0 5rem' }} mobile>
          <ProfilePic user={user} style={{ width: '5rem', height: '5rem' }} />
        </Col>

        <UsernameCol>
          <Row style={{ alignItems: 'center', flexWrap: 'nowrap' }}>
            <h2 style={{ margin: 0, color: 'var(--color-text)' }}>
              {user.username}
            </h2>
            {user.is_verified && (
              <BetstampStamp
                width={22}
                height={22}
                style={{
                  marginLeft: 'var(--space-xxxs)',
                }}
              />
            )}

            {user.twitter && (
              <Wrapper style={{ margin: 'var(--space-xs) var(--space-sm)' }}>
                <Row mobile>
                  <Twitter user={user} />
                </Row>
              </Wrapper>
            )}
          </Row>

          <FollowStats
            user={user}
            isPublic={isPublic}
            userToken={userToken}
            openFollowers={() => setFollowersModalVisible(true)}
            openFollowing={() => setFollowingModalVisible(true)}
          />
          <Row>
            {userToken && !hideViewMyProfileButton && (
              <LinkButton
                colorTheme={'inverted'}
                containerStyle={{
                  margin: 'var(--space-xxs) var(--space-xxs)',
                  padding: '0 0',
                }}
                to={`/u/${user.username}`}
              >
                View Profile
              </LinkButton>
            )}
            {onMobile && (
              <AuthButton
                btnTheme="borderless"
                btnStyle={{ paddingLeft: 'var(--space-md)' }}
                textStyle={{ textAlign: 'left' }}
                colorTheme="danger"
                isLoading={isLoadingLogout}
                onPress={() => {
                  dispatch(logoutUser());
                  dispatch(cleanAutoSyncStore());
                }}
              >
                Log out
              </AuthButton>
            )}
          </Row>

          {userToken && isPublic === true && showFollowButton ? (
            <Row style={{ marginTop: 'var(--space-sm)' }} desktop>
              <FollowBtn user={user} />
            </Row>
          ) : (
            <Row
              style={{
                marginTop: '0',
                alignItems: 'center',
                minHeight: 42,
              }}
              desktop
            >
              {showExpressDashboardButton && user.stripe_connect_id && (
                <>
                  {expressDashboardError ? (
                    <Col style={{ marginLeft: 'var(--space-xs)' }}>
                      <small>
                        Sorry, we ran into an error! Refresh the page and try
                        again.
                      </small>
                    </Col>
                  ) : (
                    <AuthButton
                      btnStyle={{ marginLeft: 'var(--space-xs)' }}
                      isLoading={expressDashboardLoading}
                      disabled={expressDashboardLoading}
                      onClick={() => dispatch(getStripeExpressDashboardUrl())}
                    >
                      Payment Account
                    </AuthButton>
                  )}
                </>
              )}
            </Row>
          )}
        </UsernameCol>

        <UserStats user={user} userToken={userToken} isPublic={isPublic} />
      </Wrapper>

      <Row
        mobile
        style={{ justifyContent: 'flex-start', alignItems: 'center' }}
      >
        {isPublic === true && showFollowButton && <FollowBtn user={user} />}
      </Row>
    </>
  );
}

function Twitter({ user }) {
  return (
    <Row
      style={{
        alignItems: 'center',
        justifyContent: 'flex-start',
        flexFlow: 'nowrap',
      }}
    >
      <IoLogoTwitter
        alt={`${user.username}'s Twitter`}
        size={28}
        color={user.twitter ? 'var(--color-text)' : 'transparent'}
      />
      {user.twitter && (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`https://twitter.com/${user.twitter}`}
        >
          {user.twitter}
        </a>
      )}
    </Row>
  );
}

function FollowStats(props) {
  const dispatch = useDispatch();
  const { user, isPublic } = props;
  const linkStyle = { marginRight: '1rem', cursor: 'pointer' };

  return (
    <Row
      style={{
        width: '100%',
        color: 'var(--color-text)',
        justifyContent: 'flex-start',
        padding: 'var(--space-sm)',
        alignItems: 'center',
      }}
    >
      {/* <AuthButton
        onPress={
          userToken && isPublic === false ? () => props.openFollowing() : null
        }
        colorTheme={'inverted'}
        btnStyle={
          isPublic
            ? { color: 'white', cursor: 'not-allowed', boxShadow: 'none' }
            : { color: 'white' }
        }
      > */}
      <span style={linkStyle} onClick={() => props.openFollowing()}>
        <b>{commaSeparateNumber(user.following)}</b> Following
      </span>
      {/* </AuthButton> */}
      {/* <AuthButton
        onPress={userToken ? () => props.openFollowers() : null}
        colorTheme={'inverted'}
        btnStyle={
          !userToken
            ? { color: 'white', cursor: 'not-allowed', boxShadow: 'none' }
            : { color: 'white' }
        }
      > */}
      <span style={linkStyle} onClick={() => props.openFollowers()}>
        <b>{commaSeparateNumber(user.followers)}</b> Followers
      </span>
      {/* </AuthButton> */}
      {user.relationship_to_user.status === 1 ? (
        <IconButton
          colorTheme="inverted"
          iconSize={26}
          onPress={() =>
            dispatch(
              editRelationship(
                user.id,
                !user.relationship_to_user.notifications
              )
            )
          }
          iconName={
            isPublic && user.relationship_to_user.notifications
              ? IoNotifications
              : IoNotificationsOff
          }
        />
      ) : (
        ''
      )}
    </Row>
  );
}

function UserStats(props) {
  const { user } = props;
  if (!user) return null;

  let units = user.stats.total_profit / user.stats.bet_size;
  if (isNaN(units)) {
    units = 0;
  }

  return (
    <Col desktop style={{ width: '100%', marginTop: '-4rem' }}>
      <Row
        style={{
          width: '100%',
          backgroundColor: 'var(--color-bg)',
          borderRadius: 'var(--std-border-radius)',
          padding: 'var(--space-sm)',
        }}
      >
        <Col>
          <small style={{ opacity: 0.75, fontWeight: 'bold' }}>RECORD</small>
          <b style={{ margin: 0 }}>
            {user.stats.W}-{user.stats.L}-{user.stats.P}
          </b>
        </Col>
        <Col>
          <small style={{ opacity: 0.75, fontWeight: 'bold' }}>
            TOTAL UNITS
          </small>
          <b
            style={{
              // fontSize: 'var(--text-xl)',
              color:
                units > 0
                  ? 'var(--color-dollar-green)'
                  : units === 0
                  ? 'var(--color-text)'
                  : 'var(--color-dollar-red)',
            }}
          >
            {units > 0 ? '+' : ''}
            {Math.round(units * 10) / 10}
          </b>
        </Col>
        <Col>
          <small style={{ opacity: 0.75, fontWeight: 'bold' }}>ROI</small>
          <AutoColoredAmount bold={'true'} symbol={'%'}>
            {user.stats.roi}
          </AutoColoredAmount>
        </Col>
        <Col>
          <small style={{ opacity: 0.75, fontWeight: 'bold' }}>CLV</small>
          <AutoColoredAmount bold={'true'} symbol={'%'}>
            {user.stats.clv}
          </AutoColoredAmount>
        </Col>
        <Col style={{ flex: 0 }}>
          <Link
            to={`/analysis?user=${user.id}&time_period=7&date_range=[null,null]&verified=true&`}
          >
            <IoAnalyticsSharp
              size={32}
              title={`ANALYZE ${
                user.username ? user.username.toUpperCase() : 'USER'
              }'S PICKS`}
              style={{ padding: '0 var(--space-sm)' }}
            />
          </Link>
        </Col>
      </Row>
    </Col>
  );
}
