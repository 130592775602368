import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { IoIosSearch } from 'react-icons/io';
import InfiniteScroll from 'react-infinite-scroll-component';

// components
import { AuthTextInput } from 'components/AuthTextInput';
import { Row, Col } from 'components/generic/Layout';
import ActivityIndicator from 'components/generic/ActivityIndicator';

// actions
import { search, getUsersNext } from 'actions';
import { AuthButton } from 'components/AuthButton';

const SearchResult = styled.div`
  flex: 0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  background-color: var(--color-fg);
  outline: none;
  padding: var(--space-sm);
  margin: var(--space-xxxs) var(--space-xxs);
  cursor: pointer;
  transition: all var(--std-transition);
  border-radius: var(--std-border-radius);
  border: 1px solid var(--color-text-light);
  color: var(--color-text);
  font-size: var(--text-base-size);
  &:hover {
    box-shadow: 0 2px 8px 0 var(--color-shadow);
  }
  &:active {
    background-color: var(--color-bg);
  }
`;

export default function AnalysisFilterUser(props) {
  // redux props
  const reduxProps = useSelector(state => ({
    user: state.authReducer.user,
    searchResults: state.searchReducer.searchResults,
    isLoading: state.searchReducer.isLoading,
    searchUsersNext: state.searchReducer.searchUsersNext,
  }));

  // redux dispatch
  const dispatch = useDispatch();

  const { user, searchResults, isLoading, searchUsersNext } = reduxProps;

  const [searchTerm, setSearchTerm] = useState('');

  // only search after user stops typing for 0.5 seconds
  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(search(searchTerm));
    }, 500);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  const _getUsersNext = () => {
    if (searchUsersNext) {
      dispatch(getUsersNext());
    }
  };

  return (
    <>
      <Row style={{ flex: 0, margin: 'var(--space-xxxs) 0' }}>
        <AuthTextInput
          autoFocus
          placeholder="Search Users"
          containerStyle={{
            flex: 1,
          }}
          onChangeText={newUserVal => {
            setSearchTerm(newUserVal);
          }}
          onEndEditing={() => {
            setSearchTerm('');
            dispatch(search(''));
          }}
          rightIcon={IoIosSearch}
          rightIconColor={'var(--color-text)'}
        />
      </Row>
      <SearchResult
        onClick={() => {
          props.onDone({
            filter: 'User',
            value: user.id,
            slug: user.username,
          });
        }}
      >
        {user.username ? user.username : ''} (You)
      </SearchResult>

      <Col
        style={{
          flexFlow: 'column nowrap',
          justifyContent: 'flex-start',
          alignItems: 'stretch',
          overflowY: 'auto',
        }}
      >
        {isLoading && <ActivityIndicator size={2} />}
        {searchResults.length !== 0 && !isLoading && (
          <>
            <InfiniteScroll
              scrollableTarget="analysis-infinite-scroll-target"
              scrollThreshold={0.66}
              next={_getUsersNext}
              hasMore={searchUsersNext}
              dataLength={searchResults.length}
              loader={<ActivityIndicator size={1.5} />}
              style={{
                overflow: 'hidden',
                minHeight: '100vh',
              }}
            >
              {searchResults.map((result, i) => (
                <SearchResult
                  key={`analysis-user-search-result-${i}-${result.id}`}
                  onClick={() => {
                    props.onDone({
                      filter: 'User',
                      value: result.id,
                      slug: result.username,
                    });
                  }}
                >
                  <p style={{ margin: 0 }}>{result.username}</p>
                </SearchResult>
              ))}
              {/* no idea why infinite scroll isn't working, probably something to do with the absolute positioning of the parent */}
              {searchUsersNext && (
                <AuthButton btnTheme="borderless" onPress={_getUsersNext}>
                  Load More
                </AuthButton>
              )}
            </InfiniteScroll>
          </>
        )}
      </Col>
    </>
  );
}
