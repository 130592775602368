import { Observable } from 'rxjs';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import { ajax } from 'rxjs/observable/dom/ajax';
import { getHeaders } from 'utils';

import {
  GET_LINE_HISTORY_NEXT,
  getLineHistorySuccess,
  getLineHistoryFailure,
} from 'actions';

export default function getLineHistoryNext(action$, state$) {
  return action$.ofType(GET_LINE_HISTORY_NEXT).switchMap(() => {
    let url = state$.value.gameReducer.lineHistoryNext;
    return ajax
      .getJSON(url, getHeaders(state$))
      .map(data => getLineHistorySuccess(data))
      .catch(error => Observable.of(getLineHistoryFailure(error.xhr)));
  });
}
