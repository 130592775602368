import { Observable } from 'rxjs';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import { ajax } from 'rxjs/observable/dom/ajax';
import { getHeaders } from 'utils';

import {
  GET_FOLLOWING_NEXT,
  getFollowingSuccess,
  getFollowingFailure,
} from 'actions';

export default function getFollowingNext(action$, state$) {
  return action$.ofType(GET_FOLLOWING_NEXT).switchMap(() => {
    return ajax
      .getJSON(state$.value.publicUserReducer.followingNext, getHeaders(state$))
      .map(data => getFollowingSuccess(data))
      .catch(error => Observable.of(getFollowingFailure(error.xhr)));
  });
}
