import {
  SEARCH,
  SEARCH_SUCCESS,
  SEARCH_FAILURE,
  UPDATE_FILTER_OPTIONS,
  RESET_ERRORS_SEARCH,
  GET_USERS_NEXT,
  FOLLOWED_USER_FROM_SEARCH,
} from 'actions';

const initialState = {
  searchTerm: '',
  searchResults: [],
  filterOptions: {
    timeFrame: 'This Week',
    sport: 'Football',
    betType: 'Bet Type',
    verified: false,
  },
  isLoading: false,
  fetchError: null,
  isNextUsersLoading: null,
  searchUsersNext: null,
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case SEARCH:
      return Object.assign({}, state, {
        ...state,
        isLoading: true,
        fetchError: null,
        searchResults: [],
        searchTerm: action.searchTerm,
        searchUsersNext: null,
        isNextUsersLoading: false,
      });

    case SEARCH_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        searchResults: state.searchResults.concat(action.payload.results),
        isLoading: false,
        searchUsersNext: action.payload.next,
        isNextUsersLoading: false,
      });
    case SEARCH_FAILURE:
      return Object.assign({}, state, {
        ...state,
        fetchError: action.payload,
        isLoading: false,
        searchUsersNext: null,
        isNextUsersLoading: false,
      });

    case UPDATE_FILTER_OPTIONS:
      return Object.assign({}, state, {
        ...state,
        filterOptions: action.options,
      });

    case RESET_ERRORS_SEARCH:
      return Object.assign({}, state, {
        ...state,
        fetchError: null,
      });

    case GET_USERS_NEXT:
      return Object.assign({}, state, {
        ...state,
        isNextUsersLoading: true,
        fetchError: null,
      });

    case FOLLOWED_USER_FROM_SEARCH:
      const afterFollowNewResults = state.searchResults.map((u) => {
        if (u.id === action.otherUserID) {
          return {
            ...u,
            relationship_to_user: action.payload.response.relationship_to_user,
          };
        }
        return u;
      });
      return Object.assign({}, state, {
        ...state,
        searchResults: afterFollowNewResults,
      });

    default:
      return state;
  }
}
