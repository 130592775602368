import React, { useState, useEffect } from 'react';
import moment from 'moment';

// utils
import { stringToArray } from 'utils';

// components
import { Row } from 'components/generic/Layout';
import { AuthButton } from 'components/AuthButton';
import DateRange from 'components/generic/DateRange';

function parseURLForDates(value) {
  const jsonvalue = stringToArray(value);
  const date_after = jsonvalue[0] === 'null' ? undefined : jsonvalue[0];
  const date_before = jsonvalue[1] === 'null' ? undefined : jsonvalue[1];

  return [date_after, date_before];
}

export default function AnalysisDateRange(props) {
  // const value = JSON.parse(props.defaultValue);
  const dates = parseURLForDates(props.defaultValue);
  const date_after = dates[0];
  const date_before = dates[1];

  const [date1, setDate1] = useState(date_after);
  const [date2, setDate2] = useState(date_before);

  useEffect(() => {
    let dates = parseURLForDates(props.defaultValue);

    setDate1(dates[0]);
    setDate2(dates[1]);
  }, [props.defaultValue]);

  return (
    <>
      <h6 style={{ margin: 'var(--space-xs) 0' }}>Date Range</h6>
      <p style={{ margin: 'var(--space-xs) 0' }}>
        Include bets between these dates. If the Time Period is set, this filter
        will be ignored
      </p>

      <Row style={{ flex: 0 }}>
        <DateRange
          containerStyle={{ maxWidth: '310px' }}
          // defaultStartDate={moment(date1) || date1}
          // defaultEndDate={moment(date2) || date2}
          defaultStartDate={date1}
          defaultEndDate={date2}
          getItemsInRange={(d1, d2) => {
            setDate1(moment(d1));
            setDate2(moment(d2).endOf('day'));
          }}
        />
      </Row>

      <br />
      <AuthButton
        onPress={() => {
          let value = '';
          let slug = '';
          let d1 = moment(date1);
          let d2 = moment(date2);
          if (date1 && date2) {
            value += '["' + d1.toISOString() + '","' + d2.toISOString() + '"]';
            slug = d1.format('ddd MMM D') + ' - ' + d2.format('ddd MMM D');
          } else if (date1) {
            value += '["' + d1.toISOString() + '",null]';
            slug = d1.format('ddd MMM D') + ' - End of Time';
          } else if (date2) {
            value += '[null,"' + d2.toISOString() + '"]';
            slug = 'Beginning of Time - ' + d2.format('ddd MMM D');
          } else {
            value = '[null,null]';
            slug = 'All Time';
          }

          props.onDone({ filter: 'Date_Range', value: value, slug: slug });
          // reset time period filter when date is set
          props.onDone({
            filter: 'Time_Period',
            title: 'Time Period',
            value: 'null',
            slug: 'All Time',
          });
        }}
      >
        Done
      </AuthButton>
    </>
  );
}
