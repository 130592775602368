import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// components
import { BetstampStampInverted } from 'components/generic/Logos';
import { Col, Row } from 'components/generic/Layout';
import { AuthButton } from 'components/AuthButton';
import SignupWrapper from 'components/SignupWrapper';

// utils
import { preloadImageHelper } from 'utils';

// actions
import {
  getAllBooks,
  getAffiliates,
  getFeatured,
  getRecommendedUsers,
  setTempUserStates,
} from 'actions';

export default function OnboardingWrapper(props) {
  const dispatch = useDispatch();
  const history = useHistory();

  const reduxProps = useSelector(state => ({
    featured: state.featuredReducer.featured,
    recommended: state.featuredReducer.recommended,
    affiliates: state.affiliatesReducer.affiliates,
    allBooks: state.authReducer.allBooks,
    tempUser: state.authReducer.tempUser,
  }));
  const { allBooks, affiliates, featured, recommended, tempUser } = reduxProps;

  const {
    showSkip,
    nextScreen,
    hideNext,
    nextDisabled,
    hideBetstamp,
    nextText,
    onNext,
  } = props;

  useEffect(() => {
    // cache sportsbook images for smoother UI experience
    if (allBooks.length > 0 && !tempUser.isBookBannerPreloaded) {
      let preFetchTasks = [];
      allBooks.forEach(book => {
        ['generic_banner', 'open_banner', 'logo'].forEach(urlKey => {
          if (book[urlKey] && book[urlKey].includes('digital')) {
            preFetchTasks.push(preloadImageHelper(book[urlKey]));
          }
        });
      });

      const promiseHandler = () =>
        dispatch(
          setTempUserStates({ ...tempUser, isBookBannerPreloaded: true })
        );
      // Cache images once per onboarding session
      Promise.all(preFetchTasks).then(promiseHandler, promiseHandler);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allBooks, tempUser]);

  // Pre-fetching for smoother UI experience
  useEffect(() => {
    if (nextScreen) {
      if (nextScreen.includes('SB')) {
        if (featured.length === 0) {
          dispatch(getFeatured());
        }
        if (recommended.length === 0) {
          dispatch(getRecommendedUsers());
        }
        if (affiliates.length === 0) {
          dispatch(getAffiliates());
        }
      } else if (
        nextScreen === '/onboarding/odds-compare' &&
        !tempUser.oddsCompareImgLoaded
      ) {
        preloadImageHelper(
          'https://betstamp-public.sfo2.cdn.digitaloceanspaces.com/signup-flow/compare-odds.png'
        ).then(() => {
          dispatch(
            setTempUserStates({ ...tempUser, oddsCompareImgLoaded: true })
          );
        });

        if (affiliates.length === 0) {
          dispatch(getAffiliates());
        }
        if (allBooks.length === 0) {
          dispatch(getAllBooks());
        }
      } else if (
        nextScreen === '/onboarding/find-users' &&
        !tempUser.linkBooksImgLoaded
      ) {
        preloadImageHelper(
          'https://betstamp-public.sfo2.cdn.digitaloceanspaces.com/signup-flow/betlink.png'
        ).then(() =>
          dispatch(setTempUserStates({ ...tempUser, linkBooksImgLoaded: true }))
        );
      } else if (
        nextScreen === '/onboarding/meantime' &&
        !tempUser.meantimeImgLoaded
      ) {
        preloadImageHelper(
          'https://betstamp-public.sfo2.cdn.digitaloceanspaces.com/signup-flow/track-bets.png'
        ).then(() =>
          dispatch(setTempUserStates({ ...tempUser, meantimeImgLoaded: true }))
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    nextScreen,
    tempUser,
    featured.length,
    allBooks.length,
    affiliates.length,
    recommended.length,
  ]);

  const _goToNextPage = () => {
    history.push({
      pathname: nextScreen,
    });
  };

  return (
    <SignupWrapper>
      <Col
        style={{
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        <Col style={{ flex: 1, width: '100%', justifyContent: 'flex-start' }}>
          {!hideBetstamp && (
            <Row style={{ alignItems: 'center', flex: 0 }}>
              <BetstampStampInverted height={64} width={64} />
            </Row>
          )}
          {props.children}
        </Col>
        <Row
          style={{
            flex: 0,
            width: '100%',
          }}
        >
          {showSkip && (
            <AuthButton
              colorTheme="text"
              btnTheme="borderless"
              containerStyle={{
                maxWidth: '50%',
                marginRight: 'var(--space-sm)',
              }}
              onPress={_goToNextPage}
            >
              Skip for now
            </AuthButton>
          )}
          {!hideNext && (
            <AuthButton
              disabled={nextDisabled}
              containerStyle={{ maxWidth: '50%' }}
              btnTheme="borderless"
              colorTheme="inverted"
              onPress={() => {
                onNext();
                _goToNextPage();
              }}
            >
              {nextText || 'Next'}
            </AuthButton>
          )}
        </Row>
      </Col>
    </SignupWrapper>
  );
}
