import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useEffectOnce, useUpdateEffect } from 'react-use';
import {
  IoCheckmarkCircleOutline,
  IoCloseCircleOutline,
} from 'react-icons/io5';

// components
import ActivityIndicator from 'components/generic/ActivityIndicator';
import { Col } from 'components/generic/Layout';

// actions
import { getPlayerBoxscores, getPlayerBoxscoresNext } from 'actions';

export default function Boxscores() {
  const dispatch = useDispatch();
  const reduxProps = useSelector(state => ({
    game: state.gameReducer.game,
    playerBoxscores: state.gameReducer.playerBoxscores,
    isLoadingPlayerBoxscores: state.gameReducer.isLoadingPlayerBoxscores,
    playerBoxscoresError: state.gameReducer.playerBoxscoresError,
    isLoadingPlayerBoxscoresNext:
      state.gameReducer.isLoadingPlayerBoxscoresNext,
    playerBoxscoresNext: state.gameReducer.playerBoxscoresNext,
  }));
  const {
    game,
    playerBoxscores,
    isLoadingPlayerBoxscores,
    playerBoxscoresError,
    isLoadingPlayerBoxscoresNext,
    playerBoxscoresNext,
  } = reduxProps;

  useEffectOnce(() => dispatch(getPlayerBoxscores(game.id)));

  const _getNext = () => {
    if (playerBoxscoresNext && !isLoadingPlayerBoxscoresNext) {
      dispatch(getPlayerBoxscoresNext());
    }
  };
  useUpdateEffect(() => {
    _getNext();
  }, [playerBoxscoresNext]);

  if (isLoadingPlayerBoxscores) {
    return (
      <Col style={{ height: '75%', justifyContent: 'center' }}>
        <ActivityIndicator size={3} />
      </Col>
    );
  }

  if (playerBoxscoresError) {
    return (
      <h5 style={{ textAlign: 'center' }}>
        We ran into an error getting the boxscore
      </h5>
    );
  }

  return (
    <div style={{ minHeight: '100vh' }}>
      {playerBoxscores && playerBoxscores?.length === 0 && (
        <h5 style={{ textAlign: 'center' }}>
          We don't have the boxscore for this game
        </h5>
      )}
      {playerBoxscores && playerBoxscores?.length > 0 && (
        <table
          cellSpacing={0}
          style={{
            margin: 'auto',
            marginTop: 'var(--space-sm)',
            textAlign: 'center',
            width: '100%',
            tableLayout: 'fixed',
          }}
        >
          <thead>
            <tr>
              <td
                style={{
                  borderBottom: '2px solid var(--color-text-light)',
                }}
                colSpan={2}
              >
                <b style={{ flex: 1 }}>Player</b>
              </td>
              {Object.entries(playerBoxscores[0]).map(([k, v]) => {
                if (k === 'player' || k === 'game') return null;
                return (
                  <td
                    style={{
                      borderBottom: '2px solid var(--color-text-light)',
                    }}
                  >
                    <b style={{ flex: 1 }}>{k.split('_').join(' ')}</b>
                  </td>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {playerBoxscores.map(boxscore => (
              <tr>
                {Object.entries(boxscore).map(([k, v]) => {
                  if (k === 'player')
                    return (
                      <td
                        colSpan={2}
                        style={{
                          borderBottom: '1px solid var(--color-text-light)',
                          padding: 'var(--space-xs)',
                          hyphens: 'auto',
                          wordBreak: 'break-word',
                        }}
                      >
                        <b>{v?.name}</b>
                      </td>
                    );
                  if (k === 'game') return null;
                  if (k !== 'player' && k !== 'game') {
                    return (
                      <td
                        style={{
                          borderBottom: '1px solid var(--color-text-light)',
                          padding: 'var(--space-xs)',
                        }}
                        title={`${k.split('_').join(' ')}: ${v || '—'}`}
                      >
                        {k === 'played' ? (
                          v ? (
                            <IoCheckmarkCircleOutline
                              size={24}
                              style={{ color: 'var(--color-success)' }}
                            />
                          ) : (
                            <IoCloseCircleOutline
                              size={24}
                              style={{ color: 'var(--color-danger)' }}
                            />
                          )
                        ) : (
                          v || '—'
                        )}
                      </td>
                    );
                  }
                  return null;
                })}
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
}
