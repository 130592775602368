import React, { useState, useEffect } from 'react';

// utils
import { stringToArray } from 'utils';

import ListOptionBtn from '../ListOptionBtn';
import TeamLeagueLogo from 'components/TeamLeagueLogo';
import { AuthButton } from 'components/AuthButton';
import { AuthTextInput } from 'components/AuthTextInput';

//utils
import { LEAGUES } from 'utils';

export default function League(props) {
  const [selectedOptions, setSelectedOptions] = useState(
    props.defaultValue ? stringToArray(props.defaultValue) : []
  );
  const [customLeague, setCustomLeague] = useState('');

  const [availableLeagues, setAvailableLeagues] = useState(LEAGUES);

  useEffect(() => {
    setSelectedOptions(
      props.defaultValue ? stringToArray(props.defaultValue) : []
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.defaultValue]);

  return (
    <div style={{ paddingBottom: 'var(--space-xs' }}>
      <h6 style={{ margin: 'var(--space-xs) 0' }}>League</h6>
      <p style={{ margin: 'var(--space-xs) 0' }}>
        Include only bets from these leagues
      </p>

      <div
        style={{
          width: '100%',
          justifyContent: 'flex-start',
          margin: '0 0 var(--space-md) 0',
        }}
      >
        <AuthTextInput
          label=""
          placeholder="Custom League"
          type="text"
          onChangeText={t => setCustomLeague(t)}
          helpText="Filter analysis based on a custom league"
          containerStyle={{ margin: 'var(--space-xs) 0 0 0' }}
        />

        <AuthButton
          onPress={() => {
            if (
              customLeague !== '' &&
              !availableLeagues.some(league => league.league === customLeague)
            ) {
              let newOptions = Array.from(selectedOptions);
              newOptions.push(customLeague);
              setSelectedOptions(newOptions);
              setAvailableLeagues([
                { league: customLeague },
                ...availableLeagues,
              ]);
            }
          }}
          containerStyle={{
            maxWidth: 'fit-content',
            margin: 'var(--space-xs)',
            flex: 0,
          }}
        >
          Add League
        </AuthButton>
      </div>

      <ListOptionBtn
        onClick={() => setSelectedOptions([])}
        selected={selectedOptions.length === 0}
        text={'All Leagues'}
      />
      {availableLeagues.map(item => (
        <ListOptionBtn
          key={`analysis-filter-leagues-${item.league}`}
          onClick={() => {
            let newOptions = Array.from(selectedOptions);
            let index = newOptions.findIndex(opt => item.league === opt);
            if (index > -1) {
              newOptions.splice(index, 1);
            } else {
              newOptions.push(item.league);
            }

            if (newOptions.length === 0) newOptions = [];

            setSelectedOptions(newOptions);
          }}
          selected={
            selectedOptions.filter(opt => opt === item.league).length > 0
          }
          text={item.league}
          Icon={() => (
            <TeamLeagueLogo
              iconSize={30}
              displayTeam={false}
              league={item.league}
              style={{ marginLeft: 'var(--space-sm)' }}
            />
          )}
        />
      ))}
      <br />
      <AuthButton
        containerStyle={{
          margin: 0,
        }}
        onPress={() => {
          let slug = selectedOptions.join(', ');
          let value =
            '[' + selectedOptions.map(opt => '"' + opt + '"').toString() + ']';
          if (value === '[""]' || value === '[]') {
            value = '[]';
            slug = 'All';
          }

          props.onDone({
            filter: 'Leagues',
            value: value,
            slug: slug,
          });
        }}
      >
        Done
      </AuthButton>
    </div>
  );
}
