import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// import { IoTrophySharp } from 'react-icons/io5';
// import moment from 'moment';

// import { __DEV__ } from 'utils';

// components
import {
  Toolbar,
  MainAreaWrapper,
  InnerMainArea,
  Row,
  Col,
  HorizontalScroller,
} from 'components/generic/Layout';
import SearchBarUsers from 'components/SearchBarUsers';
import MediaListItem from 'components/MediaListItem';
import FeaturedUser from 'components/FeaturedUser';
//import FeaturedPackage from 'components/FeaturedPackage';
import FeaturedCompetition from 'components/FeaturedCompetition';
import ModalWrapper from 'components/generic/ModalWrapper';
import ContentLoader from 'components/generic/ContentLoader';
import AffiliateLink from 'components/AffiliateLink';
import { LinkButton } from 'components/AuthButton';
import BGA from 'images/begambleaware.png';
import BGAWhite from 'images/begambleaware_white.png';

// modals
import BuyPackage from 'components/modals/BuyPackage';

// actions
import {
  resetStripeErrors,
  // getLeaderboard
} from 'actions';
// import ProfilePic from 'components/generic/ProfilePic';
// import AutoColoredAmount from 'components/generic/AutoColoredAmount';

export default function MarketplaceMobile() {
  const [modalIsOpen, setModalisOpen] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState(null);

  // redux props
  const reduxProps = useSelector(state => ({
    user: state.authReducer.user,
    following: state.publicUserReducer.following,
    fetchError: state.publicUserReducer.fetchError,
    featured: state.featuredReducer.featured,
    featuredIsLoading: state.featuredReducer.isLoading,
    featuredFetchError: state.featuredReducer.fetchError,
    followingNext: state.publicUserReducer.followingNext,
    isLoadingFollowing: state.publicUserReducer.isLoadingFollowing,
    leaderboard: state.publicUserReducer.leaderboard,
    affiliates: state.affiliatesReducer.affiliates,
    competitions: state.competitionsReducer.competitions,
    competitionsIsLoading: state.featuredReducer.isLoadingCompetitions,
    competitionsFetchError: state.featuredReducer.fetchCompetitionsError,
    theme: state.settingsReducer.theme,
  }));

  // redux dispatch
  const dispatch = useDispatch();

  const {
    user,
    following,
    fetchError,
    featured,
    featuredIsLoading,
    featuredFetchError,
    isLoadingFollowing,
    // leaderboard,
    affiliates,
    competitions,
    competitionsIsLoading,
    competitionsFetchError,
    theme,
  } = reduxProps;

  const mediaUsers = featured.filter(f => f.media_account);

  const featuredUsers = featured.filter(f => f.is_featured);
  const featuredPackages = featured.filter(f => f.type === 'featured_packages');

  // useEffect(() => {
  //   if (leaderboard.length !== 3) {
  //     let today = moment();
  //     let lastWeekDate = moment(today).subtract(__DEV__ ? 100 : 7, 'days');
  //     dispatch(
  //       getLeaderboard(
  //         3,
  //         null,
  //         'roi',
  //         lastWeekDate,
  //         today,
  //         false,
  //         __DEV__ ? 1 : 10
  //       )
  //     );
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [leaderboard.length]);

  return (
    <>
      <ModalWrapper
        modalIsOpen={modalIsOpen}
        onClose={() => {
          dispatch(resetStripeErrors());
          setModalisOpen(false);
        }}
        onRequestClose={() => {
          dispatch(resetStripeErrors());
          setModalisOpen(false);
        }}
        modalSize={'small'}
      >
        <BuyPackage
          selectedPackage={selectedPackage}
          dismissModal={() => {
            dispatch(resetStripeErrors());
            setModalisOpen(false);
          }}
        />
      </ModalWrapper>

      <Toolbar>
        <Row style={{ maxWidth: '750px', margin: 'auto' }}>
          <SearchBarUsers />
        </Row>
      </Toolbar>
      <MainAreaWrapper>
        <InnerMainArea style={{ alignItems: 'stretch' }}>
          <div
            style={{
              height: '100%',
              overflowY: 'auto',
              overflowX: 'hidden',
              boxSizing: 'border-box',
              paddingBottom: 'var(--space-xl)',
            }}
          >
            <div
              style={{
                width: '100%',
                maxWidth: '1266px',
                margin: '0 auto',
                paddingBottom: 'var(--space-xxxl)',
              }}
            >
              {featuredFetchError ? (
                <h6
                  style={{ textAlign: 'center', marginTop: 'var(--space-sm)' }}
                >
                  Sorry, we ran into an error getting featured content. Check
                  back later!
                </h6>
              ) : (
                <>
                  <h5 style={{ margin: 'var(--space-sm)' }}>Media Picks</h5>
                  <HorizontalScroller>
                    {featuredIsLoading && mediaUsers.length === 0 && (
                      <>
                        {[1, 2, 3, 4].map(i => (
                          <Col
                            key={`feature-user-loader-${i}`}
                            style={{
                              minHeight: '122px',
                              width: '98px',
                              padding: '0 var(--space-md)',
                            }}
                          >
                            <ContentLoader
                              height={'64px'}
                              style={{
                                width: '64px',
                                borderRadius: '100%',
                                marginBottom: '4px',
                              }}
                            />
                            <ContentLoader
                              height={'18px'}
                              style={{
                                width: '99%',
                                margin: 'auto',
                                marginBottom: '3px',
                              }}
                            />
                            <ContentLoader
                              height={'24px'}
                              style={{ width: '99%', margin: 'auto' }}
                            />
                          </Col>
                        ))}
                      </>
                    )}
                    {mediaUsers.length === 0 && !featuredIsLoading ? (
                      <p>No media picks right now. Check back later!</p>
                    ) : (
                      <HorizontalScroller>
                        {mediaUsers.map((media, i) => (
                          <MediaListItem
                            style={{
                              margin: '0 var(--space-xs)',
                              fontSize: '80%',
                            }}
                            key={`marketplace-mediapicks-${media.id}-${i}`}
                            user={media}
                          />
                        ))}
                        {/* for spacing */}
                        <div style={{ padding: '0 var(--space-xs)' }} />
                      </HorizontalScroller>
                    )}
                  </HorizontalScroller>

                  <h5 style={{ margin: 'var(--space-sm)' }}>Featured Users</h5>
                  <HorizontalScroller>
                    {featuredIsLoading &&
                      featuredUsers.length === 0 &&
                      [1, 2, 3, 4].map(i => (
                        <ContentLoader
                          key={`feature-user-loader-${i}`}
                          height={'150px'}
                          style={{
                            minWidth: '128px',
                            borderRadius: 'var(--std-border-radius)',
                            margin: '0 var(--space-xs)',
                          }}
                        />
                      ))}
                    {featuredUsers.length === 0 && !featuredIsLoading ? (
                      <p>No featured users right now. Check back later!</p>
                    ) : (
                      <>
                        {featuredUsers.map((u, i) => (
                          <FeaturedUser
                            style={{
                              minWidth: '128px',
                              margin: '0 var(--space-xs)',
                            }}
                            mobile
                            key={`featured-user-${u.id}-${i}`}
                            user={u}
                            hightlightStats={['roi', 'clv']}
                            featured
                          />
                        ))}
                        {/* for spacing */}
                        <div style={{ padding: '0 var(--space-xs)' }} />
                      </>
                    )}
                  </HorizontalScroller>

                  {/*
                  <br />
                  <h5 style={{ margin: 'var(--space-sm)' }}>
                    Featured Packages
                  </h5>
                  <HorizontalScroller>
                    {featuredIsLoading &&
                      featuredPackages.length === 0 &&
                      [1, 2, 3].map(i => (
                        <ContentLoader
                          key={`feature-package-loader-${i}`}
                          height={'214px'}
                          style={{
                            minWidth: '158px',
                            borderRadius: 'var(--std-border-radius)',
                            margin: '0 var(--space-xs)',
                          }}
                        />
                      ))}
                    {featuredPackages.map((p, i) => (
                      <FeaturedPackage
                        style={{
                          minWidth: '158px',
                          margin: '0 var(--space-xs)',
                        }}
                        key={`feature-package-${p.id}-${i}`}
                        pack={p}
                        onClick={() => {
                          setSelectedPackage(p);
                          setModalisOpen(true);
                        }}
                      />
                    ))}
                    <div style={{ padding: '0 var(--space-xs)' }} />
                  </HorizontalScroller>
                  */}
                </>
              )}
              {competitions && competitions.length > 0 && (
                <>
                  <br />
                  <h5 style={{ margin: 'var(--space-sm)' }}>
                    Featured Competitions
                  </h5>
                  {competitionsFetchError ? (
                    <h5>
                      Sorry, we ran into an error getting competitions. Check
                      back later!
                    </h5>
                  ) : (
                    <>
                      <HorizontalScroller>
                        {competitionsIsLoading &&
                          competitions.length === 0 &&
                          [1].map(i => (
                            <ContentLoader
                              key={`competition-loader-mobile-${i}`}
                              height={'145px'}
                              style={{
                                borderRadius: 'var(--std-border-radius)',
                              }}
                            />
                          ))}

                        {competitions.map((c, i) => (
                          <FeaturedCompetition
                            key={`feature-competition-mobile-${c.id}-${i}`}
                            competition={c}
                            style={{
                              minWidth: '128px',
                              margin: '0 var(--space-xs)',
                            }}
                          />
                        ))}
                      </HorizontalScroller>
                    </>
                  )}
                </>
              )}

              <br />
              <h5 style={{ margin: 'var(--space-sm)' }}>Following</h5>
              {fetchError && (
                <h6
                  style={{ textAlign: 'center', marginTop: 'var(--space-sm)' }}
                >
                  Sorry, we ran into an error getting users you follow. Check
                  back later!
                </h6>
              )}
              <HorizontalScroller>
                {isLoadingFollowing &&
                  following.length === 0 &&
                  [1, 2, 3, 4, 5].map(i => (
                    <ContentLoader
                      key={`following-user-loader-${i}`}
                      height={'150px'}
                      style={{
                        minWidth: '128px',
                        borderRadius: 'var(--std-border-radius)',
                        margin: '0 var(--space-xs)',
                      }}
                    />
                  ))}
                {following.slice(0, 5).map((u, i) => (
                  <FeaturedUser
                    mobile
                    key={`following-user-${u.id}-${i}`}
                    user={u}
                    hightlightStats={['pending', 'roi']}
                    showRecord
                    disablePopup
                    style={{
                      border: 'none',
                      minWidth: '128px',
                      margin: '0 var(--space-xs)',
                    }}
                  />
                ))}
                {/* for spacing */}
                <div style={{ padding: '0 var(--space-xs)' }} />
              </HorizontalScroller>
              {!isLoadingFollowing && !fetchError && following.length === 0 && (
                <p
                  style={{ textAlign: 'center', marginTop: 'var(--space-xs)' }}
                >
                  You're not following anyone! Check out the featured users
                  above.
                </p>
              )}
              {user.username && (
                <Row style={{ marginTop: 'var(--space-md)' }}>
                  <LinkButton to={`/u/${user.username}`}>
                    View My Profile
                  </LinkButton>
                </Row>
              )}

              {/* {leaderboard.length >= 3 && (
                <>
                  <br />
                  <h5 style={{ margin: 'var(--space-sm)', marginBottom: 0 }}>
                    betstamp Top 3
                  </h5>

                  <div style={{ padding: '0 var(--space-sm)' }}>
                    <small>
                      <i>
                        Top 3 users by ROI with at least 10 bets in the last 7
                        days
                      </i>
                    </small>
                  </div>

                  <Col
                    style={{ margin: 'var(--space-sm)', alignItems: 'stretch' }}
                  >
                    <Link
                      style={{ textDecoration: 'none' }}
                      to={`/u/${leaderboard[0].username}`}
                    >
                      <Row
                        style={{
                          alignItems: 'center',
                          justifyContent: 'flex-start',
                          marginBottom: 'var(--space-sm)',
                        }}
                      >
                        <ProfilePic
                          user={leaderboard[0]}
                          style={{ width: '52px', height: '52px' }}
                        />
                        <h6 style={{ flex: 1, margin: '0 var(--space-sm)' }}>
                          {leaderboard[0].username}
                        </h6>
                        <AutoColoredAmount bold="true" symbol={'%'}>
                          {leaderboard[0].stats['roi']}
                        </AutoColoredAmount>
                        <IoTrophySharp
                          size={28}
                          color={'#ffd700'}
                          style={{
                            padding: 'var(--space-xxs)',
                            backgroundColor: '#141100',
                            borderRadius: '28px',
                            height: '28px',
                            width: '28px',
                          }}
                        />
                      </Row>
                    </Link>

                    <Link
                      style={{ textDecoration: 'none' }}
                      to={`/u/${leaderboard[1].username}`}
                    >
                      <Row
                        style={{
                          alignItems: 'center',
                          justifyContent: 'flex-start',
                          marginBottom: 'var(--space-sm)',
                        }}
                      >
                        <ProfilePic
                          user={leaderboard[1]}
                          style={{ width: '52px', height: '52px' }}
                        />
                        <h6 style={{ flex: 1, margin: '0 var(--space-sm)' }}>
                          {leaderboard[1].username}
                        </h6>
                        <AutoColoredAmount bold="true" symbol={'%'}>
                          {leaderboard[1].stats['roi']}
                        </AutoColoredAmount>
                        <IoTrophySharp
                          size={28}
                          color={'#c0c0c0'}
                          style={{
                            padding: 'var(--space-xxs)',
                            backgroundColor: '#060606',
                            borderRadius: '28px',
                            height: '28px',
                            width: '28px',
                          }}
                        />
                      </Row>
                    </Link>

                    <Link
                      style={{ textDecoration: 'none' }}
                      to={`/u/${leaderboard[2].username}`}
                    >
                      <Row
                        style={{
                          alignItems: 'center',
                          justifyContent: 'flex-start',
                          marginBottom: 'var(--space-sm)',
                        }}
                      >
                        <ProfilePic
                          user={leaderboard[2]}
                          style={{ width: '52px', height: '52px' }}
                        />
                        <h6 style={{ flex: 1, margin: '0 var(--space-sm)' }}>
                          {leaderboard[2].username}
                        </h6>
                        <AutoColoredAmount bold="true" symbol={'%'}>
                          {leaderboard[2].stats['roi']}
                        </AutoColoredAmount>
                        <IoTrophySharp
                          size={28}
                          color={'#cd7f32'}
                          style={{
                            padding: 'var(--space-xxs)',
                            backgroundColor: '#020100',
                            borderRadius: '28px',
                            height: '28px',
                            width: '28px',
                          }}
                        />
                      </Row>
                    </Link>
                  </Col>

                  <Row>
                    <LinkButton
                      to="/leaderboard"
                    >
                      View Full Leaderboard
                    </LinkButton>
                  </Row>
                </>
              )} */}

              <Row style={{ marginTop: 'var(--space-md)' }}>
                <LinkButton to="/leaderboard">View Leaderboard</LinkButton>
              </Row>

              <br />

              {affiliates.length > 0 && (
                <>
                  <h5 style={{ margin: 'var(--space-sm)' }}>Featured Books</h5>
                  <HorizontalScroller>
                    {affiliates.map(book => (
                      <div
                        style={{ margin: '0 var(--space-xs)' }}
                        key={`accounting-affiliate-marketplace-mobile-${book.id}`}
                      >
                        <AffiliateLink
                          book={book}
                          fromPage="Marketplace Web Mobile"
                          shape="box"
                          containerStyle={{ minWidth: '196px' }}
                        />
                      </div>
                    ))}
                  </HorizontalScroller>
                  <div style={{ margin: 'var(--space-sm)' }}>
                    <p>
                      Opening
                      <b> as many sportsbook accounts </b>
                      as possible guarantees you get the best odds and the
                      biggest bonuses.
                    </p>
                    <p>
                      <i>
                        When you sign up by clicking above, betstamp gets a
                        referral fee and you get the signup bonus. This helps us
                        improve the app and keep it free to use.
                      </i>
                    </p>
                    <p>
                      <b>Thanks for your support!</b>
                    </p>
                  </div>
                </>
              )}
              <Row
                style={{
                  alignItems: 'center',
                  marginTop: 'var(--space-md)',
                  padding: '0 var(--space-xs)',
                }}
              >
                <Col
                  style={{
                    flex: '0 0 42px',
                    width: '42px',
                    height: '42px',
                    border: '2px solid var(--color-danger)',
                    borderRadius: '42px',
                    fontWeight: 'bold',
                    marginRight: 'var(--space-xxs)',
                  }}
                >
                  18+
                </Col>
                <span style={{ flex: 1 }}>
                  Please Gamble Responsibly. Visit{' '}
                  <a href="https://www.gambleaway.org/">www.gambleaware.org</a>{' '}
                  for more info.
                </span>
              </Row>
              {theme === 'dark' ? (
                <img
                  style={{ height: '32px', width: 'auto' }}
                  src={BGAWhite}
                  alt="Be Gamble Aware"
                />
              ) : (
                <img
                  style={{ height: '32px', width: 'auto' }}
                  src={BGA}
                  alt="Be Gamble Aware"
                />
              )}
            </div>
          </div>
        </InnerMainArea>
      </MainAreaWrapper>
    </>
  );
}
