import React, { useState, useEffect } from 'react';

import { stringToArray } from 'utils';

import ListOptionBtn from '../ListOptionBtn';
import { AuthButton } from 'components/AuthButton';

const OPTIONS = [
  { value: 'FT', label: 'Full Game' },
  { value: '1H', label: '1st Half' },
  { value: '2H', label: '2nd Half' },
];
export default function GamePeriod(props) {
  const [selectedOptions, setSelectedOptions] = useState(
    props.defaultValue ? stringToArray(props.defaultValue) : []
  );

  useEffect(() => {
    setSelectedOptions(
      props.defaultValue ? stringToArray(props.defaultValue) : []
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.defaultValue]);

  return (
    <>
      <h6 style={{ margin: 'var(--space-xs) 0' }}>Game Period</h6>
      <p style={{ margin: 'var(--space-xs) 0' }}>
        Include only bets with these game periods
      </p>
      <ListOptionBtn
        onClick={() => setSelectedOptions([])}
        selected={selectedOptions.length === 0}
        text={'All Periods'}
      />
      {OPTIONS.map(item => (
        <ListOptionBtn
          key={`analysis-filter-periods-${item.value}`}
          onClick={() => {
            let newOptions = Array.from(selectedOptions);
            let index = newOptions.findIndex(opt => item.value === opt);
            if (index > -1) {
              newOptions.splice(index, 1);
            } else {
              newOptions.push(item.value);
            }
            if (newOptions.length === 0) newOptions = [];

            setSelectedOptions(newOptions);
          }}
          selected={
            selectedOptions.filter(opt => opt === item.value).length > 0
          }
          text={item.label}
        />
      ))}
      <br />
      <AuthButton
        onPress={() => {
          let slug = selectedOptions.join(', ');
          let value;
          if (slug === '1H') {
            value = '["1H","1S","1P","F5"]';
          } else {
            value =
              '[' +
              selectedOptions.map(opt => '"' + opt + '"').toString() +
              ']';
          }
          if (value === '[""]' || value === '[]') {
            value = '[]';
            slug = 'All';
          }
          props.onDone({
            filter: 'Game_Period',
            value: value,
            slug: slug,
          });
        }}
      >
        Done
      </AuthButton>
    </>
  );
}
