import { BACKEND_API_URL } from 'utils';
import { getBaseHeaders, handleRes } from './privateFns.js';

export async function defaultMutationFn({ endpoint, body, method, skipSlash }) {
  let url = `${BACKEND_API_URL}api/${endpoint}`;
  if (!skipSlash) url += '/';

  let headers = getBaseHeaders();

  if (!(body instanceof FormData)) {
    headers['Content-Type'] = 'application/json';
    body = JSON.stringify(body);
  }

  const res = await fetch(url, {
    method: method || 'POST',
    headers: headers,
    body: body,
  });

  return handleRes(res);
}
