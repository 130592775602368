import { Observable } from 'rxjs';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import { ajax } from 'rxjs/observable/dom/ajax';
import { BACKEND_API_URL, getHeaders } from 'utils';

import {
  GET_SPORTSBOOK_REVIEW,
  getSportsbookReviewSuccess,
  getSportsbookReviewFailure,
} from 'actions';

export default function getSportsbookReview(action$, state$) {
  return action$.ofType(GET_SPORTSBOOK_REVIEW).switchMap(action => {
    let url = BACKEND_API_URL + `api/sportsbook-reviews/${action.slug}/`;

    return ajax
      .getJSON(url, getHeaders(state$))
      .map(data => getSportsbookReviewSuccess(data))
      .catch(error => Observable.of(getSportsbookReviewFailure(error.xhr)));
  });
}
