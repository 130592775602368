import React, { useState } from 'react';
import {
  IoAlertCircleOutline,
  IoCheckmarkCircleOutline,
  IoCloseCircleOutline,
  IoGameControllerOutline,
} from 'react-icons/io5';
import { useQuery } from '@tanstack/react-query';

// components
import { Row, Col } from 'components/generic/Layout';
import StaffNavBar from 'components/nav/StaffNavBar';
import BooksDropdown from 'components/BooksDropdown';
import ActivityIndicator from 'components/generic/ActivityIndicator';
import { AuthTextInput } from 'components/AuthTextInput';

export default function CheckBookData() {
  const [bookIds, setBookIds] = useState(null);

  const [refCode, setRefCode] = useState(null);

  const fetchReport = useQuery({
    enabled: !!bookIds,
    queryKey: [
      {
        endpoint: `onboarding-check-book-data`,
        urlParams: {
          book_ids: JSON.stringify(bookIds),
          referral_code_code: refCode,
        },
      },
    ],
  });

  const checkRefCodeExists = useQuery({
    refetchOnWindowFocus: false,
    enabled: !!refCode,
    queryKey: [
      {
        endpoint: 'referral_codes/exists',
        urlParams: { code: refCode },
      },
    ],
  });

  return (
    <>
      <StaffNavBar />

      <div
        style={{
          width: '100%',
          maxWidth: '1280px',
          padding: 'var(--space-md) var(--space-xs)',
          margin: '0 auto',
        }}
      >
        <Row
          style={{
            alignItems: 'center',
            gap: 'var(--space-md)',
            margin: 'var(--space-md) 0',
          }}
        >
          <p style={{ fontWeight: 'bold' }}>
            Use this tool to verify that a sportsbook has all required data to
            work in the Sportsbook Assist Tool.
          </p>
          <Col>
            <IoCloseCircleOutline
              size={32}
              color="var(--color-danger)"
              title="Critical Failure. It won't work at all, attendees are likely to get stuck."
            />
            <>Critical Failure</>
          </Col>
          <Col>
            <IoCloseCircleOutline
              size={32}
              color="var(--color-complement)"
              title="Non-critical Failure. It will work, just some data will be missing or not optimal."
            />
            <>Non-critical Failure</>
          </Col>
          <Col>
            <IoCheckmarkCircleOutline
              size={32}
              color="var(--color-success)"
              title="OK. Everything is ok, Sportsbook Assist Tool will work as expected."
            />
            <>OK</>
          </Col>
        </Row>

        <Row
          style={{
            alignItems: 'flex-end',
            gap: 'var(--space-md)',
            border: '1px solid var(--color-text-light)',
            borderRadius: 'var(--std-border-radius)',
            padding: 'var(--space-sm) var(--space-md)',
          }}
        >
          <Col style={{ flex: '1 0 120px', alignSelf: 'center' }}>
            <span style={{ display: 'block', alignSelf: 'flex-start' }}>
              Sportsbook
            </span>
            <BooksDropdown
              onChange={opt => {
                //setBookIds(bookIds ? [...bookIds, opt.value] : [opt.value]);
                setBookIds([opt.value]);
              }}
              admin
            />
          </Col>

          <AuthTextInput
            containerStyle={{ flex: '2 0 120px', margin: 0 }}
            label="Check affiliate links for referral code"
            placeholder="Leave blank for any referral code"
            errorText={
              !checkRefCodeExists?.isFetched || checkRefCodeExists?.data?.exists
                ? null
                : 'Referral code does not exist'
            }
            onChangeText={t => setRefCode(t.toUpperCase())}
          />
        </Row>

        {!fetchReport.isFetched && (
          <h6 style={{ textAlign: 'center', margin: 'var(--space-xl)' }}>
            Select a sportsbooks to check if we have all the correct data for it
            work in the Sportsbook Assist Tool
          </h6>
        )}

        {fetchReport.isFetching && fetchReport.isLoading && (
          <ActivityIndicator size={3} />
        )}

        {fetchReport.isError && (
          <h5 style={{ alignSelf: 'center', color: 'var(--color-danger)' }}>
            {fetchReport?.error?.message}
          </h5>
        )}

        {fetchReport.isSuccess && (
          <>
            {bookIds.map(bookId => (
              <React.Fragment key={`onb-check-book-report-${bookId}`}>
                <Row
                  style={{
                    marginTop: 'var(--space-lg)',
                    marginBottom: 'var(--space-md)',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    gap: 'var(--space-xs)',
                  }}
                >
                  <StatusIcon
                    status={fetchReport.data[bookId].overall_status}
                    size={32}
                  />
                  <img
                    style={{ borderRadius: '4px' }}
                    src={fetchReport.data[bookId].logo}
                    width={32}
                    height={32}
                    alt={fetchReport.data[bookId].name}
                  />
                  <h4 style={{ marginTop: 0, marginBottom: 0 }}>
                    {fetchReport.data[bookId].name}
                  </h4>
                </Row>
                <table
                  style={{ tableLayout: 'fixed' }}
                  cellSpacing={0}
                  cellPadding={8}
                >
                  <tbody>
                    <tr style={{ backgroundColor: 'var(--color-text-light)' }}>
                      <td>All States</td>
                      <td colSpan={2}>
                        {fetchReport.data[bookId].states.join(', ')}
                      </td>
                    </tr>
                    <tr>
                      <td>Excluded by default odds exclude states</td>
                      <td colSpan={2}>
                        {fetchReport.data[bookId].default_odds_exclude_states
                          ?.length > 0
                          ? fetchReport.data[
                            bookId
                          ].default_odds_exclude_states.join(', ')
                          : '-'}
                      </td>
                    </tr>
                    <tr style={{ backgroundColor: 'var(--color-text-light)' }}>
                      <td>Excluded by onboarding page exclude states</td>
                      <td colSpan={2}>
                        {fetchReport.data[bookId].onboarding_page_exclude_states
                          ?.length > 0
                          ? fetchReport.data[
                            bookId
                          ].onboarding_page_exclude_states.join(', ')
                          : '-'}
                      </td>
                    </tr>
                    <tr>
                      <td>Excluded by aff exclude states</td>
                      <td colSpan={2}>
                        {fetchReport.data[bookId].aff_exclude_states?.length > 0
                          ? fetchReport.data[bookId].aff_exclude_states.join(
                            ', '
                          )
                          : '-'}
                      </td>
                    </tr>
                    <tr style={{ backgroundColor: 'var(--color-text-light)' }}>
                      <td>Target States</td>
                      <td colSpan={2}>
                        {fetchReport.data[bookId].target_regions.join(', ')}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <br />
                {fetchReport.data[bookId].overall_items.map((item, i) => (
                  <Row
                    key={`onb-check-book-report-${bookId}-overall-errors-${i}`}
                    style={{
                      alignItems: 'center',
                      justifyContent: 'flex-start',
                      gap: 'var(--space-xxs)',
                      marginTop: 'var(--space-xxxs)',
                    }}
                  >
                    <StatusIcon status={item.status} size={24} />
                    <span style={{ flex: 1 }}>{item.message}</span>
                  </Row>
                ))}
                <h5>Data by region</h5>
                {fetchReport.data[bookId].by_region.map(report => (
                  <React.Fragment
                    key={`onb-check-book-report-region-${bookId}-${report.region}`}
                  >
                    <Row
                      style={{
                        marginTop: 'var(--space-md)',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                        gap: 'var(--space-xs)',
                      }}
                    >
                      <StatusIcon status={report.status} size={24} />
                      <Col style={{ alignItems: 'flex-start' }}>
                        <h6 style={{ margin: 0 }}>{report.region}&nbsp;</h6>
                        {report.controlled_traffic && (
                          <Row
                            style={{
                              alignItems: 'center',
                              justifyContent: 'flex-start',
                              gap: 'var(--space-xxs)',
                              marginTop: 'var(--space-xxxs)',
                            }}
                          >
                            <IoGameControllerOutline
                              style={{ verticalAlign: 'middle' }}
                              color="var(--color-text)"
                              size={16}
                            />
                            <small>
                              This is a controlled traffic region with a monthly
                              cap of {report.monthly_signup_cap}
                            </small>
                          </Row>
                        )}
                        {report.items.map((item, i) => (
                          <Row
                            key={`onb-check-book-report-${bookId}-errors-${i}`}
                            style={{
                              alignItems: 'center',
                              justifyContent: 'flex-start',
                              gap: 'var(--space-xxs)',
                              marginTop: 'var(--space-xxxs)',
                            }}
                          >
                            <StatusIcon status={item.status} size={16} />
                            <small>{item.message}</small>
                          </Row>
                        ))}
                      </Col>
                    </Row>
                  </React.Fragment>
                ))}
              </React.Fragment>
            ))}
          </>
        )}
      </div>
      <br />
      <br />
      <br />
      <br />
    </>
  );
}

function StatusIcon({ status, size }) {
  if (status === 'OK') {
    return (
      <IoCheckmarkCircleOutline
        size={size}
        color="var(--color-success)"
        title="OK"
      />
    );
  }
  if (status === 'Critical Failure') {
    return (
      <IoCloseCircleOutline
        size={size}
        color="var(--color-danger)"
        title="Critical Failure"
      />
    );
  }
  if (status === 'Non-critical Failure') {
    return (
      <IoAlertCircleOutline
        size={size}
        color="var(--color-complement)"
        title="Non-critical Failure"
      />
    );
  }

  return <></>;
}
