import { useMemo } from 'react';
import { IoChevronUp, IoChevronDown } from 'react-icons/io5';

// components
import { AuthButton } from 'components/AuthButton';
import { Table, Thead, Th, Tbody } from 'components/generic/Tables';

export default function AdminTable(props) {
  const { headings, onSort, sort, sortKeys } = props;

  const sortDict = useMemo(() => {
    if (!sortKeys) return {};
    const newArr = sort && sortKeys ? JSON.parse(sort) : [];
    let newDict = {};
    for (const opt of newArr) {
      if (opt !== null) {
        newDict[sortKeys[opt.replace('-', '')]] = opt;
      }
    }
    return newDict;
  }, [sort, sortKeys]);

  return (
    <Table cellSpacing={0} style={props.style}>
      <Thead>
        <tr>
          {headings.map(head => (
            <Th
              style={head === '' ? { width: '128px' } : {}}
              key={`admin-table-head-${head}`}
            >
              {head !== '' && (
                <AuthButton
                  containerStyle={{
                    margin: 0,
                    borderRadius: '4px',
                  }}
                  btnStyle={{
                    padding: 'var(--space-xs) 0',
                    borderRadius: '4px',
                    backgroundColor: 'transparent',
                  }}
                  textStyle={{ textAlign: 'left', color: 'var(--color-text)' }}
                  colorTheme="text"
                  btnTheme="borderless"
                  rightIcon={
                    sortDict[head]
                      ? sortDict[head].includes('-')
                        ? IoChevronDown
                        : IoChevronUp
                      : null
                  }
                  rightIconSize={22}
                  title={
                    sortKeys && sortKeys[head]
                      ? `Sort by ${head} ${
                          sortDict[head]?.includes('-')
                            ? 'Ascending'
                            : 'Descending'
                        }`
                      : undefined
                  }
                  disabled={!sortKeys || !sortKeys[head]}
                  onPress={
                    sortKeys && sortKeys[head]
                      ? () => {
                          let newSortDict = { ...sortDict };
                          if (!sortDict[head]) {
                            newSortDict[head] = sortKeys[head];
                            onSort({
                              sort: JSON.stringify(Object.values(newSortDict)),
                            });
                          } else if (
                            newSortDict[head] &&
                            !sortDict[head].includes('-')
                          ) {
                            newSortDict[head] = `-${sortKeys[head]}`;
                            onSort({
                              sort: JSON.stringify(Object.values(newSortDict)),
                            });
                          } else {
                            delete newSortDict[head];
                            onSort({
                              sort: JSON.stringify(Object.values(newSortDict)),
                            });
                          }
                        }
                      : null
                  }
                >
                  {head.toUpperCase()}
                </AuthButton>
              )}
            </Th>
          ))}
        </tr>
      </Thead>
      <Tbody>{props.children}</Tbody>
    </Table>
  );
}
