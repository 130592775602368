import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';

// react query custom hooks
import { useUserGroups } from 'react-query/global-hooks';

const Wrapper = styled.div`
  z-index: 9999;
  background-color: var(--color-fg);
  min-height: 64px;
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
  gap: 1rem;
  > a,
  * a,
  * span,
  * * a,
  * * * a {
    text-decoration: none;
    white-space: nowrap;
    border-bottom: 2px solid transparent;
    transition: all var(--std-transition);
    font-weight: bold;
  }
  > a:hover,
  * a:hover,
  * span:hover,
  * * a:hover,
  * * * a:hover {
    color: var(--color-primary);
    border-bottom: 2px solid var(--color-primary);
  }
  > a[data-selected='true'] {
    color: var(--color-primary);
  }
  @media only screen and (max-width: 625px) {
    justify-content: flex-start;
    gap: 2rem;
    padding: 0 var(--space-sm);
    overflow-x: auto;
  }
`;

const Drop = styled.div`
  height: 64px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  :hover > ul {
    pointer-events: auto;
    display: block;
    opacity: 1;
    top: 65%;
  }
`;

const DropdownOptionsWrapper = styled.ul`
  position: absolute;
  background: var(--color-fg);
  border-radius: var(--std-border-radius);
  padding: var(--space-md);
  box-shadow: 0 2px 8px 0 var(--color-shadow);
  transition: all var(--std-transition);
  list-style: none;
  top: 55%;
  opacity: 0;
  z-index: 99999;
  pointer-events: none;
  min-width: 222px;

  &:after {
    content: '';
    display: block;
    position: absolute;
    right: ${props => {
    return props.arrowRight ? props.arrowRight : '21px';
  }};
    bottom: 100%;
    width: 0;
    height: 0;
    border-bottom: 10px solid var(--color-fg);
    border-top: 10px solid transparent;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
  }
`;

export default function StaffNavBar(params) {
  const userGroups = useUserGroups();
  return (
    <Wrapper>
      <Item to="/staff/my-dashboard">My Dashboard</Item>
      {(userGroups.isOnboardingFollowUpHost ||
        userGroups.isOnboardingHost ||
        userGroups.isOnboardingSalesTeam ||
        userGroups.isFullDeveloper ||
        userGroups.isExecutive ||
        userGroups.isAffiliateEarnings ||
        userGroups.isAffiliateReports ||
        userGroups.isAffiliateDashboard) && (
          <Drop>
            <span>Metrics ⯆</span>
            <DropdownOptionsWrapper arrowRight="90%">
              <>
                {(userGroups.isAffiliateEarnings ||
                  userGroups.isExecutive ||
                  userGroups.isAffiliateReports) && (
                    <>
                      <li>
                        <Item to="/staff/analysis/earnings">
                          Affiliate Earning Reports
                        </Item>
                      </li>
                    </>
                  )}
                {(userGroups.isAffiliateEarnings || userGroups.isExecutive) && (
                  <li>
                    <Item to="/staff/affiliate-metrics">
                      Affiliate Earning Metrics
                    </Item>
                  </li>
                )}
                {(userGroups.isFullDeveloper ||
                  userGroups.isAffiliateDashboard ||
                  userGroups.isExecutive) && (
                    <li>
                      <Item to="/staff/analysis/clickstats">
                        Affiliate Click Data
                      </Item>
                    </li>
                  )}
                {(userGroups.isFullDeveloper ||
                  userGroups.isExecutive ||
                  userGroups.isOnboardingHost ||
                  userGroups.isOnboardingFollowUpHost) && (
                    <li>
                      <Item to="/staff/csm-metrics">CSM Team Metrics</Item>
                    </li>
                  )}
                {(userGroups.isFullDeveloper ||
                  userGroups.isExecutive ||
                  userGroups.isOnboardingHost ||
                  userGroups.isOnboardingFollowUpHost ||
                  userGroups.isOnboardingSalesTeam) && (
                    <li>
                      <Item
                        to="/staff/overall-metrics"
                        params={`groupings=["book"]&time_period=month_to_date&`}
                      >
                        Overall Metrics
                      </Item>
                    </li>
                  )}
              </>
            </DropdownOptionsWrapper>
          </Drop>
        )}
      {(userGroups.isOnboardingHost ||
        userGroups.isOnboardingFollowUpHost ||
        userGroups.isOnboardingSalesTeam ||
        userGroups.isOnboardingDebug ||
        userGroups.isFullDeveloper ||
        userGroups.isExecutive) && (
          <>
            <Item to="/staff/sessions">All Sessions</Item>
            <Item to="/staff/attendees">All Attendees</Item>
            <Item to="/staff/payments">Payments</Item>
          </>
        )}
      {(userGroups.isFullDeveloper || userGroups.isExecutive) && (
        <>
          <Item to="/staff/hosts">Hosts</Item>
          <Item to="/staff/schedule">Schedule</Item>
        </>
      )}
      {(userGroups.isDeveloper ||
        userGroups.isFullDeveloper ||
        userGroups.isExecutive ||
        userGroups.isAffiliateManager) && (
          <Item to="/staff/sportsbook-infos">Sportsbook Info</Item>
        )}
      {(userGroups.isDeveloper ||
        userGroups.isFullDeveloper ||
        userGroups.isExecutive ||
        userGroups.isAffiliateManager ||
        userGroups.isReferralCodeOnly) && (
          <Item to="/staff/referral-codes">Referral Codes</Item>
        )}
      {(userGroups.isFullDeveloper || userGroups.isExecutive) && (
        <Item to="/staff/troubleshooter">Troubleshooter</Item>
      )}
      {!(userGroups.isFullDeveloper || userGroups.isExecutive) && (
        <Item to="/staff/verify-attendees">Verify Attendees</Item>
      )}
      {(userGroups.isFullDeveloper ||
        userGroups.isExecutive ||
        userGroups.isVA) && (
          <Drop>
            <span>Tools ⯆</span>
            <DropdownOptionsWrapper style={{ right: '16px' }}>
              {!userGroups.isVA && (
                <>
                  <li>
                    <Item to="/staff/verify-attendees">Verify Attendees</Item>
                  </li>
                  <li title="Sync all attendees to airtable">
                    <Item to="/staff/sync-to-airtable">Sync to Airtable</Item>
                  </li>
                  <li title="Make sure a sportbook has all required data before adding it to an attendee's tool">
                    <Item to="/staff/check-book-data">Check Book Data</Item>
                  </li>
                </>
              )}

              <li title="Upload Affiliate Report Files">
                <Item to="/staff/upload-email-reports">
                  Upload Affiliate Data
                </Item>
              </li>
            </DropdownOptionsWrapper>
          </Drop>
        )}
    </Wrapper>
  );
}

function Item(props) {
  const { pathname } = useLocation();
  return (
    <Link
      to={{ pathname: props.to, search: props.params }}
      data-selected={pathname === props.to}
    >
      {props.children}
    </Link>
  );
}
