import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { IoTrashOutline } from 'react-icons/io5';

//components
import ModalWrapper from 'components/generic/ModalWrapper';
import { Row, Col } from 'components/generic/Layout';
import ErrorDisplay from 'components/generic/ErrorDisplay';
import { AuthButton, IconButton, LinkButton } from 'components/AuthButton';
import ActivityIndicator from 'components/generic/ActivityIndicator';
import Badge from 'components/generic/Badge';
import ProfilePic from 'components/generic/ProfilePic';

//actions
import {
  viewPurchases,
  cancelSubscription,
  // resetUpdatePMSuccess,
  viewPurchasesNext,
} from 'actions';

const PurchaseWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-flow: column wrap;
  justify-content: space-around;
  align-items: stretch;
  background-color: var(--color-fg);
  padding: var(--space-sm);
  margin: var(--space-sm) 0;
  border-radius: 8px;
  border: none;
  outline: none;
  font-size: var(--text-base-size);
  transition: all var(--std-transition);
  color: var(--color-text);
  box-sizing: border-box;
`;

export default function Subscriptions(props) {
  const [unsubModalIsOpen, setUnsubModalIsOpen] = useState(false);
  const [selectedPurchase, setSelectedPurchase] = useState(null);
  // const [pmModalIsOpen, setPMModalIsOpen] = useState(false);

  const dispatch = useDispatch();

  const reduxProps = useSelector(state => ({
    isPurchasesLoading: state.stripeReducer.isPurchasesLoading,
    purchaseLoadingError: state.stripeReducer.purchaseLoadingError,
    purchases: state.stripeReducer.purchases,
    isCancellingSub: state.stripeReducer.isCancellingSub,
    cancelSubFailure: state.stripeReducer.cancelSubFailure,
    purchasesNext: state.stripeReducer.purchasesNext,
  }));

  const {
    purchaseLoadingError,
    isPurchasesLoading,
    purchases,
    isCancellingSub,
    cancelSubFailure,
    purchasesNext,
  } = reduxProps;

  useEffect(() => {
    dispatch(viewPurchases());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _getNextPurchases = () => {
    if (purchasesNext) {
      dispatch(viewPurchasesNext());
    }
  };

  if (purchaseLoadingError) {
    return (
      <div className="container">
        <ErrorDisplay
          error={purchaseLoadingError}
          message={'getting your purchases'}
          retry={() => dispatch(viewPurchases())}
        />
      </div>
    );
  }

  if (cancelSubFailure) {
    return (
      <div className="container">
        <ErrorDisplay
          error={cancelSubFailure}
          message={'unsubscribing.'}
          retry={() =>
            dispatch(
              cancelSubscription(selectedPurchase.id, {
                is_cancelled: true,
              })
            )
          }
        />
      </div>
    );
  }

  return (
    <>
      <ModalWrapper
        modalIsOpen={unsubModalIsOpen}
        onClose={() => setUnsubModalIsOpen(false)}
        onRequestClose={() => setUnsubModalIsOpen(false)}
        title="Unsubscribe?"
        modalSize={'small'}
      >
        {selectedPurchase && (
          <>
            <p>
              Are you sure you want to unsubscribe from{' '}
              {selectedPurchase.package.title} by{' '}
              {selectedPurchase.package.package_owner}?
            </p>
            <Row>
              <AuthButton
                onPress={() => {
                  dispatch(
                    cancelSubscription(selectedPurchase.id, {
                      is_cancelled: true,
                    })
                  );
                  setUnsubModalIsOpen(false);
                }}
                colorTheme="danger"
              >
                Unsubscribe
              </AuthButton>
              <AuthButton onPress={() => setUnsubModalIsOpen(false)}>
                Cancel
              </AuthButton>
            </Row>
          </>
        )}
      </ModalWrapper>
      <Col
        style={{
          flex: 1,
          justifyContent: 'flex-start',
          alignItems: 'stretch',
          ...props.style,
        }}
      >
        {isPurchasesLoading || isCancellingSub ? (
          <ActivityIndicator size={2} />
        ) : (
          <>
            <Row
              style={{
                alignItems: 'center',
                justifyContent: 'space-between',
                position: 'sticky',
                top: 0,
                backgroundColor: 'var(--color-bg)',
                zIndex: 101,
                borderBottom: '1px solid #9b9b9b',
                padding: 'var(--space-sm) 0',
              }}
            >
              <h3 style={{ margin: 0, flex: 2 }}>Subscriptions</h3>

              {!isPurchasesLoading && (
                <td style={{ color: 'var(--color-success)' }}>
                  Showing {purchases.filter(item => item.is_active).length}{' '}
                  active
                </td>
              )}
              <Link to="/find-bettors" style={{ textDecoration: 'none' }}>
                <AuthButton
                  containerStyle={{ margin: 0 }}
                  btnTheme="borderless"
                  onPress={() => {}}
                >
                  + Add a Package
                </AuthButton>
              </Link>
            </Row>
            {purchases.length === 0 ? (
              <Col style={{ alignItems: 'flex-start' }}>
                <h5>You haven't made any purchases yet!</h5>
                <p>
                  Check out the betstamp Marketplace to subscribe to another
                  user's picks package. Get notifications in real-time when they
                  input new picks.
                </p>
                <LinkButton to="/find-bettors">
                  Find Bettors
                </LinkButton>
              </Col>
            ) : (
              <Col style={{ alignItems: 'stretch' }}>
                {/* <InfiniteScroll
                  next={() => _getNextPurchases}
                  hasMore={purchasesNext !== null}
                  dataLength={purchases.length}
                  loader={<ActivityIndicator size={2} />}
                  style={{ overflow: 'hidden' }}
                  scrollThreshold={0.4}
                > */}
                {purchases.map((purchase, i) => (
                  <Sub
                    key={`purchase-${i}`}
                    purchase={purchase}
                    clickUnsub={() => {
                      setUnsubModalIsOpen(true);
                      setSelectedPurchase(purchase);
                    }}
                  />
                ))}
                {purchasesNext !== null && (
                  <AuthButton
                    containerStyle={{ width: '100%' }}
                    btnTheme="borderless"
                    onPress={() => _getNextPurchases()}
                  >
                    View More
                  </AuthButton>
                )}
              </Col>
            )}
          </>
        )}
      </Col>
    </>
  );
}

function Sub(props) {
  const { purchase } = props;
  let ex_date = null;
  if (purchase.expiry_date) {
    ex_date = moment(purchase.expiry_date);
  }

  let status = 'active';
  let badgeStyle = {
    color: 'var(--color-success)',
    borderColor: 'var(--color-success)',
  };
  if (purchase.is_cancelled && purchase.is_active) {
    status = 'cancelling';
    badgeStyle = {
      color: 'var(--color-complement)',
      borderColor: 'var(--color-complement)',
    };
  } else if (purchase.is_cancelled) {
    status = 'cancelled';
    badgeStyle = {
      color: 'var(--color-danger)',
      borderColor: 'var(--color-danger)',
    };
  } else if (!purchase.is_active) {
    status = 'inactive';
    badgeStyle = {
      color: 'var(--color-text)',
      borderColor: 'var(--color-text)',
    };
  }

  const [descOpen, setDescOpen] = useState(false);

  return (
    <PurchaseWrapper>
      <Row style={{ flex: 0, alignItems: 'flex-start' }}>
        <Col style={{ flex: 2, alignItems: 'flex-start' }}>
          <h6 style={{ marginBottom: '6px' }}>{purchase.package.title}</h6>
          <Row style={{ alignItems: 'center', justifyContent: 'flex-start' }}>
            <ProfilePic user={purchase.package.user} />
            <span>&nbsp;{purchase.package.package_owner}</span>
          </Row>
          <span style={{ marginTop: 'var(--space-sm)' }}>
            {!purchase.is_active || ex_date === null ? (
              <>EXPIRED</>
            ) : (
              <>
                {purchase.is_cancelled ? (
                  <>Ends: {ex_date.format('MMMM D, YYYY')}</>
                ) : (
                  <>Next Charge: {ex_date.format('MMMM D, YYYY')}</>
                )}
              </>
            )}
          </span>
        </Col>
        <Col style={{ alignItems: 'flex-end' }}>
          <h6 style={{ flex: 1, textAlign: 'right' }}>
            ${purchase.package.cost}/{purchase.package.billing_freq}
          </h6>
          <Badge large text={status} style={badgeStyle} />
        </Col>
      </Row>
      {descOpen && <p>{purchase.package.description}</p>}
      <Row style={{ flex: 0, justifyContent: 'space-between' }}>
        <AuthButton
          btnStyle={{
            flex: 1,
            width: 'fit-content',
          }}
          colorTheme="primary"
          btnTheme="borderless"
          onPress={() => setDescOpen(!descOpen)}
        >
          {descOpen ? 'Hide Description' : 'See description'}
        </AuthButton>

        {purchase.is_active && !purchase.is_cancelled && (
          <IconButton
            btnStyle={{ flex: 1 }}
            colorTheme="danger"
            btnTheme="borderless"
            iconName={IoTrashOutline}
            iconSize={22}
            subtext={'Cancel'}
            onPress={() => props.clickUnsub()}
          />
        )}
      </Row>
    </PurchaseWrapper>
  );
}
