import React, { useState, useMemo } from 'react';
import styled from 'styled-components';
import { IoAnalyticsSharp, IoClose, IoFilter } from 'react-icons/io5';
import { useQuery, useInfiniteQuery } from '@tanstack/react-query';
import { useLocation } from 'react-router-dom';

// utils
import {
  humanDate,
  humanDatetime,
  formatMoney,
  MONTHS,
  REGION_FLAGS,
  PROVINCE_OPTIONS,
  US_STATES,
  STATE_OPTIONS,
} from 'utils';

// components
import { Row, Col, Grid } from 'components/generic/Layout';
import { AuthButton, IconButton } from 'components/AuthButton';
import StaffOnboardingBase from 'components/StaffOnboardingBase';
import GenericAdminFilter from 'components/onboarding/GenericAdminFilter';
import ActivityIndicator from 'components/generic/ActivityIndicator';

const Tbody = styled.tbody`
  > tr {
    transition: all var(--std-transition);
  }
  > tr:hover {
    background-color: var(--color-text-light);
  }
`;

const SUPPORTED_GROUPINGS = [
  'region',
  'book',
  'referral_code',
  'source',
  'marketing_group',
];
const GROUP_MAP = {
  region: 'region',
  book: 'progresses__book__name',
  referral_code: 'signed_up_with_referral_code__code',
  source: 'source',
  marketing_group: 'signed_up_with_referral_code__group__name',
  //
  progresses__book__name: 'book',
  signed_up_with_referral_code__code: 'referral_code',
  signed_up_with_referral_code__group__name: 'marketing_group',
};

const specialHeaderFormat = (t, obj, groupings, group) => {
  let text = obj[group];
  if (obj.depth !== groupings.indexOf(GROUP_MAP[group]) + 1) {
    return ' ';
  }
  if (groupings[obj.depth - 1] !== GROUP_MAP[group]) return ' ';
  if (obj.depth === 1) text = '⦿' + text;
  else text = '⤷' + text;
  return text;
};

export default function OverallMetrics(props) {
  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);
  const [groupings, setGroupings] = useState(
    searchParams.get('groupings')
      ? JSON.parse(searchParams.get('groupings'))
      : ['book']
  );

  return (
    <>
      <StaffOnboardingBase
        endpoint="onboarding-metrics"
        modelName="Metric"
        headings={groupings
          .map(group => `${group.replaceAll('_', ' ').toUpperCase()}`)
          .concat([
            'Attendees',
            'Target',
            //'Signups',
            'Portal Signups',
            //'Deposits',
            //'Portal Deposits',
            //'Placed Bets',
            'Portal CPA',
            'Complete',
            //'Signups Left',
            //'Conversion Rate',
            'Initial Payments',
            'Returned Money',
            'Bonus Paid',
            'Amount Used',
            'Balance',
          ])}
        objKeys={groupings
          .map(group => GROUP_MAP[group.toLowerCase()])
          .concat([
            'num_attendees',
            'sum_target',
            //'num_signups',
            'portal_signups',
            //'num_deposits',
            //'portal_deposits',
            //'num_place_bets',
            'portal_cpa',
            'num_fully_complete',
            //'signups_left',
            //'fully_complete_conversion_rate',
            'sum_initial_payments',
            'sum_returned_payments',
            'sum_bonus_payments',
            'sum_amount_used',
            'annotated_balance',
          ])}
        defaultUrlParams={{ groupings: JSON.stringify(groupings) }}
        extraButtons={SUPPORTED_GROUPINGS.map(group => ({
          text: group.toUpperCase().replaceAll('_', ' '),
          //urlParams: { groupings: JSON.stringify('group') },
          deriveUrlParams: oldUrlParams => {
            let newGroupings = Array.from(groupings);
            if (groupings.includes(group)) {
              newGroupings.splice(groupings.indexOf(group), 1);
            } else {
              newGroupings.push(group);
            }
            setGroupings(newGroupings);
            return {
              urlParams: {
                ...oldUrlParams,
                groupings: JSON.stringify(newGroupings),
              },
              selectedQuickFilters: newGroupings.map(g =>
                g.toUpperCase().replaceAll('_', ' ')
              ),
            };
          },
        }))}
        defaultSelectedQuickFilter={'BOOK'}
        formatText={{
          [GROUP_MAP['region']]: (t, obj) =>
            specialHeaderFormat(t, obj, groupings, GROUP_MAP['region']),
          [GROUP_MAP['book']]: (t, obj) =>
            specialHeaderFormat(t, obj, groupings, GROUP_MAP['book']),
          [GROUP_MAP['referral_code']]: (t, obj) =>
            specialHeaderFormat(t, obj, groupings, GROUP_MAP['referral_code']),
          [GROUP_MAP['source']]: (t, obj) =>
            specialHeaderFormat(t, obj, groupings, GROUP_MAP['source']),
          [GROUP_MAP['marketing_group']]: (t, obj) =>
            specialHeaderFormat(
              t,
              obj,
              groupings,
              GROUP_MAP['marketing_group']
            ),

          sum_initial_payments: t => formatMoney(t, 'CAD'),
          sum_returned_payments: t => formatMoney(t, 'CAD'),
          sum_bonus_payments: t => formatMoney(t, 'CAD'),
          sum_amount_used: t => formatMoney(t, 'CAD'),
          fully_complete_conversion_rate: t => `${Math.round(t * 100)}%`,
          annotated_balance: t => formatMoney(t, 'CAD'),
        }}
        panelTabs={['Attendees', 'Sessions', 'Payments']}
        sortKeys={{
          annotated_balance: 'Balance',
          Balance: 'annotated_balance',
          signups_left: 'Signups Left',
          'Signups Left': 'signups_left',
          fully_complete_conversion_rate: 'Conversion Rate',
          'Conversion Rate': 'fully_complete_conversion_rate',
        }}
        hideCreate
        showSearch={true}
        searchPlaceholder={`Search by ${groupings[0]}`}
        FilterChildren={FilterChildren}
        extraHeaderRow={pages => {
          let overall = null;
          if (pages && pages?.length > 0 && pages[0]?.overall) {
            overall = pages[0]?.overall;
          }
          if (overall) {
            return (
              <>
                <Col
                  style={{
                    padding: 'var(--space-xs)',
                    borderRadius: '8px',
                    border: '1px solid var(--color-text-light)',
                    height: '100%',
                    flexWrap: 'nowrap',
                  }}
                  title="Total number of attendees"
                >
                  <b>{overall?.num_attendees}</b>
                  <small style={{ textAlign: 'center' }}>Attendees</small>
                </Col>
                <Col
                  style={{
                    padding: 'var(--space-xs)',
                    borderRadius: '8px',
                    border: '1px solid var(--color-text-light)',
                    height: '100%',
                    flexWrap: 'nowrap',
                  }}
                  title="Sum of the target number of signups"
                >
                  <b>{overall?.sum_target}</b>
                  <small style={{ textAlign: 'center' }}>Target</small>
                </Col>
              {/*<Col
                  style={{
                    padding: 'var(--space-xs)',
                    borderRadius: '8px',
                    border: '1px solid var(--color-text-light)',
                    height: '100%',
                    flexWrap: 'nowrap',
                  }}
                  title="Number of sportsbook accounts created"
                >
                  <b>{overall?.num_signups}</b>
                  <small style={{ textAlign: 'center' }}>Signups</small>
                </Col>*/}
                <Col
                  style={{
                    padding: 'var(--space-xs)',
                    borderRadius: '8px',
                    border: '1px solid var(--color-text-light)',
                    height: '100%',
                    flexWrap: 'nowrap',
                  }}
                  title="Number of portal reported sportsbook accounts created"
                >
                  <b>{overall?.num_portal_registrations}</b>
                  <small style={{ textAlign: 'center' }}>Portal Signups</small>
                </Col>
              {/*<Col
                  style={{
                    padding: 'var(--space-xs)',
                    borderRadius: '8px',
                    border: '1px solid var(--color-text-light)',
                    height: '100%',
                    flexWrap: 'nowrap',
                  }}
                  title="Number of sportsbook that had a deposit made"
                >
                  <b>{overall?.num_deposits}</b>
                  <small style={{ textAlign: 'center' }}>Deposits</small>
                </Col>
                <Col
                  style={{
                    padding: 'var(--space-xs)',
                    borderRadius: '8px',
                    border: '1px solid var(--color-text-light)',
                    height: '100%',
                    flexWrap: 'nowrap',
                  }}
                  title="Number of portal reported sportsbook deposit made"
                >
                  <b>{overall?.num_portal_deposits}</b>
                  <small style={{ textAlign: 'center' }}>Portal Deposits</small>
                </Col>
                <Col
                  style={{
                    padding: 'var(--space-xs)',
                    borderRadius: '8px',
                    border: '1px solid var(--color-text-light)',
                    height: '100%',
                    flexWrap: 'nowrap',
                  }}
                  title="Number of sportsbook that had a bet placed at it"
                >
                  <b>{overall?.num_place_bets}</b>
                  <small style={{ textAlign: 'center' }}>Placed Bets</small>
                </Col>*/}
                <Col
                  style={{
                    padding: 'var(--space-xs)',
                    borderRadius: '8px',
                    border: '1px solid var(--color-text-light)',
                    height: '100%',
                    flexWrap: 'nowrap',
                  }}
                  title="Number of portal reported CPA"
                >
                  <b>{overall?.num_portal_cpa}</b>
                  <small style={{ textAlign: 'center' }}>Portal CPA</small>
                </Col>
                <Col
                  style={{
                    padding: 'var(--space-xs)',
                    borderRadius: '8px',
                    border: '1px solid var(--color-text-light)',
                    height: '100%',
                    flexWrap: 'nowrap',
                  }}
                  title="Number of sportsbook that we marked as fully complete"
                >
                  <b>{overall?.num_fully_complete}</b>
                  <small style={{ textAlign: 'center' }}>Complete</small>
                </Col>
              {/*<Col
                  style={{
                    padding: 'var(--space-xs)',
                    borderRadius: '8px',
                    border: '1px solid var(--color-text-light)',
                    height: '100%',
                    flexWrap: 'nowrap',
                  }}
                  title="Difference between the target and the number of fully complete signups"
                >
                  <b>{overall?.signups_left}</b>
                  <small style={{ textAlign: 'center' }}>Signups Left</small>
                </Col>*/}
                {/*<Col
                  style={{
                    padding: 'var(--space-xs)',
                    borderRadius: '8px',
                    border: '1px solid var(--color-text-light)',
                    height: '100%',
                    flexWrap: 'nowrap',
                  }}
                  title="The number of fully complete books divided by the target"
                >
                  <b>
                    {Math.round(overall?.fully_complete_conversion_rate * 100)}%
                  </b>
                  <small style={{ textAlign: 'center' }}>Conversion</small>
                </Col>*/}
                <Col
                  style={{
                    padding: 'var(--space-xs)',
                    borderRadius: '8px',
                    border: '1px solid var(--color-text-light)',
                    height: '100%',
                    flexWrap: 'nowrap',
                  }}
                  title="The sum of the initial setup session payments"
                >
                  <b>{formatMoney(overall?.sum_initial_payments, 'CAD')}</b>
                  <small style={{ textAlign: 'center' }}>Initial Pymts</small>
                </Col>
                <Col
                  style={{
                    padding: 'var(--space-xs)',
                    borderRadius: '8px',
                    border: '1px solid var(--color-text-light)',
                    height: '100%',
                    flexWrap: 'nowrap',
                  }}
                  title="The sum of the any money returned"
                >
                  <b>{formatMoney(overall?.sum_returned_payments, 'CAD')}</b>
                  <small style={{ textAlign: 'center' }}>Returned Pymts</small>
                </Col>
                <Col
                  style={{
                    padding: 'var(--space-xs)',
                    borderRadius: '8px',
                    border: '1px solid var(--color-text-light)',
                    height: '100%',
                    flexWrap: 'nowrap',
                  }}
                  title="The sum of the any bonuses paid (POST_ONBOARDING_BONUS or BETSTAMP_101_BONUS or GROUP_TARGET_BONUS)"
                >
                  <b>{formatMoney(overall?.sum_bonus_payments, 'CAD')}</b>
                  <small style={{ textAlign: 'center' }}>Bonus Paid</small>
                </Col>
                <Col
                  style={{
                    padding: 'var(--space-xs)',
                    borderRadius: '8px',
                    border: '1px solid var(--color-text-light)',
                    height: '100%',
                    flexWrap: 'nowrap',
                  }}
                  title="The sum of the min deposits at fully complete sportsbooks"
                >
                  <b>{formatMoney(overall?.sum_amount_used, 'CAD')}</b>
                  <small style={{ textAlign: 'center' }}>Amount Used</small>
                </Col>
                <Col
                  style={{
                    padding: 'var(--space-xs)',
                    borderRadius: '8px',
                    border: '1px solid var(--color-text-light)',
                    height: '100%',
                    flexWrap: 'nowrap',
                  }}
                  title="The sum of the initial payments sent minus the sum of the min deposits at fully completed sportsbooks minus the sum of the money returned (negative means we owe them)"
                >
                  <b>{formatMoney(overall?.balance, 'CAD')}</b>
                  <small style={{ textAlign: 'center' }}>Balance</small>
                </Col>
              </>
            );
          }
          return null;
        }}
      />
    </>
  );
}

function Panel(props) {
  const { selectedObj, tab } = props;

  const fetchAttendees = useInfiniteQuery({
    refetchOnWindowFocus: false,
    enabled: tab === 'Attendees',
    queryKey: [
      {
        endpoint: `onboarding-attendees`,
        urlParams: {
          calculate_stats: JSON.stringify([
            'num_signups',
            'num_deposits',
            'num_placed_bets',
            'num_fully_complete',
            'amount_sent',
            'amount_used',
          ]),
          signed_up_with_referral_code_codes: JSON.stringify([
            selectedObj?.signed_up_with_referral_code__code,
          ]),
        },
      },
    ],
    getNextPageParam: (lastPage, allPages) => lastPage.next,
    getPreviousPageParam: (firstPage, allPages) => firstPage.prev,
  });

  const fetchSessions = useInfiniteQuery({
    refetchOnWindowFocus: false,
    enabled: tab === 'Sessions',
    queryKey: [
      {
        endpoint: `onboarding-sessions`,
        urlParams: {
          referral_code_codes: JSON.stringify([
            selectedObj?.signed_up_with_referral_code__code,
          ]),
        },
      },
    ],
    getNextPageParam: (lastPage, allPages) => lastPage.next,
    getPreviousPageParam: (firstPage, allPages) => firstPage.prev,
  });

  const fetchPayments = useInfiniteQuery({
    refetchOnWindowFocus: false,
    enabled: tab === 'Payments',
    queryKey: [
      {
        endpoint: `onboarding-payments`,
        urlParams: {
          signed_up_with_referral_code_codes: JSON.stringify([
            selectedObj?.signed_up_with_referral_code__code,
          ]),
        },
      },
    ],
    getNextPageParam: (lastPage, allPages) => lastPage.next,
    getPreviousPageParam: (firstPage, allPages) => firstPage.prev,
  });

  if (tab === 'Attendees' && fetchAttendees?.isSuccess) {
    const pages = fetchAttendees?.data?.pages;
    return (
      <>
        <table
          cellSpacing={0}
          style={{
            width: '100%',
          }}
        >
          <thead>
            <tr>
              <td>
                <b>Name</b>
              </td>
              <td style={{ textAlign: 'center' }}>
                <b>Target</b>
              </td>
              <td style={{ textAlign: 'center' }}>
                <b>Signups</b>
              </td>
              <td style={{ textAlign: 'center' }}>
                <b>Deposits</b>
              </td>
              <td style={{ textAlign: 'center' }}>
                <b>Bet</b>
              </td>
              <td style={{ textAlign: 'center' }}>
                <b>Complete</b>
              </td>
              <td style={{ textAlign: 'right' }}>
                <b>Amt Sent</b>
              </td>
              <td style={{ textAlign: 'right' }}>
                <b>Amt Used</b>
              </td>
            </tr>
          </thead>
          <Tbody>
            {pages?.length > 0 && pages[0].results?.length === 0 && (
              <tr>
                <td colSpan={8} style={{ textAlign: 'center' }}>
                  No Attedees
                </td>
              </tr>
            )}
            {pages.map((page, i) => (
              <React.Fragment
                key={`sales-page-ref-code-${selectedObj?.signed_up_with_referral_code_code}-page-${i}`}
              >
                {page.results.map(atde => (
                  <tr
                    key={`sales-page-ref-code-attendee-${atde.id}`}
                    title={`${atde.full_name} (${atde.id})`}
                  >
                    <td>
                      <a
                        className="color-on-hover"
                        href={`/staff/attendees?search=${atde?.id}`}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        {atde.full_name.split(' ')[0]}
                      </a>
                    </td>
                    <td style={{ textAlign: 'center' }}>
                      <span>{atde.target_num_signups}</span>
                    </td>
                    <td style={{ textAlign: 'center' }}>
                      <span>{atde.num_signups}</span>
                    </td>
                    <td style={{ textAlign: 'center' }}>
                      <span>{atde.num_deposits}</span>
                    </td>
                    <td style={{ textAlign: 'center' }}>
                      <span>{atde.num_placed_bets}</span>
                    </td>
                    <td style={{ textAlign: 'center' }}>
                      <span>{atde.num_fully_complete}</span>
                    </td>
                    <td style={{ textAlign: 'right' }}>
                      <span>{formatMoney(atde.amount_sent, 'CAD')}</span>
                    </td>
                    <td style={{ textAlign: 'right' }}>
                      <span>{formatMoney(atde.amount_used, 'CAD')}</span>
                    </td>
                  </tr>
                ))}
              </React.Fragment>
            ))}
          </Tbody>
        </table>
        {fetchAttendees.hasNextPage && (
          <AuthButton
            containerStyle={{
              margin: 'var(--space-lg) 0',
              maxWidth: '256px',
            }}
            btnTheme="borderless"
            onPress={() => fetchAttendees.fetchNextPage()}
            isLoading={fetchAttendees.isFetchingNextPage}
          >
            Load More
          </AuthButton>
        )}
      </>
    );
  }

  if (tab === 'Sessions' && fetchSessions?.isSuccess) {
    const pages = fetchSessions?.data?.pages;
    return (
      <>
        <table
          cellSpacing={0}
          style={{
            width: '100%',
          }}
        >
          <thead>
            <tr>
              <td>
                <b>ID</b>
              </td>
              <td>
                <b>Type</b>
              </td>
              <td>
                <b>Date</b>
              </td>
            </tr>
          </thead>
          <Tbody>
            {pages?.length > 0 && pages[0].results?.length === 0 && (
              <tr>
                <td colSpan={3} style={{ textAlign: 'center' }}>
                  No Sessions
                </td>
              </tr>
            )}
            {pages.map((page, i) => (
              <React.Fragment
                key={`sales-page-ref-code-${selectedObj?.signed_up_with_referral_code_code}-page-${i}`}
              >
                {page.results.map(sess => (
                  <tr key={`sales-page-ref-code-session-${sess.id}`}>
                    <td>
                      <a
                        className="color-on-hover"
                        href={`/staff/sessions?search=${sess?.id}`}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        {sess.id}
                      </a>
                    </td>
                    <td>
                      <span>{sess.session_type}</span>
                    </td>
                    <td>
                      <span>{humanDatetime(sess.date)}</span>
                    </td>
                  </tr>
                ))}
              </React.Fragment>
            ))}
          </Tbody>
        </table>
        {fetchAttendees.hasNextPage && (
          <AuthButton
            containerStyle={{
              margin: 'var(--space-lg) 0',
              maxWidth: '256px',
            }}
            btnTheme="borderless"
            onPress={() => fetchAttendees.fetchNextPage()}
            isLoading={fetchAttendees.isFetchingNextPage}
          >
            Load More
          </AuthButton>
        )}
      </>
    );
  }

  if (tab === 'Payments' && fetchPayments?.isSuccess) {
    const pages = fetchPayments?.data?.pages;
    return (
      <>
        <table
          cellSpacing={0}
          style={{
            width: '100%',
          }}
        >
          <thead>
            <tr>
              <td>
                <b>Attendee or Session ID</b>
              </td>
              <td style={{ textAlign: 'right' }}>
                <b>Reason</b>
              </td>
              <td style={{ textAlign: 'right' }}>
                <b>Amt</b>
              </td>
            </tr>
          </thead>
          <Tbody>
            {pages?.length > 0 && pages[0].results?.length === 0 && (
              <tr>
                <td colSpan={3} style={{ textAlign: 'center' }}>
                  No Sessions
                </td>
              </tr>
            )}
            {pages.map((page, i) => (
              <React.Fragment
                key={`sales-page-ref-code-${selectedObj?.signed_up_with_referral_code_code}-page-${i}`}
              >
                {page.results.map(payment => (
                  <tr
                    key={`sales-page-ref-code-payment-${payment.id}`}
                    title={`Payment to ${payment.direction === 'TO_BETSTAMP' ? 'betstamp' : ''
                      }${payment.direction !== 'TO_BETSTAMP' && payment.attendee
                        ? 'attendee'
                        : ''
                      }${payment.direction !== 'TO_BETSTAMP' && payment.session
                        ? 'session'
                        : ''
                      }`}
                  >
                    <td>
                      <a
                        className="color-on-hover"
                        href={
                          payment.session
                            ? `/staff/sessions?search=${payment?.session}`
                            : `/staff/attendees?search=${payment?.attendee}`
                        }
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        {payment.attendee || payment.session}
                      </a>
                    </td>
                    <td style={{ textAlign: 'right' }}>
                      <span>{payment.reason.replaceAll('_', ' ')}</span>
                    </td>
                    <td style={{ textAlign: 'right' }}>
                      <span>
                        {formatMoney(payment.amount, payment.currency || 'CAD')}
                      </span>
                    </td>
                  </tr>
                ))}
              </React.Fragment>
            ))}
          </Tbody>
        </table>
        {fetchPayments.hasNextPage && (
          <AuthButton
            containerStyle={{
              margin: 'var(--space-lg) 0',
              maxWidth: '256px',
            }}
            btnTheme="borderless"
            onPress={() => fetchPayments.fetchNextPage()}
            isLoading={fetchPayments.isFetchingNextPage}
          >
            Load More
          </AuthButton>
        )}
      </>
    );
  }

  if (
    fetchAttendees.isLoading ||
    fetchSessions.isLoading ||
    fetchPayments.isLoading
  ) {
    return <ActivityIndicator size={2} />;
  }

  return <></>;
}

function FilterChildren(props) {
  const { urlParams } = props;

  const fetchMarketingGroups = useQuery({
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 2,
    queryKey: [
      {
        endpoint: `marketing-groups`,
      },
    ],
  });

  const marketingGroupOptions = useMemo(() => {
    let opts = [];
    if (
      fetchMarketingGroups?.isSuccess &&
      fetchMarketingGroups?.data?.length > 0
    ) {
      for (const group of fetchMarketingGroups.data) {
        opts.push({
          label: group.name,
          value: group.id,
        });
      }
    }
    return opts;
  }, [fetchMarketingGroups?.isSuccess, fetchMarketingGroups?.data]);

  const defaultFilters = useMemo(() => {
    let filters = {};
    if (urlParams.marketing_group_ids) {
      let d = {};
      for (const r of JSON.parse(urlParams.marketing_group_ids)) {
        d[r] = true;
      }
      filters.marketing_group_ids = d;
    }

    if (urlParams.balances) {
      let d = {};
      for (const r of JSON.parse(urlParams.balances)) {
        d[r] = true;
      }
      filters.balances = d;
    }

    if (urlParams.time_period) {
      filters.time_period = { [urlParams.time_period]: true };
    }
    if (urlParams.regions) {
      let d = {};
      for (const r of JSON.parse(urlParams.regions)) {
        d[r] = true;
      }
      filters.regions = d;
    }

    if (urlParams.date_after) {
      filters.date_after = new Date(urlParams.date_after);
    }

    if (urlParams.date_before) {
      filters.date_before = new Date(urlParams.date_before);
    }

    return filters;
  }, [urlParams]);

  const today = new Date();
  const lastWeek = [
    new Date().setDate(today.getDate() - today.getDay() - 7),
    new Date().setDate(today.getDate() + today.getDay() - 7),
  ];

  return (
    <div style={{ width: '100%' }}>
      {marketingGroupOptions?.length > 0 && (
        <GenericAdminFilter
          filterTitle="Referral Code Group"
          filterKey="marketing_group_ids"
          options={{
            groups: [
              { title: 'Referral Code Group', options: marketingGroupOptions },
            ],
          }}
          onFilter={selections => {
            props.onFilter({
              marketing_group_ids: JSON.stringify(selections),
            });
          }}
          defaultDict={defaultFilters.marketing_group_ids}
        />
      )}

      <GenericAdminFilter
        filterTitle="Balance"
        filterKey="balances"
        options={{
          groups: [
            {
              title: 'Balance',
              options: [
                { label: 'Even', value: 'even' },
                { label: 'They owe us', value: 'they_owe_us' },
                { label: 'We owe them', value: 'we_owe_them' },
              ],
            },
          ],
        }}
        onFilter={selections => {
          props.onFilter({
            balances: JSON.stringify(selections),
          });
        }}
        defaultDict={defaultFilters.balances}
      />

      <GenericAdminFilter
        filterTitle="Time Period"
        filterKey="time_period"
        singleChoice
        options={{
          groups: [
            {
              title: 'Time Period',
              options: [
                {
                  label: `Last Week (${humanDate(lastWeek[0], {
                    year: undefined,
                  })} - ${humanDate(lastWeek[1], { year: undefined })})`,
                  value: 'last_week',
                },
                {
                  label: `Last Month (${MONTHS[new Date().getMonth() - 1]})`,
                  value: 'last_month',
                },
                { label: 'Past Three Months', value: 'past_three_months' },
                { label: 'Year to Date', value: 'year_to_date' },
                { label: 'Month to Date', value: 'month_to_date' },
              ],
            },
          ],
        }}
        onFilter={selections => {
          props.onFilter({
            time_period: selections,
            date_after: null,
            date_before: null,
          });
        }}
        defaultDict={defaultFilters.time_period}
      />
      <GenericAdminFilter
        filterTitle="Region"
        filterKey="regions"
        options={{
          groups: [
            {
              title: 'Canada',
              options: PROVINCE_OPTIONS.map(p => ({ label: p, value: p })),
            },
            {
              title: 'USA',
              options: US_STATES.map(p => ({ label: p, value: p })),
            },
          ],
        }}
        onFilter={selections => {
          props.onFilter({
            regions: JSON.stringify(selections),
          });
        }}
        defaultDict={defaultFilters.regions}
      />
      <GenericAdminFilter
        filterTitle="After Date"
        filterKey="date_after"
        type="date"
        defaultDate={defaultFilters.date_after}
        onFilter={date => {
          props.onFilter({ date_after: date.toISOString(), time_period: null });
        }}
      />
      <GenericAdminFilter
        filterTitle="Before Date"
        filterKey="date_before"
        type="date"
        defaultDate={defaultFilters.date_before}
        onFilter={date => {
          props.onFilter({
            date_before: date.toISOString(),
            time_period: null,
          });
        }}
      />
    </div>
  );
}

//import React, { useState } from 'react';
//import { IoChevronUp, IoChevronDown } from 'react-icons/io5';
//import { useInfiniteQuery } from '@tanstack/react-query';
//
//// utils
//import { formatMoney } from 'utils';
//
//// components
//import { Row, Col } from 'components/generic/Layout';
//import StaffNavBar from 'components/nav/StaffNavBar';
//import { AuthButton, IconButton } from 'components/AuthButton';
//import ActivityIndicator from 'components/generic/ActivityIndicator';
//import AdminTable from 'components/generic/AdminTable';
//
//const SUPPORTED_GROUPINGS = [
//  'region',
//  'book',
//  'referral_code',
//  'source',
//  'marketing_group',
//];
//const GROUP_MAP = {
//  region: 'region',
//  book: 'progresses__book__name',
//  referral_code: 'signed_up_with_referral_code__code',
//  source: 'source',
//  marketing_group: 'signed_up_with_referral_code__group__name',
//};
//
//export default function OverallMetrics() {
//  const [hiddenGroups, setHiddenGroups] = useState({});
//  const [urlParams, setURLParams] = useState({
//    groupings: [],
//  });
//
//  const fetchData = useInfiniteQuery({
//    enabled: urlParams.groupings?.length > 0,
//    keepPreviousData: true,
//    refetchOnWindowFocus: false,
//    staleTime: 60,
//    queryKey: [
//      {
//        endpoint: `onboarding-metrics`,
//        urlParams: {
//          ...urlParams,
//          groupings: JSON.stringify(urlParams.groupings),
//        },
//      },
//    ],
//    getNextPageParam: (lastPage, allPages) => lastPage.next,
//    getPreviousPageParam: (firstPage, allPages) => firstPage.prev,
//  });
//  const pages = fetchData?.data?.pages;
//
//  let overall = null;
//  if (pages && pages?.length > 0 && pages[0]?.overall) {
//    overall = pages[0]?.overall;
//  }
//
//  return (
//    <>
//      <StaffNavBar />
//      <Row style={{ width: '100%', alignItems: 'flex-start' }}>
//        <div
//          style={{
//            flex: 3,
//            height: 'calc(100vh - 64px)',
//            overflowX: 'hidden',
//          }}
//        >
//          <Col
//            style={{
//              height: '100%',
//              overflowY: 'auto',
//              overflowX: 'auto',
//              flexWrap: 'nowrap',
//              justifyContent: 'flex-start',
//              alignItems: 'flex-start',
//            }}
//          >
//            <Row
//              style={{
//                flex: 0,
//                alignItems: 'center',
//                padding: 'var(--space-xs)',
//                width: '100%',
//                gap: 'var(--space-sm)',
//                position: 'sticky',
//                top: 0,
//                left: 0,
//                background: 'var(--color-bg)',
//                zIndex: 10,
//              }}
//            >
//              <div style={{ flex: '0 0 64px' }}>
//                {urlParams?.groupings?.length > 1 &&
//                  (fetchData?.isLoading ||
//                    fetchData?.isFetching ||
//                    fetchData?.isRefetching) && <ActivityIndicator size={2} />}
//              </div>
//              {SUPPORTED_GROUPINGS.map(group => (
//                <AuthButton
//                  key={`supp-group-${group}`}
//                  colorTheme={
//                    urlParams?.groupings?.includes(group)
//                      ? 'inverted'
//                      : 'primary'
//                  }
//                  onPress={() => {
//                    let newGroupings = Array.from(urlParams.groupings);
//                    if (urlParams.groupings.includes(group)) {
//                      newGroupings.splice(
//                        urlParams.groupings.indexOf(group),
//                        1
//                      );
//                    } else {
//                      newGroupings.push(group);
//                    }
//                    setURLParams({
//                      ...urlParams,
//                      groupings: newGroupings,
//                    });
//                  }}
//                >
//                  {group.toUpperCase().replaceAll('_', ' ')}
//                </AuthButton>
//              ))}
//              <div style={{ flex: '0 0 64px' }}></div>
//            </Row>
//            {urlParams.groupings.length === 0 && (
//              <h4 style={{ alignSelf: 'center' }}>Select groupings</h4>
//            )}
//
//            {urlParams.groupings.length === 1 && fetchData.isLoading && (
//              <ActivityIndicator size={3} style={{ marginTop: '20vh' }} />
//            )}
//
//            {fetchData.isError && (
//              <h5 style={{ alignSelf: 'center', color: 'var(--color-danger)' }}>
//                {fetchData?.error?.message}
//              </h5>
//            )}
//            {!!overall && (
//              <Row
//                style={{
//                  flex: 0,
//                  alignItems: 'center',
//                  padding: 'var(--space-xs)',
//                  width: '100%',
//                  gap: 'var(--space-sm)',
//                  position: 'sticky',
//                  top: 0,
//                  left: 0,
//                  background: 'var(--color-bg)',
//                  zIndex: 10,
//                }}
//              >
//                <Col
//                  style={{
//                    padding: 'var(--space-xs)',
//                    borderRadius: '8px',
//                    border: '1px solid var(--color-text-light)',
//                    height: '100%',
//                  }}
//                  title="Total number of attendees"
//                >
//                  <b>{overall?.num_attendees}</b>
//                  <small style={{ textAlign: 'center' }}>Attendees</small>
//                </Col>
//                <Col
//                  style={{
//                    padding: 'var(--space-xs)',
//                    borderRadius: '8px',
//                    border: '1px solid var(--color-text-light)',
//                    height: '100%',
//                  }}
//                  title="Sum of the target number of signups"
//                >
//                  <b>{overall?.sum_target}</b>
//                  <small style={{ textAlign: 'center' }}>Target</small>
//                </Col>
//                <Col
//                  style={{
//                    padding: 'var(--space-xs)',
//                    borderRadius: '8px',
//                    border: '1px solid var(--color-text-light)',
//                    height: '100%',
//                  }}
//                  title="Number of sportsbook accounts created"
//                >
//                  <b>{overall?.num_signups}</b>
//                  <small style={{ textAlign: 'center' }}>Signups</small>
//                </Col>
//                <Col
//                  style={{
//                    padding: 'var(--space-xs)',
//                    borderRadius: '8px',
//                    border: '1px solid var(--color-text-light)',
//                    height: '100%',
//                  }}
//                  title="Number of sportsbook that had a deposit made"
//                >
//                  <b>{overall?.num_deposits}</b>
//                  <small style={{ textAlign: 'center' }}>Deposits</small>
//                </Col>
//                <Col
//                  style={{
//                    padding: 'var(--space-xs)',
//                    borderRadius: '8px',
//                    border: '1px solid var(--color-text-light)',
//                    height: '100%',
//                  }}
//                  title="Number of sportsbook that had a bet placed at it"
//                >
//                  <b>{overall?.num_place_bets}</b>
//                  <small style={{ textAlign: 'center' }}>Placed Bets</small>
//                </Col>
//                <Col
//                  style={{
//                    padding: 'var(--space-xs)',
//                    borderRadius: '8px',
//                    border: '1px solid var(--color-text-light)',
//                    height: '100%',
//                  }}
//                  title="Number of sportsbook that we marked as fully complete"
//                >
//                  <b>{overall?.num_fully_complete}</b>
//                  <small style={{ textAlign: 'center' }}>Complete</small>
//                </Col>
//                <Col
//                  style={{
//                    padding: 'var(--space-xs)',
//                    borderRadius: '8px',
//                    border: '1px solid var(--color-text-light)',
//                    height: '100%',
//                  }}
//                  title="Difference between the target and the number of fully complete signups"
//                >
//                  <b>{overall?.signups_left}</b>
//                  <small style={{ textAlign: 'center' }}>Signups Left</small>
//                </Col>
//                <Col
//                  style={{
//                    padding: 'var(--space-xs)',
//                    borderRadius: '8px',
//                    border: '1px solid var(--color-text-light)',
//                    height: '100%',
//                  }}
//                  title="The number of fully complete books divided by the target"
//                >
//                  <b>
//                    {Math.round(overall?.fully_complete_conversion_rate * 100)}%
//                  </b>
//                  <small style={{ textAlign: 'center' }}>Conversion</small>
//                </Col>
//                <Col
//                  style={{
//                    padding: 'var(--space-xs)',
//                    borderRadius: '8px',
//                    border: '1px solid var(--color-text-light)',
//                    height: '100%',
//                  }}
//                  title="The sum of the initial setup session payments"
//                >
//                  <b>{formatMoney(overall?.sum_initial_payments, 'CAD')}</b>
//                  <small style={{ textAlign: 'center' }}>Initial Pymts</small>
//                </Col>
//                <Col
//                  style={{
//                    padding: 'var(--space-xs)',
//                    borderRadius: '8px',
//                    border: '1px solid var(--color-text-light)',
//                    height: '100%',
//                  }}
//                  title="The sum of the any money returned"
//                >
//                  <b>{formatMoney(overall?.sum_returned_payments, 'CAD')}</b>
//                  <small style={{ textAlign: 'center' }}>Returned Pymts</small>
//                </Col>
//                <Col
//                  style={{
//                    padding: 'var(--space-xs)',
//                    borderRadius: '8px',
//                    border: '1px solid var(--color-text-light)',
//                    height: '100%',
//                  }}
//                  title="The sum of the any bonuses paid (POST_ONBOARDING_BONUS or BETSTAMP_101_BONUS or GROUP_TARGET_BONUS)"
//                >
//                  <b>{formatMoney(overall?.sum_bonus_payments, 'CAD')}</b>
//                  <small style={{ textAlign: 'center' }}>Bonus Paid</small>
//                </Col>
//                <Col
//                  style={{
//                    padding: 'var(--space-xs)',
//                    borderRadius: '8px',
//                    border: '1px solid var(--color-text-light)',
//                    height: '100%',
//                  }}
//                  title="The sum of the min deposits at fully complete sportsbooks"
//                >
//                  <b>{formatMoney(overall?.sum_amount_used, 'CAD')}</b>
//                  <small style={{ textAlign: 'center' }}>Amount Used</small>
//                </Col>
//                <Col
//                  style={{
//                    padding: 'var(--space-xs)',
//                    borderRadius: '8px',
//                    border: '1px solid var(--color-text-light)',
//                    height: '100%',
//                  }}
//                  title="The sum of the initial payments sent minus the sum of the min deposits at fully completed sportsbooks minus the sum of the money returned (negative means we owe them)"
//                >
//                  <b>{formatMoney(overall?.balance, 'CAD')}</b>
//                  <small style={{ textAlign: 'center' }}>Balance</small>
//                </Col>
//              </Row>
//            )}
//
//            {urlParams.groupings.length > 0 &&
//              pages?.length > 0 &&
//              pages[0]?.results?.length > 0 && (
//                <AdminTable
//                  headings={urlParams.groupings
//                    .map(group => `${group.replaceAll('_', ' ')}`)
//                    .concat([
//                      'Attendees',
//                      'Target',
//                      'Signups',
//                      'Deposits',
//                      'Placed Bets',
//                      'Complete',
//                      'Signups Left',
//                      'Conversion Rate',
//                      'Initial Payments',
//                      'Returned Money',
//                      'Bonus Paid',
//                      'Amount Used',
//                      'Balance',
//                    ])}
//                  sortKeys={{
//                    annotated_balance: 'Balance',
//                    Balance: 'annotated_balance',
//                    signups_left: 'Signups Left',
//                    'Signups Left': 'signups_left',
//                    fully_complete_conversion_rate: 'Conversion Rate',
//                    'Conversion Rate': 'fully_complete_conversion_rate',
//                  }}
//                  onSort={sort => setURLParams({ ...urlParams, ...sort })}
//                  sort={urlParams?.sort}
//                >
//                  {pages.map((page, i) => (
//                    <React.Fragment key={`onb-metrics-page-${i}`}>
//                      {page.results.map((obj, j) => (
//                        <React.Fragment
//                          key={`onb-metrics-obj-${i}-${j}-${obj[GROUP_MAP[urlParams.groupings[obj.depth - 1]]]
//                            }`}
//                        >
//                          {!hiddenGroups[obj.parent_key] && (
//                            <tr
//                              title={`${obj.parent_key}${obj[
//                                GROUP_MAP[urlParams.groupings[obj.depth - 1]]
//                                ]
//                                }`}
//                              style={
//                                obj.depth < urlParams.groupings?.length
//                                  ? {
//                                    fontWeight: 'bold',
//                                    background: 'var(--color-fg)',
//                                    position: 'sticky',
//                                    top: 143 + (obj.depth - 1) * 73,
//                                    zIndex: 11,
//                                  }
//                                  : {}
//                              }
//                            >
//                              {Array.apply(null, Array(obj.depth - 1)).map(
//                                k => (
//                                  <td
//                                    key={`group-heading-with-subgroups-spacer-${k}`}
//                                  ></td>
//                                )
//                              )}
//                              <td
//                                style={{ background: 'rgba(255,0,0,0)' }}
//                                colSpan={
//                                  urlParams.groupings.length - (obj.depth - 1)
//                                }
//                              >
//                                <Row
//                                  style={{
//                                    justifyContent: 'flex-start',
//                                    alignItems: 'center',
//                                  }}
//                                >
//                                  {obj.depth === 1 ? <>⦿</> : <>⤷</>}&nbsp;
//                                  {obj[
//                                    GROUP_MAP[
//                                    urlParams.groupings[obj.depth - 1]
//                                    ]
//                                  ] || 'None'}
//                                  {obj.depth < urlParams.groupings.length && (
//                                    <IconButton
//                                      colorTheme="text"
//                                      iconName={
//                                        hiddenGroups[obj.children_key]
//                                          ? IoChevronDown
//                                          : IoChevronUp
//                                      }
//                                      onPress={() => {
//                                        const newHiddenGroups = {
//                                          ...hiddenGroups,
//                                        };
//                                        newHiddenGroups[obj.children_key] =
//                                          !hiddenGroups[obj.children_key];
//                                        setHiddenGroups(newHiddenGroups);
//                                      }}
//                                    />
//                                  )}
//                                </Row>
//                              </td>
//
//                              <td>{obj.num_attendees}</td>
//                              <td>
//                                {obj.sum_target !== null ? obj.sum_target : '-'}
//                              </td>
//                              <td>{obj.num_signups}</td>
//                              <td>{obj.num_deposits}</td>
//                              <td>{obj.num_place_bets}</td>
//                              <td>{obj.num_fully_complete}</td>
//                              <td>
//                                {obj.signups_left !== null
//                                  ? obj.signups_left
//                                  : '-'}
//                              </td>
//                              <td>
//                                {obj?.fully_complete_conversion_rate !== null
//                                  ? `${Math.round(
//                                    obj.fully_complete_conversion_rate * 100
//                                  )}%`
//                                  : '-'}
//                              </td>
//                              <td>
//                                {obj.sum_initial_payments !== null
//                                  ? formatMoney(obj.sum_initial_payments, 'CAD')
//                                  : '-'}
//                              </td>
//                              <td>
//                                {obj.sum_returned_payments !== null
//                                  ? formatMoney(
//                                    obj.sum_returned_payments,
//                                    'CAD'
//                                  )
//                                  : '-'}
//                              </td>
//                              <td>
//                                {obj.sum_bonus_payments !== null
//                                  ? formatMoney(obj.sum_bonus_payments, 'CAD')
//                                  : '-'}
//                              </td>
//                              <td>{formatMoney(obj.sum_amount_used, 'CAD')}</td>
//                              <td
//                                title={
//                                  obj.annotated_balance > 0
//                                    ? 'They owe us'
//                                    : 'We owe them'
//                                }
//                              >
//                                {obj.annotated_balance !== null
//                                  ? formatMoney(obj.annotated_balance, 'CAD')
//                                  : '-'}
//                              </td>
//                            </tr>
//                          )}
//                        </React.Fragment>
//                      ))}
//                    </React.Fragment>
//                  ))}
//                </AdminTable>
//              )}
//
//            {fetchData.hasNextPage && (
//              <AuthButton
//                containerStyle={{
//                  margin: 'var(--space-lg) 0',
//                  maxWidth: '256px',
//                }}
//                btnTheme="borderless"
//                onPress={() => fetchData.fetchNextPage()}
//                isLoading={fetchData.isFetchingNextPage}
//              >
//                Load More
//              </AuthButton>
//            )}
//          </Col>
//        </div>
//      </Row>
//    </>
//  );
//}
