export default {
  link_to_video: '',
  content: [
    { type: 'heading', text: 'How Verified Picks Work' },
    {
      type: 'paragraph',
      text:
        'When you track a bet on betstamp, our software will automatically classify this pick as verified or unverified.',
    },
    {
      type: 'video',
      url:
        'https://betstamp-public.sfo2.cdn.digitaloceanspaces.com/tutorials/verified-bets/verified-bets-video-1.mp4',
    },
    {
      type: 'paragraph',
      text:
        'In this video, we are trying to track a bet on the Dallas Stars at bet365. Our app has automatically checked the bet365 site and determined that the odds they are currently offering on Dallas are +115. ',
    },
    {
      type: 'paragraph',
      text:
        "Our tracked bet on Dallas at +115 will be verified. However, when we attempt to change the odds to +119 to give ourselves more favourable odds, the bet can not be verified. You'll notice if you are tracking a bet after its scheduled start time that it will also not be verified.",
    },
    {
      type: 'paragraph',
      text:
        'Tap the refresh icon as shown below to update the odds. Almost all odds shown on the app are up to the minute.',
    },
    {
      type: 'video',
      url:
        'https://betstamp-public.sfo2.cdn.digitaloceanspaces.com/tutorials/verified-bets/verified-bets-video-2.mp4',
    },
    { type: 'heading', text: 'Why is Verified vs Unverified Important?' },
    {
      type: 'paragraph',
      text:
        'Verifying your picks eliminates misinformation in the sports betting space. By tracking a pick at odds that are currently available and not after the game has started or completed, you can build a credible record.',
    },
    {
      type: 'paragraph',
      text:
        'Other users viewing your public profile will only be able to view your verified picks and your record against picks that were verified. Once you track a verified bet, it will remain on your public profile forever and cannot be deleted. ',
    },
    {
      type: 'paragraph',
      text:
        'Verified picks can still be edited for your own personal record keeping, but they will never change on your public profile.',
    },
    {
      type: 'paragraph',
      text:
        'If you decide to sell your picks through the betstamp app, only picks that are verified will be sent to your subscribers. Alternatively, if you are buying picks from a betstamp user, you are protected against falsified records and misinformation. ',
    },
    { type: 'heading', text: 'How Do I Identify Verified vs Unverified?' },
    {
      type: 'paragraph',
      text:
        'Verified picks will be marked with a blue betstamp logo while bets that are not verified will not have this logo. Shown below:',
    },
    {
      type: 'image',
      url:
        'https://betstamp-public.sfo2.cdn.digitaloceanspaces.com/tutorials/verified-bets/verified-bets-image.png',
    },
  ],
};
