import React, { useState } from 'react';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import moment from 'moment';

// components
import { Row } from 'components/generic/Layout';
import CustomSelect from 'components/generic/Select';

export default function DateRange(props) {
  // const [presetsOpen, setPresetsOpen] = useState(false);
  const [date1, setDate1] = useState(
    props.defaultStartDate === null
      ? null
      : moment(props.defaultStartDate).toDate() || moment().toDate()
  );
  const [date2, setDate2] = useState(
    props.defaultEndDate === null
      ? null
      : moment(props.defaultEndDate).toDate() || moment().toDate()
  );
  // const [allTime, setAllTime] = useState(props.defaultAllTime || false);

  const _getXDays = x => {
    let today = moment();
    let otherday = moment(today).add(x, 'days');
    if (x < 0) {
      today = today.endOf('day');
      otherday = otherday.startOf('day');
      props.getItemsInRange(otherday, today);
      setDate1(otherday);
      setDate2(today);
    } else {
      today = today.startOf('day');
      otherday = otherday.endOf('day');
      props.getItemsInRange(today, otherday);
      setDate1(today);
      setDate2(otherday);
    }
    // setAllTime(false);
    // setPresetsOpen(false);
  };

  return (
    <Row
      style={{
        alignItems: 'center',
        justifyContent: 'center',
        margin: 'auto',
        ...props.containerStyle,
      }}
    >
      <DateRangePicker
        className={'calendar'}
        format={'d MMM y'}
        onChange={dates => {
          if (!dates) {
            // setAllTime(true);
            setDate1(null);
            setDate2(null);
            props.getItemsInRange();
          } else {
            setDate1(dates[0]);
            setDate2(dates[1]);
            props.getItemsInRange(dates[0], dates[1]);
            // setAllTime(false);
          }
        }}
        value={[date1, date2]}
        rangeDivider={'to'}
        clearIcon={null}
      />

      <div style={{ flex: 1, maxWidth: '175px' }}>
        <CustomSelect
          placeholder={props.placeholder ? props.placeholder : 'Yesterday'}
          options={[
            { label: 'Yesterday', value: 'Yesterday' },
            { label: 'Past Week', value: 'Past Week' },
            { label: 'Previous Month', value: 'Previous Month' },
            { label: 'Last 30 Days', value: 'Last 30 Days' },
            { label: 'Month to Date', value: 'MTD' },
            { label: 'Past Quarter', value: 'Past Quarter' },
            { label: 'Past Year', value: 'Past Year' },
            { label: 'All Time', value: 'All Time' },
          ]}
          onChange={opt => {
            if (opt.value === 'All Time') {
              // setAllTime(true);
              setDate1(null);
              setDate2(null);
              props.getItemsInRange(
                props.allTimeReturnValue || null,
                props.allTimeReturnValue || null
              );
              // setPresetsOpen(false);
            } else if (opt.value === 'Past Year') {
              _getXDays(-365);
            } else if (opt.value === 'Past Quarter') {
              _getXDays(-90);
            } else if (opt.value === 'Last 30 Days') {
              _getXDays(-31);
            } else if (opt.value === 'Previous Month') {
              let startLastMonth = moment()
                .subtract(1, 'month')
                .startOf('month');
              let endLastMonth = moment().startOf('month').subtract(1, 'day');
              props.getItemsInRange(startLastMonth, endLastMonth);
              setDate1(startLastMonth);
              setDate2(endLastMonth);
            } else if (opt.value === 'MTD') {
              let startMonth = moment().startOf('month');
              let toDate = moment();
              props.getItemsInRange(startMonth, toDate);
              setDate1(startMonth);
              setDate2(toDate);
            } else if (opt.value === 'Past Week') {
              _getXDays(-7);
            } else if (opt.value === 'Yesterday') {
              let startyesterday = moment().subtract(1, 'day').startOf('day');
              let endyesterday = moment().subtract(1, 'day').endOf('day');
              props.getItemsInRange(startyesterday, endyesterday);
              setDate1(startyesterday);
              setDate2(endyesterday);
              // setAllTime(false);
              // setPresetsOpen(false);
            }
          }}
        />
      </div>
    </Row>
  );
}
