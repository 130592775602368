export const SET_GAME_SORT_BY = 'SET_GAME_SORT_BY';
export const SET_GAME_FILTER = 'SET_GAME_FILTER';
export const SET_APP_SETTING = 'SET_APP_SETTING';
export const TOGGLE_LEAGUE_FAV = 'TOGGLE_LEAGUE_FAV';

export const setGameSortBy = (gameSortBy) => ({
  type: SET_GAME_SORT_BY,
  gameSortBy,
});

export const setGameFilter = (gameFilter) => ({
  type: SET_GAME_FILTER,
  gameFilter,
});

export const setAppSetting = (setting, value) => ({
  type: SET_APP_SETTING,
  setting,
  value,
});

export const toggleLeagueFav = (league) => ({
  type: TOGGLE_LEAGUE_FAV,
  league,
});
