import React from 'react';

import { BlueBubble, OnboardingWrapper } from 'components/auth/onboarding';
import { Col } from 'components/generic/Layout';

export default function OddsCompare(props) {
  const nextScreen = '/onboarding/select-SBPRO';
  return (
    <OnboardingWrapper nextScreen={nextScreen} onNext={() => {}}>
      <Col style={{ flex: 0, width: '100%' }}>
        <h5
          style={{
            marginTop: 'var(--space-md)',
            marginBottom: 'var(--space-md)',
            fontWeight: 'bold',
          }}
        >
          There are many different sportsbooks, all with different odds
        </h5>
        <img
          style={{
            marginVertical: 'var(--space-md)',
            maxHeight: 326,
          }}
          src={
            'https://betstamp-public.sfo2.cdn.digitaloceanspaces.com/signup-flow/compare-odds.png'
          }
          alt={'Odds Comparison'}
        />
        <BlueBubble>
          <h5
            style={{
              marginTop: 'var(--space-md)',
              marginBottom: 'var(--space-md)',
              color: 'white',
            }}
          >
            Betstamp compares the odds from every sportsbook and shows you which
            one has the best odds for the bet you want to make
          </h5>
        </BlueBubble>
      </Col>
    </OnboardingWrapper>
  );
}
