import { Observable } from 'rxjs';
import 'rxjs/add/operator/exhaustMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import { ajax } from 'rxjs/observable/dom/ajax';
import { BACKEND_API_URL, getHeaders } from 'utils';

import { LOGOUT_ALL, logoutAllSuccess, logoutAllFailure } from 'actions';

export default function logoutAll(action$, state$) {
  return action$.ofType(LOGOUT_ALL).exhaustMap(() => {
    return ajax
      .post(BACKEND_API_URL + 'api/logoutall/', {}, getHeaders(state$))
      .map(data => logoutAllSuccess(data))
      .catch(error => Observable.of(logoutAllFailure(error.xhr)));
  });
}
