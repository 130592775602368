import { Observable } from 'rxjs';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import { ajax } from 'rxjs/observable/dom/ajax';
import { BACKEND_API_URL, getHeaders } from 'utils';

import {
  REFRESH_SS_BOOK_HISTORY,
  refreshSSBookHistorySuccess,
  refreshSSBookHistoryFailure,
} from 'actions';

export default function refreshSSBookHistory(action$, state$) {
  return action$.ofType(REFRESH_SS_BOOK_HISTORY).switchMap(action => {
    let url = `${BACKEND_API_URL}api/sharpsports/betslips/?bettor_account=${action.bettor_account_id}`;
    return ajax
      .getJSON(url, getHeaders(state$))
      .map(data => refreshSSBookHistorySuccess(data))
      .catch(error => Observable.of(refreshSSBookHistoryFailure(error.xhr)));
  });
}
