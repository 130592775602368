import { Observable } from 'rxjs';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import { ajax } from 'rxjs/observable/dom/ajax';
import { BACKEND_API_URL, getHeaders } from 'utils';

import { GET_2_HALF_BA, get2HalfBASuccess, get2HalfBAFailure } from 'actions';

export default function get2HalfBA(action$, state$) {
  return action$.ofType(GET_2_HALF_BA).switchMap(action => {
    let url =
      BACKEND_API_URL +
      `api/best_available/?game_id=${action.game_id}&period=2H`;

    return ajax
      .getJSON(url, getHeaders(state$))
      .map(data => get2HalfBASuccess(data))
      .catch(error => Observable.of(get2HalfBAFailure(error.xhr)));
  });
}
