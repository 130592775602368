import React, { useEffect, useState, useMemo } from 'react';
import { useEffectOnce, useIdle, useIntersection } from 'react-use';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import {
  IoChevronForward,
  IoChevronBack,
  IoTodayOutline,
  IoText,
  IoRemove,
  IoAdd,
} from 'react-icons/io5';
import moment from 'moment';
import { Link } from 'react-router-dom';
import {
  BsLayoutSidebarInsetReverse,
  BsLayoutSidebarReverse,
} from 'react-icons/bs';
import Analytics from 'amplitude/Analytics';

// components
import ActivityIndicator from 'components/generic/ActivityIndicator';
import AffiliateLink from 'components/AffiliateLink';
import { Row, Col } from 'components/generic/Layout';
import TeamLeagueLogo from 'components/TeamLeagueLogo';
import SearchBarGames from 'components/SearchBarGames';
import {
  Toolbar,
  MainAreaWrapper,
  InnerMainArea,
} from 'components/generic/Layout';
import BetSlip from 'components/BetSlip';
import CalendarInput from 'components/generic/CalendarInput';
import ErrorDisplay from 'components/generic/ErrorDisplay';
import LeagueSelector from 'components/LeagueSelector';
import { AuthButton, IconButton } from 'components/AuthButton';
import OddBtn2 from 'components/generic/OddBtn2';
import Select from 'components/generic/Select';

// actions
import {
  getSched,
  getSchedNext,
  getAllBooks,
  getOddscomp,
  getAffiliates,
  getOddsCompBA,
  getOddsCompGames,
  setOddsCompSize,
  setOddsCompOddTypes,
} from 'actions';

const COL_SIZES = { 1: 140, 2: 167, 3: 190, 4: 218, 5: 247 };
const FONT_SIZES = {
  1: 'var(--text-xs)',
  2: 'var(--text-sm)',
  3: 'var(--text-base-size)',
  4: 'var(--text-md)',
  5: 'var(--text-lg)',
};

const TOOLBAR_HEIGHT = 58;
const labels = {
  FT: 'Full Game',
  '1H': '1st Half, 1st Period, First 5 Innings, 1st Set',
  '2H': '2nd Half',
};

const SubToolbarRow = styled.div`
  flex: 1;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;

  box-sizing: border-box;
  flex: 0;
  width: 100%;
  padding: 0 var(--space-xs);
  display: ${props => {
    if (props.isLoading) {
      return 'none';
    }
    return 'flex';
  }};
  position: sticky;
  top: 0;
  background-color: var(--color-bg);
  z-index: 100;
`;

const LargeSearchBarRow = styled.div`
  flex: 1;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;

  height: 100%;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  border: 2px solid var(--color-text-light);
  border-radius: 25px;
  padding: 0 var(--space-sm);
  box-sizing: border-box;

  @media only screen and (max-width: 1220px) {
    display: none;
  }
`;

const SmallSearchBarRow = styled.div`
  flex: 1;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: var(--space-xxxs) var(--space-xs);
  margin-bottom: var(--space-xs);
  box-sizing: border-box;
  display: none;
  background: var(--color-fg);
  @media only screen and (max-width: 1220px) {
    display: flex;
  }
`;

const ScrollContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: scroll;
  padding: 0 var(--space-xxs);
`;

const RefreshingIndicator = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 100%;
  position: absolute;
  background-color: var(--color-primary);
  animation: move 1.5s infinite ease-in-out;
  @keyframes move {
    0% {
      left: 0;
      width: 10px;
    }
    25% {
      width: 13px;
    }
    50% {
      left: 100%;
      width: 10px;
    }
    75% {
      width: 13px;
    }
    100% {
      left: 0;
      width: 10px;
    }
  }
`;

export default function OddsComp(props) {
  const dispatch = useDispatch();
  const reduxProps = useSelector(state => ({
    user: state.authReducer.user,
    allBooks: state.authReducer.allBooks,
    showingDate: state.scheduleReducer.showingDate,
    schedule: state.scheduleReducer.schedule,
    fetchSchedError: state.scheduleReducer.fetchSchedError,
    searchterm: state.scheduleReducer.searchterm,
    defaultGameFilter: state.settingsReducer.defaultGameFilter,
    gameFilter: state.settingsReducer.gameFilter,
    isSearchingOrFiltering: state.scheduleReducer.isSearchingOrFiltering,
    bets: state.betslipReducer.bets,
    odds: state.oddscompReducer.odds,
    since: state.oddscompReducer.since,
    booksWithOdds: state.oddscompReducer.booksWithOdds,
    oddsCompBA: state.oddscompReducer.oddsCompBA,
    oddsCompGameRefresh: state.oddscompReducer.oddsCompGameRefresh,
    sizeIndex: state.oddscompReducer.sizeIndex,
    oddTypes: state.oddscompReducer.oddTypes,
    selectedLeagues: state.scheduleReducer.selectedLeagues,
  }));

  const {
    user,
    allBooks,
    showingDate,
    schedule,
    fetchSchedError,
    searchterm,
    defaultGameFilter,
    gameFilter,
    isSearchingOrFiltering,
    bets,
    odds,
    since,
    isLoadingNextSched,
    booksWithOdds,
    oddsCompBA,
    oddsCompGameRefresh,
    sizeIndex,
    oddTypes,
    selectedLeagues,
  } = reduxProps;

  const [calendarOpen, setCalendarOpen] = useState(false);
  const [showBetslip, setShowBetslip] = useState(false);
  const [period, setPeriod] = useState('FT');
  const isIdle = useIdle(60e3);

  const COL_WIDTH = COL_SIZES[sizeIndex];

  const uniqueBooks = useMemo(() => {
    if (allBooks && allBooks.length > 0 && user && user.books) {
      let ub = Array.from(user.books.filter(b => !b.is_custom));
      allBooks
        .sort((a, b) => {
          if (a.is_affiliate && !b.is_affiliate) return -1;
          if (!a.is_affiliate && b.is_affiliate) return 1;
          if (a.priority_level < b.priority_level) return -1;
          if (a.priority_level > b.priority_level) return 1;
          return 0;
        })
        .forEach(book => {
          if (
            (book.states.includes(user.state) || user.show_books === 'ALL') &&
            !book.is_casino &&
            !user.books.find(b => b.id === book.id)
          ) {
            ub.push(book);
          }
        });
      return ub;
    }
    return [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, allBooks]);

  const books = useMemo(() => {
    return uniqueBooks.filter(b => booksWithOdds.has(b.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uniqueBooks, booksWithOdds.size]);

  useEffectOnce(() => {
    dispatch(getAffiliates());
    if (allBooks.length === 0) {
      dispatch(getAllBooks());
    }
    if (Object.keys(schedule).length === 0) {
      dispatch(
        getSched(
          null, // leagues
          showingDate, // date
          null, // book
          null // search term
        )
      );
    }
  });

  const currGameFilter = gameFilter ? gameFilter : defaultGameFilter;

  let formattedPrevDate,
    formattedShowingDate,
    formattedNextDate = '';

  let scheduleForDate = undefined;
  if (schedule[showingDate.substr(0, 10)]) {
    scheduleForDate = schedule[showingDate.substr(0, 10)];
  }

  formattedShowingDate = moment(showingDate).format('ddd MMM D');
  if (scheduleForDate) {
    formattedPrevDate = moment(scheduleForDate.prevEventDate).format(
      'dddd MMMM D'
    );
    formattedNextDate = moment(scheduleForDate.nextEventDate).format(
      'dddd MMMM D'
    );
  } else {
    scheduleForDate = {
      prevEventDate: null,
      nextEventDate: null,
      games: [],
      isLoading: true,
    };
  }

  useEffect(() => {
    if (selectedLeagues) {
      Analytics.track(Analytics.events.VIEW_ODDSCOMP, user?.id, {
        leagues: selectedLeagues,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLeagues]);

  const gameIDs = useMemo(() => {
    return scheduleForDate.games.map(g => g.id);
  }, [scheduleForDate]);

  const bookIDs = useMemo(() => {
    return uniqueBooks.map(b => b.id);
  }, [uniqueBooks]);

  const historicalDay = useMemo(() => {
    return moment().diff(moment(showingDate), 'days') > 0;
  }, [showingDate]);

  const atLeastOneLiveGame = useMemo(() => {
    if (historicalDay) return true;
    if (Object.values(oddsCompGameRefresh).length > 0) {
      return Object.values(oddsCompGameRefresh).find(
        g => g.is_live || g.is_complete
      );
    }
    if (scheduleForDate.games) {
      return scheduleForDate.games.find(g => g.is_live || g.is_complete);
    }
    return false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scheduleForDate, oddsCompGameRefresh, historicalDay, showingDate]);

  // inital get odds when date changes or inital page load
  useEffect(() => {
    if (gameIDs?.length > 0) {
      dispatch(
        getOddscomp({
          game_ids: gameIDs,
          book_ids: bookIDs,
          period: period,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameIDs]);

  // light on queries, but may contain lot's of data, especially the first request
  useEffect(() => {
    const interval = setInterval(() => {
      if (!isIdle && !historicalDay) {
        dispatch(
          getOddscomp({
            game_ids: gameIDs,
            book_ids: bookIDs,
            period: period,
            since: since,
          })
        );
      }
    }, 1000 * 7);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameIDs, bookIDs, period, since, isIdle]);

  // heavy, 2 queries per game + 1
  useEffect(() => {
    const interval = setInterval(() => {
      if (!isIdle && !historicalDay) {
        dispatch(
          getOddsCompBA({
            game_ids: gameIDs,
            period: period,
          })
        );
      }
    }, 1000 * 27);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameIDs, period, isIdle]);

  // light, 1 query, do often to get updates on exclude books
  useEffect(() => {
    const interval = setInterval(() => {
      if (!isIdle && !historicalDay) {
        dispatch(
          getOddsCompGames({
            game_ids: gameIDs,
          })
        );
      }
    }, 1000 * 12);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameIDs, isIdle]);

  const displays = useMemo(() => {
    let showML,
      showSpread,
      showTotal = false;
    for (const t of oddTypes) {
      showML = showML || t === 'Moneyline';
      showSpread = showSpread || t === 'Spread';
      showTotal = showTotal || t === 'Total';
    }
    return {
      hideMLSpreadRow: oddTypes.length === 1 && oddTypes[0] === 'Total',
      hideML: !showML,
      hideSpread: !showSpread,
      hideTotal: !showTotal,
    };
  }, [oddTypes]);

  return (
    <Col style={{ height: '100vh', flexWrap: 'nowrap', overflow: 'hidden' }}>
      <Toolbar style={{ height: `${TOOLBAR_HEIGHT}px` }}>
        <LargeSearchBarRow style={{ border: 'none' }}>
          <SearchBarGames schedule={true} />
        </LargeSearchBarRow>
        <LeagueSelector schedule={true} />
        {showBetslip ? (
          <div style={{ position: 'relative', height: '100%' }}>
            <IconButton
              iconName={BsLayoutSidebarInsetReverse}
              iconSize={22}
              iconTitle="Collapse betslip"
              containerStyle={{
                height: '100%',
                padding: '0 var(--space-sm)',
                borderLeft: '1px solid var(--color-text)',
                display: 'flex',
                alignItems: 'center',
              }}
              iconColor="var(--color-text)"
              onPress={() => setShowBetslip(false)}
            />
            {bets.length > 0 && (
              <span
                style={{
                  height: '18px',
                  width: '18px',
                  position: 'absolute',
                  top: 0,
                  right: 2,
                  background: 'var(--color-danger)',
                  color: 'white',
                  borderRadius: '50%',
                  textAlign: 'center',
                  lineHeight: 1.2,
                  fontSize: '14px',
                }}
              >
                {bets.length}
              </span>
            )}
          </div>
        ) : (
          <div style={{ position: 'relative', height: '100%' }}>
            <IconButton
              iconName={BsLayoutSidebarReverse}
              iconSize={22}
              iconTitle="Expand betslip"
              containerStyle={{
                height: '100%',
                padding: '0 var(--space-sm)',
                borderLeft: '1px solid var(--color-text)',
                display: 'flex',
                alignItems: 'center',
              }}
              iconColor="var(--color-text)"
              onPress={() => setShowBetslip(true)}
            />
            {bets.length > 0 && (
              <span
                style={{
                  height: '18px',
                  width: '18px',
                  position: 'absolute',
                  top: 0,
                  right: 2,
                  background: 'var(--color-danger)',
                  color: 'white',
                  borderRadius: '50%',
                  textAlign: 'center',
                  lineHeight: 1.2,
                  fontSize: '14px',
                }}
              >
                {bets.length}
              </span>
            )}
          </div>
        )}
      </Toolbar>
      <MainAreaWrapper
        style={isSearchingOrFiltering ? { pointerEvents: 'none' } : {}}
      >
        <InnerMainArea>
          {fetchSchedError && (
            <ErrorDisplay
              error={fetchSchedError}
              message={'getting the schedule.'}
            />
          )}

          <SmallSearchBarRow>
            <SearchBarGames schedule={true} />
          </SmallSearchBarRow>

          <ScrollContainer id="infinite-scroll-target-games">
            <SubToolbarRow
              style={{ height: `${TOOLBAR_HEIGHT}px` }}
              isLoading={scheduleForDate.isLoading}
            >
              <Col style={{ flex: 0.2, maxWidth: '64px' }}>
                <IconButton
                  iconName={IoTodayOutline}
                  colorTheme="text"
                  onPress={() =>
                    dispatch(
                      getSched(
                        null,
                        moment().toISOString(true),
                        null,
                        null,
                        null,
                        true,
                        null,
                        true // reset
                      )
                    )
                  }
                  title={'Go to today'}
                />
              </Col>
              <Col style={{ flex: 0.3, maxWidth: '64px' }}>
                {scheduleForDate.prevEventDate && (
                  <IconButton
                    iconName={IoChevronBack}
                    colorTheme="text"
                    onPress={
                      scheduleForDate.prevEventDate
                        ? () =>
                          dispatch(
                            getSched(null, scheduleForDate.prevEventDate)
                          )
                        : null
                    }
                    title={
                      scheduleForDate.prevEventDate
                        ? formattedPrevDate
                        : 'No More Events'
                    }
                    disabled={!scheduleForDate.prevEventDate}
                  />
                )}
              </Col>
              <Col style={{ flex: 0.6, maxWidth: '256px' }}>
                <Row style={{ alignItems: 'center' }}>
                  <AuthButton
                    colorTheme="text"
                    btnTheme="borderless"
                    containerStyle={{ margin: 0 }}
                    onPress={() => setCalendarOpen(!calendarOpen)}
                    textStyle={{ fontSize: 'var(--text-md)' }}
                  >
                    {formattedShowingDate}
                  </AuthButton>
                  <CalendarInput
                    extraClassNames={['hide-calendar-inputs']}
                    onChange={date => {
                      setCalendarOpen(false);
                      dispatch(
                        getSched(
                          null,
                          moment(date).toISOString(true),
                          null,
                          null,
                          null,
                          null,
                          true // exact
                        )
                      );
                    }}
                    isOpen={calendarOpen}
                  />
                </Row>
              </Col>
              <Col style={{ flex: 0.3, maxWidth: '64px' }}>
                {scheduleForDate.nextEventDate && (
                  <IconButton
                    iconName={IoChevronForward}
                    colorTheme="text"
                    onPress={
                      scheduleForDate.nextEventDate
                        ? () =>
                          dispatch(
                            getSched(null, scheduleForDate.nextEventDate)
                          )
                        : null
                    }
                    title={
                      scheduleForDate.nextEventDate
                        ? formattedNextDate
                        : 'No More Events'
                    }
                    disabled={!scheduleForDate.nextEventDate}
                  />
                )}
              </Col>
              <Row
                title="Auto Refreshing Odds"
                style={{
                  flex: 0.66,
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  padding: '0 var(--space-md)',
                }}
              >
                {!isIdle ? (
                  <div
                    style={{ width: '11%', minWidth: '64px', height: '11px' }}
                  >
                    <div style={{ position: 'relative' }}>
                      <RefreshingIndicator />
                    </div>
                  </div>
                ) : (
                  <small>Auto Refreshing Paused</small>
                )}
              </Row>
              <Row
                style={{
                  flex: '0.33 0 100px',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <IconButton
                  title="Decrease Font Size"
                  colorTheme="text"
                  iconName={IoRemove}
                  disabled={sizeIndex <= 1}
                  onPress={() => dispatch(setOddsCompSize(sizeIndex - 1))}
                />
                <IoText
                  style={{ padding: '0 var(--space-md)' }}
                  title={`Font Size ${sizeIndex}`}
                  size={22}
                  color={'var(--color-text)'}
                />
                <IconButton
                  title="Increase Font Size"
                  colorTheme="text"
                  iconName={IoAdd}
                  disabled={sizeIndex >= 5}
                  onPress={() => dispatch(setOddsCompSize(sizeIndex + 1))}
                />
              </Row>
              <Row
                style={{
                  flex: '0.33 0 100px',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <OddTypeBtn t="Moneyline" />
                <OddTypeBtn t="Spread" />
                <OddTypeBtn t="Total" />
              </Row>
              <Col style={{ flex: 0.5 }}>
                <Select
                  defaultValue={{
                    value: period,
                    label: labels[period],
                  }}
                  options={[
                    { value: 'FT', label: 'Full Game' },
                    {
                      value: '1H',
                      label: '1st Half, 1st Period, First 5 Innings, 1st Set',
                    },
                    //{ value: '2H', label: '2nd Half' },
                  ]}
                  onChange={opt => {
                    dispatch(
                      getOddscomp({
                        game_ids: gameIDs,
                        book_ids: bookIDs,
                        period: opt.value,
                      })
                    );
                    setPeriod(opt.value);
                  }}
                />
              </Col>
            </SubToolbarRow>

            {(isSearchingOrFiltering || scheduleForDate.isLoading) &&
              fetchSchedError === null && (
                <ActivityIndicator
                  style={{ marginTop: 'var(--space-lg)' }}
                  size={3}
                />
              )}

            <div
              style={{
                height: `calc(100vh - ${2.5 * TOOLBAR_HEIGHT}px)`,
                overflowY: 'scroll',
                overflowX: 'scroll',
              }}
            >
              {scheduleForDate.games?.length > 0 && (
                <Row
                  style={{
                    width: '100%',
                    flexWrap: 'nowrap',
                    minWidth: atLeastOneLiveGame
                      ? `${32 + COL_WIDTH * (booksWithOdds.size + 2.33)}px`
                      : `${32 + COL_WIDTH * (booksWithOdds.size + 1.66)}px`,
                    borderBottom: '5px solid var(--color-text-light)',
                    justifyContent: 'flex-start',
                    position: 'sticky',
                    top: 0,
                    zIndex: 5,
                  }}
                >
                  <Col
                    style={{
                      flex: `0 0 ${COL_WIDTH * 0.66}px`,
                      position: 'sticky',
                      left: 0,
                      background: 'var(--color-bg)',
                      fontSize: FONT_SIZES[sizeIndex],
                    }}
                  >
                    <b>Side</b>
                  </Col>
                  {!!atLeastOneLiveGame && (
                    <Col
                      style={{
                        flex: `0 0 ${COL_WIDTH * 0.5}px`,
                        position: 'sticky',
                        left: `${COL_WIDTH * 0.66}px`,
                        background: 'var(--color-bg)',
                        fontSize: FONT_SIZES[sizeIndex],
                      }}
                    >
                      <b>Score</b>
                    </Col>
                  )}
                  <Col
                    style={{
                      flex: `0 0 ${COL_WIDTH}px`,
                      position: 'sticky',
                      left: atLeastOneLiveGame
                        ? `${COL_WIDTH * 0.66 + COL_WIDTH * 0.5}px`
                        : `${COL_WIDTH * 0.66}px`,
                      background: 'var(--color-bg)',
                      boxShadow: '4px 0px 4px -4px var(--color-text)',
                      fontSize: FONT_SIZES[sizeIndex],
                    }}
                    title={`Best Available ${labels[period]}`}
                  >
                    <b>Best Available</b>
                    <small
                      style={{
                        textAlign: 'center',
                        maxWidth: `${COL_WIDTH}px`,
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        lineClamp: 1,
                      }}
                    >
                      {labels[period]}
                    </small>
                  </Col>
                  {books.map(b => (
                    <AffCol
                      key={`ub-odds-comp-${b.id}`}
                      book={b}
                      COL_WIDTH={COL_WIDTH}
                    />
                  ))}
                </Row>
              )}
              {!scheduleForDate.isLoading &&
                scheduleForDate.games.length === 0 && (
                  <h6
                    style={{
                      textAlign: 'center',
                      marginTop: 'var(--space-sm)',
                    }}
                  >
                    {searchterm !== ''
                      ? `No results for:  "${searchterm}"`
                      : `No ${currGameFilter === 'all_games'
                        ? ''
                        : currGameFilter === 'only_unstarted_games'
                          ? 'unstarted '
                          : 'live '
                      }games on this day`}
                  </h6>
                )}
              {scheduleForDate.games.map((g, i) => {
                const game = oddsCompGameRefresh[g.id]
                  ? oddsCompGameRefresh[g.id]
                  : g;
                const ba =
                  oddsCompBA[g.id] &&
                    (oddsCompBA[g.id]?.period === period || game.is_live)
                    ? oddsCompBA[g.id]
                    : period === 'FT'
                      ? g.is_live && g.live_odds
                        ? g.live_odds
                        : g.best_available
                      : g.best_available_ht;

                let topTeam = game.away_team;
                let topRotNum = game.away_rotation_number;
                let topScore = game.away_score;
                let botTeam = game.home_team;
                let botRotNum = game.home_rotation_number;
                let botScore = game.home_score;
                if (game.sport === 'Soccer') {
                  topTeam = game.home_team;
                  topRotNum = game.home_rotation_number;
                  topScore = game.home_score;
                  botTeam = game.away_team;
                  botRotNum = game.away_rotation_number;
                  botScore = game.away_score;
                }
                return (
                  <Row
                    key={`odds-comp-${game.id}-${i}`}
                    style={{
                      borderBottom: '5px solid var(--color-text-light)',
                      justifyContent: 'flex-start',
                      flexWrap: 'nowrap',
                      minWidth: atLeastOneLiveGame
                        ? `${32 + COL_WIDTH * (booksWithOdds.size + 2.33)}px`
                        : `${32 + COL_WIDTH * (booksWithOdds.size + 1.66)}px`,
                    }}
                  >
                    <Link
                      to={`/games/${game.id}`}
                      style={{
                        display: 'flex',
                        flexFlow: 'column nowrap',
                        alignItems: 'center',
                        flex: `0 0 ${COL_WIDTH * 0.66}px`,
                        textDecoration: 'none',
                        position: 'sticky',
                        left: 0,
                        background: 'var(--color-bg)',
                        fontSize: FONT_SIZES[sizeIndex],
                        zIndex: 4,
                      }}
                      title={`${game.away_team.full_name} @ ${game.home_team.full_name
                        } on ${moment(game.date).format('ddd MMM Do, h:mma')}`}
                    >
                      <Row style={{ alignItems: 'center' }}>
                        {!!topRotNum && (
                          <small
                            style={{
                              lineBreak: 'anywhere',
                              maxWidth:
                                game.sport === 'MMA' || game.sport === 'Tennis'
                                  ? '52px'
                                  : '42px',
                            }}
                          >
                            {topRotNum}&nbsp;
                          </small>
                        )}
                        <TeamLeagueLogo
                          team={topTeam.name}
                          league={game.league}
                        />
                        <b
                          style={{
                            fontSize:
                              game.sport === 'MMA' || game.sport === 'Tennis'
                                ? '80%'
                                : '100%',
                          }}
                        >
                          &nbsp;{topTeam.name}
                        </b>
                      </Row>
                      <Row style={{ alignItems: 'center' }}>
                        {!!botRotNum && (
                          <small
                            style={{
                              lineBreak: 'anywhere',
                              maxWidth:
                                game.sport === 'MMA' || game.sport === 'Tennis'
                                  ? '52px'
                                  : '42px',
                            }}
                          >
                            {botRotNum}&nbsp;
                          </small>
                        )}
                        <TeamLeagueLogo
                          team={botTeam.name}
                          league={game.league}
                        />
                        <b
                          style={{
                            fontSize:
                              game.sport === 'MMA' || game.sport === 'Tennis'
                                ? '80%'
                                : '100%',
                          }}
                        >
                          &nbsp;{botTeam.name}
                        </b>
                      </Row>
                      {game.sport === 'Soccer' && (
                        <Row style={{ alignItems: 'center' }}>
                          <b>Tie</b>
                        </Row>
                      )}
                      {oddTypes.includes('Total') && (
                        <Row style={{ alignItems: 'center' }}>
                          <b>Total</b>
                        </Row>
                      )}
                    </Link>
                    {!!atLeastOneLiveGame && (
                      <Col
                        style={{
                          flex: `0 0 ${COL_WIDTH * 0.5}px`,
                          borderLeft: '1px solid var(--color-text-light)',
                          position: 'sticky',
                          left: `${COL_WIDTH * 0.66}px`,
                          background: 'var(--color-bg)',
                          fontSize: FONT_SIZES[sizeIndex],
                          zIndex: 4,
                        }}
                      >
                        {!!game.is_live || !!game.is_complete ? (
                          <>
                            <span>{topScore}</span>
                            <span>{botScore}</span>
                            <small
                              title={game.is_live_text}
                              style={{
                                whiteSpace: 'nowrap',
                                maxWidth: `${COL_WIDTH * 0.45}px`,
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                                color: game.is_complete
                                  ? 'var(--color-text)'
                                  : 'var(--color-success)',
                              }}
                            >
                              {!!game.is_complete
                                ? `Final${game.finish_type
                                  ? ` (${game.finish_type})`
                                  : ''
                                }`
                                : game.is_live_text.replace('● LIVE - ', '')}
                            </small>
                          </>
                        ) : (
                          <></>
                        )}
                      </Col>
                    )}
                    <OddCol
                      style={{
                        flex: `0 0 ${COL_WIDTH}px`,
                        borderLeft: '1px solid var(--color-text-light)',
                        position: 'sticky',
                        left: atLeastOneLiveGame
                          ? `${COL_WIDTH * 0.66 + COL_WIDTH * 0.5}px`
                          : `${COL_WIDTH * 0.66}px`,
                        background: 'var(--color-bg)',
                        boxShadow: '4px 0px 4px -4px var(--color-text)',
                        zIndex: 4,
                      }}
                      odd={{ ...ba, bestAvailable: true }}
                      game={game}
                      book={null}
                      period={period}
                      displays={displays}
                    />
                    {odds &&
                      odds[game.id] &&
                      books.map(book => (
                        <OddCol
                          key={`odd-col-${game.id}-${book.id}`}
                          odd={odds[game.id][book.id]}
                          game={game}
                          book={book}
                          period={period}
                          displays={displays}
                        />
                      ))}
                  </Row>
                );
              })}
              {!!scheduleForDate.next ? (
                <Row
                  style={{
                    width: '100%',
                    justifyContent: 'flex-start',
                    minWidth: atLeastOneLiveGame
                      ? `${32 + COL_WIDTH * (booksWithOdds.size + 2.33)}px`
                      : `${32 + COL_WIDTH * (booksWithOdds.size + 1.66)}px`,
                  }}
                >
                  <AuthButton
                    containerStyle={{
                      position: 'sticky',
                      left: 0,
                      maxWidth: `${COL_WIDTH * 1.66}px`,
                    }}
                    isLoading={isLoadingNextSched}
                    onPress={() => dispatch(getSchedNext())}
                  >
                    Load More Games
                  </AuthButton>
                </Row>
              ) : null}
            </div>
          </ScrollContainer>
        </InnerMainArea>
        {showBetslip && <BetSlip />}
      </MainAreaWrapper>
    </Col>
  );
}

function StyledOddBtn(props) {
  const reduxProps = useSelector(state => ({
    sizeIndex: state.oddscompReducer.sizeIndex,
  }));
  const { sizeIndex } = reduxProps;
  const COL_WIDTH = COL_SIZES[sizeIndex];
  const FONT_SIZE = FONT_SIZES[sizeIndex];
  return (
    <OddBtn2
      containerStyle={{
        borderRadius: 0,
        flex: `0 0 ${COL_WIDTH / 2}px`,
      }}
      btnStyle={{
        borderRadius: 0,
      }}
      textStyle={{
        whiteSpace: 'nowrap',
        maxWidth: `${COL_WIDTH / 2}px`,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        fontSize: FONT_SIZE,
        color:
          (props?.odd?.type_name === 'Moneyline' ||
            props?.odd?.side === 'Under') &&
            !props?.odd?.is_live &&
            props?.odd?.odds
            ? 'var(--color-complement-2)'
            : null,
      }}
      oddscomp={true}
      btnTheme="borderless"
      {...props}
    />
  );
}

function OddCol(props) {
  const reduxProps = useSelector(state => ({
    sizeIndex: state.oddscompReducer.sizeIndex,
    allBooksMap: state.authReducer.allBooksMap,
  }));
  const { sizeIndex, allBooksMap } = reduxProps;

  const intersectionRef = React.useRef(null);
  const intersection = useIntersection(intersectionRef, {
    root: null,
    rootMargin: '0px',
    threshold: 1,
  });

  const { odd, game, book, period, displays } = props;

  const hideOddPeriod = useMemo(() => {
    if (odd?.is_live) return false;
    if (period === '1H' && (odd?.period === 'FT' || odd?.period === '2H')) {
      return true;
    } else if (period !== '1H' && odd?.period !== period) {
      return true;
    }
    return false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [period, odd?.period]);

  const hideOddExclude = useMemo(() => {
    if (!odd?.is_live && game.exclude_books.includes(book?.name)) {
      return true;
    } else if (odd?.is_live && game.exclude_books_live.includes(book?.name)) {
      return true;
    }
    return false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [game.exclude_books, game.exclude_books.live]);

  const COL_WIDTH = COL_SIZES[sizeIndex];

  // by not rendering odd buttons, we save performance
  if (hideOddPeriod || hideOddExclude || !odd) {
    return (
      <div
        ref={intersectionRef}
        style={{
          flex: `0 0 ${COL_WIDTH}px`,
          borderLeft: '3px solid var(--color-text-light)',
          background: 'var(--color-bg)',
        }}
      />
    );
  }

  const COMMON = {
    period: odd?.period,
    oddID: odd?.id,
    bestAvailable: odd?.bestAvailable,
    game: game,
    is_live: odd?.is_live,
    book: odd?.book_id ? allBooksMap[odd.book_id] : null,
  };

  let awayMLBookID = odd.book_id || odd.away_ml_book_id;
  const awayMLBook = awayMLBookID ? allBooksMap[awayMLBookID] : null;

  let homeMLBookID = odd.book_id || odd.home_ml_book_id;
  const homeMLBook = homeMLBookID ? allBooksMap[homeMLBookID] : null;

  let tieMLBookID = odd.book_id || odd.tie_ml_book_id;
  const tieMLBook = tieMLBookID ? allBooksMap[tieMLBookID] : null;

  let homeSPBookID = odd.book_id || odd.home_spread_book_id;
  const homeSPBook = homeSPBookID ? allBooksMap[homeSPBookID] : null;

  let awaySPBookID = odd.book_id || odd.away_spread_book_id;
  const awaySPBook = awaySPBookID ? allBooksMap[awaySPBookID] : null;

  let overBookID = odd.book_id || odd.over_book_id;
  const overBook = overBookID ? allBooksMap[overBookID] : null;

  let underBookID = odd.book_id || odd.under_book_id;
  const underBook = underBookID ? allBooksMap[underBookID] : null;

  let topSpread = {
    ...COMMON,
    type_name: 'Spread',
    odds: odd?.away_spread_odds,
    number: -1 * odd?.spread || odd?.away_spread,
    side: game.away_team.name,
    //book: book || odd.away_spread_book,
    book: book || awaySPBook,
  };

  let botSpread = {
    ...COMMON,
    type_name: 'Spread',
    odds: odd?.home_spread_odds,
    number: odd?.spread || odd?.home_spread,
    side: game.home_team.name,
    //book: book || odd.home_spread_book,
    book: book || homeSPBook,
  };

  let topML = {
    ...COMMON,
    type_name: 'Moneyline',
    odds: odd?.away_ml,
    side: game.away_team.name,
    //book: book || odd.away_ml_book,
    book: book || awayMLBook,
  };

  let botML = {
    ...COMMON,
    type_name: 'Moneyline',
    odds: odd?.home_ml,
    side: game.home_team.name,
    //book: book || odd.home_ml_book,
    book: book || homeMLBook,
  };

  if (game.sport === 'Soccer') {
    let temp = topSpread;
    topSpread = botSpread;
    botSpread = temp;
    temp = topML;
    topML = botML;
    botML = temp;
  }

  return (
    <div
      ref={intersectionRef}
      style={{
        display: 'flex',
        flexFlow: 'column wrap',
        justifyContent: 'space-around',
        alignItems: 'center',
        flex: `0 0 ${COL_WIDTH}px`,
        borderLeft: '3px solid var(--color-text-light)',
        minHeight: '132px',
        ...props.style,
      }}
    >
      {intersection && intersection.intersectionRatio < 0.5 ? (
        <></>
      ) : (
        <>
          {!displays.hideMLSpreadRow && (
            <>
              <Row
                style={{
                  flexWrap: 'nowrap',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                {!displays.hideSpread && game.sport !== 'MMA' && (
                  <>
                    {topSpread.odds ? (
                      <StyledOddBtn odd={topSpread} />
                    ) : (
                      <div style={{ flex: `0 0 ${COL_WIDTH / 2}px` }} />
                    )}
                  </>
                )}
                {!displays.hideML && (
                  <>
                    {topML?.odds ? (
                      <StyledOddBtn odd={topML} />
                    ) : (
                      <div style={{ flex: `0 0 ${COL_WIDTH / 2}px` }} />
                    )}
                  </>
                )}
              </Row>
              <Row
                style={{
                  flexWrap: 'nowrap',
                  borderTop: '1px solid var(--color-text-light)',
                  borderBottom: '1px solid var(--color-text-light)',
                  width: '100%',
                  alignItems: 'center',
                }}
              >
                {!displays.hideSpread && game.sport !== 'MMA' && (
                  <>
                    {botSpread.odds ? (
                      <StyledOddBtn odd={botSpread} />
                    ) : (
                      <div style={{ flex: `0 0 ${COL_WIDTH / 2}px` }} />
                    )}
                  </>
                )}
                {!displays.hideML && (
                  <>
                    {botML.odds ? (
                      <StyledOddBtn odd={botML} />
                    ) : (
                      <div style={{ flex: `0 0 ${COL_WIDTH / 2}px` }} />
                    )}
                  </>
                )}
              </Row>
              {!displays.hideML && game.sport === 'Soccer' && (
                <Row
                  style={{
                    flexWrap: 'nowrap',
                    borderBottom: '1px solid var(--color-text-light)',
                    width: '100%',
                    alignItems: 'center',
                  }}
                >
                  {odd?.tie_ml ? (
                    <StyledOddBtn
                      odd={{
                        ...COMMON,
                        type_name: 'Moneyline',
                        odds: odd?.tie_ml,
                        side: 'Tie',
                        book: book || odd.tie_ml_book,
                      }}
                    />
                  ) : (
                    <div style={{ flex: `0 0 ${COL_WIDTH / 2}px` }} />
                  )}
                </Row>
              )}
            </>
          )}
          {!displays.hideTotal && (
            <>
              <Row
                style={{
                  flexWrap: 'nowrap',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                {odd?.over_odds ? (
                  <StyledOddBtn
                    odd={{
                      ...COMMON,
                      type_name: 'Total',
                      odds: odd?.over_odds,
                      number: odd?.total || odd?.over_total,
                      side: 'Over',
                      //book: book || odd.over_book,
                      book: book || overBook,
                    }}
                  />
                ) : (
                  <div style={{ flex: `0 0 ${COL_WIDTH / 2}px` }} />
                )}
                {odd?.under_odds ? (
                  <StyledOddBtn
                    odd={{
                      ...COMMON,
                      type_name: 'Total',
                      odds: odd?.under_odds,
                      number: odd?.total || odd?.under_total,
                      side: 'Under',
                      //book: book || odd.under_book,
                      book: book || underBook,
                    }}
                  />
                ) : (
                  <div style={{ flex: `0 0 ${COL_WIDTH / 2}px` }} />
                )}
              </Row>
            </>
          )}
        </>
      )}
    </div>
  );
}

function OddTypeBtn(props) {
  const dispatch = useDispatch();
  const reduxProps = useSelector(state => ({
    oddTypes: state.oddscompReducer.oddTypes,
  }));
  const { oddTypes } = reduxProps;
  const { t } = props;
  return (
    <AuthButton
      containerStyle={{
        flex: 0,
        width: 'fit-content',
        margin: '0 var(--space-xs)',
      }}
      btnStyle={{ borderRadius: '4px' }}
      colorTheme={oddTypes.includes(t) ? 'inverted' : 'primary'}
      title={`${oddTypes.includes(t) ? 'Hide' : 'Show'} ${t}`}
      btnTheme="borderless"
      onPress={() => {
        if (oddTypes.includes(t)) {
          dispatch(setOddsCompOddTypes(oddTypes.filter(o => o !== t)));
        } else {
          dispatch(setOddsCompOddTypes(oddTypes.concat([t])));
        }
      }}
    >
      {t === 'Moneyline' && 'ML'}
      {t === 'Spread' && 'SP'}
      {t === 'Total' && 'TO'}
    </AuthButton>
  );
}

function AffCol(props) {
  const { book, COL_WIDTH } = props;
  const intersectionRef = React.useRef(null);
  const intersection = useIntersection(intersectionRef, {
    root: null,
    rootMargin: '0px',
    threshold: 1,
  });

  if (intersection && intersection.intersectionRatio < 0.5) {
    return (
      <div
        ref={intersectionRef}
        style={{ flex: `0 0 ${COL_WIDTH}px` }}
        title={book.name}
      />
    );
  }
  return (
    <div
      ref={intersectionRef}
      style={{ flex: `0 0 ${COL_WIDTH}px` }}
      title={book.name}
    >
      <AffiliateLink
        shape="box"
        book={book}
        fromPage="oddscomp Table - Web"
        src={book.generic_banner ? book.generic_banner : null}
        hideBetlink
        hideText
        fallbackToGeneric
      />
    </div>
  );
}
