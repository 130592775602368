import { Observable } from 'rxjs';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import { ajax } from 'rxjs/observable/dom/ajax';
import { BACKEND_API_URL, getHeaders } from 'utils';

import {
  DELETE_TRANSACTION,
  deleteTransactionSuccess,
  deleteTransactionFailure,
} from 'actions';

export default function deleteTransaction(action$, state$) {
  return action$.ofType(DELETE_TRANSACTION).exhaustMap(action => {
    let url = `${BACKEND_API_URL}api/transactions/${action.id}/`;

    return ajax
      .delete(url, getHeaders(state$))
      .map(data => deleteTransactionSuccess(data.response, action.id))
      .catch(error =>
        Observable.of(deleteTransactionFailure(error.xhr, action.id))
      );
  });
}
