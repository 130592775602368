import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import SwipeableViews from 'react-swipeable-views';

// mui imports
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

// sub screens
import UserPicks from 'components/user_profile/UserPicks.toptab.js';
import UserAnalysis from 'components/user_profile/UserAnalysis.toptab.js';

// components
import { Row, Col } from 'components/generic/Layout';
import { LinkButton } from 'components/AuthButton';

// mui stuff
const StyledTabs = withStyles({
  root: {
    paddingBottom: 'var(--space-md)',
  },
  indicator: {
    backgroundColor: 'var(--color-primary)',
  },
})(props => <Tabs {...props} />);

const StyledTab = withStyles(theme => ({
  root: {
    flex: 1,
    color: 'var(--color-text)',
    transition: 'all var(--std-transition)',
    '&:hover': {
      backgroundColor: 'var(--color-fg)',
    },
    '&:focus': {
      opacity: 1,
    },
  },
}))(props => <Tab disableRipple {...props} />);

export default function ProfileTab(props) {
  const [tab, setTab] = useState(0);
  const {
    publicProfile_id,
    pendingName,
    historyName,
    viewingOtherUser,
    showPublicBets,
    hidePendingPlays,
    publicProfile_pending,
    publicProfile_name,
  } = props;

  const reduxProps = useSelector(state => ({
    userToken: state.authReducer.userToken,
  }));

  const { userToken } = reduxProps;

  const _handleChange = (event, showTab) => {
    setTab(showTab);
  };

  const _handleChangeIndex = showTab => {
    setTab(showTab);
  };
  return (
    <>
      <StyledTabs value={tab} onChange={_handleChange} centered>
        <StyledTab label="Pending" />
        <StyledTab label="History" />
        <StyledTab label="Analysis" />
      </StyledTabs>

      {userToken ? (
        <SwipeableViews
          axis={'x'}
          index={tab}
          onChangeIndex={_handleChangeIndex}
        >
          <div style={{ maxHeight: tab === 0 ? 'none' : '20vh' }}>
            <UserPicks
              screen={pendingName}
              graded_only={false}
              publicProfile_id={publicProfile_id}
              publicProfile_name={publicProfile_name}
              publicProfile_pending={publicProfile_pending}
              viewingOtherUser={viewingOtherUser}
              showPublicBets={showPublicBets}
              hidePendingPlays={hidePendingPlays}
            />
          </div>
          <div style={{ maxHeight: tab === 1 ? 'none' : '20vh' }}>
            <UserPicks
              screen={historyName}
              graded_only={true}
              publicProfile_id={publicProfile_id}
              viewingOtherUser={viewingOtherUser}
              showPublicBets={showPublicBets}
            />
          </div>
          <div style={{ maxHeight: tab === 2 ? 'none' : '20vh' }}>
            <UserAnalysis viewingOtherUser={viewingOtherUser} />
          </div>
        </SwipeableViews>
      ) : (
        <Col style={{ margin: 'var(--space-sm) 0' }}>
          <h6>
            Login or create an account to view {publicProfile_name}'s bets
          </h6>
          <Row>
            <LinkButton
              to="/login"
              containerStyle={{
                marginRight: 'var(--space-sm)',
              }}
            >
              Login
            </LinkButton>
            <LinkButton
              to="/signup"
              colorTheme="inverted"
              containerStyle={{
                marginLeft: 'var(--space-sm)',
              }}
            >
              Signup
            </LinkButton>
          </Row>
        </Col>
      )}
    </>
  );
}
