import Select from 'components/generic/Select';

const MAP = {
  ONBOARDING_SCHEDULED: 'Onboarding scheduled',
  ONBOARDING_ATTENDED: 'Onboarding attended',
  ONBOARDING_MISSED: 'Onboarding missed',
  ONBOARDING_DECLINED: 'Onboarding declined',
  VERIFYING_SUBMISSIONS_PENDING: 'Submissions pending',
  VERIFYING_SUBMISSIONS_PARTIAL_APPROVED: 'Submissions partially approved',
  VERIFYING_SUBMISSIONS_FULLY_APPROVED: 'Submissions fully approved',
  VERIFYING_SUBMISSIONS_PAID: 'Submissions paid',
  SUBMISSIONS_DECLINED: 'Submissions declined',
  SUBMISSIONS_ARCHIVED: 'Submissions archived',
  ONBOARDING_COMPLETE: 'Onboarding complete',
};

export default function StageDropdown(props) {
  const { attendee } = props;
  return (
    <Select
      key={`actual-stage-dropdown-component-${attendee?.id}`}
      label={props.hideLabel ? '' : 'Stage'}
      name="stage"
      options={[
        { value: 'ONBOARDING_SCHEDULED', label: MAP['ONBOARDING_SCHEDULED'] },
        { value: 'ONBOARDING_ATTENDED', label: MAP['ONBOARDING_ATTENDED'] },
        { value: 'ONBOARDING_MISSED', label: MAP['ONBOARDING_MISSED'] },
        { value: 'ONBOARDING_DECLINED', label: MAP['ONBOARDING_DECLINED'] },
        {
          value: 'VERIFYING_SUBMISSIONS_PENDING',
          label: MAP['VERIFYING_SUBMISSIONS_PENDING'],
        },
        {
          value: 'VERIFYING_SUBMISSIONS_PARTIAL_APPROVED',
          label: MAP['VERIFYING_SUBMISSIONS_PARTIAL_APPROVED'],
        },
        {
          value: 'VERIFYING_SUBMISSIONS_FULLY_APPROVED',
          label: MAP['VERIFYING_SUBMISSIONS_FULLY_APPROVED'],
        },
        {
          value: 'VERIFYING_SUBMISSIONS_PAID',
          label: MAP['VERIFYING_SUBMISSIONS_PAID'],
        },
        { value: 'SUBMISSIONS_DECLINED', label: MAP['SUBMISSIONS_DECLINED'] },
        { value: 'SUBMISSIONS_ARCHIVED', label: MAP['SUBMISSIONS_ARCHIVED'] },
        { value: 'ONBOARDING_COMPLETE', label: MAP['ONBOARDING_COMPLETE'] },
      ]}
      onChange={props.onChange}
      defaultValue={
        attendee.createNew
          ? {
              label: MAP['ONBOARDING_SCHEDULED'],
              value: 'ONBOARDING_SCHEDULED',
            }
          : {
              label: MAP[attendee.stage],
              value: attendee.stage,
            }
      }
    />
  );
}
