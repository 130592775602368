import React, { useMemo, useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { IoCheckmarkCircle } from 'react-icons/io5';
import InfiniteScroll from 'react-infinite-scroll-component';

// utils
import { REGULATED_STATES, MAX_SCREEN_HEIGHT_SIGNUP_ONBOARD } from 'utils';

// components
import { OnboardingWrapper } from 'components/auth/onboarding';
import { Col } from 'components/generic/Layout';
import { AuthButton } from 'components/AuthButton';

// actions
import { getAllBooks, postBooks } from 'actions';
const BOOK_BANNER_HEIGHT = '72px';

export default function SelectSBPro(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const reduxProps = useSelector(state => ({
    user: state.authReducer.user,
    allBooks: state.authReducer.allBooks,
  }));

  const { allBooks, user } = reduxProps;
  const [selectedBooks, setSelectedBooks] = useState({});
  const [forceLink, setForceLink] = useState(false);
  const headerTextRef = useRef(null);
  const headerButtonRef = useRef(null);

  useEffect(() => {
    if (allBooks.length === 0) {
      dispatch(getAllBooks());
    }

    if (user?.books?.length > 0) {
      // when users use back/forward browser button to get to this page, need to check if this condition is met
      for (const bookId in user.books) {
        if (user.books[bookId].is_autosyncable) {
          setForceLink(true);
          break;
        }
      }
    }
  }, []);

  // Use objects for faster look-up
  useEffect(() => {
    if (user?.books?.length > 0) {
      const newBooks = user.books.reduce((obj, item) => {
        return {
          ...obj,
          [item.id]: item,
        };
      }, {});
      setSelectedBooks(newBooks);
    }
  }, [user.books]);

  useEffect(
    // Sync selected books when users press forward browser button
    () =>
      history.listen(_ => {
        if (history.action === 'POP') {
          dispatch(postBooks(Object.values(selectedBooks)));
        }
      }),
    [selectedBooks]
  );

  const renderItem = item => {
    // renderer for a book item
    const selected = item.id in selectedBooks;
    return (
      <Col
        style={{
          justifyContent: 'center',
          minWidth: '50%',
          height: BOOK_BANNER_HEIGHT,
          padding: 'var(--space-xxs)',
        }}
        key={`sportsBook-${item.id}-${item.name}`}
      >
        <AuthButton
          overrideChildren={item.generic_banner !== null}
          btnTheme={item.generic_banner ? 'borderless' : null}
          colorTheme={
            item.generic_banner ? 'text' : selected ? 'inverted' : 'primary'
          }
          containerStyle={{
            width: '100%',
            height: '100%',
            borderRadius: 8,
            margin: 0,
            position: 'relative',
            maxWidth: '250px',
            display: 'flex',
            justifyContent: 'center',
          }}
          btnStyle={{
            width: '100%',
            height: '100%',
            borderRadius: 8,
            padding: 0,
          }}
          onPress={() => {
            let newSelectedBooks = { ...selectedBooks };
            if (item.id in newSelectedBooks) {
              delete newSelectedBooks[item.id];
            } else {
              newSelectedBooks[item.id] = item;
            }
            if (!REGULATED_STATES[user.state]) {
              let newForceLink = false;

              for (const bookId in newSelectedBooks) {
                if (newSelectedBooks[bookId].is_autosyncable) {
                  newForceLink = true;
                  break;
                }
              }
              setForceLink(newForceLink);
            }
            setSelectedBooks(newSelectedBooks);
          }}
          rightIcon={
            selected && !item.generic_banner ? IoCheckmarkCircle : null
          }
        >
          {item.generic_banner !== null ? (
            <>
              <img
                src={item.generic_banner}
                style={{ width: '100%', height: '100%', borderRadius: 8 }}
                alt={item.name}
              />
              {selected && (
                <Col
                  style={{
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                    borderRadius: 8,
                    backgroundColor: 'rgba(0,0,0,0.3)',
                    alignItems: 'flex-end',
                    justifyContent: 'center',
                    paddingRight: 'var(--space-xxxs)',
                  }}
                >
                  <IoCheckmarkCircle color="var(--color-primary)" size={28} />
                </Col>
              )}
            </>
          ) : (
            item.name
          )}
        </AuthButton>
      </Col>
    );
  };

  let nextScreen = '/onboarding/should-open-more-books';
  if (!REGULATED_STATES[user.state]) {
    nextScreen = '/onboarding/find-users';
  }
  const numSelectedBooks = Object.keys(selectedBooks).length;
  if (forceLink || numSelectedBooks > 9) {
    nextScreen = '/onboarding/find-users';
  }
  if (numSelectedBooks === 0) {
    nextScreen = '/onboarding/select-SBRookie';
  }

  const filterThenSort = books =>
    books
      .filter(b => b.is_active && !b.is_casino)
      .sort((a, b) => {
        if (a.generic_banner && b.generic_banner) {
          return 0;
        } else if (a.generic_banner && !b.generic_banner) {
          return -1;
        } else if (!a.generic_banner && b.generic_banner) {
          return 1;
        }
        return 0;
      });

  const books = useMemo(() => {
    if (!allBooks) {
      return null;
    }
    if (allBooks && REGULATED_STATES[user.state]) {
      return filterThenSort(
        allBooks.filter(b => b.states.includes(user.state))
      );
    }
    return filterThenSort(allBooks);
  }, [allBooks, user.state]);

  const headerTextHeight =
    headerTextRef?.current?.getBoundingClientRect()?.height;
  const headerButtonHeight =
    headerButtonRef?.current?.getBoundingClientRect()?.height;

  return (
    <OnboardingWrapper
      nextScreen={nextScreen}
      showSkip
      onNext={() => dispatch(postBooks(Object.values(selectedBooks)))}
    >
      <h5
        style={{
          marginTop: 'var(--space-md)',
          marginBottom: 'var(--space-md)',
          fontWeight: 'bold',
          textAlign: 'center',
          alignSelf: 'center',
        }}
        ref={headerTextRef}
      >
        Select all the sportsbooks you play at
      </h5>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        ref={headerButtonRef}
      >
        <AuthButton
          containerStyle={{
            marginBottom: 'var(--space-sm)',
          }}
          colorTheme="inverted"
          onPress={() => {
            dispatch(postBooks(Object.values(selectedBooks)));
            const newRoute = REGULATED_STATES[user.state]
              ? '/onboarding/select-SBRookie'
              : '/onboarding/unregulated-rookie';
            setTimeout(() => history.push(newRoute), 250); // delay a bit so that we have enough time to post books to backend
          }}
        >
          None, I do not have a sportsbook account
        </AuthButton>
      </div>
      {books?.length ? (
        <Col
          id="infinite-scroll-target-selectSBPro"
          style={{
            maxHeight:
              // betstamp logo = 64px in height, header text = variable, header button = variable height, skip/next button = 50px
              // rest is margin/padding
              `calc(min(100vh, ${MAX_SCREEN_HEIGHT_SIGNUP_ONBOARD}) - 2 * var(--space-sm) - 64px - 2 * var(--space-md) - ${headerTextHeight}px - ${headerButtonHeight}px - 50px)`,
            overflow: 'hidden scroll',
            minHeight: `${BOOK_BANNER_HEIGHT}`,
            justifyContent: 'flex-start',
            maxWidth: '600px',
          }}
        >
          <InfiniteScroll
            scrollableTarget={'infinite-scroll-target-selectSBPro'}
            dataLength={books?.length || 0}
            style={{
              display: 'flex',
              flexFlow: 'row wrap',
            }}
          >
            {books.map(book => renderItem(book))}
          </InfiniteScroll>
        </Col>
      ) : (
        <span>Sorry, No Sportbooks Available in Your Region</span>
      )}
    </OnboardingWrapper>
  );
}
