import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import {
  IoClose,
  IoCheckmarkCircleOutline,
  IoAlertCircle,
  IoOpenOutline,
} from 'react-icons/io5';
import { useIntersection } from 'react-use';
import { Link } from 'react-router-dom';

// utils
import {
  betLine,
  roundOdds,
  roundNumber,
  toAmericanOdds,
  calculateBetPayout,
} from 'utils';

// components
import { Row, Col } from 'components/generic/Layout';
import TeamLeagueLogo from 'components/TeamLeagueLogo';
import { BetstampStampInverted } from 'components/generic/Logos';
import GameStats from 'components/GameStats';
import { AuthButton, IconButton } from 'components/AuthButton';
import BetLineLogos from 'components/BetLineLogos';
import AutoColoredAmount from './generic/AutoColoredAmount';
import CompetitionLogo from 'components/generic/CompetitionLogo';

// actions
import {
  removeOneBet,
  editBetslipBet,
  clearTrackedBets,
  placeBet,
} from 'actions';
import { useUpdateEffect } from 'react-use';

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-around;
  box-sizing: border-box;
  border: 1px solid var(--color-text-light);
  padding: var(--space-xxxs);
  margin: var(--space-xxs);
  border-radius: var(--std-border-radius);
  &:hover {
    box-shadow: 0 2px 8px 0 var(--color-shadow);
  }
  &:hover + .hoverthing {
    display: block;
    opacity: 1;
  }
`;

// const HOVER_WIDTH = 648;
const HOVER_WIDTH = 664;
const HOVER_HEIGHT = 271; // only an estimate since it's not fixed

const HoverThing = styled.div`
  width: ${HOVER_WIDTH}px;
  // min-height: ${HOVER_HEIGHT}px;
  position: fixed;
  top: clamp(
    -1000px,
    ${props => `${props.top - HOVER_HEIGHT * 0.14}px`},
    calc(100vh - ${HOVER_HEIGHT + 10}px)
  );
  left: ${props => `${props.left - HOVER_WIDTH - 5}px`};
  background-color: var(--color-fg);
  border-radius: var(--std-border-radius);
  box-shadow: 0 2px 8px 0 var(--color-shadow);
  box-sizing: border-box;
  padding: var(--space-xs);
  display: ${props => {
    if (props.forceShow) {
      return 'block';
    }
    return 'none';
  }};
  opacity: ${props => {
    if (props.forceShow) {
      return '1';
    }
    return '0';
  }};
  transition: opacity var(--std-transition);
  &:hover {
    opacity: 1;
    display: block;
  }

  &:after {
    content: '';
    display: block;
    position: fixed;
    top: clamp(
      -1000px,
      ${props => `${props.top + 20}px`},
      calc(100vh - ${20}px)
    );
    left: ${props => `${props.left - 5}px`};
    margin-top: -10px;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 10px solid var(--color-fg);
  }
  z-index: 999;
`;

export default function BetSlipBet(props) {
  const dispatch = useDispatch();

  const [modalVisible, setModalVisible] = useState(false);

  const reduxProps = useSelector(state => ({
    user: state.authReducer.user,
    mode: state.betslipReducer.mode,
    isLoadingBetSlip: state.betslipReducer.isLoadingBetSlip,
    isLoadingBetSlipSuccess: state.betslipReducer.isLoadingBetSlipSuccess,
    isLoadingBetSlipFailure: state.betslipReducer.isLoadingBetSlipFailure,
    loadedBetslipUrl: state.betslipReducer.loadedBetslipUrl,
  }));
  const {
    user,
    mode,
    isLoadingBetslip,
    isLoadingBetSlipSuccess,
    isLoadingBetSlipFailure,
    loadedBetslipUrl,
  } = reduxProps;
  const { odds_preference } = user;

  useUpdateEffect(() => {
    if (isLoadingBetSlipSuccess) {
      window.open(loadedBetslipUrl, '_blank');
    }
  }, [isLoadingBetSlipSuccess]);

  const intersectionRef = React.useRef(null);
  const intersection = useIntersection(intersectionRef, {
    root: null,
    rootMargin: '0px',
    threshold: 1,
  });

  const { game } = props.bet;
  const startingComp = user.competitions ? user.competitions[0] : undefined;
  const mergedBet = {
    ...props.bet,
    ...props.bet.changes,
    competition: startingComp?.id,
  };

  const compLogo = startingComp?.logo;
  const compName = startingComp?.name;

  return (
    <>
      <Wrapper ref={intersectionRef} style={{ ...props.style }}>
        <Link
          to="/edit-betslip"
          style={{
            flex: 1,
            textDecoration: 'none',
            display: 'flex',
            flexFlow: 'row nowrap',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}
        >
          <Col
            style={{ cursor: 'pointer', alignItems: 'flex-start' }}
            onClick={
              props.onClick
                ? props.onClick
                : () => setModalVisible(!modalVisible)
            }
          >
            <BetLineLogos bet={mergedBet} odds_preference={odds_preference} />
            {mode === 'singles' && (
              <>
                $
                {`${mergedBet.risk_amount} to win $${calculateBetPayout(
                  mergedBet.risk_amount,
                  mergedBet.odds
                )}`}
              </>
            )}
          </Col>
          {mergedBet.tracked && (
            <Col style={{ flex: 0 }}>
              <IoCheckmarkCircleOutline
                style={{
                  fontSize: 28,
                  color: 'var(--color-success)',
                }}
                title={'Bet Tracked'}
              />
            </Col>
          )}
          {mergedBet.requires_action && (
            <Col style={{ flex: 0 }}>
              <IoAlertCircle
                style={{
                  fontSize: 28,
                  color: 'var(--color-danger)',
                }}
                title={'Requires Action'}
              />
            </Col>
          )}
          {mergedBet.competition && (
            <CompetitionLogo
              style={{ width: '20px', height: '20px' }}
              compLogo={compLogo}
              compName={compName}
            />
          )}
          {mergedBet.is_public && mode === 'singles' && (
            <Col
              style={{
                flex: '0 0 26px',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '100px',
                width: '24px',
                height: '24px',
                border: '1px solid var(--color-complement)',
                margin: '0 var(--space-xxxs)',
              }}
            >
              <small
                style={{
                  color: 'var(--color-complement)',
                  fontWeight: 'bold',
                }}
                title="This bet is public"
              >
                P
              </small>
            </Col>
          )}
          {mergedBet.is_verified && !mergedBet.requires_action && (
            <Col style={{ flex: 0 }}>
              <BetstampStampInverted
                title={'Bet Verified'}
                width={32}
                height={32}
                style={{ padding: '0 var(--space-xxxs)' }}
              />
            </Col>
          )}
          {!props.hideEdit && (
            <Col style={{ flex: 0 }}>
              <IconButton
                iconName={IoClose}
                iconColor="var(--color-text)"
                onPress={ev => {
                  ev.preventDefault();
                  dispatch(removeOneBet(props.bet));
                }}
                iconTitle={'Remove Bet'}
                iconSize={18}
              />
            </Col>
          )}
        </Link>

        {mode == 'singles' &&
          mergedBet.book.load_betslip &&
          !mergedBet.game?.is_live &&
          !mergedBet.game?.is_complete &&
          !mergedBet.season?.is_complete &&
          mergedBet.book.states.includes(user.state) &&
          !mergedBet.book.aff_exclude_states.includes(user.state) && (
            <AuthButton
              rightIcon={IoOpenOutline}
              isLoading={isLoadingBetslip}
              onPress={() => {
                const betslip_info = {
                  game: mergedBet['game'],
                  season: mergedBet['season'],
                  risk_amount: mergedBet['risk_amount'],
                  prop_type: mergedBet['prop_type'],
                  period: mergedBet['period'],
                  type_name: mergedBet['type_name'],
                  side: mergedBet['side'],
                  number: mergedBet['number'],
                  odds: mergedBet['odds'],
                  book_name: mergedBet['book']['name'],
                  generic_link: mergedBet['book']['generic_link'],
                  mode: mode,
                  is_future: mergedBet['is_future'],
                  player: mergedBet['player'],
                };
                dispatch(placeBet(betslip_info));
              }}
            >
              Place Bet at {mergedBet.book.name}
            </AuthButton>
          )}
        {isLoadingBetSlipFailure && (
          <Row>
            <b
              style={{
                color: 'var(--color-danger)',
              }}
            >
              {isLoadingBetSlipFailure.response.error}
            </b>
          </Row>
        )}
      </Wrapper>

      <HoverThing
        className="hoverthing"
        top={intersection ? intersection.intersectionRect.y : 0}
        left={intersection ? intersection.intersectionRect.x : 0}
        betHeight={intersection ? intersection.intersectionRect.height : 0}
        forceShow={modalVisible}
      >
        {!mergedBet.is_future && (
          <GameStats
            game={game}
            leagueIconSize={26}
            teamIconSize={42}
            smallScore
          />
        )}

        <br />

        <Row
          style={{
            boxSizing: 'border-box',
            width: '100%',
            padding: '0 var(--space-sm)',
          }}
        >
          <Col>
            <span style={{ lineHeight: 1 }}>
              {mergedBet.book.name.replace(
                'Live_Internal_betstamp_963',
                'Consensus'
              )}
            </span>
            <small style={{ lineHeight: 1 }}>book</small>
          </Col>
          <Col>
            {(mergedBet.type_name === 'Moneyline' ||
              mergedBet.type_name === 'Spread' ||
              (mergedBet.type_name === 'Game Prop' &&
                mergedBet.subtype === 'Moneyline')) && (
              <TeamLeagueLogo
                iconSize={32}
                displayTeam
                league={game.league}
                team={
                  mergedBet.side ? game.away_team.name : game.home_team.name
                }
                style={{ margin: 0, padding: 0, marginTop: '-3px' }}
              />
            )}
            {(mergedBet.type_name === 'Total' ||
              mergedBet.type_name === 'Player Prop' ||
              (mergedBet.type_name === 'Game Prop' &&
                mergedBet.subtype === 'Total')) && (
              <span style={{ lineHeight: 1 }}>
                {mergedBet.side ? 'Under' : 'Over'}
              </span>
            )}
            <small style={{ lineHeight: 1 }}>side</small>
          </Col>
          <Col>
            <span style={{ lineHeight: 1 }}>
              <span
                style={{
                  textDecoration:
                    mergedBet.latest_changes &&
                    mergedBet.latest_changes.odds &&
                    mergedBet.latest_changes.odds !== mergedBet.odds
                      ? 'line-through'
                      : 'none',
                }}
              >
                {odds_preference
                  ? roundOdds(mergedBet.odds)
                  : toAmericanOdds(mergedBet.odds)}
              </span>
              {mergedBet.latest_changes &&
                mergedBet.latest_changes.odds &&
                mergedBet.latest_changes.odds !== mergedBet.odds && (
                  <b>
                    {' '}
                    {odds_preference
                      ? roundOdds(mergedBet.latest_changes.odds)
                      : toAmericanOdds(mergedBet.latest_changes.odds)}
                  </b>
                )}
            </span>
            <small style={{ lineHeight: 1 }}>odds</small>
          </Col>
          {mergedBet.number && (
            <Col>
              <span style={{ lineHeight: 1 }}>
                <span
                  style={{
                    textDecoration:
                      mergedBet.latest_changes &&
                      mergedBet.latest_changes.number !== mergedBet.number
                        ? 'line-through'
                        : 'none',
                  }}
                >
                  {mergedBet.number > 0 && mergedBet.type_name === 'Spread'
                    ? `+${roundNumber(mergedBet.number)}`
                    : roundNumber(mergedBet.number)}
                </span>
                {mergedBet.latest_changes &&
                  mergedBet.latest_changes.number !== mergedBet.number && (
                    <b>
                      {' '}
                      {mergedBet.latest_changes.number > 0 &&
                      mergedBet.type_name === 'Spread'
                        ? `+${roundNumber(mergedBet.latest_changes.number)}`
                        : roundNumber(mergedBet.latest_changes.number)}
                    </b>
                  )}
              </span>
              <small style={{ lineHeight: 1 }}>number</small>
            </Col>
          )}
          <Col>
            <span style={{ lineHeight: 1 }}>${mergedBet.risk_amount}</span>
            <small style={{ lineHeight: 1 }}>risk</small>
          </Col>
          <Col>
            <span style={{ lineHeight: 1 }}>
              {mergedBet.is_graded ? (
                <AutoColoredAmount symbol={'$'}>
                  {mergedBet.result}
                </AutoColoredAmount>
              ) : (
                <>
                  $
                  {mergedBet.result === 0
                    ? calculateBetPayout(mergedBet.risk_amount, mergedBet.odds)
                    : mergedBet.result}
                </>
              )}
            </span>
            <small style={{ lineHeight: 1 }}>
              {mergedBet.is_graded ? 'result' : 'to win'}
            </small>
          </Col>
        </Row>

        <br />

        {mergedBet.is_verified && !mergedBet.requires_action && (
          <Row
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <BetstampStampInverted
              width={48}
              height={48}
              style={{ padding: '0 var(--space-sm)' }}
            />
            <h4
              style={{
                margin: 0,
                color: 'var(--color-primary)',
                fontWeight: 'bold',
              }}
            >
              Verified
            </h4>
          </Row>
        )}

        {mergedBet.tracked && (
          <>
            <br />
            <Row
              style={{
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <IoCheckmarkCircleOutline
                size={38}
                color="var(--color-success)"
              />
              <h6
                style={{
                  margin: 0,
                  color: 'var(--color-success)',
                  fontWeight: 'bold',
                }}
              >
                Bet Tracked
              </h6>
            </Row>
          </>
        )}

        {mergedBet.requires_action && mergedBet.latest_changes && (
          <>
            <br />
            <Row style={{ width: '100%' }}>
              <Col style={{ flex: 0 }}>
                <IoAlertCircle
                  size={28}
                  color="var(--color-danger)"
                  style={{ padding: '0 var(--space-xs)' }}
                />
              </Col>
              <Col style={{ alignItems: 'flex-start' }}>
                <p
                  style={{
                    margin: 0,
                    color: 'var(--color-danger)',
                  }}
                >
                  {mergedBet.latest_changes && 'The odds have changed!'}
                </p>

                {mergedBet.latest_changes &&
                  mergedBet.latest_changes.odds &&
                  mergedBet.latest_changes.odds !== mergedBet.odds && (
                    <span style={{ lineHeight: 1 }}>
                      New odds:{' '}
                      {odds_preference
                        ? roundOdds(mergedBet.latest_changes.odds)
                        : toAmericanOdds(mergedBet.latest_changes.odds)}
                    </span>
                  )}
                {mergedBet.latest_changes &&
                  mergedBet.latest_changes.number &&
                  mergedBet.latest_changes.number !== mergedBet.number && (
                    <span style={{ lineHeight: 1 }}>
                      New number:{' '}
                      {mergedBet.latest_changes.number > 0 &&
                      mergedBet.type_name === 'Spread'
                        ? `+${roundNumber(mergedBet.latest_changes.number)}`
                        : roundNumber(mergedBet.latest_changes.number)}
                    </span>
                  )}
              </Col>
              <Col>
                <AuthButton
                  containerStyle={{
                    width: 'calc(100% - var(--space-sm))',
                  }}
                  onPress={() => {
                    dispatch(editBetslipBet(props.index, {}, 'track_original'));
                    dispatch(clearTrackedBets());
                  }}
                >
                  Select Original
                </AuthButton>
                <span>{betLine(mergedBet, odds_preference)}</span>
              </Col>
              <Col>
                <AuthButton
                  containerStyle={{
                    width: 'calc(100% - var(--space-sm))',
                  }}
                  onPress={() => {
                    dispatch(editBetslipBet(props.index, {}, 'track_verified'));
                    dispatch(clearTrackedBets());
                  }}
                >
                  Select Verified
                </AuthButton>
                <span>
                  {betLine(
                    { ...mergedBet, ...mergedBet.latest_changes },
                    odds_preference
                  )}
                </span>
              </Col>
            </Row>
          </>
        )}

        {mergedBet.requires_action && mergedBet.errors && (
          <>
            <br />
            <Row
              style={{
                width: '100%',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}
            >
              <IoAlertCircle
                size={32}
                color="var(--color-danger)"
                style={{ padding: '0 var(--space-xs)' }}
              />
              {Object.keys(mergedBet.errors).map(k => (
                <p
                  key={`bsb-track-error-${k}`}
                  style={{ margin: 0, color: 'var(--color-danger)' }}
                >
                  <b>{k}</b>: {mergedBet.errors[k]}
                </p>
              ))}
            </Row>
          </>
        )}

        <br />
        <br />
      </HoverThing>
    </>
  );
}
