import { Observable } from 'rxjs';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import { ajax } from 'rxjs/observable/dom/ajax';
import { BACKEND_API_URL, getHeaders } from 'utils';

import { GET_ACCOUNTS, getAccountsSuccess, getAccountsFailure } from 'actions';

export default function getAccounts(action$, state$) {
  return action$.ofType(GET_ACCOUNTS).mergeMap(action => {
    return ajax
      .getJSON(BACKEND_API_URL + 'api/autosync_accounts/', getHeaders(state$))
      .map(data => getAccountsSuccess(data))
      .catch(error => Observable.of(getAccountsFailure(error.xhr)));
  });
}
