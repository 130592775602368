import { Observable } from 'rxjs';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import { ajax } from 'rxjs/observable/dom/ajax';
import moment from 'moment';
import { BACKEND_API_URL, getHeaders } from 'utils';

import {
  GET_LEADERBOARD,
  getLeaderboardSuccess,
  getLeaderboardFailure,
} from 'actions';

export default function getLeaderboard(action$, state$) {
  return action$.ofType(GET_LEADERBOARD).switchMap(action => {
    let end = action.limit ? '&limit=' + action.limit : '';
    end += action.sport ? '&sport=' + action.sport : '';
    end += action.order_by ? '&order_by=' + action.order_by : '';
    end += action.date_after
      ? '&date_after=' + moment(action.date_after).local().toISOString()
      : '';
    end += action.date_before
      ? '&date_before=' + moment(action.date_before).local().toISOString()
      : '';
    end += action.only_following ? '&only_following=true' : '';
    end += action.sample_size ? '&sample_size=' + action.sample_size : '';
    let url = 'api/users/?leaderboard=true' + end;
    return ajax
      .getJSON(BACKEND_API_URL + url, getHeaders(state$))
      .map(data => getLeaderboardSuccess(data))
      .catch(error => Observable.of(getLeaderboardFailure(error.xhr)));
  });
}
