import { Observable } from 'rxjs';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import { ajax } from 'rxjs/observable/dom/ajax';
import { BACKEND_API_URL, getHeaders } from 'utils';

import { GET_GAME_DATA, getGameDataSuccess, getGameDataFailure } from 'actions';

export default function getGameData(action$, state$) {
  return action$.ofType(GET_GAME_DATA).switchMap(action => {
    let url = BACKEND_API_URL + `api/games/${action.game_id}/`;

    return ajax
      .getJSON(url, getHeaders(state$))
      .map(data => getGameDataSuccess(data))
      .catch(error => Observable.of(getGameDataFailure(error.xhr)));
  });
}
