import React from 'react';
import { Redirect } from 'react-router-dom';
import { useDispatch } from 'react-redux';

// components
import { AuthButton } from 'components/AuthButton';
import { Row, Col } from './Layout';

// actions
import {
  resetToken,
  resetErrorsFeatured,
  resetErrorsUser,
  resetErrorsPicks,
  resetErrorsSearch,
  cleanAutoSyncStore,
} from 'actions';

export default function ErrorDisplay(props) {
  const dispatch = useDispatch();
  const { status } = props.error;

  if (status === 403) {
    dispatch(resetErrorsFeatured());
    dispatch(resetErrorsUser());
    dispatch(resetErrorsPicks());
    dispatch(resetErrorsSearch());
    dispatch(resetToken());
    dispatch(cleanAutoSyncStore());
    return <Redirect to="/login" />;
  }

  let res = [['']];

  try {
    if (props.error._response) {
      res = Object.entries(props.error._response);
    } else if (props.error.response) {
      res = Object.entries(props.error.response);
    } else {
      res = Object.entries(props.error);
    }
  } catch (e) {
    if (props.basic) {
      res = [['Sorry! We ran into an error ', props.message]];
    }
  }

  if (props.basic) {
    return (
      <div
        style={{ flex: 0, padding: 'var(--space-md)', ...props.containerStyle }}
      >
        {res.map((r, i) => (
          <div style={{ color: 'var(--color-danger)' }} key={`error-${i}`}>
            {`${r[0].replace('non_field_errors', '').replace('detail', '')} ${
              r[1]
            }`}
          </div>
        ))}
      </div>
    );
  }

  return (
    <Row>
      <Col style={{ flex: '1 0 325px' }}>
        <p style={{ color: 'var(--color-danger)' }}>
          Sorry! We ran into an error {props.message}
          {res.map((r, i) => (
            <React.Fragment key={`error-${i}`}>
              <span>{r[1]}</span>
              <br />
            </React.Fragment>
          ))}
        </p>
      </Col>
      {props.retry && (
        <Col style={{ flex: '1 0 325px' }}>
          <AuthButton
            containerStyle={{
              maxWidth: '50%',
            }}
            onPress={props.retry}
          >
            {props.retryMessage || 'Retry'}
          </AuthButton>
        </Col>
      )}
    </Row>
  );
}
