/**
 * Return the HTTP headers to use
 * @param {Object} redux state, user token is pulled from here
 * @returns {Object} the HTTP request headers object to use
 */
import { PLATFORM } from 'utils';
export default function getHeaders(state) {
  let headers = { PLATFORM: PLATFORM, 'Content-Type': 'application/json' };
  if (state?.value?.authReducer.userToken) {
    headers.Authorization = 'Token ' + state.value.authReducer.userToken;
  }
  return headers;
}
