import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

// utils
import { RELATIONSHIP_STATUS } from 'utils';

// components
import { AuthButton } from 'components/AuthButton';

// actions
import { followUser, unfollowUser } from 'actions';

export default function FollowBtn(props) {
  const dispatch = useDispatch();
  const reduxProps = useSelector(state => ({
    loggedInUser: state.authReducer.user,
    fetchError: state.publicUserReducer.fetchError,
    followLoading: state.publicUserReducer.followLoading,
    isLoading: state.publicUserReducer.isLoading,
    userRels: state.publicUserReducer.userRels,
  }));
  const { loggedInUser, followLoading, userRels } = reduxProps;

  const { user } = props;
  if (userRels[user.id]) {
    user.relationship_to_user = userRels[user.id];
  }

  const _followUser = () => {
    dispatch(followUser(user.id));
  };

  const _unfollowUser = () => {
    dispatch(unfollowUser(user.id));
  };

  let colorTheme = 'primary';
  let btnText = 'Follow';
  let _onPress = _followUser;
  let _loading = followLoading === user.id;
  let disabled = false;
  if (loggedInUser.id === user.id) {
    btnText = 'You';
    colorTheme = 'disabled';
    _onPress = () => {};
    disabled = true;
  } else if (
    user.relationship_to_user.status === RELATIONSHIP_STATUS.FOLLOWING
  ) {
    btnText = 'Unfollow';
    colorTheme = 'inverted';
    _onPress = _unfollowUser;
  } else if (
    user.relationship_to_user.status === RELATIONSHIP_STATUS.PURCHASED
  ) {
    btnText = 'Purchased';
    colorTheme = 'disabled';
    _onPress = () => {};
    disabled = true;
  }

  return (
    <AuthButton
      colorTheme={colorTheme}
      onPress={_onPress}
      isLoading={_loading}
      disabled={disabled}
    >
      {btnText}
    </AuthButton>
  );
}
