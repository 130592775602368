import { Observable } from 'rxjs';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import { ajax } from 'rxjs/observable/dom/ajax';
import { BACKEND_API_URL, getHeaders } from 'utils';

// import actions
import {
  UPDATE_SETTINGS,
  updateSettingsSuccess,
  updateSettingsFailure,
} from 'actions';

export default function updateSettings(action$, state$) {
  return action$
    .ofType(UPDATE_SETTINGS)
    .switchMap(action => {
      return ajax.patch(
        BACKEND_API_URL + `api/autosync_accounts/${action.params.id}/`,
        action.params.options,
        getHeaders(state$)
      );
    })
    .map(data => updateSettingsSuccess(data))
    .catch(error => Observable.of(updateSettingsFailure(error.xhr)));
}
