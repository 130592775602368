import {
  REFRESH_SHARPSPORTS_ALL,
  REFRESH_SHARPSPORTS_ALL_SUCCESS,
  REFRESH_SHARPSPORTS_ALL_FAILURE,
  REFRESH_SHARPSPORTS_BOOK,
  REFRESH_SHARPSPORTS_BOOK_SUCCESS,
  REFRESH_SHARPSPORTS_BOOK_FAILURE,
  GET_SHARPSPORTS_BOOKS,
  GET_SHARPSPORTS_BOOKS_SUCCESS,
  GET_SHARPSPORTS_BOOKS_FAILURE,
  FETCH_INTEGRATION_SS,
  FETCH_INTEGRATION_SS_SUCCESS,
  FETCH_INTEGRATION_SS_FAILURE,
  CLEAR_SS_INTEGRATION_DATA,
  UPDATE_SHARPSPORTS_BOOK,
  UPDATE_SHARPSPORTS_BOOK_SUCCESS,
  UPDATE_SHARPSPORTS_BOOK_FAILURE,
  RESET_SS_ERRORS,
} from 'actions';

const initialState = {
  isLoadingSharpsports: false,
  fetchSharpsportsError: null,
  sharpsportsBooks: [],
  isFetchingIntegration: false,
  integrationData: null,
  fetchIntegrationError: null,
  refreshingSharpsportsAll: false,
  refreshingSharpsportsAllError: null,
  refreshingSharpsportsAllMessage: null,
  refreshingSharpsportsBook: null,
  refreshingSharpsportsBookFailure: null,
  refreshingSharpsportsBookSuccess: null,
  updatingSSBook: null,
  updatingSSBookError: null,
};

export default function sharpsportsReducer(state = initialState, action) {
  switch (action.type) {
    case REFRESH_SHARPSPORTS_ALL:
      return Object.assign({}, state, {
        ...state,
        refreshingSharpsportsAll: true,
        refreshingSharpsportsAllError: null,
        refreshingSharpsportsAllMessage: null,
      });
    case REFRESH_SHARPSPORTS_ALL_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        refreshingSharpsportsAll: false,
        refreshingSharpsportsAllError: null,
        refreshingSharpsportsAllMessage: action.message ? action.message : '',
      });
    case REFRESH_SHARPSPORTS_ALL_FAILURE:
      return Object.assign({}, state, {
        ...state,
        refreshingSharpsportsAll: false,
        refreshingSharpsportsAllError: action.error.response,
        refreshingSharpsportsAllMessage: null,
      });

    case REFRESH_SHARPSPORTS_BOOK:
      return Object.assign({}, state, {
        ...state,
        refreshingSharpsportsBook: action.bettorAccount,
        refreshingSharpsportsBookFailure: null,
        refreshingSharpsportsBookSuccess: null,
      });
    case REFRESH_SHARPSPORTS_BOOK_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        refreshingSharpsportsBook: null,
        refreshingSharpsportsBookFailure: null,
        refreshingSharpsportsBookSuccess: true,
      });
    case REFRESH_SHARPSPORTS_BOOK_FAILURE:
      return Object.assign({}, state, {
        ...state,
        refreshingSharpsportsBook: null,
        refreshingSharpsportsBookFailure: action.error,
        refreshingSharpsportsBookSuccess: null,
      });

    case GET_SHARPSPORTS_BOOKS:
      return Object.assign({}, state, {
        ...state,
        isLoadingSharpsports: true,
        fetchSharpsportsError: null,
      });
    case GET_SHARPSPORTS_BOOKS_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        isLoadingSharpsports: false,
        fetchSharpsportsError: null,
        sharpsportsBooks: action.payload.results,
      });
    case GET_SHARPSPORTS_BOOKS_FAILURE:
      return Object.assign({}, state, {
        ...state,
        isLoadingSharpsports: false,
        fetchSharpsportsError: action.error,
        sharpsportsBooks: [],
      });

    case FETCH_INTEGRATION_SS:
      return Object.assign({}, state, {
        ...state,
        isFetchingIntegration: true,
        fetchIntegrationError: null,
        integrationData: null,
      });
    case FETCH_INTEGRATION_SS_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        isFetchingIntegration: false,
        fetchIntegrationError: null,
        integrationData: action.payload,
      });
    case FETCH_INTEGRATION_SS_FAILURE:
      return Object.assign({}, state, {
        ...state,
        isFetchingIntegration: false,
        fetchIntegrationError: action.error,
        integrationData: null,
      });
    case CLEAR_SS_INTEGRATION_DATA:
      return Object.assign({}, state, {
        ...state,
        isFetchingIntegration: false,
        fetchIntegrationError: null,
        integrationData: null,
      });

    case UPDATE_SHARPSPORTS_BOOK:
      return Object.assign({}, state, {
        ...state,
        updatingSSBook: action.book.id,
        updatingSSBookError: null,
      });
    case UPDATE_SHARPSPORTS_BOOK_SUCCESS:
      const newSSbook = state.sharpsportsBooks.map(b => {
        if (b.id === action.payload.id) {
          return action.payload;
        }
        return b;
      });
      return Object.assign({}, state, {
        ...state,
        updatingSSBook: null,
        updatingSSBookError: null,
        sharpsportsBooks: newSSbook,
      });
    case UPDATE_SHARPSPORTS_BOOK_FAILURE:
      return Object.assign({}, state, {
        ...state,
        updatingSSBook: null,
        updatingSSBookError: action.error,
      });

    case RESET_SS_ERRORS:
      return Object.assign({}, state, {
        isLoadingSharpsports: false,
        fetchSharpsportsError: null,
        isFetchingIntegration: false,
        integrationData: null,
        fetchIntegrationError: null,
        refreshingSharpsportsAll: false,
        refreshingSharpsportsAllError: null,
        refreshingSharpsportsAllMessage: null,
        refreshingSharpsportsBook: null,
        refreshingSharpsportsBookFailure: null,
        refreshingSharpsportsBookSuccess: null,
        updatingSSBook: null,
        updatingSSBookError: null,
      });
    default:
      return state;
  }
}
