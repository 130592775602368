import React from 'react';

import { BetstampStampInverted } from 'components/generic/Logos';

export default function SignupHeader(props) {
  const { style, label, logoSize } = props;
  return (
    <>
      <BetstampStampInverted height={logoSize} />
      <h1
        style={{
          marginTop: 'var(--space-md)',
          marginBottom: 'var(--space-md)',
          ...style,
        }}
      >
        {label}
      </h1>
    </>
  );
}
