import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

// components
import { Row, Col } from 'components/generic/Layout';

const Hero = styled.div`
  width: 100%;
  background-color: var(--color-primary);
  color: white;
  padding: var(--space-xl);
  box-sizing: border-box;
  @media only screen and (max-width: 625px) {
    padding: var(--space-sm);
    padding-bottom: var(--space-xl);
  }
`;

export default function GamblingProblem() {
  return (
    <>
      <Hero>
        <Col style={{ alignItems: 'flex-start' }}>
          <h1>Gambling Problem?</h1>
          <p>
            Get help by contacting the appropriate support systems for your
            region.
          </p>
        </Col>
      </Hero>
      <Col
        style={{
          alignItems: 'flex-start',
          margin: '0 var(--space-xl)',
          paddingBottom: 'var(--space-xl)',
        }}
      >
        <h3>New Jersey:</h3>
        <p>GAMBLING PROBLEM? CALL 1-800-GAMBLER • 21+</p>
        <h3>Indiana:</h3>
        <p>
          GAMBLING PROBLEM? CALL 1-800-9-WITH-IT • 1-800-994-8448 • MUST BE 21+
        </p>
        <h3>Colorado:</h3>
        <p>MUST BE 21+ • GAMBLING PROBLEM? CALL 1-800-522-4700</p>
        <h3>Illinois:</h3>
        <p>
          MUST BE 21 YEARS OF AGE OR OLDER • IF YOU OR SOMEONE YOU KNOW HAS A
          GAMBLING PROBLEM, CRISIS COUNSELING AND REFERRAL SERVICES CAN BE
          ACCESSED BY CALLING 1-800-GAMBLER (1-800-426-2537)
        </p>
        <h3>Michigan:</h3>
        <p>21+ GAMBLING PROBLEM? CALL 1-800-270-7117</p>
        <h3>Iowa:</h3>
        <p>GAMBLING PROBLEM? CALL 1-800-BETS-OFF</p>
        <h3>Ontario:</h3>
        <p>
          MUST BE 19+ • PLEASE PLAY RESPONSIBLY • IF YOU HAVE QUESTIONS OR
          CONCERNS ABOUT YOUR GAMBLING OR SOMEONE CLOSE TO YOU, PLEASE CONTACT
          CONNEXONTARIO AT 1-866-531-2600 TO SPEAK TO AN ADVISOR, FREE OF CHARGE
        </p>
        <h3>Connecticut:</h3>
        <p>
          MUST BE 21+ • IF YOU OR SOMEONE YOU KNOW HAS A GAMBLING PROBLEM AND
          WANTS HELP, CALL: 1 (888) 789-7777 OR VISIT{' '}
          <a href={'https://www.ccpg.org/chat'}>CCPG.ORG/CHAT</a>
        </p>
        <h3>New York:</h3>
        <p>
          Must be 21+. Gambling Problem? Call 1-877-8-HOPENY or text HOPENY
          (467369). Standard text rates may apply.
        </p>
      </Col>
    </>
  );
}
