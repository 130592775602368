import { Observable } from 'rxjs';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import { ajax } from 'rxjs/observable/dom/ajax';
import { getHeaders } from 'utils';

import {
  GET_PLAYER_PROP_PLAYER_LIST_NEXT,
  getPlayerPropPlayerListSuccess,
  getPlayerPropPlayerListFailure,
} from 'actions';

export default function getPlayerPropPlayerListNext(action$, state$) {
  return action$.ofType(GET_PLAYER_PROP_PLAYER_LIST_NEXT).switchMap(() => {
    let url = state$.value.gameReducer.playerPropPlayerListNext;
    return ajax
      .getJSON(url, getHeaders(state$))
      .map(data => getPlayerPropPlayerListSuccess(data))
      .catch(error => Observable.of(getPlayerPropPlayerListFailure(error.xhr)));
  });
}
