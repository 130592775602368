import React, { useState } from 'react';
import styled from 'styled-components';
import {
  useQuery,
  useInfiniteQuery,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';

// utils
import { humanDate, humanDatetime, REGION_FLAGS } from 'utils';

// components
import StaffNavBar from 'components/nav/StaffNavBar';
import { Grid, Row } from 'components/generic/Layout';
import Select from 'components/generic/Select';
import MailboxLayout from 'components/generic/MailboxLayout';
import { AuthButton } from 'components/AuthButton';
import { AuthTextInput } from 'components/AuthTextInput';
import ModalWrapper from 'components/generic/ModalWrapper';
import ActivityIndicator from 'components/generic/ActivityIndicator';

const Tbody = styled.tbody`
  > tr {
    transition: all var(--std-transition);
  }
  > tr:hover {
    background-color: var(--color-text-light);
  }
`;

export default function VerifyAttendees() {
  const fetchMarketingGroups = useQuery({
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 2,
    queryKey: [
      {
        endpoint: `marketing-groups`,
      },
    ],
  });

  if (fetchMarketingGroups.isLoading) {
    return <ActivityIndicator />;
  }

  let marketingGroups = [];
  if (fetchMarketingGroups.data?.length > 0) {
    marketingGroups = fetchMarketingGroups.data.reduce((acc, curr) => {
      if (!['Friends + Family', 'Betstamp 101', 'Groups'].includes(curr.name)) {
        acc.push(curr.id);
      }
      return acc;
    }, []);
  }

  return (
    <>
      <StaffNavBar />
      <MailboxLayout
        modelOptions={[
          { label: 'Attendees To Verify', value: 'verifyAttendees' },
          { label: 'All Attendees', value: 'allAttendees' },
        ]}
        endpoints={{
          verifyAttendees: {
            endpoint: `onboarding-attendees`,
            urlParams: {
              verification_statuses: JSON.stringify(['NEEDS_VERIFICATION']),
              sort: JSON.stringify(['created_at']),
              marketing_group_ids: JSON.stringify(marketingGroups),
            },
          },
          allAttendees: {
            endpoint: `onboarding-attendees`,
            urlParams: {
              verification_statuses: null,
              sort: JSON.stringify(['created_at']),
            },
          },
        }}
        modelNames={{
          verifyAttendees: 'Attendee',
          allAttendees: 'Attendee',
        }}
        titleKeys={{
          verifyAttendees: 'full_name',
          allAttendees: 'full_name',
        }}
        descKeys={{
          verifyAttendees: 'created_at',
          allAttendees: 'created_at',
        }}
        searchPlaceholders={{
          verifyAttendees: 'Search name, email, source, id',
          allAttendees: 'Search name, email, source, id',
        }}
        formatText={{ created_at: t => `Signed up ${humanDate(t)}` }}
        PanelChildren={Panel}
        showSearchButton
      />
    </>
  );
}

function Panel(props) {
  const attendee = props.selectedObj;

  const [modal, setModal] = useState({ show: false });

  const fetchRefCodes = useQuery({
    enabled: !!attendee?.betstamp_user,
    queryKey: [
      {
        endpoint: `referral_codes/belongs_to`,
        urlParams: { owner_id: attendee?.betstamp_user },
      },
    ],
  });

  const queryClient = useQueryClient();
  const submitAttendee = useMutation({
    mutationKey: 'submit-attendee-verifiy',
    onSuccess: data => {
      queryClient.invalidateQueries({
        queryKey: [
          {
            endpoint: 'onboarding-attendees',
            urlParams: props.urlParams,
          },
        ],
      });
      queryClient.invalidateQueries({
        queryKey: [
          {
            endpoint: `referral_codes/belongs_to`,
            urlParams: { owner_id: attendee?.betstamp_user },
          },
        ],
      });
      props.clearSelected();
    },
  });

  const ModalComp = modal.component || null;

  return (
    <div style={{ padding: '0 var(--space-md)', width: '100%' }}>
      <ModalWrapper
        modalIsOpen={modal.show}
        onClose={() => setModal({ ...modal, show: false })}
        onRequestClose={() => setModal({ ...modal, show: false })}
        title={modal.title || null}
        modalSize={modal.size || 'small'}
      >
        {modal.component && (
          <ModalComp
            attendee={attendee}
            submitAttendee={submitAttendee}
            setModal={setModal}
            urlParams={props.urlParams}
            clearSelectedAttendee={props.clearSelected}
            assignRefCode={() =>
              setModal({
                show: true,
                component: AssignRefCodeModal,
                size: 'large',
              })
            }
          />
        )}
      </ModalWrapper>

      <h4 style={{ marginBottom: 0, marginTop: 'var(--space-xs)' }}>
        {attendee?.full_name}
      </h4>
      <span style={{ opacity: 0.8 }}>
        Signed up {humanDatetime(attendee?.created_at)}
      </span>
      <Row
        style={{
          flex: 0,
          width: '100%',
          alignItems: 'flex-start',
          marginTop: 'var(--space-xs)',
        }}
      >
        <table
          cellSpacing={0}
          style={{ flex: 1, width: '100%', maxWidth: '512px' }}
        >
          <Tbody>
            <tr>
              <td>
                <b>Region</b>
              </td>
              <td>
                {REGION_FLAGS[attendee?.region] && (
                  <img
                    src={REGION_FLAGS[attendee?.region]}
                    alt={attendee?.region}
                    width={32}
                    style={{ verticalAlign: 'middle' }}
                  />
                )}
                <span style={{ verticalAlign: 'middle' }}>
                  &nbsp;{attendee?.region}
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <b>Date of Birth</b>
              </td>
              <td title="YYYY-MM-DD">
                {attendee?.date_of_birth} (
                {Math.floor(
                  (new Date() - new Date(attendee?.date_of_birth)) /
                  1000 /
                  60 /
                  60 /
                  24 /
                  365
                )}
                &nbsp;years)
              </td>
            </tr>
            <tr>
              <td>
                <b>Email</b>
              </td>
              <td>{attendee?.email}</td>
            </tr>
            <tr>
              <td>
                <b>Payment Email</b>
              </td>
              <td>{attendee?.payment_email}</td>
            </tr>
            <tr>
              <td>
                <b>Phone Number</b>
              </td>
              <td>{attendee?.phone_number}</td>
            </tr>
          </Tbody>
        </table>
        <table
          cellSpacing={0}
          style={{ flex: 1, width: '100%', maxWidth: '512px' }}
        >
          <Tbody>
            <tr>
              <td>
                <b>Signed Up With Referral Code</b>
              </td>
              <td>{attendee?.signed_up_with_referral_code?.code || '-'}</td>
            </tr>
            <tr>
              <td>
                <b>Source</b>
              </td>
              <td>{attendee?.source || '-'}</td>
            </tr>
            <tr>
              <td>
                <b>betstamp User ID</b>
              </td>
              <td>{attendee?.betstamp_user || '-'}</td>
            </tr>
            {fetchRefCodes && fetchRefCodes?.data && (
              <tr>
                <td>
                  Owns Referral Code{fetchRefCodes?.data?.length > 1 && 's'}
                </td>
                <td>{fetchRefCodes?.data.map(c => c.code).join(', ')}</td>
              </tr>
            )}
          </Tbody>
        </table>
      </Row>
      <Row
        style={{
          flex: 0,
          marginTop: 'var(--space-md)',
          width: '100%',
          justifyContent: 'flex-start',
          gap: '1rem',
          position: 'sticky',
          top: '0px',
          background: 'var(--color-bg)',
          alignItems: 'center',
        }}
      >
        <AuthButton
          containerStyle={{ maxWidth: '256px' }}
          onPress={() =>
            setModal({
              show: true,
              component: AssignRefCodeModal,
              size: 'large',
            })
          }
        >
          Assign Referral Code
        </AuthButton>
        <AuthButton
          containerStyle={{ maxWidth: '256px' }}
          disabled={
            submitAttendee.isLoading ||
            attendee.verification_status === 'VERIFIED'
          }
          onPress={() =>
            setModal({
              show: true,
              title: 'Approve Attendee',
              component: ApproveModal,
            })
          }
        >
          Approve Attendee
        </AuthButton>
        <AuthButton
          containerStyle={{ maxWidth: '256px' }}
          colorTheme="danger"
          isLoading={submitAttendee.isLoading}
          onPress={() =>
            setModal({
              show: true,
              title: 'Reject Attendee',
              component: RejectModal,
            })
          }
        >
          Reject Attendee
        </AuthButton>
      </Row>
      {attendee?.photo_id ? (
        <img
          src={attendee?.photo_id}
          alt={`${attendee?.full_name}'s government ID`}
          style={{ height: '512px' }}
        />
      ) : (
        <p>No photo ID</p>
      )}
    </div>
  );
}

function SelectRefCode(props) {
  const { attendee, betstamp_user } = props;
  const [selectedCode, setSelectedCode] = useState(null);
  const [searchRefCode, setSearchRefCode] = useState(null);
  const [refCodeCountry, setRefCodeCountry] = useState(null);

  const [searchUser, setSearchUser] = useState(
    attendee.email || attendee.full_name || attendee.payment_email
  );
  const [selectedBetstampUser, setSelectedBetstampUser] = useState(null);
  const [formData, setFormData] = useState({});

  const searchUsers = useQuery({
    enabled: !!searchUser,
    queryKey: [
      {
        endpoint: `admin-search-users`,
        urlParams: {
          search: searchUser,
        },
      },
    ],
  });

  const fetchUnassignedReferralCodes = useInfiniteQuery({
    refetchOnWindowFocus: false,
    queryKey: [
      {
        endpoint: 'referral_codes/unassigned',
        urlParams: { search: searchRefCode, country: refCodeCountry },
      },
    ],
    getNextPageParam: (lastPage, allPages) => lastPage.next,
    getPreviousPageParam: (firstPage, allPages) => firstPage.prev,
  });

  const fetchMarketingGroups = useQuery({
    refetchOnWindowFocus: false,
    queryKey: [
      {
        endpoint: `marketing-groups`,
      },
    ],
  });

  const queryClient = useQueryClient();
  const submitAssign = useMutation({
    mutationKey: 'submit-attach-ref-code',
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [
          {
            endpoint: 'onboarding-attendees',
            urlParams: props.attendeeURLParams,
          },
        ],
      });
      queryClient.invalidateQueries({
        queryKey: [
          {
            endpoint: `referral_codes/belongs_to`,
            urlParams: {
              owner_id: attendee?.betstamp_user || betstamp_user?.id,
            },
          },
        ],
      });
      queryClient.invalidateQueries({
        queryKey: [
          {
            endpoint: 'referral_codes/unassigned',
            urlParams: { search: searchRefCode, country: refCodeCountry },
          },
        ],
      });
      props.setModal({ show: false });
      props.clearSelectedAttendee();
    },
  });

  if (selectedCode) {
    return (
      <>
        <br />
        <h6>
          <b>
            Assigning internal code: {selectedCode.internal_code || '-'} to{' '}
            {attendee?.full_name}
          </b>
        </h6>
        {betstamp_user && (
          <h6>
            <b>
              Attaching betstamp user: {betstamp_user.username || '-'} to
              attendee: {attendee?.full_name}
            </b>
          </h6>
        )}
        <br />
        <form
          style={{ margin: '0 auto', maxWidth: '768px' }}
          onSubmit={ev => {
            ev.preventDefault();
            const fd = new FormData(ev.target);
            fd.append('attendee_id', attendee.id);
            if (attendee?.betstamp_user || selectedBetstampUser?.id) {
              fd.append(
                'owner_id',
                attendee?.betstamp_user || selectedBetstampUser?.id
              );
            }
            submitAssign.mutate({
              endpoint: `referral_codes/${selectedCode.id}/assign`,
              body: fd,
            });
          }}
        >
          <AuthTextInput
            name="code"
            label="External Code"
            textTransform="uppercase"
            required
            placeholder="Set the code for this ambassdor/influencer to give out"
            onChangeText={() => { }}
          />
          {fetchMarketingGroups.isSuccess && (
            <Select
              name="group_id"
              id="group_id"
              label="Marketing Group"
              options={fetchMarketingGroups.data.map(g => ({
                value: g.id,
                label: g.name,
              }))}
              onChange={opt =>
                setFormData({ ...formData, group_id: opt.value })
              }
            />
          )}

          <p>Optionally attach a betstamp user</p>
          <AuthTextInput
            label="Search by full name, email, or username"
            defaultValue={attendee.email}
            onChangeText={text => setSearchUser(text)}
          />
          {searchUsers.isLoading && (
            <ActivityIndicator size={2} style={{ minHeight: '128px' }} />
          )}
          {searchUsers.isSuccess && (
            <>
              {searchUsers?.data?.results?.length === 0 && (
                <h5 style={{ textAlign: 'center', margin: 0 }}>
                  No users matching <i>"{searchUser}"</i>
                </h5>
              )}
              <Grid style={{ height: '128px', overflowY: 'auto' }}>
                {searchUsers?.data?.results?.map(u => (
                  <AuthButton
                    key={`search-users-admin-${u.id}`}
                    type="button"
                    title={`${u.username}, ${u.email}, ${u.full_name}`}
                    btnTheme="borderless"
                    colorTheme={
                      selectedBetstampUser?.id === u.id ? 'inverted' : 'primary'
                    }
                    onPress={() => setSelectedBetstampUser(u)}
                  >
                    {u.username}
                  </AuthButton>
                ))}
              </Grid>
            </>
          )}

          <AuthButton
            disabled={!formData.group_id}
            isLoading={submitAssign.isLoading}
            type="submit"
          >
            Assign Code
          </AuthButton>
          {submitAssign.isError && (
            <span style={{ color: 'var(--color-danger)', alignSelf: 'center' }}>
              {submitAssign.error.message}
            </span>
          )}
          <br />
        </form>
        <AuthButton
          btnTheme="borderless"
          colorTheme="danger"
          onPress={() => setSelectedCode(null)}
        >
          Cancel
        </AuthButton>
      </>
    );
  }

  const pages = fetchUnassignedReferralCodes?.data?.pages;

  return (
    <>
      {fetchUnassignedReferralCodes.isLoading && <ActivityIndicator size={2} />}
      {fetchUnassignedReferralCodes.isError && (
        <h5 style={{ alignSelf: 'center', color: 'var(--color-danger)' }}>
          {fetchUnassignedReferralCodes?.error?.message}
        </h5>
      )}
      {pages && pages?.length === 1 && pages[0]?.results.length === 0 && (
        <h5 style={{ alignSelf: 'center' }}>No unassigned referral codes</h5>
      )}
      {fetchUnassignedReferralCodes.isSuccess &&
        pages &&
        pages?.length > 0 &&
        pages[0]?.results?.length > 0 && (
          <>
            <Row style={{ alignItems: 'center', gap: 'var(--space-sm)' }}>
              <AuthTextInput
                containerStyle={{ flex: 3 }}
                placeholder="Search by internal code"
                onChangeText={text => setSearchRefCode(text)}
              />
              <AuthButton
                onPress={() =>
                  setRefCodeCountry(refCodeCountry === 'USA' ? null : 'USA')
                }
                colorTheme={refCodeCountry === 'USA' ? 'inverted' : 'primary'}
              >
                USA
              </AuthButton>
              <AuthButton
                onPress={() =>
                  setRefCodeCountry(
                    refCodeCountry === 'Canada' ? null : 'Canada'
                  )
                }
                colorTheme={
                  refCodeCountry === 'Canada' ? 'inverted' : 'primary'
                }
              >
                Canada
              </AuthButton>
            </Row>
            <Row
              style={{
                padding: 'var(--space-sm) 0',
                borderTop: '2px solid var(--color-text-light)',
                borderBottom: '1px solid var(--color-text-light)',
                position: 'sticky',
                marginTop: 'var(--space-sm)',
                top: 0,
                background: 'var(--color-fg)',
              }}
            >
              <b style={{ flex: 1, opacity: 0.8 }}>CODE</b>
              <b style={{ flex: 1, opacity: 0.8 }}>INTERAL CODE</b>
              <b style={{ flex: 1, opacity: 0.8 }}>COUNTRY</b>
              <b style={{ flex: 1, opacity: 0.8 }}>GROUP</b>
              <b style={{ flex: 1, opacity: 0.8 }}>ACTIVE</b>
              <b style={{ flex: 1, opacity: 0.8 }}></b>
            </Row>
          </>
        )}
      {pages &&
        pages.map((page, i) => (
          <React.Fragment key={`assign-ref-code-table-page-${i}`}>
            {page.results.map(code => (
              <Row
                key={`select-ref-code-${code.code}`}
                style={{ alignItems: 'center' }}
              >
                <span style={{ flex: 1 }}>{code.code || '-'}</span>
                <span style={{ flex: 1 }}>{code.internal_code || '-'}</span>
                <span style={{ flex: 1 }}>{code.country || '-'}</span>
                <span style={{ flex: 1 }}>{code.group || '-'}</span>
                <span style={{ flex: 1 }}>{code.active ? '✓' : '✕'}</span>
                <AuthButton
                  title={`Select ${code.code || code.internal_code}`}
                  btnTheme="borderless"
                  onPress={() => setSelectedCode(code)}
                >
                  Select
                </AuthButton>
              </Row>
            ))}
          </React.Fragment>
        ))}
      {fetchUnassignedReferralCodes.hasNextPage && (
        <AuthButton
          containerStyle={{
            margin: 'var(--space-lg) 0',
            maxWidth: '256px',
          }}
          onPress={() => fetchUnassignedReferralCodes.fetchNextPage()}
          isLoading={fetchUnassignedReferralCodes.isFetchingNextPage}
        >
          Load More
        </AuthButton>
      )}
      <div style={{ minHeight: '64px' }} />
    </>
  );
}

function ApproveModal(props) {
  const { attendee, submitAttendee } = props;
  const [formData, setFormData] = useState({});
  return (
    <>
      <form
        onSubmit={ev => {
          ev.preventDefault();
          const fd = new FormData(ev.target);
          fd.append('verification_status', 'VERIFIED');
          submitAttendee.mutate({
            endpoint: `onboarding-attendees/${attendee.id}`,
            body: fd,
          });
        }}
      >
        <div style={{ marginTop: 'var(--space-md)' }}>
          <input
            id="is_ambassador"
            type="checkbox"
            value="true"
            name="is_ambassador"
            onChange={ev =>
              setFormData({ ...setFormData, is_ambassador: ev.target.checked })
            }
          />
          <label htmlFor="is_ambassador">Attendee is a Brand Ambassdor</label>
        </div>
        <div>
          <input
            id="is_influencer"
            type="checkbox"
            value="true"
            name="is_influencer"
            onChange={ev =>
              setFormData({ ...setFormData, is_ambassador: ev.target.checked })
            }
          />
          <label htmlFor="is_influencer">Attendee is an influencer</label>
        </div>
        {(formData.is_influencer || formData.is_ambassador) &&
          !attendee.betstamp_user && (
            <>
              <p style={{ textAlign: 'center' }}>
                <b>
                  Ambassdors/influencers should have betstamp user attached to
                  them as well as a referral code
                </b>
              </p>
              <AuthButton
                btnTheme="borderless"
                containerStyle={{ marginBottom: 'var(--space-md)' }}
                type="button"
                onPress={props.assignRefCode}
              >
                Assign Referral Code
              </AuthButton>
            </>
          )}
        <AuthButton
          containerStyle={{ marginTop: 'var(--space-lg)' }}
          type="submit"
        >
          Approve {attendee.full_name}
        </AuthButton>
      </form>
    </>
  );
}

function AssignRefCodeModal(props) {
  const { attendee } = props;
  return (
    <SelectRefCode
      attendee={attendee}
      setModal={props.setModal}
      attendeeURLParams={props.urlParams}
      clearSelectedAttendee={props.clearSelectedAttendee}
    />
  );
}

function RejectModal(props) {
  const { attendee, submitAttendee } = props;
  return (
    <>
      <p>
        What are the reason(s) to reject this attendee? (check all that apply)
      </p>
      <form
        onSubmit={ev => {
          ev.preventDefault();
          const fd = new FormData(ev.target);
          fd.append('verification_status', 'INVALID');
          fd.append('stage', 'ONBOARDING_DECLINED');
          submitAttendee.mutate({
            endpoint: `onboarding-attendees/${attendee.id}`,
            body: fd,
          });
        }}
      >
        <div>
          <input
            id="underage"
            type="checkbox"
            value="Attendee is underage"
            name="rejection_reasons"
          />
          <label htmlFor="underage">Attendee is underage</label>
        </div>
        <div>
          <input
            id="id_invalid"
            type="checkbox"
            value="Attendee's photo ID is invalid"
            name="rejection_reasons"
          />
          <label htmlFor="id_invalid">Attendee's ID is not valid</label>
        </div>
        <div>
          <input
            id="contract_not_signed"
            type="checkbox"
            value="Contract not signed"
            name="rejection_reasons"
          />
          <label htmlFor="contract_not_signed">Contract not signed</label>
        </div>

        <AuthTextInput
          name="other_rejection_reason"
          label="Other reason"
          placeholder="Attendee gave a fake email"
          onChangeText={() => { }}
          maxLength={256}
        />

        <AuthButton
          type="submit"
          isLoading={submitAttendee.isLoading}
          colorTheme="danger"
        >
          Reject
        </AuthButton>
      </form>
    </>
  );
}
