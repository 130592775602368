// actions (excluding success and failure actions):
export const RESET_ERRORS_FEATURED = 'RESET_ERRORS_FEATURED';
export const GET_FEATURED = 'GET_FEATURED';
export const GET_RECOMMENDED_USERS = 'GET_RECOMMENDED_USERS';

// RESET ERRORS
export const resetErrorsFeatured = () => ({
  type: RESET_ERRORS_FEATURED,
});

// GET_FEATURED
export const GET_FEATURED_SUCCESS = 'GET_FEATURED_SUCCESS';
export const GET_FEATURED_FAILURE = 'GET_FEATURED_FAILURE';

export const getFeatured = () => ({
  type: GET_FEATURED,
});

export const getFeaturedSuccess = payload => ({
  type: GET_FEATURED_SUCCESS,
  payload: payload,
});

export const getFeaturedFailure = error => ({
  type: GET_FEATURED_FAILURE,
  error,
});

// GET_RECOMMENDED_USERS
export const GET_RECOMMENDED_USERS_SUCCESS = 'GET_RECOMMENDED_USERS_SUCCESS';
export const GET_RECOMMENDED_USERS_FAILURE = 'GET_RECOMMENDED_USERS_FAILURE';
export const GET_RECOMMENDED_USERS_NEXT = 'GET_RECOMMENDED_USERS_NEXT';

export const getRecommendedUsers = () => ({
  type: GET_RECOMMENDED_USERS,
});

export const getRecommendedUsersNext = () => ({
  type: GET_RECOMMENDED_USERS_NEXT,
});

export const getRecommendedUsersSuccess = payload => ({
  type: GET_RECOMMENDED_USERS_SUCCESS,
  payload: payload,
});

export const getRecommendedUsersFailure = error => ({
  type: GET_RECOMMENDED_USERS_FAILURE,
  error,
});

// GET_HAMMER_USERS
export const GET_HAMMER_USERS = 'GET_HAMMER_USERS';
export const GET_HAMMER_USERS_NEXT = 'GET_HAMMER_USERS_NEXT';
export const GET_HAMMER_USERS_SUCCESS = 'GET_HAMMER_USERS_SUCCESS';
export const GET_HAMMER_USERS_FAILURE = 'GET_HAMMER_USERS_FAILURE';

export const getHammerUsers = () => ({
  type: GET_HAMMER_USERS,
});

export const getHammerUsersNext = () => ({
  type: GET_HAMMER_USERS_NEXT,
});

export const getHammerUsersSuccess = payload => ({
  type: GET_HAMMER_USERS_SUCCESS,
  payload,
});

export const getHammerUsersFailure = error => ({
  type: GET_HAMMER_USERS,
  error,
});
