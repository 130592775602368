import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

// components
import { Row, Col } from 'components/generic/Layout';

const Hero = styled.div`
  width: 100%;
  background-color: var(--color-primary);
  color: white;
  padding: var(--space-xl);
  box-sizing: border-box;
  @media only screen and (max-width: 625px) {
    padding: var(--space-sm);
    padding-bottom: var(--space-xl);
  }
`;

export default function TutorialAll() {
  return (
    <>
      <Hero>
        <Col style={{ alignItems: 'flex-start' }}>
          <h1>Tutorials</h1>
          <p>
            Take a look at our availble tutorials to learn how to make the best
            of betstamp
          </p>
          <Link to="/faq" style={{ color: 'white' }}>
            View Frequently Asked Questions
          </Link>
        </Col>
      </Hero>
      <div className="container" style={{ padding: '0 var(--space-md)' }}>
        <Row>
          <Col
            style={{
              alignItems: 'flex-start',
              flex: '1 0 325px',
              justifyContent: 'flex-start',
            }}
          >
            <h3>Tracking {'&'} Analysis</h3>
            <Link to="/tutorial-accounting">Accounting</Link>
            <Link to="/tutorial-analysis">Analysis</Link>
            {/* <Link to="/tutorial-custom-bet">Custom Bets</Link> */}
            {/* <Link to="/tutorial-editing-bets">Editing Bets</Link> */}
            <Link to="/tutorial-transaction">Transactions</Link>
            {/* <Link to="/tutorial-verified-bets">Verified Bets</Link> */}
            <Link to="/tutorial-bets">Bets</Link>
            <Link to="/tutorial-player-prop">Player Prop Tool</Link>
          </Col>
          <Col
            style={{
              alignItems: 'flex-start',
              flex: '1 0 325px',
              justifyContent: 'flex-start',
            }}
          >
            <h3>Settings</h3>
            <Link to="/tutorial-settings">App and Profile Settings</Link>
          </Col>
        </Row>
      </div>
    </>
  );
}
