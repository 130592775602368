import styled from 'styled-components';
import {
  IoListSharp,
  IoCheckmarkCircleOutline,
  IoHelpCircleOutline,
  IoMenu,
} from 'react-icons/io5';
import { useParams, Link } from 'react-router-dom';

// components
import { IconButton } from 'components/AuthButton';
import { Row, Col } from 'components/generic/Layout';

const Wrapper = styled.div`
  flex: 0 0 64px;
  background: var(--color-onb-blue);
  display: none;
  flex-flow: row nowrap;
  justify-content: space-around;

  @media screen and (max-width: 650px) {
    display: flex;
  }
`;

export default function OnbBottomBar() {
  const params = useParams();
  const page = params?.page;
  return (
    <Wrapper>
      <Link
        to={`/session/${params?.session_id}/${params?.attendee_id}/current`}
        style={{ textDecoration: 'none' }}
      >
        <IconButton
          btnStyle={{ borderRadius: 0 }}
          colorTheme={!page || page === 'current' ? 'primary' : 'inverted'}
          iconName={IoCheckmarkCircleOutline}
          subtext="Current Task"
          onPress={() => {}}
        />
      </Link>
      <Link
        to={`/session/${params?.session_id}/${params?.attendee_id}/all`}
        style={{ textDecoration: 'none' }}
      >
        <IconButton
          btnStyle={{ borderRadius: 0 }}
          colorTheme={page === 'all' ? 'primary' : 'inverted'}
          iconName={IoListSharp}
          subtext="All Tasks"
          onPress={() => {}}
        />
      </Link>
    </Wrapper>
  );
}
