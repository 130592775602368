import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { IoMail } from 'react-icons/io5';
import { useEffectOnce } from 'react-use';

// components
import { Col } from 'components/generic/Layout';
import SignupWrapper from 'components/SignupWrapper';
import GoogleAuthButton from 'components/GoogleAuthButton';
import AppleAuthButton from 'components/AppleAuthButton';
import TwitterAuthButton from 'components/TwitterAuthButton';

// import ErrorDisplay from 'components/generic/ErrorDisplay';
import { AuthButton } from 'components/AuthButton';
import AccountCreated from 'pages/Auth/AccountCreated';

import { TOS, SignupHeader, SignupFooter } from 'components/auth/signup';

// actions
import { checkReferralCodeExists, resetErrors } from 'actions';

export default function Signup() {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useLocation().search;
  const reduxProps = useSelector(state => ({
    user: state.authReducer.user,
    userToken: state.authReducer.userToken,
    socialLoginFailure: state.authReducer.socialLoginFailure,
    isSocialAuthingUser: state.authReducer.isSocialAuthingUser,
    socialLoginSuccess: state.authReducer.socialLoginSuccess,
    referralCodeExists: state.authReducer.referralCodeExists,
    theme: state.settingsReducer.theme,
  }));
  const {
    user,
    userToken,
    socialLoginFailure,
    isSocialAuthingUser,
    socialLoginSuccess,
    referralCodeExists,
    theme,
  } = reduxProps;

  useEffectOnce(() => {
    dispatch(resetErrors());
    if (userToken) {
      history.push('/games');
    }

    return () => {
      // this component unmounts when URL changes (e.g. back to previous page) -> clear error/auth info just in case user re-signup again
      dispatch(resetErrors());
    };
  });

  const [newUser, setNewUser] = useState({
    is_public: true,
    accepted_TC: true,
    referral_code: new URLSearchParams(params).get('referral_code'),
  });

  useEffectOnce(() => {
    if (newUser.referral_code) {
      dispatch(checkReferralCodeExists(newUser.referral_code));
    }
  });
  useEffect(() => {
    if (referralCodeExists === false) {
      setNewUser({ ...newUser, referral_code: null });
    }
  }, [referralCodeExists]);

  if (
    isSocialAuthingUser ||
    socialLoginFailure ||
    (socialLoginSuccess && user && user.social_accounts.length > 0)
  ) {
    return <AccountCreated referral_code={newUser.referral_code} />;
  }

  return (
    <SignupWrapper>
      <Col style={{ justifyContent: 'flex-start' }}>
        <SignupHeader
          label="Sign Up"
          logoSize={64}
          style={{ fontWeight: 'bold' }}
        />
      </Col>
      <Col style={{ width: '100%' }}>
        <Col style={{ width: '100%', flex: 0 }}>
          {newUser.referral_code && (
            <p>Referral Code: {newUser.referral_code}</p>
          )}
          {referralCodeExists === false && (
            <p>Referral code is invalid, it will not apply</p>
          )}
          <AuthButton
            btnTheme="borderless"
            containerStyle={{
              width: '50%',
              minWidth: '250px',
            }}
            btnStyle={{
              backgroundColor: 'var(--color-fg)',
              borderColor: 'inherit',
            }}
            textStyle={{
              maxWidth: 'fit-content',
              marginLeft: 'var(--space-sm)',
              color:
                theme === 'dark' ? 'var(--color-text)' : 'var(--color-primary)',
            }}
            leftIcon={IoMail}
            onPress={() => {
              dispatch(resetErrors());
              history.push(
                `/signup-email${
                  newUser.referral_code
                    ? `?referral_code=${newUser.referral_code}`
                    : ''
                }`
              );
            }}
          >
            Continue With Email
          </AuthButton>
          <AppleAuthButton signup />
          <GoogleAuthButton signup />
          <TwitterAuthButton signup />
          <br />
          <SignupFooter onPress={() => history.push('/login')} />
        </Col>
      </Col>
      <Col style={{ flex: 0.5, justifyContent: 'flex-end' }}>
        <TOS />
      </Col>
    </SignupWrapper>
  );
}
