import {
  // signup redux actions
  CREATE_USER,
  CREATE_USER_SUCCESS,
  CREATE_USER_FAILURE,

  // login redux actions
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAILURE,
  // other redux actions
  FORGOT_PASSWORD,
  LOGOUT_USER,
  LOGOUT_ALL,
  CREATE_CONNECT_STATE,
  UPDATE_NOTIFICATION_PREFERENCES,
  ADD_PUSH_TOKEN,
  UPDATE_USER,
  SEND_EMAIL_VERIFICATION,
  DEACTIVATE_USER,
  SET_OTHER_USER,
  CREATE_ANALYSIS_PRESET,
  GET_ANALYSIS,
  SET_ACCOUNTING_BOOK,
  GET_TRANSACTION_SUMMARY,
  GET_PUBLIC_PROFILE,
  GET_PUBLIC_PROFILE_BY_USERNAME,
  START_PURCHASE_SESSION,
  FIND_BETTER_ODDS_SUCCESS,
  // game actions
  GET_FULL_TIME_ODDS_SUCCESS,
  GET_GAME_PROP_LIST_SUCCESS,
  GET_PLAYER_PROP_PLAYER_LIST_SUCCESS,
  GET_1_HALF_ODDS_SUCCESS,
  GET_LIVE_ODDS_SUCCESS,
  GET_LINE_HISTORY_SUCCESS,

  // betslip
  ADD_BET,
  REMOVE_ONE_BET,
  REMOVE_ALL_BETS,
  CHANGE_BETSLIP_MODE,
  TRACK_BETSLIP,
  TRACK_BETSLIP_SUCCESS,
  TRACK_BETSLIP_FAILURE,
  REUSE_BETS,

  // place loaded bet slip
  PLACE_BET,
  PLACE_BET_SUCCESS,
  PLACE_BET_FAILURE,

  // autosync redux actions
  AS_LOGIN_USER,
  AS_LOGIN_USER_SUCCESS,
  AS_LOGIN_USER_FAILURE,
  DISCONNECT_AUTOSYNC,
  DISCONNECT_AUTOSYNC_SUCCESS,
  DISCONNECT_AUTOSYNC_FAILURE,
  DISCONNECT_ALL_AUTOSYNC,
  DISCONNECT_ALL_AUTOSYNC_SUCCESS,
  DISCONNECT_ALL_AUTOSYNC_FAILURE,
  SYNC_BETS,
  SYNC_BETS_SUCCESS,
  SYNC_BETS_FAILURE,
  SYNC_ALL_BETS,
  SYNC_ALL_BETS_SUCCESS,
  SYNC_ALL_BETS_FAILURE,
  // find bets
  GET_FIND_BETS,
} from 'actions';
import Analytics from 'amplitude/Analytics';

export const amplitudeMiddleware = store => next => action => {
  const auth_reducer = store.getState().authReducer;
  const user = auth_reducer ? auth_reducer.user : null;
  const user_id = user ? user.id : null;

  // SIGNUP USER
  if (action.type === CREATE_USER) {
    Analytics.track(Analytics.events.CREATE_USER);
  } else if (action.type === CREATE_USER_SUCCESS) {
    Analytics.track(Analytics.events.CREATE_USER_SUCCESS);
  } else if (action.type === CREATE_USER_FAILURE) {
    let data = {};
    try {
      data.status = action.payload.status;
      data.errors = JSON.parse(action.payload._response);
    } catch (e) {
      data.errors = ['Could not get errors'];
    }
    Analytics.track(Analytics.events.CREATE_USER_FAILURE, null, data);
  }

  // LOGIN USER
  else if (action.type === LOGIN_USER) {
    Analytics.track(Analytics.events.LOGIN_USER);
  } else if (action.type === LOGIN_USER_SUCCESS) {
    Analytics.track(Analytics.events.LOGIN_USER_SUCCESS);
  } else if (action.type === LOGIN_USER_FAILURE) {
    let data = {};
    try {
      data.status = action.payload.status;
      data.errors = Object.values(JSON.parse(action.payload._response));
    } catch (e) {
      data.errors = ['Could not get errors'];
    }
    Analytics.track(Analytics.events.LOGIN_USER_FAILURE, null, data);
  } else if (action.type === FORGOT_PASSWORD) {
    Analytics.track(Analytics.events.FORGOT_PASSWORD);
  }

  // USER / AUTH
  else if (action.type === CREATE_CONNECT_STATE) {
    // when a user starts to create a Stripe connect account
    Analytics.track(Analytics.events.CREATE_STRIPE_ACCOUNT);
  } else if (action.type === UPDATE_NOTIFICATION_PREFERENCES) {
    Analytics.track(Analytics.events.UPDATE_NOTIFICATION_PREFERENCES, user_id);
  } else if (action.type === ADD_PUSH_TOKEN) {
    // when an expo push token is added to database
    Analytics.track(Analytics.events.ADD_NOTIFICATION_PUSH_TOKEN, user_id);
  } else if (action.type === UPDATE_USER) {
    Analytics.track(Analytics.events.UPDATE_USER, user_id);
  } else if (action.type === SEND_EMAIL_VERIFICATION) {
    Analytics.track(Analytics.events.SEND_EMAIL_VERIFICATION, user_id);
  } else if (action.type === DEACTIVATE_USER) {
    Analytics.track(Analytics.events.DEACTIVATE_USER, user_id);
  } else if (action.type === LOGOUT_USER || action.type === LOGOUT_ALL) {
    Analytics.track(Analytics.events.LOGOUT_USER, user_id);
  }

  // ANALYSIS
  else if (action.type === SET_OTHER_USER) {
    // this action gets triggered when analysis button is clicked
    // if otherUser === null, self analysis
    if (action.otherUser !== null) {
      Analytics.track(Analytics.events.VIEW_OTHER_USER_ANALYSIS, user_id, {
        'Viewed User': {
          id: action.otherUser.id,
          username: action.otherUser.username,
        },
      });
    } else {
      Analytics.track(Analytics.events.VIEW_SELF_ANALYSIS, user_id);
    }
  } else if (action.type === CREATE_ANALYSIS_PRESET) {
    Analytics.track(Analytics.events.CREATE_ANALYSIS_PRESET, user_id);
  } else if (action.type === GET_ANALYSIS) {
    Analytics.track(Analytics.events.GET_ANALYSIS, user_id, {
      urlparams: action.urlparams,
    });
  } else if (action.type === SET_ACCOUNTING_BOOK) {
    Analytics.track(Analytics.events.VIEW_ACCOUNTING_BOOK, user_id, {
      book: action.book,
    });
  } else if (action.type === GET_TRANSACTION_SUMMARY) {
    Analytics.track(Analytics.events.VIEW_ACCOUNTING_SUMMARY, user_id);
  }
  // PUBLIC USER
  else if (action.type === GET_PUBLIC_PROFILE) {
    Analytics.track(Analytics.events.GET_PUBLIC_PROFILE, user_id, {
      user: action.user_id,
    });
  } else if (action.type === GET_PUBLIC_PROFILE_BY_USERNAME) {
    // likely means we're coming from a deep link
    Analytics.track(Analytics.events.GET_PUBLIC_PROFILE_BY_USERNAME, user_id, {
      user: action.username,
    });
  }
  // SELL PICKS
  else if (action.type === START_PURCHASE_SESSION) {
    Analytics.track(Analytics.events.START_PURCHASE_SESSION, user_id, {
      package: action.purchaseSession.package,
    });
  }
  // AFFILIATE LINKS
  else if (action.type === FIND_BETTER_ODDS_SUCCESS) {
    const { response } = action.payload;
    if (response && response.ad_book && response.ad_book.affiliate_links) {
      // bet isn't available anymore, don't want this to crash so let's roll out this change first

      let promo = response.ad_book.affiliate_links.sort(
        (a, b) => b.priority - a.priority
      );
      promo = promo[0] || null;

      Analytics.track(Analytics.events.AFFILIATE_VIEW, user_id, {
        // game: game.home + ' vs ' + game.away,
        // game_id: game.id,
        // league: game.league,
        // viewing_book: bet.book.name,
        affiliate_book: response.ad_book.name,
        already_had_book: user.books.includes(response.ad_book.name),
        from_page: 'Place Bet',
        link: promo ? promo.link : null,
      });
    }
  } else if (action.type === GET_FULL_TIME_ODDS_SUCCESS) {
    Analytics.track(Analytics.events.VIEW_GAME_LINE, user_id, {});
  } else if (action.type === GET_1_HALF_ODDS_SUCCESS) {
    Analytics.track(Analytics.events.VIEW_GAME_1H_LINE, user_id, {});
  } else if (action.type === GET_LIVE_ODDS_SUCCESS) {
    Analytics.track(Analytics.events.VIEW_GAME_LIVE_LINE, user_id, {});
  } else if (action.type === GET_GAME_PROP_LIST_SUCCESS) {
    Analytics.track(Analytics.events.VIEW_GAME_PROPS, user_id, {});
  } else if (action.type === GET_PLAYER_PROP_PLAYER_LIST_SUCCESS) {
    Analytics.track(Analytics.events.VIEW_PLAYER_PROPS, user_id, {});
  } else if (action.type === GET_LINE_HISTORY_SUCCESS) {
    Analytics.track(Analytics.events.VIEW_LINE_HISTORY, user_id, {});
  }
  // betslip
  else if (action.type === ADD_BET) {
    Analytics.track(Analytics.events.ADD_BET, user_id, {
      user: action.user_id,
    });
  } else if (action.type === REMOVE_ONE_BET) {
    Analytics.track(Analytics.events.REMOVE_ONE_BET, user_id, {
      user: action.user_id,
    });
  } else if (action.type === REMOVE_ALL_BETS) {
    Analytics.track(Analytics.events.REMOVE_ALL_BETS, user_id, {
      user: action.user_id,
    });
  } else if (action.type === CHANGE_BETSLIP_MODE) {
    const from_mode = store.getState().betslipReducer.mode;
    Analytics.track(Analytics.events.CHANGE_BETSLIP_MODE, user_id, {
      to_mode: action.mode,
      from_mode: from_mode,
    });
  } else if (action.type === TRACK_BETSLIP) {
    const { bets, mode } = store.getState().betslipReducer;
    Analytics.track(Analytics.events.TRACK_BETSLIP, user_id, {
      user: action.user_id,
      bets_length: bets.length,
      mode: mode,
    });
  } else if (action.type === TRACK_BETSLIP_SUCCESS) {
    const { mode } = store.getState().betslipReducer;
    Analytics.track(Analytics.events.TRACK_BETSLIP_SUCCESS, user_id, {
      user: action.user_id,
      mode: mode,
    });
  } else if (action.type === TRACK_BETSLIP_FAILURE) {
    const { mode } = store.getState().betslipReducer;
    Analytics.track(Analytics.events.TRACK_BETSLIP_FAILURE, user_id, {
      user: action.user_id,
      mode: mode,
    });
  } else if (action.type === REUSE_BETS) {
    const bet = store.getState().betslipReducer.bets;
    Analytics.track(Analytics.events.REUSE_BETS, user_id, {
      user: action.user_id,
      bets_length: bet.length,
    });
  } else if (action.type === PLACE_BET) {
    const bets = store.getState().betslipReducer.bets;
    Analytics.track(Analytics.events.TRACK_BET_AT_SPORTSBOOK, user_id, {
      user: action.user_id,
      book: bets[0]?.book?.name,
      league: bets[0]?.game?.league || bets[0]?.season?.league,
    });
  } else if (action.type === PLACE_BET_SUCCESS) {
    const bets = store.getState().betslipReducer.bets;
    Analytics.track(Analytics.events.TRACK_BET_AT_SPORTSBOOK_SUCCESS, user_id, {
      user: action.user_id,
      book: bets[0]?.book?.name,
      league: bets[0]?.game?.league || bets[0]?.season?.league,
    });
  } else if (action.type === PLACE_BET_FAILURE) {
    const bets = store.getState().betslipReducer.bets;
    Analytics.track(Analytics.events.TRACK_BET_AT_SPORTSBOOK_FAILURE, user_id, {
      user: action.user_id,
      book: bets[0]?.book?.name,
    });
  } else if (action.type === AS_LOGIN_USER) {
    Analytics.track(Analytics.events.AS_LOGIN_USER, user_id, {
      user: user_id,
      book: action?.params?.book,
      region: action?.params?.region,
    });
  } else if (action.type === AS_LOGIN_USER_SUCCESS) {
    Analytics.track(Analytics.events.AS_LOGIN_USER_SUCCESS, user_id, {
      user: user_id,
      book: action?.payload?.response?.book,
    });
  } else if (action.type === AS_LOGIN_USER_FAILURE) {
    Analytics.track(Analytics.events.AS_LOGIN_USER_FAILURE, user_id, {
      user: user_id,
      response: action?.payload?.response,
      status: action?.payload?.status,
    });
  } else if (action.type === SYNC_BETS) {
    Analytics.track(Analytics.events.SYNC_BETS, user_id, {
      user: user_id,
      book: action?.params?.book,
    });
  } else if (action.type === SYNC_BETS_SUCCESS) {
    Analytics.track(Analytics.events.SYNC_BETS_SUCCESS, user_id, {
      user: user_id,
      book: action?.payload?.response?.book,
    });
  } else if (action.type === SYNC_BETS_FAILURE) {
    Analytics.track(Analytics.events.SYNC_BETS_FAILURE, user_id, {
      user: user_id,
      response: action?.payload?.response,
      status: action?.payload?.status,
    });
  } else if (action.type === SYNC_ALL_BETS) {
    Analytics.track(Analytics.events.SYNC_ALL_BETS, user_id, {
      user: user_id,
      book: action?.params?.book,
    });
  } else if (action.type === SYNC_ALL_BETS_SUCCESS) {
    Analytics.track(Analytics.events.SYNC_ALL_BETS_SUCCESS, user_id, {
      user: user_id,
      book: action?.payload?.response?.book,
    });
  } else if (action.type === SYNC_ALL_BETS_FAILURE) {
    Analytics.track(Analytics.events.SYNC_ALL_BETS_FAILURE, user_id, {
      user: user_id,
      response: action?.payload?.response,
      status: action?.payload?.status,
    });
  } else if (action.type === DISCONNECT_AUTOSYNC) {
    Analytics.track(Analytics.events.DISCONNECT_AUTOSYNC, user_id, {
      user: user_id,
      book: action?.params?.book,
    });
  } else if (action.type === DISCONNECT_AUTOSYNC_SUCCESS) {
    Analytics.track(Analytics.events.DISCONNECT_AUTOSYNC_SUCCESS, user_id, {
      user: user_id,
      book: action?.payload?.response?.book,
    });
  } else if (action.type === DISCONNECT_AUTOSYNC_FAILURE) {
    Analytics.track(Analytics.events.DISCONNECT_AUTOSYNC_FAILURE, user_id, {
      user: user_id,
      response: action?.payload?.response,
      status: action?.payload?.status,
    });
  } else if (action.type === DISCONNECT_ALL_AUTOSYNC) {
    Analytics.track(Analytics.events.DISCONNECT_ALL_AUTOSYNC, user_id, {
      user: user_id,
      book: action?.params?.book,
    });
  } else if (action.type === DISCONNECT_ALL_AUTOSYNC_SUCCESS) {
    Analytics.track(Analytics.events.DISCONNECT_ALL_AUTOSYNC_SUCCESS, user_id, {
      user: user_id,
      book: action?.payload?.response?.book,
    });
  } else if (action.type === DISCONNECT_ALL_AUTOSYNC_FAILURE) {
    Analytics.track(Analytics.events.DISCONNECT_ALL_AUTOSYNC_FAILURE, user_id, {
      user: user_id,
      response: action?.payload?.response,
      status: action?.payload?.status,
    });
  } else if (action.type === GET_FIND_BETS) {
    Analytics.track(Analytics.events.GET_FIND_BETS, user_id, {
      user: user_id,
      username: user?.username,
      ...action,
    });
  }

  next(action);
};
