import { Observable } from 'rxjs';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import { ajax } from 'rxjs/observable/dom/ajax';
import { BACKEND_API_URL, getHeaders } from 'utils';

import { CHANGE_EMAIL, changeEmailSuccess, changeEmailFailure } from 'actions';

export default function changeEmail(action$, state$) {
  return action$.ofType(CHANGE_EMAIL).switchMap(action => {
    const user = { email: action.email, email_bounced_back: false };
    return ajax
      .patch(
        BACKEND_API_URL + 'api/users/' + state$.value.authReducer.user.id + '/',
        user,
        getHeaders(state$)
      )
      .map(data => changeEmailSuccess(data))
      .catch(error => Observable.of(changeEmailFailure(error.xhr)));
  });
}
