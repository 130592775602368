import { Observable } from 'rxjs';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/of';
import { ajax } from 'rxjs/observable/dom/ajax';
import { BACKEND_API_URL, getHeaders } from 'utils';

import {
  SOCIAL_DISCONNECT_USER,
  socialDisconnectUserSuccess,
  socialDisconnectUserFailure,
} from 'actions';

export default function socialDisconnectUser(action$, state$) {
  return action$.ofType(SOCIAL_DISCONNECT_USER).mergeMap(action => {
    const data = {
      account: action.provider,
      password: action.password,
      email: action.email,
    };
    return ajax
      .post(
        `${BACKEND_API_URL}api/social_disconnect/`,
        data,
        getHeaders(state$)
      )
      .map(data => socialDisconnectUserSuccess(data))
      .catch(error => Observable.of(socialDisconnectUserFailure(error.xhr)));
  });
}
