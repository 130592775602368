import React, { useState, useMemo } from 'react';
import { useUpdateEffect, useEffectOnce } from 'react-use';
import { useSelector, useDispatch } from 'react-redux';
import { IoSwapVertical } from 'react-icons/io5';
import moment from 'moment';
import {
  VictoryChart,
  VictoryAxis,
  VictoryArea,
  VictoryVoronoiContainer,
  VictoryTooltip,
} from 'victory';

// utils
import { roundOdds, toAmericanOdds, roundNumber } from 'utils';

// components
import ActivityIndicator from 'components/generic/ActivityIndicator';
import AffiliateLink from 'components/AffiliateLink';
import BooksDropdown from 'components/BooksDropdown';
import { Row, Col } from 'components/generic/Layout';
import { AuthButton, IconButton } from 'components/AuthButton';
import Select from 'components/generic/Select';
import OddBtnLine from 'components/OddBtnLine';

// actions
import {
  getLineHistory,
  getLineHistoryNext,
  getLineHistoryGraph,
} from 'actions';

export default function History() {
  const dispatch = useDispatch();
  const reduxProps = useSelector(state => ({
    user: state.authReducer.user,
    game: state.gameReducer.game,
    fullTimeBA: state.gameReducer.fullTimeBA,
    fullTimeOdds: state.gameReducer.fullTimeOdds,
    affiliates: state.affiliatesReducer.affiliates,
    allBooks: state.authReducer.allBooks,
    allBooksMap: state.authReducer.allBooksMap,
  }));
  const {
    user,
    game,
    fullTimeOdds,
    fullTimeBA,
    affiliates,
    allBooks,
    allBooksMap,
  } = reduxProps;

  const _decideStartingBook = () => {
    if (user?.books?.length > 0) {
      return user.books[0];
    }
    if (fullTimeOdds?.length > 0) {
      return allBooksMap[fullTimeOdds[0].book_id];
    }
    if (fullTimeBA?.home_ml_book) {
      return fullTimeBA.home_ml_book;
    }
    if (affiliates?.length > 0) {
      return affiliates[0];
    }
    if (allBooks?.length > 0) {
      return allBooks[0];
    }
    return null;
  };

  const [line, setLine] = useState('home_ml');
  const [book, setBook] = useState(_decideStartingBook());
  const [book2, setBook2] = useState(null);
  const [sort, setSort] = useState('newest');

  const _initialCall = () => {
    if (book) {
      dispatch(
        getLineHistory({
          game_id: game.id,
          book_id: book.id,
          sort_history: sort,
        })
      );
      dispatch(
        getLineHistoryGraph({
          game_id: game.id,
          book_ids: [book.id, book2?.id],
          line: line,
        })
      );
    }
  };

  useEffectOnce(() => {
    _initialCall();
  });

  useUpdateEffect(() => {
    _initialCall();
  }, [book, line]);

  useUpdateEffect(() => {
    dispatch(
      getLineHistoryGraph({
        game_id: game.id,
        book_ids: [book.id, book2?.id],
        line: line,
      })
    );
  }, [book2]);

  return (
    <>
      <Row style={{ alignItems: 'center', margin: 'var(--space-sm)' }}>
        <BooksDropdown
          defaultValue={{ value: book.id, label: book.name }}
          styles={{
            container: base => ({
              ...base,
              flex: 1,
              borderLeft: '5px solid var(--color-primary)',
              paddingLeft: 'var(--space-xs)',
              margin: '0 var(--space-sm)',
            }),
          }}
          onChange={book => setBook({ id: book.value, name: book.label })}
        />
        <BooksDropdown
          styles={{
            container: base => ({
              ...base,
              flex: 1,
              borderLeft: '5px solid var(--color-complement)',
              paddingLeft: 'var(--space-xs)',
              margin: '0 var(--space-sm)',
            }),
          }}
          onChange={book => setBook2({ id: book.value, name: book.label })}
        />
      </Row>

      <Row>
        <Col style={{ minHeight: '370px' }}>
          <Graph line={line} book={book} book2={book2} />
        </Col>
      </Row>
      <OddList
        book={book}
        book2={book2}
        sort={sort}
        line={line}
        setSort={setSort}
        setLine={setLine}
      />
    </>
  );
}

function OddList(props) {
  const dispatch = useDispatch();
  const reduxProps = useSelector(state => ({
    user: state.authReducer.user,
    game: state.gameReducer.game,
    lineHistory: state.gameReducer.lineHistory,
    lineHistoryCount: state.gameReducer.lineHistoryCount,
    isLoadingLineHistory: state.gameReducer.isLoadingLineHistory,
    lineHistoryError: state.gameReducer.lineHistoryError,
    isLoadingLineHistoryNext: state.gameReducer.isLoadingLineHistoryNext,
    lineHistoryNext: state.gameReducer.lineHistoryNext,
    lineHistoryGraph: state.gameReducer.lineHistoryGraph,
  }));
  const {
    user,
    game,
    lineHistory,
    lineHistoryCount,
    isLoadingLineHistory,
    lineHistoryError,
    isLoadingLineHistoryNext,
    lineHistoryNext,
    lineHistoryGraph,
  } = reduxProps;

  const { book, book2, sort, line } = props;

  const _getNext = () => {
    if (lineHistoryNext && !isLoadingLineHistoryNext) {
      dispatch(getLineHistoryNext());
    }
  };
  //useUpdateEffect(() => {
  //  _getNext();
  //}, [lineHistoryNext]);

  const _renderItem = ({ item, index }) => {
    if (item.book_id !== book.id) return null;
    let date = moment(item.created_at).format('MMM D @ h:mma');
    let text = date;
    if (sort === 'newest') {
      if (index === lineHistoryCount - 1) {
        text = 'Opening Line';
      }
      if (index === 0 && game.is_complete) {
        text = 'Closing Line';
      }
      if (index === 0 && !game.is_complete && !game.is_live) {
        text = 'Current Line';
      }
    }
    if (sort === 'oldest') {
      if (index === 0) {
        text = 'Opening Line';
      }
      if (index === lineHistoryCount - 1 && game.is_complete) {
        text = 'Closing Line';
      }
      if (
        index === lineHistoryCount - 1 &&
        !game.is_complete &&
        !game.is_live
      ) {
        text = 'Current Line';
      }
    }

    return (
      <Row
        key={`line-history-odd-${item.id}-${index}`}
        style={{ alignItems: 'center' }}
      >
        <span style={{ flex: 1.33, textAlign: 'center' }} title={date}>
          {text}
        </span>
        <OddBtnLine
          odd={item}
          index={index}
          hideBook
          style={{ flex: 6 }}
          forceShowAll
          forceNotVerified
        />
      </Row>
    );
  };
  let lineOptions = [
    {
      label: `Home Moneyline (${game?.home_team.name})`,
      value: 'home_ml',
    },
    {
      label: `Away Moneyline (${game?.away_team.name})`,
      value: 'away_ml',
    },
    {
      label: `Home Spread (${game?.home_team.name})`,
      value: 'home_spread',
    },
    {
      label: `Away Spread (${game?.away_team.name})`,
      value: 'away_spread',
    },
    {
      label: 'Over',
      value: 'over',
    },
    {
      label: 'Under',
      value: 'under',
    },
  ];

  if (game.sport === 'Soccer') {
    lineOptions.push({ label: 'Tie', value: 'tie_ml' });
  }

  return (
    <>
      <Row style={{ alignItems: 'center', margin: 'var(--space-sm) 0' }}>
        <Col style={{ flex: 1 }}>
          <AffiliateLink
            book={book}
            shape="box"
            hideText
            fromPage="Line History - Web"
            fallbackToGeneric
            fallbackStyle={{ width: '100%' }}
          />
        </Col>
        <Row
          style={{
            flex: 2,
            alignItems: 'center',
            justifyContent: 'space-around',
            border: '1px solid',
            borderRadius: '4px',
            padding: 'var(--space-xxs)',
            margin: '0 var(--space-sm)',
            borderColor: lineHistoryGraph
              ? 'var(--color-primary)'
              : 'transparent',
          }}
        >
          {lineHistoryGraph && (
            <>
              <span>
                Opening Line: {line === 'over' && 'o'}
                {line === 'under' && 'u'}
                {formatNum(line, lineHistoryGraph['opening_num'])}
                <b style={{ color: 'var(--color-primary)' }}>
                  {user.odds_preference === 1
                    ? roundOdds(lineHistoryGraph['opening_odd'])
                    : toAmericanOdds(lineHistoryGraph['opening_odd'])}
                </b>
              </span>
              <span>
                {game.is_live || game.is_complete ? 'Closing' : 'Current'} Line:{' '}
                {line === 'over' && 'o'}
                {line === 'under' && 'u'}
                {formatNum(line, lineHistoryGraph['current_num'])}
                <b style={{ color: 'var(--color-primary)' }}>
                  {user.odds_preference === 1
                    ? roundOdds(lineHistoryGraph['current_odd'])
                    : toAmericanOdds(lineHistoryGraph['current_odd'])}
                </b>
              </span>
            </>
          )}
        </Row>
        <Select
          styles={{
            container: base => ({
              ...base,
              flex: 1,
              marginRight: '0 var(--space-sm)',
            }),
          }}
          defaultValue={{
            value: line,
            label: `Home Moneyline (${game?.home_team.name})`,
          }}
          options={lineOptions}
          onChange={({ value, label }) => {
            props.setLine(value);
            dispatch(
              getLineHistoryGraph({
                game_id: game.id,
                book_ids: [book.id, book2?.id],
                sort: sort,
                line: value,
              })
            );
          }}
        />
        <IconButton
          iconName={IoSwapVertical}
          colorTheme="text"
          onPress={() => {
            let newSort = sort === 'newest' ? 'oldest' : 'newest';
            props.setSort(newSort);
            dispatch(
              getLineHistory({
                game_id: game.id,
                book_id: book.id,
                sort_history: newSort,
              })
            );
          }}
          title="Sort"
        />
      </Row>
      <Row
        style={{
          borderBottom: '1px solid var(--color-text-light)',
          paddingBottom: 'var(--space-xxs)',
        }}
      >
        <b style={{ flex: 1.33, textAlign: 'center' }}>Time</b>
        <Row style={{ flex: 6 }}>
          <b style={{ flex: 1, textAlign: 'center' }}>{game.away_team.name}</b>
          {game.sport === 'Soccer' && (
            <b style={{ flex: 1, textAlign: 'center' }}>Tie</b>
          )}
          <b style={{ flex: 1, textAlign: 'center' }}>{game.home_team.name}</b>
          <b style={{ flex: 1, textAlign: 'center' }}>{game.away_team.name}</b>
          <b style={{ flex: 1, textAlign: 'center' }}>{game.home_team.name}</b>
          <b style={{ flex: 1, textAlign: 'center' }}>Over</b>
          <b style={{ flex: 1, textAlign: 'center' }}>Under</b>
        </Row>
      </Row>

      {isLoadingLineHistory && (
        <>
          <br />
          <ActivityIndicator size={3} />
        </>
      )}
      {lineHistoryError && (
        <>
          <br />
          <h6>We ran into an error getting line history</h6>
        </>
      )}
      {lineHistory &&
        lineHistory.map((item, index) => _renderItem({ item, index }))}
      {lineHistoryNext && !isLoadingLineHistoryNext && (
        <AuthButton onPress={() => _getNext()}>Load More</AuthButton>
      )}
    </>
  );
}

function Graph(props) {
  const reduxProps = useSelector(state => ({
    user: state.authReducer.user,
    game: state.gameReducer.game,
    lineHistoryGraph: state.gameReducer.lineHistoryGraph,
    isLoadingLineHistoryGraph: state.gameReducer.isLoadingLineHistoryGraph,
    lineHistoryGraphError: state.gameReducer.lineHistoryGraphError,
  }));
  const {
    user,
    game,
    lineHistoryGraph,
    isLoadingLineHistoryGraph,
    lineHistoryGraphError,
  } = reduxProps;

  const { line, book, book2 } = props;

  let notEnoughData = useMemo(() => {
    if (isLoadingLineHistoryGraph) return false;
    if (!lineHistoryGraph) return false;
    if (
      lineHistoryGraph['book_1'] === null ||
      lineHistoryGraph['book_1']?.length < 2
    ) {
      return true;
    }
    return false;
  }, [lineHistoryGraph, isLoadingLineHistoryGraph]);

  let oddOrNum = 'odd';
  if (
    line === 'over' ||
    line === 'under' ||
    (line.includes('spread') && !['MLB', 'NHL'].includes(game.league))
  ) {
    oddOrNum = 'num';
  }

  const yAccessor = item => {
    if (oddOrNum === 'num') {
      return item.number;
    }
    return item.odd;
  };

  let yMin = 0;
  let yMax = 0;
  if (lineHistoryGraph) {
    yMin =
      oddOrNum === 'odd'
        ? lineHistoryGraph['oddMin']
        : lineHistoryGraph['numMin'];
    yMax =
      oddOrNum === 'odd'
        ? lineHistoryGraph['oddMax']
        : lineHistoryGraph['numMax'];
    if (oddOrNum === 'odd') {
      yMin = Math.max(yMin - 0.05, 1);
      yMax *= 1.01;
    } else if (oddOrNum === 'num' && line.includes('spread')) {
      if (lineHistoryGraph && lineHistoryGraph['book_1'][0].number < 0) {
        yMin *= 1.01;
        yMax += Math.abs(yMax) * 0.1;
      } else {
        yMin *= 0.99;
        yMax *= 1.01;
      }
    } else if (oddOrNum === 'num' && (line === 'over' || line === 'under')) {
      yMin *= 0.99;
      yMax *= 1.01;
    }
  }

  const _renderGraph = useMemo(() => {
    return (
      <>
        {lineHistoryGraph &&
          !notEnoughData &&
          lineHistoryGraph['book_1']?.length > 2 && (
            <VictoryChart
              //animate={true}
              height={128}
              padding={{ top: 0, bottom: 20, left: 25, right: 25 }}
              domain={{
                x: [
                  moment(lineHistoryGraph['book_1'][0].created_at),
                  moment(
                    lineHistoryGraph['book_1'][
                      lineHistoryGraph['book_1'].length - 1
                    ].created_at
                  ),
                ],
                y: [yMin, yMax],
              }}
              containerComponent={
                <VictoryVoronoiContainer
                  voronoiDimension="x"
                  labels={({ datum }) => {
                    let label = '';
                    if (datum.book_id === book.id) label = book.name + ' ';
                    if (datum.book_id === book2?.id) label = book2?.name + ' ';

                    if (line.includes('ml')) {
                      if (user.odds_preference === 1) {
                        return label + roundOdds(datum.odd).toString();
                      }
                      if (datum.odd <= 1) return '-';
                      return label + toAmericanOdds(datum.odd);
                    }
                    return label + roundNumber(datum.number);
                  }}
                  labelComponent={
                    <VictoryTooltip
                      center={{ x: 40 + 25 + 5, y: 15 }}
                      flyoutWidth={80}
                      cornerRadius={2}
                      pointerLength={0}
                      pointerWidth={0}
                      flyoutStyle={{
                        fill: 'rgba(0,0,0,0.75)',
                        strokeWidth: 0,
                      }}
                    />
                  }
                />
              }
            >
              <defs>
                <linearGradient
                  id={'chart-gradient1'}
                  x1="0%"
                  y="0%"
                  x2="0%"
                  y2="100%"
                >
                  <stop
                    offset="0%"
                    stopColor="var(--color-primary)"
                    stopOpacity={1}
                  />
                  <stop
                    offset="100%"
                    stopColor="var(--color-primary)"
                    stopOpacity={0}
                  />
                </linearGradient>
              </defs>
              <VictoryAxis
                orientation="bottom"
                style={{
                  axis: { stroke: 'var(--color-text)' },
                  ticks: { stroke: 'var(--color-text)', size: 3 },
                  tickLabels: {
                    fontSize: 6,
                    fill: 'var(--color-text)',
                    angle: 20,
                    padding: 10,
                  },
                }}
                tickCount={5}
                tickFormat={x => moment(x).format('MMM D @ h:mma')}
                fixLabelOverlap={true}
              />
              <VictoryAxis
                orientation={'left'}
                dependentAxis
                tickFormat={value => {
                  if (oddOrNum === 'odd') {
                    if (user.odds_preference === 1) {
                      return roundOdds(value);
                    }
                    if (value <= 1) return '-';
                    return toAmericanOdds(value);
                  }
                  return formatNum(line, value);
                }}
                domain={[yMin, yMax]}
                tickCount={5}
                style={{
                  axis: { stroke: 'var(--color-text)' },
                  ticks: { stroke: 'var(--color-text)', size: 3 },
                  tickLabels: {
                    fontSize: 6,
                    padding: 2,
                    fill: 'var(--color-text)',
                  },
                }}
              />
              <VictoryArea
                style={{
                  data: {
                    fill: book2 ? 'transparent' : 'url(#chart-gradient1)',
                    stroke: 'var(--color-primary)',
                    strokeWidth: 1,
                  },
                  labels: {
                    fill: 'var(--color-primary)',
                    fontSize: 6,
                    textAnchor: 'left',
                  },
                }}
                interpolation="stepAfter"
                data={lineHistoryGraph['book_1']}
                y={yAccessor}
                x={item => moment(item.created_at)}
              />
              {lineHistoryGraph['book_2']?.length > 2 && (
                <VictoryArea
                  style={{
                    data: {
                      fill: 'transparent',
                      stroke: 'var(--color-complement)',
                      strokeWidth: 1,
                    },
                    labels: {
                      fill: 'var(--color-complement)',
                      fontSize: 6,
                      textAnchor: 'left',
                    },
                  }}
                  interpolation="stepAfter"
                  data={lineHistoryGraph['book_2']}
                  y={yAccessor}
                  x={item => moment(item.created_at)}
                />
              )}
            </VictoryChart>
          )}
      </>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lineHistoryGraph]);

  return (
    <>
      {notEnoughData && (
        <h6>Not enough data to graph. Try another book or another line.</h6>
      )}
      {lineHistoryGraphError && (
        <h6>We ran into an error getting graph data</h6>
      )}
      {isLoadingLineHistoryGraph && <ActivityIndicator size={3} />}

      {_renderGraph}
    </>
  );
}

function formatNum(lineType, num) {
  if (num === null) return '';
  if (lineType.includes('spread') && num > 0) {
    return `+${roundNumber(num)} `;
  }
  return roundNumber(num) + ' ';
}
