import { Observable } from 'rxjs';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import { ajax } from 'rxjs/observable/dom/ajax';
import { BACKEND_API_URL, getHeaders } from 'utils';

import {
  UPDATE_NOTIFICATION_PREFERENCES,
  updateNotificationPreferencesSuccess,
  updateNotificationPreferencesFailure,
} from 'actions';

export default function updateNotificationPreferences(action$, state$) {
  return action$.ofType(UPDATE_NOTIFICATION_PREFERENCES).switchMap(action => {
    return ajax
      .patch(
        BACKEND_API_URL +
          'api/notifications/' +
          state$.value.authReducer.user.id +
          '/',
        action.params,
        getHeaders(state$)
      )
      .map(data => updateNotificationPreferencesSuccess(data))
      .catch(error =>
        Observable.of(updateNotificationPreferencesFailure(error.xhr))
      );
  });
}
