import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { Row } from 'components/generic/Layout';

const Wrapper = styled.button`
  font-size: var(--text-base-size);
  outline: none;
  cursor: pointer;
  text-align: left;
  font-weight: normal;
  color: var(--color-text);

  border-radius: var(--std-border-radius);
  padding: var(--space-xs) var(--space-sm);
  border: 2px solid var(--color-dollar-green);
  background-color: var(--color-fg);
  transition: all var(--std-transition);

  flex: 1;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: flex-start;

  &:hover {
    box-shadow: 0 2px 8px 0 var(--color-shadow);
  }

  &:active {
    box-shadow: inset 0 0 0 100vh rgba(0, 0, 0, 0.1);
  }
`;

export default function FeaturedCompetition(props) {
  const { competition } = props;
  return (
    <Link
      style={{ textDecoration: 'none' }}
      to={`/competitions/${competition.id}`}
    >
      <Wrapper style={props.style}>
        <Row style={{ padding: '5px 5px', alignItems: 'center' }}>
          <img
            style={{ width: '40px', height: '40px' }}
            alt={competition.name}
            title={competition.name}
            src={competition.logo}
          />
          <h5
            style={{
              flex: 1,
              fontWeight: 'bold',
              padding: '0 10px',
              margin: '0',
            }}
          >
            {competition.name.substr(0, 178)}
            {competition.name.length > 178 ? '...' : ''}
          </h5>
        </Row>

        <div>
          <img
            style={{ width: '100%', height: 'auto' }}
            alt={competition.name}
            title={competition.name}
            src={competition.banner}
          />
        </div>
        <p style={{ margin: '10px', 'whiteSpace': 'pre-line' }}>
          {competition.tag_line.substr(0, 256)}
          {competition.tag_line.length > 256 ? '...' : ''}
        </p>
      </Wrapper>
    </Link>
  );
}
