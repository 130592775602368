import React from 'react';
import { useSelector } from 'react-redux';

import { GROUPING_OPTIONS } from 'utils/ANALYSIS_CONSTANTS';

import CustomSelect from 'components/generic/Select';

export default function Yaxis({ defaultValue, filterName, onDone }) {
  const reduxProps = useSelector((state) => ({
    user: state.authReducer.user,
    filters: state.analysisReducer.filters,
  }));

  const { user, filters } = reduxProps;

  let options = GROUPING_OPTIONS;

  if (filters['User'].value && filters['User'].value !== user.id) {
    options = GROUPING_OPTIONS.filter((o) => o !== 'Tags');
  }

  return (
    <>
      <CustomSelect
        onChange={(opt) => {
          onDone({
            filter: filterName,
            value: opt.value,
            slug: opt.label,
          });
        }}
        value={{
          value: defaultValue,
          label: defaultValue
            .replace('Book', 'Sportsbook')
            .replace(/%20/g, ' '),
        }}
        options={options.map((o) => ({
          value: o,
          label: o.replace('Book', 'Sportsbook'),
        }))}
      />
    </>
  );
}
