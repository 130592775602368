import { Observable } from 'rxjs';
import 'rxjs/add/operator/exhaustMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import { ajax } from 'rxjs/observable/dom/ajax';
import { BACKEND_API_URL, getHeaders } from 'utils';

import { BUY_PACKAGE, buyPackageSuccess, buyPackageFailure } from 'actions';

export default function buyPackage(action$, state$) {
  return action$.ofType(BUY_PACKAGE).exhaustMap(action => {
    return ajax
      .post(
        BACKEND_API_URL + 'api/users/add_purchase/',
        {
          package: action.userPackage.id,
          session_id: action.sessionId,
        },
        getHeaders(state$)
      )
      .map(data => buyPackageSuccess(data, action.userPackage))
      .catch(error => Observable.of(buyPackageFailure(error.xhr)));
  });
}
