import {
  GET_SURVEYS,
  GET_SURVEYS_SUCCESS,
  GET_SURVEYS_FAILURE,
  GET_SURVEYS_NEXT,
  GET_SURVEY,
  GET_SURVEY_SUCCESS,
  GET_SURVEY_FAILURE,
  POST_SURVEY_RESULTS,
  POST_SURVEY_RESULTS_SUCCESS,
  POST_SURVEY_RESULTS_FAILURE,
} from 'actions';

const initialState = {
  surveys: [],
  isLoadingSurveys: false,
  fetchSurveysFailure: null,
  isLoadingSurveysNext: false,
  surveysNext: null,

  survey: null,
  isLoadingSurvey: false,
  fetchSurveyFailure: null,

  isPostingSurveyResults: false,
  getSurveyResultsSuccess: null,
  surveyPostError: null,
  recommendation: null,
  recommendationText: null,

  surveyResult: null,
};

export default function surveyReducer(state = initialState, action) {
  switch (action.type) {
    case GET_SURVEYS:
      return Object.assign({}, state, {
        ...state,
        isLoadingSurveys: true,
        fetchSurveysFailure: false,
        surveys: [],
        surveysNext: null,
        survey: null,
        recommendation: null,
        surveyPostError: null,
      });
    case GET_SURVEYS_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        isLoadingSurveys: false,
        isLoadingSurveysNext: false,
        fetchSurveysFailure: null,
        surveys: state.surveys.concat(action.payload.results),
        surveysNext: action.payload.next,
      });
    case GET_SURVEYS_FAILURE:
      return Object.assign({}, state, {
        ...state,
        isLoadingSurveys: false,
        fetchSurveysFailure: action.error,
      });
    case GET_SURVEYS_NEXT:
      return Object.assign({}, state, {
        ...state,
        isLoadingSurveysNext: true,
      });

    case GET_SURVEY:
      return Object.assign({}, state, {
        ...state,
        isLoadingSurvey: true,
        fetchSurveyFailure: false,
        surveyPostError: null,
      });
    case GET_SURVEY_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        isLoadingSurvey: false,
        fetchSurveyFailure: null,
        survey: action.payload,
      });
    case GET_SURVEY_FAILURE:
      return Object.assign({}, state, {
        ...state,
        isLoadingSurvey: false,
        fetchSurveyFailure: action.error,
      });

    case POST_SURVEY_RESULTS:
      return Object.assign({}, state, {
        ...state,
        isPostingSurveyResults: true,
        surveyPostError: false,
      });
    case POST_SURVEY_RESULTS_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        isPostingSurveyResults: false,
        surveyResult: action.payload.response.survey_result,
        recommendation: action.payload.response.recommendation,
        recommendationText: action.payload.response.recommendation_text,
        surveyPostError: false,
      });
    case POST_SURVEY_RESULTS_FAILURE:
      return Object.assign({}, state, {
        ...state,
        apiError: action.error,
        isPostingSurveyResults: false,
        surveyPostError: action.error,
      });

    default:
      return state;
  }
}
