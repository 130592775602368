import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// component
import ActivityIndicator from 'components/generic/ActivityIndicator';
import { Col } from 'components/generic/Layout';
import OddBtnLine from 'components/OddBtnLine';
import GameBAHeader from 'components/GameBAHeader';

// actions
import { getFullTimeOddsNext } from 'actions';

export default function FullTime() {
  const dispatch = useDispatch();
  const reduxProps = useSelector(state => ({
    fullTimeOdds: state.gameReducer.fullTimeOdds,
    isLoadingFullTimeOdds: state.gameReducer.isLoadingFullTimeOdds,
    fullTimeOddsError: state.gameReducer.fullTimeOddsError,
    fullTimeOddsNext: state.gameReducer.fullTimeOddsNext,
    isLoadingFullTimeOddsNext: state.gameReducer.isLoadingFullTimeOddsNext,
    fullTimeBA: state.gameReducer.fullTimeBA,
    isLoadingFullTimeBA: state.gameReducer.isLoadingFullTimeBA,
    fullTimeBAError: state.gameReducer.fullTimeBAError,
    currentTourStep: state.tourReducer.currentTourStep,
  }));
  const {
    fullTimeOdds,
    isLoadingFullTimeOdds,
    fullTimeOddsError,
    fullTimeOddsNext,
    isLoadingFullTimeOddsNext,
    fullTimeBA,
    isLoadingFullTimeBA,
    fullTimeBAError,
  } = reduxProps;

  const _getNext = () => {
    if (fullTimeOddsNext && !isLoadingFullTimeOddsNext) {
      dispatch(getFullTimeOddsNext());
    }
  };
  useEffect(() => {
    _getNext();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fullTimeOddsNext]);

  if (isLoadingFullTimeOdds) {
    return (
      <Col style={{ height: '75%', justifyContent: 'center' }}>
        <ActivityIndicator size={3} />
      </Col>
    );
  }

  if (fullTimeOddsError) {
    return (
      <h5 style={{ textAlign: 'center' }}>
        We ran into an error getting fulltime odds
      </h5>
    );
  }

  return (
    <div style={{ minHeight: '100vh' }}>
      <GameBAHeader
        BA={fullTimeBA}
        isLoading={isLoadingFullTimeBA}
        error={fullTimeBAError}
      />
      {fullTimeOdds &&
        fullTimeOdds.map((odd, index) => (
          <OddBtnLine
            key={`fulltime-list-odd-${index}`}
            odd={odd}
            index={index}
          />
        ))}
    </div>
  );
}
