import {
  GET_ODDSCOMP,
  GET_ODDSCOMP_SUCCESS,
  GET_ODDSCOMP_FAILURE,
  GET_ODDSCOMP_BA,
  GET_ODDSCOMP_BA_SUCCESS,
  GET_ODDSCOMP_BA_FAILURE,
  GET_ODDSCOMP_GAMES,
  GET_ODDSCOMP_GAMES_SUCCESS,
  GET_ODDSCOMP_GAMES_FAILURE,
  SET_ODDSCOMP_SIZE,
  SET_ODDSCOMP_ODD_TYPES,
} from 'actions';

const initialState = {
  odds: {},
  isLoadingOddsComp: false,
  fetchOddsCompError: null,
  since: null,
  booksWithOdds: new Set(),
  // the BA are not deltas
  oddsCompBA: {},
  isLoadingBAOddsComp: false,
  oddsCompBAError: null,
  // the games are not deltas
  oddsCompGameRefresh: {},
  isLoadingOddsCompGameRefresh: false,
  oddsCompGameRefreshError: null,
  // ui settings
  sizeIndex: 3,
  oddTypes: ['Moneyline', 'Spread', 'Total'],
};

export default function oddscompReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ODDSCOMP:
      // to avoid re-rendering, since we dont actually use these loading state
      return state;
    //return Object.assign({}, state, {
    //  isLoadingOddsComp: true,
    //  fetchOddscompError: null,
    //});
    case GET_ODDSCOMP_SUCCESS:
      let newOdds = state.odds;
      let books = state.booksWithOdds;
      action.payload.forEach(item => {
        if (!newOdds[item.game]) {
          newOdds[item.game] = {};
        }
        newOdds[item.game][item.book] = item;
        books.add(item.book);
      });
      return Object.assign({}, state, {
        isLoadingOddsComp: false,
        odds: newOdds,
        since: new Date(),
        booksWithOdds: books,
        fetchOddscompError: null,
      });
    case GET_ODDSCOMP_FAILURE:
      return Object.assign({}, state, {
        fetchOddscompError: action.error,
        refreshingAll: false,
        isLoadingOddsComp: false,
      });
    case GET_ODDSCOMP_BA:
      return state;
    //return Object.assign({}, state, {
    //  isLoadingBAOddsComp: true,
    //  oddsCompBAError: null,
    //});
    case GET_ODDSCOMP_BA_SUCCESS:
      // oddsCompBA is not a delta
      return Object.assign({}, state, {
        isLoadingBAOddsComp: false,
        oddsCompBA: action.payload,
        oddsCompBAError: null,
      });
    case GET_ODDSCOMP_BA_FAILURE:
      return Object.assign({}, state, {
        isLoadingBAOddsComp: false,
        oddsCompBAError: action.error,
      });

    case GET_ODDSCOMP_GAMES:
      return state;
    //return Object.assign({}, state, {
    //  isLoadingOddsCompGameRefresh: true,
    //  oddsCompGameRefreshError: null,
    //});
    case GET_ODDSCOMP_GAMES_SUCCESS:
      // not a delta
      let games = {};
      action.payload.forEach(game => (games[game.id] = game));
      return Object.assign({}, state, {
        isLoadingOddsCompGameRefresh: false,
        oddsCompGameRefresh: games,
        oddsCompGameRefreshError: null,
      });
    case GET_ODDSCOMP_GAMES_FAILURE:
      return Object.assign({}, state, {
        isLoadingOddsCompGameRefresh: false,
        oddsCompGameRefreshError: action.error,
      });

    case SET_ODDSCOMP_SIZE:
      return Object.assign({}, state, {
        sizeIndex: action.size,
      });

    case SET_ODDSCOMP_ODD_TYPES:
      return Object.assign({}, state, {
        oddTypes: action.oddTypes,
      });

    default:
      return state;
  }
}
