import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import moment from 'moment';

// components
import StaffOnboardingBase from 'components/StaffOnboardingBase';
import { AuthTextInput } from 'components/AuthTextInput';
import CalendarInput from 'components/generic/CalendarInput';
import { AuthButton, ConfirmAuthButton } from 'components/AuthButton';
import { Col, Row } from 'components/generic/Layout';
import Select from 'components/generic/Select';
import { SwitchSetting } from 'components/SettingsBtn';
import GenericAdminFilter from 'components/onboarding/GenericAdminFilter';

// utils
import { CANADA_FLAG, humanDatetime, USA_FLAG } from 'utils';

// actions
import { checkReferralCodeExists } from 'actions';

export default function StaffReferralCodes(params) {
  return (
    <>
      <StaffOnboardingBase
        endpoint="staff-ref-codes"
        modelName="Referral Code"
        hideCreate
        headings={[
          'Code',
          'Internal Code',
          'Owner',
          'Country',
          'Group',
          'Start Date',
          'End Date',
          'Subaffiliate',
          'Status',
          '',
        ]}
        objKeys={[
          'code',
          'internal_code',
          'owner_name',
          'country',
          'group',
          'start_date',
          'end_date',
          'sub_affiliate',
          'status',
        ]}
        sortKeys={{
          Owner: 'owner__username',
          Country: 'country',
          Code: 'code',
          'Internal Code': 'internal_code',
          Group: 'group',
          'Start Date': 'start_date',
          'End Date': 'end_date',
          Subaffiliate: 'sub_affiliate',
          owner__username: 'Owner',
          country: 'Country',
          code: 'Code',
          internal_code: 'Internal Code',
          group: 'Group',
          start_date: 'Start Date',
          end_date: 'End Date',
          sub_affiliate: 'Subaffiliate',
        }}
        formatText={{
          country: country => (
            <>
              {country !== 'Global' && (
                <img
                  src={country === 'Canada' ? CANADA_FLAG : USA_FLAG}
                  alt={country}
                  width={32}
                  style={{
                    verticalAlign: 'middle',
                    marginRight: 'var(--space-xxxs)',
                  }}
                />
              )}

              <span
                style={{
                  verticalAlign: 'middle',
                }}
              >
                {country}
              </span>
            </>
          ),
          created_at: humanDatetime,
          status: status => (
            <>
              <span
                style={{
                  borderRadius: 'var(--std-border-radius)',
                  backgroundColor:
                    status === 'CURRENT'
                      ? 'var(--color-success)'
                      : status === 'EXPIRED'
                      ? 'var(--color-danger'
                      : status === 'FUTURE'
                      ? 'var(--color-complement)'
                      : status === 'UNCLAIMED'
                      ? 'lightskyblue'
                      : 'transparent',

                  color: 'black',
                  fontSize: 'var(--text-xs)',
                  padding: 'var(--space-xxxs)',
                }}
              >
                {status}
              </span>
            </>
          ),
          sub_affiliate: sa => (
            <div
              style={{
                width: '10px',
                height: '10px',
                borderRadius: '50%',
                backgroundColor: sa
                  ? 'var(--color-success)'
                  : 'var(--color-danger)',
              }}
            />
          ),
        }}
        showSearch
        searchPlaceholder="Search by code or internal code"
        PanelChildren={Panel}
        panelTabs={['Details', 'Edit', 'CSV']}
        deleteBaseEndpoint="staff-ref-codes"
        FilterChildren={FilterChildren}
      />
    </>
  );
}

function FilterChildren(props) {
  const { urlParams } = props;
  const defaultFilters = useMemo(() => {
    let filters = {};
    filters.status = urlParams?.status;
    filters.claimed = urlParams?.claimed;
    if (urlParams.country) {
      filters.country = urlParams.country || 'All';
    }
    if (urlParams.groups) {
      let d = {};
      for (const r of JSON.parse(urlParams.groups)) {
        d[r] = true;
      }
      filters.groups = d;
    }
    return filters;
  }, [urlParams]);

  const fetchMarketingGroups = useQuery({
    refetchOnWindowFocus: false,
    queryKey: [
      {
        endpoint: `marketing-groups`,
      },
    ],
  });

  return (
    <div style={{ width: '100%' }}>
      <GenericAdminFilter
        filterTitle="Filter by Country"
        filterKey="country"
        type="toggleSelect"
        options={['All', 'Canada', 'USA', 'Global']}
        onFilter={opt =>
          props.onFilter({
            country: opt,
          })
        }
        defaultActive={defaultFilters?.country || 'All'}
      />
      <GenericAdminFilter
        filterTitle="Filter by Marketing Group"
        filterKey="groups"
        options={{
          groups: [
            {
              title: 'Groups',
              options:
                fetchMarketingGroups.isSuccess &&
                fetchMarketingGroups.data.map(group => ({
                  label: group.name,
                  value: group.id,
                })),
            },
          ],
        }}
        onFilter={selections => {
          props.onFilter({
            groups: JSON.stringify(selections),
          });
        }}
        defaultDict={defaultFilters.groups}
      />
      <GenericAdminFilter
        filterTitle="Filter by Status"
        filterKey="status"
        type="toggleSelect"
        options={['All', 'Current', 'Expired']}
        onFilter={opt =>
          props.onFilter({
            status: opt,
          })
        }
        defaultActive={!defaultFilters.status ? 'All' : defaultFilters.status}
      />
      <GenericAdminFilter
        filterTitle="Filter by Claimed/Unclaimed"
        filterKey="claimed"
        type="toggleSelect"
        options={['All', 'Claimed', 'Unclaimed']}
        onFilter={opt =>
          props.onFilter({
            claimed: opt,
          })
        }
        defaultActive={!defaultFilters.claimed ? 'All' : defaultFilters.claimed}
      />
    </div>
  );
}

function Panel(props) {
  const dispatch = useDispatch();
  const reduxProps = useSelector(state => ({
    referralCodeExists: state.authReducer.referralCodeExists,
  }));
  const refCode = props.selectedObj;
  const { tab } = props;
  const { referralCodeExists } = reduxProps;

  const [filters, setFilters] = useState({
    start_date: !!refCode?.start_date
      ? new Date(refCode?.start_date.replaceAll('-', '/'))
      : new Date(),
    end_date: !!refCode?.end_date
      ? new Date(refCode?.end_date.replaceAll('-', '/'))
      : undefined,
    owner: refCode?.owner,
    country: refCode?.country,
    code: refCode?.code,
    internal_code: refCode?.internal_code,
    group_name: refCode?.group,
    sub_affiliate: refCode?.sub_affiliate,
  });

  const [downloadFilter, setDownloadFilter] = useState({
    from_date: moment().subtract(1, 'month').startOf('month').toDate(),
    to_date: moment().subtract(1, 'month').endOf('month').toDate(),
  });

  const queryClient = useQueryClient();

  const fetchMarketingGroups = useQuery({
    refetchOnWindowFocus: false,
    queryKey: [
      {
        endpoint: `marketing-groups`,
      },
    ],
  });

  const submitRefCodeChange = useMutation({
    mutationKey: 'submit-ref-code-change',
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [{ endpoint: 'staff-ref-codes' }],
      });
      props.closePanel();
    },
  });

  const downloadCsv = useMutation({
    mutationKey: 'download-csv',
  });

  return (
    <>
      {tab === 'CSV' && (
        <>
          <Col
            style={{
              alignItems: 'flex-start',
              width: '100%',
            }}
          >
            <span style={{ marginTop: 'var(--space-xs)' }}>Start Date</span>
            <Row
              style={{
                backgroundColor: 'var(--color-fg)',
                border: '1px solid var(--color-text-light)',
                borderRadius: '32px',
                padding: 'var(--space-xxxs)',
                maxHeight: '64px',
              }}
            >
              <CalendarInput
                name="from_date"
                value={downloadFilter.from_date}
                onChange={date =>
                  setDownloadFilter({ ...downloadFilter, from_date: date })
                }
              />
            </Row>
            <span style={{ marginTop: 'var(--space-xs)' }}>End Date</span>
            <Row
              style={{
                backgroundColor: 'var(--color-fg)',
                border: '1px solid var(--color-text-light)',
                borderRadius: '32px',
                padding: 'var(--space-xxxs)',
                maxHeight: '64px',
              }}
            >
              <CalendarInput
                name="end_date"
                value={downloadFilter.to_date}
                onChange={date =>
                  setDownloadFilter({ ...downloadFilter, to_date: date })
                }
              />
            </Row>
          </Col>
          {downloadCsv.isSuccess && (
            <small
              style={{
                margin: 'var(--space-xs) 0',
                color: 'var(--color-success)',
              }}
            >
              {downloadCsv.data.message}
            </small>
          )}
          {downloadCsv.isError && (
            <small
              style={{
                margin: 'var(--space-xs) 0',
                color: 'var(--color-danger)',
              }}
            >
              {downloadCsv.error.message}
            </small>
          )}
          <AuthButton
            isLoading={downloadCsv.isLoading}
            onPress={() =>
              downloadCsv.mutate({
                endpoint: `referral_codes/${refCode.id}/generate_csv`,
                body: downloadFilter,
              })
            }
            btnTheme={'slim'}
          >
            Request CSV Report
          </AuthButton>
        </>
      )}
      {tab === 'Edit' && (
        <>
          <Col
            style={{
              alignItems: 'flex-start',
              width: '100%',
            }}
          >
            <span style={{ marginTop: 'var(--space-md)' }}>Start Date</span>
            <Row
              style={{
                backgroundColor: 'var(--color-fg)',
                border: '1px solid var(--color-text-light)',
                borderRadius: '32px',
                padding: 'var(--space-xxxs)',
              }}
            >
              <CalendarInput
                name="start_date"
                value={
                  filters.start_date ||
                  (refCode?.start_date &&
                    new Date(refCode.start_date.replaceAll('-', '/')))
                }
                onChange={date => setFilters({ ...filters, start_date: date })}
              />
            </Row>
            <span style={{ marginTop: 'var(--space-md)' }}>End Date</span>
            <Row
              style={{
                backgroundColor: 'var(--color-fg)',
                border: '1px solid var(--color-text-light)',
                borderRadius: '32px',
                padding: 'var(--space-xxxs)',
              }}
            >
              <CalendarInput
                name="end_date"
                value={
                  filters.end_date ||
                  (refCode?.end_date &&
                    new Date(refCode.end_date.replaceAll('-', '/')))
                }
                onChange={date => setFilters({ ...filters, end_date: date })}
              />
            </Row>
          </Col>
          <Col style={{ alignItems: 'flex-start', width: '100%' }}>
            <Row
              style={{
                width: '100%',
                justifyContent: 'space-between',
              }}
            >
              <p>Internal Code:</p>
              <p>
                <strong>{refCode?.internal_code}</strong>
              </p>
            </Row>
            <Row style={{ width: '100%', justifyContent: 'space-between' }}>
              <p>Country:</p>
              <p>
                <strong>{refCode?.country}</strong>
              </p>
            </Row>
          </Col>
          <AuthTextInput
            label="Owner ID"
            name="owner"
            placeholder="103..."
            onChangeText={user => setFilters({ ...filters, owner: user })}
            defaultValue={refCode?.owner}
          />
          <AuthTextInput
            label="Code"
            name="code"
            placeholder="Code"
            textTransform="uppercase"
            onChangeText={text => {
              dispatch(checkReferralCodeExists(text));
              setFilters({ ...filters, code: text });
            }}
            defaultValue={refCode?.code}
          />
          {referralCodeExists === true && (
            <small style={{ color: 'var(--color-danger)' }}>
              NOTE: This Referral Code already exists. You can still create it
              but it may update historical data associated with it. If this is
              not your intention, please use a new code.
            </small>
          )}
          {fetchMarketingGroups.isSuccess && !!fetchMarketingGroups.data && (
            <Select
              label="Group"
              name="group_name"
              options={fetchMarketingGroups.data.map(g => ({
                label: g.name,
                value: g.name,
              }))}
              onChange={opt =>
                setFilters({ ...filters, group_name: opt.value })
              }
              defaultValue={{ label: refCode?.group, value: refCode?.group }}
            />
          )}
          {refCode?.status === 'CURRENT' && (
            <ConfirmAuthButton
              isLoading={submitRefCodeChange.isLoading}
              onPress={() =>
                submitRefCodeChange.mutate({
                  endpoint: `staff-ref-codes/${refCode.id}`,
                  body: { ...filters, unclaim_code: true },
                })
              }
              colorTheme={'complement'}
              btnTheme={'slim'}
              confirmText={'Are you sure you want to clear this Referral Code?'}
              containerStyle={{ marginTop: 'var(--space-sm)' }}
            >
              Clear (Unclaim) this ReferralCode
            </ConfirmAuthButton>
          )}
          <AuthButton
            isLoading={submitRefCodeChange.isLoading}
            onPress={() =>
              submitRefCodeChange.mutate({
                endpoint: `staff-ref-codes/${refCode.id}`,
                body: filters,
              })
            }
            btnTheme={'slim'}
          >
            {'Save Changes'}
          </AuthButton>
          {submitRefCodeChange.isError && (
            <small
              style={{ textAlign: 'center', color: 'var(--color-danger)' }}
            >
              {submitRefCodeChange.error.message}
            </small>
          )}
        </>
      )}
    </>
  );
}
