/**
 * Rounds a spread/total to either 0 or 1 decimal place
 * @param {String/Number} number Unrounded number
 * @returns {String} Rounded number.
 */
export default function roundNumber(number) {
  // return number rounded to 0 decimal places if integer, else to 1
  if (!number) return number;
  return parseInt(number) === parseFloat(number)
    ? parseInt(number).toFixed(0)
    : (parseFloat(number) * 100) % 10 === 0
    ? parseFloat(number).toFixed(1)
    : (parseFloat(number) * 100) % 25 === 0
    ? parseFloat(number).toFixed(2)
    : parseFloat(number).toFixed(1);
}
