import { Observable } from 'rxjs';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import { ajax } from 'rxjs/observable/dom/ajax';
import { BACKEND_API_URL, getHeaders } from 'utils';

import {
  GET_ODDSCOMP_BA,
  getOddsCompBASuccess,
  getOddsCompBAFailure,
} from 'actions';

export default function getOddsCompBA(action$, state$) {
  return action$.ofType(GET_ODDSCOMP_BA).switchMap(action => {
    let url = BACKEND_API_URL + `api/best_available/?`;

    if (action.game_ids) url += `game_ids=${JSON.stringify(action.game_ids)}&`;

    if (action.period) url += `period=${action.period}&`;

    return ajax
      .getJSON(url, getHeaders(state$))
      .map(data => getOddsCompBASuccess(data))
      .catch(error => Observable.of(getOddsCompBAFailure(error.xhr)));
  });
}
