import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// import { Link } from 'react-router-dom';

// components
import {
  Row,
  Col,
  MainAreaWrapper,
  InnerMainArea,
  Grid,
} from 'components/generic/Layout';
import ActivityIndicator from 'components/generic/ActivityIndicator';
import AnalysisSidebar from 'components/analysis/AnalysisSidebar';
import ContentLoader from 'components/generic/ContentLoader';
import SortableTable from 'components/generic/SortableTable';
import Graph from 'components/analysis/Graph';
import CustomSelect from 'components/generic/Select';
import PresetBtn from 'components/analysis/PresetBtn';
import ErrorDisplay from 'components/generic/ErrorDisplay';
import PresetToolbar from 'components/analysis/PresetToolbar';

import AnalysisLogo from 'images/logos/analysis.svg';
import { AuthButton } from 'components/AuthButton';

// actions
import { getGraphData, getAnalysisPresetsNext } from 'actions';

// descriptions of what each graph plot
const symbolMap = {
  roi: '%',
  clv: '%',
  net_profit: '$',
  risk_amount: '$',
};

// const IntervalBtn = styled.button`
//   flex: 1;
//   width: 100%;
//   box-sizing: border-box;
//   border: none;
//   border-radius: var(--std-border-radius);
//   outline: none;
//   padding: var(--space-xs);
//   margin: var(--space-xs);
//   cursor: pointer;
//   transition: all var(--std-transition);
//   font-weight: bold;
//   font-size: var(--text-base-size);
//   &:active {
//     background-color: var(--color-bg);
//   }

//   &:hover {
//     background-color: var(--color-fg);
//   }

//   background-color: ${(props) => {
//     if (props.selected) {
//       return 'var(--color-primary-transparent)';
//     }
//     return 'transparent';
//   }};

//   color: ${(props) => {
//     if (props.selected) {
//       return 'var(--color-primary)';
//     }
//     return 'var(--color-text)';
//   }};
// `;

export default function AnalysisDesktop() {
  const dispatch = useDispatch();
  const reduxProps = useSelector(state => ({
    presets: state.analysisReducer.presets,
    presetsNext: state.analysisReducer.presetsNext,
    currentAnalysis: state.analysisReducer.currentAnalysis,
    currentUrlParams: state.analysisReducer.currentUrlParams,
    isLoading: state.analysisReducer.isLoading,
    isLoadingPresets: state.analysisReducer.isLoadingPresets,
    fetchError: state.analysisReducer.fetchError,
    graphData: state.analysisReducer.graphData,
    graphData2: state.analysisReducer.graphData2,
    isLoadingGraphData: state.analysisReducer.isLoadingGraphData,
    graphExtraFilters: state.analysisReducer.graphExtraFilters,
    graphExtraFiltersSlug: state.analysisReducer.graphExtraFiltersSlug,
    filters: state.analysisReducer.filters,
  }));
  const {
    presets,
    isLoading,
    isLoadingPresets,
    presetsNext,
    fetchError,
    currentUrlParams,
    currentAnalysis,
    graphData,
    graphData2,
    isLoadingGraphData,
    graphExtraFilters,
    graphExtraFiltersSlug,
    filters,
  } = reduxProps;

  useEffect(() => {
    if (currentUrlParams) {
      dispatch(
        getGraphData(
          currentUrlParams,
          graphSelection.value,
          graphInterval,
          null,
          null,
          1
        )
      );
      if (graphData2 && graphSelection2.value) {
        dispatch(
          getGraphData(
            currentUrlParams,
            graphSelection2.value,
            graphInterval,
            null,
            null,
            2
          )
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUrlParams]);

  const [graphSelection, setGraphSelection] = useState({
    value: 'roi',
    label: 'ROI',
  });
  const [graphSelection2, setGraphSelection2] = useState({
    value: null,
    label: 'Choose a second dataset',
  });

  // const [graphInterval, setGraphInterval] = useState('day');
  const graphInterval = 'day';

  // one day we want this
  // const _changeInterval = (interval) => {
  //   setGraphInterval(interval);
  //   dispatch(
  //     getGraphData(
  //       currentUrlParams,
  //       graphSelection.value,
  //       interval,
  //       graphExtraFilters,
  //       graphExtraFiltersSlug,
  //       1
  //     )
  //   );
  //   if (graphData2 && graphSelection2.value) {
  //     dispatch(
  //       getGraphData(
  //         currentUrlParams,
  //         graphSelection2.value,
  //         interval,
  //         graphExtraFilters,
  //         graphExtraFiltersSlug,
  //         2
  //       )
  //     );
  //   }
  // };

  return (
    <MainAreaWrapper>
      <InnerMainArea>
        <div
          style={{
            width: '100%',
            height: '100%',
            overflowY: 'auto',
            padding: '0',
            boxSizing: 'border-box',
            paddingBottom: 'var(--space-xl)',
            margin: 'auto',
          }}
        >
          {currentAnalysis && !isLoading && !fetchError ? (
            <>
              <PresetToolbar />

              <div
                style={{
                  width: '100%',
                  maxWidth: '1300px',
                  margin: 'auto',
                  padding: '0 var(--space-xs)',
                  boxSizing: 'border-box',
                }}
                id="analysis-div-wrapper"
              >
                <br />

                {graphData && !isLoadingGraphData ? (
                  <>
                    {graphData.length < 2 ? (
                      <h5 style={{ textAlign: 'center' }}>
                        Sorry, not enough data to graph
                      </h5>
                    ) : (
                      <>
                        <Row
                          style={{
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          }}
                        >
                          <Col
                            style={{
                              margin: '0 var(--space-md)',
                              padding: 'var(--space-xxxs) var(--space-sm)',
                              borderLeft: '5px solid var(--color-primary)',
                            }}
                          >
                            <CustomSelect
                              onChange={opt => {
                                setGraphSelection(opt);
                                dispatch(
                                  getGraphData(
                                    currentUrlParams,
                                    opt.value,
                                    graphInterval,
                                    graphExtraFilters,
                                    graphExtraFiltersSlug,
                                    1
                                  )
                                );
                              }}
                              value={graphSelection}
                              options={[
                                { value: 'roi', label: 'ROI' },
                                { value: 'clv', label: 'CLV' },
                                { value: 'net_profit', label: 'Net Profit' },
                                {
                                  value: 'risk_amount',
                                  label: 'Risk Amount',
                                },
                              ]}
                            />
                          </Col>
                          <Col
                            style={{
                              margin: '0 var(--space-md)',
                              padding: 'var(--space-xxxs) var(--space-sm)',
                              borderLeft: '5px solid var(--color-complement)',
                            }}
                          >
                            <CustomSelect
                              onChange={opt => {
                                setGraphSelection2(opt);
                                dispatch(
                                  getGraphData(
                                    currentUrlParams,
                                    opt.value,
                                    graphInterval,
                                    graphExtraFilters,
                                    graphExtraFiltersSlug,
                                    2
                                  )
                                );
                              }}
                              value={graphSelection2}
                              options={[
                                { value: 'roi', label: 'ROI' },
                                { value: 'clv', label: 'CLV' },
                                { value: 'net_profit', label: 'Net Profit' },
                                {
                                  value: 'risk_amount',
                                  label: 'Risk Amount',
                                },
                              ]}
                            />
                          </Col>
                          <Col style={{ alignItems: 'flex-start' }}>
                            {Boolean(graphExtraFilters) && (
                              <h5 style={{ margin: 0, fontWeight: 'bold' }}>
                                {graphExtraFiltersSlug}
                              </h5>
                            )}
                          </Col>

                          {/* <Row
                        style={{
                          maxWidth: '300px',
                          alignItems: 'center',
                          flexFlow: 'row nowrap',
                        }}
                      >
                        <IntervalBtn
                          selected={graphInterval === 'day'}
                          onClick={() => _changeInterval('day')}
                        >
                          Day
                        </IntervalBtn>
                        <IntervalBtn
                          selected={graphInterval === 'week'}
                          onClick={() => _changeInterval('week')}
                        >
                          Week
                        </IntervalBtn>
                        <IntervalBtn
                          selected={graphInterval === 'month'}
                          onClick={() => _changeInterval('month')}
                        >
                          Month
                        </IntervalBtn>
                      </Row> */}
                        </Row>
                        <Row>
                          <Col style={{ minHeight: '370px' }}>
                            <Graph
                              type={'area'}
                              data={graphData}
                              dataSymbol={symbolMap[graphSelection.value]}
                              data2={graphData2}
                              data2Symbol={symbolMap[graphSelection2.value]}
                            />
                          </Col>
                        </Row>
                      </>
                    )}
                  </>
                ) : (
                  <ContentLoader height={'370px'} speed={'750ms'} />
                )}

                <br />

                <h3>
                  {currentAnalysis?.user?.username
                    ? `${currentAnalysis.user.username}'s `
                    : ``}
                  Analysis{' '}
                  {filters.Time_Period.value &&
                  filters.Time_Period.value !== 'null'
                    ? ` - ${
                        filters.Time_Period.value > 0 ? 'Last' : 'Next'
                      } ${Math.abs(filters.Time_Period.value)} days`
                    : ''}
                </h3>
                <SortableTable
                  rows={currentAnalysis.rows}
                  onClickRow={row => {
                    document
                      .getElementById(`analysis-div-wrapper`)
                      .scrollIntoView({ behavior: 'smooth' });
                    dispatch(
                      getGraphData(
                        currentUrlParams,
                        graphSelection.value,
                        graphInterval,
                        row.urlFilter,
                        row.urlFilterSlug,
                        1
                      )
                    );
                    if (graphData2 && graphSelection2.value) {
                      dispatch(
                        getGraphData(
                          currentUrlParams,
                          graphSelection2.value,
                          graphInterval,
                          row.urlFilter,
                          row.urlFilterSlug,
                          2
                        )
                      );
                    }
                  }}
                  onUnClickRow={() => {
                    dispatch(
                      getGraphData(
                        currentUrlParams,
                        graphSelection.value,
                        graphInterval,
                        null,
                        null,
                        1
                      )
                    );
                    if (graphData2 && graphSelection2.value) {
                      dispatch(
                        getGraphData(
                          currentUrlParams,
                          graphSelection2.value,
                          graphInterval,
                          null,
                          null,
                          2
                        )
                      );
                    }
                  }}
                />
              </div>
            </>
          ) : (
            <Col
              style={{
                justifyContent: isLoading ? 'center' : 'flex-start',
                height: '100%',
              }}
            >
              {isLoading ? (
                <>
                  <div>
                    <ActivityIndicator size={2} />
                  </div>
                  <h6>Crunching the numbers ...</h6>
                </>
              ) : (
                <>
                  <br />
                  <br />
                  {fetchError && (
                    <Col style={{ flex: 0 }}>
                      <ErrorDisplay error={fetchError} />
                      <p>Try loading a preset below</p>
                    </Col>
                  )}
                  <Row style={{ flex: 0, alignItems: 'center' }}>
                    <Col style={{ flex: 0 }}>
                      <img
                        src={AnalysisLogo}
                        style={{
                          height: '128px',
                          width: 'auto',
                          margin: '0 auto',
                        }}
                        alt={'betstamp marketplace'}
                      />
                    </Col>
                    <Col>
                      <h4>
                        POWERFUL ANALYSIS TOOL USED BY PROFESSIONAL BETTORS{' '}
                        <br />
                        <small>Analyze, Learn, Improve</small>
                      </h4>
                    </Col>
                  </Row>

                  <br />

                  <h6>
                    Select a default analysis below or create your own using the
                    toolbar on the right to start analyzing your bets.
                  </h6>
                  {/* <p>
                    Not sure how it works? Check out the{' '}
                    <Link to="/tutorial-analysis">tutorial</Link>
                  </p> */}

                  <br />
                  <Grid
                    style={{
                      width: '100%',
                      gridTemplateColumns:
                        'repeat(auto-fit, minmax(310px, 1fr))',
                    }}
                  >
                    {isLoadingPresets &&
                      [1, 2, 3, 4].map(o => (
                        <ContentLoader
                          key={`table-home-preset-content-loader-${o}`}
                          height={25}
                        />
                      ))}
                    {presets.map((preset, index) => (
                      <PresetBtn
                        key={`desktop-table-home-preset-load-list-${index}`}
                        preset={preset}
                        index={index}
                      />
                    ))}
                    {presetsNext && (
                      <AuthButton
                        containerStyle={{ display: 'flex' }}
                        btnTheme="borderless"
                        onPress={() => dispatch(getAnalysisPresetsNext())}
                      >
                        Load More
                      </AuthButton>
                    )}
                  </Grid>
                </>
              )}
            </Col>
          )}
        </div>
      </InnerMainArea>

      <AnalysisSidebar />
    </MainAreaWrapper>
  );
}
