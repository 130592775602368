import { Observable } from 'rxjs';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import { ajax } from 'rxjs/observable/dom/ajax';
import { BACKEND_API_URL, getHeaders } from 'utils';

import { GET_WAGERS, getWagersSuccess, getWagersFailure } from 'actions';

export default function getWagers(action$, state$) {
  return action$.ofType(GET_WAGERS).mergeMap(action => {
    let url = `${BACKEND_API_URL}api/wagers/?`;

    if (action.date_after !== null && action.date_after !== undefined) {
      url += `date_after=${action.date_after.toISOString()}&`;
    }

    if (action.date_before !== null && action.date_before !== undefined) {
      url += `date_before=${action.date_before.toISOString()}&`;
    }

    if (action.is_verified !== null && action.is_verified !== undefined) {
      if (action.is_verified) {
        url += `is_verified=True&`;
      } else {
        url += `is_verified=False&`;
      }
    }

    if (action.is_graded !== null && action.is_graded !== undefined) {
      if (action.is_graded) {
        url += `is_graded=True&`;
      } else {
        url += `is_graded=False&`;
      }
    }

    if (action.exclude_types !== null && action.exclude_types !== undefined) {
      url += `exclude_types=${action.exclude_types}&`;
    }

    if (action.book !== null && action.book !== undefined) {
      url += `book=${action.book.replace('+', '%2B')}&`;
    }

    if (action.user !== null && action.user !== undefined) {
      url += `user=${action.user}`;
    }

    if (action.screen === 'todaysAction') {
      url += `todaysAction=True&`;
    }

    if (action.show_only_hidden) {
      url += `show_only_hidden=True&`;
    }

    if (action.game_id) {
      url += `game_id=${action.game_id}`;
    }

    return ajax
      .getJSON(url, getHeaders(state$))
      .map(data => getWagersSuccess(data, action.screen))
      .catch(error =>
        Observable.of(getWagersFailure(error.xhr, action.screen))
      );
  });
}
