import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// components
import Select from 'components/generic/Select';
// import Select from 'react-select';
import ActivityIndicator from 'components/generic/ActivityIndicator';

// actions
import { getAllBooks, getAllBooksMap } from 'actions';

export default function BooksDropdown(props) {
  const dispatch = useDispatch();
  const [uniqueBooks, setUniqueBooks] = useState([]);

  const reduxProps = useSelector(state => ({
    user: state.authReducer.user,
    allBooks: state.authReducer.allBooks,
    allBooksMap: state.authReducer.allBooksMap,
  }));

  const { allBooks, user, allBooksMap } = reduxProps;
  const { admin } = props;

  useEffect(() => {
    if (allBooks.length === 0) {
      dispatch(getAllBooks());
    }
    dispatch(getAllBooksMap());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (admin) {
      setUniqueBooks(
        Object.keys(allBooksMap)
          .map(key => ({
            value: key,
            label: allBooksMap[key]?.name,
          }))
          .sort((a, b) => {
            if (a.label > b.label) return 1;
            if (a.label < b.label) return -1;
            return 0;
          })
      );
    } else if (allBooks && allBooks.length > 0 && user && user.books) {
      let ub = Array.from(
        user.books.map(book => ({ value: book.id, label: book.name }))
      );
      allBooks.forEach(book => {
        if (!user.books.find(b => b.id === book.id)) {
          ub.push({ value: book.id, label: book.name });
        }
      });
      setUniqueBooks(ub);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allBooks, allBooksMap, user]);

  // hard-coding 38px because `Select` element below has a size of 38
  if (uniqueBooks.length === 0) return <ActivityIndicator width={38} />;

  let defaultValue = props.defaultValue;

  if (defaultValue && !defaultValue.label && defaultValue.value) {
    defaultValue.label = allBooks.find(b => b.id === defaultValue.value)?.name;
  }

  return (
    <Select
      {...props}
      defaultValue={defaultValue}
      options={
        props.additionalOptions
          ? props.additionalOptions.concat(uniqueBooks)
          : uniqueBooks
      }
    />
  );
}
