import { Observable } from 'rxjs';
import 'rxjs/add/operator/exhaustMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import { ajax } from 'rxjs/observable/dom/ajax';
import { BACKEND_API_URL, getHeaders } from 'utils';

import {
  CREATE_ANALYSIS_PRESET,
  createAnalysisPresetSuccess,
  createAnalysisPresetFailure,
} from 'actions';

export default function createPreset(action$, state$) {
  return action$.ofType(CREATE_ANALYSIS_PRESET).exhaustMap(action => {
    return ajax
      .post(
        BACKEND_API_URL + 'api/analysispresets/',
        action.preset,
        getHeaders(state$)
      )
      .map(data => createAnalysisPresetSuccess(data))
      .catch(error => Observable.of(createAnalysisPresetFailure(error.xhr)));
  });
}
