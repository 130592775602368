import React from 'react';

// utils
import { STRIPE } from 'utils';

// stripe
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

export default function StripeSetup(props) {
  const { useConnectAccount, connectID } = props;

  let stripePromise = null;

  if (useConnectAccount) {
    stripePromise = loadStripe(STRIPE.PUBLIC_KEY, {
      stripeAccount: connectID,
    });
  } else if (!useConnectAccount) {
    stripePromise = loadStripe(STRIPE.PUBLIC_KEY);
  }

  return <Elements stripe={stripePromise}>{props.children}</Elements>;
}
