import React from 'react';

// components
import Select from 'react-select';

export default function CustomSelect(props) {
  const { value, onChange, options, placeholder } = props;
  const styles = {
    container: base => ({
      ...base,
      width: props.width ? props.width : '100%',
    }),
    menuList: base => ({ ...base, zIndex: 100 }),
    control: base => ({
      ...base,
      borderRadius: 'var(--std-border-radius)',
    }),
    ...props.styles,
    option: (base, state) => ({
      ...base,
      cursor: 'pointer',
      color: state.isFocused ? 'white' : 'var(--color-text)',
    }),
    multiValue: (styles, { data }) => ({
      ...styles,
      backgroundColor: 'var(--color-bg)',
    }),
  };

  return (
    <>
      {props.label && (
        <span
          style={{
            display: 'block',
            marginTop: 'var(--space-sm)',
            padding: '0 var(--space-sm)',
            width: '100%',
            ...props.labelStyle,
          }}
        >
          {props.label}
        </span>
      )}
      <Select
        placeholder={placeholder ? placeholder : `Select...`}
        {...props}
        styles={styles}
        value={value}
        onChange={onChange}
        options={options}
        theme={theme => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary: 'var(--color-primary)',
            primary25: 'hsl(203, 68%, 25%)',
            primary50: 'hsl(203, 68%, 50%)',
            primary75: 'hsl(203, 68%, 75%)',
            neutral0: 'var(--color-fg)',
            neutral5: 'var(--color-text)',
            neutral10: 'var(--color-text)',
            neutral20: 'var(--color-text)',
            neutral30: 'var(--color-text)',
            neutral40: 'var(--color-text)',
            neutral50: 'var(--color-text)',
            neutral60: 'var(--color-text)',
            neutral70: 'var(--color-text)',
            neutral80: 'var(--color-text)',
            neutral90: 'var(--color-text)',
          },
        })}
      />
    </>
  );
}
