// affiliates on the accounting screen
export const GET_AFFILIATES = 'GET_AFFILIATES';
export const GET_SPORTSBOOKS = 'GET_SPORTSBOOKS';
export const SET_SELECTED_STATE_AFF = 'SET_SELECTED_STATE_AFF';
export const GET_SPORTSBOOK_REVIEW = 'GET_SPORTSBOOK_REVIEW';
export const GET_SPORTSBOOK_INFO_MAP = 'GET_SPORTSBOOK_INFO_MAP';

export const GET_AFFILIATES_SUCCESS = 'GET_AFFILIATES_SUCCESS';
export const GET_AFFILIATES_FAILURE = 'GET_AFFILIATES_FAILURE';

export const getAffiliates = (refCode, promoPage, state, linkType) => ({
  type: GET_AFFILIATES,
  refCode,
  promoPage,
  state,
  linkType,
});

export const getAffiliatesSuccess = affiliates => ({
  type: GET_AFFILIATES_SUCCESS,
  payload: affiliates,
});

export const getAffiliatesFailure = error => ({
  type: GET_AFFILIATES_FAILURE,
  error,
});

export const GET_SPORTSBOOKS_SUCCESS = 'GET_SPORTSBOOKS_SUCCESS';
export const GET_SPORTSBOOKS_FAILURE = 'GET_SPORTSBOOKS_FAILURE';

export const getSportsbooks = (states, is_affiliate) => ({
  type: GET_SPORTSBOOKS,
  states,
  is_affiliate,
});

export const getSportsbooksSuccess = sportsbooks => ({
  type: GET_SPORTSBOOKS_SUCCESS,
  payload: sportsbooks,
});

export const getSportsbooksFailure = error => ({
  type: GET_SPORTSBOOKS_FAILURE,
  error,
});

export const setSelectedStateAff = state => ({
  type: SET_SELECTED_STATE_AFF,
  state,
});

export const GET_SPORTSBOOK_REVIEW_SUCCESS = 'GET_SPORTSBOOK_REVIEW_SUCCESS';
export const GET_SPORTSBOOK_REVIEW_FAILURE = 'GET_SPORTSBOOK_REVIEW_FAILURE';

export const getSportsbookReview = slug => ({
  type: GET_SPORTSBOOK_REVIEW,
  slug,
});

export const getSportsbookReviewSuccess = payload => ({
  type: GET_SPORTSBOOK_REVIEW_SUCCESS,
  payload,
});

export const getSportsbookReviewFailure = error => ({
  type: GET_SPORTSBOOK_REVIEW_FAILURE,
  error,
});

// GET_SPORTSBOOK_INFOS
export const GET_SPORTSBOOK_INFO_MAP_SUCCESS =
  'GET_SPORTSBOOK_INFO_MAP_SUCCESS';
export const GET_SPORTSBOOK_INFO_MAP_FAILURE =
  'GET_SPORTSBOOK_INFO_MAP_FAILURE';

export const getSportsbookInfoMap = region => ({
  type: GET_SPORTSBOOK_INFO_MAP,
  region: region,
});

export const getSportsbookInfoMapSuccess = infos => ({
  type: GET_SPORTSBOOK_INFO_MAP_SUCCESS,
  payload: infos,
});

export const getSportsbookInfoMapFailure = error => ({
  type: GET_SPORTSBOOK_INFO_MAP_FAILURE,
  error,
});
