export const GET_SURVEYS = 'GET_SURVEYS';
export const GET_SURVEYS_SUCCESS = 'GET_SURVEYS_SUCCESS';
export const GET_SURVEYS_FAILURE = 'GET_SURVEYS_FAILURE';
export const GET_SURVEYS_NEXT = 'GET_SURVEYS_NEXT';

export const GET_SURVEY = 'GET_SURVEY';
export const GET_SURVEY_SUCCESS = 'GET_SURVEY_SUCCESS';
export const GET_SURVEY_FAILURE = 'GET_SURVEY_FAILURE';

export const getSurveys = () => ({
  type: GET_SURVEYS,
});
export const getSurveysNext = () => ({
  type: GET_SURVEYS_NEXT,
});
export const getSurveysSuccess = (payload) => {
  return {
    type: GET_SURVEYS_SUCCESS,
    payload,
  };
};
export const getSurveysFailure = (error) => ({
  type: GET_SURVEYS_FAILURE,
  error,
});

export const getSurvey = (survey_id) => ({
  type: GET_SURVEY,
  survey_id,
});
export const getSurveySuccess = (payload) => {
  return {
    type: GET_SURVEY_SUCCESS,
    payload,
  };
};
export const getSurveyFailure = (error) => ({
  type: GET_SURVEY_FAILURE,
  error,
});

export const POST_SURVEY_RESULTS = 'POST_SURVEY_RESULTS';
export const POST_SURVEY_RESULTS_SUCCESS = 'POST_SURVEY_RESULTS_SUCCESS';
export const POST_SURVEY_RESULTS_FAILURE = 'POST_SURVEY_RESULTS_FAILURE';

export const postSurveyResults = (answers, survey) => {
  return {
    type: POST_SURVEY_RESULTS,
    answers,
    survey,
  };
};

export const postSurveyResultsSuccess = (payload) => {
  return {
    type: POST_SURVEY_RESULTS_SUCCESS,
    payload,
  };
};

export const postSurveyResultsFailure = (error) => ({
  type: POST_SURVEY_RESULTS_FAILURE,
  error,
});
