import { Observable } from 'rxjs';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import { ajax } from 'rxjs/observable/dom/ajax';
import { BACKEND_API_URL, getHeaders } from 'utils';

import { DELETE_WAGER, deleteWagerSuccess, deleteWagerFailure } from 'actions';

export default function deleteWager(action$, state$) {
  return action$.ofType(DELETE_WAGER).exhaustMap(action => {
    let url = `${BACKEND_API_URL}api/wagers/${action.wager_id}/`;

    return ajax
      .delete(url, getHeaders(state$))
      .map(data => deleteWagerSuccess(data.response, action.wager_id))
      .catch(error =>
        Observable.of(deleteWagerFailure(error.xhr, action.wager_id))
      );
  });
}
