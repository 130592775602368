import { Observable } from 'rxjs';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import { ajax } from 'rxjs/observable/dom/ajax';
import { BACKEND_API_URL, getHeaders } from 'utils';

import {
  GET_PLAYER_PROP_BA,
  getPlayerPropBASuccess,
  getPlayerPropBAFailure,
} from 'actions';

export default function getPlayerPropBA(action$, state$) {
  return action$.ofType(GET_PLAYER_PROP_BA).switchMap(action => {
    let url =
      BACKEND_API_URL +
      `api/best_available/?type_name=playerprop&player_name=${
        action.player_name
      }&prop_type=${action.prop_type.split('+').join('%2b')}&game_id=${
        action.game_id
      }&period=FT`;

    return ajax
      .getJSON(url, getHeaders(state$))
      .map(data => getPlayerPropBASuccess(data))
      .catch(error => Observable.of(getPlayerPropBAFailure(error.xhr)));
  });
}
