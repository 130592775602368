import ActivityIndicator from 'components/generic/ActivityIndicator';
import React, { useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';

import { Col } from 'components/generic/Layout';

export const __DEV__ = process.env.NODE_ENV !== 'production';

export default function SocialCallback() {
  const params = useParams();
  const location = useLocation();
  useEffect(() => {
    if (params.provider && location.search.includes('oauth_token')) {
      const searchParams = new URLSearchParams(location.search);
      // put the return tokens in the global window object so the
      // TwitterAuthButton can grab them and login
      if (params.provider === 'twitter') {
        // example return of twitter auth
        // https://auth.expo.io/@packerju/betstamp?oauth_token=SOME-TOKEN&oauth_verifier=SOME-VERIFIER
        window.opener.Betstamp = {
          provider: params.provider,
          oauth_token: searchParams.get('oauth_token'),
          oauth_verifier: searchParams.get('oauth_verifier'),
        };
      }
      // we're using a package for google and apple so we don't need them here
    }
  }, [params, location]);

  return (
    <>
      <Col style={{ height: '100vh', justifyContent: 'center' }}>
        <div>
          <ActivityIndicator size={3} />
        </div>
        <br />
        <h2 style={{ margin: 0, textAlign: 'center' }}>
          Connecting to your{' '}
          {params.provider[0].toUpperCase() + params.provider.slice(1)} account
        </h2>
        <div style={{ height: '256px' }} />
      </Col>
    </>
  );
}
