import React from 'react';
import { AuthButton } from 'components/AuthButton';
export default function SignupFooter(props) {
  const { onPress, footerStyle } = props;
  return (
    <AuthButton
      containerStyle={{ width: '50%', ...footerStyle }}
      btnTheme="borderless"
      onPress={onPress}
    >
      <span
        style={{ color: 'var(--color-text)', marginRight: 'var(--space-xs)' }}
      >
        Already registered?
      </span>
      Login
    </AuthButton>
  );
}
