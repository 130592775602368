import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TwitterLogo from 'images/twitter-logo.svg';

// componenets
import * as AuthButton from './AuthButton';

// actions
import {
  socialLoginUser,
  socialConnectUser,
  getTwitterRequestToken,
  getTwitterAccessToken,
} from 'actions';

export default function TwitterAuthButton(props) {
  const dispatch = useDispatch();
  const reduxProps = useSelector(state => ({
    isGettingTwitterRequestToken:
      state.authReducer.isGettingTwitterRequestToken,
    twitterRequestToken: state.authReducer.twitterRequestToken,
    twitterRequestTokenError: state.authReducer.twitterRequestTokenError,
    isGettingTwitterAccessToken: state.authReducer.isGettingTwitterAccessToken,
    twitterAccessToken: state.authReducer.twitterAccessToken,
    twitterAccessTokenError: state.authReducer.twitterAccessTokenError,
    theme: state.settingsReducer.theme,
  }));
  const {
    isGettingTwitterRequestToken,
    twitterRequestToken,
    isGettingTwitterAccessToken,
    twitterAccessToken,
    theme,
  } = reduxProps;

  const [popup, setPopup] = useState(null);
  const [oAuthValues, setOAuthValues] = useState(null);

  useEffect(() => {
    if (popup) {
      const int = setInterval(() => {
        if (window && window.hasOwnProperty('Betstamp')) {
          if (
            window.Betstamp.hasOwnProperty('oauth_token') &&
            window.Betstamp.hasOwnProperty('oauth_verifier')
          ) {
            setOAuthValues({
              oauth_token: window.Betstamp.oauth_token,
              oauth_verifier: window.Betstamp.oauth_verifier,
            });
            popup.close();
            setPopup(null);
          }
        }
        if (popup.closed) {
          setPopup(null);
        }
      }, 3000);
      return () => clearInterval(int);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [popup]);

  useEffect(() => {
    if (twitterRequestToken) {
      // popup/redirect twitter
      const url = `https://api.twitter.com/oauth/authenticate?force_login=true&oauth_token=${twitterRequestToken.oauth_token}`;
      const title = 'Continue With Twitter';
      const w = 500;
      const h = 500;
      const win = window;
      const y = win.top.outerHeight / 3 + win.top.screenY - h / 2;
      const x = win.top.outerWidth / 3 + win.top.screenX - w / 2;
      const popup = win.open(
        url,
        title,
        `toolbar=no, location=no, directories=no, status=no, menubar=no, copyhistory=no, width=${w}, height=${h}, top=${y}, left=${x}`
      );
      setPopup(popup);
    }
  }, [twitterRequestToken]);

  useEffect(() => {
    if (oAuthValues && twitterRequestToken) {
      dispatch(
        getTwitterAccessToken(
          twitterRequestToken.oauth_token,
          twitterRequestToken.oauth_token_secret,
          oAuthValues.oauth_verifier
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oAuthValues, twitterRequestToken]);

  useEffect(() => {
    if (twitterAccessToken) {
      if (props.signup || props.login) {
        dispatch(
          socialLoginUser(
            'twitter',
            twitterAccessToken.oauth_token,
            twitterAccessToken.oauth_token_secret
          )
        );
      } else if (props.connectExisting) {
        dispatch(
          socialConnectUser(
            'twitter',
            twitterAccessToken.oauth_token,
            twitterAccessToken.oauth_token_secret
          )
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [twitterAccessToken]);

  return (
    <AuthButton.AuthButton
      type="button"
      btnTheme="borderless"
      colorTheme="text"
      containerStyle={
        props.signup
          ? {
            width: '50%',
            minWidth: '250px',
          }
          : {}
      }
      btnStyle={{ backgroundColor: 'var(--color-fg)' }}
      textStyle={{
        color: theme === 'dark' ? 'var(--color-text)' : '#1DA1F2',
        flex: 0,
        minWidth: 'fit-content',
        marginLeft: 'var(--space-sm)',
      }}
      leftIcon={TwitterLogoComp}
      leftIconColor="white"
      isLoading={isGettingTwitterRequestToken || isGettingTwitterAccessToken}
      onPress={() => dispatch(getTwitterRequestToken('web'))}
    >
      Continue With Twitter
    </AuthButton.AuthButton>
  );
}

const TwitterLogoComp = props => (
  <img src={TwitterLogo} {...props} height={32} alt="twitter logo" />
);
