export default {
  link_to_video: '',
  content: [
    { type: 'heading', size: 3, text: 'Tutorial Coming Soon!' },
    {
      type: 'paragraph',
      text:
        "We know you're excited to go through this tutorial. It should be up in a couple days, check back later!",
    },
  ],
};
