import { Observable } from 'rxjs';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import { ajax } from 'rxjs/observable/dom/ajax';
import { BACKEND_API_URL, getHeaders } from 'utils';

import { GET_ODDSCOMP, getOddscompSuccess, getOddscompFailure } from 'actions';

export default function getOddscomp(action$, state$) {
  return action$.ofType(GET_ODDSCOMP).switchMap(action => {
    let url = BACKEND_API_URL + `api/odds_comp/?`;

    if (action.game_ids) url += `game_ids=${JSON.stringify(action.game_ids)}&`;

    if (action.book_ids) url += `book_ids=${JSON.stringify(action.book_ids)}&`;

    if (action.period) url += `period=${action.period}&`;

    if (action.since) url += `since=${action.since.toISOString()}&`;

    return ajax
      .getJSON(url, getHeaders(state$))
      .map(data => getOddscompSuccess(data))
      .catch(error => Observable.of(getOddscompFailure(error.xhr)));
  });
}
