import { Observable } from 'rxjs';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import { ajax } from 'rxjs/observable/dom/ajax';
import { BACKEND_API_URL, getHeaders } from 'utils';

import {
  GET_FUTURES_SCHED,
  getFuturesSchedSuccess,
  getFuturesSchedFailure,
} from 'actions';

export default function getFuturesSched(action$, state$) {
  return action$.ofType(GET_FUTURES_SCHED).switchMap(action => {
    let url = BACKEND_API_URL + `api/futures_sched/?`;

    if (action.leagues) url += `&leagues=${JSON.stringify(action.leagues)}`;
    if (action.season) url += `&season=${action.season}`;

    if (action.hasNotStarted !== undefined) {
      url += `&has_not_started=${action.hasNotStarted}`;
    }
    if (action.inProgress !== undefined) {
      url += `&in_progress=${action.inProgress}`;
    }
    if (action.seasonComplete !== undefined) {
      url += `&season_complete=${action.seasonComplete}`;
    }

    return ajax
      .getJSON(url, getHeaders(state$))
      .map(data => getFuturesSchedSuccess(data))
      .catch(error => Observable.of(getFuturesSchedFailure(error.xhr)));
  });
}
