import React from 'react';

const styles = {
  badge: {
    borderRadius: 'var(--std-border-radius)',
    border: '1px solid var(--color-primary)',
    margin: 0,
    padding: 'var(--space-xxxxs) var(--space-sm)',
    fontWeight: 'bold',
    flex: 0,
    whiteSpace: 'nowrap',
  },
};

export default function Badge(props) {
  if (props.large) {
    return (
      <p style={{ ...styles.badge, ...props.style }} title={props.title}>
        {props.text}
      </p>
    );
  }

  return (
    <small style={{ ...styles.badge, ...props.style }} title={props.title}>
      {props.text}
    </small>
  );
}
