import { useGoogleLogin } from '@react-oauth/google';
import { useDispatch, useSelector } from 'react-redux';

// components
import { AuthButton } from './AuthButton';
import GoogleLogo from 'images/google-logo.svg';

// actions
import { socialLoginUser, socialConnectUser } from 'actions';

export default function GoogleAuthButton(props) {
  const dispatch = useDispatch();
  const reduxProps = useSelector(state => ({
    theme: state.settingsReducer.theme,
  }));
  const { theme } = reduxProps;

  const login = useGoogleLogin({
    onSuccess: codeResponse => {
      if (codeResponse.code) {
        if (props.signup || props.login) {
          dispatch(socialLoginUser('googlenewweb', codeResponse.code));
        } else if (props.connectExisting) {
          dispatch(socialConnectUser('googlenewweb', codeResponse.code));
        }
      }
    },
    flow: 'auth-code',
  });

  return (
    <AuthButton
      type="button"
      btnTheme="borderless"
      colorTheme="text"
      containerStyle={
        props.signup
          ? {
            width: '50%',
            minWidth: '250px',
          }
          : {}
      }
      btnStyle={{ backgroundColor: 'var(--color-fg)' }}
      textStyle={{
        color: theme === 'dark' ? 'var(--color-text)' : '#757575',
        flex: 0,
        minWidth: 'fit-content',
        marginLeft: 'var(--space-sm)',
      }}
      leftIcon={GoogleLogoComp}
      onPress={login}
    >
      Continue With Google
    </AuthButton>
  );
}
const GoogleLogoComp = props => (
  <img src={GoogleLogo} {...props} height={32} alt="google logo" />
);

// OLD
//import React from 'react';
//import { useDispatch, useSelector } from 'react-redux';
//import { GoogleLogin } from '@leecheuk/react-google-login';
//import GoogleLogo from 'images/google-logo.svg';
//
//import { AuthButton } from './AuthButton';
//
//// actions
//import { socialLoginUser, socialConnectUser } from 'actions';
//
//export default function GoogleAuthButton(props) {
//  const dispatch = useDispatch();
//  const reduxProps = useSelector(state => ({
//    theme: state.settingsReducer.theme,
//  }));
//  const { theme } = reduxProps;
//  const responseGoogle = response => {
//    console.log(response);
//    if (response.error) {
//    } else {
//      if (response.code) {
//        if (props.signup || props.login) {
//          dispatch(socialLoginUser('google', response.code));
//        } else if (props.connectExisting) {
//          dispatch(socialConnectUser('google', response.accessToken));
//        }
//      }
//    }
//  };
//
//  return (
//    <GoogleLogin
//      clientId="684605109812-lvpiej86e0em46osn5kcugqrp39tnr8o.apps.googleusercontent.com"
//      buttonText="Continue With Google"
//      onSuccess={responseGoogle}
//      onFailure={responseGoogle}
//      cookiePolicy={'single_host_origin'}
//      accessType="offline"
//      responseType="code"
//      render={renderProps => (
//        <AuthButton
//          type="button"
//          btnTheme="borderless"
//          colorTheme="text"
//          containerStyle={
//            props.signup
//              ? {
//                width: '50%',
//                minWidth: '250px',
//              }
//              : {}
//          }
//          btnStyle={{ backgroundColor: 'var(--color-fg)' }}
//          textStyle={{
//            color: theme === 'dark' ? 'var(--color-text)' : '#757575',
//            flex: 0,
//            minWidth: 'fit-content',
//            marginLeft: 'var(--space-sm)',
//          }}
//          leftIcon={GoogleLogoComp}
//          onPress={renderProps.onClick}
//          disabled={renderProps.disabled}
//        >
//          Continue With Google
//        </AuthButton>
//      )}
//    />
//  );
//}
//
//const GoogleLogoComp = props => (
//  <img src={GoogleLogo} {...props} height={32} alt="google logo" />
//);
