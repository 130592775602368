import { combineEpics } from 'redux-observable';

import createUser from 'epics/auth/createUser.epic.js';
import changePassword from 'epics/auth/changePassword.epic.js';
import forgotPassword from 'epics/auth/forgotPassword.epic.js';
import loginUser from 'epics/auth/loginUser.epic.js';
import socialLoginUser from 'epics/auth/socialLoginUser.epic.js';
import socialConnectUser from 'epics/auth/socialConnectUser.epic.js';
import socialDisconnectUser from 'epics/auth/socialDisconnectUser.epic.js';
import logoutUser from 'epics/auth/logoutUser.epic.js';
import logoutAll from 'epics/auth/logoutAll.epic.js';
import sendEmailVerification from 'epics/auth/sendEmailVerification.epic.js';
import getUser from 'epics/auth/getUser.epic.js';
import updateUser from 'epics/auth/updateUser.epic.js';
import updateNotificationPreferences from 'epics/auth/updateNotificationPreferences.epic.js';
import addPushToken from 'epics/auth/addPushToken.epic.js';
import addPackage from 'epics/auth/addPackage.epic.js';
import checkEmailAvailable from 'epics/auth/checkEmailAvailable.epic.js';
import checkUsernameAvailable from 'epics/auth/checkUsernameAvailable.epic.js';
import deletePackage from 'epics/auth/deletePackage.epic.js';
import updatePackage from 'epics/auth/updatePackage.epic.js';
import getAllBooks from 'epics/auth/getAllBooks.epic.js';
import postBooks from 'epics/auth/postBooks.epic.js';
import createConnectState from 'epics/auth/createConnectState.epic.js';
import verifyConnectAccount from 'epics/auth/verifyConnectAccount.epic.js';
import deactivateUser from 'epics/auth/deactivateUser.epic.js';
import getExpressDashboardURL from 'epics/auth/getExpressDashboardURL.epic';
import checkReferralCodeExists from 'epics/auth/checkReferralCodeExists.epic';
import changeEmail from 'epics/auth//changeEmail.epic';
import getTwitterRequestToken from 'epics/auth/getTwitterRequestToken.epic';
import getTwitterAccessToken from 'epics/auth/getTwitterAccessToken.epic';
import getAllBooksMap from 'epics/auth/getAllBooksMap.epic';

export default combineEpics(
  createUser,
  changePassword,
  forgotPassword,
  loginUser,
  logoutUser,
  logoutAll,
  sendEmailVerification,
  deactivateUser,
  getUser,
  updateUser,
  updateNotificationPreferences,
  addPushToken,
  addPackage,
  checkEmailAvailable,
  checkUsernameAvailable,
  deletePackage,
  updatePackage,
  getAllBooks,
  postBooks,
  createConnectState,
  verifyConnectAccount,
  getExpressDashboardURL,
  checkReferralCodeExists,
  changeEmail,
  socialLoginUser,
  socialConnectUser,
  socialDisconnectUser,
  getTwitterRequestToken,
  getTwitterAccessToken,
  getAllBooksMap
);
