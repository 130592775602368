import React, { useState, useMemo } from 'react';
import styled from 'styled-components';
import { IoClose, IoFilter, IoSearch } from 'react-icons/io5';
import { useQuery } from '@tanstack/react-query';
import { useLocation, useHistory } from 'react-router-dom';

// utils
import {
  humanDate,
  formatMoney,
  MONTHS,
  PROVINCE_OPTIONS,
  US_STATES,
} from 'utils';

// components
import { Row, Col } from 'components/generic/Layout';
import { AuthButton, IconButton } from 'components/AuthButton';
import GenericAdminFilter from 'components/onboarding/GenericAdminFilter';
import ActivityIndicator from 'components/generic/ActivityIndicator';
import { Table, Thead, Th, Tbody } from 'components/generic/Tables';
import StaffNavBar from 'components/nav/StaffNavBar';
import { AuthTextInput } from 'components/AuthTextInput';

const formatOneDecimal = new Intl.NumberFormat('en', {
  minimumIntegerDigits: 1,
  minimumFractionDigits: 1,
});

const SUPPORTED_GROUPINGS = [
  'region',
  'book',
  'referral_code',
  'source',
  'marketing_group',
];

const SUPPORTED_GROUP_KEYS = {
  region: true,
  book: true,
  referral_code: true,
  source: true,
  marketing_group: true,
};

const SUPPORTED_VALUE_KEYS = {
  sum_target: true,
  num_attendees: true,
  num_fully_complete: true,
  sum_returned_payments: true,
  sum_bonus_payments: true,
  sum_initial_payments: true,
  sum_amount_used: true,
  portal_signups: true,
  portal_cpa: true,
  balance: true,
};

const PanelWrapper = styled.div`
  flex: 1.5;
  border-left: 1px solid var(--color-text-light);
  height: calc(100vh - 64px);
  @media only screen and (max-width: 1266px) {
    flex: 2;
  }
  @media only screen and (max-width: 625px) {
    position: absolute;
    background-color: var(--color-bg);
    width: 100vw;
    z-index: 99999999;
    top: 0;
  }
`;

function parseDataToMetrics(data, depth, groupings, fromKeys) {
  let rows = [];
  let row = [...fromKeys];
  if (row.length < groupings.length) {
    for (let i = 0; i <= groupings.length - row.length; i++) {
      row.push('');
    }
  }
  for (const [key, value] of Object.entries(data)) {
    if (SUPPORTED_VALUE_KEYS[key]) {
      if (
        key === 'sum_amount_used' ||
        key === 'sum_bonus_payments' ||
        key === 'sum_initial_payments' ||
        key === 'sum_returned_payments' ||
        key === 'balance'
      ) {
        row.push(formatMoney(value, 'CAD'));
      } else if (key === 'portal_signups' || key === 'portal_cpa') {
        row.push(formatOneDecimal.format(value));
      } else {
        row.push(value);
      }
    } else {
      if (row.length > fromKeys?.length + groupings.length) {
        rows.push(row);
      }
      let nextFromKeys = [];
      if (SUPPORTED_GROUP_KEYS[key]) {
        nextFromKeys = fromKeys;
      } else {
        if (fromKeys?.length > 0) {
          nextFromKeys = ['⤷', key];
          if (fromKeys.includes('⤷')) {
            nextFromKeys.unshift('');
          }
        } else {
          nextFromKeys = [key];
        }
      }
      rows = rows.concat(
        parseDataToMetrics(value, depth + 1, groupings, nextFromKeys)
      );
    }
  }

  // row was already pushed (before the concat)
  if (rows.length > 0) return rows;

  rows.push(row);
  return rows;
}

export default function OverallMetrics3() {
  const url = useLocation().search;
  const params = useMemo(() => {
    const searchParams = new URLSearchParams(url);
    let newParams = { groupings: JSON.stringify(['referral_code']) };
    for (const [key, value] of searchParams.entries()) {
      newParams[key] = value;
    }
    return newParams;
  }, [url]);

  const [showFilters, setShowFilters] = useState(false);
  const [urlParams, setURLParams] = useState(params);

  const history = useHistory();

  const fetchData = useQuery({
    retry: false,
    refetchOnWindowFocus: false,
    queryKey: [{ endpoint: 'onboarding-metrics-3', urlParams: params }],
    select: data => {
      let g = JSON.parse(params.groupings);
      const initGroup = g[0];
      if (!data?.results[initGroup]) return data;
      let sortedData = Object.keys(data?.results[initGroup])
        .sort()
        .reduce(
          (acc, key) => {
            acc[initGroup][key] = data?.results[initGroup][key];
            return acc;
          },
          { [initGroup]: {} }
        );
      return {
        ...data,
        headings: [
          ...g,
          'sum_target',
          'num_attendees',
          'num_fully_complete',
          'sum_returned_payments',
          'sum_bonus_payments',
          'sum_initial_payments',
          'sum_amount_used',
          'portal_signups',
          'portal_cpa',
          'balance',
        ],
        //rows: parseDataToMetrics(data?.results, 0, g, []),
        rows: parseDataToMetrics(sortedData, 0, g, []),
      };
    },
  });

  const overall = fetchData?.data?.overall;

  const today = new Date();
  const lastWeek = [
    new Date().setDate(today.getDate() - today.getDay() - 7),
    new Date().setDate(today.getDate() + today.getDay() - 7),
  ];
  const fetchMarketingGroups = useQuery({
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 2,
    queryKey: [
      {
        endpoint: `marketing-groups`,
      },
    ],
  });

  const marketingGroupOptions = useMemo(() => {
    let opts = [];
    if (
      fetchMarketingGroups?.isSuccess &&
      fetchMarketingGroups?.data?.length > 0
    ) {
      for (const group of fetchMarketingGroups.data) {
        opts.push({
          label: group.name,
          value: group.id,
        });
      }
    }
    return opts;
  }, [fetchMarketingGroups?.isSuccess, fetchMarketingGroups?.data]);

  const defaultFilters = useMemo(() => {
    let filters = {};
    if (urlParams.marketing_group_ids) {
      let d = {};
      for (const r of JSON.parse(urlParams.marketing_group_ids)) {
        d[r] = true;
      }
      filters.marketing_group_ids = d;
    }

    if (urlParams.balances) {
      let d = {};
      for (const r of JSON.parse(urlParams.balances)) {
        d[r] = true;
      }
      filters.balances = d;
    }

    if (urlParams.time_period) {
      filters.time_period = { [urlParams.time_period]: true };
    }
    if (urlParams.regions) {
      let d = {};
      for (const r of JSON.parse(urlParams.regions)) {
        d[r] = true;
      }
      filters.regions = d;
    }

    if (urlParams.date_after) {
      filters.date_after = new Date(urlParams.date_after);
    }

    if (urlParams.date_before) {
      filters.date_before = new Date(urlParams.date_before);
    }

    return filters;
  }, [urlParams]);

  const _applyFilter = () => {
    let paramString = '';
    for (const [key, value] of Object.entries(urlParams)) {
      if (value || value === 0 || value === false) {
        paramString += `${encodeURIComponent(key)}=${encodeURIComponent(
          value
        )}&`;
      }
    }

    history.replace(`${history.location.pathname}?${paramString}`);
  };

  const parsedGroupings = JSON.parse(urlParams.groupings);

  return (
    <>
      <StaffNavBar />
      <Row style={{ width: '100%', alignItems: 'flex-start' }}>
        <div
          style={{
            flex: 3,
            height: 'calc(100vh - 64px)',
            overflowX: 'hidden',
          }}
        >
          <Row
            style={{
              flex: 0,
              alignItems: 'center',
              padding: 'var(--space-xs)',
              width: '100%',
              gap: 'var(--space-sm)',
              position: 'sticky',
              top: 0,
              left: 0,
              background: 'var(--color-bg)',
              zIndex: 10,
            }}
          >
            <form
              style={{ flex: '1.75 0 86px' }}
              onSubmit={ev => {
                ev.preventDefault();
                _applyFilter();
              }}
            >
              <AuthTextInput
                defaultValue={urlParams?.search}
                placeholder={`Search by ${parsedGroupings[0]}`}
                containerStyle={{ margin: 0 }}
                onChangeText={text =>
                  setURLParams({ ...urlParams, search: text })
                }
                leftIcon={IoSearch}
                rightIcon={
                  fetchData.isLoading || fetchData.isFetching
                    ? () => <ActivityIndicator size={1.5} />
                    : () => (
                      <AuthButton
                        containerStyle={{ flex: '0 0 32px' }}
                        btnTheme="borderless"
                        onPress={_applyFilter}
                      >
                        GO
                      </AuthButton>
                    )
                }
              />
            </form>
            {SUPPORTED_GROUPINGS.map(group => (
              <AuthButton
                key={`metrics-grouping-button-switcher-${group}`}
                onPress={() => {
                  let newGroupings = Array.from(JSON.parse(params.groupings));
                  if (newGroupings.includes(group)) {
                    newGroupings.splice(newGroupings.indexOf(group), 1);
                  } else {
                    newGroupings.push(group);
                  }
                  let newUrlParams = {
                    ...urlParams,
                    groupings: JSON.stringify(newGroupings),
                  };

                  // dont call _applyFilter here b/c there will be a race between it
                  // and setURLParams
                  let paramString = '';
                  for (const [key, value] of Object.entries(newUrlParams)) {
                    if (value || value === 0 || value === false) {
                      paramString += `${encodeURIComponent(
                        key
                      )}=${encodeURIComponent(value)}&`;
                    }
                  }

                  history.replace(
                    `${history.location.pathname}?${paramString}`
                  );
                  setURLParams(newUrlParams);
                }}
                colorTheme={params?.groupings.includes(group) ? 'inverted' : ''}
              >
                {group.toUpperCase().replaceAll('_', ' ')}
              </AuthButton>
            ))}
            <IconButton
              colorTheme="text"
              iconName={IoFilter}
              onPress={() => setShowFilters(!showFilters)}
            />
          </Row>
          {fetchData?.isError && (
            <p
              style={{
                textAlign: 'center',
                margin: 'var(--space-md) 0',
                color: 'var(--color-danger)',
              }}
            >
              Error: {fetchData?.error?.message}
            </p>
          )}
          {fetchData?.isSuccess && fetchData?.data?.overall && (
            <Row
              style={{
                flex: 0,
                alignItems: 'center',
                padding: 'var(--space-xs)',
                width: '100%',
                gap: 'var(--space-sm)',
                position: 'sticky',
                top: 0,
                left: 0,
                background: 'var(--color-bg)',
                zIndex: 10,
              }}
            >
              {parsedGroupings.map(g => (
                <OverallBox
                  key={`overall-grouping-spacer-${g}`}
                  num="Overall"
                  label={g}
                />
              ))}
              <OverallBox
                num={overall?.sum_target}
                label="Target"
                title="Sum of the target number of signups"
              />
              <OverallBox
                num={overall?.num_attendees}
                label="Attendees"
                title="Total number of attendees"
              />
              <OverallBox
                num={overall?.num_fully_complete}
                label="Complete"
                title="Number of sportsbook that we marked as fully complete"
              />
              <OverallBox
                num={formatMoney(overall?.sum_returned_payments, 'CAD')}
                label="Returned Pymts"
                title="The sum of the any money returned"
              />
              <OverallBox
                num={formatMoney(overall?.sum_bonus_payments, 'CAD')}
                label="Bonus Pymts"
                title="The sum of the any bonuses paid (POST_ONBOARDING_BONUS or BETSTAMP_101_BONUS or GROUP_TARGET_BONUS)"
              />
              <OverallBox
                num={formatMoney(overall?.sum_initial_payments, 'CAD')}
                label="Initial Pymts"
                title="The sum of the initial setup session payments"
              />
              <OverallBox
                num={formatMoney(overall?.sum_amount_used, 'CAD')}
                label="Amount Used"
                title="The sum of the min deposits at fully complete sportsbooks"
              />
              <OverallBox
                num={formatOneDecimal.format(overall?.portal_signups)}
                label="Portal Signups"
                title="Number of portal reported sportsbook accounts created"
              />
              <OverallBox
                num={formatOneDecimal.format(overall?.portal_cpa)}
                label="Portal CPA"
                title="Number of portal reported CPA"
              />
              <OverallBox
                num={formatMoney(overall?.balance, 'CAD')}
                label="Balance"
                title="The sum of the initial payments sent minus the sum of the min deposits at fully completed sportsbooks minus the sum of the money returned (negative means we owe them)"
              />
            </Row>
          )}

          {fetchData?.isSuccess &&
            (!fetchData?.data?.results[parsedGroupings[0]] ||
              fetchData?.data?.results[parsedGroupings[0]]?.length === 0) && (
              <h5 style={{ textAlign: 'center' }}>No data</h5>
            )}

          <Table
            cellSpacing={0}
            style={{ width: '100%', tableLayout: 'fixed' }}
          >
            <Thead>
              <tr>
                {fetchData?.isSuccess && fetchData?.data?.headings && (
                  <>
                    {fetchData?.data?.headings.map(h => (
                      <Th
                        key={`metrics-table-heading-${h}`}
                        style={{ textAlign: 'center' }}
                      >
                        {h
                          .replaceAll('_', ' ')
                          .replace('sum', '')
                          .replace('num', '')}
                      </Th>
                    ))}
                  </>
                )}
              </tr>
            </Thead>

            <Tbody>
              {fetchData?.isSuccess && fetchData?.data?.rows && (
                <>
                  {fetchData?.data?.rows.map((row, i) => (
                    <tr key={`metrics-row-${i}`}>
                      {row.map((item, j) => (
                        <td
                          key={`metrics-col-${i}-${j}-${item}`}
                          style={{ textAlign: 'center' }}
                        >
                          {item}
                        </td>
                      ))}
                    </tr>
                  ))}
                </>
              )}
            </Tbody>
          </Table>
        </div>
        {showFilters && (
          <PanelWrapper>
            <Col
              style={{
                height: '100%',
                overflowY: 'auto',
                flexWrap: 'nowrap',
                justifyContent: 'flex-start',
                padding: '0 var(--space-sm)',
              }}
            >
              <Row
                style={{
                  flex: 0,
                  width: '100%',
                  alignItems: 'center',
                  position: 'sticky',
                  top: 0,
                  background: 'var(--color-bg)',
                  zIndex: 10,
                }}
              >
                <h5 style={{ flex: 1, margin: 0 }}>Filters</h5>
                <IconButton
                  colorTheme="text"
                  iconName={IoClose}
                  onPress={() => {
                    setShowFilters(false);
                  }}
                />
              </Row>

              <AuthButton
                containerStyle={{ flex: 0 }}
                colorTheme="inverted"
                onPress={() => _applyFilter()}
              >
                Apply Filters
              </AuthButton>
              {marketingGroupOptions?.length > 0 && (
                <GenericAdminFilter
                  filterTitle="Referral Code Group"
                  filterKey="marketing_group_ids"
                  options={{
                    groups: [
                      {
                        title: 'Referral Code Group',
                        options: marketingGroupOptions,
                      },
                    ],
                  }}
                  onFilter={selections =>
                    setURLParams({
                      ...urlParams,
                      marketing_group_ids: JSON.stringify(selections),
                    })
                  }
                  defaultDict={defaultFilters.marketing_group_ids}
                />
              )}
              <GenericAdminFilter
                filterTitle="Time Period"
                filterKey="time_period"
                singleChoice
                options={{
                  groups: [
                    {
                      title: 'Time Period',
                      options: [
                        {
                          label: `Last Week (${humanDate(lastWeek[0], {
                            year: undefined,
                          })} - ${humanDate(lastWeek[1], {
                            year: undefined,
                          })})`,
                          value: 'last_week',
                        },
                        {
                          label: `Last Month (${MONTHS[
                            new Date().getMonth() === 0
                              ? 11
                              : new Date().getMonth() - 1
                            ]
                            })`,
                          value: 'last_month',
                        },
                        {
                          label: 'Past Three Months',
                          value: 'past_three_months',
                        },
                        { label: 'Year to Date', value: 'year_to_date' },
                        { label: 'Month to Date', value: 'month_to_date' },
                      ],
                    },
                  ],
                }}
                onFilter={selections =>
                  setURLParams({
                    ...urlParams,
                    time_period: selections,
                    date_after: null,
                    date_before: null,
                  })
                }
                defaultDict={defaultFilters.time_period}
              />
              <GenericAdminFilter
                filterTitle="Region"
                filterKey="regions"
                options={{
                  groups: [
                    {
                      title: 'Canada',
                      options: PROVINCE_OPTIONS.map(p => ({
                        label: p,
                        value: p,
                      })),
                    },
                    {
                      title: 'USA',
                      options: US_STATES.map(p => ({ label: p, value: p })),
                    },
                  ],
                }}
                onFilter={selections =>
                  setURLParams({
                    ...urlParams,
                    regions: JSON.stringify(selections),
                  })
                }
                defaultDict={defaultFilters.regions}
              />
              <GenericAdminFilter
                filterTitle="After Date"
                filterKey="date_after"
                type="date"
                defaultDate={defaultFilters.date_after}
                onFilter={date =>
                  setURLParams({
                    ...urlParams,
                    date_after: date.toISOString(),
                    time_period: null,
                  })
                }
              />
              <GenericAdminFilter
                filterTitle="Before Date"
                filterKey="date_before"
                type="date"
                defaultDate={defaultFilters.date_before}
                onFilter={date =>
                  setURLParams({
                    ...urlParams,
                    date_before: date.toISOString(),
                    time_period: null,
                  })
                }
              />
            </Col>
          </PanelWrapper>
        )}
      </Row>
    </>
  );
}

function OverallBox({ num, label, title }) {
  return (
    <Col
      style={{
        padding: 'var(--space-xs)',
        borderRadius: '8px',
        border: '1px solid var(--color-text-light)',
        height: '100%',
        flexWrap: 'nowrap',
      }}
      title={title}
    >
      <b>{num}</b>
      <small style={{ textAlign: 'center' }}>{label}</small>
    </Col>
  );
}
