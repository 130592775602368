import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

// util
import { kFormatter } from 'utils';

// components
import { Row, Col } from 'components/generic/Layout';
import UserPicks from 'components/user_profile/UserPicks.toptab.js';
import DateRange from 'components/generic/DateRange';
import AutoColoredAmount from 'components/generic/AutoColoredAmount';
import ActivityIndicator from 'components/generic/ActivityIndicator';

// actions
import { getPicksStats, getWagers } from 'actions';

export default function Historicals(props) {
  const dispatch = useDispatch();
  const reduxProps = useSelector(state => ({
    pickStats: state.picksReducer.pickStats,
    isLoadingPickStats: state.picksReducer.isLoadingPickStats,
    hist_date_after: state.picksReducer.hist_date_after,
    hist_date_before: state.picksReducer.hist_date_before,
  }));
  const { pickStats, isLoadingPickStats, hist_date_after, hist_date_before } =
    reduxProps;
  return (
    <>
      {!props.desktop && (
        <DateRange
          getItemsInRange={(d1, d2) => {
            dispatch(
              getWagers({
                screen: 'historicals',
                date_after: d1,
                date_before: d2,
                is_graded: true,
              })
            );
            dispatch(getPicksStats(d1, d2));
          }}
          defaultStartDate={hist_date_after}
          defaultEndDate={hist_date_before}
        />
      )}

      <Row
        style={{
          alignItems: 'center',
          width: '100%',
          padding: 'var(--space-sm) 0',
        }}
      >
        {isLoadingPickStats && <ActivityIndicator />}
        {pickStats['historicals'].map((stat, i) => (
          <Col key={`historical-stat-${i}`}>
            {stat.symbol ? (
              <AutoColoredAmount symbol={stat.symbol} bold="true">
                {stat.num >= 10 ? Math.round(stat.num) : stat.num}
              </AutoColoredAmount>
            ) : (
              <b>{kFormatter(stat.num)}</b>
            )}

            <small style={{ lineHeight: 1 }}>{stat.text.toUpperCase()}</small>
          </Col>
        ))}
      </Row>

      <UserPicks
        screen={'historicals'}
        graded_only={true}
        showPublicBets={false}
        scrollableTarget={props.scrollTarget || null}
        isDesktop={props.desktop}
      />
    </>
  );
}
