export default {
  link_to_video: '',
  content: [
    {
      type: 'paragraph',
      text: 'To use betstamp’s analysis function, go to the “My Picks” tab and click the “Analysis” button in the top right corner.',
    },
    {
      type: 'image',
      url: 'https://betstamp-public.sfo2.cdn.digitaloceanspaces.com/tutorials/analysis-1.png',
    },
    {
      type: 'paragraph',
      text: "Or if you're on web, you can find it listed in the sidebar as “Analysis”",
    },
    {
      type: 'image',
      url: 'https://betstamp-public.sfo2.cdn.digitaloceanspaces.com/tutorials/analysis-2.png',
      maxWidth: '90%',
    },
    {
      type: 'paragraph',
      text: 'Here, on mobile, you will automatically be shown analysis of all of your wagers over the past 7 days, consisting of Net Profit, ROI, CLV, Volume, Average Bet Size, CLV Best Available, Record (W-L-P), and Sample Size',
    },
    // image grid here
    {
      type: 'image-grid',
      images: [
        {
          url: 'https://betstamp-public.sfo2.cdn.digitaloceanspaces.com/tutorials/analysis-3.png',
          caption: '',
        },
        {
          url: 'https://betstamp-public.sfo2.cdn.digitaloceanspaces.com/tutorials/analysis-4.png',
          caption: '',
        },
        {
          url: 'https://betstamp-public.sfo2.cdn.digitaloceanspaces.com/tutorials/analysis-5.png',
          caption: '',
        },
      ],
    },
    // Or on web: web image here
    {
      type: 'paragraph',
      text: 'Or on web:',
    },
    {
      type: 'image',
      url: 'https://betstamp-public.sfo2.cdn.digitaloceanspaces.com/tutorials/analysis-6.png',
      maxWidth: '90%',
    },
    {
      type: 'paragraph',
      text: 'From here, you also have the ability to sort by ‘All Time - By Sportsbook’, ‘All Time - By League’, ‘Past Day - By Sportsbook’, and ‘Past Day - By League’ using the scrollable filters above the Analysis chart',
    },
    // image grid here
    {
      type: 'image-grid',
      images: [
        {
          url: 'https://betstamp-public.sfo2.cdn.digitaloceanspaces.com/tutorials/analysis-7.png',
          caption: '',
        },
        {
          url: 'https://betstamp-public.sfo2.cdn.digitaloceanspaces.com/tutorials/analysis-8.png',
          caption: '',
        },
        {
          url: 'https://betstamp-public.sfo2.cdn.digitaloceanspaces.com/tutorials/analysis-9.png',
          caption: '',
        },
        {
          url: 'https://betstamp-public.sfo2.cdn.digitaloceanspaces.com/tutorials/analysis-10.png',
          caption: '',
        },
      ],
    },
    {
      type: 'paragraph',
      text: 'Or on web:',
    },
    {
      type: 'image',
      url: 'https://betstamp-public.sfo2.cdn.digitaloceanspaces.com/tutorials/analysis-15.png',
      maxWidth: '90%',
    },
    {
      type: 'paragraph',
      text: 'If you would like to create your own analysis, click “Filters” on mobile, or find the ‘Filters’ tab on the right side of the web',
    },
    // image grid here
    {
      type: 'image-grid',
      images: [
        {
          url: 'https://betstamp-public.sfo2.cdn.digitaloceanspaces.com/tutorials/analysis-11.png',
          caption: '',
        },
        {
          url: 'https://betstamp-public.sfo2.cdn.digitaloceanspaces.com/tutorials/analysis-12.png',
          caption: '',
        },
      ],
    },
    {
      type: 'paragraph',
      text: 'Now, you can adjust the filters to show which bets will be included in the analysis. Filter by date range, by sport and much more. For example, if you want to include only the bets that are verified, use the filter to select this.',
    },
    {
      type: 'paragraph',
      text: 'Under this tab, you’ll have the ability to create any analysis table and view it now, or save it as a preset for easy access later.',
    },
    {
      type: 'image',
      url: 'https://betstamp-public.sfo2.cdn.digitaloceanspaces.com/tutorials/analysis-13.png',
    },
    {
      type: 'image',
      url: 'https://betstamp-public.sfo2.cdn.digitaloceanspaces.com/tutorials/analysis-14.png',
    },
    {
      type: 'paragraph',
      text: 'Check back for more filters to be added in the next few months!',
    },
  ],
};
