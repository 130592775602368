import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

// components
import { Col } from 'components/generic/Layout';

// actual screens
import AnalysisDesktop from './AnalysisDesktop';
import AnalysisMobile from './AnalysisMobile';

// actions
import { getAnalysis, getAnalysisPresets } from 'actions';

export default function Analysis() {
  const params = useLocation().search;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAnalysisPresets());
    if (params && params !== '') {
      let validParams = params;
      if (validParams.charAt(validParams.length - 1) !== '&') {
        validParams += '&';
      }

      dispatch(getAnalysis(validParams, 'table'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Col
        desktop
        style={{ height: '100vh', flexWrap: 'nowrap', overflow: 'hidden' }}
      >
        <AnalysisDesktop />
      </Col>
      <Col mobile style={{ padding: '0 var(--space-xxxs)' }}>
        <AnalysisMobile />
      </Col>
    </>
  );
}
