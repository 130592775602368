/**
 * Checks to see which period options a user should be able to choose from.
 * @param {Object} wager of the user's wager.
 * @returns {Array} of period choices for specific wager.
 */
import { SECOND_HALF_LEAGUES } from 'utils';
export default function periodChoices(wager) {
  if (!wager.game) {
    return [];
  }
  let choices = [];
  if (wager.bet_type !== 'BET' || !wager.game) return choices;
  const halvesLeague =
    [
      'NCAAF',
      'NFL',
      'NCAAB',
      'NBA',
      'WNBA',
      'CFL',
      'MLS',
      'WCUP',
      'EURO',
      'COPA',
      'LIG1',
      'EPL',
      'BUND',
      'UCL',
      'SERA',
      'LIGA',
    ].indexOf(wager.game.league) >= 0;
  const isFT = wager.period === 'FT';
  const labels = {
    FT: 'Full Game',
    '1H': '1st Half',
    '2H': '2nd Half',
    '1S': '1st Set',
    '1P': '1st Period',
    F5: '1st 5 Innings',
  };

  if (wager.period === 'FT') {
    choices.push({
      value: 'FT',
      label: 'Full Game',
    });
  }

  if (wager.game.league === 'NHL') {
    choices.push({
      value: '1P',
      label: '1st Period',
    });
  } else if (wager.game.league === 'MLB') {
    choices.push({
      value: 'F5',
      label: '1st 5 Innings',
    });
  } else if (['ATP', 'WTA'].indexOf(wager.game.league) >= 0) {
    choices.push({
      value: '1S',
      label: '1st Set',
    });
  } else if (halvesLeague) {
    if (!isFT) {
      choices.push({
        value: wager.period,
        label: labels[wager.period],
      });
    }
    if (wager.period !== '1H') {
      choices.push({
        value: '1H',
        label: '1st Half',
      });
    }
    if (SECOND_HALF_LEAGUES.includes(wager.league) && wager.period !== '2H') {
      choices.push({
        value: '2H',
        label: '2nd Half',
      });
    }
  }

  if (!isFT) {
    choices.push({
      value: 'FT',
      label: 'Full Game',
    });
  }

  return choices;
}
