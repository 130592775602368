import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import AutoColoredAmount from './generic/AutoColoredAmount';
import { Col, Row } from './generic/Layout';
import ProfilePic from 'components/generic/ProfilePic';

const Wrapper = styled.div`
  min-width: 96px;
  height: auto;
  transition: all var(--std-transition);
  color: var(--color-text);
  box-sizing: border-box;
  cursor: pointer;
  padding: var(--space-xs) 0;
  border-radius: var(--std-border-radius);
  box-sizing: border-box;
  &:active {
    box-shadow: inset 0 0 0 100vh rgba(0, 0, 0, 0.1);
  }
`;

const PendingCircle = styled.div`
  position: absolute;
  border-radius: 100%;
  top: 0;
  right: 0;
  width: 22px;
  height: 22px;
  background: var(--color-danger);
  text-align: center;
  color: white;
  font-weight: bold;
`;

export default function MediaListItem(props) {
  const { user } = props;
  return (
    <Wrapper style={props.style}>
      <Link
        to={`/u/${user.username}`}
        style={{ textDecoration: 'none', flex: 1, width: '100%' }}
      >
        <Row>
          <Col style={{ width: '64px', height: '64px' }}>
            <div style={{ position: 'relative' }}>
              <ProfilePic
                user={user}
                style={{ width: '64px', height: '64px' }}
              />
              {props.user.stats.pending > 0 && (
                <PendingCircle>{props.user.stats.pending}</PendingCircle>
              )}
            </div>
          </Col>
        </Row>
        <h6 style={{ textAlign: 'center', margin: 0, width: '100%' }}>
          {user.username}
        </h6>
        <Row style={{ justifyContent: 'center', flexWrap: 'nowrap' }}>
          <AutoColoredAmount symbol={'%'}>{user.stats.roi}</AutoColoredAmount>
          &nbsp;
          <span>ROI</span>
        </Row>
      </Link>
    </Wrapper>
  );
}
