import { Observable } from 'rxjs';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import { ajax } from 'rxjs/observable/dom/ajax';
import { BACKEND_API_URL, getHeaders } from 'utils';

import {
  CHECK_EMAIL_AVAILABLE,
  checkEmailAvailableSuccess,
  checkEmailAvailableFailure,
} from 'actions';

export default function checkEmailAvailable(action$, state$) {
  return action$.ofType(CHECK_EMAIL_AVAILABLE).switchMap(action => {
    return ajax
      .get(
        BACKEND_API_URL + `api/check_email/?email=${action.email}`,
        getHeaders(state$)
      )
      .map(res => checkEmailAvailableSuccess(res))
      .catch(error => Observable.of(checkEmailAvailableFailure(error.xhr)));
  });
}
