import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { IoIosArrowUp } from 'react-icons/io';

// components
import { Col, Grid } from 'components/generic/Layout';
import FAB from 'components/generic/FAB';

const Hero = styled.div`
  width: 100%;
  background-color: var(--color-primary);
  color: white;
  padding: var(--space-xl);
  box-sizing: border-box;
  @media only screen and (max-width: 625px) {
    padding: var(--space-sm);
    padding-bottom: var(--space-xl);
  }
`;

const NavElement = styled.a`
  font-size: var(--text-base-size);
  border: none;
  border-bottom: 2px solid transparent;
  padding: var(--space-sm) 0;
  text-align: center;
  text-decoration: none;
  outline: none;
  cursor: pointer;
  transition: all var(--std-transition);
  &:hover {
    border-bottom: 2px solid var(--color-primary);
  }
`;

const Details = styled.details`
  cursor: pointer;
  & > summary {
    outline: 0;
  }
  & > summary::-webkit-details-marker {
    display: none;
  }
  & > summary:after {
    content: '+';
    color: black;
    position: absolute;
    font-size: 1.75rem;
    line-height: 0;
    margin-top: 0.66rem;
    padding-left: 5px;
    font-weight: 200;
    transform-origin: center;
    transition: 200ms linear;
  }
  &[open] summary:after {
    transform: rotate(45deg);
    margin-top: 0.5rem;
  }
  @media only screen and (max-width: 625px) {
    max-width: 90vw;
  }
`;

export default function FAQ() {
  return (
    <>
      <FAB onClick={() => window.scrollTo(0, 0)}>
        <IoIosArrowUp
          title="Back to top"
          style={{
            fontSize: 'var(--text-xl)',
          }}
        />
      </FAB>
      <span id="faq-top"></span>
      <Hero>
        <Col style={{ alignItems: 'flex-start' }}>
          <h1>Frequently Asked Questions</h1>
          <p>
            Question not listed?{' '}
            <a style={{ color: 'white' }} href="mailto:contact@betstamp.app">
              Contact Us
            </a>{' '}
            with your Question
          </p>
          <Link to="/tutorial-all" style={{ color: 'white' }}>
            View Tutorials
          </Link>
        </Col>
      </Hero>

      <Grid
        style={{
          gridTemplateColumns: 'repeat(auto-fit, minmax(220px, 1fr))',
          gridGap: 0,
          margin: 'var(--space-xs)',
        }}
      >
        <NavElement href="#faq-general">
          <h6>General</h6>
        </NavElement>
        <NavElement href="#faq-odds-screen">
          <h6>Odds Screen</h6>
        </NavElement>
        <NavElement href="#faq-tracking-bets">
          <h6>Tracking Bets</h6>
        </NavElement>
        <NavElement href="#faq-pick-verification">
          <h6>Pick Verification</h6>
        </NavElement>
        <NavElement href="#faq-editing-bets">
          <h6>Editing Bets</h6>
        </NavElement>
        <NavElement href="#faq-accounting">
          <h6>Accounting</h6>
        </NavElement>
        <NavElement href="#faq-analysis">
          <h6>Analysis</h6>
        </NavElement>
        <NavElement href="#faq-betstamp-marketplace">
          <h6>betstamp Marketplace</h6>
        </NavElement>
        <NavElement href="#faq-media-picks">
          <h6>Media Picks</h6>
        </NavElement>
        <NavElement href="#faq-other-settings">
          <h6>Other Settings</h6>
        </NavElement>
      </Grid>

      <div className="container">
        <span id="faq-general"></span>
        <h3>General</h3>
        <QA
          q="What is betstamp?"
          as={[
            'betstamp is the best sports betting aid app, designed to help bettors increase their profits and manage their process easier. betstamp provides real-time bet tracking, bet analysis, odds comparison and the ability to follow your friends or favourite handicappers.',
          ]}
        />

        <QA
          q="Can I place real money wagers on the betstamp app?"
          as={[
            'No. betstamp is best used to help you determine what you should bet on, where you should bet it, and how you should track it. In all cases, you will still need to visit your sportsbook’s site or physical location to place a real money wager.',
          ]}
        />
        <QA
          q="Why would I track my bets on betstamp?"
          as={[
            'See which sportsbook has the best odds before you bet',
            'Easiest way to follow your bets all in one place. Live scores and odds',
            'Auto-grading of your bets once the game is complete',
            'Keep real records and conduct analysis on your betting history',
            'Manage your sportsbook balances with our custom accounting software',
            'Build credibility by verifying your betting record ',
          ]}
        />
        <QA
          q="What is betstamp Marketplace?"
          as={[
            'A peer-to-peer market where anybody can view other users’ verified betting history and conduct analysis on their picks',
            "Determine who's bets you should follow or tail",
          ]}
        />
        <QA
          q="Can I sell my picks on betstamp?"
          as={[
            'Unfortunately we no longer provide the option to sell your picks on our platform.',
          ]}
        />
        <span id="faq-odds-screen"></span>
        <h3>Odds Screen</h3>

        <QA
          q="What is the odds screen and how does it work?"
          as={[]}
          tutorial_embed={{
            link: 'https://betstamp-public.sfo2.cdn.digitaloceanspaces.com/tutorials/odds_screen.mp4',
          }}
        />

        <QA
          q="What is line shopping?"
          as={[]}
          tutorial_embed={{
            link: 'https://betstamp-public.sfo2.cdn.digitaloceanspaces.com/tutorials/line_shopping.mp4',
          }}
        />

        <QA
          q="How do I customize my odds screen?"
          as={[]}
          tutorial_embed={{
            link: 'https://betstamp-public.sfo2.cdn.digitaloceanspaces.com/tutorials/customize_odds_screen.mp4',
          }}
        />

        <QA
          q="What is the “Player Prop Tool”?"
          as={[
            'The Player Prop Tool is a tool provided by betstamp to help compare player prop lines from multiple sportsbooks in an effort to find the best price in the market.',
          ]}
        />
        <QA
          q="How do I use the Player Prop Tool?"
          as={['Using the Player Prop Tool is quite easy. ']}
          tutorial={{
            link: '/tutorial-player-prop',
            text: 'View the tutorial on how to use the Player Prop Tool here',
          }}
        />

        <span id="faq-tracking-bets"></span>
        <h3>Using betstamp to Track Bets</h3>
        <QA
          q="How do I track a bet?"
          as={[
            'To track a bet, visit the games tab (far left tab) and click on any game. Here you can view the aggregated odds from all supported sportsbooks and click to track the bet.',
          ]}
        />
        <QA
          q="What leagues are supported?"
          as={[
            'NFL, NBA, MLB, NHL, UFC, Bellator, ATP, WTA, WNBA, CFL, NCAAF, NCAAB, SERA, BUND, MLS, UCL, EPL, LIG1, LIGA, and Golf',
            'For these sports, betstamp will automatically verify bets, grade bets after competition, and track hundreds of metrics including closing line value',
            'More leagues will be added soon including E-sports',
          ]}
        />
        <QA
          q="Can I track a bet from a league that is not supported?"
          as={[
            'Yes. Track any bet from a league that is not supported above by using the + button in the My Picks screen to add a custom bet.',
          ]}
          tutorial={{
            text: 'View the tutorial on adding a custom bet here.',
            link: '/tutorial-custom-bet',
          }}
        />
        <QA
          q="Can I track futures bets?"
          as={[
            'Yes. Track futures bets the same way you would track a custom bet (above). If you would like to keep all futures bets sorted then use the tag function and tag the bet as a future. ',
          ]}
        />
        <QA
          q="Can I track prop bets?"
          as={[
            'Yes. All main game prop and player prop bets are supported and can be found in the games screen.',
          ]}
        />
        <QA
          q="Can I track live bets?"
          as={[
            'Yes. During the games, live bets can be tracked and verified at our consensus live line (shown when you click on a game). After the game, you can track live bets in the game screen by checking the box "Track as Live Bet". Live bets don\'t count towards your public record, ROI, or CLV, but do show up on your public profile.',
          ]}
        />
        <QA
          q="What happens when I add a tag to my bet?"
          as={[
            'When tracking a bet, you can add a custom tag to classify this bet as something that you may want to research in a future analysis. By tagging all bets as “future” bets then you will later be able to track your record on future bets in your analysis screen.',
          ]}
        />
        <QA
          q="Can I track a parlay or a teaser?"
          as={[
            'Yes. Under the games screen, click multiple games to add them to your betslip. Next click into the betslip and tap "Parlay"',
            'Teaser bets can also be tracked, however cannot be verified.',
          ]}
        />
        <QA
          q="How do I track a bet that I have “cashed out” of at my sportsbook?"
          as={[
            'In order to accurately reflect a cash out in your records, you can manually edit the bet by clicking on it in the my picks screen. To reflect a cash out, change the win amount to the amount you have received for cashing out, and change the bet outcome to a Win. If you would like to keep track of all of the bets you have cashed out, you can also add a tag to classify this as a cash out.',
          ]}
        />
        <QA
          q="What do I do if I forgot to put the bet in and now the game started?"
          as={[
            'Simply track the bet after the game has started. Unfortunately, this bet will not be verified and will not show up in your public profile, but it will still be visible in your personal betting history. ',
          ]}
        />
        <QA
          q="What sportsbooks are supported?"
          as={[
            'To view the list of supported Sportsbooks, or to edit which Sportsbooks you would like to show odds from, head over to the settings tab and add or delete Sportsbooks. We are constantly working to add more Sportsbooks, if there is one we are missing that you frequently use, feel free to let us know at contact@betstamp.app.',
          ]}
          tutorial={{
            link: '/tutorial-my-sportsbooks',
            text: 'View our tutorial on setting your sportsbook settings here',
          }}
        />
        <QA
          q="Who can see the bets I track on betstamp?"
          as={[
            'If you select to keep your profile private, then only you will be able to see your betting history. This includes both your verified record as well as your unverified record.',
            'If you select to make your profile public, any user will be able to search your profile and see a detailed record of your verified picks. They will not be able to see your unverified record or any picks tracked on non-supported sports, sportsbooks, or bet types.',
          ]}
          tutorial={{
            link: '/tutorial-my-profile',
            text: 'View our tutorial on your profile settings here',
          }}
        />

        <span id="faq-pick-verification"></span>
        <h3>Pick Verification, Editing Picks</h3>
        <QA
          q="How does Pick Verification Work?"
          as={[
            'When you track a bet, betstamp’s software will automatically classify your bet as verified or unverified. For your bet to be verified, the spread or odds must be available at the sportsbook at the time you track the bet. If you are attempting to track a bet at different odds that are available at the sportsbook, your bet will not be verified.',
          ]}
          tutorial={{
            link: '/tutorial-verified-bets',
            text: 'Learn all about how betstamp’s verification works here',
          }}
        />
        <span id="faq-editing-bets"></span>
        <QA
          q="What do I do if I put the wrong tracked bet in?"
          as={['Edit the bet, see below:']}
        />
        <QA
          q="What do I do if my sportsbook cancelled this bet or graded this bet differently than how betstamp did?"
          as={['Edit the bet, see below:']}
        />
        <QA
          q="Can I edit a bet that I tracked on betstamp?"
          as={[
            'You can edit any bet for any reason. Editing a bet will never change your public profile, instead, it will change your personal record that is tracked and viewed only by you.',
            'The original bet will still appear unedited on your public profile and will count towards your public record.',
          ]}
          tutorial={{
            link: '/tutorial-editing-bets',
            text: ' Learn all about how editing bets work here',
          }}
        />
        <QA
          q="Why would I change a bet after it has been locked?"
          as={[
            'I bet it earlier in the day or week and forgot to track it right away',
            'I tracked a verified bet by mistake and need to change or delete it from my personal record',
            'I got different odds at my Sportsbook so I want to change my verified bet to those odds for my personal record',
            'I tracked the bet at a different Sportsbook',
            'The payout or grading I received from my Sportsbook was different than how betstamp graded the bet',
          ]}
        />

        <span id="faq-analysis"></span>
        <h3>Accounting {'&'} Analysis</h3>
        <QA
          q="How do I analyze my tracked bets?"
          as={[
            'Under the My Picks tab, click the analysis button in the top right corner. Here you can create custom analysis tables, or use the preset tables. You can use the filters to create custom tables to analyze all of your bets and see where you are strong and where you need improvement.',
          ]}
          tutorial_embed={{
            link: 'https://betstamp-public.sfo2.cdn.digitaloceanspaces.com/tutorials/analyze_tracked_bets.mp4',
          }}
          tutorial={{
            link: '/tutorial-analysis',
            text: 'View the complete tutorial here',
          }}
        />
        <QA
          q="What is CLV and why does it matter?"
          as={[
            'CLV stands for Closing Line Value and is deemed by many professionals to be the best indicator of long term betting success. CLV is the % difference between the closing odds and the odds that you placed the bet at.',
            'Closing Line Value is calculated by comparing the implied win probability of the odds of your bet and the implied win probability of the closing odds for the game. ',
            'According to the efficient market hypothesis, as time progresses, the betting market becomes more efficient. This means that if you are consistently getting better odds or a better point spread than what is available right before the game, you have likely made a good bet.',
          ]}
          tutorial_embed={{
            link: 'https://betstamp-public.sfo2.cdn.digitaloceanspaces.com/tutorials/clv.mp4',
          }}
        />

        <QA
          q="Why are my sportsbook balances negative on the accounting screen?"
          as={[
            'If you started using the app and already had a balance in your account, you will need to log what the initial account balance was. You can do this by tapping the blue plus button at the bottom right corner of the accounting page.',
          ]}
        />
        <QA
          q="What do I do if my sportsbook balances don’t add up?"
          as={[
            'If your balances don’t add up then either you have not accurately logged all transactions and bets into the account, or your sportsbook has made an error.',
            'For all larger transactions, we recommend clicking on the sportsbook in the accounting screen while simultaneously checking your graded bets at your sportsbooks to try to spot any discrepancies.',
            'For smaller amounts that may be off by a few dollars, you can log an adjustment which does not count towards your record and adjust your sportsbook balance to be accurate. You can log an adjustment by clicking the blue plus button in the bottom right corner of the screen.',
          ]}
        />
        <QA
          q="Can I use betstamp to deposit and withdraw from sportsbooks?"
          as={[
            'No, betstamp is used in conjunction with sportsbooks and is best used as a tracker.',
          ]}
        />

        <span id="faq-betstamp-marketplace"></span>
        <h3>betstamp Marketplace</h3>
        <QA
          q="Can I view free picks on betstamp?"
          as={[
            'Yes. You can analyze the betting history of any public user. If the user has posted a pick package for sale, then you will not be able to view their pending bets without buying their picks first. If a user does not have a package posted you can view their picks.',
          ]}
        />
        <QA
          q="Can I get notified when somebody I follow tracks a bet?"
          as={[
            'Yes. When you follow somebody, by default you will be notified when they track a bet. If you would like to change this setting, click on your following tab under your profile. Here you will see the list of people who you follow. Click the notification bell to toggle on/off push notifications.',
          ]}
        />
        <QA
          q="How do I know who is a successful bettor?"
          as={[
            'The best way to determine if somebody is a successful bettor is to analyze their betting record on betstamp. Conduct the same analysis you do for your own betting record on their history. Use this to determine who is a successful bettor based on your own metrics.',
          ]}
        />
        <QA
          q="Why do some users have a lock beside their pending bets?"
          as={[
            'These users have posted a package and hid their picks behind a paywall. You can unlock this content by paying the specified price.',
          ]}
        />

        <span id="faq-media-picks"></span>
        <h3>Tracked Media Picks</h3>
        <QA
          q="What is the Media Picks section?"
          as={[
            'The media picks section tracks sports betting picks given out on various platforms including tv shows, podcasts, articles etc. These picks are tracked manually by the betstamp community and are updated as quickly as possible. If you want to join the community, if you think you can help, or if you have any requests for media personalities that you feel should be tracked, email us at contact@betstamp.app or DM us on twitter ',
          ]}
          tutorial={{
            external: true,
            link: 'https://twitter.com/betstamp',
            text: '@betstamp',
          }}
        />
        <QA
          q="Why track media picks?"
          as={[
            'Transparency and accountability. If you give out picks publicly, even for free, there needs to be a certain standard of tracking and accountability. betstamp guarantees accuracy and transparency in the reporting of the bets. Any media member or personality that is being 3rd party tracked on betstamp is invited to create an account and track their own lines through our line verification software.',
          ]}
        />
        <QA
          q="How are these picks being tracked?"
          as={[
            'The picks are tracked by members of the betstamp community and added manually as close to release as possible. Each media account is tracked with different specifications based on the content provided and the way the picks are given out. In the description of each account, you can find specific details on what is being tracked and the way it is being accounted for. If you want to join the community, if you think you can help, or if you have any requests for media personalities that you feel should be tracked mail contact@betstamp.app or DM us on twitter ',
          ]}
          tutorial={{
            external: true,
            link: 'https://twitter.com/betstamp',
            text: '@betstamp',
          }}
        />

        <span id="faq-other-settings"></span>
        <h3>Other Settings</h3>
        <QA
          q="How do I change my push notification settings?"
          as={[
            'Click “Push Notifications” in the settings tab. At this time, you can receive push notifications for new followers, new package subscribers, and when your bets are graded.',
          ]}
        />
        <QA
          q="What are Default Odds?"
          as={[
            'Default odds allow you to choose the way you will be able to view your odds in the app. You can choose between standard American Odds or Decimal Odds.',
          ]}
          tutorial={{
            link: '/tutorial-defaults',
            text: ' Read a little more about this here',
          }}
        />
        <QA
          q="What is my Default Bet Size?"
          as={[
            'Default bet size is the amount that will auto populate in the picks screen when you go to track a bet. You should set this number to your standard unit size for faster and easier tracking of bets.',
          ]}
          tutorial={{
            link: '/tutorial-defaults',
            text: 'Read a little more about this here',
          }}
        />
        <QA
          q="How do I add or edit my Sportsbooks?"
          as={[
            'My Sportsbooks under the settings tab can be used to view and edit all of the sportsbooks that you wish to view odds for.',
          ]}
          tutorial={{
            link: '/tutorial-my-sportsbooks',
            text: 'View the tutorial on how to add and edit sportsbooks here',
          }}
        />
        <QA
          q="Can I track a bet if I don’t have any of the Sportsbooks that are supported?"
          as={[
            'You can add a custom sportsbook in the settings tab under sportsbooks. If you are using a custom sportsbook, your bet will not be verified. However, this can still be valuable as you can still keep a clean accounting record for all of your sportsbook accounts.',
          ]}
        />
        <QA
          q="What is my primary Sportsbook?"
          as={[
            'Adding a primary Sportsbook will allow you to see odds from this Sportsbook at the top of the screen. This will also be the first Sportsbook that pops up when you log a parlay, teaser, or prop bet. To make a Sportsbook your primary, just tap and hold the sportsbook in the my books setting and drag it to the top of the list. You can change your primary book at any time.',
          ]}
        />
        <QA
          q="What is “additional” under Sportsbooks in the settings page?"
          as={[
            'The additional function allows you to choose which Sportsbooks will be displayed under the best available in the games screen. You can choose to display all sportsbooks that we support by clicking “All”, or just the sportsbooks that accept players from your region or state by selecting “State”.',
          ]}
        />
        <QA
          q="What is “best available” under Sportsbook in the settings page?"
          as={[
            'The best available function allows you to customize how the best available odds are populated in the games screen. You can choose to display the best available odds from All books, from the books in your region/state, or the books you have selected in the “My Sportsbooks” tab.',
          ]}
        />
        <QA
          q="Why is one of the sportsbooks I use missing from the odds screen?"
          as={[
            'Betstamp does not support unregulated sportsbooks, we apologize for the inconvenience. If the sportsbook is regulated, please send an email to contact@betstamp.app about having this sportsbook added.',
          ]}
          tutorial={{
            link: '/games',
            text: ' To view a full list of the sportsbooks available on our odds screen, click here.',
          }}
        />
        <QA
          q="How do I delete my account?"
          as={[
            'Betstamp allows you to deactivate your account and any data associated with it for any reason at any time. In order to deactivate your account, go to the edit profile section of the Betstamp app or betstamp.app/settings and select deactivate account. In order to completely delete your account and all of the data associated with it, contact us at contact@betstamp.app and request for your data to be deleted. We will respond with a confirmation within 21 days. Betstamp may retain and use your email address after deactivation to send you periodic advertisements and offers relating to Betstamp’s products.',
          ]}
        />
      </div>
    </>
  );
}

function QA({ q, as, tutorial, tutorial_embed }) {
  // return (
  //   <>
  //     <b>{q}</b>
  //     <br />
  //     {as.length > 1 ? (
  //       <ul style={{ marginTop: 0, marginBottom: -15 }}>
  //         {as.map((a, i) => (
  //           <li key={`${q}-ans-${i}`}>{a}</li>
  //         ))}
  //       </ul>
  //     ) : (
  //       <>
  //         {as.map((a, i) => (
  //           <span key={`${q}-ans-${i}`}>{a}</span>
  //         ))}
  //       </>
  //     )}
  //     {tutorial && (
  //       <>
  //         {as.length > 1 ? <br /> : ' '}
  //         <Link to={tutorial.link}>{tutorial.text}</Link>
  //       </>
  //     )}
  //     <br />
  //     <br />
  //   </>
  // );
  return (
    <>
      <Details
        open={
          q === 'How do I delete my account?' ||
          q === 'What is betstamp?' ||
          q === 'Can I place real money wagers on the betstamp app?' ||
          q === '"What is the odds screen and how does the odds screen work?' ||
          q === 'How do I track a bet?' ||
          q === 'How does Pick Verification Work?' ||
          q === 'How do I use betstamp’s Analysis function?' ||
          q === 'Can I view free picks on betstamp?' ||
          q === 'How do I change my push notification settings?' ||
          q === 'What is the Media Picks section?'
        }
      >
        <summary>
          <b>{q}</b>
        </summary>
        {as.length > 1 ? (
          <ul style={{ marginTop: 0, marginBottom: 0 }}>
            {as.map((a, i) => (
              <li key={`${q}-ans-${i}`}>{a}</li>
            ))}
          </ul>
        ) : (
          <>
            {as.map((a, i) => (
              <span key={`${q}-ans-${i}`}>{a}</span>
            ))}
          </>
        )}
        <br />
        {tutorial_embed && (
          <video
            width="375"
            controls
            loop
            style={{
              border: '1px solid var(--color-text)',
              borderRadius: '25px',
            }}
          >
            <source src={tutorial_embed.link} type="video/mp4" />
            Sorry, your browser doesn't support embedded videos.
          </video>
        )}
        {tutorial && (
          <>
            {as.length > 1 ? <br /> : ' '}
            {tutorial.external ? (
              <a href={tutorial.link}>{tutorial.text}</a>
            ) : (
              <Link to={tutorial.link}>{tutorial.text}</Link>
            )}
          </>
        )}
      </Details>
      <br />
    </>
  );
}
