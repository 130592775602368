import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const Hero = styled.div`
  width: 100%;
  background-color: var(--color-primary);
  color: white;
  padding: var(--space-xl);
  box-sizing: border-box;
  @media only screen and (max-width: 625px) {
    padding: var(--space-sm);
    padding-bottom: var(--space-xl);
  }
`;

export default function Careers() {
  const [showFallback, setShowFallback] = useState(false);

  useEffect(() => {
    window.location.href = 'https://jobs.lever.co/Betstamp';
    const to = setTimeout(() => {
      setShowFallback(true);
    }, 1000);

    return () => clearTimeout(to);
  }, []);

  if (!showFallback)
    return (
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'white',
        }}
      />
    );

  return (
    <div>
      <Hero>
        <div className="container" style={{ minHeight: 0 }}>
          <h1>Careers at betstamp</h1>
          <p>
            betstamp is the #1 sports betting tracker / analysis platform in the
            sports betting industry. Enjoy working with sports or analyzing
            sports data? Check out betstamp.app or the betstamp app on the App
            stores to discover betstamp yourself.
            <br />
            <br />
            At betstamp, we are looking to establish a brand that is viewed as
            authentic and trustworthy in the sports betting space. As a company
            that is growing quickly, we are looking to hire creative people who
            are knowledgeable in the sports betting space to join a rapidly
            evolving team.
          </p>
        </div>
      </Hero>
      <p>
        See open positions <a href="https://jobs.lever.co/Betstamp">here</a>
      </p>
    </div>
  );
}
