// Competitions on the accounting screen
export const GET_COMPETITIONS = 'GET_COMPETITIONS';
export const GET_COMPETITION = 'GET_COMPETITION';
export const GET_COMPETITION_LEADERBOARD = 'GET_COMPETITION_LEADERBOARD';

export const GET_COMPETITION_LEADERBOARD_SUCCESS =
  'GET_COMPETITION_LEADERBOARD_SUCCESS';
export const GET_COMPETITION_LEADERBOARD_FAILURE =
  'GET_COMPETITION_LEADERBOARD_FAILURE';
export const GET_COMPETITIONS_SUCCESS = 'GET_COMPETITIONS_SUCCESS';
export const GET_COMPETITIONS_FAILURE = 'GET_COMPETITIONS_FAILURE';
export const GET_COMPETITION_SUCCESS = 'GET_COMPETITION_SUCCESS';
export const GET_COMPETITION_FAILURE = 'GET_COMPETITION_FAILURE';

export const getCompetitions = (data) => ({
  type: GET_COMPETITIONS,
});

export const getCompetitionsSuccess = (competitions) => ({
  type: GET_COMPETITIONS_SUCCESS,
  payload: competitions,
});

export const getCompetitionsFailure = (error) => ({
  type: GET_COMPETITIONS_FAILURE,
  error,
});

export const getCompetition = (competition_id) => ({
  type: GET_COMPETITION,
  competition_id,
});

export const getCompetitionSuccess = (competition) => ({
  type: GET_COMPETITION_SUCCESS,
  payload: competition,
});

export const getCompetitionFailure = (error) => ({
  type: GET_COMPETITION_FAILURE,
  error,
});

export const getCompetitionLeaderboard = (
  limit,
  sport,
  order_by,
  date_after,
  date_before,
  only_following,
  sample_size,
  competition_id
) => ({
  type: GET_COMPETITION_LEADERBOARD,
  limit,
  sport,
  order_by,
  date_after,
  date_before,
  only_following,
  sample_size,
  competition_id,
});

export const getCompetitionLeaderboardSuccess = (leaderboard) => ({
  type: GET_COMPETITION_LEADERBOARD_SUCCESS,
  payload: leaderboard,
});

export const getCompetitionLeaderboardFailure = (error) => ({
  type: GET_COMPETITION_LEADERBOARD_FAILURE,
  error,
});
