// actions (excluding success and failure actions):
export const ADD_BET = 'ADD_BET';
export const REMOVE_ALL_BETS = 'REMOVE_ALL_BETS';
export const REMOVE_ONE_BET = 'REMOVE_ONE_BET';
export const CHANGE_BETSLIP_MODE = 'CHANGE_BETSLIP_MODE';
export const EDIT_BETSLIP_BET = 'EDIT_BETSLIP_BET';
export const RISK_BASE_FOR_ALL = 'RISK_BASE_FOR_ALL';
export const ADD_TAG_TO_ALL = 'TAGS_FOR_ALL';
export const TRACK_BETSLIP = 'TRACK_BETSLIP';
export const TRACK_BETSLIP_SUCCESS = 'TRACK_BETSLIP_SUCCESS';
export const TRACK_BETSLIP_FAILURE = 'TRACK_BETSLIP_FAILURE';
export const SET_PARLAY_VALUE = 'SET_PARLAY_VALUE';
export const BETSLIP_CHANGE_BOOK_PARLAY = 'BETSLIP_CHANGE_BOOK_PARLAY';
export const BETSLIP_CHANGE_BOOK_PARLAY_SUCCESS =
  'BETSLIP_CHANGE_BOOK_PARLAY_SUCCESS';
export const BETSLIP_CHANGE_BOOK_PARLAY_FAILURE =
  'BETSLIP_CHANGE_BOOK_PARLAY_FAILURE';
export const BETSLIP_CHANGE_BOOK_BET = 'BETSLIP_CHANGE_BOOK_BET';
export const BETSLIP_CHANGE_BOOK_BET_SUCCESS =
  'BETSLIP_CHANGE_BOOK_BET_SUCCESS';
export const BETSLIP_CHANGE_BOOK_BET_FAILURE =
  'BETSLIP_CHANGE_BOOK_BET_FAILURE';
export const REUSE_BETS = 'REUSE_BETS';
export const CLEAR_TRACKED_BETS = 'CLEAR_TRACKED_BETS';
export const PLACE_BET = 'PLACE_BET';
export const PLACE_BET_SUCCESS = 'PLACE_BET_SUCCESS';
export const PLACE_BET_FAILURE = 'PLACE_BET_FAILURE';

export const addBet = data => ({
  type: ADD_BET,
  data,
});

export const removeAllBets = () => ({
  type: REMOVE_ALL_BETS,
});

export const removeOneBet = bet => ({
  type: REMOVE_ONE_BET,
  bet,
});

export const changeBetslipMode = (mode, riskOrBase, betSize) => ({
  type: CHANGE_BETSLIP_MODE,
  mode,
  riskOrBase,
  betSize,
});

export const editBetslipBet = (index, changes, whatJustChanged, recalc) => ({
  type: EDIT_BETSLIP_BET,
  index,
  changes,
  whatJustChanged,
  recalc,
});

export const riskBaseForAll = (riskOrBase, betSize) => ({
  type: RISK_BASE_FOR_ALL,
  riskOrBase,
  betSize,
});

export const addTagToAll = tag => ({
  type: ADD_TAG_TO_ALL,
  tag,
});

export const trackBetslip = () => ({
  type: TRACK_BETSLIP,
});

export const trackBetslipSuccess = payload => ({
  type: TRACK_BETSLIP_SUCCESS,
  payload,
});

export const trackBetslipFailure = error => ({
  type: TRACK_BETSLIP_FAILURE,
  error,
});

export const placeBet = bet => ({
  type: PLACE_BET,
  bet,
});

export const placeBetSuccess = payload => ({
  type: PLACE_BET_SUCCESS,
  payload,
});

export const placeBetFailure = error => ({
  type: PLACE_BET_FAILURE,
  error,
});

export const setParlayValue = (item, value) => ({
  type: SET_PARLAY_VALUE,
  item,
  value,
});

export const betslipChangeBookParlay = book => ({
  type: BETSLIP_CHANGE_BOOK_PARLAY,
  book,
});

export const betslipChangeBookParlaySuccess = payload => ({
  type: BETSLIP_CHANGE_BOOK_PARLAY_SUCCESS,
  payload,
});

export const betslipChangeBookParlayFailure = error => ({
  type: BETSLIP_CHANGE_BOOK_PARLAY_FAILURE,
  error,
});

export const betslipChangeBookBet = (book, index) => ({
  type: BETSLIP_CHANGE_BOOK_BET,
  book,
  index,
});

export const betslipChangeBookBetSuccess = (payload, index) => ({
  type: BETSLIP_CHANGE_BOOK_BET_SUCCESS,
  payload,
  index,
});

export const betslipChangeBookBetFailure = (error, index) => ({
  type: BETSLIP_CHANGE_BOOK_BET_FAILURE,
  error,
  index,
});

export const reuseBets = () => ({ type: REUSE_BETS });

export const clearTrackedBets = () => ({ type: CLEAR_TRACKED_BETS });
