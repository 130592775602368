import { combineEpics } from 'redux-observable';

import getAffiliates from 'epics/affiliates/getAffiliates.epic';
import getSportsbooks from 'epics/affiliates/getSportsbooks.epic';
import getSportsbookReview from 'epics/affiliates/getSportsbookReview';
import getSportsbookInfoMap from 'epics/affiliates/getSportsbookInfoMap.epic';

export default combineEpics(
  getAffiliates,
  getSportsbooks,
  getSportsbookReview,
  getSportsbookInfoMap
);
