import React, { useState } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { useDebounce, useUpdateEffect } from 'react-use';
import { IoSearch, IoFilter, IoCheckmark } from 'react-icons/io5';

// components
import { AuthTextInput } from 'components/AuthTextInput';
import { AuthButton, IconButton } from 'components/AuthButton';
import ActivityIndicator from './generic/ActivityIndicator';

// actions
import { getSched, getOddscomp, setGameSortBy, setGameFilter } from 'actions';

const Popup = styled.div`
  flex: 1;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-around;
  align-items: center;
  position: absolute;
  margin-left: -47px;
  top: 50px;
  width: 250px;
  background-color: var(--color-fg);
  border-radius: var(--std-border-radius);
  box-shadow: 0 2px 8px 0 var(--color-shadow);
  box-sizing: border-box;
  padding: var(--space-xs) 0;
  z-index: 9999;
  &:after {
    content: '';
    display: block;
    position: absolute;
    left: 10px;
    bottom: 100%;
    width: 0;
    height: 0;
    border-bottom: 10px solid var(--color-fg);
    border-top: 10px solid transparent;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
  }

  max-height: 500px;
  @keyframes AnimateOpen {
    0% {
      max-height: 0;
      opacity: 0;
      top: 75px;
    }
    100% {
      max-height: 500px;
      opacity: 1;
      top: 50px;
    }
  }
  animation-name: AnimateOpen;
  animation-duration: 333ms;
  animation-timing-function: ease-in-out;
`;

const Btn = styled.button`
  flex: 1;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-size: var(--text-base-size);
  align-self: flex-start;
  text-align: left;
  text-decoration: none;
  outline: none;
  transition: all var(--std-transition);
  cursor: pointer;
  color: var(--text-color);
  border: none;
  box-sizing: border-box;
  padding: var(--space-sm) var(--space-xs);
  line-height: 1;

  background: ${props => {
    if (props.selected) {
      return 'var(--color-bg)';
    }
    return 'transparent';
  }};

  &:hover {
    background-color: var(--color-bg);
  }

  &:active {
    opacity: 0.5;
    box-shadow: inset 0 0 0 100vh var(--color-bg);
  }
`;

export default function SearchBarGames(props) {
  const dispatch = useDispatch();
  const reduxProps = useSelector(state => ({
    SCHED_showingDate: state.scheduleReducer.showingDate,
    ODDS_showingDate: state.oddscompReducer.showingDate,
    defaultGameSortBy: state.settingsReducer.defaultGameSortBy,
    defaultGameFilter: state.settingsReducer.defaultGameFilter,
    gameSortBy: state.settingsReducer.gameSortBy,
    gameFilter: state.settingsReducer.gameFilter,
    isSearchingOrFiltering: state.scheduleReducer.isSearchingOrFiltering,
    SCHED_searchterm: state.scheduleReducer.searchterm,
    ODDS_searchterm: state.oddscompReducer.searchterm,
  }));
  const {
    isSearchingOrFiltering,
    ODDS_showingDate,
    SCHED_showingDate,
    defaultGameSortBy,
    defaultGameFilter,
    gameSortBy,
    gameFilter,
    ODDS_searchterm,
    SCHED_searchterm,
  } = reduxProps;
  const { schedule, oddscomp } = props;

  let searchterm, showingDate;
  if (schedule) {
    searchterm = SCHED_searchterm;
    showingDate = SCHED_showingDate;
  } else if (oddscomp) {
    searchterm = ODDS_searchterm;
    showingDate = ODDS_showingDate;
  }

  useUpdateEffect(() => {
    if (searchterm === '') {
      setSearchTerm(searchterm);
    }
  }, [searchterm]);

  const [searchTerm, setSearchTerm] = useState(null);
  const [filterOpen, setFilterOpen] = useState(false);
  const [typing, setTyping] = useState(false);

  useDebounce(
    () => {
      if (searchTerm !== null) {
        _dispatchGetSchedOrOddscomp(showingDate, searchTerm);
        setTyping(false);
      }
    },
    250,
    [searchTerm]
  );

  const currGameSortBy = gameSortBy ? gameSortBy : defaultGameSortBy;
  const currGameFilter = gameFilter ? gameFilter : defaultGameFilter;

  const _dispatchAfterFilter = () => {
    let forcedST = searchTerm ? searchTerm : '';
    _dispatchGetSchedOrOddscomp(showingDate, forcedST);
    setFilterOpen(false);
  };

  const _dispatchGetSchedOrOddscomp = (showingDate, searchterm) => {
    if (schedule) {
      dispatch(getSched(null, showingDate, null, searchterm, false));
    } else if (oddscomp) {
      dispatch(getOddscomp(null, showingDate, null, searchTerm, false));
    }
  };

  return (
    <>
      <AuthTextInput
        leftIcon={IoSearch}
        type="text"
        placeholder="Search"
        inputRowStyle={{ flexWrap: 'nowrap' }}
        containerStyle={{
          flex: 1,
          marginRight: 'var(--space-sm)',
        }}
        value={searchTerm === null ? '' : searchTerm}
        onChangeText={newSearchVal => {
          setTyping(true);
          setSearchTerm(newSearchVal);
        }}
      />
      {(typing || isSearchingOrFiltering) && <ActivityIndicator />}
      {Boolean(searchTerm) && (
        <AuthButton
          containerStyle={{
            maxWidth: '6rem',
          }}
          btnTheme="borderless"
          onPress={() => setSearchTerm('')}
        >
          CLEAR
        </AuthButton>
      )}
      <IconButton
        iconName={IoFilter}
        iconSize="22px"
        iconColor={'var(--color-text)'}
        containerStyle={{
          padding: '0 var(--space-xxs)',
        }}
        onPress={() => setFilterOpen(!filterOpen)}
      />
      <div>
        {filterOpen && (
          <Popup>
            <span style={{ opacity: 0.75 }}>Sort By</span>
            <FilterOption
              onClick={() => {
                dispatch(setGameSortBy('date'));
                _dispatchAfterFilter();
              }}
              text={`Game Start Time${
                defaultGameSortBy === 'date' ? ' (default)' : ''
              }`}
              checkedCondition={currGameSortBy === 'date'}
            />
            <FilterOption
              onClick={() => {
                dispatch(setGameSortBy('home_rotation_number'));
                _dispatchAfterFilter();
              }}
              text={`Rotation Number${
                defaultGameSortBy === 'home_rotation_number' ? ' (default)' : ''
              }`}
              checkedCondition={currGameSortBy === 'home_rotation_number'}
            />

            <span style={{ opacity: 0.75, marginTop: 'var(--space-xxs)' }}>
              Filter By
            </span>
            <FilterOption
              onClick={() => {
                dispatch(setGameFilter('all_games'));
                _dispatchAfterFilter();
              }}
              text={`All Games${
                defaultGameFilter === 'all_games' ? ' (default)' : ''
              }`}
              checkedCondition={currGameFilter === 'all_games'}
            />
            <FilterOption
              onClick={() => {
                dispatch(setGameFilter('only_live_games'));
                _dispatchAfterFilter();
              }}
              text={`Only Live Games${
                defaultGameFilter === 'only_live_games' ? ' (default)' : ''
              }`}
              checkedCondition={currGameFilter === 'only_live_games'}
            />
            <FilterOption
              onClick={() => {
                dispatch(setGameFilter('only_unstarted_games'));
                _dispatchAfterFilter();
              }}
              text={`Only Unstarted Games${
                defaultGameFilter === 'only_unstarted_games' ? ' (default)' : ''
              }`}
              checkedCondition={currGameFilter === 'only_unstarted_games'}
            />
          </Popup>
        )}
      </div>
    </>
  );
}

function FilterOption(props) {
  const { text, checkedCondition } = props;
  return (
    <Btn selected={checkedCondition} onClick={props.onClick}>
      {text}
      {checkedCondition && (
        <IoCheckmark
          style={{
            color: 'var(--color-success',
            fontSize: 'var(--text-md)',
          }}
        />
      )}
    </Btn>
  );
}
