import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.button`
  border: none;
  background-color: var(--color-primary);
  position: fixed;
  bottom: 20px;
  right: 24px;
  outline: none;
  flex: 1;
  justify-content: center;
  color: white;
  width: 56px;
  height: 56px;
  border-radius: 32px;
  cursor: pointer;
  opacity: 1;
  box-shadow: 0 2px 8px var(--color-shadow);
  transition: all var(--std-transition);
  &:hover {
    box-shadow: inset 0 0 0 100vh rgba(255, 255, 255, 0.1);
  }
  &:active {
    box-shadow: inset 0 0 0 100vh rgba(0, 0, 0, 0.1);
  }
  z-index: 999;
  @media only screen and (max-width: 625px) {
    // bottom nav height is 56
    bottom: 64px;
    right: 8px;
  }
`;

export default function FAB(props) {
  return (
    <Wrapper onClick={() => props.onClick()} style={props.style}>
      {props.children}
    </Wrapper>
  );
}
