export const GET_FIND_BETS = 'GET_FIND_BETS';
export const GET_FIND_BETS_SUCCESS = 'GET_FIND_BETS_SUCCESS';
export const GET_FIND_BETS_FAILURE = 'GET_FIND_BETS_FAILURE';
export const GET_FIND_BETS_NEXT = 'GET_FIND_BETS_NEXT';

export const getFindBets = ({
  book_ids,
  leagues,
  periods,
  date,
  odd_types,
  game_ids,
}) => ({
  type: GET_FIND_BETS,
  book_ids,
  leagues,
  periods,
  date,
  odd_types,
  game_ids,
});
export const getFindBetsSuccess = payload => ({
  type: GET_FIND_BETS_SUCCESS,
  payload,
});
export const getFindBetsFailure = error => ({
  type: GET_FIND_BETS_FAILURE,
  error,
});
export const getFindBetsNext = () => ({
  type: GET_FIND_BETS_NEXT,
});
