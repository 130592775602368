import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { IoIosLock } from 'react-icons/io';
import InfiniteScroll from 'react-infinite-scroll-component';

// utils for constants
import { DEFAULT_FETCH_COUNT } from '../../utils/index';

// components
import ActivityIndicator from 'components/generic/ActivityIndicator';
import { Row, Col } from 'components/generic/Layout';
import ErrorDisplay from 'components/generic/ErrorDisplay';

// actions
import { getWagers, getWagersNext } from 'actions';
import Wager from 'components/Wager';

const pickMap = {
  userHistory: 'history',
  userPending: 'pending',
  publicUserPending: 'pending',
  publicUserHistory: 'history',
  pendingToday: 'pending',
  gradedToday: 'history',
  historicals: 'history',
  allPending: 'pending',

  todaysAction: "today's",
  hidden: 'hidden',
};

const listEmptyTextMap = {
  userPending: 'No pending wagers, check back later!',
  userHistory: 'No wager history yet, check back later!',
  publicUserPending: 'No pending wagers, check back later!',
  publicUserHistory: 'No wager history yet, check back later!',
  pendingToday: 'Nothing pending today',
  gradedToday: 'Nothing graded today',
  allPending: 'Nothing pending',
  historicals: 'No graded wagers for this time period',

  todaysAction: 'No pending wagers',
  hidden: 'No wagers hidden from personal tracking in this time period',
};

const listEndTextMap = {
  userPending: 'No more pending wagers',
  userHistory: 'No more bet history',
  publicUserPending: 'No more pending wagers',
  publicUserHistory: 'No more bet history',
  pendingToday: '',
  gradedToday: '',
  allPending: '',
  historicals: '',

  todaysAction: '',
};

export default function UserPicks(props) {
  const {
    publicProfile_id,
    viewingOtherUser,
    showPublicBets,
    screen,
    graded_only,
    hidePendingPlays,
    publicProfile_name,
    publicProfile_pending,
    isDesktop,
  } = props;

  const dispatch = useDispatch();
  const reduxProps = useSelector(state => ({
    publicProfile: state.publicUserReducer.publicProfile,
    wagers: state.wagerReducer.wagers[screen],
    isWagersLoading: state.wagerReducer.isWagersLoading[screen],
    wagersError: state.wagerReducer.wagersError[screen],
    wagersNext: state.wagerReducer.wagersNext[screen],
    isWagersNextLoading: state.wagerReducer.isWagersNextLoading[screen],
  }));

  const {
    publicProfile,
    wagers,
    isWagersLoading,
    wagersError,
    wagersNext,
    isWagersNextLoading,
  } = reduxProps;

  const [forcedNextPage, setForcedNextPage] = useState(false);
  const [fetchCount, setFetchCount] = useState(DEFAULT_FETCH_COUNT);

  const _getWagersNext = () => {
    if (wagersNext) {
      if (!forcedNextPage) setForcedNextPage(true);
      dispatch(getWagersNext(screen));
    }
  };

  useEffect(() => {
    if (isDesktop && wagersNext && !isWagersNextLoading && fetchCount > 0) {
      dispatch(getWagersNext(screen));
      setFetchCount(fetchCount - 1);
    }
  }, [wagersNext, isWagersNextLoading, fetchCount]);

  useEffect(() => {
    // User may change date range => refresh state variables for handling long screens
    if (isWagersLoading) {
      setFetchCount(DEFAULT_FETCH_COUNT);
      setForcedNextPage(false);
    }
  }, [isWagersLoading]);

  // picksMap declare above function
  const pickType = pickMap[screen];

  if (wagersError) {
    return (
      <div style={{ flex: 1, backgroundColor: 'var(--color-bg)' }}>
        <ErrorDisplay
          error={wagersError}
          message={`getting your ${pickType} picks.`}
          retry={() =>
            dispatch(
              getWagers({
                screen: screen,
                is_graded: graded_only,
                user: publicProfile_id,
              })
            )
          }
        />
      </div>
    );
  }

  if (hidePendingPlays && viewingOtherUser && pickType === 'pending') {
    return (
      <Col>
        <IoIosLock
          style={{
            fontSize: 'var(--text-xxxxl)',
            padding: 'var(--space-md) 0',
          }}
          title="Buy this user's package to view their picks"
        />
        {publicProfile.hide_pending_bets ? (
          <p style={{ maxWidth: '460px', textAlign: 'center' }}>
            {publicProfile_name} has opted to hide their pending picks.
          </p>
        ) : (
          <>
            <h5 style={{ margin: 0 }}>{publicProfile_pending} Pending</h5>
            <p style={{ maxWidth: '460px', textAlign: 'center' }}>
              Purchasing a package unlocks access to all of the verified picks
              posted by <b>{publicProfile_name}</b>. Download the betstamp app
              and enable push notifications to receive picks in real time.
            </p>
          </>
        )}
      </Col>
    );
  }

  return (
    <InfiniteScroll
      scrollableTarget={props.scrollableTarget || 'infinite-scroll-target'}
      scrollThreshold={0.75}
      next={_getWagersNext}
      hasMore={wagersNext}
      dataLength={wagers.length}
      loader={<ActivityIndicator size={1.5} />}
      style={{
        overflow: 'hidden',
        // minHeight: '100vh',
        padding: showPublicBets ? '0 var(--space-xxs)' : '0',
      }}
      endMessage={
        <>
          <Row desktop>
            <h6 style={{ textAlign: 'center', margin: 'var(--space-md) 0' }}>
              {isWagersLoading ? (
                <ActivityIndicator size={2} />
              ) : (
                <>
                  {wagers.length === 0
                    ? listEmptyTextMap[screen]
                    : listEndTextMap[screen]}
                </>
              )}
            </h6>
          </Row>
          <Row mobile>
            <span style={{ textAlign: 'center', marginTop: 'var(--space-sm)' }}>
              {isWagersLoading ? (
                <ActivityIndicator size={2} />
              ) : (
                <>
                  {wagers.length === 0
                    ? listEmptyTextMap[screen]
                    : listEndTextMap[screen]}
                </>
              )}
            </span>
          </Row>
        </>
      }
    >
      {wagers.map(wager => (
        <Wager
          key={wager.id}
          wager={wager}
          disableModal={['publicUserPending', 'publicUserHistory'].includes(
            screen
          )}
          viewingOtherUser={viewingOtherUser}
        />
      ))}
      {wagers.length > 0 && isWagersNextLoading && !forcedNextPage && (
        <ActivityIndicator size={1.5} />
      )}
    </InfiniteScroll>
  );
}
