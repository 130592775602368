import React, { useEffect, useState } from 'react';
import { IoPencilSharp, IoAdd } from 'react-icons/io5';

// components
import ModalWrapper from 'components/generic/ModalWrapper';
import { Row } from 'components/generic/Layout';
import Textarea from './generic/Textarea';
import { AuthButton } from 'components/AuthButton';

import { AUTH_BUTTON_DEFAULT_CONTAINER_STYLE } from 'utils';

export default function AddEditDescription(props) {
  // view edit add
  const [showModal, setShowModal] = useState(false);
  const [desc, setDesc] = useState(props.defaultDescription);

  useEffect(() => {
    setDesc(props.defaultDescription);
  }, [props.defaultDescription]);

  return (
    <>
      {showModal && (
        <ModalWrapper
          modalIsOpen={showModal}
          onClose={() => {
            setShowModal(false);
          }}
          onRequestClose={() => {
            setShowModal(false);
          }}
          modalSize={'small'}
          title={'Breakdown'}
        >
          <AddEditModal
            showModal={showModal}
            dismissModal={() => setShowModal(false)}
            defaultValue={desc}
            onDone={breakdown => {
              setDesc(breakdown);
              props.onDescChange(breakdown);
              setShowModal(false);
            }}
          />
        </ModalWrapper>
      )}
      <Row style={{ justifyContent: 'center', alignItems: 'center' }}>
        {desc.trim() === '' ? (
          <Row style={{ justifyContent: 'space-between' }}>
            <AuthButton
              leftIcon={IoAdd}
              leftIconSize={18}
              onPress={() => {
                setShowModal(true);
              }}
            >
              Add Breakdown
            </AuthButton>
          </Row>
        ) : (
          <>
            <b>Breakdown:</b>
            <span style={{ display: 'flex', alignItems: 'center' }}>
              {props.truncateDesc
                ? `${desc.substr(0, 128)}${desc.length > 128 ? '...' : ''}`
                : desc}
              <AuthButton
                btnTheme="borderless"
                leftIcon={IoPencilSharp}
                leftIconSize={19}
                onPress={() => {
                  setShowModal(true);
                }}
              >
                Edit
              </AuthButton>
            </span>
          </>
        )}
      </Row>
    </>
  );
}

function AddEditModal(props) {
  const [breakdown, setBreakdown] = useState(
    props.defaultValue ? props.defaultValue : ''
  );

  return (
    <>
      <Textarea
        inputStyle={{ backgroundColor: 'var(--color-bg)' }}
        maxLength={1024}
        rows={10}
        autoFocus={true}
        defaultValue={breakdown}
        onChange={input => setBreakdown(input.target.value)}
      />
      <br />
      <Row
        style={{
          flexFlow: 'row nowrap',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        {breakdown.length > 0 && (
          <AuthButton
            colorTheme="danger"
            btnTheme="slim"
            containerStyle={{
              ...AUTH_BUTTON_DEFAULT_CONTAINER_STYLE,
              maxWidth: 'fit-content',
            }}
            onPress={() => props.onDone('')}
          >
            Delete
          </AuthButton>
        )}
        <Row style={{ justifyContent: 'flex-end' }}>
          <AuthButton
            btnTheme="borderless"
            containerStyle={{
              ...AUTH_BUTTON_DEFAULT_CONTAINER_STYLE,
              maxWidth: 'fit-content',
            }}
            onPress={() => props.dismissModal()}
          >
            Cancel
          </AuthButton>
          <AuthButton
            containerStyle={{
              ...AUTH_BUTTON_DEFAULT_CONTAINER_STYLE,
              maxWidth: 'fit-content',
            }}
            colorTheme="inverted"
            onPress={() => {
              props.onDone(breakdown.trim());
            }}
          >
            Save
          </AuthButton>
        </Row>
        {/* {breakdown.length > 0 && (
          <AuthButton
            colorTheme="danger"
            containerStyle={{
              ...AUTH_BUTTON_DEFAULT_CONTAINER_STYLE,
            }}
            onPress={() => props.onDone('')}
          >
            Delete
          </AuthButton>
        )} */}
      </Row>
    </>
  );
}
