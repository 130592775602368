import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

// components
import { IoGiftOutline, IoCheckmarkCircleOutline } from 'react-icons/io5';
import { OnboardingWrapper } from 'components/auth/onboarding';
import { Col } from 'components/generic/Layout';
import { AuthTextInput } from 'components/AuthTextInput';
import ExperienceBox from 'components/auth/onboarding/ExperienceBox';

// actions
import {
  checkReferralCodeExists,
  updateUser,
  getAffiliates,
  getAllBooks,
  setTempUserStates,
} from 'actions';

export default function SelectExperience() {
  const dispatch = useDispatch();
  const history = useHistory();
  const reduxProps = useSelector(state => ({
    user: state.authReducer.user,
    tempUser: state.authReducer.tempUser,
    referralCodeExists: state.authReducer.referralCodeExists,
  }));
  const { user, referralCodeExists, tempUser } = reduxProps;

  const { experience_level, referral_code } = tempUser;
  const [newUser, setNewUser] = useState({
    experience_level: experience_level || user?.experience_level,
    referral_code,
  });
  const nextScreen = '/onboarding/odds-compare';

  let nextDisabled = !newUser.experience_level;
  if (!nextDisabled && newUser.referral_code) {
    nextDisabled = !referralCodeExists;
  }

  useEffect(() => {
    dispatch(getAllBooks());
    if (referralCodeExists) {
      dispatch(getAffiliates());
    }
  }, [user?.referral_code]);

  const _updateTempUserState = () =>
    dispatch(
      setTempUserStates({
        ...newUser,
      })
    );

  useEffect(
    // Update temp user states when users press forward browser button
    () =>
      history.listen(_ => {
        if (history.action === 'POP') {
          if (referralCodeExists) {
            dispatch(updateUser({ ...newUser }));
          } else {
            dispatch(
              updateUser({ experience_level: newUser.experience_level })
            );
          }

          _updateTempUserState();
        }
      }),
    [newUser, history]
  );

  return (
    <OnboardingWrapper
      nextScreen={nextScreen}
      onNext={() => {
        dispatch(updateUser({ ...newUser }));
        _updateTempUserState();
      }}
      nextDisabled={nextDisabled}
    >
      <span
        style={{
          marginTop: 'var(--space-sm)',
          textAlign: 'center',
          alignSelf: 'center',
        }}
      >
        Let us tailor betstamp to your betting style.
      </span>
      <span
        style={{
          marginBottom: 'var(--space-sm)',
          fontWeight: 'bold',
          textAlign: 'center',
          alignSelf: 'center',
        }}
      >
        Select the one that best describes you
      </span>
      <Col style={{ flex: 0 }}>
        <ExperienceBox
          exp="Rookie"
          onPress={() => setNewUser({ ...newUser, experience_level: 'Rookie' })}
          userExp={newUser.experience_level}
        />
        <ExperienceBox
          exp="Experienced"
          onPress={() =>
            setNewUser({ ...newUser, experience_level: 'Experienced' })
          }
          userExp={newUser.experience_level}
        />
        <ExperienceBox
          exp="Professional"
          onPress={() =>
            setNewUser({ ...newUser, experience_level: 'Professional' })
          }
          userExp={newUser.experience_level}
        />
        {user.referral_code === null && (
          <AuthTextInput
            label="Did somebody refer you?"
            leftIcon={IoGiftOutline}
            leftIconColor={'var(--color-primary)'}
            placeholder="Referral code"
            onChangeText={text => {
              dispatch(checkReferralCodeExists(text));
              setNewUser({
                ...newUser,
                referral_code: text.toUpperCase(),
              });
            }}
            errorText={
              newUser.referral_code !== '' &&
              referralCodeExists === false &&
              "Referral code doesn't exist"
            }
            rightIcon={referralCodeExists ? IoCheckmarkCircleOutline : null}
            rightIconColor={'var(--color-success)'}
            autoCapitalize={'characters'}
            value={newUser.referral_code || ''}
          />
        )}
      </Col>
    </OnboardingWrapper>
  );
}
