import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  IoLogoGoogle,
  IoLogoTwitter,
  IoLogoApple,
  IoLogoReact,
  IoArrowForward,
  IoPersonCircleOutline,
  IoCheckmarkCircleOutline,
  IoMailOutline,
} from 'react-icons/io5';

import Analytics from 'amplitude/Analytics';

import ErrorDisplay from 'components/generic/ErrorDisplay';
import { AuthButton } from 'components/AuthButton';
import { OnboardingWrapper } from 'components/auth/onboarding';
import { Row, Col } from 'components/generic/Layout';
import { BetstampStamp } from 'components/generic/Logos';
import { AuthTextInput } from 'components/AuthTextInput';
import Select from 'components/generic/Select';
import ActivityIndicator from 'components/generic/ActivityIndicator';

// utils
import { isEmailValid, STATE_OPTIONS } from 'utils';

// actions
import {
  checkEmailAvailable,
  resetErrors,
  checkUsernameAvailable,
  updateUser,
} from 'actions';

export default function AccountCreated(props) {
  const dispatch = useDispatch();
  const reduxProps = useSelector(state => ({
    user: state.authReducer.user,
    userToken: state.authReducer.userToken,
    socialLoginFailure: state.authReducer.socialLoginFailure,
    socialLoginSuccess: state.authReducer.socialLoginSuccess,
    usernameIsAvailable: state.authReducer.usernameIsAvailable,
    isSocialAuthingUser: state.authReducer.isSocialAuthingUser,
    createUserSuccess: state.authReducer.createUserSuccess,
    createUserFailure: state.authReducer.createUserFailure,
    emailIsAvailable: state.authReducer.emailIsAvailable,
    theme: state.settingsReducer.theme,
  }));

  const {
    user,
    isSocialAuthingUser,
    socialLoginSuccess,
    socialLoginFailure,
    createUserFailure,
    usernameIsAvailable,
    emailIsAvailable,
    theme,
  } = reduxProps;

  // for a social-authed user, they need to set username and region
  const [newUser, setNewUser] = useState({
    is_public: true,
    accepted_TC: true,
    username: user?.username || '',
    referral_code: props.referral_code,
  });
  const [valids, setValids] = useState({
    // server will auto-set a username, so it's default valid in the social auth case
    username: true,
  });

  const [tracked, setTracked] = useState({});
  const [forceEmailInvalid, setForceEmailInvalid] = useState(null);
  const [requireEmail, setRequireEmail] = useState(false);
  useEffect(() => {
    if (socialLoginSuccess && user) {
      setRequireEmail(user.email === null);
    }
  });

  const _validateEmail = email => {
    email = email.trim();
    const valid = isEmailValid(email);
    setValids({ ...valids, email: valid });

    let userObject = { ...newUser, email: email };

    if (valid) {
      setForceEmailInvalid(null);
      dispatch(checkEmailAvailable(email));
    }

    setNewUser(userObject);
  };

  const _validateUsername = username => {
    let valid = true;
    if (!username || username.trim() === '') valid = false;
    if (!username || username.length > 16) valid = false;
    setValids({ ...valids, username: valid });
    setNewUser({ ...newUser, username: username });

    if (valid) {
      dispatch(checkUsernameAvailable(username));
    }
  };

  if (socialLoginFailure || createUserFailure) {
    return (
      <OnboardingWrapper hideNext>
        <Row style={{ flex: 0, width: '100%' }}>
          <ErrorDisplay error={socialLoginFailure || createUserFailure} />
        </Row>
        <AuthButton
          btnTheme="borderless"
          onPress={() => {
            dispatch(resetErrors());
          }}
          containerStyle={{
            display: 'flex',
            alignItems: 'flex-end',
            width: '50%',
          }}
        >
          Go Back
        </AuthButton>
      </OnboardingWrapper>
    );
  }

  function getSocialAuthIcon(socialAccountProvider) {
    switch (socialAccountProvider) {
      case 'twitter':
        return IoLogoTwitter;
      case 'google':
        return IoLogoGoogle;
      case 'apple':
        return IoLogoApple;
      default:
        return IoLogoReact;
    }
  }

  if (socialLoginSuccess && user && user.social_accounts.length > 0) {
    let nextDisabled =
      !valids.username || !newUser.state || usernameIsAvailable !== '';
    if (requireEmail) {
      nextDisabled =
        nextDisabled ||
        newUser.email === null ||
        emailIsAvailable !== '' ||
        !valids.email;
    }

    const SocialAuthIcon = getSocialAuthIcon(
      user?.social_accounts[0]?.provider
    );

    return (
      <OnboardingWrapper
        nextScreen="/onboarding/select-experience"
        onNext={() => dispatch(updateUser(newUser))}
        nextDisabled={nextDisabled}
        hideBetstamp
        hideBack
      >
        <Row
          style={{
            justifyContent: 'flex-start',
            alignItems: 'center',
            flex: 0,
          }}
        >
          <Col style={{ flex: 0, position: 'relative' }}>
            <img
              style={{
                borderWidth: 1,
                borderColor: 'var(--color-primary)',
                backgroundColor: 'var(--color-bg)',
                borderRadius: 100,
                width: 80,
                height: 80,
              }}
              src={
                user?.profile_picture
                  ? user.profile_picture
                  : 'https://betstamp-public.sfo2.digitaloceanspaces.com/default-account.png'
              }
              alt={'Profile Pic'}
            />
            <SocialAuthIcon
              size={32}
              color={'var(--color-text)'}
              style={{
                position: 'absolute',
                backgroundColor: 'var(--color-fg)',
                padding: '--(space-xxxs)',
                bottom: -5,
                borderRadius: 32,
                left: 56,
              }}
            />
          </Col>
          <IoArrowForward
            size={64}
            color={'var(--color-text)'}
            style={{ opacity: 0.75, marginHorizontal: 'var(--space-xs)' }}
          />
          <BetstampStamp width={90} height={90} />
        </Row>
        <h2 style={{ textAlign: 'center' }}>Account Connected!</h2>
        <span style={{ textAlign: 'center' }}>
          Please set your username and region below to use betstamp
        </span>
        <Col style={{ width: 'min(100%, 500px)', flex: 0 }}>
          <AuthTextInput
            label="Username *"
            leftIcon={IoPersonCircleOutline}
            leftIconColor={
              theme === 'dark' ? 'var(--color-text)' : 'var(--color-primary)'
            }
            rightIcon={
              usernameIsAvailable === '' && valids.username
                ? IoCheckmarkCircleOutline
                : () => <div style={{ height: '24px', width: '48px' }}></div>
            }
            rightIconColor={'var(--color-success)'}
            errorText={usernameIsAvailable}
            placeholder={'example'}
            type="text"
            maxLength={16}
            value={newUser.username}
            onChangeText={text => {
              if (!tracked.manualEditUsername) {
                Analytics.track(Analytics.events.MANUALLY_EDIT_USERNAME);
                setTracked({ ...tracked, manualEditUsername: true });
              }
              _validateUsername(text);
            }}
          />
          {requireEmail && (
            <AuthTextInput
              label="Email *"
              leftIcon={IoMailOutline}
              leftIconColor={
                theme === 'dark' ? 'var(--color-text)' : 'var(--color-primary)'
              }
              rightIcon={
                emailIsAvailable === '' && valids.email
                  ? IoCheckmarkCircleOutline
                  : null
              }
              rightIconColor={'var(--color-success'}
              errorText={emailIsAvailable || forceEmailInvalid}
              placeholder={'example@gmail.com'}
              onChangeText={text => _validateEmail(text)}
              autoFocus={true}
              onEndEditing={() => {
                _validateUsername(newUser.username);
                if (!valids.email) setForceEmailInvalid('Invalid email');
              }}
              value={newUser.email || user.email || ''}
              type="email"
            />
          )}
          <span style={{ padding: '0 var(--space-sm)', width: '100%' }}>
            Region *{' '}
          </span>
          <Select
            options={STATE_OPTIONS.map(s => ({ label: s, value: s }))}
            onChange={opt => setNewUser({ ...newUser, state: opt.value })}
            value={
              newUser.state
                ? { label: newUser.state, value: newUser.state }
                : null
            }
          />
        </Col>
      </OnboardingWrapper>
    );
  }

  return (
    <OnboardingWrapper
      nextScreen="/onboarding/select-experience"
      hideNext
      hideBetstamp
      hideBack
    >
      <Col style={{ flex: 0, marginTop: 'var(--space-xl)' }}>
        <ActivityIndicator size={3} />
        <h2 style={{ textAlign: 'center', marginTop: 'var(--space-sm)' }}>
          {isSocialAuthingUser ? 'Connecting' : 'Creating'} Account
        </h2>
      </Col>
    </OnboardingWrapper>
  );
}
