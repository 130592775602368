import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useEffectOnce } from 'react-use';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { IoReorderTwoSharp, IoTrashOutline } from 'react-icons/io5';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

//components
import { AuthButton } from 'components/AuthButton';
import Books from 'components/modals/Books';
import { Row, Col } from 'components/generic/Layout';
import ActivityIndicator from 'components/generic/ActivityIndicator';

//actions
import { getAllBooks, postBooks } from 'actions';

const DragBook = styled.div`
  background-color: var(--color-fg);
  padding: var(--space-sm);
  margin-top: var(--space-md);
  margin-bottom: var(--space-sm);
  border-radius: var(--std-border-radius);
  flex: 1;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
`;

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: 'none',
  background: isDragging ? 'var(--color-text-light)' : 'var(--color-fg)',
  ...draggableStyle,
});

export default function SportsBooks(props) {
  const dispatch = useDispatch();
  const reduxProps = useSelector(state => ({
    user: state.authReducer.user,
    isLoading: state.authReducer.isLoading,
    allBooks: state.authReducer.allBooks,
    isLoadingAllBooks: state.authReducer.isLoadingAllBooks,
    allBooksError: state.authReducer.allBooksError,
  }));
  const { user, isLoading, allBooks, isLoadingAllBooks, allBooksError } =
    reduxProps;

  const [newBooks, setNewBooks] = useState(user ? user.books : []);
  const [showModal, setShowModal] = useState(false);

  useEffectOnce(() => {
    dispatch(getAllBooks());
  });

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = result => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const newOrder = reorder(
      newBooks,
      result.source.index,
      result.destination.index
    );

    setNewBooks(newOrder);
    dispatch(
      postBooks(
        newOrder.map(b => {
          if (typeof b.id !== 'number') {
            return b;
          }
          return b.id;
        })
      )
    );
  };

  if (!user || isLoading | isLoadingAllBooks || allBooks.length === 0) {
    return <ActivityIndicator size={2} />;
  }

  if (allBooksError) {
    return (
      <h6 style={{ textAlign: 'center' }}>
        Sorry, we ran into an error trying to get the list of books. Try again
        later!
      </h6>
    );
  }

  return (
    <Col
      style={{
        justifyContent: 'flex-start',
        alignItems: 'stretch',
        ...props.style,
      }}
    >
      <Books
        showModal={showModal}
        dismissModal={() => setShowModal(false)}
        initialBooks={newBooks}
        returnSelectedBooks={selectedBooks => {
          setNewBooks(selectedBooks);
          dispatch(
            postBooks(
              selectedBooks.map(b => {
                if (typeof b.id !== 'number') {
                  return b;
                }
                return b.id;
              })
            )
          );
          setShowModal(false);
        }}
      />

      <Row
        style={{
          alignItems: 'center',
          justifyContent: 'space-between',
          position: 'sticky',
          top: 0,
          backgroundColor: 'var(--color-bg)',
          zIndex: 101,
          borderBottom: '1px solid #9b9b9b',
        }}
      >
        <h3 style={{ margin: 0, flex: 2 }}>My Books</h3>
        <Col style={{ flex: 0.5 }}>
          <AuthButton
            containerStyle={{ margin: 0 }}
            btnTheme="borderless"
            onPress={() => setShowModal(true)}
          >
            + Add Book
          </AuthButton>
        </Col>
      </Row>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {newBooks.map((item, index) => (
                <Draggable
                  key={`draggable-book-key-${item.id}`}
                  draggableId={`draggable-book-id-${item.id}`}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <DragBook
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                    >
                      <IoReorderTwoSharp
                        size={24}
                        style={{ flex: '0 0 24px' }}
                      />
                      <h6 style={{ margin: 0, flex: 1 }}>
                        {item.name}
                        {index === 0 && (
                          <span style={{ color: 'var(--color-primary)' }}>
                            &nbsp;(Primary)
                          </span>
                        )}
                      </h6>
                      <IoTrashOutline
                        size={24}
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          const nb = newBooks.filter(b => b.id !== item.id);
                          // I think book deletion bug here caused by reference to book (instead of book.id) being ignored by filter.
                          setNewBooks(nb);
                          dispatch(
                            postBooks(
                              nb.map(b => {
                                if (typeof b.id !== 'number') {
                                  return b;
                                }
                                return b.id;
                              })
                            )
                          );
                        }}
                      />
                    </DragBook>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
        <br />
        {newBooks.length === 0 ? (
          <i>
            Select "+ Add Book" to personalize your betstamp odds with the
            sportsbooks you play at.
            <br /> Don't play at any sportsbooks? Head over to
            <Link to="/sportsbooks"> betstamp.app/sportsbooks </Link>
            to find out what sportsbook is for you.
          </i>
        ) : (
          <i>
            Drag the books to reorder them. This is the book order used to
            display odds.
          </i>
        )}
      </DragDropContext>
    </Col>
  );
}
