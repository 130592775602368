import { Observable } from 'rxjs';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import { ajax } from 'rxjs/observable/dom/ajax';
import { BACKEND_API_URL, getHeaders } from 'utils';

import {
  GET_FOLLOWERS,
  getFollowersSuccess,
  getFollowersFailure,
} from 'actions';

export default function getFollowers(action$, state$) {
  return action$.ofType(GET_FOLLOWERS).switchMap(action => {
    let end = action.user ? '?user=' + action.user : '';
    let url = 'api/users/get_followers/' + end;
    return ajax
      .getJSON(BACKEND_API_URL + url, getHeaders(state$))
      .map(data => getFollowersSuccess(data))
      .catch(error => Observable.of(getFollowersFailure(error.xhr)));
  });
}
