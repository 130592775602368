import { Observable } from 'rxjs';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import { ajax } from 'rxjs/observable/dom/ajax';
import { getHeaders } from 'utils';

import {
  GET_PLAYER_PROP_PROP_LIST_NEXT,
  getPlayerPropPropListSuccess,
  getPlayerPropPropListFailure,
} from 'actions';

export default function getPlayerPropPropListNext(action$, state$) {
  return action$.ofType(GET_PLAYER_PROP_PROP_LIST_NEXT).switchMap(() => {
    let url = state$.value.gameReducer.playerPropPropListNext;
    return ajax
      .getJSON(url, getHeaders(state$))
      .map(data => getPlayerPropPropListSuccess(data))
      .catch(error => Observable.of(getPlayerPropPropListFailure(error.xhr)));
  });
}
