import { Observable } from 'rxjs';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import { ajax } from 'rxjs/observable/dom/ajax';
import { BACKEND_API_URL, getHeaders } from 'utils';

import {
  GET_GAME_PROP_LIST,
  getGamePropListSuccess,
  getGamePropListFailure,
} from 'actions';

export default function getGamePropList(action$, state$) {
  return action$.ofType(GET_GAME_PROP_LIST).switchMap(action => {
    let url = BACKEND_API_URL + `api/game_prop_list/?game_id=${action.game_id}`;

    return ajax
      .getJSON(url, getHeaders(state$))
      .map(data => getGamePropListSuccess(data))
      .catch(error => Observable.of(getGamePropListFailure(error.xhr)));
  });
}
