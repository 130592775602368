// components
import StaffOnboardingBase from 'components/StaffOnboardingBase';
import { useSelector } from 'react-redux';
import { useState, useMemo } from 'react';
import {
  useQuery,
  useInfiniteQuery,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { Link } from 'react-router-dom';

// utils
import { humanDatetime, formatMoney } from 'utils';
import { AuthButton, IconButton } from 'components/AuthButton';
import { AuthTextInput } from 'components/AuthTextInput';
import Select from 'components/generic/Select';
import ActivityIndicator from 'components/generic/ActivityIndicator';
import CalendarInput from 'components/generic/CalendarInput';
import { Row, Col } from 'components/generic/Layout';
import GenericAdminFilter from 'components/onboarding/GenericAdminFilter';

const REASON_MAP = {
  SETUP_SESSION_INITIAL_TRANSFER: 'Setup Session Initial Transfer',
  POST_ONBOARDING_BONUS: 'Post onboarding bonus',
  BETSTAMP_101_BONUS: 'Betstamp 101 Bonus',
  GROUP_TARGET_BONUS: 'Group Target Bonus',
  MONTHLY_FEE: 'Monthly Fee',
  EVENT_FUNDING: 'Event Funding ',
  EVENT_FUNDING_BONUS: 'Event Funding Bonus',
  CHALLENGE_350: 'Challenge 350',
  SOCIAL_LADDER_CASHOUT: 'Social Ladder Cashout',
  RETURNED_MONEY: 'Returned Money',
  OTHER: 'Other',
};

export default function Payments(params) {
  return (
    <>
      <StaffOnboardingBase
        endpoint="onboarding-payments"
        modelName="Payment"
        headings={[
          'Reason',
          'Amount',
          'Attendee ID',
          'Referral Code',
          'Sender',
          'Bank',
          'Cancelled',
        ]}
        objKeys={[
          'reason',
          'amount',
          'attendee',
          'referral_code',
          'sender',
          'bank',
          'is_cancelled',
        ]}
        PanelChildren={Panel}
        formatText={{
          created_at: humanDatetime,
          date: humanDatetime,
          amount: (t, payment) => formatMoney(t, payment?.currency || 'CAD'),
          is_cancelled: t => (t ? 'Cancelled' : 'Not Cancelled'),
          referral_code: obj =>
            `${obj?.code || '-'} ${obj?.group?.name ? `(${obj?.group?.name})` : ''
            } - ${obj?.code_status || ''}` || '-',
        }}
        showSearch={true}
        searchPlaceholder="Search by attendee name, attendee id, sender (email), referral code"
        FilterChildren={FilterChildren}
      />
    </>
  );
}

function Panel(props) {
  const payment = props.selectedObj;
  const { tab } = props;
  return (
    <>
      {(tab === 'Edit' || payment.createNew) && (
        <CreatePayment payment={payment} closePanel={props.closePanel} />
      )}
    </>
  );
}

function CreatePayment(props) {
  const reduxProps = useSelector(state => ({ user: state.authReducer.user }));
  const { user } = reduxProps;

  const { payment } = props;
  const [formData, setFormData] = useState(
    payment.createNew
      ? {}
      : { ...payment, referral_code_code: payment?.referral_code?.code }
  );
  const refCodeExists = useQuery({
    refetchOnWindowFocus: false,
    enabled: !!formData.referral_code_code,
    queryKey: [
      {
        endpoint: 'referral_codes/exists',
        urlParams: { code: formData.referral_code_code },
      },
    ],
  });

  const queryClient = useQueryClient();
  const submitPayment = useMutation({
    mutationKey: `onboarding-create-payment`,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [
          {
            endpoint: 'onboarding-payments',
          },
        ],
      });
      props.closePanel();
    },
  });

  const submitDisabled =
    !formData.referral_code_code ||
    !formData.amount ||
    formData.amount === 0 ||
    !formData.bank ||
    !formData.reason;

  return (
    <form
      style={{ width: '100%' }}
      onSubmit={ev => {
        ev.preventDefault();
        const fd = new FormData(ev.target);
        fd.set('date', fd.get('date') + 'T12:00:00.000Z');
        submitPayment.mutate({
          endpoint: `onboarding-payments${payment.createNew ? '' : '/' + payment.id
            }`,
          body: fd,
        });
      }}
    >
      <AuthTextInput
        placeholder="Add referral/promo code"
        label="Referral Code *"
        name="referral_code_code"
        defaultValue={formData?.referral_code_code}
        onChangeText={text => {
          setFormData({ ...formData, referral_code_code: text });
        }}
        errorText={
          refCodeExists?.data?.exists === false
            ? 'Referral code does not exitst'
            : null
        }
      />
      <AuthTextInput
        placeholder="20"
        name="amount"
        label="Amount *"
        //type="number"
        required={true}
        defaultValue={formData?.amount}
        onChangeText={text =>
          setFormData({ ...formData, amount: parseFloat(text) })
        }
      />
      <AuthTextInput
        placeholder="20"
        name="fees"
        label="Fees *"
        //type="number"
        required={true}
        defaultValue={formData?.fees || 0}
        onChangeText={text => setFormData({ ...formData, fees: text })}
      />

      <AuthTextInput
        placeholder="Email or name of the person who sent this payment"
        name="sender"
        label="Sender"
        defaultValue={formData?.sender || user.full_name}
        onChangeText={text => setFormData({ ...formData, sender: text })}
      />

      <AuthTextInput
        placeholder="Email or name of the person recieving this payment"
        name="reciever"
        label="Reciever"
        defaultValue={formData?.reciever}
        onChangeText={text => setFormData({ ...formData, reciever: text })}
      />

      <Select
        label="Bank *"
        name="bank"
        options={[
          { value: 'BMO', label: 'BMO' },
          { value: 'TD', label: 'TD' },
          { value: 'PAYPAL', label: 'PayPal' },
          { value: 'FLOAT', label: 'Float' },
          { value: 'OTHER', label: 'Other' },
        ]}
        onChange={opt => setFormData({ ...formData, bank: opt.value })}
        defaultValue={
          formData?.bank ? { value: formData.bank, label: formData.bank } : null
        }
      />
      <Select
        label="Reason *"
        name="reason"
        options={[
          {
            value: 'SETUP_SESSION_INITIAL_TRANSFER',
            label: 'Setup session initial transfer',
          },
          {
            value: 'POST_ONBOARDING_BONUS',
            label: 'Post onboarding bonus',
          },
          { value: 'BETSTAMP_101_BONUS', label: 'betstamp 101 bonus' },
          { value: 'GROUP_TARGET_BONUS', label: 'Group target bonus' },
          { value: 'MONTHLY_FEE', label: 'Monthly fee' },
          // there's a space in this option value, there was a typo in the backend
          // so now there's forever a space until someone fixes it
          // quite possibly the worst tech debt we've ever taken on
          { value: 'EVENT_FUNDING ', label: 'Event funding ' },
          { value: 'EVENT_FUNDING_BONUS', label: 'Event funding bonus' },
          // space is here       ^
          { value: 'CHALLENGE_350', label: '350 Challenge' },
          {
            value: 'SOCIAL_LADDER_CASHOUT',
            label: 'Social Ladder Cashout',
          },
          { value: 'RETURNED_MONEY', label: 'Returned Money' },
          { value: 'OTHER', label: 'Other' },
        ]}
        onChange={opt => setFormData({ ...formData, reason: opt.value })}
        defaultValue={
          formData?.reason
            ? { value: formData.reason, label: formData.reason }
            : null
        }
      />
      <Select
        label="Direction *"
        name="direction"
        defaultValue={
          formData?.direction
            ? { value: formData.direction, label: formData.direction }
            : { value: 'TO_ATTENDEE', label: 'To Attendee/Group' }
        }
        options={[
          { value: 'TO_ATTENDEE', label: 'To Attendee/Group' },
          { value: 'TO_BETSTAMP', label: 'To betstamp' },
        ]}
        onChange={opt => setFormData({ ...formData, direction: opt.value })}
      />
      <Select
        label="Currency *"
        name="currency"
        defaultValue={
          formData?.currency
            ? { value: formData.currency, label: formData.currency }
            : { value: 'CAD', label: 'CAD' }
        }
        options={[
          { value: 'CAD', label: 'CAD' },
          { value: 'USD', label: 'USD' },
          { value: 'OTHER', label: 'Other' },
        ]}
        onChange={opt => setFormData({ ...formData, currency: opt.value })}
      />
      <Col style={{ marginTop: 'var(--space-sm)', alignItems: 'stretch' }}>
        <span>Date *</span>
        <Row
          style={{
            backgroundColor: 'var(--color-fg)',
            border: '1px solid var(--color-text-light)',
            borderRadius: '32px',
            padding: 'var(--space-xxxs)',
          }}
        >
          <CalendarInput
            value={
              formData.date
                ? new Date(formData.date)
                : new Date(new Date().setHours(12))
            }
            onChange={date => {
              let d = date;
              d.setHours(12);
              setFormData({ ...formData, date: d });
            }}
          />
        </Row>
      </Col>
      <div style={{ margin: 'var(--space-sm) 0' }}>
        <input
          key={`payment-is_cancelled-${formData.id}-${formData.is_cancelled}`}
          type="checkbox"
          name="is_cancelled"
          id="is_cancelled"
          defaultChecked={formData.is_cancelled}
          onChange={ev => {
            setFormData({ ...formData, is_cancelled: ev.target.checked });
          }}
        />
        <label htmlFor="is_cancelled">Payment was cancelled</label>
      </div>

      <AuthTextInput
        name="notes"
        label="Misc. Notes"
        multiline
        onChangeText={() => { }}
        defaultValue={formData?.notes}
      />
      <br />
      <AuthButton
        type="submit"
        disabled={submitDisabled}
        isLoading={submitPayment.isLoading}
      >
        {formData?.id ? 'Save Changes' : 'Create Payment'}
      </AuthButton>
      {submitPayment.isError && (
        <small style={{ textAlign: 'center', color: 'var(--color-danger)' }}>
          {submitPayment.error.message}
        </small>
      )}
    </form>
  );
}

function FilterChildren(props) {
  const { urlParams } = props;

  const defaultFilters = useMemo(() => {
    let filters = {};

    if (urlParams.date_after) {
      filters.date_after = new Date(urlParams.date_after);
    }

    if (urlParams.date_before) {
      filters.date_before = new Date(urlParams.date_before);
    }

    if (urlParams.is_cancelled) {
      filters.is_cancelled = { [urlParams.is_cancelled]: true };
    }

    if (urlParams.reasons) {
      let d = {};
      for (const r of JSON.parse(urlParams.reasons)) {
        d[r] = true;
      }
      filters.reasons = d;
    }

    if (urlParams.banks) {
      let d = {};
      for (const r of JSON.parse(urlParams.banks)) {
        d[r] = true;
      }
      filters.banks = d;
    }

    if (urlParams.senders) {
      let d = {};
      for (const r of JSON.parse(urlParams.senders)) {
        d[r] = true;
      }
      filters.senders = d;
    }

    if (urlParams.marketing_group_ids) {
      let d = {};
      for (const r of JSON.parse(urlParams.marketing_group_ids)) {
        d[r] = true;
      }
      filters.marketing_group_ids = d;
    }

    return filters;
  }, [urlParams]);

  const fetchHosts = useInfiniteQuery({
    staleTime: 1000 * 60 * 2,
    queryKey: [
      {
        endpoint: `onboarding-hosts`,
      },
    ],
    getNextPageParam: (lastPage, allPages) => lastPage.next,
    getPreviousPageParam: (firstPage, allPages) => firstPage.prev,
  });

  const hostOptions = useMemo(() => {
    if (fetchHosts.isSuccess && fetchHosts?.data?.pages?.length > 0) {
      let opts = [];
      for (const page of fetchHosts.data.pages) {
        for (const host of page.results) {
          opts.push({
            label: host.full_name || host.email || host.id,
            value: host.full_name || host.email || host.id,
          });
        }
      }
      return opts;
    }
  }, [fetchHosts?.isSuccess, fetchHosts?.data?.pages]);

  const fetchMarketingGroups = useQuery({
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 2,
    queryKey: [
      {
        endpoint: `marketing-groups`,
      },
    ],
  });
  const marketingGroupOptions = useMemo(() => {
    let opts = [];
    if (
      fetchMarketingGroups?.isSuccess &&
      fetchMarketingGroups?.data?.length > 0
    ) {
      for (const group of fetchMarketingGroups.data) {
        opts.push({
          label: group.name,
          value: group.id,
        });
      }
    }
    return opts;
  }, [fetchMarketingGroups?.isSuccess, fetchMarketingGroups?.data]);

  return (
    <div style={{ width: '100%' }}>
      <GenericAdminFilter
        filterTitle="Reason"
        filterKey="reasons"
        options={{
          groups: [
            {
              title: 'Reason',
              options: [
                {
                  value: 'SETUP_SESSION_INITIAL_TRANSFER',
                  label: REASON_MAP['SETUP_SESSION_INITIAL_TRANSFER'],
                },
                {
                  value: 'POST_ONBOARDING_BONUS',
                  label: REASON_MAP['POST_ONBOARDING_BONUS'],
                },
                {
                  value: 'BETSTAMP_101_BONUS',
                  label: REASON_MAP['BETSTAMP_101_BONUS'],
                },
                {
                  value: 'GROUP_TARGET_BONUS',
                  label: REASON_MAP['GROUP_TARGET_BONUS'],
                },
                {
                  value: 'MONTHLY_FEE',
                  label: REASON_MAP['MONTHLY_FEE'],
                },
                {
                  value: 'EVENT_FUNDING ', // that space is required
                  label: REASON_MAP['EVENT_FUNDING'],
                },
                {
                  value: 'CHALLENGE_350',
                  label: REASON_MAP['CHALLENGE_350'],
                },
                {
                  value: 'SOCIAL_LADDER_CASHOUT',
                  label: REASON_MAP['SOCIAL_LADDER_CASHOUT'],
                },
                {
                  value: 'RETURNED_MONEY',
                  label: REASON_MAP['RETURNED_MONEY'],
                },
                {
                  value: 'OTHER',
                  label: REASON_MAP['OTHER'],
                },
              ],
            },
          ],
        }}
        onFilter={selections => {
          props.onFilter({
            reasons: JSON.stringify(selections),
          });
        }}
        defaultDict={defaultFilters.reasons}
      />
      <GenericAdminFilter
        filterTitle="Bank"
        filterKey="banks"
        options={{
          groups: [
            {
              title: 'Bank',
              options: [
                { value: 'BMO', label: 'BMO' },
                { value: 'TD', label: 'TD' },
                { value: 'PAYPAL', label: 'PayPal' },
                { value: 'FLOAT', label: 'Float' },
                { value: 'OTHER', label: 'Other' },
              ],
            },
          ],
        }}
        onFilter={selections => {
          props.onFilter({
            banks: JSON.stringify(selections),
          });
        }}
        defaultDict={defaultFilters.banks}
      />
      <GenericAdminFilter
        filterTitle="Sent After Date"
        filterKey="date_after"
        type="date"
        defaultDate={defaultFilters.date_after}
        onFilter={date => {
          props.onFilter({ date_after: date.toISOString() });
        }}
      />
      <GenericAdminFilter
        filterTitle="Sent Before Date"
        filterKey="date_before"
        type="date"
        defaultDate={defaultFilters.date_before}
        onFilter={date => {
          props.onFilter({ date_before: date.toISOString() });
        }}
      />
      <GenericAdminFilter
        filterTitle="Cancelled"
        filterKey="is_cancelled"
        options={{
          groups: [
            {
              title: 'Cancelled',
              options: [
                { label: 'Cancelled', value: true },
                { label: 'Not Cancelled', value: false },
              ],
            },
          ],
        }}
        singleChoice
        onFilter={selections => {
          props.onFilter({
            is_cancelled: selections[0],
          });
        }}
        defaultDict={defaultFilters.is_cancelled}
      />
      {hostOptions?.length > 0 && (
        <GenericAdminFilter
          filterTitle="Sender"
          filterKey="senders"
          options={{ groups: [{ title: 'Senders', options: hostOptions }] }}
          onFilter={selections => {
            props.onFilter({
              senders: JSON.stringify(selections),
            });
          }}
          defaultDict={defaultFilters.senders}
        />
      )}

      {marketingGroupOptions?.length > 0 && (
        <GenericAdminFilter
          filterTitle="Referral Code Group"
          filterKey="marketing_group_ids"
          options={{
            groups: [
              { title: 'Referral Code Group', options: marketingGroupOptions },
            ],
          }}
          onFilter={selections => {
            props.onFilter({
              marketing_group_ids: JSON.stringify(selections),
            });
          }}
          defaultDict={defaultFilters.marketing_group_ids}
        />
      )}
    </div>
  );
}
