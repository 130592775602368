import React from 'react';
import AppleSignin from 'react-apple-signin-auth';
import AppleLogo from 'images/apple-logo.svg';
import { useDispatch, useSelector } from 'react-redux';

// components
import { AuthButton } from './AuthButton';

// actions
import { socialLoginUser, socialConnectUser } from 'actions';

const __DEV__ = process.env.NODE_ENV !== 'production';

export default function AppleAuthButton(props) {
  const dispatch = useDispatch();
  const reduxProps = useSelector(state => ({
    theme: state.settingsReducer.theme,
  }));
  const { theme } = reduxProps;
  const { signup } = props;
  const onSuccess = response => {
    if (response.authorization) {
      if (props.login || props.signup) {
        dispatch(
          socialLoginUser(
            'apple',
            response.authorization.id_token,
            response.authorization.code
          )
        );
      } else if (props.connectExisting) {
        dispatch(
          socialConnectUser(
            'apple',
            response.authorization.id_token,
            response.authorization.code
          )
        );
      }
    }
  };

  return (
    <AppleSignin
      /** Auth options passed to AppleID.auth.init() */
      authOptions={{
        /** Client ID - eg: 'com.example.com' */
        clientId: 'app.betstamp.betstamp',
        /** Requested scopes, seperated by spaces - eg: 'email name' */
        scope: 'email name',
        /** Apple's redirectURI - must be one of the URIs you added to the serviceID - the undocumented trick in apple docs is that you should call auth from a page that is listed as a redirectURI, localhost fails */
        redirectURI: __DEV__
          ? 'https://d53b-2607-fea8-701-d7a0-00-7bcc.ngrok-free.app/social-callback/apple'
          : 'https://betstamp.app/social-callback/apple',
        /** State string that is returned with the apple response */
        state: 'state',
        /** Nonce */
        nonce: Math.random().toString(),
        /** Uses popup auth instead of redirection */
        usePopup: true,
        // usePopup: ${authOptions.usePopup},
      }} // REQUIRED
      /** General props */
      uiType="dark"
      /** className */
      // className="apple-auth-btn"
      /** Removes default style tag */
      // noDefaultStyle={false}
      /** Allows to change the button's children, eg: for changing the button text */
      // buttonExtraChildren="Continue with Apple"
      /** Extra controlling props */
      /** Called upon signin success in case authOptions.usePopup = true -- which means auth is handled client side */
      onSuccess={response => onSuccess(response)} // default = undefined
      /** Called upon signin error */
      // onError={error => onError(error)} // default = undefined
      /** Skips loading the apple script if true */
      // skipScript={false} // default = undefined
      render={props => (
        <AuthButton
          {...props}
          type="button"
          btnTheme="borderless"
          colorTheme="text"
          // containerStyle={{ height: 50 }}
          containerStyle={
            signup
              ? {
                width: '50%',
                minWidth: '250px',
              }
              : {}
          }
          btnStyle={{
            backgroundColor: theme === 'dark' ? 'var(--color-fg)' : 'black',
          }}
          textStyle={{
            color: 'white',
            flex: 0,
            minWidth: 'fit-content',
            marginLeft: 'var(--space-sm)',
          }}
          leftIcon={AppleLogoComp}
          leftIconColor="white"
          // leftIcon={IoLogoApple}
          onPress={props.onClick}
        // disabled={renderProps.disabled}
        >
          Continue With Apple
        </AuthButton>
      )}
    />
  );
}

const AppleLogoComp = props => (
  <img src={AppleLogo} {...props} height={32} alt="apple logo" />
);
