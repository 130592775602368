import {
  GET_PURCHASE_SESSION,
  GET_PURCHASE_SESSION_SUCCESS,
  GET_PURCHASE_SESSION_FAILURE,
  START_PURCHASE_SESSION,
  START_PURCHASE_SESSION_SUCCESS,
  START_PURCHASE_SESSION_FAILURE,
  CREATE_SUBSCRIPTION,
  CREATE_SUBSCRIPTION_SUCCESS,
  CREATE_SUBSCRIPTION_FAILURE,
  VIEW_PURCHASES,
  VIEW_PURCHASES_SUCCESS,
  VIEW_PURCHASES_FAILURE,
  VIEW_PURCHASES_NEXT,
  CANCEL_SUBSCRIPTION,
  CANCEL_SUBSCRIPTION_SUCCESS,
  CANCEL_SUBSCRIPTION_FAILURE,
  GET_PURCHASE,
  GET_PURCHASE_SUCCESS,
  GET_PURCHASE_FAILURE,
  UPDATE_PAYMENT_METHOD,
  UPDATE_PAYMENT_METHOD_SUCCESS,
  UPDATE_PAYMENT_METHOD_FAILURE,
  RESET_PAYMENT_METHOD_SUCCESS,
  RESET_STRIPE_ERRORS,
} from 'actions';

const initialState = {
  // retreiving a ps
  isPurchaseSessionLoading: false,
  purchaseSessionError: null,
  purchaseSession: null,
  // creating/starting a ps
  isStartingPurchaseSession: false,
  startPurchaseSessionError: null,
  startPurchaseSessionID: null,
  // subscribing to a package
  isSubscribing: false,
  createSubError: null,
  createSubSuccess: null,
  createSubErrorReason: null,
  createSubErrorMsg: null,
  pi_cs: null,
  sub_id: null,
  // purchases
  isPurchasesLoading: false,
  purchaseLoadingError: null,
  purchases: [],
  isCancellingSub: false,
  cancelSubFailure: null,
  purchasesNext: null,
  isPurchasesNextLoading: false,
  // get a single purchase (comes with payment methods)
  isLoadingPurchase: false,
  getPurchaseError: null,
  purchase: null,
  // update a payment method
  isUpdatingPM: false,
  updatePMError: null,
  updatePMSuccess: null,
};

export default function stripeReducer(state = initialState, action) {
  switch (action.type) {
    case GET_PURCHASE_SESSION:
      return Object.assign({}, state, {
        ...state,
        isPurchaseSessionLoading: true,
        purchaseSessionError: false,
      });
    case GET_PURCHASE_SESSION_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        isPurchaseSessionLoading: false,
        purchaseSessionError: false,
        purchaseSession: action.payload,
      });
    case GET_PURCHASE_SESSION_FAILURE:
      return Object.assign({}, state, {
        ...state,
        isPurchaseSessionLoading: false,
        purchaseSessionError: action.error,
        purchaseSession: null,
      });

    case START_PURCHASE_SESSION:
      return Object.assign({}, state, {
        ...state,
        isStartingPurchaseSession: true,
        startPurchaseSessionError: false,
        startPurchaseSessionID: null,
      });
    case START_PURCHASE_SESSION_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        isStartingPurchaseSession: false,
        startPurchaseSessionError: false,
        startPurchaseSessionID: action.payload.response.id,
      });
    case START_PURCHASE_SESSION_FAILURE:
      return Object.assign({}, state, {
        ...state,
        isStartingPurchaseSession: false,
        startPurchaseSessionError: action.error,
        startPurchaseSessionID: null,
      });

    case CREATE_SUBSCRIPTION:
      return Object.assign({}, state, {
        ...state,
        isSubscribing: true,
        createSubError: null,
        createSubErrorReason: null,
        createSubSuccess: null,
        createSubErrorMsg: null,
      });
    case CREATE_SUBSCRIPTION_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        isSubscribing: false,
        createSubError: null,
        createSubErrorReason: null,
        createSubSuccess: true,
        createSubErrorMsg: null,
      });
    case CREATE_SUBSCRIPTION_FAILURE:
      const msg = action.error.response ? action.error.response.message : '';
      if (action.error.status === 402) {
        const reason = action.error.response.reason;
        const paymentIntentSecret = action.error.response.pi_cs
          ? action.error.response.pi_cs
          : null;
        const subID = action.error.response.sub_id
          ? action.error.response.sub_id
          : null;
        return Object.assign({}, state, {
          ...state,
          isSubscribing: false,
          createSubError: null,
          createSubSuccess: null,
          furtherAction: true,
          createSubErrorReason: reason,
          createSubErrorMsg: msg,
          pi_cs: paymentIntentSecret,
          sub_id: subID,
        });
      } else {
        return Object.assign({}, state, {
          ...state,
          isSubscribing: false,
          createSubError: action.error,
          createSubSuccess: null,
          createSubErrorReason: null,
          createSubErrorMsg: msg,
        });
      }

    case VIEW_PURCHASES:
      return Object.assign({}, state, {
        ...state,
        isPurchasesLoading: true,
        purchaseLoadingError: null,
        purchases: [],
      });
    case VIEW_PURCHASES_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        purchases: state.purchases.concat(action.payload.results),
        isPurchasesLoading: false,
        isPurchasesNextLoading: false,
        purchaseLoadingError: null,
        purchasesNext: action.payload.next,
      });
    case VIEW_PURCHASES_FAILURE:
      return Object.assign({}, state, {
        ...state,
        purchaseLoadingError: action.payload._response,
        isPurchasesLoading: false,
        isPurchasesNextLoading: false,
      });
    case VIEW_PURCHASES_NEXT:
      return Object.assign({}, state, {
        ...state,
        isPurchasesNextLoading: true,
        purchaseLoadingError: null,
      });

    case CANCEL_SUBSCRIPTION:
      return Object.assign({}, state, {
        ...state,
        isCancellingSub: true,
        cancelSubFailure: null,
      });
    case CANCEL_SUBSCRIPTION_SUCCESS:
      const new_purchases = state.purchases.map((p) => {
        if (p.id === action.payload.response.id) {
          return action.payload.response;
        }
        return p;
      });
      return Object.assign({}, state, {
        ...state,
        purchases: new_purchases,
        isCancellingSub: false,
        cancelSubFailure: null,
      });
    case CANCEL_SUBSCRIPTION_FAILURE:
      return Object.assign({}, state, {
        ...state,
        isCancellingSub: action.payload,
        cancelSubFailure: false,
      });

    case GET_PURCHASE:
      return Object.assign({}, state, {
        ...state,
        isLoadingPurchase: true,
        getPurchaseError: null,
      });
    case GET_PURCHASE_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        purchase: action.payload,
        isLoadingPurchase: false,
        getPurchaseError: null,
      });
    case GET_PURCHASE_FAILURE:
      return Object.assign({}, state, {
        ...state,
        getPurchaseError: action.error,
        isLoadingPurchase: false,
      });

    case UPDATE_PAYMENT_METHOD:
      return Object.assign({}, state, {
        ...state,
        isUpdatingPM: true,
        updatePMError: null,
        updatePMSuccess: null,
      });
    case UPDATE_PAYMENT_METHOD_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        isUpdatingPM: false,
        updatePMError: null,
        updatePMSuccess: true,
      });
    case UPDATE_PAYMENT_METHOD_FAILURE:
      return Object.assign({}, state, {
        ...state,
        isUpdatingPM: false,
        updatePMError: action.error,
        updatePMSuccess: null,
      });
    case RESET_PAYMENT_METHOD_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        isUpdatingPM: false,
        updatePMError: null,
        updatePMSuccess: null,
      });

    case RESET_STRIPE_ERRORS:
      return Object.assign({}, state, {
        ...state,
        isPurchaseSessionLoading: false,
        purchaseSessionError: null,
        isStartingPurchaseSession: false,
        startPurchaseSessionError: null,
        isSubscribing: false,
        createSubError: null,
        createSubSuccess: null,
        createSubErrorReason: null,
        createSubErrorMsg: null,
        isPurchasesLoading: false,
        purchaseLoadingError: null,
        isLoadingPurchase: false,
        getPurchaseError: null,
        isUpdatingPM: false,
        updatePMError: null,
        updatePMSuccess: null,
      });

    default:
      return state;
  }
}
