import React from 'react';

import { Row, Col } from 'components/generic/Layout.js';

// utils
import { commaSeparateNumber, kFormatter } from 'utils';

export default function TransactionSummary(props) {
  const { bookSummary, isLoading, kFormat } = props;
  if (isLoading || !bookSummary) {
    return <div style={{ minHeight: 100 }} />;
  }
  return (
    <Row style={{ padding: 'var(--space-sm) 0' }}>
      <SummaryItem
        title="Current Balance"
        autoColor
        num={bookSummary.total}
        kFormat={kFormat}
      />

      <SummaryItem
        title="Net Profit"
        autoColor
        num={bookSummary.profit}
        kFormat={kFormat}
      />

      <SummaryItem
        title="Pending"
        num={bookSummary.pending}
        kFormat={kFormat}
      />

      <SummaryItem
        title="Withdrawn"
        num={bookSummary.withdraw}
        kFormat={kFormat}
      />

      <SummaryItem
        title="Deposited"
        num={bookSummary.deposit}
        kFormat={kFormat}
      />
    </Row>
  );
}

const calculateAmount = (amount, kFormat) => {
  return kFormat
    ? amount >= 0
      ? '$' + kFormatter(amount)
      : '-$' + kFormatter(amount).replace('-', '')
    : amount >= 0
    ? `$${commaSeparateNumber(amount)}`
    : `-$${commaSeparateNumber(-1 * amount)}`;
};

const SummaryItem = ({ num, title, autoColor, kFormat }) => {
  const number = calculateAmount(num, kFormat);

  let color = 'var(--color-text)';
  if (autoColor) {
    if (num > 0) color = 'var(--color-dollar-green)';
    else if (num < 0) color = 'var(--color-dollar-red)';
  }

  return (
    <Col
      style={{
        alignItems: 'flex-start',
        // backgroundColor: 'var(--color-fg)',
        borderRadius: 'var(--std-border-radius)',
        padding: 'var(--space-xs) var(--space-sm)',
        // boxShadow: '0 2px 8px 0 var(--color-shadow)',
      }}
    >
      <small style={{ opacity: 0.75, fontWeight: 'bold' }}>
        {title.toUpperCase()}
      </small>
      <h6 style={{ color: color, fontWeight: 'bold' }}>{number}</h6>
    </Col>
  );
};
