import { combineEpics } from 'redux-observable';

// public user
import getLeaderboard from 'epics/user/getLeaderboard.epic.js';
import getPublicProfile from 'epics/user/getPublicProfile.epic.js';
import searchUsers from 'epics/user/searchUsers.epic.js';
import getPublicProfileByUsername from 'epics/user/getPublicProfileByUsername.js';
import getUsersNext from 'epics/user/getUsersNext.epic';

// logged in user
import getFollowers from 'epics/user/getFollowers.epic.js';
import getFollowersNext from 'epics/user/getFollowersNext.epic.js';
import getFollowing from 'epics/user/getFollowing.epic.js';
import getFollowingNext from 'epics/user/getFollowingNext.epic.js';
import followUser from 'epics/user/followUser.epic.js';
import unfollowUser from 'epics/user/unfollowUser.epic.js';
import buyPackage from 'epics/user/buyPackage.epic.js';
import editRelationship from 'epics/user/editRelationship.epic.js';

export default combineEpics(
  getLeaderboard,
  getPublicProfile,
  getFollowers,
  getFollowersNext,
  getFollowing,
  getFollowingNext,
  followUser,
  unfollowUser,
  searchUsers,
  getUsersNext,
  buyPackage,
  getPublicProfileByUsername,
  editRelationship
);
