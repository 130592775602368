import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect, useLocation } from 'react-router-dom';
import styled from 'styled-components';

// components
import ActivityIndicator from 'components/generic/ActivityIndicator';
import CompletePurchase from 'pages/Stripe/CompletePurchase';
import SetupStripe from 'components/SetupStripe';
import { LinkButton } from 'components/AuthButton';

// actions
import { getPurchaseSession } from 'actions';

const Wrapper = styled.div`
  margin: auto;
  padding-top: var(--space-xl);
  width: 100%;
  max-width: 450px;
`;

export default function SetupPurchaseSession(props) {
  const [loading, setLoading] = useState(true);
  const [sessionID, setSessionID] = useState(null);

  const dispatch = useDispatch();
  const reduxProps = useSelector(state => ({
    userToken: state.authReducer.userToken,
    isPurchaseSessionLoading: state.stripeReducer.isPurchaseSessionLoading,
    purchaseSessionError: state.stripeReducer.purchaseSessionError,
    purchaseSession: state.stripeReducer.purchaseSession,
  }));

  const {
    isPurchaseSessionLoading,
    purchaseSessionError,
    purchaseSession,
    userToken,
  } = reduxProps;

  // Actually get the purchase session from the backend
  useEffect(() => {
    const sessionid = props.match.params.purchaseSessionID;
    if (sessionid) {
      setSessionID(sessionid);
      dispatch(getPurchaseSession(sessionid));
    }
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!loading && sessionID === null) {
    return <Redirect to="/" />;
  }

  if (purchaseSessionError) {
    // in order for the app to detect the changed url, we can't use <Link/>
    // from react-router
    return (
      <div className="container">
        <Theme />
        <Wrapper style={{ textAlign: 'center' }}>
          <h6>
            Sorry, this purchase session expired or doesn't exist. Please try
            again later.
          </h6>
          <br />
          {userToken ? (
            <LinkButton
              to="/find-bettors"
              linkStyle={{
                display: 'flex',
                justifyContent: 'center',
              }}
              containerStyle={{
                maxWidth: '21rem',
              }}
            >
              Find Bettors
            </LinkButton>
          ) : (
            <LinkButton
              to="/"
              linkStyle={{
                display: 'flex',
                justifyContent: 'center',
              }}
              containerStyle={{
                maxWidth: '7rem',
              }}
            >
              Go Back
            </LinkButton>
          )}
        </Wrapper>
      </div>
    );
  }

  if (
    !isPurchaseSessionLoading &&
    sessionID &&
    purchaseSession &&
    purchaseSession.package_info.user_connect_id
  ) {
    return (
      <div className="container">
        <SetupStripe
          useConnectAccount={true}
          connectID={purchaseSession.package_info.user_connect_id}
        >
          <Theme />
          <Wrapper
            style={{ textAlign: 'center', paddingTop: 'var(--space-sm)' }}
          >
            <CompletePurchase />
          </Wrapper>
        </SetupStripe>
      </div>
    );
  }

  return (
    <div className="container">
      <Theme />
      <Wrapper>
        <ActivityIndicator size={3} />
        <h5 style={{ textAlign: 'center', marginTop: 0 }}>Loading Purchase</h5>
      </Wrapper>
    </div>
  );
}

function Theme() {
  const location = useLocation();
  if (location.search.includes('colorScheme=dark')) {
    return (
      <>
        <style>{`
      :root {
          --color-bg: var(--color-night-bg);
          --color-fg: var(--color-night-fg);
          --color-fg2: var(--color-night-fg2);
          --color-text: var(--color-night-text);                   
          --color-primary: var(--color-night-primary);
          --color-complement: var(--color-night-complement);
          --color-danger: #d30930;
          --color-success: #14CC67;
          --color-shadow: var(--color-night-shadow);
      }
  `}</style>
      </>
    );
  } else {
    return <></>;
  }
}
