import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';

// utils
import { MAX_SCREEN_HEIGHT_SIGNUP_ONBOARD, loadImageFirst } from 'utils';

// components
import SharpSportsButton from 'components/SharpSportsButton';
import { BlueBubble, OnboardingWrapper } from 'components/auth/onboarding';
import { AuthButton } from 'components/AuthButton';
import { Row, Col } from 'components/generic/Layout';
import AffiliateLink from 'components/AffiliateLink';
import ActivityIndicator from 'components/generic/ActivityIndicator';
import ModalWrapper from 'components/generic/ModalWrapper';
import AutoSync from 'components/modals/AutoSync';

// images
import BetlinkCheckmark from 'images/logos/betlink_checkmark.png';
import Betlink from 'images/logos/betlink.png';

// actions
import {
  fetchIntegrationSS,
  getSharpsportsBooks,
  getAffiliates,
  autoSyncResetErrors,
} from 'actions';

export default function LinkBooks(props) {
  const dispatch = useDispatch();
  const reduxProps = useSelector(state => ({
    user: state.authReducer.user,
    sharpsportsBooks: state.sharpsportsReducer.sharpsportsBooks,
    affiliates: state.affiliatesReducer.affiliates,
  }));
  const { user, sharpsportsBooks, affiliates } = reduxProps;
  const headerRef = useRef(null);
  const [booksBanners, setBooksBanners] = useState(null);
  const [booksToShow, setBooksToShow] = useState(null);

  useEffect(() => {
    dispatch(getSharpsportsBooks());
    if (affiliates.length === 0) {
      dispatch(getAffiliates());
    }
  }, []);
  const [forceShowBooks, setForceShowBooks] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState('');
  const linkableBooks = user.books.filter(a => a.is_autosyncable);

  const _renderItem = (item, uniqueKey) => (
    <LinkedBook item={item} key={uniqueKey} />
  );

  const booksToLink = useMemo(() => {
    if (affiliates.length > 0) {
      const linkedNames = sharpsportsBooks.map(ssb => ssb.book.name);
      return affiliates.reduce((arr, curr) => {
        if (
          curr.states.includes(user?.state) &&
          !linkedNames.includes(curr.name) &&
          (curr.is_autosyncable || curr.is_affiliate)
        ) {
          arr.push({ book: curr, not_linked: true });
          return arr;
        }
        return arr;
      }, []);
    }
    return [];
  }, [affiliates, sharpsportsBooks, user]);

  useEffect(() => {
    if (sharpsportsBooks.length > 0 || forceShowBooks) {
      setBooksToShow(sharpsportsBooks.concat(booksToLink));
    }
  }, [sharpsportsBooks, forceShowBooks, booksToLink]);

  const setBooksBannersHelper = () =>
    setBooksBanners(
      booksToShow.map(bookItem =>
        _renderItem(
          bookItem,
          `setup-betlink-synced-books-${bookItem.book.name}`
        )
      )
    );

  useEffect(() => {
    if (booksToShow?.length && !booksBanners) {
      loadImageFirst(
        booksToShow.map(bookItem =>
          bookItem.not_linked
            ? bookItem.book.open_banner
            : bookItem.book.generic_banner
        )
      ).then(setBooksBannersHelper, setBooksBannersHelper);
    }
  }, [booksToShow]);

  const blueBubbleHeight =
    headerRef?.current?.getBoundingClientRect().height || 92;

  return (
    <OnboardingWrapper
      showSkip
      nextScreen="/onboarding/find-users"
      onNext={() => {}}
    >
      <Col style={{ justifyContent: 'flex-start', width: '100%' }}>
        <div
          ref={headerRef}
          style={{
            display: 'flex',
            flex: 0,
            width: '100%',
            justifyContent: 'flex-end',
          }}
        >
          <BlueBubble
            style={{
              marginTop: 'var(--space-md)',
              marginBottom: 'var(--space-md)',
              maxWidth: 'fit-content',
            }}
          >
            <h5
              style={{
                marginTop: 'var(--space-md)',
                marginBottom: 'var(--space-md)',
                color: 'white',
              }}
            >
              {sharpsportsBooks.length > 0
                ? "Congrats, you've linked your first book. Now you can follow your bets with live score & odds updates and improve using the analysis tool"
                : 'Betstamp connects with your sportsbooks to import your bets as soon as you place them'}
            </h5>
          </BlueBubble>
        </div>
        {booksToShow ? (
          booksToShow?.length > 0 ? (
            booksBanners ? (
              <Col
                id="infinite-scroll-target-link-books"
                style={{
                  maxWidth: 'min(100%, 400px)',
                  // Betstamp logo = 64px tall, skip/next buttons = 50px tall, `Link Now` button = 35px
                  // header text = 92px
                  // rest is paddings/margins
                  maxHeight: `calc(min(100vh, ${MAX_SCREEN_HEIGHT_SIGNUP_ONBOARD}) - 2 * var(--space-sm) - 64px - ${blueBubbleHeight}px - 50px - ${
                    linkableBooks.length > 0 || forceShowBooks
                      ? '35px - 2 * var(--space-xs)'
                      : '0px'
                  })`,
                  overflow: 'hidden scroll',
                  minHeight: '150px',
                  justifyContent: 'flex-start',
                }}
              >
                <InfiniteScroll
                  scrollableTarget={'infinite-scroll-target-link-books'}
                  hasMore={false}
                  dataLength={booksToShow?.length || 0}
                >
                  {booksBanners}
                </InfiniteScroll>
              </Col>
            ) : (
              <ActivityIndicator size={2} />
            )
          ) : (
            <Col>
              <span>Sorry, No Sportbooks Available in Your Region</span>
            </Col>
          )
        ) : (
          <>
            <img
              style={{ marginBottom: 'var(--space-md)', maxWidth: '300px' }}
              src={
                'https://betstamp-public.sfo2.cdn.digitaloceanspaces.com/signup-flow/betlink.png'
              }
              alt={'betlink'}
            />
            {linkableBooks.length > 0 ? (
              <>
                <h6
                  style={{
                    marginTop: 'var(--space-md)',
                    marginBottom: 'var(--space-md)',
                    fontWeight: 'bold',
                    textAlign: 'center',
                    alignSelf: 'center',
                  }}
                >
                  You are eligible to link
                </h6>
                <Row
                  style={{
                    justifyContent: 'center',
                    padding: 'var(--space-sm)',
                    flex: 0,
                  }}
                >
                  {linkableBooks.map(item => (
                    <>
                      <ModalWrapper
                        modalIsOpen={showLoginModal === item.name}
                        onRequestClose={() => {
                          setShowLoginModal('');
                          dispatch(autoSyncResetErrors());
                        }}
                      >
                        <AutoSync book={item} />
                      </ModalWrapper>
                      <img
                        key={`betlink-onboard-explainer-books-${item.id}`}
                        style={{
                          width: 48,
                          height: 48,
                          borderRadius: 48,
                          margin: '0 var(--space-xxs)',
                        }}
                        alt={item.name || 'sportsbook'}
                        title={item.name || 'sportsbook'}
                        src={item.logo || item.generic_banner}
                        onClick={() => setShowLoginModal(item.name)}
                      />
                    </>
                  ))}
                </Row>
              </>
            ) : (
              <h6
                style={{
                  marginBottom: 'var(--space-md)',
                  fontWeight: 'bold',
                  textAlign: 'center',
                  alignSelf: 'center',
                }}
              >
                None of your books are eligible to link
              </h6>
            )}
          </>
        )}

        {linkableBooks.length === 0 && !forceShowBooks && (
          <AuthButton
            colorTheme="text"
            btnTheme="borderless"
            containerStyle={{
              minWidth: 256,
              width: '50%',
              margin: 0,
              flex: 0,
            }}
            onPress={() => setForceShowBooks(true)}
          >
            View betlink supported sportsbooks
          </AuthButton>
        )}
      </Col>
    </OnboardingWrapper>
  );
}

function LinkedBook(props) {
  const { item } = props;

  let status = 'Linked';
  if (item.book.sharpsports_down_for_maintenance) {
    status = 'Unavailable';
  } else if (item.is_unverifiable || !item.access) {
    status = item.access ? 'Unlinked' : 'Revoked Access';
  } else if (!item.verified) {
    status = 'Reverify';
  }

  if (item.not_linked) {
    return (
      <AffiliateLink
        book={item.book}
        fromPage="Betlink Web Onboarding"
        shape="bar"
        containerStyle={{ marginTop: 'var(--space-xxxs)' }}
      />
    );
  }

  return (
    <Row style={{ alignItems: 'center', margin: 'var(--space-sm) 0' }}>
      <img
        style={{
          flex: 2,
          height: 'auto',
          width: '261px',
          borderRadius: 8,
          marginRight: 'var(--space-md)',
        }}
        src={item.book.generic_banner}
        alt={item.book.name || 'sportsbook'}
        title={item.book.name || 'sportsbook'}
      />
      <Col
        style={{
          flex: 1,
          justifyContent: 'center',
          alignitems: 'center',
          marginRight: 'var(--space-sm)',
        }}
      >
        <img
          src={status === 'Linked' ? BetlinkCheckmark : Betlink}
          style={{
            width: '50px',
            height: 'auto',
          }}
          alt={status}
          title={status}
        />
      </Col>
    </Row>
  );
}
