export const GET_INFLUENCER_PAGE = 'GET_INFLUENCER_PAGE';
export const GET_INFLUENCER_PAGE_SUCCESS = 'GET_INFLUENCER_PAGE_SUCCESS';
export const GET_INFLUENCER_PAGE_FAILURE = 'GET_INFLUENCER_PAGE_FAILURE';

export const getInfluencerPage = (slug, state, searchParams) => ({
  type: GET_INFLUENCER_PAGE,
  slug,
  state,
  searchParams,
});

export const getInfluencerPageSuccess = payload => ({
  type: GET_INFLUENCER_PAGE_SUCCESS,
  payload,
});

export const getInfluencerPageFailure = error => ({
  type: GET_INFLUENCER_PAGE_FAILURE,
  error,
});
