import { Observable } from 'rxjs';
import 'rxjs/add/operator/exhaustMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import { ajax } from 'rxjs/observable/dom/ajax';
import { BACKEND_API_URL, getHeaders } from 'utils';

import { ADD_PACKAGE, addPackageSuccess, addPackageFailure } from 'actions';

export default function addPackage(action$, state$) {
  return action$.ofType(ADD_PACKAGE).exhaustMap(action => {
    return ajax
      .post(
        BACKEND_API_URL + 'api/packages/',
        action.userPackage,
        getHeaders(state$)
      )
      .map(res => addPackageSuccess(res))
      .catch(error => Observable.of(addPackageFailure(error.xhr)));
  });
}
