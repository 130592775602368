import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useUpdateEffect } from 'react-use';

// component
import ActivityIndicator from 'components/generic/ActivityIndicator';
import GameBAHeader from 'components/GameBAHeader';
import OddBtnLine from 'components/OddBtnLine';
import Wager from 'components/Wager';
import { Col } from 'components/generic/Layout';

// actions
import { getWagers, getWagersNext, getLiveOddsNext } from 'actions';

export default function Live() {
  const dispatch = useDispatch();
  const reduxProps = useSelector(state => ({
    game: state.gameReducer.game,
    liveBA: state.gameReducer.liveBA,
    isLoadingLiveBA: state.gameReducer.isLoadingLiveBA,
    liveBAError: state.gameReducer.liveBAError,
    wagers: state.wagerReducer.wagers['game'],
    isWagersLoading: state.wagerReducer.isWagersLoading['game'],
    wagersError: state.wagerReducer.wagersError['game'],
    wagersNext: state.wagerReducer.wagersNext['game'],
    isWagersNextLoading: state.wagerReducer.isWagersNextLoading['game'],
    liveOdds: state.gameReducer.liveOdds,
    isLoadingLiveOdds: state.gameReducer.isLoadingLiveOdds,
    liveOddsError: state.gameReducer.liveOddsError,
    liveOddsNext: state.gameReducer.liveOddsNext,
    isLoadingLiveOddsNext: state.gameReducer.isLoadingLiveOddsNext,
  }));
  const {
    game,
    liveBA,
    isLoadingLiveBA,
    liveBAError,
    wagers,
    isWagersLoading,
    wagersError,
    wagersNext,
    isWagersNextLoading,
    liveOdds,
    isLoadingLiveOdds,
    liveOddsError,
    liveOddsNext,
    isLoadingLiveOddsNext,
  } = reduxProps;

  const _getNextOdds = () => {
    if (liveOddsNext && !isLoadingLiveOddsNext) {
      dispatch(getLiveOddsNext());
    }
  };
  useUpdateEffect(() => {
    _getNextOdds();
  }, [liveOddsNext]);

  useEffect(() => {
    if (game) {
      dispatch(getWagers({ screen: 'game', game_id: game.id }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const _getNext = () => {
    if (wagersNext && !isWagersNextLoading) {
      dispatch(getWagersNext('game'));
    }
  };
  useUpdateEffect(() => {
    _getNext();
  }, [wagersNext]);

  if (isLoadingLiveOdds) {
    return (
      <Col style={{ height: '75%', justifyContent: 'center' }}>
        <ActivityIndicator size={3} />
      </Col>
    );
  }

  if (liveOddsError) {
    return (
      <h5 style={{ textAlign: 'center' }}>
        We ran into an error getting live odds
      </h5>
    );
  }

  return (
    <>
      {!game.is_live && !game.is_complete ? (
        <h6
          style={{
            textAlign: 'center',
            margin: 'var(--space-lg)',
          }}
        >
          Check back when the game is live for live odds comparison
        </h6>
      ) : (
        <>
          {game.is_live && (
            <GameBAHeader
              BA={liveBA}
              isLoading={isLoadingLiveBA}
              error={liveBAError}
            />
          )}
        </>
      )}
      {game.is_live &&
        liveOdds &&
        liveOdds.map((odd, index) => (
          <OddBtnLine key={`fulltime-list-odd-${index}`} odd={odd} />
        ))}
      {isWagersLoading && <ActivityIndicator size={3} />}
      {wagers && wagers.length > 0 && (
        <h4 style={{ marginLeft: 'var(--space-sm)' }}>
          Your wagers on {game.away_team.name} @ {game.home_team.name}
        </h4>
      )}
      {wagers && wagers.map(item => <Wager wager={item} todaysAction />)}
      {(wagersError || (!isWagersLoading && wagers?.length === 0)) && (
        <h6 style={{ textAlign: 'center', marginTop: 'var(--space-lg)' }}>
          {wagersError
            ? `Sorry, we ran into an error getting your wagers on ${game.away_team.name} @ ${game.home_team.name}`
            : `Wagers you track on ${game.away_team.name} @ ${game.home_team.name} will appear here`}
        </h6>
      )}
    </>
  );
}
