import { Observable } from 'rxjs';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import { ajax } from 'rxjs/observable/dom/ajax';
import { BACKEND_API_URL, getHeaders } from 'utils';

import { GET_USER, getUserSuccess, getUserFailure } from 'actions';

export default function getUser(action$, state$) {
  return action$.ofType(GET_USER).switchMap(() => {
    return ajax
      .getJSON(BACKEND_API_URL + 'api/rest-auth/user/', getHeaders(state$))
      .map(data => getUserSuccess(data))
      .catch(error => Observable.of(getUserFailure(error.xhr)));
  });
}
