import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { IoCheckmarkCircleOutline, IoMailOutline } from 'react-icons/io5';

// components
import { Row } from 'components/generic/Layout';
import { AuthButton } from 'components/AuthButton';
import { AuthTextInput } from 'components/AuthTextInput';

// actions
import { cleanAutoSyncStore, deactivateUser } from 'actions';

export default function DeactivateAccount(props) {
  const dispatch = useDispatch();
  const reduxProps = useSelector(state => ({
    user: state.authReducer.user,
  }));
  const { user } = reduxProps;

  const [deactivateDisabled, setDeactivateDisabled] = useState(true);

  return (
    <div>
      <p>
        Are you sure? This action cannot be undone. This email <b>cannot</b> be
        reused for new betstamp account once deactivated.
      </p>
      <AuthTextInput
        label="Type your email to confirm deactivation"
        leftIcon={IoMailOutline}
        rightIcon={!deactivateDisabled ? IoCheckmarkCircleOutline : null}
        rightIconColor={'var(--color-success'}
        placeholder={'example@gmail.com'}
        onChangeText={text => setDeactivateDisabled(text !== user.email)}
      />
      <Row>
        <AuthButton
          containerStyle={{ marginRight: 'var(--space-xs)' }}
          onPress={() => props.hideModal()}
        >
          No, keep my account active
        </AuthButton>
        <AuthButton
          containerStyle={{ marginRight: 'var(--space-xs)' }}
          colorTheme="danger"
          onPress={() => {
            dispatch(deactivateUser());
            dispatch(cleanAutoSyncStore());
          }}
          disabled={deactivateDisabled}
        >
          Deactivate
        </AuthButton>
      </Row>
    </div>
  );
}
