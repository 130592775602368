/**
 * Removes things like ',' ' ' '-' from dollar amount. Only returns positive dollars. Add $ if not there.
 * @param {String} amt A string that's supposed to represent a dollar amount.
 * @returns {String} Cleaned version of amount.
 */
 export default function cleanDollarAmount(amt) {
  if (amt === null || amt === undefined) return '$';

  if (amt === '$') return amt;

  if (amt[0] !== '$') amt = '$' + amt;

  if (amt.trim() === '$') return '$';

  // A $, zero to nine digits, optional .,
  // then zero to two digits
  amt = amt.match(/^\$\-?\d{0,9}\.?\d{0,2}/g);
  if (!amt) return '';
  amt = amt.join('');

  // add 0 before period if not there
  if (amt[1] === '.') amt = '$0' + amt.substring(1);
  return amt;
}
