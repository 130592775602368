import { Observable } from 'rxjs';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import { ajax } from 'rxjs/observable/dom/ajax';
import { BACKEND_API_URL, getHeaders } from 'utils';

import { GET_LIVE_ODDS, getLiveOddsSuccess, getLiveOddsFailure } from 'actions';

export default function getLiveOdds(action$, state$) {
  return action$.ofType(GET_LIVE_ODDS).switchMap(action => {
    let url =
      BACKEND_API_URL +
      `api/gameline/?game_id=${action.game_id}&period=FT&is_live=True`;

    return ajax
      .getJSON(url, getHeaders(state$))
      .map(data => getLiveOddsSuccess(data))
      .catch(error => Observable.of(getLiveOddsFailure(error.xhr)));
  });
}
