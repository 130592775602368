/**
 * Converts a decimal odd to an American odd.
 * @param {Number} decimal Odds in decimal
 * @returns {String} American representation of decimal odds. Returns ' ' if invalid.
 */
export default function toAmericanOdds(decimal) {
  if (isNaN(decimal)) return ' ';
  if (decimal <= 1) return 'N/A';
  if (decimal >= 2) {
    return '+' + Math.round((decimal - 1) * 100);
  }
  return Math.round(-100 / (decimal - 1)).toString();
}
