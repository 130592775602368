import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import styled from 'styled-components';
import { IoChevronForward, IoAlertCircleOutline } from 'react-icons/io5';
import moment from 'moment';

// components
import ActivityIndicator from 'components/generic/ActivityIndicator';
import {
  Toolbar,
  MainAreaWrapper,
  InnerMainArea,
  Grid,
  Row,
  Col,
} from 'components/generic/Layout';
import BetSlip from 'components/BetSlip';
import ContentLoader from 'components/generic/ContentLoader';
import { AuthButton, LinkButton } from 'components/AuthButton';
import Badge from 'components/generic/Badge';
import Snackbar from 'components/generic/Snackbar';
import LeagueSelector from 'components/LeagueSelector';

// actions
import { getFuturesSched, getFuturesSchedNext } from 'actions';

const ScrollContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding: 0 var(--space-sm);
  padding-bottom: var(--space-xl);
`;

export default function FuturesSchedDesktop() {
  const dispatch = useDispatch();
  const reduxProps = useSelector(state => ({
    futuresSched: state.scheduleReducer.futuresSched,
    selectedLeagues: state.scheduleReducer.selectedLeagues,
    isLoadingFutures: state.scheduleReducer.isLoadingFutures,
    futuresSchedNext: state.scheduleReducer.futuresSchedNext,
    fetchFuturesError: state.scheduleReducer.fetchFuturesError,
    hasNotStarted: state.scheduleReducer.hasNotStarted,
    inProgress: state.scheduleReducer.inProgress,
    seasonComplete: state.scheduleReducer.seasonComplete,
  }));

  const {
    futuresSched,
    selectedLeagues,
    isLoadingFutures,
    futuresSchedNext,
    hasNotStarted,
    inProgress,
    seasonComplete,
    fetchFuturesError,
  } = reduxProps;

  if (fetchFuturesError) {
    return (
      <Col className="container">
        <h5 style={{ color: 'var(--color-danger)' }}>
          Sorry, we ran into an error getting futures for league:{' '}
          {selectedLeagues[0]}.
        </h5>
      </Col>
    );
  }

  return (
    <>
      <Toolbar>
        <Row style={{ justifyContent: 'flex-start', maxWidth: '1024px' }}>
          <AuthButton
            containerStyle={{ margin: '0 var(--space-sm)' }}
            colorTheme={hasNotStarted ? 'inverted' : 'primary'}
            onPress={() => {
              dispatch(
                getFuturesSched({
                  leagues: selectedLeagues,
                  season: futuresSched.id,
                  hasNotStarted: !hasNotStarted,
                  inProgress: inProgress,
                  seasonComplete: false,
                })
              );
            }}
          >
            Hasn't Started
          </AuthButton>
          <AuthButton
            containerStyle={{ margin: '0 var(--space-sm)' }}
            colorTheme={inProgress ? 'inverted' : 'primary'}
            onPress={() => {
              dispatch(
                getFuturesSched({
                  leagues: selectedLeagues,
                  season: futuresSched.id,
                  hasNotStarted: hasNotStarted,
                  inProgress: !inProgress,
                  seasonComplete: false,
                })
              );
            }}
          >
            In Progress
          </AuthButton>
          <AuthButton
            containerStyle={{ margin: '0 var(--space-sm)' }}
            colorTheme={seasonComplete ? 'inverted' : 'primary'}
            onPress={() => {
              dispatch(
                getFuturesSched({
                  leagues: selectedLeagues,
                  season: futuresSched.id,
                  hasNotStarted: seasonComplete,
                  inProgress: seasonComplete,
                  seasonComplete: !seasonComplete,
                })
              );
            }}
          >
            Complete
          </AuthButton>
        </Row>
        <LeagueSelector futures={true} />
      </Toolbar>
      <MainAreaWrapper>
        <InnerMainArea>
          <ScrollContainer id="infinite-scroll-target-games">
            <InfiniteScroll
              style={{ padding: 'var(--space-xs) 0' }}
              scrollableTarget={'infinite-scroll-target-games'}
              scrollThreshold={0.5}
              next={
                futuresSchedNext ? () => dispatch(getFuturesSchedNext()) : null
              }
              hasMore={futuresSchedNext !== null}
              dataLength={futuresSched?.length || 0}
              loader={<ContentLoader height={'145px'} speed={'2s'} />}
            >
              {isLoadingFutures && (
                <Col>
                  <div>
                    <ActivityIndicator size={2} />
                  </div>
                  <br />
                  <h6>Loading</h6>
                </Col>
              )}
              {!isLoadingFutures && futuresSched === null && <p>No futures</p>}
              {!isLoadingFutures && futuresSched?.length === 0 && (
                <h5 style={{ textAlign: 'center' }}>
                  No {selectedLeagues[0]} futures available for betting. Click
                  the Complete filter to see previous Futures odds.
                </h5>
              )}
              {futuresSched && (
                <Col style={{ maxWidth: '1024px', margin: 'auto' }}>
                  {futuresSched.map(season => (
                    <SeasonListItem
                      key={`season-list-futures-${season.id}`}
                      season={season}
                    />
                  ))}
                </Col>
              )}
            </InfiniteScroll>
          </ScrollContainer>
        </InnerMainArea>
        <BetSlip />
      </MainAreaWrapper>
    </>
  );
}

const StyledSeason = styled.div`
  width: 100%;
  padding: var(--space-sm) var(--space-sm);
  margin-bottom: var(--space-md);
  background-color: var(--color-fg);
  border-radius: var(--std-border-radius);
  transition: all var(--std-transition);
  box-shadow: 0 2px 8px 0 var(--color-shadow);
`;

function SeasonListItem(props) {
  const { season } = props;
  const {
    name,
    start_date,
    in_progress,
    tour,
    completed_at,
    is_complete,
    location,
    total_rounds,
    surface,
    logo,
  } = season;
  return (
    <StyledSeason>
      <Row style={{ justifyContent: 'space-between', alignItems: 'center' }}>
        {logo && (
          <img
            style={{
              flex: 0,
              width: '32px',
              height: 'auto',
              marginRight: 'var(--space-xs)',
            }}
            src={logo}
            alt={name}
          />
        )}
        <h6 style={{ flex: 1, marginBottom: 0 }}>{name}</h6>
        {in_progress && (
          <Badge
            style={{
              color: 'var(--color-success)',
              borderColor: 'var(--color-success)',
              padding: 'var(--space-xxs)',
              marginLeft: 'var(--space-xxs)',
            }}
            text={'LIVE'}
          />
        )}
        {is_complete && (
          <Badge
            style={{
              color: 'var(--color-primary)',
              borderColor: 'var(--color-primary)',
              padding: 'var(--space-xxs)',
              marginLeft: 'var(--space-xxs)',
            }}
            text={'COMPLETE'}
          />
        )}
        <div
          style={{
            flex: 1,
            height: '2px',
            backgroundColor: 'var(--color-text-light)',
            margin: '0 var(--space-md)',
          }}
        />
        <p style={{ margin: 0 }}>
          {moment(start_date).format('MMM D, YYYY')}
          {completed_at && ' - ' + moment(completed_at).format('MMM D, YYYY')}
          {tour && ` | ${tour.toUpperCase()}`}
          {total_rounds > 0 && ` | ${total_rounds} rounds`}
          {surface && ` | ${surface}`}
        </p>
      </Row>
      {Boolean(location) && <p style={{ margin: 0 }}>{location}</p>}
      <Grid
        style={{
          gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
        }}
      >
        {season.future_odds.map(future => (
          <LinkButton
            key={`future-prop-${season.id}-${future.id}`}
            btnTheme="borderless"
            rightIcon={IoChevronForward}
            to={{
              pathname: '/futures/future',
              search: `?league=${season.league}&season_id=${season.id}&prop_type=${future.prop_type}&future_id=${future.id}`,
            }}
          >
            {future.prop_type}
          </LinkButton>
        ))}
      </Grid>
    </StyledSeason>
  );
}
