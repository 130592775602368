import { Observable } from 'rxjs';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import { ajax } from 'rxjs/observable/dom/ajax';
import { SHARPSPORTS } from 'utils';

import {
  FETCH_INTEGRATION_SS,
  fetchIntegrationSSSuccess,
  fetchIntegrationSSFailure,
} from 'actions';

export default function getIntegrationData(action$, state$) {
  return action$.ofType(FETCH_INTEGRATION_SS).switchMap(() => {
    return ajax
      .post(
        `https://api.sharpsports.io/v1/context`,
        { internalId: state$.value.authReducer.user.id },
        {
          Authorization: `Token ${SHARPSPORTS.API_KEY}`,
          'Content-Type': 'application/json',
        }
      )
      .map((data) => fetchIntegrationSSSuccess(data))
      .catch((error) => Observable.of(fetchIntegrationSSFailure(error.xhr)));
  });
}
