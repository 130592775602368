import React from 'react';
import styled from 'styled-components';
import moment from 'moment';

const StyledPaymentMethod = styled.div`
  border-radius: var(--std-border-radius);
  padding: var(--space-md);
  margin-bottom: var(--space-sm);
  cursor: ${(props) => {
    if (props.expired) {
      return 'not-allowed;';
    } else {
      return 'pointer;';
    }
  }};
  border: ${(props) => {
    if (props.checked) {
      return '1px solid var(--color-primary);';
    } else {
      return '1px solid var(--color-text);';
    }
  }};
  opacity: ${(props) => {
    if (props.checked) {
      return '1;';
    } else {
      return '0.75;';
    }
  }};
  transition: all var(--std-transition);
  &:hover {
    opacity: ${(props) => {
      if (props.expired) {
        return '0.75';
      } else {
        return '1';
      }
    }};
    border: ${(props) => {
      if (props.expired) {
        return '1px solid var(--color-text);';
      } else {
        return '1px solid var(--color-primary);';
      }
    }};
    box-shadow: ${(props) => {
      if (props.expired) {
        return '0 0px 0px 0 transparent';
      } else {
        return '0 2px 8px 0 var(--color-shadow)';
      }
    }};
  }
  &:active {
    box-shadow: inset 0 0 0 100vh rgba(0, 0, 0, 0.1);
    box-shadow: ${(props) => {
      if (props.expired) {
        return 'inset 0 0 0 0vh rgba(0, 0, 0, 0.1)';
      } else {
        return 'inset 0 0 0 100vh rgba(0, 0, 0, 0.1)';
      }
    }};
  }
`;

export default function PaymentMethod(props) {
  const { method, name, checked } = props;
  let exp_date = '';
  if (method.card.exp_month < 10) {
    exp_date = '0' + method.card.exp_month;
  } else {
    exp_date = method.card.exp_month.toString();
  }
  exp_date += '/' + method.card.exp_year.toString().substr(2);

  const expired = moment().isAfter(method.expiry_date);

  return (
    <StyledPaymentMethod
      expired={expired}
      onClick={expired ? null : () => props.onClick()}
      checked={props.checked}
    >
      <input
        type="radio"
        name={name}
        value={method.id}
        checked={checked}
        onChange={expired ? null : () => props.onClick()}
      />
      <label>
        &nbsp;
        {method.card.brand.toUpperCase()} ending in {method.card.last4}{' '}
        <span
          style={{
            color: expired ? 'var(--color-danger)' : 'var(--color-text)',
          }}
        >
          ({exp_date})
        </span>
      </label>
    </StyledPaymentMethod>
  );
}
