import { AMPLITUDE } from 'utils';
const Amplitude = require('amplitude-js');

let isInitialized = false;
let setUser = false;

export const events = {
  // SIGNUP USER
  CREATE_USER: 'CREATE_USER',
  CREATE_USER_SUCCESS: 'CREATE_USER_SUCCESS',
  CREATE_USER_FAILURE: 'CREATE_USER_FAILURE',
  MANUALLY_EDIT_USERNAME: 'MANUALLY_EDIT_USERNAME',
  CLICKED_TOS: 'CLICKED_TOS',
  CLICKED_PRIVACY_POLICY: 'CLICKED_PRIVACY_POLICY',
  SIGNUP_GRANTED_LOCATION_PERMISSION: 'SIGNUP_GRANTED_LOCATION_PERMISSION',
  SIGNUP_DENIED_LOCATION_PERMISSION: 'SIGNUP_DENIED_LOCATION_PERMISSION',
  SIGNUP_LOCATION_ERROR: 'SIGNUP_LOCATION_ERROR',
  SIGNUP_SET_REGION_MANUALLY: 'SIGNUP_SET_REGION_MANUALLY',
  SIGNUP_LOCATE_ME: 'SIGNUP_LOCATE_ME',
  SIGNUP_LOCATION_SERVICE_DISABLED: 'SIGNUP_LOCATION_SERVICE_DISABLED',

  // LOGIN USER
  LOGIN_USER: 'LOGIN_USER',
  LOGIN_USER_SUCCESS: 'LOGIN_USER_SUCCESS',
  LOGIN_USER_FAILURE: 'LOGIN_USER_FAILURE',

  // LOGIN + SIGNUP
  SHOW_PASSWORD: 'SHOW_PASSWORD',
  HIDE_PASSWORD: 'HIDE_PASSWORD',

  // PROFILE SETUP
  PROFILE_SETUP_START_BETSYNC: 'PROFILE_SETUP_START_BETSYNC',
  PROFILE_SETUP_START_REFERRAL: 'PROFILE_SETUP_START_REFERRAL',
  PROFILE_SETUP_START_SETUP: 'PROFILE_SETUP_START_SETUP',
  PROFILE_SETUP_NEXT: 'PROFILE_SETUP_NEXT',
  PROFILE_SETUP_BACK: 'PROFILE_SETUP_BACK',
  PROFILE_SETUP_DONE: 'PROFILE_SETUP_DONE',
  PROFILE_SETUP_BACK_TO_SETUP: 'PROFILE_SETUP_BACK_TO_SETUP',
  START_USING_BETSTAMP: 'START_USING_BETSTAMP',

  // PROFILE SETUP PAGES
  PROFILE_SETUP_MY_SPORTSBOOKS: 'PROFILE_SETUP_MY_SPORTSBOOKS',
  PROFILE_SETUP_BA_ODDS: 'PROFILE_SETUP_BA_ODDS',
  PROFILE_SETUP_OTHER_ODDS: 'PROFILE_SETUP_OTHER_ODDS',
  PROFILE_SETUP_START_PROFILE_PICTURE_UPLOAD:
    'PROFILE_SETUP_START_PROFILE_PICTURE_UPLOAD',
  PROFILE_SETUP_CHANGE_THEME: 'PROFILE_SETUP_CHANGE_THEME',
  PROFILE_SETUP_PROFILE_PICTURE_SUCCESS:
    'PROFILE_SETUP_PROFILE_PICTURE_SUCCESS',
  PROFILE_SETUP_PROFILE_PICTURE_FAILURE:
    'PROFILE_SETUP_PROFILE_PICTURE_FAILURE',
  PROFILE_SETUP_LEAGUES: 'PROFILE_SETUP_LEAGUES',
  PROFILE_SETUP_DEFAULT_ODDS: 'PROFILE_SETUP_DEFAULT_ODDS',
  PROFILE_SETUP_DEFAULT_RISK_OR_BASE: 'PROFILE_SETUP_DEFAULT_RISK_OR_BASE',
  PROFILE_SETUP_EXPERIENCE: 'PROFILE_SETUP_EXPERIENCE',
  PROFILE_SETUP_IS_PUBLIC: 'PROFILE_SETUP_IS_PUBLIC',
  PROFILE_SETUP_HIDE_PENDING_BETS: 'PROFILE_SETUP_HIDE_PENDING_BETS',

  // WELCOME
  VIEW_WELCOME: 'VIEW_WELCOME',
  WELCOME_LOGIN: 'WELCOME_LOGIN',
  WELCOME_SIGNUP: 'WELCOME_SIGNUP',
  WELCOME_EXIT: 'WELCOME_EXIT',

  // AUTH/USER
  UPDATE_USER: 'UPDATE_USER',
  SEND_EMAIL_VERIFICATION: 'SEND_EMAIL_VERIFICATION',
  DEACTIVATE_USER: 'DEACTIVATE_USER',
  LOGOUT_USER: 'LOGOUT_USER',

  // LOCATION
  SET_REGION_MANUALLY: 'SET_REGION_MANUALLY',
  LOCATE_ME: 'LOCATE_ME',
  GRANTED_LOCATION_PERMISSION: 'GRANTED_LOCATION_PERMISSION',
  DENIED_LOCATION_PERMISSION: 'DENIED_LOCATION_PERMISSION',
  LOCATION_ERROR: 'LOCATION_ERROR',
  LOCATION_SERVICE_DISABLED: 'LOCATION_SERVICE_DISABLED',

  // SELL_PICKS / STRIPE
  VIEW_SELL_PICKS: 'VIEW_SELL_PICKS',
  CREATE_STRIPE_ACCOUNT: 'CREATE_STRIPE_ACCOUNT',
  START_PURCHASE_SESSION: 'START_PURCHASE_SESSION',
  VIEW_PACKAGE_FROM_FEATURED: 'VIEW_PACKAGE_FROM_FEATURED',
  VIEW_PACKAGE_FROM_USER: 'VIEW_PACKAGE_FROM_USER',
  CANCEL_TRANSACTION: 'CANCEL_TRANSACTION',
  TRANSACTION_SUCCESS_APP: 'TRANSACTION_SUCCESS_APP',

  // PUBLIC_PROFILE
  VIEW_LEADERBOARD: 'VIEW_LEADERBOARD',
  GET_PUBLIC_PROFILE: 'GET_PUBLIC_PROFILE',
  GET_PUBLIC_PROFILE_BY_USERNAME: 'GET_PUBLIC_PROFILE_BY_USERNAME',
  SHARE_PROFILE: 'SHARE_PROFILE',

  // NOTIFICATIONS
  NOTIFICATION_CLICKED: 'NOTIFICATION_CLICKED',
  UPDATE_NOTIFICATION_PREFERENCES: 'UPDATE_NOTIFICATION_PREFERENCES',
  ADD_NOTIFICATION_PUSH_TOKEN: 'ADD_NOTIFICATION_PUSH_TOKEN',

  // ACCOUNTING
  VIEW_ACCOUNTING_BOOK: 'VIEW_ACCOUNTING_BOOK',
  VIEW_ACCOUNTING_SUMMARY: 'VIEW_ACCOUNTING_SUMMARY',

  // ANALYSIS
  VIEW_SELF_ANALYSIS: 'VIEW_SELF_ANALYSIS',
  VIEW_OTHER_USER_ANALYSIS: 'VIEW_OTHER_USER_ANALYSIS',
  CREATE_ANALYSIS_PRESET: 'CREATE_ANALYSIS_PRESET',
  GET_ANALYSIS: 'GET_ANALYSIS',
  CLICK_SAVE_ANALYSIS: 'CLICK_SAVE_ANALYSIS',

  // SCREENSHOT PICKS
  SCREENSHOT_PENDING_CAMERA: 'SCREENSHOT_PENDING_CAMERA',
  SCREENSHOT_PENDING_BUTTON: 'SCREENSHOT_PENDING_BUTTON',
  SCREENSHOT_GRADED_BUTTON: 'SCREENSHOT_GRADED_BUTTON',

  // CLICKING TUTORIALS / FAQ
  CLICK_FAQ_ALERT: 'CLICK_FAQ_ALERT',
  CLICK_FAQ_LINK: 'CLICK_FAQ_LINK',

  // GAMES
  VIEW_GAME_LIVE_LINE: 'VIEW_GAME_LIVE_LINE',
  VIEW_GAME_LINE: 'VIEW_GAME_LINE',
  VIEW_GAME_1H_LINE: 'VIEW_GAME_1H_LINE',
  VIEW_LINE_HISTORY: 'VIEW_LINE_HISTORY',
  VIEW_PLAYER_PROPS: 'VIEW_PLAYER_PROPS',
  VIEW_GAME_PROPS: 'VIEW_GAME_PROPS',
  REFRESH_GAME_ODDS: 'REFRESH_GAME_ODDS',
  ADD_BOOK_GAMES_SCREEN: 'ADD_BOOK_GAMES_SCREEN',
  REMOVE_BOOK_GAMES_SCREEN: 'REMOVE_BOOK_GAMES_SCREEN',
  REORDER_BOOKS_GAMES_SCREEN: 'REORDER_BOOKS_GAMES_SCREEN',

  // AFFILIATES
  CLICK_AFFILIATE: 'CLICK_AFFILIATE',
  AFFILIATE_VIEW: 'AFFILIATE_VIEW',

  // AUTOSYNC
  CLICK_AUTOSYNC_CONNECT: 'CLICK_AUTOSYNC_CONNECT',
  AS_LOGIN_USER: 'AS_LOGIN_USER',
  AS_LOGIN_USER_SUCCESS: 'AS_LOGIN_USER_SUCCESS',
  AS_LOGIN_USER_FAILURE: 'AS_LOGIN_USER_FAILURE',
  DISCONNECT_AUTOSYNC: 'DISCONNECT_AUTOSYNC',
  DISCONNECT_AUTOSYNC_SUCCESS: 'DISCONNECT_AUTOSYNC_SUCCESS',
  DISCONNECT_AUTOSYNC_FAILURE: 'DISCONNECT_AUTOSYNC_FAILURE',
  DISCONNECT_ALL_AUTOSYNC: 'DISCONNECT_ALL_AUTOSYNC',
  DISCONNECT_ALL_AUTOSYNC_SUCCESS: 'DISCONNECT_ALL_AUTOSYNC_SUCCESS',
  DISCONNECT_ALL_AUTOSYNC_FAILURE: 'DISCONNECT_ALL_AUTOSYNC_FAILURE',
  SYNC_BETS: 'SYNC_BETS',
  SYNC_BETS_SUCCESS: 'SYNC_BETS_SUCCESS',
  SYNC_BETS_FAILURE: 'SYNC_BETS_FAILURE',
  SYNC_ALL_BETS: 'SYNC_ALL_BETS',
  SYNC_ALL_BETS_SUCCESS: 'SYNC_ALL_BETS_SUCCESS',
  SYNC_ALL_BETS_FAILURE: 'SYNC_ALL_BETS_FAILURE',

  // BETSLIP
  ADD_BET: 'ADD_BET',
  REMOVE_ONE_BET: 'REMOVE_ONE_BET',
  REMOVE_ALL_BETS: 'REMOVE_ALL_BETS',
  CHANGE_BETSLIP_MODE: 'CHANGE_BETSLIP_MODE',
  TRACK_BETSLIP: 'TRACK_BETSLIP',
  TRACK_BETSLIP_SUCCESS: 'TRACK_BETSLIP_SUCCESS',
  TRACK_BETSLIP_FAILURE: 'TRACK_BETSLIP_FAILURE',
  REUSE_BETS: 'REUSE_BETS',

  // PLACE LOADED BETSLIP
  TRACK_BET_AT_SPORTSBOOK: 'TRACK_BET_AT_SPORTSBOOK',
  TRACK_BET_AT_SPORTSBOOK_SUCCESS: 'TRACK_BET_AT_SPORTSBOOK_SUCCESS',
  TRACK_BET_AT_SPORTSBOOK_FAILURE: 'TRACK_BET_AT_SPORTSBOOK_FAILURE',

  // TOUR
  FINISHED_TOUR: 'FINISHED_TOUR',
  SKIPPED_TOUR: 'SKIPPED_TOUR',

  // BETLINK
  CLICK_GENERAL_LINK_BOOK: 'CLICK_GENERAL_LINK_BOOK',
  CLICK_SPECIFIC_LINK_BOOK: 'CLICK_SPECIFIC_LINK_BOOK',
  BETLINK_SYNC_ALL: 'BETLINK_SYNC_ALL',
  BETLINK_UNLINK_BOOK: 'BETLINK_UNLINK_BOOK',
  BETLINK_REVERIFY_BOOK: 'BETLINK_REVERIFY_BOOK',

  // ODDSCOMP
  VIEW_ODDSCOMP: 'VIEW_ODDSCOMP',

  // FIND BETS
  GET_FIND_BETS: 'GET_FIND_BETS',

  // SIGNUP ASSIST TOOL
  TROUBLESHOOTER_CLICK: 'TROUBLESHOOTER_CLICK',
  STEP_CLICK: 'STEP_CLICK',
};

export function initialize(id) {
  if (isInitialized && setUser) {
    return;
  }
  if (!isInitialized) {
    Amplitude.getInstance().init(AMPLITUDE.API_KEY);
    isInitialized = true;
  }

  if (id && !setUser) {
    Amplitude.setUserId(id.toString());
    setUser = true;
  }
}

export function track(event, id, properties) {
  initialize(id);

  if (properties) {
    Amplitude.getInstance().logEvent(event, properties);
  } else {
    Amplitude.getInstance().logEvent(event);
  }
}

export default {
  events,
  initialize,
  track,
};
