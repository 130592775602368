import React from 'react';
import styled from 'styled-components';

const Switch = styled.div`
  display: inline-block;
  cursor: pointer;
  padding: 3px var(--space-sm);
  font-size: var(--text-sm);
  transition: all 0.15s ease-in-out;

  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : 'var(--text-xs)'};

  border: 1px solid var(--color-primary);
  border-left: ${(props) => (props.borderLeft ? props.borderLeft : 'none')};

  background: ${(props) =>
    props.active ? 'var(--color-primary)' : 'transparent'};

  color: ${(props) => (props.active ? 'white' : 'var(--color-primary)')};

  &:hover {
    background: var(--color-primary);
    color: white;
    opacity: 0.9;
  }
`;

let BORDER_RADIUS = 'var(--std-border-radius)';

export default function Switcher(props) {
  const { options, active } = props;
  return (
    <>
      {options.map((option, i) => {
        let borderRadius = '0px';
        let borderLeft = 'none';
        if (i === 0) {
          borderRadius = `${BORDER_RADIUS} 0 0 ${BORDER_RADIUS}`;
          borderLeft = '1px solid var(--color-primary)';
        } else if (i === options.length - 1) {
          borderRadius = `0px ${BORDER_RADIUS} ${BORDER_RADIUS} 0px`;
        }

        return (
          <Switch
            key={`switcher-${option}-${i}`}
            active={active === option}
            borderRadius={borderRadius}
            borderLeft={borderLeft}
            onClick={() => props.changeMode(option)}
          >
            {option.toUpperCase()}
          </Switch>
        );
      })}
    </>
  );
}
