import { Observable } from 'rxjs';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import { ajax } from 'rxjs/observable/dom/ajax';
import { BACKEND_API_URL, getHeaders } from 'utils';

import {
  UPDATE_TRANSACTION,
  updateTransactionSuccess,
  updateTransactionFailure,
} from 'actions';

export default function updateAllbet(action$, state$) {
  return action$.ofType(UPDATE_TRANSACTION).exhaustMap(action => {
    let url = `${BACKEND_API_URL}api/transactions/${action.tx.id}/`;

    return ajax
      .patch(url, action.tx, getHeaders(state$))
      .map(data => updateTransactionSuccess(data.response))
      .catch(error => Observable.of(updateTransactionFailure(error.xhr)));
  });
}
