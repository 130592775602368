import { Observable } from 'rxjs';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import { ajax } from 'rxjs/observable/dom/ajax';
import { BACKEND_API_URL, getHeaders } from 'utils';

import {
  GET_ALL_BOOKS_MAP,
  getAllBooksMapSuccess,
  getAllBooksMapFailure,
} from 'actions';

export default function getAllBooksMap(action$, state$) {
  return action$.ofType(GET_ALL_BOOKS_MAP).switchMap(() => {
    return ajax
      .getJSON(`${BACKEND_API_URL}api/all-books-map/`, getHeaders(state$))
      .map(data => getAllBooksMapSuccess(data))
      .catch(error => Observable.of(getAllBooksMapFailure(error.xhr)));
  });
}
