import { Observable } from 'rxjs';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import { ajax } from 'rxjs/observable/dom/ajax';
import { BACKEND_API_URL, getHeaders } from 'utils';

import {
  GET_TRANSACTION_SUMMARY,
  getTransactionSummarySuccess,
  getTransactionSummaryFailure,
} from 'actions';

export default function getTransactionSummary(action$, state$) {
  return action$.ofType(GET_TRANSACTION_SUMMARY).switchMap(action => {
    let url = BACKEND_API_URL + 'api/transaction_summary/';
    if (action.sortBy) url += `?sort_by=${action.sortBy}`;
    return ajax
      .getJSON(url, getHeaders(state$))
      .map(data => getTransactionSummarySuccess(data))
      .catch(error => Observable.of(getTransactionSummaryFailure(error.xhr)));
  });
}
