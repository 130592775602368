import { Observable } from 'rxjs';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import { ajax } from 'rxjs/observable/dom/ajax';
import { BACKEND_API_URL, getHeaders } from 'utils';

import {
  GET_ODDSCOMP_GAMES,
  getOddsCompGamesSuccess,
  getOddsCompGamesFailure,
} from 'actions';

export default function getOddsCompGames(action$, state$) {
  return action$.ofType(GET_ODDSCOMP_GAMES).switchMap(action => {
    let url = BACKEND_API_URL + `api/games/refresh/?`;

    if (action.game_ids) url += `game_ids=${JSON.stringify(action.game_ids)}&`;

    return ajax
      .getJSON(url, getHeaders(state$))
      .map(data => getOddsCompGamesSuccess(data))
      .catch(error => Observable.of(getOddsCompGamesFailure(error.xhr)));
  });
}
