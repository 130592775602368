import { combineEpics } from 'redux-observable';

import refreshSharpsportsAll from 'epics/sharpsports/refreshSharpsportsAll.epic';
import refreshSharpsportsBook from 'epics/sharpsports/refreshSharpsportsBook.epic';
import getSharpsportsBooks from 'epics/sharpsports/getSharpsportsBooks.epic';
import getIntegrationData from 'epics/sharpsports/getIntegrationData.epic';
import updateSSBook from 'epics/sharpsports/updateSSBook.epic';
import refreshSSBookHistory from 'epics/sharpsports/refreshSSBookHistory.epic';

export default combineEpics(
  refreshSharpsportsAll,
  refreshSharpsportsBook,
  getSharpsportsBooks,
  getIntegrationData,
  updateSSBook,
  refreshSSBookHistory
);
