import { Observable } from 'rxjs';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import { ajax } from 'rxjs/observable/dom/ajax';
import { BACKEND_API_URL, getHeaders } from 'utils';

import { GET_SURVEY, getSurveySuccess, getSurveyFailure } from 'actions';

export default function getSurvey(action$, state$) {
  return action$.ofType(GET_SURVEY).switchMap(action => {
    let url = BACKEND_API_URL + `api/surveys/${action.survey_id}`;
    return ajax
      .getJSON(url, getHeaders(state$))
      .map(data => getSurveySuccess(data))
      .catch(error => Observable.of(getSurveyFailure(error.xhr)));
  });
}
