import { Observable } from 'rxjs';
import 'rxjs/add/operator/exhaustMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import { ajax } from 'rxjs/observable/dom/ajax';
import { BACKEND_API_URL, RELATIONSHIP_STATUS, getHeaders } from 'utils';

import {
  UNFOLLOW_USER,
  unfollowUserSuccess,
  unfollowUserFailure,
  followedUserFromSearch,
} from 'actions';

export default function unfollowUser(action$, state$) {
  return action$.ofType(UNFOLLOW_USER).exhaustMap(action => {
    return ajax
      .post(
        BACKEND_API_URL + 'api/users/remove_relationship/',
        {
          other_user: action.otherUserID,
          status: RELATIONSHIP_STATUS.FOLLOWING,
        },
        getHeaders(state$)
      )
      .mergeMap(data => [
        unfollowUserSuccess(data, action.otherUserID),
        followedUserFromSearch(data, action.otherUserID),
      ])
      .catch(error => Observable.of(unfollowUserFailure(error.xhr)));
  });
}
