/**
 * Returns date formatted as: Tue Mar 31
 * @param {Date} date Date object to be formatted.
 * @returns {String} Date formatting into a human readable format.
 */
export default function formatDate(date) {
  date = new Date(date);
  if (isNaN(date.getTime())) {
    // If the date object is invalid it will return 'NaN' on getTime()
    throw new Error('Invalid date given to formatDate()' + date.getTime());
  }
  const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thurs', 'Fri', 'Sat'];
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'June',
    'July',
    'Aug',
    'Sept',
    'Oct',
    'Nov',
    'Dec',
  ];
  return (
    days[date.getDay()] +
    ' ' +
    months[date.getMonth()] +
    ' ' +
    date.getDate().toString()
  );
}
