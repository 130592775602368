import React, { useState, useMemo } from 'react';
import moment from 'moment';

// components
import { IoCalendarOutline, IoArrowBack } from 'react-icons/io5';
import StaffOnboardingBase from 'components/StaffOnboardingBase';
import { LinkButton, AuthButton } from 'components/AuthButton';
import CalendarInput from 'components/generic/CalendarInput';
import CustomSelect from 'components/generic/Select';
import { SummaryEntry } from 'pages/AffiliateEarningDashboard/AffiliateEarningDashboard';
import { Row, Col } from 'components/generic/Layout';

import { formatMoney } from 'utils';

const specialHeaderFormat = (t, obj, groupings, group, allBooksMap = null) => {
  let text = obj[group];
  if (obj.depth !== groupings.indexOf(group) + 1) {
    return ' ';
  }
  if (groupings[obj.depth - 1] !== group) return ' ';
  if (obj.depth === 1) text = '⦿ ' + text;
  else text = '⤷ ' + text;
  return text;
};

const specialFormatMoney = t => {
  if (t === undefined || t === null) return '-';
  return formatMoney(t, 'CAD', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
};

export default function AffiliateEarningSnapshots(props) {
  return (
    <>
      <StaffOnboardingBase
        endpoint="aff-earnings-snapshots"
        queryOptions={{
          retryOnWindowFocus: false,
          retry: false,
        }}
        modelName="Snapshot"
        headings={[
          'Book',
          'Region',
          'Clicks',
          'Signups',
          'CPA Count',
          'CPA',
          'Rev Share',
          'Deposits',
          'NGR',
        ]}
        objKeys={[
          'book__name',
          'state',
          'clicks',
          'registrations',
          'cpa_commission_count',
          'converted_cpa',
          'converted_rev_share',
          'converted_deposits',
          'converted_ngr',
        ]}
        extraHeaderComponent={params => (
          <>
            <LinkButton
              to="/staff/affiliate-metrics"
              btnTheme="borderless"
              style={{ margin: 'var(--space-xxs)' }}
              leftIcon={IoArrowBack}
              leftIconSize={18}
            >
              Back
            </LinkButton>
            <h6 style={{ margin: 'var(--space-xxs)' }}>
              Affiliate report snapshot from {params.date_for || 'Yesterday'}
            </h6>
          </>
        )}
        formatText={{
          book__name: (t, obj) =>
            specialHeaderFormat(t, obj, ['book__name', 'state'], 'book__name'),
          state: (t, obj) =>
            specialHeaderFormat(t, obj, ['book__name', 'state'], 'state'),
          converted_deposits: t =>
            specialFormatMoney(t, 'CAD', {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            }),
          converted_ngr: t =>
            specialFormatMoney(t, 'CAD', {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            }),
          converted_cpa: t =>
            specialFormatMoney(t, 'CAD', {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            }),
          converted_rev_share: t =>
            specialFormatMoney(t, 'CAD', {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            }),
        }}
        hideCreate
        showSearch={false}
        PanelChildren={() => <></>}
        panelSkipNullValues={true}
        panelTabs={['Details']}
        hidePanelTabs={true}
        panelSkipKeys={['depth', 'parent_key', 'children_key', 'id']}
        panelTitleOverride={'Metric details'}
        FilterChildren={FilterChildren}
        extraHeaderRow={page => {
          let summary = page && page[0].summary;
          return (
            <Row style={{ position: 'sticky', right: 0 }}>
              {!!summary &&
                summary.map((item, i) => (
                  <SummaryEntry
                    key={`summary-snapshot-metrics-${i}`}
                    entry={item}
                    containerStyle={{
                      flexBasis: '15%',
                      flexWrap: 'column',
                      padding: 'var(--space-xs)',
                      minHeight: '108px',
                    }}
                  />
                ))}
            </Row>
          );
        }}
        extraHeaderRowStyle={{
          position: 'relative',
          borderBottom: '1px solid var(--color-fg)',
          borderTop: '1px solid var(--color-fg)',
          zIndex: 1,
        }}
      />
    </>
  );
}

const DATE_RANGE_MAP = {
  MTD: 'Month to Date',
  PREV_MONTH: 'Previous Month',
  PAST_WEEK: 'Past Week',
};

function FilterChildren(props) {
  const { urlParams } = props;

  const [calendarOpen, setCalendarOpen] = useState(false);
  const defaultFilters = useMemo(() => {
    let filters = {};

    if (urlParams.date_for) {
      filters.date_for = urlParams.date_for;
    }
    if (urlParams.date_range) {
      filters.date_range = urlParams.date_range;
    }
    return filters;
  }, [urlParams]);

  return (
    <div style={{ width: '100%' }}>
      <p
        style={{
          fontSize: 'var(--text-sm)',
          margin: '0 var(--space-xxs)',
        }}
      >
        Snapshot from:
      </p>
      <AuthButton
        containerStyle={{ flex: 0.5 }}
        onPress={() => setCalendarOpen(!calendarOpen)}
        leftIcon={IoCalendarOutline}
      >
        {defaultFilters.date_for || 'Yesterday'}
      </AuthButton>

      <CalendarInput
        extraClassNames={['hide-calendar-inputs']}
        onChange={newDate => {
          setCalendarOpen(false);
          props.onFilter({ date_for: moment(newDate).format('YYYY-MM-DD') });
        }}
        isOpen={calendarOpen}
      />
      <Col style={{ alignItems: 'flex-start' }}>
        <p
          style={{
            fontSize: 'var(--text-sm)',
            margin: 'var(--space-xxs)',
          }}
        >
          Select time range to show from snapshot date:
        </p>
        <CustomSelect
          defaultValue={
            defaultFilters?.date_range
              ? {
                  value: defaultFilters.date_range,
                  label:
                    DATE_RANGE_MAP[defaultFilters.date_range] +
                    ` from ${defaultFilters.date_for || 'Yesterday'}`,
                }
              : {
                  value: 'MTD',
                  label:
                    DATE_RANGE_MAP['MTD'] +
                    ` from ${defaultFilters.date_for || 'Yesterday'}`,
                }
          }
          options={[
            {
              value: 'PAST_WEEK',
              label:
                DATE_RANGE_MAP['PAST_WEEK'] +
                ` from ${defaultFilters.date_for || 'Yesterday'}`,
            },
            {
              value: 'MTD',
              label:
                DATE_RANGE_MAP['MTD'] +
                ` from ${defaultFilters.date_for || 'Yesterday'}`,
            },
            {
              value: 'PREV_MONTH',
              label:
                DATE_RANGE_MAP['PREV_MONTH'] +
                ` from ${defaultFilters.date_for || 'Yesterday'}`,
            },
          ]}
          onChange={val => props.onFilter({ date_range: val.value })}
        />
      </Col>
    </div>
  );
}
