import { Observable } from 'rxjs';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import { ajax } from 'rxjs/observable/dom/ajax';
import { BACKEND_API_URL, getHeaders } from 'utils';

import {
  GET_INFLUENCER_PAGE,
  getInfluencerPageSuccess,
  getInfluencerPageFailure,
} from 'actions';

export default function getInfluencerPage(action$, state$) {
  return action$.ofType(GET_INFLUENCER_PAGE).switchMap(action => {
    let url = `${BACKEND_API_URL}api/influencer-page/${action.slug}?/state=${action.state}&site=betstamp.app`;
    if (!!action.searchParams) {
      for (const [k, v] of action.searchParams) {
        url += `&${k}=${v}`;
      }
    }
    return ajax
      .getJSON(url, getHeaders(state$))
      .map(data => getInfluencerPageSuccess(data))
      .catch(error => Observable.of(getInfluencerPageFailure(error.xhr)));
  });
}
