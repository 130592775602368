import {
  GET_ACCOUNTS,
  GET_ACCOUNTS_SUCCESS,
  GET_ACCOUNTS_FAILURE,
  AS_LOGIN_USER,
  AS_LOGIN_USER_SUCCESS,
  AS_LOGIN_USER_FAILURE,
  AS_RESET_ERRORS,
  DISCONNECT_AUTOSYNC,
  DISCONNECT_AUTOSYNC_SUCCESS,
  DISCONNECT_AUTOSYNC_FAILURE,
  DISCONNECT_ALL_AUTOSYNC,
  DISCONNECT_ALL_AUTOSYNC_SUCCESS,
  DISCONNECT_ALL_AUTOSYNC_FAILURE,
  SYNC_BETS,
  SYNC_BETS_SUCCESS,
  SYNC_BETS_FAILURE,
  SYNC_ALL_BETS,
  SYNC_ALL_BETS_SUCCESS,
  SYNC_ALL_BETS_FAILURE,
  SYNC_ALL_ACCOUNTS,
  SYNC_ALL_ACCOUNTS_SUCCESS,
  SYNC_ALL_ACCOUNTS_FAILURE,
  REFRESH_HISTORY,
  REFRESH_HISTORY_SUCCESS,
  REFRESH_HISTORY_FAILURE,
  REFRESH_HISTORY_ALL,
  REFRESH_HISTORY_ALL_SUCCESS,
  REFRESH_HISTORY_ALL_FAILURE,
  PROMPT_TWO_FA,
  TWO_FA,
  TWO_FA_SUCCESS,
  TWO_FA_FAILURE,
  UPDATE_SETTINGS,
  UPDATE_SETTINGS_SUCCESS,
  UPDATE_SETTINGS_FAILURE,
  CLEAN_AUTOSYNC_STORE,
} from 'actions/autosync';

const initialState = {
  autoSyncBooksAccounts: {},
  isLoadingGetAccounts: false,
  isLoadingGetAccountsSuccess: null,
  isLoadingGetAccountsFailure: null,
  isLoadingAutoSyncLogin: false,
  isLoadingAutoSyncLoginSuccess: null,
  isLoadingAutoSyncLoginFailure: null,
  isLoadingAutoSyncDisconnect: false,
  isLoadingAutoSyncDisconnectSuccess: null,
  isLoadingAutoSyncDisconnectFailure: null,
  isLoadingAutoSyncDisconnectAll: false,
  isLoadingAutoSyncDisconnectAllSuccess: null,
  isLoadingAutoSyncDisconnectAllFailure: null,
  isLoadingSyncBets: false,
  isLoadingSyncBetsSuccess: null,
  isLoadingSyncBetsFailure: null,
  isLoadingSyncAllBets: {},
  isLoadingSyncAllBetsSuccess: {},
  isLoadingSyncAllBetsFailure: {},
  isLoadingRefreshHistory: false,
  isLoadingRefreshHistorySuccess: null,
  isLoadingRefreshHistoryFailure: null,
  isLoadingRefreshHistoryAll: false,
  isLoadingRefreshHistoryAllSuccess: null,
  isLoadingRefreshHistoryAllFailure: null,
  latestRefresh: null,
  needs2FA: null,
  twoFAData: {},
  isLoading2FA: false,
  isLoading2FASuccess: null,
  isLoading2FAFailure: null,
  isLoadingUpdateSettings: false,
  isLoadingUpdateSettingsSuccess: null,
  isLoadingUpdateSettingsFailure: null,
  isLoadingSyncAllAccounts: {},
  isLoadingSyncAllAccountsSuccess: {},
  isLoadingSyncAllAccountsFailure: {},
};

export default function autosyncReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ACCOUNTS: {
      return Object.assign({}, state, {
        ...state,
        isLoadingGetAccounts: true,
        isLoadingGetAccountsFailure: false,
      });
    }
    case GET_ACCOUNTS_SUCCESS: {
      let updateAccounts = {};
      let latestBookRefresh = { ...state.latestRefresh };
      for (let obj of action.payload.results) {
        updateAccounts[obj['book']['name']] = {
          ...updateAccounts[obj['book']['name']],
        };
        updateAccounts[obj['book']['name']][obj['username']] = {
          ...updateAccounts[obj['book']['name']][obj['username']],
        };
        updateAccounts[obj['book']['name']][obj['username']][obj['region']] = {
          lastRefresh: obj['last_refresh'],
          currentBalance: obj['current_balance'],
          currentBonus: obj['bonus_balance'],
          totalRefreshes: obj['total_refreshes'],
          record: obj['record'],
          createdAt: obj['created_at'],
          credentialsOOD: obj['credentials_out_of_date'],
          two_fa_required: obj['two_fa_required'],
          id: obj['pk'],
        };
        latestBookRefresh = { ...latestBookRefresh };
        latestBookRefresh[obj['book']['name']] = obj['last_refresh'];
      }
      return Object.assign({}, state, {
        ...state,
        isLoadingGetAccountsSuccess: true,
        isLoadingGetAccounts: false,
        isLoadingGetAccountsFailure: null,
        autoSyncBooksAccounts: updateAccounts,
        latestRefresh: latestBookRefresh,
      });
    }
    case GET_ACCOUNTS_FAILURE: {
      return Object.assign({}, state, {
        ...state,
        isLoadingGetAccountsFailure: action.payload,
        isLoadingGetAccountsSuccess: null,
        isLoadingGetAccounts: false,
      });
    }
    case AS_LOGIN_USER: {
      return Object.assign({}, state, {
        ...state,
        isLoadingAutoSyncLogin: true,
        isLoadingAutoSyncLoginFailure: null,
      });
    }
    case AS_LOGIN_USER_SUCCESS: {
      return Object.assign({}, state, {
        ...state,
        isLoadingAutoSyncLoginSuccess: true,
        isLoadingAutoSyncLogin: false,
        isLoadingAutoSyncLoginFailure: null,
        latestRefresh: action.payload.response.last_refresh,
      });
    }

    case AS_LOGIN_USER_FAILURE: {
      return Object.assign({}, state, {
        ...state,
        isLoadingAutoSyncLoginFailure: action.payload,
        isLoadingAutoSyncLogin: false,
        isLoadingAutoSyncLoginSuccess: null,
      });
    }
    case AS_RESET_ERRORS: {
      return Object.assign({}, state, {
        ...state,
        isLoadingGetAccounts: false,
        isLoadingGetAccountsSuccess: null,
        isLoadingGetAccountsFailure: null,
        isLoadingAutoSyncLoginFailure: null,
        isLoadingAutoSyncLoginSuccess: null,
        isLoadingAutoSyncLogin: false,
        isLoadingAutoSyncDisconnectFailure: null,
        isLoadingAutoSyncDisconnectSuccess: null,
        isLoadingAutoSyncDisconnect: false,
        isLoadingAutoSyncDisconnectAll: false,
        isLoadingAutoSyncDisconnectAllSuccess: null,
        isLoadingAutoSyncDisconnectAllFailure: null,
        isLoadingSyncBets: false,
        isLoadingSyncBetsSuccess: null,
        isLoadingSyncBetsFailure: null,
        isLoadingSyncAllBets: {},
        isLoadingSyncAllBetsSuccess: {},
        isLoadingSyncAllBetsFailure: {},
        isLoadingRefreshHistory: false,
        isLoadingRefreshHistorySuccess: null,
        isLoadingRefreshHistoryFailure: null,
        isLoadingRefreshHistoryAll: false,
        isLoadingRefreshHistoryAllSuccess: null,
        isLoadingRefreshHistoryAllFailure: null,
        isLoading2FA: false,
        isLoading2FASuccess: null,
        isLoading2FAFailure: null,
        needs2FA: null,
        twoFAData: {},
        isLoadingUpdateSettings: false,
        isLoadingUpdateSettingsSuccess: null,
        isLoadingUpdateSettingsFailure: null,
      });
    }
    case DISCONNECT_AUTOSYNC: {
      return Object.assign({}, state, {
        ...state,
        isLoadingAutoSyncDisconnect: true,
        isLoadingAutoSyncDisconnectFailure: null,
        isLoadingAutoSyncDisconnectSuccess: null,
      });
    }
    case DISCONNECT_AUTOSYNC_SUCCESS: {
      let deletedAccount = { ...state.autoSyncBooksAccounts };
      if (
        action.payload.response.region in
        deletedAccount[action.payload.response.book][
        action.payload.response.username
        ]
      ) {
        delete deletedAccount[action.payload.response.book][
          action.payload.response.username
        ][action.payload.response.region];
        if (
          Object.keys(
            deletedAccount[action.payload.response.book][
            action.payload.response.username
            ]
          ).length === 0
        ) {
          delete deletedAccount[action.payload.response.book][
            action.payload.response.username
          ];
        }
        if (
          Object.keys(deletedAccount[action.payload.response.book]).length === 0
        ) {
          delete deletedAccount[action.payload.response.book];
        }
      }
      return Object.assign({}, state, {
        ...state,
        isLoadingAutoSyncDisconnectSuccess: true,
        isLoadingAutoSyncDisconnect: false,
        isLoadingAutoSyncDisconnectFailure: null,
        autoSyncBooksAccounts: deletedAccount,
      });
    }
    case DISCONNECT_AUTOSYNC_FAILURE: {
      return Object.assign({}, state, {
        ...state,
        isLoadingAutoSyncDisconnectFailure: action.payload,
        isLoadingAutoSyncDisconnect: false,
        isLoadingAutoSyncDisconnectSuccess: null,
      });
    }

    case DISCONNECT_ALL_AUTOSYNC: {
      return Object.assign({}, state, {
        ...state,
        isLoadingAutoSyncDisconnectAll: true,
        isLoadingAutoSyncDisconnectAllFailure: null,
        isLoadingAutoSyncDisconnectAllSuccess: null,
      });
    }
    case DISCONNECT_ALL_AUTOSYNC_SUCCESS: {
      let deletedBookAccounts = { ...state.autoSyncBooksAccounts };
      for (let prop in deletedBookAccounts[action.payload.response.book]) {
        delete deletedBookAccounts[action.payload.response.book][prop];
      }
      delete deletedBookAccounts[action.payload.response.book];
      return Object.assign({}, state, {
        ...state,
        isLoadingAutoSyncDisconnectAllSuccess: true,
        isLoadingAutoSyncDisconnectAll: false,
        isLoadingAutoSyncDisconnectAllFailure: null,
        autoSyncBooksAccounts: deletedBookAccounts,
      });
    }

    case DISCONNECT_ALL_AUTOSYNC_FAILURE: {
      return Object.assign({}, state, {
        ...state,
        isLoadingAutoSyncDisconnectAllFailure: action.payload,
        isLoadingAutoSyncDisconnectAll: false,
        isLoadingAutoSyncDisconnectAllSuccess: null,
      });
    }
    case CLEAN_AUTOSYNC_STORE: {
      return Object.assign({}, state, {
        autoSyncBooksAccounts: {},
        isLoadingGetAccounts: false,
        isLoadingGetAccountsSuccess: null,
        isLoadingGetAccountsFailure: null,
        isLoadingAutoSyncLogin: false,
        isLoadingAutoSyncLoginSuccess: null,
        isLoadingAutoSyncLoginFailure: null,
        isLoadingAutoSyncDisconnect: false,
        isLoadingAutoSyncDisconnectSuccess: null,
        isLoadingAutoSyncDisconnectFailure: null,
        isLoadingAutoSyncDisconnectAll: false,
        isLoadingAutoSyncDisconnectAllSuccess: null,
        isLoadingAutoSyncDisconnectAllFailure: null,
        isLoadingSyncBets: false,
        isLoadingSyncBetsSuccess: null,
        isLoadingSyncBetsFailure: null,
        isLoadingSyncAllBets: {},
        isLoadingSyncAllBetsSuccess: {},
        isLoadingSyncAllBetsFailure: {},
        isLoadingRefreshHistory: false,
        isLoadingRefreshHistorySuccess: null,
        isLoadingRefreshHistoryFailure: null,
        isLoadingRefreshHistoryAll: false,
        isLoadingRefreshHistoryAllSuccess: null,
        isLoadingRefreshHistoryAllFailure: null,
        latestRefresh: null,
        isLoading2FA: false,
        isLoading2FASuccess: null,
        isLoading2FAFailure: null,
        needs2FA: null,
        twoFAData: {},
        isLoadingUpdateSettings: false,
        isLoadingUpdateSettingsSuccess: null,
        isLoadingUpdateSettingsFailure: null,
      });
    }
    case SYNC_BETS: {
      return Object.assign({}, state, {
        ...state,
        isLoadingSyncBets: true,
        isLoadingSyncBetsFailure: null,
        isLoadingSyncBetsSuccess: null,
      });
    }
    case SYNC_BETS_SUCCESS: {
      let updatedBooksRefresh = { ...state.autoSyncBooksAccounts };
      let latestBookRefresh = { ...state.latestRefresh };
      updatedBooksRefresh[action.payload.response.book.name] = {
        ...updatedBooksRefresh[action.payload.response.book.name],
      };
      updatedBooksRefresh[action.payload.response.book.name][
        action.payload.response.username
      ] = {
        ...updatedBooksRefresh[action.payload.response.book.name][
        action.payload.response.username
        ],
      };
      updatedBooksRefresh[action.payload.response.book.name][
        action.payload.response.username
      ][action.payload.response.region] = {
        ...updatedBooksRefresh[action.payload.response.book.name][
        action.payload.response.username
        ][action.payload.response.region],
      };
      updatedBooksRefresh[action.payload.response.book.name][
        action.payload.response.username
      ][action.payload.response.region]['lastRefresh'] =
        action.payload.response.last_refresh;
      updatedBooksRefresh[action.payload.response.book.name][
        action.payload.response.username
      ][action.payload.response.region]['currentBalance'] =
        action.payload.response.current_balance;
      updatedBooksRefresh[action.payload.response.book.name][
        action.payload.response.username
      ][action.payload.response.region]['currentBonus'] =
        action.payload.response.bonus_balance;
      updatedBooksRefresh[action.payload.response.book.name][
        action.payload.response.username
      ][action.payload.response.region]['totalRefreshes'] =
        action.payload.response.total_refreshes;
      updatedBooksRefresh[action.payload.response.book.name][
        action.payload.response.username
      ][action.payload.response.region]['record'] =
        action.payload.response.record;
      updatedBooksRefresh[action.payload.response.book.name][
        action.payload.response.username
      ][action.payload.response.region]['createdAt'] =
        action.payload.response.created_at;
      updatedBooksRefresh[action.payload.response.book.name][
        action.payload.response.username
      ][action.payload.response.region]['credentialsOOD'] =
        action.payload.response.credentials_out_of_date;
      updatedBooksRefresh[action.payload.response.book.name][
        action.payload.response.username
      ][action.payload.response.region]['two_fa_required'] =
        action.payload.response.two_fa_required;
      updatedBooksRefresh[action.payload.response.book.name][
        action.payload.response.username
      ][action.payload.response.region]['id'] = action.payload.response.pk;

      latestBookRefresh[action.payload.response.book.name] =
        action.payload.response.last_refresh;
      return Object.assign({}, state, {
        ...state,
        isLoadingSyncBetsSuccess: true,
        isLoadingSyncBets: false,
        isLoadingSyncBetsFailure: null,
        autoSyncBooksAccounts: updatedBooksRefresh,
        latestRefresh: latestBookRefresh,
      });
    }
    case SYNC_BETS_FAILURE: {
      return Object.assign({}, state, {
        ...state,
        isLoadingSyncBets: false,
        isLoadingSyncBetsFailure: action.payload,
        isLoadingSyncBetsSuccess: null,
      });
    }
    case SYNC_ALL_BETS: {
      let syncAllLoading = { ...state.isLoadingSyncAllBets };
      syncAllLoading[action.params.book] = true;
      // remove book from errors/success
      let syncAllErrors = { ...state.isLoadingSyncAllBetsFailure };
      let syncAllSuccess = { ...state.isLoadingSyncAllBetsSuccess };
      if (action.params.book in syncAllErrors) {
        delete syncAllErrors[action.params.book];
      }
      if (action.params.book in syncAllSuccess) {
        delete syncAllSuccess[action.params.book];
      }
      return Object.assign({}, state, {
        ...state,
        isLoadingSyncAllBets: syncAllLoading,
        isLoadingSyncAllBetsFailure: syncAllErrors,
        isLoadingSyncAllBetsSuccess: syncAllSuccess,
      });
    }
    case SYNC_ALL_BETS_SUCCESS: {
      let updatedBooksRefreshAll = { ...state.autoSyncBooksAccounts };
      let latestBookRefresh = { ...state.latestRefresh };
      let syncAllSuccess = { ...state.isLoadingSyncAllBets };
      syncAllSuccess[action.params.book] = true;

      for (let obj of action.payload.response) {
        updatedBooksRefreshAll[obj['book']['name']] = {
          ...updatedBooksRefreshAll[obj['book']['name']],
        };
        updatedBooksRefreshAll[obj['book']['name']][obj['username']] = {
          ...updatedBooksRefreshAll[obj['book']['name']][obj['username']],
        };
        updatedBooksRefreshAll[obj['book']['name']][obj['username']][
          obj['region']
        ] = {
          ...updatedBooksRefreshAll[obj['book']['name']][obj['username']][
          obj['region']
          ],
          lastRefresh: obj['last_refresh'],
          currentBalance: obj['current_balance'],
          currentBonus: obj['bonus_balance'],
          totalRefreshes: obj['total_refreshes'],
          record: obj['record'],
          createdAt: obj['created_at'],
          credentialsOOD: obj['credentials_out_of_date'],
          two_fa_required: obj['two_fa_required'],
          id: obj['pk'],
        };
        latestBookRefresh = { ...latestBookRefresh };
        latestBookRefresh[obj['book']['name']] = obj['last_refresh'];
      }

      // remove book from loading/errors
      let syncAllLoading = { ...state.isLoadingSyncAllBets };
      let syncAllErrors = { ...state.isLoadingSyncAllBetsFailure };
      delete syncAllLoading[action.params.book];
      if (action.params.book in syncAllErrors) {
        delete syncAllErrors[action.params.book];
      }

      return Object.assign({}, state, {
        ...state,
        isLoadingSyncAllBetsSuccess: syncAllSuccess,
        isLoadingSyncAllBets: syncAllLoading,
        isLoadingSyncAllBetsFailure: syncAllErrors,
        autoSyncBooksAccounts: updatedBooksRefreshAll,
        latestRefresh: latestBookRefresh,
      });
    }
    case SYNC_ALL_BETS_FAILURE: {
      let syncAllErrors = { ...state.isLoadingSyncAllBetsFailure };
      let book = action.params.book;
      syncAllErrors[book] = action.payload;
      // remove book from loading/success
      let syncAllLoading = { ...state.isLoadingSyncAllBets };
      let syncAllSuccess = { ...state.isLoadingSyncAllBetsSuccess };
      delete syncAllLoading[action.params.book];
      if (action.params.book in syncAllSuccess) {
        delete syncAllSuccess[action.params.book];
      }

      return Object.assign({}, state, {
        ...state,
        isLoadingSyncAllBets: syncAllLoading,
        isLoadingSyncAllBetsFailure: syncAllErrors,
        isLoadingSyncAllBetsSuccess: syncAllSuccess,
      });
    }

    case SYNC_ALL_ACCOUNTS: {
      let syncAllLoading = { ...state.isLoadingSyncAllAccounts };
      let syncAllErrors = { ...state.isLoadingSyncAllAccountsFailure };
      let syncAllSuccess = { ...state.isLoadingSyncAllAccountsSuccess };
      Object.keys(state.autoSyncBooksAccounts).forEach(book_name => {
        syncAllLoading[book_name] = true;
        if (book_name in syncAllErrors) {
          delete syncAllErrors[book_name];
        }
        if (book_name in syncAllSuccess) {
          delete syncAllSuccess[book_name];
        }
      });
      return Object.assign({}, state, {
        ...state,
        isLoadingSyncAllAccounts: syncAllLoading,
        isLoadingSyncAllAccountsFailure: syncAllErrors,
        isLoadingSyncAllAccountsSuccess: syncAllSuccess,
      });
    }

    case SYNC_ALL_ACCOUNTS_FAILURE: {
      return Object.assign({}, state, {
        ...state,
        isLoadingSyncAllAccounts: {},
        isLoadingSyncAllAccountsFailure: { error: 'Sync Unsuccessful' },
        isLoadingSyncAllAccountsSuccess: {},
      });
    }

    case SYNC_ALL_ACCOUNTS_SUCCESS: {
      let updatedBooksRefreshAll = { ...state.autoSyncBooksAccounts };
      let latestBookRefresh = { ...state.latestRefresh };
      let syncAllSuccess = { ...state.isLoadingSyncAllAccounts };
      let syncAllLoading = {};
      let syncAllErrors = { ...state.isLoadingSyncAllAccountsFailure };

      Object.keys(state.autoSyncBooksAccounts).forEach(book_name => {
        syncAllSuccess[book_name] = true;
      });

      for (let obj of action.payload.response.accounts) {
        updatedBooksRefreshAll[obj['book']['name']] = {
          ...updatedBooksRefreshAll[obj['book']['name']],
        };
        updatedBooksRefreshAll[obj['book']['name']][obj['username']] = {
          ...updatedBooksRefreshAll[obj['book']['name']][obj['username']],
        };
        updatedBooksRefreshAll[obj['book']['name']][obj['username']][
          obj['region']
        ] = {
          ...updatedBooksRefreshAll[obj['book']['name']][obj['username']][
          obj['region']
          ],
          lastRefresh: obj['last_refresh'],
          currentBalance: obj['current_balance'],
          currentBonus: obj['bonus_balance'],
          totalRefreshes: obj['total_refreshes'],
          record: obj['record'],
          createdAt: obj['created_at'],
          credentialsOOD: obj['credentials_out_of_date'],
          two_fa_required: obj['two_fa_required'],
          id: obj['pk'],
        };
        latestBookRefresh = { ...latestBookRefresh };
        latestBookRefresh[obj['book']['name']] = obj['last_refresh'];
      }

      for (let book_name of action.payload.response.error_books) {
        syncAllErrors[book_name] = { message: 'Sync Unsuccessful' };
        if (book_name in syncAllLoading) {
          delete syncAllLoading[book_name];
        }
        if (book_name in syncAllSuccess) {
          delete syncAllSuccess[book_name];
        }
      }
      return Object.assign({}, state, {
        ...state,
        isLoadingSyncAllAccountsSuccess: syncAllSuccess,
        isLoadingSyncAllAccounts: syncAllLoading,
        isLoadingSyncAllAccountsFailure: syncAllErrors,
        autoSyncBooksAccounts: updatedBooksRefreshAll,
        latestRefresh: latestBookRefresh,
      });
    }

    case REFRESH_HISTORY: {
      return Object.assign({}, state, {
        ...state,
        isLoadingRefreshHistory: true,
        isLoadingRefreshHistoryFailure: null,
        isLoadingRefreshHistorySuccess: null,
      });
    }
    case REFRESH_HISTORY_SUCCESS: {
      let refreshedAccounts = { ...state.autoSyncBooksAccounts };
      let latestBookRefresh = { ...state.latestRefresh };
      refreshedAccounts[action.payload.response.book.name] = {
        ...refreshedAccounts[action.payload.response.book.name],
      };
      refreshedAccounts[action.payload.response.book.name][
        action.payload.response.username
      ] = {
        ...refreshedAccounts[action.payload.response.book.name][
        action.payload.response.username
        ],
      };
      refreshedAccounts[action.payload.response.book.name][
        action.payload.response.username
      ][action.payload.response.region] = {
        ...refreshedAccounts[action.payload.response.book.name][
        action.payload.response.username
        ][action.payload.response.region],
      };
      refreshedAccounts[action.payload.response.book.name][
        action.payload.response.username
      ][action.payload.response.region]['lastRefresh'] =
        action.payload.response.last_refresh;
      refreshedAccounts[action.payload.response.book.name][
        action.payload.response.username
      ][action.payload.response.region]['currentBalance'] =
        action.payload.response.current_balance;
      refreshedAccounts[action.payload.response.book.name][
        action.payload.response.username
      ][action.payload.response.region]['currentBonus'] =
        action.payload.response.bonus_balance;
      refreshedAccounts[action.payload.response.book.name][
        action.payload.response.username
      ][action.payload.response.region]['totalRefreshes'] =
        action.payload.response.total_refreshes;
      refreshedAccounts[action.payload.response.book.name][
        action.payload.response.username
      ][action.payload.response.region]['record'] =
        action.payload.response.record;
      refreshedAccounts[action.payload.response.book.name][
        action.payload.response.username
      ][action.payload.response.region]['createdAt'] =
        action.payload.response.created_at;
      refreshedAccounts[action.payload.response.book.name][
        action.payload.response.username
      ][action.payload.response.region]['credentialsOOD'] =
        action.payload.response.credentials_out_of_date;
      refreshedAccounts[action.payload.response.book.name][
        action.payload.response.username
      ][action.payload.response.region]['two_fa_required'] =
        action.payload.response.two_fa_required;
      refreshedAccounts[action.payload.response.book.name][
        action.payload.response.username
      ][action.payload.response.region]['id'] = action.payload.response.pk;
      latestBookRefresh[action.payload.response.book.name] =
        action.payload.response.last_refresh;
      return Object.assign({}, state, {
        ...state,
        isLoadingRefreshHistory: false,
        isLoadingRefreshHistorySuccess: true,
        isLoadingRefreshHistoryFailure: null,
        autoSyncBooksAccounts: refreshedAccounts,
        latestRefresh: latestBookRefresh,
      });
    }
    case REFRESH_HISTORY_FAILURE: {
      return Object.assign({}, state, {
        ...state,
        isLoadingRefreshHistory: false,
        isLoadingRefreshHistorySuccess: null,
        isLoadingRefreshHistoryFailure: action.payload,
      });
    }
    case REFRESH_HISTORY_ALL: {
      return Object.assign({}, state, {
        ...state,
        isLoadingRefreshHistoryAll: true,
        isLoadingRefreshHistoryAllFailure: null,
        isLoadingRefreshHistoryAllSuccess: null,
      });
    }
    case REFRESH_HISTORY_ALL_SUCCESS: {
      let refreshedAccountsAll = { ...state.autoSyncBooksAccounts };
      let latestBookRefresh = { ...state.latestRefresh };
      refreshedAccountsAll[action.payload.response.book] = {
        ...refreshedAccountsAll[action.payload.response.book],
      };
      for (let obj of action.payload.response) {
        refreshedAccountsAll[obj['book']['name']] = {
          ...refreshedAccountsAll[obj['book']['name']],
        };
        refreshedAccountsAll[obj['book']['name']][obj['username']] = {
          ...refreshedAccountsAll[obj['book']['name']][obj['username']],
        };
        refreshedAccountsAll[obj['book']['name']][obj['username']][
          obj['region']
        ] = {
          ...refreshedAccountsAll[obj['book']['name']][obj['username']][
          obj['region']
          ],
          lastRefresh: obj['last_refresh'],
          currentBalance: obj['current_balance'],
          currentBonus: obj['bonus_balance'],
          totalRefreshes: obj['total_refreshes'],
          record: obj['record'],
          createdAt: obj['created_at'],
          credentialsOOD: obj['credentials_out_of_date'],
          two_fa_required: obj['two_fa_required'],
          id: obj['id'],
        };
        latestBookRefresh = { ...latestBookRefresh };
        latestBookRefresh[obj['book']['name']] = obj['last_refresh'];
      }

      return Object.assign({}, state, {
        ...state,
        isLoadingRefreshHistoryAll: false,
        isLoadingRefreshHistoryAllSuccess: true,
        isLoadingRefreshHistoryAllFailure: null,
        autoSyncBooksAccounts: refreshedAccountsAll,
        latestRefresh: latestBookRefresh,
      });
    }
    case REFRESH_HISTORY_ALL_FAILURE: {
      return Object.assign({}, state, {
        ...state,
        isLoadingRefreshHistoryAll: false,
        isLoadingRefreshHistoryAllSuccess: null,
        isLoadingRefreshHistoryAllFailure: action.payload,
      });
    }
    case PROMPT_TWO_FA: {
      return Object.assign({}, state, {
        ...state,
        needs2FA: action.payload.response.book,
        twoFAData: action.payload.response,
        isLoadingAutoSyncLogin: false,
        isLoadingSyncBets: false,
        isLoadingSyncAllBets: {},
        isLoading2FA: false,
        isLoading2FASuccess: null,
        isLoading2FAFailure: null,
      });
    }
    case TWO_FA: {
      return Object.assign({}, state, {
        ...state,
        isLoading2FA: true,
        isLoading2FASuccess: null,
        isLoading2FAFailure: null,
      });
    }
    case TWO_FA_SUCCESS: {
      return Object.assign({}, state, {
        ...state,
        isLoadingAutoSyncLogin: false,
        isLoadingSyncAllBets: {},
        isLoadingSyncBets: false,
        isLoading2FASuccess: true,
        isLoading2FA: false,
        isLoading2FAFailure: null,
        needs2FA: null,
        twoFAData: {},
      });
    }
    case TWO_FA_FAILURE: {
      return Object.assign({}, state, {
        ...state,
        isLoading2FA: false,
        isLoading2FASuccess: null,
        isLoading2FAFailure: action.error,
      });
    }

    case UPDATE_SETTINGS: {
      return Object.assign({}, state, {
        ...state,
        isLoadingUpdateSettings: true,
        isLoadingUpdateSettingsSuccess: null,
        isLoadingUpdateSettingsFailure: null,
      });
    }

    case UPDATE_SETTINGS_SUCCESS: {
      return Object.assign({}, state, {
        ...state,
        isLoadingUpdateSettings: false,
        isLoadingUpdateSettingsSuccess: true,
        isLoadingUpdateSettingsFailure: null,
      });
    }

    case UPDATE_SETTINGS_FAILURE: {
      return Object.assign({}, state, {
        ...state,
        isLoadingUpdateSettings: false,
        isLoadingUpdateSettingsSuccess: null,
        isLoadingUpdateSettingsFailure: action.error,
      });
    }

    default: {
      return state;
    }
  }
}
