import { combineEpics } from 'redux-observable';

import getTransactions from 'epics/transaction/getTransactions.epic';
import getTransactionSummary from 'epics/transaction/getTransactionSummary.epic';
import getTransactionsNext from 'epics/transaction/getTransactionsNext.epic';
import deleteTransaction from 'epics/transaction/deleteTransaction.epic';
import createTransaction from 'epics/transaction/createTransaction.epic';
import updateTransaction from 'epics/transaction/updateTransaction.epic';

export default combineEpics(
  getTransactions,
  getTransactionSummary,
  getTransactionsNext,
  deleteTransaction,
  createTransaction,
  updateTransaction
);
