import React from 'react';

import CustomSelect from 'components/generic/Select';

export default function IsVerified({ defaultValue, onDone }) {
  return (
    <>
      <CustomSelect
        onChange={(opt) => {
          onDone({
            filter: 'Verified',
            value: opt.value,
            slug: opt.label,
          });
        }}
        value={{
          value: defaultValue,
          label: defaultValue === 'true' ? 'Only Verified' : 'All Bets',
        }}
        options={[
          { label: 'All Bets', value: 'false' },
          { label: 'Only Verified', value: 'true' },
        ]}
      />
    </>
  );
}
