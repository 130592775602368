import React, { useState } from 'react';
import { useEffectOnce } from 'react-use';
import { useDispatch } from 'react-redux';
import moment from 'moment';

// components
import UserPicks from 'components/user_profile/UserPicks.toptab.js';
import DateRange from 'components/generic/DateRange';

// actions
import { getWagers } from 'actions';

export default function MyPicksHidden(props) {
  const dispatch = useDispatch();

  const [dateAfter, setDateAfter] = useState(
    moment().subtract(1, 'week').endOf('day')
  );
  const [dateBefore, setDateBefore] = useState(moment().endOf('day'));

  useEffectOnce(() => {
    dispatch(
      getWagers({
        screen: 'hidden',
        date_after: dateAfter,
        date_before: dateBefore,
        show_only_hidden: true,
      })
    );
  });

  return (
    <div className="container">
      <h1>Hidden Wagers</h1>
      <p>
        These wagers are hidden from your personal tracking, but still available
        for others to see (if they're public wagers) and they still count
        towards your verified record (if they're public and verified).
      </p>
      <DateRange
        getItemsInRange={(d1, d2) => {
          dispatch(
            getWagers({
              screen: 'hidden',
              date_after: d1,
              date_before: d2,
              show_only_hidden: true,
            })
          );
          setDateAfter(d1);
          setDateBefore(d2);
        }}
        defaultStartDate={dateAfter}
        defaultEndDate={dateBefore}
        placeholder={'Past Week'}
      />
      <br />
      <UserPicks
        screen={'hidden'}
        showPublicBets={false}
        scrollableTarget={props.scrollTarget || null}
        // isDesktop={props.desktop}
      />
    </div>
  );
}
