import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// components
import { OnboardingWrapper, BlueBubble } from 'components/auth/onboarding';
import { Col, Row } from 'components/generic/Layout';
import Select from 'components/generic/Select';

// utils
import { STATE_OPTIONS, REGULATED_STATES } from 'utils';

// actions
import { updateUser } from 'actions';

export default function UnregulatedRookie(props) {
  const dispatch = useDispatch();
  const reduxProps = useSelector(state => ({
    user: state.authReducer.user,
  }));
  const { user } = reduxProps;

  const [newUserRegion, setNewUserRegion] = useState({ state: user.state });

  return (
    <OnboardingWrapper nextScreen="/onboarding/meantime" onNext={() => {}}>
      <Col style={{ flex: 0, width: '100%' }}>
        <BlueBubble
          style={{
            marginTop: 'var(--space-md)',
            marginBottom: 'var(--space-md)',
            justifyContent: 'flex-start',
          }}
        >
          <h5
            style={{
              marginTop: 'var(--space-md)',
              marginBottom: 'var(--space-md)',
              color: 'white',
            }}
          >
            {`Sports betting is ${
              newUserRegion.state in REGULATED_STATES ? '' : 'not yet'
            } regulated in ${newUserRegion.state}`}
          </h5>
        </BlueBubble>
        <Row style={{ width: 'min(100%, 500px)' }}>
          <Select
            options={STATE_OPTIONS.map(s => ({ label: s, value: s }))}
            onChange={region => {
              dispatch(updateUser({ state: region.value }));
              setNewUserRegion({ state: region.value });
            }}
            value={
              newUserRegion
                ? {
                    label: newUserRegion.state,
                    value: newUserRegion.state,
                  }
                : null
            }
          />
        </Row>
        <img
          style={{
            margin: 'var(--space-md) 0',
            maxWidth: '300px',
          }}
          src={
            'https://betstamp-public.sfo2.cdn.digitaloceanspaces.com/signup-flow/betlink.png'
          }
          alt={'betlink'}
        />
        <h6
          style={{
            marginBottom: 'var(--space-md)',
            fontWeight: 'bold',
            textAlign: 'center',
            alignSelf: 'center',
            padding: '0 var(--space-sm)',
          }}
        >
          We will notify you with any updates as soon as they are available
        </h6>
      </Col>
    </OnboardingWrapper>
  );
}
