import { Observable } from 'rxjs';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import { ajax } from 'rxjs/observable/dom/ajax';
import { getHeaders } from 'utils';

import {
  GET_TRANSACTIONS_NEXT,
  getTransactionsSuccess,
  getTransactionsFailure,
} from 'actions';

export default function getTransactionsNext(action$, state$) {
  return action$.ofType(GET_TRANSACTIONS_NEXT).concatMap(action => {
    return ajax
      .getJSON(
        state$.value.transactionReducer.transactionsNext,
        getHeaders(state$)
      )
      .map(data => getTransactionsSuccess(data, action.screen))
      .catch(error =>
        Observable.of(getTransactionsFailure(error.xhr, action.screen))
      );
  });
}
