import React from 'react';
import { Row, Col } from 'components/generic/Layout';
import { useSelector, useDispatch } from 'react-redux';

//components
import { ToggleSetting } from 'components/SettingsBtn';
import CurrencyInput from 'components/generic/CurrencyInput';

import { updateUser } from 'actions';

export default function OddsSettings(props) {
  const dispatch = useDispatch();
  const reduxProps = useSelector(state => ({
    user: state.authReducer.user,
  }));

  const { user } = reduxProps;

  return (
    <Col
      style={{
        justifyContent: 'flex-start',
        alignItems: 'stretch',
        ...props.style,
      }}
    >
      <Row
        style={{
          alignItems: 'center',
          justifyContent: 'space-between',
          position: 'sticky',
          top: 0,
          backgroundColor: 'var(--color-bg)',
          zIndex: 101,
          borderBottom: '1px solid #9b9b9b',
          padding: 'var(--space-sm) 0',
        }}
      >
        <h3 style={{ margin: 0, flex: 2 }}>Odds Settings</h3>
      </Row>
      <ToggleSetting
        heading="Show Best Available Odds From"
        map={{
          ALL: 'All Books',
          REGION: 'My State',
          MY: 'My Books',
          'All Books': 'ALL',
          'My State': 'REGION',
          'My Books': 'MY',
        }}
        active={user.show_books_ba}
        options={['All Books', 'My State', 'My Books']}
        updateUser
        userSetting="show_books_ba"
      />
      <ToggleSetting
        heading="Show Additional Odds From"
        map={{
          ALL: 'All Books',
          REGION: 'My State',
          MY: 'My Books',
          'All Books': 'ALL',
          'My State': 'REGION',
          'My Books': 'MY',
        }}
        active={user.show_books}
        options={['All Books', 'My State']}
        updateUser
        userSetting="show_books"
      />
      <ToggleSetting
        heading="Default Odds"
        map={{
          0: 'American',
          1: 'Decimal',
          American: 0,
          Decimal: 1,
        }}
        active={user.odds_preference}
        options={['American', 'Decimal']}
        updateUser
        userSetting="odds_preference"
      />
      <ToggleSetting
        heading="Default Risk/Base"
        map={{
          Base: 'BASE',
          Risk: 'RISK',
          BASE: 'Base',
          RISK: 'Risk',
        }}
        active={user.risk_or_base}
        options={['Base', 'Risk']}
        updateUser
        userSetting="risk_or_base"
      >
        <CurrencyInput
          commaSeparate
          containerStyle={{ marginLeft: 'var(--space-md)' }}
          style={{ textAlign: 'center', width: '100%' }}
          defaultValue={parseFloat(user.bet_size)}
          onChange={dollars => {
            dispatch(
              updateUser({
                bet_size: parseFloat(dollars),
              })
            );
          }}
        />
      </ToggleSetting>
    </Col>
  );
}
